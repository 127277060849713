import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useDocumentsContext } from '../../hooks';

type FolderEditFields = {
  folderName: string;
  archiveInMonths?: string | null;
};

const ARCHIVE_IN_OPTIONS = [
  { id: 'never', label: 'Never', value: null },
  { id: 'end_of_quarter', label: 'At End of Quarter', value: 0 },
  { id: 'one_month', label: '1 Month After Quarter', value: 1 },
  { id: 'two_months', label: '2 Months After Quarter', value: 2 },
  { id: 'three_months', label: '3 Months After Quarter', value: 3 },
  { id: 'other', label: 'Other (Specify Months After Quarter)' },
];

export const useArchivePanel = (
  folderEdits: any,
  setFolderEdits: Function,
  handleNextAction: Function,
  isRootView: boolean | undefined,
  editing: boolean
) => {
  const { state } = useDocumentsContext();

  const [archiveInSelection, setArchiveInSelection] = useState<any>();
  const [showArchiveSubfoldersDialog, setShowArchiveSubfoldersDialog] =
    useState<boolean>(false);

  const { handleSubmit, control, getValues, reset } = useForm<FolderEditFields>(
    {
      defaultValues: {
        folderName: editing ? folderEdits.name : '',
        archiveInMonths: editing ? (folderEdits.archiveIn as string) : null,
      },
    }
  );

  useEffect(() => {
    if (editing) {
      const existingArchiveSetting = ARCHIVE_IN_OPTIONS.find(
        (option) => folderEdits.archiveIn === option.value
      );

      setArchiveInSelection(existingArchiveSetting?.id || 'other');
    } else {
      setArchiveInSelection('never');
    }
  }, []);

  useEffect(() => {
    onClose();
  }, [state?.cursor]);

  const onSubmit = (data: any) => {
    if (archiveInSelection !== 'other') {
      data.archiveInMonths = ARCHIVE_IN_OPTIONS.find(
        (option) => archiveInSelection === option.id
      )?.value;
    }

    setFolderEdits(data);

    if (editing) {
      setShowArchiveSubfoldersDialog(true);
    } else {
      handleNextAction(
        isRootView ? 'assign-permissions' : 'create-folder',
        data
      );
    }
  };

  const handleArchiveSubfolders = (applyToChildren: boolean) => {
    setFolderEdits((prevState: any) => ({
      ...prevState,
      applyToChildren: applyToChildren,
    }));

    const data = { ...folderEdits, applyToChildren: applyToChildren };

    handleNextAction(
      isRootView ? 'assign-permissions' : 'add-edit-subFolder',
      data
    );
  };

  const onClose = () => {
    resetForm();
  };

  const resetForm = () => {
    reset();
  };

  return {
    ARCHIVE_IN_OPTIONS,
    archiveInSelection,
    setArchiveInSelection,
    onSubmit,
    handleSubmit,
    control,
    getValues,
    showArchiveSubfoldersDialog,
    handleArchiveSubfolders,
  };
};
