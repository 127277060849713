import { cloneDeep } from "lodash";
import { findLast } from "lodash";

import { DateTimeFormat } from "../../../../../../../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../../../../../../../utils/helpers/quarter.helper";
import { CommitmentColumn, InvestorCommitment, InvestorRowType } from "../../../../workbook.type";
import { CellFormats, ColWidth, NewColPosition } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseColumnsManager } from "./BaseColumns.Manager";

export class CommitmentColumnsManager extends BaseColumnsManager {
    private readonly commitmentCloseOnStartStr = 'Commitment Close on';
    private _investorCommitments = new Map<string, number[]>();

    private _commitmentColumns!: CommitmentColumn[];

    public get startColumnId(): string {
        return this.commitmentColumns[0].gridColId;
    }

    public get endColumnId(): string {
        return this.commitmentColumns[this._commitmentColumns.length-1].gridColId;
    }

    public get commitmentColumns() {
        return cloneDeep(this._commitmentColumns).sort((a,b) => a.quarterNumber - b.quarterNumber || DateTimeFormat.ensureAsDate(a.commitmentDate).getTime() - DateTimeFormat.ensureAsDate(b.commitmentDate).getTime());
    }

    public initCommitmentsColumnsFromInvestorCommitments(investorCommitments: InvestorCommitment[], initialStartColId: string): void {
        const wbManager = this.workbookSheetsManager;
        const quarterStartEndDates = getQuarterStartEndDates(wbManager.frequency === 'BY_QUARTER' ? wbManager.lastQuarter : 4, wbManager.workbookYear);

        this._commitmentColumns = [];
        
        this.createNewCommitmentColumns(investorCommitments, initialStartColId!, false);
        this.updateInvestorCommitmentAmounts(investorCommitments, quarterStartEndDates.endDate);
    }

    public initCommitmentsColumnsFromWorkbook(commitmentColumns: CommitmentColumn[]) {
        this._commitmentColumns = commitmentColumns;
    }

    public updateCommitmentsColumns(investorCommitments: InvestorCommitment[]) {
        const wbManager = this.workbookSheetsManager;
        const lastQuarter = wbManager.lastQuarter;
        const quarterStartEndDates = getQuarterStartEndDates(wbManager.lastQuarter, wbManager.workbookYear);

        let startColumnId: string;
        let firstQuarterCol = this.commitmentColumns.find(c => !c.quarterSum && c.quarterNumber === lastQuarter);

        if(firstQuarterCol) {
            startColumnId = firstQuarterCol.gridColId;
        } else {
            firstQuarterCol = this.commitmentColumns.find(c => c.quarterSum && c.quarterNumber === lastQuarter-1);
            startColumnId = this.grid.getNextColId(firstQuarterCol!.gridColId);
        }

        const newColIDs = this.createNewCommitmentColumns(investorCommitments, startColumnId, true);

        this.updateInvestorCommitmentAmounts(investorCommitments, quarterStartEndDates.endDate);

        return newColIDs;
    }

    private createNewCommitmentColumns(investorCommitments: InvestorCommitment[], initialStartColId: string, insertGridCols: boolean) {
        const wb = this.workbookSheetsManager;
        const grid = this.grid;
        const firstQuarter = wb.frequency === 'BY_QUARTER' ? wb.firstQuarter : 4;
        const lastQuarter = wb.frequency === 'BY_QUARTER' ? wb.lastQuarter : 4;

        const newInsertedColIds: string[] = [];

        for(let q = firstQuarter; q <= lastQuarter; q++) {

            const colQuarter = wb.frequency === 'BY_QUARTER' ? q : 1;
            const quarterStartEndDates = getQuarterStartEndDates(q, wb.workbookYear);

            if(q === firstQuarter) {
                quarterStartEndDates.startDate = new Date(0);
            }

            const uniqueCommitmentDates = 
                this.getUniqueCommitmentDates(investorCommitments, quarterStartEndDates.startDate, quarterStartEndDates.endDate);
            
            uniqueCommitmentDates.forEach(cd => {
                const quarterCommitCols = this._commitmentColumns.filter(c => !c.quarterSum && c.quarterNumber === colQuarter)
                    .sort((a,b) => DateTimeFormat.compareDateOnly(a.commitmentDate, b.commitmentDate));      
                const commitCol = findLast(quarterCommitCols, c => !c.quarterSum && DateTimeFormat.compareDateOnly(cd, DateTimeFormat.ensureAsDate(c.commitmentDate)) >= 0);
                const foundExactCol = commitCol ? DateTimeFormat.compareDateOnly(cd, DateTimeFormat.ensureAsDate(commitCol.commitmentDate)) === 0 : false;

                if(!foundExactCol) {
                    let newColid: string;

                    if(commitCol) {
                        newColid = insertNewColumn(commitCol.gridColId);
                    } else {
                        if(quarterCommitCols?.length) {
                            newColid = insertNewColumn(grid.getPrevCol(quarterCommitCols[0].gridColId));
                        } else {
                            newColid = insertNewColumn(grid.getPrevCol(initialStartColId));
                        }
                    }
                    
                    this._commitmentColumns.push({
                        gridColId: newColid,
                        label: `${this.commitmentCloseOnStartStr} ${DateTimeFormat.longDate(cd)}`,
                        quarterSum: false,
                        commitmentDate: DateTimeFormat.isoDateString(cd),
                        quarterNumber: colQuarter,
                    } as CommitmentColumn);  
                }
            });
            

            if(!this._commitmentColumns.find(cc => cc.quarterNumber === colQuarter && cc.quarterSum)) {
                const gridColId = this._commitmentColumns?.length ? this.commitmentColumns[this._commitmentColumns.length-1].gridColId : initialStartColId;
                const newCurrColId = insertNewColumn(gridColId);
                const asOfNumber = wb.frequency === 'BY_QUARTER' ? `Q${colQuarter}` : wb.workbookYear;

                this._commitmentColumns.push({
                    gridColId: newCurrColId,
                    label: `Commitment as of ${asOfNumber}`,
                    quarterSum: true,
                    commitmentDate: DateTimeFormat.isoDateString(quarterStartEndDates.endDate),
                    quarterNumber: colQuarter
                } as CommitmentColumn);
            }

        }

        return newInsertedColIds;

        function insertNewColumn(currColId: string) {
            let newColId: string;

            if(insertGridCols) {
                newColId = grid.insertCol(currColId, 1, NewColPosition.rightOfDestination);
                newInsertedColIds.push(newColId);
            } else {
                newColId = grid.getNextColId(currColId);
            }

            return newColId;
        }
    }

    private updateInvestorCommitmentAmounts(investorCommitments: InvestorCommitment[], totalCommitmentThruQuarterDate: Date) {
        this._investorCommitments.clear();

        this.investorRowManager.investorRows.forEach(investorRow => {
            const investorCommitment = investorCommitments.find(ic => ic.investorId === investorRow.investorId);

            if(!investorCommitment) return;

            const transDetails = investorCommitment.transactionsDetails;
            const amountSums = this.commitmentColumns.map((cd, _index) => {
                if(cd.quarterSum) return 0;

                const commitmentDate = DateTimeFormat.ensureAsDate(cd.commitmentDate);
                const detailsByDate = transDetails.filter(td => DateTimeFormat.compareDateOnly(td.date, commitmentDate) === 0);
                const sum = detailsByDate.reduce((a,b) => a + b.amount, 0);

                return sum;                
            });

            this._investorCommitments.set(investorRow.investorId, amountSums);

            const detailsByDate = transDetails.filter(td => DateTimeFormat.compareDateOnly(td.date, totalCommitmentThruQuarterDate) <= 0);
            const totalTransactionAmount = detailsByDate.reduce((a,b) => a + b.amount, 0);

            investorRow.totalTransactionAmount = totalTransactionAmount;
        });    
    }

    private getUniqueCommitmentDates(investorCommits: InvestorCommitment[], startDate: Date, endDate: Date) {
        const columnDates: Date[] = [];
        
        for(let i = 0; i < investorCommits.length; i++) {
            const transDetails = investorCommits[i].transactionsDetails
                .filter(td => DateTimeFormat.isDateBetween(td.date, startDate, endDate));

            for(let j = 0; j < transDetails.length; j++) {
                const currDt = DateTimeFormat.getDateOnly(transDetails[j].date);
                const colDate = columnDates.find(cd => DateTimeFormat.compareDateOnly(currDt, cd) === 0 );

                if(!colDate) {
                    columnDates.push(currDt);
                }
            }
        }

        columnDates.sort((a,b) => a.getTime()-b.getTime());

        return columnDates;
    }

    public renderColumns(gridRowIds?: string[], gridColIds?: string[]): void {
        const grid = this.grid;
        const rowManager = this.investorRowManager;
        const investorRows = rowManager.investorRows;

        const colsToRender = gridColIds?.length ? this.commitmentColumns.filter(c =>  c.quarterSum || !!gridColIds.find(colId => colId === c.gridColId)) : this.commitmentColumns;

        colsToRender.forEach(col => {
            const commitColIndex = this.commitmentColumns.findIndex(ctr => ctr.gridColId === col.gridColId);
            const colCaption = grid.getColCaption(col.gridColId);

            this.setColumnStyle(col.gridColId, col.label, ColWidth.currency);

            const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

            grid.startUpdate();

            rowsToRender.forEach(ir => {
                this.setInvestorTypeTotalRowStyling(ir.gridRowId, col.gridColId, ir.rowType);

                const currGridRowId = ir.gridRowId;

                switch(ir.rowType) {
                    case InvestorRowType.Investor:
                        if(col.quarterSum) {
                            const prevQuarterCols = this.commitmentColumns
                                .filter(c => !c.quarterSum && c.quarterNumber <= col.quarterNumber)
                                .map(c => grid.getColCaption(c.gridColId));
                            const rowCaption = grid.getRowCaption(currGridRowId);
                            const prevQuarterColsStr = prevQuarterCols.map(c => `${c}${rowCaption}`).join('+');

                            grid.setCellFormulaIfDifferent(currGridRowId, col.gridColId, prevQuarterColsStr, CellFormats.Number_Accounting);
                        } else {
                            const commitCols = this._investorCommitments.get(ir.investorId)!;
                            const value = !commitCols || !commitCols[commitColIndex] ? '' : commitCols[commitColIndex]; 

                            grid.setCellValueIfDifferent(currGridRowId, col.gridColId, !value ? '' : value, CellFormats.Number_Accounting); 
                        }
                        break;
                    case InvestorRowType.TypeTotal:
                        const typeStartEndPos = rowManager.getInvestorTypeRowCaptions(ir.investorType);
                        const formula = `SUM(${colCaption}${typeStartEndPos.startRow}:${colCaption}${typeStartEndPos.endRow})`;

                        grid.setCellFormulaIfDifferent(currGridRowId, col.gridColId, formula, CellFormats.Number_Accounting);
                        break;
                    case InvestorRowType.PartnerTotal:
                        const typeTotalRows = investorRows
                                .filter(r => r.rowType === InvestorRowType.TypeTotal)
                                .map(r => `${ grid.getColCaption(col.gridColId)}${grid.getRowCaption(r.gridRowId)}`)
                                .join(`,`);

                        grid.setCellFormulaIfDifferent(currGridRowId, col.gridColId, `SUM(${typeTotalRows})`, CellFormats.Number_Accounting);

                        break;
                }
            });

            grid.endUpdate();
        });
    }

    public containsColumn(colId: string): boolean {
        return !!this._commitmentColumns.find(c => c.gridColId === colId);
    }

    public onStartEdit(row: string, col: string): boolean {
        return false;
    }

    public onEndEdit(row: string, col: string, save: boolean, value: string): boolean {
        return false;
    }

    public onColsAdd(cols: string[], toCol: string, right: boolean, empty: boolean): colsAddResponse {
        return { validColumnPosition: false };
    }

    public onRowAdd(parentRow: string): boolean {
        return false;
    }

    public onCanColDelete(col: string): boolean {
        return false;
    }

    public onCanRowDelete(row: string): boolean {
        return false;
    }

    public onCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }
}