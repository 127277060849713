import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Typography } from '@mui/material';
import { MouseEvent, ReactElement, useEffect } from 'react';

import useRole from '../../../../core/routing/useRole';
import { ScopeRole } from '../../../../utils/types/user.type';
import { useDocumentsContext } from '../../hooks';
import { InfoTooltip } from '../infoTooltip/InfoTooltip';
import {
  DocumentsBreadcrumbs as StyledBreadcrumbs,
  DocumentsLastBreadcrumbsTypography,
  InfoIconBox,
} from './DocumentsBreadcrumbs.styles';

const pageTitle: string = 'Documents';

function DocumentsBreadcrumbs(): ReactElement {
  const {
    state,
    changeFolder,
    resetOnChange,
    resetSelectedFiles,
    openDocument,
    resetFiltersSelection,
  } = useDocumentsContext();

  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: isFundUserAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const handleOnLinkClicked = (
    event: MouseEvent<HTMLAnchorElement>,
    value: number,
    id?: string
  ) => {
    event.preventDefault();
    changeFolder(value, !!isClientAdmin || !!isSuperAdmin || !!isBasicAdmin);
    resetOnChange();
    resetSelectedFiles();
    openDocument(id || '');
    resetFiltersSelection(true, 1);
  };

  const getLinks = (): ReactElement[] => {
    if (!state) return [];
    let node = state.documents;
    const totalDepth = state.cursor.length;
    const lastIndex = totalDepth - 1;
    const applyLinkTillDepth = lastIndex;
    const items: ReactElement[] = [];

    for (let i = 0; i < applyLinkTillDepth; i++) {
      const value = state.cursor[i];
      const currentFolderDepth = i + 1;

      node = node?.folders?.find((folder) => folder.id === value);
      if (node) {
        const nodeName = node.name;
        const nodeId = node.id || '';

        items.push(
          <Link
            underline="hover"
            key={node.id}
            href="#"
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
              handleOnLinkClicked(e, currentFolderDepth, nodeId);
            }}
          >
            {node.name}
          </Link>
        );
      }
    }

    const currentFolderId = state.cursor[lastIndex];

    node = node?.folders?.find((folder) => folder.id === currentFolderId);
    const showTooltip =
      isClientAdmin || isSuperAdmin || isBasicAdmin || isFundUserAdmin;

    if (node) {
      if (showTooltip) {
        items.push(
          <DocumentsLastBreadcrumbsTypography key={node.id}>
            {node.name}
            <InfoIconBox>
              <InfoTooltip
                key={`tooltip_${currentFolderId}`}
                id="current_folder_toolTip"
                children={
                  <>
                    <Typography>
                      Total published files:{' '}
                      {state.currentFolder?.publishedFiles?.totalItems ?? ''}
                    </Typography>
                    <Typography>
                      Total unpublished files:{' '}
                      {state.currentFolder?.unPublishedFiles?.totalItems ?? ''}
                    </Typography>
                    <Typography>
                      Folder archive setting:{' '}
                      {`${
                        state.currentFolder?.archiveIn !== null
                          ? state.currentFolder?.archiveIn
                            ? `${state.currentFolder?.archiveIn} months`
                            : 'At end of quarter'
                          : 'Never'
                      }`}
                    </Typography>
                  </>
                }
              />
            </InfoIconBox>
          </DocumentsLastBreadcrumbsTypography>
        );
      } else {
        items.push(
          <DocumentsLastBreadcrumbsTypography key={node.id}>
            {node.name}
          </DocumentsLastBreadcrumbsTypography>
        );
      }
    }
    return items;
  };

  if (state && state.cursor && state.cursor.length > 0) {
    return (
      <StyledBreadcrumbs
        separator={<NavigateNextIcon fontSize="large" />}
        maxItems={3}
        aria-label="documents breadcrumb"
      >
        <Link
          key="documentRoot"
          underline="hover"
          href=""
          onClick={(e: MouseEvent<HTMLAnchorElement>) =>
            handleOnLinkClicked(e, 0)
          }
        >
          {pageTitle}
        </Link>
        {getLinks()}
      </StyledBreadcrumbs>
    );
  }
  return <Typography variant="pageTitle">{pageTitle}</Typography>;
}

export default DocumentsBreadcrumbs;
