import { Cached } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { DataWrapperBox } from "../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import DataGrid from "../../components/DataGrid/DataGrid";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../components/SearchBarWithDebounce/SearchBar";
import { FilterBox,HeaderRow } from "./DownloadHistory.styles";
import { useDownloadHistory } from "./useDownloadHistory.hooks";

const DownloadHistory: React.FC = (): ReactElement => {
  const {
    headerList,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    loading,
    fetchHistory,
  } = useDownloadHistory();

  return (
    <DataWrapperBox
      id="download_history_list_main">
      <Progress
        id="download_history_list_loading"
        showProgress={loading}
      />
      <HeaderRow>
        <Typography variant="pageTitle">Download History</Typography>
        <FilterBox>
          <SearchBar id="download-history-search" onChange={onSearch} value={search} />
          <IconButton
            aria-label="Refresh List"
            color="inherit"
            title="Refresh List"
            id="refresh-list"
            onClick={() => fetchHistory()}
          >
            <Cached/>
          </IconButton>
        </FilterBox>
      </HeaderRow>
      <DataGrid
        id="download_history_data_grid"
        dataList={list || []}
        noDataMessage={loading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        autoHeight={false}
        activeHeaderFields={activeHeaderFields}
        handleUpdateHeader={handleUpdateHeader}
      />
    </DataWrapperBox>
  );
};

export default React.memo(DownloadHistory);
