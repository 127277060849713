import { useMemo } from "react";

import { Documents, Files } from "../../../utils/types/documents.type";
import { DocumentsState } from "../types";

const defaultFilesValue: Files = {
  pageSize: 0,
  totalItems: 0,
  files: [],
};

function useDocumentDataList(state: DocumentsState, props: any) {
  const isLoading: boolean =
    state.isInitializing ||
    state.isLoading ||
    state.isCreating ||
    state.isDeleting;
  const clientId = state.documents?.clientId || "";

  return useMemo(() => {
    if (isLoading || state.documents === null) {
      return { isLoading, dataList: [] };
    }
    let pointer = state.documents;

    for (const cursor of state.cursor) {
      pointer =
        state.currentFolder ||
        pointer?.["folders"]?.find((folder) => folder.id === cursor);
      break;
    }

    const folders: Documents[] = pointer?.folders || [];
    const showFolders: boolean = state.selectedFilters.showFolders;
    const selectedPermissionsFilter = state.selectedPermissionsFilter;
    const publishedFiles: Files = pointer?.publishedFiles || defaultFilesValue;
    const unPublishedFiles: Files =
      pointer?.unPublishedFiles || defaultFilesValue;
    const selectedNameFilter = state.selectedNameFilter;

    const dataList: Array<any> =
      state.cursor.length > 0
        ? (props.isEditable && selectedNameFilter.length === 0) ||
          showFolders === false
          ? []
          : folders
              .filter(
                (folder) =>
                  (!props.isEditable && selectedNameFilter.length === 0) ||
                  selectedNameFilter.includes(folder.id)
              )
              .map((folder: Documents) => ({
                ...folder,
                type: "folder",
              }))
        : folders
            .filter((folder) => {
              return (
                state.permissions === null ||
                state.permissions?.length === 0 ||
                (folder.permissions?.length === 0 &&
                  selectedPermissionsFilter.includes("(none)")) ||
                folder.permissions?.some((item: any) =>
                  selectedPermissionsFilter.includes(item.key)
                )
              );
            })
            .map((folder: Documents) => ({
              ...folder,
              type: "folder",
            }));

    for (let file of unPublishedFiles.files || []) {
      if (props.isEditable && selectedNameFilter.length === 0) break;
      if (
        selectedNameFilter.length > 0 &&
        !selectedNameFilter.includes(file.id)
      ) {
        continue;
      }
      file = {
        ...file,
        clientId,
        folderId: state.currentFolder?.id || "",
        isNew: props.newFiles.includes(file.id),
        isSelected:
          props.isEditable && !state.isPageLocked && !state.isReadOnly,
      };
      dataList.push({
        ...file,
        type: "file",
      });
    }

    for (let file of publishedFiles.files || []) {
      if (props.isEditable && selectedNameFilter.length === 0) break;
      if (
        selectedNameFilter.length > 0 &&
        !selectedNameFilter.includes(file.id)
      ) {
        continue;
      }
      file = {
        ...file,
        clientId,
        folderId: state.currentFolder?.id || "",
        isNew: props.newFiles.includes(file.id),
        isSelected:
          props.isEditable && !state.isPageLocked && !state.isReadOnly,
      };
      dataList.push({
        ...file,
        type: "file",
      });
    }
    return { isLoading, dataList };
  }, [
    state.permissions,
    state.cursor,
    isLoading,
    state.documents,
    state.selectedFilters.showFolders,
    state.currentFolder,
    state.selectedPermissionsFilter,
    state.selectedNameFilter,
    props.selectedFiles,
    props.newFiles,
    state.isPageLocked,
    state.isReadOnly,
  ]);
}

export default useDocumentDataList;
