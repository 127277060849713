import { useEffect, useState } from "react";

import { DocFile } from "../../utils/types/documents.type";

type Props = {
  file?: FileList | Array<File | DocFile>;
  isMulti?: boolean;
};
export const useFileContainer = ({ file }: Props) => {
  const [fileCount, setFileCount] = useState<number>(0);

  const countFiles = (files: FileList | Array<File|DocFile>) => {
    const { length } = files;

    setFileCount(length);
  };

  useEffect(() => {
    try {
      if(file && file.length) countFiles(file);
    } catch(e) {
      countFiles([]);
      return;
    }
  }, [file]);

  return {
    fileCount
  };
};
