import { Fade } from "@mui/material";
import { ButtonPropsSizeOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ReactElement, useContext } from "react";

import { ClientThemeContext } from "../../../core/context/clientThemeContext";
import {
  BUTTON_COLOR_OPTIONS,
  BUTTON_STYLE_VARIANTS,
} from "../../../utils/constants/styles.constants";
import { StyledButton } from "./Button.styles";

type Props = {
  id: string;
  icon?: any;
  variant?: BUTTON_STYLE_VARIANTS;
  text: string;
  color: BUTTON_COLOR_OPTIONS;
  size?:  OverridableStringUnion<'small' | 'medium' | 'large', ButtonPropsSizeOverrides>;
  hidden?: boolean;
  onClick?: any;
  fullWidth?: boolean;
  addSpaceBetweenButtons?: boolean;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
};

const Button = (props: Props): ReactElement => {
  const {
    id,
    icon = null,
    text,
    color,
    size,
    hidden = false,
    variant = "contained",
    onClick,
    fullWidth = false,
    addSpaceBetweenButtons = false,
    disabled = false,
    type
  } = props;
  const {
    clientTheme
  } = useContext(ClientThemeContext);

  return (
    <Fade in={!hidden}>
      <StyledButton
        buttoncolor={color}
        backgroundcolor={clientTheme?.buttonBackgroundColor}
        textcolor={clientTheme?.buttonTextColor}
        id={id}
        variant={variant}
        disableElevation
        startIcon={icon}
        onClick={onClick}
        size={size}
        fullWidth={fullWidth}
        type={type}
        spacebetween={addSpaceBetweenButtons.toString()}
        disabled={disabled}
      >
        {text}
      </StyledButton>
    </Fade>
  );
};

export default Button;
