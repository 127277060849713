import { Collapse, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ChangeEventHandler } from 'react';
import { Controller } from 'react-hook-form';

import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import NextStepDialog from '../../../../components/Modal/NextStepDialog/NextStepDialog';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import TextField from '../../../../components/TextField/TextField';
import { InfoTooltip } from '../infoTooltip/InfoTooltip';
import { useArchivePanel } from './ArchivePanel.hooks';
import {
  ArchiveGrid,
  ManualSelectionBox,
  StyledArchiveOutlinedIcon,
} from './ArchivePanel.styles';

type Props = {
  showFolderDialog?: boolean | undefined;
  editing: boolean;
  isRootView?: boolean;
  handleFolderNameChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  currentStep: string;
  resetOnClose: any;
  handleNextAction: any;
  folderEdits: any;
  setFolderEdits: any;
};

export function ArchivePanel({
  showFolderDialog = false,
  editing,
  isRootView,
  currentStep,
  resetOnClose,
  handleNextAction,
  setFolderEdits,
  folderEdits,
}: Props): React.ReactElement {
  const {
    ARCHIVE_IN_OPTIONS,
    archiveInSelection,
    setArchiveInSelection,
    onSubmit,
    handleSubmit,
    control,
    showArchiveSubfoldersDialog,
    handleArchiveSubfolders,
  } = useArchivePanel(
    folderEdits,
    setFolderEdits,
    handleNextAction,
    isRootView,
    editing
  );

  return (
    <>
      <NextStepDialog
        open={
          showFolderDialog &&
          currentStep === 'folder-name' &&
          !showArchiveSubfoldersDialog
        }
        onClose={resetOnClose}
        fullWidth={true}
        id="create_new_folder"
        actionDisplayType="inline"
        actions={[
          {
            label: 'Cancel',
            onClick: resetOnClose,
            id: 'cancel_button',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: isRootView ? 'Next' : 'Save',
            onClick: handleSubmit(onSubmit),
            id: 'next_button',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title={editing ? 'Edit Folder' : 'New Folder'}
      >
        <Grid
          container
          alignItems={'center'}
          justifyContent={'center'}
          spacing={2.5}
          xs={12}
        >
          <Grid item xs={12} alignItems="center">
            <Controller
              name="folderName"
              control={control}
              defaultValue=""
              rules={{ required: 'Folder name is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="new_folder_name"
                  label="Folder Name"
                  fullWidth={true}
                  autoFocus={true}
                  error={!!error}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <ArchiveGrid item xs={12} sm={6}>
                <Box paddingLeft={'14px'}>
                  <Typography alignItems={'center'} display="flex">
                    <StyledArchiveOutlinedIcon />
                    Archive documents in this folder:
                    <InfoTooltip
                      id="archive_by_tooltip"
                      text="Documents in this folder will be automatically archived after the selected period of time, determined individually by each document's tagged quarter. Selecting 'Never' will prevent this folder from automatically archiving files."
                    />
                  </Typography>
                </Box>
              </ArchiveGrid>
              <Grid item xs={12} sm={6}>
                <SingleSelect
                  id={'select_archive_by_period'}
                  optionList={ARCHIVE_IN_OPTIONS}
                  handleOptionSelection={(evt: any) => {
                    setArchiveInSelection(evt.target.value);
                  }}
                  fullWidth={true}
                  label={''}
                  value={archiveInSelection}
                  defaultValue={'never'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'right'}>
          <Grid item xs={12} sm={6}>
            <Collapse in={archiveInSelection === 'other'}>
              <ManualSelectionBox>
                <Controller
                  name="archiveInMonths"
                  control={control}
                  rules={{
                    validate: {
                      onlyDigits: (value) =>
                        archiveInSelection !== 'other' ||
                        /^\d+$/.test(value!) ||
                        'Enter digits 0-9 only',
                      maxLength: (value) =>
                        archiveInSelection !== 'other' ||
                        value!.toString().length <= 3 ||
                        'Enter up to 3 digits only',
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="archive_by_custom_number_input"
                      label="(0-999)"
                      autoFocus={true}
                      error={!!error}
                      helperText={error?.message}
                      infoHelperText={
                        field.value === '0' ? 'Archive at End of Quarter' : ''
                      }
                      style={{ width: '170px' }}
                    />
                  )}
                />
                <Typography>Months</Typography>
              </ManualSelectionBox>
            </Collapse>
          </Grid>
        </Grid>
      </NextStepDialog>
      {showArchiveSubfoldersDialog && (
        <ConfirmationDialog
          open={showArchiveSubfoldersDialog}
          onClose={() => {}}
          id="archiveSubfoldersConfirmation"
          actionDisplayType="stacked"
          actions={[
            {
              label: 'Yes, apply to all subfolders',
              onClick: () => handleArchiveSubfolders(true),
              id: 'apply_archive_to_subfolders_button',
              variant: 'contained',
              color: 'primary',
            },
            {
              label: 'No, apply only to this folder',
              onClick: () => handleArchiveSubfolders(false),
              id: 'do_not_apply_to_subfolders_button',
              variant: 'contained',
              color: 'secondary',
            },
          ]}
          title="Archive Settings Application"
          content="Would you like to apply the selected Archive permissions to all current subfolders, sub-subfolders, and so forth, within this folder as a one-time action?"
        />
      )}
    </>
  );
}
