import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { useNavBarContext } from "../../core/context/navbarContext";
import LockConfirmationDialog from "../LockConfirmationDialog/LockConfirmationDailog";
import {
  SideBarListItemButton,
  SideBarListItemText,
  StyledListItemIcon,
} from "./SideBar.styles";

type Props = {
  id: string;
  label: string;
  redirectionLink: string;
  icon: React.ReactNode;
  isDisabled: boolean;
  options?: {
    id: string;
    label: string;
    redirectionLink: string;
    icon: React.ReactNode;
    isDisabled: boolean;
  }[];
  color?: string;
  rightIcon?: React.ReactNode;
  isLocked?: boolean;
};

const MenuOption = ({
  label,
  redirectionLink,
  icon,
  options,
  id,
  isDisabled,
  color,
  rightIcon,
  isLocked
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [showLockedModal, setShowLockedModal] = React.useState(false);
  const history = useHistory();
  const {
    isNavBarOpen
  } = useNavBarContext();

  useEffect((): void => {
    if (!isNavBarOpen) {
      setOpen(false);
    }
  }, [isNavBarOpen]);

  const handleClick = () => {
    if(isLocked) {
      setUseDefaultDialogText(label !== 'General Ledger');
      setShowLockedModal(true);
    } else if (options && options.length > 0) {
      setOpen(!open);
    } else {
      history.push(redirectionLink);
    }
  };
  
  const [useDefaultDialogText, setUseDefaultDialogText] = useState(false);
  const arkGlDialogText = "We're thrilled to announce the arkGL feature is currently undergoing testing by our early adopters. If you are interested in becoming an early adopter, please reach out to sales@arkpes.com";

  return (
    <>
      <ListItemButton onClick={handleClick} 
      id={id} disabled={isDisabled}>
        <ListItemIcon>{icon}</ListItemIcon>
        <SideBarListItemText
          isnavbaropen={isNavBarOpen?.toString()}
          textcolor={color}
          primary={label}
        />
        {rightIcon && <StyledListItemIcon>{rightIcon}</StyledListItemIcon>}
        {options && options.length > 0 && isNavBarOpen ? (
          open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options &&
            options?.map((option) => (
              <SideBarListItemButton
                id={option.id}
                key={option.id}
                isnavbaropen={isNavBarOpen?.toString()}
                disabled={option.isDisabled}
                component={Link}
                to={option.redirectionLink}
              >
                <ListItemText primary={option.label} />
              </SideBarListItemButton>
            ))}
        </List>
      </Collapse>
      <LockConfirmationDialog
        lockedPrompt={showLockedModal}
        setLockedPrompt={setShowLockedModal}
        customText={useDefaultDialogText ? undefined : arkGlDialogText}
        customTitle=" "
      />
    </>
  );
};

export default React.memo(MenuOption);
