import IconNewCircle from '../../../assets/images/icons/icon_new_circle.svg';
import { ButtonOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { JEPreviewFormatBanksJournalEntry } from '../../../utils/types/bank.type';
import { DataGridColDef } from '../../../utils/types/listItems';

export const BANK_FEEDS_VIEW_KEY = 'BANK_FEEDS_VIEW_KEY';

export enum BankFeedViewOptions {
  DASHBOARD = 'Dashboard',
  HYBRID = 'Hybrid',
  DATA_VIEW = 'Data View',
}

export enum BankStatusOptions {
  Pending = 'PENDING',
  Categorized = 'CATEGORIZED',
  Excluded = 'EXCLUDED',
}

export enum BankTransactionTypeOptions {
  Inflow = 'INFLOW',
  Outflow = 'OUTFLOW',
}

export const BANK_TRANS_UI_STATUS_VALUE: { [key: string]: string } = {
  PENDING: 'For Review',
  CATEGORIZED: 'Submitted to GL',
  EXCLUDED: 'Excluded',
};

export const BANK_STATUS_FILTER_LIST = [
  { id: 'PENDING', name: 'For Review' },
  { id: 'CATEGORIZED', name: 'Submitted to GL' },
  { id: 'EXCLUDED', name: 'Excluded' },
];

export enum BanksBulkAction {
  BulkEditSelected = 'edit_selected',
  SendToGL = 'send_to_gl',
  CloseEditSelected = 'close_edit_selected',
  ExportSelected = 'export_selected',
  ExcludeFromGlSelected = 'exclude_from_gl_selected',
  ReviewBankTransactionSelected = 'review_bank_transaction',
  DeleteSelected = 'delete_selected',
}

export const PlaidBulkEditFieldOptions: any = [
  {
    id: 0,
    name: 'GL Account',
  },
  {
    id: 1,
    name: 'Memo Entity',
  },
];

export enum BulkEditingType {
  Plaid = 'plaid',
  Uploaded = 'uploaded',
  NoSelection = 'none',
}

export const BulkEditSelectionsDefault = {
  plaidNum: 0,
  uploadedNum: 0,
  totalNum: 0,
};

export enum BankFeedsFilter {
  BankAccount = 'bankAccount',
  Fund = 'fund',
  Status = 'status',
  Date = 'date',
  CoA = 'account',
  MemoEntity = 'memoEntity',
  RuleApplied = 'ruleApplied',
}

export const BANK_FEEDS_SORT_NAME: { [key: string]: string } = {
  bankAccountName: 'BANK_ACCOUNT',
  fundName: 'FUND',
  status: 'STATUS',
  date: 'DATE',
  inflow: 'INFLOW',
  outflow: 'OUTFLOW',
  account: 'GL_ACCOUNT',
  entities: 'MEMO_ENTITY',
  ruleApplied: 'RULE',
  score: 'SCORE',
  memoEntityName: 'MEMO_ENTITY',
};

export const DEFAULT_PAGE_SIZE: number = 50;

export const BANKS_JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE: JEPreviewFormatBanksJournalEntry =
  {
    accountId: '',
    amount: 0,
    entityIds: [],
    memo: '',
    type: '',
    isBankTransactionEntry: false,
    isEntityRequired: false,
  };

export enum AddNewButtonOptions {
  LinkAccount = 'link_account',
  UploadBankFeedTemplate = 'upload_bank_feed_template',
  DownloadBankFeedTemplate = 'download_bank_feed_template',
}

export const NewBankFeedOptions: ButtonOptions[] = [
  {
    label: 'Link New Bank Account',
    id: AddNewButtonOptions.LinkAccount,
    icon: IconNewCircle,
  },
];

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: 'bankAccountName',
    headerName: 'Bank Account',
    width: 400,
    hide: false,
    index: 1,
    type: 'string',
    sortable: true,
  },
  {
    field: 'fundName',
    headerName: 'Fund',
    hide: false,
    index: 2,
    type: 'string',
    sortable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    hide: false,
    index: 3,
    type: 'string',
    sortable: true,
  },
  {
    field: 'date',
    headerName: 'Date',
    hide: false,
    index: 4,
    type: 'string',
    sortable: true,
  },
  {
    field: 'inflow',
    headerName: 'Inflow',
    hide: false,
    index: 5,
    type: 'string',
    sortable: false,
  },
  {
    field: 'outflow',
    headerName: 'Outflow',
    hide: false,
    index: 6,
    type: 'string',
    sortable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    hide: false,
    index: 7,
    type: 'string',
    sortable: false,
  },
  {
    field: 'memo',
    headerName: 'Bank Memo',
    hide: false,
    index: 8,
    type: 'string',
    sortable: false,
  },
  {
    field: 'account',
    headerName: 'Account',
    hide: false,
    index: 9,
    type: 'string',
    sortable: true,
  },
  {
    field: 'memoEntityName',
    headerName: 'Memo Tags',
    hide: false,
    index: 10,
    type: 'string',
    sortable: true,
  },
  {
    field: 'capCallReceived',
    headerName: 'Capital Call Received',
    hide: false,
    index: 11,
    type: 'string',
    sortable: false,
  },
  {
    field: 'action',
    headerName: '',
    hide: false,
    index: 12,
    type: 'action',
    sortable: false,
  },
];
