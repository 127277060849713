import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import { getDistribution } from "../../../../services/distributions.service";
import { useDistributionDetailsEffect } from "../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { Transaction } from "../../../../utils/types/distributions.type";
type RouteProp = {
  id: string;
  section: string;
};

type Props = {
  handleAddTransaction: (transaction: Transaction) => void;
  setIsAddingNewTransactionRow: (value: boolean) => void;
  isAddingNewTransactionRow: boolean;
  cancelCreateUpdateTransaction: () => void;
  addAndUpdateTransaction: (transaction: Transaction) => void;
  setIsGoBack: (value: boolean) => void;
};

export const useAddTransactionDetails = ({
  handleAddTransaction,
  setIsAddingNewTransactionRow,
  isAddingNewTransactionRow,
  cancelCreateUpdateTransaction,
  addAndUpdateTransaction,
  setIsGoBack,
}: Props) => {
  const { id } = useParams<RouteProp>();
  const [distributionName, setDistributionName] = useState<string>("");
  const [distributionId, setDistributionId] = useState<string>("");
  const { distributions } = useDistributionDetailsEffect(
    id !== "new" ? id : undefined
  );
  const { informationAlert } = useContext(AppContext);

  const getFundName = async (id: string) => {
    const response = await getDistribution(id);

    setValue("investor", response.fundName);
    setDistributionName(response.fundName);
    setDistributionId(response?.fundId!);
  };

  getFundName(id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    clearErrors,
    getValues,
    setError,
  } = useForm<Transaction>({
    defaultValues: {
      components: [],
      dateString: null,
      metricFractionDigit: 0,
      metricSign: null,
      useMetric: false,
      quarter: "",
    },
  });
  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    if (isAddingNewTransactionRow !== isDirty) {
      setIsAddingNewTransactionRow(isDirty);
    }
  }, [isDirty]);
  const defaultTransactionValues = {
    fund: distributions?.fundName || "",
    currentType: {},
    currentInvestor: {},
    date: "",
    amount: " ",
    name: "",
    components: [],
    dateString: null,
    metricFractionDigit: 0,
    metricSign: null,
    useMetric: false,
    quarter: "",
  };

  const addTransaction = (data: Transaction | any) => {
    const { currentInvestor, currentType, ...remainingData } = data;
    const newData = {
      ...remainingData,
      investor: currentInvestor.name,
      type: currentType.name,
      date: format(remainingData.date, "MM/dd/yyyy"),
    };

    handleAddTransaction(newData);
    reset(defaultTransactionValues);
    clearErrors();
  };
  const addTransactionPopUp = async () => {
    const { currentInvestor, currentType, ...remainingData } = getValues();
    const isCurrentInvestorPresent = currentInvestor
      ? Object.keys(currentInvestor).length > 0
        ? true
        : false
      : false;
    const isCurrentTypePresent = currentType
      ? Object.keys(currentType).length > 0
        ? true
        : false
      : false;

    let flag = true;
    const name: string = remainingData.name;

    if (
      remainingData.amount === undefined ||
      remainingData.date === undefined ||
      remainingData.name === undefined ||
      isCurrentInvestorPresent === false ||
      isCurrentTypePresent === false ||
      name.trim() === "" ||
      remainingData.amount.toString().trim() === ""
    ) {
      informationAlert(
        "Incomplete data, please fill out all required fields.",
        "error"
      );
      (remainingData.amount === undefined ||
        remainingData.amount.toString().trim() === "") &&
        setError(
          "amount",
          { type: "custom", message: "Amount is required " },
          { shouldFocus: true }
        );

      (remainingData.name === undefined || name.trim() === "") &&
        setError(
          "name",
          { type: "custom", message: "Transaction Name is required" },
          { shouldFocus: true }
        );

      remainingData.date === undefined &&
        setError(
          "date",
          { type: "custom", message: "Date is required" },
          { shouldFocus: true }
        );
      isCurrentInvestorPresent === false &&
        setError(
          "currentInvestor",
          { type: "custom", message: "Investor is required" },
          { shouldFocus: true }
        );
      isCurrentTypePresent === false &&
        setError(
          "currentType",
          { type: "custom", message: "Transaction Type is required" },
          { shouldFocus: true }
        );

      flag = false;
      setIsGoBack(false);
    }

    if (flag) {
      const newData = {
        ...remainingData,
        investor: currentInvestor?.name || "",
        type: currentType?.name || "",
        date: format(new Date(remainingData.date), "MM/dd/yyyy"),
      };

      await addAndUpdateTransaction(newData);
      reset(defaultTransactionValues);
      clearErrors();
    }
    cancelCreateUpdateTransaction();
  };

  useEffect(() => {
    if (distributions) {
      setValue("fund", distributions.fundName || "");
    }
  }, [distributions]);

  return {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    addTransaction,
    distributions,
    distributionName,
    distributionId,
    addTransactionPopUp,
    clearErrors,
  };
};
