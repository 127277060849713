import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, styled } from "@mui/material";
import { GridSortDirection } from "@mui/x-data-grid-pro";

import { DataGrid } from "../../../../components";
import { DataWrapperBox } from "../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import { GRID_HEADER_BACKGROUND_COLOR, GRID_HEADER_BORDER_COLOR,ROW_HOVER_BACKGROUND_COLOR } from "../../../../styles/colors";

export const StyledDataWrapperBox = styled(DataWrapperBox)({
  '.MuiTablePagination-displayedRows': {
    display: 'none',
  },
});

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledDataGridPro = styled(DataGrid)(()=>({

  '& .MuiBadge-anchorOriginTopRightRectangular': {
    visibility: "hidden"
  },

  '& .MuiDataGrid-columnHeader--numeric .MuiDataGrid-columnHeaderTitleContainer' : {
    transform: 'scaleX(-1)'
  },

  '& .MuiDataGrid-columnHeader--numeric .MuiDataGrid-columnHeaderTitleContainerContent' : {
    transform: 'scaleX(-1)'
  },

}));

interface CustomSortIconProps {
  direction: GridSortDirection
}

export const CustomSortIcon: React.FC<CustomSortIconProps> = 
({ direction }) => {
  return (
    <>
      {direction === 'asc' && 
    <ArrowUpwardIcon fontSize="small" />}
      {direction === 'desc' &&
    <ArrowDownwardIcon fontSize="small" />}
    </>
    );
};

export const CustomSortAscIcon: React.FC<CustomSortIconProps> = () => {
  return (
    <ArrowUpwardIcon fontSize="small" />
  );
};

export const CustomSortDescIcon: React.FC<CustomSortIconProps> = () => {
  return (
    <ArrowDownwardIcon fontSize="small"/>
  );
};