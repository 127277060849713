import { ClickAwayListener } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import Filter from "../../../../components/DataGrid/Filter/Filter";
import { PopoverFilter } from "../../../../components/DataGrid/Filter/Filter.style";
import { Option } from "../../../../utils/types/filter.type";

interface Props {
  selected: Array<string>;
  anchorEl: HTMLDivElement | null;
  options: Array<any>;
  handleUpdate?: (list:Array<string> | string | Option, selectedOptions?: Option[]) => void;
  handleOnClose: () => void;
  shouldOptionReturnValue?: boolean;
  isMulti?: boolean;
  isAllSelected?: boolean;
  showFilterAll?: boolean;
  selectAllText?: string;
  applyOnSelect?: boolean;
  hideActionButtons?: boolean;
}

const EditSelection: React.FC<Props> = ({
  anchorEl,
  selected,
  options,
  handleOnClose = () => {},
  handleUpdate = () => {},
  shouldOptionReturnValue = true,
  isMulti = false,
  isAllSelected = false,
  showFilterAll = false,
  applyOnSelect = false,
  selectAllText = "All",
  hideActionButtons = true,
}:Props): React.ReactElement => {

  return (
    <PopoverFilter
      id={`edit_popover`}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <ClickAwayListener onClickAway={handleOnClose}>
        <Box>
          <Filter
            handleIDSelection={handleUpdate}
            options={options}
            selectedOptions={selected.length > 1 && isAllSelected ? []: selected}
            allSelected={isAllSelected && selected.length > 1}
            idField="id"
            labelField="name"
            singleSelect={!isMulti}
            applyText="Apply Changes"
            clearText="Clear"
            selectAllText={selectAllText}
            returnAllSelected={true}
            showFilterAll={showFilterAll}
            hideButtons={hideActionButtons}
            applyOnSelect={applyOnSelect}
            scrollContainerStyle={{ maxWidth: "initial" }}
            shouldOptionReturnValue={shouldOptionReturnValue}
            emptySelectionOnClear={true}
          />
        </Box>
      </ClickAwayListener>
    </PopoverFilter>
  );
};

export default EditSelection;