import { Container, createSvgIcon, Typography } from '@mui/material';
import React, {
  createContext,
  MouseEvent,
  ReactElement,
  useContext,
  useState,
} from 'react';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import {
  CONTACT_DETAILS_SVG_ICON,
  CONTACT_PERMISSIONS_SVG_ICON,
} from '../../../utils/constants/text.constants';
import { ImageItem } from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import ContactInfo from '../contactInfo/ContactInfo';
import ContactPermissions from '../contactPermissions/ContactPermissions';
import { useContactListEffect } from './ContactList.hooks';
import { ButtonBox, MainContainer } from './ContactList.styles';

type Props = {};

const initialList: any[] = [];

export const ContactContext = createContext({
  permissionsByInvestorList: initialList,
  setPermissionsByInvestorList: (pemissions: any) => {},
  isContactChanged: false,
  setIsContactChanged: (status: boolean) => {},
});

const ContactList: React.FC<Props> = (props: Props): ReactElement => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.currentUser?.clientId || '';
  const [selectedContactMode, setSelectedContactMode] = useState('contactInfo');
  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);
  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const {
    fundFilters,
    roleFilters,
    investorFilters,
    contactColumnOrder,
    contactPermissionColumnOrder,
  } = useContactListEffect(clientId, informationAlert, isBasicUser);

  const ContactPermissionsIcon = createSvgIcon(
    <path d={CONTACT_PERMISSIONS_SVG_ICON} />,
    'Contact Details'
  );

  const ContactDetailsIcon = createSvgIcon(
    <path d={CONTACT_DETAILS_SVG_ICON} />,
    'Home'
  );

  const defaultContactModes: ImageItem[] = [
    {
      id: 'contactInfo',
      text: 'Details',
      icon: <ContactDetailsIcon />,
      optionsSelected: 0,
    },
    {
      id: 'contactPermissions',
      text: 'Permissions',
      icon: <ContactPermissionsIcon />,
      optionsSelected: 0,
    },
  ];

  const [contactModeOptions, setContactModeOptions] =
    useState<ImageItem[]>(defaultContactModes);

  const onContactModeChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    if (newValue) setSelectedContactMode(newValue);
  };

  const [permissionsByInvestorList, setPermissionsByInvestorList] = useState(
    []
  );
  const [isContactChanged, setIsContactChanged] = useState<boolean>(false);

  const contactValue = {
    permissionsByInvestorList,
    setPermissionsByInvestorList,
    isContactChanged,
    setIsContactChanged,
  };

  return (
    <ContactContext.Provider value={contactValue}>
      <MainContainer id="contact_main" role="main" maxWidth={false}>
        <HorizontalBox fullWidth noPadding>
          <HorizontalBox noPadding>
            <Typography variant="pageTitle">Contacts</Typography>
          </HorizontalBox>

          {!isBasicUser && (
            <ButtonBox>
              <ToggleButton
                id="toggle_contact_mode"
                title="Mode"
                options={contactModeOptions}
                value={selectedContactMode}
                onChange={onContactModeChange}
              />
            </ButtonBox>
          )}
        </HorizontalBox>

        {investorFilters && (
          <>
            <ContactInfo
              hidden={selectedContactMode === 'contactPermissions'}
              fundFilters={fundFilters}
              roleFilters={roleFilters}
              investorFilters={investorFilters}
              contactColumnOrder={contactColumnOrder}
            />
            {!isBasicUser && selectedContactMode === 'contactPermissions' && (
              <ContactPermissions
                readonly={isFundAdmin}
                investorFilterList={investorFilters}
                contactPermissionColumnOrder={contactPermissionColumnOrder}
              />
            )}
          </>
        )}
      </MainContainer>
    </ContactContext.Provider>
  );
};

export default ContactList;
