import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Container, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import LockConfirmationDialog from "../LockConfirmationDialog/LockConfirmationDailog";
import { BoxWrapper, StyledButton } from "./PageNavigation.styles";
import { usePageNavigation } from "./usePageNavigation.hooks";

type MenuOption = {
  id: string;
  name: string;
  path: string;
  hidden?: boolean;
};

type PageMenu = {
  id: string;
  name: string;
  path?: string;
  menuOptions?: MenuOption[];
  hidden?: boolean;
  locked?: boolean;
};

type Props = {
  fundId: string;
  menu: PageMenu[];
};

export const PageNavigation: React.FC<Props> = (props: Props) => {
  const { fundId, menu } = props;
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | { [x: number]: HTMLElement }>(
    null
  );
  const { lockedPrompt, setLockedPrompt, showLockedDialog } =
    usePageNavigation();
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    id: string
  ) => {
    setAnchorEl({
      [index]: event.currentTarget,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <BoxWrapper>
        {menu.map((option, index) => (
          <Box
            key={option.id}
            sx={{
              display: option.hidden ? "none" : "inherit",
            }}
          >
            <StyledButton
              id={option.id}
              endIcon={option.locked ? <LockIcon /> : ""}
              {...(option.path &&
                !option?.locked && {
                  component: RouterLink,
                  to: `${option.path}/${fundId}`,
                })}
              {...(location.pathname === `${option.path}/${fundId}` && {
                className: "active",
              })}
              {...(option.menuOptions &&
                option.menuOptions.find(
                  (o) => location.pathname === `${o.path}/${fundId}`
                ) && {
                  className: "active",
                })}
              onClick={(e) =>
                  handleClick(e, index, option.id)
              }
              sx={{
                my: 2,
              }}
              {...(option.menuOptions && {
                "aria-controls":
                  anchorEl && Boolean(anchorEl[index])
                    ? `menu_${option.id}`
                    : undefined,
                "aria-haspopup": "true",
                "aria-expanded":
                  anchorEl && Boolean(anchorEl[index]) ? "true" : undefined,
                endIcon: option.locked ? (
                  <LockIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                ),
              })}
            >
              {option.name}
            </StyledButton>
            {option.menuOptions && anchorEl && anchorEl[index] && (
              <Menu
                id={`menu_${option.id}`}
                anchorEl={anchorEl && anchorEl[index]}
                open={anchorEl && Boolean(anchorEl[index])}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": option.id,
                }}
              >
                {option.menuOptions
                  .filter((item) => !item.hidden)
                  .map((menuOption) => (
                    <MenuItem
                      key={menuOption.id}
                      onClick={handleClose}
                      component={RouterLink}
                      to={`${menuOption.path}/${fundId}`}
                    >
                      {menuOption.name}
                    </MenuItem>
                  ))}
              </Menu>
            )}
          </Box>
        ))}
      </BoxWrapper>

      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
      />
    </Container>
  );
};
