import { CurrentUser, ProxyParams, User } from "../utils/types/user.type";
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

const authApiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.AuthApi); };

const preAuthApiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.PreAuth); };

export const getCurrentUser = async () => await apiClient().get<CurrentUser>('users/current');

export const updateUserStatus = async (id: string, status: string) => await apiClient().patch(`users/${id}/status`, {
  status
});

export const unlockUser = async (userEmails: string[]) => await authApiClient().post('users/unlock', userEmails);

export const resendVerificationEmail = async (id: string) => await apiClient().post(`v2/admin/users/${id}/resend-email`, { 'id':id });

export const resetPasswordRequest = async (userData: any) => await preAuthApiClient().post('users/resetPasswordRequest', userData);

export const impersonateUser = async (params: ProxyParams) => await authApiClient().post('token/proxy', params);

export const changeUserPassword = async (userData: any) => await preAuthApiClient().post('users/changePassword', userData);