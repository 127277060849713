import { Box, Button, Checkbox, FormGroup } from '@mui/material';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { FilterSelectionType } from '../../../../utils/types/filter.type';
import { FilterItem } from '../../../../utils/types/listItems';
import SearchBar from '../../../SearchBarWithDebounce/SearchBar';
import {
  ChipBox,
  Dropdown,
  FilterBox,
  OptionList,
  TypeLabel,
} from '../../../Selectors/JEEntityFilter/JEEntityFilter.styles';
import {
  ButtonText,
  FilterAll,
  FilterCheckBox,
  FilterControlLabel,
  FilterOption,
  FormControlCheckAll,
} from '../Filter.style';

type Option = { id: string; name: string };

type Props = {
  idField: string;
  labelField: string;
  options: any;
  filterName: any;
  selectedOptions?: string[] | any[];
  clearable?: boolean;
  handleFilter?: (filter: any, selectedOptions: any[]) => void;
  applyText?: string;
  clearText?: string;
  selectAllText?: string;
  allSelected?: boolean;
  enableApplyAction?: boolean;
  applyOnSelected?: boolean;
  emptySelectionOnClear?: boolean;
  returnAllSelected?: boolean;
  handleIDSelection?: (
    selected: Array<string> | string | Option,
    selectedOptions?: Option[],
    selectAll?: boolean
  ) => void;
  singleSelect?: boolean;
  handleAllSelection?: (
    selectedOptions: Option[],
    checked: boolean,
    selectAll?: boolean
  ) => void;
  applyOnSelect?: boolean;
};

type OptionItemProps = {
  id: string;
  label: string;
  onSelect: (item: FilterItem, checked: boolean) => void;
  selected: boolean;
};

const OptionItem: React.FC<OptionItemProps> = ({
  id,
  label,
  onSelect,
  selected,
}: OptionItemProps): ReactElement => {
  const onCheck = (e: any): void => {
    const isChecked = e.target.checked;

    onSelect(
      {
        id,
        name: label,
      },
      isChecked
    );
  };

  return (
    <FilterOption>
      <FilterControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
          />
        }
        label={label}
      />
    </FilterOption>
  );
};

export const FilterGroup = ({
  idField,
  labelField,
  options,
  filterName,
  selectedOptions,
  clearable = true,
  handleFilter = () => {},
  applyText = 'Apply Filter',
  selectAllText = 'All',
  clearText = 'Clear Filter',
  enableApplyAction = true,
  applyOnSelected = false,
  emptySelectionOnClear = false,
  allSelected = false,
  returnAllSelected = false,
  handleIDSelection = () => {},
  singleSelect = false,
  handleAllSelection = () => {},
  applyOnSelect = false,
}: Props) => {
  const [search, setSearch] = useState<string>('');
  const [optionList, setOptionList] = useState<Record<string, any>[]>([]);
  const [selectedOptionList, setSelectedOptionList] = useState<any[]>([]);

  const isAllSelected =
    selectedOptionList.length === options.length && options.length !== 0;

  const entityTypes = new Set();

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOptionList(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (options && options.length) {
      setOptionList(options);
    }
  }, [options]);

  const onSelectChange = (entity: any, isSelected: boolean) => {
    let selected_options = [...selectedOptionList];
    const new_option_id = entity.id;

    if (isSelected) {
      selected_options.push(new_option_id);
    } else {
      selected_options = selected_options.filter(
        (option) => option !== new_option_id
      );
    }

    setSelectedOptionList(selected_options);
  };

  const onSearch = (value: string): void => {
    const regex = new RegExp(value, 'igm');
    const listFilter = options.filter(
      (item: any) => item.name.search(regex) !== -1
    );

    setOptionList(listFilter);
    setSearch(value);
  };

  type Option = { id: string; name: string };

  const handleApply = (selected: string | Option) => {
    const currentSelection: any = selected
      ? [selected]
      : options.filter((item: any) => selectedOptionList.includes(item.id));

    if (returnAllSelected) {
      handleIDSelection(
        selected || selectedOptionList,
        currentSelection,
        isAllSelected
      );
      return;
    }

    if (singleSelect && selected) {
      handleFilter(filterName, [selected]);
    } else if (!search) {
      handleFilter(filterName, selectedOptionList);
    } else {
      const currentSelectedList = currentSelection.map(
        (item: any) => item[idField]
      );

      handleFilter(filterName, currentSelectedList);
    }
  };

  const onClear = () => {
    setSearch('');
    setSelectedOptionList(
      emptySelectionOnClear ? [] : options?.map((item: any) => item.id) ?? []
    );
  };

  const onSelectAll = (
    _: ChangeEvent | React.MouseEvent | null,
    checked: boolean
  ) => {
    const convertToChips =
      options?.map((item: any) => ({
        id: item.id,
        name: item.name,
      })) ?? [];

    let selectedOptionIDs = [];

    if (checked) {
      selectedOptionIDs = options?.map((item: any) => item.id) ?? [];
      setSelectedOptionList(selectedOptionIDs);
    } else {
      setSelectedOptionList([]);
    }

    if (applyOnSelect) {
      returnAllSelected
        ? handleIDSelection(selectedOptionIDs, convertToChips, true)
        : handleAllSelection(convertToChips, checked, true);
    }
  };

  return (
    <div id="popover_filter_group_text">
      <SearchBar id="filter_search_entity" onChange={onSearch} value={search} />
      <FormGroup>
        <FilterAll>
          <b>
            <FormControlCheckAll
              id="check_all"
              key="All"
              control={
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    options &&
                    selectedOptionList.length > 0 &&
                    selectedOptionList.length < options?.length
                  }
                  onChange={onSelectAll}
                />
              }
              label={selectAllText}
            />
          </b>
        </FilterAll>
        <OptionList>
          {optionList && optionList?.length > 0 ? (
            optionList.map((option) => (
              <React.Fragment key={option.id}>
                {!entityTypes.has(option.type) &&
                  entityTypes.add(option.type) && (
                    <TypeLabel key={`type_${option.type}`} variant="label">
                      {option.type}
                    </TypeLabel>
                  )}
                <OptionItem
                  key={option.id}
                  label={option.name}
                  id={option.id}
                  onSelect={onSelectChange}
                  selected={selectedOptionList.some(
                    (e: any) => option.id === e
                  )}
                />
              </React.Fragment>
            ))
          ) : (
            <div>No records</div>
          )}
        </OptionList>
      </FormGroup>
      <Box display="flex" justifyContent="flex-end">
        <ButtonText
          id="btn_apply"
          variant="text"
          onClick={() => handleApply('')}
        >
          {applyText}
        </ButtonText>
        <ButtonText id="btn_clear" variant="text" onClick={onClear}>
          {clearText}
        </ButtonText>
      </Box>
    </div>
  );
};

export default FilterGroup;
