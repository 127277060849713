import { Box, Grid, TextField } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import React from 'react';
import { Controller } from "react-hook-form";

import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../components/Buttons/Button/Button";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { FormBox, MarginFixContainer } from "../Clients.styles";
import { CONSENT_OPTIONS } from "../constants";
import { useConsent } from "./useConsent.hooks";

const ConsentConfig: React.FC = () => {
  const {
    control,
    handleSubmit,
    onSubmit,
    isDirty,
    loading,
    onCancel,
    handleTypeChange
  } = useConsent();

  return (
    <Box>
      <Progress
        id="consent_progress"
        showProgress={loading}
      />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <FormBox>
          <Grid container xs={12} spacing={2}>
            <Grid xs={12} item>
              <Controller
                name="type"
                render={({
                  field: {
                    value
                  },
                  fieldState: {
                    error
                  }
                }) => {
                  return (
                    <SingleSelect
                      id="consent_type"
                      noGutter
                      optionList={CONSENT_OPTIONS}
                      idField="id"
                      labelField="name"
                      handleOptionSelection={(event: SelectChangeEvent) => {
                        handleTypeChange(event.target.value);
                      }}
                      value={value ?? ''}
                      error={!!error}
                      helperText={error?.message ?? ''}
                      label="Select a format" />
                  );
                }}
                rules={{
                  required: "Format is required",
                }}
                control={control}
              />
            </Grid>
            <Grid xs={12} item>
              <Controller
                name="text"
                render={({
                  field: {
                    onChange, value
                  },
                  fieldState: {
                    error
                  }
                }) => {
                  return (
                    <TextField
                      id="consent_text"
                      size="small"
                      fullWidth
                      value={value || ''}
                      rows={20}
                      multiline
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      aria-describedby="text"
                      label="Consent"
                      placeholder="Consent"
                      variant="outlined"
                      error={!!error}
                      helperText={error?.message ?? ''}
                    />
                  );
                }}
                rules={{
                  required: "Consent is required",
                }}
                control={control}
              />
            </Grid>
          </Grid>
        </FormBox>
        <MarginFixContainer>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            sx={{
              px: '30%'
            }}
          >
            <Button
              id="cancel_consent"
              variant="outlined"
              onClick={onCancel}
              text={"Cancel"}
              color={"secondary"}
              disabled={!isDirty}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id="save_consent"
              variant="contained"
              text={"Save & Continue to Feature Config"}
              color={"primary"}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </MarginFixContainer>
      </form>
    </Box>
  );
};

export default React.memo(ConsentConfig);
