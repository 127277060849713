import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Box, Grid, styled } from '@mui/material';

export const ArchiveGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledArchiveOutlinedIcon = styled(ArchiveOutlinedIcon)`
  margin-right: 8px;
`;

export const ManualSelectionBox = styled(Box)`
  display: flex;
  align-items: center;
`;
