import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";

export const SideBarContainer = styled(Box)<{
  isnavbaropen: string;
  background?: string;
}>(({ theme, isnavbaropen, background }) => ({
  height: "calc(100vh - 80px)",
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  backgroundColor: background || theme.palette.common.sidebarBackground,
  minWidth: isnavbaropen === "true" ? 290 : 60,
  maxWidth: 380,
  overflow: isnavbaropen === "true" ? "auto" : "hidden",
  paddingTop: theme.spacing(1),
}));

export const SideBarLogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.common.sidebarFooterBackground,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const SidebarPinIcon = styled(Collapse)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const SideBarList = styled(List)<{
  isnavbaropen: string;
  component: React.ElementType;
}>(({ isnavbaropen }) => ({
  width: "100%",
  maxWidth: 380,
  overflow: isnavbaropen === "true" ? "auto" : "hidden",
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  marginLeft: "25px",
  marginRight: "-27px",
}));

export const SideBarListItemText = styled(ListItemText)<{
  isnavbaropen: string;
  textcolor?: string;
}>(({ isnavbaropen, textcolor }) => ({
  "&.MuiListItemText-root .MuiTypography-root": {
    whiteSpace: "nowrap",
    ...(isnavbaropen === "false"
      ? {
          color: "transparent",
        }
      : {
          color: textcolor || "inherit",
        }),
  },
}));

export const SideBarListItemButton = styled(ListItemButton)<{
  isnavbaropen: string;
  component: React.ElementType;
  to: any;
}>(({ isnavbaropen, theme }) => ({
  paddingLeft: isnavbaropen === "true" ? theme.spacing(10) : 0,
}));

export const StyledKeyboardDoubleArrowLeft = styled(KeyboardDoubleArrowLeft)<{
  textcolor?: string;
}>(({ textcolor }) => ({
  color: textcolor || "inherit",
}));

export const StyledKeyboardDoubleArrowRight = styled(KeyboardDoubleArrowRight)<{
  textcolor?: string;
}>(({ textcolor }) => ({
  color: textcolor || "inherit",
}));
