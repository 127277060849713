import { ReactElement } from "react";

import { StyledLateralBox } from "./LateralPanelBox.styles";

type Props = { children: any };

const LateralPanelBox = (props: Props): ReactElement => {
  const { children } = props;

  return (
    <StyledLateralBox overflow="scroll">
      {children}
    </StyledLateralBox>
  );
};

export default LateralPanelBox;
