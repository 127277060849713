import {
  Files,
  GeneralMailings,
  newGeneralMailings,
  Previews,
  SelectedContacts,
  SelectedDocuments,
  sendIds,
} from "../utils/types/generalMailings.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

const apiClientPreviews = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.DocumentsApi);
};

export const getGeneralMailingList = async () =>
  await apiClient().get<GeneralMailings[]>("email-drafts/");

export const sendGeneralMailings = async (data: sendIds) =>
  await apiClient().post<sendIds>("email-drafts/", data);

export const addGeneralContactsMailing = async (data: any) =>
  await apiClient().post<newGeneralMailings>("email-drafts/contacts", data);

export const addGeneralDocumentsMailing = async (data: any) =>
  await apiClient().post<newGeneralMailings>("email-drafts/documents", data);

export const addGeneralCashReceiptsMailing = async (data: any) =>
  await apiClient().post<newGeneralMailings>("email-drafts/receivable", data);

export const getGeneralMailing = async (id: string) =>
  await apiClient().get<GeneralMailings>(`email-drafts/${id}`);

export const updateGeneralContactsMailing = async (id: string, data: any) =>
  await apiClient().put<GeneralMailings>(`email-drafts/contacts/${id}`, data);

export const updateGeneralDocumentsMailing = async (id: string, data: any) =>
  await apiClient().put<GeneralMailings>(`email-drafts/documents/${id}`, data);

export const updateGeneralCashReceiptsMailing = async (id: string, data: any) =>
  await apiClient().put<GeneralMailings>(`email-drafts/receivable/${id}`, data);

export const deleteGeneralMailing = async (id: string) =>
  await apiClient().delete<GeneralMailings>(`email-drafts/${id}`);

export const getPreview = async (draftId: string) =>
  await apiClient().get<any>(`/email-drafts/${draftId}/previews`);

export const getDocumentsPreviews = async (data: SelectedDocuments) =>
  await apiClientPreviews().post<Previews>(
    `document-api/files/email-previews`,
    data
  );

export const getContactsPreviews = async (data: SelectedContacts) =>
  await apiClient().post<Previews>("entities/contacts/email-previews", data);

export const getContactsEmails = async (data: SelectedContacts) =>
  await apiClient().post("entities/contacts/email-previews", data);

export const uploadEmailImages = async (image: any) => {
  const formData = new FormData();

  if (image) {
    formData.append("embeddedFiles", image[0]);
  }

  return await apiClient()
    .post(`email-drafts/image`, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getEmailImage = async (imageId: any) =>
  await apiClient().get<any>(`email-drafts/image/${imageId}`);
