import { FormLabel, Stack, styled } from "@mui/material";

export const StyledFormLabel = styled(FormLabel)(({
  theme
}) => ({
  color: theme.palette.text.primary,
}));

export const StyledStack = styled(Stack)(() => ({
  minWidth: '400px'
}));
