interface Styles {
  [key: string]: React.CSSProperties;
}

const containerStyle: React.CSSProperties = {
  backgroundColor: "#888888",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  margin: 0,
};

const textStyle: React.CSSProperties = {
  textAlign: "center",
  color: "#ffffff",
};

const headingStyle: React.CSSProperties = {
  fontSize: "24px",
  marginBottom: "24px",
};

const subheadingStyle: React.CSSProperties = {
  fontSize: "16px",
};

const styles: Styles = {
  containerStyle,
  textStyle,
  headingStyle,
  subheadingStyle,
};

export default styles;
