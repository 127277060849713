import React, { ReactElement } from "react";
import { CSVLink } from "react-csv";

import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import Button from "../../../components/Buttons/Button/Button";
import { DateTimeFormat } from "../../../utils/helpers/format.helper";

type Props = {
  expButtonText?: string;
  data: Record<string, any>[];
};

const csvHeaders = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Role", key: "role.name" },
  { label: "Ark Client Tag", key: "arkClientTag" },
  { label: "Email", key: "email" },
  { label: "Location", key: "location" },
];

const date = DateTimeFormat.getFormattedDate(new Date(), "_");

const ExportButton: React.FC<Props> = ({
  expButtonText = "Export",
  data = [],
}: Props): ReactElement => {
  return (
    <React.Fragment>
      <CSVLink
        headers={csvHeaders}
        data={data}
        filename={`arkuserlist_${date}`}
      >
        <Button
          id={`btn_export`}
          color="secondary"
          size="medium"
          variant="outlined"
          text={expButtonText}
          icon={<img src={ExportIcon} alt="Export All" height="15" />}
        />
      </CSVLink>
    </React.Fragment>
  );
};

export default ExportButton;
