import {
  Client,
  ClientConsent,
  ClientConsentList,
  ClientList,
  FeatureConfig,
  Files,
} from "../utils/types/client.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

export const getClients = async () =>
  await apiClient().get<ClientList>("clients");

export const getClientDetails = async (id: string) =>
  await apiClient().get<Client>(`clients/${id}`);

export const deleteClient = async (id: string) =>
  await apiClient().delete(`clients/${id}`);

export const getConsents = async (id: string) =>
  await apiClient().get<ClientConsentList>(`clients/${id}/consents`);

export const createUpdatedConsents = async (
  id: string,
  consents: ClientConsent[]
) => await apiClient().post(`clients/${id}/consents`, consents);

export const updateClientStatus = async (id: string, isActive: boolean) =>
  await apiClient().put(`clients/${id}/status`, {
    isActive,
  });

export const createClient = async (client: Client) =>
  await apiClient().post<{ id: string }>("clients", client);

export const updateClient = async (id: string, client: Client) =>
  await apiClient().put(`clients/${id}`, client);

export const uploadFiles = async (id: string, files: Files) => {
  const formData = new FormData();

  if (files?.mainLogo) {
    formData.append("mainLogo", files?.mainLogo);
  }
  if (files?.favicon) {
    formData.append("favicon", files?.favicon);
  }
  if (files?.reportLogo) {
    formData.append("reportLogo", files?.reportLogo);
  }
  if (files?.background) {
    formData.append("background", files?.background);
  }
  formData.append("mainAsReport", (files?.mainAsReport || false).toString());

  return await apiClient()
    .put(`clients/${id}/images`, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateFeatureConfig = async (config: FeatureConfig) =>
  await apiClient().post(`portal-frame/pagelock`, config);

export const updateLockedFeatures = async (clientId: string, data: any) =>
  await apiClient().post(`clients/feature/${clientId}`, data);