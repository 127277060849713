import { GridAlignment } from '@mui/x-data-grid-pro';

import { CustomType, DataGridColDef } from '../../../utils/types/listItems';

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: 'fund',
    headerName: 'Fund Name',
    hide: false,
    index: 1,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 240,
  },
  {
    field: 'investor',
    headerName: 'Investor Name',
    hide: false,
    index: 2,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 240,
  },
  {
    field: 'remainingDue',
    headerName: 'Remaining Due',
    hide: false,
    index: 3,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 140,
  },
  {
    field: 'amount',
    headerName: 'Amount Owed',
    hide: false,
    index: 4,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 160,
  },
  {
    field: 'amountPaid',
    headerName: 'Amount Paid',
    hide: false,
    index: 5,
    type: 'string',
    sortable: true,
    decimalPlaces: 0,
    align: 'left' as GridAlignment,
    width: 140,
  },

  {
    field: 'cashReceived',
    headerName: 'Cash Received',
    hide: false,
    index: 6,
    type: 'string',
    sortable: true,
    decimalPlaces: 0,
    align: 'left' as GridAlignment,
    width: 140,
  },
  {
    field: 'receiptDate',
    headerName: 'Date of Receipt',
    hide: false,
    index: 7,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: 'notified',
    headerName: 'Notified',
    hide: false,
    index: 8,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 280,
  },
  {
    field: 'action',
    headerName: 'View Journal Entry',
    hide: false,
    hideable: false,
    index: 9,
    type: 'action',
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];
