import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

import { BankFeedView } from '../../../../utils/types/bank.type';
import { BankFeedViewOptions } from '../../bankFeeds/BankFeedList.defaults';

export const BankCardsListGrid = styled(Grid)(() => ({
  justifyContent: 'flex-start',
  flexWrap: 'nowrap',
  paddingBottom: '16px',
  overflowY: 'auto',
  overflowX: 'auto',
  display: 'flex',
  height: '100%',
  gap: '16px',
}));

export const BankCardsListItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'viewState',
})<{
  viewState: BankFeedView;
}>(({ viewState }) => ({
  display: 'grid',
  gap: '8px',
  marginTop: '8px',
  minWidth: '320px',
  maxWidth: '500px',
  width: '100%',
  gridTemplateRows:
    viewState === BankFeedViewOptions.DASHBOARD ? 'auto 1px 1fr' : 'auto',
}));

export const BankSummaryCard = styled(Card)<{
  type: string;
}>(({ type }) => {
  const baseStyles = {
    minWidth: '320px',
    maxWidth: '500px',
    width: '100%',
    padding: '8px',
    borderRadius: '10px',
  };

  let aggregateTypeStyles = {};

  if (type === 'depository') {
    aggregateTypeStyles = {
      background: '#E5F4E9',
      backgroundImage:
        'linear-gradient(-33.52deg, rgba(79, 219, 222, 0.3) -0.92%, rgba(79, 219, 222, 0.3) 7.16%, rgba(79, 219, 222, 0) 30.26%)',
    };
  } else if (type === 'credit') {
    aggregateTypeStyles = {
      backgroundImage:
        'linear-gradient(-33.52deg, rgba(242, 178, 3, 0.3) -0.92%, rgba(242, 178, 3, 0.3) 7.16%, rgba(242, 178, 3, 0.15) 30.26%)',
    };
  } else if (type === 'other') {
    aggregateTypeStyles = {
      marginRight: '0',
      background: '#D6E9FF',
      backgroundImage:
        'linear-gradient(-33.52deg, rgba(79, 219, 222, 0.3) -0.92%, rgba(79, 219, 222, 0.3) 7.16%, rgba(79, 219, 222, 0) 30.26%)',
    };
  }

  const mergedStyles = { ...baseStyles, ...aggregateTypeStyles };

  return mergedStyles;
});

export const BanksCardContent = styled(CardContent)(({ theme }) => ({
  padding: '8px',
}));

export const SummaryCardHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
}));

export const DetailsButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: '#007AFF',
}));

export const SummaryCardValueLabel = styled(Typography)(({ theme }) => ({
  md: '1.5',
}));

export const SummaryCardGlValue = styled(Typography)(({ theme }) => ({
  md: '1.5',
  marginLeft: '12px',
}));

export const IndividualBankCardsListGrid = styled(Grid)(() => ({
  gap: '8px',
  marginTop: '8px',
  '& > *:last-child': {
    marginBottom: '16px',
  },
}));

export const IndividualBankCard = styled(Card)<{
  type: string;
}>(({ type }) => {
  const baseStyles = {
    padding: '8px',
    borderRadius: '10px',
  };

  let typeStyles = {};

  if (type === 'depository') {
    typeStyles = {
      background: '#E5F4E9',
    };
  } else if (type === 'credit') {
    typeStyles = {
      background: '#FDF5E1',
    };
  } else if (type === 'other') {
    typeStyles = {
      background: '#D6E9FF',
    };
  }

  const mergedStyles = { ...baseStyles, ...typeStyles };

  return mergedStyles;
});

export const GLTotalTypography = styled(Typography)(() => ({
  marginLeft: '12px',
}));

export const GlBalanceTooltip = styled(Tooltip)(() => ({
  marginRight: '4px',
}));
