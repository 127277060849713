import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const InvestorIcon = (props: any) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z" fill={props.color || "#0000008a"} />
    <path d="M10.35 14.01C7.62 13.91 2 15.27 2 18V19C2 19.55 2.45 20 3 20H11.54C9.07 17.24 10.31 14.11 10.35 14.01Z" fill={props.color || "#0000008a"} />
    <path d="M18 10C14.688 10 12 12.688 12 16C12 19.312 14.688 22 18 22C21.312 22 24 19.312 24 16C24 12.688 21.312 10 18 10ZM18.846 19.654V20.002C18.846 20.44 18.486 20.8 18.048 20.8H18.042C17.604 20.8 17.244 20.44 17.244 20.002V19.642C16.446 19.474 15.738 19.036 15.438 18.298C15.3 17.968 15.558 17.602 15.918 17.602H16.062C16.284 17.602 16.464 17.752 16.548 17.962C16.722 18.412 17.178 18.724 18.054 18.724C19.23 18.724 19.494 18.136 19.494 17.77C19.494 17.272 19.23 16.804 17.892 16.486C16.404 16.126 15.384 15.514 15.384 14.284C15.384 13.252 16.218 12.58 17.25 12.358V11.998C17.25 11.56 17.61 11.2 18.048 11.2H18.054C18.492 11.2 18.852 11.56 18.852 11.998V12.37C19.68 12.574 20.202 13.09 20.43 13.726C20.55 14.056 20.298 14.404 19.944 14.404H19.788C19.566 14.404 19.386 14.248 19.326 14.032C19.188 13.576 18.81 13.282 18.054 13.282C17.154 13.282 16.614 13.69 16.614 14.266C16.614 14.77 17.004 15.1 18.216 15.412C19.428 15.724 20.724 16.246 20.724 17.758C20.712 18.856 19.89 19.456 18.846 19.654Z" fill={props.color || "#0000008a"} />
  </SvgIcon>
);

export default InvestorIcon;
