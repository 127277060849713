import { reject } from "lodash";
declare const google: any;


type DetailsPlace ={
    zipCode:string;
    state:string;
    country:string;
    city:string
}

// eslint-disable-next-line require-await
export const autoCompleteAddress = async (text:string) => new Promise<any>((resolve,reject)=>{
        
    if (!text) {
            return resolve([]);
    }

    if (typeof window === "undefined") {
        return resolve([]);
    }

    try {
            new google.maps.places.AutocompleteService().getPlacePredictions(
                { input: text,types: ['establishment', 'geocode'] },
                
                resolve
            );
     
    } catch (e) {
        reject(e);
    }

});


// eslint-disable-next-line require-await
export const getDetailsByIdPlace = async (pPlaceId:string) => new Promise<any>((resolve,reject)=>{
        
    if (!pPlaceId) {
            return reject("Need a valid text input");
    }

    if (typeof window === "undefined") {
        return reject("Need valid window object");
    }

    try {
          const autocompleteFormField = document.getElementById(`placeid`) as HTMLDivElement ;
        
      const service = new google.maps.places.PlacesService(autocompleteFormField);

         service.getDetails({ placeId:pPlaceId, fields: ["address_components"] },(details:any) => {
            const detailsPlace:DetailsPlace = { zipCode:"",state:"",country:"",city:"" };

            details?.address_components?.forEach((entry:any) => {
                switch (entry.types?.[0]){
                    case "postal_code":{
                        detailsPlace.zipCode = entry.long_name;
                        break;
                    }
                    case "administrative_area_level_1":{
                        detailsPlace.state = entry.short_name;
                        break;
                    }
                    case "country":{
                        detailsPlace.country = entry.short_name;
                        break;
                    }
                    case "locality":{
                        detailsPlace.city = entry.long_name;
                        break;
                    }

                }
              
            });

            return resolve(detailsPlace);
          }) ;

    } catch (e) {
        reject(e);
    }

});


// eslint-disable-next-line require-await
export const getDetailsByZipCode = async (zip:string) => new Promise<any>((resolve,reject)=>{
        
    if (!zip) {
            return reject("Need a valid text input");
    }

    if (typeof window === "undefined") {
        return reject("Need valid window object");
    }

    try {

    if (zip.length >= 5 && typeof google !== 'undefined') {
        const detailsPlace:DetailsPlace = { zipCode:"",state:"",country:"",city:"" };
        const geocoder  = new google.maps.Geocoder();

        geocoder.geocode({ 'address': zip }, function (results:any, status:any) {
            if (status === google.maps.GeocoderStatus.OK) {
                if (results) {
                    results[0].address_components.forEach((entry:any) => {
                        switch (entry.types?.[0]){
                            case "postal_code":{
                                detailsPlace.zipCode = entry.long_name;
                                break;
                            }
                            case "administrative_area_level_1":{
                                detailsPlace.state = entry.short_name;
                                break;
                            }
                            case "country":{
                                detailsPlace.country = entry.short_name;
                                break;
                            }
                            case "locality" :{
                                detailsPlace.city = entry.long_name;
                                break;
                            }
        
                        }
                      
                    });
        
                    return resolve(detailsPlace);
                    
                   
            
                } else {
                  return resolve("");
                }
            } else {
                return reject("");
            }
        });
    } else {
        return reject("");
    }       

    } catch (e) {
        return reject(e);
    }

}).catch(e=>{
    return reject(e);
});