import { Button, List, ListItemButton, Typography } from "@mui/material";
import React, { ReactElement, ReactNode, useRef } from "react";

import ArrowPopover from "../ArrowPopover/ArrowPopover";
import ListItem from "../ListItem/ListItem";
import ListItemIcon from "../ListItemIcon/ListItemIcon";

export type ButtonOptions = {
  label: string;
  id: string;
  icon?: any;
  type?: string;
  hidden?: boolean;
};

type Props = {
  buttonID: string;
  popoverID: string;
  onClick: (actionID: string, event?: any) => void;
  handleOnPopoverClose?: () => void;
  buttonLabel: string;
  buttonIcon?: ReactNode;
  handleOnButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  options: ButtonOptions[];
};

const ButtonWithOptions: React.FC<Props> = (props: Props): ReactElement => {
  const {
    buttonID,
    buttonIcon,
    buttonLabel,
    popoverID,
    handleOnPopoverClose,
    handleOnButtonClick,
    options,
    onClick,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
    handleOnButtonClick?.(event);
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileUploadRequest = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const handleOnClose = (): void => {
    setAnchorEl(null);
    handleOnPopoverClose?.();
  };

  return (
    <>
      <Button
        id={buttonID}
        variant="contained"
        color="primary"
        onClick={handleOnClick}
        startIcon={buttonIcon}
      >
        {buttonLabel}
      </Button>
      <ArrowPopover
        id={popoverID}
        anchorEl={anchorEl}
        showPopover={Boolean(anchorEl)}
        handleOnPopoverClose={handleOnClose}
        content={
          <List dense={true}>
            {options?.map((option) => (
              <ListItem key={option.id} hidden={option.hidden}>
                <ListItemButton
                  onClick={(event) => {
                    if (option.type !== "upload") {
                      onClick(option.id, event);
                      handleOnClose();
                    } else {
                      handleFileUploadRequest();
                    }
                  }}
                >
                  {option.icon && (
                    <ListItemIcon id={`${option.id}`} icon={option.icon} />
                  )}
                  <Typography variant="subtitle2">{option.label}</Typography>
                  {option.type === "upload" && (
                    <input
                      id="input_file_upload"
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(event) => {
                        onClick(option.id, event);
                        handleOnClose();
                      }}
                      hidden={true}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
      />
    </>
  );
};

export default ButtonWithOptions;
