import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { useHistory } from "react-router";

import DataGrid from "../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../components/Modal/ConfirmationDialog";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../components/SearchBarWithDebounce/SearchBar";
import TabsPanel from "../../components/TabsPanel/TabsPanel";
import { ButtonBox } from "../investors/investorList/InvestorList.styles";
import { Status } from "./constants";
import ExportButton from "./ExportButton/ExportButton";
import UserDetails from "./userDetails/UserDetails";
import {
  ArkClientBox,
  ArkClientBoxIcon,
  Container,
  FilterRow,
  HeaderBreadcrumbContainer,
  HeaderButtonBox,
  HeaderRow,
  HeaderTitleContainer,
  TabsRow,
} from "./Users.styles";
import { useUsers } from "./useUsers.hooks";

const Users: React.FC = (): ReactElement => {
  const history = useHistory();
  const {
    headerList,
    loadingUsers,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    handleOnView,
    handleTabChange,
    activeIndex,
    selectedClientArkTag,
    setSelectedClientArkTag,
    loading,
    buttonTitle,
    statusUpdateUser,
    discardStatusUpdate,
    updateStatus,
    isSuperAdmin,
    handleAddNewUser,
    selectedUser,
    cancelAddEdit,
    resetFormAndFetchUsers,
    users,
    segregatedUsers,
    showAddNewClientConfirmation,
    onAddNewClientConfirm,
    onAddNewClientCancel,
  } = useUsers();

  return (
    <Container
      id="UsersDrawer"
      anchor="right"
      open={true}
      onClose={history.goBack}
    >
      <Box id="user_list_main">
        <Progress
          id="user_detail_list_loading"
          showProgress={loadingUsers || loading}
        />
        <HeaderRow>
          <HeaderTitleContainer>
            {isSuperAdmin && selectedClientArkTag && (
              <IconButton
                onClick={() => setSelectedClientArkTag(undefined)}
                aria-label="Back"
              >
                <ArrowBackIosRoundedIcon color="primary" fontSize="large" />
              </IconButton>
            )}
            <HeaderBreadcrumbContainer>
              <Typography variant="pageTitle">Users</Typography>
              {isSuperAdmin && selectedClientArkTag && (
                <>
                  <ArrowForwardIosRoundedIcon color="action" />
                  <ArkClientBox>
                    <ArkClientBoxIcon color="secondary" />
                    <Typography>{selectedClientArkTag}</Typography>
                  </ArkClientBox>
                </>
              )}
            </HeaderBreadcrumbContainer>
            <Box />
          </HeaderTitleContainer>
          <IconButton
            aria-label="Close User"
            color="inherit"
            id="close_user_drawer"
            onClick={history.goBack}
          >
            <Close />
          </IconButton>
        </HeaderRow>
        {!selectedClientArkTag && isSuperAdmin && (
          <TabsRow>
            <TabsPanel
              id={"user_tabs"}
              value={activeIndex}
              handleChange={handleTabChange}
              options={["Client List", "Super Admins"]}
            />
          </TabsRow>
        )}
        <FilterRow>
          <SearchBar id="users-search" onChange={onSearch} value={search} />

          <HeaderButtonBox>
            {!selectedClientArkTag && isSuperAdmin ? (
              <ExportButton data={users} expButtonText="Export All Users" />
            ) : (
              <ExportButton data={list} expButtonText="Export Users" />
            )}
            <Button
              id="add_user_button"
              variant="contained"
              color="primary"
              onClick={handleAddNewUser}
              startIcon={<AddIcon />}
            >
              {buttonTitle}
            </Button>
          </HeaderButtonBox>
        </FilterRow>
        {!loadingUsers && list && list?.length > 0 && (
          <Box>
            <DataGrid
              id="user_data_grid"
              dataList={list}
              headerList={headerList}
              minHeight="80vh"
              autoHeight={false}
              handleOnView={handleOnView}
              activeHeaderFields={activeHeaderFields}
              handleUpdateHeader={handleUpdateHeader}
            />
          </Box>
        )}
        {!loadingUsers && list && list?.length === 0 && (
          <Typography id="no_data" align="center">
            No Data Available
          </Typography>
        )}
      </Box>
      <UserDetails
        onUserDetailClose={cancelAddEdit}
        resetPaginationAndFetch={resetFormAndFetchUsers}
        selectedUser={selectedUser}
      />
      <ConfirmationDialog
        open={!!statusUpdateUser}
        onClose={discardStatusUpdate}
        id="toggle-user-status-confirmation"
        actions={[
          {
            label: "Update Status",
            onClick: updateStatus,
            id: "toggle-user-status-confirm-modal-button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: discardStatusUpdate,
            id: "toggle-user-status-cancel-modal-button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`Are you sure you want to ${
          statusUpdateUser?.status?.toUpperCase() === Status.ACTIVE
            ? "deactivate"
            : "activate"
        } ${statusUpdateUser?.firstName ?? ""}${
          statusUpdateUser?.lastName ? statusUpdateUser.lastName : ""
        }?`}
        title="Update Status?"
      />
      <ConfirmationDialog
        open={showAddNewClientConfirmation}
        onClose={onAddNewClientCancel}
        id="add_new_client_confirmation"
        actions={[
          {
            label: "Continue",
            onClick: onAddNewClientConfirm,
            id: "confirm_add_new_client_button",
            variant: "contained",
            color: "primary",
          },
        ]}
        content="You must create a new user and link them to the new client."
        title="New Client"
      />
    </Container>
  );
};

export default React.memo(Users);
