import { BankRule } from '../../../utils/types/bank.type';

export const BANK_RULE_FORM_DEFAULT_VALUE: BankRule = {
  ruleName: '',
  appliesTo: [],
  banks: [],
  status: 'inactive',
  includes: '',
  detail: '',
  condition: '',
  value: '',
  account: '',
  accountId: '',
  splitBy: 'PERCENTAGE',
  percentage: undefined,
  amount: undefined,
  memoEntity: [],
  splitDetails: [
    {
      detailValue: undefined,
      detailAccount: '',
    },
    {
      detailValue: undefined,
      detailAccount: '',
    },
  ],
  conditions: [
    {
      condition: '',
      detail: '',
      value: '',
    },
  ],
};
