import { Box as MUIBox, styled } from "@mui/material";

export const Box = styled(MUIBox)`
  background-color: #ECEEF3;
  padding: 15px 35px;
`;

export const SelectBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: '5px',

  [`& .MuiSelect-select`]: {
    width: '200px',
    padding: '0'
  },
  [`& .MuiFormControl-root`]: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  [`& .MuiOutlinedInput-root`]: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: '10px',
  },
  [`&	.MuiInputLabel-formControl .MuiInputLabel-asterisk`]: {
    color: 'red',
  },
  [`& .MuiButton-root`]: {
    width: '100px',
  },
  [`& .MuiButton-root, .MuiTypography-root`]: {
    margin: '0 10px',
  },
  [`& .MuiMenuItem-root`]: {
    minWidth: '100%'
  }
}));
