import { Box, styled, Typography } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const FilterBox = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gridTemplateColumns: "400px auto",
  alignItems: "center",
}));

export const TimeText = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.grey[500],
}));
