import {
  CategoryFieldDef,
  ValueFieldDef,
} from '../../../../components/ReportGrid/ReportGrid.types';
import { ListItem } from '../../../../utils/types/listItems';

const defaultCategoryFieldOrder: CategoryFieldDef[] = [
  {
    id: 'fundId',
    name: 'fund',
    headerName: 'Fund',
    visible: true,
    order: 1,
    useLinkButton: false,
  },
  {
    id: 'countryId',
    name: 'country',
    headerName: 'Country',
    visible: false,
    order: 2,
    useLinkButton: false,
  },
  {
    id: 'industrySectorId',
    name: 'industrySector',
    headerName: 'Industry/Sector',
    visible: false,
    order: 3,
    useLinkButton: false,
  },
  {
    id: 'companyNameId',
    name: 'companyName',
    headerName: 'Portfolio Company Name',
    visible: true,
    order: 4,
    useLinkButton: true,
  },
  {
    id: 'securityTypeId',
    name: 'securityType',
    headerName: 'Security Type',
    visible: true,
    order: 5,
    useLinkButton: false,
  },
  {
    id: 'securityId',
    name: 'investmentSecurity',
    headerName: 'Security',
    visible: true,
    order: 6,
    useLinkButton: false,
  },
  {
    id: 'holdingStatusId',
    name: 'holdingStatus',
    headerName: 'Holding Status',
    visible: false,
    order: 7,
    useLinkButton: false,
  },
  {
    id: 'isPublicId',
    name: 'isPublic',
    headerName: 'Public/Private',
    visible: false,
    order: 8,
    useLinkButton: false,
  },
];

export const defaultDataFieldOrder: ValueFieldDef[] = [
  {
    id: 'shares',
    name: 'shares',
    headerName: 'Shares',
    visible: true,
    order: 1,
    numberFormat: 'Decimal-use-comas.2',
    useLinkButton: false,
  },
  {
    id: 'cost',
    name: 'cost',
    headerName: 'Cost',
    visible: true,
    order: 2,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'fairMarketValue',
    name: 'fairMarketValue',
    headerName: 'Fair Market Value',
    visible: true,
    order: 3,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'unrealizedGainLoss',
    name: 'unrealizedGainLoss',
    headerName: 'Unrealized Gain/Loss',
    visible: true,
    order: 4,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'realizedValue',
    name: 'realizedValue',
    headerName: 'Realized Value',
    visible: true,
    order: 5,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'realizedGainLoss',
    name: 'realizedGainLoss',
    headerName: 'Realized Gain/Loss',
    visible: true,
    order: 6,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'totalGainLoss',
    name: 'totalGainLoss',
    headerName: 'Total Gain/Loss',
    visible: true,
    order: 7,
    numberFormat: 'Currency.0',
    useLinkButton: false,
  },
  {
    id: 'costPerQuantity',
    name: 'costPerQuantity',
    headerName: 'Cost per Share',
    visible: false,
    order: 8,
    numberFormat: 'Currency.2',
    useLinkButton: false,
  },
];

const defaultSoiViews: ListItem[] = [
  { id: 'Unrealized_SOI', label: 'Unrealized SOI' },
  { id: 'Realized_SOI', label: 'Realized SOI' },
];

export function getDefaultCategoryFieldOrder(showFund: boolean) {
  const fundCat = defaultCategoryFieldOrder.find((c) => c.name === 'fund')!;

  fundCat.visible = showFund;

  return defaultCategoryFieldOrder;
}

export function getDefaultDataFieldOrder(soiView: string) {
  let filteredDataFieldOrder: ValueFieldDef[];

  if (soiView === 'Unrealized_SOI') {
    filteredDataFieldOrder = defaultDataFieldOrder.filter(
      (f) =>
        f.name !== 'realizedValue' &&
        f.name !== 'realizedGainLoss' &&
        f.name !== 'totalGainLoss'
    );
  } else if (soiView === 'Realized_SOI') {
    filteredDataFieldOrder = defaultDataFieldOrder.filter(
      (f) =>
        f.name !== 'fairMarketValue' &&
        f.name !== 'unrealizedGainLoss' &&
        f.name !== 'totalGainLoss'
    );
  } else {
    throw 'Invalid SOI view';
  }

  return filteredDataFieldOrder;
}

export function getDefaultSoiViews() {
  return defaultSoiViews;
}
