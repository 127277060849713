export const GET_CAPITAL_CALL_LIST_ERROR = "Error in getting capital call list";
export const GET_CAPITAL_CALL_DETAILS_ERROR =
  "Error in getting capital call details";
export const GET_CAPITAL_CALL_TRANSACTIONS_ERROR =
  "Error in getting capital call transactions";
export const SAVE_CAPITAL_CALL_LIST_ERROR = "Error in saving updates";
export const SAVE_CAPITAL_CALL_LIST_SUCCESS = "Successfully saved updates";
export const EMPTY_REQUIRED_FIELDS_ERROR =
  "Unable to save. Please fill out required fields";

export const NotifiedStatusType: {
  [key: string]: { id: string; color: string; name: string };
} = {
  YES: { id: "yes", color: "green", name: "Yes" },
  NO: { id: "no", color: "red", name: "No" },
};

export const CashReceivedStatusType = {
  true: "Yes",
  false: "No",
};
