import { Typography } from "@mui/material";
import React from "react";

import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../../components/Buttons/Button/Button";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import DataGrid from "../../../components/DataGrid/DataGrid";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import { ButtonContainer, HeaderRow } from "./Distribution.styles";
import { usePortalDistribution } from "./useDistribution.hook";
import ViewNotice from "./ViewNotice";

const Distribution: React.FC = () => {
  const {
    headerList,
    list,
    isLoading,
    selectionModel,
    setSelectionModel,
    handleFilter,
    handleOnView,
    selectedDistribution,
    onViewNoticeClose,
    bulkActions,
    handleBulkActions
  } = usePortalDistribution();

  return (
    <DataWrapperBox id="portal_distribution_list_main">
      <Progress id="portal_distribution_list_loading" showProgress={isLoading} />
      <HeaderRow>
        <Typography variant="pageTitle">Distributions</Typography>
        <ButtonContainer>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActions}
            hidden={false}
            handleOptionClick={handleBulkActions}
            ariaLabelMessage="Select bulk action option"
          />
        </ButtonContainer>
      </HeaderRow>
      <DataGrid
        id="portal_distribution_data_grid"
        dataList={list || []}
        noDataMessage={isLoading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        actionName="View"
        autoHeight={false}
        activeHeaderFields={6}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleFilter={handleFilter}
        handleOnView={handleOnView}
        initialState={{
          sorting: {
            sortModel: [{ field: "distributionDate", sort: "desc" }],
          },
          pinnedColumns: {
            right: ["action"],
          },
        }}
      />
      {!!selectedDistribution && (
        <ViewNotice
          onViewNoticeClose={onViewNoticeClose}
          selectedDistribution={selectedDistribution}
        />
      )}
    </DataWrapperBox>
  );
};

export default React.memo(Distribution);
