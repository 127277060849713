import { useUsersEffect } from "../../../services/hooks/useUsersEffect/useUsersEffect.hooks";
//TODO: Added a hook as this component may need some extra handling in future.

const useTeamMemberFilter = () => {
  const {
    users,
    loading
  } = useUsersEffect();

  return {
    users,
    loading,
  };
};

export default useTeamMemberFilter;

