import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import DeleteIcon from '../../../assets/images/icons/icon_delete.svg';
import ExportIcon from '../../../assets/images/icons/icon_export.svg';
import { StringCell } from '../../../components/DataGrid/DataGrid.styles';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import { useFundFilterEffect } from '../../../services/hooks/useFundFilterEffect/useFundFilterEffect';
import { useInvestorsFilterEffect } from '../../../services/hooks/useInvestorsFilterEffect/useInvestorsFilterEffect.hooks';
import { useTransactionTypesEffect } from '../../../services/hooks/useTransactionTypesEffect/useTransactionTypesEffect.hooks';
import { downloadTemplate } from '../../../services/template.service';
import {
  deleteAllTransactionsService,
  deleteTransactions,
  downloadData,
  getTransactionIDs,
  getTransactions,
  getTransactionsCount,
} from '../../../services/transaction.service';
import { uploadFile } from '../../../services/uploads.service';
import {
  API_DATE_FORMAT,
  M_DASH_UNICODE,
  PAGE_SIZE,
} from '../../../utils/constants/constants';
import { GENERIC_ERROR_MESSAGE } from '../../../utils/constants/text.constants';
import { capitalize } from '../../../utils/helpers/capitalize';
import downloadFile from '../../../utils/helpers/fileDownloader';
import {
  NegativeCurrencyFormat,
  NumberFormat,
} from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { AddNewButtonOptions } from '../../../utils/types/common.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../utils/types/listItems';
import {
  SelectedTransaction,
  Transaction,
  TransactionAction,
  TransactionFilter,
  TransactionParams,
  TransactionType,
} from '../../../utils/types/transaction.type';
import { ScopeRole } from '../../../utils/types/user.type';
import {
  DELETE_ACTION_TYPE,
  DELETE_TRANSACTION_ERROR,
  DELETE_TRANSACTION_SUCCESS,
  DOWNLOAD_TRANSACTION_ERROR,
  EXPORT_TRANSACTION_ERROR,
  LOCKED_PAGE_TOOLTIP_TEXT,
} from './constants';

const StatusType = {
  editing: 'yellow',
  reviewing: 'aqua',
  published: 'green',
};

const FIRST_COLUMN = {
  field: 'name',
  headerName: 'Transaction Name',
  hide: false,
  index: 1,
  sortable: false,
  type: 'string',
  align: 'left' as GridAlignment,
  width: 200,
};

const defaultHeaderList: DataGridColDef[] = [
  {
    field: 'amount',
    headerName: 'Amount',
    hide: false,
    index: 5,
    type: 'number',
    customType: CustomType.NegativeCurrency,
    sortable: false,
    currencyCodeField: 'currencyCode',
    align: 'right' as GridAlignment,
    width: 200,
    renderCell: (params: GridRenderCellParams) => {
      const currencyCode = params.row.currencyCode || 'USD';
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

      if (params.row.useMetric) {
        if (datePattern.test(params.row.metricSign)) {
          return <StringCell>{params.row.metricSign}</StringCell>;
        } else {
          return (
            NumberFormat(params.row.metricFractionDigit).format(params.value) +
            params.row.metricSign
          );
        }
      }
      return NegativeCurrencyFormat(
        currencyCode,
        params.row.metricFractionDigit
      ).format(params.value);
    },
  },
  {
    field: 'reviewable.name',
    headerName: 'Batch Name',
    hide: false,
    index: 6,
    sortable: false,
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: 'reviewableStatus.name',
    headerName: 'Batch Status',
    hide: true,
    index: 7,
    sortable: false,
    renderCell: (params) => {
      const status = params?.row?.reviewableStatus?.name?.toLowerCase();
      const color = StatusType[status as keyof typeof StatusType];

      return (
        <StringCell>
          {status ? (
            <StatusLabel
              color={color}
              label={capitalize(status ?? M_DASH_UNICODE)}
            />
          ) : (
            M_DASH_UNICODE
          )}
        </StringCell>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: 'reviewableType.name',
    headerName: 'Portal Type',
    hide: true,
    index: 8,
    sortable: false,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 150,
  },
];

const ACTION_COLUMN = {
  field: 'action',
  headerName: 'Columns',
  hide: false,
  hideable: false,
  index: 10,
  type: 'action',
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 100,
};

const initialSelectedTransaction: SelectedTransaction = {
  transaction: undefined,
  type: undefined,
};

export const useTransactions = () => {
  const [transactionList, setTransactionList] = useState<Transaction[]>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<SelectedTransaction>(initialSelectedTransaction);
  const [page, setPage] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [transactionSelectionModel, setTransactionSelectionModel] = useState<
    string[]
  >([]);
  const [isLocked, setIsLocked] = useState(true);
  const [lockedTooltipText, setLockedTooltipText] = useState<string>('');
  const [deleteAction, setDeleteAction] = useState<
    DELETE_ACTION_TYPE | undefined
  >();
  const { informationAlert } = useContext(AppContext);
  const { investorsList, loading: loadingInvestors } =
    useInvestorsFilterEffect();
  const { fundList, loading: loadingFunds } = useFundFilterEffect();
  const { transactionTypesList, loading: loadingTransactionTypes } =
    useTransactionTypesEffect();
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);
  const investors = useMemo(
    () => investorsList?.map((item) => item.id),
    [investorsList]
  );
  const transTypes = useMemo(
    () => transactionTypesList?.map((item) => item.id),
    [transactionTypesList]
  );
  const funds = useMemo(() => fundList?.map((item) => item.id), [fundList]);

  const toggleIsLocked = () => {
    setIsLocked((prevState) => !prevState);
  };

  const showLockedTooltip = () => {
    setLockedTooltipText(LOCKED_PAGE_TOOLTIP_TEXT);

    setTimeout(() => {
      setLockedTooltipText('');
    }, 3000);
  };

  const initializeHeaderList = () => {
    const updatedHeaders = [
      {
        ...FIRST_COLUMN,
        inlineFilterType: 'search',
        inlineFilter: true,
        inlineFilterName: TransactionFilter.TransactionName,
        inlineFilterSelected: [''],
      },
      {
        field: 'fund.name',
        headerName: 'Fund Name',
        hide: false,
        index: 2,
        sortable: false,
        type: 'string',
        inlineFilter: true,
        inlineFilterName: TransactionFilter.FundName,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: fundList,
        inlineFilterSelected: funds,
        align: 'left' as GridAlignment,
        width: 200,
      },
      {
        field: 'investor.name',
        headerName: 'Investor Name',
        hide: false,
        index: 3,
        sortable: false,
        type: 'string',
        inlineFilter: true,
        inlineFilterName: TransactionFilter.InvestorName,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: investorsList,
        inlineFilterSelected: investors,
        align: 'left' as GridAlignment,
        width: 200,
      },
      {
        field: 'txnType.name',
        headerName: 'Transaction Type',
        hide: false,
        index: 4,
        sortable: false,
        type: 'string',
        inlineFilter: true,
        inlineFilterName: TransactionFilter.TransactionType,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: transactionTypesList,
        inlineFilterSelected: transTypes,
        align: 'left' as GridAlignment,
        width: 200,
      },
      ...defaultHeaderList,
      {
        field: 'date',
        headerName: 'Date',
        hide: false,
        index: 9,
        type: 'date',
        sortable: false,
        align: 'left' as GridAlignment,
        inlineFilter: true,
        inlineFilterName: TransactionFilter.TransactionDate,
        inlineFilterOptions: [],
        inlineFilterSelected: ['specific_date', [null, null]],
        width: 200,
      },
      ACTION_COLUMN,
    ];

    setHeaderList(updatedHeaders);
    setActiveHeaderFields(updatedHeaders.length - 1);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: TransactionFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === TransactionFilter.FundName) {
          inlineFilterSelected = funds;
        } else if (inlineFilterName === TransactionFilter.InvestorName) {
          inlineFilterSelected = investors;
        } else if (inlineFilterName === TransactionFilter.TransactionType) {
          inlineFilterSelected = transTypes;
        } else if (inlineFilterName === TransactionFilter.TransactionDate) {
          inlineFilterSelected = ['', [null, null]];
        } else if (inlineFilterName === TransactionFilter.TransactionName) {
          inlineFilterSelected = [''];
        }
        return {
          ...header,
          hide:
            header.field === field &&
            !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const handleFilter = async (
    filterName: TransactionFilter,
    selected: string[]
  ) => {
    await setPage(1);
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );
  };

  const handleOnView = (transactionId: string, transaction: Transaction) => {
    if (isLocked && isSuperAdminOrClientAdmin) {
      showLockedTooltip();
      return;
    }
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

    if (transaction.useMetric && datePattern.test(transaction.metricSign)) {
      transaction.amount = transaction.metricSign.replace(/\//g, '');
    }

    setSelectedTransaction({
      transaction,
      type: TransactionType.Edit,
    });
  };

  const onTransactionDetailClose = () => {
    setSelectedTransaction(initialSelectedTransaction);
  };

  const onNextPage = async () => {
    if (
      isLastPage ||
      !transactionList ||
      (transactionList && transactionList?.length < PAGE_SIZE)
    ) {
      return;
    }
    await fetchTransactions(headerList, (page as number) + 1, true);
    await setPage((page as number) + 1);
  };

  const bulkActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: TransactionAction.ExportSelected,
      text: `Export Selected (${transactionSelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: TransactionAction.ExportAll,
      text: 'Export All',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };
    const deleteSelected = {
      id: TransactionAction.DeleteSelected,
      text: `Delete Selected (${transactionSelectionModel?.length || 0})`,
      icon: <img src={DeleteIcon} alt="Delete Selected" height="15" />,
      color: 'error',
      optionsSelected: 0,
    };

    const deleteAll = {
      id: TransactionAction.DeleteAll,
      text: 'Delete All',
      icon: <img src={DeleteIcon} alt="Delete All" height="15" />,
      optionsSelected: 0,
    };

    if (transactionSelectionModel?.length > 0) {
      actions.push(exportSelected);
    }
    actions.push(exportAll);
    if (transactionSelectionModel?.length > 0 && isSuperAdminOrClientAdmin) {
      actions.push(deleteSelected);
    }
    if (isSuperAdminOrClientAdmin) {
      actions.push(deleteAll);
    }

    return actions;
  }, [transactionSelectionModel]);

  useEffect(() => {
    if (
      transTypes &&
      funds &&
      investors &&
      investors?.length > 0 &&
      funds?.length > 0 &&
      transTypes?.length > 0
    ) {
      initializeHeaderList();
    }
  }, [transTypes, investors, funds]);

  function getSelectedFilters(currentHeaderList: DataGridColDef[]) {
    return currentHeaderList?.reduce(
      (acc: Record<TransactionFilter, string[] | undefined>, header) => {
        if (
          header.inlineFilter &&
          header.inlineFilterSelected &&
          header.inlineFilterSelected?.length > 0
        ) {
          return {
            ...acc,
            [header.inlineFilterName ?? '']: header.inlineFilterSelected,
          };
        }
        return acc;
      },
      {
        [TransactionFilter.InvestorName]: undefined,
        [TransactionFilter.FundName]: undefined,
        [TransactionFilter.TransactionType]: undefined,
        [TransactionFilter.TransactionDate]: undefined,
        [TransactionFilter.TransactionName]: undefined,
      }
    );
  }

  const exportTransactions = async (ids: string[]) => {
    try {
      const downloadedData = await downloadData({
        type: 'TRANSACTION',
        ids,
      });

      downloadFile(downloadedData, 'Transactions', 'csv');
    } catch (error) {
      informationAlert(DOWNLOAD_TRANSACTION_ERROR, 'error');
    }

    setIsLoading(false);
  };

  const exportSelectedTransactions = () => {
    exportTransactions(transactionSelectionModel);
  };

  const exportAllTransactions = async () => {
    try {
      const { params } = getParams(headerList, page);
      const transactionsIds = await getTransactionIDs(params);

      exportTransactions(transactionsIds as string[]);
    } catch (error) {
      informationAlert(EXPORT_TRANSACTION_ERROR, 'error');
    }
  };

  const deleteSelectedTransactions = async () => {
    try {
      await deleteTransactions(transactionSelectionModel);
      await setTransactionSelectionModel([]);
      informationAlert(DELETE_TRANSACTION_SUCCESS, 'success');
      await fetchTransactions(headerList, 1, false);
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        const message = error.response.data;

        if (
          message ===
          'Transactions that are dated within published quarters cannot be deleted.'
        ) {
          informationAlert(message, 'error');
        } else {
          informationAlert(DELETE_TRANSACTION_ERROR, 'error');
        }
      } else {
        informationAlert(DELETE_TRANSACTION_ERROR, 'error');
      }
      setIsLoading(false);
    }
  };

  const deleteAllTransactions = async () => {
    try {
      const { params } = getParams(headerList, page);

      await deleteAllTransactionsService(params);
      informationAlert(DELETE_TRANSACTION_SUCCESS, 'success');
      await fetchTransactions(headerList, 1, false);
    } catch (error) {
      informationAlert(DELETE_TRANSACTION_ERROR, 'error');
    }
  };

  const handleBulkAction = (actionId: TransactionAction) => {
    if (
      (actionId === TransactionAction.DeleteAll ||
        actionId === TransactionAction.DeleteSelected) &&
      isLocked
    ) {
      showLockedTooltip();
      return;
    }
    setIsLoading(true);
    switch (actionId) {
      case TransactionAction.ExportSelected:
        exportSelectedTransactions();
        break;
      case TransactionAction.ExportAll:
        exportAllTransactions();
        break;
      case TransactionAction.DeleteSelected:
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_SELECTED);
        break;
      case TransactionAction.DeleteAll:
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_ALL);
        break;
    }
  };

  const onDeleteConfirm = () => {
    if (deleteAction === DELETE_ACTION_TYPE.DELETE_SELECTED) {
      deleteSelectedTransactions();
    } else if (deleteAction === DELETE_ACTION_TYPE.DELETE_ALL) {
      deleteAllTransactions();
    }
    setDeleteAction(undefined);
  };

  const onDeleteCancel = () => {
    setIsLoading(false);
    setDeleteAction(undefined);
  };

  const handleNewButtonAction = async (actionId: string, event: any) => {
    if (
      (actionId === AddNewButtonOptions.AddNew ||
        actionId === AddNewButtonOptions.UploadFromTemplate) &&
      isLocked
    ) {
      showLockedTooltip();
      return;
    }
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedTransaction({
            transaction: undefined,
            type: TransactionType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        {
          setIsLoading(true);
          const data = await downloadTemplate('transactions');

          downloadFile(data, 'standard_transaction_import_template', 'csv');
          setIsLoading(false);
        }
        break;
    }
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile('transactions', uploadedFile);
      setUploadedFile(undefined);
      await setPage(1);
      await fetchTransactions(headerList, 1, false);
      setIsLoading(false);
      setIsUploadComplete(true);
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    }
  };

  const getParams = (
    currentHeaderList: DataGridColDef[],
    currentPage: number
  ) => {
    const {
      [TransactionFilter.TransactionType]: selectedTransactionType,
      [TransactionFilter.InvestorName]: selectedInvestorName,
      [TransactionFilter.FundName]: selectedFundName,
      [TransactionFilter.TransactionDate]: selectedTransactionDate,
      [TransactionFilter.TransactionName]: selectedTransactionName,
    } = getSelectedFilters(currentHeaderList);

    const fromDateFilter = selectedTransactionDate?.[1]?.[0]
      ? format(
          selectedTransactionDate?.[1]?.[0] as unknown as Date,
          API_DATE_FORMAT
        )
      : null;
    const toDateFilter = selectedTransactionDate?.[1]?.[1]
      ? format(
          selectedTransactionDate?.[1]?.[1] as unknown as Date,
          API_DATE_FORMAT
        )
      : null;

    const params: TransactionParams = {
      ...(fromDateFilter
        ? {
            fromDateFilter,
          }
        : {}),
      ...(toDateFilter
        ? {
            toDateFilter,
          }
        : {}),
      offset: (currentPage - 1) * PAGE_SIZE,
      pageSize: PAGE_SIZE,
      searchText: selectedTransactionName?.[0]
        ? selectedTransactionName?.[0]
        : undefined,
      transTypes: selectedTransactionType,
      fundFilter: selectedFundName,
      investorFilter: selectedInvestorName,
    };

    return {
      selectedTransactionType,
      selectedInvestorName,
      selectedFundName,
      params,
    };
  };

  const resetPaginationAndFetch = async () => {
    await setPage(1);
    await fetchTransactions(headerList, 1, false);
  };

  const fetchTransactions = useCallback(
    async (
      currentHeaderList: DataGridColDef[],
      currentPage: number,
      hasPageChange?: boolean,
      isCanceled?: () => boolean
    ) => {
      const {
        selectedTransactionType,
        selectedInvestorName,
        selectedFundName,
        params,
      } = getParams(currentHeaderList, currentPage);

      try {
        if (!hasPageChange) {
          setIsLoadingList(true);
        }
        const statusResponse: Transaction[] = (await getTransactions(
          params
        )) as Transaction[];

        if (
          statusResponse?.length === 0 ||
          statusResponse?.length < PAGE_SIZE
        ) {
          await setIsLastPage(true);
        }
        if (isCanceled?.()) return;
        if (hasPageChange) {
          setTransactionList((currentTransactionList) => [
            ...(currentTransactionList && currentTransactionList?.length > 0
              ? currentTransactionList
              : []),
            ...statusResponse,
          ]);
        } else {
          setTransactionList(statusResponse);
        }
      } catch (e) {
        informationAlert('Error in getting transactions list', 'error');
      }

      setIsLoadingList(false);
      setIsLoading(false);
    },
    [getParams]
  );

  const fetchTransactionCount = async (): Promise<string> => {
    const { params } = getParams(headerList, page);
    const count = await getTransactionsCount(params);

    return count;
  };

  useEffectAsync(
    async (isCanceled) => {
      if (!isEmpty(headerList)) {
        await fetchTransactions(headerList, page as number, false, isCanceled);
      }
    },
    [headerList]
  );

  return {
    transactionList,
    headerList,
    setHeaderList,
    isLoadingList,
    activeHeaderFields,
    handleUpdateHeader,
    handleFilter,
    transactionSelectionModel,
    setTransactionSelectionModel,
    handleOnView,
    onNextPage,
    selectedTransaction,
    page,
    setIsLastPage,
    bulkActions,
    handleBulkAction,
    isLoading:
      isLoading || loadingInvestors || loadingFunds || loadingTransactionTypes,
    toggleIsLocked,
    isLocked,
    lockedTooltipText,
    handleNewButtonAction,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    clearUploadCompleted,
    onTransactionDetailClose,
    resetPaginationAndFetch,
    setSelectedTransaction,
    setUploadedFile,
    deleteAction,
    setDeleteAction,
    onDeleteCancel,
    onDeleteConfirm,
    isSuperAdminOrClientAdmin,
    fetchTransactionCount,
  };
};
