import { PageSummaryItem } from '../../components/Visualizations/VisualDashboard';

export type DashboardWidget = {
  index: number;
  name: string;
  title: string;
  visible: boolean;
  widgetType: WidgetType;
  chart?: JSX.Element;
  display?: boolean;
};

export type ChartWidgets = {
  charts: DashboardWidget[];
  currency: string;
};

export enum WidgetType {
  SummaryCard = 'summary_card',
  EntityTypeByFund = 'entity_type_counts',
  Charts = 'charts',
}

export type SummaryCardItem = {
  title: string;
  name: string;
  index: number;
  value: string;
  visible: boolean;
};

export type SummaryCards = {
  currencyCode: string;
  summaryCards: SummaryCardItem[];
};

export type FundSummaryItem = {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  entityType: string;
  committed: number;
  contributions: number;
  unfunded: number;
  distribution: number;
  value: number;
  fundId?: string;
};

export type CapitalAccountSummaryItem = {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  entityType: string;
  committed: number;
  contributions: number;
  unfunded: number;
  distribution: number;
  value: number;
  fundId?: string;
  inceptionDate?: string;
};

export type SoiSummaryItem = PageSummaryItem & {
  totalCostRealized?: number;
  totalCostUnrealized?: number;
  fairMarketValueUnrealized?: number;
  portCoCount?: number;
  realizedValue?: number;
  unrealizedGainLoss?: number;
  soiPageView?: string;
  quarterAsOf?: string;
  portfolioCompanyId?: string;
  percentCalled?: number;
  name?: string;
  id?: string;
  fundId?: string;
  entityType?: string;
  currencyCode?: string;
  dataProps?: SoiDataProps;
  categoryProps?: SoiCategoryProps;
};

export type SoiDataProps = {
  cost?: number;
  costPerQuantity?: number;
  fairMarketValue?: number;
  initialInvestmentData?: Date | string;
  latestValuationDate?: Date | string;
  moic?: number;
  realizedGainLoss?: number;
  realizedValue?: number;
  shares?: number;
  totalGainLoss?: number;
  unrealizedGainLoss?: number;
  valuePerQuantity?: number;
};

export type SoiCategoryProps = {
  companyName?: string;
  country?: string;
  fund?: string;
  holdingStatus?: string;
  industrySector?: string;
  isPublic?: string;
  portfolioCustomProperties?: any[];
  security?: string;
  securityType?: string;
  currencyCode?: string;
};

export type SummaryCardListItem = {
  name: string;
  title?: string;
  visible?: boolean;
  index?: number;
  value: string;
};

export type PieDataItem = {
  id: string;
  value: number;
  label: string;
  color: string;
};

export type LineDataItem = {
  id: string;
  value: number;
  label: string;
  color: string;
};

export type visualizationsDataItem = {
  currencyCode: string;
  summaryCards?: SummaryCardItem[] | undefined;
  charts?: DashboardWidget[] | undefined;
  showEntityTypeByFund?: boolean;
  typeCountItems?: any;
};
