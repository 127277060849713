import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";

import { setTransactionColumnLock, submitToPcap } from "../../../../../services/workbook.service";
import { DateTimeFormat } from "../../../../../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../../../../../utils/helpers/quarter.helper";
import { DataGridColDef } from "../../../../../utils/types/listItems";
import { SubmitToPcap, SubmitToPcapResponse, SubmitToPcapTransactionsType, TransactionColumn, TransactionColumnType, Workbook, WorkbookFrequencyType } from "../../workbook.type";
import { AllocationsSheetManager } from "../sheets/allocations/AllocationsSheet.Manager";

type TransTypeGridRow = {
    id: string,
    status?: string,
    locked?: boolean,
    submittable: boolean,
    submitted?: boolean,
    pcapTransactions: SubmitToPcapTransactionsType
};

export enum SubmitTypes {
    Transactions = 'transactions', 
    UnlockTransactionTypes = 'Unlock-transaction-types'
}

export const useSubmitToPcapEffect = (allocationsSheetManager: AllocationsSheetManager) => {
    const [submitType, setSubmitType] = useState<SubmitTypes>(SubmitTypes.Transactions);
    const [gridRows, setGridRows] = useState<TransTypeGridRow[]>([]);
    const [refreshGrid, setRefreshGrid]= useState(0);
    const [selectionModel, setSelectionModel] = useState<string[]>([]);
    const [transactionDate, setTransactionDate] = useState(new Date());
    const [minQuarterDate, setMinQuarterDate] = useState(new Date());
    const [maxQuarterDate, setMaxQuarterDate] = useState(new Date());
    const [quarter, setQuarter] = useState<number>();
    const [wbYear, setWbYear] = useState<number>();
    const [frequency, setFrequency] = useState<WorkbookFrequencyType>();
    const [transDateErrorText, setTransDateErrorText] = useState('');
    const [transactionColumns, setTransactionColumns] = useState<TransactionColumn[]>();
    const [processing, setProcessing] = useState(false);    

    useEffect(() => {
        const wbSheetsManager = allocationsSheetManager.workbookSheetsManager;
        const tmpTransCols = wbSheetsManager.allocationsSheetmanager.transactionColumnsManager.transactionColumns
            .filter(tc => 
                tc.quarterNumber === wbSheetsManager.lastQuarter && 
                tc.colType === TransactionColumnType.STANDARD_TRANSACTION_TYPE &&
                !tc.useArkTransactionValues &&
                !tc.isUserColumn);
        
        const qtrStartEnd = wbSheetsManager.frequency === "BY_QUARTER" ? 
            getQuarterStartEndDates(wbSheetsManager.lastQuarter, wbSheetsManager.workbookYear) :
            { startDate: new Date(wbSheetsManager.workbookYear, 0, 1), endDate: new Date(wbSheetsManager.workbookYear, 11, 31) };

        setTransactionColumns(tmpTransCols);
        setQuarter(wbSheetsManager.lastQuarter);
        setFrequency(wbSheetsManager.frequency);
        setTransactionDate(qtrStartEnd.endDate);
        setMinQuarterDate(qtrStartEnd.startDate);
        setMaxQuarterDate(qtrStartEnd.endDate);
        
        const initialGriwRows = tmpTransCols.map((col, index) => {
            const gridRow: TransTypeGridRow = {
                id: index.toString(),
                submittable: false,
                pcapTransactions: {
                    transactionName: col.label,
                    transactionTypeId: col.transTypeId,
                    transactionTypeModels: []
                }
            };
            
            updateGridrowFromTransCol(gridRow, tmpTransCols);

            return gridRow;
        });

        setGridRows(initialGriwRows);
    }, []);

    useEffect(() => {
        gridRows.forEach(gridRow => { updateGridrowFromTransCol(gridRow, transactionColumns!); });
    }, [refreshGrid]);

    function updateGridrowFromTransCol(gridRow: TransTypeGridRow, transactionColumns: TransactionColumn[]) {
        if(processing && gridRow.submittable) {
            gridRow.status = 'Processing...';
            return gridRow;
        }

        const wbSheetsManager = allocationsSheetManager.workbookSheetsManager;
        const transactionColManager = allocationsSheetManager.transactionColumnsManager;
        const col = transactionColumns!.find(transCol => transCol.transTypeId === gridRow.pcapTransactions.transactionTypeId && transCol.quarterNumber === wbSheetsManager.lastQuarter) as TransactionColumn;

        gridRow.locked = col.locked;

        if(col.locked) {
            gridRow.submittable = false;
            gridRow.status = 'Submitted';
            return gridRow;
        } else if (col.transSubmitError) {
            gridRow.submittable = true;
            gridRow.status = 'Error occurred. please try again or contact support';
            
            return gridRow;
        } else {
            gridRow.pcapTransactions = transactionColManager.getTransactionTypeValues(wbSheetsManager.lastQuarter, col.transTypeId);
                
            if(gridRow.pcapTransactions.transactionTypeModels.length === 0) {
                gridRow.submittable = false;
                gridRow.status = 'No transactions to submit';
                return gridRow;
            }

            const reconcileStatus = transactionColManager.getTransactionTypeGLRconcileStatus(col.quarterNumber, col.transTypeId);

            if(!reconcileStatus) {
                gridRow.submittable = false;
                gridRow.status = 'Allocated amounts do not equal Fund total';
                return gridRow;
            } 
        }

        gridRow.submittable = true;
        gridRow.status = 'Ready to Submit';
    }

    const headerList:DataGridColDef[] = 
    [
      {
        field: "pcapTransactions.transactionName",
        headerName: "Transaction Type",
        hide: false,
        index: 1,
        type: "string",
        sortable: false,
        align: "left",
        width: 80,
        disableReorder: true
      },
      {
        field: "status",
        headerName: "Status",
        hide: false,
        index: 2,
        type: "string",
        sortable: false,
        align: "left",
        width: 250,
        disableReorder: true,
      }
    ];

    function handleTransactionDateChange(value: any) {
        let hasError: boolean;

        if(value && value.toString() !== 'Invalid Date') {
            const dateValue = value as Date;

            if(dateValue.getFullYear().toString().length !== 4) {
                hasError = true;
            } else {
                hasError = false;
            }
        } else {
            hasError = true;
        }

        if(hasError) {
            const minDate = DateTimeFormat.shortDate((minQuarterDate));
            const maxDate = DateTimeFormat.shortDate((maxQuarterDate));

            setTransDateErrorText(`Must be between ${ minDate } and ${ maxDate }`);
        } else {
            setTransactionDate(value as Date);
            setTransDateErrorText('');
        }        
    }

    function handleSubmitButton() {
        if(submitType === SubmitTypes.Transactions) {
            handleSubmitToPcap();
        } else {
            handleUnlockTransactions();
        }

        allocationsSheetManager.workbookSheetsManager.beginWorkbookSaving();

        setSelectionModel([]);
    }

    async function handleSubmitToPcap() {
        const transDateStr = `${transactionDate.getUTCFullYear()}-${transactionDate.getUTCMonth()+1}-${transactionDate.getUTCDate()}`;

        const submittableRows = gridRows.filter(row => row.submittable);
        const gridRowsToSubmit = submittableRows.filter(row => selectionModel.find(selId => selId === row.id));
        const wbSheetsManager = allocationsSheetManager.workbookSheetsManager;
        
        let refreshCount = refreshGrid+1;

        const pcapPayload: SubmitToPcap = {
            workbookId: wbSheetsManager.workbookId!,
            fundId: wbSheetsManager.fund.id,
            workbookTransactionModels: []
        };

        gridRowsToSubmit.forEach(gridRow => {
            pcapPayload.workbookTransactionModels.push(gridRow.pcapTransactions);
        });

        setGridRows(cloneDeep(gridRows));
        setRefreshGrid(refreshCount++);
        setProcessing(true);

        let apiResponse: SubmitToPcapResponse[]|undefined;

        try {
            apiResponse = await submitToPcap(transDateStr, pcapPayload);
        } catch {
            apiResponse = undefined;
        }

        gridRowsToSubmit.forEach(gridRow => {
            const transCol = transactionColumns!
                .find(col => col.quarterNumber === wbSheetsManager.lastQuarter && col.transTypeId === gridRow.pcapTransactions.transactionTypeId) as TransactionColumn;
            
            const typeResponse = apiResponse?.find(r => r.type === transCol.label);

            if(apiResponse && typeResponse?.success) {
                transCol.locked = true;
                transCol.transSubmitError = false;
                gridRow.submitted = true;
            } else {
                transCol.locked = false;
                transCol.transSubmitError = true;
                gridRow.submitted = false;
            }

            allocationsSheetManager.transactionColumnsManager.updateColumnValues(transCol);

        });

        setGridRows(cloneDeep(gridRows));
        setRefreshGrid(refreshCount++);
        setProcessing(false);

        setTimeout(() => {setRefreshGrid(refreshCount++);}, 1);//force refresh
    }

    function handleUnlockTransactions() {
        const wbSheetsManager = allocationsSheetManager.workbookSheetsManager;
        const submittableRows = gridRows.filter(row => row.locked);
        const gridRowsToUnlock = submittableRows.filter(row => selectionModel.find(selId => selId === row.id));

        gridRowsToUnlock.forEach(gridRow => {
            const transCol = transactionColumns!
                .find(transCol => transCol.quarterNumber === wbSheetsManager.lastQuarter && transCol.transTypeId === gridRow.pcapTransactions.transactionTypeId) as TransactionColumn;

            transCol.locked = false;
            transCol.transSubmitError = false;

            setTransactionColumnLock(wbSheetsManager.workbookId as string, gridRow.pcapTransactions.transactionTypeId, wbSheetsManager.lastQuarter);
            allocationsSheetManager.transactionColumnsManager.updateColumnValues(transCol);
        });

        setGridRows(cloneDeep(gridRows));
        setRefreshGrid(refreshGrid+1);
    }

    return {
        submitType, setSubmitType,
        headerList, 
        gridRows, selectionModel, setSelectionModel,
        handleSubmitButton,
        transactionDate, handleTransactionDateChange, minQuarterDate, maxQuarterDate, transDateErrorText, quarter, wbYear, frequency,
        processing
    };
};