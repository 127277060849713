import { Autocomplete, CircularProgress, TextField, Typography } from "@mui/material";
import React from 'react';

import usePortfolioCompanyFilterEffect from "./usePortfolioCompanyFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (portfolioCompany: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
  refetch?: boolean;
}

type PortfolioCompanyValue = any | null | undefined;

export const PortfolioCompanyFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = 'small',
  id = 'portfolio_company_filter',
  textFieldID = 'txt_portfolio_company',
  refetch = false
}: Props) => {
  const {
    portfolioCompanyList,
    loading,
  } = usePortfolioCompanyFilterEffect(refetch);

  const onSelectChange = (_: any, portfolioCompany: any) => {
    if (!portfolioCompany) {
      return;
    }
    onChange?.(idSelectOnly ? (portfolioCompany?.id ?? '') : portfolioCompany);
  };

  const selectedValue: PortfolioCompanyValue = idSelectOnly ? portfolioCompanyList?.find((item: any) => item.id === value) ?? null : value as PortfolioCompanyValue;

  return (
    <Autocomplete
      id={id}
      options={portfolioCompanyList || []}
      getOptionLabel={(option: any) => option?.name ?? ''}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      size={size}
      disabled={disabled || loading}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            label={placeholder}
            id={textFieldID}
            error={!!error}
            helperText={error?.message ?? ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default PortfolioCompanyFilter;