import { DateRange } from '@mui/x-date-pickers-pro';
import { Dispatch, SetStateAction } from 'react';

import { ProgressModal } from '../../../../../components';
import { DataWrapperBox } from '../../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import ReportGrid from '../../../../../components/ReportGrid/ReportGrid';
import {
  GridData,
  GridRow,
  ValueFieldDef,
} from '../../../../../components/ReportGrid/ReportGrid.types';
import { DateColumnInfo, TrialBalanceDetailsParams } from '../shared';
import { useIncomeStatement } from './IncomeStatement.hooks';

interface Props {
  attributes: any;
  dateRange: DateRange<Date>;
  funds: any;
  isLoading: boolean;
  params: TrialBalanceDetailsParams;
  reportView: string;
  setIsLoading: (isLoading: boolean) => void;
  setMainReportListData: (gridDate: GridData) => void;
  suppressIfZero: boolean;
  viewFilters: any;
  handleValueLinkButtonClick: (
    gridRow: GridRow,
    valueFieldOrder: ValueFieldDef,
    dateColInfo: DateColumnInfo[],
    isCustom: boolean,
    currentDecimals: number
  ) => void;
  onDataGridChange: (gridData: GridData, exportName: string) => void;
  handleDateRangeChange: (
    dateRange: DateRange<Date>,
    clearGrid: boolean
  ) => void;
  fetchGlReport: boolean;
  setFetchGlReport: Dispatch<SetStateAction<boolean>>;
}

export const IncomeStatement: React.FC<Props> = ({
  attributes,
  dateRange,
  funds,
  isLoading,
  params,
  reportView,
  setIsLoading,
  setMainReportListData,
  suppressIfZero,
  viewFilters,
  handleValueLinkButtonClick,
  onDataGridChange,
  handleDateRangeChange,
  fetchGlReport,
  setFetchGlReport,
}) => {
  const {
    categoryFieldOrder,
    currentCurrency,
    currentDecimals,
    dataFieldOrder,
    footerRollUp,
    handleCategoryFieldOrderChange,
    handleDataFieldOrderChange,
    handleDataGridChange,
    handleValueClick,
    hideUseerCategorySelector,
    pinActionColumn,
    reportGridBaseData,
    rollupMode,
    setShowGroups,
    setShowMonthlyGroup,
    setShowQuarterlyGroup,
    setShowYearlyGroup,
    showGroups,
    showMonthlyGroup,
    showQuarterlyGroup,
    showYearlyGroup,
  } = useIncomeStatement(
    params,
    reportView,
    suppressIfZero,
    dateRange,
    funds,
    attributes,
    viewFilters,
    setIsLoading,
    handleValueLinkButtonClick,
    onDataGridChange,
    handleDateRangeChange,
    fetchGlReport
  );

  return (
    <ReportGrid
      id="gl_reports_balancesheet_custom_grid"
      reportGridBaseData={reportGridBaseData}
      userCategoryFieldOrder={categoryFieldOrder}
      userColDefs={dataFieldOrder}
      noDataMessage={isLoading ? '' : undefined}
      summaryRowCategoryName="Totals"
      hideUseerCategorySelector={hideUseerCategorySelector}
      rollupMode={rollupMode}
      categoryColumnWidth={600}
      onDataGridChange={handleDataGridChange}
      onCategoryFieldOrderChange={handleCategoryFieldOrderChange}
      onDataFieldOrderChange={handleDataFieldOrderChange}
      onValueLinkButtonClick={handleValueClick}
      reportView={reportView}
      suppressIfZero={suppressIfZero}
      currentDecimals={currentDecimals}
      currentCurrency={currentCurrency}
      pinActionColumn={pinActionColumn}
      footerRollUp={footerRollUp}
    />
  );
};
