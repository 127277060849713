import { TextFieldProps } from "@mui/material";
import React from "react";

import StyledBootstrapInput from "./BootstrapInput.styles";

type BootstrapInputProps = TextFieldProps & {
  label: string,
  id: string,
  placeholder?: string,
}

const BootstrapInput = ({
  label,
  id,
  placeholder = '',
  fullWidth = false,
  ...rest
}: BootstrapInputProps ): React.ReactElement => {
  return (
    <StyledBootstrapInput
      {...rest}
      placeholder={placeholder}
      id={id}
      fullWidth={fullWidth}
      label={label}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default BootstrapInput;