import IconDownload from '../../../assets/images/icons/icon_download.svg';
import IconNewCircle from '../../../assets/images/icons/icon_new_circle.svg';
import IconUpload from '../../../assets/images/icons/icon_upload.svg';
import { ButtonOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { AddNewButtonOptions } from '../../../utils/types/common.type';
import { DataGridColDef } from '../../../utils/types/listItems';

export const NewBankRuleOptions: ButtonOptions[] = [
  {
    label: 'New Bank Rule Entry',
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
  {
    label: 'Upload From Template',
    id: AddNewButtonOptions.UploadFromTemplate,
    icon: IconUpload,
    type: 'upload',
  },
  {
    label: 'Download Template',
    id: AddNewButtonOptions.DownloadTemplate,
    icon: IconDownload,
  },
];

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: 'order',
    headerName: 'Order',
    hide: false,
    index: 1,
    type: 'string',
    sortable: false,
  },
  {
    field: 'ruleName',
    headerName: 'Rule Name',
    hide: false,
    index: 2,
    type: 'string',
    sortable: false,
  },
  {
    field: 'bank',
    headerName: 'Bank',
    hide: false,
    index: 3,
    type: 'string',
    sortable: false,
  },
  {
    field: 'appliedTo',
    headerName: 'Applied To',
    hide: false,
    index: 4,
    type: 'string',
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    hide: false,
    index: 5,
    type: 'string',
    sortable: false,
  },
  {
    field: 'action',
    headerName: '',
    hide: false,
    index: 6,
    type: 'action',
    sortable: false,
  },
];
