import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangeValidationError } from '@mui/x-date-pickers-pro/internal/hooks/validation/useDateRangeValidation';
import { isValid } from 'date-fns';
import { cloneDeep, some } from 'lodash';
import { response } from 'msw';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  GridData,
  GridRow,
  ValueFieldDef,
} from '../../../components/ReportGrid/ReportGrid.types';
import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import {
  addReportTemplate,
  bulkUpdateAccounts,
  createReportTemplate,
  exportFsPackage,
  getAllArkAccounts,
  getArkLedgers,
  getAttributes,
  getReportTemplates,
  updateReportTemplate,
} from '../../../services/arkGL.service';
import { getFundsList } from '../../../services/fund.service';
import downloadFile from '../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { getQuarterStartEndDateByDate } from '../../../utils/helpers/quarter.helper';
import { awaitReactUpdate } from '../../../utils/helpers/timeoutFunctions';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  AccountCategoryProps,
  FSDisplayCategoryProps,
} from '../../../utils/types/arkGLFinancialStatementsTypes';
import { FilterItem } from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import {
  useScheduleOfInvestments,
  ViewTypes,
} from '../../fund/scheduleOfInvestments/ScheduleOfInvestments.hooks';
import { FilterSelection } from '../../fund/scheduleOfInvestments/ScheduleOfInvestments.types';
import {
  CreditRange,
  DateColumnInfo,
  DebitRange,
  FSDisplayNotSet,
  GridCallerData,
  netIncomeTotalCatStr,
  netInvestmentIncomeCatStr,
  netRealizedCatStr,
  netUnrealizedCatStr,
  ReportDetailsParams,
  retainedEarningsTotalCatStr,
  retainedInvestmentIncomeCatStr,
  retainedRealizedCatStr,
  retainedUnrealizedCatStr,
} from '../reports/glReports/shared';

const financialStatementsList = [
  { id: 'balance_sheet_fs_mapping', label: 'Balance Sheet' },
  { id: 'income_statement_fs_mapping', label: 'Income Statement' },
  { id: 'Unrealized_SOI', label: 'Unrealized SOI' },
  { id: 'Realized_SOI', label: 'Realized SOI' },
  {
    id: 'statement_of_changes_in_partners',
    label: 'Statement of Changes in Partners',
  },
  { id: 'statement_of_cash_flows', label: 'Statement of Cash Flows' },
];

const FS_NAME_CHANGE_SUCCESS = 'Success changing FS name';
const FS_NAME_CHANGE_ERROR = 'Error changing FS name';

export const FS_DISPLAY_NOT_SET = '(FS Display Not set)';

export enum FsViews {
  balanceSheet = 'balance_sheet_fs_mapping',
  incomeStatement = 'income_statement_fs_mapping',
  unrealizedSOI = 'Unrealized_SOI',
  realizedSOI = 'Realized_SOI',
  statementOfChangesInPartners = 'statement_of_changes_in_partners',
  statementOfCashFlows = 'statement_of_cash_flows',
}

export enum PackageTypes {
  balanceSheet = 'BALANCE_SHEET',
  incomeStatement = 'INCOME_STATEMENT',
}

const defaultStartEndDates = getQuarterStartEndDateByDate(new Date());
const defaultEndDateToday = new Date();
const currentYear = new Date().getFullYear();
const incomeStateStartDate: Date = new Date(`${currentYear}-01-01`);
const incomeDefaultDate: DateRange<Date> = [
  new Date(
    incomeStateStartDate.getUTCFullYear(),
    incomeStateStartDate.getUTCMonth(),
    incomeStateStartDate.getUTCDate()
  ),
  defaultEndDateToday,
];

type FsPackage = {
  id: string;
  name: string;
};

type Account = {
  id: string;
  name: string;
  number: string;
  parentId: string;
  fundId: string;
  attributeId: string;
  fsDisplayName: string;
};

type ReportAccount = {
  accountId: string;
  accountName: string;
  accountNo: string;
  fundId: string;
  attributeId: string;
  fsMappingId: string;
  fsName: string;
  state: string;
  startBalance: number;
  endBalance: number;
  totalAmount: number;
  lineItems: ReportLineItem[];
  parentAccountId: string;
  childAccounts: ReportAccount[];
  decimals: number;
  categoryProps?: AccountCategoryProps | FSDisplayCategoryProps;
};

type ReportLineItem = {
  amount: number;
  date: Date;
  ledgerId: string;
  ledgerName: string;
  journalEntryNum: number;
  adjustingJournalEntry: boolean;
  memo: string;
  type?: string;
  state?: string;
  subtract?: boolean;
};

enum GlStatuses {
  DRAFT = 'Draft',
  POSTED = 'Posted',
}

export type TrialBalanceDetailsParams = {
  startDate: Date;
  endDate: Date;
  accounts: string[];
  ledgerIDs: string[];
  glStatus: GlStatuses[];
  attributes: string[];
  fsName?: string;
  summaryRow?: boolean;
  isCustom?: boolean;
};

export const useFinancialStatements = () => {
  const { state, informationAlert } = useContext(AppContext);
  const { fundId } = useParams<{ fundId: string }>();
  const clientId = state.loginUser.clientId;

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [allReportAccounts, setAllReportAccounts] = useState<ReportAccount[]>(
    []
  );
  const [attributes, setAttributes] = useState<any[]>([]);
  const [attributeFilterDisabled, setAttributeFilterDisabled] = useState(false);
  const [changesInPartners, setChangesInPartners] = useState(false);
  const [changesInPartnersParams, setChangesInPartnersParams] = useState<any>();
  const [confirmNameChangeDialog, setConfirmNameChangeDialog] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState('');
  const [currFsPackage, setCurrFsPackage] = useState<any>(null);
  const [currentTemplateType, setCurrentTemplateType] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    defaultStartEndDates.startDate,
    defaultEndDateToday,
  ]);
  const [editModeActivated, setEditModeActivated] = useState<boolean>(false);
  const [filteredAccounts, setFilteredAccounts] = useState<FilterItem[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<FilterItem[]>(
    []
  );
  const [filteredLedgers, setFilteredLedgers] = useState<FilterItem[]>([]);
  const [financialStatementsView, setFinancialStatementsView] = useState('');
  const [financialStatementsParams, setFinancialStatementsParam] =
    useState<TrialBalanceDetailsParams>();
  const [fsDetailsApiParams, setFSDetailsApiParams] =
    useState<ReportDetailsParams>();
  const [fsPackageNewName, setFsPackageNewName] = useState('');
  const [fsPackageValue, setFsPackageValue] = useState<any>(null);
  const [funds, setFunds] = useState<any[]>([]);
  const [fundsFilter, setFundFilter] = useState<FilterItem[]>([]);
  const [generalAttributes, setGeneralAttributes] = useState<string[]>([]);
  const [gridData, setGridData] = useState<GridData>();
  const [gridDataBs, setGridDataBs] = useState<GridData>();
  const [gridDataIs, setGridDataIs] = useState<GridData>();
  const [hideAllZeros, setHideAllZeros] = useState(false);
  const [invalidSelectedFunds, setInvalidSelectedFunds] = useState(false);
  const [invalidSelectedLedgers, setInvalidSelectedledgers] = useState(false);
  const [dateRangeError, setDateRangeError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [ledgers, setLedgers] = useState<any[]>([]);
  const [level1Clicked, setLevel1Clicked] = useState<boolean>(false);
  const [mainFSListData, setMainFSListData] = useState<GridData>();
  const [netRow, setNetRow] = useState(false);
  const [packageExportEnabled, setPackageExportEnabled] =
    useState<boolean>(false);
  const [retainedRow, setRetainedRow] = useState(false);
  const [roundedReportActivated, setRoundedReportActivated] = useState(false);
  const [runFinancialStatementsView, setRunFinancialStatementsView] =
    useState('');
  const [statusFilter, setStatusFilter] = useState<FilterItem[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
  const [selectedFunds, setSelectedFunds] = useState<string[]>([]);
  const [selectedLedgers, setSelectedLedgers] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<GlStatuses[]>([]);
  const [soiView, setSOIView] = useState<boolean>(false);
  const [reportView, setReportView] = useState<boolean>(false);
  const [summaryRowClicked, setSummaryRowClicked] = useState<boolean>(false);
  const [suppressIfZero, setSuppressIfZero] = useState(true);
  const [tempAttribsFilterResponse, setTempAttribsFilterResponse] = useState<
    FilterItem[]
  >([]);
  const [viewFiltersState, setViewFiltersState] = useState<any>({});
  const [zeroSumStartBalance, setZeroSumStartBalance] =
    useState<boolean>(false);

  const [fsNameChangeParams, setFsNameChangeParams] = useState<any>({});
  const [prevFsNameParam, setPrevFsNameParam] = useState<string>('');
  const [fsNameChangeRow, setFsNameChangeRow] = useState<GridRow>();
  const [fsNameChangeRows, setFsNameChangeRows] = useState<GridRow[]>([]);
  const [triggerBuilder, setTriggerBuilder] = useState(false);
  const [fsAddPackage, setFsAddPackage] = useState<boolean>(false);
  const [fsPackagesResponse, setFsPackagesResponse] = useState([]);
  const [fsPackagesList, setFsPackagesList] = useState<FilterItem[]>([]);
  const [fsSavePackage, setFsSavePackage] = useState<boolean>(false);
  const [fsSaveFieldOrder, setFsSaveFieldOrder] = useState<any>([]);
  const [fsSaveCategoryOrder, setFsSaveCategoryOrder] = useState<any>([]);
  const [fsPackageBuilder, setFsPackageBuilder] = useState<boolean>(false);
  const [fsUpdatePackage, setFsUpdatePackage] = useState<boolean>(false);
  const [fsPackageSaveName, setFsPackageSaveName] = useState<string>('');
  const [fsUpdateColumnOrder, setFsUpdateColumnOrder] = useState<any[]>([]);
  const [fsColumnBuilder, setFsColumnBuilder] = useState<boolean>(false);
  const [isPackageNameValid, setIsPackageNameValid] = useState<boolean>(true);
  const [fsPackageFlag, setFsPackageFlag] = useState<boolean>(false);
  const [fsGridDataFlag, setFsGridDataFlag] = useState<boolean>(false);
  const [userClickAway, setUserClickAway] = useState<boolean>(false);
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const [exitEvent, setExitEvent] = useState<string>('');
  const [exitValue, setExitValue] = useState<any>('');
  const [nextPackage, setNextPackage] = useState<any>();
  const [soiFilterSelection, setSoiFilterSelection] = useState<FilterSelection>(
    {
      portCo: [],
      fund: [],
    }
  );
  const [statementOfChangeData, setStatementOfChangeData] =
    useState<boolean>(false);
  const [fetchGlReport, setFetchGlReport] = useState<boolean>(false);

  const dataWrapperRef = useRef<HTMLDivElement>(null);
  const viewListRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef([]);

  const fsView = true;
  const glReportView = false;

  const {
    isLoadingList,
    categoryFieldOrder,
    currSOIview,
    setCurrSOIview,
    handleSoiViewChange,
    soiGridData,
    handleColumnOrderChange,
    setCategoryFieldOrder,
    setDataFieldOrder,
    dataFieldOrder,
    dateFilter: soiDateFilter,
    setDateFilter: setSoiDateFilter,
    setRebuildSoiGridData,
    saveDataFieldOrder,
    saveCategoryFieldOrder,
    filterSelection,
  } = useScheduleOfInvestments(
    fsView,
    financialStatementsView === FsViews.unrealizedSOI ||
      financialStatementsView === FsViews.realizedSOI,
    glReportView,
    setFsSaveFieldOrder,
    setFsSaveCategoryOrder,
    setFsPackageFlag,
    soiFilterSelection,
    roundedReportActivated
  );

  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);
    setIsInitialLoading(true);

    const allResponses = await getFilterData(isCanceled);

    if (isCanceled() || !allResponses) return;

    const reportTemplates: any = await getReportTemplates();

    let options: any = [];

    reportTemplates.items.map((fsPackage: any) => {
      options.push({
        id: fsPackage.id,
        name: fsPackage.name,
      });
    });

    options = options.sort((a: any, b: any) => a.name.localeCompare(b.name));

    setFsPackagesList(options);
    setFsPackagesResponse(reportTemplates.items);

    const { tempLedgers, tempAccounts, tempAttribsFilter } = allResponses;
    const tempStatuses = [
      { id: 'DRAFT', name: GlStatuses.DRAFT },
      { id: 'POSTED', name: GlStatuses.POSTED },
    ] as FilterItem[];

    const selectedStatusIDs = tempStatuses.map((item: any) => item.id);

    setSelectedAttributes(tempAttribsFilter.map((item: any) => item.id));
    setFilteredAttributes(tempAttribsFilter);
    setTempAttribsFilterResponse(tempAttribsFilter);
    setStatusFilter(tempStatuses);
    setSelectedStatus(selectedStatusIDs);
    setFinancialStatementsView(FsViews.balanceSheet);

    if (fundId) {
      setSelectedFunds([fundId]);
      setLedgerAndAccountFilters(
        [fundId],
        tempLedgers,
        tempAttribsFilter.map((item: any) => item.id),
        [],
        tempAccounts,
        []
      );
    }

    setIsLoading(false);
    setIsInitialLoading(false);
  }, []);

  let viewFilters: {
    selectedAttributes: any[];
    filteredAttributesFilter: FilterItem[];
    rollupMode: boolean;
    hideUseerCategorySelector: boolean;
  };

  useEffectAsync(
    async (isCanceled) => {
      setIsLoading(true);

      switch (financialStatementsView) {
        case FsViews.balanceSheet:
          setDateRange([null, defaultEndDateToday]);
          viewFilters = buildBalanceSheetFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          setSelectedAttributes(viewFilters.selectedAttributes);
          setLedgerAndAccountFilters(
            selectedFunds,
            ledgers,
            viewFilters.selectedAttributes,
            selectedLedgers,
            accounts,
            []
          );
          setViewFiltersState(viewFilters);
          setReportView(true);
          setCurrentTemplateType('BALANCE_SHEET');
          break;
        case FsViews.incomeStatement:
          setDateRange(incomeDefaultDate);
          viewFilters = buildIncomeStatementFilters();
          setHideAllZeros(true);
          setAttributeFilterDisabled(true);
          setSelectedAttributes(viewFilters.selectedAttributes);
          setLedgerAndAccountFilters(
            selectedFunds,
            ledgers,
            viewFilters.selectedAttributes,
            selectedLedgers,
            accounts,
            []
          );
          setViewFiltersState(viewFilters);
          setReportView(true);
          setCurrentTemplateType('INCOME_STATEMENT');
          break;
        case FsViews.statementOfChangesInPartners:
          setDateRange(incomeDefaultDate);
          setAttributeFilterDisabled(false);
          setViewFiltersState({});
          setEditModeActivated(false);
          setCurrentTemplateType('STATEMENT_OF_CHANGES');
          break;
        case FsViews.statementOfCashFlows:
          setAttributeFilterDisabled(false);
          setViewFiltersState({});
          setReportView(false);
          setEditModeActivated(false);
          setCurrentTemplateType('STATEMENT_OF_CASH_FLOWS');
          break;
        case FsViews.realizedSOI:
          setCurrSOIview(financialStatementsView);
          handleSoiViewChange(ViewTypes.Realized);
          setViewFiltersState({});
          setReportView(false);
          setEditModeActivated(false);
          setCurrentTemplateType('REALIZED_SOI');
          break;
        case FsViews.unrealizedSOI:
          setCurrSOIview(financialStatementsView);
          handleSoiViewChange(ViewTypes.Unrealized);
          setViewFiltersState({});
          setReportView(false);
          setEditModeActivated(false);
          setCurrentTemplateType('UNREALIZED_SOI');
          break;
      }

      await awaitReactUpdate();
      runFinancialStatementsView && validFilters
        ? setRunFinancialStatementsView(financialStatementsView)
        : setRunFinancialStatementsView('');

      soiView ? setRunFinancialStatementsView(financialStatementsView) : '';

      !soiView && validFilters ? '' : setIsLoading(false);
      !soiView && !validFilters ? setIsLoading(false) : '';
    },
    [financialStatementsView]
  );

  useEffect(() => {
    const fsParams: TrialBalanceDetailsParams = {
      startDate: dateRange[0]!,
      endDate: dateRange[1]!,
      accounts: selectedAccounts,
      glStatus: selectedStatus,
      ledgerIDs: selectedLedgers,
      attributes: selectedAttributes,
    };

    setFinancialStatementsParam(fsParams);
  }, [
    selectedAccounts,
    selectedAttributes,
    selectedFunds,
    selectedLedgers,
    selectedStatus,
    dateRange,
  ]);

  useEffect(() => {
    if (changesInPartners) {
      const changesInPartnersCapitalParams = {
        startDate: dateRange[0]
          ? DateTimeFormat.getReversedDate(dateRange[0])
          : '',
        endDate: dateRange[1]
          ? DateTimeFormat.getReversedDate(dateRange[1])
          : '',
        fundIds: selectedFunds,
        pageSize: 500000,
      };

      setChangesInPartnersParams(changesInPartnersCapitalParams);
    }
  }, [selectedFunds, dateRange]);

  function buildBalanceSheetFilters() {
    const defaultAttribFilters = [
      'Assets',
      'Income',
      'Expense',
      'Gain/Loss',
      'Liabilities',
      'Partners Capital',
    ];
    const generalAttribFilters = ['Assets', 'Liabilities', 'Partners Capital'];

    const tempFilteredAttribs = tempAttribsFilterResponse.filter((a) =>
      defaultAttribFilters.find((df) => a.name === df)
    );
    const attributeIds = tempFilteredAttribs.map((item) => item.id);
    const generalAttribs = tempAttribsFilterResponse.filter((attribute) =>
      generalAttribFilters.find((gf) => attribute.name === gf)
    );
    const generalAttributeIds = generalAttribs.map((item) => item.id);

    setGeneralAttributes(generalAttributeIds);

    return {
      selectedAttributes: attributeIds,
      filteredAttributesFilter: tempFilteredAttribs,
      rollupMode: false,
      hideUseerCategorySelector: true,
    };
  }

  function buildIncomeStatementFilters() {
    const defaultAttribFilters = ['Expense', 'Gain/Loss', 'Income'];
    const tempFilteredAttribs = tempAttribsFilterResponse.filter((a) =>
      defaultAttribFilters.find((df) => a.name === df)
    );
    const attributeIds = tempFilteredAttribs.map((item) => item.id);

    return {
      selectedAttributes: attributeIds,
      filteredAttributesFilter: tempFilteredAttribs,
      rollupMode: false,
      hideUseerCategorySelector: true,
    };
  }

  async function getFilterData(isCanceled: () => boolean) {
    let tempFunds: any;
    let tempFundFilters: FilterItem[];
    let tempLedgers: any[];
    let tempAccounts: Account[];
    let attribsResponse: any[];

    if (
      !funds?.length ||
      !ledgers?.length ||
      !accounts?.length ||
      !attributes?.length
    ) {
      const allResponses = await Promise.all([
        getFundsList(clientId),
        getAttributes(),
        getAllArkAccounts(),
        getArkLedgers(),
      ]);

      if (isCanceled()) return;

      const fundsApiResponse = allResponses[0];
      const attributesApiResponse = allResponses[1];
      const accountsApiResponse = allResponses[2];
      const legdersApiResponse = allResponses[3];

      // *********  funds **************
      tempFunds = fundsApiResponse;

      tempFundFilters = tempFunds
        .sort((a: any, b: any) => (a.fund.name < b.fund.name ? -1 : 1))
        .map((item: any) => {
          return { id: item.fund.id, name: item.fund.name } as FilterItem;
        }) as FilterItem[];

      if (fundId) {
        const fundFilter: any = tempFundFilters.find(
          (fund) => fund.id === fundId
        );
        const tempFund = tempFunds.find((fund: any) => fund.fund.id === fundId);

        setFunds([tempFund]);
        setFundFilter([fundFilter]);
      } else {
        setFunds(tempFunds);
        setFundFilter(tempFundFilters);
      }

      // *********  ledgers **************
      tempLedgers = (legdersApiResponse.items as any[]).sort((a, b) =>
        a.name < b.name ? -1 : 1
      );

      setLedgers(tempLedgers);

      // *********  accounts **************
      tempAccounts = accountsApiResponse.items as Account[];
      tempAccounts
        .filter((x) => !x.fsDisplayName)
        .forEach((x) => (x.fsDisplayName = FSDisplayNotSet));
      tempAccounts = tempAccounts.sort((a, b) => (a.name < b.name ? -1 : 1));

      setAccounts(tempAccounts);

      // *********  attibutes **************
      attribsResponse = attributesApiResponse.items;
      setAttributes(attribsResponse);
    } else {
      tempFunds = funds;
      tempFundFilters = fundsFilter;
      tempLedgers = ledgers;
      tempAccounts = accounts;
      attribsResponse = attributes;
    }

    const tempAttribsFilter = attribsResponse
      .sort((a, b) => (a.type < b.type ? -1 : 1))
      .map((item: any) => {
        return { id: item.id, name: item.type } as FilterItem;
      });

    return {
      tempFunds,
      tempFundFilters,
      tempLedgers,
      tempAccounts,
      tempAttribsFilter,
    };
  }

  function handleAccountFilterChange(_filter: any, accountIds: string[]) {
    setRunFinancialStatementsView('');
    if (fsPackageValue) setFsPackageFlag(true);
    setSelectedAccounts(accountIds);
    // setGridData(undefined);
    setFsGridDataFlag(false);
  }

  function handleAttributeFilterChange(_filter: any, attributeIds: string[]) {
    setRunFinancialStatementsView('');
    if (fsPackageValue) setFsPackageFlag(true);
    setSelectedAttributes(attributeIds);
    setLedgerAndAccountFilters(
      selectedFunds,
      ledgers,
      attributeIds,
      selectedLedgers,
      accounts,
      []
    );
    // setGridData(undefined);
    setFsGridDataFlag(false);
  }

  function handleStatusFilterChange(_filter: any, statusIds: GlStatuses[]) {
    setRunFinancialStatementsView('');
    if (fsPackageValue) setFsPackageFlag(true);
    setSelectedStatus(statusIds);
    // setGridData(undefined);
    setFsGridDataFlag(false);
  }

  function handleDateRangeChange(dateRange: DateRange<Date>, clearGrid = true) {
    if (fsPackageValue) setFsPackageFlag(true);
    if (clearGrid) {
      setRunFinancialStatementsView('');
      setFsGridDataFlag(false);
    }

    setDateRange(dateRange);
  }

  function handleLedgerFilterChange(_filter: any, ledgerIDs: string[]) {
    setRunFinancialStatementsView('');
    if (fsPackageValue) setFsPackageFlag(true);
    let invalid = false;

    if (ledgerIDs.length) {
      const firstSelLedger = ledgers.find((l) => l.id === ledgerIDs[0]);

      ledgerIDs.every((lId) => {
        const ledger = ledgers.find((l) => l.id === lId);

        if (firstSelLedger.currency === ledger.currency) {
          return true;
        } else {
          invalid = true;
          return false;
        }
      });
    }

    const firstSelLedgerId = ledgerIDs?.length ? ledgerIDs[0] : undefined;
    const gl = ledgers.find((l) => l.id === firstSelLedgerId);

    setCurrentCurrency(gl?.currency ?? '');
    setInvalidSelectedledgers(invalid);
    setSelectedLedgers(ledgerIDs);
    // setGridData(undefined);
    setFsGridDataFlag(false);
  }

  function setLedgerAndAccountFilters(
    selectedFunds: string[],
    ledgers: any[],
    selectedAttributes: string[],
    selectedLedgers: string[],
    accounts: Account[],
    selectedAccounts: string[]
  ) {
    const newLedgerFilter = ledgers.filter((item) =>
      selectedFunds.includes(item.fundId)
    );
    const tempLedgers = newLedgerFilter
      .sort((a, b) => (a.currency < b.currency ? -1 : 1))
      .map((item) => {
        return {
          id: item.id,
          name: `${item.name} (${item.currency})`,
        } as FilterItem;
      });

    setFilteredLedgers(tempLedgers);
    setSelectedLedgers(selectedLedgers);

    const newAccountFilter1 = accounts.filter((item) =>
      selectedFunds.includes(item.fundId)
    );

    const newAccountFilter2 = newAccountFilter1.filter((item) =>
      selectedAttributes.includes(item.attributeId)
    );

    const tempFilteredAccounts = newAccountFilter2.map((item) => {
      return { id: item.id, name: item.name } as FilterItem;
    });

    const newSelAcctIDs = tempFilteredAccounts.map((item) => item.id);

    setFilteredAccounts(tempFilteredAccounts);
    setSelectedAccounts(newSelAcctIDs);
    // setGridData(undefined);
    setFsGridDataFlag(false);
  }

  function handleFundFilterChange(
    _filter: any,
    fundIDs: string[],
    selectionType: any,
    automatic?: boolean
  ) {
    setRunFinancialStatementsView('');
    if (fsPackageValue && !automatic) setFsPackageFlag(true);

    let invalid = false;

    if (
      financialStatementsView === FsViews.statementOfChangesInPartners &&
      fundIDs.length
    ) {
      const firstSelectedFund = funds.find(
        (fund) => fund.fund.id === fundIDs[0]
      );

      fundIDs.every((fundId) => {
        const fund = funds.find((f) => f.fund.id === fundId);

        if (firstSelectedFund.fund.currency === fund.fund.currency) {
          return true;
        } else {
          invalid = true;
          return false;
        }
      });

      setInvalidSelectedFunds(invalid);
      setCurrentCurrency(firstSelectedFund.fund.currency);
    }

    setSelectedFunds(fundIDs);
    setSelectedLedgers([]);
    setLedgerAndAccountFilters(
      fundIDs,
      ledgers,
      selectedAttributes,
      [],
      accounts,
      []
    );
  }

  function suppressGridRowsIfZero(
    reportAccounts: ReportAccount[],
    checkForMultiChildren: boolean
  ) {
    let acctWitNoChildrenIdx: number;

    do {
      if (!checkForMultiChildren) {
        acctWitNoChildrenIdx = reportAccounts.findIndex(
          (acct) => !acct.lineItems?.length
        );
      } else {
        acctWitNoChildrenIdx = reportAccounts.findIndex(
          (acct) => !acct.lineItems?.length && !acct.childAccounts?.length
        );
      }

      if (acctWitNoChildrenIdx >= 0) {
        const acctWitNoChildren = reportAccounts[acctWitNoChildrenIdx];

        const parentAcct = reportAccounts.find(
          (acct) => acct.accountId === acctWitNoChildren!.parentAccountId
        );

        if (parentAcct) {
          const childIdx = parentAcct.childAccounts.findIndex(
            (ca) => ca.accountId === acctWitNoChildren!.accountId
          );

          parentAcct.childAccounts.splice(childIdx, 1);
        }

        reportAccounts.splice(acctWitNoChildrenIdx, 1);
      }
    } while (acctWitNoChildrenIdx >= 0);
  }

  function handleSuppressIfZeroChange(_filter: any, suppressIfZero: boolean) {
    setRunFinancialStatementsView('');
    if (fsPackageValue) setFsPackageFlag(true);
    setSuppressIfZero(suppressIfZero);
    if (gridData || statementOfChangeData) {
      handleRunFinancialStatementsButtonClick();
    }
  }

  const [prevView, setPrevView] = useState<string>('');

  async function handleViewSelection(evt: any) {
    setPrevView(financialStatementsView);
    if (fsPackageFlag && fsPackageValue) {
      checkFsPackageFlag('viewChange', evt.target.value);
      return;
    }
    setSoiDateFilter(null);
    setRoundedReportActivated(false);
    setFsPackageFlag(false);

    if (
      evt.target.value === FsViews.unrealizedSOI ||
      evt.target.value === FsViews.realizedSOI
    ) {
      setSOIView(true);
      setFinancialStatementsView(evt.target.value);
    } else {
      setSOIView(false);
      setFinancialStatementsView(evt.target.value);
    }

    if (evt.target.value === FsViews.statementOfChangesInPartners) {
      setChangesInPartners(true);
    } else {
      setChangesInPartners(false);
    }

    const valid = areValidFilters();

    if (
      evt.target.value === FsViews.balanceSheet ||
      evt.target.value === FsViews.incomeStatement ||
      evt.target.value === FsViews.statementOfChangesInPartners
    ) {
      if (fsPackageValue && valid) {
        await awaitReactUpdate(400);
        setRunFinancialStatementsView(evt.target.value);
      }
    }
  }

  function getNoDataMessage() {
    if (invalidSelectedFunds) {
      return 'Fund filters must be of the same currency.';
    } else if (invalidSelectedLedgers) {
      return 'GL Name filters must be of the same currency.';
    } else if (dateRangeError) {
      return 'Invalid Date Range';
    } else if (!validFilters) {
      return 'Select filters above, then click Apply button to run the financial statement.';
    } else if (!isLoading && !runFinancialStatementsView) {
      return 'Click Apply to run the financial statement.';
    } else if (isLoading) {
      return '';
    } else {
      return undefined;
    }
  }

  function areValidFilters() {
    if (changesInPartners) {
      return !!selectedFunds.length && !invalidSelectedFunds && !dateRangeError;
    }

    return (
      !!selectedFunds.length &&
      !!selectedLedgers.length &&
      !!selectedAccounts.length &&
      !!selectedStatus.length &&
      !invalidSelectedLedgers
    );
  }

  const validFilters = areValidFilters();
  const packageDisabled = isPackageDisabled();
  const noDataMessage = getNoDataMessage();

  function isPackageDisabled() {
    if (
      financialStatementsView === FsViews.realizedSOI ||
      financialStatementsView === FsViews.unrealizedSOI
    ) {
      return false;
    } else if (gridData || statementOfChangeData) {
      return false;
    } else {
      return true;
    }
  }

  const handleRunFinancialStatementsButtonClick = (automatic?: boolean) => {
    if (fsPackageValue && !automatic) setFsPackageFlag(true);
    setRunFinancialStatementsView(financialStatementsView);
  };

  function handleValueLinkButtonClick(
    gridRow: GridRow,
    valueFieldOrder: ValueFieldDef,
    dateColInfo: DateColumnInfo[],
    isCustom: boolean,
    currentDecimals: number,
    netCatStartDate?: Date,
    retainedCatEndDate?: Date
  ) {
    const dateColumn: any = cloneDeep(
      dateColInfo.find((c) => c.name === valueFieldOrder.name)!
    );

    const callerDateArray = gridRow.callerDataArray.filter(
      (cd) => !!cd
    ) as GridCallerData[];
    const accountIds: string[] = [];
    let summaryRow: boolean = false;

    // Pass fsName into params for correctly parsing the returned data from the second API call for details
    const fsName = gridRow.callerDataArray[0].reportAccounts[0].fsName;

    // Pass summaryRow into params for currectly including parent row data in totals click through details
    if (gridRow.entityId.includes('Summary')) {
      summaryRow = true;
    }

    valueFieldOrder.category === 'Yearly' ||
    valueFieldOrder.category === 'Quarterly' ||
    valueFieldOrder.category === 'Months' ||
    valueFieldOrder.name === CreditRange ||
    valueFieldOrder.name === DebitRange ||
    valueFieldOrder.name.includes('selected')
      ? setZeroSumStartBalance(true)
      : setZeroSumStartBalance(false);

    // Prop drilled setLevel1Clicked to indicate if a custom view top parent details view is clicked to include all parent values
    if (gridRow.level === 1 && isCustom) {
      setLevel1Clicked(true);
    } else {
      setLevel1Clicked(false);
    }

    // Now that summaryRow is passed as a param this prop drilled is believed to be obsolete
    gridRow.entityId.includes('Summary')
      ? setSummaryRowClicked(true)
      : setSummaryRowClicked(false);

    callerDateArray.forEach((cd) => {
      cd.reportAccounts.forEach((ra) => {
        accountIds.push(ra.accountId);
      });
    });

    if (
      gridRow.categoryName === netInvestmentIncomeCatStr ||
      gridRow.categoryName === netRealizedCatStr ||
      gridRow.categoryName === netUnrealizedCatStr ||
      gridRow.categoryName === netIncomeTotalCatStr
    ) {
      if (
        dateColumn.name.includes('monthly') ||
        dateColumn.name.includes('quarterly') ||
        dateColumn.name.includes('yearly')
      ) {
        ('');
      } else {
        dateColumn.startDate = netCatStartDate;
        dateColumn.endDate = dateRange[1];
      }
      setNetRow(true);
    } else if (
      gridRow.categoryName === retainedInvestmentIncomeCatStr ||
      gridRow.categoryName === retainedRealizedCatStr ||
      gridRow.categoryName === retainedUnrealizedCatStr ||
      gridRow.categoryName === retainedEarningsTotalCatStr
    ) {
      if (
        dateColumn.name.includes('monthly') ||
        dateColumn.name.includes('quarterly') ||
        dateColumn.name.includes('yearly')
      ) {
        ('');
      } else {
        dateColumn.endDate = retainedCatEndDate;
        dateColumn.startDate = dateRange[0];
      }
      setRetainedRow(true);
    } else {
      setNetRow(false);
      setRetainedRow(false);
    }

    const params: ReportDetailsParams = {
      fundIds: selectedFunds,
      ledgerIds: selectedLedgers,
      accountIds: accountIds,
      attributeIds: selectedAttributes,
      journalEntryStates: selectedStatus,
      dateColInfos: dateColumn,
      currency: currentCurrency,
      decimals: currentDecimals,
      fsName: fsName,
      summaryRow: summaryRow,
      isCustom: isCustom,
      level1Clicked: gridRow.level === 1 && isCustom ? true : false,
      dateRange: dateRange,
    };

    setFSDetailsApiParams(params);
  }

  function handleDataGridChange(gridData: GridData, exportName: string) {
    setGridData(gridData);
    // setExportName(exportName);
  }

  function handleDataGridChangeBs(gridData: GridData, exportName: string) {
    setGridDataBs(gridData);
    // setExportName(exportName);
  }

  function handleDataGridChangeIs(gridData: GridData, exportName: string) {
    setGridDataIs(gridData);
    // setExportName(exportName);
  }

  function handleFsDataGridChange() {
    setFsGridDataFlag(true);
  }

  function handleCloseReportDetails() {
    setFSDetailsApiParams(undefined);
    setZeroSumStartBalance(false);
    setSummaryRowClicked(false);
    // setCustomEndBalanceClicked(false);
  }

  function handleFsNameChange(
    params: any,
    gridRows: GridRow[],
    _allReportAccounts: ReportAccount[]
  ) {
    setAllReportAccounts(_allReportAccounts);
    const row = gridRows.filter((row) => row.id === params.id);

    setPrevFsNameParam(row[0].categoryName);

    const rows = gridRows.filter(
      (row2) => row2.categoryName === row[0].categoryName
    );

    setConfirmNameChangeDialog(true);
    setFsNameChangeParams(params);
    setFsNameChangeRow(row[0]);
    setFsNameChangeRows(rows);
  }

  function confirmFsNamechange() {
    setConfirmNameChangeDialog(false);

    const tempBody: any = {
      fundId: '',
      fieldValues: {
        FS_MAPPING: '',
        FS_NAME: fsNameChangeParams.value,
      },
      accountIds: [],
    };

    const bodies: any = [];
    const fundIdMap: any = {};

    function findAccounts(accounts: ReportAccount[], nameValue: string) {
      const matchingAccounts: ReportAccount[] = [];

      function search(accounts: ReportAccount[]) {
        for (const account of accounts) {
          if (account.fsName === nameValue) {
            matchingAccounts.push(account);
          }

          if (account.childAccounts && account.childAccounts.length > 0) {
            search(account.childAccounts);
          }
        }
      }

      search(accounts);

      return matchingAccounts;
    }

    const matches = Array.from(
      new Set(findAccounts(allReportAccounts, prevFsNameParam))
    );

    matches.forEach((account: ReportAccount) => {
      const reqBody = { ...tempBody };

      reqBody.accountIds = [];
      reqBody.fieldValues = { ...tempBody.fieldValues };
      reqBody.accountIds.push(account.accountId);
      reqBody.fundId = account.fundId;
      reqBody.fieldValues.FS_MAPPING = account.fsMappingId;

      if (fundIdMap[reqBody.fundId] !== undefined) {
        bodies[fundIdMap[reqBody.fundId]].accountIds.push(
          ...reqBody.accountIds
        );
      } else {
        fundIdMap[reqBody.fundId] = bodies.length;
        bodies.push(reqBody);
      }
    });

    bodies.forEach((reqBody: any) => {
      reqBody.accountIds = Array.from(new Set(reqBody.accountIds));
    });

    updateFsName(bodies);
  }

  async function updateFsName(reqBodies: any[]) {
    setIsLoading(true);
    try {
      await Promise.all(
        reqBodies.map(async (reqBody: any) => {
          try {
            await bulkUpdateAccounts(reqBody);
            informationAlert(FS_NAME_CHANGE_SUCCESS, 'success');
          } catch (e) {
            informationAlert(FS_NAME_CHANGE_ERROR, 'error');
          }
        })
      );
    } catch (error) {
      informationAlert(FS_NAME_CHANGE_ERROR, 'error');
    }
    setIsLoading(false);
    triggerBuild();
  }

  function triggerBuild() {
    setTriggerBuilder(!triggerBuilder);
  }

  function handleEditMode() {
    setEditModeActivated(!editModeActivated);
  }

  function handleRoundedReport() {
    setRoundedReportActivated(!roundedReportActivated);

    if (fsPackageValue) setFsPackageFlag(true);

    triggerBuild();
  }

  const filterPackageOptions = (options: any, state: any) => {
    const inputValue = state.inputValue.toLowerCase().trim();

    if (!inputValue) {
      return [{ label: '+ Add FS Package', isButton: true }, ...options];
    }

    const filteredOptions = options.filter((option: any) =>
      option.name.toLowerCase().includes(inputValue)
    );

    return [{ label: '+ Add FS Package', isButton: true }, ...filteredOptions];
  };

  const isPackageOptionEqualToValue = (option: any, value: any) => {
    return option.label === value.label;
  };

  function handleNewFsPackage() {
    if (!fsPackageNewName) return;
    if (isPackageNameValidTest(fsPackageNewName)) {
      setFsAddPackage(true);
      setFsUpdatePackage(false);
      setIsPackageNameValid(true);
    } else {
      setFsAddPackage(false);
      setIsPackageNameValid(false);
    }
    if (
      financialStatementsView === FsViews.realizedSOI ||
      financialStatementsView === FsViews.unrealizedSOI ||
      financialStatementsView === FsViews.statementOfChangesInPartners
    ) {
      triggerFsPackageBuilder();
    }
  }

  function handleSaveFsPackage() {
    setFsSavePackage(true);
    setFsUpdatePackage(true);
    if (
      financialStatementsView === FsViews.realizedSOI ||
      financialStatementsView === FsViews.unrealizedSOI ||
      financialStatementsView === FsViews.statementOfChangesInPartners
    ) {
      triggerFsPackageBuilder();
    }
  }

  function triggerFsPackageBuilder() {
    setFsPackageBuilder(!fsPackageBuilder);
  }

  useEffect(() => {
    if (!fsPackageValue && fsPackageFlag) setFsPackageFlag(false);
  }, [fsPackageFlag]);

  useEffect(() => {
    if (fsPackageValue) {
      const packageValues: any = fsPackagesResponse.filter(
        (fsPackage: any) => fsPackage.id === fsPackageValue.id
      );

      setCurrFsPackage(packageValues[0]);

      let templateValues: any = {};
      let categoryValues: any = {};

      switch (financialStatementsView) {
        case FsViews.balanceSheet:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) => template.name === 'BALANCE_SHEET'
          );
          break;
        case FsViews.incomeStatement:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) => template.name === 'INCOME_STATEMENT'
          );
          break;
        case FsViews.realizedSOI:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) => template.name === 'REALIZED_SOI'
          );
          categoryValues = packageValues[0].templates.filter(
            (template: { name: string }) =>
              template.name === 'CATEGORY_REALIZED_SOI'
          );

          const updatedFieldOrderRealized = cloneDeep(dataFieldOrder);
          const updatedCatOrderRealized = cloneDeep(categoryFieldOrder);

          if (templateValues[0]) {
            updatedFieldOrderRealized.forEach((field) => {
              templateValues[0].columns.forEach((column: any) => {
                if (column.code === field.name) {
                  field.visible = column.visible;
                  field.order = column.order;
                }
              });
            });
          }

          if (categoryValues[0]) {
            updatedCatOrderRealized.forEach((field) => {
              categoryValues[0].columns.forEach((column: any) => {
                if (column.code === field.name) {
                  field.visible = column.visible;
                  field.order = column.order;
                }
              });
            });

            setSoiFilterSelection({
              fund: categoryValues[0].fundIds,
              portCo: categoryValues[0].portfolioCompanyIds,
            });

            if (categoryValues[0].endDate) {
              setSoiDateFilter(
                new Date(categoryValues[0].endDate.concat('T23:59:59'))
              );
            }
          }

          setDataFieldOrder(updatedFieldOrderRealized);
          setCategoryFieldOrder(updatedCatOrderRealized);
          setRebuildSoiGridData((prevVal) => prevVal + 1);
          saveDataFieldOrder(updatedFieldOrderRealized);
          saveCategoryFieldOrder(updatedCatOrderRealized);

          setTimeout(() => {
            setRebuildSoiGridData((prevVal) => prevVal + 1);
          }, 1);
          break;
        case FsViews.unrealizedSOI:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) => template.name === 'UNREALIZED_SOI'
          );
          categoryValues = packageValues[0].templates.filter(
            (template: { name: string }) =>
              template.name === 'CATEGORY_UNREALIZED_SOI'
          );

          const updatedFieldOrder = cloneDeep(dataFieldOrder);
          const updatedCatOrder = cloneDeep(categoryFieldOrder);

          if (templateValues[0]) {
            updatedFieldOrder.forEach((field) => {
              templateValues[0].columns.forEach((column: any) => {
                if (column.code === field.name) {
                  field.visible = column.visible;
                  field.order = column.order;
                }
              });
            });
          }

          if (categoryValues[0]) {
            updatedCatOrder.forEach((field) => {
              categoryValues[0].columns.forEach((column: any) => {
                if (column.code === field.name) {
                  field.visible = column.visible;
                  field.order = column.order;
                }
              });
            });

            setSoiFilterSelection({
              fund: categoryValues[0].fundIds,
              portCo: categoryValues[0].portfolioCompanyIds,
            });

            if (categoryValues[0].endDate) {
              setSoiDateFilter(
                new Date(categoryValues[0].endDate.concat('T23:59:59'))
              );
            }
          }

          setDataFieldOrder(updatedFieldOrder);
          setCategoryFieldOrder(updatedCatOrder);
          setRebuildSoiGridData((prevVal) => prevVal + 1);
          saveDataFieldOrder(updatedFieldOrder);
          saveCategoryFieldOrder(updatedCatOrder);

          setTimeout(() => {
            setRebuildSoiGridData((prevVal) => prevVal + 1);
          }, 1);
          break;
        case FsViews.statementOfChangesInPartners:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) =>
              template.name === 'STATEMENT_OF_CHANGES'
          );
          break;
        case FsViews.statementOfCashFlows:
          templateValues = packageValues[0].templates.filter(
            (template: { name: string }) =>
              template.name === 'STATEMENT_OF_CASH_FLOWS'
          );
          break;
      }

      templateValues = templateValues[0];

      if (templateValues) {
        if (templateValues.fundIds.length)
          handleFundFilterChange('', templateValues.fundIds, '', true);
        if (templateValues.ledgerIds.length)
          setSelectedLedgers(templateValues.ledgerIds);
        if (templateValues.accountIds.length)
          setSelectedAccounts(templateValues.accountIds);
        if (templateValues.statuses.length)
          setSelectedStatus(templateValues.statuses);
        if (!soiView)
          setDateRange([
            templateValues.startDate
              ? new Date(templateValues.startDate.concat('T00:00:00'))
              : null,
            templateValues.endDate
              ? new Date(templateValues.endDate.concat('T23:59:59'))
              : null,
          ]);
        setSuppressIfZero(templateValues.suppress);
        setRoundedReportActivated(templateValues.rounded);
        setFsUpdateColumnOrder(templateValues.columns);
      }

      if (fsPackageValue && templateValues) {
        runReportWithPackage();
      }
    }

    setFsPackageFlag(false);
  }, [fsPackageValue, financialStatementsView]);

  async function runReportWithPackage() {
    await awaitReactUpdate();
    handleRunFinancialStatementsButtonClick(true);
    setFsColumnBuilder(!fsColumnBuilder);
  }

  useEffectAsync(async () => {
    if (fsSavePackage || fsAddPackage) {
      const sheetData = {
        name: '',
        templates: [
          {
            type: '',
            fundIds: soiView ? filterSelection.fund! : selectedFunds,
            ledgerIds: selectedLedgers,
            accountIds: selectedAccounts,
            statuses: selectedStatus,
            startDate: dateRange[0]
              ? DateTimeFormat.getReversedDate(dateRange[0])
              : null,
            endDate: dateRange[1]
              ? DateTimeFormat.getReversedDate(dateRange[1])
              : null,
            suppress: suppressIfZero,
            rounded: roundedReportActivated,
            columns: fsSaveFieldOrder,
            portfolioCompanyIds: filterSelection.portCo!,
          },
        ],
      };

      let sheetCategory: any = {};

      if (
        financialStatementsView === FsViews.realizedSOI ||
        financialStatementsView === FsViews.unrealizedSOI
      ) {
        sheetCategory = {
          name: '',
          templates: [
            {
              type: '',
              fundIds: filterSelection.fund!,
              portfolioCompanyIds: filterSelection.portCo!,
              ledgerIds: [],
              accountIds: [],
              statuses: [],
              startDate: '',
              endDate: DateTimeFormat.getReversedDate(soiDateFilter!),
              suppress: false,
              rounded: false,
              columns: fsSaveCategoryOrder,
            },
          ],
        };
      }

      let templateValues: any = {};
      let categoryValues: any = {};

      switch (financialStatementsView) {
        case FsViews.balanceSheet:
          sheetData.templates[0].type = 'BALANCE_SHEET';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) => template.name === 'BALANCE_SHEET'
            );
          break;
        case FsViews.incomeStatement:
          sheetData.templates[0].type = 'INCOME_STATEMENT';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) =>
                template.name === 'INCOME_STATEMENT'
            );
          break;
        case FsViews.realizedSOI:
          sheetData.templates[0].type = 'REALIZED_SOI';
          sheetData.templates[0].startDate = '';
          sheetData.templates[0].endDate = DateTimeFormat.getReversedDate(
            soiDateFilter!
          );
          sheetCategory.templates[0].endDate = DateTimeFormat.getReversedDate(
            soiDateFilter!
          );
          sheetCategory.templates[0].startDate = '';
          sheetCategory.templates[0].type = 'CATEGORY_REALIZED_SOI';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) => template.name === 'REALIZED_SOI'
            );
          if (currFsPackage)
            categoryValues = currFsPackage.templates.filter(
              (template: { name: string }) =>
                template.name === 'CATEGORY_REALIZED_SOI'
            );
          break;
        case FsViews.unrealizedSOI:
          sheetData.templates[0].type = 'UNREALIZED_SOI';
          sheetData.templates[0].startDate = '';
          sheetData.templates[0].endDate = DateTimeFormat.getReversedDate(
            soiDateFilter!
          );
          sheetCategory.templates[0].endDate = DateTimeFormat.getReversedDate(
            soiDateFilter!
          );
          sheetCategory.templates[0].startDate = '';
          sheetCategory.templates[0].type = 'CATEGORY_UNREALIZED_SOI';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) => template.name === 'UNREALIZED_SOI'
            );
          if (currFsPackage)
            categoryValues = currFsPackage.templates.filter(
              (template: { name: string }) =>
                template.name === 'CATEGORY_UNREALIZED_SOI'
            );
          break;
        case FsViews.statementOfChangesInPartners:
          sheetData.templates[0].type = 'STATEMENT_OF_CHANGES';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) =>
                template.name === 'STATEMENT_OF_CHANGES'
            );
          break;
        case FsViews.statementOfCashFlows:
          sheetData.templates[0].type = 'STATEMENT_OF_CASH_FLOWS';
          if (currFsPackage)
            templateValues = currFsPackage.templates.filter(
              (template: { name: string }) =>
                template.name === 'STATEMENT_OF_CASH_FLOWS'
            );
          break;
      }

      if (fsUpdatePackage && fsPackageValue !== null && fsPackageValue.id) {
        setIsLoading(true);
        sheetData.name = fsPackageSaveName;

        try {
          if (
            templateValues.find(
              (template: any) => template.type === sheetData.templates[0].type
            )
          ) {
            if (
              financialStatementsView === FsViews.realizedSOI ||
              financialStatementsView === FsViews.unrealizedSOI
            ) {
              await updateReportTemplate(
                fsPackageValue.id,
                sheetCategory.templates[0].type,
                sheetCategory.templates[0]
              );
            }

            await updateReportTemplate(
              fsPackageValue.id,
              sheetData.templates[0].type,
              sheetData.templates[0]
            );
          } else {
            if (
              financialStatementsView === FsViews.realizedSOI ||
              financialStatementsView === FsViews.unrealizedSOI
            ) {
              await addReportTemplate(
                fsPackageValue.id,
                sheetCategory.templates[0].type,
                sheetCategory.templates[0]
              );
            }
            await addReportTemplate(
              fsPackageValue.id,
              sheetData.templates[0].type,
              sheetData.templates[0]
            );
          }
          informationAlert('Success updating FS Package Template', 'success');
        } catch (e) {
          informationAlert('Error updating FS Package Template', 'error');
        }

        const reportTemplates: any = await getReportTemplates();
        let options: any = [];

        reportTemplates.items.map((fsPackage: any) => {
          options.push({
            id: fsPackage.id,
            name: fsPackage.name,
          });
        });

        options = options.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );

        setFsPackagesList(options);
        setFsPackagesResponse(reportTemplates.items);

        const updatedPackage = options.find(
          (option: any) => option.id === fsPackageValue.id
        );

        setFsPackageValue(updatedPackage);
        setIsLoading(false);
      } else {
        if (fsPackageSaveName) {
          setIsLoading(true);
          sheetData.name = fsPackageSaveName;
          try {
            if (
              financialStatementsView === FsViews.realizedSOI ||
              financialStatementsView === FsViews.unrealizedSOI
            ) {
              sheetData.templates.push(sheetCategory.templates[0]);
            }
            await createReportTemplate(sheetData);
            informationAlert('Success creating FS Package Template', 'success');
          } catch (e) {
            informationAlert('Error creating FS Package Template', 'error');
          }

          const reportTemplates: any = await getReportTemplates();
          let options: any = [];

          reportTemplates.items.map((fsPackage: any) => {
            options.push({
              id: fsPackage.id,
              name: fsPackage.name,
            });
          });

          options = options.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );

          setFsPackagesList(options);
          setFsPackagesResponse(reportTemplates.items);

          const createdPackage = options.find(
            (option: any) => option.name === fsPackageSaveName
          );

          setFsPackageValue(createdPackage);
          setIsLoading(false);
        }
      }

      setFsAddPackage(false);
      setFsSavePackage(false);
      setFsPackageFlag(false);
    }
  }, [fsPackageBuilder]);

  function isPackageNameValidTest(input: string) {
    return !fsPackagesList.some((option) => option.name === input);
  }

  function checkFsPackageFlag(event: string, value?: any) {
    setExitEvent(event);
    setExitValue(value);
    fsPackageFlag ? setOpenFlag(true) : setOpenFlag(false);
  }

  function handleExitConfirmation() {
    if (exitEvent === 'packageChange') {
      setFsPackageValue(nextPackage);
    }

    if (exitEvent === 'viewChange') {
      if (
        exitValue === FsViews.unrealizedSOI ||
        exitValue === FsViews.realizedSOI
      ) {
        setSOIView(true);
        setFinancialStatementsView(exitValue);
      } else {
        setSOIView(false);
        setFinancialStatementsView(exitValue);
      }
    }

    if (exitEvent === 'export') {
      exportPackage(true);
    }

    setOpenFlag(false);
  }

  const [prevFsPackage, setPrevFsPackage] = useState();

  function handleSetFsPackageValue(newValue: any) {
    setPrevFsPackage(fsPackageValue);
    setNextPackage(newValue);
    if (fsPackageFlag && fsPackageValue) {
      checkFsPackageFlag('packageChange', fsPackageValue);
      return;
    }
    setFsPackageValue(newValue);
  }

  const isCompleteAndValidDate = (date: Date | null | undefined) => {
    if (!date) return false;
    if (!isValid(date)) return false;

    const year = date.getFullYear();

    return year >= 1900 && year <= new Date().getFullYear();
  };

  function handleSoiDateChange(value: Date | null | undefined) {
    setPrevFsPackage(fsPackageValue);
    if (fsPackageValue) setFsPackageFlag(true);
    if (isCompleteAndValidDate(value)) {
      setSoiDateFilter(value);
    }
  }

  const [runBS, setRunBS] = useState<boolean>(false);
  const [runIS, setRunIS] = useState<boolean>(false);
  const [bsExportData, setBsExportData] = useState<GridData>();
  const [isExportData, setIsExportData] = useState<GridData>();
  const [processingExport, setProcessingExport] = useState<boolean>(false);
  const [prevFsView, setPrevFsView] = useState('');

  async function exportPackage(skip?: boolean) {
    if (!fsPackageValue) {
      informationAlert(
        'FS Package selection is required for export package.',
        'error'
      );
      return;
    }

    if (fsPackageFlag && !skip) {
      checkFsPackageFlag('export');
      return;
    }

    setPrevFsView(financialStatementsView);
    setIsExportLoading(true);
    setProcessingExport(true);
    setGridDataBs(undefined);
    setGridDataIs(undefined);
    setFinancialStatementsView('');
    await awaitReactUpdate();
    setRunBS(true);
  }

  useEffect(() => {
    if (processingExport && runBS) {
      const fsPackage: any = fsPackagesResponse.find(
        (selectedPackage: any) => selectedPackage.id === fsPackageValue.id
      );

      if (
        fsPackage.templates.find(
          (template: any) => template.type === 'BALANCE_SHEET'
        )
      ) {
        setFinancialStatementsView(FsViews.balanceSheet);

        if (gridDataBs) {
          setBsExportData(cloneDeep(gridDataBs));
          setRunBS(false);
          setRunIS(true);
        }
      } else {
        setRunIS(true);
        setRunBS(false);
      }
    }
  }, [runBS, gridDataBs]);

  useEffect(() => {
    if (processingExport && runIS) {
      const fsPackage: any = fsPackagesResponse.find(
        (selectedPackage: any) => selectedPackage.id === fsPackageValue.id
      );

      if (
        fsPackage.templates.find(
          (template: any) => template.type === 'INCOME_STATEMENT'
        )
      ) {
        setFinancialStatementsView(FsViews.incomeStatement);

        if (gridDataIs) {
          setRunIS(false);
          setIsExportData(gridDataIs);
          runExportPackage(gridDataIs);
        }
      } else {
        runExportPackage({ gridRows: [], dataGridColDefs: [] });
        setRunIS(false);
      }
    }
  }, [runIS, gridDataIs]);

  async function runExportPackage(isData: GridData) {
    const reqBody: any = {
      packageId: fsPackageValue.id,
      financialStatementReportOutputFormat: 'XLSX',
      reportData: [] as any,
    };

    if (bsExportData) {
      const filteredRows = bsExportData.gridRows.map(
        ({ callerDataArray, ...rest }) => rest
      );

      reqBody.reportData.push({
        type: 'BALANCE_SHEET',
        gridRows: filteredRows,
        dataGridColDefs: bsExportData.dataGridColDefs,
      });
    }

    if (isData) {
      const filteredRows = isData.gridRows.map(
        ({ callerDataArray, ...rest }) => rest
      );

      reqBody.reportData.push({
        type: 'INCOME_STATEMENT',
        gridRows: filteredRows,
        dataGridColDefs: isData.dataGridColDefs,
      });
    }

    try {
      const exportResponse = await exportFsPackage(reqBody);

      const bufferedresponse = await exportResponse.arrayBuffer();

      if (exportResponse) {
        const formattedDate = DateTimeFormat.getFormattedDate(new Date());

        downloadFile(
          bufferedresponse,
          `${fsPackageValue.name} ` + formattedDate,
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }

      informationAlert('Success exporting FS Package.', 'success');
    } catch (e) {
      informationAlert('Error exporting FS Package.', 'error');
    } finally {
      setProcessingExport(false);
      setBsExportData(undefined);
      setGridDataBs(undefined);
      setIsExportData(undefined);
      setGridDataIs(undefined);
      setIsExportLoading(false);
      setFinancialStatementsView(prevFsView);
    }

    setIsExportLoading(false);
  }

  function handleDateRangeError(error: DateRangeValidationError) {
    if (error) {
      if (error[0] || error[1]) {
        setDateRangeError(true);
      } else {
        setDateRangeError(false);
      }
    }
  }

  useEffect(() => {
    if (fsPackageValue && reportView && fsPackagesResponse.length > 0) {
      const currentPackage: any = fsPackagesResponse.find(
        (fsPackage: FsPackage) => fsPackage.id === fsPackageValue.id
      );

      if (currentPackage) {
        const found = currentPackage.templates.find(
          (template: any) => template.type === currentTemplateType
        );

        found ? setPackageExportEnabled(true) : setPackageExportEnabled(false);
      } else {
        setPackageExportEnabled(false);
      }
    }
  }, [fsPackageValue, financialStatementsView, fsPackagesResponse]);

  return {
    attributeFilterDisabled,
    attributes,
    changesInPartners,
    changesInPartnersParams,
    checkFsPackageFlag,
    confirmFsNamechange,
    confirmNameChangeDialog,
    currentCurrency,
    dataWrapperRef,
    dateRange,
    editModeActivated,
    exportPackage,
    filteredAccounts,
    filteredAttributes,
    filteredLedgers,
    filterPackageOptions,
    filtersRef,
    financialStatementsList,
    financialStatementsParams,
    financialStatementsView,
    fsAddPackage,
    fsColumnBuilder,
    fsDetailsApiParams,
    fsPackageFlag,
    fsPackagesList,
    fsPackageNewName,
    fsPackageValue,
    fsSavePackage,
    fsUpdateColumnOrder,
    funds,
    fundsFilter,
    generalAttributes,
    gridData,
    handleAccountFilterChange,
    handleAttributeFilterChange,
    handleCloseReportDetails,
    handleDataGridChange,
    handleDataGridChangeBs,
    handleDataGridChangeIs,
    handleDateRangeChange,
    handleEditMode,
    handleExitConfirmation,
    handleFsDataGridChange,
    handleFsNameChange,
    handleFundFilterChange,
    handleLedgerFilterChange,
    handleNewFsPackage,
    handleRoundedReport,
    handleRunFinancialStatementsButtonClick,
    handleSaveFsPackage,
    handleSetFsPackageValue,
    handleStatusFilterChange,
    handleSoiDateChange,
    handleSuppressIfZeroChange,
    handleValueLinkButtonClick,
    handleViewSelection,
    invalidSelectedFunds,
    invalidSelectedLedgers,
    isLoading,
    isExportLoading,
    isPackageNameValid,
    isPackageOptionEqualToValue,
    level1Clicked,
    netRow,
    noDataMessage,
    openFlag,
    packageDisabled,
    packageExportEnabled,
    readonly,
    reportView,
    retainedRow,
    roundedReportActivated,
    runFinancialStatementsView,
    selectedAccounts,
    selectedAttributes,
    selectedFunds,
    selectedLedgers,
    selectedStatus,
    setConfirmNameChangeDialog,
    handleDateRangeError,
    setFsPackageFlag,
    setFsPackageNewName,
    setFsPackageSaveName,
    setFsPackageValue,
    setFsSaveCategoryOrder,
    setFsSaveFieldOrder,
    setIsLoading,
    setMainFSListData,
    setOpenFlag,
    setRoundedReportActivated,
    setTriggerBuilder,
    soiView,
    statementOfChangeData,
    statusFilter,
    summaryRowClicked,
    suppressIfZero,
    triggerBuilder,
    triggerFsPackageBuilder,
    userClickAway,
    validFilters,
    viewFiltersState,
    viewListRef,
    zeroSumStartBalance,
    isLoadingList,
    currSOIview,
    setCurrSOIview,
    handleSoiViewChange,
    soiGridData,
    handleColumnOrderChange,
    dateFilter: soiDateFilter,
    setDateFilter: setSoiDateFilter,
    setStatementOfChangeData,
    fetchGlReport,
    setFetchGlReport,
    isInitialLoading,
  };
};
