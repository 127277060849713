import { Grid, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../components/Progress/ProgressPanel/ProgressPanel';
import JEAccountFilter from '../../../components/Selectors/JEAccountFilter/JEAccountFilter';
import JELedgerFilter from '../../../components/Selectors/JELedgerFilter/JELedgerFilter';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import { SelectedBankAccount } from '../../../utils/types/bank.type';
import { useBankAccountDetails } from './BankAccountDetails.hooks';
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from './BankAccountDetails.styles';

interface Props {
  onDetailClose: Function;
  selectedBankAccount: SelectedBankAccount;
  fetchAllFilters: Function;
}

export const BankAccountDetailsReadOnly: React.FC<Props> = ({
  onDetailClose,
  selectedBankAccount,
  fetchAllFilters,
}: Props) => {
  const { type, bankAccount } = selectedBankAccount;

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateBankAccountDetails,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    toggleDrawer,
    ledgerList,
    accountList,
    fundList,
  } = useBankAccountDetails({
    bankAccount,
    onClose: onDetailClose,
    selectedBankAccount,
    fetchAllFilters,
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_bank_card"
        title={bankAccount?.name ? bankAccount.name : ''}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(updateBankAccountDetails)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel">
              <Typography variant="h4">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Name</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.name || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Official Name</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.name || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>

                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Nickname</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.nickname || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} mt={2} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Type</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.type.toUpperCase() || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Sub Type</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.subType.toUpperCase() || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Fund</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.fundName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Ledger</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.ledgerName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>GL Account</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.glAccountName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
              </Grid>
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'bank_account_close_button'}
              variant="outlined"
              onClick={closeDrawer}
              text={'Close'}
              color={'secondary'}
              fullWidth
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_bank_card_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ''}
        />
      </DetailPanel>
    </>
  );
};
