import { Box, Chip, styled } from '@mui/material';

export const ViewOptionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '20px',
  alignItems: 'center',
}));

export const ViewOptionChip = styled(Chip)(({ theme }) => ({
  margin: '8px',
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
