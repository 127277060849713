import { cloneDeep } from "lodash";

import { getInvestorCommitmentList, getTranactionTypeColumns } from "../../../../../../services/workbook.service";
import { ALLOCATIONS_DATE_REGEX } from "../../../../../../utils/constants/constants";
import { DateTimeFormat } from "../../../../../../utils/helpers/format.helper";
import { getCurrentQuarter } from "../../../../../../utils/helpers/getCurrentQuarter";
import { getQuarterStartEndDates } from "../../../../../../utils/helpers/quarter.helper";
import { AllocationColumn, CommitmentColumn, GlEntry, InvestorCommitment, InvestorRow, ItdColumn, ITDType, SheetTypes, StringConstants, ToolbarButtons,TransactionColumn, TransactionColumnType, WbConfigDialogType, Workbook } from "../../../workbook.type";
import { consoleDebug, debugPrintAllCols, debugPrintAllocCols, debugPrintCommitCols } from "../../debug";
import { CellFormats, ColWidth, NewColPosition, SpreadsheetGrid } from "../../SpreadsheetGrid";
import { colsAddResponse } from "../ICellEditHandling";
import { ISheetManager } from "../ISheet.Manager";
import { WorkbookSheetsManager } from "../WorkbookSheets.Manager";
import { AllocationRulesColumnsManager } from "./columns/AllocationRulesColumns.Manager";
import { BaseColumnsManager } from "./columns/BaseColumns.Manager";
import { CommitmentColumnsManager } from "./columns/CommitmentColumns.Manager";
import { InvestorColumnsManager } from "./columns/InvestorColumns.Manager";
import { ItdColumnsManager } from "./columns/ItdColumns.manager";
import { PitdColumnsManager } from "./columns/PitdColumns.manager";
import { TransactionColumnsManager } from "./columns/TransactionColumns.Manager";
import { YtdColumnsManager } from "./columns/YtdColumns.manager";
import { InvestorRowManager } from "./InvestorRows.Manager";

export class AllocationsSheetManager implements ISheetManager {
    private _grid!: SpreadsheetGrid;
    private _sheetNeverOpened = true;

    private _investorRows!: InvestorRow[];
    private _allocationRulesColumns!: AllocationColumn[];
    private _commitmentColumns!: CommitmentColumn[];
    private _transactionColumns!: TransactionColumn[];
    private _itdColumns!: ItdColumn[];

    private _workbookSheetsManager!:WorkbookSheetsManager;
    private _investorRowManager = new InvestorRowManager();

    private _investorColumnsManager = new InvestorColumnsManager();
    private _commitmentColumnsManager = new CommitmentColumnsManager();
    private _allocationRulesManager = new AllocationRulesColumnsManager();
    private _pitdColumnsManager = new PitdColumnsManager();
    private _transactionColumnsManager = new TransactionColumnsManager();
    private _ytdColumnsManager = new YtdColumnsManager();
    private _itdColumnsManager = new ItdColumnsManager();

    private _allColumnManagers!:BaseColumnsManager[];
    

    public onShowConfig!: ((wbConfigDialogType: WbConfigDialogType) => void);
    public onShowLoadingMessage!: ((message: string|undefined) => Promise<void>);
    public onShowInvalidPasteText!: ((invalidCells: string) => void);
    public onShowMessageDialog!: (message: {title:string, message:string}) => void;
    public onShowSubmitToPcapSaveDialog!: (show: boolean) => void;
    public onShowGlConnectDialog!: (show: boolean) => void;
    public onShowRolloverNewWB!: (show: boolean) => void;

    public get sheetType(): SheetTypes {
        return SheetTypes.Allocations;
    }

    public get workbookSheetsManager(): WorkbookSheetsManager {
        return this._workbookSheetsManager;
    }

    public get investorRowManager(): InvestorRowManager {
        return this._investorRowManager;
    }

    public get investorColumnsManager(): InvestorColumnsManager {
        return this._investorColumnsManager;
    }

    public get commitmentColumnsManager(): CommitmentColumnsManager {
        return this._commitmentColumnsManager;
    }

    public get allocationRulesManager(): AllocationRulesColumnsManager {
        return this._allocationRulesManager;
    }

    public get pitdColumnsManager() : PitdColumnsManager {
        return this._pitdColumnsManager;
    }

    public get transactionColumnsManager(): TransactionColumnsManager {
        return this._transactionColumnsManager;
    }

    public get ytdColumnsManager(): YtdColumnsManager|undefined {
        return this._ytdColumnsManager;
    }

    public get itdColumnsManager(): ItdColumnsManager|undefined {
        return this._itdColumnsManager;
    }

    public setAllocationSheetData(
        workbookSheetsManager: WorkbookSheetsManager,
        workbook: Workbook,
        setSheetAsNeverOpened: boolean
    ): void {
        this._sheetNeverOpened = setSheetAsNeverOpened;
        
        this._workbookSheetsManager = workbookSheetsManager;
        this._investorRows = workbook.investorRows;
        this._commitmentColumns = workbook.commitmentColumns;
        this._allocationRulesColumns = workbook.allocationColumns;
        this._transactionColumns = workbook.transactionColumns;
        this._itdColumns = workbook.itdColumns;


        this._allColumnManagers = [
            this._investorColumnsManager,
            this._commitmentColumnsManager,
            this._allocationRulesManager,
            this._pitdColumnsManager,
            this._transactionColumnsManager
        ];

        if(this._workbookSheetsManager.includeYtd) {
            this._allColumnManagers.push(this._ytdColumnsManager!);
        }

        if(this._workbookSheetsManager.includeItd) {
            this._allColumnManagers.push(this._itdColumnsManager!);
        }
    }

    public OnPreloadedSheet() {
        const visible = !this.workbookSheetsManager.isReadonly;

        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.Save, visible);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SaveAs, visible);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.GetFromGl, visible);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.RollForward, visible);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SubmitToPcap, visible);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.AllocationsConfig, visible);
    };

    public async OnLoadedSheet(grid: SpreadsheetGrid) {
        this._grid = grid;
        const wbManager = this._workbookSheetsManager;

        if(this._sheetNeverOpened) {
            await this.onShowLoadingMessage('Allocations workbook is being calculated and reconciled, this may take a minute.');
            
            this._investorRowManager.SetDependencies(grid);

            this._allColumnManagers.forEach(cm =>{
                cm.SetDependencies(grid, wbManager);
            });

            if(this._workbookSheetsManager.isNewWorkbook) {
                const investorCommitments = await this.getInvestorsAndCommitments();

                await this.setNewWorkbookFirstLoadedSheet(grid, investorCommitments);                
            } else {
                await this.existingWorkbookFirstLoadedSheet(grid);
            }

            await this.onShowLoadingMessage(undefined);

            this._sheetNeverOpened = false;
        }
    };

    private async getInvestorsAndCommitments() {
        const wbManager = this._workbookSheetsManager;
        const investorCommitments = await getInvestorCommitmentList(
            wbManager.fund.id, wbManager.lastQuarter, wbManager.workbookYear, wbManager.commitmentTransactionTypeIds);

        return investorCommitments;
    }

    private async setNewWorkbookFirstLoadedSheet(grid: SpreadsheetGrid, investorCommitments: InvestorCommitment[]) {        
        const wbManager = this._workbookSheetsManager;
        const investorRowManager = this._investorRowManager;

        //  ensure enough for 3 investor cols, alloc rules cols, pitd, 1 quarter transactions, ytd, itd, spacers + padding
        grid.ensureColumnIndexes(3 + this._allocationRulesColumns.length + (this._transactionColumns.length*4) + 100);
        grid.ensureRowIndexes(investorCommitments.length+20);

        investorRowManager.initInvestorsFromInvestorCommitments(investorCommitments);

        this._investorColumnsManager.initInvestorColumnsData();

        let colStart = grid.getNextColId(this._investorColumnsManager.endColumnId);

        this._commitmentColumnsManager.initCommitmentsColumnsFromInvestorCommitments(investorCommitments, colStart);

        colStart = this.renderColumnSpacer(this._commitmentColumnsManager.endColumnId);
        colStart = grid.getNextColId(colStart);
        this._allocationRulesManager.initAllocationRulesColumnsFromWorkbook(this._allocationRulesColumns, colStart);


        colStart = this.renderColumnSpacer(this._allocationRulesManager.endColumnId);
        colStart = grid.getNextColId(colStart);

        await this._pitdColumnsManager.initPitdColumnsData([], this._transactionColumns, colStart);


        colStart = this.renderColumnSpacer(this._pitdColumnsManager.endColumnId);
        colStart = grid.getNextColId(colStart);
        await this._transactionColumnsManager.initTransactionsColumnsData(this._transactionColumns, colStart);

        if(wbManager.includeYtd) {
            colStart = this.renderColumnSpacer(this._transactionColumnsManager.endColumnId);
            colStart = grid.getNextColId(colStart);
            this._ytdColumnsManager!.initYtdColumnsData([], this._transactionColumns, colStart);
        }

        if(wbManager.includeItd) {
            const endColumnId = wbManager.includeYtd ? this._ytdColumnsManager!.endColumnId : this._transactionColumnsManager.endColumnId;

            colStart = this.renderColumnSpacer(endColumnId);
            colStart = grid.getNextColId(colStart);
            this._itdColumnsManager!.initItdColumnsData([], this._transactionColumns, colStart);
        }

        this.renderHeaderInfo();

        this._allColumnManagers.forEach(cm => {
            cm.renderColumns();
        });

        grid.beginWorkbookSaving();
    }

    private async existingWorkbookFirstLoadedSheet(grid: SpreadsheetGrid) {
        const wbManager = this._workbookSheetsManager;
        const prevVersion = wbManager.WorkbookVersion;

        consoleDebug(`** Workbook Version Before Upgrade ${prevVersion} **`);
        
        if(wbManager.WorkbookVersion <= 3) {
            await this.onShowLoadingMessage('Upgrading allocations workbook...');

            this.upgradeWorkbook(grid);

            wbManager.changeMade();
        }

        await this.onShowLoadingMessage('Loading...');

        const investorCommitments = await this.getInvestorsAndCommitments();

        this._investorRowManager.initInvestorsFromWorkbook(this._investorRows);
        const updatedGridRowIDs = this._investorRowManager.updateInvestorFromInvestorCommitments(investorCommitments);

        this._commitmentColumnsManager.initCommitmentsColumnsFromWorkbook(this._commitmentColumns);
        const newCommitmentColIDs = this._commitmentColumnsManager.updateCommitmentsColumns(investorCommitments);

        this._allocationRulesManager.initAllocationRulesColumnsFromWorkbook(this._allocationRulesColumns);

        await this.updateMetricsSignsOnItdCols();

        const pitdCols = this._itdColumns.filter(col => 
            col.colType === ITDType.BEGINING_BALANCE_PITD_TYPE || 
            col.colType === ITDType.STANDARD_PITD_TYPE ||
            col.colType === ITDType.ENDING_BALANCE_PITD_TYPE
        );

        await this._pitdColumnsManager.initPitdColumnsData(pitdCols);

        await this._transactionColumnsManager.initTransactionsColumnsData(this._transactionColumns);

        if(wbManager.includeYtd) {
            const ytdCols = this._itdColumns.filter(col => 
                col.colType === ITDType.BEGINING_BALANCE_YTD_TYPE || 
                col.colType === ITDType.STANDARD_YTD_TYPE ||
                col.colType === ITDType.ENDING_BALANCE_YTD_TYPE
            );

            this.ytdColumnsManager!.initYtdColumnsData(ytdCols);
        }

        if(wbManager.includeItd) {
            const itdCols = this._itdColumns.filter(col => 
                col.colType === ITDType.BEGINING_BALANCE_ITD_TYPE || 
                col.colType === ITDType.STANDARD_ITD_TYPE ||
                col.colType === ITDType.ENDING_BALANCE_ITD_TYPE
            );

            this.itdColumnsManager!.initItdColumnsData(itdCols);
        }

        this.debugPrintoutAllData('WB Open after data init');

        if(prevVersion <= 2) {
            this._investorColumnsManager.renderColumns();
            this._pitdColumnsManager.renderColumns();
            this._commitmentColumnsManager.renderColumns();
            this._allocationRulesManager.renderColumns(undefined, undefined, true);
            this._transactionColumnsManager.renderColumns();

            if(wbManager.includeYtd && updatedGridRowIDs?.length) {
                this._ytdColumnsManager!.renderColumns(updatedGridRowIDs);
            }

            if(wbManager.includeItd && updatedGridRowIDs?.length) {
                this._itdColumnsManager!.renderColumns(updatedGridRowIDs);
            }

            wbManager.changeMade();
        } else if(prevVersion >= 3) {
            if(updatedGridRowIDs?.length) {
                this._investorColumnsManager.renderColumns(updatedGridRowIDs);
                this._commitmentColumnsManager.renderColumns(updatedGridRowIDs, undefined);
                this._allocationRulesManager.renderColumns(updatedGridRowIDs);                
                
                if(wbManager.includeYtd) {
                    this._ytdColumnsManager!.renderColumns(updatedGridRowIDs);
                }
    
                if(wbManager.includeItd) {
                    this._itdColumnsManager!.renderColumns(updatedGridRowIDs);
                }
            }

            if(newCommitmentColIDs?.length) {
                this._commitmentColumnsManager.renderColumns(undefined, newCommitmentColIDs);
            }

            this._pitdColumnsManager.renderColumns();
            this._transactionColumnsManager.renderColumns(undefined, undefined, true);
        }
        
        await this.onShowLoadingMessage(undefined);
    }


    private async updateMetricsSignsOnItdCols() {
        const transTypeColumnsResponse = await getTranactionTypeColumns(this.workbookSheetsManager.clientId);

        this._itdColumns.filter(col => !!col.transTypeId).forEach(col => {
            const transType = transTypeColumnsResponse.find(t => t.id === col.transTypeId);
    
            col.metricSign = transType?.metricSign ? parseInt(transType.metricSign) : 0;
        });
    }


    public upgradeWorkbook(grid: SpreadsheetGrid) {
        const wbManager = this.workbookSheetsManager;

        this._commitmentColumns = [];
        this._itdColumns = [];

        //Populate investorRows.gridRowId
        this._investorRows.forEach(row => {
            const rowId = grid.getRowByIndex(row.row!);
        
            row.gridRowId = String(rowId);
        });

        //Set corresponding gridColId in sequential order to loop through and add to each column category
        //design standardizes an empty column between column categories to signify end of column grouping
        let currColId = grid.getColFirstCol(1);
        let quarterNum = wbManager.firstQuarter;
        let allocationsCurrColId = "";
        let pitdCurrColId = "";
        let qtdCurrColId = "";
        let ytdCurrColId = "";
        let itdCurrColId = "";

        const yearly = wbManager.frequency === "BY_YEAR" ? true : false;

        //Upgrade Commitment Columns by creating new column objects
        while (currColId !== ""){
            const val = grid.getCellValue("4", currColId);

            if(val){
                let qtrSum = val.includes("Close on") || yearly ? false : true;
                const dateValFound =  val.match(ALLOCATIONS_DATE_REGEX);
                let dateVal = "";

                if(dateValFound){
                    dateVal = DateTimeFormat.getReversedDate(new Date(dateValFound[0]));
                    if(yearly && wbManager.workbookYear === parseInt(dateVal.split('-')[0])){
                        const quarterVal = getCurrentQuarter(new Date(dateValFound[0]));

                        quarterNum = quarterVal.quarterNumber;
                    }
                } else {
                    quarterNum = yearly ? 4 : parseInt(val.match(/Q(\d+)/)[1], 10);
                    const qrtDetails = getQuarterStartEndDates(quarterNum, wbManager.workbookYear);

                    dateVal = DateTimeFormat.getReversedDate(qrtDetails.endDate);
                    qtrSum = true;
                }
                
                this._commitmentColumns.push({
                    gridColId: currColId,
                    label: val,
                    quarterSum: qtrSum,
                    commitmentDate: dateVal,
                    quarterNumber: quarterNum
                });

                if(!dateValFound) quarterNum = quarterNum + 1;
                currColId = grid.getNextColId(currColId);
            } else {
                allocationsCurrColId = grid.getNextColId(currColId);
                currColId = "";
            }
        }

        //debugPrintCommitCols(grid, this._commitmentColumns);

        if(wbManager.WorkbookVersion === 2) {

            for(let q = wbManager.firstQuarter; q <= wbManager.lastQuarter; q++) {
                const qtrStartEndDates = getQuarterStartEndDates(q, wbManager.workbookYear);

                if(q === wbManager.firstQuarter) {
                    qtrStartEndDates.startDate = new Date(1);
                }

                const qtrComitCols = this._commitmentColumns
                    .filter(c => DateTimeFormat.isDateBetween(DateTimeFormat.ensureAsDate(c.commitmentDate), qtrStartEndDates.startDate, qtrStartEndDates.endDate))
                    .sort((a,b) => DateTimeFormat.ensureAsDate(a.commitmentDate).getTime() - DateTimeFormat.ensureAsDate(b.commitmentDate).getTime());

                const asOfNumber = wbManager.frequency === 'BY_QUARTER' ? `Q${q}` : wbManager.workbookYear;

                let lastCol:CommitmentColumn;
                
                if(qtrComitCols?.length) {
                    lastCol = qtrComitCols[qtrComitCols.length-1];
                } else {
                    lastCol = this._commitmentColumns.find(c => c.quarterSum && c.quarterNumber === q - 1)!;
                }

                const newColid = grid.insertCol(lastCol.gridColId, 1, NewColPosition.rightOfDestination);
                
                this._commitmentColumns.push({
                    gridColId: newColid,
                    label: `Commitment as of ${asOfNumber}`,
                    quarterSum: true,
                    commitmentDate: DateTimeFormat.isoDateString(qtrStartEndDates.endDate),
                    quarterNumber: q
                } as CommitmentColumn);

                allocationsCurrColId = grid.getNextColId(newColid);
            }

            allocationsCurrColId = grid.getNextColId(allocationsCurrColId); // spacer
        }

        //debugPrintCommitCols(grid, this._commitmentColumns);

        //Upgrade Allocations Columns by adding in the appropriate gridColId
        while (allocationsCurrColId !== ""){
            this._allocationRulesColumns.forEach(col => {
                col.gridColId = allocationsCurrColId;
                allocationsCurrColId = grid.getNextColId(allocationsCurrColId);
            });
            pitdCurrColId = grid.getNextColId(allocationsCurrColId);
            allocationsCurrColId = "";
        }

        //debugPrintAllocCols(grid, this._allocationRulesColumns);

        if(wbManager.WorkbookVersion === 2) {
            // note: the rest of commit cols from V2 will get automatically upgraded from commit manager

            const prevFirstAllocColSet = this._allocationRulesColumns;
            let currCol = grid.getPrevCol(this._allocationRulesColumns[0].gridColId);

            this._allocationRulesColumns = [];

            for(let q=wbManager.firstQuarter; q<=wbManager.lastQuarter; q++) {
                const copy = cloneDeep(prevFirstAllocColSet);
                
                copy.forEach(allocCol => {
                    currCol = grid.getNextColId(currCol);

                    let allocColLabel: string;

                    switch(allocCol.code) {
                        case 'COMMITMENT_PERCENT':
                            allocColLabel = 'Commitment % at <dynamic date>';
                            break;
                        case 'LP':
                            allocColLabel = 'LP % at <dynamic date>';
                            break;
                        case 'GP':
                            allocColLabel = 'GP % at <dynamic date>';
                            break;
                        default:
                            allocColLabel = allocCol.label;
                    }

                    if(q !== wbManager.firstQuarter) {
                        allocCol.gridColId = grid.insertCol(currCol, 1, NewColPosition.destination);
                        currCol = allocCol.gridColId;
                    }

                    allocCol.label = allocColLabel;
                    allocCol.quarterNumber = q;
                });            

                this._allocationRulesColumns = this._allocationRulesColumns.concat(copy);
            }

            pitdCurrColId = this._allocationRulesColumns[this._allocationRulesColumns.length-1].gridColId;
            pitdCurrColId = grid.getNextColId(pitdCurrColId, 2);
        }

        //debugPrintAllocCols(grid, this._allocationRulesColumns);


        //Reference for ITD column generator
        const transTypes = this._transactionColumns.filter(column =>  column.quarterNumber === wbManager.lastQuarter && column.isUserColumn === false);
        let index = 0;

        //Upgrade PITD Columns by creating new structures
        while (pitdCurrColId !== "") {
            const val = grid.getCellValue("4", pitdCurrColId);
            
            if(val === StringConstants.beginingBalanceStr){
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: pitdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.BEGINING_BALANCE_PITD_TYPE
                });

                pitdCurrColId = grid.getNextColId(pitdCurrColId);
            } else if (val === StringConstants.endingBalanceStr) {
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: pitdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.ENDING_BALANCE_PITD_TYPE
                });

                pitdCurrColId = grid.getNextColId(pitdCurrColId);
            } else if (val) {
                const typeObj: any = transTypes.find(type => type.label === val)!;

                this._itdColumns.push({
                    transTypeId: typeObj.transTypeId,
                    gridColId: pitdCurrColId,
                    index: index,
                    label: typeObj.label,
                    metricSign: 0,
                    colType: ITDType.STANDARD_PITD_TYPE
                });
                pitdCurrColId = grid.getNextColId(pitdCurrColId);
            } else {
                qtdCurrColId = grid.getNextColId(pitdCurrColId);
                pitdCurrColId = "";
            }

            index++;
        }        

        //Upgrade QTD Columns by creating new structures
        for(let currQuarter = wbManager.firstQuarter; currQuarter <= wbManager.lastQuarter; currQuarter++) {
            const quarterCols = this._transactionColumns
                    .filter(tc => tc.quarterNumber === currQuarter)
                    .sort((a,b) => a.index-b.index);

            this._transactionColumns.push({
                index: 0,
                label: StringConstants.beginingBalanceStr,
                colType: TransactionColumnType.BEGINING_BALANCE,
                gridColId: qtdCurrColId,
                isUserColumn: false,
                allocationCode: undefined,
                allocationCodeQuarterReference: undefined,
                metricSign: 0,
                useArkTransactionValues: false,
                locked: true,
                quarterNumber: currQuarter,
                transSubmitError: false,
                transTypeId: ''
            });

            qtdCurrColId = grid.getNextColId(qtdCurrColId);

            quarterCols.forEach((qc, index) => {
                qc.gridColId = qtdCurrColId;
                qc.colType = TransactionColumnType.STANDARD_TRANSACTION_TYPE;
                qc.index = index+1;

                if(qc.allocationCode) {
                    qc.allocationCodeQuarterReference = qc.quarterNumber;
                }

                qtdCurrColId = grid.getNextColId(qtdCurrColId);
            });

            this._transactionColumns.push({
                index: quarterCols.length + 1,
                label: StringConstants.endingBalanceStr,
                colType: TransactionColumnType.ENDING_BALANCE,
                gridColId: qtdCurrColId,
                isUserColumn: false,
                allocationCode: undefined,
                allocationCodeQuarterReference: undefined,
                metricSign: 0,
                useArkTransactionValues: false,
                locked: true,
                quarterNumber: currQuarter,
                transSubmitError: false,
                transTypeId: ''
            });

            qtdCurrColId = grid.getNextColId(qtdCurrColId, 2);
        }


        if(wbManager.includeYtd) {
            ytdCurrColId = qtdCurrColId;
            index = 0;
        } else {
            ytdCurrColId = "";
        }

        //Upgrade YTD Columns by creating new structures
        while (ytdCurrColId !== "") {
            const val = grid.getCellValue("4", ytdCurrColId);
            
            if(val === StringConstants.beginingBalanceStr){
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: ytdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.BEGINING_BALANCE_YTD_TYPE
                });

                ytdCurrColId = grid.getNextColId(ytdCurrColId);
            } else if (val === StringConstants.endingBalanceStr) {
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: ytdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.ENDING_BALANCE_YTD_TYPE
                });

                ytdCurrColId = grid.getNextColId(ytdCurrColId);
            } else if (val) {
                const typeObj: any = transTypes.find(type => type.label === val)!;

                this._itdColumns.push({
                    transTypeId: typeObj.transTypeId,
                    gridColId: ytdCurrColId,
                    index: index,
                    label: typeObj.label,
                    metricSign: 0,
                    colType: ITDType.STANDARD_YTD_TYPE
                });
                ytdCurrColId = grid.getNextColId(ytdCurrColId);
            } else {
                itdCurrColId = grid.getNextColId(ytdCurrColId);
                ytdCurrColId = "";
            }

            index++;
        }

        //Upgrade ITD Columns with new structures
        if(wbManager.includeItd) {
            index = 0;
            if(!wbManager.includeYtd) {
                itdCurrColId = grid.getNextColId(qtdCurrColId, 2);
            }
        } else {
            itdCurrColId = "";
        }

        while (itdCurrColId !== "") {
            const val = grid.getCellValue("4", itdCurrColId);
            
            if(val === StringConstants.beginingBalanceStr){
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: itdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.BEGINING_BALANCE_ITD_TYPE
                });
                itdCurrColId = grid.getNextColId(itdCurrColId);
            } else if (val === StringConstants.endingBalanceStr) {
                this._itdColumns.push({
                    transTypeId: '',
                    gridColId: itdCurrColId,
                    index: index,
                    label: val,
                    metricSign: 0,
                    colType: ITDType.ENDING_BALANCE_ITD_TYPE
                });

                itdCurrColId = grid.getNextColId(itdCurrColId);
            } else if (val) {
                const typeObj: any = transTypes.find(type => type.label === val)!;

                this._itdColumns.push({
                    transTypeId: typeObj.transTypeId,
                    gridColId: itdCurrColId,
                    index: index,
                    label: typeObj.label,
                    metricSign: 0,
                    colType: ITDType.STANDARD_ITD_TYPE
                });
                itdCurrColId = grid.getNextColId(itdCurrColId);
            } else {
                itdCurrColId = "";
            }

            index++;
        }

        wbManager.WorkbookVersion = 4;
    }

    public renderHeaderInfo() {
        const longStartDateStr = this._workbookSheetsManager.getLongDateForQuarter();
        const value = `${this._workbookSheetsManager.reportName} - ${longStartDateStr}`;

        this._grid.setCellValue('1', 'A', value, CellFormats.Text_Unformatted);
    }

    private renderColumnSpacer(destination: string, newColPosition: NewColPosition = NewColPosition.rightOfDestination) {
        const grid = this._grid;
        const newColId = newColPosition === NewColPosition.rightOfDestination ? grid.getNextColId(destination) : destination;
        
        this._grid.setColWidth(newColId, ColWidth.spacer);

        return newColId;
    }

    public onToolbarButtonClick(buttonName: string) {
        if (buttonName === "AllocationsConfig") {
            this.onShowConfig(WbConfigDialogType.Standard);
        } else if(buttonName === "RollForward") {
            if(this._workbookSheetsManager.frequency === "BY_YEAR" || this._workbookSheetsManager.lastQuarter === 4) {
                this.workbookSheetsManager.beginWorkbookSaving();
                this.onShowRolloverNewWB(true);
            } else {
                this.onShowConfig(WbConfigDialogType.Rollforward);
            }
        } else if(buttonName === "GetFromGl") {
            this.onShowGlConnectDialog(true);
        } else if(buttonName === "SubmitToPcap") {
            this._workbookSheetsManager.cancelCurrentMode();
            this.onShowSubmitToPcapSaveDialog(true);
        }
    }

    public handleConfigOKClicked(wbConfigDialogType: WbConfigDialogType, workbook: Workbook) {
        this._workbookSheetsManager.setWorkbookData(workbook, false, false);

        switch(wbConfigDialogType) {
            case WbConfigDialogType.Standard:
                this.updateConfigChanges(workbook);
                break;
            case WbConfigDialogType.Rollforward:
                this.executeRollForward(workbook);
                break;
        }
    }

    public handleGlConnectEntities(glEntries: GlEntry[]) {
        this._transactionColumnsManager.handleGlConnectEntities(glEntries);
    }
    
    private async updateConfigChanges(workbook: Workbook) {
        await this.onShowLoadingMessage('Updating changes in workbook...');

        this._workbookSheetsManager.reportName = workbook.reportName;


        const quarterCols = this._transactionColumns.filter(col => col.quarterNumber === this._workbookSheetsManager.lastQuarter);

        this._transactionColumnsManager.updateCurrentQuarterTransactionColumns(quarterCols);

        this.renderHeaderInfo();
        this._transactionColumnsManager.renderColumns(undefined, quarterCols.map(c => c.gridColId));

        this._workbookSheetsManager.changeMade();

        await this.onShowLoadingMessage(undefined);
    }

    private async executeRollForward(workbook: Workbook) {
        const wbManager = this._workbookSheetsManager;
        const lastQuarter = wbManager.lastQuarter;

        this.debugPrintoutAllData('Start Follforward');

        await this.onShowLoadingMessage('Allocations workbook is being rolled forward and calculated, this may take a minute.');

        const investorCommitments = await this.getInvestorsAndCommitments();
        const rowManager = this._investorRowManager;
        const updatedGridRowIDs = rowManager.updateInvestorFromInvestorCommitments(investorCommitments);

        this._commitmentColumnsManager.updateCommitmentsColumns(investorCommitments);
        this._allocationRulesManager.insertNewColumnsForQuarter();

        this._pitdColumnsManager.updateSummaryValues();

        const newTransactionsCols = workbook.transactionColumns.filter(tc => tc.quarterNumber === lastQuarter && tc.colType === TransactionColumnType.STANDARD_TRANSACTION_TYPE);

        await this._transactionColumnsManager.insertNewColumnsForQuarter(newTransactionsCols);
        
        if(updatedGridRowIDs?.length) {
            this._investorColumnsManager.renderColumns(updatedGridRowIDs);
        }

        this._commitmentColumnsManager.renderColumns();
        this._allocationRulesManager.renderColumns(updatedGridRowIDs);
        this._allocationRulesManager.rollForwardCustomRules();
        this._pitdColumnsManager.renderColumns(updatedGridRowIDs);
        this._transactionColumnsManager.renderColumns(updatedGridRowIDs);

        if(wbManager.includeYtd) {
            this._ytdColumnsManager!.renderColumns(updatedGridRowIDs);
        }

        if(wbManager.includeItd) {
            this._itdColumnsManager!.renderColumns(updatedGridRowIDs);
        }

        this.renderHeaderInfo();
        this._grid.AutoUpdateRowHeight('4');

        this._workbookSheetsManager.changeMade();

        this.debugPrintoutAllData('End Follforward');
        
        await this.onShowLoadingMessage(undefined);
    }


    private getColumnManagerFromColumn(colId: string) {
        return this._allColumnManagers.find(cm => cm.containsColumn(colId))!;
    }

    public getFirstUserFreeSpaceColumn() {
        const wb = this._workbookSheetsManager;
        let lastCol: string;

        if(wb.includeItd) {
            lastCol = this._itdColumnsManager!.endColumnId;
        } else if (wb.includeYtd) {
            lastCol = this._ytdColumnsManager!.endColumnId;
        } else {
            lastCol = this._transactionColumnsManager.endColumnId;
        }

        return this._grid.getNextColId(lastCol);
    }

    public isUserFreeSpaceColumn(colId: string) {
        const grid = this._grid;
        const colPos = grid.getColPosition(colId, 1);
        const firstFreeSpacePos = grid.getColPosition(this.getFirstUserFreeSpaceColumn(), 1);

        return colPos >= firstFreeSpacePos;
    }

    public isUserFreeSpaceRow(rowId: string) {
        const grid = this._grid;
        const rowPos = grid.getRowPosition(rowId);
        const reconcilerRowId = this._investorRowManager.getReconcilerRowId();
        const reconcilerRowPos = grid.getRowPosition(reconcilerRowId);

        return rowPos > reconcilerRowPos;
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        if(this.isUserFreeSpaceColumn(colId) || this.isUserFreeSpaceRow(rowID)) {
            return true;
        }

        const colmanager =  this.getColumnManagerFromColumn(colId);

        if(colmanager) {
            return colmanager.onStartEdit(rowID, colId);
        }

        return false;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        if(this.isUserFreeSpaceColumn(colId) || this.isUserFreeSpaceRow(rowID)) {
            this._workbookSheetsManager.changeMade();
            return true;
        }

        const colmanager = this.getColumnManagerFromColumn(colId);

        if(colmanager) {
            const canEdit = colmanager.onEndEdit(rowID, colId, save, value);

            if(canEdit) {
                this._workbookSheetsManager.changeMade();
            }

            return canEdit;
        }

        return false;        
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        if(this.isUserFreeSpaceColumn(toColId)) {
            this._workbookSheetsManager.changeMade();

            return {
                validColumnPosition: true,
                newColumnIDs: cols
            };
        }

        this.onColsAddAsync(cols, toColId, right, empty);

        //  actuall insert will be handled in col manager
        return { validColumnPosition: false };
    }

    public async onColsAddAsync(cols: string[], toColId: string, right: boolean, empty: boolean) {
        const destColId = right ? toColId : this._grid.getPrevCol(toColId);
        const colmanager = this.getColumnManagerFromColumn(destColId);
        let colsAddResponse: colsAddResponse;

        if(colmanager) {
            await this.onShowLoadingMessage('Adding column');
            colsAddResponse = colmanager.onColsAdd(cols, destColId, right, empty);

            if(colsAddResponse.validColumnPosition) {
                colmanager.renderColumns(undefined, colsAddResponse.newColumnIDs);
            }

            await this.onShowLoadingMessage(undefined);            
        } else {
            colsAddResponse = { validColumnPosition: false };
        }

        if(colsAddResponse.validColumnPosition) {
            this._workbookSheetsManager.changeMade();
        } else {
            this.onShowMessageDialog({ title: 'Invalid New Column Position', message: 'You can not add a column here' });
        }
    }

    public onRowAdd(parentRowId: string): boolean {
        return false;
    }

    public onCanColDelete(colId: string): boolean {
        return false;
    }

    public onCanRowDelete(rowID: string): boolean {
        return false;
    }

    public onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean {
        const grid = this._grid;
        const invalidCells: string[] = [];

        let colId = startColID;

        for(let c=0; c<numOfCols; c++) {
            let rowId = startRowId;

            for(let r=0; r<numOfRows; r++) {
              
              const isEditable = this.onEndEdit(rowId, colId, false, '');

              if(!isEditable) {
                const colCaption = this._grid.getColCaption(colId);
                const rowCaption = this._grid.getRowCaption(rowId);

                invalidCells.push(`${colCaption}${rowCaption}`);
              }

              rowId = grid.getNextRowId(rowId);
            }

            colId = grid.getNextColId(colId);
        }

        const canPaste = invalidCells.length >= 0;

        if(canPaste) {
            this.onShowInvalidPasteText(invalidCells.join(','));
            this._workbookSheetsManager.changeMade();

            return true;
        } else {
            return false;
        }
    }

    public debugPrintoutAllData(startMessage: string) {
        const wb = this._workbookSheetsManager;

        debugPrintAllCols(
            startMessage,
            this._grid, 
            this.commitmentColumnsManager.commitmentColumns, 
            this.allocationRulesManager.allocationRulesColumns, 
            this.pitdColumnsManager.pitdTransactionColumns, 
            this.transactionColumnsManager.transactionColumns, 
            wb.includeYtd ? this.ytdColumnsManager!.ytdTransactionColumns: [], 
            wb.includeItd ? this.itdColumnsManager!.itdTransactionColumns: []
        );
    }
}