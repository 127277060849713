import { ArrowDropDown, FolderOpenOutlined } from "@mui/icons-material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { Typography } from "@mui/material";
import React, { FC, ReactElement } from "react";

import { ChildListContainer, ClickableBox, ClickableSpan, HoverableSpan, List, ListContainer } from "./FolderTree.styles";

type Folder = {
  id: string;
  name: string;
  folders: Array<Folder> | null;
}

interface FolderProps {
  structure: Folder[];
  onSelect: (id?: string) => void;
  selectedFolder?: string | null;
}

interface FolderNodeProps {
  expandable: boolean;
  id: string;
  name: string;
  onExpand?: (id: string) => void;
  selected?: boolean;
  onSelect?: (id: string) => void;
  folders: ReactElement | null | undefined;
  expanded?: boolean;
}

export const FolderNode: FC<FolderNodeProps> = ({ id, name, expandable, expanded = false, onExpand = () => {}, selected = false, onSelect = () => {}, folders }: FolderNodeProps): ReactElement => {
  return (
    <List>
      <ClickableBox>
        <ClickableSpan onClick={() => onExpand(id)} visible={expandable}>
          { expanded ? <ArrowDropDown color="primary" /> : <ArrowRight color="primary" /> }
        </ClickableSpan>
        <span>
          <FolderOpenOutlined sx={{ color: theme => theme.palette.grey[500] }} />
        </span>
        <HoverableSpan onClick={() => onSelect(id)} selected={selected}>
          <Typography variant="filterUnchecked">{name}</Typography>
        </HoverableSpan>
      </ClickableBox>
      {expanded && folders}
    </List>
  );
};

export const FolderTree: FC<FolderProps> = ({
  structure,
  onSelect = () => {},
  selectedFolder,
}: FolderProps): ReactElement => {

  const [expandedFolders, setExpandedFolders] = React.useState<Record<string, boolean>>({});

  const handleSelect = (id: string) => {
    onSelect(id);
  };

  
  const expand = (item: any): ReactElement => {
    if(item?.length > 0) {
      return (
        <ChildListContainer>
          {
            item.map((sub_item: any) => (
              <FolderNode
                id={sub_item.id}
                name={sub_item.name}
                key={sub_item.id}
                selected={selectedFolder === sub_item.id}
                onSelect={handleSelect}
                expandable={Array.isArray(sub_item.folders) && sub_item.folders.length > 0}
                expanded={!!expandedFolders[sub_item.id]}
                onExpand={(id) => {
                  setExpandedFolders((expanded) => ({
                    ...expanded,
                    [id]: !expanded[id]
                  }));
                }}
                folders={(Array.isArray(sub_item.folders) && sub_item.folders.length > 0) ? expand(sub_item.folders): null}
              />
            ))
          }
        </ChildListContainer>
      );
    }
    return <></>;
  };

  return (
    <ListContainer>
      {structure.map(item => (
        <FolderNode
          id={item.id}
          name={item.name}
          key={item.id}
          selected={selectedFolder === item.id}
          onSelect={handleSelect}
          expandable={Array.isArray(item.folders) && item.folders.length > 0}
          expanded={!!expandedFolders[item.id]}
          onExpand={(id) => {
            setExpandedFolders((expanded) => ({
              ...expanded,
              [id]: !expanded[id]
            }));
          }}
          folders={(Array.isArray(item.folders) && item.folders.length > 0) ? expand(item.folders): null}
        />
      ))}
    </ListContainer>
  );
};