import { Grid } from "@mui/material";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from "../../../../components/DetailPanel/DetailPanel.styles";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import StatusLabel from "../../../../components/StatusLabel/StatusLabel";
import { M_DASH_UNICODE } from "../../../../utils/constants/constants";
import { LedgerStateTypes } from "../../../../utils/types/arkGLAccount.type";
import { DetailsType } from "../../../../utils/types/form.type";
import { SelectedLedger } from "../../../../utils/types/glSetup.type";
import { useGLSetupDetails } from "./GLSetupDetails.hooks";
import { KeyLabel, KeyValuePair, ValueLabel } from "./GLSetupDetails.styles";

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedLedger: SelectedLedger;
  fetchAllLedgers: Function;
  fundId?: string;
  arkGlLocked: boolean;
  glLocked: boolean;
}

export const GLSetupDetailsReadOnly: React.FC<Props> = ({
  onDetailClose,
  selectedLedger,
  fetchAllLedgers,
  fundId,
  arkGlLocked,
  glLocked
}: Props) => {
  const { type, ledger } = selectedLedger;
  const isNewLedger = type === DetailsType.New;

  const {
    isLoading,
    register,
    closeDrawer,
    toggleDrawer,
    currencyList,
    fundList,
    status,
  } = useGLSetupDetails({
    ledger,
    onClose: onDetailClose,
    fetchAllLedgers,
    isNewLedger,
    fundId,
    arkGlLocked,
    glLocked,
  });

  const currency = currencyList.find((c) => c.id === ledger?.currency);
  const fund = fundList.find((f) => f.id === ledger?.fundId);

  return (
    <>
      <DetailPanel
        id="detail_panel_ledger"
        title={isNewLedger ? "Add Ledger" : ledger?.name}
        open={Boolean(type)}
        variant={"temporary"}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <FormBox>
          <Panel id="details_panel">
            <Typography variant="h4">Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <KeyValuePair>
                  <KeyLabel>Ledger Name</KeyLabel>
                  <ValueLabel>{ledger?.name || M_DASH_UNICODE}</ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={2} mt={1}>
                {status.id && (
                  <StatusLabel
                    color={status.color}
                    isUpperCase={false}
                    label={status.name}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Currency</KeyLabel>
                  <ValueLabel>
                    {(currency && currency.label && currency.label) ||
                      M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Description</KeyLabel>
                  <ValueLabel>
                    {ledger?.description || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>

              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Fund</KeyLabel>
                  <ValueLabel>
                    {(fund && fund.name && fund.name) || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
            </Grid>
          </Panel>
        </FormBox>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
        >
          <Button
            id={"btn_account_close"}
            variant="outlined"
            onClick={closeDrawer}
            text={"Close"}
            color={"secondary"}
            fullWidth
          />
        </HorizontalBox>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={"progress_glsetup_panel"}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading} account...` : ""}
        />
      </DetailPanel>
    </>
  );
};
