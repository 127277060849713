import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Typography } from "@mui/material";
import React, { ReactElement, useMemo } from "react";

import IconArrowLeft from "../../../assets/images/icons/icon_arrow_left.svg";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../../components/Buttons/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { ActionLink } from "../../../components/Link/ActionLink/ActionLink";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import TeamDetails from "./teamDetails/TeamDetails";
import { useTeamList } from "./TeamList.hooks";
import { HeaderRow, StyledDataGrid } from "./TeamList.styles";
import TeamMembers from "./teamMembers/TeamMembers";

export const TeamList: React.FC = () => {
  const {
    isLoading,
    teamsResponse,
    headerList,
    selectedTeam,
    openTeam,
    addMember,
    selectedTeamMember,
    showDeleteConfirmationBox,
    isEditable,
    teamRoles,
    handleUpsertTeamButton,
    onTeamDetailClose,
    setOpenTeam,
    handleOnCellClick,
    goBack,
    handleTeamMember,
    setTeamsResponse,
    onDeleteConfirm,
    onDeleteCancel,
    handleDeleteMemberAction,
  } = useTeamList();

  const generateHeader = (
    team: Record<string, any> | null | undefined
  ): ReactElement => {
    if (team) {
      return (
        <HeaderRow>
          <Stack flexDirection="row" gap={1}>
            <ActionLink id="go_back" onClick={goBack}>
              <img alt="go back icon" src={IconArrowLeft} />
            </ActionLink>
            <Typography variant="pageTitle">{team.name}</Typography>
          </Stack>
          {isEditable && (
            <Button
              id="btn_add_new_team_button"
              variant="contained"
              icon={<AddIcon />}
              text="Add Team Member"
              color={"primary"}
              onClick={() => handleTeamMember("AddNewMember")}
            />
          )}
        </HeaderRow>
      );
    }
    return (
      <HeaderRow>
        <Typography variant="pageTitle">Teams</Typography>
        {isEditable && (
          <Button
            id="btn_add_new_team_button"
            variant="contained"
            icon={<AddIcon />}
            text="Create New Team"
            color={"primary"}
            onClick={() => handleUpsertTeamButton(AddNewButtonOptions.AddNew)}
          />
        )}
      </HeaderRow>
    );
  };

  const renderHeader = useMemo(() => generateHeader(openTeam), [openTeam]);

  const uniqueEmployeesIDs = new Set();
  const uniqueEmployeesList = new Set();

  openTeam?.employees?.forEach((member: any) => {
    if (!uniqueEmployeesIDs.has(member.id)) {
      uniqueEmployeesIDs.add(member.id);
      uniqueEmployeesList.add(member);
    }
  });

  const openTeamMemberDrawer: boolean = addMember || !!selectedTeamMember;

  return (
    <DataWrapperBox id="teamlist_main">
      <ProgressModal id="modal_teamlist_loading" showProgress={isLoading} />
      {renderHeader}
      {!isLoading && teamsResponse && teamsResponse?.length && (
        <DataGrid
          id="teamlist_data_grid"
          dataList={
            openTeam ? Array.from(uniqueEmployeesList) : teamsResponse
          }
          headerList={headerList}
          minHeight="100px"
          onCellClick={handleOnCellClick}
          cellOverflow="scroll"
          autoHeight={false}
          checkboxSelection={false}
        />
      )}
      {!!selectedTeam?.type && (
        <TeamDetails
          onTeamDetailClose={onTeamDetailClose}
          selectedTeam={selectedTeam}
          setTeamResponse={setTeamsResponse}
        />
      )}
      {openTeam && openTeamMemberDrawer && (
        <TeamMembers
          selectedMember={selectedTeamMember}
          selectedTeam={openTeam}
          setTeamResponse={setTeamsResponse}
          setOpenTeam={setOpenTeam}
          onTeamDetailClose={onTeamDetailClose}
          onDelete={handleDeleteMemberAction}
          openDrawer={true}
          roles={teamRoles}
        />
      )}
      {
        <DeleteConfirmationDialog
          open={showDeleteConfirmationBox}
          id="delete_confirmation_modal"
          primaryButtonAction={onDeleteConfirm}
          secondaryButtonAction={onDeleteCancel}
        />
      }
    </DataWrapperBox>
  );
};
