import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";

import useTransactionTypeFilter from "./useTransactionTypesFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  nameSelectOnly?: boolean;
  onChange?: (data: any | null | string, transactionType: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  isShrink?: boolean;
  isPlaceHolder?: boolean;
};

type TransactionTypeValue = any | null | undefined;

const TransactionTypeFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = "small",
  id = "transaction_type_filter",
  textFieldID = "transaction_type_filter_text_field",
  nameSelectOnly = false,
  isShrink = false,
  isPlaceHolder = true,
}: Props) => {
  const { transactionTypesList, loading } = useTransactionTypeFilter();
  const onSelectChange = (_: any, transactionType: any) => {
    if (!transactionType) {
      return;
    }
    onChange?.(
      idSelectOnly || nameSelectOnly
        ? transactionType?.[idSelectOnly ? "id" : "name"] ?? ""
        : transactionType,
      transactionType
    );
  };
  const selectedValue: TransactionTypeValue =
    idSelectOnly || nameSelectOnly
      ? transactionTypesList?.find(
          (item: any) => item[idSelectOnly ? "id" : "name"] === value
        ) ?? null
      : (value as TransactionTypeValue);

  return (
    <Autocomplete
      options={transactionTypesList || []}
      id={id}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.name === selected?.name
      }
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      disabled={disabled || loading}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={isPlaceHolder ? placeholder : ""}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ""}
          InputLabelProps={
            (isShrink && {
              shrink: true,
            }) ||
            {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(TransactionTypeFilter);
