import IconNewCircle from "../../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../../assets/images/icons/icon_upload.svg";
import { ButtonOptions } from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import { Transaction } from "../../../../utils/types/capitalCalls.type";
import { AddNewButtonOptions } from "../../../../utils/types/common.type";

export const FETCH_TRANSACTION_ERROR = "Error in fetching transactions.";
export const DELETE_TRANSACTION_ERROR = "Error in deleting transactions.";
export const DELETE_TRANSACTION_SUCCESS = "Transactions deleted successfully.";
export const DOWNLOAD_TRANSACTION_ERROR = "Error in downloading transactions.";
export const CREATE_TRANSACTION_ERROR = "Error in creating transaction.";
export const CREATE_TRANSACTION_SUCCESS = "Transaction created successfully.";
export const UPDATE_TRANSACTION_ERROR = "Error in updating transaction.";
export const UPDATE_TRANSACTION_SUCCESS = "Transaction updated successfully.";
export const REVIEW_FETCH_ERROR = "Error in fetching reviews";
export const PORTAL_MAPPING = [
  {
    id: "Distribution",
    name: "Distribution",
  },
  {
    id: "CapitalCall",
    name: "CapitalCall",
  },
];
export const TRANSACTION_FORM_DEFAULT_VALUE: Transaction = {
  id: "",
  name: "",
  date: new Date(),
  dateString: null,
  displayOrder: "",
  quarter: null,
  metricSign: null,
  metricFractionDigit: 0,
  amount: 0,
  components: [],
  useMetric: false,
  fund: "",
  investor: "",
  type: "",
};
export const TransactionOptions: ButtonOptions[] = [
  {
    label: "Add Single Transaction",
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
  {
    label: "Upload From Template",
    id: AddNewButtonOptions.UploadFromTemplate,
    icon: IconUpload,
    type: "upload",
  },
];

export enum DELETE_ACTION_TYPE {
  DELETE_ALL = "DELETE_ALL",
  DELETE_SELECTED = "DELETE_SELECTED",
}
