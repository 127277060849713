import { Box, Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";

import {
  ACCORDION_CHILD_BACKGROUND,
  DATAGRID_TOP_HEADER,
  FONT_PRIMARY,
  GRID_HEADER_BACKGROUND_COLOR,
  GRID_HEADER_BORDER_COLOR,
  LIST_DIVIDER,
  ROW_HOVER_BACKGROUND_COLOR,
} from "../../../../styles/colors";

export const ColumnName = styled("div")(() => ({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  paddingLeft: "5px",
  width: "100%",
  alignItems: "center",
}));

export const StyledDataGridPro = styled(DataGridPro)<{ rowamount: number }>`
  border: 1px solid transparent;
  border-radius: 0;
  .MuiDataGrid-row:hover {
    background-color: ${ROW_HOVER_BACKGROUND_COLOR};
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  align-items: baseline;
  .MuiBox-root {
  }

  .MuiDataGrid-columnHeaders {
    min-height: 85px !important;
    position: inherit;
    align-items: end;
    z-index: 1;
  }

  .MuiDataGrid-columnHeader {
    border-left: 1px solid ${GRID_HEADER_BORDER_COLOR};
    padding: 0px;
    color: ${DATAGRID_TOP_HEADER};
    .MuiTypography-root {
      margin-right: -25px;
    }
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    align-items: flex-end;
    display: flex;
    width: 100%;
    justify-content: center;
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
    background: ${GRID_HEADER_BACKGROUND_COLOR};
    height: 30px;
    overflow: visible;
  }

  .MuiDataGrid-pinnedColumnHeaders {
    position: inherit !important;
    align-items: end;
  }

  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  .MuiDataGrid-columnSeparator {
    color: transparent;
  }

  .MuiDataGrid-columnSeparator:hover {
    color: transparent;
  }

  .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
  }

  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
    .MuiDataGrid-sortIcon {
    opacity: 0.3;
  }

  .MuiDataGrid-virtualScroller {
    margin-top: -1px !important;
    padding-bottom: 52px;
  }

  .MuiDataGrid-cell--withRenderer {
    justify-content: center;
    padding-left: 0px;
  }

  .MuiDataGrid-columnHeaderTitle {
    height: 100%;
    display: flex;
    align-items: center;
    color: ${FONT_PRIMARY};
  }

  .MuiDataGrid-columnHeaderTitle {
    width: 100%;
    padding-left: 8px;
  }

  .MuiDataGrid-main {
    width: 100%;
  }

  .MuiDataGrid-columnHeaderTitleContainerContent {
    overflow: visible;
    :last-child{
      align-self: center;
      margin-left: -10px;
    }
    
  }

  .MuiDataGrid-footerContainer {
    display: ${(props) => (props.rowamount > 0 ? "flex" : "none")}
  }

  .MuiDataGrid-columnHeaderTitle {
    height: 30px;
  }

  .MuiDataGrid-virtualScrollerContent{
    height: ${(props) => (props.rowamount > 0 ? "" : "0px !important")}
`;

export const StyledTypography = styled(Typography)`
  white-space: break-spaces;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
`;

export const ParentCheckbox = styled(Checkbox)`
  padding-top: 8px;
  padding-bottom:3px;
  margin: 0px;
  border-radius: 0px;
  width:100%;
  ${(props) =>
    props.disabled
      ? `
           color:#007aff80 !important; 
        `
      : `
          
        `};
  }
`;

export const ChildCheckbox = styled(Checkbox)`
  padding-left: 0px;
  margin-left: -16px;
  padding: 9px;
  ${(props) =>
    props.disabled
      ? `
           color:#007aff80 !important; 
        `
      : `
          
        `};
`;

export const ChildRow = styled(GridRow)`
  background-color: ${ACCORDION_CHILD_BACKGROUND};
  .MuiDataGrid-cell:first-of-type {
    border-bottom: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${FONT_PRIMARY} !important;
    justify-content: flex-end;
  }
  
  .MuiDataGrid-treeDataGroupingCell {
    justify-content: flex-end;
  }

  .MuiDataGrid-cell {
    border-color: ${LIST_DIVIDER} !important;
    border-bottom: none;
  }

  .MuiDataGrid-treeDataGroupingCellToggle {
    margin-right: -10px;
  }
`;

export const ParentRow = styled(GridRow)`
  .MuiDataGrid-cell {
    border-color: ${LIST_DIVIDER} !important;
  }

  .MuiDataGrid-cell:first-of-type {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${FONT_PRIMARY} !important;
    padding-left: 2px;
  }

  .MuiDataGrid-treeDataGroupingCellToggle {
    margin-right: 5px;
  }
`;

export const TopHeaderBox = styled(Box)`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const WrapperCheckBox = styled("div")`
    position: relative;
}`;

export const CheckboxOverlay = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const AddInvestorsBox = styled(Box)`
  width: 100%;
  border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
  border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
  height: 37px;
  width: 100%;
  display: inline-flex;
  background-color: white;
  padding-left: 10px;
  margin-top: -2px;
  z-index: 1;
`;

export const InvestorsFilterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 292px;
  .MuiFormControl-root {
    margin-left: 0px !important;
  }
  .MuiOutlinedInput-root {
    max-width: 270px;
  }
`;

export const InvestorsListBox = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 300px;
  padding-left: 20px;
  padding-right: 20px;
  label:first-of-type {
    .MuiTypography-root {
      font-weight: bold;
    }
    .MuiSvgIcon-root {
      font-size: 25px;
    }
  }
`;

export const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  .MuiButton-root:focus {
    border: none !important;
  }
  .MuiButton-root {
    min-width: 50px !important;
    margin-right: 10px;
  }
`;

export const GridBox = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  white-space: nowrap;
`;