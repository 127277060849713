import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useEffect, useRef, useState } from 'react';

import {
  DashboardWidget,
  SummaryCardListItem,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { SummaryCard } from '../summaryCards/SummaryCard';
import {
  DragAndDropContainer,
  DraggableItem,
  VisibilityButton,
} from './DragAndDropBox.styles';

interface DraggableGridProps {
  summaryCardOrderItems?: SummaryCardListItem[];
  isEditingWidgets: boolean;
  setIsEditingWidgets: (value: boolean) => void;
  dashboardWidgets: DashboardWidget[];
  saveDashboardWidgets: (dashboardWidgets: DashboardWidget[]) => void;
  handleWidgetVisibilityChange: (name: string, visible: boolean) => void;
  handleWidgetOrderChange(
    widgetType: WidgetType,
    orderedVisibleWidgets: DashboardWidget[]
  ): void;
}

export const DraggableGrid: React.FC<DraggableGridProps> = ({
  summaryCardOrderItems,
  isEditingWidgets,
  setIsEditingWidgets,
  dashboardWidgets,
  saveDashboardWidgets,
  handleWidgetVisibilityChange,
  handleWidgetOrderChange,
}) => {
  const [currentItems, setCurrentItems] = useState(summaryCardOrderItems);

  useEffect(() => {
    const currentItems = summaryCardOrderItems
      ?.filter(
        (item) =>
          dashboardWidgets.find((widget) => widget.name === item.name)?.visible
      )
      .map((SummaryCard) => {
        const matchedWidget = dashboardWidgets.find(
          (widget) => widget.name === SummaryCard.name
        );

        return {
          ...SummaryCard,
          index: matchedWidget?.index!,
          visible: matchedWidget?.visible!,
        };
      })
      .sort((a, b) => a.index - b.index);

    setCurrentItems(currentItems);
  }, [dashboardWidgets, summaryCardOrderItems]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const draggables = Array.from(
      containerRef.current?.querySelectorAll('.draggable') || []
    );
    const container = containerRef.current;

    const handleDragStart = (e: DragEvent) => {
      const target = e.target as HTMLElement;

      target.classList.add('dragging');
    };

    const handleDragEnd = (e: DragEvent) => {
      const target = e.target as HTMLElement;

      target.classList.remove('dragging');

      target.classList.remove('dragging');

      const updatedWidgetOrder: DashboardWidget[] = Array.from(
        container?.children || []
      ).map((child, index) => {
        const itemId = child.getAttribute('data-id');

        return {
          ...dashboardWidgets?.find((item) => item.name === itemId)!,
          index: index,
        };
      });

      handleWidgetOrderChange(WidgetType.SummaryCard, updatedWidgetOrder);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      if (container) {
        const afterElement = getDragAfterElement(
          container,
          e.clientX,
          e.clientY
        );
        const draggable = container.querySelector('.dragging') as HTMLElement;

        if (afterElement === null) {
          container.appendChild(draggable);
        } else {
          container.insertBefore(draggable, afterElement);
        }
      }
    };

    const getDragAfterElement = (
      container: HTMLElement,
      x: number,
      y: number
    ) => {
      const draggableElements = Array.from(
        container.querySelectorAll('.draggable:not(.dragging)')
      ) as HTMLElement[];

      return draggableElements.reduce<{
        offset: number;
        element: HTMLElement | null;
      }>(
        (closest, child, index) => {
          const box = child.getBoundingClientRect();
          const nextBox = draggableElements[index + 1]?.getBoundingClientRect();
          const inRow = y - box.bottom <= 0 && y - box.top >= 0;
          const offset = x - (box.left + box.width / 2);

          if (inRow) {
            if (offset < 0 && offset > closest.offset) {
              return { offset: offset, element: child };
            } else {
              if (
                nextBox &&
                y - nextBox.top <= 0 &&
                closest.offset === Number.NEGATIVE_INFINITY
              ) {
                return { offset: 0, element: draggableElements[index + 1] };
              }
              return closest;
            }
          } else {
            return closest;
          }
        },
        { offset: Number.NEGATIVE_INFINITY, element: null }
      ).element;
    };

    draggables.forEach((draggable) => {
      draggable.addEventListener('dragstart', handleDragStart as EventListener);
      draggable.addEventListener('dragend', handleDragEnd as EventListener);
    });

    container?.addEventListener('dragover', handleDragOver as EventListener);

    return () => {
      draggables.forEach((draggable) => {
        draggable.removeEventListener(
          'dragstart',
          handleDragStart as EventListener
        );
        draggable.removeEventListener(
          'dragend',
          handleDragEnd as EventListener
        );
      });

      container?.removeEventListener(
        'dragover',
        handleDragOver as EventListener
      );
    };
  }, [currentItems, isEditingWidgets]);

  return (
    <DragAndDropContainer ref={containerRef}>
      {currentItems &&
        currentItems?.map((item, index) => {
          if (!item.visible) {
            return null;
          } else {
            return (
              <DraggableItem
                key={item.index}
                className={isEditingWidgets ? 'draggable' : ''}
                isInEditMode={isEditingWidgets}
                draggable={isEditingWidgets ? true : false}
                data-id={item.name}
              >
                {isEditingWidgets && (
                  <VisibilityButton
                    isVisible={item.visible}
                    onClick={() => {
                      handleWidgetVisibilityChange(item.name, false);
                    }}
                  >
                    <RemoveOutlinedIcon />
                  </VisibilityButton>
                )}
                <SummaryCard
                  key={item.index}
                  title={item.title || ''}
                  value={item.value}
                />
              </DraggableItem>
            );
          }
        })}
    </DragAndDropContainer>
  );
};
