import { Box } from "@mui/material";
import React, { ReactElement } from "react";

import { TeamMember } from "../../../../utils/types/user.type";
import { ClientTeamRoleGridContainer, StyledClientSelector } from "../UserDetailPanel.styles";
import ClientSelector from "./ClientSelector/ClientSelector";
import SelectedClientsList from "./ClientsList/SelectedClientsList";
import RolesList from "./RolesList/RolesList";
import TeamsList from "./TeamsList/TeamsList";
import { useClientTeamRoleSelector } from "./useClientTeamRoleSelector.hooks";

type Props = {
  teamMembers?: TeamMember[];
  onChange: (value: TeamMember[]) => void;
  arkClientTag?: string;
}

const ClientTeamRoleSelector: React.FC<Props> = ({
  teamMembers,
  onChange,
  arkClientTag
}: Props): ReactElement => {
  const {
    clientList,
    selectedClients,
    selectedClient,
    onClientToggle,
    onClientSelect,
    onTeamSelect,
    onTeamDeselect,
    selectedTeam,
    onRoleSelect,
    teamRoles,
    selectedRole
  } = useClientTeamRoleSelector({
    teamMembers,
    onChange,
    arkClientTag
  });

  return (
    <Box>
      <Box>
        <ClientSelector
          clientList={clientList}
          onClientToggle={onClientToggle}/>
      </Box>
      <ClientTeamRoleGridContainer>
        <StyledClientSelector>
          <SelectedClientsList
            clientList={selectedClients}
            teamMembers={teamMembers}
            onClientSelect={onClientSelect}
            onClientDeselect={onClientToggle}/>
          <TeamsList
            onTeamDeselect={onTeamDeselect}
            teamMembers={teamMembers}
            onTeamSelect={onTeamSelect}
            teamsList={selectedClient?.teams || []} />
          <RolesList
            onRoleSelect={onRoleSelect}
            teamRoles={teamRoles}
            selectedRole={selectedRole}
            selectedTeam={selectedTeam} />
        </StyledClientSelector>
      </ClientTeamRoleGridContainer>
    </Box>
  );
};

export default ClientTeamRoleSelector;
