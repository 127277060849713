import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import FileSelector from "../../../../components/FileSelector/FileSelector";
import { SaveExitModal } from "../../../../components/Modal/SaveExitModal/SaveExitModal";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import FundFilter from "../../../../components/Selectors/FundsFilter";
import useRole from "../../../../core/routing/useRole";
import noop from "../../../../utils/helpers/noop";
import { ScopeRole } from "../../../../utils/types/user.type";
import { CapitalCallDetailsTabs } from "../constants";
import {
  AddFilesContainer,
  Button,
  CapitalCallSetUpContainer,
  CapitalCallSetupWrapper,
  Divider,
  FieldContainer,
  FieldLabel,
  FileContainerLabel,
  HeaderLabel,
} from "./CapitalCallSetUp.style";
import { FileDisplay } from "./FileDisplay";
import Reviewers from "./Reviewers";
import { useCapitalCallSetUp } from "./useCapitalCallSetUp.hooks";

type Props = {
  onNavigateAwayCanceled: () => void,
};

const CapitalCallSetUpTab: React.FC<Props> = ({ onNavigateAwayCanceled }) => {
  const {
    control,
    handleSubmit,
    errors,
    addCapitalCall,
    getValues,
    setValue,
    teamMembers,
    getTeamRolesWithMembers,
    id,
    documents,
    setDocuments,
    loading,
    isSuperAdminOrClientAdmin,
    allowEdit,
    allowReviewerEdit,
    handleValidateReviewers,
    setErrorMessage,
    errorMessage,
    isDirty,
    handleconfirmSaveAndNavagateAway
  } = useCapitalCallSetUp(onNavigateAwayCanceled);
  const history = useHistory();

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const submitRef = useRef<HTMLFormElement | null>(null);

  return (
    <CapitalCallSetupWrapper>
      <Progress id="capital_calls_list_loading" showProgress={loading} />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(addCapitalCall)}
        noValidate
        ref={submitRef}
      >
        <CapitalCallSetUpContainer>
          <HeaderLabel variant="h2">Select a Fund</HeaderLabel>
          <Divider variant="fullWidth" />
          <FieldContainer>
            <FieldLabel variant="h6">
              <strong>What is the capital call named?</strong>
            </FieldLabel>

            <Controller
              name="name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled={!isSuperAdminOrClientAdmin}
                  size="small"
                  id="name_capital_call"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: "Capital call name is  required",
              }}
              control={control}
            />
          </FieldContainer>
          {errors?.name && (
            <FormHelperText error={true}>
              {errors?.name?.message}{" "}
            </FormHelperText>
          )}

          <FieldLabel variant="h6">
            <strong>Which fund will be associated with this call?</strong>
          </FieldLabel>

          <FieldContainer>
            <Controller
              name="fundName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const fundId = getValues("fundId");

                return (
                  <FundFilter
                    size="small"
                    id={"transaction-fund-filter"}
                    value={{
                      name: value,
                      id: fundId,
                    }}
                    disabled={!isSuperAdminOrClientAdmin}
                    onChange={(fund) => {
                      onChange(fund?.name || "");
                      setValue("fundId", fund?.id);
                      getTeamRolesWithMembers(fund?.id);
                    }}
                    error={error}
                  />
                );
              }}
              rules={{
                required: "Fund is required",
              }}
              control={control}
            />
          </FieldContainer>
          <HeaderLabel variant="h2">Reviewers</HeaderLabel>
          <Divider variant="fullWidth" />
          <Stack>
            <FieldLabel variant="h6">
              <strong>
                Select at least one user group to review this capital call
              </strong>
            </FieldLabel>
          </Stack>

          <Controller
            name="reviewers"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Reviewers
                  disabled={!isSuperAdminOrClientAdmin}
                  value={value}
                  onChange={onChange}
                  teamMembers={teamMembers}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              </>
            )}
            rules={{
              required: "Reviewers are required",
              validate: (value: any) => {
                if (!handleValidateReviewers(value)) {
                  return "";
                } else return true;
              },
            }}
            control={control}
          />

          <HeaderLabel variant="h2">Add Files</HeaderLabel>
          <Divider variant="fullWidth" />
          <AddFilesContainer direction="row">
            <Stack>
              <FileContainerLabel variant="h6">
                <strong>Capital Call Notice Template</strong>
              </FileContainerLabel>
              <Controller
                name="noticeTemplate"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    {!value && !documents?.document && (
                      <>
                        <FileSelector
                          disabled={!isSuperAdminOrClientAdmin}
                          title="Drag and drop your file here,or"
                          acceptType=".txt,.doc,.docx"
                          onUpload={(_, file) => onChange(file)}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                    {value && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        file={value}
                        onClear={() => onChange()}
                      />
                    )}
                    {documents?.document && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        document={documents.document}
                        onClear={() => {
                          onChange();
                          setDocuments({
                            ...documents,
                            document: undefined,
                          });
                        }}
                      />
                    )}
                  </>
                )}
                rules={{
                  required: !documents?.document && "Please upload the Notice Template",
                }}
                control={control}
              />
            </Stack>
            <Stack>
              <FileContainerLabel variant="h6">
                <strong>Capital Call Email Template</strong>
              </FileContainerLabel>
              <Controller
                name="emailTemplate"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    {!value && !documents?.email && (
                      <>
                        <FileSelector
                          disabled={!isSuperAdminOrClientAdmin}
                          title="Drag and drop your file here,or"
                          acceptType=".txt,.doc,.docx"
                          onUpload={(_, file) => onChange(file)}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                    {value && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        file={value}
                        onClear={() => onChange()}
                      />
                    )}
                    {documents?.email && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        document={documents.email}
                        onClear={() => {
                          onChange();
                          setDocuments({
                            ...documents,
                            email: undefined,
                          });
                        }}
                      />
                    )}
                  </>
                )}
                rules={{
                  required: !documents?.email && "Please upload the Email Template",
                }}
                control={control}
              />
            </Stack>
          </AddFilesContainer>
        </CapitalCallSetUpContainer>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          noPadding
          stickToCenter={true}
          columnGap={true}
          sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }} 
        >
          {(isSuperAdminOrClientAdmin || allowReviewerEdit) && (
            <Button
              variant="contained"
              type="submit"
              disabled={allowReviewerEdit ? false : !allowEdit}
            >
              Save
            </Button>
          )}
          <Button
            variant="contained"
            disabled={!id || id === "new"}
            onClick={() => {
              history.push(
                `/capital-calls/${id}/${CapitalCallDetailsTabs.AddTransactions}`
              );
            }}
          >
            Continue to Add Transactions
          </Button>
        </HorizontalBox>
      </form>

      <SaveExitModal
        isBlocked={isDirty && id !== 'new'}
        title="Unsaved Changes Detected"
        content="You have unsaved modifications to either the notice template and/or email template. Would you like to save your changes or cancel and remain on this page?"
        actionTitle="Save Changes"
        onActionClickAsync={ () => handleconfirmSaveAndNavagateAway(submitRef.current!) }
        confirmNavigationTitle="Exit Without Saving"
        onNavigateAway={ noop }
        onCancelClick={onNavigateAwayCanceled}
      />
    </CapitalCallSetupWrapper>
  );
};

export default React.memo(CapitalCallSetUpTab);
