import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const DataWrapperBox = styled(Box)<{
  hidden?: boolean;
  gapSize?: number;
}>(({ theme, hidden, gapSize = 1 }) => ({
  height: "100%",
  display: hidden === true ? "none" : "grid",
  gap: theme.spacing(gapSize),
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto 1fr auto",
}));
