import "react-phone-input-2/lib/material.css";

import { Box, styled, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";

import palette from "../../styles/theme/palette";

export const StyledBox = styled(Box)<{fullwidth: string}>`
  margin-left: ${props => props.fullwidth === 'true' ? '0' : '10px'};
  margin-right: ${props => props.fullwidth === 'true' ? '0' : '10px'};
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
`;

export const StyledInfoLabel = styled(Typography)<{error: string}>`
  margin-bottom: -7px;
  margin-left: 40px;
  padding-left: 3px;
  padding-right: 3px;
  z-index: 1;
  background-color: white;
  width: fit-content;
  color: ${props => props.error === 'true' ? palette.error.main : "inherit"};
`;

export const StyledPhoneInput = styled(PhoneInput)<{fullwidth: string, error: string, listitemcolor?: string}>`
  .form-control {
    width: ${props => props.fullwidth === 'true' ? '100% !important' : '320px !important'};
    height: 37px;
    border: ${props => props.error === 'true' ? `1px solid ${palette.error.main} !important` : `1px solid ${palette.secondary.dark} !important`};
  }

  [class="flag 0"]{
      background-image: none;
  }
  li{
    color: ${props => props?.listitemcolor ? props.listitemcolor : 'inherit'};
  }
`;
