import { GridAlignment } from "@mui/x-data-grid";

import { DocumentFilter } from "../../../../../utils/types/documents.type";
import {
  CustomType,
  DataGridColDef,
} from "../../../../../utils/types/listItems";
import ArchiveCellRenderer from "../cells/archiveCellRenderer/ArchiveCellRenderer";
import CopyCellRenderer from "../cells/CopyCellRenderer";
import DateUploadedCellRenderer from "../cells/DateUploadedCellRenderer";
import DownloadCellRenderer from "../cells/DownloadCellRenderer";
import FileSizeCellRenderer from "../cells/FileSizeCellRenderer";
import FileStatusCellRenderer from "../cells/FileStatusCellRenderer";
import FundCellRenderer from "../cells/FundCellRenderer";
import InvestorCellRenderer from "../cells/InvestorCellRenderer";
import NameCellRenderer from "../cells/NameCellRenderer";
import QuarterCellRenderer from "../cells/PeriodCellRenderer";
import PrintCellRenderer from "../cells/PrintCellRenderer";
import WaterMarkCellRenderer from "../cells/WaterMarkCellRenderer";

export const RootNameColDef: DataGridColDef = {
  field: "name",
  headerName: "Name",
  hide: false,
  index: 1,
  sortable: true,
  type: "string",
  align: "left" as GridAlignment,
  width: 500,
  disableReorder: true,
  renderCell: NameCellRenderer,
};

export const SubNameColDef: DataGridColDef = {
  flex: 0,
  field: "name",
  headerName: "Name",
  hide: false,
  index: 1,
  sortable: true,
  type: "string",
  align: "left" as GridAlignment,
  width: 400,
  minWidth: 300,
  renderCell: NameCellRenderer,
  inlineFilter: true,
  inlineFilterName: DocumentFilter.Name,
  inlineFilterIDField: "id",
  inlineFilterLabelField: "name",
};

export const PermissionsColDef: DataGridColDef = {
  field: "permissions",
  headerName: "Permissions",
  hide: false,
  index: 2,
  sortable: false,
  type: "chips",
  align: "left" as GridAlignment,
  width: 600,
  disableReorder: true,
  chipLabelField: "name",
  chipIDField: "key",
  chipCount: 3,
  onChipClick: () => {},
  toggleVisibilityOnClick: true,
  inlineFilter: true,
  inlineFilterName: DocumentFilter.Permission,
  inlineFilterIDField: "key",
  inlineFilterLabelField: "name",
};

export const InvestorColDef: DataGridColDef = {
  flex: 0,
  field: "investor",
  headerName: "Investor",
  hide: false,
  index: 2,
  sortable: true,
  type: "string",
  align: "left" as GridAlignment,
  width: 300,
  minWidth: 300,
  renderCell: (params) => <InvestorCellRenderer row={params.row} />,
  inlineFilter: true,
  inlineFilterName: DocumentFilter.Investor,
  inlineFilterIDField: "id",
  inlineFilterLabelField: "name",
  scrollContainerStyle: { maxWidth: "initial" },
};

export const FundColDef: DataGridColDef = {
  flex: 0,
  field: "fund",
  headerName: "Fund",
  hide: false,
  index: 3,
  sortable: true,
  type: "chips",
  align: "left" as GridAlignment,
  width: 300,
  minWidth: 200,
  chipLabelField: "name",
  chipIDField: "id",
  chipCount: 3,
  renderChipForFilesOnly: true,
  renderChipForMultiItemsOnly: true,
  inlineFilter: true,
  inlineFilterName: DocumentFilter.Fund,
  inlineFilterIDField: "id",
  inlineFilterLabelField: "name",
  scrollContainerStyle: { maxWidth: "initial" },
  renderCell: (params) => <FundCellRenderer row={params.row} />,
};

export const QuarterPeriodColDef: DataGridColDef = {
  flex: 0,
  field: "quarter",
  headerName: "Period",
  hide: false,
  index: 4,
  sortable: true,
  type: "string",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 200,
  renderCell: (params) => <QuarterCellRenderer row={params.row} />,
  inlineFilter: true,
  inlineFilterName: DocumentFilter.Quarter,
  inlineFilterIDField: "name",
  inlineFilterLabelField: "name",
  scrollContainerStyle: { maxWidth: "initial" },
};

export const FileSizeColDef: DataGridColDef = {
  flex: 0,
  field: "sizeInBytes",
  headerName: "File Size",
  hide: false,
  index: 5,
  sortable: false,
  type: "number",
  align: "left" as GridAlignment,
  width: 150,
  minWidth: 100,
  renderCell: FileSizeCellRenderer,
};

export const WaterMarkColDef: DataGridColDef = {
  field: "hasWatermark",
  headerName: "Watermark",
  hide: false,
  index: 6,
  sortable: false,
  type: "boolean",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 150,
  renderCell: (params) => <WaterMarkCellRenderer row={params.row} />,
};

export const PublishStatusColDef: DataGridColDef = {
  flex: 0,
  field: "status",
  headerName: "Status",
  hide: false,
  index: 7,
  sortable: false,
  type: "boolean",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 200,
  renderCell: (params) => <FileStatusCellRenderer row={params.row} />,
};

export const DownloadColDef: DataGridColDef = {
  flex: 0,
  field: "download",
  headerName: "Download",
  hide: true,
  index: 8,
  sortable: false,
  type: "boolean",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 180,
  renderCell: (params) => <DownloadCellRenderer row={params.row} />,
};

export const PrintColDef: DataGridColDef = {
  flex: 0,
  field: "print",
  headerName: "Print",
  hide: true,
  index: 9,
  sortable: false,
  type: "boolean",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 140,
  renderCell: (params) => <PrintCellRenderer row={params.row} />,
};

export const CopyColDef: DataGridColDef = {
  flex: 0,
  field: "copy",
  headerName: "Copy",
  hide: true,
  index: 10,
  sortable: false,
  type: "boolean",
  align: "left" as GridAlignment,
  width: 200,
  minWidth: 150,
  renderCell: (params) => <CopyCellRenderer row={params.row} />,
};

export const ArchiveColDef: DataGridColDef = {
  flex: 0,
  field: "archive",
  headerName: "Archive",
  hide: false,
  index: 11,
  sortable: false,
  type: "string",
  align: "left" as GridAlignment,
  width: 250,
  minWidth: 150,
  renderCell: (params) => <ArchiveCellRenderer row={params.row} />,
};

export const ColumnFilterColDef: DataGridColDef = {
  field: "action",
  headerName: "Columns",
  hide: false,
  index: 12,
  type: "action",
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 50,
  minWidth: 50,
  maxWidth: 50,
  resizable: false,
  renderCell: () => {
    return "";
  },
};

// export const DateUploadedColDef: DataGridColDef = {
//   field: "uploaded",
//   headerName: "Date Uploaded",
//   hide: false,
//   index: 5,
//   sortable: false,
//   type: "string",
//   align: "left" as GridAlignment,
//   width: 200,
//   renderCell: DateUploadedCellRenderer,
// };
