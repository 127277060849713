import { ProgressPanel } from "../../../../../components";
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import DetailPanel from "../../../../../components/DetailPanel/DetailPanel";
import { PanelBoxExpanded } from "../../../../../components/DetailPanel/DetailPanel.styles";
import { TransactionDetails, useChangesInPartnersCapital } from "../changesInPartnersCapital.hooks";
import { useChangesInPartnersCapitalDetails } from "./changesInPartnersCapitalDetails.hooks";

type ChangesInPartnersCapitalProps = {
    open: boolean,
    onClose: () => void,
    cellInfo: any,
    transacitonDetails: TransactionDetails[],
    apiResponse: any,
    roundedReportActivated: boolean,
    currencyCode: string,
    params: any,
};

export const ChangesInPartnersCapitalDetails: React.FC<ChangesInPartnersCapitalProps> = ({
    open,
    onClose,
    cellInfo,
    transacitonDetails,
    apiResponse,
    roundedReportActivated,
    currencyCode,
    params
}) => {

    const {
        dataGridColDefs,
        gridData,
        isLoading
    } = useChangesInPartnersCapitalDetails(
        cellInfo,
        transacitonDetails,
        apiResponse,
        roundedReportActivated,
        currencyCode,
        params,
    );

    return (
        <>
            <DetailPanel
                id='changes_in_partners_capital_detail_panel'
                title={''}
                open={open}
                hasTabsPanel={false}
                onClose={onClose}
                isGridDisplay={true}
            >
                <PanelBoxExpanded>
                    <DataGrid
                        id={'change_in_partners_capital_details_grid'}
                        dataList={gridData || []}
                        headerList={dataGridColDefs ?? []}
                        noDataMessage={isLoading ? '' : undefined}
                        minHeight='77vh'
                        autoHeight={false}
                        checkboxSelection={false}
                        disableColumnReorder={true}
                    />
                </PanelBoxExpanded>
                <ProgressPanel
                    id={'progress_changes_in_partners_capital_panel'}
                    showProgress={isLoading}
                    text={'Loading...'}
                />
            </DetailPanel>
        </>
    );
};