import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Role } from "../../../utils/types/role.type";
import { getRoles } from "../../role.service";

export const useRolesEffect = () => {
  const [roles, setRoles] = useState<Role[] | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchRoles = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getRoles();

      if (isCanceled?.()) return;

      setRoles(response);
      setLoading(false);
    } catch (e) {
        informationAlert("Error in getting roles", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchRoles(isCanceled);
  }, []);

  return {
    roles,
    fetchRoles,
    loading,
  };
};
