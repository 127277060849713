import { Box, Container, styled } from "@mui/material";

export const FooterContainer = styled("div")`
  display: flex;
  min-height: 7vh;
  flex-wrap: wrap;
`;

export const FooterText = styled("div")`
  margin-top: 6px;
`;

export const StyledButton = styled(Box)(({
  theme
}) => ({
  margin: '10px'
}));

export const PermissionBox = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
`;