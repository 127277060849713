import { Typography } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import IconDelete from "../../../../assets/images/icons/icon_delete.svg";
import IconNewCircle from "../../../../assets/images/icons/icon_new_circle.svg";
import { ButtonOptions } from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import { StringCell } from "../../../../components/DataGrid/DataGrid.styles";
import { ActionLink } from "../../../../components/Link/ActionLink/ActionLink";
import RoutingPaths from "../../../../core/routing/routingPaths";
import useRole from "../../../../core/routing/useRole";
import { deleteWorkbookById, getWorkbooksByFund } from "../../../../services/workbook.service";
import { M_DASH_UNICODE } from "../../../../utils/constants/constants";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import { DataGridColDef, ImageItem } from "../../../../utils/types/listItems";
import { ScopeRole } from "../../../../utils/types/user.type";
import { Workbook } from "../workbook.type";
import { WbConfigDialogInfo } from "../workbook/sheets/allocations/AllocationsSheet.hooks";
import { TimeText } from "./AllocationFiles.styles";


export enum DELETE_ACTION_TYPE {
    DELETE_NONE = "DELETE_NONE",
    DELETE_ALL = "DELETE_ALL",
    DELETE_SELECTED = "DELETE_SELECTED"
  }

export const useAllocationFiles = () => {
    const history = useHistory();
    const { fundId } = useParams<{ fundId: string }>();
    const [workbooks, setWorkbooks] = useState<Workbook[]>([]);
    const [selectionModel, setSelectionModel] = useState<string[]>([]);
    const [showAllocationWBddialog, setShowAllocationWBddialog] = useState<boolean>(false);
    const [deleteAction, setDeleteAction] = useState(DELETE_ACTION_TYPE.DELETE_NONE);
    const [reloadWorkbooks, setReloadWorkbooks] = useState(0);

    useEffectAsync(async (isCanceled) => {
        const wbList = await getWorkbooksByFund(fundId);

        if(isCanceled()) return;

        wbList.sort((a, b) => Number(new Date(b.dateModified!)) - Number(new Date(a.dateModified!)) );
        setWorkbooks(wbList);
    }, [reloadWorkbooks]);

    const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);


    function handleOnView(id: any) {
        setShowAllocationWBddialog(false);

        history.push(`${RoutingPaths.FundAllocationsFile}/${fundId}/${id}`);
    }

    function getButtonOptions() {
        const buttonOptions: ButtonOptions[] = [];

        buttonOptions.push({
            label: 'New Allocation File',
            id: 'add_new',
            icon: IconNewCircle
        });

        return buttonOptions;
    }

    function handleButtonAction(actionID: string, _event?: any) {
        switch(actionID) {
            case 'add_new':
                setShowAllocationWBddialog(true);
        }
    }

    function getHeaderList() {
        const headerList: DataGridColDef[] = [
            {
                field: "reportName",
                headerName: "Name",
                hide: false,
                index: 1,
                sortable: true,
                type: 'string',
                renderCell: (params: any) => {
                    return (
                        <ActionLink
                            id={`link_allocarion_file_${params.row.id}`}
                            onClick={() => handleOnView(params.row.id)}
                        >
                            {params.value}
                        </ActionLink>
                    );
                }
            },
            {
                field: "dateCreated",
                headerName: "Created",
                hide: false,
                index: 2,
                renderCell: (params) => { return getDateCell(params); },
                align: 'left' as GridAlignment,
                width: 200,
            },
            {
                field: "dateModified",
                headerName: "Modified",
                hide: false,
                index: 3,
                renderCell: (params) => { return getDateCell(params); },
                align: 'left' as GridAlignment,
                width: 200,
            },
            {
                field: "modifiedBy",
                headerName: "Last Modified By",
                hide: false,
                index: 4,
                align: 'left' as GridAlignment,
                width: 200,
            }
        ];

        return headerList;

        function getDateCell(params: GridRenderCellParams<any, any, any>) {
            const dt = params.value;

            if(!dt) {
                return (
                  <StringCell>
                    <Typography variant="body2">{M_DASH_UNICODE}</Typography>
                  </StringCell>
                );
            }

            const formattedData = format(new Date(dt), 'MM/dd/yyyy');
            const time = format(new Date(dt), 'hh:mm a');

            return (
                <StringCell>
                    <Typography component="span" variant="body2">{formattedData}</Typography>
                    <TimeText component="span" display="block" variant="body2">{time}</TimeText>
                </StringCell>   
            );
        }
    }

    function getBulkItems() {
        const bulkActions: ImageItem[] = [];

        if(selectionModel.length > 0) {
            bulkActions.push({
                id: "delete-selected",
                text: `Delete Selected (${selectionModel?.length})`,
                icon: <img src={IconDelete} alt="Delete Selected" height="15" />,
                // color: "error",
                optionsSelected: 0,
            });
        }

        bulkActions.push({
            id: "delete-all",
            text: "Delete All",
            icon: <img src={IconDelete} alt="Delete All" height="15" />,
            optionsSelected: 0,
        });

        return bulkActions;
    }

    function handleBulkAction (actionId: string) {
        switch (actionId) {
          case "delete-selected":
            setDeleteAction(DELETE_ACTION_TYPE.DELETE_SELECTED);
            break;
          case "delete-all":
            setDeleteAction(DELETE_ACTION_TYPE.DELETE_ALL);
            break;
        }
    }


    function onDeleteConfirm() {
        let wbIDsToDelete: string[] = [];

        switch(deleteAction) {
            case DELETE_ACTION_TYPE.DELETE_SELECTED:
                wbIDsToDelete = selectionModel;
                break;
            case DELETE_ACTION_TYPE.DELETE_ALL:
                wbIDsToDelete = workbooks.map(wb => wb.id!);
        }

        setDeleteAction(DELETE_ACTION_TYPE.DELETE_NONE);

        if(wbIDsToDelete.length === 0) return;

        wbIDsToDelete.forEach(async wbId => {
            await deleteWorkbookById(wbId);
        });

         // delay reload to give the delete process time to finish
        setTimeout(() => { setReloadWorkbooks(reloadWorkbooks+1); }, 1000);
    }

    function onDeleteCancel() {
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_NONE);
    }

    function handleLoadNewWorkbook(wbConfigDialogInfo: WbConfigDialogInfo) {
        setShowAllocationWBddialog(false);

        history.push(`${RoutingPaths.FundAllocationsFile}/${fundId}/new`, wbConfigDialogInfo.workbook);
    }


    return {
        getBulkItems,
        handleBulkAction,
        getButtonOptions,
        handleButtonAction,
        getHeaderList,
        handleOnView,
        selectionModel, setSelectionModel,
        workbooks,
        showAllocationWBddialog, setShowAllocationWBddialog,
        handleLoadNewWorkbook,
        deleteAction,
        onDeleteConfirm, onDeleteCancel,
        isFundAdmin
    };
};
