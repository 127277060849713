import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import IconRightArrow from "../../../../assets/images/icons/icon_right_arrow.svg";
import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import MemoizedChips from "../../../../components/MemoizedChips/MemoizedChips";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import { SelectedTeam, TeamType } from "../../../../utils/types/team.type";
import { TransactionFilter } from "../../../../utils/types/transaction.type";
import {
  ArrowIconBox,
  DetailPanel,
  FormBox,
  TitleBox,
} from "../../../transactions/transactionList/transactionDetails/TransactionDetailPanel.styles";
import { ChipsContent, FundBox, StyledFilter } from "./TeamDetailsPanel.styles";
import { useTeamDetails } from "./useTeamDeatails.hook";

interface Props extends RouteProps {
  onTeamDetailClose: Function;
  selectedTeam: SelectedTeam;
  setTeamResponse: Function;
}

const TeamDetails: React.FC<Props> = ({
  onTeamDetailClose,
  selectedTeam,
  setTeamResponse
}: Props): ReactElement => {
  const theme = useTheme();
  const { type, team } = selectedTeam;
  const isNewTeam = type === TeamType.New;
  const {
    handleSubmit,
    control,
    loading,
    createUpdateTeam,
    toggleDrawer,
    fundsList,
    selectedFunds,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    handleFundsSelection,
    handleAllFundsSelection,
    removeSelectedFund,
  } = useTeamDetails({
    team,
    onTeamDetailClose,
    isNewTeam,
    setTeamResponse,
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="team_drawer"
        anchor="right"
        open={Boolean(type)}
        onClose={toggleDrawer}
      >
        <TitleBox>
          <Typography id="title" variant="formTitle" display="flex">
            {isNewTeam ? "Create New Team" : `Edit ${team?.name}`}{" "}
            {!isNewTeam && <ArrowIconBox sx={{ marginLeft: 1 }} onClick={toggleDrawer}>
              <ImgIcon icon={IconRightArrow} />
            </ArrowIconBox> }
          </Typography>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        <ProgressPanel
          id={"progress_teams_panel"}
          showProgress={Boolean(loading)}
          text={`Loading Team Details...`}
        />
        {!loading && (
          <form
            autoComplete="off"
            onSubmit={handleSubmit(createUpdateTeam)}
            noValidate
          >
            <FormBox>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="team_name"
                          size="small"
                          fullWidth
                          aria-describedby="name"
                          label="Team Name"
                          placeholder="Team Name"
                          variant="outlined"
                          value={value ?? ""}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          error={!!error}
                          helperText={error?.message ?? ""}
                        />
                      );
                    }}
                    rules={{
                      required: "Team name is required",
                    }}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Typography variant="subtitle1" mt={2}>
                Funds
              </Typography>
              <Box flex={1}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <StyledFilter
                      options={fundsList}
                      selectedOptions={selectedFunds.map(item => item.id)}
                      idField="id"
                      labelField="name"
                      filterName={TransactionFilter.FundName}
                      popoverStyle={{
                        border: `1px solid ${theme.palette.secondary.dark}`,
                        padding: theme.spacing(1),
                        borderRadius: 4,
                      }}
                      scrollContainerStyle={{
                        maxWidth: '100%',
                      }}
                      hideButtons
                      applyOnSelect
                      handleSelection={handleFundsSelection}
                      handleAllSelection={handleAllFundsSelection}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <FundBox>
                      <ChipsContent>
                        <MemoizedChips
                          removeSelected={removeSelectedFund}
                          options={selectedFunds}
                        />
                      </ChipsContent>
                    </FundBox>
                  </Grid>
                </Grid>
              </Box>
            </FormBox>
            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              fullWidth
            >
              <Button
                id={"team_cancel_button"}
                variant="outlined"
                onClick={toggleDrawer}
                text={"Cancel"}
                color={"secondary"}
                fullWidth
                addSpaceBetweenButtons
              />
              <Button
                id={"team_save_button"}
                variant="contained"
                text={"Save & Close"}
                color={"primary"}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            </HorizontalBox>
          </form>
        )}
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_team_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "save_team_modal_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "cancel_save_team_modal_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </React.Fragment>
  );
};

export default TeamDetails;
