export const SYNC_GL_ERROR = "Error syncing Chart of Accounts.";
export const SYNC_GL_SUCCESS = "Chart of Accounts synced";
export const SAVE_MAPPING_ERROR = "Error saving mapping.";
export const SAVE_MAPPING_SUCCESS = "Mapping saved successfully.";
export const GET_TRANS_TYPES_LIST_ERROR = "Error getting transaction types.";
export const GET_TRANS_BUCKETS_LIST_ERROR =
  "Error getting transaction bucket list.";
export const GET_MAPPED_FUNDS_LIST_ERROR = "Error getting mapped fund list.";
export const GET_FUND_MAPPED_ERROR = "Error getting selected fund.";
export const NO_FUND_MAPPED_ACCOUNTS_ERROR =
  "The selected fund has no mapped accounts. Select a fund that has preexisting accounts mapped to Ark transactions to proceed.";
export const FUND_MAPPED_SUCCESS = "Imported fund mapped successfully.";
export const SAVE_NEW_TRANSACTION_SUCCESS = "New transaction type saved.";
export const SAVE_NEW_TRANSACTION_ERROR = "Error saving new transaction type.";
