import { PageInfo } from './common.type';
import { Role } from './role.type';
import { Team } from './team.type';

export interface LoginUser {
  email: string;
  clientId: string;
  clientName?: string;
  currentUser?: CurrentUser;
  arkClientTag?: string;
  isAuth0Login?: boolean;
}

export enum ScopeRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  BASIC_ADMIN = 'BASIC_ADMIN',
  ARK_CLIENT_ADMIN = 'ARK_CLIENT_ADMIN',
  FUND_USER_ADMIN = 'FUND_USER_ADMIN',
  BASIC_USER = 'BASIC_USER',
}

export const ADMIN_ROLES = [
  ScopeRole.SUPER_ADMIN,
  ScopeRole.ARK_CLIENT_ADMIN,
  ScopeRole.BASIC_ADMIN,
  ScopeRole.FUND_USER_ADMIN,
];

export interface CurrentUser {
  clientId: string;
  roles: {
    authority: string;
  }[];
  scopeRole: ScopeRole;
  username: string;
}

export interface TeamMember {
  team?: {
    id?: string;
  };
  role?: string;
}

export interface User {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string;
  phone?: string | null;
  location?: string | null;
  arkClientTag?: string | null;
  loginStatus?: {
    attempts?: number | null;
    clientId?: string | null;
    lastLoginAttempt?: string | null;
    locked?: boolean | null;
    username?: string | null;
  };
  mfaAttempts?: {
    attempts?: number | null;
    id?: number | null;
    username?: string | null;
  };
  role?: Role;
  status?: string;
  teamMembers?: TeamMember[];
}

export type SelectedUser = {
  user?: User;
  type?: OperationType;
  arkClientTag?: string;
};

export enum OperationType {
  AddNewClient = 'Add New Client',
  AddNewUser = 'Add New User',
  AddSuperAdmin = 'Add Super Admin',
  AddNew = 'Add New',
  New = 'new',
  EditUser = 'editUser',
  EditSuperAdmin = 'editSuperAdmin',
}

export interface UserList {
  items: User[];
  pageInfo?: PageInfo;
}

export enum UserAction {
  ResendEmail = 'Resend Email',
  DeleteUser = 'Delete User',
  UnlockUser = 'Unlock User'
}

export type ClientListItem = {
  clientId: string;
  clientName: string;
  teams: Team[];
  selected: boolean;
};

export type ProxyParams = {
  userName: string;
  clientId: string;
};

export type MfaInfo = {
  totalAttempts?: number;
  remainingAttempts?: number;
  showWarning?: boolean;
  userEmail?: string;
  phoneNumber?: number;
  trustedDevice?: boolean;
  isDevEnv?: boolean;
};
