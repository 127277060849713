import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import RoutingPaths from "../../../core/routing/routingPaths";
import { useDistributionDetailsEffect } from "../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { DistributionDetailsTabs } from "./Constants";

interface RouteProp {
    id: string;
    section: string;
  }

const getIndexBySection = (section?: string) => {
    switch (section) {
      case DistributionDetailsTabs.AddTransactions:
        return 1;
      case DistributionDetailsTabs.ReviewDocuments:
        return 2;
      case DistributionDetailsTabs.Publish:
        return 3;
      case DistributionDetailsTabs.DistributionSetup:
      default:
        return 0;
    }
    return 0;
  };

export const UseDistributionDetails = () => {
    const { id,section }= useParams<RouteProp>();
    const history = useHistory();
    const [tab,setTab] = useState(getIndexBySection(section));
    const { distributions, loading } = useDistributionDetailsEffect(
      id !== "new" ? id : undefined
    );
    const handleChange = (_: React.ChangeEvent<{}>|undefined, newValue: number) => {
        setTab(newValue);
        if (newValue === 0) {
          return history.push(
            `/distributions/${id}/${DistributionDetailsTabs.DistributionSetup}`
          );
        }
        if (newValue === 1) {
          return history.push(
            `/distributions/${id}/${DistributionDetailsTabs.AddTransactions}`
          );
        }
        if (newValue === 2) {
          return history.push(
            `/distributions/${id}/${DistributionDetailsTabs.ReviewDocuments}`
          );
        }
        if (newValue === 3) {
          return history.push(
            `/distributions/${id}/${DistributionDetailsTabs.Publish}`
          );
        }
        return null;
      };

      useEffect(() => {
          setTab(getIndexBySection(section));
      }, [section]);

      const handleClose = () => {
        history.push(RoutingPaths.Distributions);
      };

    return{
        tab,
        setTab,
        handleChange,
        distributions,
        handleClose,
        loading
    };
};