import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { NameIdPair } from "../../../utils/types/transaction.type";
import { getTransactionTypes } from "../../fund.service";

export const useTransactionTypesEffect = () => {
  const [transactionTypesList, setTransactionTypesList] = useState<NameIdPair[]>();
  const [loading, setLoading] = useState(false);
  const {
    state,
    informationAlert,
  } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoading(true);
      const transactionTypesResponse = await getTransactionTypes(state.loginUser.clientId);

      if (isCanceled()) return;

      setTransactionTypesList(transactionTypesResponse);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting transaction types", "error");
      setLoading(false);
    }
  }, [state.loginUser.clientId]);

  return {
    transactionTypesList,
    loading
  };
};
