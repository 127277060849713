import { EmailOrUrl } from "../utils/types/distributionDocument.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
    return ApiClient.Client(ClientType.NotCached, ServiceType.DocumentsApi);
};

export const getDistributionDocumentUrl = async (id: string) =>
  await apiClient().get<EmailOrUrl>(`document-api/files/${id}/url`);

export const  getDistributionEmail = async (id:string) => 
    await apiClient().get<EmailOrUrl>(`document-api/approvals/${id}/emails`);