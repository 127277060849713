import { Paper, styled } from "@mui/material";

export const StyledDragAndDropPaperItem = styled(Paper)`
    display: flex;
    alignItems: center;
    justifyContent: flex-start;
    cursor: move;
    padding: 3px 6px 3px 20px;
    margin: 4px;
    background-color: #ECEEF3;
    width: 100%;
`;