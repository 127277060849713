import { useTheme } from '@mui/material';

import {
  DashboardWidget,
  PieDataItem,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { getColor } from '../helpers/Chart.utils';
import { PageSummaryItem } from '../VisualDashboard';
import { VisualDashboardType } from '../VisualDashboard.constants';
import { isFundSummaryItemArray } from '../VisualDashboard.hooks';
import { PieChart } from './PieChart';

export function getPieChartData(
  summaryItems: PageSummaryItem[],
  pageType: string,
  dashboardWidgets: DashboardWidget[],
  currency: string
) {
  const theme = useTheme();

  let pageSummaryItems = [];
  let committedByFundDataItems: PieDataItem[] = [];
  let committedTotal = 0;
  let unfundedTotal = 0;
  let chartWidgets = [];

  function aggregateAndSortByCountry(
    pgsum: PageSummaryItem[],
    fields: string[]
  ): PieDataItem[] {
    const locationMap: { [key: string]: number } = {};

    for (const item of pgsum) {
      const country = item.categoryProps.country || 'Unspecified';

      let totalValue = 0;

      fields.forEach((field) => {
        if (Math.abs(item.dataProps[field])) {
          totalValue += item.dataProps[field];
        }
      });

      if (locationMap[country]) {
        locationMap[country] += totalValue;
      } else {
        locationMap[country] = totalValue;
      }
    }

    const aggregatedItems: PieDataItem[] = Object.keys(locationMap).map(
      (label) => ({
        label,
        value: locationMap[label],
        id: label,
        color: getColor(0),
      })
    );

    aggregatedItems.sort((a, b) => b.value - a.value);

    const result = aggregatedItems.map((item, index) => ({
      ...item,
      id: `${index + 1}`,
      color: getColor(index),
    }));

    return result;
  }

  function aggregateAndSortDashboardByCountry(
    pgsum: PageSummaryItem[],
    fields: string[]
  ): PieDataItem[] {
    const locationMap: { [key: string]: number } = {};

    for (const item of pgsum) {
      const country = item.categoryProps.country || 'Unspecified';

      let totalValue = 0;

      if (item.soiPageView === 'Unrealized_SOI') {
        if (Math.abs(item.dataProps['fairMarketValue'])) {
          totalValue += item.dataProps['fairMarketValue'];
        }
      } else {
        if (Math.abs(item.dataProps['realizedValue'])) {
          totalValue += item.dataProps['realizedValue'];
        }
      }

      if (locationMap[country]) {
        locationMap[country] += totalValue;
      } else {
        locationMap[country] = totalValue;
      }
    }

    const aggregatedItems: PieDataItem[] = Object.keys(locationMap).map(
      (label) => ({
        label,
        value: locationMap[label],
        id: label,
        color: getColor(0),
      })
    );

    aggregatedItems.sort((a, b) => b.value - a.value);

    const result = aggregatedItems.map((item, index) => ({
      ...item,
      id: `${index + 1}`,
      color: getColor(index),
    }));

    return result;
  }

  function aggregateAndSortByIndustry(
    pgsum: PageSummaryItem[],
    fields: string[]
  ): PieDataItem[] {
    const industryMap: { [key: string]: number } = {};

    for (const item of pgsum) {
      const sector = item.categoryProps.industrySector || 'Unspecified';

      let totalValue = 0;

      fields.forEach((field) => {
        if (Math.abs(item.dataProps[field])) {
          totalValue += item.dataProps[field];
        }
      });

      if (industryMap[sector]) {
        industryMap[sector] += totalValue;
      } else {
        industryMap[sector] = totalValue;
      }
    }

    const aggregatedItems: PieDataItem[] = Object.keys(industryMap).map(
      (label) => ({
        label,
        value: industryMap[label],
        id: label,
        color: getColor(0),
      })
    );

    aggregatedItems.sort((a, b) => b.value - a.value);

    const result = aggregatedItems.map((item, index) => ({
      ...item,
      id: `${index + 1}`,
      color: getColor(index),
    }));

    return result;
  }

  function aggregateAndSortDashboardByIndustry(
    pgsum: PageSummaryItem[],
    fields: string[]
  ): PieDataItem[] {
    const industryMap: { [key: string]: number } = {};

    for (const item of pgsum) {
      const sector = item.categoryProps.industrySector || 'Unspecified';

      let totalValue = 0;

      if (item.soiPageView === 'Unrealized_SOI') {
        if (Math.abs(item.dataProps['fairMarketValue'])) {
          totalValue += item.dataProps['fairMarketValue'];
        }
      } else {
        if (Math.abs(item.dataProps['realizedValue'])) {
          totalValue += item.dataProps['realizedValue'];
        }
      }

      if (industryMap[sector]) {
        industryMap[sector] += totalValue;
      } else {
        industryMap[sector] = totalValue;
      }
    }

    const aggregatedItems: PieDataItem[] = Object.keys(industryMap).map(
      (label) => ({
        label,
        value: industryMap[label],
        id: label,
        color: getColor(0),
      })
    );

    aggregatedItems.sort((a, b) => b.value - a.value);

    const result = aggregatedItems.map((item, index) => ({
      ...item,
      id: `${index + 1}`,
      color: getColor(index),
    }));

    return result;
  }

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);
      const accumulated = pageSummaryItems.reduce((acc: any, item) => {
        const { fundId, name, committed, unfunded } = item;
        const key = `${fundId}-${name}`;

        if (!acc[key]) {
          acc[key] = { id: fundId, value: 0, unfunded: 0, label: name };
        }
        acc[key].value += committed;
        acc[key].unfunded += unfunded;

        return acc;
      }, {});

      committedByFundDataItems = Object.values(accumulated).map(
        (item: any, index) => {
          committedTotal += item.value;
          unfundedTotal += item.unfunded;

          return {
            id: item.id,
            value: item.value,
            label: item.label,
            color: getColor(index),
          };
        }
      );
      break;
    case VisualDashboardType.Fund:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);

      committedByFundDataItems = pageSummaryItems.map<PieDataItem>(
        (fs, index) => {
          committedTotal += fs.committed;
          unfundedTotal += fs.unfunded;
          return {
            id: fs.id,
            value: fs.committed,
            label: fs.name,
            color: getColor(index),
          } as PieDataItem;
        }
      );
      break;
  }

  chartWidgets = dashboardWidgets
    ?.filter(
      (widget: DashboardWidget) =>
        widget.widgetType === WidgetType.Charts && widget.visible
    )
    .map((widget: DashboardWidget) => {
      let display = false;
      let chart: JSX.Element | undefined = undefined;

      switch (widget.name) {
        case 'committedByFund':
          chart = (
            <PieChart
              id="pieChart"
              pieDataItems={committedByFundDataItems}
              currency={currency}
              title="Commitments by Fund"
              centerLabel="Committed"
            />
          );
          if (committedByFundDataItems.length > 0) display = true;
          break;

        case 'committedToPaid':
          const committedToPaidDataItems: PieDataItem[] = [
            {
              id: 'funded',
              value: committedTotal - unfundedTotal,
              label: 'Funded',
              color: theme.palette.common.visUltramarineBlue,
            },
            {
              id: 'unfunded',
              value: unfundedTotal,
              label: 'Unfunded',
              color: theme.palette.common.visSkyBlue,
            },
          ];

          chart = (
            <PieChart
              id="committed_to_paid_pie_chart"
              pieDataItems={committedToPaidDataItems}
              currency={currency}
              title="Committed to Funded"
              centerLabel="Committed"
            />
          );
          if (committedByFundDataItems.length > 0) display = true;
          break;
        case 'investedCapitalByGeography':
          const investedCapitalByGeographyData = aggregateAndSortByCountry(
            summaryItems,
            ['cost']
          );

          chart = (
            <PieChart
              id={`${widget.name}"_pie_chart`}
              pieDataItems={investedCapitalByGeographyData}
              currency={currency}
              title={widget.title}
              centerLabel="Invested"
            />
          );

          if (investedCapitalByGeographyData.length > 0) display = true;
          break;
        case 'fairMarketValueByGeography':
          let fairMarketValueByGeographyData: PieDataItem[] = [];

          if (pageType === 'SCHEDULE_OF_INVESTMENTS_UNREALIZED') {
            fairMarketValueByGeographyData = aggregateAndSortByCountry(
              summaryItems,
              ['fairMarketValue']
            );
          } else if (pageType === 'SCHEDULE_OF_INVESTMENTS_REALIZED') {
            fairMarketValueByGeographyData = aggregateAndSortByCountry(
              summaryItems,
              ['realizedValue']
            );
          } else {
            fairMarketValueByGeographyData = aggregateAndSortDashboardByCountry(
              summaryItems,
              ['realizedValue', 'fairMarketValue']
            );
          }

          chart = (
            <PieChart
              id={`${widget.name}"_pie_chart`}
              pieDataItems={fairMarketValueByGeographyData}
              currency={currency}
              title={widget.title}
              centerLabel="FMV"
            />
          );
          if (fairMarketValueByGeographyData.length > 0) display = true;

          break;
        case 'investedCapitalByIndustry':
          const investedCapitalByIndustry: PieDataItem[] =
            aggregateAndSortByIndustry(summaryItems, ['cost']);

          chart = (
            <PieChart
              id={`${widget.name}"_pie_chart`}
              pieDataItems={investedCapitalByIndustry}
              currency={currency}
              title="Invested Capital by Industry"
              centerLabel="Invested"
            />
          );
          if (investedCapitalByIndustry.length > 0) display = true;
          break;
        case 'fairMarketValueByIndustry':
          let fairMarketValueByIndustry: PieDataItem[] = [];

          if (pageType === 'SCHEDULE_OF_INVESTMENTS_UNREALIZED') {
            fairMarketValueByIndustry = aggregateAndSortByIndustry(
              summaryItems,
              ['fairMarketValue']
            );
          } else if (pageType === 'SCHEDULE_OF_INVESTMENTS_REALIZED') {
            fairMarketValueByIndustry = aggregateAndSortByIndustry(
              summaryItems,
              ['realizedValue']
            );
          } else {
            fairMarketValueByIndustry = aggregateAndSortDashboardByIndustry(
              summaryItems,
              ['realizedValue', 'fairMarketValue']
            );
          }
          chart = (
            <PieChart
              id={`${widget.name}"_pie_chart`}
              pieDataItems={fairMarketValueByIndustry}
              currency={currency}
              title="Fair Market Value by Industry"
              centerLabel="FMV"
            />
          );
          if (fairMarketValueByIndustry.length > 0) display = true;
          break;
      }

      return {
        index: widget.index,
        name: widget.name,
        title: widget.title,
        visible: widget.visible,
        widgetType: widget.widgetType,
        chart: chart,
        display: display,
      };
    });

  const filteredCharts = chartWidgets.filter(
    (chart) => chart.chart !== undefined
  );

  return { currency: currency, charts: filteredCharts };
}
