import React from "react";

import styles from "./PortalUnavailable.styles";

const PortalUnavailable: React.FC = () => {
  return (
    <div style={styles.containerStyle}>
      <div style={styles.textStyle}>
        <div style={styles.headingStyle}>
          This portal is temporarily unavailable
        </div>
        <div style={styles.subheadingStyle}>
          Please contact support or try again later.
        </div>
      </div>
    </div>
  );
};

export default PortalUnavailable;
