import { DrawerProps } from "@mui/material";
import { ReactElement } from "react";

import { StyledDrawer } from "./InformationPanel.styles";

export interface Props extends Omit<DrawerProps, "anchor"> {
  id: string;
  children: any;
  expanded?: boolean
}

const InformationPanel = (props: Props): ReactElement => {
  const { id, children, ...other } = props;

  return (
    <StyledDrawer id={id} anchor="right" {...other}>
      {children}
    </StyledDrawer>
  );
};

export default InformationPanel;
