import { Box, styled } from "@mui/material";

export const StyledHorizontalBox = styled(Box) <{
  fullwidth: boolean;
  addTopShadow: boolean;
  attachToBottom: boolean;
  nopadding: boolean;
  sticktoright: string;
  sticktoleft: string;
  sticktocenter: string;
  columngap: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    if (props.sticktoright === "true") {
      return "flex-end";
    } else if (props.sticktocenter === "true") {
      return "center";
    } else if (props.sticktoleft === "true") {
      return "flex-start";
    } else {
      return "space-between";
    }
  }};
  width: ${(props) => {
    if (props.fullwidth) return "100%";
    else return "auto";
  }};
  padding-bottom: ${(props) => {
    if (props.nopadding) return "0px";
    else return "10px";
  }};

  -webkit-box-shadow: ${(props) => {
    if (props.addTopShadow) return "-1px -4px 13px 2px rgba(0, 0, 0, 0.33)";
    else return "none";
  }};
  -moz-box-shadow: ${(props) => {
    if (props.addTopShadow) return "-1px -4px 13px 2px rgba(0, 0, 0, 0.33)";
    else return "none";
  }};
  box-shadow: ${(props) => {
    if (props.addTopShadow) return "-1px -4px 13px 2px rgba(0, 0, 0, 0.33)";
    else return "none";
  }};

  min-height: ${(props) => {
    if (props.addTopShadow) return "66px";
    else return "auto";
  }};

  position: ${(props) => {
    if (props.attachToBottom) return "absolute";
    else return "";
  }};

  bottom: ${(props) => {
    if (props.attachToBottom) return 0;
    else return "";
  }};

  padding-left: ${(props) => {
    if (props.attachToBottom || props.nopadding) return "0px";
    else return "10px";
  }};
  padding-right: ${(props) => {
    if (props.attachToBottom || props.nopadding) return "0px";
    else return "10px";
  }};

  column-gap: ${(props) => {
    if (props.columngap) return "13px";
    else return "0px";
  }};
  background-color: white;
  z-index: 1;
`;
