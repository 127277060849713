import { Box, Link, styled } from "@mui/material";

import { StatusColor } from "../../../../../components/StatusLabel/StatusLabel.style";

export const DocumentNameLink = styled(Link)(
  ({ isReadOnly }: { isReadOnly: boolean }) => ({
    cursor: isReadOnly ? "arrow" : "pointer",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    position: "relative",
  })
);

export const NewItemDot = styled(StatusColor)(() => ({
  position: "absolute",
  left: -10,
  marginRight: "10px",
}));

export const SelectBox = styled(Box)(({ theme }) => ({
  border: "1px solid #BABABD",
  borderRadius: 4,
  display: "flex",
  padding: theme.spacing(0, 1.25),
  cursor: "pointer",
  justifyContent: "space-between",
  flex: 1,
  alignItems: "center",
  gap: 8,
}));
