export enum AddNewButtonOptions {
  AddNew = 'add_new',
  UploadFromTemplate = 'upload_from_template',
  DownloadTemplate = 'download_template',
  AddNewCustomField = 'add_new_custom_field'
}

export interface PageInfo {
  isPaged: boolean;
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export enum DownloadTransactionHistoryOptionIds {
  DownloadExcel = 'download_trans_history_excel',
  DownloadPdf = 'download_trans_history_pdf'
}

export enum DownloadStatementReportOptionIds {
  DownloadExcel = 'download_stat_report_excel',
  DownloadPdf = 'download_stat_report_pdf'
}

export enum DetailViewType {
  New = 'new',
  Edit = 'edit',
}
