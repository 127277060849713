import { Box, Container, styled } from "@mui/material";

export const ButtonBox = styled(Box)`
  display: flex;
`;

export const ContactActionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: end;
  width:100%;
`;

export const MainContainer = styled(Container)`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
`;