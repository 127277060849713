import { GridAlignment } from "@mui/x-data-grid-pro";

import { CustomType, DataGridColDef } from "../../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    hide: false,
    index: 1,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "funds",
    headerName: "Funds",
    hide: false,
    index: 2,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "currency",
    headerName: "Currency",
    hide: false,
    index: 3,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "status",
    headerName: "Status",
    hide: false,
    index: 4,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 150,
  },
  {
    field: "action",
    headerName: "Columns",
    hide: false,
    hideable: false,
    index: 5,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];
