import { GridAlignment } from "@mui/x-data-grid-pro";

import { DataGridColDef } from "../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: "transactionTypeId",
    headerName: "Ark Transactions",
    hide: false,
    index: 2,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 350,
  },
  {
    field: "dateMapped",
    headerName: "Date Mapped",
    hide: false,
    index: 3,
    type: "date",
    sortable: true,
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "lastGLActivity",
    headerName: "Last GL Activity",
    hide: false,
    index: 4,
    type: "date",
    sortable: true,
    align: "left" as GridAlignment,
    width: 200,
  },
];
