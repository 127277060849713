import {
  Autocomplete,
  Divider,
  InputAdornment,
  Popover,
  Popper,
  styled,
  TextField,
} from "@mui/material";

export const SearchBarInput = styled(TextField)`
  padding: 10px;
  margin-left: 10px;
  fieldset {
    border-radius: 50px;
    border: 1px solid #919195;
  }

  input {
    width: 100%;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #919195;
  }
`;

export const SearchBarInputPopover = styled(TextField)`
  & .MuiInput-input {
    height: 52px;
  }
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const AutoCompleteInput = styled(Autocomplete)`
  width: 300px;
`;

export const AutoCompletePopper = styled(Popper)`
  box-shadow: 7px 4px 9px -1px rgba(0, 0, 0, 0.15),
    -7px 4px 9px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 7px 4px 9px -1px rgba(0, 0, 0, 0.15),
    -7px 4px 9px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 7px 4px 9px -1px rgba(0, 0, 0, 0.15),
    -7px 4px 9px -1px rgba(0, 0, 0, 0.15);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: transparent;
  width: 322px !important;

  & .MuiAutocomplete-paper {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: -5px;
  }

  & .MuiAutocomplete-listbox {
    padding-top: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid #919195;
    border-top: none;
    border-bottom: none;
  }

  & li {
    margin-left: 30px;
    margin-right: 30px;
    display: block;
    list-style-position: inside;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis ellipsis;
  }
`;

export const AutoCompleteInputAdornment = styled(InputAdornment)`
  padding-left: 10px;
`;

export const AutoCompletePopover = styled(Popover)`
  & .MuiPaper-root {
    border: 1px solid #919195;
    margin-left: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 16px;
  }
`;

export const AutoCompleteDivider = styled(Divider)`
  margin-top: -10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 16px;
  background: #919195;
`;
