import { Chip, styled } from '@mui/material';

export const StyledBetaChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.betaYellow,
  border: `1px solid ${theme.palette.grey[400]}`,
  cursor: 'default',
  pointerEvents: 'none',
  '&:hover': {
    backgroundColor: theme.palette.common.betaYellow,
  },
}));
