import { Button, FormControl } from "@mui/material";
import { Box, styled } from "@mui/system";

export const ReportConfigContainer = styled(Box)(({
    theme
  }) => ({
    padding: theme.spacing(2, 0),
    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
  }));

export const SelectTemplateContainer=styled(Box)(({
    theme
}) => ({
    display:'grid',
    gridTemplateColumns:'1fr 2fr 2fr',
    gridGap:theme.spacing(1),
    marginTop:theme.spacing(1)
}));

export const StyledButton = styled(Button)(({
    theme
}) => ({
        fontSize: theme.spacing(2)
}));

export const Container = styled(Box)(({
    theme
}) => ({
    display: 'grid',
    gridGap: theme.spacing(1),
    marginTop: theme.spacing(1)
}));

export const MainContainer = styled(Box)(({
    theme
})=>({
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5)
}));

export const FullWidthFormControl = styled(FormControl)`
    width: 100%
`;

export const ButtonContainer = styled(Box)(({
    theme
}) => ({
    display: 'flex',
    gap: theme.spacing(1)
}));