import { FileDownloadOutlined } from "@mui/icons-material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import { fetchBeforeSave } from "../../../../utils/helpers/misc.helper";
import { CapitalCallDetailsTabs } from "../constants";
import DisplayPdf from "./DisplayPdf";
import EmailViewer from "./EmailViewer";
import PdfViewer from "./PdfViewer";
import {
  ApproveNextContainer,
  ButtonBox,
  ButtonContainer,
  DownLoadButton,
  EmailContainer,
  EmailPdfContainer,
  FlagButton,
  InvestorContainer,
  LeftBox,
  LinkButton,
  MainContainer,
  MarginFixContainer,
  PreviousContainer,
  ReviewTypography,
  RightBox,
  SplitButtonContainer,
  StyledButton,
  StyledContainer,
  StyledDivider,
  StyledStack,
  StyledTypography,
} from "./ReviewDocuments.styles";
import { useReviewDocuments } from "./useReviewDocument.hooks";
type RouteProp = {
  id: string;
};

const ReviewDocumentsTab: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteProp>();
  const {
    selectedContactMode,
    contactModeOptions,
    onContactModeChange,
    reviews,
    investors,
    documentUrls,
    currentIndex,
    handleSelectChange,
    handleRightSideOnClick,
    handlePrevious,
    handleNext,
    handleFlag,
    fullScreen,
    setFullScreen,
    currentReview,
    isNextDisabled,
    nextButtonText,
    emails,
    loading,
    isSuperAdminOrClientAdmin,
    capitalCall,
    allowEdit,
    handleDownloadAll,
  } = useReviewDocuments();

  return (
    <Box>
      <Progress id="review_documents_progress_bar" showProgress={loading} />
      {reviews?.length === 0 ? (
        <>
          <ReviewTypography variant="h2">No reviews found</ReviewTypography>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            stickToCenter={true}
            columnGap={true}
            sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }} 
          >
            <StyledButton
              id="btn_go_back"
              variant="outlined"
              onClick={() => {
                history.push(
                  `/capital-calls/${params?.id}/${CapitalCallDetailsTabs.AddTransactions}`
                );
              }}
            >
              Go Back
            </StyledButton>
            <StyledButton
              id="btn_save_continue"
              variant="contained"
              onClick={() => {
                history.push(
                  `/capital-calls/${params?.id}/${CapitalCallDetailsTabs.Publish}`
                );
              }}
            >
              Continue to Publish
            </StyledButton>
          </HorizontalBox>
        </>
      ) : (
        <>
          <StyledDivider variant="fullWidth" />
          <MainContainer>
            <LeftBox>
              <Stack direction="row">
                <StyledStack>
                  <Select
                    id="investors_select"
                    value={currentReview?.investorName || ""}
                    onChange={handleSelectChange}
                    sx={{
                      height: "75%",
                    }}
                  >
                    {investors.map((investor, index) => (
                      <MenuItem key={index} value={investor}>
                        <InvestorContainer direction="row">
                          <Typography variant="caption">
                            <strong> {investor} </strong>
                          </Typography>

                          <StyledTypography variant="h6">
                            {index + 1} of {investors.length}
                          </StyledTypography>
                        </InvestorContainer>
                      </MenuItem>
                    ))}
                  </Select>
                </StyledStack>
                <ButtonBox>
                  <ToggleButton
                    id="toggle_contact_mode"
                    title=""
                    options={contactModeOptions}
                    value={selectedContactMode}
                    onChange={onContactModeChange}
                  />
                </ButtonBox>
                <PreviousContainer>
                  <LinkButton
                    id="previous"
                    variant="text"
                    disableElevation
                    startIcon={<ArrowBackIosRoundedIcon />}
                    onClick={handlePrevious}
                    name="previous"
                    disabled={currentIndex === 0}
                  >
                    Previous
                  </LinkButton>
                </PreviousContainer>
                <ApproveNextContainer>
                  <LinkButton
                    id="approve_next"
                    variant="contained"
                    disableElevation
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleNext}
                    name="next"
                    disabled={isNextDisabled}
                  >
                    {capitalCall?.status?.toLowerCase() !== "reviewing" &&
                    !allowEdit
                      ? "Next"
                      : currentReview?.canApprove
                      ? nextButtonText
                      : "Next"}
                  </LinkButton>
                </ApproveNextContainer>
              </Stack>
              <StyledContainer>
                <Stack>
                  {currentReview?.canApprove && (
                    <FlagButton
                      size="small"
                      variant="outlined"
                      disabled={
                        (capitalCall?.status?.toLowerCase() !== "reviewing" &&
                          !allowEdit) ||
                        capitalCall?.status?.toLowerCase() === "published"
                      }
                      startIcon={<FlagCircleIcon />}
                      onClick={() => {
                        const resultFlag =
                          currentReview?.checked ||
                          currentReview?.checked === null
                            ? false
                            : null;

                        handleFlag(resultFlag);
                      }}
                      color={
                        currentReview?.checked === false ? "error" : "inherit"
                      }
                      fullscreen={fullScreen.toString()}
                    >
                      Flag
                    </FlagButton>
                  )}
                </Stack>
                {selectedContactMode === "capitalCalls" && (
                  <EmailPdfContainer fullscreen={fullScreen.toString()}>
                    <ButtonContainer direction="row" spacing={1}>
                      <DownLoadButton
                        id={`pdf_download_button`}
                        variant="outlined"
                        onClick={() =>
                          fetchBeforeSave(
                            documentUrls[currentIndex],
                            `${investors[currentIndex]}.pdf`
                          )
                        }
                        size="small"
                        startIcon={<FileDownloadOutlined />}
                      >
                        <strong>Download PDF</strong>
                      </DownLoadButton>
                      <IconButton
                        aria-label="fullscreen"
                        onClick={() =>
                          setFullScreen((fullscreen) => !fullscreen)
                        }
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </ButtonContainer>
                    <PdfViewer pdfLink={documentUrls[currentIndex]} />
                  </EmailPdfContainer>
                )}
                {selectedContactMode === "email" && (
                  <EmailContainer fullscreen={fullScreen.toString()}>
                    <EmailViewer
                      contactEmails={currentReview?.contactEmails || ""}
                      email={emails[currentIndex]}
                    />
                  </EmailContainer>
                )}
              </StyledContainer>
            </LeftBox>

            <RightBox>
              <SplitButtonContainer>
                <DownLoadButton
                  id={`pdf_download_button`}
                  variant="outlined"
                  onClick={handleDownloadAll}
                  size="small"
                  startIcon={<FileDownloadOutlined />}
                >
                  <strong>Download All </strong>&nbsp;
                </DownLoadButton>

                <DisplayPdf
                  handleOnClick={handleRightSideOnClick}
                  investors={investors}
                  documentUrls={documentUrls}
                  reviews={reviews}
                  selectedIndex={currentIndex}
                ></DisplayPdf>
              </SplitButtonContainer>
            </RightBox>

            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              stickToCenter={true}
              columnGap={true}
              sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }} 
            >
              <StyledButton
                id="btn_go_back"
                variant="outlined"
                onClick={() => {
                  history.push(
                    `/capital-calls/${params?.id}/${CapitalCallDetailsTabs.AddTransactions}`
                  );
                }}
              >
                Go Back
              </StyledButton>
              <StyledButton
                id="btn_save_continue"
                variant="contained"
                onClick={() => {
                  history.push(
                    `/capital-calls/${params?.id}/${CapitalCallDetailsTabs.Publish}`
                  );
                }}
              >
                Continue to Publish
              </StyledButton>
            </HorizontalBox>
          </MainContainer>
        </>
      )}
    </Box>
  );
};

export default React.memo(ReviewDocumentsTab);
