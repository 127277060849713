import CloseIcon from "@mui/icons-material/Close";
import { createSvgIcon, useTheme } from "@mui/material";
import parse from "date-fns/parse";
import React from "react";

import {
  DOWNLOAD_ICON,
  FILE_ICON,
} from "../../../utils/constants/text.constants";
import { DateTimeFormat } from "../../../utils/helpers/format.helper";
import { fetchBeforeSave } from "../../../utils/helpers/misc.helper";
import { PortalDistributions } from "../../../utils/types/distributions.type";
import PdfViewer from "../../capitalCalls/capitalCallDetails/ReviewDocuments/PdfViewer";
import { useViewNotice } from "./useViewNotice.hooks";
import {
  DetailPanel,
  DownLoadButton,
  DownloadButtonContainer,
  PdfContainer,
  StyledTypography,
  TitleBox,
} from "./ViewNotice.styles";
interface Props {
  onViewNoticeClose: Function;
  selectedDistribution: PortalDistributions;
}
const ViewNotice = ({ onViewNoticeClose, selectedDistribution }: Props) => {
  const { pdfLink } = useViewNotice({ selectedDistribution });
  const theme = useTheme();
  const FileDownloadIcon = createSvgIcon(
    <svg>
      <path d={FILE_ICON} fill={theme.palette.secondary.light} />{" "}
      <path d={DOWNLOAD_ICON} fill={theme.palette.secondary.light} />
    </svg>,

    "FileDownLoad"
  );

  return (
    <DetailPanel
      id="notice_panel"
      anchor="right"
      open={Boolean(selectedDistribution)}
      onClose={() => {
        onViewNoticeClose();
      }}
    >
      <TitleBox>
        <StyledTypography variant="h2">
          {selectedDistribution?.investorName}
        </StyledTypography>
        <CloseIcon
          onClick={() => {
            onViewNoticeClose();
          }}
        />
      </TitleBox>
      <StyledTypography variant="h3">
        {selectedDistribution?.name}
      </StyledTypography>
      <DownloadButtonContainer>
        <StyledTypography variant="caption">
          {DateTimeFormat.shortDate(selectedDistribution.distributionDate)}
        </StyledTypography>

        <DownLoadButton
          id={`pdf_download_button`}
          variant="contained"
          onClick={() =>
            fetchBeforeSave(pdfLink, `${selectedDistribution.investorName}.pdf`)
          }
          size="small"
          startIcon={<FileDownloadIcon />}
        >
          Download
        </DownLoadButton>
      </DownloadButtonContainer>
      <PdfContainer>
        <PdfViewer pdfLink={pdfLink} clientView={true} />
      </PdfContainer>
    </DetailPanel>
  );
};

export default React.memo(ViewNotice);
