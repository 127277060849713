import { FormControlLabel } from "@mui/material";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";

import { ButtonText, FormControlCheckAll } from "../Filter.style";
import {
  FilterAllStatus,
  FilterCheckBox,
  FilterItem,
  ListFilter,
} from "./FilterStatus.style";

type Props = {
  options?: Record<string, any>[];
  selectedOptions?: string[];
  handleFilter: (filter: any, selectedOptions: string[]) => void;
  idField: string;
  labelField: string;
  filterName: any;
  clearable?: boolean;
};

const FilterStatus: React.FC<Props> = ({
  options,
  selectedOptions,
  handleFilter,
  idField,
  labelField,
  filterName,
  clearable = true,
}: Props): ReactElement => {
  const [selectedOptionList, setSelectedOptionList] = useState<string[]>([]);
  const [checkCheckStatus, setCheckStatus] = useState<any>([]);
  let selectedItem: any = [];

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOptionList(selectedOptions);
    }
  }, [selectedOptions]);

  const onCheckAll = (_: ChangeEvent, checked: boolean) => {
    if (checked) {
      setSelectedOptionList(options?.map((item) => item[idField]) ?? []);
    } else {
      setSelectedOptionList([]);
    }
  };

  const onItemClick = (id: string) => {
    if (selectedOptionList.includes(id)) {
      selectedItem = selectedOptionList.filter((item) => item !== id);

      setSelectedOptionList([...selectedItem]);
      handleFilter(filterName, selectedItem);
    } else {
      selectedItem = [...selectedOptionList, id];

      setSelectedOptionList(selectedItem);
      handleFilter(filterName, selectedItem);
    }
  };

  return (
    <div id="popoverCheckFilter">
      <FilterAllStatus>
        <b>
          <FilterItem>
            <FormControlCheckAll
              id="checkAll"
              key="All"
              control={
                <FilterCheckBox
                  checked={selectedOptionList.length === options?.length}
                  indeterminate={
                    options &&
                    selectedOptionList.length > 0 &&
                    selectedOptionList.length < options?.length
                  }
                  onChange={onCheckAll}
                />
              }
              label="All"
            />
          </FilterItem>
        </b>
      </FilterAllStatus>
      <ListFilter>
        {options &&
          options.reduce((acc: Record<string, any>[], cur) => {
            return [
              ...acc,
              <FilterItem key={cur[idField]}>
                <FormControlLabel
                  key={cur[idField]}
                  control={
                    <FilterCheckBox
                      id={`${cur[idField]}_check`}
                      name={cur[labelField]}
                      checked={selectedOptionList?.includes(cur[labelField])}
                      onChange={() => onItemClick(cur[idField])}
                    />
                  }
                  label={cur[labelField]}
                />
              </FilterItem>,
            ];
          }, [])}
      </ListFilter>
      <ButtonText id="btnApply" variant="text">
        Apply
      </ButtonText>
    </div>
  );
};

export default FilterStatus;
