import { useState } from "react";

import { getLedgers } from "../../../../../services/arkGL.service";
import { getGlEntries } from "../../../../../services/workbook.service";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { DataGridColDef } from "../../../../../utils/types/listItems";
import { GlEntry, Workbook, WorkbookFrequencyType } from "../../workbook.type";
import { AllocationsSheetManager } from "../sheets/allocations/AllocationsSheet.Manager";

export const useGlConnect = (
  allocationsSheetManager: AllocationsSheetManager,
  onClose: () => void
) => {

  const [ledgers, setLedgers] = useState<any[]>([]);
  const [selectedLedgersIDs, setSelectedLedgersIDs] = useState<any[]>([]);
  const [showGlConnectError, setShowGlConnectError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffectAsync(async isCanceled => {
    const fund = allocationsSheetManager.workbookSheetsManager.fund;

    const ledgersResponse = await getLedgers(fund.id);

    if(isCanceled() || !ledgersResponse?.items) return;

    const ledgers = ledgersResponse.items as any[];
    
    ledgers.forEach((l, index) => { 
        l.id = l.ledgerId;
        l.currency = l.currency ?? fund.currency;
    });

    setLedgers(ledgers);
  }, []);

  async function handleConnect() {
    const wbSheetManager = allocationsSheetManager.workbookSheetsManager;
    const summaryType: WorkbookFrequencyType = wbSheetManager.frequency;
    let response: GlEntry[]|string;

    try {
      setIsLoading(true);
      response = await getGlEntries(selectedLedgersIDs, summaryType, wbSheetManager.workbookYear);
      setIsLoading(false);

      if(typeof response === 'string') {
        setShowGlConnectError(true);
      } else {
        setShowGlConnectError(false);
        allocationsSheetManager.handleGlConnectEntities(response);
        onClose();
      }
    } catch(err) {
      setShowGlConnectError(true);
    }
  }

  function handleSetSelectedLedgers(selectedledgers: string[]) {
    setSelectedLedgersIDs(selectedledgers);
  }

  const headerList:DataGridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        hide: false,
        index: 1,
        type: "string",
        sortable: false,
        align: "left",
        headerAlign: "left",
        width: 500
      },
      {
        field: "currency",
        headerName: "Currency",
        hide: false,
        index: 2,
        type: "string",
        sortable: false,
        align: "left",
        headerAlign: "left",
        maxWidth: 125
      }
    ];

  function getInvalidFormDataError(): string|null {
    if(!selectedLedgersIDs?.length) return 'Must select at least one ledger';

    const selectedLedgers = ledgers.filter(l => selectedLedgersIDs.find(id => id === l.id));
    const sameCurrency = selectedLedgers.every((val, index, ary) => index === 0 ? true : val.currency === ary[index-1].currency);

    return sameCurrency ? null : 'All selected ledgers must have the same currency';
  }

  const invalidFormDataError = getInvalidFormDataError();

  return {
    isLoading,
    headerList,
    ledgers,
    selectedLedgersIDs, handleSetSelectedLedgers,
    handleConnect,
    invalidFormDataError,
    showGlConnectError, setShowGlConnectError
  };
};