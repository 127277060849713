import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getPortfolioCompanyFilterList } from "../../../services/filters.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { PortfolioCompanySimple } from "../../../utils/types/portfolioCompany.type";

export const usePortfolioCompanyFilterEffect = (refetch: boolean) => {
  const { informationAlert } = useContext(AppContext);
  const [portfolioCompanyList, setPortfolioCompanyList] = useState<PortfolioCompanySimple[]>();
  const [loading, setLoading] = useState(false);

  const fetchPortfolioCompanyFilterList = async (isCanceled?: () => boolean) => {
    setLoading(true);
    try {
      const statusResponse = await getPortfolioCompanyFilterList();

      if (isCanceled?.()) return;

      if (statusResponse)
        setPortfolioCompanyList(statusResponse);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchPortfolioCompanyFilterList(isCanceled);
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchPortfolioCompanyFilterList(isCanceled);
  }, [refetch]);

  return {
    portfolioCompanyList,
    loading,
  };
};

export default usePortfolioCompanyFilterEffect;

