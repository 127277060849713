import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ClientTransTypeRow } from '../../../../utils/types/clientTransMapping.type';

type Props = {
  row?: ClientTransTypeRow;
  onSave: Function;
  onCancel: Function;
};

export const useAddRow = ({ row, onSave, onCancel }: Props) => {
  const { handleSubmit, control, watch, reset } = useForm<ClientTransTypeRow>({
    defaultValues: row ?? {},
  });

  const useMetric = watch('useMetric');

  useEffect(() => {
    reset(row);
  }, [row, reset]);

  const addUpdatedRow = (data: ClientTransTypeRow) => {
    let metricSign = '';
    let metricFractionDigit = 0;

    if (data?.useMetric) {
      if (data.metricSign === '00/00/0000') {
        metricSign = '00/00/0000';
        metricFractionDigit = 0;
      } else {
        const fractionPart = data?.metricSign?.split('.')[1] ?? '';
        const fractionPartLength = fractionPart.length;

        metricSign =
          fractionPartLength > 0
            ? fractionPart?.charAt(fractionPartLength - 1)
            : '';
        metricFractionDigit =
          fractionPartLength > 0 ? fractionPartLength - 1 : 0;
      }
    }

    onSave({
      ...data,
      metricSign,
      metricFractionDigit,
    });
    reset();
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  return {
    addUpdatedRow,
    handleSubmit,
    control,
    handleCancel,
    useMetric,
  };
};
