import {
  Box,
  ButtonBase,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import ArkLogo from "../../../../assets/images/logos/ark_main_logo.png";
import QuickBooksLogo from "../../../../assets/images/logos/qb-logo.svg";
import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from "../../../../components/DetailPanel/DetailPanel.styles";
import LockConfirmationDialog from "../../../../components/LockConfirmationDialog/LockConfirmationDailog";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import { usePageNavigation } from "../../../../components/PageNavigation/usePageNavigation.hooks";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import StatusLabel from "../../../../components/StatusLabel/StatusLabel";
import { M_DASH_UNICODE } from "../../../../utils/constants/constants";
import { DateTimeFormat } from "../../../../utils/helpers/format.helper";
import { LedgerStateTypes } from "../../../../utils/types/arkGLAccount.type";
import { DetailsType } from "../../../../utils/types/form.type";
import {
  LedgerName,
  LedgerType,
  SelectedLedger,
} from "../../../../utils/types/glSetup.type";
import { useGLSetupDetails } from "./GLSetupDetails.hooks";
import {
  KeyLabel,
  KeyValuePair,
  PlatformButton,
  PlatformButtonArk,
  SelectFormControl,
  StepDescription,
  ValueLabel,
} from "./GLSetupDetails.styles";

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedLedger: SelectedLedger;
  fetchAllLedgers: Function;
  fundId?: string;
  arkGlLocked: boolean;
  glLocked: boolean;
}

export const GLSetupDetails: React.FC<Props> = ({
  onDetailClose,
  selectedLedger,
  fetchAllLedgers,
  fundId,
  arkGlLocked,
  glLocked
}: Props) => {
  const { type, ledger } = selectedLedger;
  const isNewLedger = type === DetailsType.New;
  const { lockedPrompt, setLockedPrompt, showLockedDialog } = usePageNavigation();
  const [useDefaultDialogText, setUseDefaultDialogText] = useState(false);

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    createUpdateLedger,
    toBeDeleted,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    selectedPlatform,
    handleSelectPlatform,
    setupCurrentIndex,
    setupSteps,
    isRedirecting,
    handleConnectLedger,
    toggleDrawer,
    currencyList,
    fundList,
    status,
    readonly,
    showReadyToDisconnect,
    handleDisconnect,
    handleCancelDisconnect,
    handleConfirmDisconnect,
    selectedFundId,
    setSelectedFundId,
    isNewQBLedgerAllowed,
  } = useGLSetupDetails({
    ledger,
    onClose: onDetailClose,
    fetchAllLedgers,
    isNewLedger,
    fundId,
    arkGlLocked,
    glLocked
  });

  const arkGlDialogText = "We're thrilled to announce the arkGL feature is currently undergoing testing by our early adopters. If you are interested in becoming an early adopter, please reach out to sales@arkpes.com";

  return (
    <>
      <DetailPanel
        id="detail_panel_ledger"
        title={isNewLedger ? "Add Ledger" : ledger?.name ? ledger.name : ""}
        open={Boolean(type)}
        variant={"temporary"}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        {isNewLedger && !fundId && setupCurrentIndex !== 2 && (
          <>
            <FormBox>
              <Panel id="details_panel">
                <Typography variant="h4">Select a Fund</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectFormControl>
                      <InputLabel id="label_fund">Fund</InputLabel>
                      <Select
                        id="select_fund"
                        labelId="label_fund"
                        label="Fund"
                        size="small"
                        value={selectedFundId}
                        onChange={(e) => {
                          setSelectedFundId(e.target.value);
                        }}
                      >
                        {fundList.map((fund: any) => (
                          <MenuItem key={fund.id} value={fund.id}>
                            {fund.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectFormControl>
                  </Grid>
                </Grid>
              </Panel>
            </FormBox>
          </>
        )}
        {isNewLedger &&
          (fundId || selectedFundId) &&
          setupCurrentIndex === 1 && (
            <>
              <FormBox>
                <Panel id="details_panel">
                  <Typography variant="h4">
                    {setupSteps[setupCurrentIndex - 1].description}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PlatformButtonArk
                        variant="outlined"
                        onClick={(e) => {
                            setUseDefaultDialogText(false);
                            arkGlLocked ? showLockedDialog() : handleSelectPlatform(e);
                          }
                        }
                        className={selectedPlatform === "ark" ? "selected" : ""}
                      >
                        <img src={ArkLogo} alt="ArkGL Logo" id="ark" />
                      </PlatformButtonArk>
                      {isNewQBLedgerAllowed && (
                        <PlatformButton
                          variant="outlined"
                          onClick={(e) => {
                              setUseDefaultDialogText(true);
                              glLocked ? showLockedDialog() : handleSelectPlatform(e);
                            }
                          }
                          className={
                            selectedPlatform === "qb" ? "selected" : ""
                          }
                        >
                          <img
                            src={QuickBooksLogo}
                            alt="QuickBooks Logo"
                            id="qb"
                          />
                        </PlatformButton>
                      )}
                    </Grid>
                  </Grid>
                </Panel>
                <StyledBackdrop open={isRedirecting} />
                <ProgressPanel
                  id={"progress_glsetup_panel"}
                  showProgress={isRedirecting}
                  text="Redirecting to QuickBooks..."
                />
              </FormBox>
              <HorizontalBox
                addTopShadow
                attachToBottom={true}
                hidden={false}
                fullWidth
              >
                <Button
                  id={"btn_glsetup_cancel"}
                  variant="outlined"
                  onClick={toggleDrawer}
                  text={"Cancel"}
                  color={"secondary"}
                  fullWidth
                  addSpaceBetweenButtons
                />
                <Button
                  id={"btn_glsetup_panel_connect"}
                  variant="contained"
                  onClick={handleConnectLedger}
                  text={"Connect"}
                  color={"primary"}
                  fullWidth
                  addSpaceBetweenButtons
                  disabled={!selectedPlatform}
                />
              </HorizontalBox>
            </>
          )}
        {((selectedPlatform === "ark" && setupCurrentIndex === 2) ||
          ledger?.glPlatform === "ARK_LEDGER") && (
          <form
            autoComplete="off"
            onSubmit={handleSubmit(createUpdateLedger)}
            noValidate
          >
            <FormBox>
              <Panel id="details_panel">
                <Typography variant="h4">Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Controller
                      name="name"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextField
                            id="txt_ledger_name"
                            label="Name"
                            aria-describedby="name"
                            placeholder="Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={value ?? ""}
                            disabled={status.id === "POSTED"}
                            onChange={onChange}
                            InputLabelProps={
                              !isNewLedger ? { shrink: !!value } : {}
                            }
                            error={!!error}
                            helperText={error?.message ?? ""}
                          />
                        );
                      }}
                      rules={{
                        validate: (value) => {
                          const input = value.trim().length;

                          if (input === 0) {
                            return "Name is required";
                          } else if (input < 3 || input > 128) {
                            return "Name must be between 3 and 128 characters";
                          }
                          return true;
                        },
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={2} mt={1}>
                    {status.id && (
                      <StatusLabel
                        color={status.color}
                        isUpperCase={false}
                        label={status.name}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="currency"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <SelectFormControl>
                            <InputLabel id="label_currency" error={!!error}>
                              Currency
                            </InputLabel>
                            <Select
                              id="select_currency"
                              labelId="label_currency"
                              label="Currency"
                              size="small"
                              disabled={status.id === "POSTED"}
                              value={value ? value : ""}
                              onChange={(e) => onChange(e.target.value)}
                              error={!!error}
                            >
                              {currencyList.map((currency: any) => (
                                <MenuItem key={currency.id} value={currency.id}>
                                  {currency.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>
                              {error?.message ?? ""}
                            </FormHelperText>
                          </SelectFormControl>
                        );
                      }}
                      rules={{
                        required: "Currency is required",
                      }}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="txt_ledger_description"
                      label="Description"
                      aria-describedby="description"
                      placeholder="Description"
                      size="small"
                      multiline
                      rows={6}
                      fullWidth
                      variant="outlined"
                      disabled={status.id === "POSTED"}
                      error={!!errors.description}
                      {...register("description", {
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="fundId"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <SelectFormControl>
                            <InputLabel id="label_fund" error={!!error}>
                              Fund
                            </InputLabel>
                            <Select
                              id="select_fund"
                              labelId="label_fund"
                              label="Fund"
                              size="small"
                              disabled={!isNewLedger}
                              value={value ? value : ""}
                              onChange={(e) => onChange(e.target.value)}
                              error={!!error}
                            >
                              {fundList.map((fund: any) => (
                                <MenuItem key={fund.id} value={fund.id}>
                                  {fund.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>
                              {error?.message ?? ""}
                            </FormHelperText>
                          </SelectFormControl>
                        );
                      }}
                      rules={{
                        required: "Fund is required",
                      }}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Panel>
            </FormBox>
            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              fullWidth
            >
              <Button
                id={"btn_glsetup_cancel"}
                variant="outlined"
                onClick={toggleDrawer}
                text={"Cancel"}
                color={"secondary"}
                fullWidth
                addSpaceBetweenButtons
              />

              <Button
                id={"btn_glsetup_save"}
                variant="contained"
                disabled={status.id === "POSTED"}
                text={"Save & Close"}
                color={"primary"}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            </HorizontalBox>
          </form>
        )}
        {ledger?.glPlatform === "QUICKBOOKS_ONLINE" && (
          <FormBox>
            <Panel id="details_panel">
              <Typography variant="h4">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Status</KeyLabel>
                    <ValueLabel>
                      {(ledger && ledger.status && `${ledger.status}`) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Linked By:</KeyLabel>
                    <ValueLabel>
                      {(ledger && ledger.linkedBy && `${ledger.linkedBy}`) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12}>
                  <KeyValuePair>
                    <KeyLabel>Linked On</KeyLabel>
                    <ValueLabel>
                      {(ledger &&
                        ledger.linkedOn &&
                        `${DateTimeFormat.shortDate(ledger.linkedOn)}`) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                {!readonly && (
                  <Grid item xs={12}>
                    <Button
                      id={"btn_glsetup_disconnect"}
                      variant="contained"
                      onClick={handleDisconnect}
                      text={"Disconnect"}
                      color={"primary"}
                    />
                  </Grid>
                )}
              </Grid>
            </Panel>
          </FormBox>
        )}
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={"progress_glsetup_panel"}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ""}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_account_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "btn_glsetup_save",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "btn_glsetup_cancel_save",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
      <ConfirmationDialog
        open={showReadyToDisconnect}
        onClose={handleCancelDisconnect}
        id="disconnect_gl_confirmation"
        actions={[
          {
            label: "Disconnect",
            onClick: () => handleConfirmDisconnect(),
            id: "btn_confirm_disconnect_gl",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: handleCancelDisconnect,
            id: "btn_cancel_disconnect",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Are you sure you want to disconnect General Ledger? This action cannot be undone."
        title="Disconnect General Ledger?"
      />
      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
        customText={useDefaultDialogText ? undefined : arkGlDialogText} 
        customTitle=" "
      />
    </>
  );
};
