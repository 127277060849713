import { Autocomplete, FormControl, TextField } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import {
  AccountMatchedBankTransaction,
  BankTransactionJELineItem,
  GlAccountDetails,
  GlAccountsMap,
} from '../../../../utils/types/bank.type';
import { InfoTooltip } from '../../../documents/components/infoTooltip/InfoTooltip';
import { BankStatusOptions } from '../../bankFeeds/BankFeedList.defaults';
import { CellBox, IconBox } from '../../bankFeeds/BankFeedList.styles';
import {
  EditableGlAccountChips,
  NoLedgerOrGlAccountChip,
  ReadOnlyChipsOrDash,
  SelectOptionChip,
} from './DataGridChips';

interface TreeNode extends GlAccountDetails {
  children: TreeNode[];
  depth: number;
}

type Props = {
  isAdmin: boolean;
  row: AccountMatchedBankTransaction;
  CoAList: GlAccountDetails[];
  glAccountIdNameMap: Map<string, GlAccountsMap>;
  updateBankFeedsDataList: (updatedRow: AccountMatchedBankTransaction) => void;
};

export const GLAccountCellRenderer: React.FC<Props> = ({
  isAdmin,
  row,
  CoAList,
  glAccountIdNameMap,
  updateBankFeedsDataList,
}: Props): ReactElement => {
  const isReadOnly = !isAdmin || row.status === BankStatusOptions.Categorized;

  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [glAccountTree, setGlAccountTree] = useState<GlAccountDetails[]>([]);

  const getUniqueGLAccounts = (): string[][] => {
    if (!glAccountIdNameMap.size) return [[], []];

    const uniqueGlAccountNames: string[] = [];
    const glAccountIds: string[] = row.journalEntry?.map(
      (listItem: BankTransactionJELineItem) => listItem.accountId
    );

    const uniqueGlAccountIds = Array.from(new Set(glAccountIds));

    uniqueGlAccountIds.forEach((id) => {
      const name = glAccountIdNameMap.get(id)?.name;

      if (name) uniqueGlAccountNames.push(name);
    });

    return [uniqueGlAccountIds, uniqueGlAccountNames];
  };

  const [uniqueGlAccountIds, uniqueGlAccountNames] = getUniqueGLAccounts();

  useEffect(() => {
    const fundFilteredGLAccountList: GlAccountDetails[] = CoAList.filter(
      (item) => item.fundId === row.fundId
    );

    const buildTree = (
      fundFilteredGLAccountList: GlAccountDetails[]
    ): TreeNode[] => {
      const map: { [key: string]: TreeNode } = {};
      const roots: TreeNode[] = [];

      fundFilteredGLAccountList.forEach((item: GlAccountDetails) => {
        map[item.id] = { ...item, children: [], depth: 0 };
      });

      fundFilteredGLAccountList.forEach((item) => {
        if (item.parentId === null) {
          roots.push(map[item.id]);
        } else {
          map[item.parentId].children.push(map[item.id]);
        }
      });

      return roots;
    };

    const flattenTree = (nodes: TreeNode[], depth = 0): GlAccountDetails[] => {
      let result: GlAccountDetails[] = [];

      nodes.forEach((node) => {
        node.depth = depth;
        node.isDisabled = false;
        result.push(node);
        if (node.children.length > 0) {
          result = result.concat(flattenTree(node.children, depth + 1));
        }
      });
      return result;
    };

    const treeData = buildTree(fundFilteredGLAccountList);
    const flattenedData = flattenTree(treeData);

    setGlAccountTree(flattenedData);
  }, [CoAList, row]);

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleGlAccountSelection = (glAccount: GlAccountDetails) => {
    if (!glAccount) return;
    const updatedRow = {
      ...row,
      journalEntry: row.journalEntry.map((lineItem) => {
        return { ...lineItem, accountId: glAccount.id };
      }),
      isEditedRow: {
        ...row.isEditedRow,
        isGlAccountEdited:
          uniqueGlAccountIds?.length === 1 &&
          uniqueGlAccountIds[0] === glAccount.id
            ? false
            : true,
        isEntityRequired:
          glAccount.isEntityRequired || row.isEntityRequiredByBankAcc,
      },
    };

    updateBankFeedsDataList(updatedRow);
  };

  const handleStopEditing = () => {
    setIsEditing(false);
    setIsHovered(false);
  };

  const currentOption =
    uniqueGlAccountIds?.length === 1
      ? glAccountTree.find((acc) => acc.id === uniqueGlAccountIds[0])
      : undefined;

  return isReadOnly ? (
    <ReadOnlyChipsOrDash chipData={uniqueGlAccountNames} />
  ) : isEditing ? (
    <FormControl fullWidth onClick={() => {}}>
      <IconBox>
        <FormControl fullWidth>
          <Autocomplete
            id="gl_account_inline_edit"
            size="small"
            options={glAccountTree}
            value={currentOption}
            getOptionLabel={(option) => `${option.number} - ${option.name}`}
            renderOption={(props, option) => (
              <li {...props} style={{ paddingLeft: option.depth * 16 + 16 }}>
                {option.number} - {option.name}
              </li>
            )}
            onClose={handleStopEditing}
            openOnFocus
            fullWidth
            onChange={(event: React.SyntheticEvent, value: any) => {
              handleGlAccountSelection(value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === ' ' ||
                event.key === 'ArrowDown' ||
                event.key === 'ArrowUp'
              ) {
                event.stopPropagation();
              }
            }}
            renderInput={(params) => (
              <TextField autoFocus {...params} placeholder="GL Account" />
            )}
          />
        </FormControl>
        {uniqueGlAccountNames.length > 1 && (
          <InfoTooltip text="This transaction has multiple Journal Entry Line Items. Selecting a GL Account will update all Journal Entry Line Items." />
        )}
      </IconBox>
    </FormControl>
  ) : uniqueGlAccountNames.length ? (
    <EditableGlAccountChips
      chipData={uniqueGlAccountNames}
      onChipClick={handleStartEditing}
    />
  ) : (
    <CellBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseMove={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        row.isNonGlBankAccount ? (
          <NoLedgerOrGlAccountChip />
        ) : (
          <SelectOptionChip
            onChipClick={handleStartEditing}
            label="Select GL Account"
          />
        )
      ) : (
        M_DASH_UNICODE
      )}
    </CellBox>
  );
};
