import { Box, styled, Typography } from '@mui/material';

import {
  ViewOption,
  ViewOptionTypes,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '64px',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
}));

export const SoiDataWrapperBox = styled(Box)<{
  hidden?: boolean;
  gapSize?: number;
}>(({ theme, hidden, gapSize = 1 }) => ({
  height: '100%',
  display: hidden === true ? 'none' : 'grid',
  gap: theme.spacing(gapSize),
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
}));

export const HeaderButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minHeight: '64px',
  gap: theme.spacing(2),
}));

export const HeaderSubComponent = styled(Box)<{
  gap?: number;
}>(({ theme, gap = 0 }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(gap),
}));

export const DataGridBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: '8px',
}));

export const SoiDataWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'auto',
  maxHeight: '100%',
  gridTemplateColumns: '1fr',
}));

export const SoiDataBox = styled(Box)<{
  viewState: ViewOptionTypes;
}>(({ theme, viewState }) => ({
  position: 'absolute',
  display: 'grid',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr',
  gridTemplateRows:
    viewState === ViewOption.DASHBOARD
      ? '1fr'
      : 'minmax(0, auto) minmax(40%, 1fr)',
}));

export const VisualizationsBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: '8px',
  overflow: 'auto',
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));
