import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(3, 6),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(1),
  },
  '&  .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.modalBackground,
  },
  '& .MuiDialogActions-root a': {
    textDecoration: 'none',
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(26),
  lineHeight: 1.1,
  color: theme.palette.common.black,
  textAlign: 'center',
  padding: 0,
  marginBottom: theme.spacing(2),
}));

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.22,
    color: theme.palette.common.black,
    textAlign: 'center',
  })
);

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

export const InlineDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '97% 3%',
}));
