import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material";

import TransactionTypeFilter from "../../../../components/Selectors/TransactionTypesFilter";

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const FormBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
}));

export const StyledTransactionFilter = styled(TransactionTypeFilter)(
  ({ theme }) => ({
    width: "100%",
  })
);

export const MarginFixContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, -8),
}));
export const StyledButton = styled(Button)(() => ({
  width: "20%",
}));
export const StyledStack = styled(Stack)(() => ({
  width: "15%",
}));

export const AddButton = styled(Button)(() => ({
  width: "18%",
}));
export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  padding: theme.spacing(10),
}));
export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledTypography = styled(Typography)<{ flag: string }>(
  ({ theme, flag }) => ({
    marginLeft: flag === "false" ? theme.spacing(2.7) : theme.spacing(0),
    fontFamily: "PublicSans",
    fontWeight: "400",
  })
);
export const DataGridContainer = styled(Box)(() => ({
  overflow: "scroll",
  maxHeight: "50vh",
}));
