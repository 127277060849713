import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Grid, TextField, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import { DeleteButton } from '../../../../components/Buttons/DeleteButton/DeleteButton.styles';
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import { FormBox, StyledBackdrop, Title } from "../../../../components/DetailPanel/DetailPanel.styles";
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import CustomPropertyTypeFilter from "../../../../components/Selectors/CustomPropertyTypeFilter/CustomPropertyTypeFilter";
import { CustomPropertyDetailsViewType, SelectedCustomProperty } from '../../../../utils/types/customProperty.type';
import { useCustomFieldDetails } from "./customFieldDetails.hooks";
import { CustomPropertyListBox, DeleteIconButton, FieldButton, HeaderRow, Panel, Typography } from "./customFieldDetails.styles";

interface Props extends RouteProps {
  selectedCustomProperty: SelectedCustomProperty;
  setSelectedCustomProperty: Function;
  fetchAllPortfolioCompanies: Function;
  onDetailClose: Function;
}

export const CustomFieldDetails: React.FC<Props> = ({
  selectedCustomProperty,
  setSelectedCustomProperty,
  fetchAllPortfolioCompanies,
  onDetailClose
}: Props) => {

  const { viewType, customProperty } = selectedCustomProperty;
  const isNewCustomProperty = viewType === CustomPropertyDetailsViewType.New;

  const {
    loading,
    register,
    handleCancelDelete,
    handleConfirmDelete,
    handleDelete,
    handleSubmit,
    handleViewCustomPropertyDetails,
    errors,
    control,
    fields,
    append,
    remove,
    createUpdateCustomProperty,
    toggleDrawer,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    formType,
    toBeDeleted,
    customPropertyList
  } = useCustomFieldDetails({
    customProperty,
    isNewCustomProperty,
    setSelectedCustomProperty,
    fetchAllPortfolioCompanies,
    onClose: onDetailClose,
  });

  return (
    <>
      <DetailPanel
        id="custom_field_panel"
        title={isNewCustomProperty ? "Add Custom Field" : customProperty?.name}
        open={Boolean(viewType)}
        onClose={toggleDrawer}
        variant={"temporary"}
        hasTabsPanel={false}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdateCustomProperty)}
          noValidate
        >
          <FormBox>
            <Panel id="custom_fields_panel">
              <Grid container xs={12} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <TextField
                    error={!!errors.name}
                    {...register("name", {
                      required: true,
                      validate: (value: any) => !!value?.trim(),
                    })}
                    helperText={
                      (!!errors.name && "Custom Field Name is required")
                    }
                    id={`custom_field_name`}
                    size="small"
                    fullWidth
                    aria-describedby={'custom_field_name'}
                    label="Custom Field Name"
                    placeholder="Custom Field Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={'formType'}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <CustomPropertyTypeFilter
                          size="small"
                          id={'custom_property_filter'}
                          value={value ?? ''}
                          onChange={(type: any) => onChange(type)}
                          error={error}
                          placeholder="Custom Field Type"
                        />
                      </>
                    )}
                    rules={{
                      required: "Custom Field type is required",
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('description')}
                    id={'custom_field_description'}
                    size="small"
                    fullWidth
                    aria-describedby={'custom_field_description'}
                    label="Custom Field Description"
                    placeholder="Custom Field Description"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              {formType === 'FORMATTED_NUMBER' &&
                <Grid container xs={12} spacing={2} mb={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...register('formattingValue', {
                        required: true,
                        validate: (value: any) => !!value?.trim(),
                      })}
                      error={!!errors.formattingValue}
                      helperText={
                        (!!errors.formattingValue && "Formatting value required") ||
                        (!errors.formattingValue && 'Example: x##-##')
                      }
                      id={'custom_field_formatting_value'}
                      size="small"
                      fullWidth
                      aria-describedby={'custom_field_formatting_value'}
                      label="Custom Field Formatting Value"
                      placeholder="Custom Field Formatting Value"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              }
              {(formType === 'MULTIPLE_CHECKBOX' ||
                formType === 'DROPDOWN_SELECTION' ||
                formType === 'RADIO_SELECTION') &&
                <>
                  <Typography variant='label'>Values</Typography>
                  <Grid container xs={12} mt={3}>
                    {fields.map((field, index) => (
                      <>
                        <Grid item xs={9} spacing={2} mb={2} key={field.id}>
                          <TextField
                            {...register(`values.${index}`, {
                              required: true,
                              validate: (value: any) => !!value?.trim(),
                            })}
                            error={!!errors.values}
                            helperText={
                              (!!errors.values && "Custom Field Values are required")
                            }
                            {...field}
                            id={`custom_field_value_${index}`}
                            size="small"
                            fullWidth
                            aria-describedby={`custom_field_value_${index}`}
                            label={`Custom Field Value ${index + 1}`}
                            placeholder="Custom Field Value"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        {index !== 0 &&
                          <Grid item xs={1}>
                            <DeleteIconButton
                              id={`btn_delete_field${index}`}
                              aria-label={`btn_delete_field${index}`}
                              onClick={() => remove(index)}
                            >
                              <ClearIcon />
                            </DeleteIconButton>
                          </Grid>
                        }
                      </>
                    ))}
                  </Grid>
                  <Grid container xs={12} mt={2} mb={4}>
                    <Grid item xs={6}>
                      <Tooltip title="Add more values">
                        <FieldButton
                          id='btn_add_field'
                          aria-label='btn_add_field'
                          onClick={() => append('')}
                        >
                          +
                        </FieldButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>
              }
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={"custom_field_cancel_button"}
              variant="outlined"
              onClick={toggleDrawer}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={"custom_field_save_button"}
              variant="contained"
              text={"Save & Close"}
              color={"primary"}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
        <FormBox>
          {!isNewCustomProperty &&
            <DeleteButton
              id="btn_delete_custom_field"
              variant="text"
              disableElevation
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={handleDelete}
              name="Delete Custom Field"
            >
              Delete Custom Field
            </DeleteButton>
          }
          <Panel id="list_panel">
            <HeaderRow>
              <Typography variant="h4">Custom Fields</Typography>
            </HeaderRow>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                {customPropertyList &&
                  customPropertyList.length > 0 &&
                  customPropertyList.map(customProperty => {
                    return (
                      <CustomPropertyListBox
                        key={customProperty.id}
                      >
                        <ActionLink
                          id={`link_${customProperty.id}`}
                          onClick={() => handleViewCustomPropertyDetails(customProperty)}
                        >
                          {customProperty.name}
                        </ActionLink>
                        <Typography>
                          {customProperty.label}
                        </Typography>
                      </CustomPropertyListBox>
                    );
                  }
                  )}
              </Grid>
            </Grid>
          </Panel>
        </FormBox>
        <StyledBackdrop
          open={Boolean(loading)}
        />
        <ProgressPanel
          id={"progress_custom_field_panel"}
          showProgress={Boolean(loading)}
          text={`${loading} Custom Field...`}
        />

      </DetailPanel>
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete_custom_property_confirmation"
        actions={[
          {
            label: "Delete Permanently",
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: "btn_delete_custom_field",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: handleCancelDelete,
            id: "btn_cancel_delete",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Are you sure you want to delete this custom field? This action cannot be undone."
        title="Delete custom field?"
      />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_custom_field_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'save_custom_field_modal_button',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'cancel_save_custom_field_modal_button',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};