import { styled, Typography } from "@mui/material";


export const NameText = styled(Typography, {
  shouldForwardProp: (props) => props !== "columnWidth"
})<{
  columnWidth: number;
}>(({ columnWidth, theme }) => ({
  marginLeft: theme.spacing(2),
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: columnWidth - 30,
  maxWidth: 'max-content',
}));