import { cloneDeep } from "lodash";

import { getTranactionTypeColumns, getTransactions } from "../../../../../../../services/workbook.service";
import { generateUUID } from "../../../../../../../utils/helpers/uuidGenerator";
import { ClientTransTypeRow } from "../../../../../../../utils/types/clientTransMapping.type";
import { GlEntry, InvestorRowType, SubmitToPcapTransactionsType, TransactionColumn, TransactionColumnType, TransactionTypeQuarterlySum } from "../../../../workbook.type";
import { CellFormats, ColWidth, NewColPosition } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseTransactionsColumnsManager } from "./BaseTransactionsColumns.Manager";

export class TransactionColumnsManager extends BaseTransactionsColumnsManager {
    private _transactionColumns!: TransactionColumn[];
    private _transTypeSums!: Map<number, TransactionTypeQuarterlySum[]>;

    public get transactionColumns() {
        return cloneDeep(this._transactionColumns).sort((a,b) => a.index-b.index);
    }

    public get startColumnId(): string {
        return this.transactionColumns[0].gridColId;
    }

    public get endColumnId(): string {
        return this.transactionColumns[this.transactionColumns.length-1].gridColId;
    }

    public getTransactionTypeValues(quarter: number, transactionTypeId: string) {
        const grid = this.grid;
        const transCol = this.transactionColumns.find(c => c.quarterNumber === quarter && c.transTypeId === transactionTypeId)!;
        const transTypeName = transCol.label;

        const transTypeValues: SubmitToPcapTransactionsType = {
            transactionName: transTypeName,
            transactionTypeId: transactionTypeId,
            transactionTypeModels: []
        };

        this.investorRowManager.investorRows.filter(ir => ir.rowType === InvestorRowType.Investor).forEach(ir => {
            const cellNum = grid.getCellValue(ir.gridRowId, transCol.gridColId);
            const parseNum = parseFloat(cellNum);
            let num = isNaN(parseNum) ? 0 : parseNum;

            if(num !== 0) {
                if(transCol?.metricSign === -1) {
                    num *= -1;
                }

                transTypeValues.transactionTypeModels.push({
                    investorId: ir.investorId,
                    balance: parseFloat( num.toFixed(2) )
                });
            }
        });
        
        return transTypeValues;
    }

    public getTransactionTypeGLRconcileStatus(quarter: number, transactionTypeId: string) {
        try {
            const grid = this.grid;
            const transCol = this.transactionColumns.find(c => c.quarterNumber === quarter && c.transTypeId === transactionTypeId)!;
            const partnerTotalRow = this.investorRowManager.investorRows.find(ir => ir.rowType === InvestorRowType.PartnerTotal)!;

            // Partner total reconciler row
            const reconcilerRowId = grid.getNextRowId(partnerTotalRow.gridRowId);
            let partnerTotalVal = grid.getCellValue(reconcilerRowId, transCol.gridColId);

            partnerTotalVal = parseFloat( partnerTotalVal.toFixed(2) );  // round to nearest cent because sometimes we get very tiny (scientific notation) numbers

            if(isNaN(partnerTotalVal)) {
                return false;
            }

            return partnerTotalVal === 0;
        } catch {
            return false;
        }
    }

    public async initTransactionsColumnsData(transactionColumns: TransactionColumn[], initialStartColId?: string) {
        if(this.workbookSheetsManager.isNewWorkbook) {
            this._transactionColumns = this.buildNewTransactionColumns(transactionColumns, this.workbookSheetsManager.firstQuarter);

            let currColId = initialStartColId!;

            this._transactionColumns.sort((a, b) => a.index - b.index).forEach((col, index) => {
                col.gridColId = currColId;
    
                currColId = this.grid.getNextColId(currColId);
            });
        } else {
            this._transactionColumns = transactionColumns;
        }

        await this.updateTransactionsFromApi();
    }

    private async updateTransactionsFromApi() {
        const transTypeColumnsResponse = await getTranactionTypeColumns(this.workbookSheetsManager.clientId);
        
        this.applyMetricSignsToTransTypColumns(this._transactionColumns, transTypeColumnsResponse);

        this._transTypeSums =  await this.getTransTypeSums();
    }

    public async insertNewColumnsForQuarter(transactionColumns: TransactionColumn[]) {
        const grid = this.grid;
        const currQuarter = this.workbookSheetsManager.lastQuarter;

        const newTransactionColumns = this.buildNewTransactionColumns(transactionColumns, currQuarter);

        let currColId = this.endColumnId;

        newTransactionColumns.forEach(tc => {
            currColId = this.grid.insertCol(currColId, 1, NewColPosition.rightOfDestination);

            tc.gridColId = currColId;
        });


        this._transactionColumns = this._transactionColumns.concat(newTransactionColumns);

        await this.updateTransactionsFromApi();
    }

    public updateCurrentQuarterTransactionColumns(transactionColumns: TransactionColumn[]) {

        transactionColumns.forEach(colToemove => {
            const index = this._transactionColumns.findIndex(col => col.index && col.quarterNumber === colToemove.quarterNumber);

            this._transactionColumns.splice(index, 1);
        });


        this._transactionColumns = this._transactionColumns.concat(transactionColumns);
    }

    public handleGlConnectEntities(glEntries: GlEntry[]) {
        const wb = this.workbookSheetsManager;
        const lastQuarter = wb.lastQuarter;
        const clonedGlEntries = cloneDeep(glEntries);
        const partnerTotalRowId = this.investorRowManager.investorRows.find(cr => cr.rowType === InvestorRowType.PartnerTotal)!.gridRowId;
        const transQuarterCols = this._transactionColumns.filter(col => col.quarterNumber === lastQuarter && !col.useArkTransactionValues)!;

        if(wb.frequency === 'BY_YEAR') {
            clonedGlEntries.forEach(e => e.quarter = lastQuarter);
        }

        transQuarterCols.forEach(transCol => {
            const glEntry = clonedGlEntries.find(entry => entry.transactionTypeId === transCol.transTypeId && entry.quarter === lastQuarter);

            if(glEntry && !transCol.locked) {
                this.grid.setCellValue(partnerTotalRowId, transCol.gridColId, glEntry?.amount, CellFormats.Number_Accounting);
            }            
        });
    }

    private async getTransTypeSums() {
        type getQuarterTransactionsType = { quarter: number, qtrTransTypeSums: TransactionTypeQuarterlySum[] };

        const wb = this.workbookSheetsManager;
        const investorRowManager = this.investorRowManager;
        const transTypes = this._transactionColumns.filter(t => t.useArkTransactionValues).map(t => t.transTypeId);
        
        const allTransTypeSums = new Map<number, TransactionTypeQuarterlySum[]>();
    
        if(wb.frequency === 'BY_YEAR') {
            const qtrTransTypeSums = await getTransactions(wb.fund.id, undefined, wb.workbookYear, investorRowManager.investorIds, transTypes);

            allTransTypeSums.set(wb.lastQuarter, qtrTransTypeSums);
        } else {
            const promises: Promise<getQuarterTransactionsType>[] = [];

            for(let q = wb.firstQuarter; q <= 4; q++) {
                promises.push(getQuarterTransactions(q));               
            }

            const responses = await Promise.all(promises);

            responses.forEach(response => {
                allTransTypeSums.set(response.quarter, response.qtrTransTypeSums);
            });             
        }
    
        return allTransTypeSums;

        async function getQuarterTransactions(quarter: number) {
            const qtrTransTypeSums = await getTransactions(wb.fund.id, quarter, wb.workbookYear, investorRowManager.investorIds, transTypes);

            return { quarter, qtrTransTypeSums };
        }
    }

    private applyMetricSignsToTransTypColumns(transactionColumns: TransactionColumn[], transTypes: ClientTransTypeRow[]) {
        transactionColumns.filter(col => !!col.transTypeId).forEach(col => {
            const transType = transTypes.find(t => t.id === col.transTypeId);
    
            col.metricSign = transType?.metricSign ? parseInt(transType.metricSign) : 0;
        });
    }

    public renderColumns(gridRowIds?: string[], gridColIds?: string[], onlyRenderUseAsArkForAllQuarters?: boolean): void {
        const wb = this.workbookSheetsManager;

        const gridRowsToRender = cloneDeep(gridRowIds);

        if(gridRowsToRender?.length) {
            const partnerRowId = this.investorRowManager.getPartnerTotalRow().gridRowId;
            const index = gridRowsToRender.findIndex(gr => gr === partnerRowId);

            gridRowsToRender.splice(index, 1);
        }

        this.grid.startUpdate();
        
        for(let q = wb.firstQuarter; q <= wb.lastQuarter; q++) {
            const transQuarterCols = this._transactionColumns.filter(col => col.quarterNumber === q).sort((a, b) => a.index - b.index)!;
            let prevEndBalanceColId: string;

            if(wb.frequency === "BY_YEAR" || q===wb.firstQuarter) {
                prevEndBalanceColId = wb.allocationsSheetmanager.pitdColumnsManager.endColumnId;
            } else {
                prevEndBalanceColId = this._transactionColumns
                    .find(col => col.quarterNumber === (q-1) && col.colType === TransactionColumnType.ENDING_BALANCE)!.gridColId;
            }

            this.renderQuarterColumns(q, transQuarterCols, prevEndBalanceColId, gridRowsToRender, gridColIds, onlyRenderUseAsArkForAllQuarters);
        }

        this.grid.endUpdate();
    }

    private renderQuarterColumns(
        quarter: number, transactionQuarterCols: TransactionColumn[], 
        prevEndBalanceColId: string, gridRowIds?: string[], gridColIds?: string[],
        onlyRenderUseAsArkForAllQuarters?: boolean): void 
    {
        const grid = this.grid;
        const wb = this.workbookSheetsManager;
        const allocationRulesColumns = wb.allocationsSheetmanager.allocationRulesManager.allocationRulesColumns;
        const investorRowManager = this.investorRowManager;
        const investorRows = investorRowManager.investorRows;
        const quarterStartColId = transactionQuarterCols[0].gridColId;
        const quarterLastColId = transactionQuarterCols[transactionQuarterCols.length-1].gridColId;
        const prevEndBalanceColCaption = grid.getColCaption(prevEndBalanceColId);
        const qtrDateStr = wb.frequency === 'BY_QUARTER' ? `QTD Q${quarter}-${wb.workbookYear}` : `YTD ${wb.workbookYear}`;

        this.setQuarterHeaderStyle(quarterStartColId, quarterLastColId, qtrDateStr);

        const colsToRender = gridColIds?.length ? transactionQuarterCols.filter(c => !!gridColIds.find(colId => colId === c.gridColId)) : transactionQuarterCols;

        colsToRender.forEach(col => {
            if(onlyRenderUseAsArkForAllQuarters && !col.useArkTransactionValues) return;
            if(col.quarterNumber < wb.lastQuarter && !col.useArkTransactionValues) return;            

            const colCaption = grid.getColCaption(col.gridColId);

            this.setColumnStyle(col.gridColId, col.label, ColWidth.currency);
            this.grid.setCellValue(this.investorRowManager.mainHeaderRowId, col.gridColId, col.label, CellFormats.Text_Unformatted);

            const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;
 
            rowsToRender.forEach(ir => {
                this.setInvestorTypeTotalRowStyling(ir.gridRowId, col.gridColId, ir.rowType);

                const rowCaption = grid.getRowCaption(ir.gridRowId);
                const typeTotalRows = investorRows.filter(r => r.rowType === InvestorRowType.TypeTotal)
                    .map(r => `${colCaption}${grid.getRowCaption(r.gridRowId)}`).join(`,`);
                const sumFormula = `SUM(${typeTotalRows})`;
                const partnerRowId = investorRowManager.getPartnerTotalRow().gridRowId;
                const partnerRowCaption = grid.getRowCaption(partnerRowId);

                if(col.colType === TransactionColumnType.BEGINING_BALANCE) {
                    grid.setCellFormula(ir.gridRowId, col.gridColId, `${prevEndBalanceColCaption}${rowCaption}`, CellFormats.Number_Accounting);
                    return;
                } else if(col.colType === TransactionColumnType.ENDING_BALANCE) {
                    const firstCol = transactionQuarterCols.find(col => col.colType === TransactionColumnType.BEGINING_BALANCE)!;
                    const firstColCaption = grid.getColCaption(firstCol.gridColId);
                    const lastCalcCol = transactionQuarterCols.find(col => col.colType === TransactionColumnType.ENDING_BALANCE)!;
                    const lastCalcColCaption =  grid.getColCaption(grid.getPrevCol(lastCalcCol.gridColId));
                    const endBalanceFormula = `SUM(${firstColCaption}${rowCaption}:${lastCalcColCaption}${rowCaption})`;

                    grid.setCellFormula(ir.gridRowId, col.gridColId, endBalanceFormula, CellFormats.Number_Accounting);

                    if(ir.rowType === InvestorRowType.PartnerTotal) {
                        const reconcilerRowID = grid.getNextRowId(partnerRowId);

                        grid.setCellFormula(reconcilerRowID, col.gridColId, `${sumFormula}-${colCaption}${rowCaption}`, CellFormats.Number_Accounting);
                    }

                    return;
                }

                if(col.locked) return;

                switch(ir.rowType) {
                    case InvestorRowType.Investor: {
                        const allocRuleCol = allocationRulesColumns.find(ac => ac.code === col.allocationCode && ac.quarterNumber === col.allocationCodeQuarterReference);
                        const roundInfo = this.getCellRoundingInfo(quarter, col.transTypeId, rowCaption);
        
                        if(col.useArkTransactionValues) {
                            const transTypeSum = this._transTypeSums.get(quarter)!.find(t => ir.investorId === t.investorId && col.transTypeId === t.transTypeId);
                            let transTypeSumVal: number | undefined;
            
                            if(transTypeSum?.sum || !isNaN(transTypeSum?.sum as number)) {
                                transTypeSumVal = col.metricSign === -1 ? transTypeSum?.sum! * -1 : transTypeSum!.sum!;
                            } else {
                                transTypeSumVal = undefined;
                            }
            
                            const roundInfo = this.getCellRoundingInfo(quarter, col.transTypeId, ir.gridRowId);
            
                            if(roundInfo.isRoundingFormula) {
                                grid.setCellFormula(ir.gridRowId, col.gridColId, `Round(${transTypeSumVal ?? 0}, ${roundInfo.decimalPoints})`, CellFormats.Number_Accounting);
                            } else {
                                grid.setCellValue(ir.gridRowId, col.gridColId, transTypeSumVal ?? '', CellFormats.Number_Accounting);
                            }
                        } else {
                            if(allocRuleCol?.gridColId) {
                                const allocRuleColCaption = grid.getColCaption(allocRuleCol.gridColId);
                                const allocColFormula = `${allocRuleColCaption}${rowCaption}*$${colCaption}$${partnerRowCaption}`;

                                if(roundInfo.isRoundingFormula) {
                                    grid.setCellFormula(ir.gridRowId, col.gridColId, `Round(${allocColFormula}, ${roundInfo.decimalPoints})`, CellFormats.Number_Accounting);
                                } else {
                                    grid.setCellFormula(ir.gridRowId, col.gridColId, allocColFormula, CellFormats.Number_Accounting);
                                }
                            } else if(roundInfo.isRoundingFormula && roundInfo.formula) {
                                grid.setCellFormula(ir.gridRowId, col.gridColId, roundInfo.formula, CellFormats.Number_Accounting);
                            } else {
                                if(grid.getCellValue(ir.gridRowId, col.gridColId) !== '') {
                                    grid.setCellValue(ir.gridRowId, col.gridColId, '', CellFormats.Text_Unformatted);
                                }
                            }
                        }
                        break;
                    }
                    case InvestorRowType.TypeTotal:
                        const typeStartEnd = investorRowManager.getInvestorTypeRowCaptions(ir.investorType);
                        const formula = `SUM(${colCaption}${typeStartEnd.startRow}:${colCaption}${typeStartEnd.endRow})`;

                        grid.setCellFormula(ir.gridRowId, col.gridColId, formula, CellFormats.Number_Accounting);
                        break;
                    case InvestorRowType.PartnerTotal: {
                        if(col.useArkTransactionValues) {
                            grid.setCellFormulaIfDifferent(ir.gridRowId, col.gridColId, sumFormula, CellFormats.Number_Accounting);
                        }

                        const formula = `${sumFormula}-${colCaption}${rowCaption}`;

                        // Partner total reconciler row
                        const reconcilerRowId = grid.getNextRowId(partnerRowId);

                        grid.setCellFormula(reconcilerRowId, col.gridColId, formula, CellFormats.Number_Accounting);
                        break;
                    }
                }
            });
        });
    }

    private getCellRoundingInfo(quarterNumber: number, transTypeId: string|undefined, rowId: string): {isRoundingFormula: boolean, formula: string|undefined, decimalPoints: number} {
        const wbSheetManager = this.workbookSheetsManager;
        const grid = this.grid;
        const decimalRounding = wbSheetManager.decimalRounding;

        if(!transTypeId) {
            return { isRoundingFormula: false, formula: undefined, decimalPoints: 0 };
        }

        const transType = this._transactionColumns.find(c => c.quarterNumber === quarterNumber && c.transTypeId === transTypeId)!;

        if(quarterNumber === wbSheetManager.firstQuarter) {
            if(decimalRounding > -1 && (transType.allocationCode || transType.useArkTransactionValues)) {
                return { isRoundingFormula: true, formula: undefined, decimalPoints: decimalRounding };
            }

            return { isRoundingFormula: false, formula: undefined, decimalPoints: 0 };
        }

        if(transType.allocationCode) {
            if(decimalRounding > -1) {
                return { isRoundingFormula: true, formula: undefined, decimalPoints: decimalRounding };
            } else {
                return { isRoundingFormula: false, formula: undefined, decimalPoints: 0 };
            }
        }

        const prevTransType = this._transactionColumns.find(c => c.quarterNumber === (quarterNumber-1) && c.transTypeId === transTypeId)!;

        // if prev trans type was from a alloc rule then dont treat it as a user formula
        if(!prevTransType.gridColId || prevTransType.allocationCode) {
            return { isRoundingFormula: false, formula: undefined, decimalPoints: 0 };
        }

        const actualVal = grid.getCellFormula(rowId, prevTransType.gridColId);

        if(!actualVal) {
            return { isRoundingFormula: false, formula: undefined, decimalPoints: 0 };
        }

        // if formula is something like '=1234' then that will come back as a number so need to make sure its a string first
        const isRound = typeof actualVal === 'string' ? actualVal.toLowerCase().startsWith('round') : false;

        if(!isRound) {
            return { isRoundingFormula: false, formula: actualVal, decimalPoints: 0 };
        }

        const lastCloseParanIdx = actualVal.lastIndexOf(')');
        const decimalPoint = actualVal[lastCloseParanIdx-1];

        return { isRoundingFormula: true, formula: actualVal, decimalPoints: parseInt(decimalPoint) };
    }

    public updateColumnValues(transactionColumn: TransactionColumn) {
        const wbSheetManager = this.workbookSheetsManager;
        const existingTransCol = this._transactionColumns
            .find(col => col.quarterNumber === transactionColumn.quarterNumber && col.transTypeId === transactionColumn.transTypeId);

        if(existingTransCol) {
            Object.assign(existingTransCol, transactionColumn);
        } else {
            throw `cannot update column - ${transactionColumn}`;
        }
    }


    public containsColumn(colId: string): boolean {
        return !!this._transactionColumns.find(c => c.gridColId === colId);
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        return true;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        this.workbookSheetsManager.allocationsSheetmanager.debugPrintoutAllData('Trans Start Edit');

        const wbSheetManager = this.workbookSheetsManager;
        const transCol = this._transactionColumns.find(c => c.gridColId === colId);

        this.workbookSheetsManager.allocationsSheetmanager.debugPrintoutAllData('Trans Start Edit');

        if(!transCol) return false;

        return (!transCol.locked && transCol.quarterNumber === wbSheetManager.lastQuarter) && (!transCol.useArkTransactionValues);
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        const grid = this.grid;
        const insertArrayIndex = this._transactionColumns.findIndex(c => c.gridColId === toColId);
        const insertCol = this._transactionColumns[insertArrayIndex];

        if(insertCol.colType === TransactionColumnType.ENDING_BALANCE) {
            return { validColumnPosition: false };
        }

        const newColIDs: string[] = [];
        let currColId = toColId;

        cols.forEach(_col => {
            currColId = grid.insertCol(currColId, 1, NewColPosition.rightOfDestination);
            newColIDs.push(currColId);

            const quarterTransRightCols = this._transactionColumns.filter(c => c.quarterNumber === insertCol.quarterNumber && c.index >= insertCol.index);
            
            quarterTransRightCols.forEach(c => c.index++);

            this._transactionColumns.splice(insertArrayIndex, 0, {
                gridColId: currColId,
                index: insertCol.index,
                colType: TransactionColumnType.STANDARD_TRANSACTION_TYPE,
                isUserColumn: true,
                label: 'Custom Transaction Column',
                locked: false,
                quarterNumber: insertCol.quarterNumber,
                metricSign: 0,
                transSubmitError: false,
                transTypeId: generateUUID(),
                useArkTransactionValues: false
            });
        });

        this.renderColumns(undefined, newColIDs);

        return {
            validColumnPosition: true,
            newColumnIDs: newColIDs
        };
    }

    public onRowAdd(parentRow: string): boolean {
        return false;
    }

    public onCanColDelete(col: string): boolean {
        return false;
    }

    public onCanRowDelete(row: string): boolean {
        return false;
    }

    public onCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }  
}