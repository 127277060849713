import { cloneDeep } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import { approvePendingChanges, checkInvestorNameAvailability, declinePendingChanges, getInvestor, saveInvestor } from "../../../../services/investor.service";
import {
  CHANGES_APPROVED_SUCCESS_MESSAGE,
  CHANGES_DECLINE_SUCCESS_MESSAGE,
  ERROR_TAKEN_INVESTOR_NAME,
  GENERIC_ERROR_MESSAGE,
  INVESTORS_NO_FUNDS_ERROR,
} from "../../../../utils/constants/text.constants";
import { LoadingStatus } from "../../../../utils/types/form.type";
import { InvestorDetail } from "../../../../utils/types/investor.type";
import { ScopeRole } from "../../../../utils/types/user.type";
import { DEFAULT_INVESTOR, DEFAULT_WIRE_INFO, SAVE_INVESTOR_ERROR, SAVE_INVESTOR_SUCCESS } from "../InvestorList.constants";

type Props = {
  investor?: InvestorDetail;
  isNewInvestor: boolean;
  setIsSubmitting: Function;
  onClose: Function
}

export const useInvestorDetails = ({
  investor,
  isNewInvestor,
  setIsSubmitting,
  onClose
}: Props) => {
  const [isLoading, setIsLoading] = useState<LoadingStatus | ''>();
  const [showExitConfirmation, setShowExitConfirmation] = useState<boolean>(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [originalName, setOriginalName] = useState<string | undefined>('');
  const [originalInvestor, setOriginalInvestor] = useState<InvestorDetail | undefined>();

  const [title, setTitle] = useState<string | undefined>('');
  const [isUniqueNameError, setIsUniqueNameError] = useState(false);

  const { informationAlert, setAppHasChanges, preAuthClientConfigs } = useContext(AppContext);

  const { hasRole: isClientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const hostname = window.location.hostname;
  const isLPPortal =
    hostname.startsWith('platform.') || hostname.startsWith('platform-sso.')
      ? false
      : true;

  useEffect(() => {
    setAppHasChanges(isDirty);

    // Reset null values to empty string so formState can detect dirty fields
    if (isLPPortal && investor) {
      if (investor?.mailingAddress && !investor?.mailingAddress?.city) investor.mailingAddress.city = '';
      if (investor?.mailingAddress && !investor?.mailingAddress?.country) investor.mailingAddress.country = '';
      if (investor?.mailingAddress && !investor?.mailingAddress?.postalCode) investor.mailingAddress.postalCode = '';
      if (investor?.mailingAddress && !investor?.mailingAddress?.state) investor.mailingAddress.state = '';
      if (investor?.mailingAddress && !investor?.mailingAddress?.street1) investor.mailingAddress.street1 = '';
      if (investor?.mailingAddress && !investor?.mailingAddress?.street2) investor.mailingAddress.street2 = '';

      if (investor?.taxAddress && !investor?.taxAddress?.city) investor.taxAddress.city = '';
      if (investor?.taxAddress && !investor?.taxAddress?.country) investor.taxAddress.country = '';
      if (investor?.taxAddress && !investor?.taxAddress?.postalCode) investor.taxAddress.postalCode = '';
      if (investor?.taxAddress && !investor?.taxAddress?.state) investor.taxAddress.state = '';
      if (investor?.taxAddress && !investor?.taxAddress?.street1) investor.taxAddress.street1 = '';
      if (investor?.taxAddress && !investor?.taxAddress?.street2) investor.taxAddress.street2 = '';

      if (!investor?.taxIdentificationNumber) investor.taxIdentificationNumber = '';
      if (!investor?.pre) investor.pre = '';
      if (!investor?.ownerName) investor.ownerName = '';
      if (investor?.wireInfo && !investor?.wireInfo?.bankToBankInstructions1) investor.wireInfo.bankToBankInstructions1 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.bankToBankInstructions2) investor.wireInfo.bankToBankInstructions2 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.bankToBankInstructions3) investor.wireInfo.bankToBankInstructions3 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.bankToBankInstructions4) investor.wireInfo.bankToBankInstructions4 = '';

      if (investor?.wireInfo && !investor?.wireInfo?.originatorToBeneficiaryInstructions1) investor.wireInfo.originatorToBeneficiaryInstructions1 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.originatorToBeneficiaryInstructions2) investor.wireInfo.originatorToBeneficiaryInstructions2 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.originatorToBeneficiaryInstructions3) investor.wireInfo.originatorToBeneficiaryInstructions3 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.originatorToBeneficiaryInstructions4) investor.wireInfo.originatorToBeneficiaryInstructions4 = '';

      if (investor?.wireInfo && !investor?.wireInfo?.beneficiaryAccount) investor.wireInfo.beneficiaryAccount = '';
      if (investor?.wireInfo && !investor?.wireInfo?.beneficiaryAddressLine1) investor.wireInfo.beneficiaryAddressLine1 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.beneficiaryAddressLine2) investor.wireInfo.beneficiaryAddressLine2 = '';
      if (investor?.wireInfo && !investor?.wireInfo?.beneficiaryName) investor.wireInfo.beneficiaryName = '';

      if (investor?.wireInfo && investor?.wireInfo.beneficiaryBankInfo && !investor.wireInfo.beneficiaryBankInfo.abaNumber) investor.wireInfo.beneficiaryBankInfo.abaNumber = '';
      if (investor?.wireInfo && investor?.wireInfo.beneficiaryBankInfo && !investor.wireInfo.beneficiaryBankInfo.addressLine1) investor.wireInfo.beneficiaryBankInfo.addressLine1 = '';
      if (investor?.wireInfo && investor?.wireInfo.beneficiaryBankInfo && !investor.wireInfo.beneficiaryBankInfo.addressLine2) investor.wireInfo.beneficiaryBankInfo.addressLine2 = '';
      if (investor?.wireInfo && investor?.wireInfo.beneficiaryBankInfo && !investor.wireInfo.beneficiaryBankInfo.name) investor.wireInfo.beneficiaryBankInfo.name = '';
      if (investor?.wireInfo && investor?.wireInfo.beneficiaryBankInfo && !investor.wireInfo.beneficiaryBankInfo.type) investor.wireInfo.beneficiaryBankInfo.type = null;

      if (investor?.wireInfo && investor?.wireInfo.intermediaryBankInfo && !investor.wireInfo.intermediaryBankInfo.abaNumber) investor.wireInfo.intermediaryBankInfo.abaNumber = '';
      if (investor?.wireInfo && investor?.wireInfo.intermediaryBankInfo && !investor.wireInfo.intermediaryBankInfo.addressLine1) investor.wireInfo.intermediaryBankInfo.addressLine1 = '';
      if (investor?.wireInfo && investor?.wireInfo.intermediaryBankInfo && !investor.wireInfo.intermediaryBankInfo.addressLine2) investor.wireInfo.intermediaryBankInfo.addressLine2 = '';
      if (investor?.wireInfo && investor?.wireInfo.intermediaryBankInfo && !investor.wireInfo.intermediaryBankInfo.name) investor.wireInfo.intermediaryBankInfo.name = '';
      if (investor?.wireInfo && investor?.wireInfo.intermediaryBankInfo && !investor.wireInfo.intermediaryBankInfo.type) investor.wireInfo.intermediaryBankInfo.type = null;

      if (investor?.wireInfo && investor?.wireInfo.receivingBankInfo && !investor.wireInfo.receivingBankInfo.abaNumber) investor.wireInfo.receivingBankInfo.abaNumber = '';
      if (investor?.wireInfo && investor?.wireInfo.receivingBankInfo && !investor.wireInfo.receivingBankInfo.addressLine1) investor.wireInfo.receivingBankInfo.addressLine1 = '';
      if (investor?.wireInfo && investor?.wireInfo.receivingBankInfo && !investor.wireInfo.receivingBankInfo.addressLine2) investor.wireInfo.receivingBankInfo.addressLine2 = '';
      if (investor?.wireInfo && investor?.wireInfo.receivingBankInfo && !investor.wireInfo.receivingBankInfo.name) investor.wireInfo.receivingBankInfo.name = '';
      if (investor?.wireInfo && investor?.wireInfo.receivingBankInfo && !investor.wireInfo.receivingBankInfo.type) investor.wireInfo.receivingBankInfo.type = null;
    }

  }, [investor]);

  const {
    register,
    handleSubmit,
    setError,
    formState,
    control,
    watch,
    reset,
    resetField,
    setValue,
    getValues
  } = useForm<InvestorDetail>({
    defaultValues: investor ?? DEFAULT_INVESTOR
  });
  const {
    isDirty
  } = useFormState({
    control
  });

  useEffect(() => {
    setAppHasChanges(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (investor && !isNewInvestor) {
      if (!investor.wireInfo) {
        investor.wireInfo = DEFAULT_WIRE_INFO;
      }

      let fullName = '';

      if (investor.entityType === 'INDIVIDUAL') {
        fullName = investor.middle
          ? `${investor.firstName} ${investor.middle} ${investor.lastName}`
          : `${investor.firstName} ${investor.lastName}`;

      } else if (investor.entityType === 'ENTITY' && investor.name) {
        fullName = investor.name;
      }

      if (investor.ownerSSN) {
        setValue('ownerSSN', investor.ownerSSN.replace(/\D/g, ''));
      }
      if (investor.taxIdentificationNumber) {
        setValue('taxIdentificationNumber', investor.taxIdentificationNumber);
      }

      setOriginalName(fullName);
      setTitle(investor.fullName || investor.name);
      setOriginalInvestor({ ...investor });
    }
  },
    [investor?.id]
  );

  const createUpdateInvestor = async (data: any) => {
    if (data.funds.length === 0) { return; }

    const unsetInvestorType = data.fundInvestorTypes.find((t: any) => !t.investorType.label);

    if (unsetInvestorType) return;

    deleteEmptyProps(data.wireInfo);
    deleteEmptyProps(data.wireInfo.beneficiaryBankInfo);
    deleteEmptyProps(data.wireInfo.receivingBankInfo);
    deleteEmptyProps(data.wireInfo.intermediaryBankInfo);

    if (data.ownerSSN === '') {
      data.ownerSSN = null;
    }

    if (data.taxIdentificationNumber === '') {
      data.taxIdentificationNumber = null;
    }

    if (data.ownerSSN) {
      data.ownerSSN = data.ownerSSN.replace('-', '');
    }

    if (data.taxIdentificationNumber) {
      data.taxIdentificationNumber = data.taxIdentificationNumber;
    }

    const investorData = {
      ...data
    };

    if (!data.id) {
      investorData.canEditWireInfo = false;
      investorData.canReadWireInfo = false;
      investorData.className = "investor";
      investorData.clientId = "";
      investorData.email = "";
      investorData.fax = "";
      investorData.isPrimary = false;
      investorData.joinedQuarter = "";
      investorData.pendingWireInfo = null;
      investorData.status = "ACTIVE";
    }

    let fullName = '';

    if (data.entityType === 'INDIVIDUAL') {
      fullName = data.middle
        ? `${data.firstName} ${data.middle} ${data.lastName}`
        : `${data.firstName} ${data.lastName}`;

    } else if (data.entityType === 'ENTITY' && data.name) {
      fullName = data.name;
    }

    if ((fullName && originalName !== fullName && data.id) || (fullName && isNewInvestor)) {
      isNewInvestor ? setIsLoading(LoadingStatus.Adding) : setIsLoading(LoadingStatus.Updating);

      try {
        const isNameAvailable = await checkInvestorNameAvailability(fullName);

        if (!isNameAvailable) {
          informationAlert(ERROR_TAKEN_INVESTOR_NAME, "error");
          setIsUniqueNameError(true);
          return;
        }
      } catch (error) {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
        return;
      } finally {
        setIsLoading('');
      }
    }

    if (isClientPortalUser) {
      investorData.wireInfoClient = data.pendingWireInfo ? { ...data.pendingWireInfo } : { ...data.wireInfo };
      investorData.wireInfo = { ...originalInvestor?.wireInfo };
      investorData.pendingWireInfo = { ...originalInvestor?.pendingWireInfo };
      deleteEmptyProps(investorData.wireInfo);
      deleteEmptyProps(investorData.pendingWireInfo);

      if (formState.dirtyFields.hasOwnProperty('wireInfo')) {
        investorData.wireInfoClient.changesPending = true;
      }
    }

    const clonedInvestorData = cloneDeep(investorData);

    clonedInvestorData.fundInvestorTypes = investorData.fundInvestorTypes.map((t: any) => {
      return {
        "fundId": t.fundId,
        "investorType": t.investorType.id
      };
    });


    try {
      setIsSubmitting(true);
      isNewInvestor ? setIsLoading(LoadingStatus.Adding) : setIsLoading(LoadingStatus.Updating);

      await saveInvestor(clonedInvestorData);
      informationAlert(SAVE_INVESTOR_SUCCESS, "success");

      closeDrawer();
    } catch (error: any) {
      informationAlert(SAVE_INVESTOR_ERROR, "error");
      if (error && error.response && error.response.data && error.response.data.errors) {
        if (Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
          error.response.data.errors.map((err: { field: string, message: string }) => {
            if (err.field === 'taxIdentificationNumber') {
              setError(err.field, { type: 'custom', message: err.message });
            }
            if (err.field === 'ownerSSN') {
              setError(err.field, { type: 'custom', message: err.message });
            }
          });
        }
      }
    } finally {
      setIsLoading('');
      setIsSubmitting(false);
    }
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const approveChanges = async () => {
    if (investor && investor.id) {
      try {
        setIsSubmitting(true);
        await approvePendingChanges(
          investor.pendingWireInfo?.id,
          investor.id
        );
        setIsSubmitting(false);
        closeDrawer();
        informationAlert(CHANGES_APPROVED_SUCCESS_MESSAGE, "success");
      } catch (err) {
        informationAlert(INVESTORS_NO_FUNDS_ERROR, "error");
      }
    }
  };

  const declineChanges = async () => {
    if (investor && investor.id) {
      try {
        setIsSubmitting(true);
        await declinePendingChanges(investor.id);
        setIsSubmitting(false);
        closeDrawer();
        informationAlert(CHANGES_DECLINE_SUCCESS_MESSAGE, "success");
      } catch (err) {
        informationAlert(INVESTORS_NO_FUNDS_ERROR, "error");
        setIsSubmitting(false);
      }
    }
  };

  const deleteEmptyProps = (obj: any): any => {
    return Object.keys(obj).forEach((k) => {
      if (
        !obj[k] ||
        obj[k] === undefined ||
        (Array.isArray(obj[k]) && obj[k].length === 0)
      ) {
        delete obj[k];
      }
    });
  };

  const handleOnSave = (data: any) => {
    if (isLPPortal) {
      setShowSaveConfirmation(true);
    } else {
      createUpdateInvestor(getValues());
    }
  };

  const confirmSave = () => {
    setShowSaveConfirmation(false);
    setShowExitConfirmation(false);

    createUpdateInvestor(getValues());
  };

  return {
    form: {
      register,
      control,
      handleSubmit,
      setError,
      errors: formState.errors,
      setValue,
      watch,
      reset,
      resetField,
      formState
    },
    isLoading,
    setIsLoading,
    createUpdateInvestor,
    investor,
    showExitConfirmation,
    setShowExitConfirmation,
    title,
    isUniqueNameError,
    setIsUniqueNameError,
    toggleDrawer,
    closeDrawer,
    keepDrawerOpen,
    approveChanges,
    declineChanges,
    isLPPortal,
    showSaveConfirmation,
    setShowSaveConfirmation,
    preAuthClientConfigs,
    handleOnSave,
    confirmSave
  };
};

export const useTabs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const detailsRef = useRef<HTMLInputElement>(null);
  const fundsRef = useRef<HTMLInputElement>(null);
  const wiringRef = useRef<HTMLInputElement>(null);
  const pageBottomRef = useRef<HTMLInputElement>(null);

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case 1:
        fundsRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case 2:
        wiringRef.current?.scrollIntoView({
          behavior: "smooth",
        });
        break;
    }
  };

  const scrollToBottom = () => {
    pageBottomRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return {
    currentTab,
    detailsRef,
    fundsRef,
    wiringRef,
    pageBottomRef,
    handleTabChange,
    scrollToBottom
  };
};
