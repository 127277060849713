import { Typography } from '@mui/material';
import {
  GridAlignment,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import { AppContext } from '../../../../core/context/appContextProvider';
import { getChangesInPartnersCapital } from '../../../../services/arkGL.service';
import {
  getClientTransactionMapping,
  updateTransactionMapping,
} from '../../../../services/client.service';
import { getTransactionTypes } from '../../../../services/fund.service';
import {
  CurrencyFormat,
  FormatIntegerWithCurrencyOffset,
} from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { ViewItemsEntity } from '../../../../utils/types/columnOrder';
import {
  CustomType,
  DataGridColDef,
  FilterItem,
} from '../../../../utils/types/listItems';
import { defaultHeaderList } from './changesInPartnersCapital.constants';

type InvestorType = {
  id: string;
  label: string;
};

export type InvestorTypeResponse = {
  fundId: string;
  investorType: InvestorType;
};

export type TransactionDetails = {
  amount: number;
  currencyCode: string;
  fund: {
    name: string;
    id: string;
  };
  investor: {
    name: string;
    id: string;
  };
  fundInvestorType: InvestorTypeResponse;
  name: string;
  transactionDate: Date | null;
  transactionId: string;
  transactionType: {
    id: string;
    name: string;
  };
};

type GridRow = {
  id?: string;
  transactionName?: string;
  lp?: number;
  slp?: number;
  gp?: number;
  ilp?: number;
  elp?: number;
  hlp?: number;
  member?: number;
  managingMember?: number;
  totalBalance?: number;
  rowHeader?: string;
};

const START_BALANCE = 'Starting Balance';
const END_BALANCE = 'Ending Balance';

export const useChangesInPartnersCapital = (
  params: any,
  setStatementOfChangeData: (statementOfChangeData: boolean) => void,
  setIsLoading: (isLoading: boolean) => void,
  editModeActivated: boolean,
  roundedReportActivated: boolean,
  suppressIfZeroActivated: boolean,
  currentCurrency: string,
  setFsPackageFlag: (fsPackageFlag: boolean) => void,
  setFsSaveFieldOrder: Dispatch<SetStateAction<any[]>>,
  fsUpdateColumnOrder: any,
  fsColumnBuilder: boolean
) => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [gridData, setGridData] = useState<GridRow[]>([]);
  const [apiResponse, setApiResponse] = useState<any>();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [transactionDetails, setTransactionDetails] = useState<
    TransactionDetails[]
  >([]);
  const [currencyCode, setCurrencyCode] = useState<string>('');
  const [firstTransactionDate, setFirstTransactionDate] = useState<string>('');
  const [lastTransactionDate, setLastTransactionDate] = useState<string>('');
  const [hideLp, setHideLp] = useState<boolean>(true);
  const [hideSlp, setHideSlp] = useState<boolean>(true);
  const [hideGp, setHideGp] = useState<boolean>(true);
  const [hideIlp, setHideIlp] = useState<boolean>(true);
  const [hideElp, setHideElp] = useState<boolean>(true);
  const [hideHlp, setHideHlp] = useState<boolean>(true);
  const [hideMember, setHideMember] = useState<boolean>(true);
  const [hideManagingMember, setHideManagingMember] = useState<boolean>(true);
  const [buildCounter, setBuildCounter] = useState(0);
  const [transactionTypes, setTransactionTypes] = useState<FilterItem[]>([]);
  const [transactionMap, setTransactionMap] = useState<any>();
  const [transactionNameChangeOpen, setTransactionNameChangeOpen] =
    useState<boolean>(false);
  const [triggerBuild, setTriggerBuilder] = useState<boolean>(false);

  useEffectAsync(async () => {
    setIsLoading(true);
    setBuildCounter(0);

    try {
      const dataResponse: any = await getChangesInPartnersCapital(params);

      if (!dataResponse) return;
      if (dataResponse && dataResponse.transactionDetails.length === 0) return;

      const transactionTypes: any = await getTransactionTypes(clientId);

      if (!transactionTypes) return;

      const transactionMap: any = await getClientTransactionMapping(clientId);

      if (!transactionMap) return;

      setTransactionTypes(transactionTypes);
      setTransactionMap(transactionMap);

      const { statementOfChangesSummary, transactionDetails } = dataResponse;

      setFirstTransactionDate(
        transactionDetails[transactionDetails.length - 1].transactionDate
      );
      setLastTransactionDate(transactionDetails[0].transactionDate);
      setCurrencyCode(transactionDetails[0].currencyCode);
      setStartDate(statementOfChangesSummary.startDate);
      setEndDate(statementOfChangesSummary.endDate);
      setTransactionDetails(transactionDetails);
      setApiResponse(dataResponse);
    } catch (e) {
      informationAlert('Error retreiving data', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [roundedReportActivated, suppressIfZeroActivated, triggerBuild]);

  useEffect(() => {
    if (apiResponse) {
      setBuildCounter(buildCounter + 1);
      buildGridData(cloneDeep(apiResponse));
    }
  }, [apiResponse]);

  useEffect(() => {
    const newHeaderList: DataGridColDef[] = headerList.map((header) => {
      if (header.field === 'transactionName') {
        return { ...header, editable: editModeActivated };
      }
      return header;
    });

    setHeaderList(newHeaderList);
  }, [editModeActivated]);

  useEffect(() => {
    if (gridData.length) {
      initializeHeaderList();
    }
  }, [gridData]);

  useEffect(() => {
    if (buildCounter === 1) {
      const updatedHeaderList = cloneDeep(headerList);

      updatedHeaderList.map((header) => {
        switch (header.field) {
          case 'lp':
            header.hide = hideLp;
            break;
          case 'slp':
            header.hide = hideSlp;
            break;
          case 'gp':
            header.hide = hideGp;
            break;
          case 'ilp':
            header.hide = hideIlp;
            break;
          case 'elp':
            header.hide = hideElp;
            break;
          case 'hlp':
            header.hide = hideHlp;
            break;
          case 'member':
            header.hide = hideMember;
            break;
          case 'managingMember':
            header.hide = hideManagingMember;
            break;
        }
      });

      if (fsUpdateColumnOrder.length) {
        updatedHeaderList.forEach((header) => {
          fsUpdateColumnOrder.map(
            (column: { code: string; visible: boolean; order: number }) => {
              if (header.field === column.code) {
                header.hide = !column.visible;
                header.index = column.order;
              }
            }
          );
        });

        updatedHeaderList.sort((a, b) => a.index - b.index);
      }

      const fsHeaders = updatedHeaderList.filter(
        (header) =>
          header.field !== 'action' &&
          header.field !== 'transactionName' &&
          header.field !== 'totalBalance'
      );
      const viewItems = fsHeaders.map((catCol) => {
        return {
          label: catCol.headerName,
          code: catCol.field,
          order: catCol.index,
          visible: !catCol.hide,
        } as ViewItemsEntity;
      });

      setFsSaveFieldOrder(viewItems);

      setBuildCounter(buildCounter + 1);
      setHeaderList(updatedHeaderList);
    }
  }, [headerList]);

  function buildGridData(dataResponse: any) {
    const { statementOfChangesSummary } = dataResponse;

    const formattedRowStructure: GridRow = {
      transactionName: '',
      lp: undefined,
      slp: undefined,
      gp: undefined,
      ilp: undefined,
      elp: undefined,
      hlp: undefined,
      member: undefined,
      managingMember: undefined,
      totalBalance: undefined,
      id: '',
    };

    const formattedGridData: GridRow[] =
      statementOfChangesSummary.statementOfChangesRows.map((row: GridRow) => {
        const formattedRow = cloneDeep(formattedRowStructure);

        formattedRow.transactionName = row.rowHeader;
        formattedRow.lp = row.lp ? row.lp : undefined;
        if (formattedRow.lp !== undefined) setHideLp(false);
        formattedRow.slp = row.slp ? row.slp : undefined;
        if (formattedRow.slp !== undefined) setHideSlp(false);
        formattedRow.gp = row.gp ? row.gp : undefined;
        if (formattedRow.gp !== undefined) setHideGp(false);
        formattedRow.ilp = row.ilp ? row.ilp : undefined;
        if (formattedRow.ilp !== undefined) setHideIlp(false);
        formattedRow.elp = row.elp ? row.elp : undefined;
        if (formattedRow.elp !== undefined) setHideElp(false);
        formattedRow.hlp = row.hlp ? row.hlp : undefined;
        if (formattedRow.hlp !== undefined) setHideHlp(false);
        formattedRow.member = row.member ? row.member : undefined;
        if (formattedRow.member !== undefined) setHideMember(false);
        formattedRow.managingMember = row.managingMember
          ? row.managingMember
          : undefined;
        if (formattedRow.managingMember !== undefined)
          setHideManagingMember(false);
        formattedRow.totalBalance = row.totalBalance
          ? row.totalBalance
          : undefined;
        formattedRow.id = row.rowHeader;

        return formattedRow;
      });

    if (!suppressIfZeroActivated) {
      transactionTypes.forEach((type) => {
        if (formattedGridData.some((row) => row.transactionName === type.name))
          return;

        const formattedRow = cloneDeep(formattedRowStructure);

        formattedRow.transactionName = type.name;
        formattedRow.id = type.name;

        formattedGridData.splice(formattedGridData.length - 1, 0, formattedRow);
      });
    }

    setStatementOfChangeData(true);
    setGridData(formattedGridData);
  }

  function formatDate(dateString: string): string {
    const months: string[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const [year, month, day] = dateString.split('-').map(Number);
    const date: Date = new Date(year, month - 1, day);

    return `Partners' Capital, ${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }

  function initializeHeaderList() {
    const updatedHeaders: DataGridColDef[] = [
      {
        headerName: 'Transaction Name',
        field: 'transactionName',
        hide: false,
        index: 0,
        sortable: false,
        type: 'string',
        minWidth: 280,
        customType: CustomType.Action,
        disableColumnMenu: true,
        disableReorder: true,
        editable: editModeActivated ? true : false,
        renderCell: (params: any) => {
          if (params.row.transactionName === START_BALANCE) {
            return (
              <Typography fontWeight={'bold'}>
                {formatDate(startDate ? startDate : firstTransactionDate)}
              </Typography>
            );
          }
          if (params.row.transactionName === END_BALANCE) {
            return (
              <Typography fontWeight={'bold'}>
                {formatDate(endDate ? endDate : lastTransactionDate)}
              </Typography>
            );
          }
          return (
            <Typography marginLeft={'22px'}>
              {params.row.transactionName}
            </Typography>
          );
        },
      },
      {
        headerName: 'Limited Partner',
        field: 'lp',
        hide: true,
        index: 1,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_lp${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Special Limited Partner',
        field: 'slp',
        hide: true,
        index: 2,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_lp_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'General Partner',
        field: 'gp',
        hide: true,
        index: 3,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_gp_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Internal Limited Partner',
        field: 'ilp',
        hide: true,
        index: 4,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_ilp_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'External Limited Partner',
        field: 'elp',
        hide: true,
        index: 5,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_elp_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Hybrid Limited Partner',
        field: 'hlp',
        hide: true,
        index: 6,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_hlp_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Member',
        field: 'member',
        hide: true,
        index: 7,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_member_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Managing Member',
        field: 'managingMember',
        hide: true,
        index: 8,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_managing_member_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        headerName: 'Total',
        field: 'totalBalance',
        hide: false,
        index: 9,
        sortable: false,
        type: 'string',
        customType: CustomType.Action,
        disableColumnMenu: true,
        disableReorder: true,
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) {
            return (
              <ActionLink
                id={`changes_in_p_cap_id_total_${params.id}`}
                onClick={() => {}}
              >
                <Typography>{formatCurrency(params.value)}</Typography>
              </ActionLink>
            );
          }
        },
      },
      {
        field: 'action',
        headerName: '',
        hide: false,
        index: 10,
        type: 'action',
        customType: CustomType.Action,
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        maxWidth: 35,
        renderCell: (params: any) => {
          return '';
        },
      },
    ];

    setHeaderList(updatedHeaders);
  }

  function handleColumnOrderChange(params: GridColumnOrderChangeParams) {
    const fromIndex = params.oldIndex;
    const toIndex = params.targetIndex;
    const updatedHeaderList = cloneDeep(headerList);

    if (updatedHeaderList) {
      const element = updatedHeaderList[fromIndex];

      updatedHeaderList.splice(fromIndex, 1);
      updatedHeaderList.splice(toIndex, 0, element);

      updatedHeaderList.map((item, index) => {
        item.index = index + 1;
      });
    }

    const fsHeaders = updatedHeaderList.filter(
      (header) =>
        header.field !== 'action' &&
        header.field !== 'transactionName' &&
        header.field !== 'totalBalance'
    );
    const viewItems = fsHeaders.map((catCol) => {
      return {
        label: catCol.headerName,
        code: catCol.field,
        order: catCol.index,
        visible: !catCol.hide,
      } as ViewItemsEntity;
    });

    setFsSaveFieldOrder(viewItems);
    setFsPackageFlag(true);
    setHeaderList(updatedHeaderList);
  }

  function handleUpdateHeaderVisibility(field: string) {
    if (!headerList || headerList?.length === 0) return;

    const updatedHeaders: DataGridColDef[] = headerList.map((header) => {
      if (header.field === field) {
        header.hide = !header.hide;
      }
      return header;
    });

    const fsHeaders = updatedHeaders.filter(
      (header) =>
        header.field !== 'action' &&
        header.field !== 'transactionName' &&
        header.field !== 'totalBalance'
    );
    const viewItems = fsHeaders.map((catCol) => {
      return {
        label: catCol.headerName,
        code: catCol.field,
        order: catCol.index,
        visible: !catCol.hide,
      } as ViewItemsEntity;
    });

    setFsSaveFieldOrder(viewItems);
    setFsPackageFlag(true);
    setHeaderList(updatedHeaders);
  }

  const [prevTransactionName, setPrevTransactionName] = useState<string>('');
  const [newTransactionName, setNewTransactionName] = useState<string>('');

  function editTransactionName(params: any) {
    if (!params.value) {
      informationAlert(
        'Transaction Name cannot be updated to a blank value.',
        'error'
      );
      setTriggerBuilder(!triggerBuild);
      return;
    }
    setPrevTransactionName(params.id);
    setNewTransactionName(params.value);
    setTransactionNameChangeOpen(true);
  }

  function cancelEditTransaction() {
    setTransactionNameChangeOpen(false);
    setTriggerBuilder(!triggerBuild);
  }

  async function confirmEditTransaction() {
    setTransactionNameChangeOpen(false);
    const updatedMap = cloneDeep(transactionMap);

    updatedMap.rows.forEach((row: any) => {
      if (row.type === prevTransactionName) {
        row.type = newTransactionName;
      }
    });

    setIsLoading(true);
    try {
      await updateTransactionMapping(clientId, updatedMap);
      informationAlert('Success updating transaction name', 'success');
    } catch (e) {
      informationAlert('Error updating transaction name', 'error');
      setIsLoading(false);
    }

    setTriggerBuilder(!triggerBuild);
  }

  const [cellInfo, setCellInfo] = useState<any>();

  function handleCellClick(params: any) {
    if (params.field === 'transactionName') return;
    setCellInfo(params);
    setDetailsOpen(true);
  }

  function handleCloseDetails() {
    setDetailsOpen(false);
  }

  function formatCurrency(value: number) {
    if (roundedReportActivated) {
      return CurrencyFormat(currencyCode, 0).format(value);
    }
    return CurrencyFormat(currencyCode, 2).format(value);
  }

  return {
    activeHeaderFields,
    apiResponse,
    cellInfo,
    currencyCode,
    detailsOpen,
    handleCellClick,
    handleCloseDetails,
    handleColumnOrderChange,
    handleUpdateHeaderVisibility,
    headerList,
    gridData,
    editTransactionName,
    confirmEditTransaction,
    cancelEditTransaction,
    transactionNameChangeOpen,
    transactionDetails,
  };
};
