import {
  AccordionSummary,
  Box,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import DataGrid from '../../../components/DataGrid/DataGrid';
import { ViewOption } from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';

interface FundWrapper {
  wrapperHeight: number | undefined;
}

export const FundListWrapper = styled(Box)<{}>(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr ',
}));

export const FundListHeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const HeaderSubComponent = styled(Box)<{
  gap?: number;
}>(({ theme, gap = 0 }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(gap),
}));

export const FilterBorderColor: SxProps<Theme> = {
  borderColor: (theme) => theme.palette.primary.dark,
};

export const FundListDataWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  position: 'relative',
  overflow: 'auto',
}));

export const FundListDataBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  gap: theme.spacing(1),
}));

export const StyledDataGrid = styled(DataGrid)`
  & .MuiDataGrid-row[data-id='total'] {
    font-weight: bold;
    .MuiDataGrid-cellCheckbox {
      opacity: 0;
    }
  },
`;

export const FundWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'wrapperHeight',
})<FundWrapper>(({ theme, wrapperHeight = 'auto' }) => ({
  overflowY: 'auto',
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: wrapperHeight,
}));

export const ButtonBox = styled(Box)(() => ({
  gap: 16,
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const StickyAccordionSummary = styled(AccordionSummary)({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  height: '24px',
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  background: '#F4EFE3',
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
});
