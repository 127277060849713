import {
  Box,
  FormControl as MUIFormControl,
  IconButton,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
  styled,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "20% 15% 20% 20%",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  alignItems: "center",
}));

export const TabRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "60% 30%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "40% 60%",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const TypographyBold = styled(Typography)`
  &.MuiTypography-body1 {
    font-weight: 700;
  }
`;

export const SyncButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  minWidth: "47px",
  [`&:hover`]: {
    backgroundColor: "#4aa1ff",
    color: "#FFFFFF",
    border: "0px",
  },
}));

export const SyncTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{
      popper: className,
    }}
  />
))(({ theme }) => ({
  [`& .MuiTypography-root`]: {
    textAlign: "center",
    marginBottom: "10px",
  },
}));

export const CellFormControl = styled(MUIFormControl)(({ theme }) => ({
  minWidth: "100%",
}));

export const CellSelect = styled(MUISelect)`
  minwidth: "100%";
  fieldset {
    border: 0;
    padding: 0;
  }
`;

export const CellMenuItem = styled(MUIMenuItem)(({ theme }) => ({
  minWidth: "100%",
}));

interface GLAccountProps {
  depth: number;
}
export const GLAccount = styled(Box, {
  shouldForwardProp: (props) => props !== "depth",
})<GLAccountProps>(({ theme, depth }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: `${depth * 30}px`,
}));
