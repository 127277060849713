import React, { ReactElement, useContext, useEffect, useRef } from "react";

import { AppContext } from "../../../../core/context/appContextProvider";
import { logDocumentEvent } from "../../../../services/documents.service";
import {
  AdobeEvent,
  DebounceFunction,
  DocEvent,
  DocEventLogData,
} from "../../../../utils/types/documents.type";
import {
  CloseIcon,
  StyledDialog,
  StyledDialogTitle,
} from "./adobeViewer.styles";

declare global {
  interface Window {
    AdobeDC: any;
  }
}
interface PDFViewerProps {
  isAdmin: boolean;
  openAdobeViewer: boolean;
  setOpenAdobeViewer: Function;
  pdfFile: any;
}

const isMobile = "ontouchstart" in window || navigator.maxTouchPoints > 0;

const adobeEmbedApiKey: string = process.env
  .REACT_APP_ADOBE_EMBED_API_KEY as string;

const PDFViewer: React.FC<PDFViewerProps> = ({
  isAdmin,
  openAdobeViewer,
  setOpenAdobeViewer,
  pdfFile,
}: PDFViewerProps): ReactElement => {
  const viewerRef = useRef<HTMLIFrameElement | null>(null);
  const AdobeDC = window.AdobeDC;

  const { proxyToken } = useContext(AppContext);

  const permissions = pdfFile?.fileData?.filePermissions;
  const isPrintable: boolean = isAdmin || permissions?.printEnabled;
  const isDownloadable: boolean = isAdmin || permissions?.downloadEnabled;
  const isCopyEnabled: boolean = isAdmin || permissions?.copyEnabled;
  const logData: DocEventLogData = {
    fileId: pdfFile?.fileData.id,
    fileName: pdfFile?.fileData.name,
    printSupported: isPrintable,
  };
  let lastLogPageViewStart: number = 0;
  let lastLogPageViewEnd: number = 0;
  let totalPages: number = 0;

  const debounceFunction = (): DebounceFunction => {
    let timeoutId: NodeJS.Timeout | undefined;
    let lastParams: AdobeEvent | undefined;

    return (event: AdobeEvent) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (lastParams) {
          logPageView(lastParams);
        }

        lastParams = undefined;
      }, 500);

      lastParams = event;
    };
  };

  const pageViewDebouncedFunc: DebounceFunction = debounceFunction();

  const logPageView = async (event: AdobeEvent) => {
    const startPage = event.data.startPage!.pageNumber;
    let endPage = event.data.endPage!.pageNumber;

    if (totalPages - endPage === 1) {
      endPage++;
    }

    if (lastLogPageViewStart === startPage && lastLogPageViewEnd === endPage) {
      return;
    } else {
      logData.documentEventType = DocEvent.PAGE_VIEW;

      const asyncEventPromises: Promise<void>[] = [];

      for (let i = startPage; i <= endPage; i++) {
        logData.pageNumber = i;
        asyncEventPromises.push(logDocumentEvent(logData));
      }

      await Promise.all(asyncEventPromises).catch((e) => {});

      lastLogPageViewStart = startPage;
      lastLogPageViewEnd = endPage;
    }
  };

  const handleDocumentEvents = async (event: AdobeEvent) => {
    logData.documentEventType = event.type;

    switch (event.type) {
      case DocEvent.DOCUMENT_OPEN:
      case DocEvent.DOCUMENT_PRINT:
      case DocEvent.DOCUMENT_DOWNLOAD:
        break;
      case DocEvent.TEXT_SEARCH:
        logData.searchedText = truncateString(event.data.searchedText!);
        break;
      case DocEvent.TEXT_COPY:
        logData.copiedText = truncateString(event.data.copiedText!);
        break;
      case DocEvent.PAGES_IN_VIEW_CHANGE:
        pageViewDebouncedFunc(event);
        return;
      default:
        return;
    }
    await logDocumentEvent(logData).catch((e) => {});
  };

  const handleClose = () => {
    setOpenAdobeViewer(false);
  };

  const truncateString = (str: string) => {
    return str.length <= 100 ? str : str.slice(0, 50) + "…" + str.slice(-49);
  };

  useEffect(() => {
    const initializePDFViewer = async () => {
      if (window.AdobeDC && pdfFile?.pdfBlob) {
        const adobeDCView = await new window.AdobeDC.View({
          clientId: adobeEmbedApiKey,
          divId: "adobe-dc-view",
        });

        adobeDCView
          .previewFile(
            {
              content: { promise: pdfFile.pdfBlob.arrayBuffer() },
              metaData: {
                fileName: pdfFile.fileData?.name,
              },
            },
            {
              embedMode: isMobile ? "LIGHT_BOX" : "FULL_WINDOW",
              showAnnotationTools: false,
              showDownloadPDF: isDownloadable,
              showPrintPDF: isPrintable,
              sendAutoPDFAnalytics: false,
              hasReadOnlyAccess: true,
              showFullScreen: true,
              exitPDFViewerType: "CLOSE",
            }
          )
          .then((adobeViewer: { getAPIs: () => Promise<any> }) => {
            adobeViewer.getAPIs().then(async (apis) => {
              apis.enableTextSelection(isCopyEnabled);
              totalPages = (await apis.getPDFMetadata()).numPages;
            });
          });

        if (!isAdmin && !proxyToken) {
          const eventOptions = {
            listenOn: [
              AdobeDC.View.Enum.PDFAnalyticsEvents.DOCUMENT_OPEN,
              AdobeDC.View.Enum.PDFAnalyticsEvents.DOCUMENT_PRINT,
              AdobeDC.View.Enum.PDFAnalyticsEvents.DOCUMENT_DOWNLOAD,
              AdobeDC.View.Enum.PDFAnalyticsEvents.TEXT_COPY,
              AdobeDC.View.Enum.PDFAnalyticsEvents.TEXT_SEARCH,
              AdobeDC.View.Enum.FilePreviewEvents.PAGES_IN_VIEW_CHANGE,
            ],
            enablePDFAnalytics: true,
            enableFilePreviewEvents: true,
          };

          adobeDCView.registerCallback(
            AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            (event: AdobeEvent) => {
              handleDocumentEvents(event);
            },
            eventOptions
          );
        }
      }
    };

    initializePDFViewer();
  }, [pdfFile]);

  return isMobile ? (
    <div id="adobe-dc-view" ref={viewerRef}></div>
  ) : (
    <StyledDialog open={openAdobeViewer}>
      <StyledDialogTitle>
        <CloseIcon onClick={handleClose} />
      </StyledDialogTitle>
      <div id="adobe-dc-view" ref={viewerRef}></div>
    </StyledDialog>
  );
};

export default PDFViewer;
