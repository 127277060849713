import { Box, Button, Drawer, styled, Typography } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../utils/constants/styles.constants";
export const DetailPanel = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: theme.spacing(120),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(4),
}));

export const DownLoadButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4),
  width: "20%",
}));
export const DownloadButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  marginRight: theme.spacing(4),
}));

export const PdfContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(97),
  margin: theme.spacing(4),
  height: theme.spacing(105),
  overflowY: "scroll",
  borderRadius: theme.spacing(0.8),
  background: "#FFFFFF",
  boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.25)",
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
}));
