import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";

import { ClientThemeContext } from "../../core/context/clientThemeContext";
import { PageType } from "../../utils/types/login.type";
import { ContentDiv } from "./Login.styles";

const AccountRecovery = () => {
  const { clientTheme } = useContext(ClientThemeContext);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      rowSpacing={1}
      direction="row"
    >
      <Grid container justifyContent="center">
        <ContentDiv>
          <Box my={2}>
            <Typography variant="h1" style={{ color: "white" }}>
              Account Recovery Request Submitted
            </Typography>
          </Box>
          <Box my={2}>A team member will be in touch shortly.</Box>
        </ContentDiv>
      </Grid>
    </Grid>
  );
};

export default AccountRecovery;
