import { Box, FormControl, styled, TextField } from '@mui/material';

import { PRIMARY } from "../../../../styles/colors";

export const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    background-color: #fff;
    box-shadow: 24;
    padding: 25px;

  // Title
  & h2.MuiTypography-root {
    font-weight: bold;
    margin: 10px 0 20px 0;
  }

  // Description
  & p.MuiTypography-root:nth-of-type(2) {
    margin: 10px 0;
    font-weight: normal;
  }

  // Divider
  & .MuiDivider-root {
    margin: 25px 0;
  }

  & .MuiFormControl-root {
    width: 100%;
    padding: 0;
  }
`;

export const TransactionNameTextfield = styled(TextField)`
  margin: 0 0 20px 0;

  input {
    text-align: left;
  }
`;

export const ValueFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 5px 40px;

  & .MuiTypography-root {
    font-weight: bold;
    width: 25%;
  }

  & .MuiTextField-root {
    width: 60px;

    input {
      text-align: center;
    }
  }

  & .MuiIconButton-root {
    font-size: 36px;
    color: ${PRIMARY};
    width: 50px;
  }
`;