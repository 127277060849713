import { useContext, useState } from "react";

import { AppContext } from "../../../../core/context/appContextProvider";
import {
  postAccounts,
  postJournalEntries,
  postLedgers,
} from "../../../../services/arkGL.service";
import { POST_ACTION_TYPE } from "../../../../utils/constants/common.constants";
import { PostingDialogCRUDStatus } from "../../../../utils/types/arkGLJournalEntry.type";
import {
  POST_ACCOUNTS_ERROR,
  POST_ACCOUNTS_SUCCESS,
  POST_GL_ERROR,
  POST_GL_SUCCESS,
  POST_JOURNAL_ENTRY_ERROR,
  POST_JOURNAL_ENTRY_SUCCESS,
} from "../journalEntryList/JournalEntryList.constants";

type Props = {
  ids: string[];
  onPostComplete: Function;
  postAction?: POST_ACTION_TYPE;
  postFiltersRequest?: any;
};

export const useJournalEntryPostDialog = ({
  ids,
  onPostComplete,
  postAction,
  postFiltersRequest,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [isPosting, setIsPosting] = useState<PostingDialogCRUDStatus>();
  const [glError, setGlError] = useState<boolean>(false);
  const [accountError, setAccountError] = useState<boolean>(false);
  const [ledgersToPost, setLedgersToPost] = useState<string[]>([]);
  const [accountsToPost, setAccountsToPost] = useState<string[]>([]);

  const { informationAlert } = useContext(AppContext);

  const actionType =
    ids.length === 1
      ? `Post ${ids.length} Journal Entry`
      : `Post ${ids.length} Journal Entries`;

  const handlePost = async () => {
    await postJournalEntry();
  };

  const postJournalEntry = async () => {
    try {
      setIsPosting(PostingDialogCRUDStatus.PostingJE);

      switch (postAction) {
        case POST_ACTION_TYPE.POST_ALL:
          await postJournalEntries(postFiltersRequest);
          break;
        case POST_ACTION_TYPE.POST_SELECTED:
          await postJournalEntries({ ids });
          break;
      }

      informationAlert(POST_JOURNAL_ENTRY_SUCCESS, "success");
      setIsPosting(undefined);
      onPostComplete();
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        const message = error.response.data.errors;

        let accountString;
        let ledgerString;

        if (message.includes("accountIds") && message.includes("ledgerIds")) {
          const stringArrays = message
            .split("accountIds ")[1]
            .split(", ledgerIds ");

          // eslint-disable-next-line no-useless-escape
          accountString = stringArrays[0].replace(/[\[\]\s]/g, "");
          // eslint-disable-next-line no-useless-escape
          ledgerString = stringArrays[1].replace(/[\[\]\s]/g, "");
        } else if (
          message.includes("accountIds") &&
          !message.includes("ledgerIds")
        ) {
          const stringArrays = message.split("accountIds ")[1];

          accountString = stringArrays.replace(/, $/g, "");
          // eslint-disable-next-line no-useless-escape
          accountString = accountString.replace(/[\[\]\s]/g, "");
        } else if (
          !message.includes("accountIds") &&
          message.includes("ledgerIds")
        ) {
          const stringArrays = message.split("ledgerIds ")[1];

          // eslint-disable-next-line no-useless-escape
          ledgerString = stringArrays.replace(/[\[\]\s]/g, "");
          ledgerString = ledgerString.replace(/, $/g, "");
        }

        if (!message.includes("accountIds") && message.includes("ledgerIds")) {
          const stringArrays = message.split("ledgerIds ")[1];

          // eslint-disable-next-line no-useless-escape
          accountString = stringArrays[0].replace(/[\[\]\s]/g, "");
        }

        const accountIds =
          accountString && accountString.includes(",")
            ? accountString.split(",")
            : [accountString];
        const ledgerIds =
          ledgerString && ledgerString.includes(",")
            ? ledgerString.split(",")
            : [ledgerString];

        if (accountString && accountIds.length > 0) {
          setAccountsToPost(accountIds);
          setAccountError(true);
        }
        if (ledgerString && ledgerIds.length > 0) {
          setLedgersToPost(ledgerIds);
          setGlError(true);
        }
      } else {
        informationAlert(POST_JOURNAL_ENTRY_ERROR, "error");
      }
    } finally {
      setIsPosting(undefined);
    }
  };

  const handlePostGL = async () => {
    try {
      setIsPosting(PostingDialogCRUDStatus.PostingGL);
      await postLedgers(ledgersToPost);
      informationAlert(POST_GL_SUCCESS, "success");
      setGlError(false);
      setLedgersToPost([]);
      setIsPosting(undefined);
    } catch (error) {
      informationAlert(POST_GL_ERROR, "error");
      setIsPosting(undefined);
    }
  };

  const handlePostAccounts = async () => {
    try {
      setIsPosting(PostingDialogCRUDStatus.PostingAccounts);
      await postAccounts(accountsToPost);
      informationAlert(POST_ACCOUNTS_SUCCESS, "success");
      setAccountError(false);
      setAccountsToPost([]);
      setIsPosting(undefined);
    } catch (error) {
      informationAlert(POST_ACCOUNTS_ERROR, "error");
      setIsPosting(undefined);
    }
  };

  return {
    isPosting,
    inputValue,
    setInputValue,
    actionType,
    glError,
    accountError,
    setGlError,
    setAccountError,
    handlePost,
    handlePostGL,
    handlePostAccounts,
  };
};
