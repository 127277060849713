import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export const FormContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: '32px !important',
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr auto',
  overflow: 'auto',
  gap: theme.spacing(2),
}));

export const StyledBankIcon = styled(AccountBalanceOutlinedIcon)(
  ({ theme }) => ({
    color: '#503629',
    paddingRight: theme.spacing(1),
  })
);

export const InstitutionBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}));

export const InstitutionTitle = styled('div')(() => ({
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '100%',
  textAlign: 'left',
  verticalAlign: 'center',
  paragraphSpacing: '10px',
  fontWeight: 'bold',
  display: 'flex',
}));

export const DateBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  background: '#F4EFE3',
  height: '120px',
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
  marginLeft: '-32px',
  paddingLeft: '32px !important',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

export const AddCountBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const GreenAddIcon = styled(AddCircleRoundedIcon)(({ theme }) => ({
  color: '#5BBC76',
  paddingRight: theme.spacing(1),
}));

export const InstructionBox = styled(Box)(({ theme }) => ({
  marginLeft: '35px',
}));

export const FormBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: 0,
  width: '100%',
}));

export const ButtonCircularProgress = styled(CircularProgress)({
  marginRight: '16px',
});

export const BanksInfoBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
  border: '1px solid gray',
  borderRadius: theme.spacing(1),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '7px',
    height: '100%',
    borderRadius: '7px 0 0 7px',
    backgroundColor: 'orange',
  },
}));

export const BankAccAssociationBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  width: '100%',
}));

export const StyledFormControl = styled(BankAccAssociationBox, {
  shouldForwardProp: (props) => props !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  border: ${(props) => {
    if (props.isSelected) return '2px solid #007AFF';
    else return '2px solid #C4C4C4';
  }};
`;

export const PaddedTextBox = styled(Box)(({ theme }) => ({
  paddingRight: '16px',
  paddingBotom: '8px',
  paddingLeft: '16px',
}));

export const MarginOptioniBox = styled(Box)(({ theme }) => ({
  marginTop: '16px',
}));

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
}));

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));
