import { TeamUpsertData } from "../../../utils/types/team.type";

export const CREATE_TEAM_ERROR = "Error in creating team.";
export const CREATE_TEAM_SUCCESS = "Team created successfully.";
export const UPDATE_TEAM_ERROR = "Error in updating team.";
export const UPDATE_TEAM_SUCCESS = "Team updated successfully.";

export const CREATE_TEAM_MEMBER_ERROR = "Error in creating team member.";
export const CREATE_TEAM_MEMBER_SUCCESS = "Team member created successfully.";
export const UPDATE_TEAM_MEMBER_ERROR = "Error in updating team member.";
export const UPDATE_TEAM_MEMBER_SUCCESS = "Team member updated successfully.";

export const DELETE_TEAM_MEMBER_SUCCESS = "Team member deleted successfully.";
export const DELETE_TEAM_MEMBER_ERROR = "Error in team member deletion.";

export const TEAM_FORM_DEFAULT_VALUE: TeamUpsertData = {
    id: undefined,
    name: "",
    funds: [],
};
