import { DetailsType } from "./form.type";

export type ArkGLAccount = {
  id?: string;
  fundId: string;
  parentId: string | null;
  name: string;
  number: number | null;
  description?: string;
  isTaxable: boolean;
  isEntityRequired: boolean;
  transactionTypeId: string | null;
  transactionTypeName: string | null;
  doNotMap: boolean;
  dateMapped: Date | null;
  attributeId: number | null;
  fsMappingId: string | null;
  fsDisplayName: string | null;
  cashFlowMapping: string | null;
  cashFlowName: string | null;
  state: string;
};

export type SelectedAccount = {
  account?: ArkGLAccount;
  type?: DetailsType;
};

export type AttributeListItem = {
  id: string;
  name: string;
};

export type AccountStatusTypes = "draft" | "posted" | "unused";

export type LedgerStateTypes = "draft" | "posted";

export enum ArkAccountAddNewButtonOptions {
  ImportBasic = "import_basic",
  ImportAdvanced = "import_advanced",
  DownloadBasicTemplate = 'download_basic_template',
  DownloadComplexTemplate = 'download_complex_template'
}
