import {
  Box,
  Button as StyledButton,
  Card,
  Divider as StyledDivider,
  Stack,
  styled,
  Typography,
} from "@mui/material";

export const CapitalCallSetupWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  maxHeight: "68vh",
  overflowY: "auto",
}));

export const StyledReviewerContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridGap: theme.spacing(2),
}));

export const StyledParentCard = styled(Card)<{ isChecked: boolean }>(
  ({ theme, isChecked }) => ({
    width: "100%",
    padding: theme.spacing(0.5, 1),
    borderColor: isChecked
      ? theme.palette.primary.main
      : theme.palette.grey[300],
    borderRadius: theme.spacing(0.8),
  })
);

export const CapitalCallSetUpContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

export const Divider = styled(StyledDivider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const HeaderLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2.2),
}));

export const FieldLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.2),
  marginTop: theme.spacing(1.2),
}));

export const Button = styled(StyledButton)(() => ({ width: "20%" }));

export const AddFilesContainer = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: theme.spacing(3),
}));

export const FieldContainer = styled(Stack)(() => ({
  width: "20%",
}));

export const FileContainerLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(-1.5),
  marginTop: theme.spacing(1.2),
}));

export const MarginFixContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, -4),
  marginRight: theme.spacing(0),
}));

export const ErrorLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: theme.typography.pxToRem(12),
  marginTop: theme.spacing(0.3),
}));
