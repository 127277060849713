import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ClientTransMapping } from "../../../utils/types/clientTransMapping.type";
import { getClientTransactionMapping } from "../../client.service";

export const useClientTransactionMappingEffect = (id?: string) => {
  const [transactionMapping, setTransactionMapping] = useState<ClientTransMapping | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchTransactionMappings = useCallback( async (isCanceled?: () => boolean) => {
    if(!id) return;
    try {
      setLoading(true);
      const response = await getClientTransactionMapping(id!);

      if (isCanceled?.()) return;

      setTransactionMapping({
        columns: response.columns?.map((column, index) => {
          return {
            ...column,
            index,
          };
        }),
        rows: response.rows,
      });
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting transaction mapping", "error");
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async (isCanceled) => {
    await fetchTransactionMappings(isCanceled);
  }, [fetchTransactionMappings]);

  return {
    transactionMapping,
    fetchTransactionMappings,
    loading,
    setTransactionMapping
  };
};
