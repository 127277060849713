
export const checkTrustedDeviceURL = "auth-api/mfa/isTrustedDevice";
export const currentUserPhoneURL = "api/users/current/phone";
export const isWhiteListedURL = "auth-api/mfa/isWhitelisted";
export const maxAllowedAttemptURL = "api/env/mfa/attempts/maxAllowed";
export const totalMfaAttemptsURL = "auth-api/mfa/attempts";
export const verifyCodeURL = "auth-api/mfa/verifyCode";
export const requestCodeURL = "auth-api/mfa/requestCode";

export const getHeaders = (method: string, body: any, loginToken: string) => {
    let headers: any = {
        headers: {
            accept: "application/json",
            authorization: `Bearer ${loginToken}`,
            "content-type": "application/json",
        },
        method: method
    };

    if(method === "POST") {
        headers = { ...headers, body: JSON.stringify(body) };
    }     

    return headers;
};
