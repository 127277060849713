import { Box, styled, Typography } from "@mui/material";

export const MainContainer = styled(Box)(() => ({
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    maxWidth: "false"
  }));

export const FinancialStatementsSelectorButtonBox = styled(Box)(() => ({
    display: "flex",
    marginLeft: "10px",
    flexWrap: 'wrap'
  }));

export const FSPackageSelectorButtonBox = styled(Box)(() => ({
    marginTop: '4px'
  }));

export const FinancialStatementsActionsContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    width: "100%"
  }));

export const StyledFilterBox = styled(Box)(() => ({
    marginTop:"8px",
    marginRight: "8px",
  }));

export const StyledDateFilterBox = styled(Box)(() => ({
    marginTop: "8px",
    marginRight: "8px",
    marginBottom: "8px"
}));

export const StyledSwitchFilterBox = styled(Box)(() => ({
  marginTop: '5px',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '5px'
}));

export const SingleStyledSwitchFilterBox = styled(Box)(() => ({
  marginTop: '2px',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2px'
}));


export const StyledFilterSpacerBox = styled(StyledFilterBox)(() => ({
    width: "5px"
  }));

export const NoDataMessageTypography = styled(Typography)(() => ({
    textAlign: "center",
    marginTop: "35px",
    variant: "h3"
  }));

export const FsDataGridBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: '75vh'
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex"
}));

export const FsWrapper = styled(Box)(() =>({
  position: 'relative'
}));

export const FsOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: theme.palette.common.white,
  bodyBackground: theme.palette.common.white,
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
