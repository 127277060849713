import { useEffect } from "react";

import { FormProps } from "../../../utils/types/form.type";
import { InvestorDetail } from "../../../utils/types/investor.type";

export const useWireEffect = (
  form: FormProps,
  scrollToBottom: Function,
  investor?: InvestorDetail
) => {

  const { register, control, errors, setError, setValue, watch } = form;

  const intermediaryBankInfoAbaNumber = watch('wireInfo.intermediaryBankInfo.abaNumber');

  useEffect(() => {
    if (intermediaryBankInfoAbaNumber) setValue('wireInfo.foreign', true);
  }, []);

  const onIntermediaryBankCheck = (event: any) => {
    if (!event.target.checked) {
      setValue('wireInfo.intermediaryBankInfo.abaNumber', '');
      setValue('wireInfo.intermediaryBankInfo.addressLine1', '');
      setValue('wireInfo.intermediaryBankInfo.addressLine2', '');
    }
    scrollToBottom();
  };

  return {
    onIntermediaryBankCheck,
  };
};
