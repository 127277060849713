import { DownloadFileParam } from '../utils/types/download.type';
import {
  Fund,
  FundDetails,
  FundFilters,
  FundSummary,
  FundSummaryItem,
  GlMapping,
  NewTransType,
  TransType,
  TransTypeBucket,
} from '../utils/types/fund.type';
import { Fund as FundV2 } from '../utils/types/fund.v2.type';
import { ListItem } from '../utils/types/listItems';
import { NameIdPair } from '../utils/types/transaction.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => ApiClient.Client(ClientType.NotCached, ServiceType.Api);
const apiClientV2 = () =>
  ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);

export const getFunds = async (): Promise<FundV2[]> => {
  const response: FundV2[] = await apiClient().get<FundV2[]>('funds');

  return response;
};

export const getFundFilters = async (): Promise<FundFilters[]> => {
  const response: FundFilters[] = await apiClient().get<FundFilters[]>(
    'funds/filters'
  );

  return response;
};

export const getFund = async (fundId: string) =>
  await apiClientV2().get<Fund>(`/entities/funds/${fundId}`);

export const getFundsList = async (clientId: string) => {
  const funds = await apiClient().get<any>(`funds/client/${clientId}`);

  return funds;
};

export const getHasMappingAdmin = async () =>
  await apiClient().get<boolean>(`/general-ledger/hasMappingAdminRights`);

export const getAccountsForGLPlatform = async (
  fundId: string,
  glPlatform: string
) =>
  await apiClientV2().get<GlMapping>(
    `/ledgers/accounts?fundId=${fundId}&glPlatform=${glPlatform}`
  );

export const syncAccountsForFund = async (fundId: string, glPlatform: string) =>
  await apiClientV2().get<GlMapping>(
    `/ledgers/accounts?fundId=${fundId}&sync=true&glPlatform=${glPlatform}`
  );

export const getMappedFunds = async () =>
  await apiClient().get<any>(`/general-ledger/mappedFunds`);

export const getTransactionTypes = async (currentClientId: string) =>
  await apiClient().get<TransType[]>(`/transactions/types/${currentClientId}`);

export const postMapsForFund = async (data: any) =>
  await apiClientV2().post<any>(`/ledgers/accounts`, data);

export const getTransactionBuckets = async (currentClientId: string) =>
  await apiClient().get<TransTypeBucket[]>(
    `transactionType/${currentClientId}/buckets`
  );

export const postNewTransType = async (data: NewTransType) =>
  await apiClient().post<NewTransType>(`/transactionType`, data);

export const getFundDetails = async (fundId: string) =>
  await apiClient().get<FundDetails>(`funds/${fundId}/details`);

export const updateFundDetails = async (fundId: string, data: any) =>
  await apiClient().put<FundDetails>(`funds/${fundId}/details`, data);

export const postNewFund = async (data: any) =>
  await apiClientV2().post<FundDetails>(`/entities/funds`, data);

export const getFundCurrencyList = async () =>
  await apiClient().get<ListItem[]>(`funds/currencies`);

export const getFundEntityTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/entityTypes`);

export const getFundLegalTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/legalTypes`);

export const getFundTypeList = async () =>
  await apiClient().get<ListItem[]>(`funds/types`);

export const getFundNameAvailability = async (fundName: string) =>
  await apiClient().get<boolean>(`funds/${fundName}/available`);

export const getFundNames = async () =>
  await apiClient().get<NameIdPair[]>('funds/names');

export const downloadData = async (data: DownloadFileParam) =>
  await apiClient().post('downloads/', data);

export const getGLAuthQuickbooks = async (fundId: string, url: string) =>
  await apiClientV2().get<any>(
    `/ledgers/qbo/auth-url?fundId=${fundId}&returnUrl=${url}`
  );

export const disconnectGL = async (generalLedgerId: string) =>
  await apiClientV2().post<any>(`/ledgers/disconnect`, {
    generalLedgerId,
  });

export const deleteFund = async (clientId: string, fundId: string) =>
  await apiClient().delete<any>(`funds/${fundId}`);
