import { Box } from "@mui/material";
import React, { useState } from 'react';

import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../components/Buttons/Button/Button";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import { MarginFixContainer } from "../Clients.styles";
import Auth0MigrationClient from "./Auth0MigrationClient";
import {
  Container,
} from "./ClientDetails.style";
import ClientLogos from "./ClientLogos";
import ClientSettingForm from "./ClientSettingForm/ClientSettingForm";
import MultiFactorConfiguration from "./MultiFactorConfiguration";
import LoginThemeConfig from "./ThemeConfig/LoginThemeConfig";
import PortalThemeConfig from "./ThemeConfig/PortalThemeConfig";
import { useClientDetails } from "./useClientDetails.hooks";

const ClientDetailsTab: React.FC = () => {
  const {
    loading,
    arkClientTags,
    control,
    handleSubmit,
    theme,
    addUpdateClient,
    watch,
    onCancel,
    isDirty,
    setValue,
    checkSubdomainAvailability,
    clearErrors,
    onLogoChange,
    isNewClient,
    isSuperAdmin,
    isAuthClient, setIsAuthClient,
  } = useClientDetails();

  return (
    <Box justifyContent="center" alignItems="center" flexDirection="column" gap={4} mt={5}>
      <Progress
        id="client_details_progress"
        showProgress={loading}
      />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(addUpdateClient)}
        noValidate
      >
        <Container>
          <Box>
            <ClientSettingForm
              arkClientTags={arkClientTags}
              control={control}
              checkSubdomainAvailability={checkSubdomainAvailability}
              clearErrors={clearErrors}
              isSuperAdmin={isSuperAdmin}
              setValue={setValue}
            />
            <ClientLogos control={control} watch={watch} setValue={setValue} onLogoChange={onLogoChange} />
          </Box>
          <Box>
            <PortalThemeConfig control={control} theme={theme}/>
            <LoginThemeConfig control={control} theme={theme}/>

            {
              isSuperAdmin && 
                <Auth0MigrationClient disabled={!isNewClient} value={isAuthClient} onChange={setIsAuthClient}/>
            }

            {
              !isAuthClient && <MultiFactorConfiguration control={control} />
            }
          </Box>
        </Container>
        <MarginFixContainer>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            sx={{
              px: "30%",
            }}
          >
            <Button
              id="cancel_client_config"
              variant="outlined"
              onClick={onCancel}
              text={"Cancel"}
              color={"secondary"}
              disabled={!isDirty}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id="save_client_config"
              variant="contained"
              text={"Save & Continue to Transaction Mapping"}
              color={"primary"}
              type="submit"
              fullWidth
              disabled={isNewClient && !isDirty}
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </MarginFixContainer>
      </form>
    </Box>
  );
};

export default React.memo(ClientDetailsTab);
