import CloseIcon from '@mui/icons-material/Close';
import { DrawerProps } from '@mui/material';
import React, { useEffect } from 'react';
import { ReactElement } from 'react';

import IconRightArrow from '../../assets/images/icons/icon_right_arrow.svg';
import LateralPanelBox from '../Boxes/LateralPanelBox/LateralPanelBox';
import ImgIcon from '../ImgIcon/ImgIcon';
import TabsPanel from '../TabsPanel/TabsPanel';
import { Drawer, Title, TitleBox } from './DetailPanel.styles';

interface Props extends DrawerProps {
  id: string;
  open: boolean;
  title: any;
  children: any;
  onClose: any;
  hasTabsPanel?: boolean;
  tabsPanelValue?: number;
  tabsPanelHandleChange?: any;
  tabsPanelOptions?: string[];
  isGridDisplay?: boolean;
  customTitleBox?: boolean;
}

const DetailPanel: React.FC<Props> = ({
  id,
  open,
  title,
  onClose,
  hasTabsPanel = false,
  tabsPanelValue,
  tabsPanelHandleChange,
  tabsPanelOptions,
  children,
  isGridDisplay,
  customTitleBox = false,
  ...props
}: Props): ReactElement => {
  return (
    <React.Fragment key={'right'}>
      <Drawer
        id={id}
        anchor="right"
        open={open}
        onClose={onClose}
        variant={props.variant || 'temporary'}
        PaperProps={{
          sx: {
            maxWidth: props.variant !== 'temporary' ? '99% !important' : 'auto',
          },
        }}
      >
        {!customTitleBox && (
          <TitleBox>
            {title?.props === undefined ? (
              <Title id={`${id}_title`}>
                {title} <ImgIcon icon={IconRightArrow} />
              </Title>
            ) : (
              <Title> {title} </Title>
            )}
            <CloseIcon color="primary" onClick={onClose} />
          </TitleBox>
        )}

        {hasTabsPanel &&
          tabsPanelValue !== undefined &&
          tabsPanelOptions &&
          tabsPanelHandleChange && (
            <TabsPanel
              id={`${id}_tabs`}
              value={tabsPanelValue}
              handleChange={tabsPanelHandleChange}
              options={tabsPanelOptions}
            />
          )}
        {isGridDisplay ? (
          children
        ) : (
          <LateralPanelBox>{children}</LateralPanelBox>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default DetailPanel;
