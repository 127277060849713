import {
  ContactsRounded,
  DeviceHubRounded,
  DownloadRounded,
  FolderRounded,
  LeaderboardRounded,
  LocalAtmRounded,
  MailRounded,
  MonetizationOnRounded,
  PaymentRounded,
  PeopleAltRounded,
  RequestQuoteRounded,
  UploadRounded,
} from '@mui/icons-material';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import HomeIcon from '@mui/icons-material/Home';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import React, { useCallback } from 'react';

import InvestorIcon from '../Icons/InvestorIcon';

type Props = {
  iconKey: string;
  sx?: React.CSSProperties;
};

const MenuOptionIcon = ({ iconKey, sx }: Props) => {
  const getIcon = useCallback(() => {
    switch (iconKey) {
      case 'Banks':
        return <AccountBalanceRoundedIcon sx={sx} />;
      case 'Funds':
        return <MonetizationOnRounded sx={sx} />;
      case 'Mailings':
        return <MailRounded sx={sx} />;
      case 'Investors':
        return <InvestorIcon color={sx?.color} />;
      case 'Contacts':
        return <ContactsRounded sx={sx} />;
      case 'Transactions':
        return <PaymentRounded sx={sx} />;
      case 'Capital Accounts':
        return <LocalAtmRounded sx={sx} />;
      case 'Documents':
        return <FolderRounded sx={sx} />;
      case 'General Ledger':
        return <AccountBalanceWalletOutlinedIcon sx={sx} />;
      case 'Portfolio Investments':
        return <QueryStatsOutlinedIcon sx={sx} />;
      case 'Capital Calls':
        return <RequestQuoteRounded sx={sx} />;
      case 'Distributions':
        return <DeviceHubRounded sx={sx} />;
      case 'Reports':
        return <LeaderboardRounded sx={sx} />;
      case 'Teams':
        return <PeopleAltRounded sx={sx} />;
      case 'Upload History':
        return <UploadRounded sx={sx} />;
      case 'Download History':
        return <DownloadRounded sx={sx} />;
      case 'HomeIcon':
      default:
        return <HomeIcon sx={sx} />;
    }
  }, [iconKey]);

  return <>{getIcon()}</>;
};

export default React.memo(MenuOptionIcon);
