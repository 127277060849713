import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from 'react';

import useSecurityTypeFilterEffect from "./useSecurityTypeFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (securityType: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
}

type SecurityTypeValue = any | null | undefined;

export const SecurityTypeFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = true,
  disabled,
  size = 'small',
  id = 'portfolio_company_filter',
  textFieldID = 'txt_portfolio_company'
}: Props) => {
  const {
    securityTypeList,
    loading,
  } = useSecurityTypeFilterEffect();
  const onSelectChange = (_: any, securityType: any) => {
    if (!securityType) {
      return;
    }
    if (typeof securityType === 'string') {
      securityType = { id: securityType, name: securityType };
      securityTypeList?.push(securityType);
    }
    onChange?.(idSelectOnly ? (securityType?.id ?? value) : securityType);
  };

  const selectedValue: SecurityTypeValue = idSelectOnly ? securityTypeList?.find((item: any) => item.id === value) ?? value : null as SecurityTypeValue;

  return (
    <Autocomplete
      freeSolo
      autoSelect
      id={id}
      options={securityTypeList || []}
      getOptionLabel={(option: any) => option?.name ?? value}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue ?? ''}
      size={size}
      disabled={disabled || loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SecurityTypeFilter;