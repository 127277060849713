import {
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import ProgressModal from "../../../../components/Progress/ProgressModal/ProgressModal";
import { TransTypeBucket } from "../../../../utils/types/fund.type";
import { useNewTransactionModal } from "./NewTransactionModal.hooks";
import {
  StyledBox,
  TransactionNameTextfield,
  ValueFormControl,
} from "./NewTransactionModal.styles";

type Props = {
  clientId: string;
  open: boolean;
  transTypeModel: TransTypeBucket[];
  onSuccess: () => void;
  onClose: () => void;
};

const NewTransactionModal: React.FC<Props> = (props: Props) => {
  const { clientId, open, transTypeModel, onSuccess, onClose } = props;

  const {
    handleNewTransactionValues,
    handleNewTransactionSave,
    isSending,
    name,
    setName,
    newTransTypeModel,
  } = useNewTransactionModal({
    clientId,
    transTypeModel,
    onSuccess,
    onClose,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      id="mdl_new_transaction"
      aria-labelledby="modal_newtranstype_title"
      aria-describedby="modal_newtranstype_description"
    >
      <StyledBox>
        <ProgressModal
          id="modal_saving_mapping"
          showProgress={isSending}
          text="Saving new transaction type..."
        />

        <Typography id="modal_newtranstype_title" component="h2">
          Create Custom Ark Transaction Type
        </Typography>
        <Typography id="modal_newtranstype_description" variant="body2">
          This will create a new Ark Transaction Type that you can then map
          accounts to. This custom type will also appear in the relevant reports
          and in your Workbook.
        </Typography>
        <Divider />
        <FormControl>
          <TransactionNameTextfield
            id="txt_new_transtype_name"
            label="Transaction Name"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            size="small"
          />

          {newTransTypeModel.map((bucket) => (
            <ValueFormControl key={bucket.label}>
              <Typography variant="body2">{bucket.label}</Typography>
              <IconButton
                id={`btn_decrease_${bucket.label.toLowerCase()}`}
                aria-label={`lbl_decrease_${bucket.label.toLowerCase()}`}
                onClick={() => handleNewTransactionValues(-1, bucket.label)}
                disabled={bucket.value === -1}
              >
                {" "}
                -
              </IconButton>
              <TextField
                required
                id={`txt_newtrans_${bucket.label.toLowerCase()}`}
                variant="outlined"
                value={bucket.value}
                size="small"
              />
              <IconButton
                id={`btn_increase_${bucket.label.toLowerCase()}`}
                aria-label={`lbl_increase_${bucket.label.toLowerCase()}`}
                onClick={() => handleNewTransactionValues(1, bucket.label)}
                disabled={bucket.value === 1}
              >
                +
              </IconButton>
            </ValueFormControl>
          ))}
          <Stack direction="row" spacing={4} sx={{ mt: 3 }}>
            <Button
              id="btn_save_new_transtype"
              variant="contained"
              onClick={handleNewTransactionSave}
              disabled={!name}
            >
              Save Custom Type
            </Button>

            <Button id="btn_cancel_save" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </FormControl>
      </StyledBox>
    </Modal>
  );
};

export default NewTransactionModal;
