import { Fade } from "@mui/material";
import React, { ReactElement, useState } from "react";

import IconSearch from "../../assets/images/icons/icon_search.svg";
import ImgIcon from "../ImgIcon/ImgIcon";
import {
  AutoCompleteDivider,
  AutoCompleteInput,
  AutoCompleteInputAdornment,
  AutoCompletePopover,
  AutoCompletePopper,
  SearchBarInput,
  SearchBarInputPopover,
} from "./SearchBar.styles";

type Props = {
  id: string;
  size: string;
  useIcon?: boolean;
  onChange: any;
  options: string[];
  searchText: string;
  showPopover: boolean;
  setShowPopover: any;
};

//Custom Popper
const CustomPopper = function (props: any) {
  return <AutoCompletePopper {...props} placement="bottom" />;
};

const SearchBar = (props: Props): ReactElement => {
  const {
    id,
    size,
    useIcon = true,
    onChange,
    options,
    searchText,
    showPopover,
    setShowPopover,
  } = props;

  const handleOnClick = (event: any) => {
    setShowPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleBlur = (event: any) => {
    setShowPopover(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <SearchBarInput
        id={id}
        size="small"
        placeholder="Search"
        value={searchText}
        onClick={handleOnClick}
        InputProps={{
          startAdornment: (
            <AutoCompleteInputAdornment position="start">
              {useIcon ? <ImgIcon icon={IconSearch} /> : ""}
            </AutoCompleteInputAdornment>
          ),
          readOnly: true,
        }}
        variant="outlined"
      />

      <AutoCompletePopover
        id="auto_complete_popover"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={showPopover}
        TransitionComponent={Fade}
        TransitionProps={{
          timeout: 0
        }}
      >
        <AutoCompleteInput
          freeSolo
          id={id + "_popover"}
          onChange={onChange}
          options={options}
          PopperComponent={CustomPopper}
          value={searchText}
          renderInput={(params) => (
            <SearchBarInputPopover
              {...params}
              id={id + "_popover_textfield"}
              size="small"
              placeholder="Search"
              autoFocus
              onBlur={handleBlur}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <AutoCompleteInputAdornment position="start">
                    {useIcon ? <ImgIcon icon={IconSearch} /> : ""}
                  </AutoCompleteInputAdornment>
                ),
                disableUnderline: true,
              }}
              variant="standard"
            />
          )}
        />
        <AutoCompleteDivider />
      </AutoCompletePopover>
    </div>
  );
};

export default SearchBar;
