import { Box } from "@mui/material";
import React, { ReactElement } from "react";
import { useIdleTimer } from 'react-idle-timer';

import { AppContext } from "../../core/context/appContextProvider";
import ConfirmationDialog from "../Modal/ConfirmationDialog";
import NavBar from "../NavBar/NavBar";
import SideBar from '../SideBar/SideBar';
import { LayoutContainer, LayoutContent, LayoutContentWithSideBar } from "./Layout.styles";

type Props = {
  id?: string;
  notice?: ReactElement;
  children?: ReactElement;
};

export const IDLE_TIMEOUT = 2640000;
export const PROMPT_TIMEOUT = 60000;

const Layout = ({
  id,
  notice,
  children
}: Props): ReactElement => {
  const [showPrompt, setPrompt] = React.useState<boolean>(false);
  const [remainingTime, setRemainingTime] = React.useState<number>(PROMPT_TIMEOUT);

  const {
    onLogout,
    onIdleWarning
  } = React.useContext(AppContext);

  const onPrompt = () => {
    setPrompt(true);
    onIdleWarning();
  };

  const onIdle = () => {
    setPrompt(false);
    setRemainingTime(PROMPT_TIMEOUT);
    onLogout();
  };

  const {
    reset,
    isPrompted,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    events: [
      'mousemove', 
      'keydown', 
      'wheel', 
      'DOMMouseScroll', 
      'mousewheel', 
      'mousedown', 
      'touchstart', 
      'touchmove', 
      'MSPointerDown', 
      'MSPointerMove', 
    ],
    timeout: IDLE_TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
  });

  React.useEffect(() => {
    if (!isPrompted() || !remainingTime || remainingTime <= 0) return;
    const intervalId = setInterval(() => {
      setRemainingTime(remainingTime - 1000);
    }, 1000);

    return () => intervalId && clearInterval(intervalId);
  }, [remainingTime, isPrompted()]);

  return (
    <LayoutContainer
      role="main"
      id={id || 'layout-container'}>
      <Box>
        <NavBar/>
      </Box>
      <LayoutContentWithSideBar>
        <SideBar />
        <LayoutContent>
          {notice}
          {children}
          <ConfirmationDialog
          open={showPrompt}
          id="consent_confirmation"
          actionDisplayType="inline"
          actions={[
            {
              label: 'Continue',
              onClick: () => {
                setPrompt(false);
                setRemainingTime(PROMPT_TIMEOUT);
                reset();
              },
              id: 'continue_button',
              variant: 'contained',
              color: 'primary'
            },
          ]}
          title="Session Inactivity"
          content={`Your session will expire in ${remainingTime/1000} seconds. Click on continue to prevent from logout.`}
        />
        </LayoutContent>
      </LayoutContentWithSideBar>
    </LayoutContainer>
  );
};

export default Layout;

