import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

const BootstrapInput =  styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    'fieldset': {
      display: 'none'
    }
  },
  "& .MuiOutlinedInput-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.inputOutline}`,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0.8, 1),
    '&.Mui-focused': {
      borderColor: theme.palette.common.inputOutline
    }
  },
  "& .MuiInputLabel-shrink": {
    color: theme.palette.common.subTitle,
    fontWeight: 'bold',
    position: 'static',
    transform: 'scale(0.75)',
    '&.Mui-focused': {
      color: theme.palette.common.subTitle
    }
  }
}));

export default BootstrapInput;