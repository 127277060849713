import { DetailsType } from './form.type';
import { FilterItem } from './listItems';

export type ArkGLJournalEntry = {
  id?: string | null;
  journalId?: string;
  number?: number | null;
  ledgerId: string;
  fundId?: string;
  date: Date | string | null;
  currencyName?: string;
  currencyDecimal?: number;
  adjustingJournalEntry?: boolean;
  state?: string;
  postDate?: Date | string | null;
  lineItems: JournalEntryLineItem[];
  lineItemsPosted?: JournalEntryLineItem[];
  memo?: string | null;
  reference?: string | null;
  attachments?: FileList | File[] | null;
  currentAttachments?: JournalEntryAttachment[] | null;
};

export type JournalEntryLineItem = {
  id?: string;
  accountId: string;
  amount: number | null;
  entities?: FilterItem[] | null;
  entityIds?: string[] | null;
  entryType: string;
  entryMemo?: string;
  isEntityRequired?: boolean;
};

export type JournalEntryAttachment = {
  documentId: string;
  documentMemo?: string;
};

export type JournalEntryParams = {
  fundFilter: string[] | null;
  accountFilter: string[] | null;
  entityFilter: string[] | null;
  statusFilter: string[] | null;
  dateFilter: string[] | null;
};

export type SelectedJournalEntry = {
  journalEntry?: ArkGLJournalEntry;
  type?: DetailsType;
  connectTransactionToJournalEntry?: {
    journalEntryId?: string;
    investmentTransactionIds?: string[];
  };
  connectCashReceiptToJournalEntry?: {
    journalEntryId?: string;
    cashReceiptTransactionIds?: string[];
  };
  connectAdjustmentToJournalEntry?: any;
  multiView?: any;
  singleView?: any;
  fromView?: string;
};

export type JournalEntryStatusTypes = 'draft' | 'posted';

export enum PostingDialogCRUDStatus {
  PostingGL = 'Posting GL...',
  PostingJE = 'Posting journal entries...',
  PostingAccounts = 'Posting accounts...',
}

export interface Files {
  files: File;
}
