export enum DistributionDetailsTabs {
  DistributionSetup = "Distribution Setup",
  AddTransactions = "ADD TRANSACTIONS",
  ReviewDocuments = "REVIEW DOCUMENTS",
  Publish = "PUBLISH",
}

export enum DistributionReviewersList {
  Associate = "ASSOCIATE",
  FundController = "FUND_CONTROLLER",
  Manager = "MANAGER",
  Director = "DIRECTOR",
  FundUserReadOnly = "FUND_USER_READ_ONLY",
}
export const InitialErrorMessages = {
  ASSOCIATE: "",
  FUND_CONTROLLER: "",
  FUND_USER_READ_ONLY: "",
  MANAGER: "",
  DIRECTOR: "",
};

export const CREATE_DISTRIBUTION_CALL_ERROR =
  "Error creating distribution call.";
export const CREATE_DISTRIBUTION_SUCCESS =
  "distribution setup created successfully.";
export const CREATE_DISTRIBUTION_UPDATE =
  "distribution setup updated successfully";
export const DISTRIBUTION_TEAM_MEMBERS_ROLE_ERROR =
  "Error accessing team members";
export const DISTRIBUTION_DOCUMENT_UPLOAD_ERROR =
  "Error uploading the document";
export const DISTRIBUTION_DOCUMENT_GET_ERROR = "Error retrieving the document";
export const DISTRIBUTION_REVIEW_SUMMARY_GET_ERROR =
  "Error retrieving review summary";
export const DISTRIBUTION_REVIEW_DOCUMENT_SUCCESS =
  "Documents Submitted for Review";
export const DISTRIBUTION_UPDATE_STATUS_ERROR =
  "Error updating the status of capital call";
export const DISTRIBUTION_UPDATE_STATUS_DOCUMENT_ERROR =
  "Error updating the status of document";
export const DISTRIBUTION_REVIEW_VALIDATION_ERROR =
  "There should be at least one user in reviewer you select";
