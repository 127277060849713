import { FormControl, Select, styled } from "@mui/material";


export const StyledSelect = styled(Select, {
  shouldForwardProp: (props) =>
    props !== "isFullWidth" && props !== "isShort"
})<{
  isFullWidth: string;
  isShort: string;
  width?: string;
}>`
  white-space: nowrap;
  width: ${(props) => {
    if (props.isShort === "true") return "100px";
    else if (props.width) return props.width;
    else if (props.isFullWidth === "false") return "320px";
    else return "100%";
  }};
`;

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (props) =>
    props !== "isFullWidth" && props !== "isShort" && props !== "noGutter"
})<{
  isFullWidth: string;
  isShort: string;
  noGutter: string;
}>`
  margin: ${(props) => {
    if (props.noGutter === "true") return "0px";
    else return "10px";
  }};
  width: ${(props) => {
    if (props.isShort === "true") return "250px";
    else if (props.isFullWidth === "false") return "320px";
    else return "100%";
  }};
`;
