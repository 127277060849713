import { useEffect, useState } from "react";

import { awaitReactUpdate } from "../../../../../../utils/helpers/timeoutFunctions";
import { WbConfigDialogType, Workbook } from "../../../workbook.type";
import { AllocationsSheetManager } from "./AllocationsSheet.Manager";

export type WbConfigDialogInfo = {
    wbConfigDialogType: WbConfigDialogType,
    workbook?: Workbook
};



export const useAllocationsSheet = (
    allocationsSheetManager: AllocationsSheetManager
) => {
    const [loadingMessage, setLoadingMessage] = useState<string|undefined>(undefined);
    const [invalidPasteText, setInvalidPasteText] = useState<string|undefined>(undefined);
    const [messageDialogText, setMessageDialogText] = useState<{title:string, message:string}|undefined>(undefined);
    const [showWbConfigDialog, setShowWbConfigDialog] = useState<WbConfigDialogInfo>({ wbConfigDialogType: WbConfigDialogType.NoShow });
    const [showPcapSavePromt, setShowPcapSavePromt] = useState(false);
    const [showSubmitToPcapDialog, setShowSubmitToPcapDialog] = useState(false);
    const [showGlConnectDialog, setShowGlConnectDialog] = useState(false);
    const [showRolloverNewWBDialog, setShowRolloverNewWBDialog] = useState(false);

    useEffect(() => {
        allocationsSheetManager.onShowConfig = handleShowConfig;
        allocationsSheetManager.onShowLoadingMessage = handleLoadingMessage;
        allocationsSheetManager.onShowInvalidPasteText = setInvalidPasteText;
        allocationsSheetManager.onShowMessageDialog = setMessageDialogText;
        allocationsSheetManager.onShowSubmitToPcapSaveDialog = setShowPcapSavePromt;
        allocationsSheetManager.onShowGlConnectDialog = setShowGlConnectDialog;
        allocationsSheetManager.onShowRolloverNewWB = setShowRolloverNewWBDialog;
        allocationsSheetManager.workbookSheetsManager.saveComplete = undefined;
    }, []);

    function handleShowConfig(wbConfigDialogType: WbConfigDialogType) {
        const workbook = allocationsSheetManager.workbookSheetsManager.getWorkbookDataCloned();

        setShowWbConfigDialog({
            wbConfigDialogType,
            workbook: workbook
        });
    }

    function handleConfigOK(wbConfigDialogInfo: WbConfigDialogInfo) {
        const workbook = wbConfigDialogInfo.workbook!;

        allocationsSheetManager.handleConfigOKClicked(wbConfigDialogInfo.wbConfigDialogType, workbook);

        setShowWbConfigDialog({ wbConfigDialogType: WbConfigDialogType.NoShow });
    }

    async function handleLoadingMessage(message: string|undefined) {
        setLoadingMessage(message);
        await awaitReactUpdate();
    }

    function handleRolloverNewWB() {
        allocationsSheetManager.workbookSheetsManager.beginRolloverNewWorkbook();

        setShowRolloverNewWBDialog(false);
    }

    function handleSaveAndLoadPcapDialog() {
        setShowPcapSavePromt(false);
        setLoadingMessage('Saving...') ;

        allocationsSheetManager.workbookSheetsManager.saveComplete = () => {
            setShowSubmitToPcapDialog(true);
            setLoadingMessage(undefined);
            allocationsSheetManager.workbookSheetsManager.saveComplete = undefined;
        };

        allocationsSheetManager.workbookSheetsManager.beginWorkbookSaving();
    }

    return {
        loadingMessage,
        showWbConfigDialog, setShowWbConfigDialog, handleConfigOK,
        invalidPasteText, setInvalidPasteText,
        messageDialogText, setMessageDialogText,
        showSubmitToPcapDialog, setShowSubmitToPcapDialog,
        showPcapSavePromt, setShowPcapSavePromt, handleSaveAndLoadPcapDialog,
        showGlConnectDialog, setShowGlConnectDialog,
        showRolloverNewWBDialog, setShowRolloverNewWBDialog, handleRolloverNewWB
    };
};