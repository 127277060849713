import { Box, Grid, Stack, styled } from '@mui/material';

export const MainGridStyled = styled(Grid)(() => ({
  marginRight: 20,
}));

export const ColorBoxStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'index',
})<{
  index: number;
}>(({ theme, index }) => {
  const getColor = (itemIndex: number) => {
    const colorID = itemIndex % 18;

    switch (colorID) {
      case 0:
        return theme.palette.common.visUltramarineBlue;
      case 1:
        return theme.palette.common.visTealBlue;
      case 2:
        return theme.palette.common.visDarkSlateBlue;
      case 3:
        return theme.palette.common.visBrightBlue;
      case 4:
        return theme.palette.common.visNavyBlue;
      case 5:
        return theme.palette.common.visRoyalBlue;
      case 6:
        return theme.palette.common.visCharcoalBlue;
      case 7:
        return theme.palette.common.visElectricBlue;
      case 8:
        return theme.palette.common.visDeepSeaBlue;
      case 9:
        return theme.palette.common.visLightTan;
      case 10:
        return theme.palette.common.visMutedCopper;
      case 11:
        return theme.palette.common.visLightAqua;
      case 12:
        return theme.palette.common.visSoftCoral;
      case 13:
        return theme.palette.common.visSkyBlue;
      case 14:
        return theme.palette.common.visLightSalmonPink;
      case 15:
        return theme.palette.common.visPeach;
      case 16:
        return theme.palette.common.visPaleMauve;
      case 17:
        return theme.palette.common.visMidnightBlue;
      default:
        return 'transparent';
    }
  };

  return {
    color: 'white',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getColor(index),
  };
});

export const CountBoxStyled = styled(Box)(() => ({
  marginRight: '5px',
}));

export const LabelBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const StackStyled = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}));
