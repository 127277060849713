import { Box, Grid, styled } from "@mui/material";

import Button from "../../../components/Buttons/Button/Button";


export const StyledContentGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "10%",
  justifyContent: "center",
   alignItems: "center"
}));

export const StylesRetryButton = styled(Button)(({ theme }) => ({
  marginTop: "30"
}));

