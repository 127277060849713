import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { isEqual } from "lodash";
import { Fragment, ReactElement, useContext, useEffect, useState } from "react";

import ExpandIcon from "../../../../assets/images/icons/icon_expand.svg";
import MinimizeIcon from "../../../../assets/images/icons/icon_minimize.svg";
import IconRightArrow from "../../../../assets/images/icons/icon_right_arrow.svg";
import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import InformationPanel from "../../../../components/InformationPanel/InformationPanel";
import DeleteConfirmationDialog from "../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import FullScreenModal from "../../../../components/Modal/FullScreenModal/FullScreenModal";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import TabContent from "../../../../components/TabContent/TabContent";
import TabsPanel from "../../../../components/TabsPanel/TabsPanel";
import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import {
  checkUniqueEmail,
  deleteContact,
  emailContacts,
  saveNewContact,
  updateExistingContact,
} from "../../../../services/contact.service";
import {
  addressSchema,
  contactDetailSchema,
  defaultContactInfo,
  defaultTaxAddress,
} from "../../../../utils/constants/form.constants";
import {
  EMAIL_SENT_ALERT,
  SEND_EMAIL_CONTACT_CONFIRMATION,
  UNABLE_TO_SAVE_SUBTITLE,
} from "../../../../utils/constants/text.constants";
import {
  GENERIC_CHANGED_INFO_SUCCESS_MESSAGE,
  GENERIC_ERROR_MESSAGE,
  INVESTOR_TYPE_ENTITY,
  INVESTOR_TYPE_INDIVIDUAL,
  SAVE_CONTACT_CONFIRMATION_TEXT,
  TEXT_CANCEL,
  TEXT_CONFIRM_OK,
  TEXT_DISCARD_CHANGES,
  TEXT_SAVE_CHANGES,
  UNSAVED_CHANGES_SUBTITLE,
} from "../../../../utils/constants/text.constants";
import { getHostname } from "../../../../utils/helpers/misc.helper";
import { useForm } from "../../../../utils/hooks/useForm.hook";
import { FundItem } from "../../../../utils/types/fund.type";
import { InvestorFilter } from "../../../../utils/types/investor.type";
import { FilterItem } from "../../../../utils/types/listItems";
import { ScopeRole } from "../../../../utils/types/user.type";
import { ContactContext } from "../../contactList/ContactList";
import { formatPermissionByInvestors } from "../../contactList/ContactList.hooks";
import ContactPermissions from "../../contactPermissions/ContactPermissions";
import {
  useContactDetailPanelEffect,
  useContactEffect,
  useContactValidator,
} from "./ContactDetailPanel.hooks";
import {
  ActionButtonBox,
  IconText,
  PanelActionBox,
  PermissionsBox,
  StyledBackdrop,
  Title,
} from "./ContactDetailPanel.styles";
import ContactDetails from "./ContactDetails/ContactDetails";

type Anchor = "right";

type Props = {
  showPanel: boolean;
  setShowPanel: any;
  selectedContact: string;
  setSelectedContact: any;
  roleFilters: FilterItem[];
  setDataUpdate?: any;
  investorFilters: InvestorFilter[];
  primaryInvestors?: Set<string>;
  fundFilters?: FundItem[];
  setLoadingContactList: any;
  reloadContactList: () => void;
  isLoadingImpersonation: boolean;
  handleImpersonateUser: Function;
  onApprove: Function;
  onDecline: Function;
  resetContactPanel: boolean;
  setResetContactPanel: any
};

const getSaveConfirmationModalTextForClientPortal = (supportEmail: string) => {
  return `The contact's information has been sent to ${supportEmail} for review and approval. Once approved, the contact will receive an activation email.`;
};

const hostname = getHostname(window.location.hostname);

const ContactInfoPanel: React.FC<Props> = (props: Props): ReactElement => {
  const { permissionsByInvestorList, setPermissionsByInvestorList, isContactChanged, setIsContactChanged } =
    useContext(ContactContext);

  const {
    showPanel,
    setShowPanel,
    selectedContact: selectedContactId,
    setSelectedContact,
    roleFilters,
    setDataUpdate,
    investorFilters,
    fundFilters,
    setLoadingContactList,
    isLoadingImpersonation,
    handleImpersonateUser,
    reloadContactList,
    onApprove,
    onDecline,
    resetContactPanel,
    setResetContactPanel,
    primaryInvestors
  } = props;

  const { informationAlert } = useContext(AppContext);

  const {
    countryList,
    entityTypeOptions,
    showLeaveConfirmation,
    setShowLeaveConfirmation,
    showLeaveNoSaveConfirmation,
    setShowLeaveNoSaveConfirmation,
    activeTabIndex,
    setAciveTabIndex,
    handleTabChange,
    showSaveConfirmation,
    setShowSaveConfirmation,
    isSubmittingContact,
    setSubmitingContact,
    splitActionConfirmation,
    setSplitButtonActionConfirmation,
  } = useContactDetailPanelEffect({ informationAlert, readonly: false });

  const { preAuthClientConfigs } = useContext(AppContext);
  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsSaveDisabled(true);
    const foundCheckedPermission = permissionsByInvestorList.find((entry: any) => {

      const foundChecked = Object.entries(entry).find(([key, value]) => {
        if (key !== 'id' &&
          key !== 'name' &&
          key !== 'investor' &&
          key !== 'hierarchy' &&
          key !== 'isLocked'
        ) {
          return value === true;
        }
      });

      return foundChecked;
    });

    if (foundCheckedPermission) {
      setIsSaveDisabled(false);
    }
  }, [permissionsByInvestorList]);

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setShowPanel(open);
    setPanelVisible({
      ...isPanelVisible,
      [anchor]: open,
    });
  };

  useEffect(() => {
    if (resetContactPanel) {
      toggleDrawer("right", false);
      resetContactConfig();
      setResetContactPanel(false);
    }
  }, [resetContactPanel]);

  const {
    title,
    contact,
    contactDetail,
    isLoadingContact,
    isPanelVisible,
    setPanelVisible,
    isLoadingPermissions,
    setLoadingPermissions,
    emailError,
    setEmailError,
    setContact,
  } = useContactEffect({
    contactId: selectedContactId,
    showPanel,
    informationAlert,
    investorFilters,
    roleFilters,
    fundFilters,
    readonly: false,
    setPermissionsByInvestorList,
  });

  const {
    useInput: useInputContactDetail,
    values: valuesContactDetail,
    setValues: setValuesContactDetail,
  } = useForm(contact.details, contactDetailSchema, false);

  const {
    useInput: useInputContactAddressInput,
    useInputSelect: useInputSelectContactAddress,
    values: valuesContactAddress,
    setValues: setContactAddressValues,
  } = useForm(contact.details.address, addressSchema, false);

  const { isFormValid } = useContactValidator(valuesContactDetail, emailError);

  const isContactObjectChanged = () => {
    const contactDetails = { ...valuesContactDetail, address: valuesContactAddress };

    return !isEqual(contactDetails, contact.details);
  };

  const resetContactConfig = () => {
    const contactConfig = {
      details: {
        ...defaultContactInfo,
        address: { ...defaultTaxAddress },
      },
      investorFundRoles: [],
    };

    setContactAddressValues(contactConfig.details.address);
    setSelectedContact("");
    setValuesContactDetail(contactConfig?.details);
    setContact(contactConfig);
    setAciveTabIndex(0);
    setEmailError("");
    toggleDrawer("right", false);
    setPermissionsByInvestorList([]);
    setIsContactChanged(false);
  };

  const handleOnClose = () => {
    setIsContactChanged(false);
    if (isContactChanged || isContactObjectChanged()) {
      if (activeTabIndex === 1 && isSaveDisabled) {
        setShowLeaveNoSaveConfirmation(true);
      } else {
        setShowLeaveConfirmation(true);
      }
    } else {
      resetContactConfig();
      setShowLeaveConfirmation(false);
    }
  };

  const hidePanel = () => {
    toggleDrawer("right", false);
    setAciveTabIndex(0);
  };

  const handleOnSave = async () => {
    setShowSaveConfirmation(false);
    setShowLeaveConfirmation(false);

    if (permissionsByInvestorList.length === 0 && activeTabIndex === 0) {
      setAciveTabIndex(1);
      return;
    }
    if (
      valuesContactDetail.contactType === INVESTOR_TYPE_ENTITY &&
      !valuesContactDetail.name
    ) {
      return;
    } else if (
      valuesContactDetail.contactType === INVESTOR_TYPE_INDIVIDUAL &&
      (!valuesContactDetail.firstName || !valuesContactDetail.lastName)
    ) {
      return;
    }
    setSubmitingContact(true);
    try {
      valuesContactDetail.address = valuesContactAddress;
      valuesContactDetail.email = valuesContactDetail.email?.trim()?.toLowerCase();
      const investorFundRoles = formatPermissionByInvestors(
        permissionsByInvestorList,
        roleFilters
      );

      const uniqueEmail = await checkUniqueEmail(
        valuesContactDetail.email,
        selectedContactId !== "new" ? selectedContactId : ""
      );

      if (!uniqueEmail) return;

      if (selectedContactId === "new") {
        valuesContactDetail.status = "PENDING";
        valuesContactDetail.id = "";

        await saveNewContact({
          details: valuesContactDetail,
          investorFundRoles: investorFundRoles,
        });
      }
      else {
        await updateExistingContact(
          {
            details: valuesContactDetail,
            investorFundRoles: investorFundRoles,
          },
          selectedContactId
        );
        informationAlert(GENERIC_CHANGED_INFO_SUCCESS_MESSAGE, "success");
      }

      resetContactConfig();
      hidePanel();
      setDataUpdate(true);
    } catch (exception) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    } finally {
      setSubmitingContact(false);
    }
  };

  const handleOnPermissionOrSave = () => {
    if (isContactChanged || isContactObjectChanged()) {
      if (permissionsByInvestorList.length > 0) setShowSaveConfirmation(true);
      else {
        setAciveTabIndex(1);
      }
    } else {
      setShowLeaveConfirmation(false);
      resetContactConfig();
    }
  };

  const handleOnDiscard = () => {
    setShowLeaveConfirmation(false);
    setShowLeaveNoSaveConfirmation(false);
    resetContactConfig();
  };

  const handleOnOkay = () => {
    setShowLeaveNoSaveConfirmation(false);
  };

  const handleOnSplitButtonClick = (optionSelected: string) => {
    const params = {
      userName: contact.details.email,
      clientId: contact.details.tenantId,
    };

    switch (optionSelected) {
      case "btn_resend_email":
        setSplitButtonActionConfirmation({
          show: true,
          confirmText: "Approve",
          cancelText: "Cancel",
          title: SEND_EMAIL_CONTACT_CONFIRMATION,
          subtitle: "",
          action: optionSelected,
        });
        return;
      case "btn_impersonate":
        return handleImpersonateUser(params);
      case "btn_delete_contact":
        setSplitButtonActionConfirmation({
          show: true,
          confirmText: "OK",
          cancelText: "Cancel",
          title: "Are you sure you want to delete this contact?",
          subtitle: "",
          action: optionSelected,
        });
        return;
    }
  };

  const cancelConfirmation = () => {
    setSplitButtonActionConfirmation((prev) => ({
      ...prev,
      show: false,
      title: "",
      subtitle: "",
      action: "",
    }));
  };

  const onConfirmationSuccess = async () => {
    setLoadingContactList(true);
    cancelConfirmation();
    switch (splitActionConfirmation.action) {
      case "btn_resend_email":
        try {
          await emailContacts([selectedContactId]);
          setLoadingContactList(false);
          informationAlert(EMAIL_SENT_ALERT, "success");
        } catch (exception) {
          setLoadingContactList(false);
          informationAlert(GENERIC_ERROR_MESSAGE, "error");
        }
        return;
      case "btn_delete_contact":
        try {
          await deleteContact(selectedContactId);
          hidePanel();
          resetContactConfig();
          setLoadingContactList(false);
          informationAlert(GENERIC_CHANGED_INFO_SUCCESS_MESSAGE, "success");
          reloadContactList();
        } catch (exception) {
          setLoadingContactList(false);
          informationAlert(GENERIC_ERROR_MESSAGE, "error");
        }
        return;
    }
  };

  return (
    <Fragment key="right">
      <InformationPanel
        id="contact_drawer"
        open={isPanelVisible["right"]}
        onClose={handleOnClose}
        expanded={isPanelVisible.expand}
      >
        <Box>
          <HorizontalBox>
            <Title id="title">
              {title} <ImgIcon icon={IconRightArrow} />
            </Title>
            <PanelActionBox>
              <PanelActionBox
                onClick={() => {
                  setPanelVisible({
                    ...isPanelVisible,
                    expand: !isPanelVisible.expand,
                  });
                }}
              >
                {isPanelVisible.expand ? (
                  <>
                    <ImgIcon icon={MinimizeIcon} />
                    <IconText>Minimize</IconText>{" "}
                  </>
                ) : (
                  <>
                    <ImgIcon icon={ExpandIcon} />
                    <IconText>Expand</IconText>{" "}
                  </>
                )}
              </PanelActionBox>
              <CloseIcon
                id="btn_contact_detail_close"
                color="primary"
                onClick={handleOnClose}
              />
            </PanelActionBox>
          </HorizontalBox>
          <Box>
            <TabsPanel
              id={"contact_detail_panel_tabs"}
              value={activeTabIndex}
              handleChange={handleTabChange}
              options={[
                {
                  label: "Details",
                  disableCheck: false,
                },
                {
                  label: "Permissions",
                  disableCheck: !isFormValid,
                },
              ]}
            />
          </Box>
        </Box>

        <TabContent index={0} value={activeTabIndex}>
          <ContactDetails
            entityTypeOptions={entityTypeOptions}
            useContactInput={useInputContactDetail}
            valuesContactDetail={
              contactDetail?.contactDetails
                ? contactDetail.contactDetails
                : valuesContactDetail
            }
            useContactAddressInput={useInputContactAddressInput}
            countryList={countryList}
            useInputSelectContactAddress={useInputSelectContactAddress}
            addressValues={
              contactDetail?.address
                ? contactDetail.address
                : valuesContactAddress
            }
            emailError={emailError}
            setEmailError={setEmailError}
            handleOnSplitButtonClick={handleOnSplitButtonClick}
            onApprove={onApprove}
            onDecline={onDecline}
            investorFundRoles={contact?.investorFundRoles}
          />
        </TabContent>

        <TabContent index={1} value={activeTabIndex} height="81%">
          <PermissionsBox>
            <ContactPermissions
              preloadedContactId={selectedContactId}
              setLoadingContactInPanel={setLoadingPermissions}
              investorFilterList={investorFilters}
              primaryInvestors={primaryInvestors}
              setDataUpdate={setDataUpdate}
            />
          </PermissionsBox>
        </TabContent>

        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
          stickToRight
        >
          <ActionButtonBox>
            <Button
              id={"btn_contact_detail_panel_cancel"}
              variant="outlined"
              onClick={handleOnClose}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />

            <Button
              id={"btn_contact_detail_panel_save"}
              variant="contained"
              onClick={handleOnPermissionOrSave}
              text={
                permissionsByInvestorList.length === 0 && activeTabIndex === 0
                  ? "Continue to Permissions"
                  : "Save"
              }
              color={"primary"}
              fullWidth
              addSpaceBetweenButtons
              disabled={
                (activeTabIndex === 0 && !isFormValid) ||
                ((activeTabIndex === 1 && permissionsByInvestorList.length === 0) ||
                  (activeTabIndex === 1 && isSaveDisabled))
              }
            />
          </ActionButtonBox>
        </HorizontalBox>

        <StyledBackdrop
          open={
            isLoadingContact ||
            isSubmittingContact ||
            isLoadingPermissions ||
            isLoadingImpersonation
          }
        />
        <ProgressPanel
          id={"progress_contact_panel"}
          text="Loading"
          showProgress={
            isLoadingContact ||
            isSubmittingContact ||
            isLoadingPermissions ||
            isLoadingImpersonation
          }
        />
      </InformationPanel>

      {/* Contact Create/Edit Leave confirmation  */}
      <FullScreenModal
        id="dialog_contact_panel_leave_confirmation"
        open={showLeaveConfirmation}
        title={`You have unsaved changes`}
        subtitle={UNSAVED_CHANGES_SUBTITLE}
        confirmButtonText={TEXT_SAVE_CHANGES}
        cancelButtonText={TEXT_DISCARD_CHANGES}
        onCancel={handleOnDiscard}
        onSuccess={handleOnSave}
      />

      <FullScreenModal
        id="dialog_contact_panel_leave_confirmation_no_save"
        open={showLeaveNoSaveConfirmation}
        title={`You have unsaved changes`}
        subtitle={UNABLE_TO_SAVE_SUBTITLE}
        confirmButtonText={TEXT_CONFIRM_OK}
        cancelButtonText={TEXT_DISCARD_CHANGES}
        onCancel={handleOnDiscard}
        onSuccess={handleOnOkay}
      />

      <FullScreenModal
        id="dialog_contact_panel_split_action_confirmation"
        open={
          splitActionConfirmation.show &&
          !["btn_delete_contact"].includes(splitActionConfirmation.action)
        }
        title={splitActionConfirmation.title}
        subtitle={splitActionConfirmation.subtitle}
        confirmButtonText={splitActionConfirmation.confirmText}
        cancelButtonText={splitActionConfirmation.cancelText}
        onCancel={cancelConfirmation}
        onSuccess={onConfirmationSuccess}
      />

      <DeleteConfirmationDialog
        open={splitActionConfirmation.action === "btn_delete_contact"}
        id="delete_confirmation_modal"
        primaryButtonAction={onConfirmationSuccess}
        secondaryButtonAction={cancelConfirmation}
        content="This action is permanent and cannot be undone"
      />

      <FullScreenModal
        id="dialog_save_contact_confirmation"
        open={showSaveConfirmation}
        setOpen={setShowLeaveConfirmation}
        title={""}
        subtitle={
          isBasicUser
            ? getSaveConfirmationModalTextForClientPortal(
              preAuthClientConfigs?.supportEmail ?? ""
            )
            : SAVE_CONTACT_CONFIRMATION_TEXT
        }
        confirmButtonText={TEXT_CONFIRM_OK}
        cancelButtonText={TEXT_CANCEL}
        onSuccess={handleOnSave}
        onCancel={() => setShowSaveConfirmation(false)}
      />
    </Fragment>
  );
};

export default ContactInfoPanel;
