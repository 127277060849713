import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

import { getNoticeUrl } from "../../../../services/documents.service";

type Props = {
  file?: FileList;
  document?: { fileId: string; name: string; size: number };
};
export const useFileDisplay = ({ file, document }: Props) => {
  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState<string>("");
  const [pdfLink, setPdfLink] = useState<string>("");

  const calculateFileSize = (size: number) => {
    if (size < 1000000) {
      return Math.floor(size / 1000) + "KB";
    } else {
      return Math.floor(size / 1000000) + "MB";
    }
  };

  const displayFileSize = async () => {
    if (file) {
      setFileSize(calculateFileSize(file[0].size));
    }
    if (document) {
      setFileSize(calculateFileSize(document.size));
      const res = await getNoticeUrl(document.fileId);

      setPdfLink(res[document.fileId]);
    }
  };

  const displayFileName = () => {
    if (file) {
      setFileName(file[0].name);
    }
    if (document) {
      setFileName(document.name);
    }
  };

  const downloadFile = () => {
    if (file) {
      saveAs(file[0]);
    } else {
      saveAs(pdfLink);
    }
  };

  useEffect(() => {
    displayFileName();
    displayFileSize();
  }, [file, document]);

  return {
    fileName,
    fileSize,
    pdfLink,
    downloadFile,
  };
};
