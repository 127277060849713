import {
  GridColumnOrderChangeParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DeleteIcon from '../../../assets/images/icons/icon_delete.svg';
import { AppContext } from '../../../core/context/appContextProvider';
import { getBankRules, getBanks } from '../../../services/bank.service';
import { saveColumnOrder } from '../../../services/columnOrder.service';
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from '../../../utils/helpers/columnOrder.helper';
import {
  BankRule,
  BankRuleFilter,
  BankRulesAction,
  SelectedBankRule,
} from '../../../utils/types/bank.type';
import { AddNewButtonOptions } from '../../../utils/types/common.type';
import { DetailsType } from '../../../utils/types/form.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../utils/types/listItems';
import { defaultHeaderList } from './BankRuleList.defaults';

const BANK_RULE_VIEW_KEY = 'TO_BE_MADE'; //CHECK WITH BACKEND

const initialBankRule: SelectedBankRule = {
  bankRule: undefined,
  type: undefined,
};

export const useBankRulesList = () => {
  const { state, informationAlert } = useContext(AppContext);
  const { fundId } = useParams<{ fundId: string }>();
  const clientId = state.loginUser.clientId;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerList, setHeaderList] =
    useState<Array<DataGridColDef>>(defaultHeaderList);
  const [bankRulesSelectionModel, setBankRulesSelectionModel] = useState<
    string[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] =
    useState<boolean>(false);
  const [selectedBankRule, setSelectedBankRule] = useState<SelectedBankRule>();
  const [bankRulesResponse, setBankRulesResponse] = useState<BankRule[]>([]);
  const [bankRulesList, setBankRulesList] = useState<BankRule[]>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [rulesNameList, setRulesNameList] = useState<any[]>([]); //TBD type
  const [bankList, setBankList] = useState<any[]>([]); //TBD type
  const [appliesToList, SetAppliesToList] = useState<any[]>([]); //TBD type
  const [statusList, setStatusList] = useState<any[]>([]); //TBD type

  useEffect(() => {
    initializeHeaderList();
  }, []);

  const fetchAllBankRules = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const allBankRulesResponse = await getBankRules(fundId);

      if (isCanceled?.()) return;

      setBankRulesResponse(allBankRulesResponse);
    } catch (e) {
      informationAlert('error getting rules', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllBanks = async (isCanceled?: () => boolean) => {
    const bankList: any = []; //TBD type

    try {
      setIsLoading(true);
      const allBanksResponse = await getBanks(fundId);

      if (isCanceled?.()) return;

      bankList.push(allBanksResponse.items);

      setBankList(bankList);
    } catch (e) {
      informationAlert('error getting bank list', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectedRulesNameList = useMemo(
    () => rulesNameList?.map((item) => item.id),
    [rulesNameList]
  );

  const selectedBankList = useMemo(
    () => bankList?.map((item) => item.id),
    [bankList]
  );

  const selectedAppliesToList = useMemo(
    () => appliesToList?.map((item) => item.id),
    [appliesToList]
  );

  const selectedStatusList = useMemo(
    () => statusList?.map((item) => item.id),
    [statusList]
  );

  const initializeHeaderList = () => {
    const updatedHeaders: DataGridColDef[] = [
      {
        field: 'order',
        headerName: 'Order',
        hide: false,
        index: 1,
        type: 'string',
        sortable: false,
        flex: 1,
        maxWidth: 100,
      },
      {
        field: 'ruleName',
        headerName: 'Rule Name',
        hide: false,
        index: 2,
        type: 'string',
        sortable: false,
        inlineFilter: true,
        inlineFilterName: BankRuleFilter.RuleName,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'ruleName',
        inlineFilterOptions: rulesNameList,
        inlineFilterSelected: selectedRulesNameList,
        flex: 2,
      },
      {
        field: 'bank',
        headerName: 'Bank',
        hide: false,
        index: 3,
        type: 'string',
        sortable: false,
        inlineFilter: true,
        inlineFilterName: BankRuleFilter.Bank,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'bank',
        inlineFilterOptions: bankList,
        inlineFilterSelected: selectedBankList,
        flex: 2,
      },
      {
        field: 'appliesTo',
        headerName: 'Applies To',
        hide: false,
        index: 4,
        type: 'string',
        sortable: false,
        inlineFilter: true,
        inlineFilterName: BankRuleFilter.AppliedTo,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'appliesTo',
        inlineFilterOptions: appliesToList,
        inlineFilterSelected: selectedAppliesToList,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        hide: false,
        index: 5,
        type: 'string',
        sortable: false,
        inlineFilter: true,
        inlineFilterName: BankRuleFilter.Status,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'status',
        inlineFilterOptions: statusList,
        inlineFilterSelected: selectedStatusList,
        flex: 1,
      },
      {
        field: 'action',
        headerName: '',
        hide: false,
        hideable: false,
        index: 6,
        type: 'action',
        sortable: false,
        filterable: false,
        flex: 1,
        maxWidth: 100,
        customType: CustomType.Action,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
      },
    ];

    setHeaderList(updatedHeaders);
  };

  const initializeSearchOptions = (response: any[]) => {
    //TBD type
    const options: string[] = [];

    const uniqueOptions = options.filter(
      (option, idx) => options.indexOf(option) === idx
    );

    setSearchOptions(uniqueOptions);
  };

  const handleFilter = (filterName: BankRuleFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }
        return header;
      })
    );
  };

  const handleSearch = (
    event: any, //TBD type
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearchText(newValue);
    } else if (newValue === null) {
      setSearchText('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    //TBD type
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedBankRule({
            bankRule: undefined,
            type: DetailsType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            //setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate: {
        //handleTemplateDownload();
      }
    }
  };

  const handleOnView = (bankRuleId: string, bankRule: BankRule) => {
    setSelectedBankRule({
      bankRule,
      type: DetailsType.Edit,
    });
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      BANK_RULE_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      BANK_RULE_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const bulkActionOptions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: BankRulesAction.ExportSelected,
      text: `Export Selected (${bankRulesSelectionModel?.length || 0})`,
      icon: <img src={DeleteIcon} alt="Delete Selected" height="15" />,
      optionsSelected: 0,
    };

    if (bankRulesSelectionModel?.length > 0) {
      actions.push(exportSelected);
    }

    return actions;
  }, [bankRulesSelectionModel]);

  const handleBulkAction = (actionId: BankRulesAction) => {
    const list: any[] = []; //TBD type
    //let emptyRequiredFieldsError: boolean = false;

    bankRulesSelectionModel.map((bankRuleId) => {
      list.push(bankRulesList.find((rule) => rule.id === bankRuleId));
    });

    switch (actionId) {
      case BankRulesAction.ExportSelected:
        //handleExport()
        break;
      case BankRulesAction.InactivateSelected:
        //handleInactivate()
        break;
      case BankRulesAction.ActivateSelected:
        //handleActivate()
        break;
    }
  };

  const updateRowPosition = (
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>
  ): Promise<any> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];

        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // simulate network latency
      //need to make sure the actual value for "ORDER" is changed
    });
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setIsLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      bankRulesList
    );

    setBankRulesList(newRows);
    setIsLoading(false);
  };

  const onDetailsClose = () => {
    setSelectedBankRule(initialBankRule);
  };

  return {
    activeHeaderFields,
    bankList,
    bankRulesList,
    //bankRulesOptions,
    bankRulesSelectionModel,
    bulkActionOptions,
    fetchAllBankRules,
    fundId,
    handleBulkAction,
    handleFilter,
    handleNewButtonAction,
    handleOnView,
    handleRowOrderChange,
    handleSearch,
    handleUpdateHeader,
    headerList,
    isLoading,
    onColumnOrderChange,
    onDetailsClose,
    searchOptions,
    searchText,
    selectedBankRule,
    setBankRulesSelectionModel,
    setShowSuggestionPopover,
    showSuggestionPopover,
  };
};
