import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import PendingChangesView from "../../../../components/PendingChangesView/PendingChangesView";
import {
  BeneficiaryBankInfoMapping,
  BeneficiaryInformationMapping,
  IntermediaryBankInfoMapping,
  InvestorDetailsMapping,
  ReceivingBankInfoMapping,
} from "../../constant";
import {
  InfoLine,
  ItalicLabel,
  ListContainer,
  PendingInfoDot,
} from "./InvestorPendingView.styles";

type ChangedItem = { label: string; changedKeys: string[] };

const CurrentChange = (leftSectionData: any) => {
  const { currentData, item } = leftSectionData;

  return (
    <div style={{ width: "320px" }}>
      <ItalicLabel>Current</ItalicLabel>
      <span>
        {item?.changedKeys?.map((key: any) => (
          <React.Fragment key={key}>
            <Typography>{currentData?.[key]}</Typography>
          </React.Fragment>
        ))}
      </span>
    </div>
  );
};

const PendingChange = (rightSectionData: any) => {
  const { pendingData, item } = rightSectionData;

  return (
    <div>
      <ItalicLabel>Pending</ItalicLabel>
      <Typography style={{ display: "flex" }}>
        <div>
          <PendingInfoDot />
        </div>
        <div>
          <span>
            {item?.changedKeys?.map((key: any) => (
              <React.Fragment key={key}>
                <Typography>{pendingData?.[key]}</Typography>
              </React.Fragment>
            ))}
          </span>
        </div>
      </Typography>
    </div>
  );
};

const InvestorPendingView = ({
  investor,
  approvePendingChanges,
  declinePendingChanges,
}: any) => {
  const [count, setCount] = useState<number>(0);
  const [investorInfo, setInvestorInfo] = useState([]);
  const [receivingBankInfo, setReceivingBankInfo] = useState([]);
  const [beneficiaryBankInfo, setBeneficiaryBankInfo] = useState([]);
  const [beneficiaryInfo, setBeneficiaryInfo] = useState([]);
  const [intermediaryBankInfo, setIntermediaryBankInfo] = useState([]);

  const pendingWireInfo = investor.pendingWireInfo;
  const currentWireInfo = investor.wireInfo;

  const pendingReceivingBankInfo = pendingWireInfo?.receivingBankInfo;
  const currentReceivingBankInfo = currentWireInfo?.receivingBankInfo;

  const pendingBeneficiaryBankInfo = pendingWireInfo?.beneficiaryBankInfo;
  const currentBeneficiaryBankInfo = currentWireInfo?.beneficiaryBankInfo;

  const pendingIntermediaryBankInfo = pendingWireInfo?.intermediaryBankInfo;
  const currentIntermediaryBankInfo = currentWireInfo?.intermediaryBankInfo;

  let pendingInvestorInfo: any;
  let currentInvestorInfo: any;

  if (investor.pendingInvestorChanges) {
    pendingInvestorInfo = investor.pendingInvestorChanges;
    currentInvestorInfo = {
      ownerName: investor.ownerName,
      ownerSSN: investor.ownerSSN,
    };
  }

  const isValueChanged = (newData: any, oldData: any, filterableKeys: any) => {
    let keys: any = [];

    if (newData && oldData) {
      keys = filterableKeys.filter((key: string) => {
        if (key !== "id" && key !== "graphId") {
          return oldData[key] !== newData[key];
        }
      });
    }
    return keys;
  };

  useEffect(() => {
    let count = 0;
    let investorInfo: any = [],
      receivingInfo: any = [],
      beneficiaryBankInfo: any = [],
      beneficiaryInfo: any = [],
      intermediaryInfo: any = [];

    if (investor.pendingInvestorChanges) {
      InvestorDetailsMapping.map((changedItem: any) => {
        const keys = isValueChanged(
          pendingInvestorInfo,
          currentInvestorInfo,
          changedItem.keys
        );

        if (keys.length > 0) {
          investorInfo = [
            ...investorInfo,
            { label: changedItem.label, changedKeys: keys },
          ];
        }

        count = count + keys.length;
      });
    }

    ReceivingBankInfoMapping.map((changedItem: any) => {
      const keys = isValueChanged(
        pendingReceivingBankInfo,
        currentReceivingBankInfo,
        changedItem.keys
      );

      if (keys.length > 0) {
        receivingInfo = [
          ...receivingInfo,
          { label: changedItem.label, changedKeys: keys },
        ];
      }

      count = count + keys.length;
    });

    BeneficiaryBankInfoMapping.map((changedItem: any) => {
      const keys = isValueChanged(
        pendingBeneficiaryBankInfo,
        currentBeneficiaryBankInfo,
        changedItem.keys
      );

      if (keys.length > 0) {
        beneficiaryBankInfo = [
          ...beneficiaryBankInfo,
          { label: changedItem.label, changedKeys: keys },
        ];
      }

      count = count + keys.length;
    });

    BeneficiaryInformationMapping.map((changedItem: any) => {
      const keys = isValueChanged(
        pendingWireInfo,
        currentWireInfo,
        changedItem.keys
      );

      if (keys.length > 0) {
        beneficiaryInfo = [
          ...beneficiaryInfo,
          { label: changedItem.label, changedKeys: keys },
        ];
      }

      count = count + keys.length;
    });

    IntermediaryBankInfoMapping.map((changedItem: any) => {
      const keys = isValueChanged(
        pendingIntermediaryBankInfo,
        currentIntermediaryBankInfo,
        changedItem.keys
      );

      if (keys.length > 0) {
        intermediaryInfo = [
          ...intermediaryInfo,
          { label: changedItem.label, changedKeys: keys },
        ];
      }

      count = count + keys.length;
    });

    setCount(count);

    if (investor.pendingInvestorChanges) {
      setInvestorInfo(investorInfo);
    }
    setReceivingBankInfo(receivingInfo);
    setBeneficiaryBankInfo(beneficiaryBankInfo);
    setBeneficiaryInfo(beneficiaryInfo);
    setIntermediaryBankInfo(intermediaryInfo);
  }, [investor]);

  const RenderChangedItem = (type: string) => {
    let changedItems: ChangedItem[], currentData: any, pendingData: any;

    switch (type) {
      case "Investor Details":
        changedItems = investorInfo;
        currentData = currentInvestorInfo;
        pendingData = pendingInvestorInfo;
        break;
      case "Receiving Bank Info":
        changedItems = receivingBankInfo;
        currentData = currentReceivingBankInfo;
        pendingData = pendingReceivingBankInfo;
        break;
      case "Beneficiary Bank Info":
        changedItems = beneficiaryBankInfo;
        currentData = currentBeneficiaryBankInfo;
        pendingData = pendingBeneficiaryBankInfo;
        break;
      case "Intermediary Info":
        changedItems = intermediaryBankInfo;
        currentData = currentIntermediaryBankInfo;
        pendingData = pendingIntermediaryBankInfo;
        break;
      default:
        changedItems = beneficiaryInfo;
        currentData = currentWireInfo;
        pendingData = pendingWireInfo;
        break;
    }

    return (
      <>
        {changedItems?.length > 0 &&
          changedItems.map((item: ChangedItem) => {
            return (
              <React.Fragment key={item.label}>
                <div key={item.label}>
                  <InfoLine>
                    <Typography variant="label">{item.label}</Typography>
                    <div style={{ display: "flex" }}>
                      {CurrentChange({ currentData, item })}
                      {PendingChange({ pendingData, item })}
                    </div>
                  </InfoLine>
                </div>
              </React.Fragment>
            );
          })}
      </>
    );
  };

  return (
    <>
      <PendingChangesView
        declineChanges={declinePendingChanges}
        approveChanges={approvePendingChanges}
        title={`Pending Changes ${count ? `(${count})` : ""}`}
      >
        <ListContainer>
          {investor.pendingInvestorChanges &&
            RenderChangedItem("Investor Details")}
          {RenderChangedItem("Receiving Bank Info")}
          {RenderChangedItem("Beneficiary Bank Info")}
          {RenderChangedItem("Beneficiary Info")}
          {RenderChangedItem("Intermediary Info")}
        </ListContainer>
      </PendingChangesView>
    </>
  );
};

export default InvestorPendingView;
