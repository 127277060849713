import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const uploadFile = async (
  uploadType: string,
  file: File,
  options?: { [key: string]: any }
) => {
  const formData = new FormData();

  if (uploadType !== "capitalcalls" && uploadType !== "distributions") {
    formData.append("name", "file");
    formData.append("filename", file.name);
    formData.append("Content-Type", "text/csv");
  }
  formData.append("file", file);
  let endpoint;

  switch (uploadType) {
    case "contacts":
    case "investors":
    case "transactions":
      endpoint = `uploads/${uploadType}`;
      break;
    case "Investment_transaction":
      endpoint = `/v2/transactions/soi/upload?type=INVESTMENT_TRANSACTION`;
      break;
    case "capitalcalls":
      endpoint = `transactions/files`;
      break;
    case "distributions":
      endpoint = `transactions/files`;
      break;
    case "journal-entries":
      endpoint = `v2/ark-ledger/journal-entries/upload`;
      break;
    case "accounts":
      endpoint = `ark-ledger/accounts/templates/template/${options?.fundId}/accounts?saveAccounts=true`;
      break;
    case "entities":
      endpoint = `v2/entities/uploads/general-entities`;
      break;
    case "funds":
    default:
      endpoint = `v2/entities/uploads/${uploadType}`;
  }

  const uploadFileResponse = await apiClient()
    .post(endpoint, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });

  return uploadFileResponse;
};
