import {
  Box,
  Button,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { StyledBackdrop } from "../../../../components/DetailPanel/DetailPanel.styles";
import { RouterLink } from "../../../../components/Link/RouterLink/RouterLink";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import RoutingPaths from "../../../../core/routing/routingPaths";
import { POST_ACTION_TYPE } from "../../../../utils/constants/common.constants";
import { useJournalEntryPostDialog } from "./journalEntryPostDialog.hooks";
import {
  Actions,
  ContentText,
  Dialog,
  InputCaption,
  InputWrapper,
  Title,
} from "./journalEntryPostDialog.styles";

interface Props {
  open: boolean;
  onPostCancel: Function;
  onPostComplete: Function;
  ids: string[];
  postAction?: POST_ACTION_TYPE;
  postFiltersRequest?: any;
  onClose?: () => void;
}

const JournalEntryPostDialog = ({
  open,
  onClose,
  onPostCancel,
  onPostComplete,
  postAction,
  postFiltersRequest,
  ids,
}: Props) => {
  const { fundId } = useParams<{ fundId: string }>();
  const {
    isPosting,
    inputValue,
    setInputValue,
    actionType,
    glError,
    accountError,
    setGlError,
    setAccountError,
    handlePost,
    handlePostGL,
    handlePostAccounts,
  } = useJournalEntryPostDialog({
    ids,
    onPostComplete,
    postAction,
    postFiltersRequest,
  });

  let confirmCount: string;
  let actionText: string;

  if (postAction === POST_ACTION_TYPE.POST_ALL) {
    confirmCount = "all journal entries";

    actionText = "Post All Journal Entries";
  } else {
    actionText = actionType;
    confirmCount =
      ids.length === 1
        ? `${ids.length} journal entry`
        : `${ids.length} journal entries`;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      id="dialog_post_confirmation"
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        elevation: 0,
      }}
    >
      <Title>Post to GL</Title>
      <DialogContent>
        <ContentText>
          Journal Entries are mutable while in the Draft status, and become
          immutable in the Ark GL once they are posted.{" "}
        </ContentText>
        <ContentText>
          This posting action is permanent and cannot be undone. Any changes to
          the entries will require an adjusting entry.
        </ContentText>
        {glError && (
          <>
            <ContentText>
              <strong>Error:</strong> General Ledgers are in a DRAFT status, and
              must be POSTED prior to Journal Entry post. Posting a GL will make
              the GL instance immutable. At that point, no changes can be made
              to{" "}
              {fundId ? (
                <RouterLink
                  to={{
                    pathname: `${RoutingPaths.FundGLSetup}/${fundId}`,
                    search: "",
                  }}
                >
                  GL Details
                </RouterLink>
              ) : (
                <RouterLink
                  to={{
                    pathname: `${RoutingPaths.GLSetup}`,
                    search: "",
                  }}
                >
                  GL Details
                </RouterLink>
              )}
              .
            </ContentText>
            <Button
              id="btn_post_gl"
              variant="contained"
              fullWidth
              onClick={handlePostGL}
            >
              Post GL
            </Button>
          </>
        )}
        {accountError && (
          <>
            <ContentText>
              <strong>Error:</strong> Accounts are in a DRAFT status, and must
              be POSTED prior to Journal Entry post. Posting Accounts will make
              the Account instance immutable. At that point, no changes can be
              made to{" "}
              {fundId ? (
                <RouterLink
                  to={{
                    pathname: `${RoutingPaths.FundGLAccounts}/${fundId}`,
                    search: "",
                  }}
                >
                  Account Details
                </RouterLink>
              ) : (
                <RouterLink
                  to={{
                    pathname: `${RoutingPaths.FundList}`,
                    search: "",
                  }}
                >
                  Account Details
                </RouterLink>
              )}
              .
            </ContentText>
            <Button
              id="btn_post_accounts"
              variant="contained"
              fullWidth
              onClick={handlePostAccounts}
            >
              Post Accounts
            </Button>
          </>
        )}
        {!glError && !accountError && (
          <InputWrapper>
            <InputCaption variant="caption" component="p">
              To confirm the posting of {confirmCount}, type{" "}
              <strong>{actionText}</strong> below.
            </InputCaption>
            <TextField
              id="txt_delete_confirmation"
              size="small"
              fullWidth
              aria-describedby="Confirmation Text"
              label="Confirmation Text"
              placeholder="Confirmation Text"
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </InputWrapper>
        )}
      </DialogContent>
      <Box display="flex" justifyContent="center">
        <Actions disableSpacing={true}>
          <Button
            id="btn_post_journal_entry"
            variant="contained"
            fullWidth
            onClick={() => {
              if (inputValue === actionText) {
                setInputValue("");
                setGlError(false);
                setAccountError(false);
                handlePost();
              }
            }}
            disabled={inputValue !== actionText}
          >
            Post Journal Entries
          </Button>
          <Button
            id="btn_cancel_post_journal_entry"
            variant="outlined"
            fullWidth
            onClick={() => {
              setInputValue("");
              setGlError(false);
              setAccountError(false);
              onPostCancel();
            }}
          >
            Cancel
          </Button>
        </Actions>
      </Box>
      <StyledBackdrop open={Boolean(isPosting)} />
      <ProgressPanel
        id={"progress_je_post_dialog"}
        showProgress={Boolean(isPosting)}
        text={isPosting ? isPosting : ""}
      />
    </Dialog>
  );
};

export default JournalEntryPostDialog;
