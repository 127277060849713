import { PageInfo } from "./common.type";
import { FundSimple } from "./fund.type";
import { User } from "./user.type";

export type TeamDetailInfo = {
    id: string;
    name?: string;
    clientId: string;
    clientName: string;
    clientDefault: boolean;
    funds: [{ id: string, name: string }];
    teamMembers: [{ id: string, email: string, location: string, name: string, phone: string, role: string }];
}

export type TeamUpsertData = {
    id?: string | null;
    name: string;
    funds: FundSimple[] | [];
}

export type TeamMemberData = {
    teamId: string;
    employee?: { id: string, name?: string, isFundAdmin: boolean } | null;
    role: string;
    isFundAdmin: boolean;
}

export enum TeamFilter {
    FundName = 'fundName',
}

export enum TeamType {
    New = 'new',
    Edit = 'edit',
}

export type SelectedTeam = {
    team?: TeamUpsertData;
    type?: TeamType;
}

export type TeamIDObject = {
    id: string;
}

export type TeamMembers = {
    team: TeamIDObject;
    role: string;
}

export type MemberRole = {
    id: string;
    name: string;
    description?: string;
    permissions: Array<any>;
}

export type SelectedTeamMember = {
    id: string;
    firstName: string;
    lastName?: string;
    email: string;
    phone?: string;
    location?: string;
    arkClientTag: string;
    role?: MemberRole;
    status: string;
    teamMembers: Array<TeamMembers>;
}

export enum TeamCRUDStatus {
    Adding = 'Adding',
    Updating = 'Updating',
    Deleting = 'Deleting',
}

export type TeamParams = {
    id?: string | null;
    name: string;
    clientId: string;
    funds?: FundSimple[]
}

export interface Team {
    "clientId"?: string | null;
    "clientName"?: string,
    "arkClientTag"?: string,
    "teamId"?: string,
    "teamName"?: string,
}

export interface TeamsList {
    pageInfo?: PageInfo;
    items: Team[];
}

export interface Role {
    key: string;
    title: string;
}

export interface RolesList {
    pageInfo?: PageInfo;
    items: Role[];
}

export interface TeamInfo {
    id?: string | null;
    name?: string,
    tenantId?: string,
    funds?: FundSimple[]
    employees?: User[],
}

export interface ClientTeamList {
    pageInfo?: PageInfo;
    items?: TeamInfo[];
}

export type TeamCreateUpdateParams = {
    id?: string | null;
    name?: string;
    tenantId?: string;
    funds?: {id: string}[] | [];
}

export type TeamMemberParams = {
    id?: string;
    name?: string;
    teamId: string;
    employeeId?: string;
    role: string;
}
