import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import RoutingPaths from '../../../../core/routing/routingPaths';
import useRole from '../../../../core/routing/useRole';
import {
  DistributionupdateStatus,
  getDistributionReviewSummary,
  updateDistributionsStatus,
} from '../../../../services/distributions.service';
import { useClientEffect } from '../../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import { useDistributionDetailsEffect } from '../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { SelectedJournalEntry } from '../../../../utils/types/arkGLJournalEntry.type';
import { DistributionReviewsSummary } from '../../../../utils/types/distributions.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import {
  DISTRIBUTION_DOCUMENT_GET_ERROR,
  DISTRIBUTION_REVIEW_DOCUMENT_SUCCESS,
  DISTRIBUTION_REVIEW_SUMMARY_GET_ERROR,
  DISTRIBUTION_UPDATE_STATUS_ERROR,
} from '../Constants';
import { DistributionReviewersList } from '../Constants';

type RouteProp = {
  id: string;
};

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

export const UsePublish = () => {
  const [reviewSummary, setReviewSummary] =
    useState<DistributionReviewsSummary>();
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [confirmPublish, setConfirmPublish] = useState<boolean>(false);
  const [toPublish, setToPublish] = useState<boolean>(false);
  const [toReview, setToReview] = useState<boolean>(false);
  const params = useParams<RouteProp>();
  const { informationAlert, state } = useContext(AppContext);
  const { distributions } = useDistributionDetailsEffect(params?.id);
  const [formattedReviewSummary, setFormattedReviewSummary] = useState<any>();
  const [usernames, setUsernames] = useState<string[]>([]);
  const [sendToGL, setSendToGL] = useState<boolean>(false);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();
  const clientId = state.loginUser.currentUser?.clientId;
  const { client } = useClientEffect(clientId !== 'new' ? clientId : undefined);
  const arkGlLocked = client?.arkGlLocked;

  const history = useHistory();
  const headerTitles = useMemo(() => {
    const headers =
      reviewSummary?.reviews[0].approvals.map((approval) => approval.role) ||
      [];
    const filteredHeaders = headers.filter((header, index) => {
      return headers.indexOf(header) === index;
    });

    return filteredHeaders;
  }, [reviewSummary]);
  const dummyTitle: number[] = [1, 2, 3, 4, 5];
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  useEffect(() => {
    getSummaryOfReviews();
  }, [params?.id]);

  useEffect(() => {
    setStatus(distributions?.status);
  }, [distributions]);
  const selectReviewer = (member: string) => {
    switch (member) {
      case DistributionReviewersList.Associate:
        return 'Associate';
      case DistributionReviewersList.Director:
        return 'Director';
      case DistributionReviewersList.FundController:
        return 'Fund Controller';
      case DistributionReviewersList.FundUserReadOnly:
        return 'Fund User Read Only';
      case DistributionReviewersList.Manager:
        return 'Manager';
      default:
        return '';
    }
  };
  const allowPublish = useMemo(() => {
    return usernames.includes(
      state.loginUser.currentUser?.username.toLowerCase() || ''
    );
  }, [usernames]);
  const getSummaryOfReviews = async () => {
    try {
      setIsLoading(LoadingStatus.Loading);
      await getDistributionReviewSummary(params?.id || '').then((data) => {
        setReviewSummary(data);
        let reviewerApprovals = false;
        const createdBy = data?.createdBy;
        let isFlaggedReview = false;
        let tosubmitReview = false;
        const formattedReviews = [];
        const userNameList: string[] = [];

        for (const review of data.reviews) {
          const approvals: any = { investor: review.investor };

          for (const approval of review.approvals) {
            !userNameList.includes(approval?.username?.toLowerCase()) &&
              userNameList.push(approval?.username?.toLowerCase());
            approvals[approval.role] = approvals[approval.role]
              ? [
                  ...approvals[approval.role],
                  {
                    id: approval.id,
                    username: approval.username,
                    tenantId: approval.tenantId,
                    approved: approval.approved,
                  },
                ]
              : [
                  {
                    id: approval.id,
                    username: approval.username,
                    tenantId: approval.tenantId,
                    approved: approval.approved,
                  },
                ];
          }

          formattedReviews.push(approvals);
        }

        setFormattedReviewSummary(formattedReviews);
        setUsernames(userNameList);
        data?.reviews?.forEach((review) => {
          const countApprovals = review.approvals.length;
          let count = 0;

          review.approvals.forEach((approval) => {
            if (approval.approved === false) {
              isFlaggedReview = true;

              return;
            }

            if (approval.approved === true) {
              count = count + 1;
            }

            if (approval.approved === true && approval.username === createdBy) {
              tosubmitReview = true;
            }
          });
          if (count === countApprovals) {
            reviewerApprovals = true;
          }
        });

        const publish = !isFlaggedReview && reviewerApprovals;
        const allowsubmitreview = !isFlaggedReview && tosubmitReview;

        setToPublish(publish);
        setToReview(allowsubmitreview);
      });
    } catch (err) {
      informationAlert(DISTRIBUTION_REVIEW_SUMMARY_GET_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };
  const allowEdit = useMemo(() => {
    if (params?.id !== 'new') {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          distributions?.createdBy?.toLowerCase() &&
        distributions?.status?.toLowerCase() !== 'published'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [params.id, distributions, state]);
  const submitReview = async () => {
    try {
      setIsLoading(LoadingStatus.Submitting);
      setStatus('REVIEWING');
      await DistributionupdateStatus(params?.id, 'REVIEWING');
      informationAlert(DISTRIBUTION_REVIEW_DOCUMENT_SUCCESS, 'success');
      setIsLoading(undefined);
      setTimeout(() => {
        history.push(RoutingPaths.Distributions);
      }, 1000);
    } catch (error) {
      informationAlert(DISTRIBUTION_DOCUMENT_GET_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const handlePublishCancel = () => {
    setConfirmPublish(false);
  };

  const handlePublishConfirm = async () => {
    try {
      setIsLoading(LoadingStatus.Publishing);
      setStatus('PUBLISHED');
      setConfirmPublish(false);

      await updateDistributionsStatus(params?.id, 'PUBLISHED');

      setIsLoading(undefined);
      if (sendToGL) {
        setSelectedJournalEntry({
          journalEntry: {
            ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
            fundId: distributions?.fundId,
            lineItems: [
              {
                ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
                entities: [],
                entryType: 'DEBIT',
                amount: distributions?.amount!,
                entryMemo: distributions?.name,
              },
              {
                ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
                entities: [],
                entryType: 'CREDIT',
                amount: distributions?.amount!,
                entryMemo: distributions?.name,
              },
            ],
            date:
              distributions?.transactions && distributions?.transactions.length
                ? DateTimeFormat.ensureAsDate(
                    distributions.transactions[0].date
                  )
                : null,
          },
          type: DetailsType.New,
        });
      } else {
        setTimeout(() => {
          history.push(RoutingPaths.Distributions);
        }, 1000);
      }
    } catch (error) {
      setStatus(distributions?.status);
      informationAlert(DISTRIBUTION_UPDATE_STATUS_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const onJournalEntryPanelClose = () => {
    setSelectedJournalEntry(initialJournalEntry);
    if (distributions && distributions.fundId) {
      history.push(
        `${RoutingPaths.FundGLJournalEntries}/${distributions.fundId}`
      );
    } else {
      history.push(`${RoutingPaths.GLJournalEntries}`);
    }
  };

  return {
    reviewSummary,
    status,
    selectReviewer,
    submitReview,
    confirmPublish,
    isLoading,
    handlePublishCancel,
    handlePublishConfirm,
    toPublish,
    setConfirmPublish,
    isSuperAdminOrClientAdmin,
    toReview,
    allowEdit,
    headerTitles,
    dummyTitle,
    formattedReviewSummary,
    allowPublish,
    sendToGL,
    setSendToGL,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    arkGlLocked,
  };
};
