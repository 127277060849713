import { useEffect, useReducer, useState } from 'react';

import {
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../../pages/arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import { DetailsType } from '../../../utils/types/form.type';

type Props = {
  list: any[];
  setSelectedJournalEntry: Function;
  onClose: Function;
};
export const useMultiJEViewer = ({
  list,
  setSelectedJournalEntry,
  onClose,
}: Props) => {
  const [currentList, setCurrentList] = useState<any[]>(list);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (list.length) {
      setCurrentList(list);
    }
  }, []);

  useEffect(() => {
    if (currentList.length) {
      loadNextJournalEntry(currentIndex);
    }
  }, [currentIndex]);

  const loadNextJournalEntry = (index: number) => {
    setSelectedJournalEntry(undefined);

    const lineItems: any[] = [];

    lineItems.push({
      ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      entities: [...currentList[index].entities],
      entryType: 'CREDIT',
      amount: currentList[index].amountPaid,
    });
    lineItems.push({
      ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      entities: [...currentList[index].entities],
      entryType: 'DEBIT',
      amount: currentList[index].amountPaid,
    });

    setSelectedJournalEntry({
      journalEntry: {
        ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
        fundId: currentList[index].fundId,
        lineItems: lineItems,
        date: currentList[index].receiptDate,
        memo: currentList[index].memo,
      },
      type: DetailsType.New,
      connectCashReceiptToJournalEntry: currentList[index].isOriginalAmount
        ? {
            cashReceiptTransactionIds: [...currentList[index].transactionIds],
          }
        : null,
      connectAdjustmentToJournalEntry: currentList[index].isAdjustmentAmount
        ? {
            cashReceiptTransactionIds: [...currentList[index].transactionIds],
            adjustmentIds: [currentList[index].adjustmentId],
          }
        : null,
    });
  };

  const showPrevious = () => {
    setCurrentIndex(currentIndex - 1);
    setSelectedJournalEntry(undefined);
  };

  const showNext = () => {
    setCurrentIndex(currentIndex + 1);
    setSelectedJournalEntry(undefined);
  };

  const onSave = () => {
    currentList.splice(currentIndex, 1);
    setCurrentList([...currentList]);

    forceUpdate();

    if (currentList.length) {
      loadNextJournalEntry(0);
      setCurrentIndex(0);
    }
  };

  const onSaveAndNext = () => {
    currentList.splice(currentIndex, 1);
    setCurrentList([...currentList]);

    loadNextJournalEntry(0);
    setCurrentIndex(0);
  };

  const closeDrawer = () => {
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    currentIndex,
    setCurrentIndex,
    currentList,
    showPrevious,
    showNext,
    onSaveAndNext,
    onSave,
    showExitConfirmation,
    setShowExitConfirmation,
    closeDrawer,
    keepDrawerOpen,
  };
};
