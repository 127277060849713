import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getCustomPropertyTypeFilterList } from "../../../services/filters.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { CustomPropertyTypeSimple } from "../../../utils/types/portfolioCompany.type";

export const useCustomPropertyTypeFilterEffect = () => {
  const { informationAlert } = useContext(AppContext);
  const [customPropertyTypeList, setCustomPropertyTypeList] = useState<CustomPropertyTypeSimple[]>();
  const [loading, setLoading] = useState(false);

  useEffectAsync(async (isCanceled) => {
    setLoading(true);
    try {
      const response = await getCustomPropertyTypeFilterList();

      if (isCanceled()) return;

      if (response)
        setCustomPropertyTypeList(response);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setLoading(false);
  }, []);

  return {
    customPropertyTypeList,
    loading,
  };
};

export default useCustomPropertyTypeFilterEffect;

