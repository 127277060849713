import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getFundNames } from "../../../services/fund.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FundSimple } from "../../../utils/types/fund.type";

const useFundFilter = () => {
  const [fundsList, setFundsList] = useState<FundSimple[]>();
  const [loading, setLoading] = useState(false);
  const {
    informationAlert
  } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    setLoading(true);
    try {
      const statusResponse = await getFundNames();

      if (isCanceled()) return;

      setFundsList(statusResponse);
    } catch (e) {
      informationAlert("Error in getting funds list", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    fundsList,
    loading,
  };
};

export default useFundFilter;

