import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Accordion, AccordionDetails, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import { StickyAccordionSummary } from '../../pages/fund/fundList/FundList.styles';
import { SoiBaseData } from '../../pages/fund/scheduleOfInvestments/ScheduleOfInvestments.types';
import { ListItem } from '../../utils/types/listItems';
import {
  CapitalAccountSummaryItem,
  FundSummaryItem,
  SoiSummaryItem,
} from '../../utils/types/visualDashboard.type';
import { EntityTypeCounts } from './barChart/EntityTypeCounts';
import { DashboardViewConfig } from './dashboardViewConfig/DashboardViewConfig';
import { DraggableGrid } from './dragAndDropBox/DragAndDropBox';
import { VisibilityButton } from './dragAndDropBox/DragAndDropBox.styles';
import { ViewOption, ViewOptionTypes } from './viewOptionsTabs/ViewOptionsTabs';
import { useVisualDashboard } from './VisualDashboard.hooks';
import {
  ChartItemGrid,
  ChartsGrid,
  ChartWidgetBox,
  EntityTypeChartGrid,
  StyledAccordion,
  StyledAccordionDetails,
  VisualDashboardBox,
  VisualDashboardGrid,
  VisualDashboardItems,
} from './VisualDashboard.style';

export interface PageSummaryItem {
  id: string;
  name: string;
  currencyCode: string;
  percentCalled: number;
  quarterAsOf: string;
  entityType: string;
  [key: string]: string | number | any;
}

export interface PageSummary {
  items:
    | PageSummaryItem[]
    | FundSummaryItem[]
    | CapitalAccountSummaryItem[]
    | SoiSummaryItem[];
  currencyCode: string;
}

export interface VisualDashboardProps {
  pageType: string;
  pageSummary: PageSummary[];
  allItemsSelected: boolean;
  fundEntityTypeList?: ListItem[];
  selectedCurrencyTab?: string;
  setSelectedCurrencyTab?: React.Dispatch<React.SetStateAction<string>>;
  viewState: ViewOptionTypes;
  getDataGrid?: (fund: PageSummary, index: number) => JSX.Element;
}

export const VisualDashboard: FC<VisualDashboardProps> = ({
  pageType,
  pageSummary,
  allItemsSelected,
  fundEntityTypeList,
  selectedCurrencyTab,
  setSelectedCurrencyTab,
  viewState,
  getDataGrid,
}) => {
  if (!pageSummary?.length) return null;
  pageSummary.forEach((summary) => {
    if (summary.items.length === 0) return null;
  });

  const {
    dashboardWidgets,
    handleWidgetVisibilityChange,
    handleWidgetOrderChange,
    isEditingWidgets,
    saveDashboardWidgets,
    setIsEditingWidgets,
    visualizationData,
  } = useVisualDashboard(
    pageType,
    pageSummary,
    allItemsSelected,
    fundEntityTypeList
  );

  const getDashboard = (dataSet: any, index: number) => {
    return (
      <VisualDashboardItems key={index}>
        {dataSet.summaryCards?.some((sc: any) => sc.visible) &&
          viewState !== ViewOption.DATA_VIEW && (
            <VisualDashboardBox>
              <DraggableGrid
                summaryCardOrderItems={dataSet.summaryCards}
                isEditingWidgets={isEditingWidgets}
                setIsEditingWidgets={setIsEditingWidgets}
                dashboardWidgets={dashboardWidgets}
                saveDashboardWidgets={saveDashboardWidgets}
                handleWidgetVisibilityChange={handleWidgetVisibilityChange}
                handleWidgetOrderChange={handleWidgetOrderChange}
              />
            </VisualDashboardBox>
          )}
        {dataSet.typeCountItems &&
          dashboardWidgets.find((widget) => widget.name === 'entityTypeByFund')
            ?.visible && (
            <EntityTypeChartGrid item xs={12} isInEditMode={isEditingWidgets}>
              {isEditingWidgets && (
                <VisibilityButton
                  isVisible={isEditingWidgets}
                  onClick={() => {
                    handleWidgetVisibilityChange('entityTypeByFund', false);
                  }}
                >
                  <RemoveOutlinedIcon />
                </VisibilityButton>
              )}
              <EntityTypeCounts
                title="Entity Type by Fund"
                typeCountItems={dataSet.typeCountItems.typeCountItems}
              />
            </EntityTypeChartGrid>
          )}

        {!!dataSet.charts?.length && (
          <ChartsGrid>
            {dataSet.charts.map((chart: any, index: number) => {
              if (chart.visible && chart.display) {
                return (
                  <ChartItemGrid item key={index} chartType={chart.name}>
                    <ChartWidgetBox isInEditMode={isEditingWidgets}>
                      {isEditingWidgets && (
                        <VisibilityButton
                          isVisible={isEditingWidgets}
                          onClick={() => {
                            handleWidgetVisibilityChange(chart.name, false);
                          }}
                        >
                          <RemoveOutlinedIcon />
                        </VisibilityButton>
                      )}
                      {chart.chart}
                    </ChartWidgetBox>
                  </ChartItemGrid>
                );
              }
            })}
          </ChartsGrid>
        )}
      </VisualDashboardItems>
    );
  };

  return (
    <VisualDashboardGrid>
      {viewState !== ViewOption.DATA_VIEW && (
        <VisualDashboardBox>
          <DashboardViewConfig
            isInEditMode={isEditingWidgets}
            setIsEditingWidgets={setIsEditingWidgets}
            dashboardWidgets={dashboardWidgets}
            saveDashboardWidgets={saveDashboardWidgets}
            editedWidgets={dashboardWidgets}
            handleWidgetVisibilityChange={handleWidgetVisibilityChange}
          />
        </VisualDashboardBox>
      )}
      {visualizationData.length > 1 ? (
        <>
          {visualizationData.map((dataSet: any, index: number) => {
            return (
              <StyledAccordion defaultExpanded key={index}>
                <StickyAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id={`${dataSet.currencyCode}_currency_accordion_header`}
                >
                  <Typography>{`${dataSet.currencyCode}`}</Typography>
                </StickyAccordionSummary>
                <StyledAccordionDetails>
                  {viewState !== ViewOption.DATA_VIEW && (
                    <>{getDashboard(dataSet, index)}</>
                  )}
                  {viewState !== ViewOption.DASHBOARD &&
                    getDataGrid &&
                    getDataGrid(pageSummary[index], index)}
                </StyledAccordionDetails>
              </StyledAccordion>
            );
          })}
        </>
      ) : (
        <>
          {!!visualizationData.length && (
            <>
              {viewState !== ViewOption.DATA_VIEW && (
                <>{getDashboard(visualizationData[0], 0)}</>
              )}
              {viewState !== ViewOption.DASHBOARD &&
                getDataGrid &&
                getDataGrid(pageSummary[0], 0)}
            </>
          )}
        </>
      )}
    </VisualDashboardGrid>
  );
};
