import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  ButtonBase,
  ButtonGroup,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { BankFeedView } from '../../../utils/types/bank.type';
import { BankFeedViewOptions } from './BankFeedList.defaults';

export const BankFeedWrapperBox = styled(Box)<{
  hidden?: boolean;
  gapSize?: number;
  viewState: BankFeedView;
}>(({ theme, hidden, gapSize = 1, viewState }) => ({
  height: '100%',
  display: hidden === true ? 'none' : 'grid',
  gap: theme.spacing(gapSize),
  gridTemplateColumns: '1fr',
  gridTemplateRows:
    viewState === BankFeedViewOptions.DASHBOARD
      ? 'auto 1fr '
      : 'auto auto 1fr ',
}));

export const DataGridBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr ',
}));

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridTemplateColumns: '20% 15% 20% 20%',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  minHeight: '40px',
}));

export const FilterBorderColor: SxProps<Theme> = {
  borderColor: (theme) => theme.palette.primary.dark,
};

export const ViewOptionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: '20px',
  alignItems: 'center',
}));

export const ViewOptionChip = styled(Chip)(({ theme }) => ({
  margin: '8px',
}));

export const TransactionTypeAvatar = styled(IconButton)(({ theme }) => ({
  width: 16,
  height: 16,
  marginLeft: '16px',
  fontSize: '12px',
  bgcolor: 'grey',
}));

export const ProgressBar = styled(LinearProgress)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
});

export const BulkEditHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const TransactionTypeBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  border-bottom: ${(props) =>
    props.isSelected ? '2px solid #007AFF' : 'none'};
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

export const TransactionTypeButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  height: '24px',
}));

export const TransactionTypeDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#383842',
  marginLeft: '16px',
  marginRight: '24px',
}));

export const BulkEditFormControl = styled(FormControl)(({ theme }) => ({
  width: '250px',
  minWidth: '250px',
  marginLeft: '20px',
}));

export const BulkEditFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    marginLeft: '20px',
  })
);

export const BottomPlacementBulkEditFormControl = styled(FormControl)(
  ({ theme }) => ({
    width: '250px',
    marginLeft: '20px',
  })
);

export const BulkEditButtonBox = styled(FormControl)(({ theme }) => ({
  width: '100px',
  marginLeft: '40px',
}));

export const FundChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-label': {
    fontSize: '1rem',
    lineHeight: '1.5',
  },
}));

export const StatusChip = styled(Chip)<{ status: string }>`
  background-color: ${(props) =>
    props.status === 'CATEGORIZED'
      ? '#DEF2E4'
      : props.status === 'PENDING'
      ? '#F4EFE3'
      : '#F9DCDC'};
  .MuiChip-label {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const ScoreChip = styled(Chip)<{ score: number }>`
  background-color: ${(props) =>
    props.score > 92 ? '#DEF2E4' : props.score > 85 ? '#F4EFE3' : '#F9DCDC'};
  .MuiChip-label {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const BlueContentButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: '#007AFF',
}));

export const RotatingIcon = styled(ExpandMoreIcon, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease',
}));

export const TwoStepSelectButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CellBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));

export const GreenCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(
  () => ({
    color: '#5BBC76 !important',
  })
);

export const BlueAddCircleOutlineOutlinedIcon = styled(
  AddCircleOutlineOutlinedIcon
)(() => ({
  color: '#007AFF !important',
}));

export const OrangeErrorOutlineOutlinedIcon = styled(ErrorOutlineOutlinedIcon)(
  () => ({
    color: '#F2B203 !important',
  })
);

export const NowrapTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  color: 'GrayText',
  marginLeft: '4px',
}));

export const AutocompleteGroupHeader = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  padding: theme.spacing(1),
}));

export const DisabledStyledChip = styled(Chip)({
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  color: 'rgba(0, 0, 0, 0.38)',
});

export const GreyInfoIcon = styled(InfoOutlinedIcon)(() => ({
  color: '#A9A9A9 !important',
  marginLeft: '8px',
  marginBottom: '2px',
}));
