import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

const authApiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.AuthApi);
};
const getConfig = (loginToken: string) => {
  return {
    headers: { Authorization: `Bearer ${loginToken}` },
  };
};

export const getCurrentEnv = async (loginToken?: string) => {
  return await apiClient().get(
    "/env/application/environment",
    loginToken ? getConfig(loginToken) : undefined
  );
};

export const maxAllowedAttempts = async (loginToken?: string) => {
  return await apiClient().get<number>(
    "/env/mfa/attempts/maxAllowed",
    loginToken ? getConfig(loginToken) : undefined
  );
};

export const getUserPhoneDetails = async (loginToken?: string) => {
  return await apiClient().get(
    `/users/current/phone`,
    loginToken ? getConfig(loginToken) : undefined
  );
};

export const checkMFASettings = async (clientId: string) => {
  return await apiClient().get(`/clients/${clientId}/mfaSettings`);
};

export const totalAttempts = async (loginToken?: string) => {
  return await authApiClient().get<number>(
    "/mfa/attempts",
    loginToken ? getConfig(loginToken) : undefined
  );
};
export const isDeviceTrusted = async () => {
  return await authApiClient().get(`/mfa/isTrustedDevice`);
};

export const requestLoginCode = async (payload: any) => {
  return await authApiClient().post(`/mfa/requestCode`, payload);
};

export const verifyLoginCode = async (payload: any) => {
  return await authApiClient().post(`/mfa/verifyCode`, payload);
};

export const registerUserPhone = async (payload: any, loginToken?: string) => {
  return await apiClient().post(
    `/users/current/registerphone`,
    payload,
    loginToken ? getConfig(loginToken) : undefined
  );
};

export const sendRecoveryEmail = async (payload: any, loginToken?: string) => {
  return await authApiClient().post(`/mfa/sendRecoveryEmail`, payload, loginToken ? getConfig(loginToken) : undefined);
};

export const isAuthorized = async (loginToken?: string) => {
  return await apiClient().get(
    "/users/isActive",
    loginToken ? getConfig(loginToken) : undefined
  );
};

export const updateTrustedDevices = async (loginToken?: string) => {
  return await authApiClient().post(`/mfa/trustedDevices/update`, {}, loginToken ? getConfig(loginToken) : undefined);
};