import { 
  Box, 
  Button,
  Divider, 
  Stack, 
  styled 
} from "@mui/material";

export const DistributionFormBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  marginBottom: theme.spacing(0),
  columnGap: theme.spacing(1),
}));

export const DistributionStyledStack = styled(Stack)(() => ({
  width: "15%",
}));

export const DistributionAddButton = styled(Button)(() => ({
  width: "18%",
}));
export const DistributionButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: theme.spacing(2),
}));

export const DistributionStyledDivider = styled(Divider) (({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
