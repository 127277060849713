import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../../core/context/appContextProvider';
import {
  connectPlaidAccounts,
  getBanks,
} from '../../../../services/bank.service';
import { BankAccount, nameIdFundPair } from '../../../../utils/types/bank.type';
import { NameIdPair } from '../../../../utils/types/transaction.type';
import {
  addAccountFormType,
  addBanksFormType,
  arkIntegratedAccount,
  bankIntegrationPayload,
  fundLedgerCoAType,
  plaidConnectResponseType,
  VALIDATION_ERROR,
} from '../BankConnectionPanel.defaults';

const today = new Date();
const minDate = new Date(
  today.getFullYear() - 2,
  today.getMonth(),
  today.getDate()
);

type Props = {
  plaidAccountsRes: plaidConnectResponseType;
  fundList: NameIdPair[];
  ledgerList: nameIdFundPair[];
  CoAList: nameIdFundPair[];
  bankAccountList: BankAccount[];
  handleBankConnectionClose: () => void;
};

export const useBankFundAssociation = ({
  plaidAccountsRes,
  fundList,
  ledgerList,
  CoAList,
  bankAccountList,
  handleBankConnectionClose,
}: Props) => {
  const { informationAlert } = useContext(AppContext);

  const [totalAccountsToAdd, setTotalAccountsToAdd] = useState<number>(0);
  const [totalExistingAccounts, setTotalExistingAccounts] = useState<number>(0);
  const [initFormValues, setInitFormValues] = useState<addBanksFormType>();
  const [fundLedgerCoAOptions, setFundLedgerCoAOptions] = useState<
    fundLedgerCoAType[]
  >([]);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getDateString = (archiveDate: Date) => {
    const year = archiveDate.getFullYear();
    const month = String(archiveDate.getMonth() + 1).padStart(2, '0');
    const day = String(archiveDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    trigger,
    reset,
    handleSubmit,
  } = useForm<addBanksFormType>();

  useEffect(() => {
    if (fundList.length) {
      const fundLedgerCoA = fundList.map((fundItem) => {
        const fundLedgers = ledgerList.filter(
          (ledgerItem) => ledgerItem.fundId === fundItem.id
        );

        const fundCoA = CoAList.filter(
          (CoAItem) => CoAItem.fundId === fundItem.id
        );

        return {
          id: fundItem.id,
          name: fundItem.name,
          ledgers: fundLedgers,
          CoA: fundCoA,
        };
      });

      setFundLedgerCoAOptions(fundLedgerCoA);
    }
  }, [fundList, ledgerList, CoAList]);

  useEffect(() => {
    const existingPlaidAccIds: string[] = bankAccountList.map(
      (item) => item.accountId
    );

    const newAccountsOnly = plaidAccountsRes?.accounts?.filter(
      (plaidAcc) => !existingPlaidAccIds.includes(plaidAcc.id)
    );

    if (newAccountsOnly.length < plaidAccountsRes.accounts.length) {
      setTotalExistingAccounts(
        plaidAccountsRes.accounts.length - newAccountsOnly.length
      );
    }

    const initFormVals: addBanksFormType = {
      startDate: null,
      accounts: newAccountsOnly.length
        ? newAccountsOnly.map((account) => {
            return {
              ...account,
              selected: false,
              fundId: null,
              ledgerId: null,
              glAccountId: null,
            };
          })
        : [],
    };

    setInitFormValues(initFormVals);
    reset(initFormVals);
  }, [plaidAccountsRes]);

  const handleResetAccount = (index: number) => {
    setValue(`accounts.${index}`, {
      ...getValues(`accounts.${index}`),
      selected: false,
      fundId: null,
      ledgerId: null,
      glAccountId: null,
    });
    trigger(`accounts.${index}`);
  };

  const fetchAllBankAccounts = async (isCanceled?: () => boolean) => {
    try {
      const allBanksResponse = await getBanks();

      if (isCanceled?.()) return;
    } catch (e) {
      informationAlert('Error getting bank list', 'error');
    }
  };

  const watchFormValues = watch();

  useEffect(() => {
    let selectedCount = 0;

    for (const acc of getValues('accounts')) {
      if (acc.selected === true) {
        selectedCount++;
      }
    }
    setTotalAccountsToAdd(selectedCount);
  }, [watchFormValues]);

  const onReview = async (data: any) => {
    const filteredAccounts = data.accounts.filter(
      (obj: addAccountFormType) => obj.fundId
    );

    const accounts: arkIntegratedAccount[] = filteredAccounts.map(
      (acc: arkIntegratedAccount) => ({
        id: acc.id,
        name: acc.name,
        fundId: acc.fundId,
        ledgerId: acc.ledgerId,
        glAccountId: acc.glAccountId,
        startDate: '1973-01-23',
      })
    );

    const payload: bankIntegrationPayload = {
      publicToken: plaidAccountsRes?.public_token!!,
      accounts: [...accounts],
    };

    try {
      setIsSaving(true);
      await connectPlaidAccounts(payload);
      informationAlert('Bank accounts successfully saved', 'success');
      handleBankConnectionClose();
    } catch (error) {
      setIsSaving(false);
      informationAlert('Error saving bank account', 'error');
    } finally {
      await fetchAllBankAccounts();
    }
  };

  const validateDate = (value: Date | undefined | null) => {
    if (!value) {
      return VALIDATION_ERROR.INVALID;
    }
    const selectedDate = new Date(value);

    if (
      !(value instanceof Date && !isNaN(value.getTime())) ||
      selectedDate < new Date('01/01/1900')
    ) {
      return VALIDATION_ERROR.INVALID;
    } else if (selectedDate < minDate) {
      return VALIDATION_ERROR.PAST_MIN_DATE;
    }
    return true;
  };

  const onExitConfirmation = () => {
    setShowExitConfirmation(true);
  };

  const keepPanelOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    fundLedgerCoAOptions,
    initFormValues,
    totalAccountsToAdd,
    totalExistingAccounts,
    control,
    getValues,
    setValue,
    reset,
    watch,
    watchFormValues,
    errors,
    trigger,
    handleSubmit,
    handleResetAccount,
    onReview,
    minDate,
    validateDate,
    onExitConfirmation,
    showExitConfirmation,
    keepPanelOpen,
    isSaving,
  };
};
