import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { ButtonPropsVariantOverrides } from "@mui/material";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";
import { OverridableStringUnion } from "@mui/types";
import React from "react";
import { useEffect, useState } from "react";

import noop from "../../../utils/helpers/noop";
import { Action } from "./constants";
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContentText,
  StyledDialogTitle,
  StyledInputCaption,
  StyledInputWrapper,
} from "./DeleteConfirmationDialog.styles";

interface Props {
  // open: This is the boolean that determines if the dialog is open or not
  open: boolean;

  // onClose: This is the function that closes the dialog
  onClose?: () => void;

  // title: This is the title of the dialog
  title?: string | React.ReactNode;

  // content: This is the content of the dialog
  content?: string | React.ReactNode;

  // instructionModifier: Text added to end of type-to-delete instruction to specify action results
  instructionModifier?: string;

  // actionDisplayType: This is the display type of the action buttons if we want to display then stacked or inline, by default it is stacked.
  actionDisplayType?: "inline" | "stacked";

  // id: This is the id of the dialog
  id: string;

  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  primaryButtonVariant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  secondaryButtonVariant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  primaryButtonColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  secondaryButtonColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  primaryButtonId?: string;
  secondaryButtonId?: string;
  actionType?: Action | string;
  transactionCount?: string;
}

const DeleteConfirmationDialog = ({
  open,
  onClose,
  title = "Are you sure you want to delete?",
  content = "This action is permanent and cannot be undone. It is recommended that you export and save these records first.",
  instructionModifier = ".",
  primaryButtonLabel = "Delete Permanently",
  secondaryButtonLabel = "Cancel",
  primaryButtonAction = noop,
  secondaryButtonAction = noop,
  primaryButtonVariant = "contained",
  secondaryButtonVariant = "outlined",
  primaryButtonColor = "primary",
  secondaryButtonColor = "error",
  primaryButtonDisabled = false,
  secondaryButtonDisabled = false,
  primaryButtonId = "delete-confirmation-dialog-confirm-button",
  secondaryButtonId = "delete-confirmation-dialog-cancel-button",
  actionDisplayType = "stacked",
  id,
  actionType = Action.DELETE,
  transactionCount,
}: Props) => {
  const [inputValue, setInputValue] = React.useState("");
  const [deleteString, setDeleteString] = useState("");

  if (instructionModifier !== ".")
    instructionModifier = " " + instructionModifier.trim();

  const DialogActionContainer =
    actionDisplayType === "inline" ? DialogActions : StyledDialogActions;

  useEffect(() => {
    setDeleteString(
      transactionCount ? actionType + " " + transactionCount : actionType
    );
  }, [transactionCount, actionType]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      id={id}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        elevation: 0,
      }}
    >
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
      {content && (
        <DialogContent>
          <StyledDialogContentText>{content}</StyledDialogContentText>
          <StyledInputWrapper>
            <StyledInputCaption variant="caption" component="p">
              Type <strong>{deleteString}</strong> in the text box below
              {instructionModifier}
            </StyledInputCaption>
            <TextField
              id="delete-confirmation-text-field"
              size="small"
              fullWidth
              aria-describedby="Confirmation Text"
              label="Confirmation Text"
              placeholder="Confirmation Text"
              variant="outlined"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </StyledInputWrapper>
        </DialogContent>
      )}
      <Box display="flex" justifyContent="center">
        <DialogActionContainer disableSpacing={actionDisplayType === "stacked"}>
          <Button
            key={primaryButtonId}
            id={primaryButtonId}
            variant={primaryButtonVariant}
            color={primaryButtonColor}
            onClick={() => {
              if (inputValue === deleteString) {
                setInputValue("");
                primaryButtonAction?.();
              }
            }}
            disabled={primaryButtonDisabled || inputValue !== deleteString}
            fullWidth
          >
            {primaryButtonLabel}
          </Button>
          <Button
            key={secondaryButtonId}
            id={secondaryButtonId}
            variant={secondaryButtonVariant}
            color={secondaryButtonColor}
            onClick={() => {
              setInputValue("");
              secondaryButtonAction?.();
            }}
            disabled={secondaryButtonDisabled}
            fullWidth
          >
            {secondaryButtonLabel}
          </Button>
        </DialogActionContainer>
      </Box>
    </StyledDialog>
  );
};

export default DeleteConfirmationDialog;
