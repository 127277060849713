import {
  GppGood,
  HelpOutlineRounded,
  LogoutRounded,
  ManageAccountsRounded,
  MiscellaneousServicesRounded,
  PrivacyTipOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  Link as RedirectionLink,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box, FormControl, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import RoutingPaths from "../../core/routing/routingPaths";
import ConfirmationDialog from "../Modal/ConfirmationDialog";
import Progress from "../Progress/ProgressModal/ProgressModal";
import Zendesk from "../Zendesk/Zendesk";
import {
  AutocompleteTextField,
  ClientImage,
  NavBarContainer,
  StyledAccountCircle,
  StyledImage,
  StyledMoreHoriz,
  StyledTypography,
} from "./NavBar.styles";
import useNavbar from "./useNavbar";
import { SELECT_CLIENT } from "./useNavbar";

const NavBar: React.FC = () => {
  const {
    loading,
    state,
    open,
    handleClick,
    handleClose,
    onLogout,
    anchorEl,
    clientList,
    handleClientChange,
    clearSelectedClient,
    selectedClient,
    onClientChange,
    hasClient,
    isSuperAdminOrArkClientAdmin,
    getHelp,
    isAdmin,
    preAuthClientConfigs,
    clientTheme,
  } = useNavbar();


  return (
    <NavBarContainer
      id="top-navbar"
      bgcolor={clientTheme?.navbarBackgroundColor}
      position="static"
      color="transparent"
    >
      <Progress id="navbar_loading" showProgress={loading} />
      {isAdmin && (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id="selector-client"
            options={clientList}
            value={
              (state?.loginUser?.clientId
                ? clientList.find(
                  (client) => client.clientId === state.loginUser.clientId
                )
                : SELECT_CLIENT) || SELECT_CLIENT
            }
            getOptionLabel={(option) => option.clientName}
            getOptionDisabled={(option) =>
              option.clientId === SELECT_CLIENT.clientId
            }
            renderOption={(props, option) => (
              <li {...props} key={option.clientId}>
                <ClientImage alt="logo" src={option.logoUrl || undefined} />
                {option.clientName}
              </li>
            )}
            onChange={handleClientChange}
            renderInput={(params) => (
              <AutocompleteTextField
                {...params}
                placeholder="Select Client"
                id={"select-client-textfield"}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {hasClient ? (
                        <ClientImage
                          alt="logo"
                          src={
                            clientList.find(
                              (client) =>
                                client.clientId === state.loginUser.clientId
                            )?.logoUrl || undefined
                          }
                        />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            )}
          />
        </FormControl>
      )}
      {!isAdmin && (
        <Box>
          {preAuthClientConfigs?.logoUrl && (
            <StyledImage
              fullwidth="true"
              alt="logo"
              src={preAuthClientConfigs?.logoUrl || undefined}
            />
          )}
        </Box>
      )}
      <Box />
      <Box display="flex" alignItems="center">
        <StyledTypography textcolor={clientTheme?.navbarTextColor}>
          {state?.loginUser?.currentUser?.username ?? ""}
        </StyledTypography>
        <IconButton
          aria-label="user-account"
          aria-controls="user-account"
          aria-haspopup="true"
          color="inherit"
          id={"user-account"}
        >
          <StyledAccountCircle textcolor={clientTheme?.navbarTextColor} />
        </IconButton>
        <IconButton
          aria-label="Profile action"
          color="inherit"
          id="profile-options"
          onClick={handleClick}
        >
          <StyledMoreHoriz textcolor={clientTheme?.navbarTextColor} />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "menu-button",
          }}
        >
          {isAdmin && (
            <MenuItem id="help" onClick={getHelp}>
              <ListItemIcon>
                <HelpOutlineRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Help</ListItemText>
            </MenuItem>
          )}
          {!isAdmin && preAuthClientConfigs?.supportEmail && (
            <MenuItem
              id="help"
              target="_top"
              rel="noopener noreferrer"
              onClick={handleClose}
              component={RedirectionLink}
              href={`mailto:${preAuthClientConfigs?.supportEmail ?? ""}`}
            >
              <ListItemIcon>
                <HelpOutlineRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Help</ListItemText>
            </MenuItem>
          )}
          {(isAdmin || isSuperAdminOrArkClientAdmin) && (
            <MenuItem
              id='trust'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleClose}
              component={RedirectionLink}
              href=' https://trust.arkpes.com'
            >
              <ListItemIcon>
                <GppGood fontSize="small" />
              </ListItemIcon>
              <ListItemText>Trust Center</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            id="privacy"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
            component={RedirectionLink}
            href="https://www.arkpes.com/privacy/"
          >
            <ListItemIcon>
              <PrivacyTipOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Privacy Policy</ListItemText>
          </MenuItem>
          {isSuperAdminOrArkClientAdmin && (
            <MenuItem
              id="client-configuration"
              onClick={handleClose}
              component={Link}
              to={RoutingPaths.Clients}
            >
              <ListItemIcon>
                <MiscellaneousServicesRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Client Configuration</ListItemText>
            </MenuItem>
          )}
          {isSuperAdminOrArkClientAdmin && (
            <MenuItem
              id="User Management"
              onClick={handleClose}
              component={Link}
              to={RoutingPaths.Users}
            >
              <ListItemIcon>
                <ManageAccountsRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>User Management</ListItemText>
            </MenuItem>
          )}
          
          {/* {
            for now we will just hide this code because there is a good chane we will bring it back
            preAuthClientConfigs?.isAuth0User && 
              <MenuItem id="help" onClick={() => { linkAuth0Account(); }}>
                <ListItemIcon>
                  <ManageAccountsRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign Up for Social</ListItemText>
              </MenuItem>
          } */}

          <MenuItem id="logout" onClick={onLogout}>
            <ListItemIcon>
              <LogoutRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <ConfirmationDialog
        open={Boolean(selectedClient)}
        onClose={clearSelectedClient}
        id="client-change-confirmation"
        actions={[
          {
            label: "Proceed",
            onClick: () => onClientChange(selectedClient),
            id: "proceed-button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearSelectedClient,
            id: "cancel-button",
            variant: "outlined",
            color: "error",
          },
        ]}
        title="You may have Unsaved Changes."
        content="Unsaved changes will be lost"
      />
      <Zendesk />
    </NavBarContainer>
  );
};

export default React.memo(NavBar);
