import { LinkProps, Typography } from "@mui/material";
import { Link } from "@mui/material";
import { ReactElement } from "react";

export interface Props extends LinkProps {
  id: string;
  onClick: any,
  children: any;
}

export const ActionLink = (props: Props): ReactElement => {
  const { id, children, onClick } = props;

  return (
    <Link
      id={id}
      onClick={onClick}
      component="button"
      underline="none"
    >
      <Typography variant="label">
        {children}
      </Typography>
    </Link>
  );
};
