import { ArrowForwardIosOutlined } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import { Collapse, IconButton } from '@mui/material';
import { snakeCase } from 'lodash';
import React from 'react';

import ArkLogo from '../../assets/images/logos/ark_pes_logo.svg';
import ArkOnlyLogo from '../../assets/images/logos/ark_pes_only_logo.svg';
import MenuOption from './MenuOption';
import MenuOptionIcon from './MenuOptionIcon';
import {
  SideBarContainer,
  SideBarList,
  SideBarLogoContainer,
  SidebarPinIcon,
  StyledKeyboardDoubleArrowLeft,
  StyledKeyboardDoubleArrowRight,
} from './SideBar.styles';
import useSideBar from './useSideBar';

const SideBar = () => {
  const {
    isNavBarOpen,
    openSideBar,
    closeSideBar,
    isDisabled,
    isNavBarPinned,
    togglePinSideBar,
    sideNavs,
    clientTheme,
  } = useSideBar();

  return (
    <Collapse
      collapsedSize="60px"
      orientation="horizontal"
      in={isNavBarOpen}
      onMouseEnter={openSideBar}
      onMouseLeave={closeSideBar}
    >
      <SideBarContainer
        background={clientTheme?.sidebarBackgroundColor}
        isnavbaropen={isNavBarOpen?.toString()}
      >
        <SidebarPinIcon
          collapsedSize="40px"
          orientation="horizontal"
          in={isNavBarPinned}
        >
          <IconButton
            aria-label="expand-collapse-sidebar"
            color="inherit"
            id="expand_collapse_sidebar"
            onClick={togglePinSideBar}
          >
            {isNavBarPinned ? (
              <StyledKeyboardDoubleArrowLeft
                textcolor={clientTheme?.sidebarTextColor}
              />
            ) : (
              <StyledKeyboardDoubleArrowRight
                textcolor={clientTheme?.sidebarTextColor}
              />
            )}
          </IconButton>
        </SidebarPinIcon>
        <SideBarList
          isnavbaropen={isNavBarOpen?.toString()}
          component="nav"
          aria-labelledby="sidebar"
        >
          {sideNavs?.map((menu) => (
            <MenuOption
              label={menu.label}
              id={`${snakeCase(menu.label)}_menu_option`}
              key={`${menu.icon}_${menu.id}`}
              redirectionLink={menu.routerLink || ''}
              isDisabled={isDisabled && !!menu.canDisable}
              color={clientTheme?.sidebarTextColor}
              isLocked={menu?.locked}
              icon={
                <MenuOptionIcon
                  iconKey={menu.label}
                  sx={{
                    color: clientTheme?.sidebarTextColor,
                  }}
                />
              }
              rightIcon={menu?.locked && <LockIcon />}
              options={
                menu?.children && menu.children?.length > 0 && !menu?.locked
                  ? menu.children?.map((subMenu) => ({
                      label: subMenu.label,
                      id: `${snakeCase(subMenu.label)}_menu_option`,
                      redirectionLink: subMenu.routerLink || '',
                      isDisabled: isDisabled && !!subMenu.canDisable,
                      icon: <HomeIcon />,
                    }))
                  : []
              }
            />
          ))}
        </SideBarList>
        <SideBarLogoContainer>
          <img src={isNavBarOpen ? ArkLogo : ArkOnlyLogo} alt="Ark Logo" />
        </SideBarLogoContainer>
      </SideBarContainer>
    </Collapse>
  );
};

export default React.memo(SideBar);
