import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import { RouteProps } from "react-router-dom";

import IconLeftArrow from "../../../assets/images/icons/icon_left_arrow.svg";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ImgIcon from "../../../components/ImgIcon/ImgIcon";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import { StatusColor } from "../../../components/StatusLabel/StatusLabel.style";
import { NegativeCurrencyFormat } from "../../../utils/helpers/format.helper";
import {
  SelectedFund,
  StatRepTransactionType,
} from "../../../utils/types/capitalAccounts.type";
import { DownloadStatementReportOptionIds } from "../../../utils/types/common.type";
import { ImageItem } from "../../../utils/types/listItems";
import {
  ArrowIconBox,
  DetailPanel,
  FooterBox,
  StatCell,
  StatementReportBox,
  StatRow,
  StyledIcon,
  TitleBox,
} from "./StatementReport.styles";
import { useStatementReport } from "./useStatementReport.hooks";

interface Props extends RouteProps {
  onStatementReportViewClose: Function;
  resetPaginationAndFetch: Function;
  fund: SelectedFund;
  setSelectedTransaction: Function;
}

const defaultActions: ImageItem[] = [
  {
    id: DownloadStatementReportOptionIds.DownloadPdf,
    text: "Download (PDF)",
  },
  {
    id: DownloadStatementReportOptionIds.DownloadExcel,
    text: "Download (Excel)",
  },
];

const StatementReportView: React.FC<Props> = ({
  onStatementReportViewClose,
  resetPaginationAndFetch,
  fund,
  setSelectedTransaction,
}: Props): ReactElement => {
  const {
    toggleDrawer,
    isLoading,
    statementReport,
    columns,
    statementRows,
    handleSplitButtonAction,
    handleValueClick,
  } = useStatementReport({
    fund,
    onStatementReportViewClose,
    resetPaginationAndFetch,
    setSelectedTransaction,
  });

  let isLabelValue = false;
  const formatter = NegativeCurrencyFormat(fund.currencyCode || "USD", 0);

  return (
    <React.Fragment>
      <DetailPanel
        id="statement_report"
        anchor="right"
        open={true}
        onClose={toggleDrawer}
      >
        <ProgressModal id="cap_acc_stat_rep_loading" showProgress={isLoading} />
        <TitleBox>
          <Box>
            <ArrowIconBox>
              <StyledIcon icon={IconLeftArrow} onClick={toggleDrawer} />
              <Typography id="title" variant="h1" marginLeft={0.5}>
                Capital Account Statement
              </Typography>
            </ArrowIconBox>

            <Typography id="sub_title" variant="h2">
              {fund.investorName} - {fund.fundName}
            </Typography>
          </Box>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        {statementReport && columns.length > 0 && (
          <StatementReportBox>
            <StatRow>
              <StatCell className="UNDERLINE BOLD">
                <Typography variant="modalSubtitle" display="flex">
                  As of {statementReport.asOf.name}
                </Typography>
              </StatCell>
              {columns.map((col) => (
                <StatCell key={col.index} className="UNDERLINE BOLD right">
                  <Typography variant="modalSubtitle" display="flex">
                    {col.label}
                  </Typography>
                </StatCell>
              ))}
            </StatRow>
            {statementRows.length > 0
              ? statementRows
                  .filter((row) => row.type !== StatRepTransactionType.SPACER)
                  .map((row) => {
                    if (row.type === StatRepTransactionType.LABEL)
                      isLabelValue = true;
                    else if (row.type !== StatRepTransactionType.TRANSACTION)
                      isLabelValue = false;
                    return (
                      <StatRow key={row.index}>
                        <StatCell
                          className={`${
                            isLabelValue &&
                            row.type === StatRepTransactionType.TRANSACTION
                              ? "left"
                              : ""
                          } 
                                                    ${row.styles.join(" ")} 
                                                    ${
                                                      row.type ===
                                                      StatRepTransactionType.ENDING
                                                        ? "BOLD"
                                                        : ""
                                                    }`}
                        >
                          <Typography variant="modalSubtitle" display="flex">
                            {row.label}
                          </Typography>
                        </StatCell>
                        {row.type !== StatRepTransactionType.LABEL &&
                          columns.map((col) => (
                            <StatCell
                              key={`${row.index}-${col.index}`}
                              className={`value ${row.styles.join(" ")} 
                                                            ${
                                                              row.type ===
                                                              StatRepTransactionType.ENDING
                                                                ? "BOLD"
                                                                : ""
                                                            }`}
                              onClick={() => {
                                handleValueClick(col, row);
                              }}
                            >
                              <Typography
                                variant="modalSubtitle"
                                display="flex"
                              >
                                {formatter.format(
                                  row.amounts[col.reportColumnType]
                                )}
                              </Typography>
                            </StatCell>
                          ))}
                      </StatRow>
                    );
                  })
              : null}
          </StatementReportBox>
        )}
        <FooterBox>
          <SplitButton
            id={"download_trans_history_options"}
            options={defaultActions}
            hidden={false}
            handleOptionClick={handleSplitButtonAction}
            ariaLabelMessage="Download Transaction History"
          />
        </FooterBox>
      </DetailPanel>
    </React.Fragment>
  );
};

export default StatementReportView;
