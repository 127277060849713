import { 
    Box, 
    Button, 
    Divider, 
    Stack, 
    styled 
} from "@mui/material";

export const DistributionsHeaderRow = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
}));

export const DataGridContainer = styled(Box)(() => ({
    overflow: "scroll",
    maxHeight: "50vh",  
}));

export const DistributionFormBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
}));

export const DistributionFixContainer = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0, -8),
}));

export const DistributionTransactionButton = styled(Button)(() => ({
    width: "20%",
}));

export const DistributiomStack = styled(Stack)(() => ({
    width: "15%",
}));

export const DistributionAddButton = styled(Button)(() => ({
    width: "18%",
}));

export const DistributionButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    padding: theme.spacing(10),
}));

export const DistributionStyledDivider = styled(Divider)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));
  

export const DistributionButtonBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
  }));
  
