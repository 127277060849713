import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { FormControlLabel, InputAdornment, Radio, Typography } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { endOfMonth,endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import React, { ReactElement, useEffect, useState } from "react";

import { DISPLAY_DATE_FORMAT } from "../../../../utils/constants/constants";
import TextField from "../../../TextField/TextField";
import { ButtonText, HeaderTitle } from "../Filter.style";
import { FilterItem, ListFilter } from "../PopoverFilter.style";
import { DateRangeContainer, StyledRadioContainer } from "./FilterDate.style";

type Props = {
  options?: Record<string, any>[];
  selectedOptions?: string[] | any[];
  handleFilter: (filter: any, selectedOptions: any[]) => void;
  idField: string;
  labelField: string;
  filterName: any;
  clearable?: boolean;
};

enum FastDateFilterType {
  Today = "today",
  ThisWeek = "this_week",
  ThisMonth = "this_month",
  SpecificDate = "specific_date",
}

const FAST_FILTER_OPTIONS = [{
  id: FastDateFilterType.Today,
  name: "Today",
}, {
  id: FastDateFilterType.ThisWeek,
  name: "This Week",
}, {
  id: FastDateFilterType.ThisMonth,
  name: "This Month",
}, {
  id: FastDateFilterType.SpecificDate,
  name: "Specific Date",
}];

const FilterDate: React.FC<Props> = ({
  selectedOptions = [],
  handleFilter,
  filterName,
}: Props): ReactElement => {
  const [currentFastSelection, setCurrentFastSelection] = useState<FastDateFilterType | null>(null);
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([
    null,
    null,
  ]);

  useEffect(() => {
    if(selectedOptions.length === 2) {
      setDateRange(selectedOptions[1] as unknown as DateRange<Date>);
      setCurrentFastSelection(selectedOptions[0] as FastDateFilterType);
    }
  }, [selectedOptions]);

  const handleDateChange = (dateRange: DateRange<any>) => {
    let toBeUpdated: DateRange<Date | null>;

    if (Array.isArray(dateRange)) {
      toBeUpdated = dateRange;
    } else {
      toBeUpdated = [dateRange, null];
    }
    setDateRange(toBeUpdated);
  };

  const onClear = () => {
    setCurrentFastSelection(null);
    setDateRange([null, null]);
  };

  const applyFilter = () => {
    if(dateRange?.length === 2) {
      handleFilter(filterName, [currentFastSelection, dateRange]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as FastDateFilterType;
    let newDateRange: DateRange<Date> = [null, null];

    const currentDate = new Date();

    const startOfTheWeek = startOfWeek(currentDate);
    const endOfTheWeek = endOfWeek(currentDate);

    const startOfTheMonth = startOfMonth(currentDate);
    const endOfTheMonth = endOfMonth(currentDate);

    switch (value) {
      case FastDateFilterType.Today:
        newDateRange = [currentDate, currentDate];
        break;
      case FastDateFilterType.ThisWeek:
        newDateRange = [startOfTheWeek, endOfTheWeek];
        break;
      case FastDateFilterType.ThisMonth:
        newDateRange = [startOfTheMonth, endOfTheMonth];
        break;
      default:
        break;
    }
    setDateRange(newDateRange as DateRange<Date>);
    setCurrentFastSelection(value);
  };

  return (
    <div id="popoverCheckFilter">
      <ListFilter>
        <StyledRadioContainer
          id="fast_date_selection_options"
          row
          aria-label="fast_date_selection_options"
          name="Date Selection"
          value={currentFastSelection}
          onChange={handleChange}
        >
          {FAST_FILTER_OPTIONS.map(({
            id, name
          }) => (
            <FilterItem key={id}>
              <FormControlLabel
                id={id}
                value={id}
                control={<Radio id={`${id}_selection`} />}
                label={name}
              />
            </FilterItem>
          ))}
        </StyledRadioContainer>
      </ListFilter>
      <DateRangePicker
        disabled={currentFastSelection !== FastDateFilterType.SpecificDate}
        calendars={1}
        value={dateRange}
        onChange={handleDateChange}
        inputFormat={DISPLAY_DATE_FORMAT}
        showToolbar={false}
        mask="__/__/____"
        renderInput={(startProps, endProps) => (
          <DateRangeContainer>
            <Typography variant="h6">From</Typography>
            <TextField
              {...startProps}
              isShort
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="h6">To</Typography>
            <TextField
              {...endProps}
              isShort
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </DateRangeContainer>
        )}
      />
      <HeaderTitle>
        <ButtonText id="btn_apply" variant="text" onClick={applyFilter}>
          Apply
        </ButtonText>
        <ButtonText id="btn_clear" variant="text" onClick={onClear}>
          Clear
        </ButtonText>
      </HeaderTitle>
    </div>
  );
};

export default FilterDate;
