import { useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Team, TeamsList } from "../../../utils/types/team.type";
import { getTeams } from "../../teams.service.v2";

export const useTeamsEffect = () => {
  const [teams, setTeams] = useState<TeamsList | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchTeams = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getTeams();

      if (isCanceled?.()) return;

      setTeams(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting teams", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchTeams(isCanceled);
  }, []);

  const currentTeams = useMemo(() => {
    return teams?.items || [];
  }, [teams]);

  const updateTeams = (updatedTeams: Team[]) => {
    setTeams((prevState) => ({
      ...(prevState ? prevState : {}),
      items: updatedTeams
    }));
  };

  return {
    teams: currentTeams,
    fetchTeams,
    loading,
    updateTeams,
  };
};
