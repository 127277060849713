import { cloneDeep } from "lodash";
import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import InformationAlert from "../../components/InformationAlert/InformationAlert";
import { IDLE_TIMEOUT } from "../../components/Layout/Layout";
import { useClientConfig } from "../../components/NavBar/useClientConfig.hooks";
import PortalUnavailable from "../../components/PortalUnavailable/PortalUnavailable";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { getArkClientToken, getAuth0ClientSilentToken, getAuth0Token, linkWithNewAuth0Account, logoutAuth0 } from "../../services/client.service";
import { isAuthorized } from "../../services/login.service";
import { getSideNavs } from "../../services/navigation.service";
import {
  getAuth0ClientLoginInfo,
  getClientConfigs,
  getClientPortalAvaiability,
} from "../../services/preAuthAPIs.service";
import { getCurrentUser } from "../../services/user.service";
import {
  CLIENT_PROXY_TOKEN_KEY,
  LAST_INACTIVE_TAB_KEY,
  LOGIN_PAYLOAD_KEY,
  TOKEN_KEY,
} from "../../utils/constants/constants";
import { INFO_ALERT_SEVERTIY_TYPES } from "../../utils/constants/styles.constants";
import { getSubdomainAndDomain } from "../../utils/helpers/misc.helper";
import noop from "../../utils/helpers/noop";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import useLocalStorage from "../../utils/hooks/useLocalStorage";
import useSessionStorage from "../../utils/hooks/useSessionStorage";
import { ClientConfigs } from "../../utils/types/client.type";
import { Menu } from "../../utils/types/navigation.type";
import { ADMIN_ROLES, LoginUser, ScopeRole } from "../../utils/types/user.type";
import { parseJwt } from "../authentication/parseJWT";
import RoutingPaths from "../routing/routingPaths";

type StateType = {
  loginUser: LoginUser;
};

type InformationMessage = {
  text: string;
  open: boolean;
  severity: INFO_ALERT_SEVERTIY_TYPES;
  title?: string | undefined;
  actionTitle?: string;
  action?: any;
};

type timeoutCallbackContainerType = {
  callback: () => void;
};

const initialState: StateType = {
  loginUser: {
    clientId: "",
    clientName: "",
    email: "",
    currentUser: undefined,
  },
};

const timeoutCallbackContainer = {
  callback: noop,
};

const timeoutCallbackWarningContainer = {
  callback: noop,
};

const initialInformationMessageState: InformationMessage = {
  text: "",
  open: false,
  severity: "info",
};

let { subdomain, domain } = getSubdomainAndDomain(window.location.hostname);

const substituteLocal = getSubdomainAndDomain(
  process.env.REACT_APP_API_ENDPOINT_URL as string
).domain.slice(0, -1);

if (domain === "localhost") {
  domain = substituteLocal;
} else if (subdomain === "localhost") {
  domain = substituteLocal;
  subdomain = "platform";
}

const isLPPortal = subdomain.startsWith('platform') || subdomain.startsWith('platform-sso') ? false : true;

const AppContext = createContext<{
  state: StateType;
  informationAlertMessage: InformationMessage;
  informationAlert: any;
  onLogout: () => void;
  onIdleWarning: () => void;
  setTimeoutCallback: (callback: () => void) => void;
  setTimeoutWarningCallback: (callback: () => void) => void;
  appHasChanges: boolean;
  setAppHasChanges: (value: boolean) => void;
  token: string;
  proxyToken: string;
  isClientInProxyMode: boolean;
  sideNavs?: Menu[];
  preAuthClientConfigs?: ClientConfigs;
  isCurrSelectedClientAuth0: boolean;
  clientConfigList: ClientConfigs[];
  onClientChange: (clientId: string, refetchClientList?: boolean) => void;
  onLogin: (loginUser: LoginUser, token: string, isAuth0Login: boolean) => void;
  refetchSideNav: () => void;
  onProxyLogin: (
    loginUser: LoginUser,
    clientToken: string,
    proxyToken: string
  ) => void;
}>({
  state: initialState,
  informationAlertMessage: initialInformationMessageState,
  informationAlert: () => null,
  onLogout: noop,
  onIdleWarning: noop,
  setTimeoutCallback: noop,
  setTimeoutWarningCallback: noop,
  appHasChanges: false,
  isCurrSelectedClientAuth0: false,
  setAppHasChanges: noop,
  onClientChange: noop,
  onLogin: noop,
  onProxyLogin: noop,
  refetchSideNav: noop,
  token: "",
  proxyToken: "",
  isClientInProxyMode: false,
  clientConfigList: [],
});


const AppProvider: React.FC = ({ children }) => {
  const [loginUser, setLoginUser] = useSessionStorage(LOGIN_PAYLOAD_KEY);
  const [token, setToken, removeToken] = useSessionStorage(TOKEN_KEY);
  const [proxyToken, setProxyToken, removeProxyToken] = useSessionStorage(CLIENT_PROXY_TOKEN_KEY);
  const [lastTabInactive, setLastTabInactive] = useLocalStorage(LAST_INACTIVE_TAB_KEY);


  const [sideNavs, setSideNavs] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(false);
  const [preAuthClient, setPreAuthClient] = useState<ClientConfigs>();
  const [isCurrSelectedClientAuth0, setIsCurrSelectedClientAuth0] = useState(false);
  const [informationAlertMessage, setInformationAlertMessage] = useState(
    initialInformationMessageState
  );
  const [appHasChanges, setAppHasChanges] = useState<boolean>(false);
  const [isPortalAvailable, setIsPortalAvailable] = useState<boolean>(
    isLPPortal ? false : true
  );
  const [isCheckingPortal, setIsCheckingPortal] = useState<boolean>(
    isLPPortal ? true : false
  );
  const [processedAuth0Effect, setProcessedAuth0Effect] = useState(0);
  const [proxyXferVals, setProxyXferVals] = useState<any>();
  const [activateRefreshTokenTimer, setActivateRefreshTokenTimer] = useState(false);

  const isAdmin = useMemo(() => {
    return (
      token &&
      ADMIN_ROLES?.includes(
        (loginUser?.currentUser?.scopeRole as ScopeRole) ?? ""
      )
    );
  }, [loginUser, token]);

  const { clientConfigList, fetchClientConfigList } = useClientConfig({
    isAdmin,
  });

  const currentClientId = useRef('');

  const setTimeoutCallback = (callback: () => void) => {
    timeoutCallbackContainer.callback = callback;
  };

  const setTimeoutWarningCallback = (callback: () => void) => {
    timeoutCallbackWarningContainer.callback = callback;
  };

  
  //  we want to get this once because the url will be redirected later
  const isClientInProxyMode = useMemo(() => {
    const isProxy = window.location.search === '?proxy=true';

    return isProxy;
  }, []);


  useEffect(() => {
    if(isLPPortal && isClientInProxyMode) {
      window.addEventListener("message", (event) => {
        if(event.data.type !== '_ark_proxy_data') return;

        setProxyXferVals(event.data.data);

        // let parent window know message was received
        event!.source!.postMessage({
            type: "_ark_proxy_message_received"
          }, {
            targetOrigin: event.origin 
          }
        );
      });
    }
  }, []);

  const onLogout = async () => {
    try {
      timeoutCallbackContainer.callback();
    } catch {}

    try {
      await setLoginUser(initialState.loginUser);
    } catch {}

    try {
      await removeToken();
    } catch {}

    try {
      await removeProxyToken();
    } catch {}

    try {
      if(preAuthClient?.isAuth0User) {
        logoutAuth0();
      }
    } catch(err) {
      consoleLog(err);
    }
  };

  const onIdleWarning = () => {
    timeoutCallbackWarningContainer.callback();
  };

  
  const informationAlert = (
    text: string,
    severity: INFO_ALERT_SEVERTIY_TYPES,
    title?: string | undefined,
    actionTitle?: string | undefined,
    action?: any
  ) => {
    setInformationAlertMessage({
      text: text,
      severity: severity,
      open: true,
      title: title,
      actionTitle: actionTitle,
      action: action,
    });
  };

  useEffectAsync(async (isCanceled) => {
    if (subdomain && domain && !isPortalAvailable) {
      try {
        const response = await getClientPortalAvaiability(domain, subdomain);

        if(isCanceled()) return;

        if (!response) onLogout();
        setIsCheckingPortal(false);
        setIsPortalAvailable(response);
      } catch (e) {
        informationAlert("Error fetching portal status", "error");
      }
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      if (subdomain && domain && isPortalAvailable) {
        try {
          setLoading(true);
          const response = await getClientConfigs(domain, subdomain);

          if (isCanceled()) return;

          const preAuthClient = {
            ...response,
            baseDomain: domain,
            subdomain: subdomain,
          };

          setPreAuthClient(preAuthClient);

          if(preAuthClient?.clientId) {
            const auth0ClientLoginInfo = await getAuth0ClientLoginInfo(preAuthClient?.clientId);

            setIsCurrSelectedClientAuth0(auth0ClientLoginInfo.isAuth0);
          }
  
        } catch (e) {
          informationAlert("Error fetching client configs", "error");
        } finally {
          setLoading(false);
        }
      }
    },
    [isPortalAvailable]
  );


  // Ark auth user in proxy mode
  useEffectAsync(async (_isCanceled) => {
    if(!preAuthClient) return;
    if(isClientInProxyMode && !proxyXferVals) return;

    if(!preAuthClient.isAuth0User && isClientInProxyMode) {
      await onProxyLogin(
        loginUser,
        proxyXferVals.matClientToken,
        proxyXferVals.matProxyToken,
        true
      );
    }
  }, [preAuthClient, JSON.stringify(proxyXferVals)]);



  // UseEffect for Auth0 user
  useEffectAsync(async (_isCanceled) => {
    if(!preAuthClient) return ;
    if(isClientInProxyMode && !proxyXferVals) return;
  
    if(!preAuthClient.isAuth0User) {
      setProcessedAuth0Effect(1);
      return;
    }

    const qs = window.location.search;

    // if auth0 issues error that it could redirect correcly to ark, then we just go back to auth0 login page
    if(qs.startsWith('?error_source=auth0') && qs.includes('&error_description=Unable%20to%20issue%20redirect%20for%20OAuth%202.0%20transaction&')) {
      onLogout();
      return;
    }

    let accessToken: string | undefined;

    consoleLog("Starting auth0 type login");

    try {
      if(window.location.pathname === RoutingPaths.AuthError) return;

      if(location.search.includes("error=")) {
        consoleLog("query string", location.search);
        throw "auth error";
      }

      if(token) {
        accessToken = token;
        consoleLog("found existing token. using this as starting");
      } else {
        if (isLPPortal && isClientInProxyMode) {
          accessToken = proxyXferVals.matProxyToken;
        } else {
          setLoading(true);

          accessToken = await getAuth0Token(preAuthClient!);
          if(!accessToken) return;

          await setToken(accessToken);

          setLoading(false);
        }
      }

    } catch(_err) {
      consoleLog(_err);
      window.location.replace(`${RoutingPaths.AuthError}${window.location.search}`);
    }

    if(accessToken) {
      const authStatus = await isAuthorized(accessToken);

      if(authStatus !== 'AUTHORIZED') {
        window.location.replace(`${RoutingPaths.AuthError}${'?error=access_denied'}`);
        return;
      }      

      if(!loginUser?.clientId) {
        try {
          setLoading(true);

          if(isLPPortal && isClientInProxyMode) {
            await onProxyLogin(
              loginUser,
              proxyXferVals.matClientToken,
              proxyXferVals.matProxyToken,
              true
            );
          } else {
            await onLogin({ clientId: '', email: '' }, accessToken, true);
          }
        } finally {
          setLoading(false);
        }
      }

      setProcessedAuth0Effect(1);
      
      // if auth0 then we dont want to redirect to allow a sub path to work correctly.
      if(window.location.hostname.startsWith('platform')) {
        if(window.location.pathname === RoutingPaths.AppDashboard && window.location.search !== '') {
          window.location.replace(RoutingPaths.AppDashboard);
        }
      } else {
        if(!window.location.pathname || window.location.pathname === RoutingPaths.AppDashboard) {
          window.location.replace(RoutingPaths.CapitalAccounts);
        }
      }
    }
  
  }, [preAuthClient, proxyXferVals]);


  async function updateSilentRefreshToken() {
    try {
    const newAccessToken = await getAuth0ClientSilentToken(preAuthClient!, currentClientId.current, true, true); 

    if(!newAccessToken) return false;

    await setToken(newAccessToken);

    consoleLog("refresh token", "Successfully retrieved new refresh token");

    return true;
    } catch (err) {
      consoleLog('Error in getting refresh token', err);

      return false;
    }
  }



  // handle events when tab become hidden or minimized
  useEffect(() => {
    if(!preAuthClient || !preAuthClient.isAuth0User) {
      return;
    }

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        // save tab inactive state in session storage
        setLastTabInactive(new Date().getTime());

        // turn off refresh token timer
        setActivateRefreshTokenTimer(false);
      } else {
        // check if inactive for too long
        if((new Date(lastTabInactive+IDLE_TIMEOUT)) < new Date()) {
          onLogout();
          return;
        }

        // get refresh token. if A0 session is inactive then logoff
        if(!await updateSilentRefreshToken()) {
          onLogout();
          return;
        }

        // turn on refresh token timer
        setActivateRefreshTokenTimer(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [preAuthClient]);

  
  // UseEffect get refresh token for Auth0 user
  useEffect(() => {

      setInterval(async () => {
        if(activateRefreshTokenTimer) {
          await updateSilentRefreshToken();
        }
      }, IDLE_TIMEOUT/2);
    
  }, []);


  const currentToken = proxyToken || token;

  useEffectAsync(
    async (isCanceled) => {
      if (!currentToken) return;
      if (processedAuth0Effect === 0) return;


      if(!isLPPortal) {
        const el = document.getElementsByClassName('App container');

        if(!el.length) {;
          //  *** hack alert  ***
          //  for some reason when another ark window is open and logged in with auth0, it does not 
          // run react correctly and we will get a blank screen unless re reload the window
          window.location.reload();
          return;
        }
      }


      try {
        setLoading(true);
        const [user, sideNavResponse] = await Promise.all([
          getCurrentUser(),
          getSideNavs(),
        ]);

        if (isCanceled()) {
          return;
        }

        const parsed = parseJwt(currentToken);
        const currUser = loginUser.currentUser??user;

        await setLoginUser({
          ...loginUser,
          clientName: parsed.clientName,
          clientId: currUser.clientId,
          currentUser: currUser,
        });

        setSideNavs(sideNavResponse);
      } catch (error) {
        informationAlert("Error loading side navs or user information", "error");
      } finally {
        setLoading(false);
      }
      
    },
    [proxyToken, token, processedAuth0Effect]
  );

  const onClientChange = async (
    clientId?: string,
    refetchClientList?: boolean
  ) => {
    try {
      setLoading(true);
      if (clientId) {
        let newAccessToken: string|undefined|null;

        if(loginUser.isAuth0Login) {
          newAccessToken = await getAuth0ClientSilentToken(preAuthClient!, clientId, false, true);
        } else { 
          const arkToken = await getArkClientToken(clientId);

          newAccessToken = arkToken?.access_token;
        }

        if (!newAccessToken) {
          return null;
        }

        currentClientId.current = clientId;
        const clientCfg = clientConfigList.find(c => c.clientId === clientId);
        const parsed = parseJwt(newAccessToken);

        await setLoginUser({
          ...loginUser,
          clientName: parsed.clientName,
          clientId: clientId,
          arkClientTag: clientCfg?.arkClientTag,
          currentUser: {
            ...loginUser.currentUser,
            clientId: clientId
          }
        });
        await setToken(newAccessToken);


        if (refetchClientList) {
          await fetchClientConfigList();
        }

        const auth0ClientLoginInfo = await getAuth0ClientLoginInfo(clientId);

        setIsCurrSelectedClientAuth0(auth0ClientLoginInfo.isAuth0);
      }
    } catch (err) {
      informationAlert("Issue in getting client", "error");
    } finally {
      setLoading(false);
    }
  };

  const onLogin = async (newLoginUser: LoginUser, token: string, isAuth0Login: boolean) => {
    const parsed = parseJwt(token);

    await setLoginUser({
      ...newLoginUser,
      clientName: parsed.clientName,
      isAuth0Login
    });

    await setToken(token);
  };

  const onProxyLogin = async (loginUser: LoginUser|undefined, clientToken: string, proxyToken: string, isAuth0Login: boolean = false) => {
    const parsed = parseJwt(proxyToken);

    //  I hate use any type here but need to make an exception until auth0 cleanup phase
    let loginUserClone: any;
    
    if(loginUser) {
      loginUserClone = cloneDeep(loginUser);
      loginUserClone!.currentUser!.clientId = parsed.clientId;
    } else {
      loginUserClone = {  
        clientId:  parsed.clientId
      };
    }

    await setLoginUser({
      ...loginUserClone,
      clientName: parsed.clientName,
    });
    
    await setToken(clientToken);
    await setProxyToken(proxyToken);
  };

  const handleInformationAlertMessageClose = () => {
    setInformationAlertMessage({
      text: "",
      severity: informationAlertMessage.severity,
      open: false,
    });
  };

  const refetchSideNav = async () => {
    const sideNavs = await getSideNavs();

    setSideNavs(sideNavs);
  };

  function consoleLog(message1: any, message2?: any): void {
    // eslint-disable-next-line no-console
    console.log(message1, message2);
  }

  return (
    <>
      {!isCheckingPortal && !isPortalAvailable ? (
        <PortalUnavailable />
      ) : (
          <AppContext.Provider
            value={{
              state: {
                loginUser: loginUser,
              },
              clientConfigList,
              sideNavs,
              token,
              proxyToken,
              isClientInProxyMode,
              informationAlertMessage,
              informationAlert,
              onLogout,
              onIdleWarning,
              setTimeoutCallback,
              setTimeoutWarningCallback,
              preAuthClientConfigs: preAuthClient,
              isCurrSelectedClientAuth0,
              onLogin,
              onProxyLogin,
              onClientChange,              
              refetchSideNav,
              appHasChanges,
              setAppHasChanges,
            }}
          >
            <HelmetProvider>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{preAuthClient?.tabName || "Ark"}</title>
                <link
                  id="favicon"
                  rel="icon"
                  href={
                    preAuthClient?.useFavicon && preAuthClient?.faviconUrl
                      ? preAuthClient?.faviconUrl
                      : "/favicon.ico"
                  }
                />
              </Helmet>
            </HelmetProvider>
            <Progress id="global_loader" showProgress={loading} />
            {children}
            <InformationAlert
              id={"ark_notification"}
              text={informationAlertMessage.text}
              open={informationAlertMessage.open}
              severity={informationAlertMessage.severity}
              title={informationAlertMessage.title}
              actionTitle={informationAlertMessage.actionTitle}
              handleClose={handleInformationAlertMessageClose}
              action={informationAlertMessage.action}
            />
          </AppContext.Provider>
      )}
    </>
  );
};

export { AppProvider, AppContext };
