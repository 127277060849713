import { Box, Link, styled } from "@mui/material";

import DataGrid from "../../../components/DataGrid/DataGrid";

export const StyledDataGrid = styled(DataGrid)`
  margin: 20px 0 50px 0;
`;
export const HeaderRow = styled(Box)(({
  theme
}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: theme.spacing(2),
}));

export const NameLink = styled(Link)(() => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
  position: 'relative',
}));