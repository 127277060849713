import { ListItem, styled } from "@mui/material";

export const StyledListItem = styled(ListItem)<{
  hidden?: boolean;
}>`
  display: ${(props) => (props.hidden ? "none" : "inherit")};
  .MuiListItemButton-root:hover {
    background-color: #e1edfb;
  }
`;
