import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from 'react';

import useFundFilter from './useFundFilter.hook';

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (fund: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
}

type FundValue = any | null | undefined;

const FundFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = 'small',
  id='fund-filter',
  textFieldID='fund-filter-textfield'
}: Props) => {
  const {
    fundsList,
    loading,
  } = useFundFilter();
  const onSelectChange = (_: any, fund: any) => {
    if(!fund) {
      return;
    }
    onChange?.(idSelectOnly ? (fund?.id ?? '') : fund);
  };

  const selectedValue: FundValue = idSelectOnly ? fundsList?.find((item: any) => item.id === value) ?? null : value as FundValue;

  return (
    <Autocomplete
      id={id}
      options={fundsList || []}
      getOptionLabel={(option: any) => option?.name ?? ''}
      isOptionEqualToValue={(option: any, selected: any) => option?.name === selected?.name}
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      size={size}
      disabled={disabled || loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(FundFilter);
