import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../../../core/context/appContextProvider";
import { getStateList } from "../../../../../services/address.service";
import { GENERIC_ERROR_MESSAGE } from "../../../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { Contact } from "../../../../../utils/types/contact.type";
import { ContactAddress } from "../../../../../utils/types/contactDetail.type";
import { Address } from "../../../../../utils/types/contactPermission.type";
import { CountryItem, ListItem } from "../../../../../utils/types/listItems";

export const useContactsEffect = (contact: Contact, informationAlert: any) => {
  const [selectedContactType, setSelectedContactType] = useState("");
  const [selectedLegalType, setSelectedLegalType] = useState("");
  const [hideTaxAddress, setHideTaxAddress] = useState(false);
  const [ContactName, setContactName] = useState("");

  useEffect(() => {
    try {
      setContactName(contact.name);
      if (contact && contact.contactType) {
        setSelectedContactType(contact.contactType);
      }
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  }, [contact]);

  return {
    selectedContactType,
    setSelectedContactType,
    selectedLegalType,
    setSelectedLegalType,
    hideTaxAddress,
    setHideTaxAddress,
    ContactName,
    setContactName,
  };
};

export const useAddressEffect = (addressValues: ContactAddress | Address) => {
  const { informationAlert } = useContext(AppContext);

  const [selectedCountry, setSelectedCountry] = useState(addressValues.country);
  const [selectedState, setSelectedState] = useState(addressValues.state);
  const [addressLine1, setAddressLine1] = useState(addressValues.street1);
  const [city, setCity] = useState(addressValues.city);
  const [stateList, setStateList] = useState<ListItem[]>([]);
  const [zipCode, setZipCode] = useState(addressValues.postalCode);

  useEffectAsync(
    async (isCanceled) => {
      try {
        setSelectedCountry(addressValues.country);
        setAddressLine1(addressValues.street1);
        setCity(addressValues.city);
        setZipCode(addressValues.postalCode);
        setSelectedState(addressValues.state);
        if (selectedCountry) {
          const statesResponse: CountryItem[] = await getStateList(
            selectedCountry
          );

          if (isCanceled()) return;

          setStateList(statesResponse);
        }
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
      }
    },
    [selectedCountry, selectedState, addressValues]
  );

  return {
    stateList,
    selectedCountry,
    selectedState,
    addressLine1,
    city,
    zipCode,
    setZipCode,
    setAddressLine1,
    setSelectedCountry,
    setSelectedState,
    setCity,
  };
};
