import { Checkbox, FormControlLabel, Grid, MenuItem } from '@mui/material';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import {
  AccountStatusTypes,
  SelectedAccount,
} from '../../../../utils/types/arkGLAccount.type';
import { DetailsType } from '../../../../utils/types/form.type';
import { useAccountDetails } from './AccountDetails.hooks';
import {
  KeyLabel,
  KeyValuePair,
  SelectFormControl,
  StatusRadioGroup,
  ValueLabel,
} from './AccountDetails.styles';

interface Props {
  onDetailClose: Function;
  selectedAccount: SelectedAccount;
  fetchAllAccounts: Function;
  fundId: string;
}

type Mapping = Record<AccountStatusTypes, string>;

const StatusType: Mapping = {
  draft: 'yellow',
  posted: 'green',
  unused: 'red',
};

export const AccountDetailsReadOnly: React.FC<Props> = ({
  onDetailClose,
  selectedAccount,
  fetchAllAccounts,
  fundId,
}: Props) => {
  const { type, account } = selectedAccount;
  const isNewAccount = type === DetailsType.New;

  const {
    isLoading,
    closeDrawer,
    toggleDrawer,
    accountList,
    attributeList,
    arkTransactionList,
  } = useAccountDetails({
    account,
    onClose: onDetailClose,
    fetchAllAccounts,
    isNewAccount,
    fundId,
  });

  const attribute = attributeList.find(
    (attribute) => attribute.id === account?.attributeId
  );
  const parentAccount = accountList.find(
    (item) => item.id === account?.parentId
  );
  const fsMappingAccount = accountList.find(
    (item) => item.id === account?.fsMappingId
  );

  const transaction = arkTransactionList.find(
    (item) => item.id === account?.transactionTypeId
  );

  return (
    <>
      <DetailPanel
        id="detail_panel_account"
        title={account?.name}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <FormBox>
          <Panel id="details_panel">
            <Typography variant="h4">Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Account Name</KeyLabel>
                  <ValueLabel>{account?.name || M_DASH_UNICODE}</ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Account Number</KeyLabel>
                  <ValueLabel>{account?.number || M_DASH_UNICODE}</ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Attribute</KeyLabel>
                  <ValueLabel>
                    {(attribute &&
                      attribute.type &&
                      attribute.detail &&
                      `${attribute.type} - ${attribute.detail}`) ||
                      M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6} mt={1}>
                <KeyValuePair>
                  <KeyLabel>Status</KeyLabel>
                  <ValueLabel>
                    {account?.state && (
                      <StatusLabel
                        color={
                          StatusType[
                            account?.state?.toLowerCase() as keyof typeof StatusType
                          ]
                        }
                        label={account?.state}
                      />
                    )}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Description</KeyLabel>
                  <ValueLabel>
                    {account?.description || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                {account?.parentId && (
                  <KeyValuePair>
                    <KeyLabel>Parent Account</KeyLabel>
                    <ValueLabel>
                      {(parentAccount &&
                        parentAccount.number &&
                        parentAccount.name &&
                        `${parentAccount.number} ${parentAccount.name}`) ||
                        M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                )}
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Is Taxable</KeyLabel>
                  <ValueLabel>
                    {account?.isTaxable === false ? 'No' : 'Yes'}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Is Entity Required</KeyLabel>
                  <ValueLabel>
                    {account?.isEntityRequired === false ? 'No' : 'Yes'}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Financial Statement Mapping</KeyLabel>
                  <ValueLabel>
                    {(account?.doNotMap && 'Do Not Map') ||
                      (fsMappingAccount &&
                        fsMappingAccount.number &&
                        fsMappingAccount.name &&
                        `${fsMappingAccount.number} ${fsMappingAccount.name}`) ||
                      M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={6}>
                <KeyValuePair>
                  <KeyLabel>Financial Statement Display Name</KeyLabel>
                  <ValueLabel>
                    {account?.fsDisplayName || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Ark Transaction</KeyLabel>
                  <ValueLabel>
                    {(account?.transactionTypeId === '1' && 'Do Not Map') ||
                      (transaction && transaction.name) ||
                      M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
            </Grid>
          </Panel>
        </FormBox>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
        >
          <Button
            id={'btn_account_close'}
            variant="outlined"
            onClick={closeDrawer}
            text={'Close'}
            color={'secondary'}
            fullWidth
          />
        </HorizontalBox>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_account_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading} account...` : ''}
        />
      </DetailPanel>
    </>
  );
};
