import { PendingItem } from "../utils/types/dashboard.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const getPortalLink = async (clientId: string) => await apiClient().get<string>(`clients/${clientId}/portalLink`);

export const getDashboard = async (clientId: string) => await apiClient().get<PendingItem[]>(`clients/${clientId}/dashboard`);
