import { Box, Drawer, IconButton, styled, Typography } from "@mui/material";

import ImgIcon from "../../../components/ImgIcon/ImgIcon";
import { INFO_PANEL_MAX_WIDTH } from "../../../utils/constants/styles.constants";

export const DetailPanel = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
    display: "grid",
    gridTemplateRows: "12% 76% 12%",
    overflow: "hidden",
  },
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(4),
  maxWidth: INFO_PANEL_MAX_WIDTH,
}));

export const FooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(4),
  maxWidth: INFO_PANEL_MAX_WIDTH,
}));

export const PublishStatusBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PublishStatusText = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const StatementReportBox = styled(Box)(({ theme }) => ({
  minWidth: "720px",
  maxWidth: INFO_PANEL_MAX_WIDTH,
  margin: `${theme.spacing(1)} ${theme.spacing(4)}`,
  overflow: "auto",
}));

export const StatRow = styled(Box)(({ theme }) => ({
  minWidth: "calc(100% - 112px)",
  maxWidth: "calc(100% - 112px)",
  display: "grid",
  gridTemplateColumns: "40% 20% 20% 22%",
  columnGap: theme.spacing(2),
}));

export const StatLabelCell = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.spacing(1)} 0px;
`
);

export const StatValueCell = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${theme.spacing(1)} 0px;
`
);

export const StatCell = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.spacing(1)} 0px`,
  "&.value": {
    justifyContent: "flex-end",
    textAlign: "right",
    cursor: "pointer",
    color: "#007aff",
  },
  "&.right": {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  "&.left": {
    justifyContent: "flex-start",
    marginLeft: theme.spacing(6),
  },
  "&.UNDERLINE": {
    borderBottom: "1px solid black",
  },
  "&.BOLD h4": {
    fontWeight: "bold",
  },
  "&.OVERLINE": {
    borderTop: "1px solid black",
  },
  "&.DOUBLE_UNDERLINE": {
    borderBottom: "5px double black",
  },
}));
export const StyledIcon = styled(ImgIcon)(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  marginLeft: theme.spacing(-2),
}));

export const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  verticalAlign: "middle",
  marginBottom: theme.spacing(1),
}));
