import { InvestorDetail } from '../utils/types/investor.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

export const downloadTemplate = async (templateType: string) => {
    const templateFile = await apiClient().get(`templates/${templateType}`);

    return templateFile;
};