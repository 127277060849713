import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router";

import SplitButton from "../../components/Buttons/SplitButton/SplitButton";
import DataGrid from "../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../components/Modal/ConfirmationDialog";
import { Action } from "../../components/Modal/DeleteConfirmationDialog/constants";
import DeleteConfirmationDialog from "../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../components/SearchBarWithDebounce/SearchBar";
import { DateTimeFormat } from "../../utils/helpers/format.helper";
import { ClientTabs } from "../clientDetails/constants";
import { ButtonBox } from "../investors/investorList/InvestorList.styles";
import {
  ArkClientBox,
  ArkClientBoxIcon,
  Container,
  FilterRow,
  HeaderBreadcrumbContainer,
  HeaderRow,
  HeaderTitleContainer,
} from "./Clients.styles";
import { useClients } from "./useClients.hooks";

const Clients: React.FC = (): ReactElement => {
  const history = useHistory();
  const date = DateTimeFormat.getFormattedDate(new Date(), "_");
  const {
    clientSelectionModel,
    setClientSelectionModel,
    headerList,
    loadingClients,
    list,
    bulkActions,
    handleBulkActions,
    csvLinkRef,
    csvHeaders,
    csvData,
    resetCsvData,
    handleUpdateHeader,
    search,
    onSearch,
    handleOnView,
    selectedClientArkTag,
    setSelectedClientArkTag,
    loading,
    statusUpdateClient,
    discardStatusUpdate,
    updateStatus,
    handleClose,
    onDeleteCancel,
    onDeleteConfirm,
    toBeDeleted,
    isSuperAdmin,
  } = useClients();

  return (
    <Container
      id="clients_drawer"
      anchor="right"
      open={true}
      onClose={handleClose}
    >
      <Box id="client_list_main">
        <Progress
          id="client_detail_list_loading"
          showProgress={loadingClients || loading}
        />
        <HeaderRow>
          <HeaderTitleContainer>
            {isSuperAdmin && selectedClientArkTag && (
              <IconButton
                onClick={() => setSelectedClientArkTag(undefined)}
                aria-label="Back"
              >
                <ArrowBackIosRoundedIcon color="primary" fontSize="large" />
              </IconButton>
            )}
            <HeaderBreadcrumbContainer>
              <Typography variant="pageTitle">Clients</Typography>
              {isSuperAdmin && selectedClientArkTag && (
                <>
                  <ArrowForwardIosRoundedIcon color="action" />
                  <ArkClientBox>
                    <ArkClientBoxIcon color="secondary" />
                    <Typography>{selectedClientArkTag}</Typography>
                  </ArkClientBox>
                </>
              )}
            </HeaderBreadcrumbContainer>
            <Box />
          </HeaderTitleContainer>
          <IconButton
            aria-label="Close Client"
            color="inherit"
            id="close_client_drawer"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </HeaderRow>
        <FilterRow>
          <SearchBar id="clients_search" onChange={onSearch} value={search} />
          <ButtonBox>
            <SplitButton
              id={"btn_bulk_action_options"}
              options={bulkActions}
              hidden={false}
              handleOptionClick={handleBulkActions}
              ariaLabelMessage="Select bulk action option"
            />
            <Button
              id="add_client_button"
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(
                  `/clients/new/${selectedClientArkTag ?? "new"}/${
                    ClientTabs.ClientDetails
                  }`
                );
              }}
              startIcon={<AddIcon />}
            >
              Add New Client
            </Button>
          </ButtonBox>
        </FilterRow>

        <DataGrid
          id="client_data_grid"
          dataList={list || []}
          headerList={headerList || []}
          minHeight="71vh"
          noDataMessage={
            loadingClients || loading ? "Fetching Data..." : undefined
          }
          autoHeight={false}
          handleOnView={handleOnView}
          selectionModel={clientSelectionModel}
          setSelectionModel={setClientSelectionModel}
          activeHeaderFields={6}
          handleUpdateHeader={handleUpdateHeader}
        />
      </Box>
      <ConfirmationDialog
        open={!!statusUpdateClient}
        onClose={discardStatusUpdate}
        id="toggle_client_status_confirmation"
        actions={[
          {
            label: "Update Status",
            onClick: updateStatus,
            id: "toggle_client_status_confirmation_update",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: discardStatusUpdate,
            id: "toggle_client_status_confirmation_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`Are you sure you want to turn ${
          statusUpdateClient?.active ? "off" : "on"
        } ${statusUpdateClient?.name ?? ""}?`}
        title="Update Status?"
      />
      <DeleteConfirmationDialog
        open={Boolean(toBeDeleted)}
        id="delete_client_confirmation"
        title="Delete Client?"
        content={`Are you sure you want to delete ${toBeDeleted?.name ?? ""}?`}
        primaryButtonAction={onDeleteConfirm}
        actionType={toBeDeleted?.name ?? Action.DELETE}
        secondaryButtonAction={onDeleteCancel}
      />
      <CSVLink
        ref={csvLinkRef}
        headers={csvHeaders}
        data={csvData}
        filename={`Ark_Clients_List_${date}`}
        onClick={resetCsvData}
      />
    </Container>
  );
};

export default React.memo(Clients);
