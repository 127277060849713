import { Contact } from "../utils/types/contact.type";
import { ContactDetail } from "../utils/types/contactDetail.type";
import { ContactPermission } from "../utils/types/contactPermission.type";
import { FundSimple } from "../utils/types/fund.type";
import { InvestorFilter } from "../utils/types/investor.type";
import { FilterItem } from "../utils/types/listItems";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const getContacts = async (
  fundFilters: FundSimple[],
  statusFilters: any[],
  investorFilters: InvestorFilter[] | Array<string>,
  roleFilters: FilterItem[],
  searchFilter: string,
  offset: number = 0,
  pageSize : number=50,
  isAuth0: boolean,
) => {
  const body = {
    fundFilter: getIdList(fundFilters),
    investorFilter: typeof investorFilters[0] === "string" ? investorFilters: getIdList(investorFilters),
    roleFilter: getIdList(roleFilters),
    statusFilter: getNameList(statusFilters),
    searchText: searchFilter,
    offset: offset,
    pageSize: pageSize,
  };

  const contactsResponse = await apiClient().post<Contact[]>(
    "contacts/all",
    body
  );

  if(isAuth0) {
    await Promise.all(
      contactsResponse.map(async (c) => {
        c.loginStatus.locked = await getContactLockStatus(c.id);
      })
    );
  }

  return contactsResponse;
};

const getContactLockStatus = async (contactId: string) => {
  const statusResponse = await apiClient().get<any>(`contacts/${contactId}/locks`);

  return statusResponse.locked;
};

export const removeContactLock = async (contactIDs: string) => {
  await apiClient().delete<{locked: boolean}>(`contacts/${contactIDs}/locks`);
};

export const getContactFilterList = async ()=>{
  const contactsResponse = await apiClient().get<Contact[]>("contacts/list");

  return contactsResponse;
};

export const getContactInvestmentsByPage = async (
  contactId: string,
  fundFilters: FundSimple[],
  investorFilters: InvestorFilter[],
  roleFilters: FilterItem[]
) => {
  const body = {
    contactId: contactId,
    offset: 0,
    pageSize: 100000,
    fundFilter: getIdList(fundFilters),
    investorFilter: getIdList(investorFilters),
    roleFilter: getIdList(roleFilters),
  };

  const investmentsResponse = await apiClient().post<ContactPermission>(
    `contacts/${contactId}/investments`,
    body
  );

  return investmentsResponse;
};

const getIdList = (filterList: any) => {
  const itemIds: string[] = filterList
    ? filterList.map((item: any) => {
        return item.id || item;
      })
    : [];

  return itemIds;
};

export const getContactRoleFilter = async (clientId: string) => {
  const roleList = await apiClient().get<FilterItem[]>(
    `contacts/roles/client/${clientId}`
  );

  return roleList;
};

const getNameList = (filterList: any) => {
  const fundIds: string[] = filterList
    ? filterList.map((fund: any) => {
        return fund.name;
      })
    : [];

  return fundIds;
};

export const approveContact = async (contactIdList: string[]) => {
  const investmentsResponse = await apiClient().post<ContactPermission>(
    `contacts/approve`,
    contactIdList
  );

  return investmentsResponse;
};

export const inactiveContact = async (contactIdList: string[]) => {
  const investmentsResponse = await apiClient().patch<any>(
    `contacts/status/inactivate`,
    contactIdList
  );

  return investmentsResponse;
};

export const activeContact = async (contactIdList: string[]) => {
  const investmentsResponse = await apiClient().patch<any>(
    `contacts/status/activate`,
    contactIdList
  );

  return investmentsResponse;
};

export const getContact = async (contactId: string) => {
  const contact = await apiClient().get<ContactDetail>(`contacts/${contactId}`);

  return contact;
};

export const updateContact = async (contact: ContactDetail) => {
  const updateInvestorResponse = await apiClient().post("contacts", contact);

  return updateInvestorResponse;
};

export const approvePendingContacts = async (contactIdList: string[]) => {
  const approveResponse = await apiClient().post(
    "contacts/approve",
    contactIdList
  );

  return approveResponse;
};

export const emailContacts = async (contactIdList: string[]) => {
  const emailResponse = await apiClient().post("contacts/email", contactIdList);

  return emailResponse;
};

export const updateExistingContact = async (
  savingObject: any,
  contactId: string
) => {
  const updateContactResponse = await apiClient().put(
    `contacts/${contactId}`,
    savingObject
  );

  return updateContactResponse;
};

export const getContactFunds = async (contactId: string) => {
  const FundListResponse = await apiClient().get(
    `contacts/distinct/funds/${contactId}`
  );

  return FundListResponse;
};

export const getContactInvestors = async (contactId: string) => {
  const InvestorListResponse = await apiClient().get(`contacts/distinct/investors/${contactId}`);

  return InvestorListResponse;
};

export const saveNewContact = async (savingObject: any) => {
  const savingContactResponse = await apiClient().post("contacts", savingObject);

  return savingContactResponse;
};

export const checkUniqueEmail = async (email: string, contactId: string) => {
  const uniqueEmailResponse = await apiClient().get(`contacts/${email}/available?contactId=${contactId}`);

  return uniqueEmailResponse;
};

export const declineChange = async(contactId: string) => {
  const declineChange = await apiClient().post(`contacts/decline`, {
    "contactId": `${contactId}`
  });

  return declineChange;
};

export const deleteContact = async(contactId: string) => {
  return await apiClient().delete(`contacts/${contactId}`);
};

export async function exportContacts(
  params: any
): Promise<Blob> {
  return await apiClient().post(
    `downloads`,
    params,
    {
      responseType: "blob",
    }
  );
}

export async function getAvailableRoles(): Promise<any> {
  return await apiClient().get('/clients/roles/grouped');
}