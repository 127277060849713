import { FormControlLabel, Link } from '@mui/material';
import {
  GridAlignment,
  GridCallbackDetails,
  GridColDef,
  GridColumnOrderChangeParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import {
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ChipCellStack from '../../../components/DataGrid/ChipsCell/ChipCellStack';
import { StringCell } from '../../../components/DataGrid/DataGrid.styles';
import { ActionLink } from '../../../components/Link/ActionLink/ActionLink';
import Switch from '../../../components/Switch/Switch';
import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../services/columnOrder.service';
import {
  getFundsFilterList,
  getStatusFilterList,
} from '../../../services/filters.service';
import { useFundFilterEffect } from '../../../services/hooks/useFundFilterEffect/useFundFilterEffect';
import { useInvestorsFilterEffect } from '../../../services/hooks/useInvestorsFilterEffect/useInvestorsFilterEffect.hooks';
import {
  deleteInvestors,
  downloadFileInvestor,
  getInvestor,
  getInvestorList,
  updateInvestorStatus,
} from '../../../services/investor.service';
import {
  checkMFASettings,
  getCurrentEnv,
  getUserPhoneDetails,
  isDeviceTrusted,
} from '../../../services/login.service';
import { downloadTemplate } from '../../../services/template.service';
import { uploadFile } from '../../../services/uploads.service';
import {
  GENERIC_ERROR_MESSAGE,
  INVESTORS_COLUMN_ORDER_VIEW_KEY,
} from '../../../utils/constants/text.constants';
import { arrayIndexUpdate as updateArrayIndex } from '../../../utils/helpers/columnOrder.helper';
import { arrayVisibilityUpdate } from '../../../utils/helpers/columnOrder.helper';
import downloadFile from '../../../utils/helpers/fileDownloader';
import {
  CurrencyFormat,
  NumberFormat,
} from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { MFASettingsResponse } from '../../../utils/types/client.type';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import {
  AddNewButtonOptions,
  DetailViewType,
} from '../../../utils/types/common.type';
import { Investor, SelectedInvestor } from '../../../utils/types/investor.type';
import { DataGridColDef, ImageItem } from '../../../utils/types/listItems';
import { StatusFilter } from '../../../utils/types/statusFilter.type';
import { MfaInfo, ScopeRole } from '../../../utils/types/user.type';
import {
  DELETE_INVESTOR_SUCCESS,
  DOWNLOADED_TEMPLATE_INVESTOR_FILE_NAME,
  GET_INVESTOR_DETAILS_ERROR,
} from './InvestorList.constants';

const CONTACT_VISIBLE_HEADERS = [
  'Investor Name',
  'Status',
  'Funds',
  'Commitment Total',
  'City',
  'Country',
  'Investor Type',
  'Legal Type',
  'Columns',
];

enum InvestorFilter {
  FundName = 'fundName',
  InvestorName = 'investorName',
  Status = 'status',
  InvestorType = 'investorType',
  InvestorLegalType = 'investorLegalType',
  InvestorMailAddressState = 'investorMailAddressState',
  InvestorTaxAddressState = 'InvestorTaxAddressState',
  InvestorTaxAddressCountry = 'InvestorTaxAddressCountry',
}

enum CustomType {
  Currency = 'currency',
  PositiveCurrency = 'positiveCurrency',
  Action = 'action',
}

type InvestorType = {
  id: string;
  label: string;
};

type LegalType = {
  id: string;
  label: string;
};

type MailStateType = {
  id: string;
  state: string;
};

type AddressCountryType = {
  id: string;
  country: string;
};

const defaultHeaderList: DataGridColDef[] = [
  {
    field: 'phone',
    headerName: 'Phone',
    hide: false,
    index: 2,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: 'fax',
    headerName: 'Fax',
    hide: false,
    index: 3,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: 'mailingAddress.street1',
    headerName: 'Address Line 1',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 7,
    valueGetter: (params: any) => {
      return params.row.mailingAddress.street1;
    },
  },
  {
    field: 'mailingAddress.street2',
    headerName: 'Address Line 2',
    index: 8,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    valueGetter: (params: any) => {
      return params.row.mailingAddress.street2;
    },
  },
  {
    field: 'mailingAddress.city',
    headerName: 'City',
    index: 9,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    valueGetter: (params: any) => {
      return params.row.mailingAddress.city;
    },
  },
  {
    field: 'mailingAddress.country',
    headerName: 'Country',
    index: 11,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    valueGetter: (params: any) => {
      return params.row.mailingAddress.country;
    },
  },
  {
    field: 'mailingAddress.postalCode',
    headerName: 'ZIP/Post Code',
    index: 12,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    valueGetter: (params: any) => {
      return params.row.mailingAddress.postalCode;
    },
  },
  {
    field: 'taxIdentificationNumber',
    headerName: 'TIN',
    index: 13,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: 'disregardedEntity',
    headerName: 'Disregarded Entity',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 16,
    renderCell: (params: any) => {
      return params.row?.disregardedEntity === false ? 'No' : 'Yes';
    },
  },
  {
    field: 'ownerName',
    headerName: 'Owner Name',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 17,
  },
  {
    field: 'ownerSSN',
    headerName: 'Owner SSN',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 18,
    valueGetter: (params: any) => {
      return params.row.ownerSSN;
    },
  },
  {
    field: 'taxAddress.street1',
    headerName: 'Tax Address Line 1',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 19,
    valueGetter: (params: any) => {
      return params.row.taxAddress.street1;
    },
  },
  {
    field: 'taxAddress.street2',
    headerName: 'Tax Address Line 2',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 20,
    valueGetter: (params: any) => {
      return params.row.taxAddress.street2;
    },
  },
  {
    field: 'taxAddress.city',
    headerName: 'Tax City',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 21,
    valueGetter: (params: any) => {
      return params.row.taxAddress.city;
    },
  },
  {
    field: 'taxAddress.postalCode',
    headerName: 'Tax ZIP/Post Code',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 24,
    valueGetter: (params: any) => {
      return params.row.taxAddress.postalCode;
    },
  },
  {
    field: 'wireInfo.receivingBankInfo.abaNumber',
    headerName: 'Receiving Bank ABA/Swift',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 25,
    valueGetter: (params: any) => {
      return params.row.wireInfo.receivingBankInfo.abaNumber;
    },
  },
  {
    field: 'wireInfo.receivingBankInfo.name',
    headerName: 'Receiving Bank Name',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 26,
    valueGetter: (params: any) => {
      return params.row.wireInfo.receivingBankInfo.name;
    },
  },
  {
    field: 'wireInfo.receivingBankInfo.addressLine1',
    headerName: 'Receiving Bank Address Line 1',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 27,
    valueGetter: (params: any) => {
      return params.row.wireInfo.receivingBankInfo.addressLine1;
    },
  },

  {
    field: 'wireInfo.receivingBankInfo.addressLine2',
    headerName: 'Receiving Bank Address Line 2',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 28,
    valueGetter: (params: any) => {
      return params.row.wireInfo.receivingBankInfo.addressLine2;
    },
  },
  {
    field: 'wireInfo.beneficiaryAccount',
    headerName: 'Beneficiary Account',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 29,
    valueGetter: (params: any) => {
      return params.row.wireInfo.beneficiaryAccount;
    },
  },
  {
    field: 'wireInfo.beneficiaryName',
    headerName: 'Beneficiary Name',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 30,
    valueGetter: (params: any) => {
      return params.row.wireInfo.beneficiaryName;
    },
  },
  {
    field: 'wireInfo.beneficiaryAddresLine1',
    headerName: 'Beneficiary Address Line 1',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 31,
    valueGetter: (params: any) => {
      return params.row.wireInfo.beneficiaryAddresLine1;
    },
  },
  {
    field: 'wireInfo.beneficiaryBankInfo.abaNumber',
    headerName: 'Beneficiary Bank ABA/Swift',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 32,
    valueGetter: (params: any) => {
      return params.row.wireInfo.beneficiaryBankInfo.abaNumber;
    },
  },
  {
    field: 'wireInfo.beneficiaryBankInfo.name',
    headerName: 'Beneficiary Bank Name',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 33,
    valueGetter: (params: any) => {
      return params.row.wireInfo.beneficiaryBankInfo.name;
    },
  },
  {
    field: 'wireInfo.intermediaryBankInfo.abaNumber',
    headerName: 'Intermediary Bank ABA/Swift',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 34,
    valueGetter: (params: any) => {
      return params.row.wireInfo.intermediaryBankInfo.abaNumber;
    },
  },
  {
    field: 'wireInfo.intermediaryBankInfo.addressLine1',
    headerName: 'Intermediary Bank Address Line 1',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 35,
    valueGetter: (params: any) => {
      return params.row.wireInfo.intermediaryBankInfo.addressLine1;
    },
  },
  {
    field: 'wireInfo.intermediaryBankInfo.addressLine2',
    headerName: 'Intermediary Bank Address Line 2',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 36,
    valueGetter: (params: any) => {
      return params.row.wireInfo.intermediaryBankInfo.addressLine2;
    },
  },
  {
    field: 'wireInfo.originatorToBeneficiaryInstructions1',
    headerName: 'Originator To Beneficiary Instructions',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 37,
    valueGetter: (params: any) => {
      return params.row.wireInfo.originatorToBeneficiaryInstructions1;
    },
  },
  {
    field: 'wireInfo.bankToBankInstructions1',
    headerName: 'Bank To Bank Instructions',
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
    index: 38,
    valueGetter: (params: any) => {
      return params.row.wireInfo.bankToBankInstructions1;
    },
  },
  {
    field: 'action',
    headerName: 'Columns',
    hide: false,
    hideable: false,
    index: 39,
    type: 'action',
    customType: CustomType.Action,
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];

const initialInvestor: SelectedInvestor = {
  investorListItem: undefined,
  investor: undefined,
  detailView: undefined,
};

export const useInvestorListEffect = (clientId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [investorDataList, setInvestorsList] = useState<Investor[]>();
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [page, setPage] = useState<number>(1);
  const [originalInvestorList, setOriginalInvestorList] =
    useState<Investor[]>();
  const [investorTypeList, setInvestorTypeList] = useState<InvestorType[]>([]);
  const [investorLegalTypeList, setInvestorLegalTypeList] = useState<
    InvestorType[]
  >([]);
  const [investorMailStateList, setInvestorMailStateList] = useState<
    MailStateType[]
  >([]);
  const [investorTaxAddressStateList, setInvestorTaxAddressStateList] =
    useState<MailStateType[]>([]);

  const [investorTaxAddressCountryList, setInvestorTaxAddressCountryList] =
    useState<AddressCountryType[]>([]);

  const [statusList, setStatusList] = useState<StatusFilter[]>();
  const { investorsList } = useInvestorsFilterEffect();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [sugestionList, setSugestionList] = useState<string[]>([]);
  const [isLoadingInvestorList, setLoadingInvestorList] = useState(false);
  const [investorListLoaded, setInvestorListLoaded] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState('');
  const [selectedInvestor, setSelectedInvestor] =
    useState<SelectedInvestor>(initialInvestor);
  const [selectedInvestorListItem, setSelectedInvestorListItem] =
    useState<Investor | null>();
  const [investorSelectionModel, setInvestorSelectionModel] = useState<
    string[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const [showActivateConfirmationPopover, setShowActivateConfirmationPopover] =
    useState(false);
  const [showDeleteConfirmationPopover, setShowDeleteConfirmationPopover] =
    useState(false);
  const [clientMfaSettings, setClientMfaSettings] =
    useState<MFASettingsResponse>();
  const [userMfaInfo, setUserMfaInfo] = useState<MfaInfo>();
  const [showMfaModal, setShowMfaModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();

  const { fundList, loading, setFundList } = useFundFilterEffect();

  const funds = useMemo(() => fundList?.map((item) => item.id), [fundList]);
  const selectedInvestorType = useMemo(
    () => investorTypeList?.map((item) => item.id),
    [investorTypeList]
  );

  const selectedTaxAddressState = useMemo(
    () => investorTaxAddressStateList?.map((item) => item.state),
    [investorTaxAddressStateList]
  );

  const selectedTaxAddressCountry = useMemo(
    () => investorTaxAddressCountryList?.map((item) => item.country),
    [investorTaxAddressCountryList]
  );

  const emailState = useMemo(
    () => investorMailStateList?.map((item) => item.id),
    [investorMailStateList]
  );

  const selectedInvestorLegalType = useMemo(
    () => investorLegalTypeList?.map((item) => item.id),
    [investorLegalTypeList]
  );

  const status = useMemo(
    () => statusList?.map((item) => item.id),
    [statusList]
  );

  const investors = useMemo(
    () => investorsList?.map((item) => item.id),
    [investorsList]
  );

  const [filteredInvestorList, setFilteredInvestorList] =
    useState<Investor[]>();

  const [investorColumnOrder, setInvestorColumnOrder] =
    useState<ColumnOrder | null>(null);
  const stateRef = useRef(isActivating);
  const {
    informationAlert,
    preAuthClientConfigs,
    state: {
      loginUser: { email },
    },
  } = useContext(AppContext);

  const onSingleStatusChange = (investorId: string) => {
    const selectedItem = investorDataList?.find(
      (item: any) => item.id === investorId
    );

    if (selectedItem) {
      setIsActivating(selectedItem.status !== 'ACTIVE');
      stateRef.current = selectedItem.status !== 'ACTIVE';
      setShowActivateConfirmationPopover(true);
      setInvestorSelectionModel([investorId]);
    }
  };

  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);

  const { hasRole: superAdminORClientAdminUser } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const { hasRole: basicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: fundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: clientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const fundColumnHeader: any = {
    field: 'funds',
    headerName: 'Funds',
    hide: false,
    index: 5,
    sortable: true,
    type: 'chips',
    inlineFilter: true,
    inlineFilterName: InvestorFilter.FundName,
    inlineFilterIDField: 'id',
    inlineFilterLabelField: 'name',
    inlineFilterOptions: fundList,
    inlineFilterSelected: funds,
    emptySelectionOnClear: false,
    chipLabelField: 'name',
    chipIDField: 'id',
    align: 'left' as GridAlignment,
    width: 200,
    sortComparator: (v1: any, v2: any) => {
      const firstTag1 = v1[0].name || '';
      const firstTag2 = v2[0].name || '';

      return firstTag1.localeCompare(firstTag2);
    },
  };
  const investorTypeColHeader: any = {
    field: 'label',
    headerName: 'Investor Type',
    hide: false,
    index: 14,
    sortable: true,
    type: 'chips',
    inlineFilter: !!investorTypeList?.length,
    inlineFilterName: InvestorFilter.InvestorType,
    inlineFilterIDField: 'id',
    inlineFilterLabelField: 'label',
    inlineFilterOptions: investorTypeList,
    inlineFilterSelected: selectedInvestorType,
    chipLabelField: 'label',
    chipIDField: 'id',
    align: 'left' as GridAlignment,
    width: 200,
  };
  const initializeHeaderList = () => {
    const [firstHeader, ...remainingHeaders] = defaultHeaderList;
    let updatedHeaders = [
      firstHeader,
      {
        field: 'name',
        headerName: 'Investor Name',
        hide: false,
        index: 1,
        sortable: true,
        type: 'string',
        inlineFilter: true,
        inlineFilterName: InvestorFilter.InvestorName,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: investorsList,
        inlineFilterSelected: investors,
        emptySelectionOnClear: false,
        align: 'left' as GridAlignment,
        width: 200,
        renderCell: (params: any) => {
          return (
            <ActionLink
              id={`link_investor_${params.row.id}`}
              onClick={() => handleOnView(params.row?.id, params.row)}
            >
              {params.value}
            </ActionLink>
          );
        },
      },
      {
        field: 'mailingAddress.state',
        headerName: 'State/Province',
        index: 10,
        sortable: true,
        hide: false,
        type: 'string',
        inlineFilter: true,
        inlineFilterName: InvestorFilter.InvestorMailAddressState,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'state',
        inlineFilterOptions: investorMailStateList,
        inlineFilterSelected: emailState,
        emptySelectionOnClear: false,
        align: 'left' as GridAlignment,
        width: 200,
        valueGetter: (params: any) => {
          return params.row.mailingAddress.state;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        hide: false,
        index: 4,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        valueGetter: (params: any) => {
          if (params.row.pendingWireInfo) return 'PENDING';
          return params.row.status;
        },
        renderCell: (params: any) => {
          return params?.row?.pendingWireInfo ? (
            <div>PENDING</div>
          ) : fundAdmin || clientPortalUser ? (
            <div>{params.row?.status}</div>
          ) : (
            <FormControlLabel
              control={
                <Switch
                  label="Status switch"
                  id={`switch_status_${params.row?.id}_switch`}
                  checked={params.row?.status?.toLowerCase() === 'active'}
                  onChange={() => onSingleStatusChange(params.row?.id)}
                />
              }
              label={params.row?.status}
            />
          );
        },
        inlineFilter: true,
        inlineFilterName: InvestorFilter.Status,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: statusList,
        inlineFilterSelected: status,
        emptySelectionOnClear: false,
      },
      {
        inlineFilter: true,
        inlineFilterName: InvestorFilter.InvestorLegalType,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'label',
        field: 'legalType.label',
        headerName: 'Legal Type',
        index: 15,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        inlineFilterOptions: investorLegalTypeList,
        inlineFilterSelected: selectedInvestorLegalType,
        emptySelectionOnClear: false,
        valueGetter: (params: any) => {
          return params.row.legalType;
        },
      },
      {
        inlineFilter: true,
        inlineFilterName: InvestorFilter.InvestorTaxAddressState,
        inlineFilterIDField: 'state',
        inlineFilterLabelField: 'state',
        inlineFilterOptions: investorTaxAddressStateList,
        inlineFilterSelected: selectedTaxAddressState,
        emptySelectionOnClear: false,
        field: 'taxAddress.state',
        headerName: 'Tax State/Province',
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        index: 22,
        valueGetter: (params: any) => {
          return params.row.taxAddress.state;
        },
      },
      {
        inlineFilter: true,
        inlineFilterName: InvestorFilter.InvestorTaxAddressCountry,
        inlineFilterIDField: 'country',
        inlineFilterLabelField: 'country',
        inlineFilterOptions: investorTaxAddressCountryList,
        inlineFilterSelected: selectedTaxAddressCountry,
        emptySelectionOnClear: false,
        field: 'taxAddress.country',
        headerName: 'Tax Country',
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        index: 23,
        valueGetter: (params: any) => {
          return params.row.taxAddress.country;
        },
      },
      {
        ...investorTypeColHeader,
        renderCell: (params: any) => {
          const uniqueTypes: any[] = [];

          params.row?.fundInvestorTypes?.forEach((t: any) => {
            if (!uniqueTypes.find((ut) => ut.id === t.investorType.id)) {
              uniqueTypes.push(t.investorType);
            }
          });

          return (
            <ChipCellStack
              header={investorTypeColHeader}
              items={uniqueTypes}
              row={params?.row}
            />
          );
        },
        valueGetter: (params: any) => {
          const uniqueTypes: any[] = [];

          params.row?.fundInvestorTypes?.forEach((t: any) => {
            if (!uniqueTypes.find((ut) => ut.id === t.investorType.id)) {
              uniqueTypes.push(t.investorType);
            }
          });
          return uniqueTypes;
        },
        sortComparator: (v1: any, v2: any) => {
          const firstTag1 = v1[0].label || '';
          const firstTag2 = v2[0].label || '';

          return firstTag1.localeCompare(firstTag2);
        },
      },
      {
        ...fundColumnHeader,
        renderCell: (params: any) => {
          return (
            <ChipCellStack
              header={fundColumnHeader}
              items={params.row?.funds ?? []}
              row={params?.row}
            />
          );
        },
      },
      {
        field: 'committedAmount',
        headerName: 'Commitment Total',
        hide: false,
        index: 6,
        sortable: false,
        type: 'string',
        align: 'right' as GridAlignment,
        customType: CustomType.PositiveCurrency,
        width: 200,
        renderCell: (params: any) => {
          const row: Investor = params.row;
          const {
            // @ts-ignore
            fundFilters, // filters for all investors in grid
            funds, // funds that current investor belongs to
            committedAmount, // committed amount for current investor
          } = row;

          let sum = 0;
          let currency = funds?.length ? funds[0].currency : undefined;

          if (currency && fundFilters?.length) {
            fundFilters.forEach((fundId: string) => {
              const fund = funds.find((f) => f.id === fundId);

              if (!fund) return; // we only want to count funds that the current investor belongs too

              if (fund?.currency !== currency) {
                currency = undefined;
              }

              const committed = committedAmount.find(
                (ca) => ca.fundId === fundId
              );

              sum += committed ? committed.amount : 0;
            });
          }

          if (currency) {
            const formatter = currency ? CurrencyFormat(currency, 0) : null;

            return (
              <StringCell>{formatter ? formatter.format(sum) : sum}</StringCell>
            );
          } else {
            return <StringCell>{NumberFormat(2, true).format(sum)}</StringCell>;
          }
        },
      },
      ...remainingHeaders,
    ];

    if (clientPortalUser) {
      /**
       * Hide Fund Column for client portal user ARK-643
       */
      updatedHeaders = updatedHeaders.filter(
        (header) => header.headerName !== 'Funds'
      );
    }

    let sortedHeaders: any;

    if (investorColumnOrder && investorColumnOrder.viewItems) {
      updatedHeaders.map((header) => {
        const item = investorColumnOrder.viewItems?.find(
          (item) => item.code === header.field
        );

        if (item && item?.code !== 'action') {
          header.index = item?.order;
          header.hide = !item?.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );

      if (investorColumnOrder.viewItems.length !== updatedHeaders.length) {
        sortedHeaders = sortedHeaders.map((header: any, idx: number) => {
          header.index = idx + 1;
          return header;
        });
      }

      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1, item2) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: InvestorFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === InvestorFilter.FundName) {
          inlineFilterSelected = funds;
        } else if (inlineFilterName === InvestorFilter.InvestorName) {
          inlineFilterSelected = investors;
        } else if (inlineFilterName === InvestorFilter.Status) {
          inlineFilterSelected = status;
        } else if (inlineFilterName === InvestorFilter.InvestorType) {
          inlineFilterSelected = selectedInvestorType;
        } else if (inlineFilterName === InvestorFilter.InvestorLegalType) {
          inlineFilterSelected = selectedInvestorLegalType;
        } else if (
          inlineFilterName === InvestorFilter.InvestorMailAddressState
        ) {
          inlineFilterSelected = emailState;
        } else if (
          inlineFilterName === InvestorFilter.InvestorTaxAddressState
        ) {
          inlineFilterSelected = selectedTaxAddressState;
        } else if (
          inlineFilterName === InvestorFilter.InvestorTaxAddressCountry
        ) {
          inlineFilterSelected = selectedTaxAddressCountry;
        }

        return {
          ...header,
          hide:
            header.field === field &&
              !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
        flex:
          header.field !== 'action' && activeFields.length < 6 ? 1 : undefined,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(
        updatedHeaders.sort((item1, item2) => item1.index - item2.index)
      );
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      INVESTORS_COLUMN_ORDER_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const handleFilter = (filterName: InvestorFilter, selected: string[]) => {
    setPage(1);
    setHeaderList((prevHeaderList) =>
      prevHeaderList
        ?.map((header) => {
          if (header.inlineFilterName === filterName) {
            if (header.inlineFilterName === InvestorFilter.FundName) {
              const appliedFilters = selected.length
                ? selected
                : header.inlineFilterOptions?.map((option) => option.id);

              return {
                ...header,
                inlineFilterSelected: appliedFilters,
                renderCell: (params: any) => {
                  const filteredFund = params.row?.funds?.filter((fund: any) =>
                    appliedFilters?.includes(fund.id)
                  );

                  return (
                    <ChipCellStack
                      header={fundColumnHeader}
                      items={filteredFund ?? []}
                      row={params?.row}
                    />
                  );
                },
              };
            }
            if (header.inlineFilterName === InvestorFilter.InvestorType) {
              const appliedFilters = selected.length
                ? selected
                : header.inlineFilterOptions?.map((option) => option.id);

              return {
                ...header,
                inlineFilterSelected: appliedFilters,
                renderCell: (params: any) => {
                  const uniqueTypes: any[] = [];

                  params.row?.fundInvestorTypes?.map((t: any) => {
                    if (
                      !uniqueTypes.find((ut) => ut.id === t.investorType.id)
                    ) {
                      appliedFilters?.includes(t.investorType.id)
                        ? uniqueTypes.push(t.investorType)
                        : '';
                    }
                  });

                  return (
                    <ChipCellStack
                      header={investorTypeColHeader}
                      items={uniqueTypes ?? []}
                      row={params?.row}
                    />
                  );
                },
              };
            }
            return {
              ...header,
              inlineFilterSelected: selected.length
                ? selected
                : header.inlineFilterOptions?.map((option) => option.id),
            };
          }
          return header;
        })
        .sort((item1, item2) => item1.index - item2.index)
    );
  };

  useEffect(() => {
    initializeHeaderList();
  }, [
    investors,
    funds,
    status,
    investorDataList,
    investorTypeList,
    investorLegalTypeList,
    investorMailStateList,
    investorTaxAddressStateList,
    investorTaxAddressCountryList,
    investorColumnOrder,
  ]);

  useEffectAsync(async (isCanceled) => {
    if(preAuthClientConfigs?.isAuth0User) return;

    if (isBasicUser) {
      const [mfa, phoneDetail, isTrusted, currentEnv]: any =
        await Promise.all([
          checkMFASettings(preAuthClientConfigs?.clientId as string),
          getUserPhoneDetails(),
          isDeviceTrusted(),
          getCurrentEnv(),
        ]);

        if (isCanceled()) return;
        setUserMfaInfo((prev: any) => ({
          ...prev,
          userEmail: email,
          phoneNumber: phoneDetail?.phoneNumber,
          trustedDevice: isTrusted?.trusted,
          isDevEnv: currentEnv === 'dev',
        }));
        setClientMfaSettings(mfa);
      }
    },
    [preAuthClientConfigs?.clientId]
  );

  useEffectAsync(
    async (isCanceled) => {
      if (isSubmitting) return;
      try {
        if (isCanceled?.()) return;

        setLoadingInvestorList(true);
        const [investorResponse, columnOrder, statusResponse, fundsResponse] =
          await Promise.all([
            await getInvestorList(),
            await getColumnOrder(INVESTORS_COLUMN_ORDER_VIEW_KEY, clientId),
            await getStatusFilterList(),
            await getFundsFilterList(),
          ]);

        const sortedInvestorList = investorResponse.reduce(
          (acc: any, element: any) => {
            if (element?.pendingWireInfo) {
              return [element, ...acc];
            }
            return [...acc, element];
          },
          []
        );

        sortedInvestorList.forEach((investor) => {
          if (investor.pendingWireInfo) investor.status = 'PENDING';
        });

        setNameToDisplayForIndividuals(sortedInvestorList);
        setInvestorsList(sortedInvestorList);
        setOriginalInvestorList(sortedInvestorList);
        setFilteredInvestorList(sortedInvestorList);
        setInvestorColumnOrder(columnOrder || null);
        setStatusList(statusResponse);
        setFundList(fundsResponse);

        const sugestions: SetStateAction<string[] | undefined> = [];

        sortedInvestorList.forEach((item) => {
          sugestions.push(item.name);
        });

        const listInvestorType: InvestorType[] = Array.from(
          sortedInvestorList
            .flatMap((item) =>
              item.fundInvestorTypes?.map(
                (fundInvestorType: any) => fundInvestorType.investorType
              ) || []
            )
            .reduce((acc, investorType) => {
              if (investorType?.id) {
                acc.set(investorType.id, investorType);
              }
              return acc;
            }, new Map<string, { id: string; label: string }>())
            .values()
        );

        const listInvestorLegalType: LegalType[] = sortedInvestorList
          ?.map((p: { legalType: any }) => p.legalType) //get status property
          .filter(
            (item: InvestorType, index: any, ar: any[]) =>
              ar.findIndex((m) => m?.id === item?.id && m) === index
          );

        const listInvestorMailAddressState: MailStateType[] = sortedInvestorList
          ?.map((p: { mailingAddress: any }) => p.mailingAddress) //get status property
          .filter(
            (item: MailStateType, index: any, ar: any[]) =>
              ar.findIndex((m) => m?.id === item?.id && m.state) === index
          );

        const uniqueMailAddressState: MailStateType[] = Object.values(
          listInvestorMailAddressState.reduce(
            (acc, cur) =>
              Object.assign(acc, {
                [cur.state]: cur,
              }),
            {}
          )
        );
        const listInvestorTaxAddressState: MailStateType[] = sortedInvestorList
          ?.map((p: { taxAddress: any }) => p.taxAddress) //get status property
          .filter(
            (item: MailStateType, index: any, ar: any[]) =>
              ar.findIndex((m) => m?.id === item?.id && m.state) === index
          );

        const uniqueListInvestorTaxAddressState: MailStateType[] =
          Object.values(
            listInvestorTaxAddressState.reduce(
              (acc, cur) =>
                Object.assign(acc, {
                  [cur.state]: cur,
                }),
              {}
            )
          );

        const listInvestorTaxAddressCountry: AddressCountryType[] =
          sortedInvestorList
            ?.map((p: { taxAddress: any }) => p.taxAddress)
            .filter(
              (item: AddressCountryType, index: any, ar: any[]) =>
                ar.findIndex((m) => m?.id === item?.id && m.country) === index
            );

        const uniqueListInvestorTaxAddressCountry: AddressCountryType[] =
          Object.values(
            listInvestorTaxAddressCountry.reduce(
              (acc, cur) =>
                Object.assign(acc, {
                  [cur.country]: cur,
                }),
              {}
            )
          );

        setInvestorTaxAddressCountryList(uniqueListInvestorTaxAddressCountry);
        setInvestorTaxAddressStateList(uniqueListInvestorTaxAddressState);
        setInvestorTypeList(listInvestorType);
        setInvestorLegalTypeList(listInvestorLegalType);
        setInvestorMailStateList(uniqueMailAddressState);
        setInvestorListLoaded(true);
        setSugestionList(sugestions);
      } catch (e) {
        informationAlert('Error in getting investors list', 'error');
      } finally {
        setLoadingInvestorList(false);
      }
    },
    [isSubmitting]
  );

  useEffect(() => {
    const investorDataListCopy = cloneDeep(investorDataList);

    let filteredList: Investor[] | undefined = investorDataListCopy;

    headerList?.map((header) => {
      const auxList = filteredList ?? investorDataListCopy;

      switch (header.inlineFilterName) {
        case InvestorFilter.FundName:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) => {
              return header?.inlineFilterSelected?.some((fund) =>
                JSON.stringify(investor.funds).includes(fund)
              );
            });
          }
          break;
        case InvestorFilter.InvestorName:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) =>
              header?.inlineFilterSelected?.includes(investor.id)
            );
          }
          break;
        case InvestorFilter.Status:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) =>
              header?.inlineFilterSelected?.includes(investor.status)
            );
          }
          break;
        case InvestorFilter.InvestorType:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor: any) => {
              return header?.inlineFilterSelected?.some((investorType) =>
                JSON.stringify(investor.fundInvestorTypes).includes(
                  investorType
                )
              );
            });
          }
          break;
        case InvestorFilter.InvestorLegalType:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) => {
              return header?.inlineFilterSelected?.some(
                (curStatus) => investor.legalType?.id === curStatus
              );
            });
          }
          break;
        case InvestorFilter.InvestorMailAddressState:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) => {
              return header?.inlineFilterSelected?.some(
                (curStatus) => investor.mailingAddress?.id === curStatus
              );
            });
          }
          break;
        case InvestorFilter.InvestorTaxAddressState:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) => {
              return header?.inlineFilterSelected?.some(
                (curStatus) => investor.taxAddress?.state === curStatus
              );
            });
          }
          break;
        case InvestorFilter.InvestorTaxAddressCountry:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((investor) => {
              return header?.inlineFilterSelected?.some(
                (curStatus) => investor.taxAddress?.country === curStatus
              );
            });
          }
          break;
      }
    });

    const fundFilters = headerList?.find(
      (hl) => hl.field === 'funds'
    )?.inlineFilterSelected;

    if (fundFilters?.length) {
      filteredList?.forEach((investor) => {
        // @ts-ignore
        investor.fundFilters = fundFilters;
      });
    }

    setFilteredInvestorList(filteredList);
  }, [headerList, investorDataList]);

  const onRowColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = updateArrayIndex(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      INVESTORS_COLUMN_ORDER_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const onDetailPanelClose = () => {
    setSelectedInvestor(initialInvestor);
  };

  const handleOnView = (investorId: string, investor: Investor) => {
    if (clientMfaSettings?.wireInfoValidation) {
      setSelectedInvestorId(investorId);
      setSelectedInvestorListItem(investor);
      setShowMfaModal(true);
    } else {
      setSelectedInvestorId(investorId);
      setSelectedInvestorListItem(investor);
      openDetailPanel(investorId, investor);
    }
  };

  const openDetailPanel = async (investorId: string, investor?: Investor) => {
    if (investorId) {
      try {
        setIsLoading(true);

        const investorDetails = await getInvestor(investorId);

        setSelectedInvestor({
          investorListItem: selectedInvestorListItem
            ? selectedInvestorListItem
            : investor,
          investor: investorDetails,
          detailView: DetailViewType.Edit,
        });
      } catch (e) {
        informationAlert(GET_INVESTOR_DETAILS_ERROR, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBulkOptionClick = async (selectedOption: string) => {
    if (selectedOption === 'export') {
      const fundIds: string[] =
        headerList.find((item) => item.field === 'funds')
          ?.inlineFilterSelected || [];
      const file = await downloadFileInvestor(
        investorSelectionModel,
        fundIds,
        'INVESTOR'
      );

      downloadFile(file, 'Investors', 'csv');
    } else
      switch (selectedOption) {
        case 'delete':
          setShowDeleteConfirmationPopover(true);
          break;
        case 'activate':
          setIsActivating(true);
          setShowActivateConfirmationPopover(true);
          break;
        case 'inactivate':
          setIsActivating(false);
          setShowActivateConfirmationPopover(true);
          break;
      }
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      setIsSubmitting(true);
      await uploadFile('investors', uploadedFile);
      setUploadedFile(undefined);
      setIsUploadComplete(true);
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleOnSearch = (newValue: string | null) => {
    if (typeof newValue === 'string') {
      setSearchText(newValue);
    } else if (newValue === null) {
      setSearchText('');
    }
  };

  const handleNewButtonAction = async (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        setSelectedInvestorListItem(null);
        {
          setSelectedInvestor({
            investorListItem: undefined,
            investor: undefined,
            detailView: DetailViewType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        {
          setIsLoading(true);
          const data = await downloadTemplate('investors');

          downloadFile(data, DOWNLOADED_TEMPLATE_INVESTOR_FILE_NAME, 'csv');

          setIsLoading(false);
        }
        break;
    }
  };

  const handleDeleteInvestorsCancel = () => {
    setShowDeleteConfirmationPopover(false);
  };

  const handleDeleteInvestorsConfirmation = async () => {
    setIsSubmitting(true);
    try {
      await deleteInvestors(investorSelectionModel);
      informationAlert(DELETE_INVESTOR_SUCCESS, 'success');
    } catch (exception) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setShowDeleteConfirmationPopover(false);
      setIsSubmitting(false);
    }
  };

  const handleActivateInvestorsCancel = () => {
    setShowActivateConfirmationPopover(false);
  };

  const handleActivateInvestorsConfirmation = async () => {
    setIsSubmitting(true);
    try {
      await updateInvestorStatus(
        investorSelectionModel,
        isActivating ? 'ACTIVE' : 'INACTIVE'
      );
    } catch (exception) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setShowActivateConfirmationPopover(false);
      setIsSubmitting(false);
    }
  };

  return {
    isLoading,
    isSubmitting,
    isUploadComplete,
    setIsSubmitting,
    clearUploadedFile,
    clearUploadCompleted,
    uploadedFile,
    handleActivateInvestorsConfirmation,
    handleActivateInvestorsCancel,
    handleDeleteInvestorsConfirmation,
    handleDeleteInvestorsCancel,
    handleUploadTemplate,
    handleOnSearch,
    handleNewButtonAction,
    handleUpdateHeader,
    handleFilter,
    onRowColumnOrderChange,
    investorDataList,
    setInvestorsList,
    investorFilteredList: filteredInvestorList ?? [],
    setOriginalInvestorList,
    originalInvestorList,
    headerList,
    setHeaderList,
    sugestionList,
    isLoadingInvestorList,
    investorListLoaded,
    activeHeaderFields,
    showActivateConfirmationPopover,
    setShowActivateConfirmationPopover,
    onDetailPanelClose,
    openDetailPanel,
    selectedInvestorId,
    setSelectedInvestorId,
    selectedInvestor,
    setSelectedInvestor,
    handleOnView,
    searchText,
    setSearchText,
    investorSelectionModel,
    setInvestorSelectionModel,
    isActivating,
    setIsActivating,
    stateRef,
    showDeleteConfirmationPopover,
    setShowDeleteConfirmationPopover,
    handleBulkOptionClick,
    selectedInvestorListItem,
    setSelectedInvestorListItem,
    clientMfaSettings,
    showMfaModal,
    setShowMfaModal,
    userMfaInfo,
    setUserMfaInfo,
    setLoadingInvestorList,
  };
};

export const useSearchTextEffect = (
  searchText: string,
  pInvestorList: any[] = [],
  setFilterList: any
) => {
  useEffect(() => {
    setFilterList(
      pInvestorList.filter((investor) =>
        investor.name.match(new RegExp(searchText, 'i'))
      )
    );
  }, [searchText]);
};

export const useBulkActionOptionSelectionEffect = (
  bulkActionList: ImageItem[],
  investorSelectionModel: any
) => {
  const { hasRole: superAdminORClientAdminUser } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const { hasRole: basicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: fundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: clientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const [bulkActionOptions, setBulkActionOptions] = useState<ImageItem[]>([]);

  useEffect(() => {
    let optionList: ImageItem[] = [];

    if (superAdminORClientAdminUser) {
      optionList = bulkActionList;
    }
    if (basicAdmin) {
      bulkActionList = bulkActionList.filter((item) => item.id !== 'delete');
    }
    if (clientPortalUser || fundAdmin) {
      bulkActionList = bulkActionList.filter((item) => item.id === 'export');
    }

    const updatedOptions: ImageItem[] = bulkActionList.map((option) => {
      option.text = option.text + '(' + investorSelectionModel.length + ')';
      return option;
    });

    setBulkActionOptions(updatedOptions);
  }, [investorSelectionModel]);

  return {
    bulkActionOptions,
  };
};

const setNameToDisplayForIndividuals = (investorList: Investor[]) => {
  investorList.map((investor) => {
    if (investor.entityType === 'INDIVIDUAL')
      investor.name = `${investor.firstName} ${investor.lastName}`;
  });
};
