import { useContext, useState } from 'react';

import { AppContext } from "../../core/context/appContextProvider";

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean>

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const {
    informationAlert
  } = useContext(AppContext);

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      informationAlert('Copied to clipboard', "success");
      return true;
    } catch (error) {
      informationAlert('Copy failed', "error");
      setCopiedText(null);
      return false;
    }
  };

  return {
    copiedText,
    copy
  };
}

export default useCopyToClipboard;
