import { FormHelperText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';

import { StyledFormControl, StyledSelect } from './MultiSelect.styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type MultiSelectItem = {
  id: string,
  name: string,
  selected: boolean
};

type MultiSelectProps = {
  id: string,
  label: string,
  items: MultiSelectItem[],
  error?: boolean,
  helperText?: string,
  infoHelperText?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  isShort?: boolean,
  noGutter?: boolean,
  width?: string,
  onChange: (items: MultiSelectItem[]) => void
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  label,
  items,
  error = false,
  helperText = "",
  infoHelperText = undefined,
  disabled = false,
  onChange,
  fullWidth = false,
  isShort = false,
  noGutter = false,
  width,
}) => {
  const handleChange = (event: any) => {
    const values = event.target.value as string[];

    items.forEach((item) => {
      item.selected = values.find((v) => v === item.id) !== undefined;
    });

    onChange([...items]);
  };

  const getSelectedItems = () => {
    return items.filter((item) => item.selected).map((item) => item.id);
  };

  const getRenderValue = () => {
    const selectedNames: string[] = [];

    items
      .filter((item) => item.selected)
      .forEach((item) => {
        selectedNames.push(item.name);
      });

    return selectedNames.join(", ");
  };

  return (
    <StyledFormControl
      size="small"
      isFullWidth={fullWidth.toString()}
      isShort={isShort.toString()}
      noGutter={noGutter.toString()}
    >
      <InputLabel id={`${id}_input_label`}>{label}</InputLabel>
      <StyledSelect
        labelId={`${id}_label`}
        id={id}
        multiple
        value={getSelectedItems()}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => getRenderValue()}
        error={error}
        MenuProps={MenuProps}
        disabled={disabled}
        isFullWidth={fullWidth.toString()}
        isShort={isShort.toString()}
        width={width}
      >
        {items.map((item, idx) => (
          <MenuItem key={idx} value={item.id}>
            <Checkbox checked={item.selected} />
            <ListItemText primary={item.name}/>
          </MenuItem>
        ))}
      </StyledSelect>

      <FormHelperText
        hidden={!error && infoHelperText === undefined}
        error={error}
      >
        {error ? helperText : infoHelperText}
      </FormHelperText>
    </StyledFormControl>
  );
};
