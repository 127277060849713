import { Box, Button, ClickAwayListener, Container, Typography } from "@mui/material";

import { useNavBarContext } from "../../core/context/navbarContext";
import { LOCK_PANEL_HEIGHT } from "../../utils/constants/styles.constants";
import noop from "../../utils/helpers/noop";
import {
  FooterBox,
  FooterWrapper,
  GreenLockIcon,
  RedLockIcon,
  StyledContainer,
  StyledTooltip
} from "./PageLock.styles";

type Props = {
  locked: boolean;
  disabled: boolean;
  onChange: () => void;
  showTooltip?: boolean;
  onTooltipClose?: () => void;
  tooltipText?: string;
};

export const PageLock: React.FC<Props> = ({
  locked,
  disabled,
  onChange,
  showTooltip = false,
  onTooltipClose = noop,
  tooltipText = "The page is locked",
}: Props) => {

  const {
    isNavBarOpen
  } = useNavBarContext();

  return (
    <FooterWrapper>
      <FooterBox height={isNavBarOpen ? 67: LOCK_PANEL_HEIGHT}>
        <StyledContainer>
          <Button
            id="btn_unlock_changes"
            onClick={onChange}
            disabled={disabled}
          >
            <ClickAwayListener onClickAway={onTooltipClose}>
              <Box component="span">
                <StyledTooltip
                  onClose={onTooltipClose}
                  open={showTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipText}
                  arrow
                  placement="top"
                >
                  {locked ? <RedLockIcon /> : <GreenLockIcon />}
                </StyledTooltip>
              </Box>
            </ClickAwayListener>
            <Typography>
              {locked
                ? "Click the lock to make changes"
                : "Click the lock to prevent further changes"}
            </Typography>
          </Button>
        </StyledContainer>
      </FooterBox>
    </FooterWrapper>
  );
};
