import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Box,
  CircularProgress,
  Container,
  Drawer,
  styled,
} from '@mui/material';

export const MainContainer = styled(Container)`
  padding: 0px !important;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: auto;
`;

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  gap: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
}));

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ChipsContent = styled('div')(() => ({
  maxWidth: '90%',
}));

export const PermissionBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(1),
}));

interface DocDownloadButtonProps {
  isDownloading: boolean;
  isReadOnly: boolean;
}

const DocDownloadButton = styled(Box)<DocDownloadButtonProps>(
  ({ theme, isDownloading, isReadOnly }) => ({
    margin: theme.spacing(1),
    position: 'relative',
    marginLeft: theme.spacing(0),
    color: isDownloading
      ? theme.palette.action.disabled
      : theme.palette.primary.main,
    cursor: isReadOnly ? 'arrow' : 'pointer',
  })
);

interface DownloadButtonProps {
  isDownloading: boolean;
  currentlyDownloadingIds: string[];
  row: { id: string };
  isReadOnly: boolean;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  isDownloading,
  currentlyDownloadingIds,
  row,
  isReadOnly,
}) => {
  return (
    <DocDownloadButton
      isDownloading={isDownloading}
      id={`download_icon_${row.id}`}
      isReadOnly={isReadOnly}
    >
      <FileDownloadOutlinedIcon />
      {currentlyDownloadingIds.includes(row.id) && (
        <CircularProgress
          size={35}
          sx={{
            animation: 'slow',
            opacity: '40%',
            color: 'primary',
            position: 'absolute',
            top: -5,
            left: -5,
            zIndex: 1,
          }}
        />
      )}
    </DocDownloadButton>
  );
};

export const DataContainer = styled(Box)(() => ({
  display: 'flex',
  maxWidth: '100%',
  overflowY: 'clip',
}));

export const DataGridBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -400,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
}));

export const EditPanelDrawer = styled(Drawer)<{
  open?: boolean;
  headerAndLockbarHeight: number;
}>(({ headerAndLockbarHeight }) => ({
  height: `calc(100vh - 80px - ${headerAndLockbarHeight}px)`,
  overflowY: 'hidden',
  width: 400,
  overflow: 'clip',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    position: 'relative',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
  },
}));
