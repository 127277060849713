import { FileDownloadOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";

import FileIcon from "../../../../assets/images/icons/icon_file.svg";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import {
  Container,
  FileContainer,
  StyledBox,
  StyledTypography,
} from "./FileDisplay.style";
import { useFileDisplay } from "./useFileDisplay.hooks";

type FileDisplayProps = {
  file?: FileList;
  document?: { fileId: string; name: string; size: number };
  onClear: Function;
  disabled?: boolean;
};

export const FileDisplay = ({ file, document, onClear, disabled }: FileDisplayProps) => {
  const { fileName, fileSize, downloadFile } = useFileDisplay({ file, document });
  
  return (
    <Container>
      <StyledBox>
        <FileContainer>
          <ImgIcon icon={FileIcon} />
          <StyledTypography variant="caption">{fileName}</StyledTypography>
        </FileContainer>
        <StyledTypography variant="caption">{fileSize}</StyledTypography>
        <Box>
          {!disabled &&
            <IconButton
              aria-label="download_file"
              color="primary"
              title="Download File"
              id="download_file"
              onClick={downloadFile}
            >
              <FileDownloadOutlined />
            </IconButton>
          }
        </Box>
        {!disabled &&
          <IconButton
            aria-label="cancel_file"
            color="primary"
            title="cancel File"
            id="cancel_file"
            onClick={() => onClear()}
          >
            <CloseIcon />
          </IconButton>
        }
      </StyledBox>
    </Container>
  );
};
