import './TreeGrid.TypeScript.API.d';
import './TreeGrid.TypeScript.React.d';

import { Typography } from '@mui/material';
import React from 'react';

import { getBearerToken } from '../../../../services/workbook.service';
import { SheetTypes } from '../workbook.type';
import { colsAddResponse } from './sheets/ICellEditHandling';
import { SpreadsheetGrid } from './SpreadsheetGrid';
import { SelectionSummaryStyled } from './WorkbookBase.Component.styles';

type WorkbookBaseComponentProps = {
    spreadsheetUrl: string,
    exportFilename: string,
    onPreloadSheet(sheetName: string): void,
    onSheetLoaded(spreadsheetGrid: SpreadsheetGrid): void,
    onSaveClicked() : void,
    onSaveAsClicked() : void,
    onSave(fileBlob: Blob): void,
    onSaveAs(fileBlob: Blob): void,
    onToolbarButtonClick(buttonName: string): void,
    onStartEdit(row: string, col: string): boolean,
    onEndEdit(row: string, col: string, save: boolean, value: string): boolean,
    onColsAdd(cols: string[], toCol: string, right: boolean, empty: boolean): colsAddResponse,
    onRowAdd(parentRow: string): boolean,
    onCanColDelete(col: string): boolean,
    onCanRowDelete(row: string): boolean,
    onCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number): boolean
};

type SelectionSummary = { 
    count: number, 
    sum: number, 
    avgCount: number,
    displayType: 'Clear' | 'Count Only' | 'All'
}

export class WorkbookBaseComponent extends React.Component<WorkbookBaseComponentProps> {
    state: {
        exportAsSave: boolean, 
        exportAsSaveAs: boolean,
        selectionSummary: SelectionSummary,
        spreadsheetUrl: string|undefined
    } = { 
        exportAsSave: false, 
        exportAsSaveAs: false, 
        selectionSummary: { count: 0, sum: 0, avgCount: 0, displayType: 'Clear' },
        spreadsheetUrl: undefined
    };

    constructor(public props : WorkbookBaseComponentProps) {
        super(props);

        const { 
            spreadsheetUrl,
            exportFilename,
            onPreloadSheet,
            onSheetLoaded,
            onSaveClicked,
            onSaveAsClicked,
            onSave,
            onSaveAs,
            onToolbarButtonClick,
            onStartEdit,
            onEndEdit,
            onColsAdd,
            onRowAdd,
            onCanColDelete,
            onCanRowDelete,
            onCanPaste
        } =  this.props;
        
        this.state.spreadsheetUrl = spreadsheetUrl;


        Grids.OnLoadSheet = (grid: TGrid, name: string, hidden:boolean|number): number | boolean | void | null | undefined => {
            if(!hidden) {
                onPreloadSheet(name);
            }
        };
        
        Grids.OnRenderFinish = (grid: TGrid) => {
            const spreadsheetGrid = new SpreadsheetGrid(grid);

            onSheetLoaded(spreadsheetGrid);
        };

        Grids.OnExport = (_grid: TGrid, dataBlob: any, type: number | boolean) => {  
            const { exportAsSave, exportAsSaveAs } = this.state;

            if(exportAsSave) {
                onSave(dataBlob);
            } else if(exportAsSaveAs) {
                onSaveAs(dataBlob);
            } else {
                downloadFile(dataBlob, exportFilename);
            }

            this.setState({ exportAsSave: false, exportAsSaveAs: false });

            return true;
        };

        Grids.OnSave = (grid: TGrid, row: TRow, autoupdate: number | boolean): number | boolean | void | null | undefined => {
            if(!this.state.exportAsSave && !this.state.exportAsSaveAs) {
                this.setState({ exportAsSave: true });
            }

            setTimeout(() => {
                // TG doesn't let you call an action within a action, so we need to run this async
                grid?.ActionExport(false, false);
            }, 1);

            return true;
        };

        Grids.OnClick = (grid: TGrid, row: TRow, col: string, x: number, y: number): number | boolean | void | null | undefined => {
            const idString = row?.id ? String(row.id) : '';

            if(idString.startsWith('Toolbar')) {
                if(col === 'CustSave') {
                    onSaveClicked();
                    this.setState({ exportAsSaveAs: false, exportAsSave: true });
                } else if(col === 'CustSaveAs') {
                    onSaveAsClicked();
                    this.setState({ exportAsSaveAs: true, exportAsSave: false });
                } else {
                    onToolbarButtonClick(col);
                }
            }
        };

        function downloadFile(blob: Blob, filename: string) {
            const anchor = document.createElement('a');

            anchor.href = URL.createObjectURL(blob);
            anchor.download = filename;
            anchor.style.display = "none";
        
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }

        
        Grids.OnStartEdit = (grid: TGrid, row: TRow, col: string): number | boolean | void | null | undefined => {
            if(row.id !== 'OUT') {
                const canEdit =  !onStartEdit(row.id, col);

                return canEdit;
            }

            return false;
        };

        Grids.OnEndEdit = (grid: TGrid, row: TRow, col: string, save: number | boolean, _val: any, value: string) => {
            let canEdit: boolean;

            if(row.id !== 'OUT') {
                canEdit = !onEndEdit(row.id, col, Boolean(save), value);
            } else {
                canEdit = false;
            }

            if(canEdit) {
                setTimeout(() => { grid?.EndEdit(false); }, 1); 
                return true;
            } else {
                return false;
            }
        };

        Grids.OnColsAdd = (grid: TGrid, cols: string[], tocol: string, right: number | boolean, empty: number | boolean): number | boolean | void | null | undefined => {
            return !onColsAdd(cols, tocol, right ? true : false, empty ? true : false).validColumnPosition;
        };

        Grids.OnCanRowAdd = (grid: TGrid, parent: TRow, next: TRow): number | boolean | void | null | undefined => {
            return onRowAdd(next.id);
        };

        Grids.OnCanColDelete = (grid: TGrid, col: string, type: number, cols: string[]): number | boolean | void | null | undefined => {
            const canDel = onCanColDelete(col);

            return canDel ? 2 : 0;
        };

        Grids.OnCanRowDelete = (grid: TGrid, row: TRow, type: number, rows: TRow[]): number | void | null | undefined => {
            const canDel = onCanRowDelete(row.id);

            return canDel ? 2 : 0;
        };

        Grids.OnPaste = (grid: TGrid, pastedtext: string[], cols: string[]): number | boolean | void | null | undefined => {
            if(!grid) return;

            const numOfCols = cols[0].split('\t').length;
            const numOfRows = cols.length;

            const startRow = grid.ARow;
            const startCol = grid.ACol;

            const canPaste = onCanPaste(startCol, startRow.id, numOfCols, numOfRows);

            return !canPaste;
        };


        Grids.OnSelect = (grid: TGrid, row: TRow, deselect: number | boolean, Cols: string[], test: number | boolean): number | boolean | void | null | undefined => {
            setTimeout(function(){calculateSummaryValues(grid as TTGrid);}, 10);
        };

        Grids.OnLoadError = (grid:any) => {  
            // eslint-disable-next-line no-console
            console.log(grid);
        };

        const calculateSummaryValues = (grid: TTGrid) => {
            setSummaryInformation();
      
            const summaryModel: SelectionSummary = { count: 0, sum: 0, avgCount: 0, displayType: 'Clear' };
            const  selRanges = grid.GetSelRanges() as any[];

            for(let rangeIdx=0; rangeIdx < selRanges.length;  rangeIdx++){
               const cellRange = selRanges[rangeIdx];
      
               calculateRectValues(
                grid, cellRange[0].Index, cellRange[2].Index, cellRange[1], cellRange[3], summaryModel);
            }
      
            if(summaryModel.count > 1){
               if(summaryModel.avgCount > 0){
                  const average = summaryModel.sum/summaryModel.avgCount;

                  setSummaryInformation(summaryModel.count, average, summaryModel.sum);
               } else {
                  setSummaryInformation(summaryModel.count);
               }
            }
        };

        const calculateRectValues = (
                grid: TTGrid, startRowIdx:number, endRowIdx:number, startCol:string, endCol:string,
                model: SelectionSummary) => { 

            const startColIdx:number = grid.GetColIndex(startCol);
            const endColIdx:number = grid.GetColIndex(endCol);
    
            for(let collIdx= startColIdx; collIdx <= endColIdx; collIdx++){
                const colIdxStr = grid.GetColByIndex(collIdx, 0);
    
                for (let rowIdx = startRowIdx; rowIdx <= endRowIdx; rowIdx++) {
                    const cellVal = grid.GetValue(grid.Rows[rowIdx], colIdxStr);
        
                    if(cellVal.toString().trim() !== "")
                        model.count++;
        
                    const numVal = getCellNumber(grid, grid.Rows[rowIdx], colIdxStr);

                    if(!isNaN(numVal)){
                        model.sum+=numVal;
                        model.avgCount++;
                    }
                }
            }
        };

        const getCellNumber = (grid:TTGrid, row:TRow, colIdxStr:string) : number => {
            let cellStr:string = grid.GetValue(row, colIdxStr);

            cellStr = cellStr.toString().replace(",", "").trim();
      
            // check is negative
            if(cellStr.indexOf('(') === 0 && cellStr.indexOf(')') === cellStr.length-1){
               cellStr = cellStr.replace("(", "");
               cellStr = cellStr.replace(")", "");
               cellStr = `-${cellStr}`;
            }
      
            return parseFloat(cellStr);
         };

        const setSummaryInformation = (count?: number, average?: number, sum?: number) => {
            const selectionSummary: SelectionSummary = { count: 0, sum: 0, avgCount: 0, displayType: 'Clear' };
            
            if(!isNaN(average as number) || !isNaN(count as number) || !isNaN(sum as number)) {
                if(!isNaN(average as number) && !isNaN(count as number) && !isNaN(sum as number)) {
                    selectionSummary.count = count!;
                    selectionSummary.sum = sum!;
                    selectionSummary.avgCount = average!;
                    selectionSummary.displayType = 'All';
                } else {
                    selectionSummary.count = count ? count : 0;
                    selectionSummary.displayType = 'Count Only';
                }
            }

            this.setState({ selectionSummary: selectionSummary });
        };
    }

    componentDidMount() {
        window.StartTreeGrid(); 
        Grids.OnLoadSheet(undefined, SheetTypes.Allocations, false);
    }

    componentWillUnmount() {
        window.DisposeGrids();
    }

    render() {
        const { selectionSummary: summary } = this.state;
        const { spreadsheetUrl } = this.props;

        return (
            <div>
                <div id="treegrid_main_tag"  >
                    { /* <bdo> tag is used instead of <treegrid> tag to avoid TypeScript error for custom tag */ }
                    <bdo 
                        id="excel_spreadsheet" 
                        debug="problem" suppressmessage="4"
                        layout_url="/Layouts/ExcelDef.xml" layout_static="ExcelDef"
                        exportpdf_url='' exportpdf_data='Data'  exportpdf_type='settings'
                        //@ts-ignore
                        data_header_authorization={getBearerToken()}
                        data_url={spreadsheetUrl} data_format="xlsx" 
                        upload_url="/" upload_xml="2" upload_type="Changes,Cells,Body,Data,All"
                        source="Defaults+Text,Base,Layout;Data"
                    />
                </div>
                <div>
                    <SelectionSummaryStyled>
                        <Typography variant="subtitle1">
                        {
                            summary.displayType === 'All' ?
                                <div>
                                    <span style={ { marginRight: "25px" } }>{ `Average: ${summary.avgCount.toFixed(2)}` }</span>
                                    <span style={ { marginRight: "25px" } }>{ `Count: ${summary.count}` }</span>
                                    <span>{ `Sum: ${summary.sum.toFixed(2)}` }</span>
                                </div> 
                            : summary.displayType === 'Count Only' ? 
                                <span style={ { marginRight: "25px" } }>{ `Count: ${summary.count}` }</span>
                            : null
                        }
                        </Typography>
                    </SelectionSummaryStyled>
                </div>
            </div>
        );
    }
}    