import { JournalEntryDetails } from '../../../pages/arkGL/journalEntries/journalEntryDetails/JournalEntryDetails';
import { SelectedJournalEntry } from '../../../utils/types/arkGLJournalEntry.type';
import ConfirmationDialog from '../../Modal/ConfirmationDialog';
import { useMultiJEViewer } from './MultiJEViewer.hooks';

interface Props {
  list: any[];
  onClose: Function;
  selectedJournalEntry?: SelectedJournalEntry;
  setSelectedJournalEntry: Function;
  onSuccessfulPostClose: Function;
}

export const MultiJEViewer: React.FC<Props> = ({
  list,
  onClose,
  onSuccessfulPostClose,
  setSelectedJournalEntry,
  selectedJournalEntry,
}: Props) => {
  const {
    currentIndex,
    currentList,
    showPrevious,
    showNext,
    onSaveAndNext,
    onSave,
    showExitConfirmation,
    setShowExitConfirmation,
    closeDrawer,
    keepDrawerOpen,
  } = useMultiJEViewer({
    list,
    setSelectedJournalEntry,
    onClose,
  });

  return (
    <>
      {!!selectedJournalEntry?.type && currentList.length > 0 && (
        <JournalEntryDetails
          selectedJournalEntry={{
            ...selectedJournalEntry,
            multiView: {
              currentIndex: currentIndex,
              onClose: onClose,
              totalCount: currentList.length,
              previous: showPrevious,
              next: showNext,
              onSaveAndNext: onSaveAndNext,
              onSave: onSave,
              setShowExitConfirmation: setShowExitConfirmation,
              isOriginalAmount:
                (currentList[currentIndex] &&
                  currentList[currentIndex].isOriginalAmount) ??
                false,
            },
          }}
          setSelectedJournalEntry={() => {}}
          fetchAllJournalEntries={() => {}}
          onDetailClose={onClose}
          onSuccessfulPostClose={onSuccessfulPostClose}
          fundId={selectedJournalEntry.journalEntry?.fundId}
        />
      )}
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_journal_entry_multiview_confirmation"
        actions={[
          {
            label: 'Continue Editing',
            onClick: keepDrawerOpen,
            id: 'btn_journal_entry_multiview_keep',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Exit Without Saving',
            onClick: closeDrawer,
            id: 'btn_journal_entry_multiview_cancel_discard',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          "You have unsaved Journal Entries associated with your Cash Receipts' 'Send to GL' selection. These entries will be lost if you navigate away. Do you want to continue"
        }
        title={'Unsaved Journal Entries'}
      />
    </>
  );
};
