import {
  DashboardWidget,
  WidgetType,
} from '../../utils/types/visualDashboard.type';

export enum VisualDashboardType {
  Fund = 'FUND_PAGE',
  CapitalAccounts = 'CAPITAL_ACCOUNTS_PAGE',
  SoiUnrealized = 'SCHEDULE_OF_INVESTMENTS_UNREALIZED',
  SoiRealized = 'SCHEDULE_OF_INVESTMENTS_REALIZED',
  SoiDashboard = 'SCHEDULE_OF_INVESTMENTS_DASHBOARD',
}

export enum VisualDashboardViewKey {
  Fund = 'FUNDS_VISUALIZATIONS_VIEW_KEY',
  CapitalAccounts = 'CAPITAL_ACCOUNTS_VISUALIZATIONS_VIEW_KEY',
  SoiUnrealized = 'SOI_UNREALIZED_VISUALIZATIONS_VIEW_KEY',
  SoiRealized = 'SOI_REALIZED_VISUALIZATIONS_VIEW_KEY',
  SoiDashboard = 'SOI_DASHBOARD_VISUALIZATIONS_VIEW_KEY',
}

export const defaultFundPageDashboardWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'totalFunds',
    title: 'Total Funds',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'capitalCommitted',
    title: 'Capital Committed',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'funded',
    title: 'Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'unfunded',
    title: 'Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'distributions',
    title: 'Distributions',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'nav',
    title: 'Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 6,
    name: 'rvpi',
    title: 'RVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 7,
    name: 'dpi',
    title: 'DPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 8,
    name: 'tvpi',
    title: 'TVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 9,
    name: 'percentRemaining',
    title: '% Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 10,
    name: 'percentCalled',
    title: '% Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 11,
    name: 'moic',
    title: 'MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },

  {
    index: 12,
    name: 'committedByFund',
    title: 'Committed By Fund',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 13,
    name: 'totalValueDistributions',
    title: 'Total Value and Distributions',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 14,
    name: 'committedToPaid',
    title: 'Committed to Paid',
    visible: true,
    widgetType: WidgetType.Charts,
  },

  {
    index: 15,
    name: 'entityTypeByFund',
    title: 'Entity Type by Fund',
    visible: true,
    widgetType: WidgetType.EntityTypeByFund,
  },
];

export const defaultCapitalAccountsPageDashboardWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'capitalCommitted',
    title: 'Capital Committed',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'funded',
    title: 'Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'unfunded',
    title: 'Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'distributions',
    title: 'Distributions',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'nav',
    title: 'Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'rvpi',
    title: 'RVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 6,
    name: 'dpi',
    title: 'DPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 7,
    name: 'tvpi',
    title: 'TVPI',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 8,
    name: 'percentRemaining',
    title: '% Unfunded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 9,
    name: 'percentCalled',
    title: '% Funded',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 10,
    name: 'moic',
    title: 'MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },

  {
    index: 11,
    name: 'committedByFund',
    title: 'Committed By Fund',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 12,
    name: 'totalValueDistributions',
    title: 'Total Value and Distributions',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 13,
    name: 'committedToPaid',
    title: 'Committed to Paid',
    visible: true,
    widgetType: WidgetType.Charts,
  },
];

export const defaultUnrealizedSoiWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'totalInvestedCapital',
    title: 'Total Invested Capital',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'currentCost',
    title: 'Current Cost',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'currentFairMarketValue',
    title: 'Current Fair Market Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'activePortfolioInvestments',
    title: 'Active Portfolio Investments',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'unrealizedMOIC',
    title: 'Unrealized MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'portfolioCompanyByLocation',
    title: 'Portfolio Company by Location',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 6,
    name: 'investedCapitalByGeography',
    title: 'Invested Capital by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 7,
    name: 'fairMarketValueByGeography',
    title: 'Fair Market Value by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 8,
    name: 'investedCapitalByIndustry',
    title: 'Invested Capital by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 9,
    name: 'fairMarketValueByIndustry',
    title: 'Fair market Value by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 10,
    name: 'topPortfolioCompanies',
    title: 'Top Portfolio Companies',
    visible: true,
    widgetType: WidgetType.Charts,
  },
];

export const defaultRealizedSoiWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'totalInvestedCapital',
    title: 'Total Invested Capital',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'realizedCost',
    title: 'Realized Cost',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'totalProceeds',
    title: 'Total Proceeds',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'realizedGains',
    title: 'Realized Gains',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'realizedMOIC',
    title: 'Realized MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'portfolioCompanyByLocation',
    title: 'Portfolio Company by Location',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 6,
    name: 'investedCapitalByGeography',
    title: 'Invested Capital by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 7,
    name: 'fairMarketValueByGeography',
    title: 'Fair Market Value by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 8,
    name: 'investedCapitalByIndustry',
    title: 'Invested Capital by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 9,
    name: 'fairMarketValueByIndustry',
    title: 'Fair market Value by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 10,
    name: 'topPortfolioCompanies',
    title: 'Top Portfolio Companies',
    visible: true,
    widgetType: WidgetType.Charts,
  },
];

export const defaultDashboardSoiWidgets: DashboardWidget[] = [
  {
    index: 0,
    name: 'totalInvestedCapital',
    title: 'Total Invested Capital',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 1,
    name: 'currentCost',
    title: 'Current Cost',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 2,
    name: 'currentFairMarketValue',
    title: 'Current Fair Market Value',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 3,
    name: 'activePortfolioInvestments',
    title: 'Active Portfolio Investments',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 4,
    name: 'unrealizedMOIC',
    title: 'Unrealized MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 5,
    name: 'realizedCost',
    title: 'Realized Cost',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 6,
    name: 'totalProceeds',
    title: 'Total Proceeds',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 7,
    name: 'realizedGains',
    title: 'Realized Gains',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 8,
    name: 'realizedMOIC',
    title: 'Realized MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 9,
    name: 'totalMOIC',
    title: 'Total MOIC',
    visible: true,
    widgetType: WidgetType.SummaryCard,
  },
  {
    index: 10,
    name: 'portfolioCompanyByLocation',
    title: 'Portfolio Company by Location',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 11,
    name: 'investedCapitalByGeography',
    title: 'Invested Capital by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 12,
    name: 'fairMarketValueByGeography',
    title: 'Fair Market Value by Geography',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 13,
    name: 'investedCapitalByIndustry',
    title: 'Invested Capital by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 14,
    name: 'fairMarketValueByIndustry',
    title: 'Fair market Value by Industry',
    visible: true,
    widgetType: WidgetType.Charts,
  },
  {
    index: 15,
    name: 'topPortfolioCompanies',
    title: 'Top Portfolio Companies',
    visible: true,
    widgetType: WidgetType.Charts,
  },
];
