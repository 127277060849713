import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import React from "react";

import { NameIdPair } from "../../../../../utils/types/transaction.type";
import { CheckboxFormControl, CheckboxLabel } from "../../../ClientDetails/ClientDetails.style";

type Props = {
  fundsList?: NameIdPair[],
  error?: any;
  value?: any[] | null | string[];
  onChange?: (fund: any[]) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  isShrunk?: boolean;
  isPlaceHolder?: boolean;
  idSelectOnly?: boolean;
};

const FundMultiple = ({
  fundsList,
  error,
  placeholder,
  onChange,
  value,
  disabled,
  size = "small",
  id = "fund_filter",
  textFieldID = "fund_filter_text_field",
  isShrunk = false,
  isPlaceHolder = true,
  idSelectOnly
}: Props) => {
  const onSelectChange = (e: any, fund: any[]) => {
    e?.preventDefault();
    e?.stopPropagation();
    if(idSelectOnly) {
      onChange?.(fund?.map((item: any) => item.id) ?? []);
    } else {
      onChange?.(fund);
    }
  };

  const selectedValue = idSelectOnly ? fundsList?.filter((item: any) => value?.includes(item["id"])) : (value);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={fundsList || []}
      id={id}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.id === selected?.id
      }
      onChange={onSelectChange}
      value={selectedValue ?? []}
      disabled={disabled}
      size={size}
      renderTags={(value: any[]) => {
        return value?.length > 0 ? `${value?.length} selected` : "Select transaction types";
      }}
      renderOption={(props, option, {
        selected
      }) => (
        <li {...props}>
          <CheckboxFormControl>
            <CheckboxLabel>
              <Checkbox
                checked={selected || false}
              />
              <Typography variant="subInfo">
                {option.name}
              </Typography>
            </CheckboxLabel>
          </CheckboxFormControl>

        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ""}
          InputLabelProps={
            (isShrunk && {
              shrink: true,
            }) ||
            {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(FundMultiple);
