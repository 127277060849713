export function getFirstDayOfYear(dateString: Date) {
    const inputDate = new Date(dateString);

    const year = inputDate.getFullYear();

    const firstDayOfYear = new Date(year, 0, 1);

    const formattedFirstDayOfYear = firstDayOfYear;

    return formattedFirstDayOfYear;
}