import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import { useDistributionDetailsEffect } from "../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { ContentContainer } from "../../../capitalCalls/capitalCallDetails/Publish/Publish.styles";
import { DistributionDetailsTabs } from "../Constants";
import {
  DataGridContainer,
  DistributionButtonBox,
  DistributionFixContainer,
  DistributionsHeaderRow,
  DistributionStyledDivider,
  DistributionTransactionButton,
} from "./AddTransaction.style";
import AddTransactionsDetails from "./AddTransactionDetails";
import { DistributionOptions } from "./constants";
import EditTransactionDetails from "./EditTransactionDetails";
import { useTransactionDetails } from "./useAddTransaction.hook";

type RouteProp = {
  id: string;
};

const AddTransactionsTab: React.FC = () => {
  const params = useParams<RouteProp>();

  const history = useHistory();

  const { distributions } = useDistributionDetailsEffect(
    params?.id !== "new" ? params?.id : undefined
  );

  const {
    distributionTransactionList,
    headerList,
    loading,
    handleUpdateHeader,
    showTransactionForm,
    handleAddTransaction,
    handleNewButtonAction,
    selectedTransaction,
    onTransactionDetailClose,
    handleOnView,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    deleteAction,
    onDeleteConfirm,
    onDeleteCancel,
    selectionModel,
    setSelectionModel,
    handleEditTransaction,
    handleDeleteTransaction,
    DistributionBulkActions,
    handleBulkAction,
    saveAndProceed,
    isAddingTransaction,
    isSuperAdminOrClientAdmin,
    isAddingNewTransactionRow,
    setIsAddingNewTransactionRow,
    createUpdateTransactionConfirm,
    cancelCreateUpdateTransaction,
    showDirtyModal,
    allowEdit,
    allowReviewerEdit,
    addAndUpdateTransaction,
    isGoBack,
    goBack,
    setIsGoBack,
  } = useTransactionDetails();

  return (
    <Box id="add_transaction_distributions_list" marginLeft={4}>
      <Progress id="distributions_list_loading" showProgress={loading} />
      <DistributionsHeaderRow>
        <Typography variant="h2">Add Transactions</Typography>
        <DistributionButtonBox>
          {(distributions?.status?.toLowerCase() !== "published" &&
            distributionTransactionList.length > 0 &&
            isSuperAdminOrClientAdmin &&
            allowEdit) ||
          (allowReviewerEdit && distributionTransactionList.length > 0) ? (
            <SplitButton
              id={"btn_bulk_action_options"}
              options={DistributionBulkActions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          ) : (
            ""
          )}
          {(isSuperAdminOrClientAdmin &&
            distributions?.status?.toLowerCase() !== "published" &&
            allowEdit) ||
          allowReviewerEdit ? (
            <ButtonWithOptions
              buttonID="add_transaction_button"
              popoverID="add_transaction_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New Transaction"
              buttonIcon={<AddIcon />}
              options={DistributionOptions}
            />
          ) : (
            ""
          )}
        </DistributionButtonBox>
      </DistributionsHeaderRow>
      <DataGridContainer>
        <DataGrid
          id="transaction_data_grid"
          dataList={distributionTransactionList || []}
          getRowId={(row) => {
            return row.id;
          }}
          headerList={headerList}
          noDataMessage={loading ? "Fetching Data..." : undefined}
          minHeight="10vh"
          autoHeight={true}
          activeHeaderFields={0}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          handleUpdateHeader={handleUpdateHeader}
          handleOnView={handleOnView}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "investor",
                  sort: "asc",
                },
              ],
            },
          }}
        />
        <DistributionStyledDivider variant="fullWidth" />
        {showTransactionForm && !isAddingTransaction && (
          <AddTransactionsDetails
            handleAddTransaction={handleAddTransaction}
            isAddingNewTransactionRow={isAddingNewTransactionRow}
            setIsAddingNewTransactionRow={setIsAddingNewTransactionRow}
            showDirtyModal={showDirtyModal}
            addAndUpdateTransaction={addAndUpdateTransaction}
            cancelCreateUpdateTransaction={cancelCreateUpdateTransaction}
            isGoBack={isGoBack}
            setIsGoBack={setIsGoBack}
          />
        )}
      </DataGridContainer>

      <HorizontalBox
        addTopShadow
        attachToBottom={true}
        hidden={false}
        fullWidth
        stickToCenter={true}
        columnGap={true}
        sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-6) }}
      >
        <DistributionTransactionButton
          id="btn_go_back"
          variant="outlined"
          onClick={goBack}
        >
          Go Back
        </DistributionTransactionButton>
        <DistributionTransactionButton
          id="btn_save_continue"
          variant="contained"
          onClick={saveAndProceed}
        >
          {allowEdit ? "Save and Continue" : "Continue"}
        </DistributionTransactionButton>
      </HorizontalBox>
      {!!selectedTransaction?.type &&
        !["reviewing", "published"].includes(
          distributions?.status?.toLowerCase() || ""
        ) && (
          <EditTransactionDetails
            isFundAdmin={!isSuperAdminOrClientAdmin}
            selectedTransaction={selectedTransaction}
            onTransactionDetailClose={onTransactionDetailClose}
            handleEditTransaction={handleEditTransaction}
            handleDeleteTransaction={handleDeleteTransaction}
          />
        )}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="upload_confirmation_distributions"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "upload_button_distributions",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "cancel_button_distributions",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />

      <DeleteConfirmationDialog
        open={Boolean(deleteAction)}
        id="delete_confirmation_modal_distributions"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content="This action is permanent and cannot be undone"
      />
    </Box>
  );
};

export default React.memo(AddTransactionsTab);
