import { FlagCircleOutlined } from "@mui/icons-material";
import { GridAlignment } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import DeleteIcon from "../../../../assets/images/icons/icon_delete.svg";
import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import {
  addOrUpdateTransaction,
  getCapitalCallDetails,
  getReviews,
  getTeamRoleswithMembersForFund,
  getTransactions
} from "../../../../services/capitalCalls.service";
import { getFunds } from "../../../../services/fund.service";
import { useCapitalCallDetailsEffect } from "../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks";
import { uploadFile } from "../../../../services/uploads.service";
import { getCurrentUser } from "../../../../services/user.service";
import { GENERIC_ERROR_MESSAGE } from "../../../../utils/constants/text.constants";
import { NegativeCurrencyFormat } from "../../../../utils/helpers/format.helper";
import { Direction, sortData } from "../../../../utils/helpers/sort";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import {
  Reviews,
  SelectedTransaction,
  TeamMembers,
  Transaction,
  TransactionAction,
  TransactionType
} from "../../../../utils/types/capitalCalls.type";
import { AddNewButtonOptions } from "../../../../utils/types/common.type";
import { IFundDetails } from "../../../../utils/types/fund.v2.type";
import {
  CustomType,
  DataGridColDef,
  ImageItem
} from "../../../../utils/types/listItems";
import { ScopeRole } from "../../../../utils/types/user.type";
import { CapitalCallDetailsTabs, TEAM_MEMBERS_ROLE_ERROR } from "../constants";
import { StyledTypography } from "./AddTransaction.styles";
import {
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  DELETE_ACTION_TYPE,
  DELETE_TRANSACTION_ERROR,
  DELETE_TRANSACTION_SUCCESS,
  FETCH_TRANSACTION_ERROR,
  REVIEW_FETCH_ERROR
} from "./constants";

const firstHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Transaction Name",
    hide: false,
    index: 1,
    sortable: false,
    type: "string",
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "fund",
    headerName: "Fund Name",
    hide: false,
    index: 2,
    sortable: false,
    type: "string",
    align: "left" as GridAlignment,
    width: 200,
  },
];

const secondHeaderList: DataGridColDef[] = [
  {
    field: "type",
    headerName: "Transaction Type",
    hide: false,
    index: 4,
    sortable: false,
    type: "string",
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "amount",
    headerName: "Amount",
    hide: false,
    index: 5,
    type: "number",
    customType: CustomType.PositiveCurrency,
    sortable: false,
    currencyCodeField: "currencyCode",
    align: "right" as GridAlignment,
    width: 200,
  },

  {
    field: "date",
    headerName: "Date",
    hide: false,
    index: 6,
    type: "date | string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 200,
  },
];

type RouteProp = {
  id: string;
  section: string;
};
const initialSelectedTransaction: SelectedTransaction = {
  transaction: undefined,
  type: undefined,
};

const INVESTOR_HEADER = {
  field: "investor",
  headerName: "Investor Name",
  hide: false,
  index: 3,
  sortable: false,
  type: "string",
  align: "left" as GridAlignment,
  width: 200,
};
const DefaultTeamMembers = {
  ASSOCIATE: [],
  MANAGER: [],
  DIRECTOR: [],
  FUND_CONTROLLER: [],
  FUND_USER_READ_ONLY: [],
};

export const useTransactionDetails = () => {
  const { id } = useParams<RouteProp>();
  const { informationAlert, state } = useContext(AppContext);
  const user = state.loginUser.currentUser?.username || "";
  const [loading, setLoading] = useState<boolean>(false);
  const [showTransactionForm, setShowTransactionForm] =
    useState<boolean>(false);
  const [isAddingTransaction, setIsAddingTransaction] = useState(false);
  const [isAddingNewTransactionRow, setIsAddingNewTransactionRow] =
    useState(false);
  const [showDirtyModal, setShowDirtyModal] = useState<boolean>(false);
  const [transactionList, setTransactionList] = useState<Transaction[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<SelectedTransaction>(initialSelectedTransaction);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [activeHeaderFields, setActiveHeaderFields] = useState(6);
  const [isGoBack, setIsGoBack] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<
    DELETE_ACTION_TYPE | undefined
  >();
  const [transactionSelectionModel, setTransactionSelectionModel] = useState<
    string[]
  >([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { capitalCall } = useCapitalCallDetailsEffect(
    id !== "new" ? id : undefined
  );
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const history = useHistory();
  const [reviews, setReviews] = useState<Reviews[]>([]);
  const [teamMembers, setTeamMembers] =
    useState<TeamMembers>(DefaultTeamMembers);
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);
  const [selectedFund, setSelectedFund] = useState<IFundDetails>();
  const [fundList, setFundList] = useState<IFundDetails[]>([]);
  const currencySymbol = selectedFund?.currency ||"USD";

  const formatter = NegativeCurrencyFormat(currencySymbol);

  secondHeaderList.map(column => {
    if(column.field === "amount") {
      column.renderCell = ({ value }) =>  formatter.format(value);
      return column;
    }
    return column;
  });

  const handleBulkAction = (actionId: TransactionAction) => {
    switch (actionId) {
      case TransactionAction.DeleteSelected:
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_SELECTED);
        break;
      case TransactionAction.DeleteAll:
        setDeleteAction(DELETE_ACTION_TYPE.DELETE_ALL);
        break;
    }
  };

  const onTransactionDetailClose = () => {
    setSelectedTransaction(initialSelectedTransaction);
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const sortByInvestors = (transList: Transaction[]) => {
    transList.sort(
      sortData({
        name: "investor",
        direction: Direction.Up,
      })
    );

    return transList;
  };

  const allowEdit = useMemo(() => {
    if (id !== "new") {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          capitalCall?.createdBy?.toLowerCase() &&
        capitalCall?.status?.toLowerCase() !== "published"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [id, capitalCall, state]);
  const allowReviewerEdit = useMemo(() => {
    if (capitalCall !== null) {
      const reviewersList = capitalCall?.reviewers?.reduce(
        (
          acc: string[],
          current: {
            name?: string;
          }
        ) => {
          const teamMembersForRole =
            teamMembers?.[(current?.name as keyof TeamMembers) || ""] || [];

          if (teamMembersForRole?.length > 0) {
            return [...acc, ...teamMembersForRole];
          }
          return acc;
        },
        []
      );

      if (id !== "new") {
        if (
          state.loginUser.currentUser?.username.toLowerCase() !==
            capitalCall?.createdBy?.toLowerCase() &&
          capitalCall?.status?.toLowerCase() === "reviewing" &&
          reviewersList?.includes(
            state.loginUser.currentUser?.username.toLowerCase() || ""
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }, [id, capitalCall, state, teamMembers]);

  const fetchTransactions = async (id: string) => {
    try {
      if (id && id !== "new") {
        setLoading(true);
        const res = await getTransactions(id);
        const newList: Transaction[] = res.map((transaction) => {
          return {
            ...transaction,
            metricFractionDigit: 0,
            metricSign: null,
          };
        });

        setTransactionList(sortByInvestors(newList));

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      informationAlert(FETCH_TRANSACTION_ERROR, "error");
    }
  };

  const cancelCreateUpdateTransaction = () => {
    setShowDirtyModal(false);
  };

  const createUpdateTransaction = async (
    currentTransactionList?: Transaction[]
  ) => {
    try {
      setLoading(true);

      const transactionListResult = (
        currentTransactionList || transactionList
      )?.map((transaction) => {
        if (!transaction.isUnsaved) {
          return transaction;
        } else {
          const { id, isUnsaved, ...remainingTransaction } = transaction;

          return remainingTransaction;
        }
      });

      const res = await addOrUpdateTransaction(id, transactionListResult);

      informationAlert(CREATE_TRANSACTION_SUCCESS, "success");
      if (res.length > 0) {
        isGoBack
          ? history.push(
              `/capital-calls/${capitalCall?.id}/${CapitalCallDetailsTabs.CapitalCallSetUp}`
            )
          : history.push(
              `/capital-calls/${capitalCall?.id}/${CapitalCallDetailsTabs.ReviewDocuments}`
            );
      }
    } catch (error) {
      informationAlert(
        (error as unknown as any)?.response?.data?.message ||
          CREATE_TRANSACTION_ERROR,
        "error"
      );
    } finally {
      setLoading(false);
      setIsAddingNewTransactionRow(false);
    }
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setShowTransactionForm(true);
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
    }
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setLoading(true);

      const res = await uploadFile("capitalcalls", uploadedFile);

      setIsDirty(true);
      let newList: Transaction[];
      let order: number = transactionList.length;

      if (res) {
        newList = (res as Transaction[])?.map((listItem) => {
          order = order + 1;
          return {
            ...listItem,
            id: `temp${Math.random() * 100}`,
            displayOrder: order.toString(),
            isUnsaved: true,
          };
        });
        const newTransList = [...transactionList, ...newList];

        setTransactionList(sortByInvestors(newTransList));
      } else {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      informationAlert(
        (error as unknown as any)?.response?.data?.message ||
          GENERIC_ERROR_MESSAGE,
        "error"
      );
    } finally {
      setLoading(false);
      setUploadedFile(undefined);
    }
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleOnView = (transactionId: string, transaction: Transaction) => {
    setSelectedTransaction({
      transaction,
      type: TransactionType.Edit,
    });
  };

  const handleAddTransaction = async (newData: Transaction) => {
    await setIsAddingTransaction(true);
    const data = {
      ...newData,
      id: `temp${Math.random() * 100}`,
      displayOrder: (transactionList.length + 1).toString(),
      isUnsaved: true,
    };

    await setIsDirty(true);
    await setTransactionList((currentTransactionList) =>
      sortByInvestors([
        ...(currentTransactionList && currentTransactionList?.length > 0
          ? currentTransactionList
          : []),
        data,
      ])
    );
    setIsAddingTransaction(false);
  };

  const addAndUpdateTransaction = async (newData: Transaction) => {
    const data = {
      ...newData,
      id: `temp${Math.random() * 100}`,
      displayOrder: (transactionList.length + 1).toString(),
      isUnsaved: true,
    };

    await createUpdateTransaction([
      ...(transactionList && transactionList?.length > 0
        ? transactionList
        : []),
      data,
    ]);
  };

  const handleEditTransaction = (newData: Transaction) => {
    const newList = transactionList.map((listItem) => {
      if (listItem.id === newData.id) {
        return newData;
      } else {
        return listItem;
      }
    });

    setIsDirty(true);
    setTransactionList(sortByInvestors(newList));
  };

  const bulkActions: ImageItem[] = useMemo(() => {
    const actions = [];

    const deleteSelected = {
      id: TransactionAction.DeleteSelected,
      text: `Delete Selected (${transactionSelectionModel?.length || 0})`,
      icon: <img src={DeleteIcon} alt="Delete Selected" height="15" />,
      color: "error",
      optionsSelected: 0,
    };

    const deleteAll = {
      id: TransactionAction.DeleteAll,
      text: "Delete All",
      icon: <img src={DeleteIcon} alt="Delete All" height="15" />,
      optionsSelected: 0,
    };

    if (transactionSelectionModel?.length > 0) {
      actions.push(deleteSelected);
    }

    actions.push(deleteAll);

    return actions;
  }, [transactionSelectionModel]);

  const handleDeleteTransaction = (transactionId: string) => {
    const newList = transactionList.filter(
      (listItem) => listItem.id !== transactionId
    );

    setIsDirty(true);
    setTransactionList(sortByInvestors(newList));
  };

  const deleteSelectedTransactions = () => {
    try {
      const filteredTransactionList = transactionList.filter(
        (transaction) =>
          !transactionSelectionModel.includes(transaction?.id || "")
      );

      setTransactionSelectionModel([]);
      setTransactionList(sortByInvestors(filteredTransactionList));
    } catch (error) {
      informationAlert(DELETE_TRANSACTION_ERROR, "error");
    }
  };

  const deleteAllTransactions = () => {
    setTransactionList([]);
    informationAlert(DELETE_TRANSACTION_SUCCESS, "success");
  };

  const onDeleteConfirm = () => {
    setIsDirty(true);
    if (deleteAction === DELETE_ACTION_TYPE.DELETE_SELECTED) {
      deleteSelectedTransactions();
    } else if (deleteAction === DELETE_ACTION_TYPE.DELETE_ALL) {
      deleteAllTransactions();
    }
    setDeleteAction(undefined);
  };

  const onDeleteCancel = () => {
    setDeleteAction(undefined);
  };

  const getTeamRolesWithMembers = async (fundId: string) => {
    try {
      setTeamMembers(await getTeamRoleswithMembersForFund(fundId));
    } catch (error) {
      informationAlert(TEAM_MEMBERS_ROLE_ERROR, "error");
    }
  };

  useEffect(() => {
    const newHeaderList = [
      ...firstHeaderList,
      {
        ...INVESTOR_HEADER,
        renderCell: (params: any) => {
          let flag = false;

          if (reviews.length > 0) {
            reviews.forEach((review) => {
              if (
                review.investorName === params.row.investor &&
                review.transactionId === params.row.id &&
                review.username === user
              ) {
                if (review.checked === false) {
                  flag = true;
                  return;
                }
              }
            });
          }

          return (
            <>
              {flag ? <FlagCircleOutlined color="error" /> : ""}
              <StyledTypography flag={flag.toString()}>
                {params.row.investor}
              </StyledTypography>
            </>
          );
        },
      },
      ...secondHeaderList,
    ];

    if (
      (capitalCall?.status?.toLowerCase() !== "published" && allowEdit) ||
      allowReviewerEdit
    ) {
      const updatedHeaderList = [
        ...newHeaderList,
        {
          field: "action",
          headerName: "Columns",
          hide: false,
          hideable: false,
          index: 7,
          type: "action",
          customType: CustomType.Action,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          disableReorder: true,
          width: 100,
        },
      ];

      setHeaderList(updatedHeaderList);
    } else {
      setHeaderList(newHeaderList);
    }
  }, [reviews, capitalCall, allowReviewerEdit]);

  useEffect(() => {
    setLoading(true);
    fetchTransactions(id);
    setLoading(false);
  }, [id]);

  useEffectAsync(async(isCanceled) => {
    try {
      const funds: any = await getFunds();

      if(isCanceled()) return;
      setFundList(funds.map((item: any): IFundDetails => item.fund));
    }
    catch(e) {
      if(isCanceled()) return;
      setFundList([]);
    }
  }, []);

  useEffect(() => {
    getTeamRolesWithMembers(capitalCall?.fundId || "");

    setSelectedFund(fundList.find(item => item.id === capitalCall?.fundId));
  }, [capitalCall]);

  const saveAndProceed = async () => {
    if (isAddingNewTransactionRow) {
      setShowDirtyModal(true);
    } else {
      if (isDirty) {
        await createUpdateTransaction();
      } else {
        const currentUser = await getCurrentUser();
        const currentCapitalCall = await getCapitalCallDetails(id);

        if(user === reviews[0].username){
         if(currentUser.scopeRole === 'SUPER_ADMIN' || currentUser.scopeRole === 'ARK_CLIENT_ADMIN'){      
          currentCapitalCall.status === 'REVIEWING' ? ("") : (await createUpdateTransaction());
        }}
        
        history.push(
          `/capital-calls/${id}/${CapitalCallDetailsTabs.ReviewDocuments}`
        );
      }
    }
  };

  const goBack = () => {
    setIsGoBack(true);
  };

  const createUpdateTransactionConfirm = () => {
    if (isDirty) {
      createUpdateTransaction();
    } else {
      history.push(
        `/capital-calls/${id}/${CapitalCallDetailsTabs.ReviewDocuments}`
      );
    }
  };
  const fetchReviews = async () => {
    try {
      const res = await getReviews(id);

      setReviews(res);
    } catch (error) {
      informationAlert(REVIEW_FETCH_ERROR, "error");
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [id]);

  useEffect(() => {
    if (isGoBack) {
      if (isAddingNewTransactionRow) {
        setShowDirtyModal(true);
      } else {
        if (isDirty) {
          createUpdateTransaction();
        } else {
          history.push(
            `/capital-calls/${id}/${CapitalCallDetailsTabs.CapitalCallSetUp}`
          );
        }
      }
    }
  }, [isGoBack]);
  return {
    transactionList,
    headerList,
    loading,
    activeHeaderFields,
    handleUpdateHeader,
    setTransactionList,
    createUpdateTransaction,
    showTransactionForm,
    setShowTransactionForm,
    handleAddTransaction,
    handleNewButtonAction,
    handleOnView,
    selectedTransaction,
    handleBulkAction,
    bulkActions,
    onTransactionDetailClose,
    clearUploadedFile,
    uploadedFile,
    setUploadedFile,
    clearUploadCompleted,
    onDeleteConfirm,
    onDeleteCancel,
    handleUploadTemplate,
    deleteAction,
    isUploadComplete,
    transactionSelectionModel,
    setTransactionSelectionModel,
    handleEditTransaction,
    handleDeleteTransaction,
    saveAndProceed,
    isAddingTransaction,
    isSuperAdminOrClientAdmin,
    cancelCreateUpdateTransaction,
    isAddingNewTransactionRow,
    setIsAddingNewTransactionRow,
    showDirtyModal,
    createUpdateTransactionConfirm,
    allowEdit,
    allowReviewerEdit,
    addAndUpdateTransaction,
    isGoBack,
    goBack,
    setIsGoBack,
  };
};
