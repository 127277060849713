import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { InputAdornment, Typography } from '@mui/material';
import { DateRangePicker as DateRangePickerPro } from '@mui/x-date-pickers-pro';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRangeValidationError } from '@mui/x-date-pickers-pro/internal/hooks/validation/useDateRangeValidation';
import { Dispatch, SetStateAction } from 'react';

import { DISPLAY_DATE_FORMAT } from '../../utils/constants/constants';
import TextField from '../TextField/TextField';
import { useDateRangePicker } from './DateRangePicker.hooks';
import { DateRangeContainer } from './DateRangePicker.styles';

type DateRangePickerProps = {
  disabled: boolean;
  dateRange: DateRange<Date>;
  onChange: (dateRange: DateRange<Date>) => void;
  onError?: (error: DateRangeValidationError) => void;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  disabled = false,
  dateRange,
  onChange,
  onError,
}) => {
  const { currentDateRange, handleDateRangeChange, handleDateRangeError } =
    useDateRangePicker(dateRange, onChange, onError);

  return (
    <DateRangePickerPro
      disabled={disabled}
      calendars={1}
      value={currentDateRange}
      onChange={handleDateRangeChange}
      onError={handleDateRangeError}
      inputFormat={DISPLAY_DATE_FORMAT}
      showToolbar={false}
      mask="__/__/____"
      renderInput={(startProps, endProps) => (
        <DateRangeContainer>
          <Typography variant="h6">From</Typography>
          <TextField
            {...startProps}
            isShort
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="h6">To</Typography>
          <TextField
            {...endProps}
            isShort
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        </DateRangeContainer>
      )}
    />
  );
};
