import React, { useEffect, useMemo, useState } from "react";

import { useTeamRolesEffect } from "../../../../services/hooks/useTeamsEffect/useTeamRolesEffect.hooks";
import { useTeamsEffect } from "../../../../services/hooks/useTeamsEffect/useTeamsEffect.hooks";
import { Team } from "../../../../utils/types/team.type";
import {
  ClientListItem,
  TeamMember,
} from "../../../../utils/types/user.type";
import { NO_CLIENT } from "../../constants";

type Props = {
  teamMembers?: TeamMember[];
  onChange: (value: TeamMember[]) => void;
  arkClientTag?: string;
}

export const useClientTeamRoleSelector = ({
  teamMembers,
  onChange,
  arkClientTag
}: Props) => {
  const [clientList, setClientList] = useState<ClientListItem[]>([]);
  const [selectedClient, setSelectedClient] = useState<ClientListItem | undefined>(undefined);
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined);
  const {
    teams
  } = useTeamsEffect();
  const {
    teamRoles
  } = useTeamRolesEffect();

  const isClientSelected= (id: string) => {
    return clientList.find(client => client.clientId === id)?.selected || false;
  };

  useEffect(() => {
    const segregatedClientsMap = teams.reduce((segregatedClients: Record<string, any>, team: Team) => {
      if(arkClientTag && team?.arkClientTag !== arkClientTag) {
        return segregatedClients;
      }
      const isTeamSelected = teamMembers?.some((teamMember: TeamMember) => teamMember.team?.id === team.teamId);
      const clientId = team?.clientId ?? "";
      const isCurrentClientSelected = isClientSelected(clientId);
      const isSelected = isTeamSelected || isCurrentClientSelected;

      if(segregatedClients?.[clientId]) {
        segregatedClients[clientId] = {
          ...segregatedClients[clientId],
          teams: [...(segregatedClients[clientId]?.teams || []), team],
          selected: segregatedClients[clientId]?.selected ? true : isSelected || false,
        };
      } else {
        segregatedClients[clientId] = {
          clientId,
          clientName: team?.clientName ?? "",
          arkClientTag: team?.arkClientTag ?? NO_CLIENT,
          teams: [team],
          selected: isSelected || false,
        };
      }
      return segregatedClients;

    }, {});

    const clients = Object.keys(segregatedClientsMap).map((client) => {
      return segregatedClientsMap[client];
    });

    setClientList(clients);
  }, [teams, arkClientTag, teamMembers]);

  const handleClientListChange = (updatedClientList: ClientListItem[]) => {
    setClientList(updatedClientList);
  };

  const onClientSelect = (client: ClientListItem) => {
    setSelectedClient(client);
    setSelectedTeam(undefined);
  };

  const onClientToggle = (clientId: string) => {
    const currentClientsList = [...clientList];
    const clientIndex = currentClientsList.findIndex((client) => client.clientId === clientId);

    if(clientId === selectedClient?.clientId) {
      setSelectedClient(undefined);
      setSelectedTeam(undefined);
    }

    currentClientsList[clientIndex].selected = !currentClientsList[clientIndex].selected;
    setClientList(currentClientsList);
  };

  const onTeamDeselect = (teamId: string) => {
    onChange((teamMembers || [])?.filter((teamMember) => teamMember?.team?.id !== teamId));
    if(selectedTeam?.teamId === teamId) {
      setSelectedTeam(undefined);
    }
  };

  const onTeamSelect = (team: Team) => {
    setSelectedTeam(team);
  };

  const onRoleSelect = (role: string) => {
    if(!selectedTeam) {
      return;
    }
    const currentTeamMembers = [...(teamMembers || [])];
    const currentTeamIndex = currentTeamMembers.findIndex((teamMember) => teamMember?.team?.id === selectedTeam?.teamId);

    if(currentTeamIndex > -1) {
      currentTeamMembers[currentTeamIndex].role = role;
      onChange(currentTeamMembers);
    } else {
      onChange([
        ...currentTeamMembers,
        {
          role,
          team: {
            id: selectedTeam?.teamId
          },
        }
      ]);
    }
  };

  const selectedClients = useMemo(() => clientList?.filter(client => client.selected), [clientList]);

  const selectedRole = useMemo(() => {
    return teamMembers?.find((teamMember) => teamMember?.team?.id === selectedTeam?.teamId)?.role;
  } , [selectedTeam, teamMembers]);

  return {
    clientList,
    handleClientListChange,
    selectedClients,
    selectedClient,
    onClientSelect,
    onClientToggle,
    onTeamSelect,
    onTeamDeselect,
    selectedTeam,
    onRoleSelect,
    teamRoles,
    selectedRole
  };
};


