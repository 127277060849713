import { SheetTypes, ToolbarButtons } from "../../../workbook.type";
import { SpreadsheetGrid } from "../../SpreadsheetGrid";
import { colsAddResponse } from "../ICellEditHandling";
import { ISheetManager } from "../ISheet.Manager";
import { WorkbookSheetsManager } from "../WorkbookSheets.Manager";

export class TransfersSheetManager implements ISheetManager {
    private _grid!: SpreadsheetGrid;
    private _workbookSheetsManager!: WorkbookSheetsManager;

    get sheetType(): SheetTypes {
        return SheetTypes.Transfers;
    }

    get workbookSheetsManager(): WorkbookSheetsManager {
        return this._workbookSheetsManager;
    }

    setTransfersSheetData(workbookSheetsManager: WorkbookSheetsManager): void {
        this._workbookSheetsManager = workbookSheetsManager;
    }

    OnPreloadedSheet() {
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.Save, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SaveAs, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.GetFromGl, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.RollForward, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SubmitToPcap, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.AllocationsConfig, false);
    };

    OnLoadedSheet(grid: SpreadsheetGrid) {
        this._grid = grid;
    };

    onToolbarButtonClick(buttonName: string) {
        //console.debug('TransfersSheetManager - ' + buttonName);
    }

    onStartEdit(row: string, col: string) {
        return true;
    };

    onEndEdit(row: string, col: string, save: boolean, value: string) {
        return true;
    }

    onColsAdd(cols: string[], toCol: string, right: boolean, empty: boolean): colsAddResponse {
        return {
            validColumnPosition: true,
            newColumnIDs: cols
        };
    }

    onRowAdd(parentRow: string){
        return true;
    }

    onCanColDelete(col: string) {
        return true;
    }

    onCanRowDelete(row: string){
        return true;
    }

    onCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number){
        return true;
    }
}