import { Box, Checkbox, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import React from 'react';

import ContentColumnBox from "../../../components/Boxes/ContentColumnBox/ContentColumnBox";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Container from "../../../components/Container/Container";
import {
  Investor, InvestorDetail,
} from "../../../utils/types/investor.type";
import { InfoSection } from '../investorList/investorDetailPanel/InvestorDetailPanel.styles';

type Props = {
  investor: InvestorDetail | Investor;
}

const InvestorDetailsReadOnlyView = ({ investor }: Props) => {
  const {
    fullName,
    name,
    entityType,
    phone,
    legalType,
    mailingAddress,
    taxAddress,
    disregardedEntity,
    taxIdentificationNumber
  } = investor;

  const useMailingForTax = investor.useMailingForTax ? investor.useMailingForTax : false;

  const FieldValue = (label: string, value: string | undefined) => (
    <InfoSection>
      <div><Typography variant="label">{label}</Typography></div>
      <Typography className="field-value">{value}</Typography>
    </InfoSection>
  );

  return (
    <Container id="container_investor_main" role="main">
      <HorizontalBox>
        {FieldValue('Name', fullName || name)}
        {FieldValue('This investor is an', entityType)}
      </HorizontalBox>
      <HorizontalBox >
        {FieldValue('Phone', phone)}
      </HorizontalBox>
      <HorizontalBox >
        {FieldValue('Address Line 1', mailingAddress?.street1)}
        {FieldValue('Address Line 2', mailingAddress?.street2)}
      </HorizontalBox>
      <HorizontalBox >
        {FieldValue('Zip/Post-Code', mailingAddress?.postalCode)}
        {FieldValue('City', mailingAddress?.city)}
      </HorizontalBox>
      <HorizontalBox >
        {FieldValue('State/Province/Region', mailingAddress?.state)}
        {FieldValue('Country', mailingAddress?.country)}
      </HorizontalBox>

      <ContentColumnBox>
        <FormControlLabel
          control={<Checkbox id="check_Tax_Address" disabled={true} />}
          id="check_investor_tax_address"
          label="Tax Address"
          checked={useMailingForTax}

        />
        <FormHelperText>
          Check if same as primary address above
        </FormHelperText>
      </ContentColumnBox>
      {!useMailingForTax && <React.Fragment>
        <HorizontalBox >
          {FieldValue('Address Line 1', taxAddress?.street1)}
          {FieldValue('Address Line 2', taxAddress?.street2)}
        </HorizontalBox>
        <HorizontalBox >
          {FieldValue('Zip/Post-Code', taxAddress?.postalCode)}
          {FieldValue('City', taxAddress?.city)}
        </HorizontalBox>
        <HorizontalBox >
          {FieldValue('State/Province/Region', taxAddress?.state)}
          {FieldValue('Country', taxAddress?.country)}
        </HorizontalBox>
      </React.Fragment>}

      <HorizontalBox >
        {FieldValue('Legal Type', legalType?.label)}
        <InfoSection>
          <div>
            <FormControlLabel
              control={<Checkbox checked={disregardedEntity} disabled={true} />}
              id="check_investor_disregarded"
              label="Disregarded Entity"
            />
          </div>
          <FormHelperText>Check if yes</FormHelperText>
        </InfoSection>
      </HorizontalBox>
    </Container>
  );
};

export default InvestorDetailsReadOnlyView;