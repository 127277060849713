import { GridAlignment } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import { getDistribution } from "../../../../services/distributions.service";
import { useDistributionDetailsEffect } from "../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { DISPLAY_DATE_FORMAT } from "../../../../utils/constants/constants";
import {
  Component,
  Transaction,
  TransactionCRUDStatus,
} from "../../../../utils/types/distributions.type";
import { DataGridColDef } from "../../../../utils/types/listItems";
import { DELETE_TRANSACTION_ERROR } from "./constants";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "label",
    headerName: "Label",
    hide: false,
    index: 1,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "value",
    headerName: "Value",
    hide: false,
    index: 2,
    type: "number | string",
    sortable: true,
    align: "right" as GridAlignment,
    width: 200,
  },
];

type Props = {
  transaction?: Transaction;
  onTransactionDetailClose: Function;
  handleEditTransaction: (transaction: Transaction) => void;
  handleDeleteTransaction: (id: string) => void;
};

type RouteProp = {
  id: string;
  section: string;
};

export const useEditTransactionDetails = ({
  transaction,
  onTransactionDetailClose,
  handleEditTransaction,
  handleDeleteTransaction,
}: Props) => {
  const { id } = useParams<RouteProp>();

  const compList = transaction?.components.map((comp, index) => {
    return { ...comp, id: (index + 1).toString() };
  });

  const { distributions } = useDistributionDetailsEffect(
    id !== "new" ? id : undefined
  );

  const [loading, setLoading] = useState<TransactionCRUDStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [distributionName, setDistributionName] = useState<string>("");
  const [distributionId, setDistributionId] = useState<string>("");
  const { informationAlert } = useContext(AppContext);
  const [componentList, setComponentList] = useState<Component[]>([]);

  const getDistributionDetails = async (id: string) => {
    const distributionDetails = await getDistribution(id);

    setDistributionName(distributionDetails.fundName);

    setDistributionId(distributionDetails.fundId!);
  };

  getDistributionDetails(id);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm<Transaction>({
    defaultValues: transaction
      ? transaction
      : {
          date: new Date(),
        },
  });
  const { isDirty } = useFormState({
    control,
  });

  useEffect(() => {
    reset(transaction);
  }, [transaction, reset]);

  useEffect(() => {
    if (distributions) {
      setValue("fund", distributions.fundName || "");
    }
  }, [distributions]);

  useEffect(() => {
    setHeaderList(defaultHeaderList);

    setComponentList(compList as Component[]);
  }, []);

  const updateTransaction = (data: Transaction | any) => {
    const newData = {
      ...data,
      date: format(new Date(data.date), DISPLAY_DATE_FORMAT),
    };

    handleEditTransaction(newData);
    closeDrawer();
  };
  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset({
      date: new Date(),
    });
    onTransactionDetailClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleDelete = () => {
    setToBeDeleted(transaction?.id ?? "");
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = (transactionToBeDeleted: string) => {
    if (!transactionToBeDeleted) {
      return;
    }
    setToBeDeleted(undefined);
    try {
      handleDeleteTransaction(transaction?.id ?? "");
      closeDrawer();
    } catch (error) {
      informationAlert(DELETE_TRANSACTION_ERROR, "error");
      setLoading(undefined);
    }
  };

  const selectedFund = watch("fund");

  return {
    loading,
    register,
    handleSubmit,
    errors,
    control,
    selectedFund,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    distributions,
    updateTransaction,
    headerList,
    componentList,
    distributionName,
    distributionId,
  };
};
