import { Typography } from '@mui/material';

export type TagType = 'investor' | 'fund' | 'quarter';

export type Tags = {
  investor?: string;
  funds?: string[];
  quarter?: string;
  selectedTags: Array<TagType>;
  permissions: {
    downloadEnabled: boolean;
    printEnabled: boolean;
    copyEnabled: boolean;
  };
  archivePolicy: string;
  archiveOn?: Date;
};

export const DEFAULT_FORM_VALUES: Tags = {
  funds: [],
  investor: '',
  quarter: '',
  selectedTags: [],
  permissions: {
    downloadEnabled: true,
    printEnabled: true,
    copyEnabled: true,
  },
  archivePolicy: 'RESPECT_FOLDER',
  archiveOn: new Date(),
};

export type ArchiveOption = {
  id: string;
  label: string;
};

export const ARCHIVE_POLICY_OPTIONS: ArchiveOption[] = [
  { id: 'RESPECT_FOLDER', label: 'Respect Folder Settings' },
  { id: 'DISABLE_ARCHIVE', label: 'Disable Archiving' },
  { id: 'RESPECT_FILE', label: 'Set Custom Date' },
];

export enum ERROR_MESSAGE {
  INVALID = 'Valid date is required',
  PAST_MIN_DATE = 'Date must be after January 1st, 1970',
}

interface JSXElementObject {
  pdfSettings: React.ReactNode;
  download: React.ReactNode;
  print: React.ReactNode;
  copy: React.ReactNode;
}

export const TooltipText: JSXElementObject = {
  pdfSettings: (
    <Typography variant="body2">
      Any adjustments made in 'PDF Specific Settings' will only affect PDF files
      in this bulk upload. Please be aware that settings applied to PDFs before
      upload will persist.
    </Typography>
  ),
  download: (
    <>
      <Typography variant="body2" paddingBottom={'8px'}>
        <strong>Download On:</strong> Enabling download allows LP users to
        download the selected PDFs.
      </Typography>
      <Typography variant="body2">
        <strong>Download Off:</strong> Disabling download prevents LP users from
        downloading the selected PDFs.
      </Typography>
    </>
  ),
  print: (
    <>
      <Typography variant="body2" paddingBottom={'8px'}>
        <strong>Print On:</strong> Enabling print allows LP users to print the
        selected PDFs.
      </Typography>
      <Typography variant="body2">
        <strong>Print Off:</strong> Disabling print prevents LP users from
        printing the selected PDFs.
      </Typography>
    </>
  ),
  copy: (
    <>
      <Typography variant="body2" paddingBottom={'8px'}>
        <strong>Copy On:</strong> Enabling copy allows LP users to copy text
        from the selected PDFs.
      </Typography>
      <Typography variant="body2">
        <strong>Copy Off:</strong> Disabling copy prevents LP users from copying
        text from the selected PDFs.
      </Typography>
    </>
  ),
};
