import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React, { ReactElement, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import DeleteIcon from "../../../assets/images/icons/icon_delete.svg";
import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import GreenEllipse from "../../../assets/images/icons/icon_green_ellipse.svg";
import RedEllipse from "../../../assets/images/icons/icon_red_ellipse.svg";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import FullScreenModal from "../../../components/Modal/FullScreenModal/FullScreenModal";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../components/SearchBarWithDebounce/SearchBar";
import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import useRole from "../../../core/routing/useRole";
import {
  TEXT_CANCEL,
  TEXT_CONFIRM,
} from "../../../utils/constants/text.constants";
import { ImageItem } from "../../../utils/types/listItems";
import { ScopeRole } from "../../../utils/types/user.type";
import InvestorDetailPanel from "./investorDetailPanel/InvestorDetailPanel";
import { NewInvestorOptions } from "./InvestorList.constants";
import {
  useBulkActionOptionSelectionEffect,
  useInvestorListEffect,
  useSearchTextEffect,
} from "./InvestorList.hooks";
import { ButtonBox } from "./InvestorList.styles";
import MfaModal from "./MfaModal";

const InvestorList: React.FC = (): ReactElement => {
  const { state } = useContext(AppContext);
  const clientId = state.loginUser?.clientId;
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const {
    isLoading,
    isUploadComplete,
    isSubmitting,
    setIsSubmitting,
    clearUploadedFile,
    clearUploadCompleted,
    uploadedFile,
    handleActivateInvestorsConfirmation,
    handleActivateInvestorsCancel,
    handleDeleteInvestorsConfirmation,
    handleDeleteInvestorsCancel,
    handleUploadTemplate,
    handleOnSearch,
    handleNewButtonAction,
    onRowColumnOrderChange,
    headerList,
    activeHeaderFields,
    investorFilteredList,
    originalInvestorList,
    setInvestorsList,
    handleUpdateHeader,
    investorListLoaded,
    sugestionList,
    isLoadingInvestorList,
    handleFilter,
    showActivateConfirmationPopover,
    openDetailPanel,
    onDetailPanelClose,
    selectedInvestorId,
    setSelectedInvestorId,
    selectedInvestor,
    handleOnView,
    searchText,
    investorSelectionModel,
    setInvestorSelectionModel,
    isActivating,
    showDeleteConfirmationPopover,
    handleBulkOptionClick,
    setSelectedInvestorListItem,
    showMfaModal,
    setShowMfaModal,
    userMfaInfo,
    setUserMfaInfo,
    setLoadingInvestorList,
  } = useInvestorListEffect(clientId);

  const history = useHistory();

  useSearchTextEffect(searchText, originalInvestorList, setInvestorsList);

  const defaultBulkActions: ImageItem[] = [
    {
      id: "export",
      text: "Export",
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
    {
      id: "activate",
      text: "Activate",
      icon: <img src={GreenEllipse} alt="" width="10" height="10" />,
      optionsSelected: 0,
    },
    {
      id: "inactivate",
      text: "Inactivate",
      icon: <img src={RedEllipse} alt="" width="10" height="10" />,
      optionsSelected: 0,
    },
    {
      id: "delete",
      text: "Delete",
      icon: <img src={DeleteIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
  ];

  const { bulkActionOptions } = useBulkActionOptionSelectionEffect(
    defaultBulkActions,
    investorSelectionModel
  );

  return (
    <DataWrapperBox id="investor_list_main" role="main">
      <Progress id="investor_detail_list_loading" showProgress={isLoading} />

      <HorizontalBox fullWidth noPadding>
        <HorizontalBox noPadding>
          <Typography variant="pageTitle">Investors</Typography>

          <SearchBar
            id="txt_search_investor"
            size="small"
            onChange={handleOnSearch}
            value={searchText}
          />
        </HorizontalBox>

        <ButtonBox>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActionOptions}
            hidden={investorSelectionModel.length === 0}
            handleOptionClick={handleBulkOptionClick}
            ariaLabelMessage="Select bulk action option"
          />

          {isSuperAdminOrClientAdmin && (
            <ButtonWithOptions
              buttonID="btn_add_new_investor"
              popoverID="popover_add_investor"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewInvestorOptions}
            />
          )}
        </ButtonBox>
      </HorizontalBox>

      {(!isLoadingInvestorList || investorListLoaded) &&
        investorFilteredList && (
          <DataGrid
            id="investor_data_grid"
            dataList={investorFilteredList}
            headerList={headerList}
            autoHeight={false}
            minHeight="1vh"
            handleOnView={handleOnView}
            selectionModel={investorSelectionModel}
            setSelectionModel={setInvestorSelectionModel}
            activeHeaderFields={activeHeaderFields}
            handleUpdateHeader={handleUpdateHeader}
            handleFilter={handleFilter}
            onColumnOrderChange={onRowColumnOrderChange}
            onNextPage={() => {}}
          />
        )}

      {!!selectedInvestor.detailView && (
        <InvestorDetailPanel
          onDetailPanelClose={onDetailPanelClose}
          selectedInvestor={selectedInvestor}
          setIsSubmitting={setIsSubmitting}
          isSubmitting={isSubmitting}
        />
      )}

      <FullScreenModal
        id="dialog_investor_list_activate_confirmation"
        open={showActivateConfirmationPopover}
        title={`Are you sure you want to ${
          isActivating ? "activate" : "inactivate"
        } (${investorSelectionModel.length}) items?`}
        subtitle={undefined}
        confirmButtonText={TEXT_CONFIRM}
        cancelButtonText={TEXT_CANCEL}
        onSuccess={handleActivateInvestorsConfirmation}
        onCancel={handleActivateInvestorsCancel}
      />

      <MfaModal
        open={showMfaModal}
        handleClose={() => {
          setShowMfaModal(false);
        }}
        userMfaInfo={userMfaInfo}
        setUserMfaInfo={setUserMfaInfo}
        setLoadingInvestorList={setLoadingInvestorList}
        onSuccessCallback={() => {
          setShowMfaModal(false);
          openDetailPanel(selectedInvestorId);
        }}
        onfailCallback={() => {
          setSelectedInvestorId("");
          setSelectedInvestorListItem(null);
        }}
      />
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "btn_upload",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "btn_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="confirmation_upload_complete"
        actions={[
          {
            label: "Upload History",
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: "btn_upload_history",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Close",
            onClick: clearUploadCompleted,
            id: "btn_close",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Investors file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the upload."
        title="Upload Results"
      />
      <DeleteConfirmationDialog
        id="dialog_investor_list_delete_confirmation"
        open={showDeleteConfirmationPopover}
        primaryButtonAction={handleDeleteInvestorsConfirmation}
        secondaryButtonAction={handleDeleteInvestorsCancel}
      />
    </DataWrapperBox>
  );
};

export default InvestorList;
