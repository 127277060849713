import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { AppContext } from '../../core/context/appContextProvider';
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import RoutingPaths from "../../core/routing/routingPaths";
import { changeUserPassword } from '../../services/user.service';
import { getSubdomainAndDomain } from '../../utils/helpers/misc.helper';
import {
  ActionButton,
  ContentDiv,
  LoginView,
  StyledTextField,
} from "./Login.styles";
import LoginSidebar from "./LoginSidebar";
import RightSection from "./RightSection";

const loginFormValidation = Yup.object({
  password: Yup.string()
    .min(8, "Minimum 8 characters are required")
    .max(36, "Maximum 36 characters are allowed")
    .required("Password is required")
    .matches(/^(?=.*\S)(?=.*[!@#$%^&*()\-_=+|?\/.<>,])[\w!@#$%^&*()\-_=+|?\/.<>,]{8,36}$/, 
    "The password must be between 8 and 36 characters, containing at least one symbol !@#$%^&*()-_+=|?/.<>, Symbols outside of those listed are not accepted in passwords."),
    confirmpassword: Yup.string().required("Confirm password is required"),
}).test("PasswordInequalityError", (value, { createError }) => {
  const { password, confirmpassword } = value;

  return password === confirmpassword ? true : createError({
      message: "The passwords do not match",
      path: "confirmpassword",
  });
});

const ResetPassword = () => {
  const {
    subdomain, domain
  } = getSubdomainAndDomain(window.location.hostname);
  const { preAuthClientConfigs } = useContext(AppContext);
  const { clientTheme } = useContext(ClientThemeContext);
  const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [passwordRequestSent, setPasswordRequestSent] = useState(false);
  const [changePasswordMessage, setChangePasswordMessage] = useState('');

  const urlParams = new URLSearchParams(useLocation().search);
  const tokenFromParam = urlParams.get('t');
  const history = useHistory();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    password: string;
    confirmpassword: string;
  }>({
    defaultValues: {
      password: "",
      confirmpassword: "",
    },
    resolver: yupResolver(loginFormValidation),
  });

  const changePassword = async (data: any) => {
    if(tokenFromParam) {
      setShowLoginLoader(true);

      const params = {
          clientBaseDomain: preAuthClientConfigs?.baseDomain,
          clientSubdomain: preAuthClientConfigs?.subdomain,
          clientId: preAuthClientConfigs?.clientId,
          clientName: preAuthClientConfigs?.clientName,
          clientSupportEmail: preAuthClientConfigs?.supportEmail,
          password: data?.password,
          token: tokenFromParam,
        };

        try {
          await changeUserPassword(params);
        
          setShowLoginLoader(false);
          history.push(RoutingPaths.SigninPath);
          setPasswordRequestSent(true);
          setChangePasswordMessage("Password changed successfully");
        } catch (error: any) {
          const errorMessage = error.response?.data?.message || "Failed to change password";
        
          setShowLoginLoader(false);
          setPasswordRequestSent(true);
          setChangePasswordMessage(errorMessage);
        }
    }
  };

  return (
    <>
      <Progress id="login_loader" showProgress={showLoginLoader} />
      <div className="container">
        <LoginView container>
          <Grid item md={3}>
          <LoginSidebar>
            <form onSubmit={handleSubmit(changePassword)}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                rowSpacing={1}
                direction="row"
              >
                <Grid container justifyContent="center" md={12} xs={12}>
                  <ContentDiv mb={12}>
                    New Password:
                    <StyledTextField
                      size="small"
                      id="reset_new_pass"
                      type="password"
                      variant="outlined"
                      InputProps={{ notched: true }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                    />
                  </ContentDiv>
                </Grid>
                <Grid container justifyContent="center" md={12} xs={12}>
                  <ContentDiv mb={12}>
                    Confirm Password:
                    <StyledTextField
                      size="small"
                      id="reset_confirm_pass"
                      type="password"
                      variant="outlined"
                      InputProps={{ notched: true }}
                      {...register("confirmpassword")}
                      error={!!errors.confirmpassword}
                      helperText={errors?.confirmpassword?.message}
                    />
                  </ContentDiv>
                </Grid>

                <Grid container justifyContent="center" md={12} xs={12}>
                  <ContentDiv>
                    <ActionButton
                      type="submit"
                      variant="contained"
                      className="btn"
                      disableElevation
                      textcolor={clientTheme?.loginButtonTextColor}
                      btnbackgroundcolor={
                        clientTheme?.loginButtonBackgroundColor
                      }
                      fullWidth
                    >
                      Change password
                    </ActionButton>
                  </ContentDiv>
                </Grid>

            {passwordRequestSent ? (
              <Grid container justifyContent="center" md={12} xs={12}>
                <ContentDiv mb={12}>
                {changePasswordMessage}
                </ContentDiv>
              </Grid>
            ) : null}

              </Grid>
            </form>
          </LoginSidebar>
          </Grid>
          <Grid item md={9}>
            <RightSection />
          </Grid>
        </LoginView>
      </div>
    </>
  );
};

export default ResetPassword;
