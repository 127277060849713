import { useInvestorsNamesEffect } from "../../../services/hooks/useInvestorsNameEffect/useInvestorsNamesEffect.hooks";
//TODO: Added a hook as this component may need some extra handling in future.

type Props = {
  fundId?: string;
}

const useInvestorFilter = ({
  fundId
}: Props) => {
  const {
    investorsList,
    loading
  } = useInvestorsNamesEffect({
    fundId
  });

  return {
    investorsList,
    loading,
  };
};

export default useInvestorFilter;

