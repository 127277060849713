import { Dispatch, SetStateAction } from 'react';

import { DataGrid } from '../../../../../components';
import { JournalEntryDetails } from '../../../journalEntries/journalEntryDetails/JournalEntryDetails';
import {
  GlGridData,
  useGeneralLedgerReport,
} from './GeneralLedgerReport.hooks';

interface Props {
  attributes: any;
  funds: any;
  isLoading: boolean;
  params: any;
  setIsLoading: (isLoading: boolean) => void;
  suppressIfZero: boolean;
  onDataGridChange: (gridData: GlGridData, exportName: string) => void;
  fetchGlReport: boolean;
  setFetchGlReport: Dispatch<SetStateAction<boolean>>;
}

export const GeneralLedgerReport: React.FC<Props> = ({
  attributes,
  funds,
  isLoading,
  params,
  setIsLoading,
  suppressIfZero,
  onDataGridChange,
  fetchGlReport,
  setFetchGlReport,
}: Props) => {
  const {
    headerList,
    gridData,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    handleUpdateHeader,
  } = useGeneralLedgerReport(
    params,
    setIsLoading,
    suppressIfZero,
    attributes,
    funds,
    onDataGridChange,
    fetchGlReport,
    setFetchGlReport
  );

  return (
    <>
      <DataGrid
        id={'general_ledger_report_grid_id'}
        dataList={gridData ?? []}
        headerList={headerList ?? []}
        noDataMessage={isLoading ? '' : 'No Data'}
        minHeight={'1vh'}
        autoHeight={false}
        checkboxSelection={false}
        onColumnOrderChange={() => {}}
        handleUpdateHeader={handleUpdateHeader}
      />
      {!!selectedJournalEntry?.type && (
        <JournalEntryDetails
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
          onDetailClose={onJournalEntryPanelClose}
          fetchAllJournalEntries={() => {}}
          fundId={selectedJournalEntry.journalEntry?.fundId}
        />
      )}
    </>
  );
};
