import { DataGridColDef } from "../../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
    {
        headerName: "Limited Partner",
        field: "lp",
        hide: false,
        index: 0,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "Special Limited Partner",
        field: "slp",
        hide: false,
        index: 1,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "General Partner",
        field: "gp",
        hide: false,
        index: 2,        
        sortable: false,
        type: 'string'
    },
    {
        headerName: "Internal Limited Partner",
        field: "ilp",
        hide: false,
        index: 3,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "External Limited Partner",
        field: "elp",
        hide: false,
        index: 4,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "Hybrid Limited Partner",
        field: "hlp",
        hide: false,
        index: 5,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "Member",
        field: "member",
        hide: false,
        index: 6,
        sortable: false,
        type: 'string'
    },
    {
        headerName: "Managing Member",
        field: "managingMember",
        hide: false,
        index: 7,        
        sortable: false,
        type: 'string'
    },
    {   
        field: "action",
        headerName: "",
        hide: false,
        index: 8,
        type: "action",
        sortable: false,
    },
];