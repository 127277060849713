import { Dispatch, SetStateAction } from 'react';

import DataGrid from '../../../../../components/DataGrid/DataGrid';
import DetailPanel from '../../../../../components/DetailPanel/DetailPanel';
import { PanelBoxExpanded } from '../../../../../components/DetailPanel/DetailPanel.styles';
import ProgressPanel from '../../../../../components/Progress/ProgressPanel/ProgressPanel';
import { JournalEntryDetails } from '../../../journalEntries/journalEntryDetails/JournalEntryDetails';
import ExportButton from '../../ExportButton';
import { GlViews } from '../../NewGlReportList.hooks';
import { ButtonBoxDetails } from '../../ReportList.styles';
import { ReportDetailsParams } from '../shared';
import { useReportDetails } from './ReportDetails.hooks';

type ReportDetailsProps = {
  open: boolean;
  reportParams?: ReportDetailsParams;
  reportView: string;
  summaryRowClicked?: boolean;
  zeroSumStartBalance?: boolean;
  customStartBalanceClicked?: boolean;
  customEndBalanceClicked?: boolean;
  customItdClicked?: boolean;
  level1Clicked?: boolean;
  dateRange?: any;
  netRow?: boolean;
  retainedRow?: boolean;
  fetchGlReport?: boolean;
  setFetchGlReport?: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

export const ReportDetails: React.FC<ReportDetailsProps> = ({
  open,
  reportParams,
  onClose,
  reportView,
  summaryRowClicked,
  zeroSumStartBalance,
  customStartBalanceClicked,
  customEndBalanceClicked,
  customItdClicked,
  level1Clicked,
  dateRange,
  netRow,
  retainedRow,
  fetchGlReport,
  setFetchGlReport,
}) => {
  if (!reportParams) return <></>;

  const {
    isLoading,
    dataGridColDefs,
    gridData,
    dateTitle,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
  } = useReportDetails(
    reportParams,
    reportView,
    summaryRowClicked!,
    zeroSumStartBalance!,
    customStartBalanceClicked!,
    customEndBalanceClicked!,
    customItdClicked!,
    level1Clicked!,
    dateRange,
    netRow!,
    retainedRow!,
    fetchGlReport,
    setFetchGlReport
  );

  return (
    <>
      <DetailPanel
        id="detail_panel_reports"
        title={`Details ${dateTitle}`}
        open={open}
        hasTabsPanel={false}
        onClose={onClose}
        isGridDisplay={true}
      >
        <PanelBoxExpanded>
          <DataGrid
            id={'report_details'}
            dataList={gridData || []}
            headerList={dataGridColDefs ?? []}
            noDataMessage={isLoading ? '' : undefined}
            minHeight="77vh"
            autoHeight={false}
            checkboxSelection={false}
            disableColumnReorder={true}
          />
          <ButtonBoxDetails>
            <ExportButton
              id="btn_export_wrapper"
              hidden={!gridData.length}
              headers={dataGridColDefs || []}
              data={gridData || []}
              fileName={`Ark Report Details ${dateTitle}`}
            />
          </ButtonBoxDetails>
        </PanelBoxExpanded>
        <ProgressPanel
          id={'progress_report_detail_panel'}
          showProgress={isLoading}
          text={'Loading...'}
        />
      </DetailPanel>
      {!!selectedJournalEntry?.type && (
        <JournalEntryDetails
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
          onDetailClose={onJournalEntryPanelClose}
          fetchAllJournalEntries={() => {}}
          fundId={selectedJournalEntry.journalEntry?.fundId}
        />
      )}
    </>
  );
};
