import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Grid, TextField, Typography } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import DataGrid from "../../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import InvestorFilter from "../../../../components/Selectors/InvestorsFilter";
import TransactionTypeFilter from "../../../../components/Selectors/TransactionTypesFilter";
import NumberFormatCustom from "../../../../components/TextFieldNumeric/NumberFormat";
import {
  SelectedTransaction,
  Transaction,
} from "../../../../utils/types/capitalCalls.type";
import { DatePicker } from "../../../fund/fundDetails/FundDetails.style";
import { StyledBackdrop } from "../../../investors/investorList/investorDetailPanel/InvestorDetailPanel.styles";
import {
  AmountTextField,
  DetailPanel,
  FormBox,
  LinkButton,
  StyledStack,
  StyledTextField,
  StyledTypography,
  TitleBox,
} from "./EditTransactionDetails.styles";
import { useEditTransactionDetails } from "./useEditTransactionDetails.hooks";

interface Props extends RouteProps {
  onTransactionDetailClose: Function;
  handleEditTransaction: (transaction: Transaction) => void;
  selectedTransaction: SelectedTransaction;
  handleDeleteTransaction: (id: string) => void;
  isFundAdmin: boolean | undefined;
}

const EditTransactionDetails: React.FC<Props> = ({
  onTransactionDetailClose,
  handleEditTransaction,
  handleDeleteTransaction,
  selectedTransaction,
  isFundAdmin
}: Props): ReactElement => {
  const { type, transaction } = selectedTransaction;
  const {
    register,
    handleSubmit,
    errors,
    control,
    updateTransaction,
    loading,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    capitalCall,
    headerList,
    componentList,
  } = useEditTransactionDetails({
    transaction,
    handleEditTransaction,
    handleDeleteTransaction,
    onTransactionDetailClose,
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="transaction_drawer"
        anchor="right"
        open={Boolean(type)}
        onClose={toggleDrawer}
      >
        <TitleBox>
          <Typography id="title" variant="formTitle" display="flex">
            {"View & Edit Transaction"}
          </Typography>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(updateTransaction)}
          noValidate
        >
          <FormBox>
            <Grid container xs={12} spacing={4}>
              <Grid item xs={6}>
                <TextField
                  id="transaction_name"
                  size="small"
                  fullWidth
                  aria-describedby="name"
                  label="Transaction Name"
                  placeholder="Transaction Name"
                  variant="outlined"
                  disabled={isFundAdmin}
                  error={!!errors.name}
                  {...register("name", {
                    required: true,
                    validate: (value: any) => !!value?.trim(),
                  })}
                  helperText={!!errors.name && "Transaction Name is required"}
                />
              </Grid>
              {!isFundAdmin &&
                <Grid xs={6} item>
                  <LinkButton
                    id="delete_transaction_button"
                    variant="outlined"
                    disableElevation
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={handleDelete}
                    name="Delete Transaction"
                  >
                    Delete Transaction
                  </LinkButton>
                </Grid>
              }
              <Grid item xs={12}>
                <StyledTextField
                  id="fund_name"
                  size="small"
                  aria-describedby="name"
                  label="Fund Name"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  {...register("fund")}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="investor"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <StyledStack>
                      <InvestorFilter
                        size="small"
                        id={"transaction_investor_filter"}
                        value={value}
                        fundId={capitalCall?.fundId}
                        onChange={onChange}
                        error={error}
                        disabled={isFundAdmin}
                        nameSelectOnly
                        placeholder="Investor Name"
                      />
                    </StyledStack>
                  )}
                  rules={{
                    required: "Investor is required",
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="type"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <StyledStack>
                      <TransactionTypeFilter
                        size="small"
                        id={"transaction_type_filter"}
                        value={value}
                        error={error}
                        disabled={isFundAdmin}
                        onChange={onChange}
                        nameSelectOnly
                        placeholder="Transaction Type"
                      />
                    </StyledStack>
                  )}
                  rules={{
                    required: "Transaction Type is required",
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="amount"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <AmountTextField
                        label="Amount"
                        variant="outlined"
                        size="small"
                        value={value ?? ""}
                        disabled={isFundAdmin}
                        onChange={onChange}
                        id="transaction_amount"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message ?? ""}
                      />
                    );
                  }}
                  rules={{
                    required: "Amount is required",
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <StyledStack>
                        <DatePicker
                          label="Date"
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              size="small"
                              id="transaction_date"
                              defaultValue={value}
                              disabled={isFundAdmin}
                              error={!!error}
                              helperText={error?.message ?? ""}
                            />
                          )}
                          onChange={(value) => {
                            onChange({
                              target: {
                                value: value,
                              },
                            });
                          }}
                          value={value}
                        />
                      </StyledStack>
                    );
                  }}
                  rules={{
                    required: "Date is required",
                  }}
                  control={control}
                  defaultValue={undefined}
                />
              </Grid>
              <Grid xs={6} item />
              {transaction?.components && transaction?.components.length > 0 && (
                <Grid xs={12} item>
                  <StyledTypography variant="h3">
                    Custom Merge Feilds
                  </StyledTypography>

                  <DataGrid
                    id="component_data_grid"
                    dataList={componentList}
                    headerList={headerList}
                    noDataMessage={loading ? "Fetching Data..." : undefined}
                    minHeight="10vh"
                    autoHeight={true}
                  />
                </Grid>
              )}
            </Grid>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            stickToCenter={true}
            columnGap={true}
          >
            <Button
              id={"transaction_cancel_button"}
              variant="outlined"
              onClick={toggleDrawer}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />
            {!isFundAdmin &&
              <Button
                id={"transaction_save_button"}
                variant="contained"
                text={"Save & Close"}
                color={"primary"}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            }
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={"progress_transaction_panel"}
          showProgress={Boolean(loading)}
          text={`${loading} Transaction...`}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete_transaction_confirmation"
        actions={[
          {
            label: "Delete Permanently",
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: "delete_transaction_modal_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: handleCancelDelete,
            id: "cancel_delete_transaction_modal_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Are you sure you want to delete this transaction? This action cannot be undone."
        title="Delete transaction?"
      />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_transaction_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "save_transaction_modal_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "cancel_save_transaction_modal_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </React.Fragment>
  );
};

export default EditTransactionDetails;
