import AddIcon from '@mui/icons-material/Add';
import { Box, ButtonProps, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProgressModal } from '../../components';
import { DataWrapperBox } from '../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import Button from '../../components/Buttons/Button/Button';
import SplitButton from '../../components/Buttons/SplitButton/SplitButton';
import ConfirmationDialog from '../../components/Modal/ConfirmationDialog';
import { Action } from '../../components/Modal/DeleteConfirmationDialog/constants';
import DeleteConfirmationDialog from '../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import SearchBar from '../../components/SearchBar/SearchBar';
import TabsPanel from '../../components/TabsPanel/TabsPanel';
import { useGeneralMailings } from './GeneralMailings.hooks';
import {
  ButtonBox,
  HeaderRow,
  StyledDataGridPro,
} from './GeneralMailings.style';
import MailingsComposerPanel from './mailingsComposerPanel/MailingsComposerPanel';
import { ViewType } from './mailingsComposerPanel/MailingsComposerPanel.constants';
import { useMailingsComposerEffect } from './mailingsComposerPanel/MailingsComposerPanel.hooks';
import MailingsPreviewPanel from './mailingsPreviewPanel/MailingsPreviewPanel';

export const GeneralMailings: React.FC = () => {
  const {
    generalMailingList,
    setGeneralMailingList,
    headerList,
    onSearch,
    searchOptions,
    search,
    showMailingPanel,
    setShowMailingPanel,
    showSuggestionPopover,
    setShowSuggestionPopover,
    currentTab,
    handleTabChange,
    draftMailingList,
    sentMailingList,
    selectedMailingId,
    setSelectedMailingId,
    showPreviewModal,
    setShowPreviewModal,
    selectedPreviewMailing,
    setSelectedPreviewMailing,
    sendConfirmationOpen,
    confirmedSend,
    cancelSend,
    deleteConfirmationOpen,
    confirmedDelete,
    cancelDelete,
    isLoading,
    fetchAllGeneralMailings,
    isSuperAdminOrClientAdmin,
    previewCounter,
    setPreviewCounter,
    sortModel,
    setSortModel,
    dataGridRows,
    prepItemsForDataGrid,
    apiRef,
    groupingColDef,
    activeHeaderFields,
    handleUpdateHeader,
    setMailingView,
    mailingView,
    selectedMailing,
    setSelectedMailing,
    generalMailingsSelectionModel,
    setGeneralMailingsSelectionModel,
    bulkActionOptions,
    handleBulkAction,
    bulkDeleteConfirmationOpen,
    handleBulkDelete,
    handleCancelBulkDelete,
  } = useGeneralMailings();

  const { createMail } = useMailingsComposerEffect({
    selectedMailingId: selectedMailingId,
    setSelectedMailingId: setSelectedMailingId,
    fetchAllGeneralMailings: fetchAllGeneralMailings,
    view: ViewType.Contacts,
    setView: setMailingView,
  });

  const createContactsGeneratedMailing = () => {
    setSelectedMailingId('');
    createMail();
  };

  return (
    <DataWrapperBox id="general_mailings_box" role="main">
      <ProgressModal id="modal_mailingslist_loading" showProgress={isLoading} />
      <HeaderRow id="general_mailings_title">
        <Typography variant="pageTitle">General Mailings</Typography>
        <TabsPanel
          id={'general_mailings_tabs_panel'}
          value={currentTab}
          handleChange={handleTabChange}
          options={[
            `Drafts (${draftMailingList.length})`,
            `Sent  (${sentMailingList.length})`,
          ]}
        />
        <SearchBar
          id="general_mailings_search"
          size="small"
          onChange={onSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          {isSuperAdminOrClientAdmin ? (
            <>
              {isSuperAdminOrClientAdmin &&
                currentTab === 0 &&
                generalMailingsSelectionModel.length > 0 && (
                  <SplitButton
                    id={'btn_bulk_action_options'}
                    text="Actions"
                    showSelectedOptionIcon={false}
                    stopClickForwarding={true}
                    options={bulkActionOptions}
                    hidden={false}
                    handleOptionClick={handleBulkAction}
                    ariaLabelMessage="Select bulk action option"
                  />
                )}
              <Button
                id="btn_add_new_capital_calls_button"
                variant="contained"
                icon={<AddIcon />}
                text="Start New General Mailing"
                color={'primary'}
                onClick={() => {
                  createContactsGeneratedMailing();
                }}
              />
            </>
          ) : (
            ``
          )}
        </ButtonBox>
      </HeaderRow>
      <Box id="datagrid_mailings" sx={{ height: '70vh' }}>
        {currentTab === 0 && (
          <StyledDataGridPro
            id="general_mailings_dataGrid"
            treeData
            getTreeDataPath={(row) => row.path}
            apiRef={apiRef}
            dataList={
              !isLoading && dataGridRows?.length > 0 ? dataGridRows : []
            }
            noDataMessage={
              currentTab === 0
                ? 'Click Start New to Begin.'
                : 'Sent mailings will appear here.'
            }
            headerList={headerList}
            activeHeaderFields={activeHeaderFields}
            handleUpdateHeader={handleUpdateHeader}
            groupingColDef={groupingColDef}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            initialState={{
              pinnedColumns: { right: ['action'] },
              sorting: {
                sortModel: [{ field: 'createdOn', sort: 'desc' }],
              },
            }}
            hideFooter={true}
            autoHeight={false}
            isRowSelectable={(row) => {
              return row.row.path.length === 1 ? true : false;
            }}
            {...(isSuperAdminOrClientAdmin
              ? {
                  selectionModel: generalMailingsSelectionModel,
                  onSelectionModelChange: (newModel) => {
                    setGeneralMailingsSelectionModel(newModel as string[]);
                  },
                  getRowClassName: (params) => {
                    return params.row.isChild ? 'is-child' : '';
                  },
                }
              : { checkboxSelection: false })}
          />
        )}
        {currentTab === 1 && (
          <StyledDataGridPro
            id="general_mailings_dataGrid"
            treeData
            getTreeDataPath={(row) => row.path}
            apiRef={apiRef}
            dataList={
              !isLoading && dataGridRows?.length > 0 ? dataGridRows : []
            }
            noDataMessage={'Sent mailings will appear here.'}
            headerList={headerList}
            activeHeaderFields={activeHeaderFields}
            handleUpdateHeader={handleUpdateHeader}
            groupingColDef={groupingColDef}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            initialState={{
              pinnedColumns: { right: ['action'] },
              sorting: {
                sortModel: [{ field: 'createdOn', sort: 'desc' }],
              },
            }}
            hideFooter={true}
            autoHeight={false}
            checkboxSelection={false}
          />
        )}
      </Box>
      <MailingsComposerPanel
        selectedMailingId={selectedMailingId}
        setSelectedMailingId={setSelectedMailingId}
        view={mailingView}
        setView={setMailingView}
        selectedMailing={selectedMailing}
        setSelectedMailing={setSelectedMailing}
        fetchAllGeneralMailings={fetchAllGeneralMailings}
      />
      <MailingsPreviewPanel
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        selectedPreviewMailing={selectedPreviewMailing}
        setSelectedPreviewMailing={setSelectedPreviewMailing}
        previewCounter={previewCounter}
        setPreviewCounter={setPreviewCounter}
      />
      <ConfirmationDialog
        open={sendConfirmationOpen}
        id="mailings_send_confirmation"
        actions={[
          {
            label: 'Confirm Send',
            onClick: confirmedSend,
            id: 'confirm_send_general_mailing',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: cancelSend,
            id: 'cancel_send_general_mailing',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={'Are you sure you want to send?'}
        title="Send Mailing"
      />
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        id="mailings_delete_confirmation"
        actions={[
          {
            label: 'Confirm Delete',
            onClick: confirmedDelete,
            id: 'confirm_delete_general_mailing',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: cancelDelete,
            id: 'cancel_delete_general_mailing',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={'Are you sure you want to delete?'}
        title="Delete Mailing"
      />
      <DeleteConfirmationDialog
        open={bulkDeleteConfirmationOpen}
        id="delete-confirmation-modal"
        content="This action is permanent and cannot be undone."
        primaryButtonAction={handleBulkDelete}
        secondaryButtonAction={handleCancelBulkDelete}
        actionType={`${Action.DELETE} ${generalMailingsSelectionModel.length}`}
      />
    </DataWrapperBox>
  );
};

export default React.memo(GeneralMailings);
