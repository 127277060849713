import { Box, styled } from "@mui/material";

export const CountryCodeSelectBox = styled(Box)`
  width: 170px;
  display: inline-flex;
`;

export const HorizontalPanelBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    padding-left: 15px;
  }
`;

export const StyledSplitButton = styled(Box)`
  padding-bottom: 15px !important;
`;

export const StyledHorizontalBox = styled(Box)`
  display: flex;
  align-items: end !important;
  padding-bottom: 0px !important;
  justify-content: space-between;
  padding-right: 0px;
`;

export const KeyValuePair = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const KeyLabel = styled(Box)`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const ValueLabel = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const EmptySplitButton = styled(`div`)`
  width: 230px,
`;