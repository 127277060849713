import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { memo } from "react";

import { Container, LoginContainer, StyledLoginLeftSkeleton, StyledSkeleton } from "../Skeleton.style";

type Props={
    loginBackground?: string | null;
    loginButtonBackground?: string | null;
    loginBodyBackground?: string | null;
}

const LoginSkeleton = ({
  loginBackground,
  loginButtonBackground,
  loginBodyBackground,
}:Props) =>{

  const theme = useTheme();

  return(
    <Container>
      <LoginContainer>
        <StyledLoginLeftSkeleton backgroundColor={loginBackground || theme.palette.grey[200]}>
          <Box/>
          <StyledSkeleton width="30%" backgroundColor={theme.palette.common.white}/>
          <StyledSkeleton backgroundColor={theme.palette.common.white}/>
          <StyledSkeleton width="30%" backgroundColor={theme.palette.common.white} />
          <StyledSkeleton backgroundColor={theme.palette.common.white} />
          <StyledSkeleton width="35%" backgroundColor={loginButtonBackground || theme.palette.secondary.dark}/>
          <Box/>
        </StyledLoginLeftSkeleton>
        <StyledSkeleton backgroundColor={loginBodyBackground || theme.palette.common.white} />
      </LoginContainer>
    </Container>
  );
};

export default memo(LoginSkeleton);
