import { Fade, PopoverReference } from "@mui/material";
import { ReactElement } from "react";

import {
  POPOVER_ORIENTATION,
  POPOVER_VERTICAL_ORIGIN,
} from "../../utils/constants/styles.constants";
import { StyledPopover } from "./ArrowPopover.styles";

type Props = {
  id: string;
  anchorEl?: HTMLButtonElement | HTMLDivElement | HTMLAnchorElement | null;
  showPopover: boolean;
  handleOnPopoverClose: any;
  content: any;
  verticalOrigin?: POPOVER_VERTICAL_ORIGIN;
  transformOrigin?: POPOVER_VERTICAL_ORIGIN;
  orientation?: POPOVER_ORIENTATION;
  size?: string;
  arrowLeft?: string;
  anchorReference?: PopoverReference;
  anchorPosition?: { left: number, top: number };
};

const ArrowPopover: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id,
    anchorEl,
    showPopover,
    handleOnPopoverClose,
    content,
    verticalOrigin = "bottom",
    transformOrigin = "top",
    orientation = "right",
    size = "sm",
    arrowLeft = "right",
    anchorPosition,
    anchorReference,
  } = props;

  return (
    <StyledPopover
      id={id}
      anchorOrigin={{
        vertical: verticalOrigin,
        horizontal: orientation,
      }}
      transformOrigin={{
        vertical: transformOrigin,
        horizontal: orientation,
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      anchorEl={anchorEl}
      open={showPopover}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 0
      }}
      onClose={handleOnPopoverClose}
      displaysfromtop={(
        verticalOrigin === "top" && transformOrigin === "top"
      ).toString()}
      size={size}
      inverted={(transformOrigin === "bottom").toString()}
      arrowleft={arrowLeft?.toString()}
      anchorPosition={anchorPosition}
      anchorReference={anchorReference}
    >
      {content}
    </StyledPopover>
  );
};

export default ArrowPopover;
