import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";
import React from 'react';

import ImgIcon from "../ImgIcon/ImgIcon";

interface Example {
  icon: string;
  info: string;
}

type Props = {
  title: string;
  info?: string;
  hasExample?: boolean;
  example?: Example;
};

const OptionLabel: React.FC<Props> = ({
  title,
  info,
  hasExample = false,
  example
}: Props): React.ReactElement => {
  return (
    <Grid container columnSpacing={4} mt={1.5} mb={4}>
      <Grid item xs={12} md={hasExample ? 6: 12}>
        <Stack spacing={2}>
          <Typography variant="label">{title}</Typography>
          {!!info && <Typography variant="subInfo">{info}</Typography>}
        </Stack>
      </Grid>
      { hasExample && <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Typography variant="subInfo">Example:</Typography>
            {!isEmpty(example) && (<Stack direction="row" spacing={2} alignItems="center">
              {example?.icon && <ImgIcon icon={example.icon} />}
              {example?.info && <Typography variant="subInfo">{example.info}</Typography>}
            </Stack>)}
          </Stack>
        </Grid>
      }
    </Grid>
  );
};

export default React.memo(OptionLabel);