import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../core/context/appContextProvider";
import { getClientConfigsList } from "../../services/client.service";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { ClientConfigs } from "../../utils/types/client.type";

type Props = {
  isAdmin?: boolean;
}

export const useClientConfig = ({
  isAdmin = false
}: Props) => {
  const [clientConfigList, setClientConfigList] = useState<ClientConfigs[]>([]);
  const { informationAlert } = useContext(AppContext);

  const fetchClientConfigList = useCallback(
    async (isCanceled?: () => boolean) => {
      try {
        const statusResponse = await getClientConfigsList();

        if (isCanceled?.()) return;

        setClientConfigList(statusResponse);
      } catch (e) {
        informationAlert("Error in getting client configs", "error");
      }
    },
    []
  );

  useEffectAsync(async (isCanceled) => {
    if(isAdmin) {
      await fetchClientConfigList(isCanceled);
    }
  }, [isAdmin]);

  return {
    clientConfigList,
    fetchClientConfigList,
  };
};

