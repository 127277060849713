import { List, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { ClientListItem, TeamMember } from "../../../../../utils/types/user.type";
import ClientCard from "../ClientCard";
import SelectedClientsListRow from "./SelectedClientsListRow";

interface Props {
    clientList?: ClientListItem[];
    onClientDeselect: (clientId: string) => void,
    onClientSelect: (client: ClientListItem) => void,
    teamMembers?: TeamMember[];
}

const SelectedClientsList: React.FC<Props> = ({
  clientList,
  onClientDeselect,
  onClientSelect,
  teamMembers
}: Props): ReactElement => {
  return (
    <ClientCard title="Clients">
      <List>
        {clientList && clientList?.length > 0 ? clientList?.map((client) => (
          <SelectedClientsListRow
            key={client.clientId}
            teamMembers={teamMembers}
            client={client}
            onClientDeselect={onClientDeselect}
            onClientSelect={onClientSelect}/>
        )) : (
          <Typography align="center">No Client Selected</Typography>
        )}
      </List>
    </ClientCard>
  );
};

export default SelectedClientsList;
