import { Box, FormControl, RadioGroup, styled, Typography } from "@mui/material";

export const SelectFormControl = styled(FormControl)(({
    theme
  }) => ({
    width: '100%',
    '.MuiInputLabel-formControl': {
      top: '-7px',
    },
    '.MuiInputLabel-shrink': {
      top: '0',
    }
  }));

  export const StyledTypography = styled(Typography)(({
    theme
  }) => ({
    marginLeft: "15px",
    marginTop: "5.5px",
    fontWeight: "normal",
    fontSize: "16px",
    variant: "h4"
  }));