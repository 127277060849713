export interface PendingItem {
    id?: string | null;
    name?: string | null;
    type?: string | null;
    createdDate?: string | null;
    fundName?: string | null;
}

export enum DashboardType {
    CONTACT = "CONTACT",
    DISTRIBUTION = "DISTRIBUTION",
    CAPITAL_CALL = "CAPITAL_CALL",
    INVESTOR = "INVESTOR",
    INVESTOR_WIRE_INFO = "INVESTOR_WIRE_INFO",
}
