import {
  Box,
  Button as StyledButton,
  Card,
  Divider as StyledDivider,
  Stack,
  styled,
  Typography,
} from "@mui/material";

export const DistributionSetupWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  maxHeight: "68vh",
  overflowY: "auto",
}));

export const StyledDistributionContainer = styled(Box)(
({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridGap: theme.spacing(2),
}));

export const StyledParentCard = styled(Card)<
  { isChecked: boolean }
>(
  ({ theme, isChecked }) => ({
    width: "100%",
    padding: theme.spacing(0.5, 1),
    borderColor: isChecked
      ? theme.palette.primary.main
      : theme.palette.grey[300],
    borderRadius: theme.spacing(0.8),
  })
);

export const DistributionSetUpContainer = styled(Box)(
({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}));

export const Divider = styled(StyledDivider)(
({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const DistributionHeaderLabel = styled(Typography)(
({ theme }) => ({
  marginTop: theme.spacing(2.2),
}));

export const FieldLabelDistribution = styled(Typography)(
({ theme }) => ({
  marginBottom: theme.spacing(0.2),
  marginTop: theme.spacing(1.2),
}));

export const Button = styled(StyledButton)(() => ({ width: "20%" }));

export const DistributionFilesContainer = styled(Stack)(
({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: theme.spacing(3),
}));

export const DistributionFieldContainer = styled(Stack)(() => ({
  width: "20%",
}));

export const DistributionFileLabel = styled(Typography)(
({ theme }) => ({
  marginBottom: theme.spacing(-1.5),
  marginTop: theme.spacing(1.2),
}));

export const MarginDistributionFixContainer = styled(Box)(
({ theme }) => ({
  margin: theme.spacing(0, -4),
}));
