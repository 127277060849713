import Add from "@mui/icons-material/Add";
import { FormHelperText, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import isEmpty from "lodash/isEmpty";
import { Controller } from "react-hook-form";

import IconPDFile from "../../../../assets/images/icons/icon_pdf_file.svg";
import IconUploadArrow from "../../../../assets/images/icons/icon_upload_arrow.svg";
import FileSelector from "../../../../components/FileSelector/FileSelector";
import NextStepDialog from "../../../../components/Modal/NextStepDialog/NextStepDialog";
import OptionLabel from "../../../../components/OptionLabel/OptionLabel";
import useDocumentsContext from "../../hooks/useDocumentsContext.hooks";
import { AddTags } from "../addTags/AddTags";
import { Errors } from "../errors/Errors";
import { StyledFormControlLabel, StyledRadioGroup } from "./UploadFiles.styles";
import useUploadFiles from "./useUploadFiles.hooks";

type Props = {
  showDialog: boolean;
  closeDialog: () => void;
  reload: Function;
  addNewFiles: Function;
};

const UploadFileOptions = [
  {
    label: "Upload Multiple Files",
    value: "upload_by_filename",
    info: "(When selected, upload using file name only. Manually tag files to funds, investors, and quarters, and update additional file settings during upload).",
    hasExample: true,
    example: {
      icon: IconPDFile,
      info: "ABC Partners I.PDF",
    },
  },
  {
    label: "Upload Multiple Files Using Names",
    value: "upload_by_custom_name",
    info: "(When selected, file names must be formatted as INVESTOR NAME - FUND NAME - QUARTER - FILE NAME, and documents will automatically be tagged).",
    hasExample: true,
    example: {
      icon: IconPDFile,
      info: "Michael A. Johnson - ABC Partners I, LP - 2019 Q1 - Michael A. Johnson Document.PDF",
    },
  },
  {
    label: "Upload Multiple Files Using UUID",
    value: "upload_by_uuid",
    info: "(When selected, file names must be formatted as INVESTOR ID - FUND ID - QUARTER - FILE NAME, and documents will automatically be tagged).",
    hasExample: true,
    example: {
      icon: IconPDFile,
      info: "b493b61b-f079-401e-a978-48edb99eaff4 - 9ef08177-c880-4a93-81a1-3c1529a9a4f4 - 2019 Q1 - Katherine Porter Document.PDF",
    },
  },
];

export default function UploadFiles({ ...props }: Props) {
  const { state } = useDocumentsContext();
  const {
    errors,
    uploadErrors,
    currentFiles,
    step,
    control,
    uploadProgress,
    loading,
    clearUploadErrors,
    handleSubmit,
    handleUpload,
    resetUploadFiles,
    setUploadErrors,
  } = useUploadFiles(state, {
    closeDialog: props.closeDialog,
    addNewFiles: props.addNewFiles,
  });

  const theme = useTheme();

  return (
    <>
      <NextStepDialog
        open={props.showDialog && step === "upload"}
        onClose={() => {
          resetUploadFiles();
          props.closeDialog();
        }}
        fullWidth={true}
        id="upload_new_files"
        actionDisplayType="inline"
        actions={[
          {
            label: "Upload Files",
            onClick: handleSubmit((data) => handleUpload(data, props.reload)),
            id: "upload_files_button",
            variant: "contained",
            color: "primary",
            disabled: loading,
          },
        ]}
        title="Upload Files"
      >
        <Box flex={1}>
          <Grid container rowSpacing={2} p={2}>
            <Grid item xs={12}>
              <Controller
                name="uploadType"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <StyledRadioGroup onChange={onChange} value={value}>
                        {UploadFileOptions.map((option) => (
                          <StyledFormControlLabel
                            value={option.value}
                            key={option.value}
                            control={<Radio />}
                            disabled={loading}
                            disableTypography={true}
                            label={
                              <OptionLabel
                                title={option.label}
                                info={option.info}
                                hasExample={option.hasExample}
                                example={option.example}
                              />
                            }
                          />
                        ))}
                      </StyledRadioGroup>
                      <FormHelperText error={!isEmpty(error)}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  );
                }}
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return "Please select an upload type.";
                    }
                    return true;
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="uploadedFiles"
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <>
                    <FileSelector
                      title="Drag and drop or browse to files or folders"
                      acceptType=".pdf, .xls, .xlsx, .zip, .doc, .docx, .rtf, .csv, .xlt, .rtc"
                      onUpload={(blob, file) => {
                        onChange(file);
                      }}
                      disabled={loading}
                      multiple={true}
                      inline={true}
                      onClear={onChange}
                      height={theme.spacing(21)}
                      uploadIcon={IconUploadArrow}
                      preserveFiles={true}
                      progress={uploadProgress}
                      uploadTitleBoxStyle={{
                        "& > img": {
                          width: 16,
                        },
                        "& .MuiTypography-root": {
                          color: theme.palette.info.light,
                          fontWeight: 600,
                        },
                      }}
                      btnText={
                        <>
                          <Add />
                          Add File(s)
                        </>
                      }
                    />
                    <FormHelperText error={!isEmpty(error)}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value || value.length === 0) {
                      return "Please select files to upload.";
                    }
                    return true;
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </NextStepDialog>
      <AddTags
        showDialog={props.showDialog && step === "tag"}
        files={currentFiles}
        resetUploadFiles={resetUploadFiles}
        reload={props.reload}
        setErrors={setUploadErrors}
      />
      <Errors
        open={uploadErrors?.length > 0}
        title="Incorrect Specified File Format"
        subTitle="Please review, rename, and reupload documents."
        label={`(${
          uploadErrors?.length ?? 0
        }) files do not match funds or investors for the cilent selected.`}
        errors={uploadErrors}
        handleClose={() => {
          clearUploadErrors();
          props.closeDialog();
        }}
      />
    </>
  );
}
