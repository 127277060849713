import { useCallback, useContext, useEffect, useState } from 'react';
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';

import { AppContext } from '../../../core/context/appContextProvider';
import { getPlaidLinkToken } from '../../../services/bank.service';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import {
  FETCH_LINK_TOKEN_ERROR,
  LoadingMessage,
} from './BankConnectionPanel.defaults';

interface Props {
  institutionId: string;
  importStartDate: Date;
  setIsLoading: (value: string) => void;
  setOpenPlaid: (value: boolean) => void;
  setPlaidAccountsRes: React.Dispatch<React.SetStateAction<any>>;
}

export const PlaidLinkWithOAuth: React.FC<Props> = ({
  institutionId,
  importStartDate,
  setIsLoading,
  setOpenPlaid,
  setPlaidAccountsRes,
}: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

  const { informationAlert } = useContext(AppContext);

  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem('link_token'));
      return;
    }
    const createLinkToken = async (isCanceled?: () => boolean) => {
      try {
        setIsLoading(LoadingMessage.CONNECTING);

        const dateStr = DateTimeFormat.getReversedDate(importStartDate, '-');
        const linkTokenRes = await getPlaidLinkToken(institutionId, dateStr); //  2024-06-12

        if (isCanceled?.()) return;

        setToken(linkTokenRes.linkToken);
        localStorage.setItem('link_token', linkTokenRes.linkToken);
      } catch (e) {
        setIsLoading('');
        informationAlert(FETCH_LINK_TOKEN_ERROR, 'error');
        setOpenPlaid(false);
      }
    };

    createLinkToken();
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    setPlaidAccountsRes(metadata);
    setOpenPlaid(false);
  }, []);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    if (eventName === 'OPEN') {
      setIsLoading('');
    }
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    setOpenPlaid(false);
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  if (isOAuthRedirect) {
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready, error, exit } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  return <></>;
};

export default PlaidLinkWithOAuth;
