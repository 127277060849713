import {
  DistributionDocument,
  DistributionId,
  DistributionReviews,
  DistributionReviewsSummary,
  Distributions,
  DistributionTeamMembers,
  PortalDistributions,
  StatusType,
  Transaction,
} from "../utils/types/distributions.type";
import { Quarter } from "../utils/types/filter.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";
const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const download = async (payload: any) =>
  await apiClient().post(`/downloads`, payload);

export const getQuarterList = async () =>
  await apiClient().get<Quarter[]>(`/quarters/filters?published=true`);

export const getPortalDistribution = async (quarterName: string) =>
  await apiClient().get<PortalDistributions[]>(
    `/distributions?quarterName=${quarterName}`
  );

export const getDistributions = async () =>
  await apiClient().get<Distributions[]>("admin/distributions");

export const updateDistributionsStatus = async (id: string, status: string) =>
  await apiClient().patch(`admin/distributions/${id}/status`, {
    status,
  });

export const getDistributionDetails = async (id: string) =>
  await apiClient().get<Distributions>(`admin/distributions/${id}`);

export const createDistributionSetUp = async (params: Distributions) =>
  await apiClient().post<DistributionId>(`admin/distributions`, params);

export const uploadDistributionDocument = async (
  DistributionId: String,
  file: FileList,
  type: string
) => {
  const formData = new FormData();

  formData.append("file", file[0]);
  await apiClient().post(
    `admin/distributions/${DistributionId}/documents?type=${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const getDistributionTeamRoleswithMembersForFund = async (
  fundId: string
) =>
  await apiClient().get<DistributionTeamMembers>(
    `teams/members/?fundId=${fundId}`
  );
export const updateDistribution = async (id: string, params: Distributions) => {
  await apiClient().put(`admin/distributions/${id}`, params);
};
export const getDistributionDocuments = async (id: string) =>
  await apiClient().get<DistributionDocument>(
    `admin/distributions/${id}/documents`
  );

export const getDistribution = async (id: string) =>
  await apiClient().get<Distributions>(`admin/distributions/${id}`);

export const getAllDistributionTransactions = async (id: string) =>
  await apiClient().get<Transaction[]>(
    `admin/distributions/${id}/transactions`
  );

export const addOrUpdateDistributionTransaction = async (
  id: string,
  params: Transaction[]
) => await apiClient().put(`admin/distributions/${id}/transactions`, params);

export const getInvestorsDetails = async (fundId: string) =>
  await apiClient().get(`investors/names?fundId=${fundId}`);

export const getDistributionReviewSummary = async (id: string) =>
  await apiClient().get<DistributionReviewsSummary>(
    `admin/distributions/${id}/reviews/summary`
  );

export const DistributionupdateStatus = async (id: string, status: string) =>
  await apiClient().patch(`admin/distributions/${id}/status`, {
    status,
  });

export const getDistributionReviews = async (id: string) =>
  await apiClient().get<DistributionReviews[]>(
    `admin/distributions/${id}/reviews`
  );

export const updateDistributionStatus = async (
  distributionId: string,
  approvalId: string,
  params: StatusType
) => {
  await apiClient().patch(
    `admin/distributions/${distributionId}/approvals/${approvalId}`,
    params
  );
};
