import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ListItem, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import IconWarning from "../../../../assets/images/icons/icon_warning.svg";
import IconWarningHightlight from "../../../../assets/images/icons/icon_warning_highlight.svg";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import { Error } from '../../../../utils/types/documents.type';
import { StyledButton } from './Error.styles';

type Props = {
  title: ReactElement | string;
  subTitle?: string;
  isTextContent?: boolean;
  open: boolean;
  label?: string;
  errors?: Array<Error>;
  handleClose: Function;
}

type DocIconProps = {
  type?: string;
}

function DocumentIcon({ type = "" }: DocIconProps ): React.ReactElement {

  switch (type) {
    case "pdf":
      return <PictureAsPdfIcon color='disabled' />;
    case "docx":
    case "doc":
    case "xlsx":
    default:
      return <InsertDriveFileIcon color='disabled' />;
  }
}

export function Errors ({
  title = "",
  subTitle = "",
  isTextContent = false,
  open = false,
  label = "",
  errors = [],
  handleClose = () => {},
}: Props) {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchor => anchor ? null: event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    handleClose();
  };

  return (
    <ConfirmationDialog
      id="error_dialog"
      open={open}
      title={title}
      onClose={onClose}
      actions={[
        {
          label: 'Close',
          onClick: onClose,
          id: 'close_upload_error_button',
          variant: 'outlined',
          color: 'error'
        }
      ]}
      isTextContent={isTextContent}
      content={
        <>
          <Stack sx={{ textAlign: "center" }}>
            { subTitle && <Typography>{subTitle}</Typography> }
            { !!errors && errors.length > 0 && <>
                <StyledButton
                  id="error_menu_btn"
                  aria-controls={openMenu ? 'error_menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    bgcolor: theme => openMenu ? theme.palette.error.main: theme.palette.common.white
                  }}
                  
                >
                  <Stack gap={1} flexDirection="row" alignItems="center">
                    <ImgIcon icon={openMenu ? IconWarningHightlight: IconWarning} />
                    <Typography variant="h6" sx={{ color: theme => openMenu ? theme.palette.common.white : theme.palette.text.primary }}>{label}</Typography>
                    {openMenu ? <ExpandLess sx={{ color: theme => theme.palette.common.white }} />: <ExpandMore color='disabled' />}
                  </Stack>
                </StyledButton>
                <Menu
                  id="error_menu"
                  aria-labelledby="error_menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    "& .MuiMenu-list": {
                      maxHeight: 350,
                    }
                  }}
                >
                  
                  {errors.map((error) => {
                    return (
                      <ListItem
                        id={"error_item_" + error.id}
                        key={error.id}
                        value={error.label}
                        sx={{ width: anchorEl?.clientWidth, padding: theme =>  theme.spacing(1) }}
                      >
                        <Stack gap={1} flexDirection="row" alignItems="flex-start">
                          <DocumentIcon type={error.iconType} />
                          <Stack>
                            <Typography variant="h6" sx={{ color: theme => theme.palette.grey[500], whiteSpace: 'normal' }}>{error.label}</Typography>
                            <Typography variant="errorText">{error.errorMsg}</Typography>
                          </Stack>
                        </Stack>
                      </ListItem>
                    );
                  })}
                </Menu>
              </>
            }
          </Stack>
        </>
      }

    />
  );
}