import {
  GridAlignment,
  GridColumnOrderChangeParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useMemo, useState } from "react";

import ChipCellStack from "../../../components/DataGrid/ChipsCell/ChipCellStack";
import { StringCell } from "../../../components/DataGrid/DataGrid.styles";
import { StyledPreviewImg } from "../../../components/FileSelector/FileSelector.style";
import { ActionLink } from "../../../components/Link/ActionLink/ActionLink";
import StatusLabel from "../../../components/StatusLabel/StatusLabel";
import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import {
  getColumnOrder,
  saveColumnOrder,
} from "../../../services/columnOrder.service";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import {
  downloadPortfolioCompanies,
  getCustomProperties,
  getPortfolioCompanies,
} from "../../../services/portfolioCompanies.service";
import { uploadFile } from "../../../services/uploads.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from "../../../utils/helpers/columnOrder.helper";
import downloadFile from "../../../utils/helpers/fileDownloader";
import {
  CurrencyFormat,
  DateTimeFormat,
} from "../../../utils/helpers/format.helper";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ColumnOrder } from "../../../utils/types/columnOrder";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import {
  CustomPropertyDetailsViewType,
  SelectedCustomProperty,
} from "../../../utils/types/customProperty.type";
import { DetailsType } from "../../../utils/types/form.type";
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from "../../../utils/types/listItems";
import StatusTypes, {
  PortfolioCompany,
  SelectedPortfolioCompany,
} from "../../../utils/types/portfolioCompany.type";
import { ScopeRole } from "../../../utils/types/user.type";
import { GridCellExpand } from "./GridCellExpand";
import {
  GET_VIEW_LIST_ERROR,
  PORTFOLIO_COMPANIES_VIEW_KEY,
} from "./PortfolioCompanyList.constants";
import defaultHeaderList from "./PortfolioCompanyList.headerList";
import { CompanyType } from "./PortfolioCompanyList.styles";

export enum CompanyFilter {
  Name = "name",
  Industry = "industry",
  HoldingStatus = "holdingStatus",
  Country = "country",
  FundName = "fundName",
}

type Mapping = Record<StatusTypes, string>;

const StatusType: Mapping = {
  active: "green",
  inactive: "red",
};

const initialPortfolioCompany: SelectedPortfolioCompany = {
  portfolioCompany: undefined,
  type: undefined,
};
const initialCustomProperty: SelectedCustomProperty = {
  customProperty: undefined,
  viewType: undefined,
};

export const usePortfolioCompanies = () => {
  const { informationAlert, state } = useContext(AppContext);

  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [clientCustomProperties, setClientCustomProperties] = useState<any>();
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrder | null>(null);
  const [search, setSearch] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [companyFilteredList, setCompanyFilteredList] = useState<any[]>([]);
  const [companySelectionModel, setCompanySelectionModel] = useState<string[]>(
    []
  );
  const [countryList, setCountryList] = useState<any[]>([]);
  const [companiesResponse, setCompaniesResponse] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [industryList, setIndustryList] = useState<any[]>([]);
  const [fundList, setFundList] = useState<any[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [selectedPortfolioCompany, setSelectedPortfolioCompany] =
    useState<SelectedPortfolioCompany>(initialPortfolioCompany);
  const [selectedCustomProperty, setSelectedCustomProperty] =
    useState<SelectedCustomProperty>(initialCustomProperty);
  const [isCustomFieldPanelOpen, setIsCustomFieldPanelOpen] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lockedPrompt, setLockedPrompt] = useState<boolean>(false);

  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== "new" ? clientId : undefined);
  const selectedCompanyList = useMemo(
    () => companyList?.map((item) => item.id),
    [companyList]
  );
  const selectedCountryList = useMemo(
    () => countryList?.map((item) => item.id),
    [countryList]
  );
  const selectedIndustryList = useMemo(
    () => industryList?.map((item) => item.id),
    [industryList]
  );
  const selectedStatusList = useMemo(
    () => statusList?.map((item) => item.id),
    [statusList]
  );
  const selectedFundList = useMemo(
    () => fundList?.map((item) => item.id),
    [fundList]
  );
  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;

  const fetchAllPortfolioCompanies = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      if (isCanceled?.()) return;
      const companiesResponse = await getPortfolioCompanies();

      setCompaniesResponse(companiesResponse.items.sort(
        (a: any, b: any) => a.name.localeCompare(b.name)
      ));

      const clientCustomPropertiesResponse: any = await getCustomProperties();

      setClientCustomProperties(clientCustomPropertiesResponse.items);

      setIsLoading(false);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const columnOrderResponse = await getColumnOrder(
        PORTFOLIO_COMPANIES_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setColumnOrder(columnOrderResponse || null);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      informationAlert(GET_VIEW_LIST_ERROR, "error");
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAllPortfolioCompanies(isCanceled);
    await fetchColumnOrder(isCanceled);
  }, []);

  useEffect(() => {
    initializeHeaderList();
  }, [columnOrder]);

  useEffect(() => {
    if (companiesResponse && companiesResponse.length > 0) {
      const processedCompanyList = processCustomFieldRows(companiesResponse);

      setCompanyList(processedCompanyList);
      setFilters(processedCompanyList);
    }
  }, [companiesResponse]);

  useEffect(() => {
    if (
      industryList &&
      statusList &&
      countryList &&
      fundList &&
      clientCustomProperties
    ) {
      initializeHeaderList();
    }
  }, [clientCustomProperties, industryList, statusList, countryList, fundList]);

  useEffect(() => {
    initializeSearchOptions(companyList);
  }, [companyList]);

  const setFilters = (list: any) => {
    if (list && list.length > 0) {
      setIndustryList(uniqueArray(list, "industrySector"));
      setStatusList(uniqueArray(list, "holdingStatus"));
      setCountryList(
        uniqueArray(
          list.map((company: any) => company.address),
          "country"
        )
      );
      setFundList(uniqueFunds(list));
    }
  };

  useEffect(() => {
    updateCompanyList(search);
  }, [search]);

  useEffect(() => {
    let filteredList: any[] | undefined = companyList;

    headerList?.map((header) => {
      const auxList = filteredList ?? companyList;

      switch (header.inlineFilterName) {
        case CompanyFilter.Name:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((company) => {
              return header?.inlineFilterSelected?.some((selectedCompany) => {
                return selectedCompany === company.id;
              });
            });
          }
          break;
        case CompanyFilter.Industry:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((company) => {
              return header?.inlineFilterSelected?.some((selectedCompany) => {
                return selectedCompany === company.industrySector;
              });
            });
          }
          break;
        case CompanyFilter.HoldingStatus:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((company) => {
              return header?.inlineFilterSelected?.some((selectedCompany) => {
                return selectedCompany === company.holdingStatus;
              });
            });
          }
          break;
        case CompanyFilter.Country:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((company) => {
              return header?.inlineFilterSelected?.some((selectedCompany) => {
                if (company.address && company.address.country) {
                  return selectedCompany === company.address.country;
                }
              });
            });
          }
          break;
        case CompanyFilter.FundName:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((company) => {
              return header?.inlineFilterSelected?.some((fund) =>
                JSON.stringify(company.funds).includes(fund)
              );
            });
          }
          break;
      }
    });

    setCompanyFilteredList(filteredList);
  }, [headerList, companyList]);

  const processCustomFieldColumns = (): DataGridColDef[] => {
    const customHeaderList: DataGridColDef[] = [];
    let headerListIndex: number = defaultHeaderList.length - 1;

    if (clientCustomProperties) {
      clientCustomProperties.map((customProperty: any) => {
        let render: any = null;

        switch (customProperty.type) {
          case "DATE_PICKER": {
            render = (params: any) => {
              const { value } = params;

              if (value) {
                return (
                  <StringCell>
                    {DateTimeFormat.shortDate(new Date(value))}
                  </StringCell>
                );
              }
            };
            break;
          }
          case "MULTIPLE_CHECKBOX": {
            render = (params: any) => {
              const { value, row } = params;
              const items = value;

              const header = {
                field: customProperty.id,
                headerName: customProperty.name,
                hide: false,
                index: ++headerListIndex,
                type: "chips",
                sortable: false,
                align: "left" as GridAlignment,
                width: 200,
                chipLabelField: "name",
                chipIDField: "id",
              };

              if (items && items.length > 0) {
                return (
                  <ChipCellStack header={header} items={items} row={row} />
                );
              }
            };
            break;
          }
          case "NUMBERS": {
            if (customProperty.formattingType === "CURRENCY") {
              render = (params: any) => {
                const { value, row } = params;
                const { funds } = row;
                let currency = "";

                if (value) {
                  if (funds.length === 1) {
                    currency = funds[0].currency;
                  } else if (funds.length > 1) {
                    currency = funds[0].currency;
                    funds.map((fund: any) => {
                      if (fund.currency !== funds[0].currency) {
                        currency = "";
                      }
                    });
                  }

                  const formatter = currency ? CurrencyFormat(currency) : null;

                  return (
                    <StringCell>
                      {formatter ? formatter.format(value) : value}
                    </StringCell>
                  );
                }
              };
            } else {
              render = (params: any) => {
                const { value } = params;

                if (value) {
                  return <StringCell>{value || ""}</StringCell>;
                }
              };
            }
            break;
          }
          case "MULTIPLE_LINE_TEXT":
          case "SINGLE_LINE_TEXT": {
            render = (params: GridRenderCellParams<string>) => {
              const { value, colDef } = params;

              return (
                <GridCellExpand
                  value={value || ""}
                  width={colDef.computedWidth}
                />
              );
            };
            break;
          }
          default: {
            render = (params: any) => {
              const { value } = params;

              if (value) {
                return <StringCell>{value || ""}</StringCell>;
              }
            };
            break;
          }
        }

        customHeaderList.push({
          field: customProperty.id,
          headerName: customProperty.name,
          hide: false,
          index: ++headerListIndex,
          renderCell: render,
          type: "string",
          sortable: false,
          align: "left" as GridAlignment,
          width: 200,
        });
      });
    }
    return customHeaderList;
  };

  const processCustomFieldRows = (dataList: any) => {
    const customList = dataList.map((company: any) => {
      if (company.additionalProperties.length > 0) {
        company.additionalProperties.map((property: any) => {
          switch (property.customProperty.type) {
            case "DATE_PICKER": {
              company[property.customProperty.id] = property.values[0];
              break;
            }
            case "MULTIPLE_CHECKBOX": {
              const matchedValues: any = [];

              property.customProperty.values.map(
                (value: any, index: number) => {
                  if (property.values[index] === "true") {
                    matchedValues.push({
                      id: `${property.customProperty.id}-${index}`,
                      name: value,
                    });
                  }
                }
              );

              company[property.customProperty.id] = matchedValues;
              break;
            }
            case "SINGLE_CHECKBOX": {
              let matchedValue = "";

              if (property.values[0] === "true") {
                matchedValue = property.customProperty.values[0];
              }
              company[property.customProperty.id] = matchedValue;
              break;
            }
            default:
              company[property.customProperty.id] = property.values.join();
          }
        });
      }
      return company;
    });

    return customList;
  };

  const initializeSearchOptions = (response: any[]) => {
    const options = Array.from(
      new Set(response.map((company) => company.name))
    );

    setSearchOptions(options);
  };

  const uniqueArray = (list: any[], key: string) => {
    const uniqueValues = Array.from(
      list
        .reduce((list, item) => {
          if (item && item[key]) {
            list.set(item[key], {
              id: item[key],
              label: item[key],
            });
          }
          return list;
        }, new Map())
        .values()
    );

    return uniqueValues;
  };

  const uniqueFunds = (companyList: any[]) => {
    const uniqueFundNames = Array.from(
      companyList
        .reduce((fundList, company) => {
          if (company.funds) {
            company.funds.map((fund: any) => {
              fundList.set(fund.name, {
                id: fund.name,
                name: fund.name,
              });
            });
          }
          return fundList;
        }, new Map())
        .values()
    );

    return uniqueFundNames;
  };

  const updateCompanyList = (searchText: string) => {
    if (companiesResponse) {
      if (searchText === "") {
        setCompanyList(companiesResponse);
      } else if (typeof searchText === "string") {
        const companies = [...companiesResponse];

        const updatedCompanyList = companies.filter((company: any) =>
          company.name.match(new RegExp(searchText, "i"))
        );

        setCompanyList(updatedCompanyList);
      }
    }
  };

  const initializeHeaderList = () => {
    const customFieldHeaderList = processCustomFieldColumns();

    const updatedHeaders = [
      {
        field: "name",
        headerName: "Company Name",
        hide: false,
        index: 1,
        sortable: true,
        type: "string",
        renderCell: (params: any) => {
          return (
            <ActionLink
              id={`link_portfolio_company_${params.row.id}`}
              onClick={() => handleOnView(params.row.id, params.row)}
            >
              {params.value}
            </ActionLink>
          );
        },
        inlineFilter: true,
        inlineFilterName: CompanyFilter.Name,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: companyList,
        inlineFilterSelected: selectedCompanyList,
        emptySelectionOnClear: false,
      },
      {
        field: "industrySector",
        headerName: "Industry/Sector",
        hide: false,
        index: 2,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
        inlineFilter: true,
        inlineFilterName: CompanyFilter.Industry,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: industryList,
        inlineFilterSelected: selectedIndustryList,
        emptySelectionOnClear: false,
      },
      {
        field: "holdingStatus",
        headerName: "Holding Status",
        hide: false,
        index: 3,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        renderCell: (params: any) => {
          const status = params.row.holdingStatus.toLowerCase();
          const color = StatusType[status as keyof typeof StatusType];

          return <StatusLabel color={color} label={status} />;
        },
        width: 200,
        inlineFilter: true,
        inlineFilterName: CompanyFilter.HoldingStatus,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: statusList,
        inlineFilterSelected: selectedStatusList,
        emptySelectionOnClear: false,
      },
      {
        field: "address.country",
        headerName: "Country",
        hide: false,
        index: 4,
        sortable: false,
        type: "string",
        inlineFilter: true,
        inlineFilterName: CompanyFilter.Country,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "label",
        inlineFilterOptions: countryList,
        inlineFilterSelected: selectedCountryList,
        emptySelectionOnClear: false,
        align: "left" as GridAlignment,
        width: 180,
      },
      {
        field: "funds",
        headerName: "Funds",
        hide: false,
        index: 5,
        sortable: false,
        type: "chips",
        inlineFilter: true,
        inlineFilterName: CompanyFilter.FundName,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: fundList,
        inlineFilterSelected: selectedFundList,
        emptySelectionOnClear: false,
        chipLabelField: "name",
        chipIDField: "id",
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "logo",
        headerName: "Logo",
        hide: false,
        index: 6,
        sortable: false,
        align: "left" as GridAlignment,
        width: 180,
        renderCell: (params: any) => {
          return params.row.logo ? (
            <StyledPreviewImg
              src={params.row.logo}
              alt="Preview"
              previewHeight={"80px"}
              previewWidth={"50px"}
            />
          ) : (
            ""
          );
        },
      },
      {
        field: "address.street1",
        headerName: "Street 1",
        hide: false,
        index: 7,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "address.street2",
        headerName: "Street 2",
        hide: false,
        index: 8,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "address.postalCode",
        headerName: "Zip code",
        hide: false,
        index: 9,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "address.city",
        headerName: "City",
        hide: false,
        index: 10,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "address.state",
        headerName: "State",
        hide: false,
        index: 11,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "yearFounded",
        headerName: "Year Founded",
        hide: false,
        index: 12,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "about",
        headerName: "About",
        hide: false,
        index: 13,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "ceo",
        headerName: "CEO",
        hide: false,
        index: 14,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "numOfEmployees",
        headerName: "Number of employees",
        hide: false,
        index: 15,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 210,
      },
      {
        field: "isPublic",
        headerName: "Public",
        hide: false,
        index: 16,
        type: "string",
        renderCell: (params: any) => {
          const type = params.row.isPublic ? "Public" : "Private";

          return <CompanyType>{type}</CompanyType>;
        },
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "tickerSymbol",
        headerName: "Ticker Symbol",
        hide: false,
        index: 17,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "website",
        headerName: "Website",
        hide: false,
        index: 18,
        type: "string",
        sortable: true,
        align: "left" as GridAlignment,
        width: 200,
      },
      {
        field: "investmentTypes",
        headerName: "Securities",
        hide: false,
        index: 19,
        type: "chips",
        chipLabelField: "label",
        chipIDField: "id",
        sortable: false,
        align: "left" as GridAlignment,
        width: 250,
      },
      ...customFieldHeaderList,
      {
        field: "action",
        headerName: "Columns",
        hide: false,
        hideable: false,
        index: defaultHeaderList.length + customFieldHeaderList.length + 1,
        type: "action",
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 100,
      },
    ];

    let sortedHeaders: any;

    if (columnOrder && columnOrder.viewItems) {
      columnOrder.viewItems.map((item) => {
        const header = updatedHeaders.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );

      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleFilter = (filterName: CompanyFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }

        return header;
      })
    );
  };

  const handleOnView = (
    portfolioCompanyId: string,
    portfolioCompany: PortfolioCompany
  ) => {
    setSelectedPortfolioCompany({
      portfolioCompany,
      type: DetailsType.Edit,
    });
  };

  const onPortfolioCompanyPanelClose = () => {
    setSelectedPortfolioCompany(initialPortfolioCompany);
  };

  const onCustomFieldPanelClose = () => {
    setSelectedCustomProperty(initialCustomProperty);
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === "string") {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch("");
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedPortfolioCompany({
            portfolioCompany: undefined,
            type: DetailsType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        {
          setIsLoading(true);
          const portCoUrl: string = process.env
            .REACT_APP_CDN_ENDPOINT_URL as string;

          location.href = portCoUrl.concat(
            "templates/Portfolio_Company_Template.csv"
          );
          setIsLoading(false);
        }
        break;
      case AddNewButtonOptions.AddNewCustomField:
        {
          setSelectedCustomProperty({
            customProperty: undefined,
            viewType: CustomPropertyDetailsViewType.New,
          });

          setIsCustomFieldPanelOpen(true);
        }
        break;
    }
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      PORTFOLIO_COMPANIES_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: CompanyFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === CompanyFilter.Name) {
          inlineFilterSelected = selectedCompanyList;
        } else if (inlineFilterName === CompanyFilter.FundName) {
          inlineFilterSelected = selectedFundList;
        } else if (inlineFilterName === CompanyFilter.Industry) {
          inlineFilterSelected = selectedIndustryList;
        } else if (inlineFilterName === CompanyFilter.HoldingStatus) {
          inlineFilterSelected = selectedStatusList;
        } else if (inlineFilterName === CompanyFilter.Country) {
          inlineFilterSelected = selectedCountryList;
        }

        return {
          ...header,
          hide:
            header.field === field &&
              !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      PORTFOLIO_COMPANIES_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile("portfolio-company", uploadedFile);
      setUploadedFile(undefined);
      setIsLoading(false);
      setIsUploadComplete(true);
      await fetchAllPortfolioCompanies();
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const showLockedDialog = () => {
    setLockedPrompt(true);
  };

  const handleBulkOptionClick = async (selectedOption: string) => {
    if (selectedOption === "export") {
      const portfolioCompanyIds: string[] =
        headerList.find((item) => item.field === "Company Name")
          ?.inlineFilterSelected || [];
      const file = await downloadPortfolioCompanies(
        companySelectionModel,
        portfolioCompanyIds
      );

      downloadFile(file, "Portfolio_Companies", "csv");
    }
  };

  return {
    fetchAllPortfolioCompanies,
    activeHeaderFields,
    companyFilteredList,
    companySelectionModel,
    headerList,
    readonly,
    clearUploadedFile,
    clearUploadCompleted,
    isCustomFieldPanelOpen,
    isUploadComplete,
    handleFilter,
    handleNewButtonAction,
    handleOnView,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleUploadTemplate,
    onPortfolioCompanyPanelClose,
    onCustomFieldPanelClose,
    isLoading,
    search,
    searchOptions,
    selectedPortfolioCompany,
    selectedCustomProperty,
    setSelectedCustomProperty,
    setCompanySelectionModel,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    client,
    lockedPrompt,
    showLockedDialog,
    setLockedPrompt,
    uploadedFile,
  };
};

export const useBulkActionOptionSelectionEffect = (
  bulkActionList: ImageItem[],
  companySelectionModel: any
) => {
  const { hasRole: superAdminORClientAdminUser } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const { hasRole: basicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: fundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: clientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const [bulkActionOptions, setBulkActionOptions] = useState<ImageItem[]>([]);

  useEffect(() => {
    let optionList: ImageItem[] = [];

    if (superAdminORClientAdminUser) {
      optionList = bulkActionList;
    }
    if (basicAdmin) {
      bulkActionList = bulkActionList.filter((item) => item.id !== "delete");
    }
    if (clientPortalUser || fundAdmin) {
      bulkActionList = bulkActionList.filter((item) => item.id === "export");
    }

    const updatedOptions: ImageItem[] = bulkActionList.map((option) => {
      option.text = option.text + "(" + companySelectionModel.length + ")";
      return option;
    });

    setBulkActionOptions(updatedOptions);
  }, [companySelectionModel]);

  return {
    bulkActionOptions,
  };
};
