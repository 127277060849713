import ArrowBack from "@mui/icons-material/ArrowBack";
import { Button, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { PageNavigation } from "../../../components/PageNavigation/PageNavigation";
import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import useRole from "../../../core/routing/useRole";
import { getFund } from "../../../services/fund.service";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import useWindowSize from "../../../utils/hooks/useWindowSize.hook";
import { ScopeRole } from "../../../utils/types/user.type";
import { AccountList } from "../../arkGL/accounts/accountList/AccountList";
import { EntityList } from "../../arkGL/entities/entityList/EntityList";
import { JournalEntryList } from "../../arkGL/journalEntries/journalEntryList/JournalEntryList";
import { GlReportList } from "../../arkGL/reports/NewGlReportList";
import { GLSetupList } from "../../arkGL/setup/setupList/GLSetupList";
import { AllocationFiles } from "../allocations/allocationWorkbookFiles/AllocationFiles";
import { Workbook } from "../allocations/workbook/Workbook";
import { FundDetails } from "../fundDetails/FundDetails";
import { ReadOnlyFundDetails } from "../fundDetails/ReadOnlyFundDetails";
import { FundWrapper } from "../fundList/FundList.styles";
import { InvestmentTransactionList } from "../InvestmentTransactions/InvestmentTransactionList";
import { PortfolioCompanyList } from "../portfolioCompanies/PortfolioCompanyList";
import { ScheduleOfInvestments } from "../scheduleOfInvestments/ScheduleOfInvestments";
import TransactionMapper from "../transactionMapper/TransactionMapper";
import { usepageMenuOptions } from "./pageMenuOptions.hooks";

export const FundNavigation: React.FC = () => {
  const location = useLocation();
  const [fundName, setFundName] = useState<string>();
  const history = useHistory<any>();
  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const params = useParams();
  const { menu } = usepageMenuOptions();
  const isAllocationFile = location.pathname.startsWith(
    "/fund-nav/fund-allocations-list-file"
  );
  let fundId: string;

  //Show COA when auto-routed from createNewLedger in GlSetupDetails.hooks
  if(history.location?.state?.data === "show_coa_fund_menu"){
   menu.forEach((item: any) => {
    if(item.id === "general_ledger"){
      item.menuOptions.forEach((option: any) => {
        if(option.name === "Chart of Accounts (arkGL)"){
          option.hidden = false;
        }
      });
    }
   });
  }

  if (isAllocationFile) {
    // @ts-ignore: expression error
    fundId = params[0] as string;
    fundId = fundId.split("/")[1];
  } else {
    // @ts-ignore: expression error
    fundId = params["fundId"] as string;
  }
  const { state, informationAlert } = useContext(AppContext);

  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== "new" ? clientId : undefined);
  const isNewFundDetail =
    fundId === "new" && location.pathname.startsWith("/fund-nav/fund-details");
  const showPageNav = !(isNewFundDetail || isAllocationFile);

  const backArrowLocation = isAllocationFile
    ? `/fund-nav/fund-allocations-list/${fundId}`
    : "/funds";
  const backArrowName = isAllocationFile ? "Allocation Files" : fundName;

  useEffectAsync(async (isCanceled) => {
    if (fundId === "new") {
      setFundName("New Fund");
    } else {
      const fund = await getFund(fundId);

      if (isCanceled()) return;

      setFundName(fund.fundDetail.name);
    }
  }, []);

  const window = useWindowSize();
  const wrapperHeight = window.height - 80;

  return (
    <FundWrapper wrapperHeight={wrapperHeight}>
      <Grid container mb={2.5}>
        <Grid item sm={3}>
          <Button
            onClick={() => {
              history.push(backArrowLocation);
            }}
            startIcon={<ArrowBack />}
          >
            <Typography>{backArrowName}</Typography>
          </Button>
        </Grid>
        <Grid item sm={9}>
          {showPageNav && <PageNavigation fundId={fundId} menu={menu} />}
        </Grid>
      </Grid>

      <Switch>
        <Route
          exact
          path={`${RoutingPaths.FundDetails}/:fundId`}
          component={isFundAdmin ? ReadOnlyFundDetails : FundDetails}
        />
        <Route
          exact
          path={`${RoutingPaths.TransactionMapper}/:fundId`}
          component={TransactionMapper}
        />
        <Route
          exact
          path={`${RoutingPaths.FundAllocationsList}/:fundId`}
          component={AllocationFiles}
        />
        <Route
          exact
          path={`${RoutingPaths.FundAllocationsFile}/:fundId/:workbookId`}
          component={Workbook}
        />
        <Route
          exact
          path={`${RoutingPaths.FundPortfolioCompanies}/:fundId`}
          component={PortfolioCompanyList}
        />
        <Route
          exact
          path={`${RoutingPaths.ScheduleOfInvestmentsFund}/:fundId`}
          component={ScheduleOfInvestments}
        />
        <Route
          exact
          path={`${RoutingPaths.InvestmentTransactionsFund}/:fundId`}
          component={InvestmentTransactionList}
        />
        <Route
          exact
          path={`${RoutingPaths.FundGLSetup}/:fundId`}
          component={GLSetupList}
        />
        <Route
          exact
          path={`${RoutingPaths.FundGLAccounts}/:fundId`}
          component={AccountList}
        />
        <Route
          exact
          path={`${RoutingPaths.FundGLEntityLists}/:fundId`}
          component={EntityList}
        />
        <Route
          exact
          path={`${RoutingPaths.FundGLJournalEntries}/:fundId`}
          component={JournalEntryList}
        />
        <Route
          exact
          path={`${RoutingPaths.FundGLReports}/:fundId`}
          component={GlReportList}
        />
        <Route>No Fund Nav Route! A route need to be wired up.</Route>
      </Switch>
    </FundWrapper>
  );
};
