export enum CapitalCallDetailsTabs {
  CapitalCallSetUp = "CAPITAL CALL SETUP",
  AddTransactions = "ADD TRANSACTIONS",
  ReviewDocuments = "REVIEW DOCUMENTS",
  Publish = "PUBLISH",
}

export enum ReviewersList {
  Associate = "ASSOCIATE",
  FundController = "FUND_CONTROLLER",
  Manager = "MANAGER",
  Director = "DIRECTOR",
  FundUserReadOnly = "FUND_USER_READ_ONLY",
}
export const InitialErrorMessages = {
  ASSOCIATE: "",
  FUND_CONTROLLER: "",
  FUND_USER_READ_ONLY: "",
  MANAGER: "",
  DIRECTOR: "",
};

export const CREATE_CAPITAL_CALL_ERROR = "Error creating capital call.";
export const CREATE_CAPITAL_CALL_SUCCESS = "Capital call created successfully.";
export const CREATE_CAPITAL_CALL_UPDATE = "Capital call updated successfully";
export const TEAM_MEMBERS_ROLE_ERROR = "Error accessing team members";
export const DOCUMENT_UPLOAD_ERROR = "Error uploading the document";
export const DOCUMENT_GET_ERROR = "Error retrieving the document";
export const REVIEW_SUMMARY_GET_ERROR = "Error retrieving review summary";
export const REVIEW_DOCUMENT_SUCCESS = "Documents Submitted for Review";
export const REVIEW_DOCUMENT_ERROR = "Error fetching data for documents";
export const REVIEW_STATUS_UPDATE_ERROR = "Error updating flag";
export const UPDATE_STATUS_ERROR = "Error updating the status of capital call";
export const UPDATE_STATUS_DOCUMENT_ERROR =
  "Error updating the status of document";
export const REVIEW_VALIDATION_ERROR =
  "There should be at least one user in reviewer you select";
