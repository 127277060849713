import { Box, styled } from "@mui/material";

export const DistributionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100%",
}));

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const HeaderTitleContainer = styled(Box)(({
  theme
}) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "repeat(2, auto) 1fr",
  gridGap: theme.spacing(4),
}));  

export const HeaderBreadcrumbContainer = styled(Box)(({
  theme
}) => ({
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "repeat(3, auto)",
  gridGap: theme.spacing(2),
}));
  
