import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { ReactElement } from "react";

import { StyledHorizontalBox } from "./HorizontalBox.styles";

type Props = {
  fullWidth?: boolean;
  addTopShadow?: boolean;
  children: any;
  hidden?: boolean;
  attachToBottom?: boolean;
  noPadding?: boolean;
  id?: string;
  stickToRight?: boolean;
  stickToLeft?: boolean;
  stickToCenter?: boolean;
  columnGap?: boolean;
  sx?: SxProps<Theme>
};

const HorizontalBox = (props: Props): ReactElement => {
  const {
    fullWidth = false,
    addTopShadow = false,
    children,
    hidden = false,
    attachToBottom = false,
    noPadding = false,
    id = "",
    stickToRight = false,
    stickToLeft = false,
    stickToCenter = false,
    columnGap = false,
    sx
  } = props;

  return (
    <>
      {!hidden && (
        <StyledHorizontalBox
          fullwidth={fullWidth}
          addTopShadow={addTopShadow}
          attachToBottom={attachToBottom}
          nopadding={noPadding}
          id={id}
          sticktoright={stickToRight.toString()}
          sticktoleft={stickToLeft.toString()}
          sticktocenter={stickToCenter.toString()}
          columngap={columnGap}
          sx={sx}
        >
          {children}
        </StyledHorizontalBox>
      )}
    </>
  );
};

export default HorizontalBox;
