import { RemoveCircle } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import React from 'react';

import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import StyledButton from "../../../components/Buttons/Button/Button";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import {
  MarginFixContainer,
  TransactionMappingContainer,
  TransactionMappingRowContainer,
  TransactionMappingRowFooterContainer,
  TransactionMappingRowHeaderContainer
} from "../Clients.styles";
import ActionCell from "./ActionCell";
import AddColumn from "./AddColumn/AddColumn";
import AddRow from "./AddRow/AddRow";
import RowValueCounter from "./RowValueCounter";
import { MappingBodyContainer } from "./TransactionMapping.styles";
import { useTransactionMapping } from "./useTransactionMapping.hooks";

const TransactionMapping: React.FC = () => {

  const {
    transactionMapping,
    loading,
    updateValue,
    onDelete,
    toBeDeleted,
    onDeleteCancel,
    onDeleteConfirm,
    columnLength,
    onAddColumn,
    onUpdateColumn,
    addUpdateColumn,
    onCancelAddUpdateColumn,
    onSaveAddUpdateColumn,
    onAddRow,
    onUpdateRow,
    addUpdateRow,
    onCancelAddUpdateRow,
    onSaveAddUpdateRow,
    saveTransactionMapping,
    cancelTransactionMapping,
    hasChanges,
    metricRowToColumnMappingMismatch
  } = useTransactionMapping();

  return (
    <TransactionMappingContainer>
      <Progress
        id="transaction_mapping_progress"
        showProgress={loading}
      />
      { metricRowToColumnMappingMismatch && <Box p={2}>
          <Typography color="error">Metric transactions can only be mapped with metric columns.</Typography>
        </Box>
      }
      <TransactionMappingRowHeaderContainer columnlength={columnLength?.toString()}>
        <Box />
        {transactionMapping?.columns?.map((column, index) => (
          <ActionCell type="column" data={column} onEdit={() => onUpdateColumn(column)} key={index} />
        ))}
        {transactionMapping && (
          <Button
            id="add_column"
            variant="contained"
            startIcon={<AddIcon />}
            color="primary"
            onClick={onAddColumn}
          >
              Add Column
          </Button>
        )}
      </TransactionMappingRowHeaderContainer>
      <MappingBodyContainer>
        {transactionMapping?.rows?.map((row, rowIndex) => (
          <TransactionMappingRowContainer
            key={rowIndex}
            columnlength={columnLength?.toString()}>
            <ActionCell type="row" data={row} onEdit={() => onUpdateRow(row)} />
            {row.values?.map((item, valueIndex) => (
              <RowValueCounter
                count={item}
                onChange={(value: number) => updateValue(rowIndex, valueIndex, value)}
                key={valueIndex} />
            ))}
            <Button
              id={`delete_row_${rowIndex}`}
              variant="text"
              className="delete-button"
              startIcon={<RemoveCircle color="error" />}
              onClick={() => onDelete("row", rowIndex)}
              color="primary"
            >
              Delete
            </Button>
          </TransactionMappingRowContainer>
        ))}
        {transactionMapping && (
          <TransactionMappingRowFooterContainer columnlength={columnLength?.toString()}>
            <Button
              id="add_row"
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              onClick={onAddRow}
            >
            Add Transaction or Metric
            </Button>
            {[...Array(columnLength || 0)].map((_, index) => (
              <Box component={"span"} key={index} className="delete-button-wrapper">
                <Button
                  id={`delete_column_${index}`}
                  variant="text"
                  className="delete-button"
                  startIcon={<RemoveCircle color="error" />}
                  onClick={() => onDelete("column", index)}
                  color="primary"
                >
                  Delete
                </Button>
              </Box>
            ))}
            <Box />
          </TransactionMappingRowFooterContainer>
        )}
      </MappingBodyContainer>
      <AddColumn
        open={Boolean(addUpdateColumn)}
        onCancel={onCancelAddUpdateColumn}
        onSave={onSaveAddUpdateColumn}
        maxColumnOrder={addUpdateColumn?.type === 'add' ? columnLength : columnLength - 1}
        column={addUpdateColumn?.column} />
      <AddRow
        open={Boolean(addUpdateRow)}
        onCancel={onCancelAddUpdateRow}
        onSave={onSaveAddUpdateRow}
        row={addUpdateRow?.row} />
      <ConfirmationDialog
        open={Boolean(toBeDeleted)}
        onClose={onDeleteCancel}
        id="delete_confirmation_modal"
        actions={[
          {
            label: 'Yes, Delete',
            onClick: onDeleteConfirm,
            id: 'delete_confirmation_modal_yes',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Cancel',
            onClick: onDeleteCancel,
            id: 'delete_confirmation_modal_cancel',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        title={`Are you sure you want to delete this ${toBeDeleted?.type === 'row' ? 'transaction' : 'column'}?`}/>
      <MarginFixContainer>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
          sx={{
            px: '30%'
          }}
        >
          <StyledButton
            id="cancel_transaction_mapping"
            variant="outlined"
            onClick={cancelTransactionMapping}
            text={"Cancel"}
            disabled={!hasChanges}
            color={"secondary"}
            fullWidth
            addSpaceBetweenButtons
          />
          <StyledButton
            id="save_transaction_mapping"
            variant="contained"
            text={"Save & Continue to Report Config"}
            color={"primary"}
            onClick={saveTransactionMapping}
            fullWidth
            addSpaceBetweenButtons
          />
        </HorizontalBox>
      </MarginFixContainer>
    </TransactionMappingContainer>
  );
};

export default TransactionMapping;
