import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { ReactNode, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, RouteProps } from 'react-router';

import Layout from "../../components/Layout/Layout";
import { getHostname } from '../../utils/helpers/misc.helper';
import { ScopeRole } from "../../utils/types/user.type";
import { AppContext } from '../context/appContextProvider';
import useRole from "./useRole";
export interface IPrivateRouteProps extends RouteProps {
  redirectPath: string,
  roles?: ScopeRole[]
}

type Notice = {
  msg: string;
  action?: ReactNode;
}

const documentDownloadPath = "/portal/documents";
const reportDownloadPath = "/portal/downloads";

const cookieList = [
  'matClientConfig',
  'matClientToken',
  'matClientTokenSubdomain',
  'matProxyToken',
  'matTokenDurationMinutes',
  'matTokenExpirationCountdown'
];

const hostname = getHostname(window.location.hostname);

const Notice = ({
  msg,
  action
}: Notice) => {
  return (
    <Alert
      severity="warning"
      action={action}
      sx={{
        marginY: 2
      }}
    >
      {msg}
    </Alert>
  );
};

const StopImpersonation = () => {
  const {
    onLogout,
    isCurrSelectedClientAuth0
  } = useContext(AppContext);

  const [cookies, removeCookie] = useCookies(cookieList);

  const handleClose = () => {

    if(!isCurrSelectedClientAuth0) {
      onLogout();
    }

    cookieList.forEach((item: any) => removeCookie(item, {
      path: "/",
      domain: `.${hostname}`
    }));
    window.close();
  };

  return <Button onClick={handleClose} variant="text" color="warning">
    <Typography sx={{
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: theme => theme.typography.pxToRem(14)
    }}>
      Stop Impersonating
    </Typography>
  </Button>;
};

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  path, component, redirectPath, roles, ...props
}) => {

  const {
    token,
    proxyToken,
    state,
    sideNavs
  } = useContext(AppContext);
  const {
    hasRole
  } = useRole(roles);
  const {
    hasRole: isBasicUser
  } = useRole([ScopeRole.BASIC_USER]);

  const haveNotice = !!proxyToken;

  const internalRedirectPath = sideNavs?.[0]?.routerLink ?? "/";

  //This handling is added for the client portal. If user doesn't have access to the route as per sidenav API then they should not be able to access the route.
  const checkSidenavExist = () => {
    return sideNavs && sideNavs?.length > 0 ? sideNavs?.some((item: any) => path?.includes(item?.routerLink ?? "")) : true;
  };

  if(token) {
    if((!roles || (roles && hasRole)) && (!isBasicUser || (isBasicUser && (
      checkSidenavExist() || props.location?.pathname.includes(documentDownloadPath) || props.location?.pathname.includes(reportDownloadPath)
      )))) {
      return (
        <Layout
          notice={haveNotice ? <Notice
            msg={`You are impersonating ${state.loginUser?.currentUser?.username}`}
            action={<StopImpersonation />}
          />: <></>}
        >
          <Route path={path} component={component} {...props}/>
        </Layout>
      );
    } else {
      return(
        <Redirect to={{
          pathname: props.location?.pathname.includes(documentDownloadPath) || props.location?.pathname.includes(reportDownloadPath) ? props.location.pathname: internalRedirectPath,
        }} />
      );
    }
  }
  return <Redirect to={{
    pathname: redirectPath,
    state: {
      from: props.location?.pathname.includes(documentDownloadPath) || props.location?.pathname.includes(reportDownloadPath) ? props.location.pathname: "",
    },
  }}
  />;

};

export default PrivateRoute;
