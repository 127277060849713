import { GridAlignment } from "@mui/x-data-grid-pro";

import { CustomType, DataGridColDef } from "../../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: "number",
    headerName: "Account Number",
    hide: false,
    index: 1,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "transactionTypeName",
    headerName: "Ark Transactions",
    hide: false,
    index: 2,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "attributeId",
    headerName: "Attribute",
    hide: false,
    index: 3,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "dateMapped",
    headerName: "Date Mapped",
    hide: false,
    index: 4,
    type: "date",
    sortable: true,
    align: "left" as GridAlignment,
    width: 160,
  },
  {
    field: "isTaxable",
    headerName: "Taxable",
    hide: false,
    index: 5,
    type: "string",
    sortable: true,
    decimalPlaces: 0,
    align: "left" as GridAlignment,
    width: 140,
  },
  {
    field: "isEntityRequired",
    headerName: "Entity Requirement",
    hide: false,
    index: 6,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "fsMappingId",
    headerName: "Financial Statement Mapping",
    hide: false,
    index: 7,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 280,
  },
  {
    field: "fsDisplayName",
    headerName: "Financial Statement Name",
    hide: false,
    index: 8,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 240,
  },
  {
    field: "state",
    headerName: "Status",
    hide: false,
    index: 9,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    width: 180,
  },
  {
    field: "action",
    headerName: "Columns",
    hide: false,
    hideable: false,
    index: 10,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
  {
    field: "numberAccountExp",
    headerName: "Number",
    hide: true,
    index: 11,
    disableColumnMenu: true,
    type: "number",
  },
  {
    field: "parentAccountExp",
    headerName: "Parent Account",
    hide: true,
    index: 12,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "accountNameExp",
    headerName: "Account Name",
    hide: true,
    index: 13,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "attributeAccountExp",
    headerName: "Attribute",
    hide: true,
    index: 14,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "accountDescriptionExp",
    headerName: "Description",
    hide: true,
    index: 15,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "fsMappingAccountExp",
    headerName: "FS Mapping",
    hide: true,
    index: 16,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "fsAccountNameExp",
    headerName: "FS - Name",
    hide: true,
    index: 17,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "transactionAccountExp",
    headerName: "Ark Transaction",
    hide: true,
    index: 18,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "taxableAccountExp",
    headerName: "Taxable Account Y-N",
    hide: true,
    index: 19,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "entityReqAccountExp",
    headerName: "Entity Required?",
    hide: true,
    index: 20,
    disableColumnMenu: true,
    type: "string",
  },
  {
    field: "idAccountExp",
    headerName: "Id",
    hide: true,
    index: 21,
    disableColumnMenu: true,
    type: "string",
  },
];
