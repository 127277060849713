import { Box,Skeleton,styled } from "@mui/material";

export const Container = styled(Box)(({
  theme
}) => ({
  minHeight: "100px",
  minWidth: "200px",
  boxShadow: `1px 2px 4px ${theme.palette.common.boxShadow}`,
}));

export const LoginContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  justifyContent: 'center',
}));

export const BodyContainer = styled(Box)<{backgroundColor?: string}>(({
  theme, backgroundColor
}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  backgroundColor: backgroundColor || theme.palette.common.white,
  height: '100%'
}));

export const HeaderContainer=styled(Box)<{backgroundColor?: string}>(({
  theme, backgroundColor
}) => ({
  display: 'grid',
  gridTemplateColumns: '3% 10% 1fr 10% 3%',
  gridGap: theme.spacing(0.5),
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.3, 1),
  boxShadow: `0px 1px 2px ${theme.palette.common.boxShadow}`,
  backgroundColor: backgroundColor || theme.palette.common.white,
}));

export const StyledHeaderSkeleton = styled(Skeleton)(({
  theme
}) => ({
  backgroundColor: theme.palette.secondary.dark,
  height: theme.spacing(1),
}));

export const StyledSkeleton= styled(Box)<{backgroundColor?: string}>(({
  theme, backgroundColor
}) => ({
  backgroundColor: backgroundColor || theme.palette.secondary.dark,
  height: '100%'
}));

export const StyledLoginLeftSkeleton= styled(Box)<{backgroundColor?: string}>(({
  theme, backgroundColor
}) => ({
  backgroundColor: backgroundColor || theme.palette.secondary.dark,
  height: '100%',
  display: "grid",
  gridTemplateRows: "1fr 2% 5% 2% 5% 4% 1fr",
  gridGap: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export const StyledBodyWrapperSkeleton= styled(Box)<{backgroundColor?: string}>(({
  theme, backgroundColor
}) => ({
  backgroundColor: backgroundColor || theme.palette.secondary.dark,
  height: '100%',
  display: 'grid',
  gridTemplateRows: '5fr 1fr 5fr 1fr',
  gridTemplateColumns: '1fr',
  justifyItems: 'end',
  padding: theme.spacing(1),
  gridGap: theme.spacing(1),
}));
