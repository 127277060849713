import { Box, Button, styled } from "@mui/material";

import { ITEM_LIST_BACKGROUND, WHITE } from "../../styles/colors";

export const StyledButton = styled(Button)`
  &.active {
    background-color: ${ITEM_LIST_BACKGROUND};
  }
`;

export const BoxWrapper = styled(Box)`
  flex: 1;
  display: flex;

  .MuiButton-root {
    margin-top: 0;
  }
`;
