import { GridAlignment } from "@mui/x-data-grid-pro";

import { CustomType, DataGridColDef } from "../../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: "fundName",
    headerName: "Fund",
    hide: false,
    index: 1,
    type: "string",
    sortable: true,
  },
  {
    field: "glName",
    headerName: "Ledger",
    hide: false,
    index: 2,
    type: "string",
    sortable: true,
  },
  {
    field: "journalNumber",
    headerName: "Number",
    hide: false,
    index: 3,
    sortable: true,
    type: "number",
  },
  {
    field: "date",
    headerName: "Date",
    hide: false,
    index: 4,
    type: "date",
    sortable: true,
  },
  {
    field: "lineNumber",
    headerName: "Line Number",
    hide: false,
    index: 5,
    type: "number",
    sortable: true,
  },
  {
    field: "totalDebit",
    headerName: "Total Debit",
    hide: false,
    index: 6,
    type: "string",
    sortable: true,
  },
  {
    field: "totalCredit",
    headerName: "Total Credit",
    hide: false,
    index: 7,
    type: "string",
    sortable: true,
  },
  {
    field: "account",
    headerName: "Account",
    hide: false,
    index: 8,
    type: "string",
    sortable: true,
  },
  {
    field: "lineItems.entities",
    headerName: "Memo Tags",
    hide: false,
    index: 9,
    type: "string",
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    hide: false,
    index: 9,
    type: "string",
    sortable: true,
  },
  {
    field: "action",
    headerName: "",
    hide: false,
    index: 10,
    type: "action",
    sortable: true,
  },
];
