import { Box, styled } from "@mui/material";

import IconDownload from "../../../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../../assets/images/icons/icon_upload.svg";
import { DataGrid } from "../../../../components";
import { ButtonOptions } from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import { GRID_HEADER_BACKGROUND_COLOR, GRID_HEADER_BORDER_COLOR, ROW_HOVER_BACKGROUND_COLOR } from "../../../../styles/colors";
import { AddNewButtonOptions } from "../../../../utils/types/common.type";

export const NewEntityListOptions: ButtonOptions[] = [
    {
      label: "New Memo Entity",
      id: AddNewButtonOptions.AddNew,
      icon: IconNewCircle,
    },
    {
      label: "Upload From Template",
      id: AddNewButtonOptions.UploadFromTemplate,
      icon: IconUpload,
      type: "upload",
    },
    {
      label: "Download Template",
      id: AddNewButtonOptions.DownloadTemplate,
      icon: IconDownload,
    },
  ];

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const StyledDataGridPro = styled(DataGrid)`
  margin-top: 10px;
  margin-bottom: 25px;
  border: 1px solid transparent;
  border-radius: 0;
  
  .MuiDataGrid-row.Mui-hovered {
    background-color: ${ROW_HOVER_BACKGROUND_COLOR};
  }
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-columnHeaders {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }
  .MuiDataGrid-pinnedColumnHeaders--right {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }
  .MuiDataGrid-columnHeader(:not(:first-of-type)) {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border: 1px solid ${GRID_HEADER_BORDER_COLOR};
    padding: 1px 10px;
  }
  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  .MuiDataGrid-columnSeparator {
    color: transparent;
  }
  .MuiDataGrid-columnSeparator:hover {
    color: transparent;
  }
  .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
  }
  .MuiDataGrid-pinnedColumns {
    box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
  }
  & .MuiDataGrid-cell[data-field="subject"] {
    padding: 0;
  }
`;