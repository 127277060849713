import { useEffect, useState } from "react";

import { getNoticeUrl } from "../../../services/documents.service";
import { ManipulatedPortalCapitalCalls } from "../../../utils/types/capitalCalls.type";

type Props = {
  selectedCapitalCall: ManipulatedPortalCapitalCalls;
};
export const useViewNotice = ({ selectedCapitalCall }: Props) => {
  const [pdfLink, setPdfLink] = useState<string>("");
  const getDocument = async () => {
    const res = await getNoticeUrl(selectedCapitalCall?.documentId || "");

    if (selectedCapitalCall.documentId) {
      setPdfLink(res[selectedCapitalCall.documentId]);
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  return {
    pdfLink,
  };
};
