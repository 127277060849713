import {
    Button,
    FormLabel,
    Grid,
    Typography,
  } from "@mui/material";
import { useContext, useEffect, useState } from 'react';

import CountrySelect from "../../../components/CountryCodeSelector/CountrySelect";
import { AppContext } from "../../../core/context/appContextProvider";
import { ClientThemeContext } from "../../../core/context/clientThemeContext";
import {
  getUserPhoneDetails,
  registerUserPhone
} from '../../../services/login.service';
import { MfaInfo } from "../../../utils/types/user.type";
import { DivMargin, StyledBox, StyledDiv } from "./InvestorList.styles";


const MfaRegistration = ({ 
  userMfaInfo, 
  setUserMfaInfo, 
  setLoadingInvestorList 
}: {
  userMfaInfo?: MfaInfo, 
  setUserMfaInfo: React.Dispatch<React.SetStateAction<MfaInfo>>
  setLoadingInvestorList: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [phoneDetails, setPhoneDetails] = useState<any>({
    countryCode: "1",
    countryAbbreviation: "us",
    phoneNumber: ""
  });
  const [error, setError]= useState<string>("");

  const { preAuthClientConfigs } = useContext(AppContext);

  const handleCountryCodeChange = (
    countryCode: string,
    countryAbbreviation: string,
    phoneNumber: string
  ) => {

    setPhoneDetails(((prev: any) => ({
      ...prev,
      countryCode,
      countryAbbreviation,
      phoneNumber
    })));
    if(!phoneDetails?.phoneNumber || !phoneDetails?.countryCode) {
      setError("Please provide your phone number with country code");
    } else {
      setError("");
    }
  };

  const registerPhone = async () => {
    if(!phoneDetails?.phoneNumber || !phoneDetails?.countryCode) {
      setError("Please enter your phone number with country code");
    } else {
      setLoadingInvestorList(true);
      try {
        const registeredPhoneInfo: any = await registerUserPhone({
          ...phoneDetails
        });

        if(registeredPhoneInfo?.success) {
          const phone: any = await getUserPhoneDetails();

          if(phone) {
            setUserMfaInfo(((prev: any)=> ({
              ...prev,
              phoneNumber: phone.phoneNumber
            })));
          }
        }
        setLoadingInvestorList(false);
      } catch (e) {
        setLoadingInvestorList(false);
      }
    }
  };

  return (
    <div>
      <Typography variant="h4">Multi-factor Authentication Setup</Typography>
      <StyledDiv/>
      {`${preAuthClientConfigs?.clientName ?? 'Admin Dev'} requires secondary authentication. Please enter a phone number to receive phone call or text codes.`}
      <StyledDiv/>
      <Typography variant="h3">
        Register Phone Number
     </Typography>
     <div></div>
      <StyledDiv/>
      <StyledDiv/>
      {error && <FormLabel error={true}> {error}</FormLabel>}
      <DivMargin/>
      <CountrySelect
        id={"select_country_contact_details"}
        onChange={handleCountryCodeChange}
        value={phoneDetails.countryCode + phoneDetails.phoneNumber}
        fullWidth={true}
        listitemcolor={'black'}
        label={''}
      />
      <DivMargin/>
      <Button
        type="submit"
        variant="contained"
        className="btn"
        disableElevation
        fullWidth
        onClick={()=> {
          registerPhone();
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default MfaRegistration;