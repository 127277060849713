import { FileDownloadOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { SxProps } from "@mui/system";
import { saveAs } from "file-saver";

import FileIcon from "../../assets/images/icons/icon_file.svg";
import { DocFile } from "../../utils/types/documents.type";
import ImgIcon from "../ImgIcon/ImgIcon";
import {
  Container,
  MultiFileContainer,
  StyledBox,
  StyledProgressBar,
  StyledTypography,
} from "./FileContainer.style";
import { useFileContainer } from "./useFileContainer.hooks";

type FileContainerProps = {
  icon?: string;
  file?: FileList | Array<File | DocFile>;
  onClear?: Function;
  showUploadProgress?: boolean;
  progressBarStyle?: SxProps;
  progressValue?:number;
  styles?:SxProps;
  hideClear?: boolean;
};

export const FileContainer = ({
  icon = FileIcon,
  file,
  onClear = () => {},
  showUploadProgress = false,
  progressBarStyle = {},
  progressValue=0,
  styles,
  hideClear = false,
}: FileContainerProps) => {
  const { fileCount } = useFileContainer({ file });

  return (
    <Container sx={styles}>
      <StyledBox>
        <MultiFileContainer>
          <ImgIcon icon={icon} />
          <StyledTypography variant="caption">
            {`(${fileCount}) file${fileCount > 1 ? 's': ''} added` }
          </StyledTypography>
          {showUploadProgress && progressValue > 0 && (
            <StyledProgressBar
              id="upload_progress_percent"
              variant="determinate"
              sx={progressBarStyle}
              value={progressValue}
            />
          )}
        </MultiFileContainer>
        { !hideClear && <IconButton
          aria-label="cancel file upload"
          color="primary"
          title="Cancel file upload"
          id="cancel_file_upload"
          onClick={() => onClear()}
        >
          <CloseIcon />
        </IconButton> }
      </StyledBox>
    </Container>
  );
};
