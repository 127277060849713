import { Box, Collapse, FormControl, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const BulkEditCollapse = styled(Collapse)({
  marginBottom: '8px',
});

export const BulkEditFlexBox = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  width: 'fit-content',
});

export const BulkEditInput = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
});

export const BulkEditStyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '200px',
}));

export const BulkEditStyledTextField = styled(TextField)(({ theme }) => ({
  width: '200px',
}));
