import { Fund } from '../utils/types/fund.v2.type';
import { TeamDetailInfo, TeamParams } from '../utils/types/team.type';
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

export const getTeams = async () => await apiClient().get<TeamDetailInfo[]>('teams');

export const addTeam = async (params: TeamParams) => await apiClient().post("/teams", params);

export const editTeam = async (params: TeamParams) => await apiClient().put(`/teams/${params.id}`, params);

export const getFunds = async (clientId: string) => await apiClient().get<Fund[]>(`/funds/client/${clientId}`);