import {
  Box,
  ToggleButton as ToggleButtonMui,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";

import { ImageItem } from "../../utils/types/listItems";
import {
  StyledBox,
  StyledText,
  StyledToggleButtonGroup,
} from "./ToggleButton.styles";

type Props = {
  id: string;
  options: ImageItem[];
  title?: string;
  onChange?: any;
  value: string;
};

const ToggleButton = (props: Props): ReactElement => {
  const { id, title = "", options, onChange, value } = props;

  return (
    <Box id={id}>
      {title && <Typography variant="h6">{title}</Typography>}
      <StyledToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={onChange}
        size="small"
      >
        {options.map((option, index) => (
          <ToggleButtonMui key={index} value={option.id} id={option.id}>
            <StyledBox>
              {option.icon}
              <StyledText variant="h5">{option.text}</StyledText>
            </StyledBox>
          </ToggleButtonMui>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
};

export default ToggleButton;
