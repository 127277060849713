import { Grid, Typography } from "@mui/material";
import { ReactElement } from "react";

import Container from "../../../components/Container/Container";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { FormProps } from "../../../utils/types/form.type";
import { useFundListEffect, useUpdateFundListEffect } from "./InvestorFunds.hooks";

type Props = {
  form: FormProps;
};

const InvestorFundsReadOnly: React.FC<Props> = ({  form }: Props): ReactElement => {
  const { fundList } = useFundListEffect(true, form);

  const {
    gridHeaderList,
    getGridFundList,
    searchVal,
    handleOnSearchChange,
    onFundCheck,
  } = useUpdateFundListEffect(
    fundList,
    form,
    true
  );

  const gridFundList = getGridFundList();

    return (
      <Container id="investor_funds_container" role="main">
      <Grid container mb={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Funds</Typography>
              <DataGrid
                id="data_grid"
                headerList={gridHeaderList}
                dataList={gridFundList.filter(f => f.isChecked)}
                checkboxSelection={false}
                autoHeight={false}
                minHeight="270px"
              />
        </Grid>
      </Grid>
      </Container>
    );
  };

export default InvestorFundsReadOnly;