import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { ReactElement } from "react";

import useDocumentsContext from "../../../hooks/useDocumentsContext.hooks";
import { DocumentNameLink, NewItemDot } from "./DocumentsGridCells.styles";
import { NameText } from "./NameCellRenderer.styles";

interface IDocumentIconProps {
  type: string;
  fileType?: string;
}

type DocumentIconProps = IDocumentIconProps;

function DocumentIcon(props: DocumentIconProps): ReactElement {
  const { type, fileType } = props;

  if (type === "folder") {
    return <FolderOpenOutlinedIcon />;
  }

  switch (fileType) {
    case "pdf":
      return <PictureAsPdfIcon />;
    case "docx":
    case "doc":
    case "xlsx":
    default:
      return <InsertDriveFileIcon />;
  }
}

function NameCellRenderer({ row }: GridRenderEditCellParams): ReactElement {
  const { state } = useDocumentsContext();

  return (
    <DocumentNameLink underline="none" isReadOnly={!!state?.isReadOnly}>
      <DocumentIcon type={row.type} fileType={row.fileType} />
      {row.isNew && <NewItemDot color="aqua" />}
      <NameText columnWidth={row.width} variant="body1">
        {row.name}
      </NameText>
    </DocumentNameLink>
  );
}

export default NameCellRenderer;
