import { useState } from "react";

import { FilterSelectionType } from "../../utils/types/filter.type";


export const useMultiSelectFilters = (
    onFilter?: (filter: any, selected: any[] | any, selectionType?: FilterSelectionType) => void
) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const handleOnClose = (): void => {
      setAnchorEl(null);
    };
  
    const handleOnFilter = (filterName: any, selectedOptions: Array<any>, selectionType?: FilterSelectionType) => {
      handleOnClose();
      onFilter?.(filterName, selectedOptions, selectionType);
    };
  
    const handleOnClick = (
      event: any
    ): void => {
      event?.preventDefault();
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
    };


    return {
        anchorEl,
        handleOnFilter,
        handleOnClick,
        handleOnClose
    };
};
  