import { Box, styled, TextField, Typography } from '@mui/material';

export const LabelTextField = styled(TextField)<{styles: string[]}>(({
    styles
}) => ({
    '& div input': {
        fontWeight: styles?.includes('BOLD') ? 'bold' : 'unset',
        fontStyle: styles?.includes('ITALIC') ? 'italic' : 'unset',
    }
}));

export const DragIcon = styled('img')(({
    theme
}) => ({
    paddingBottom: theme.spacing(2)
}));

export const SpacerText = styled('span')(({
    theme
}) => ({
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1)
}));

export const Spacer = styled(Box)(({
    theme
}) => ({
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    border: `2px dashed ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(0.625),
    margin: theme.spacing(0, 2.5, 0, 2.5)
}));

export const SpacerView = styled(Box)(({
    theme
}) => ({
    display: 'block'
}));

export const SpaceBox = styled(Box) (({
    theme
})=>({
    display:"grid",
    gridTemplateColumns: "5fr 0.1fr"
}));    

export const ListContainer = styled(Box)(({
    theme
}) => ({
    padding: theme.spacing(1.25)
}));

export const ListView = styled(Box)(({
    theme
}) => ({
    display: 'grid',
    gridTemplateColumns: '0.1fr 1fr 1fr 1fr 1fr 1fr 0.1fr',
    gridGap: theme.spacing(2),
    padding: theme.spacing(0, 0, 0, 2.5)
}));

export const SectionContainer = styled(Box)(({
    theme
}) => ({
    display: 'grid',
    gridGap: theme.spacing(1),
    marginTop: theme.spacing(3)
}));

export const MainContainer = styled(Box)(({
    theme
})=>({
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius:theme.spacing(2)
}));

export const AddRowContainer = styled(Box)(({
    theme
})=>({
    padding:theme.spacing(2,3.5),
    display:"grid",
    alignItems:"center",
    gridTemplateColumns:"0.3fr 0.5fr 1fr",
    borderBottom:`1px solid ${theme.palette.secondary.dark}`
}));

export const ReportContainer = styled(Box)(({
    theme
})=>({
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end",
    gridGap:theme.spacing(1)
}));

export const SpiltButtonContainer = styled(Box)(({
    theme
})=>({
    display:"flex",
    justifyContent:"flex-start"
}));

export const TypographyBox = styled(Typography)(({
    theme
}) =>({
    marginRight:theme.spacing(0.5),
    textAlign:"center"
}));

export const SummaryMiddleBox = styled(Box)(({
    theme
}) =>({
    display:"flex",
    alignItems:"center",
    gap:theme.spacing(0.9)
}));