import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";

type OnChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  order?: number | undefined;
  value: unknown;
}>;

export const useForm = <T extends object>(
  defaultValues: T,
  schema: any,
  isLoading: boolean = false
) => {
  const [values, setValues] = useState(defaultValues);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    async function changeValid() {
      const isFormValid = await yup.object().shape(schema).isValid(values);

      setIsValid(isFormValid);
    }

    changeValid();
  }, [values, schema]);

  const useInput = (name: keyof T, setHasError?: (hasError: string | null) => void) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : "";
    const loadingInput = isLoading;
    const onChange = useCallback(
      (event: any) => {
        const { value } = event.target;
        const validateResult = validate(value, schema[name] as any);
        
        setValues((prev) => ({ ...prev, [name]: value }));
        setError(validateResult);
        setHasError?.(validateResult);
        setIsDirty(!isEqual(value, defaultValues[name]));
      },
      [name, loadingInput]
    );

    return { onChange, value, error: Boolean(error), helperText: error };
  };

  const useInputSelect = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : "";
    const loadingInput = isLoading;
    const handleOptionSelection = useCallback(
      (event: any) => {
        const { value } = event.target;
        
        setValues((prev) => ({ ...prev, [name]: value }));
        setError(validate(value, schema[name] as any));
        setIsDirty(!isEqual(value, defaultValues[name]));
      },
      [name, loadingInput]
    );

    return { handleOptionSelection, value, error: Boolean(error), helperText: error };
  };

  const useInputMultiSelect = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const currentValues = !isLoading ? (values[name] as any) : [];

    const onChange = useCallback(
      (event: OnChangeEvent) => {
        const { value } = event.target;

        setValues((values) => ({ ...values, [name]: value }));
        setError(validate(value, schema[name] as any));
        setIsDirty(!isEqual(value, defaultValues[name]));
      },
      [name]
    );

    return {
      onChange,
      currentValues,
      error: Boolean(error),
      helpertext: error,
    };
  };

  const useCheckbox = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const loadingCheckbox = isLoading;
    const checked = !isLoading ? values[name] : "";

    const onChange = useCallback(
      (event: any) => {
        const { checked } = event.target;

        setValues((values) => ({
          ...values,
          [name]: checked,
        }));
        setError(validate(checked, schema[name] as any));
        setIsDirty(!isEqual(checked, defaultValues[name]));
      },
      [loadingCheckbox, name]
    );

    return { onChange, checked, error: Boolean(error), helperText: error };
  };

  const useSwitch = (name: keyof T) => {
    const [error, setError] = useState<string | null>();
    const value = !isLoading ? values[name] : false;

    const onChange = useCallback(
      (event: any) => {
        const { checked } = event.target;

        setValues((prev) => ({ ...prev, [name]: checked }));
        setError(validate(value, schema[name] as any));
        setIsDirty(!isEqual(checked, defaultValues[name]));
      },
      [name, value]
    );

    return { onChange, error: error, checked: Boolean(value) };
  };

  return {
    useInput,
    useCheckbox,
    useSwitch,
    useInputMultiSelect,
    values,
    setValues,
    isValid,
    setIsValid,
    useInputSelect,
    isDirty,
  };
};

const validate = <T = any>(value: any, schema: yup.SchemaOf<T[keyof T]>) => {
  try {
    schema.validateSync(value);
    return null;
  } catch (ex: any) {
    return ex.errors[0] as string;
  }
};
