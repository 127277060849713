export const DEBOUNCE_THRESHOLD = 500;
export const PAGE_SIZE = 50;
export const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
export const DISPLAY_TIME = 'hh:mm a';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const CLIENT_PROXY_TOKEN_KEY = "clientProxyToken";
export const LAST_INACTIVE_TAB_KEY = 'lastInactiveTabKey';
export const TOKEN_KEY = "clientToken";
export const LOGIN_PAYLOAD_KEY = "loginPayload";
export const M_DASH_UNICODE = '\u2014';
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
export const ENTER_KEY_CODE = 13;
export const ALLOCATIONS_DATE_REGEX = /(?:January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}/;
