import { Autocomplete, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";

import { CheckboxFormControl, CheckboxLabel } from "../../../pages/clientDetails/ClientDetails/ClientDetails.style";
import useTransactionTypeFilter from "./useTransactionTypesFilter.hook";

type Props = {
  error?: any;
  value?: any[] | null | string[];
  onChange?: (transactionType: any[]) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  isShrink?: boolean;
  isPlaceHolder?: boolean;
  idSelectOnly?: boolean;
};

const TransactionTypeMultiple = ({
  error,
  placeholder,
  onChange,
  value,
  disabled,
  size = "small",
  id = "transaction_type_filter",
  textFieldID = "transaction_type_filter_text_field",
  isShrink = false,
  isPlaceHolder = true,
  idSelectOnly
}: Props) => {
  const {
    transactionTypesList, loading
  } = useTransactionTypeFilter();
  const onSelectChange = (e: any, transactionType: any[]) => {
    e?.preventDefault();
    e?.stopPropagation();
    if(idSelectOnly) {
      onChange?.(transactionType?.map((item: any) => item.id) ?? []);
    } else {
      onChange?.(transactionType);
    }
  };

  const selectedValue = idSelectOnly ? transactionTypesList?.filter((item: any) => value?.includes(item["id"])) : (value);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={transactionTypesList || []}
      id={id}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.id === selected?.id
      }
      onChange={onSelectChange}
      value={selectedValue ?? []}
      disabled={disabled || loading}
      size={size}
      renderTags={(value: any[]) => {
        return value?.length > 0 ? `${value?.length} selected` : "Select transaction types";
      }}
      renderOption={(props, option, {
        selected
      }) => (
        <li {...props}>
          <CheckboxFormControl>
            <CheckboxLabel>
              <Checkbox
                checked={selected || false}
              />
              <Typography variant="subInfo">
                {option.name}
              </Typography>
              </CheckboxLabel>
          </CheckboxFormControl>

        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ""}
          InputLabelProps={
            (isShrink && {
              shrink: true,
            }) ||
            {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(TransactionTypeMultiple);
