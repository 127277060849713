import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

import { FilterSelectionType } from '../../utils/types/filter.type';
import Filter from '../DataGrid/Filter/Filter';
import { PopoverFilter } from '../DataGrid/Filter/PopoverFilter.style';
import { useMultiSelectFilters } from './MultiSelectFilter.hook';

type Props = {
  value?: any[] | null | string[];
  onFilter?: (
    filter: any,
    selected: any[] | any,
    selectionType?: FilterSelectionType
  ) => void;
  label?: string;
  size?: 'small' | 'medium';
  loading?: boolean;
  listData?: [];
  filterName: string;
  id: string;
  emptySelectionOnClear?: boolean;
  buttonSx?: SxProps<Theme>;
};

const MultiSelectFilter = ({
  id,
  label,
  onFilter,
  value,
  filterName,
  listData,
  emptySelectionOnClear = true,
  buttonSx = null,
}: Props) => {
  const { anchorEl, handleOnFilter, handleOnClick, handleOnClose } =
    useMultiSelectFilters(onFilter);

  return (
    <>
      <Button
        variant={label ? 'outlined' : 'text'}
        endIcon={<FilterAltIcon />}
        onClick={handleOnClick}
        sx={buttonSx}
      >
        {label}
      </Button>
      <PopoverFilter
        id={`${label}_${id}_popover`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Filter
          handleFilter={handleOnFilter}
          options={listData}
          selectedOptions={value as string[]}
          idField={'id'}
          labelField={'name'}
          filterName={filterName as any}
          emptySelectionOnClear={emptySelectionOnClear}
        />
      </PopoverFilter>
    </>
  );
};

export default React.memo(MultiSelectFilter);
