import { ColumnOrder, ViewItemsEntity } from "../types/columnOrder";
import { DataGridColDef } from "../types/listItems";

export const arrayIndexUpdate = (
  columnList: Array<DataGridColDef>,
  fromIndex: number,
  toIndex: number,
  clientId: string,
  viewKey: string
) => {
  const element = columnList[fromIndex];

  columnList.splice(fromIndex, 1);
  columnList.splice(toIndex, 0, element);

  columnList.map((item, index) => {
    item.index = index + 1;
  });

  const viewItems: ViewItemsEntity[] = columnList.map((item) => ({
    code: item.field,
    label: item.headerName!,
    visible: !item.hide,
    order: item.index,
  }));

  const columOrderObject: ColumnOrder = {
    viewKey: viewKey,
    clientId: clientId,
    viewItems: viewItems,
  };

  return columOrderObject;
};

export const arrayVisibilityUpdate = (
  columnList: Array<DataGridColDef>,
  clientId: string,
  viewKey: string,
  selectedColumn: string
) => {
  const viewItems: ViewItemsEntity[] = columnList.map((item) => ({
    code: item.field,
    label: item.headerName!,
    visible: item.field === selectedColumn ? item.hide! : !item.hide,
    order: item.index,
  }));

  const columOrderObject: ColumnOrder = {
    viewKey: viewKey,
    clientId: clientId,
    viewItems: viewItems,
  };

  return columOrderObject;
};