import unescape from 'lodash/unescape';

import { generateUUID } from "../../../../utils/helpers/uuidGenerator";

export enum Alignment { Left='Left', Center='Center', Right='Right' }
export enum Edge { Top=1, Right=2, Bottom=4, Left=8, All=15}
export enum StandardColors {  Black='black', Red='red', Green='green', Blue='blue', Brown='Brown', Maroon='maroon', Gray='gray', Yellow='yellow', Lime='lime', Aqua='aqua', Pink='pink', Orange='orange', Silver='silver' }
export enum CellFormats {
    //eslint-disable-next-line
    Number_Accounting='_("$"* #,##0.00_);_("$"* \(#,##0.00\);_("$"* "-"??_);_(@_)',
    Number_Percent_2='p',
    Text_Unformatted=''
}
export enum ColWidth {spacer=20,text=180, smallText=55, currency=130, percent=40}
export enum NewRowPosition {destination, belowDestination}
export enum NewColPosition {destination, rightOfDestination}

export class SpreadsheetGrid {
    
    constructor(private treegrid : TGrid) { 

    }

    private get tg() {
        return this.treegrid!;
    }

    public startUpdate() {
        this.tg.StartUpdate();
    }

    public endUpdate() {
        this.tg.EndUpdate();
    }

    public getActiveSheet() {
        return this.tg.GetActiveSheet();
    }


    public getCellValue(rowId: string, colId: string) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        let value = this.tg.GetValue(tRow, colId);

        if (typeof value === 'string') {
            value = unescape(value);
        }

        return value;
    }

    public setCellValue(rowId: string, colId: string, value: any, format: CellFormats) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        // if cell is a formula then it must be cleared first otherwise cell won't be replaced properly
        if(this.getCellFormula(rowId, colId)) { 
            this.tg.SetAttribute(tRow, colId, 'EFormula', null, 0);
        }

        this.tg.SetValue(tRow, colId, value, true);
        this.tg.SetAttribute(tRow, colId, 'Format', format, true);
    }

    public setCellValueIfDifferent(rowId: string, colId: string, value: any, format: CellFormats) {
        let oldVal = this.getCellValue(rowId, colId);
        const hasFormula = !!this.getCellFormula(rowId, colId);

        oldVal = oldVal ? oldVal : 0;

        if(hasFormula || oldVal !== value) {
            this.setCellValue(rowId, colId, value, format);
        }
    }




    public getCellFormula(rowId: string, colId: string) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        return this.tg.GetAttribute(tRow, colId, 'EFormula') as string;
    }

    public setCellFormula(rowId: string, colId: string, formula: string, format: CellFormats) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.tg.SetAttribute(tRow, colId, 'EFormula', formula, 1);
        this.tg.Calculate(1);
        this.tg.SetAttribute(tRow, colId, 'Format', format, true);
    }

    setCellFormulaIfDifferent(rowId: string, colId: string, formula: string, format: CellFormats) {
        const oldFormula = this.getCellFormula(rowId, colId);

        if(oldFormula !== formula) {
            this.setCellFormula(rowId, colId, formula, format);
        }
    }




    public setCellWordWrap(rowId: string, colId: string, wordWrap: boolean) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.tg.SetAttribute(tRow, colId, 'Wrap', wordWrap, true );
    }

    public setCellColor(rowId: string, colId: string, color: StandardColors) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.tg.SetCellStyle(tRow, colId, { Color:'245, 245, 245' }, true);
    }

    public setCellAlign(rowId: string, colId: string, align: Alignment) {
        const tRow = this.tg.GetRowByIndex(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.tg.SetAttribute(tRow, colId, 'Align', align, true);
    }

    public setCellBorder(rowId: string, colId: string, edge: Edge, width: number) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.tg.SetBorder(tRow, colId, [width,'Black',0], edge);
    }

    public setCellStrike(rowId: string, colId: string, strike: boolean) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isColVisible(colId)) return;

        this.setTextStyleBits(rowId, colId, 1, strike);
    }

    public setCellUnderline(rowId: string, colId: string, underline: boolean) {
        if(!this.isColVisible(colId)) return;

        this.setTextStyleBits(rowId, colId, 2, underline);
    }

    public setCellItalic(rowId: string, colId: string, italic: boolean) {
        if(!this.isColVisible(colId)) return;

        this.setTextStyleBits(rowId, colId, 3, italic);
    }

    public setCellBold(rowId: string, colId: string, bold: boolean) {
        if(!this.isColVisible(rowId)) return;

        this.setTextStyleBits(rowId, colId, 4, bold);
    }

    private setTextStyleBits(rowId: string, colId: string, position: number, value: boolean) {
        if(!this.isColVisible(colId)) return;

        const paddingFormat = '0000';
        const tRow = this.tg.GetRowById(rowId) as TRow;

        const cellStyleNumStr = this.tg.GetAttribute(tRow, colId, 'TextStyle') as string;
        let cellStyleBitStr = Number(cellStyleNumStr).toString(2);

        cellStyleBitStr = paddingFormat + cellStyleBitStr;
        cellStyleBitStr = cellStyleBitStr.substr(cellStyleBitStr.length-paddingFormat.length);

        const cellStyleBitAry = cellStyleBitStr.split('');

        cellStyleBitAry[position-1] = value ? '1' : '0';
        const newCellStyleBitStr = cellStyleBitAry.join('');

        const newCellStyleNum = parseInt(newCellStyleBitStr, 2);

        this.tg.SetAttribute(tRow, colId, 'TextStyle', newCellStyleNum.toString(), true);
    }

    public setSpanRange(rowId1: string, colId1: string, rowId2: string, colId2: string) {
        if(!this.isColVisible(colId1) && !this.isColVisible(colId2)) return;

        const tRow1 = this.tg.GetRowById(rowId1) as TRow;
        const tRow2 = this.tg.GetRowById(rowId2) as TRow;

        this.tg.SpanRange(tRow1, colId1, tRow2, colId2);
    }

    public setColWidth(colId: string, newWidth: number) {
        if(!this.isColVisible(colId)) return;
                
        const currCol = this.tg.Cols[colId as any] as TCol;
        const currWidth = currCol.Width;
        const diff =  newWidth - currWidth;

        this.tg.SetWidth(colId, diff);
    }

    public setRowHeight(rowId: string, height: number) {
        const tRow = this.tg.GetRowById(rowId) as TRow;

        if(!this.isRowVisible(tRow)) return;

        this.tg.ResizeRow(tRow, height);
    }

    public AutoUpdateRowHeight(rowId: string) {
        const tRow = this.tg.GetRowByIndex(rowId) as TRow;

        if(!this.isRowVisible(tRow)) return;

        this.tg.UpdateRowHeight(tRow, true);
    }

    public setWorkbookLocked(locked: boolean) {
        if(locked) {
            this.tg.SetLocked("CanEdit,Edit");
        } else {
            this.tg.SetLocked("");
        }
    }

    public isWorkbookLocked() {
        return !!this.tg.Locked?.CanEdit;
    }


    public focus(rowId: string, colId: string) {
        const tRow = this.tg.GetRowByIndex(rowId) as TRow;

        this.tg.Focus(tRow, colId);
    }

    public cancelEditCell(saveChanes: boolean = false) {
        this.tg.EndEdit(saveChanes);
    }

    public beginWorkbookSaving() {
        this.tg.Save();
    }



    public insertRow(destinationRowId: string, newRowPosition: NewRowPosition, count: number = 1 ) {  
        let newRowId: string='';

        for(let i=0; i<count; i++) {
            const newColId = generateUUID();        
            let tRow = this.tg.GetRowById(destinationRowId) as TRow;

            if(newRowPosition === NewRowPosition.belowDestination) {
                tRow = this.tg.GetNext(tRow);
            }

            newRowId = this.tg.AddRow(undefined, tRow, 7, newColId)?.id;
        }

        return newRowId;
    }

    public getRowCaption(rowId: string) {
        const tRow = this.tg.GetRowById(rowId);
        const index = this.tg.GetRowIndex(tRow, 1);

        return index.toString() as string;
    }

    public getRowPosition(rowId: string) {
        const tRow = this.tg.GetRowById(rowId);
        const index = parseInt(this.tg.GetRowIndex(tRow, 1));

        return index;
    }

    public getRowByIndex(idx: number){
        const row = this.tg.GetRowByIndex(idx);

        return row.id;
    }

    public getPrevRow(rowId: string, count: number = 1): string {
        if(count === 0) return rowId;

        let currtTRow = this.tg.GetRowById(rowId);
    
        for(let i = 1; i <= count; i++) {
            currtTRow = this.tg.GetPrev(currtTRow);
        }

        return currtTRow.id;
    }

    public getNextRowId(rowId: string, count: number = 1) {
        if(count === 0) return rowId;

        let currtTRow = this.tg.GetRowById(rowId);
    
        for(let i = 1; i <= count; i++) {
            currtTRow = this.tg.GetNextSibling(currtTRow);
        }

        return currtTRow.id;
    }



    public insertCol(destinationColId: string, section: number, newColPosition: NewColPosition, count: number = 1) {
        let newColId: string='';

        for(let i = 1; i <= count; i++) {
            let destinationColPos = this.getColPosition(destinationColId, section);
            
            if(newColPosition === NewColPosition.rightOfDestination) {
                destinationColPos++;
            }

            newColId = generateUUID();
            
            this.tg.AddCol(newColId, section, destinationColPos, undefined, 1);
        }
        
        return newColId;
    }

    public getColById(colId: string) {
        return this.tg.GetColByIndex(colId);
    }

    public getColCaption(colId: string) {
        const colCaption = this.tg.GetCaption(colId);

        return colCaption;
    }

    public getPrevCol(colId: string, count: number = 1): string {
        if(count === 0) return colId;

        let currColId: string = colId;

        for(let i = 1; i <= count; i++) {
            currColId = this.tg.GetPrevCol(colId)!;
        }

        return currColId; 
    }

    public getNextColId(colId: string, count: number = 1): string {
        if(count === 0) return colId;

        let currColId: string = colId;

        for(let i = 1; i <= count; i++) {
            currColId = this.tg.GetNextCol(currColId)!;
        }

        return currColId; 
    }

    public getColPosition(colId: string, section: number) {
        const firstCol = this.tg.GetFirstCol(section);
        const firstColPos = this.tg.GetColIndex(firstCol, 0);
        const fullColPos = this.tg.GetColIndex(colId, 0) as number;

        return fullColPos - firstColPos;
    }

    public getColFirstCol(section: number){
        return this.tg.GetFirstCol(section);
    }



    public ensureColumnIndexes(newSheetColumnIndexSize: number): void {
        const lastCol = this.tg.GetLastCol()!;
        const lastColIndex = this.tg.GetColIndex(lastCol);
        const increaseSize = newSheetColumnIndexSize - lastColIndex;

        if(increaseSize <= 0) return;

        this.tg.AddColPage(increaseSize);
    }

    public ensureRowIndexes(newSheetRowIndexSize: number): void {
        const lastRow = this.tg.GetLastDataRow()!;
        const lastRowIndex = this.tg.GetRowIndex(lastRow)!;
        const increaseSize = newSheetRowIndexSize - lastRowIndex;

        if(increaseSize <= 0) return;
        
        for(let r=0; r<=increaseSize; r++) {
            this.tg.AddRow(undefined, undefined, 1);
        }
    }

    // Important this is static since this object may not be instantiated when first called
    public static setToolbarVisible(button: string, visible: boolean) {
        //@ts-ignore
        if(!Window.__allocations_workbook) {
        //@ts-ignore
            Window.__allocations_workbook = {};
        }
    
         //@ts-ignore
         Window.__allocations_workbook[button + 'Visible'] = visible;
    }


    public isColVisible(colId: string): boolean {
        const isColVisible = this.tg.GetAttribute(undefined, colId, 'Visible') as number; 

        return isColVisible === 1;
    }

    public isRowVisible(tRow: TRow): boolean {
        const isColVisible = this.tg.GetAttribute(tRow, undefined, 'Visible') as number; 

        return isColVisible === 1;
    }

    public isCellEmpty(rowId: string, colId: string) {
        const oldVal = this.getCellValue(rowId, colId);
        const isCellEmpty = oldVal === '';

        return isCellEmpty;
    }

    public LoadSheetHidden(name: string) {
        this.treegrid = this.tg.LoadSheetHidden(name);
    }

    public LoadSheetVisible(name: string) {
        this.tg.LoadSheet(name, false);
    }
}