import React from "react";

import ChipGroup from "../ChipsGroup/ChipsGroup";

type Option = { id: string, name: string };

interface Props {
  options: Option[],
  removeSelected: (id: string) => void
}

export default function MemoizedChips ({
  options,
  removeSelected,
}: Props): React.ReactElement {
  const renderSelectedOptions = React.useMemo(() => <ChipGroup
    ChipTitleList={options}
    handleRemoveFilter={removeSelected}
    color={"green"}
  />, [JSON.stringify(options)]);

  return renderSelectedOptions;
}