export enum EntityFilter {
  Name = "name",
  Type = "type",
  State = "state",
  Country = "country",
}

export enum EntityAction {
  ExportAll = "export_all",
  ExportSelected = "export_selected",
  DownloadTemplate = "download_template",
}

export const GET_ALL_ENTITIES_ERROR = "Error retreiving all Entities.";
export const DELETE_ENTITY_SUCCESS = "Successfully deleted Entity.";
export const DELETE_ENTITY_ERROR = "Error occured when deleting Entity.";
export const DOWNLOAD_TEMPLATE_ERROR = "Error downloading template.";

export const csvHeaders = [
  { label: "entity_name", key: "name" },
  { label: "phone_number", key: "phoneNumber" },
  { label: "email_address", key: "emailAddress" },
  { label: "tin", key: "tin" },
  { label: "1099", key: "is1099" },
  { label: "entity_type", key: "type" },
  { label: "address_street1", key: "mailingAddress.street1" },
  { label: "address_street2", key: "mailingAddress.street2" },
  { label: "address_city", key: "mailingAddress.city" },
  { label: "address_state", key: "mailingAddress.state" },
  { label: "address_postal_code", key: "mailingAddress.postalCode" },
  { label: "address_country", key: "mailingAddress.country" },
];
