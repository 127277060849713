import { cloneDeep } from "lodash";

import { saveWorkbook } from "../../../../../services/workbook.service";
import { DateTimeFormat } from "../../../../../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../../../../../utils/helpers/quarter.helper";
import { AdditionalWorkbookProperties, SheetTypes, TransactionColumnType, Workbook } from "../../workbook.type";
import { SpreadsheetGrid } from "../SpreadsheetGrid";
import { AllocationsSheetManager } from "./allocations/AllocationsSheet.Manager";
import { ISheetManager } from "./ISheet.Manager";
import { TransfersSheetManager } from "./transfers/TransfersSheet.Manager";
import { UserSheetManager } from "./user/UserSheet.Manager";

export class WorkbookSheetsManager {
    private _currentWorkbookData!: Workbook;
    private _additionalWorkbookProperties!: Workbook;

    private _allocationsSheetmanager = new AllocationsSheetManager();
    private _transfersSheetManager = new TransfersSheetManager();
    private _userSheetManager = new UserSheetManager();

    private _currentSheetManager: ISheetManager = this._allocationsSheetmanager;
    private _grid: SpreadsheetGrid|undefined = undefined;
    private _isNewWorkbook!: boolean;
    private _hasChanges: boolean = false;
    private _isReadonly: boolean = false;

    public reloadWorkbook!: ((workbook: Workbook, loadAsNew: boolean, additionalWorkbookProperties?: AdditionalWorkbookProperties) => void); 
    public handleWorkbookChangeMade!: (value: boolean) => void;
    public saveComplete?: () => void;

    public get allocationsSheetmanager(){
        return this._allocationsSheetmanager;
    }

    public get transfersSheetManager(){
        return this._transfersSheetManager;
    }

    public get userSheetManager(){
        return this._userSheetManager;
    }

    public get activeSheetManager(): ISheetManager {
        return this._currentSheetManager;
    }

    public get isReadonly() {
        return  this._isReadonly;
    }

    public get isNewWorkbook() {
        return this._isNewWorkbook;
    }

    public get additionalWorkbookProperties() {
        return this._additionalWorkbookProperties;
    }

    public get workbookId() {
        return this._currentWorkbookData.id;
    }

    public get clientId() {
        return this._currentWorkbookData.tenantId;
    }

    public get reportName() {
        return this._currentWorkbookData.reportName;
    }

    public set reportName(newValue: string) {
        this._currentWorkbookData.reportName = newValue;
    }

    public get frequency() {
        return this._currentWorkbookData.frequency;
    }

    public get commitmentTransactionTypeIds() {
        return this._currentWorkbookData.commitmentTransactionTypeIds;
    }

    public get decimalRounding() {
        return this._currentWorkbookData.decimalRounding;
    }

    public get firstQuarter() {
        return this._currentWorkbookData.startQuarterNumber;
    }

    public get lastQuarter() {
        const transCols = this._currentWorkbookData.transactionColumns;
        const lastQuarter = transCols.reduce((prevVal,currVal) => currVal.quarterNumber > prevVal ? currVal.quarterNumber : prevVal, 0);
    
        return lastQuarter;
    }

    public get workbookYear() {
        return this._currentWorkbookData.startQuarterYear;
    }

    public get includeItd() {
        return this._currentWorkbookData.includeItd;
    }

    public get includeYtd() {
        return this._currentWorkbookData.includeYtd;
    }

    public get fund () {
        return cloneDeep(this._currentWorkbookData.fund);
    }

    public get WorkbookVersion() {
        return this._currentWorkbookData.version;
    }

    public set WorkbookVersion(newVersion: number) {
        this._currentWorkbookData.version = newVersion;
    }

    public changeMade(): void{
        if(!this._isReadonly && !this._hasChanges) {
            this._hasChanges = true;
            this.handleWorkbookChangeMade(true);
        }
    }

    public getLongDateForQuarter() {
        if(this.frequency === "BY_QUARTER") {
            const startEndDates = getQuarterStartEndDates(this.lastQuarter, this.workbookYear);
            const dateStr = DateTimeFormat.longDate(startEndDates.endDate);

            return dateStr;
        } else {
            return this.workbookYear.toString();
        }
    }

    public getWorkbookDataCloned() {
        const allocSheetManager =  this._allocationsSheetmanager;
        const wbClone = cloneDeep(this._currentWorkbookData);

        // all data from the below manager are already clone so no need to clone again
        wbClone.investorRows = allocSheetManager.investorRowManager.investorRows;
        wbClone.commitmentColumns = allocSheetManager.commitmentColumnsManager.commitmentColumns;
        wbClone.allocationColumns = allocSheetManager.allocationRulesManager.allocationRulesColumns;
        wbClone.transactionColumns = allocSheetManager.transactionColumnsManager.transactionColumns;

        wbClone.itdColumns = allocSheetManager.pitdColumnsManager.pitdTransactionColumns;

        if(this.includeYtd) {
            wbClone.itdColumns = wbClone.itdColumns.concat(allocSheetManager.ytdColumnsManager!.ytdTransactionColumns);
        }
        
        if(this.includeItd) {
            wbClone.itdColumns = wbClone.itdColumns.concat(allocSheetManager.itdColumnsManager!.itdTransactionColumns);
        }

        return wbClone;
    }

    /**
     * Load this instance with workbook data
     * @param workbookData - workbook data from Configuration dialog or from saved workbook
     * @param isNewWorkbook - should only be set first time after this class is instantiated
     */
    public setWorkbookData(workbookData: Workbook, isNewWorkbook: boolean, setAsNeverOpened: boolean, isReadonly: boolean = this._isReadonly, additionalWorkbookProperties?: any) {
        this._isNewWorkbook = isNewWorkbook;
        this._currentWorkbookData = workbookData;
        this._additionalWorkbookProperties = additionalWorkbookProperties;
        this._hasChanges = false;

        if(isReadonly) {
            this._isReadonly = true;
        }

        this._allocationsSheetmanager.setAllocationSheetData(
            this,
            this._currentWorkbookData,
            setAsNeverOpened
        );

        this._transfersSheetManager.setTransfersSheetData(this);
        this._userSheetManager.setUserSheetData(this);
    }

    public setPreloadSheet(sheetName: string) {
        this._currentSheetManager = this.getSheetManager(sheetName);
        this._currentSheetManager.OnPreloadedSheet();
    }

    public setLoadedSheet(grid: SpreadsheetGrid) {
        this._grid = grid;
        this._currentSheetManager = this.getSheetManager(grid.getActiveSheet());
        this._currentSheetManager.OnLoadedSheet(grid);

        grid.setWorkbookLocked(this._isReadonly);
    }

    private getSheetManager(sheetName: string) {
        switch(sheetName) {
            case SheetTypes.Allocations: 
                return this._allocationsSheetmanager;
            case SheetTypes.Transfers:
                return this._transfersSheetManager;
            default:
                return this._userSheetManager;
        }
    }

    public beginWorkbookSaving() {
        this._grid?.beginWorkbookSaving();
    }

    public beginWorkbookSavingAs(newReportName: string) {
        this._currentWorkbookData.reportName = newReportName;
        this._allocationsSheetmanager.renderHeaderInfo();

        this._isNewWorkbook = true;

        this._grid?.beginWorkbookSaving();
    }

    public beginRolloverNewWorkbook() {
        const wbClone = this.getWorkbookDataCloned();
        const lastQuarter = wbClone.frequency === 'BY_QUARTER' ? 4 : 1;

        this.clearWorkbookIDs(wbClone);

        wbClone.reportName = `${wbClone.reportName} - Copy`;
        wbClone.startQuarterNumber=1;
        wbClone.startQuarterYear+=1;

        // take 4th quarter rules and make then Quarter 1
        wbClone.allocationColumns = wbClone.allocationColumns
            .filter(ac => ac.quarterNumber === lastQuarter)
            .map(ac => {
                ac.gridColId = '';
                ac.quarterNumber = 1;

                return ac;
            });

        wbClone.transactionColumns = wbClone.transactionColumns
            .filter(c => c.quarterNumber === lastQuarter && c.colType === TransactionColumnType.STANDARD_TRANSACTION_TYPE)
            .map(c => {
                c.id = undefined;
                c.gridColId = '';
                c.locked = false;
                c.quarterNumber = 1;
                c.transSubmitError = false;
                c.allocationCodeQuarterReference = 1;
                
                return c;
            });

        const customAllocationsRules = this._allocationsSheetmanager.allocationRulesManager.getAllocationsRulesData();

        const additionalWorkbookProperties: AdditionalWorkbookProperties = {
            customAllocationsRules: customAllocationsRules
        };
        
        this.reloadWorkbook(wbClone, true, additionalWorkbookProperties);
    }

    private clearWorkbookIDs(workbook: Workbook) {
        workbook.id = undefined;
        workbook.investorRows.forEach(c => c.id = undefined);
        workbook.commitmentColumns.forEach(c => c.id = undefined);
        workbook.allocationColumns.forEach(c => c.id = undefined);
        workbook.itdColumns.forEach(c => c.id = undefined);
        workbook.transactionColumns.forEach(c => c.id = undefined);
    }

    public cancelCurrentMode() {
        this._grid?.cancelEditCell(false);
        this._grid?.focus('1','A');
    }

    public async saveWorkbookToApi(blob: Blob) {
        const grid = this._grid!;
        const wbClone = this.getWorkbookDataCloned();

        if(this.isNewWorkbook) {
            this.clearWorkbookIDs(wbClone);
        }

        // we need to all the row and col Ids below because these dont actually get save in the excel file
        // when the file is reloaded all the row and col IDs will be set to the same as th caption 
        // so we'll sync the data structures now ahead ot time

        wbClone.investorRows.forEach(r => {
            r.gridRowId = grid.getRowCaption(r.gridRowId);
        });
         wbClone.commitmentColumns.forEach(c => {
            c.gridColId = grid.getColCaption(c.gridColId);
        });
        wbClone.allocationColumns.forEach(c => {
            c.gridColId = grid.getColCaption(c.gridColId);
        });
        wbClone.transactionColumns.forEach(c => {
            c.gridColId = grid.getColCaption(c.gridColId);
        });
        wbClone.itdColumns.forEach(c => {
            c.gridColId = grid.getColCaption(c.gridColId);
        });

        const wbResponse = await saveWorkbook(wbClone, blob);

        this._hasChanges = false;
        this.handleWorkbookChangeMade(false);

        if(this.saveComplete) this.saveComplete();
    
        if(this.isNewWorkbook) {
            this.reloadWorkbook(wbResponse, false);
        }
    }
}