import { styled, Tab, Tabs } from "@mui/material";

import { SUBTITLE } from "../../styles/colors";

export const StyledTab = styled(Tab)`
  padding-right: 30px;
  padding-left: 30px;
  color: ${SUBTITLE};
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 4px;
  }
  .MuiTabs-scroller {
    overflow: auto !important;
  }
  background-color: WHITE;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
`;
