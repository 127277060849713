import { Theme } from '@mui/system';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          pageTitle: 'h1',
          label: 'span',
          formTitle: "h2",
          modalTitle: "h3",
          modalSubtitle: "h4",
          footerNote: "span",
          formTitle2:"h3",
          label2: "span",
          subInfo:"span",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        }
      }
    }
  };
};

export default Typography;

