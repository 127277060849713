import { FormControlLabel, Switch } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { ChangeEvent, useState } from "react";
import React, { ReactElement } from "react";

import useRole from "../../../../../core/routing/useRole";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { ScopeRole } from "../../../../../utils/types/user.type";
import { useDocumentsContext } from "../../../hooks";

type Props = {
  row: any;
};

const WaterMarkCellRenderer: React.FC<Props> = ({
  row,
}: Props): ReactElement => {
  const { state, toggleWaterMark } = useDocumentsContext();

  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const isEditable: boolean =
    !!isSuperAdmin || !!isClientAdmin || !!isBasicAdmin;

  const isFolder: boolean = row.type === "folder";

  const [watermark, setWatermark] = useState<boolean>(false);
  

  // eslint-disable-next-line require-await
  useEffectAsync(async (isCanceled) => {
    if (isCanceled())
      return;

      if (isFolder) {
        setWatermark(row.hasWatermark);
      } else {
        const fileWatermark = state?.currentFolder?.hasWatermark ?? false;

        setWatermark(fileWatermark);
      }
    },
    [row.hasWatermark]
  );

  return (
    <FormControlLabel
      control={
        <Switch
          checked={watermark}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setWatermark(e.target.checked);
            toggleWaterMark({
              ...row,
              hasWatermark: e.target.checked,
            });
          }}
          disabled={!isEditable || state?.isPageLocked || state?.isReadOnly || !isFolder}
          name="watermark"
        />
      }
      label="Watermark"
    />
  );
};

export default WaterMarkCellRenderer;
