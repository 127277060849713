import React, { createContext, useContext, useState } from 'react';

import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { getThemes } from "../../services/preAuthAPIs.service";
import { getThemeColors } from "../../utils/helpers/invertColors";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { Theme } from "../../utils/types/client.type";
import { AppContext } from "./appContextProvider";

export interface ClientTheme {
  sidebarBackgroundColor?: string;
  sidebarBackgroundFooterColor?: string;
  sidebarTextColor?: string;
  navbarBackgroundColor?: string;
  navbarTextColor?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  layoutBackgroundColor?: string;
  layoutTextColor?: string;
  bodyBackgroundColor?: string;
  bodyTextColor?: string;
  loginBackgroundColor?: string;
  loginTextColor?: string;
  loginBodyBackgroundColor?: string;
  loginBodyTextColor?: string;
  loginButtonBackgroundColor?: string;
  loginButtonTextColor?: string;
}

interface Props {
  children: React.ReactNode;
}
export const ClientThemeContext = createContext<{
  clientTheme?: ClientTheme;
}>({
  clientTheme: undefined
});

const ClientThemeProvider = ({
  children
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [preauthThemes, setPreauthThemes] = useState<ClientTheme>();
  const {
    preAuthClientConfigs,
    informationAlert
  } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    const processTheme = (theme: Theme) => {
      return {
        sidebarBackgroundColor: getThemeColors(theme.mainColorHex1),
        sidebarBackgroundFooterColor: getThemeColors(theme.mainColorHex1),
        sidebarTextColor: getThemeColors(theme.mainColorHex1, true),
        navbarBackgroundColor: getThemeColors(theme.mainColorHex5),
        navbarTextColor: getThemeColors(theme.mainColorHex5, true),
        buttonBackgroundColor: getThemeColors(theme.mainColorHex3),
        buttonTextColor: getThemeColors(theme.mainColorHex3, true),
        loginBackgroundColor: getThemeColors(theme.loginColorHex1),
        loginTextColor: getThemeColors(theme.loginColorHex1, true),
        loginBodyBackgroundColor: getThemeColors(theme.loginColorHex3),
        loginBodyTextColor: getThemeColors(theme.loginColorHex3, true),
        loginButtonBackgroundColor: getThemeColors(theme.loginColorHex2),
        loginButtonTextColor: getThemeColors(theme.loginColorHex2, true),
      };
    };
    
    if(preAuthClientConfigs?.clientId) {

      try {
        setLoading(true);
        const response = await getThemes(preAuthClientConfigs?.clientId);

        if (isCanceled()) return;

        setPreauthThemes(processTheme(response) as ClientTheme);
      } catch (e) {
        informationAlert("Error in getting client Theme", "error");
      } finally {
        setLoading(false);
      }
    } else if(preAuthClientConfigs?.clientId === null || preAuthClientConfigs?.clientId.length === 0) {
      setLoading(false);
    } else {
      if(loading && window.location.hostname.split(".").length === 1) setLoading(false);
    }
  }, [preAuthClientConfigs]);

  return (
    <ClientThemeContext.Provider value={{
      clientTheme: preauthThemes
    }}>
      <Progress
        id="global_loader"
        showProgress={loading}
      />
      {!loading && children}
    </ClientThemeContext.Provider>
  );
};

export default ClientThemeProvider;
