import {
  ButtonPropsSizeOverrides,
  ClickAwayListener,
  Divider,
  Fade,
  Grow,
  Paper,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import React, { ReactElement, useContext, useEffect } from "react";

import { ClientThemeContext } from "../../../core/context/clientThemeContext";
import { ImageItem } from "../../../utils/types/listItems";
import Button from "../Button/Button";
import {
  StyledArrowIcon,
  StyledBox,
  StyledBoxText,
  StyledButton,
  StyledButtonGroup,
  StyledMenuItem,
  StyledMenuList,
  StyledPopper,
} from "./SplitButton.styles";

type Props = {
  id: string;
  ariaLabelMessage: string;
  size?: OverridableStringUnion<
    "small" | "medium" | "large",
    ButtonPropsSizeOverrides
  >;
  options: ImageItem[];
  hidden: boolean;
  handleOptionClick: any;
  selectedValue?: string;
  noGutter?: boolean;
  text?: string;
  icon?: ReactElement;
  showSelectedOptionIcon?: boolean;
  highlightSelections?: boolean;
  stopClickForwarding?: boolean; // To stop default behaviour of forwarding onClick to an option of dropdown menu
  disabled?: boolean;
};

const SplitButton: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id,
    options,
    hidden = false,
    size = "medium",
    handleOptionClick,
    ariaLabelMessage,
    selectedValue = undefined,
    noGutter = false,
    text,
    icon,
    showSelectedOptionIcon = true,
    highlightSelections = true,
    stopClickForwarding = false,
    disabled = false,
  } = props;
  const { clientTheme } = useContext(ClientThemeContext);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    if (options.length - 1 < selectedIndex) {
      setSelectedIndex(0);
    }
    const firstNotHidden = options.findIndex((item) => item.hidden !== true);

    setSelectedIndex(firstNotHidden);
  }, [options]);

  const handleClick = () => {
    if (stopClickForwarding) {
      setOpen((prevOpen) => !prevOpen);
      return;
    }
    if (!selectedValue) handleOptionClick(options[selectedIndex].id);
    else handleOptionClick(options[selectedIndex].id, [selectedValue]);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    handleOptionClick(options[index].id, [selectedValue]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Fade in={!hidden}>
        <StyledButtonGroup
          nogutter={noGutter.toString()}
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          disableElevation
          id={id}
        >
          <Button
            id={`btn_options_${options[selectedIndex]?.id}_${id}`}
            onClick={handleClick}
            color="secondary"
            size={size}
            variant="outlined"
            text={text || options[selectedIndex]?.text}
            icon={
              showSelectedOptionIcon ? options[selectedIndex]?.icon || "" : icon
            }
          />
          <StyledButton
            id={`btn_options_${options[selectedIndex]?.id}_menu`}
            size={size}
            backgroundcolor={clientTheme?.buttonBackgroundColor}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label={ariaLabelMessage}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <StyledArrowIcon textcolor={clientTheme?.buttonTextColor} />
          </StyledButton>
        </StyledButtonGroup>
      </Fade>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList id="split-button-menu">
                  {options?.map((option, index) => (
                    <StyledMenuItem
                      key={option.id}
                      selected={highlightSelections && index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      color={option.textColor || "primary"}
                      hidden={option.hidden}
                      disabled={option.disabled ?? false}
                    >
                      <StyledBox>
                        {option.icon}
                        <StyledBoxText color={option.textColor || "primary"}>
                          {option.text}
                        </StyledBoxText>
                      </StyledBox>
                    </StyledMenuItem>
                  ))}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
};

export default React.memo(SplitButton);
