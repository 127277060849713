import { List, ListItemButton, Typography } from "@mui/material";
import { ReactElement, useRef } from "react";

import IconDownload from "../../../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../../assets/images/icons/icon_upload.svg";
import ArrowPopover from "../../../../components/ArrowPopover/ArrowPopover";
import ListItem from "../../../../components/ListItem/ListItem";
import ListItemIcon from "../../../../components/ListItemIcon/ListItemIcon";
import { downloadTemplate } from "../../../../services/template.service";
import {
  DOWNLOADED_TEMPLATE_CONTACT_FILE_NAME,
  TEXT_DOWNLOAD_TEMPLATE,
  TEXT_NEW_CONTACT,
  TEXT_UPLOAD_TEMPLATE,
} from "../../../../utils/constants/text.constants";
import downloadFile from "../../../../utils/helpers/fileDownloader";

type Props = {
  id: string;
  anchorEl: HTMLButtonElement | null;
  showPopover: boolean;
  handleOnPopoverClose: any;
  handleOnTemplateUpload: any;
  handleOnNewContact: any;
};

const ContactAddOptionsPopover: React.FC<Props> = (
  props: Props
): ReactElement => {
  const {
    id,
    anchorEl,
    showPopover,
    handleOnPopoverClose,
    handleOnTemplateUpload,
    handleOnNewContact,
  } = props;

  const handleOnTemplateDownload = async () => {
    const data = await downloadTemplate("contacts");

    downloadFile(data, DOWNLOADED_TEMPLATE_CONTACT_FILE_NAME, "csv");
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileUploadRequest = (event: any) => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  return (
    <ArrowPopover
      id={id}
      anchorEl={anchorEl}
      showPopover={showPopover}
      handleOnPopoverClose={handleOnPopoverClose}
      content={
        <List dense={true}>
          <ListItem>
            <ListItemButton onClick={handleOnNewContact}>
              <ListItemIcon id={`${id})_new_contact`} icon={IconNewCircle} />
              <Typography variant="subtitle2">{TEXT_NEW_CONTACT}</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleFileUploadRequest}>
              <ListItemIcon id={`${id}_upload`} icon={IconUpload} />
              <Typography variant="subtitle2">
                {TEXT_UPLOAD_TEMPLATE}
              </Typography>
            </ListItemButton>
            <input
              id="input_file_upload"
              type="file"
              ref={hiddenFileInput}
              onChange={handleOnTemplateUpload}
              hidden={true}
            />
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleOnTemplateDownload}>
              <ListItemIcon id={`${id}_download`} icon={IconDownload} />
              <Typography variant="subtitle2">
                {TEXT_DOWNLOAD_TEMPLATE}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      }
    />
  );
};

export default ContactAddOptionsPopover;
