import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import FroalaEditor from 'react-froala-wysiwyg';
import { useParams } from 'react-router-dom';

import { ProgressModal } from '../../../components';
import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import InformationPanel from '../../../components/InformationPanel/InformationPanel';
import { ViewType } from './MailingsComposerPanel.constants';
import { useMailingsComposerEffect } from './MailingsComposerPanel.hooks';
import {
  FormBox,
  HeaderRecipient,
  ListRecipient,
  PanelActionBox,
  StyledButtonBox,
  Title,
} from './MailingsComposerPanel.styles';
import MultiSelectFilter from './MultiSelectFilter';

type Props = {
  selectedMailingId: string;
  setSelectedMailingId: any;
  selectedMailing?: any;
  setSelectedMailing?: Function;
  fetchAllGeneralMailings: any;
  view: ViewType | string | null | undefined;
  setView: Function;
  onSuccessfulPostClose?: Function;
};

const MailingsComposerPanel: React.FC<Props> = ({
  selectedMailingId,
  setSelectedMailingId,
  fetchAllGeneralMailings,
  view,
  setView,
  selectedMailing,
  setSelectedMailing,
  onSuccessfulPostClose,
}: Props) => {
  const {
    isPanelVisible,
    hidePanel,
    createGeneralMailing,
    register,
    handleSubjectChange,
    handleCcChange,
    handleBcChange,
    model,
    handleModelChange,
    selectedFiles,
    isLoading,
    recipientCcError,
    setRecipientCcError,
    recipientBccError,
    setRecipientBccError,
    selectedRoleOptions,
    selectedFundOptions,
    selectedInvestorOptions,
    handleOptionsFilter,
    roleFilters,
    fundFilters,
    investorFilters,
    docDisplayTo,
    docDisplayFunds,
    docDisplayInvestors,
    docDisplayRoles,
    selectedDocuments,
    contactDisplayFunds,
    contactDisplayInvestors,
    contactDisplayRoles,
    contactDisplayEmails,
    froalaConfigs,
  } = useMailingsComposerEffect({
    selectedMailingId,
    setSelectedMailingId,
    fetchAllGeneralMailings,
    view,
    setView,
    selectedMailing,
    setSelectedMailing,
    onSuccessfulPostClose,
  });

  return (
    <>
      <ProgressModal
        id="modal_mailings_composer_loading"
        showProgress={isLoading}
      />
      <InformationPanel
        id="email_drawer"
        open={isPanelVisible['right']}
        onClose={hidePanel}
      >
        <Box>
          <HorizontalBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '10px',
            }}
          >
            <Title id="title">General Mailing Composer</Title>
            <PanelActionBox paddingRight={'10px'} paddingTop={'25px'}>
              <CloseIcon
                id="btn_contact_email_close"
                color="primary"
                onClick={hidePanel}
              />
            </PanelActionBox>
          </HorizontalBox>
          <br />
          <FormBox>
            <Grid container spacing={2}>
              <Stack>
                <Grid item>
                  {view === ViewType.Documents ? (
                    <div>
                      <HeaderRecipient>ATTACHED</HeaderRecipient>
                      <ListRecipient>
                        {selectedFiles.map((file: any) => file.name).join(', ')}
                      </ListRecipient>
                      {selectedDocuments !== null ? (
                        ''
                      ) : (
                        <div>
                          <br />
                          <HeaderRecipient>FUNDS</HeaderRecipient>
                          <ListRecipient>{docDisplayFunds}</ListRecipient>
                          <br />
                          <HeaderRecipient>INVESTORS</HeaderRecipient>
                          <ListRecipient>{docDisplayInvestors}</ListRecipient>
                          <br />
                          <HeaderRecipient>ROLES</HeaderRecipient>
                          <ListRecipient>{docDisplayRoles}</ListRecipient>
                          <br />
                          <HeaderRecipient>TO:</HeaderRecipient>
                          <ListRecipient>{docDisplayTo}</ListRecipient>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <HeaderRecipient>RECIPIENTS:</HeaderRecipient>
                      <StyledButtonBox>
                        <MultiSelectFilter
                          size="small"
                          id={'mailing_funds_filter'}
                          value={selectedFundOptions}
                          handleFilter={handleOptionsFilter}
                          label={
                            selectedFundOptions.length > 0
                              ? `Funds (${selectedFundOptions.length})`
                              : 'Funds'
                          }
                          listData={fundFilters as any}
                          filterName={'fund_filter'}
                        />
                        <MultiSelectFilter
                          size="small"
                          id={'mailing_investor_filter'}
                          value={selectedInvestorOptions}
                          handleFilter={handleOptionsFilter}
                          label={
                            selectedInvestorOptions.length > 0
                              ? `Investors (${selectedInvestorOptions.length})`
                              : 'Investors'
                          }
                          listData={investorFilters as any}
                          filterName="investor_filter"
                        />
                        <MultiSelectFilter
                          size="small"
                          id={'mailing_role_filter'}
                          value={selectedRoleOptions}
                          handleFilter={handleOptionsFilter}
                          label={
                            selectedRoleOptions.length > 0
                              ? `Roles (${selectedRoleOptions.length})`
                              : 'Roles'
                          }
                          listData={roleFilters as any}
                          filterName={'role_filter'}
                        />
                      </StyledButtonBox>
                      {contactDisplayFunds.length > 0 ? (
                        <div>
                          <br />
                          <HeaderRecipient>FUNDS</HeaderRecipient>
                          <ListRecipient>
                            {contactDisplayFunds.join(', ')}
                          </ListRecipient>
                          <br />
                        </div>
                      ) : (
                        ''
                      )}
                      {contactDisplayInvestors.length > 0 ? (
                        <div>
                          <HeaderRecipient>INVESTORS</HeaderRecipient>
                          <ListRecipient>
                            {contactDisplayInvestors.join(', ')}
                          </ListRecipient>
                          <br />
                        </div>
                      ) : (
                        ''
                      )}
                      {contactDisplayRoles.length > 0 ? (
                        <div>
                          <HeaderRecipient>ROLES</HeaderRecipient>
                          <ListRecipient>
                            {contactDisplayRoles.join(', ')}
                          </ListRecipient>
                          <br />
                        </div>
                      ) : (
                        ''
                      )}
                      {contactDisplayEmails.length > 0 ? (
                        <div>
                          <HeaderRecipient>TO:</HeaderRecipient>
                          <ListRecipient>
                            {contactDisplayEmails.join(', ')}
                          </ListRecipient>
                          <br />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </Grid>
                <br />
                <Grid item>
                  <Box component="form">
                    <TextField
                      id="general_mailings_cc_field"
                      size="small"
                      fullWidth
                      aria-describedby="cc"
                      label="cc Recipients"
                      placeholder={'user1@example.com, user2@example.com'}
                      variant="outlined"
                      {...register('cc')}
                      onChange={(event: any) => {
                        handleCcChange(event.target.value);
                        setRecipientCcError(false);
                      }}
                      error={recipientCcError}
                      helperText={
                        recipientCcError
                          ? 'user1@example.com, user2@example.com'
                          : ''
                      }
                    />
                  </Box>
                </Grid>
                <br />
                <Grid item>
                  <Box component="form">
                    <TextField
                      id="general_mailings_bc_field"
                      size="small"
                      fullWidth
                      aria-describedby="bcc"
                      label="bcc Recipients"
                      placeholder={'user1@example.com, user2@example.com'}
                      variant="outlined"
                      {...register('bc')}
                      onChange={(event) => {
                        handleBcChange(event.target.value);
                        setRecipientBccError(false);
                      }}
                      error={recipientBccError}
                      helperText={
                        recipientBccError
                          ? 'user1@example.com, user2@example.com'
                          : ''
                      }
                    />
                  </Box>
                </Grid>
                <br />
                <Grid item>
                  <Box component="form">
                    <TextField
                      id="general_mailings_subject_field"
                      size="medium"
                      required
                      fullWidth
                      aria-describedby="subject"
                      label="Subject"
                      placeholder={'Subject'}
                      variant="outlined"
                      {...register('subject')}
                      onChange={(event) => {
                        handleSubjectChange(event.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <br />
                <Grid item>
                  <FroalaEditor
                    config={froalaConfigs}
                    tag="textarea"
                    model={model}
                    onModelChange={handleModelChange}
                  />
                </Grid>
              </Stack>
            </Grid>
            <br />
            <Button
              id="btn_save_draft_general_mailings"
              variant="contained"
              size="small"
              text="Save Draft"
              color={'primary'}
              type="submit"
              onClick={createGeneralMailing}
            />
            <Button
              id="btn_cancel_draft_general_mailings"
              variant="contained"
              size="small"
              text="Cancel"
              color={'secondary'}
              type="submit"
              addSpaceBetweenButtons={true}
              onClick={hidePanel}
            />
          </FormBox>
        </Box>
      </InformationPanel>
    </>
  );
};

export default MailingsComposerPanel;
