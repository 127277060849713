import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';

import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { CashReceiptTransaction } from '../../../utils/types/cashReceipt.type';
import { BlueModeEditOutlinedIcon } from '../../banks/components/bulkEdit/BulkEdit.styles';
import { BulkEditOptions } from '../cashReceipts/CashReceipts.constants';
import {
  BulkEditCollapse,
  BulkEditFlexBox,
  BulkEditInput,
  BulkEditStyledFormControl,
  BulkEditStyledTextField,
} from './BulkEdit.styles';

interface Props extends RouteProps {
  showBulkEdit: boolean;
  transactionFilteredList: CashReceiptTransaction[];
  transactionSelectionModel: string[];
  setTransactionSelectionModel: React.Dispatch<React.SetStateAction<string[]>>;
  lastDateInput: Date;
  setLastDateInput: React.Dispatch<React.SetStateAction<Date>>;
  handleBulkEditChange: (selectedOption: string) => void;
}

export const CashReceiptsBulkEdit: React.FC<Props> = ({
  showBulkEdit,
  transactionFilteredList,
  transactionSelectionModel,
  setTransactionSelectionModel,
  lastDateInput,
  setLastDateInput,
  handleBulkEditChange,
}: Props) => {
  const [uneditableTransactionsSelected, setUneditableTransactionsSelected] =
    useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>(
    BulkEditOptions.KeepExisting
  );
  const [bulkEditDateInput, setBulkEditDateInput] = useState<Date | null>(
    lastDateInput
  );
  const [isValidDate, setIsValidDate] = useState(true);

  const handleDateInput = (newValue: Date | null) => {
    setBulkEditDateInput(newValue);
    if (DateTimeFormat.isValidDate(newValue)) {
      setLastDateInput(newValue as Date);
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  };

  useEffect(() => {
    if (!showBulkEdit) return;
    const selectedUneditableTransactions = transactionFilteredList.filter(
      (t) => {
        if (transactionSelectionModel.includes(t.id) && t.journalEntryId)
          return true;
        return false;
      }
    );

    setUneditableTransactionsSelected(
      selectedUneditableTransactions.map((t) => t.id)
    );
  }, [transactionSelectionModel, showBulkEdit]);

  const handleUnselect = () => {
    const updatedSelectionModel = transactionSelectionModel.filter(
      (t) => !uneditableTransactionsSelected.includes(t)
    );

    setTransactionSelectionModel(updatedSelectionModel);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  return (
    <BulkEditCollapse in={showBulkEdit}>
      <BulkEditFlexBox>
        <BlueModeEditOutlinedIcon />
        <Typography>
          <strong>Bulk Edit:</strong>
        </Typography>
        {uneditableTransactionsSelected.length ? (
          <>
            <Typography>
              {`${uneditableTransactionsSelected.length} of ${transactionSelectionModel.length} transaction(s) cannot be edited.`}
            </Typography>
            <Button onClick={() => handleUnselect()}>UNSELECT</Button>
          </>
        ) : (
          <Typography>
            {transactionSelectionModel.length === 0
              ? 'Please select transactions to edit'
              : `${transactionSelectionModel.length} Transactions Selected.`}
          </Typography>
        )}

        {!uneditableTransactionsSelected.length &&
          !!transactionSelectionModel.length && (
            <BulkEditInput>
              <BulkEditStyledFormControl size="small">
                <InputLabel>Cash Received</InputLabel>
                <Select
                  value={selectedOption}
                  onChange={(e) => handleChange(e)}
                  label="Cash Received"
                >
                  <MenuItem value={BulkEditOptions.KeepExisting}>
                    Keep Existing
                  </MenuItem>
                  <MenuItem value={BulkEditOptions.CashReceived}>
                    Cash Received
                  </MenuItem>
                  <MenuItem value={BulkEditOptions.UncheckCashReceived}>
                    Uncheck Received
                  </MenuItem>
                </Select>
              </BulkEditStyledFormControl>

              <DatePicker
                value={
                  selectedOption === BulkEditOptions.UncheckCashReceived
                    ? null
                    : bulkEditDateInput
                }
                disabled={
                  selectedOption === BulkEditOptions.UncheckCashReceived
                }
                onChange={handleDateInput}
                renderInput={(params) => (
                  <BulkEditStyledTextField
                    {...params}
                    id="bulk_edit_receipt_date"
                    label="Date of Receipt"
                    aria-describedby="date"
                    variant="outlined"
                    size="small"
                    error={!isValidDate}
                  />
                )}
              />

              <Button
                id="btn_apply_bulk_edit"
                variant="contained"
                onClick={() => handleBulkEditChange(selectedOption)}
                disableElevation
                endIcon={<SendIcon />}
                disabled={!isValidDate}
              >
                Apply
              </Button>
            </BulkEditInput>
          )}
      </BulkEditFlexBox>
    </BulkEditCollapse>
  );
};
