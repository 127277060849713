import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';
import React from 'react';

import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import DeleteConfirmationDialog from '../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { WbConfigDialogType, Workbook } from '../workbook.type';
import { AllocationWorkbookInfo } from '../workbook/workbookConfiguration/WorkbookConfiguration';
import { DELETE_ACTION_TYPE, useAllocationFiles } from './AllocationFiles.hooks';
import { HeaderRow, StyledDataGrid } from './AllocationFiles.styles';

export const AllocationFiles: React.FC = () => {

    const {
        getBulkItems,
        handleBulkAction,
        getButtonOptions,
        handleButtonAction,
        getHeaderList,
        handleOnView,
        selectionModel, setSelectionModel,
        workbooks,
        showAllocationWBddialog, setShowAllocationWBddialog,
        handleLoadNewWorkbook,
        deleteAction,
        onDeleteConfirm, onDeleteCancel,
        isFundAdmin
    } = useAllocationFiles();


    return (
        <div>
            <Box id="allocation_files_main" role="main">
                <HeaderRow>
                    <Typography variant="pageTitle">Allocation Files</Typography>
                    {!isFundAdmin && (<>
                    <SplitButton
                        id={"btn_bulk_action_options"}
                        options={getBulkItems()}
                        hidden={false}
                        handleOptionClick={handleBulkAction}
                        ariaLabelMessage="Select bulk action option"
                    />
                    <ButtonWithOptions
                        buttonID="add_allocation_file_button"
                        popoverID="add_allocation_file_popover"
                        onClick={handleButtonAction}
                        buttonLabel="Add New"
                        buttonIcon={<AddIcon />}
                        options={getButtonOptions()}
                    />
                    </>)}
                </HeaderRow>
                <Box>
                    <StyledDataGrid
                        id={"allocations_data_grid"}
                        dataList={ workbooks }
                        headerList={getHeaderList()}
                        handleOnView={handleOnView}
                        selectionModel={selectionModel}
                        setSelectionModel={setSelectionModel}
                    />
                </Box>
            </Box>

            {
                showAllocationWBddialog &&
                    (<AllocationWorkbookInfo
                        importWorkbooks={workbooks as Workbook[]}
                        wbConfigDialogType={WbConfigDialogType.NewWorkbook}
                        onOk={handleLoadNewWorkbook}
                        onCancel={() => { setShowAllocationWBddialog(false); }}
                    />)
            }

            <DeleteConfirmationDialog
                open={deleteAction !== DELETE_ACTION_TYPE.DELETE_NONE}
                id="delete-confirmation-modal"
                primaryButtonAction={onDeleteConfirm}
                secondaryButtonAction={onDeleteCancel}
            />
        </div>
    );
};
