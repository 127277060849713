import { Box, FormControl } from '@mui/material';
import { GridAlignment, GridFilterModel } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import { getLedgers } from '../../../services/arkGL.service';
import {
  getAccountsForGLPlatform,
  getHasMappingAdmin,
  getTransactionBuckets,
  getTransactionTypes,
  postMapsForFund,
  syncAccountsForFund,
} from '../../../services/fund.service';
import { GENERIC_ERROR_MESSAGE } from '../../../utils/constants/text.constants';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  TransMapAccount,
  TransType,
  TransTypeBucket,
} from '../../../utils/types/fund.type';
import { LedgerName, LedgerType } from '../../../utils/types/glSetup.type';
import { DataGridColDef } from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import { GET_LEDGER_LIST_ERROR } from '../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import {
  GET_TRANS_BUCKETS_LIST_ERROR,
  GET_TRANS_TYPES_LIST_ERROR,
  SAVE_MAPPING_ERROR,
  SAVE_MAPPING_SUCCESS,
  SAVE_NEW_TRANSACTION_SUCCESS,
  SYNC_GL_ERROR,
  SYNC_GL_SUCCESS,
} from './TransactionMapper.constants';
import { defaultHeaderList } from './TransactionMapper.defaultHeaders';
import {
  CellFormControl,
  CellMenuItem,
  CellSelect,
  TypographyBold,
} from './TransactionMapper.styles';

enum AccountFilter {
  Transaction = 'transactionTypeId',
}

export const useTransactionMapperEffect = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const filterModelUnmappedOnly = {
    items: [
      {
        columnField: 'transactionTypeId',
        operatorValue: 'isEmpty',
        value: '',
      },
    ],
  };
  const filterModelAll = { items: [] };
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [accountsResponse, setAccountsResponse] = useState<any>([]);
  const [accountList, setAccountList] = useState<TransMapAccount[]>([]);
  const [accountFilteredList, setAccountFilteredList] = useState<any[]>([]);
  const [transactionList, setTransactionList] = useState<TransType[]>([]);
  const [glConnectionDate, setGlConnectionDate] = useState<string | null>(null);
  const [glConnectionPlatform, setGlConnectionPlatform] = useState<
    string | null
  >(null);
  const [glConnectionUsername, setGlConnectionUsername] = useState<
    string | null
  >(null);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    filterModelUnmappedOnly
  );
  const [fundName, setFundName] = useState<string>('');
  const [hasMappingAdmin, setHasMappingAdmin] = useState<boolean>(false);
  const [progress, setProgress] = useState<{
    mappedAmount: number;
    totalAmount: number;
  }>({ mappedAmount: 0, totalAmount: 0 });
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [transBuckets, setTransBuckets] = useState<TransTypeBucket[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [bulkMappingSelection, setBulkMappingSelection] = useState<string>('');
  const [isAlertToUnlockOpen, setIsAlertToUnlockOpen] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(true);
  const [selectedFund, setSelectedFund] = useState<string>('');
  const [selectionModel, setSelectionModel] = useState<string[]>([]);

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;

  const selectedTransactionList = useMemo(
    () => transactionList?.map((item) => item.id),
    [transactionList]
  );

  useEffectAsync(async (isCanceled) => {
    try {
      setIsLoading(true);

      let response: any;
      let isAuthenticated;

      const ledgerList = await fetchAllLedgers(isCanceled);

      if (isCanceled()) return;

      ledgerList.map((ledger: any) => {
        if (
          ledger.glPlatform === LedgerType.QUICKBOOKS_ONLINE &&
          ledger.status === 'AUTHENTICATED'
        ) {
          setGlConnectionUsername(ledger.linkedBy ? ledger.linkedBy : '');
          setGlConnectionDate(
            ledger.linkedOn ? DateTimeFormat.shortDate(ledger.linkedOn) : ''
          );

          setGlConnectionPlatform(LedgerName.QUICKBOOKS_ONLINE);
          isAuthenticated = true;
        }
      });

      if (isAuthenticated) {
        response = await getAccountsForGLPlatform(
          fundId,
          LedgerType.QUICKBOOKS_ONLINE
        );

        if (response && response.items.length === 0) {
          response = await syncAccountsForFund(
            fundId,
            LedgerType.QUICKBOOKS_ONLINE
          );
        }
      }

      if (response) {
        setAccountsResponse(response.items);
        setFundName(response.fundName);
      }

      await fetchMappingAdmin(isCanceled);
      await fetchTransTypes(isCanceled);
      await fetchTransBuckets(isCanceled);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAllLedgers = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const response = await getLedgers(fundId);

      if (isCanceled?.()) return;

      return response.items;
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMappingAdmin = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const hasMappingAdminResponse = await getHasMappingAdmin();

      if (isCanceled?.()) return;

      setHasMappingAdmin(hasMappingAdminResponse);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransTypes = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const transactionTypesResponse = await getTransactionTypes(clientId);

      if (isCanceled?.()) return;

      transactionTypesResponse.unshift({ id: '1', name: 'Do not map' });

      setTransactionList(transactionTypesResponse);
    } catch (e) {
      informationAlert(GET_TRANS_TYPES_LIST_ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransBuckets = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);

      const transactionBucketsResponse = await getTransactionBuckets(clientId);

      if (isCanceled?.()) return;

      setTransBuckets(transactionBucketsResponse);
    } catch (e) {
      informationAlert(GET_TRANS_BUCKETS_LIST_ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const processedAccountList = prepRowsForDataGrid(accountsResponse);

    setAccountList(processedAccountList);
  }, [accountsResponse]);

  useEffect(() => {
    if (accountList && transactionList) {
      initializeHeaderList();
    }
  }, [accountList, transactionList, isScreenLocked]);

  useEffect(() => {
    calculateProgress();
    initializeSearchOptions(accountList);
  }, [accountList]);

  useEffect(() => {
    if (search === '') {
      setAccountFilteredList(accountList);
    } else {
      const escapeRegex = (string: string) => {
        return string.replace(/(?=\W)/g, '\\');
      };

      const searchText = new RegExp(escapeRegex(search), 'ig');

      setAccountFilteredList(
        accountList.filter((account) => {
          return (
            account.name.match(searchText) ||
            account.transactionTypeName?.match(searchText)
          );
        })
      );
    }
  }, [search]);

  const prepRowsForDataGrid = (accounts: TransMapAccount[]) => {
    const rows = accounts.map((account) => {
      const f: any = (current: any) => {
        if (current.parentId === null) {
          return [current.name];
        }
        return [
          current.name,
          ...f(accounts.find((acc) => acc.id === current.parentId)),
        ];
      };

      return {
        ...account,
        id: account.id,
        path: f(account).reverse(),
      };
    });

    return rows;
  };

  const calculateProgress = () => {
    if (accountList) {
      const mappedAmount = accountList.filter(
        (item) => item.transactionTypeId
      ).length;
      const totalAmount = accountList.length;

      setProgress({ mappedAmount, totalAmount });
      if (mappedAmount === totalAmount) {
        setTab(1);
        setFilterModel(filterModelAll);
      }
    }
  };

  const initializeSearchOptions = (list: any[]) => {
    const accountListOptions = list.map((account) => account.name);

    const transactionListOptions = transactionList.map(
      (transaction) => transaction.name
    );

    const options = [...accountListOptions, ...transactionListOptions];

    setSearchOptions(Array.from(new Set(options)));
  };

  const initializeHeaderList = () => {
    const updatedHeaders = [
      {
        field: 'transactionTypeId',
        headerName: 'Ark Transactions',
        hide: false,
        index: 2,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 350,
        disableExport: true,
        disableColumnMenu: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.Transaction,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: transactionList,
        inlineFilterSelected: selectedTransactionList,
        renderCell: (params: any) => {
          if (transactionList) {
            return (
              <CellFormControl>
                <CellSelect
                  id={`select_transaction_name_${params.row.id}`}
                  labelId={`select_transaction_name_${params.row.id}_label`}
                  label="Transaction Name"
                  value={
                    params.row.transactionTypeId
                      ? params.row.transactionTypeId
                      : ''
                  }
                  onChange={(e) =>
                    handleTransTypeChange(
                      [params.row.id],
                      e.target.value as string
                    )
                  }
                  onClick={(e) => {
                    if (isScreenLocked) {
                      setIsAlertToUnlockOpen(true);
                    }
                  }}
                  disabled={isScreenLocked}
                >
                  <CellMenuItem value="0">Unmapped</CellMenuItem>
                  {transactionList.map((type) => {
                    return (
                      <CellMenuItem key={type.id} value={type.id}>
                        {type.name}
                      </CellMenuItem>
                    );
                  })}
                </CellSelect>
              </CellFormControl>
            );
          }
        },
      },
      {
        field: 'dateMapped',
        headerName: 'Date Mapped',
        hide: false,
        index: 3,
        type: 'date',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 200,
      },
      {
        field: 'lastGLActivity',
        headerName: 'Last GL Activity',
        hide: false,
        index: 4,
        type: 'date',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 200,
      },
    ];

    setHeaderList(updatedHeaders);
  };

  useEffect(() => {
    let filteredList: any[] = accountList;

    headerList?.map((header) => {
      const auxList = filteredList ?? accountList;

      switch (header.inlineFilterName) {
        case AccountFilter.Transaction:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((account: any) => {
              return header?.inlineFilterSelected?.some((selectedAccount) => {
                return selectedAccount === account.transactionTypeId;
              });
            });
          }
          break;
      }
    });

    setAccountFilteredList(filteredList);
  }, [headerList, accountList]);

  const updateMapItems = (
    accounts: TransMapAccount[],
    id: string,
    transactionTypeId: string | null,
    transactionTypeName: string | null
  ) => {
    const newDateMapped = new Date().toDateString();

    const newMapItems = accounts.reduce((newArray: any[], account) => {
      const newAccount: TransMapAccount = { ...account };

      if (account.id === id) {
        newAccount.transactionTypeName = transactionTypeName;
        newAccount.transactionTypeId = transactionTypeId;
        newAccount.dateMapped = newDateMapped;
      }
      newArray.push(newAccount);

      return newArray;
    }, []);

    return newMapItems;
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleTransTypeChange = (accounts: string[], selectedId: string) => {
    if (accountList && transactionList) {
      let newAccountList = [...accountList];
      let totalAccounts: string[] = [];
      let transactionTypeId: string | null;
      let transactionTypeName: string | null;

      switch (selectedId) {
        case '+':
          setIsModalOpen(true);
          break;
        default: {
          if (selectedId === '0') {
            transactionTypeId = null;
            transactionTypeName = null;
          } else {
            transactionTypeId = selectedId;
            const arkTransType = transactionList.find(
              (transaction) => transaction.id === selectedId
            );

            if (arkTransType) {
              transactionTypeName = arkTransType?.name;
            }
          }

          totalAccounts = accounts.reduce((newArray: string[], id: string) => {
            newArray.push(id);
            return newArray;
          }, []);

          totalAccounts = Array.from(new Set(totalAccounts));

          totalAccounts.map((id: string) => {
            const updatedRow = accountList.find((row) => row.id === id);

            if (updatedRow)
              newAccountList = updateMapItems(
                newAccountList,
                updatedRow.id,
                transactionTypeId,
                transactionTypeName
              );
          });

          setAccountList(newAccountList);
          setIsDirty(true);
          setBulkMappingSelection('');
          const text =
            totalAccounts.length > 1 ? 'accounts have' : 'account has';

          informationAlert(
            `${totalAccounts.length} ${text} been updated.`,
            'success'
          );

          break;
        }
      }
    }
  };

  const handleSaveMapping = async () => {
    if (accountList) {
      const mappings = accountList.map((account: TransMapAccount) => {
        return {
          id: account.id,
          transactionTypeId: account.transactionTypeId,
        };
      });

      const data = {
        glPlatform: LedgerType.QUICKBOOKS_ONLINE,
        fundId,
        mappings,
      };

      try {
        setIsSending(true);
        await postMapsForFund(data);
        informationAlert(SAVE_MAPPING_SUCCESS, 'success');
        setIsDirty(false);
      } catch (e) {
        informationAlert(SAVE_MAPPING_ERROR, 'error');
      } finally {
        setIsSending(false);
      }
    }
  };

  const handleFilter = (filterName: AccountFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: AccountFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === AccountFilter.Transaction) {
          inlineFilterSelected = selectedTransactionList;
        }

        return {
          ...header,
          hide:
            header.field === field &&
            !(!header.hide && activeFields.length <= 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(
        updatedHeaders.sort((item1, item2) => item1.index - item2.index)
      );
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);

    newValue === 0
      ? setFilterModel(filterModelUnmappedOnly)
      : setFilterModel(filterModelAll);
  };

  const handleNewTransactionSave = async () => {
    await fetchTransTypes();
    informationAlert(SAVE_NEW_TRANSACTION_SUCCESS, 'success');
  };

  const handleSyncGL = async (e: any) => {
    try {
      setIsLoading(true);
      const response = await syncAccountsForFund(
        fundId,
        LedgerType.QUICKBOOKS_ONLINE
      );

      setAccountsResponse(response.items);
      informationAlert(SYNC_GL_SUCCESS, 'success');
    } catch (e) {
      informationAlert(SYNC_GL_ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    accountList,
    accountFilteredList,
    headerList,
    activeHeaderFields,
    bulkMappingSelection,
    clientId,
    filterModel,
    fundId,
    fundName,
    glConnectionDate,
    glConnectionPlatform,
    glConnectionUsername,
    readonly,
    handleNewTransactionSave,
    handleSaveMapping,
    handleSyncGL,
    handleTabChange,
    handleTransTypeChange,
    hasMappingAdmin,
    isAlertToUnlockOpen,
    isDirty,
    isLoading,
    isModalOpen,
    isScreenLocked,
    isSending,
    progress,
    search,
    searchOptions,
    handleSearch,
    showSuggestionPopover,
    setShowSuggestionPopover,
    selectedFund,
    selectionModel,
    setBulkMappingSelection,
    setFilterModel,
    setIsAlertToUnlockOpen,
    setIsModalOpen,
    setIsScreenLocked,
    setSelectedFund,
    setSelectionModel,
    tab,
    transBuckets,
    transactionList,
    handleFilter,
    handleUpdateHeader,
  };
};
