import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { NameIdPair } from "../../../utils/types/transaction.type";
import { getStatuses } from "../../uploadHistory.service";

export const useUploadHistoryStatusEffect = () => {
  const [statuses, setStatuses] = useState<NameIdPair[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchStatuses = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getStatuses();

      if (isCanceled?.()) return;

      setStatuses(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting upload history statuses", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchStatuses(isCanceled);
  }, []);

  return {
    statuses,
    fetchStatuses,
    loading,
    setStatuses
  };
};
