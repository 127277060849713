import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../../core/context/appContextProvider';
import { setDocControlPermissions } from '../../../../services/documents.service';
import { FolderContentRequestPayload } from '../../../../utils/types/documents.type';
import { DocumentsState } from '../../types/hook.types';

type Tags = {
  permissions: {
    downloadEnabled?: string;
    printEnabled?: string;
    copyEnabled?: string;
  };
  archivePolicy: {
    archivePolicy?: string;
    archiveOn?: Date;
  };
};

type File = {
  id: string;
  type: 'folder' | 'file';
  fileType: string;
};

const DOC_CONTROL_OPTIONS = [
  { id: 'keepExisting', label: 'Keep Existing' },
  { id: 'on', label: 'On' },
  { id: 'off', label: 'Off' },
];

const DOC_ARCHIVE_OPTIONS = [
  { id: 'KEEP_EXISTING', label: 'Keep Existing' },
  { id: 'RESPECT_FOLDER', label: 'Respect Folder Settings' },
  { id: 'DISABLE_ARCHIVE', label: 'Disable Archiving' },
  { id: 'RESPECT_FILE', label: 'Set Custom Date' },
];

enum ERROR_MESSAGE {
  INVALID = 'Valid date is required',
  PAST_MIN_DATE = 'Date must be after January 1st, 1970',
}

const useEditPanel = (state: DocumentsState | null, props: any) => {
  const { informationAlert } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const filesOnly = props.files.filter((file: File) => file.type === 'file');
  const fileCount = filesOnly.length;
  const pdfFilesOnly = filesOnly.filter(
    (file: File) => file.fileType === 'pdf'
  );
  const pdfFileCount = pdfFilesOnly.length;
  const minDate = new Date('01/01/1970');

  useEffect(() => {
    onClose();
  }, [state?.cursor]);

  const { handleSubmit, control, reset, setValue, trigger, watch } =
    useForm<Tags>({
      defaultValues: {
        permissions: {
          downloadEnabled: 'keepExisting',
          printEnabled: 'keepExisting',
          copyEnabled: 'keepExisting',
        },
        archivePolicy: {
          archivePolicy: 'KEEP_EXISTING',
          archiveOn: new Date(),
        },
      },
    });

  const watchArchivePolicy = watch('archivePolicy.archivePolicy');

  useEffect(() => {
    if (watchArchivePolicy !== 'RESPECT_FILE') {
      setValue('archivePolicy.archiveOn', new Date());
      trigger('archivePolicy.archiveOn');
    }
  }, [watchArchivePolicy]);

  const resetForm = () => {
    setLoading(false);
    reset();
  };

  const onClose = () => {
    if (loading) return;
    resetForm();
    props.handleClose();
  };

  const validateDate = (value: Date | undefined) => {
    if (!value) {
      return ERROR_MESSAGE.INVALID;
    }

    const selectedDate = new Date(value);

    if (
      !(value instanceof Date && !isNaN(value.getTime())) ||
      selectedDate < new Date('01/01/1900')
    ) {
      return ERROR_MESSAGE.INVALID;
    } else if (selectedDate < minDate) {
      return ERROR_MESSAGE.PAST_MIN_DATE;
    }

    return true;
  };

  const handleSave = async (data: any) => {
    setLoading(true);

    const fileIds =
      data.archivePolicy.archivePolicy === 'KEEP_EXISTING'
        ? pdfFilesOnly.map((file: File) => file.id)
        : filesOnly.map((file: File) => file.id);

    const permissionsData: {
      [key: string]: boolean | string;
    } = {};

    for (const key in data.permissions) {
      const value = data.permissions[key];

      if (value === 'on') {
        permissionsData[key] = true;
      } else if (value === 'off') {
        permissionsData[key] = false;
      }
    }

    const archivePolicyData: {
      archivePolicy: boolean | null;
      archiveOn: string | null;
    } = { archivePolicy: null, archiveOn: null };

    if (data.archivePolicy.archivePolicy !== 'KEEP_EXISTING') {
      archivePolicyData.archivePolicy = data.archivePolicy.archivePolicy;
      if (data.archivePolicy.archivePolicy === 'RESPECT_FILE') {
        const archiveDate: Date = data.archivePolicy.archiveOn;
        const year = archiveDate.getFullYear();
        const month = String(archiveDate.getMonth() + 1).padStart(2, '0');
        const day = String(archiveDate.getDate()).padStart(2, '0');

        archivePolicyData.archiveOn = `${year}-${month}-${day}`;
      }
    }

    try {
      await setDocControlPermissions(
        fileIds,
        permissionsData,
        archivePolicyData
      );
    } catch (error) {
      informationAlert('Error setting document permissions', 'error');
    } finally {
      setLoading(false);
      resetForm();
      props.handleClose();
      props.reload();
    }
  };

  return {
    loading,
    fileCount,
    pdfFileCount,
    DOC_CONTROL_OPTIONS,
    DOC_ARCHIVE_OPTIONS,
    minDate,
    watchArchivePolicy,
    control,
    validateDate,
    handleSubmit,
    handleSave,
    onClose,
  };
};

export default useEditPanel;
