import { cloneDeep } from 'lodash';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';

import ExportIcon from '../../../assets/images/icons/icon_export.svg';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import { AppContext } from '../../../core/context/appContextProvider';
import { exportFinancialStatements } from '../../../services/arkGL.service';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import downloadFile from '../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { NumberFormat } from '../../../utils/helpers/format.helper';
import { DataGridColDef, ImageItem } from '../../../utils/types/listItems';
import { SoiGridRow } from '../../fund/scheduleOfInvestments/ScheduleOfInvestments.types';
import { ButtonBox } from './FinancialStatements.style';
import { FsViews } from './FinancialStatementsMain.hooks';

type Props = {
  id: string;
  hidden: boolean;
  headers?: DataGridColDef[];
  data?: Record<string, any>[];
  soiHeaders?: DataGridColDef[];
  soiData?: SoiGridRow[];
  fsView?: string;
  params?: any;
  funds?: any;
  accountList?: any;
  ledgerList?: any;
  setIsLoading: (isLoading: boolean) => void;
  exportPackage: () => void;
  packageExportEnabled: boolean;
};

type headerType = {
  label: string;
  key: string;
};

const FsExportButton: React.FC<Props> = ({
  id,
  hidden = false,
  headers,
  data,
  soiHeaders,
  soiData,
  fsView,
  params,
  funds,
  accountList,
  ledgerList,
  setIsLoading,
  exportPackage,
  packageExportEnabled,
}: Props): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const { state, informationAlert } = useContext(AppContext);

  const groupBulkActions: ImageItem[] = [
    {
      id: 'exportSelected',
      text: 'Export View',
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
    {
      id: 'exportPackage',
      text: 'Export Package',
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
  ];

  const singleBulkActions: ImageItem[] = [
    {
      id: 'exportSelected',
      text: 'Export View',
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
  ];

  const handleExport = (selectedOption: string) => {
    if (selectedOption === 'exportSelected') {
      const fundParams = formatFunds(funds);
      const ledgerParams = ledgerNames();
      const accountParams = accountNames();
      const statusParams = updateStatusParams();

      const payloadData: any = {
        funds: fundParams,
        glNames: ledgerParams,
        accounts: accountParams,
        status: statusParams,
        startDate: params.startDate,
        endDate: params.endDate,
        sheets: [],
        balanceSheetData: {},
        incomeStatementData: {},
        realizedSoiData: {},
        unrealizedSoiData: {},
      };

      switch (fsView) {
        case FsViews.balanceSheet:
          payloadData.sheets.push('BALANCE_SHEET');
          payloadData.balanceSheetData = {
            dataGridColDefs: headers,
            gridRows: data,
          };
          break;
        case FsViews.incomeStatement:
          payloadData.sheets.push('INCOME_STATEMENT');
          payloadData.incomeStatementData = {
            dataGridColDefs: headers,
            gridRows: data,
          };
          break;
        case FsViews.unrealizedSOI:
          payloadData.sheets.push('UNREALIZED_SOI');
          payloadData.unrealizedSoiData = {
            dataGridColDefs: soiHeaders,
            gridRows: soiData,
          };
          break;
        case FsViews.realizedSOI:
          payloadData.sheets.push('REALIZED_SOI');
          payloadData.realizedSoiData = {
            dataGridColDefs: soiHeaders,
            gridRows: soiData,
          };
          break;
      }

      exportFsData(payloadData);
    }

    if (selectedOption === 'exportPackage') {
      exportPackage();
    }
  };

  const exportFsData = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await exportFinancialStatements(data);

      const bufferedResponse = await response.arrayBuffer();

      if (response) {
        const reportName = getReportName();
        const formattedDate = DateTimeFormat.getFormattedDate(new Date());

        downloadFile(
          bufferedResponse,
          reportName + " " + formattedDate,
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }
    } catch (e) {
      informationAlert('error exporting financial statements', 'error');
    }
    setIsLoading(false);
  };

  const getReportName = (): string => {
    switch (fsView) {
      case FsViews.balanceSheet:

        return "FS Balance Sheet";
      case FsViews.incomeStatement:

        return "FS Income Statement";
      case FsViews.unrealizedSOI:

        return "FS Unrealized SOI";
      case FsViews.realizedSOI:

        return "FS Realized SOI";
      case FsViews.statementOfChangesInPartners:

        return "FS SCPC";
      case FsViews.statementOfCashFlows:

        return "FS SCF";
    }

    return "";
  };

  const updateStatusParams = () => {
    const statusValues: string[] = [];

    params.glStatus.map((status: string) => {
      if (status === 'DRAFT') statusValues.push('Draft');
      if (status === 'POSTED') statusValues.push('Posted');
    });

    return statusValues;
  };

  const formatFunds = (fundList: any) => {
    const paramsList: any = [];

    fundList.map((x: any) => {
      paramsList.push({
        fundId: x.fund.id,
        fundName: x.fundName,
      });
    });

    return paramsList;
  };

  const ledgerNames = () => {
    const nameValues: string[] = [];

    params.ledgerIDs.map((id: string) => {
      ledgerList.map((ledger: any) => {
        if (ledger.id === id) nameValues.push(ledger.name);
      });
    });

    return nameValues;
  };

  const accountNames = () => {
    const nameValues: string[] = [];

    params.accounts.map((id: string) => {
      accountList.map((account: any) => {
        if (account.id === id) nameValues.push(account.name);
      });
    });

    return nameValues;
  };

  return (
    <ButtonBox>
      <SplitButton
        id={'btn_bulk_action_options'}
        options={packageExportEnabled ? groupBulkActions : singleBulkActions}
        hidden={hidden}
        handleOptionClick={handleExport}
        ariaLabelMessage="Select bulk action option"
      />
    </ButtonBox>
  );
};

export default React.memo(FsExportButton);
