import { GlEntry, GLEntryCreateParams, GLEntryV2, InvestorCommitment, SubmitToPcap, SubmitToPcapResponse, SubmitToPcapTransaction, TransactionTypeQuarterlySum, Workbook, WorkbookFrequencyType, WorkbookLockStatus } from "../pages/fund/allocations/workbook.type";
import { DateTimeFormat } from "../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../utils/helpers/quarter.helper";
import { Transaction, TransactionParams } from "../utils/types/transaction.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";
import { getClientTransactionMapping } from "./client.service";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

const apiClientV2 = () => ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);

export const getBaseUrl = () => {
  return apiClient().BaseUrl;
};

export const getBearerToken = () => {
  return apiClient().BearerToken;
};


export const getWorkbookById = async (id: string) => {
  const workbook = await apiClient().get<Workbook>(`workbooks/${id}`);

  if(workbook.startQuarterNumber) {
    //@ts-ignore  comes in as string so nned to set as int
    workbook.startQuarterNumber = parseInt(workbook.startQuarterNumber);
  }

  if(workbook.startQuarterYear) {
    //@ts-ignore  comes in as string so nned to set as int
    workbook.startQuarterYear = parseInt(workbook.startQuarterYear);
  }

  workbook.investorRows.forEach(ir => {
    //@ts-ignore  comes in as string so nned to set as int
    ir.rowType = parseInt(ir.rowType);
  });

  return workbook;
};

export const deleteWorkbookById = async (id: string) => {
  const workbook = await apiClient().delete(`workbooks/${id}`);

  return workbook;
};

export const getWorkbooksByFund = async (fundId: string) => {
    const workbooks = await apiClient().get<Workbook[]>(`workbooks/funds/${fundId}`);

    return workbooks;
};

export const getWorkbookLock = async (id: string) => {
  const url = `/workbooks/${id}/lock`;
  const response = await apiClient().get<WorkbookLockStatus>(url);

  return response;
};

export const lockWorkbook = async (id: string) => {
  const url = `/workbooks/${id}/lock`;

  await apiClient().post(url, { workbookId: id } );
};

export const unlockWorkbook = async (id: string) => {
  const url = `/workbooks/${id}/lock`;

  await apiClient().delete(url);
};


export const getWorkbookFileById = async (id: string) => {
  const url = `${apiClient().BaseUrl}workbooks/${id}/file`;
  const token = apiClient().BearerToken;
  const init = {
      "headers": {
          "authorization": `${token}`,
          "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      "method": "GET"
  };

  const file = await fetch(url, init);

  return file.body;
};

export const saveWorkbook = async (workbook:Workbook, blob: Blob) => {
  let wbId: string;

  if(!workbook.id) {
    const response = await apiClient().post<{id:string}>(`workbooks`, workbook);

    wbId = response.id;
  } else {
    wbId = workbook.id;

    await apiClient().put(`workbooks/${wbId}`, workbook);
  }

  const updatedWorkbook = await getWorkbookById(wbId);

  const fileName = `${wbId}.xlsx`;
  const formData = new FormData();
  const fileBlob = new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
 
  formData.append("file", fileBlob, fileName);

  await apiClient().post(`workbooks/${wbId}/files`, formData);

  return updatedWorkbook;
};

export const submitToPcap = async (transDate: string, submitToPcapTrans: SubmitToPcap) => {
  const path = `v2/transactions/workbook/pcap/?date=${transDate}`;
  const response = await apiClient().post<SubmitToPcapResponse[]>(path, submitToPcapTrans);

  return response;
};

export const setTransactionColumnLock = async (workbookId: string, typeId: string, quarterNum: number) => {
  const path = `v2/transactions/workbook/${workbookId}/column/lock?typeId=${typeId}&quarterNum=${quarterNum}&isLock=false`;
  const response = await apiClient().post<any>(path);

  return response;
};

export const getInvestorCommitmentList = async (fundId: string, quarterNumber: number, quarterYear: number, transactiontypes: string[]) => {
  const path = `investors/fund/total/committments`;
  const quarterName = `${quarterYear} Q${quarterNumber}`;
  const investorList = await apiClient().post<any>(path, { quarterName: quarterName, transactiontypes: transactiontypes, fundId: fundId });

  return investorList.items as InvestorCommitment[];
};

export const getGlEntries = async (ledgerIds: string[], summaryType: WorkbookFrequencyType, year: number) => {
  if(summaryType === "") throw 'Invalid workbook frequency type';

  const summaryTypeParam = summaryType === 'BY_QUARTER' ? 'QUARTERLY' : 'YEARLY';

  try {
    const url = `v2/ledgers/journal-entries/summary`;

    const params = {
      "ledgerIds": ledgerIds,
      "year": year,
      "summaryType": summaryTypeParam
    };

    const glEntries = await apiClient().post<any>(url, params);

    return glEntries.items as GlEntry[];
  } catch(e: any) {
    return e.message as string;
  }
};

export const getPITDSummaryValues = async (fundId: string, thruDate: Date) => {
  const dateStr = `${thruDate.getMonth()+1}-${thruDate.getDate()}-${thruDate.getFullYear()}`;
  const path = `investors/${fundId}/all/transactions/${dateStr}/sum`;
  const response = await apiClient().get<any>(path);

  return response.items;
};

export const getTransactions = async (fundId: string, quarterNumber: number|undefined, quarterYear: number, investorIDs: string[], transactionTypeIDs: string[]) => {
  let fromDateFilter: string;
  let toDateFilter: string;

  if(!transactionTypeIDs?.length || !investorIDs?.length) return [];

  if(quarterNumber) {
    const quarterPeriod = getQuarterStartEndDates(quarterNumber, quarterYear);

    fromDateFilter = DateTimeFormat.isoDateString(quarterPeriod.startDate);
    toDateFilter = DateTimeFormat.isoDateString(quarterPeriod.endDate);
  } else {
    fromDateFilter = DateTimeFormat.isoDateString(new Date(quarterYear, 0, 1));
    toDateFilter = DateTimeFormat.isoDateString(new Date(quarterYear, 11, 31));
  }

  const params: TransactionParams = {
    offset: 0,
    pageSize: 20,
    fromDateFilter: fromDateFilter,
    toDateFilter: toDateFilter,
    fundFilter: [fundId],
    investorFilter: investorIDs,
    transTypes: transactionTypeIDs
  };

  const transtypeSums:TransactionTypeQuarterlySum[] = [];
  // Same transaction can come in from different pages so we need to keep track so we're not counting multi times
  const transIdTracker = new Set<string>(); 

  // eslint-disable-next-line
  while(true) {
    // eslint-disable-next-line
    const response = await apiClient().post<Transaction[]>('transactions/page', params);

    if(response.length === 0)
      break;

    response.forEach(t => {
      const transTypeSum = transtypeSums.find(tts => tts.transTypeId === t.txnType.id && tts.investorId === t.investor.id);

      if(transIdTracker.has(t.id)) return;

      transIdTracker.add(t.id);

      if(transTypeSum) {
        transTypeSum.sum += t.amount as number;
      } else {
        transtypeSums.push({
          transTypeId: t.txnType.id,
          investorId: t.investor.id,
          sum: t.amount as number
        });
      }

    });

    params.offset += params.pageSize;
  }

  return transtypeSums;
};

export async function getTranactionTypeColumns(clientId: string) {
  const clientTransMapping = await getClientTransactionMapping(clientId);

  const transTypes = clientTransMapping.rows.filter(r => !r.useMetric);
  const clientTransTypeColumns = clientTransMapping.columns;
  const clientTransValueCol = clientTransTypeColumns.find(c => c.code === 'VALUE');
  const clientTransValueIndex = clientTransValueCol ? clientTransValueCol.index : 0;

  transTypes.forEach(row => {
      const metricSign = row?.values ? row.values[clientTransValueIndex] : 0;

      row.metricSign = String(metricSign);
  });

  return transTypes;
}

/**
 * @desc  Create new GL entries.
 * @param fundId string
 * @param entry GLEntryV2
 * @returns void
 */
export const createGlEntries = async (fundId: string, entry: GLEntryV2) => {
  try {
    const body: GLEntryCreateParams = {
      fundId,
      entry
    };

    const apiPath = "ledgers/journal-entries";

    return await apiClientV2().post<any[]>(apiPath, body);

  } catch(e: any) {
    throw new Error(e);
  }
};