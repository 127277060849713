import { Icon, styled } from "@mui/material";

export const StyledImage = styled("img")(() => ({
  padding: "2px",
}));

export const StyledIcon = styled(Icon)<{
    usecursorpointer: string,
    hide: string,
}>`
  display: contents;
  cursor: ${(props) => {
    return props.usecursorpointer === 'true' ? 'pointer' : 'default';
  }};
  visibility: ${(props) => {
    return props.hide === 'true' ? 'hidden' : 'visible';
  }};
`;
