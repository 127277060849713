export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const DELETE_USER_ERROR = "Error in deleting user.";
export const DELETE_USER_SUCCESS = "User deleted successfully.";
export const CREATE_USER_ERROR = "Error in creating user.";
export const CREATE_USER_SUCCESS = "User created successfully.";
export const UPDATE_USER_ERROR = "Error in updating user.";
export const UPDATE_USER_SUCCESS = "User updated successfully.";
export const RESEND_INVITATION_ERROR = "Error in resending invitation.";
export const RESEND_INVITATION_SUCCESS = "Invitation resent successfully.";

export const SuperAdminRoles = ["Super Admin", "Basic Admin Navigation", "Fund User - Admin", "Ark Client - Admin"];
export const AdminRoles = ["Basic Admin Navigation", "Fund User - Admin", "Ark Client - Admin"];

export const STATUS = [{
  id: Status.ACTIVE,
  name: 'Active',
},{
  id: Status.INACTIVE,
  name: 'Inactive',
}];

export const NO_CLIENT = 'No Client';
