import { FormControlLabel, Stack, styled } from "@mui/material";

export const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  border: '1px solid #c2c2c2',
  borderRadius: 6,
  marginLeft: 0,
  paddingRight: theme.spacing(2),
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.error.main,
  padding: 8,
  paddingLeft: 0,
  fontWeight: 600,
  '& .MuiTypography-root': {
    fontSize: 14,
    fontWeight: 600,
  }
}));