import { Delete, Visibility } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router';

import ExportIcon from '../../assets/images/icons/icon_export.svg';
import { StringCell } from '../../components/DataGrid/DataGrid.styles';
import Switch from '../../components/Switch/Switch';
import { AppContext } from '../../core/context/appContextProvider';
import RoutingPaths from '../../core/routing/routingPaths';
import useRole from '../../core/routing/useRole';
import {
  deleteClient,
  updateClientStatus,
} from '../../services/client.service.v2';
import { useClientsEffect } from '../../services/hooks/useClientsEffect/useClientsEffect.hooks';
import { M_DASH_UNICODE } from '../../utils/constants/constants';
import { GENERIC_ERROR_MESSAGE } from '../../utils/constants/text.constants';
import {
  Client,
  ClientAction,
  CRUDType,
  SelectedClient,
} from '../../utils/types/client.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../utils/types/listItems';
import { ScopeRole } from '../../utils/types/user.type';
import { ClientTabs } from '../clientDetails/constants';
import { ArkClientBox, ArkClientBoxIcon, SwitchLabel } from './Clients.styles';

type segregatedClients = {
  clients: Record<any, any>;
  arkClientTags: Record<any, any>[];
};

const clientLevelHeaderList: DataGridColDef[] = [
  {
    field: 'name',
    headerName: 'Client Name',
    hide: false,
    index: 1,
    renderCell: (params) => {
      const name = `${params?.row?.name ?? ''}`;

      return (
        <StringCell>
          <Typography>{name || M_DASH_UNICODE}</Typography>
        </StringCell>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
  },
];

const ACTION_COLUMN = {
  field: 'action',
  headerName: 'Columns',
  hide: false,
  hideable: false,
  type: 'action',
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 100,
};
const folderLevelHeaderList: DataGridColDef[] = [
  {
    field: 'ark_client_tag',
    headerName: 'Ark Client Tags',
    hide: false,
    index: 1,
    sortable: true,
    renderCell: (params) => {
      const name = params?.row?.ark_client_tag ?? '';

      return (
        <ArkClientBox>
          <ArkClientBoxIcon />
          <Typography variant="body2">{name}</Typography>
        </ArkClientBox>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
    width: 300,
  },
  {
    index: 2,
    ...ACTION_COLUMN,
  },
];

const initialSelectedClient: SelectedClient = {
  client: undefined,
  type: undefined,
};

export const useClients = () => {
  const [list, setList] = useState<Record<any, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClientArkTag, setSelectedClientArkTag] = useState<
    string | undefined
  >();
  const [clientSelectionModel, setClientSelectionModel] = useState<string[]>(
    []
  );
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [search, setSearch] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<SelectedClient>(
    initialSelectedClient
  );
  const [toBeDeleted, setToBeDeleted] = useState<Client | undefined>();
  const [statusUpdateClient, setStatusUpdateClient] = useState<
    Client | undefined
  >();
  const [segregatedClients, setSegregatedClients] = useState<segregatedClients>(
    {
      clients: {},
      arkClientTags: [],
    }
  );
  const history = useHistory();
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const { informationAlert, onClientChange } = useContext(AppContext);
  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const { hasRole: isArkClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const {
    clients,
    loading: loadingClients,
    updateClients,
  } = useClientsEffect();

  const toggleStatus = useCallback((client: Client) => {
    setStatusUpdateClient(client);
  }, []);

  const discardStatusUpdate = () => {
    setStatusUpdateClient(undefined);
  };

  const updateStatus = async () => {
    setStatusUpdateClient(undefined);
    if (!statusUpdateClient) {
      return;
    }
    const { id, active: currentActive } = statusUpdateClient;

    try {
      setLoading(true);
      const active = !currentActive;

      await updateClientStatus(id!, active);
      const updatedClients = clients.map((client) => {
        if (client.id === id) {
          client.active = active;
        }

        return client;
      });

      updateClients(updatedClients);
      setLoading(false);
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    }
  };

  const handleOnView = useCallback(
    async (clientId: string, client: any) => {
      if (!selectedClientArkTag) {
        setSearch('');
        setSelectedClientArkTag(client.ark_client_tag);
      } else {
        await onClientChange(clientId);
        setSelectedClient({
          client,
          type: CRUDType.Edit,
        });
        history.push(
          `/clients/${client?.id}/${client?.arkClientTag}/${ClientTabs.ClientDetails}`
        );
      }
    },
    [selectedClientArkTag, history]
  );

  const clientHeaderListWithActiveToggle = useMemo(() => {
    return [
      ...clientLevelHeaderList,
      {
        field: 'active',
        headerName: 'Status',
        hide: false,
        index: 2,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <StringCell>
              <SwitchLabel
                control={
                  <Switch
                    label="Status switch"
                    id={`switch_status_${params.row?.id}_switch`}
                    checked={params.row?.active}
                    onChange={() => toggleStatus(params.row)}
                  />
                }
                label={params.row?.active ? 'On' : 'Off'}
              />
            </StringCell>
          );
        },
        type: 'string',
        align: 'left' as GridAlignment,
      },
      {
        index: 3,
        ...ACTION_COLUMN,
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box justifyContent="center" display="flex" width="100%">
              <Button
                id={`${params?.row?.id}_view_button`}
                variant="text"
                disableElevation
                startIcon={<Visibility />}
                onClick={() => handleOnView(params.row.id, params.row)}
              >
                View
              </Button>
              {isSuperAdmin && (
                <Button
                  id={`${params?.row?.id}_delete_button`}
                  variant="text"
                  disableElevation
                  startIcon={<Delete />}
                  onClick={() => setToBeDeleted(params.row)}
                >
                  Delete
                </Button>
              )}
            </Box>
          );
        },
      },
    ];
  }, [toggleStatus, handleOnView, isSuperAdmin]);

  const getFilteredList = useCallback(
    (list: Client[], searchString?: string) =>
      list?.filter(({ name }: Client) =>
        name?.toLowerCase().includes(searchString?.toLowerCase() || '')
      ),
    []
  );

  //This use effect processes the list of clients, list of header and filters them based on the search string, selected client and selected tab.
  useEffect(() => {
    let currentList: Record<any, any>[] = [];
    let currentHeaderList: Array<DataGridColDef> = [];

    if (isSuperAdmin) {
      if (!selectedClientArkTag) {
        if (search.length > 0) {
          currentList = segregatedClients.arkClientTags.filter(
            ({ ark_client_tag }) =>
              ark_client_tag.toLowerCase().includes(search.toLowerCase())
          );
        } else {
          currentList = [...segregatedClients.arkClientTags];
        }
        currentHeaderList = [...folderLevelHeaderList];
      } else if (selectedClientArkTag) {
        if (search.length > 0) {
          currentList = getFilteredList(
            segregatedClients.clients[selectedClientArkTag],
            search
          );
        } else {
          currentList = [
            ...(segregatedClients.clients[selectedClientArkTag] || []),
          ];
        }
        currentHeaderList = [...clientHeaderListWithActiveToggle];
      }
    } else if (isArkClientAdmin) {
      //If ark client admin, show tabs with individual client users based on logged in user
      const clientTag = segregatedClients.arkClientTags?.[0]?.ark_client_tag;

      currentList = getFilteredList(
        segregatedClients.clients[clientTag],
        search
      );
      currentHeaderList = [...clientHeaderListWithActiveToggle];
      setSelectedClientArkTag(clientTag);
    }
    setList(currentList);
    setHeaderList(currentHeaderList);
  }, [segregatedClients, search, selectedClientArkTag]);

  // This use effect processes the entire list of client and divides them into clients and super admins.
  useEffect(() => {
    const segregatedClients = clients.reduce(
      (processedClients: Record<string, any>, client) => {
        const value = client['arkClientTag'] || 'No Client';

        // eslint-disable-next-line no-param-reassign
        processedClients[value] = (processedClients[value] || []).concat(
          client
        );
        return {
          ...processedClients,
        };
      },
      {}
    );
    const clientTags = Object.keys(segregatedClients)
      .sort()
      .map((client, id) => ({
        ark_client_tag: client,
        id: id + 1,
      }));

    setSegregatedClients({
      clients: segregatedClients,
      arkClientTags: clientTags,
    });
  }, [clients]);

  const [csvData, setCsvData] = useState<Record<any, any>[]>([]);

  const csvHeaders = [
    { label: 'Client Name', key: 'name' },
    { label: 'SubDomain', key: 'subdomain' },
    { label: 'Ark Client Tag', key: 'arkClientTag' },
    { label: 'Allocation Locked', key: 'allocationLocked' },
    { label: 'GL Locked', key: 'glLocked' },
    { label: 'SOI Locked', key: 'soiLocked' },
    { label: 'STFP Locked', key: 'sftpLocked' },
    { label: 'MFA Settings', key: 'mfaSettings' },
    { label: 'Visualization Locked', key: 'visualizationLocked' },
    { label: 'arkGL Locked', key: 'bankLocked' },
    { label: 'Banks Locked', key: 'arkGlLocked' },
  ];

  const handleExport = (data: Record<any, any>[]) => {
    const csvDataFormat = data.map((client) => {
      if (client.mfaSettings) {
        return {
          ...client,
          mfaSettings: JSON.stringify(client.mfaSettings)
            .replace(/[{}\\"]/g, '')
            .replace(/,/g, ', ')
            .replace(/:/g, ': '),
        };
      }

      return client;
    });

    setCsvData(csvDataFormat);
  };

  const resetCsvData = () => {
    setCsvData([]);
  };

  useEffect(() => {
    if (csvData.length) csvLinkRef?.current?.link.click();
  }, [csvData]);

  const handleBulkActions = (actionId: ClientAction) => {
    switch (actionId) {
      case ClientAction.ExportAll:
        //SA on folder view exporting all clients from all folders(ark_client_tags)
        if (isSuperAdmin && !selectedClientArkTag) {
          handleExport(clients);
          //ACA or [SA w/ selectedClientArkTag] exporting all clients on page
        } else {
          handleExport(list);
        }
        break;
      case ClientAction.ExportSelected:
        //SA on folder view exporting all clients from selected folders(ark_client_tags)
        if (isSuperAdmin && !selectedClientArkTag) {
          const exportSelectedACTags = segregatedClients.arkClientTags
            .filter((arkClientTag) =>
              clientSelectionModel.includes(arkClientTag.id)
            )
            .flatMap((tag) => segregatedClients.clients[tag.ark_client_tag]);

          handleExport(exportSelectedACTags);

          //ACA or [SA w/ selectedClientArkTag] exporting selected clients only
        } else {
          const selectedExportClients = list.filter((client) =>
            clientSelectionModel.includes(client.id)
          );

          handleExport(selectedExportClients);
        }
        break;
    }
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
    }
  };

  const onSearch = (search: string) => {
    setSearch(search);
  };

  const handleClose = () => {
    history.push(RoutingPaths.AppDashboard);
  };

  const onDeleteCancel = () => {
    setToBeDeleted(undefined);
  };

  const onDeleteConfirm = async () => {
    if (!toBeDeleted) {
      return;
    }
    setLoading(true);
    try {
      const { id } = toBeDeleted;

      await deleteClient(id!);

      const updatedList = clients?.filter((client: Client) => client.id !== id);

      updateClients(updatedList);
      setToBeDeleted(undefined);
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setLoading(false);
    }
  };

  //Export button actions (export all/export selected) clients only
  const bulkActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: ClientAction.ExportSelected,
      text: `Export Selected (${clientSelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: ClientAction.ExportAll,
      text: 'Export All Clients',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    if (clientSelectionModel?.length > 0) actions.push(exportSelected);

    actions.push(exportAll);

    return actions;
  }, [clientSelectionModel]);

  return {
    clientSelectionModel,
    setClientSelectionModel,
    headerList,
    loadingClients: loadingClients,
    loading,
    list,
    bulkActions,
    handleBulkActions,
    csvLinkRef,
    csvHeaders,
    csvData,
    resetCsvData,
    handleUpdateHeader,
    search,
    onSearch,
    handleOnView,
    selectedClientArkTag,
    setSelectedClientArkTag,
    statusUpdateClient: statusUpdateClient,
    discardStatusUpdate,
    updateStatus,
    handleClose,
    onDeleteCancel,
    onDeleteConfirm,
    toBeDeleted,
    isSuperAdmin,
  };
};
