import { Button, Stack, Typography } from "@mui/material";

import DataGrid from "../../../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../../../components/Modal/ConfirmationDialog";
import FullScreenModal from "../../../../../components/Modal/FullScreenModal/FullScreenModal";
import ProgressModal from "../../../../../components/Progress/ProgressModal/ProgressModal";
import { AllocationsSheetManager } from "../sheets/allocations/AllocationsSheet.Manager";
import { useGlConnect } from "./glConnect.hooks";
import { MainStyledContainer, MappingGridStyle } from "./glConnect.styles";

type GlConnectProps = {
    allocationsSheetManager: AllocationsSheetManager,
    onClose: () => void
};

export const GlConnect: React.FC<GlConnectProps> = ({ 
    allocationsSheetManager,
    onClose
}) => {
    const {
        isLoading,
        headerList, 
        ledgers,
        selectedLedgersIDs, handleSetSelectedLedgers,
        handleConnect,
        invalidFormDataError,
        showGlConnectError, setShowGlConnectError
    } = useGlConnect(allocationsSheetManager, onClose);

    const showErroMessage = !!invalidFormDataError && !!selectedLedgersIDs?.length;

    function mainDialogContent() {
        return (
            <MainStyledContainer id="main_container">
                <Typography id="title" variant="subtitle1">Sync from:</Typography>
                <Typography id="error" visibility={ showErroMessage ? "visible" : "hidden" } variant="errorText" marginBottom="-10px">{invalidFormDataError}</Typography>
                <MappingGridStyle>                    
                    <DataGrid
                        id="data_grid"
                        dataList={ledgers}
                        headerList={headerList}
                        checkboxSelection={true}
                        selectionModel={selectedLedgersIDs}
                        setSelectionModel={handleSetSelectedLedgers}
                        minHeight="1vh"
                    />
                </MappingGridStyle>
                <Stack id="button_row" direction="row" justifyContent={"end"} spacing={3} sx={{ mt: 3 }}>                   
                    <Button id="btn_sync" disabled={!!invalidFormDataError} variant="outlined" onClick={() => { handleConnect(); }}>Sync</Button>
                    <Button id="btn_cancel" variant="outlined" onClick={() => { onClose(); }}>Cancel</Button>
                </Stack>
            </MainStyledContainer>
        );
    }

    return (
        <>
            <FullScreenModal
                id="modal_allocation_workbook_info"
                open={true}
                title=""
                subtitle={mainDialogContent()}
            />
            
            <ConfirmationDialog
                open={showGlConnectError}
                onClose={() => {setShowGlConnectError(false); }}
                id="modal_prompt_save_first"
                actions={[
                {
                    label: 'Try Again',
                    onClick: () => {setShowGlConnectError(false); handleConnect();},
                    id: 'try_again_button',
                    variant: 'contained',
                    color: 'primary'
                },
                {
                    label: 'Cancel',
                    onClick: () => {setShowGlConnectError(false); },
                    id: 'cancel_button',
                    variant: 'outlined',
                    color: 'error'
                }
                ]}
                content="Something went wrong when trying to connect to GL service.  Please try again. if the problem persists, contact support for further help."
                title="Connection Error"
            />

            <ProgressModal
                id="workbook_loading"
                showProgress={isLoading}
                text={'loading...'}
            />
        </>
    );
};