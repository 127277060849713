import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../core/context/appContextProvider';
import { getBankInstitutions } from '../../../../services/bank.service';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { BankInstitution } from '../../../../utils/types/bank.type';
import {
  LoadingMessage,
  popularBanksIds,
} from '../BankConnectionPanel.defaults';

const today = new Date();
const minDate = new Date(
  today.getFullYear() - 2,
  today.getMonth(),
  today.getDate()
);

type Props = {
  setIsLoading: (value: string) => void;
  onOk: (institutionId: string, importStartDate: Date) => void;
};

export const useBankInstitutionSelector = ({ setIsLoading, onOk }: Props) => {
  const { informationAlert } = useContext(AppContext);

  const [searchText, setSearchText] = useState<string>();
  const [bankInstitutions, setBankInstitutions] = useState<BankInstitution[]>();
  const [popularBankInstitutioins, setPopularBankInstitutioins] =
    useState<BankInstitution[]>();
  const [filteredBankInstitutions, setFilteredBankInstitutions] =
    useState<BankInstitution[]>();
  const [selectedBankInstitution, setSelectedBankInstitution] =
    useState<string>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffectAsync(async (isCanceled) => {
    try {
      setIsLoading(LoadingMessage.LOADING_BANKS);

      const bankInstitutionsResponse = await getBankInstitutions();

      if (isCanceled()) return;

      bankInstitutionsResponse.sort((a, b) => {
        const aBankName = a.bankName.toLocaleLowerCase();
        const bBankName = b.bankName.toLocaleLowerCase();

        return aBankName > bBankName ? 1 : bBankName > aBankName ? -1 : 0;
      });

      const bankInstitutionsMap = new Map(
        bankInstitutionsResponse.map((bank) => [bank.id, bank])
      );
      const popularBankInstitutioins = popularBanksIds
        .map((id) => bankInstitutionsMap.get(id))
        .filter((item): item is BankInstitution => item !== undefined);

      setPopularBankInstitutioins(popularBankInstitutioins);
      setBankInstitutions(bankInstitutionsResponse);
      setIsLoading('');
    } catch (e) {
      setIsLoading('');
      informationAlert('Error getting banks', 'error');
    }
  }, []);

  useEffect(() => {
    if (bankInstitutions?.length) {
      handleOnSearchChange('');
    }
  }, [bankInstitutions]);

  function handleSave(): void {
    onOk(selectedBankInstitution!, selectedDate!);
  }

  const handleOnSearchChange = (value: string) => {
    if (value) {
      const filteredBankInstitutions = bankInstitutions!.filter((b) =>
        b.bankName.toLowerCase().includes(value.toLowerCase())
      );

      if (filteredBankInstitutions?.length) {
        setBankFilter(filteredBankInstitutions);
      } else {
        setBankFilter([]);
      }

      setSearchText(value);
    } else {
      setBankFilter(bankInstitutions!);
      setSearchText(undefined);
    }

    setSelectedBankInstitution(undefined);

    function setBankFilter(bankInstitutions: BankInstitution[]) {
      const displayTopNumber = 250;
      let topBanks: BankInstitution[] | undefined;

      if (bankInstitutions?.length) {
        topBanks = bankInstitutions?.slice(0, displayTopNumber);
      } else {
        topBanks = [];
      }

      // react bug alert:  need to first clear list before repopulating
      setFilteredBankInstitutions(undefined);

      setTimeout(() => {
        setFilteredBankInstitutions(cloneDeep(topBanks));
      }, 1);
    }
  };

  function handleSelectionModel(id: string) {
    setSelectedBankInstitution(id);
  }

  const isSelectedDateValidDate = DateTimeFormat.isValidDate(selectedDate);
  const isDateWithin2Years = isSelectedDateValidDate
    ? DateTimeFormat.compareDateOnly(selectedDate!, minDate) >= 0
    : false;
  const isValidImportDate =
    selectedDate === null || !isSelectedDateValidDate || isDateWithin2Years
      ? null
      : 'Select a date within the last two years to continue';
  const isValidForm =
    selectedBankInstitution &&
    DateTimeFormat.isValidDate(selectedDate) &&
    isDateWithin2Years;

  return {
    popularBankInstitutioins,
    searchText,
    handleOnSearchChange,
    filteredBankInstitutions,
    selectedDate,
    setSelectedDate,
    handleSelectionModel,
    selectedBankInstitution,
    handleSave,
    minDate,
    isValidImportDate,
    isValidForm,
  };
};
