import { DataGridColDef } from "../../utils/types/listItems";

export type ReportGridBaseData = {
    itemId: string,
    currencyCode: string,
    categoryProps: { [key: string]: any }
    dataProps: { [key: string]: any }
    callerData?: any,
    decimals?: number,
    footerMethod?: string
};

export type GridRow = {
    id: string,
    entityId: string,
    categorySource: string,
    categoryName: string,
    parentCategoryPath: string,
    parentCategorySources: GridRowCategorySource[],
    level: number,
    currencyCode: string,
    isCategoryLinkButton: boolean

    [key: string]: any, // rest of data fields are dynamically added
    callerDataArray: any[],
    decimals?: number,
    footerMethod?: string
};

export type GridRowCategorySource = {
    entityId: string,
    categoryName: string,
    categorySource: string
};

export type CategoryFieldDef = {
    id: string,
    name: string,
    headerName: string,
    visible: boolean,
    order: number,
    useLinkButton: boolean,
    category?: string,
    hideZero?: boolean,
}

export type NumberFormatTypes =  'Text' | 'Integer-use_commas' | 'Currency.0' | 'Currency.1' | 'Currency.2' | 'Currency.3' | 'Currency.4' | 'Decimal-use-comas.2';

export type ValueFieldDef = CategoryFieldDef & {
    numberFormat: NumberFormatTypes,
}

export type GridData = {
    gridRows: GridRow[];
    dataGridColDefs: DataGridColDef[];
}

export const LevelColors = ['#3944bc','#281e5d','#016064','#241571','#2c3e4c','#757c88','#48AAAD','#0892c2'];