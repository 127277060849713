import { MultiSelectItem } from '../../components/MultiSelect/MultiSelect';
import { PageInfo } from './common.type';

export interface ClientConfigs {
  clientId: string;
  clientName: string;
  hostedZoneId: string;
  baseDomain: string;
  subdomain: string;
  logoUrl: string;
  supportEmail: string;
  arkClientTag?: string;
  faviconUrl?: string;
  tabName?: string;
  useFavicon: boolean;

  authDomain: string;
  authClientId: string;
  authOrganization: string;
  isAuth0User: boolean;
}

export interface Theme {
  id?: string | null;
  index?: number | null;
  loginColorHex1?: string | null;
  loginColorHex2?: string | null;
  loginColorHex3?: string | null;
  mainColorHex1?: string | null;
  mainColorHex2?: string | null;
  mainColorHex3?: string | null;
  mainColorHex4?: string | null;
  mainColorHex5?: string | null;
  name?: string | null;
  tenantId?: string | null;
}

export interface MFASettings {
  loginValidation?: boolean;
  wireInfoValidation?: boolean;
  contactValidation?: boolean;
}

export interface Files {
  mainLogo?: File;
  favicon?: File;
  reportLogo?: File;
  background?: File;
  mainAsReport?: boolean;
}

export interface Client {
  arkClientTag?: string | null;
  baseDomain?: string | null;
  clientPortalErrorMessage?: string | null;
  hostedZoneId?: string | null;
  id?: string;
  logoUrl?: string | null;
  name?: string | null;
  oldBaseDomain?: string | null;
  oldHostedZoneId?: string | null;
  oldSubDomain?: string | null;
  portalOn?: boolean | null;
  subdomain?: string | null;
  supportEmail?: string | null;
  active?: boolean;
  ccEmails?: string | null;
  faviconUrl?: string | null;
  mainLogoUrl?: string | null;
  orderFundsByShortName?: boolean | null;
  reportLogoUrl?: string | null;
  systemEmail?: string | null;
  tabName?: string | null;
  theme?: Theme;
  useFavicon?: boolean | null;
  useSupportEmail?: boolean | null;
  mfaSettings?: MFASettings | null;
  files?: Files | null;
  glLocked?: boolean;
  allocationLocked?: boolean;
  soiLocked?: boolean;
  arkGlLocked: boolean;
  sftpLocked?: boolean;
  clientSftpVendors?: MultiSelectItem[];
  bankLocked?: boolean;
  auth0Migrate?: boolean;
  visualizationLocked?: boolean;
  visualizationTypes?: MultiSelectItem[];
}

export interface ClientList {
  items: Client[];
  pageInfo?: PageInfo;
}

export type SelectedClient = {
  client?: Client;
  type?: CRUDType;
};

export enum CRUDType {
  New = 'new',
  Edit = 'edit',
}

export interface ClientConsent {
  id?: string | null;
  index?: number | null;
  tenantId?: string | null;
  text?: string | null;
  type?: string | null;
}

export interface ClientConsentList {
  consents: Record<string, ClientConsent>;
}

export interface AvailableDomain {
  baseDomain: string;
  hostedZoneId: string;
}

export interface ClientTokenResponse {
  access_token?: string | null;
  arkClientTag?: string | null;
  arkClientUserTag?: string | null;
  clientId?: string | null;
  clientName?: string | null;
  expires_in?: number | null;
  impersonatedBy?: string | null;
  isProxy?: string | null;
  jti?: string | null;
  referrer?: string | null;
  refresh_token?: string | null;
  scope?: string | null;
  tokenDurationMinutes?: number | null;
  tokenExpirationCountdownSeconds?: number | null;
  token_type?: string | null;
}

export interface MFASettingsResponse {
  clientConfigId: string;
  clientId: string;
  contactValidation: boolean;
  id: string;
  loginValidation: boolean;
  wireInfoValidation: boolean;
}

export interface FeatureConfig {
  id?: string | null;
  glLocked?: boolean;
  allocationLocked?: boolean;
  soiLocked?: boolean;
  arkGlLocked?: boolean;
  sftpLocked?: boolean;
  clientSftpVendors?: MultiSelectItem[] | null;
  bankLocked?: boolean;
  visualizationLocked?: boolean;
  visualizationTypes?: MultiSelectItem[] | null;
}

export enum ClientAction {
  ExportSelected = 'export_selected',
  ExportAll = 'export_all',
}
