import { User, UserList } from "../utils/types/user.type";
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2Admin); };

export const getUsers = async () => await apiClient().get<UserList>('users');

export const getUser = async (id: number) => await apiClient().get<User>(`users/${id}`);

export const updateUser = async (user: User) => await apiClient().put<User>(`users/${user.id}`, user);

export const createUser = async (user: User) => await apiClient().post<User>('users', user);

export const deleteUser = async (id: string) => await apiClient().delete(`users/${id}`);

export const getUserLockStatus = async (id: string) => await apiClient().get<{locked: boolean}>(`users/${id}/locks`);

export const removeUserLockStatus = async (id: string) => await apiClient().delete(`users/${id}/locks`);
