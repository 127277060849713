import { Box, Button, FormControl, styled, Typography } from "@mui/material";

export const SelectFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  ".MuiInputLabel-formControl": {
    top: "-7px",
  },
  ".MuiInputLabel-shrink": {
    top: "0",
  },
}));

export const KeyValuePair = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const KeyLabel = styled(Box)`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const ValueLabel = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const PlatformButton = styled(Button)(({ theme }) => ({
  height: "100px",
  width: "100%",
  margin: "20px 0",

  "&.selected": {
    border: `3px solid #007aff`,
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export const PlatformButtonArk = styled(Button)(({ theme }) => ({
  height: "100px",
  width: "100%",
  margin: "20px 0",

  "&.selected": {
    border: `3px solid #007aff`,
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export const StepDescription = styled(Typography)`
  margin: 30px 0;
`;
