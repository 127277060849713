import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ReactElement } from "react";

import { SelectionOptionItem } from "../../utils/types/listItems";

type Props = {
  id: string;
  label: string;
  required?: boolean;
  value?: string;
  onChange?: any;
  error?: boolean;
  helperText?: string;
  options: SelectionOptionItem[];
};

const TextField = (props: Props): ReactElement => {
  const {
    id,
    label,
    value,
    onChange,
    error = false,
    helperText = "",
    options,
  } = props;

  return (
    <RadioGroup
      id={id}
      row
      aria-label={label}
      name={label}
      value={value}
      onChange={onChange}
    >
      {options.map((option) => (
        <FormControlLabel
          id={option.id}
          key={option.id}
          value={option.value}
          control={<Radio id={`${option.id}_selection`} />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default TextField;
