import { Backdrop, Box, Drawer, FormControl, RadioGroup, styled } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";

export const InvestorDetailPanel = styled(Drawer)`
  .MuiDrawer-paper {
    border-top-left-radius: 10px;
    margin-top: 10px;
    max-width: ${INFO_PANEL_MAX_WIDTH};
  }
`;

export const Title = styled("div")(() => ({
  fontStyle: "normal",
  fontSize: "24px",
  lineHeight: "100%",
  paddingTop: "30px",
  marginLeft: "3%",
  textAlign: "left",
  verticalAlign: "center",
  paragraphSpacing: "10px",
  fontWeight: "bold",
  display: "flex",
  whiteSpace: "nowrap",
  "img:first-of-type": {
    paddingLeft: "20px",
  },
}));

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
`;

export const InfoSection = styled('div')<{
  display?: string | undefined;
  width?: string | undefined;
}>(({ display, width }) => ({
  display: `${display ?? 'block'}`,
  width: width ? width : '320px',
  '& .field-value': {
    marginTop: '8px'
  },
}));

export const BankInstructions = styled('div')<{}>(() => ({
  height: '220px'
}));

export const EntityTypeRadioGroup = styled(RadioGroup)`
  display: inline;
`;

export const CheckBox = styled(Box)`
  padding: 0;
  margin-block-start: 0;
  .MuiCheckbox-root {
    padding-left: 9px;
  }
`;

export const TypeFormControl = styled(FormControl)`
  width: 100%;
  .MuiInputLabel-formControl {
    top: -7px;   
  }
  .MuiInputLabel-shrink {
  top: 0;    
  }
`;
