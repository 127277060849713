import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro';

export const SelectFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '.MuiInputLabel-formControl': {
    top: '-7px',
  },
  '.MuiInputLabel-shrink': {
    top: '0',
  },
}));

export const KeyValuePair = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const KeyLabel = styled(Box)`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

export const ValueLabel = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const DatePicker = styled(MUIDatePicker)`
  &.MuiFormControl-root.MuiTextField-root {
    margin: 10px;
  }
`;

export const StatusControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    marginLeft: '0',
    marginRight: '2rem',
  },
}));
