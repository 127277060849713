import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { Box, ButtonBase, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const DatePickerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
  padding: '8px',
  '& > :last-child': {
    marginLeft: '16px',
  },
}));

export const InstitutionSearchBox = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'grid',
  alignItems: 'center',
  background: '#CCE4FF',

  height: '100%',
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
  marginLeft: '-32px',
  paddingLeft: '32px !important',
  marginRight: '-16px',
  paddingRight: '16px',
  padding: theme.spacing(2),
}));

export const SearchInstitutionTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '28px',
  paddingTop: '8px',
  paddingBottom: '36px',
  paddingLeft: '24px',

  paddingRight: '72px',
}));

export const PopularBanksTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '24px',
  padding: '16px',
  paddingLeft: '24px',
}));

export const FoundBankButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  display: 'block',
  marginBottom: '5px',
}));

export const FoundBankBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'isSelected',
})<{
  isSelected: boolean;
}>(({ theme, isSelected }) => ({
  width: '100%',
  margin: '1px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  border: isSelected ? '1px solid #007AFF' : '1px solid #C4C4C4',
  boxShadow: isSelected ? '0 0 0 1px rgba(0, 122, 255, 1)' : '',
  '&:hover, &:focus-within': {
    borderColor: isSelected ? '#007AFF' : '#383842',
  },

  borderRadius: theme.shape.borderRadius,
}));

export const PopularBankBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'isSelected',
})<{
  isSelected: boolean;
}>(({ theme, isSelected }) => ({
  margin: '1px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  border: isSelected ? '1px solid #007AFF' : '1px solid #C4C4C4',
  boxShadow: isSelected ? '0 0 0 1px rgba(0, 122, 255, 1)' : '',
  '&:hover, &:focus-within': {
    borderColor: isSelected ? '#007AFF' : '#383842',
  },

  borderRadius: theme.shape.borderRadius,
}));

export const PopularBankLogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  height: 44,
}));

export const PopularBankDefaultIcon = styled(AccountBalanceOutlinedIcon)(
  ({ theme }) => ({
    color: '#503629',
  })
);

export const PopularBankNameTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));

export const SearchBarInput = styled(TextField)`
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 24px;
  fieldset {
    border-radius: 50px;
    border: 1px solid #919195;
  }

  input {
    width: 100%;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
  }

  .MuiOutlinedInput-root {
    background-color: white;
    // overflow: hidden;
    border-radius: 49px;
    box-shadow: 0 0 10px rgba(0, 122, 255, 2);
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #919195;
  }
`;
