import { Box, Drawer, Stack, styled, Typography as MUITypography } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  }
}));

export const Panel = styled(Box)`
  margin: 15px 0;
`;

export const Typography = styled(MUITypography)(({
  theme
}) => ({
  marginBottom: theme.spacing(4)
}));

export const TypographyFunds = styled(MUITypography)(({
  theme
}) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2)
}));

export const ChipStack = styled(Stack)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
