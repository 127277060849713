import { styled } from '@mui/material';
import React from 'react';

import { GRID_HEADER_BORDER_COLOR } from '../../styles/colors';

export const BoxView = styled(`div`)(({ theme }) => ({
    border: `1px solid ${GRID_HEADER_BORDER_COLOR}`, 
    borderRadius: '4px',
    padding: '12px',
    width: '100%'
}));

export const Header = styled(`div`)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '20px'
}));

export const HeaderTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '8px',
    '& div': {
        display: 'flex',
        'svg:first-child': {
            marginRight: '8px'
        }
    }
}));