import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router";

import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import RoutingPaths from "../../core/routing/routingPaths";
import useRole from "../../core/routing/useRole";
import { getClientConfigsList } from "../../services/client.service";
import { GENERIC_ERROR_MESSAGE } from "../../utils/constants/text.constants";
import { Direction, sortData } from "../../utils/helpers/sort";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { ADMIN_ROLES, ScopeRole } from "../../utils/types/user.type";

export const SELECT_CLIENT = {
  clientId: 'select_client',
  clientName: 'Select Client',
  logoUrl: ''
};

export const LOADING_CLIENT = {
  clientId: 'loading_clients',
  clientName: 'Loading Clients...',
  logoUrl: ''
};

const useNavbar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedClient, setSelectedClient] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const {
    state,
    onLogout,
    onClientChange,
    preAuthClientConfigs,
    clientConfigList
  } = useContext(AppContext);
  const {
    clientTheme
  } = useContext(ClientThemeContext);
  const hasClient = state?.loginUser?.clientId;
  const isSuperAdminOrArkClientAdmin = state?.loginUser?.currentUser?.scopeRole === ScopeRole.ARK_CLIENT_ADMIN || state?.loginUser?.currentUser?.scopeRole === ScopeRole.SUPER_ADMIN;
  const {
    hasRole: isAdmin
  } = useRole(ADMIN_ROLES);

  useEffectAsync( async (isCanceled) => {
    try {
      if(!hasClient && state?.loginUser?.currentUser?.scopeRole === ScopeRole.FUND_USER_ADMIN){
        const clientInfo = await getClientConfigsList();

        await onClientChange(clientInfo[0].clientId);

        setSelectedClient(undefined);
        history.push("/funds");
      }
    } catch (e) {
      alert(GENERIC_ERROR_MESSAGE);
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNavClientChange = async (clientId?: string) => {
    const clientIdToBeUpdated = clientId || selectedClient;

    setLoading(true);
    if (clientIdToBeUpdated) {
      await onClientChange(clientId!, false);

      setSelectedClient(undefined);
    }
    setLoading(false);
    history.push(RoutingPaths.AppDashboard);
  };

  const clearSelectedClient = () => {
    setSelectedClient(undefined);
  };

  const handleClientChange = async (event: React.SyntheticEvent, newValue: any) => {
    if (!hasClient && newValue?.clientId) {
      await onClientChange(newValue.clientId);
    } else if (newValue?.clientId) {
      setSelectedClient(newValue.clientId);
    }
  };

  // WARNING:
  // @ts-ignore and @ts-expect-error suppress TypeScript validations by ignoring errors.
  // Suppressing TypeScript validations can be 🔴dangerous🔴.
  // Here it is suppressed as zendesk doesn't support typescript by default, and we are using globally exported object zE.
  const getHelp = () => {
    // @ts-ignore
    window?.zE?.activate({
      hideOnClose: true
    });
    handleClose();
  };

  const clientList = useMemo(() => [(clientConfigList?.length > 0 ? SELECT_CLIENT : LOADING_CLIENT), ...clientConfigList.sort(sortData({
    direction: Direction.Up,
    name: 'clientName'
  }))], [clientConfigList]);

  return {
    state,
    open,
    hasClient,
    isSuperAdminOrArkClientAdmin,
    handleClick,
    handleClose,
    onLogout,
    anchorEl,
    clientList,
    handleClientChange,
    onClientChange: onNavClientChange,
    clearSelectedClient,
    setSelectedClient,
    selectedClient,
    loading,
    getHelp,
    isAdmin,
    preAuthClientConfigs,
    clientTheme
  };
};

export default useNavbar;
