import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useHistory } from "react-router";

import { DataWrapperBox } from "../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../components/Buttons/Button/Button";
import DataGrid from "../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../components/Modal/ConfirmationDialog";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import RoutingPaths from "../../core/routing/routingPaths";
import { DistributionDetailsTabs } from "./DistributionDetails/Constants";
import { HeaderRow } from "./Distributions.styles";
import { useDistribution } from "./useDistribution.hook";

const Distribution: React.FC = (): ReactElement => {
  const history = useHistory();
  const {
    headerList,
    list,
    handleFilter,
    activeHeaderFields,
    handleUpdateHeader,
    loading,
    discardStatusUpdate,
    updateStatus,
    statusUpdateDistributions,
    statusAction,
    openDistributionSetUp,
    handleConfirmSkiptoTransaction,
    handleStartFromBeginning,
    discardOpenDistributionSetUp,
    isSuperAdminOrClientAdmin,
  } = useDistribution();

  return (
    <DataWrapperBox id="distribution_list_main">
      <Progress id="distribution_list_loading" showProgress={loading} />
      <HeaderRow>
        <Typography variant="pageTitle">Distributions</Typography>
        {isSuperAdminOrClientAdmin && (
          <Button
            id="distribution_button"
            variant="contained"
            icon={<AddIcon />}
            text="Start New Distributions"
            color="primary"
            onClick={() => {
              history.push(
                `${RoutingPaths.Distributions}/new/${DistributionDetailsTabs.DistributionSetup}`
              );
            }}
          />
        )}
      </HeaderRow>
      <DataGrid
        id="distribution_data_grid"
        dataList={list || []}
        noDataMessage={loading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        autoHeight={false}
        activeHeaderFields={activeHeaderFields}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
      />
      <ConfirmationDialog
        open={!!statusUpdateDistributions}
        onClose={discardStatusUpdate}
        id="toggle_distributions_status_confirmation"
        actions={[
          {
            label: "Update Status",
            onClick: updateStatus,
            id: "toggle_distributions_status_confirmation_update",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: discardStatusUpdate,
            id: "toggle_distributions_status_confirmation_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`You are about to ${statusAction} this Distribution batch. Would you like to continue? If you are unsure of the implications of this function, please reach out to your systems team before continuing.`}
        title="Update Status?"
      />
      <ConfirmationDialog
        open={!!openDistributionSetUp}
        onClose={discardOpenDistributionSetUp}
        id="toggle_capital_calls_setup_open_confirmation"
        actions={[
          {
            label: "Yes, skip to transaction",
            onClick: handleConfirmSkiptoTransaction,
            id: "toggle_open_skip_to_transaction_confirmation",
            variant: "contained",
            color: "primary",
          },
          {
            label: "No, I’ll start from the beginning",
            onClick: handleStartFromBeginning,
            id: "toggle_open_start_from_beginning_confirmation",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`Do you want to skip right to Add Transactions? You will still be able to view all sections`}
        title="To speed up the review process"
      />
    </DataWrapperBox>
  );
};

export default React.memo(Distribution);
