import { MailRounded } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { useMemo } from "react";

import { ImageItem } from "../../../utils/types/listItems";
import { DocumentsState } from "../types";

export const DownloadFolder: ImageItem = {
  id: "download_folder",
  text: "Download Folder",
  icon: <FileDownloadOutlinedIcon color="primary" />,
  optionsSelected: 0,
};

export const DeleteSelected: ImageItem = {
  id: "delete_selected",
  text: "Delete Selected",
  icon: <DeleteOutlinedIcon color="error" />,
  optionsSelected: 0,
  textColor: "error",
};

const DownloadSelectedFilesDocLinks = (count: number): ImageItem => ({
  id: "download_selected_files_links",
  text: `Download Document Links ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <LinkIcon color="primary" />,
  optionsSelected: 0,
});

const MoveSelectedFiles = (count: number): ImageItem => ({
  id: "move_selected_files",
  text: `Move Selected ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <DriveFileMoveOutlinedIcon color="primary" />,
  optionsSelected: 0,
});

const PublishSelectedFiles = (count: number): ImageItem => ({
  id: "publish_selected_files",
  text: `Publish Selected ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <PublicIcon color="primary" />,
  optionsSelected: 0,
});

const UnpublishSelectedFiles = (count: number): ImageItem => ({
  id: "unpublish_selected_files",
  text: `Unpublish Selected ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <PublicOffIcon color="primary" />,
  optionsSelected: 0,
});

const EditSelectedFiles = (count: number): ImageItem => ({
  id: "edit_selected_files",
  text: `Bulk Edit ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <EditIcon color="primary" />,
  optionsSelected: 0,
});

const DownloadSelectedFiles = (
  count: number,
  isDownloading: boolean
): ImageItem => ({
  id: "download_selected_files",
  text: `Download Files ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <FileDownloadOutlinedIcon color="primary" />,
  optionsSelected: 0,
  disabled: isDownloading,
});

const DownloadSelectedFolders = (
  count: number,
  isDownloading: boolean
): ImageItem => ({
  id: "download_selected_folders",
  text: `Download Folders ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <FileDownloadOutlinedIcon color="primary" />,
  optionsSelected: 0,
  disabled: isDownloading,
});

const DeleteSelectedFiles = (count: number): ImageItem => ({
  id: "delete_selected_files",
  text: `Delete ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <DeleteOutlinedIcon color="error" />,
  optionsSelected: 0,
  textColor: "error",
});

const MailSelectedFiles = (count: number): ImageItem => ({
  id: "mail_selected_files",
  text: `Mail Selected ${count > 0 ? "(" + count + ")" : ""}`,
  icon: <MailRounded color="primary" />,
  optionsSelected: 0,
});

interface ExtraProps {
  allowDelete?: boolean;
  fileCount?: number;
  folderCount?: number;
  isEditable?: boolean;
  haveFolders?: boolean;
  isUser?: boolean;
}

function useDocumentsBulkActions(
  state: DocumentsState,
  {
    allowDelete,
    fileCount = 0,
    folderCount = 0,
    isEditable = false,
    haveFolders = false,
    isUser = false,
  }: ExtraProps
) {
  return useMemo((): ImageItem[] => {
    const isDisabled = state.isDownloading || state.isReadOnly;
    const options = [];

    if (isEditable) {
      if (state.cursor.length === 0) {
        options.push(DownloadSelectedFolders(folderCount, isDisabled));
        if (allowDelete) {
          options.push(DeleteSelected);
        }
      } else {
        if (folderCount >= 1) {
          options.push(DownloadSelectedFolders(folderCount, isDisabled));
        }
        if (fileCount > 0) {
          options.push(
            ...[
              PublishSelectedFiles(fileCount),
              UnpublishSelectedFiles(fileCount),
              MailSelectedFiles(fileCount),
              MoveSelectedFiles(fileCount),
              DownloadSelectedFiles(fileCount, state.isDownloading),
              DownloadSelectedFilesDocLinks(fileCount),
              EditSelectedFiles(fileCount),
            ]
          );
        }
        if (allowDelete) {
          options.push(DeleteSelectedFiles(fileCount + folderCount));
        }
      }
    } else {
      {
        haveFolders &&
          options.push(
            DownloadSelectedFolders(folderCount, state.isDownloading)
          );
      }
      if (state.cursor.length > 0 && fileCount > 0) {
        options.push(
          ...[DownloadSelectedFiles(fileCount, state.isDownloading)]
        );
      }
    }
    return options;
  }, [
    state.cursor,
    state.selectedDocumentVariant,
    state.isDownloading,
    state.isReadOnly,
  ]);
}

export default useDocumentsBulkActions;
