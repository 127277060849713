export const ReceivingBankInfoMapping = [
  {
    label: "Receiving Bank ABA/Routing Number",
    keys: ["abaNumber"],
  },
  {
    label: "Receiving Bank Name",
    keys: ["name"],
  },
  {
    label: "Receiving Bank Address",
    keys: ["addressLine1", "addressLine2"],
  },
];

export const BeneficiaryBankInfoMapping = [
  {
    label: "Beneficiary Bank ABA/Routing Number",
    keys: ["abaNumber"],
  },
  {
    label: "Beneficiary Bank Name",
    keys: ["abaNumber"],
  },
  {
    label: "Beneficiary Bank Address",
    keys: ["addressLine1", "addressLine2"],
  },
];

export const BeneficiaryInformationMapping = [
  {
    label: "Beneficiary Name",
    keys: ["beneficiaryName"],
  },
  {
    label: "Beneficiary Account",
    keys: ["beneficiaryAccount"],
  },
  {
    label: "Beneficiary Address",
    keys: ["beneficiaryAddressLine1", "beneficiaryAddressLine2"],
  },
  {
    label: "Originator to Beneficiary Instructions",
    keys: [
      "originatorToBeneficiaryInstructions1",
      "originatorToBeneficiaryInstructions3",
      "originatorToBeneficiaryInstructions3",
      "originatorToBeneficiaryInstructions4",
    ],
  },
  {
    label: "Bank to Bank Instructions",
    keys: [
      "bankToBankInstructions1",
      "bankToBankInstructions2",
      "bankToBankInstructions3",
      "bankToBankInstructions4",
    ],
  },
];

export const IntermediaryBankInfoMapping = [
  {
    label: "Intermediary Bank ABA/SWift",
    keys: ["abaNumber"],
  },
  {
    label: "Intermediary Bank Address 1",
    keys: ["addressLine1"],
  },
  {
    label: "Intermediary Bank Address 2",
    keys: ["addressLine2"],
  },
];

export const InvestorDetailsMapping = [
  {
    label: "Owner Name",
    keys: ["ownerName"],
  },
  {
    label: "Owner SSN",
    keys: ["ownerSSN"],
  },
];
