import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { NameIdPair } from "../../../utils/types/transaction.type";
import { getTypes } from "../../uploadHistory.service";

export const useUploadHistoryTypesEffect = () => {
  const [types, setTypes] = useState<NameIdPair[]>();
  const [loading, setLoading] = useState(false);
  const {
    informationAlert
  } = useContext(AppContext);

  const fetchTypes = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getTypes();

      if (isCanceled?.()) return;

      setTypes(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting upload history types", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchTypes(isCanceled);
  }, []);

  return {
    types,
    fetchTypes,
    loading,
    setTypes
  };
};
