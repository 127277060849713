import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { InvestorFilter } from "../../../utils/types/investor.type";
import { getInvestorsFilterList, } from "../../filters.service";

export const useInvestorsFilterEffect = () => {
  const [investorsList, setInvestorsList] = useState<InvestorFilter[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoading(true);
      const statusResponse = await getInvestorsFilterList();

      if (isCanceled()) return;

      setInvestorsList(statusResponse);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting investors", "error");
      setLoading(false);
    }
  }, []);

  return {
    investorsList,
    loading
  };
};

