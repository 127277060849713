import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import * as Yup from "yup";

import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { AppContext } from '../../core/context/appContextProvider';
import { ClientThemeContext } from '../../core/context/clientThemeContext';
import { resetPasswordRequest } from '../../services/user.service';
import { PageType } from '../../utils/types/login.type';
import { ActionButton, ContentDiv, StyledTextField } from "./Login.styles";


const validationSchema = Yup.object({
  email: Yup.string().email().required("Email is required")
});

const ResetPassword = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
}) => {

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  }= useForm<{ email: string }>({
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    clientTheme
  } = useContext(ClientThemeContext);
  const {
    preAuthClientConfigs,
  } = useContext(AppContext);

  const [showInprogress, setShowInProgress] = useState(false);

  const sendResetRequest = async (data: any) => {
    if(data?.email) {
      const  params=  {
        clientBaseDomain: preAuthClientConfigs?.baseDomain,
        clientSubdomain: preAuthClientConfigs?.subdomain,
        clientId: preAuthClientConfigs?.clientId ? preAuthClientConfigs?.clientId : null,
        clientName: preAuthClientConfigs?.clientName,
        clientSupportEmail: preAuthClientConfigs?.supportEmail,
        password: null,
        username: data?.email
      };

      try {
        setShowInProgress(true);
        await resetPasswordRequest(params);
        setCurrentPage("Thankyou");
        setShowInProgress(false);
      } catch (err) {
        setCurrentPage("LoginScreen");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(sendResetRequest)}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={1}
        direction="row"
      >
        <Progress id="login_loader" showProgress={showInprogress} />
        <Grid container justifyContent="center"  md={12} xs={12}>
          <ContentDiv>
            <Box my={2}>
                  Enter your email address below to receive
                  instructions to reset your password.
            </Box>
            <Box my={1}>
                Email Address:
            </Box>
            <StyledTextField
              size="small"
              required
              id="reset_form_email"
              type="text"
              inputProps={{
                notched: true
              }}
              error={!!errors.email}
              {...register("email")}
              helperText={errors.email?.message}
            />
          </ContentDiv>
        </Grid>
        <Grid container justifyContent="center"  md={12} xs={12} >
          <ContentDiv mb={12}>
            <ActionButton
              type="submit"
              variant="contained"
              className="btn"
              disableElevation
              onClick={sendResetRequest}
              textcolor={clientTheme?.loginButtonTextColor}
              btnbackgroundcolor={clientTheme?.loginButtonBackgroundColor}
              fullWidth
            >
              Send Request
            </ActionButton>
          </ContentDiv>
        </Grid>
      </Grid>
    </form>

  );
};

export default ResetPassword;
