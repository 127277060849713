import { Box, styled, Typography } from "@mui/material";

export const ActionCellContainer = styled(Box)(({
  theme
}) => ({
  background: theme.palette.common.sidebarBackground,
  border: `1px solid ${theme.palette.secondary.dark}`,
  boxSizing: 'border-box',
  borderRadius: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: '1fr 30px',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const ActionCellText = styled(Typography)(() => ({
  maxWidth: '110px',
  wordBreak: 'break-word',
}));

export const RowValueCellContainer = styled(Box)(({
  theme
}) => ({
  border: `1px solid ${theme.palette.common.sidebarBackground}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(0.5),
  '& .counter-icon': {
    visibility: "hidden",
  },
  '&:hover .counter-icon': {
    visibility: "visible",
  }
}));

export const MappingBodyContainer = styled(Box)(() => ({
  overflowY: 'auto',
  height: '60vh',
}));
