import { DeleteOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControl, FormHelperText, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import IconRightArrow from "../../../../assets/images/icons/icon_right_arrow.svg";
import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import TeamMemberFilter from "../../../../components/Selectors/TeamMemberFilter/TeamMemberFilter";
import { Role, SelectedTeamMember, TeamInfo } from "../../../../utils/types/team.type";
import {
  ArrowIconBox,
  DetailPanel,
  FormBox,
  TitleBox,
} from "../../../transactions/transactionList/transactionDetails/TransactionDetailPanel.styles";
import { StyledFormLabel, StyledStack } from "./TeamMebers.styles";
import { useTeamMembers } from "./useTeamMembers.hook";

interface Props extends RouteProps {
  onTeamDetailClose: Function;
  selectedTeam: TeamInfo;
  selectedMember?: SelectedTeamMember | null;
  openDrawer?: boolean;
  setTeamResponse: Function;
  setOpenTeam: Function;
  onDelete: Function;
  roles: Role[] | undefined;
}

const TeamMembers: React.FC<Props> = ({
  onTeamDetailClose,
  selectedTeam,
  selectedMember,
  openDrawer = false,
  setTeamResponse,
  setOpenTeam,
  onDelete,
  roles,
}: Props): ReactElement => {

  const {
    handleSubmit,
    control,
    loading,
    toggleDrawer,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    handleCreateMember,
    handleEditMember,
    watch,
  } = useTeamMembers({
    member: selectedMember,
    team: selectedTeam,
    onTeamDetailClose,
    setTeamResponse,
    setOpenTeam,
  });

  const isFundAdmin = !!(watch("employee")?.isFundAdmin || selectedMember?.role?.name === "Fund User - Admin");

  const name = selectedMember ? `${selectedMember.firstName} ${selectedMember.lastName || ""}`: '';
  const isEditing = !!selectedMember?.id;

  return (
    <React.Fragment>
      <DetailPanel
        id="team_member_drawer"
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
      >
        <TitleBox>
          <Typography id="title" variant="formTitle" display="flex">
            {selectedMember ? name  : "Add Team Member"} {" "}
            { isEditing && <ArrowIconBox sx={{ marginLeft: 1 }} onClick={toggleDrawer}>
                <ImgIcon icon={IconRightArrow} />
              </ArrowIconBox>
            }
          </Typography>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        <ProgressPanel
          id={"progress_team_member_panel"}
          showProgress={Boolean(loading)}
          text={`Loading Team Members...`}
        />
        {!loading && (
          <form
            autoComplete="off"
            onSubmit={handleSubmit(isEditing ? handleEditMember : handleCreateMember)}
            noValidate
          >
            <FormBox>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  { !isEditing && <Controller
                    name="employee"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TeamMemberFilter
                          id="team_member_name"
                          value={value}
                          error={error}
                          filterOptions={selectedTeam.employees?.map(item => item?.id || "") || []}
                          onChange={(member) => {
                            onChange({
                              id: member?.id,
                              name: member?.name,
                              isFundAdmin: member?.role?.name === "Fund User - Admin"
                            });
                          }}
                          placeholder="Name"
                          fullWidth
                        />
                      );
                    }}
                    rules={{
                      required: "Name is required",
                    }}
                    control={control}
                  /> }
                </Grid>
              </Grid>
              <Typography variant="subtitle1" mt={2}>
                Role
              </Typography>
              <Box flex={1}>
                <Controller
                  name="role"
                  render={({
                    field: {
                      onChange, value
                    },
                    fieldState: {
                      error
                    }
                  }) => (
                    <FormControl>
                      <RadioGroup
                        row
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                        value={value || ""}
                      >
                        <Stack rowGap={2}>
                        {
                          roles?.filter(role => {
                            if(isFundAdmin) return role.key === "FUND_USER_READ_ONLY";
                            else return role.key !== "FUND_USER_READ_ONLY";
                          }).map(item => <StyledFormLabel
                            value={item.key}
                            key={item.key}
                            id={item.key}
                            control={<Radio />}
                            label={item.title}
                          />)
                        }
                        </Stack>
                      </RadioGroup>
                      {!!error &&
                        <FormHelperText error={!!error}>
                          {error.message}
                        </FormHelperText>
                      }
                    </FormControl>
                  )}
                  rules={{
                    validate: (value) => {
                      if (value === null || value === undefined) {
                        return "Role is required";
                      }
                      return true;
                    }
                  }}
                  control={control}
                />
              </Box>
              {
                isEditing && <Box flex={1}>
                  <StyledStack alignItems="center" flexDirection="row" onClick={() => onDelete(selectedMember)}>
                    <DeleteOutlined/>
                    <Typography>
                      Delete Team Member
                    </Typography>
                  </StyledStack>
                </Box>
              }
            </FormBox>
            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              fullWidth
            >
              <Button
                id={"team_cancel_button"}
                variant="outlined"
                onClick={toggleDrawer}
                text={"Cancel"}
                color={"secondary"}
                fullWidth
                addSpaceBetweenButtons
              />
              <Button
                id={"team_save_button"}
                variant="contained"
                text={"Save & Close"}
                color={"primary"}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            </HorizontalBox>
          </form>
        )}
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_team_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "save_team_modal_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "cancel_save_team_modal_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </React.Fragment>
  );
};

export default TeamMembers;
