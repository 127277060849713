import { ClientConfigs, Theme } from "../utils/types/client.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.PreAuth);
};

export const getClientPortalAvaiability = async (baseDomain: string, subDomain: string) => {
  const host = process.env.REACT_APP_API_ENDPOINT_URL;
  const url = `${host}preauth-api/clients/${baseDomain}/${subDomain}/available`;
  const headers = {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    method: "GET"
  };

  const response = await fetch(url, headers);
  const available = await response.json();

  return available;
};

export const getClientConfigs = async (portalName: string, client: string) => {
  const clientToUse = client === 'platform-sso' ? 'platform' : client;
  const host = process.env.REACT_APP_API_ENDPOINT_URL;
  const url = `${host}preauth-api/loginthemes/${portalName}/${clientToUse}`;
  const headers = {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    method: "GET"
  };

  const response = await fetch(url, headers);
  const config = await response.json() as ClientConfigs;

  if(client === 'platform') {
    config.isAuth0User = false;
  } else {
    const clientId = !config.clientId ? process.env.REACT_APP_DEFAULT_CLIENT_ID! : config.clientId!;
    const auth0ClientLoginInfoResponse = await getAuth0ClientLoginInfo(clientId);
    
    config.authClientId = process.env.REACT_APP_AUTH_CLIENT_ID!; 
    config.authOrganization = auth0ClientLoginInfoResponse.organizationId;

    if(client === 'platform-sso') {
      config.authDomain = process.env.REACT_APP_AUTH_ADMIN_DOMAIN!;
      config.isAuth0User = true;
    } else {

      if(client && !config?.clientId) {
        throw `invalid config returned from ${url}`;
      }
      
      config.clientId = clientId;

      if(auth0ClientLoginInfoResponse.isAuth0) {
        config.authDomain = process.env.REACT_APP_AUTH_INVESTOR_DOMAIN!;
        config.isAuth0User = true;
      } else {
        config.isAuth0User = false;
      }
    }
  }

  return config;
};

export const getAuth0ClientLoginInfo = async (clientId: string) => {
  type CdnLoginJson = { isAuth0:boolean, organizationId:string };

  const cdnUrl = process.env.REACT_APP_CDN_ENDPOINT_URL!;
  const url = `${cdnUrl!}${clientId}/login.json`;

  try{
    const cdnLogin = await fetch(url);
    const cdnLoginJsonResponse = await cdnLogin.json() as CdnLoginJson;

    cdnLoginJsonResponse.isAuth0 = true;

    return cdnLoginJsonResponse;
  } catch {

    return {
      isAuth0: false
    } as CdnLoginJson;
  }  
};


export const getThemes = async (clientId: string) => {
  const host = process.env.REACT_APP_API_ENDPOINT_URL;
  const url = `${host}preauth-api/clients/${clientId}/themes`;
  const headers = {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    method: "GET"
  };

  const response = await fetch(url, headers);
  const themes = await response.json();

  return themes;
};
