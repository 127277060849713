import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { getWorkbooksByFund } from "../../../../../services/workbook.service";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { StyledModal, StyledModalBox } from "./SaveAsPrompt.styles";

export type SaveAsPromptProps = {
  fundId: string,
  onClose: (saveClicked: boolean, newFileName?: string) => void
}

export const SaveAsPrompt: React.FC<SaveAsPromptProps> = ({
    fundId,
    onClose 
}) => {
  const [reportName, setReportName] = useState('');
  const [errorText, setErrorText] = useState<string>();
  const [validNames, setValidNames] = useState<string[]>([]);

  useEffect(() => {
    document.getElementById('txt_report_name')?.focus();
  });

  useEffectAsync(async(isCanceled) => {
    const workbooksResponse = await getWorkbooksByFund(fundId);
    const nameList = workbooksResponse.map(wb => wb.reportName);

    const validNamesResponse = nameList;

    if(isCanceled()) return;

    setValidNames(validNamesResponse);
  }, []);

  function handleTextFieldChange(newValue: string) {
    const name = validNames.find(n => n === newValue);

    setReportName(newValue);

    if(name) {
      setErrorText('Report name already exists. Enter a new name.');
    } else {
      setErrorText(undefined);
    }
  }

  return (
    <StyledModal
      id="modal_save_as"
      aria-labelledby="modal_save_as"
      aria-describedby="modal_save_as"
      open={true}
      onClose={() => {onClose(false);}}
    >
      <StyledModalBox>
        <Typography marginBottom="25px" variant="subtitle1" >
            This will save the current workbook and create a new copy.
        </Typography>
        <TextField
          id="txt_report_name"
          size="small"
          label="Report Name"
          required
          fullWidth
          value={reportName}
          onChange={(e) => { handleTextFieldChange(e.target.value); }}
          error={errorText !== undefined}
          helperText={errorText}
          onKeyDown={(e) => e.stopPropagation()}
          focused
        />
        <Stack direction="row" justifyContent={"end"} spacing={4} sx={{ mt: 3 }}>
          <Button id="btn_ok" variant="contained" onClick={() => {onClose(true, reportName);}}>Ok</Button>
          <Button id="btn_cancel" variant="outlined" onClick={() => {onClose(false);}}>Cancel</Button>
        </Stack>
      </StyledModalBox>
    </StyledModal>
  );
};