import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';

import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  connectCashReceiptAdjustmentToJournalEntry,
  connectTransactionsToJournalEntry,
  createJournalEntry,
  getArkAccounts,
  getArkLedgers,
  getInvestmentTransactionsByJournalEntry,
  getJournalEntry,
  getJournalEntryAttachment,
  updateJournalEntry,
} from '../../../../services/arkGL.service';
import { getEntities } from '../../../../services/entity.service';
import { getInvestmentTransaction } from '../../../../services/investmentTransaction.service';
import {
  FROM_VIEW,
  POST_ACTION_TYPE,
} from '../../../../utils/constants/common.constants';
import downloadFile from '../../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { SelectedAccount } from '../../../../utils/types/arkGLAccount.type';
import { SelectedEntity } from '../../../../utils/types/arkGLEntity.type';
import {
  ArkGLJournalEntry,
  JournalEntryLineItem,
  SelectedJournalEntry,
} from '../../../../utils/types/arkGLJournalEntry.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import {
  InvestmentTransactionNewView,
  SelectedInvestmentTransaction,
} from '../../../../utils/types/investmentTransaction.type';
import { ImageItem } from '../../../../utils/types/listItems';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  DISABLE_SEND_TO_LOOP_TOOLTIP,
  DISABLE_SEND_TO_SOI_ALREADY_LINKED_TOOLTIP,
} from '../../../fund/InvestmentTransactions/InvestmentTransactionList.constants';
import { GET_GL_ACCOUNT_LIST_ERROR } from '../../accounts/accountList/AccountList.constants';
import {
  CONNECT_TRANSACTION_ERROR,
  CONNECT_TRANSACTION_SUCCESS,
  CREATE_JOURNAL_ENTRY_ERROR,
  CREATE_JOURNAL_ENTRY_SUCCESS,
  DELETE_ATTACHMENT_ERROR,
  DELETE_ATTACHMENT_SUCCESS,
  DOWNLOAD_ATTACHMENT_ERROR,
  GET_ENTITY_LIST_ERROR,
  GET_INV_TRANSACTION_LIST_ERROR,
  GET_LEDGER_LIST_ERROR,
  JOURNAL_ENTRY_FILENAME_SIZE_ERROR,
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
  UPDATE_JOURNAL_ENTRY_ERROR,
  UPDATE_JOURNAL_ENTRY_SUCCESS,
} from '../journalEntryList/JournalEntryList.constants';

type Props = {
  journalEntry?: ArkGLJournalEntry;
  selectedJournalEntry: SelectedJournalEntry;
  onClose: Function;
  onSuccessfulPostClose?: Function;
  fetchAllJournalEntries: Function;
  isNewJournalEntry: boolean;
  setSelectedJournalEntry: Function;
  fundId?: string;
};

const initialAccount: SelectedAccount = {
  account: undefined,
  type: undefined,
};

const initialInvestmentTransaction: SelectedInvestmentTransaction = {
  investmentTransactionType: '',
  investmentTransaction: undefined,
  type: undefined,
};

const initialMemoEntity: SelectedEntity = {
  entity: undefined,
  type: undefined,
};

const SaveJournalEntryOptionsSingleView: ImageItem[] = [
  {
    text: 'Save',
    id: 'btn_journal_entry_save',
  },
];

const SaveJournalEntryOptionsMultiView: ImageItem[] = [
  {
    text: 'Save & Next',
    id: 'btn_journal_entry_save_and_next',
  },
  {
    text: 'Save',
    id: 'btn_journal_entry_save',
  },
];

const SaveJournalEntryOptionsSOI: ImageItem[] = [
  {
    text: 'Save & Send to SOI',
    id: 'btn_journal_entry_save_and_sendtosoi',
  },
  {
    text: 'Save & New',
    id: 'btn_journal_entry_save_and_new',
  },
];

const SaveJournalEntryOptionsDefault: ImageItem[] = [
  {
    text: 'Save & New',
    id: 'btn_journal_entry_save_and_new',
  },
  {
    text: 'Save & Close',
    id: 'btn_journal_entry_save',
  },
];

export const useJournalEntryDetails = ({
  journalEntry,
  selectedJournalEntry,
  onClose,
  onSuccessfulPostClose,
  fetchAllJournalEntries,
  isNewJournalEntry,
  setSelectedJournalEntry,
  fundId,
}: Props) => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const arkClientTag = state.loginUser.arkClientTag;
  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);
  const isStandishManagementBasicAdmin: boolean = Boolean(
    isBasicAdmin && arkClientTag === 'Standish Management'
  );
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [selectedDeleteAttachment, setSelectedDeleteAttachment] = useState<
    string | undefined
  >();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [showClearAllConfirmation, setShowClearAllConfirmation] =
    useState<boolean>(false);
  const [showUpdateLedgerConfirmation, setShowUpdateLedgerConfirmation] =
    useState<boolean>(false);
  const [
    showDeleteAttachmentConfirmation,
    setShowDeleteAttachmentConfirmation,
  ] = useState<boolean>(false);
  const [toggleAttachmentListView, setToggleAttachmentListView] =
    useState<boolean>(false);
  const [sendToSOI, setSendToSOI] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount>();
  const [selectedInvestmentTransaction, setSelectedInvestmentTransaction] =
    useState<SelectedInvestmentTransaction>();
  const [selectedMemoEntity, setSelectedMemoEntity] =
    useState<SelectedEntity>();
  const [currentMemoLineIndex, setCurrentMemoLineIndex] = useState<
    number | undefined
  >();
  const [selectedFundId, setSelectedFundId] = useState<string>('');
  const [selectedLedger, setSelectedLedger] = useState<any>();
  const [selectedLedgerCurrency, setSelectedLedgerCurrency] =
    useState<string>('');
  const [selectedLedgerDecimal, setSelectedLedgerDecimal] = useState<number>();
  const previousLedgerDecimal = useRef<number>();
  const [totalError, setTotalError] = useState<boolean>(false);
  const [refetchAccountFilter, setRefetchAccountFilter] =
    useState<boolean>(false);
  const [lockIsAdjusting, setLockIsAdjusting] = useState(false);
  const [ledgerList, setLedgerList] = useState<any[]>([]);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [entityList, setEntityList] = useState<any[]>([]);
  const [groupedEntityList, setGroupedEntityList] = useState<any[]>([]);
  const [investmentTransactionList, setInvestmentTransactionList] = useState<
    any[]
  >([]);
  const [saveButtonOptions, setSaveButtonOptions] = useState<ImageItem[]>(
    SaveJournalEntryOptionsDefault
  );
  const [showPostDialog, setShowPostDialog] = useState<boolean>(false);
  const [journalEntriesToPost, setJournalEntriesToPost] = useState<string[]>(
    []
  );
  const [isSendToSOIDisabled, setIsSendToSOIDisabled] =
    useState<boolean>(false);
  const [sendToSOITooltip, setSendToSOITooltip] = useState<string>();
  const [
    showAssociatedInvTransactionNotice,
    setShowAssociatedInvTransactionNotice,
  ] = useState<boolean>(false);

  const postAction = POST_ACTION_TYPE.POST_SELECTED;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    control,
    setValue,
    watch,
    reset,
  } = useForm<ArkGLJournalEntry>({
    defaultValues: journalEntry ?? JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
    mode: 'all',
  });
  const { isDirty } = useFormState({ control });

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const fieldValues = useWatch({
    name: 'lineItems',
    control,
  });

  const watchFieldValues = watch('lineItems');

  const controlledFields = fieldValues.map((field: any, index: number) => {
    return {
      ...field,
      ...watchFieldValues[index],
    };
  });

  const totalDebit = controlledFields
    ? controlledFields.reduce(
        (total: number, current: JournalEntryLineItem) => {
          let value;

          if (current.amount && current.entryType === 'DEBIT') {
            value = total + current.amount;
          } else {
            value = total;
          }

          return Math.abs(value);
        },
        0
      )
    : journalEntry?.lineItems.reduce(
        (total: number, current: JournalEntryLineItem) => {
          let value;

          if (current.amount && current.entryType === 'DEBIT') {
            value = total + current.amount;
          } else {
            value = total;
          }

          return Math.abs(value);
        },
        0
      );

  const totalCredit = controlledFields
    ? controlledFields.reduce(
        (total: number, current: JournalEntryLineItem) => {
          let value;

          if (current.amount && current.entryType === 'CREDIT') {
            value = total + current.amount;
          } else {
            value = total;
          }

          return Math.abs(value);
        },
        0
      )
    : journalEntry?.lineItems.reduce(
        (total: number, current: JournalEntryLineItem) => {
          let value;

          if (current.amount && current.entryType === 'CREDIT') {
            value = total + current.amount;
          } else {
            value = total;
          }

          return Math.abs(value);
        },
        0
      );

  const fetchEntityList = async (isCanceled?: () => boolean) => {
    setIsLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;

      const response = await getEntities();

      const grouped: { type: string; entities: any[] }[] = [
        {
          type: 'Memo Entity',
          entities: [],
        },
        {
          type: 'Fund',
          entities: [],
        },
        {
          type: 'Investor',
          entities: [],
        },
        {
          type: 'Portfolio Company',
          entities: [],
        },
      ];

      response.items.forEach((entity: any) => {
        if (entity.type === 'PortfolioCompany') {
          entity.type = 'Portfolio Company';
        }
        if (entity.type === 'GeneralEntity') {
          entity.type = 'Memo Entity';
        }
      });

      setEntityList(response.items);

      response.items.map((entity: any) => {
        grouped
          .find((list) => list.type === entity.type)
          ?.entities.push(entity);
      });

      grouped.forEach((group) => {
        group.entities.sort((a, b) => a.name.localeCompare(b.name));
      });

      setGroupedEntityList([
        ...grouped[0].entities,
        ...grouped[1].entities,
        ...grouped[2].entities,
        ...grouped[3].entities,
      ]);
    } catch (e) {
      informationAlert(GET_ENTITY_LIST_ERROR, 'error');
    }
    setIsLoading(undefined);
  };

  const fetchInvestmentTransactions = async (isCanceled?: () => boolean) => {
    if (journalEntry && journalEntry.id) {
      setIsLoading(LoadingStatus.Loading);
      try {
        if (isCanceled?.()) return;

        const response = await getInvestmentTransactionsByJournalEntry(
          journalEntry.id
        );

        if (response.items.length > 0) {
          setInvestmentTransactionList(response.items);
          setIsSendToSOIDisabled(true);
          setSendToSOITooltip(DISABLE_SEND_TO_SOI_ALREADY_LINKED_TOOLTIP);
        }
      } catch (e) {
        informationAlert(GET_INV_TRANSACTION_LIST_ERROR, 'error');
      }
      setIsLoading(undefined);
    }
  };

  const fetchLedgerList = async (isCanceled?: () => boolean) => {
    setIsLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;

      const response = await getArkLedgers(selectedFundId || fundId);

      const ledgers = response.items
        .map((ledger: any) => {
          return {
            id: ledger.id,
            fundId: ledger.fundId,
            name: ledger.name,
            currency: ledger.currency,
            decimal: ledger.decimal,
          };
        })
        .sort();

      if (ledgers.length === 1) {
        setValue('ledgerId', ledgers[0].id);

        setSelectedLedger(ledgers[0]);
        setSelectedLedgerCurrency(ledgers[0].currency);
        setSelectedLedgerDecimal(ledgers[0].decimal);
      }

      if (journalEntry && journalEntry.ledgerId && ledgers.length === 1) {
        const matchingLedger = ledgers.find(
          (l: any) => l.id === journalEntry.ledgerId
        );

        if (!matchingLedger) {
          setSelectedLedger(ledgers[0]);
          setSelectedLedgerCurrency(ledgers[0].currency);
          setSelectedLedgerDecimal(ledgers[0].decimal);
        }
      }

      setLedgerList(ledgers);
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
      setLedgerList([]);
      setValue('ledgerId', '');
    }
    setIsLoading(undefined);
  };

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    if (fundId || selectedFundId || journalEntry?.fundId) {
      setIsLoading(LoadingStatus.Loading);
      try {
        if (isCanceled?.()) return;

        const response = await getArkAccounts(
          selectedFundId || fundId || journalEntry?.fundId!
        );

        const accounts = response.items
          .map((account: any) => {
            return {
              id: account.id,
              name: `${account.number} - ${account.name}`,
              isEntityRequired: account.isEntityRequired,
            };
          })
          .sort();

        if (!isStandishManagementBasicAdmin) {
          accounts.push({
            id: '+',
            name: 'Create new account',
            isEntityRequired: false,
          });
        }

        setAccountList(accounts);
      } catch (e) {
        informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
      }
      setIsLoading(undefined);
    } else {
      setAccountList([]);
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccountList(isCanceled);
    await fetchEntityList(isCanceled);
    await fetchLedgerList(isCanceled);

    if (journalEntry?.id) {
      fetchInvestmentTransactions();
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      await fetchAccountList(isCanceled);
      await fetchLedgerList(isCanceled);
    },
    [selectedFundId, journalEntry]
  );

  useEffect(() => {
    if (sendToSOI) {
      setSaveButtonOptions(SaveJournalEntryOptionsSOI);
    } else {
      setSaveButtonOptions(SaveJournalEntryOptionsDefault);
    }
  }, [sendToSOI]);

  useEffect(() => {
    if (!isNewJournalEntry && journalEntry) {
      const normalizedEntries = journalEntry?.lineItems.map((entry) => {
        return {
          ...entry,
          amount: entry.amount! / Math.pow(10, journalEntry.currencyDecimal!),
        };
      });

      journalEntry.lineItems = normalizedEntries;

      const currentFiles: any[] = [];

      if (journalEntry.attachments && journalEntry.attachments.length > 0) {
        Array.from(journalEntry.attachments).forEach((f) => {
          currentFiles.push({
            ...f,
          });
        });
      }
      journalEntry.currentAttachments = currentFiles;

      journalEntry.attachments = null;
    }

    if (
      !!selectedJournalEntry?.connectTransactionToJournalEntry
        ?.investmentTransactionIds
    ) {
      setIsSendToSOIDisabled(true);
      setSendToSOITooltip(DISABLE_SEND_TO_LOOP_TOOLTIP);
    }

    if (selectedJournalEntry?.multiView) {
      if (selectedJournalEntry?.multiView.totalCount <= 1) {
        setSaveButtonOptions(SaveJournalEntryOptionsSingleView);
      } else {
        setSaveButtonOptions(SaveJournalEntryOptionsMultiView);
      }
    } else if (selectedJournalEntry?.singleView) {
      setSaveButtonOptions(SaveJournalEntryOptionsSingleView);
    }
  }, []);

  useEffect(() => {
    if (!isNewJournalEntry && journalEntry && accountList.length > 0) {
      const normalizedEntries = journalEntry?.lineItems.map((entry, index) => {
        const account = accountList.find((acc) => acc.id === entry.accountId);

        return {
          ...entry,
          isEntityRequired: account?.isEntityRequired,
        };
      });

      setValue('lineItems', normalizedEntries);
    }
  }, [accountList]);

  useEffect(() => {
    reset();
    setTotalError(false);
  }, [reset]);

  useEffect(() => {
    const currencyDecimal =
      selectedLedgerDecimal ?? journalEntry?.currencyDecimal;

    if (currencyDecimal !== undefined && currencyDecimal !== null) {
      Math.round(totalCredit * Math.pow(10, currencyDecimal)) !==
      Math.round(totalDebit * Math.pow(10, currencyDecimal))
        ? setTotalError(true)
        : setTotalError(false);
    }

    if (!totalCredit || !totalDebit) {
      setTotalError(true);
    }
  }, [totalDebit, totalCredit]);

  const handleDeleteLine = (id: string, index: number) => {
    const currentLength = watchFieldValues.length;

    if (
      !watchFieldValues[index].accountId &&
      !watchFieldValues[index].entryMemo &&
      !watchFieldValues[index].entityIds &&
      !watchFieldValues[index].amount &&
      index === currentLength - 1
    ) {
      if (currentLength > 2) {
        const current = [...watchFieldValues];

        const updated = current.filter((field: any, fieldIndex: number) => {
          if (index !== fieldIndex) {
            return {
              ...field,
              ...watchFieldValues[index],
            };
          }
        });

        setValue('lineItems', [...updated]);
      }
    } else {
      update(index, {
        ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      });
      setValue(`lineItems.${index}.entities`, []);
    }
  };

  const handleAddLine = () => {
    const line = {
      ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      index: watchFieldValues.length + 1,
    };

    append(line);
  };

  const handleClearAllLines = () => {
    setShowClearAllConfirmation(true);
  };

  const handleConfirmClearAll = () => {
    const currentLength = watchFieldValues.length;

    remove();
    setShowClearAllConfirmation(false);

    for (let i = 0; i < currentLength; i++) {
      const line = {
        ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
        index: i + 1,
      };

      append(line);
    }
  };

  const handleCancelClearAll = () => setShowClearAllConfirmation(false);

  const connectAllTransactionsToJournalEntry = async (
    id: string,
    transactionIds: string[]
  ) => {
    try {
      setIsLoading(LoadingStatus.Connecting);
      await connectTransactionsToJournalEntry(id, {
        transactionIds: transactionIds,
      });

      informationAlert(CONNECT_TRANSACTION_SUCCESS, 'success');
    } catch (e) {
      informationAlert(CONNECT_TRANSACTION_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const createNewJournalEntry = async (data: ArkGLJournalEntry, e: any) => {
    try {
      const files: any[] =
        journalEntry?.currentAttachments &&
        journalEntry.currentAttachments.length > 0
          ? [...journalEntry.currentAttachments].map((a) => {
              return {
                filename: a.documentMemo,
                documentMemo: a.documentMemo,
              };
            })
          : [];

      if (data.attachments && data.attachments.length > 0) {
        Array.from(data.attachments).forEach((f) => {
          files.push({
            filename: f.name,
            documentMemo: f.name,
          });
        });
      }

      if (!checkFileNames(files)) {
        return;
      }

      const request: any = {
        adjustingJournalEntry: data.adjustingJournalEntry,
        reference: data.reference,
        lineItems: data.lineItems,
        memo: data.memo,
        ledgerId: data.ledgerId,
        date: data.date,
        attachments: files ? files : null,
      };

      const createdJournalEntry = await createJournalEntry(
        request,
        data.attachments ? data.attachments : null
      );

      informationAlert(CREATE_JOURNAL_ENTRY_SUCCESS, 'success');

      if (
        selectedJournalEntry.connectTransactionToJournalEntry &&
        selectedJournalEntry.connectTransactionToJournalEntry
          .investmentTransactionIds
      ) {
        await connectAllTransactionsToJournalEntry(
          createdJournalEntry.id,
          selectedJournalEntry.connectTransactionToJournalEntry
            .investmentTransactionIds
        );
      }

      if (
        selectedJournalEntry.connectCashReceiptToJournalEntry &&
        selectedJournalEntry.connectCashReceiptToJournalEntry
          .cashReceiptTransactionIds &&
        selectedJournalEntry.connectCashReceiptToJournalEntry
          .cashReceiptTransactionIds.length > 0
      ) {
        await connectAllTransactionsToJournalEntry(
          createdJournalEntry.id,
          selectedJournalEntry.connectCashReceiptToJournalEntry
            .cashReceiptTransactionIds
        );
      }

      if (
        selectedJournalEntry.connectAdjustmentToJournalEntry &&
        selectedJournalEntry.connectAdjustmentToJournalEntry
          .cashReceiptTransactionIds &&
        selectedJournalEntry.connectAdjustmentToJournalEntry
          .cashReceiptTransactionIds.length > 0
      ) {
        await connectCashReceiptAdjustmentToJournalEntry(
          createdJournalEntry.id,
          {
            adjustments: [
              {
                transactionId:
                  selectedJournalEntry.connectAdjustmentToJournalEntry
                    .cashReceiptTransactionIds[0],
                adjustmentIds:
                  selectedJournalEntry.connectAdjustmentToJournalEntry
                    .adjustmentIds,
              },
            ],
          }
        );
      }

      onSave(data, e, createdJournalEntry.id);
    } catch (error) {
      informationAlert(CREATE_JOURNAL_ENTRY_ERROR, 'error');
    }
  };

  const editJournalEntry = async (data: ArkGLJournalEntry, e: any) => {
    const files: any[] =
      journalEntry?.currentAttachments &&
      journalEntry.currentAttachments.length > 0
        ? [...journalEntry.currentAttachments].map((a) => {
            return {
              filename: a.documentMemo,
              documentMemo: a.documentMemo,
            };
          })
        : [];

    if (data.attachments && data.attachments.length > 0) {
      Array.from(data.attachments).forEach((f) => {
        files.push({
          filename: f.name,
          documentMemo: f.name,
        });
      });
    }

    if (!checkFileNames(files)) {
      return;
    }

    const request: any = {
      date: data.date,
      reference: data.reference,
      memo: data.memo,
      adjustingJournalEntry: data.adjustingJournalEntry,
      ledgerId: data.ledgerId,
      attachments: files ? files : null,
      lineItems: data.lineItems,
    };

    if (journalEntry && journalEntry.id) {
      try {
        await updateJournalEntry(
          journalEntry.id,
          request,
          data.attachments ? data.attachments : null
        );
        informationAlert(UPDATE_JOURNAL_ENTRY_SUCCESS, 'success');
        onSave(data, e, journalEntry.id);
      } catch (error) {
        informationAlert(UPDATE_JOURNAL_ENTRY_ERROR, 'error');
      }
    }
  };

  const onSubmit = async (data: any, e?: any) => {
    if (totalError) {
      return;
    }

    const dataValues = getValues();

    let entityRequiedError = false;

    dataValues.lineItems.map((line) => {
      if (line.isEntityRequired && line.entities!.length === 0) {
        entityRequiedError = true;
      }
    });
    if (entityRequiedError) {
      return;
    }

    data.date = data.date.toString().match(/^\d{4}-?\d{2}-?\d{2}$/)
      ? data.date
      : DateTimeFormat.getReversedDate(new Date(data.date));

    const currencyDecimal = selectedLedgerDecimal || data.currencyDecimal;

    const normalizedLineItems = data.lineItems
      .map((line: any, index: any) => {
        const entityIds = dataValues.lineItems[index].entities?.map(
          (entity) => entity.id
        );

        if (line.accountId && line.amount) {
          return {
            entryType: line.entryType,
            accountId: line.accountId,
            entryMemo: line.entryMemo,
            amount: Math.round(line.amount * Math.pow(10, currencyDecimal)),
            entityId: null,
            entityIds,
          };
        }
      })
      .filter((item: any) => item);

    data.lineItems = normalizedLineItems;

    if (isNewJournalEntry) {
      if (!data.fundId) {
        data.fundId = fundId || selectedFundId;
      }

      setIsLoading(LoadingStatus.Adding);
      await createNewJournalEntry(data, e);
    } else {
      setIsLoading(LoadingStatus.Updating);
      await editJournalEntry(data, e);
    }
    setIsLoading(undefined);
  };

  const onSave = (data: any, e: any, journalEntryId: string) => {
    switch (e) {
      case 'btn_journal_entry_save_and_new':
        {
          const line = {
            ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
            index: watchFieldValues.length + 1,
          };

          remove();
          reset({ ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE });

          setSelectedJournalEntry({
            journalEntry: { ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE },
            type: DetailsType.New,
          });
          setLockIsAdjusting(false);
        }
        break;
      case 'btn_journal_entry_save_and_sendtosoi':
        {
          let portcoIds: any[] = [];
          const entities: any[] = [];

          data.lineItems.map((lineItem: any) => {
            lineItem.entityIds.map((e: any) => {
              entities.push(e);
            });
          });

          if (entities && entities.length) {
            portcoIds = entities
              .map((entity: any) => {
                const match = entityList?.find(
                  (portco) =>
                    portco.type === 'Portfolio Company' && portco.id === entity
                );

                if (match) return match;
              })
              .filter((item: any) => item);
          }

          const fund = entityList?.find((entity) => entity.id === data.fundId);

          const currency =
            selectedLedgerDecimal || journalEntry?.currencyDecimal;

          setSelectedInvestmentTransaction({
            investmentTransactionType: '',
            investmentTransaction: {
              tenantId: clientId,
              date: new Date(data.date),
              fund: {
                id: fund.id,
                name: fund ? fund.name : null,
                tenantId: clientId,
              },
              portfolioCompany: portcoIds.length
                ? {
                    id: portcoIds[0].id,
                    name: portcoIds[0].name,
                  }
                : null,
              totalAmount: currency
                ? data.lineItems[0].amount / Math.pow(10, currency)
                : 0,
            },
            type: DetailsType.New,
            newView: InvestmentTransactionNewView.Purchase,
            connectTransactionToJournalEntry: {
              journalEntryId: journalEntryId,
            },
          });
        }
        setLockIsAdjusting(false);
        break;
      case 'btn_journal_entry_save_and_next':
        selectedJournalEntry?.multiView.onSaveAndNext();
        break;
      case 'btn_journal_entry_save':
      default:
        if (investmentTransactionList.length) {
          setShowAssociatedInvTransactionNotice(true);
          return;
        }

        if (selectedJournalEntry.multiView) {
          selectedJournalEntry?.multiView.onSave();

          if (selectedJournalEntry.multiView.totalCount > 1) {
            selectedJournalEntry?.multiView.setShowExitConfirmation(true);
          } else {
            closeDrawer(Boolean(journalEntryId));
            onSuccessfulPostClose?.();
          }
        } else {
          closeDrawer(Boolean(journalEntryId));
          onSuccessfulPostClose?.();
        }

        setLockIsAdjusting(false);
        break;
    }
  };

  const handleBulkOptionClick = (selectedOption: string) => {
    if (journalEntry) {
      switch (selectedOption) {
        case 'make_recurring':
          //TODO Make recurring
          // eslint-disable-next-line no-console
          console.log('Make Recurring');
          break;
        case 'copy':
          {
            delete journalEntry?.id;
            delete journalEntry?.journalId;

            const newJournalEntry = {
              ...journalEntry,
              date: null,
              state: 'DRAFT',
            };

            reset(newJournalEntry);

            setSelectedJournalEntry({
              journalEntry: newJournalEntry,
              type: DetailsType.New,
            });
          }
          break;
        case 'reverse':
          {
            delete journalEntry?.id;
            delete journalEntry?.journalId;

            const newJournalEntry = {
              ...reverseJournalEntry(journalEntry),
              state: 'DRAFT',
              adjustingJournalEntry: true,
            };

            setSelectedLedger(newJournalEntry.ledgerId);

            reset(newJournalEntry);
            setLockIsAdjusting(true);

            setSelectedJournalEntry({
              journalEntry: newJournalEntry,
              type: DetailsType.New,
            });
          }
          break;
        case 'post_to_gl':
          if (journalEntry && journalEntry.id) {
            setJournalEntriesToPost([journalEntry.id]);
            setShowPostDialog(true);
          }
          break;
      }
    }
  };

  const reverseJournalEntry = (entry: ArkGLJournalEntry) => {
    const reversedLineItems = entry.lineItems.map((lineItem, index) => {
      if (lineItem.entryType === 'CREDIT') {
        return {
          ...lineItem,
          entryType: 'DEBIT',
        };
      }

      if (lineItem.entryType === 'DEBIT') {
        return {
          ...lineItem,
          entryType: 'CREDIT',
        };
      }
    });

    return {
      ...entry,
      lineItems: reversedLineItems,
      lineItemsPosted: reversedLineItems,
    };
  };

  const handleLedgerUpdate = (ledger: any) => {
    if (!isNewJournalEntry && ledger !== ledgerId) {
      setSelectedLedger(ledger);
      setShowUpdateLedgerConfirmation(true);
    } else {
      updateLedger(ledger);
    }
  };

  const updateLedger = (ledger?: any) => {
    setValue('ledgerId', ledger);

    const ledgerInfo = ledgerList.find((glInfo) => glInfo.id === ledger);
    const selectedLedgerInfo = ledgerList.find(
      (glInfo) => glInfo.id === selectedLedger
    );

    if (!fundId) {
      if (ledgerInfo && ledgerInfo.fundId) {
        setSelectedFundId(ledgerInfo.fundId);
        setSelectedLedger(ledger);
      } else {
        if (selectedLedgerInfo && selectedLedgerInfo.fundId) {
          setSelectedFundId(selectedLedgerInfo.fundId);
          setSelectedLedger(selectedLedger);
        }
      }
    }

    const updatedFields = fieldValues.map((line, index) => {
      if (line.amount && previousLedgerDecimal.current) {
        return {
          ...line,
          amount:
            Math.round(
              line.amount * Math.pow(10, previousLedgerDecimal.current)
            ) / Math.pow(10, selectedLedgerDecimal!),
        };
      } else {
        return { ...line };
      }
    });

    setValue('lineItems', updatedFields);
  };

  const toggleDrawer = () => {
    if (
      isDirty ||
      !!selectedJournalEntry?.connectTransactionToJournalEntry
        ?.investmentTransactionIds
    ) {
      if (selectedJournalEntry.multiView) {
        selectedJournalEntry.multiView?.setShowExitConfirmation(true);
      } else {
        setShowExitConfirmation(true);
      }
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = (jounralEntryId: boolean = false) => {
    if (isNewJournalEntry && !jounralEntryId) {
      onClose(true);
    } else {
      onClose();
    }

    if (selectedJournalEntry.multiView) {
      selectedJournalEntry.multiView?.setShowExitConfirmation(false);
    } else {
      setShowExitConfirmation(false);
    }
    fetchAllJournalEntries();
  };

  const keepDrawerOpen = () => {
    if (selectedJournalEntry.multiView) {
      selectedJournalEntry.multiView?.setShowExitConfirmation(false);
    } else {
      setShowExitConfirmation(false);
    }
  };

  const updateLedgerConfirm = () => {
    setShowUpdateLedgerConfirmation(false);
    updateLedger(selectedLedger);
  };

  const updateLedgerCancel = () => {
    setShowUpdateLedgerConfirmation(false);
  };

  const handleAddNewAccount = (index: number) => {
    setValue(`lineItems.${index}.accountId`, '');
    setSelectedAccount({
      account: undefined,
      type: DetailsType.New,
    });
  };

  const onAccountDetailsPanelClose = async () => {
    await fetchAccountList();
    setSelectedAccount(initialAccount);
    setRefetchAccountFilter(true);
  };

  const onInvestmentTransactionPanelClose = () => {
    setSelectedInvestmentTransaction(initialInvestmentTransaction);
    closeDrawer();
    fetchAllJournalEntries();
  };

  const onMemoEntityPanelClose = () => {
    setSelectedMemoEntity(initialMemoEntity);
  };

  const onMemoEntityCreate = async (id: string) => {
    if (currentMemoLineIndex !== undefined) {
      fetchEntityList();

      const latestEntityList = await getEntities();

      const newMemo = latestEntityList.items.find((e: any) => e.id === id);

      setValue(`lineItems.${currentMemoLineIndex}.entities`, [
        {
          id: id,
          name: newMemo.name,
        },
        ...fieldValues[currentMemoLineIndex].entities!,
      ]);
      setCurrentMemoLineIndex(undefined);
    }
  };

  const status = watch('state');
  const ledgerId = watch('ledgerId');

  const handleSaveButtonAction = (actionId: string, event: any) => {
    const data: any = getValues();

    if (!errors.hasOwnProperty('memo')) {
      trigger();

      for (const key in data) {
        if (data[key] === undefined || null || '') {
          delete data[key];
        }
      }

      if (data.date && data.ledgerId && data.lineItems.length) {
        onSubmit(data, actionId);
      }
    }
  };

  const handleCreateNewMemoEntityButtonAction = (lineIndex: number) => {
    setSelectedMemoEntity({
      entity: undefined,
      type: DetailsType.New,
    });

    setCurrentMemoLineIndex(lineIndex);
  };

  const onDeleteAttachment = (documentId: string) => {
    setShowDeleteAttachmentConfirmation(true);
    setSelectedDeleteAttachment(documentId);
  };

  const handleDownloadAttachment = async (filename: string) => {
    setIsLoading(LoadingStatus.Downloading);
    try {
      const downloadedData = await getJournalEntryAttachment(
        journalEntry!.id!,
        filename
      );

      const name = filename.split('.')?.[0];
      const reg = /(?:\.([^.]+))?$/;
      const ext = reg.exec(filename!);
      const extension = ext ? ext[1] : undefined;

      if (name && extension) {
        downloadFile(downloadedData, name, extension);
      }
    } catch (e) {
      informationAlert(DOWNLOAD_ATTACHMENT_ERROR, 'error');
    }
    setIsLoading(undefined);
  };

  const onDeleteConfirm = async () => {
    try {
      setIsLoading(LoadingStatus.Deleting);
      if (selectedDeleteAttachment) {
        if (journalEntry && journalEntry.id) {
          const updatedAttachments: any[] = [
            ...journalEntry.currentAttachments!,
          ]
            .filter((a) => a.documentMemo !== selectedDeleteAttachment)
            .map((a) => {
              return {
                filename: a.documentMemo,
                documentMemo: a.documentMemo,
              };
            });

          delete journalEntry.currentAttachments;

          const normalizedLineItems = journalEntry.lineItems.map((line) => {
            const entityIds = line.entities?.map((entity) => entity.id);

            return {
              entryType: line.entryType,
              accountId: line.accountId,
              entryMemo: line.entryMemo,
              amount:
                line.amount &&
                Math.round(
                  line.amount * Math.pow(10, journalEntry.currencyDecimal!)
                ),
              entityId: null,
              entityIds,
            };
          });

          const request: any = {
            date: journalEntry.date,
            reference: journalEntry.reference,
            memo: journalEntry.memo,
            adjustingJournalEntry: journalEntry.adjustingJournalEntry,
            ledgerId: journalEntry.ledgerId,
            attachments: updatedAttachments,
            lineItems: normalizedLineItems,
          };

          await updateJournalEntry(journalEntry.id, request, null);
          setShowDeleteAttachmentConfirmation(false);
          setSelectedDeleteAttachment(undefined);
          informationAlert(DELETE_ATTACHMENT_SUCCESS, 'success');
          closeDrawer();
        }
      }
    } catch (error) {
      setShowDeleteAttachmentConfirmation(false);
      setSelectedDeleteAttachment(undefined);
      informationAlert(DELETE_ATTACHMENT_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const onDeleteCancel = () => {
    setShowDeleteAttachmentConfirmation(false);
    setSelectedDeleteAttachment(undefined);
  };

  const checkFileNames = (files: any) => {
    if (files) {
      for (const f in files) {
        if (files[f].filename.length > 64) {
          informationAlert(JOURNAL_ENTRY_FILENAME_SIZE_ERROR, 'error');
          return false;
        }
      }
    }

    return true;
  };

  const onPostCancel = () => {
    setShowPostDialog(false);
    setJournalEntriesToPost([]);
  };

  const onPostComplete = () => {
    setSelectedJournalEntry(undefined);
    setShowPostDialog(false);
    setJournalEntriesToPost([]);
  };

  const reviewSuggestedInvestmentTransaction = () => {
    const existingTransaction = investmentTransactionList[0];
    const data: any = getValues();
    const fund = existingTransaction.fund;
    const portfolioCompany = existingTransaction.portfolioCompany;

    setShowAssociatedInvTransactionNotice(false);

    setSelectedInvestmentTransaction({
      investmentTransactionType: existingTransaction.investmentTransactionType,
      investmentTransaction: {
        ...existingTransaction,
        date: new Date(data.date),
        fund: fund
          ? {
              id: fund.id,
              name: fund.name,
              tenantId: fund.clientId,
            }
          : null,
        portfolioCompany: portfolioCompany
          ? {
              id: portfolioCompany.id,
              name: portfolioCompany.name,
            }
          : null,
        totalAmount: data.lineItems[0].amount,
        amountPerQuantity:
          data.lineItems[0].amount / existingTransaction.quantity,
      },
      type: DetailsType.Edit,
      fromView: FROM_VIEW.JOURNAL_ENTRIES,
    });
  };

  return {
    isLoading,
    register,
    handleSubmit,
    setValue,
    control,
    fields,
    fieldValues,
    watchFieldValues,
    controlledFields,
    totalCredit,
    totalDebit,
    handleClearAllLines,
    handleConfirmClearAll,
    handleCancelClearAll,
    handleAddLine,
    handleDeleteLine,
    showClearAllConfirmation,
    closeDrawer,
    keepDrawerOpen,
    toggleDrawer,
    showExitConfirmation,
    onSubmit,
    sendToSOI,
    setSendToSOI,
    handleBulkOptionClick,
    status,
    accountList,
    selectedFundId,
    setSelectedFundId,
    totalError,
    handleAddNewAccount,
    selectedAccount,
    onAccountDetailsPanelClose,
    refetchAccountFilter,
    selectedLedgerCurrency,
    setSelectedLedgerCurrency,
    selectedLedgerDecimal,
    setSelectedLedgerDecimal,
    previousLedgerDecimal,
    selectedInvestmentTransaction,
    setSelectedInvestmentTransaction,
    onInvestmentTransactionPanelClose,
    showUpdateLedgerConfirmation,
    updateLedgerCancel,
    updateLedgerConfirm,
    handleLedgerUpdate,
    handleSaveButtonAction,
    saveButtonOptions,
    onSave,
    lockIsAdjusting,
    setSelectedLedger,
    selectedLedger,
    ledgerList,
    entityList,
    groupedEntityList,
    onDeleteAttachment,
    onDeleteCancel,
    onDeleteConfirm,
    selectedDeleteAttachment,
    showDeleteAttachmentConfirmation,
    handleDownloadAttachment,
    toggleAttachmentListView,
    setToggleAttachmentListView,
    isSendToSOIDisabled,
    sendToSOITooltip,
    postAction,
    showPostDialog,
    journalEntriesToPost,
    onPostCancel,
    onPostComplete,
    showAssociatedInvTransactionNotice,
    setShowAssociatedInvTransactionNotice,
    reviewSuggestedInvestmentTransaction,
    selectedMemoEntity,
    setSelectedMemoEntity,
    onMemoEntityPanelClose,
    onMemoEntityCreate,
    handleCreateNewMemoEntityButtonAction,
  };
};
