import { IconButton, Switch, Typography } from '@mui/material';
import  React from 'react';

import IconConfig from "../../../assets/images/icons/icon_config.svg";
import ArrowPopover from '../../ArrowPopover/ArrowPopover';
import { DandDitem, DragAndDropList } from '../../DragAndDropList/DragAndDropList';
import ImgIcon from '../../ImgIcon/ImgIcon';
import { CategoryGearBox, CategoryHeaderBox, CategoryHeaderTypography, DragAndDropItemLabelBox, DragAndDropItemSwitchBox, DragAndDropItemWrapper, DragAndDropListWrapper } from './CategorySelectionPopover.styles';

type Props = {
    dAndDitems: DandDitem[],
    onChange: (items: DandDitem[]) => void,
    onVisibleChange: (itemId: string) => void
};

export const CategorySelectionPopover: React.FC<Props> = ({ dAndDitems, onChange, onVisibleChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleOnClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
      event?.preventDefault();
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
  
    const handleOnClose = (): void => {
      setAnchorEl(null);
    };

    return (
        <>
            <CategoryHeaderBox>
                <CategoryHeaderTypography>Categories</CategoryHeaderTypography>
                <CategoryGearBox
                    id="category_header_grear_box"
                    onClick={handleOnClick}
                >
                    <IconButton
                        id="column_config"
                        aria-label="Column Configuration"
                        color="inherit"
                        title="Column Configuration"
                    >
                        <ImgIcon icon={IconConfig} useCursorPointer />
                    </IconButton>
                </CategoryGearBox>
            </CategoryHeaderBox>

            <ArrowPopover
                id={'category_header'}
                anchorEl={anchorEl}
                showPopover={Boolean(anchorEl)}
                handleOnPopoverClose={handleOnClose}
                verticalOrigin="top"
                content={ 
                    <DragAndDropListWrapper>
                        <DragAndDropList
                            items={dAndDitems}
                            onChange={onChange}
                            onItemRender={ item => 
                                <DragAndDropItemWrapper>
                                    <DragAndDropItemLabelBox>
                                        <Typography variant="label">{item.name}</Typography>
                                    </DragAndDropItemLabelBox>

                                    <DragAndDropItemSwitchBox>
                                        <Switch
                                            id={"swtch_status_".concat(item.id)}
                                            checked={item.data.visible}
                                            onChange={() => onVisibleChange(item.id)}
                                        />
                                    </DragAndDropItemSwitchBox>
                                </DragAndDropItemWrapper>
                            }
                        />
                    </DragAndDropListWrapper>
                }
            />

        </>
    );
};