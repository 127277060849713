import { Box, Container, FormHelperText, styled } from "@mui/material";

import DataGrid from "../../../components/DataGrid/DataGrid";

export const ButtonBox = styled(Box)`
  display: flex;
`;

export const ContactActionsContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 0px;

  .MuiFormControl-root {
    padding-left: 0px;
    margin-left: 0px;
    max-width: 400px;
  }
`;

export const TimeLabel = styled(FormHelperText)`
  margin-bottom: 6px;
`;

export const StyledDataGrid = styled(DataGrid)`
  .MuiButtonGroup-root {
    width: 170px !important;
    margin-right: 0px;
  }
  .MuiDataGrid-cell--withRenderer .MuiButton-outlinedPrimary {
    width: 170px;
  }
  .MuiDataGrid-columnHeader:focus-within{
    outline: none;
  }
  .MuiDataGrid-cell--withRenderer:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .MuiDataGrid-columnHeader:last-child
    .MuiDataGrid-columnHeaderDraggableContainer
    .MuiDataGrid-columnHeaderTitleContainer {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
    .MuiDataGrid-columnHeaderTitleContainer:focus {
      border: none !important;
    }
  }
`;

export const InfoGridBox = styled(Box)`
  max-height: 76vh;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const StyledBox = styled(Box)(({ theme }) => ({
  marginRight: "12px",
}));
