import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import React from 'react';
import { Controller } from "react-hook-form";

import {
  StyledDialog,
  StyledDialogTitle,
} from '../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog.styles';
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import { ClientTransTypeColumn } from "../../../../utils/types/clientTransMapping.type";
import { StyledFormLabel, StyledOrderTextField, StyledStack } from "./AddColumn.styles";
import { DATE_RANGE, useAddColumns } from "./useAddColumns.hooks";

interface Props {
    open: boolean,
    onCancel: () => void,
    onSave: (data: ClientTransTypeColumn) => void,
    column?: ClientTransTypeColumn;
    maxColumnOrder: number;
}

const AddColumn = ({
  column,
  open,
  onCancel,
  onSave,
  maxColumnOrder
}: Props) => {

  const {
    handleSubmit,
    control,
    addUpdatedColumn,
    handleCancel
  } = useAddColumns({
    column,
    onSave,
    onCancel
  });

  return (
    <StyledDialog
      open={open}
      id="add_column_dialog"
      aria-labelledby="add-column-dialog"
      aria-describedby="add-column-dialog"
      PaperProps={{
        elevation: 0,
      }}
    >
      <StyledDialogTitle>
          Add Column or Metric
      </StyledDialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(addUpdatedColumn)}
          noValidate
        >
          <StyledStack spacing={2}>
            <Controller
              name="useMetric"
              render={({
                field: {
                  onChange, value
                },
                fieldState: {
                  error
                }
              }) => (
                <FormControl>
                  <StyledFormLabel id="column_type_label" >
                    <strong>What type of column is this?</strong>
                  </StyledFormLabel>
                  <RadioGroup
                    row
                    onChange={(event) => {
                      onChange(event.target.value === "true");
                    }}
                    value={value === true ? "true" : "false"}
                  >
                    <FormControlLabel
                      value="true"
                      id="metric_option"
                      control={<Radio />}
                      label="Metric" />
                    <FormControlLabel
                      value="false"
                      id="column_option"
                      control={<Radio />}
                      label="Column" />
                  </RadioGroup>
                  {!!error && <FormHelperText
                    error={!!error}>{error.message}</FormHelperText>}
                </FormControl>
              )}
              rules={{
                validate: (value) => {
                  if (value === null || value === undefined) {
                    return "Type is required";
                  }
                  return true;
                }
              }}
              control={control}
            />
            <Controller
              name="dateRange"
              render={({
                field: {
                  onChange, value
                },
                fieldState: {
                  error
                }
              }) => {
                return (
                  <SingleSelect
                    id="date_range"
                    noGutter
                    fullWidth
                    optionList={DATE_RANGE}
                    idField="id"
                    labelField="name"
                    handleOptionSelection={(event: SelectChangeEvent) => {
                      onChange({
                        target: {
                          value: event.target.value
                        }
                      });
                    }}
                    value={value ?? ''}
                    error={!!error}
                    helperText={error?.message ?? ''}
                    label="Date Range" />
                );
              }}
              rules={{
                required: "Date Range is required",
              }}
              control={control}
            />
            <Controller
              name="label"
              render={({
                field: {
                  onChange, value
                },
                fieldState: {
                  error
                }
              }) => {
                return (
                  <TextField
                    label="Label"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value ?? ""}
                    onChange={onChange}
                    id="label"
                    error={!!error}
                    helperText={error?.message ?? ""}
                  />
                );
              }}
              rules={{
                required: "Label is required",
              }}
              control={control}
            />
            <Controller
              name="code"
              render={({
                field: {
                  onChange, value
                }
              }) => {
                return (
                  <TextField
                    label="Code"
                    disabled
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value ?? ""}
                    onChange={onChange}
                    id="code"
                  />
                );
              }}
              control={control}
            />
            <Controller
              name="index"
              render={({
                field: {
                  onChange, value
                },
                fieldState: {
                  error
                }
              }) => {
                return (
                  <StyledOrderTextField
                    label="Column Order"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={value ?? ""}
                    onChange={onChange}
                    id="column_order"
                    error={!!error}
                    helperText={error?.message ?? ""}
                  />
                );
              }}
              rules={{
                required: "Column Order is required",
                max: {
                  value: maxColumnOrder,
                  message: `Column Order cannot be greater than ${maxColumnOrder}`,
                },
              }}
              control={control}
            />
            <Button
              id="ok_button"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleSubmit(addUpdatedColumn);
              }}
              fullWidth
            >
            Ok
            </Button>
            <Button
              id="cancel_button"
              variant="outlined"
              color="error"
              onClick={handleCancel}
              fullWidth
            >
                Cancel
            </Button>
          </StyledStack>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddColumn;
