import { FieldValues } from "react-hook-form";

import { PageInfo } from "./common.type";
import { CustomProperty } from "./customProperty.type";
import { DetailsType } from "./form.type";

type StatusTypes = "active" | "inactive";

type InvestmentType = {
  id: string;
  label: string;
};

type MailingAddress = {
  id: string | null;
  street1: string;
  street2: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  tenantId: null | string;
};

type Fund = {
  active: boolean;
  currency: string;
  entityType: null | string;
  fax: null | string;
  fee: null | string;
  fullName: string;
  fundFamily: string;
  id: string;
  inceptionDate: string;
  investmentGroups: any[];
  legalType: string;
  mailingAddress: MailingAddress;
  name: string;
  order: null | string;
  phone: null | string;
  reportTemplate: null | string;
  reportingPeriod: null | string;
  shortName: null | string;
  taxIdentificationNumber: null | string;
  tenantId: string;
  type: null;
  wireInfo: null | any;
};

export default StatusTypes;

export type PortfolioCompanySimple = {
  id: string;
  name: string;
};

export type SecurityTypeSimple = {
  id: string;
  name: string;
};

export type CustomPropertyTypeSimple = {
  id: string;
  name: string;
};

export type AdditionalProperty = {
  id: string | null;
  values?: any[] | null;
  value?: any | null;
  customProperty: CustomProperty;
};

export type PortfolioCompanyResponse = {
  items: PortfolioCompany[];
  pageInfo: PageInfo;
};

export type PortfolioCompany = {
  id: string;
  tenantId: string;
  about: string;
  additionalFieldsForm: AdditionalProperty[] | null;
  additionalProperties: AdditionalProperty[] | null;
  holdingStatus: string;
  industrySector: string;
  funds: any[];
  fundIds?: any[];
  ceo: string;
  isPublic: boolean;
  logo?: File | string | null;
  name: string;
  numOfEmployees: number;
  tickerSymbol: string;
  address: MailingAddress;
  investmentTypes: InvestmentType[];
  website: null | string;
  yearFounded: number | null;
};

export type PortfolioCompanyNew = {
  id: string;
  tenantId: string;
  about: string;
  additionalFieldsForm: AdditionalProperty[] | null;
  additionalProperties: AdditionalProperty[] | null;
  holdingStatus: string;
  industrySector: string;
  funds: any[];
  fundIds?: any[];
  ceo: string;
  isPublic: boolean;
  logo?: File | string | null;
  name: string;
  numOfEmployees: number;
  tickerSymbol: string;
  address: MailingAddress;
  investmentTypes: InvestmentType[];
  website: null | string;
  yearFounded: number | null;
};

export type SelectedPortfolioCompany = {
  portfolioCompany?: PortfolioCompany;
  type?: DetailsType;
};

export enum PortfolioCompanyCRUDStatus {
  Adding = "Adding",
  Updating = "Updating",
  Deleting = "Deleting",
}

export enum ScheduleOfInvestmentAction {
  ExportAsCSV = "export_as_csv",
  ExportAsPDF = "export_as_pdf",
}
