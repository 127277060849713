import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent } from '@mui/material';
import { ButtonPropsVariantOverrides } from '@mui/material';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  InlineDialogActions,
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContentText,
  StyledDialogTitle,
  TitleBox,
} from './ConfirmationDialog.styles';

interface Action {
  // label: This is the text that will be displayed on the button
  label: string;

  // onClick: This is the function that will be called when the button is clicked
  onClick?: () => void;

  // linkTo: Make the button a link to different path in the app.
  linkTo?: string;

  // variant?: This is the variant of the button as per the Material UI Button component
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;

  // color?: This is the color of the button as per the Material UI Button component
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;

  // id: This is the id of the button
  id: string;

  // disabled?: This is the disabled state of the button
  disabled?: boolean;

  // show/hide button
  hide?: boolean;
}

interface Props {
  // open: This is the boolean that determines if the dialog is open or not
  open: boolean;

  // onClose: This is the function that closes the dialog
  onClose?: () => void;

  // title: This is the title of the dialog
  title?: string | React.ReactNode;

  // whitespace: This is whitespace wrapping of the content
  whitespace?: boolean | React.ReactNode;

  // content: This is the content of the dialog
  content?: string | React.ReactNode;

  // actions: This is the array of actions that will display the buttons on the dialog
  actions?: Array<Action>;

  // actionDisplayType: This is the display type of the action buttons if we want to display then stacked or inline, by default it is stacked.
  actionDisplayType?: 'inline' | 'stacked';

  // id: This is the id of the dialog
  id: string;

  // isTextContent: identifies if content is text or html
  isTextContent?: boolean;

  // showXbutton: flag to show close button in the upper right corner
  showXbutton?: boolean;
}

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  content,
  whitespace = false,
  actions,
  actionDisplayType = 'stacked',
  id,
  isTextContent = true,
  showXbutton = false,
}: Props) => {
  const DialogActionContainer =
    actionDisplayType === 'inline' ? InlineDialogActions : StyledDialogActions;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      id={id}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        elevation: 0,
      }}
    >
      <TitleBox>
        {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
        {showXbutton && <CloseIcon color="primary" onClick={onClose} />}
      </TitleBox>
      {content && (
        <DialogContent>
          {isTextContent ? (
            <StyledDialogContentText
              {...(whitespace ? { whiteSpace: 'pre-line' } : {})}
            >
              {content}
            </StyledDialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      )}
      <Box display="flex" justifyContent="center">
        <DialogActionContainer disableSpacing={actionDisplayType === 'stacked'}>
          {actions
            ?.filter((a) => !a.hide)
            .map((action) => {
              const componentProp = action.linkTo
                ? { component: Link, to: action.linkTo }
                : {};

              return (
                <StyledButton
                  key={action.id}
                  id={action.id}
                  variant={action?.variant ?? 'contained'}
                  color={action?.color ?? 'primary'}
                  onClick={() => action.onClick?.()}
                  disabled={action.disabled}
                  fullWidth
                  {...componentProp}
                >
                  {action.label}
                </StyledButton>
              );
            })}
        </DialogActionContainer>
      </Box>
    </StyledDialog>
  );
};

export default ConfirmationDialog;
