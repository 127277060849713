import { FormControl, TextField } from "@mui/material";
import { Box,styled } from "@mui/system";

export const TextLabelContainer = styled(Box)(({
  theme
}) => ({
  display: 'flex'
}));

export const Label = styled('label')(({
  theme
}) => ({
  marginTop: theme.spacing(1.5),
  marginLeft: theme.spacing(1)
}));

export const FormTextField = styled(TextField)(() => ({
  width: '60%',
}));

export const FormFieldWrapper = styled(Box)(({
  theme
}) => ({
  marginBottom: theme.spacing(4)
}));

export const MultipleCcEmail = styled('div')(({
  theme
})=>({
  display: "block",
  width:"auto",
  minHeight: "100px",
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0, 4, 0),
  borderRadius: theme.spacing(1),
  border:`1px solid ${theme.palette.secondary.dark}`
}));

export const ChipStyle=styled('span')(({
  theme
}) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));
