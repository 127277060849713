import { InvestorRowType } from "../../../../workbook.type";
import { CellFormats, ColWidth } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseColumnsManager } from "./BaseColumns.Manager";

export class InvestorColumnsManager extends BaseColumnsManager {
    public get startColumnId(): string {
        return this.investorTypeColId;
    }

    public get endColumnId(): string {
        return this.closeCommitmentColId;
    }
    
    public get investorTypeColId(): string {
        return 'A';
    }

    public get investorNameColId(): string {
        return 'B';
    }

    public get closeCommitmentColId(): string {
        return 'C';
    }

    //  just being consistant with how other column managers work
    public initInvestorColumnsData() {
        
    }
    
    public renderColumns(gridRowIds?: string[], gridColIds?: string[]): void {
        const grid = this.grid;
        const rowManager = this.investorRowManager;
        const investorRows = rowManager.investorRows;
        const typeCol = this.investorTypeColId;
        const nameCol = this.investorNameColId;
        const closeCol = this.closeCommitmentColId;

        this.setColumnStyle(typeCol, 'Type', ColWidth.smallText);
        this.setColumnStyle(nameCol, 'Partner Name', ColWidth.text);
        this.setColumnStyle(closeCol, 'Close Commitment', ColWidth.currency);

        const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

        grid.startUpdate();

        rowsToRender.forEach(ir => {
            this.setInvestorTypeTotalRowStyling(ir.gridRowId, closeCol, ir.rowType);

            const currGridRowId = ir.gridRowId;

            switch(ir.rowType) {
                case InvestorRowType.Investor: 
                    grid.setCellValue(currGridRowId, typeCol, ir.investorType, CellFormats.Text_Unformatted);
                    grid.setCellValue(currGridRowId, nameCol, ir.investorName, CellFormats.Text_Unformatted);
                    grid.setCellValue(currGridRowId, closeCol, ir.totalTransactionAmount, CellFormats.Number_Accounting);
                    break;
                case InvestorRowType.TypeTotal:
                    const typeStartEndPos = rowManager.getInvestorTypeRowCaptions(ir.investorType);

                    grid.setCellValue(currGridRowId, nameCol, `Total ${ir.investorType}`, CellFormats.Text_Unformatted);
                    grid.setCellFormula(currGridRowId, closeCol,
                        `SUM(${closeCol}${typeStartEndPos.startRow}:${closeCol}${typeStartEndPos.endRow})`, CellFormats.Number_Accounting);
                    break;
                case InvestorRowType.PartnerTotal: {
                    const typeTotalRows = investorRows
                            .filter(r => r.rowType === InvestorRowType.TypeTotal)
                            .map(r => `${closeCol}${grid.getRowCaption(r.gridRowId)}`)
                            .join(`,`);

                    grid.setCellValue(currGridRowId, nameCol, 'Total Partners', CellFormats.Text_Unformatted);
                    grid.setCellFormula(currGridRowId, closeCol, `SUM(${typeTotalRows})`, CellFormats.Number_Accounting);
                    break;
                }
            }
        });

        grid.endUpdate();
    }

    public containsColumn(colId: string): boolean {
        return colId === this.investorTypeColId || colId === this.investorNameColId || colId === this.closeCommitmentColId;
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        return false;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        return false;
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        return { validColumnPosition: false };
    }

    public onRowAdd(parentRowId: string): boolean {
        return false;
    }

    public onCanColDelete(colId: string): boolean {
        return false;
    }

    public onCanRowDelete(rowID: string): boolean {
        return false;
    }

    public onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }
}