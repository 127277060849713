import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import { addCustomProperty, deleteCustomProperty, getCustomProperties, getCustomPropertyTypes, updateCustomProperty } from "../../../../services/portfolioCompanies.service";
import { CUSTOM_PROPERTY_FORM_DEFAULT_VALUE } from "../../../../utils/constants/form.constants";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import { CustomProperty, CustomPropertyCRUDStatus, CustomPropertyDetailsViewType } from "../../../../utils/types/customProperty.type";
import { CREATE_CUSTOM_PROPERTY_ERROR, CREATE_CUSTOM_PROPERTY_SUCCESS, DELETE_CUSTOM_PROPERTY_ERROR, DELETE_CUSTOM_PROPERTY_SUCCESS, GET_CUSTOM_PROPERTY_LIST_ERROR, UPDATE_CUSTOM_PROPERTY_ERROR, UPDATE_CUSTOM_PROPERTY_SUCCESS } from "../PortfolioCompanyList.constants";
type Props = {
  customProperty?: CustomProperty;
  isNewCustomProperty: boolean;
  setSelectedCustomProperty: Function;
  fetchAllPortfolioCompanies: Function;
  onClose: Function;
}

export const useCustomFieldDetails = ({
  customProperty,
  isNewCustomProperty,
  setSelectedCustomProperty,
  fetchAllPortfolioCompanies,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState<CustomPropertyCRUDStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] = useState<boolean>(false);
  const [customPropertyList, setCustomPropertyList] = useState<any[]>([]);

  const {
    state,
    informationAlert
  } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    control,
    setValue,
    watch,
    reset
  } = useForm<CustomProperty>({
    defaultValues: customProperty ?? CUSTOM_PROPERTY_FORM_DEFAULT_VALUE,
  });
  const { isDirty } = useFormState({ control });
  const { fields, append, remove } = useFieldArray({ control, name: 'values' });

  useEffect(() => {
    reset(customProperty);
  }, [customProperty, reset]);

  useEffectAsync(async (isCanceled) => {
    await fetchCustomPropertyList();
  }, []);

  const fetchCustomPropertyList = async (isCanceled?: () => boolean) => {
    setLoading(CustomPropertyCRUDStatus.Loading);
    try {
      if (isCanceled?.()) return;
      const customPropertyListResponse: any = await getCustomProperties();

      const defaultTypes: any = await getCustomPropertyTypes();

      if (customPropertyListResponse &&
        customPropertyListResponse.items &&
        defaultTypes) {
        const matchedTypeList = customPropertyListResponse.items.map((customProperty: CustomProperty) => {
          let label = '';
          let type = '';
          const matchingType = defaultTypes.find((item: { type: any }) => item.type === customProperty.type);

          label = matchingType.label;
          type = matchingType.type;

          if (customProperty.formattingType) {
            const subType = matchingType.formatList.find((item: { type: any }) => item.type === customProperty.formattingType);

            label = subType.label;
            type = subType.type;
          }

          return {
            ...customProperty,
            label,
            formType: type
          };
        });

        setCustomPropertyList(matchedTypeList);
      }
    } catch (e) {
      informationAlert(GET_CUSTOM_PROPERTY_LIST_ERROR, "error");
    }
    setLoading(undefined);
  };

  const createCustomProperty = async (data: any) => {
    try {
      const newCustomPropertyData = {
        ...data,
        id: null,
        appliesTo: ['PORTFOLIO_COMPANY'],
        isDisabled: false,
        tenantId: clientId,
      };

      if (data) {
        await addCustomProperty(newCustomPropertyData);
      }

      fetchAllPortfolioCompanies();
      informationAlert(CREATE_CUSTOM_PROPERTY_SUCCESS, "success");
      closeDrawer();
    } catch (error: any) {
      informationAlert(CREATE_CUSTOM_PROPERTY_ERROR, "error");
    }
  };

  const editCustomProperty = async (data: any) => {
    try {
      const newCustomPropertyData = {
        ...data,
      };

      if (data) {
        await updateCustomProperty(newCustomPropertyData, data.id);
      }

      fetchAllPortfolioCompanies();
      informationAlert(UPDATE_CUSTOM_PROPERTY_SUCCESS, "success");
      closeDrawer();
    } catch (error: any) {
      informationAlert(UPDATE_CUSTOM_PROPERTY_ERROR, "error");
    }
  };

  const createUpdateCustomProperty = async (data: CustomProperty) => {
    let formattingType: any = null;

    if (data.formType === 'FORMATTED_NUMBER' ||
      data.formType === 'UNFORMATTED_NUMBER' ||
      data.formType === 'CURRENCY') {
      formattingType = data.formType;
      data.type = 'NUMBERS';
    } else {
      data.type = data.formType;
    }

    if (data.type === 'SINGLE_CHECKBOX') {
      data.values = [data.name];
    } else if (data.type !== 'MULTIPLE_CHECKBOX' &&
      data.type !== 'DROPDOWN_SELECTION' &&
      data.type !== 'RADIO_SELECTION') {
      data.values = null;
    }

    const resultData = {
      ...data,
      formattingType: formattingType
    };

    if (isNewCustomProperty) {
      setLoading(CustomPropertyCRUDStatus.Adding);
      await createCustomProperty(resultData);
    } else {
      setLoading(CustomPropertyCRUDStatus.Updating);
      await editCustomProperty(resultData);
    }
    setLoading(undefined);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleDelete = () => {
    setToBeDeleted(customProperty?.id ?? '');
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = async (customPropertyToBeDeleted: string) => {
    setToBeDeleted(undefined);
    try {
      await deleteCustomProperty(customPropertyToBeDeleted);
      setLoading(CustomPropertyCRUDStatus.Deleting);
      fetchAllPortfolioCompanies();
      informationAlert(DELETE_CUSTOM_PROPERTY_SUCCESS, "success");
      closeDrawer();
      setLoading(undefined);
    } catch (error) {
      informationAlert(DELETE_CUSTOM_PROPERTY_ERROR, "error");
      setLoading(undefined);
    }
  };

  const handleViewCustomPropertyDetails = (customProperty: any) => {
    setSelectedCustomProperty({
      customProperty: {
        ...customProperty,
        values: customProperty.values === null ? [] : customProperty.values
      },
      viewType: CustomPropertyDetailsViewType.Edit
    });
  };

  const formType = watch("formType");

  return {
    loading,
    register,
    handleCancelDelete,
    handleConfirmDelete,
    handleDelete,
    handleSubmit,
    handleViewCustomPropertyDetails,
    errors,
    control,
    fields,
    append,
    remove,
    createUpdateCustomProperty,
    toggleDrawer,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    formType,
    toBeDeleted,
    customPropertyList
  };
};
