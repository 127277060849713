import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { ReactElement } from "react";

import { MappingProgress, NumberBox } from "./MappingProgress.styles";

interface Props extends LinearProgressProps {
  value: number;
  total: number;
}

export const MappingProgressBar: React.FC<Props> = ({
  value,
  total,
  ...props
}: Props): ReactElement => {
  return (
    <MappingProgress>
      <LinearProgress
        id="progress_percent_mapped"
        variant="determinate"
        value={value}
        {...props}
      />
      <NumberBox>
        <Typography variant="label2">{total} Accounts</Typography>
        <Typography variant="label2">{`${
          value ? Math.round(value) : 0
        }% Mapped`}</Typography>
      </NumberBox>
    </MappingProgress>
  );
};
