import { SelectChangeEvent } from '@mui/material/Select';
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { MultiSelectItem } from "../../components/MultiSelect/MultiSelect";
import { AppContext } from "../../core/context/appContextProvider";
import { getFundNames } from "../../services/fund.service";
import { getReports } from "../../services/reports.service";
import downloadFile from "../../utils/helpers/fileDownloader";

type menu = {
  label: string;
  value: string;
  dateRangeRequired: boolean;
  dateRequired: boolean;
  fundsRequired: boolean;
  endPoint: string;
  fileName: string;
  hide: boolean;
};

const CLIENT_NAMES_FOR_TAMARAC_REPORT = ["william blair"];
const TAMARAC_REPORT_VALUE = "tamarac-ark-export";
const DATE_FORMAT = 'yyyy-MM-dd';
const DOWNLOAD_DATE_FORMAT = 'MM-dd-yyyy';

export const menuOptions: menu[] = [{
  label: 'K1 Accept/Decline Audit',
  value: 'k1-accept-decline-audit',
  dateRangeRequired: false,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'consents/K1',
  fileName: 'K1_report',
  hide: false,
},
{
  label: 'Contact Record Audit',
  value: 'contact-record-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'changes/contact',
  fileName: 'contact_change_report',
  hide: false,
},
{
  label: 'Investor Record Audit',
  value: 'investor-record-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'changes/investor',
  fileName: 'investor_change_report',
  hide: false,
},
{
  label: 'Login Audit',
  value: 'login-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'logins',
  fileName: 'login_report',
  hide: false,
},
{
  label: 'Contact Email Delivery Results Audit',
  value: 'contact-email-delivery-results-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'emails/contact',
  fileName: 'contact_email_report',
  hide: false,
},
{
  label: 'Admin Email Delivery Results Audit',
  value: 'admin-email-delivery-results-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'emails/admin',
  fileName: 'admin_email_report',
  hide: false,
},
{
  label: 'Document Audit',
  value: 'document-audit',
  dateRangeRequired: true,
  dateRequired: false,
  fundsRequired: false,
  endPoint: 'download',
  fileName: 'document_report',
  hide: false,
}, {
  label: 'Tamarac - Ark Export',
  value: TAMARAC_REPORT_VALUE,
  dateRangeRequired: false,
  dateRequired: true,
  fundsRequired: true,
  endPoint: 'tamarac',
  fileName: 'tamarac_ark_report',
  hide: true,
}];

export const useReports = () => {
  const [loading, setLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<menu | undefined>();
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([null, null]);
  const [date, setDate] = useState<Date | null>(null);
  const [menuList, setMenuList] = useState<menu[]>(menuOptions);
  const [funds, setFunds] = useState<MultiSelectItem[]>([]);

  const {
    informationAlert,
    state,
  } = useContext(AppContext);

  useEffect(() => {
    if(CLIENT_NAMES_FOR_TAMARAC_REPORT.includes(state.loginUser?.clientName?.toLowerCase() || "")) {
      setMenuList(menuOptions.map(menu => {
        if(menu.value === TAMARAC_REPORT_VALUE) {
          return {
            ...menu,
            hide: false,
          };
        }
        return menu;
      }));
    }
  }, [state.loginUser]);

  const generateReport = async () => {
    if (!selectedMenu) {
      informationAlert('Please select a report', "info");
      return;
    }
    if(selectedMenu.dateRangeRequired && (!dateRange || !dateRange[0] || !dateRange[1])) {
      informationAlert('Please select a date range', "info");
      return;
    }
    let endpoint = selectedMenu.endPoint;

    if(selectedMenu.dateRangeRequired) {
      endpoint += `?fromDate=${format(new Date(dateRange?.[0] || ''), DATE_FORMAT)}&toDate=${format(new Date(dateRange?.[1] || ''), DATE_FORMAT)}`;
    }

    if(selectedMenu.dateRequired && selectedMenu.fundsRequired) {
      endpoint += `?reportDate=${format(new Date(date || ''), DATE_FORMAT)}&fundIds=${funds?.filter(fund => fund.selected)?.map(fund => fund.id).join(',')}`;
    }

    setLoading(true);

    try {
      const downloadedData = await getReports(endpoint);
      const name = `${selectedMenu.fileName}_${format(new Date(), DOWNLOAD_DATE_FORMAT)}`;

      downloadFile(downloadedData, name, "csv");
    } catch (error) {
      informationAlert("Error in downloading report.", "error");
    }

    setLoading(false);
  };

  const getFunds = async () => {
    try {
      setLoading(true);
      const latestFunds = await getFundNames();

      setFunds(latestFunds?.map(fund => ({
        ...fund,
        selected: false,
      })));
    } catch (error) {
      informationAlert("Error in getting funds list.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const {
      value
    } = event.target;
    const selectedMenu = menuList.find(menu => menu.value === value);

    if(selectedMenu?.fundsRequired) {
      getFunds();
    }
    setSelectedMenu(selectedMenu);
    setDateRange([null, null]);
    setDate(null);
  };

  const handleDateChange = (value: Date | null) => {
    setDate(value);
  };

  const handleDateRangeChange = (dateRange: DateRange<any>) => {

    let toBeUpdated: DateRange<Date | null>;

    if (Array.isArray(dateRange)) {
      toBeUpdated = dateRange;
    } else {
      toBeUpdated = [dateRange, null];
    }
    setDateRange(toBeUpdated);
  };

  const handleFundsChange = (funds: MultiSelectItem[]) => {
    setFunds(funds);
  };

  const isGenerateReportButtonDisabled = useMemo(() => !selectedMenu?.value
    || (selectedMenu?.value && selectedMenu.dateRangeRequired && (!dateRange || !dateRange?.[0] || !dateRange?.[1]))
    || (selectedMenu?.value && selectedMenu.fundsRequired && selectedMenu.dateRequired && !funds?.some(fund => fund.selected) && !date),
  [selectedMenu, dateRange, funds]);

  return {
    loading,
    handleChange,
    date,
    handleDateChange,
    handleDateRangeChange,
    dateRange,
    selectedMenu,
    generateReport,
    menuList,
    funds,
    handleFundsChange,
    isGenerateReportButtonDisabled
  };
};
