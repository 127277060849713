import { FormControlLabel, Typography } from "@mui/material";
import React, { ReactElement, useState } from "react";

import {
  FilterCheckBox,
  FilterOption,
  FundsContent,
} from "../../../../../components/DataGrid/Filter/Filter.style";
import SearchBar from "../../../../../components/SearchBarWithDebounce/SearchBar";
import { ClientListItem } from "../../../../../utils/types/user.type";
import {
  ClientListContainer,
  ClientListFormGroup,
} from "../../UserDetailPanel.styles";

type Props = {
  options?: ClientListItem[];
  onClientToggle: (clientId: string) => void;
};

const ClientList: React.FC<Props> = ({
  options = [],
  onClientToggle,
}: Props): ReactElement => {
  const [search, setSearch] = useState<string>("");

  const onSearch = (value: string): void => {
    setSearch(value);
  };

  return (
    <ClientListContainer>
      <SearchBar id="client_search" onChange={onSearch} value={search} />
      <ClientListFormGroup>
        <FundsContent>
          {options?.length > 0 ? (
            (search
              ? options.filter((client) => {
                  const regex = new RegExp(search, "igm");

                  return regex.test(client?.clientName);
                })
              : options
            ).map((client) => {
              return (
                <FilterOption key={client.clientId}>
                  <FormControlLabel
                    control={
                      <FilterCheckBox
                        id={`${client.clientId}_check`}
                        name={client.clientName}
                        checked={client.selected}
                        onChange={() => onClientToggle(client.clientId)}
                      />
                    }
                    label={client.clientName}
                  />
                </FilterOption>
              );
            })
          ) : (
            <Typography align="center">No records</Typography>
          )}
        </FundsContent>
      </ClientListFormGroup>
    </ClientListContainer>
  );
};

export default ClientList;
