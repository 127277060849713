import { Box, Button } from "@mui/material";
import React, { ReactElement, useContext, useEffect, useState } from "react";

import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import FullScreenModal from "../../../components/Modal/FullScreenModal/FullScreenModal";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { AppContext } from "../../../core/context/appContextProvider";
import { updateExistingContact } from "../../../services/contact.service";
import {
  CONTACT_CHANGE_CONFIRMATION_TITLE,
  GENERIC_CHANGED_INFO_SUCCESS_MESSAGE,
  GENERIC_ERROR_MESSAGE,
} from "../../../utils/constants/text.constants";
import { ColumnOrder } from "../../../utils/types/columnOrder";
import { InvestorFilter } from "../../../utils/types/investor.type";
import { ContactContext } from "../contactList/ContactList";
import { formatPermissionByInvestors } from "../contactList/ContactList.hooks";
import {
  useContactsPemissionsEffect,
  useGridPermissionChange,
  useLockGridScreen,
  useSelectChange,
} from "./ContactPermissions.hooks";
import { PermissionBox, StyledButton } from "./ContactPermissions.style";
import ContactPermissionsDataGrid from "./ContactPermissionsDataGrid/ContactPermissionsDataGrid";

type Props = {
  hidden?: boolean;
  readonly?: boolean;
  preloadedContactId?: string;
  setLoadingContactInPanel?: any;
  investorFilterList: InvestorFilter[];
  setDataUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  contactPermissionColumnOrder?: ColumnOrder | null;
  primaryInvestors?: Set<string>;
};

const ContactPermissions: React.FC<Props> = (props: Props): ReactElement => {
  const { permissionsByInvestorList, setPermissionsByInvestorList } =
    useContext(ContactContext);
  const {
    readonly = false,
    hidden = false,
    preloadedContactId = "",
    setLoadingContactInPanel = undefined,
    investorFilterList,
    setDataUpdate,
    contactPermissionColumnOrder,
    primaryInvestors
  } = props;
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.currentUser?.clientId || "";
  const { isScreenLocked, setIsScreenLocked } = useLockGridScreen();

  const { isGridPermissionChanged, setIsGridPermissionChanged } = useGridPermissionChange();
  const { currentSelectedVal, setCurrentSelectedVal } = useSelectChange();

  const [isSaveChangesDisabled, setIsSaveChangesDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsScreenLocked(!preloadedContactId);
  }, [preloadedContactId]);

  useEffect(() => {
    if (showSaveChangesButton) {
      setIsSaveChangesDisabled(true);
      const foundCheckedPermission = permissionsByInvestorList.find((entry: any) => {

        const foundChecked = Object.entries(entry).find(([key, value]) => {
          if (key !== 'id' &&
            key !== 'name' &&
            key !== 'investor' &&
            key !== 'hierarchy' &&
            key !== 'isLocked'
          ) {
            return value === true;
          }
        });

        return foundChecked;
      });

      if (foundCheckedPermission) {
        setIsSaveChangesDisabled(false);
      }

    }
  }, [permissionsByInvestorList]);

  const {
    isLoadingContactList,
    bulkActionOptions,
    contactSelectionList,
    contactDetail,
    fundFilters,
    roleFilters,
    investorFilters,
    setSelectedContact,
    selectedContact,
    setLoadingContactList,
    openContactChangeConfirmation,
    setOpenContactChangeConfirmation,
    selectedInvestors,
    onInvestorFilterChange,
    loadingContacts,
  } = useContactsPemissionsEffect(
    clientId,
    informationAlert,
    preloadedContactId,
    setPermissionsByInvestorList,
    setLoadingContactInPanel
  );

  const handleOnContactSelected = (event: any, value: any) => {
    const selectedValue = value?.id || "";

    if (isGridPermissionChanged) {
      selectedContact
        ? setOpenContactChangeConfirmation(true)
        : setSelectedContact(selectedValue);
      setCurrentSelectedVal(selectedValue);
    } else {
      setSelectedContact(selectedValue);
    }
    setIsScreenLocked(true);
  };

  const saveExistingContact = async () => {
    try {
      setLoadingContactList(true);
      const investorFundRoles = formatPermissionByInvestors(
        permissionsByInvestorList,
        roleFilters
      );

      await updateExistingContact(
        {
          details: {
            ...contactDetail?.contactDetails,
            address: contactDetail?.address,
          },
          investorFundRoles: investorFundRoles,
        },
        selectedContact
      );
      setIsGridPermissionChanged(false);
      setIsScreenLocked(true);
      informationAlert(GENERIC_CHANGED_INFO_SUCCESS_MESSAGE, "success");
      setDataUpdate?.(true);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    } finally {
      setLoadingContactList(false);
    }
  };

  const saveContactChanges = async () => {
    setOpenContactChangeConfirmation(false);
    try {
      await saveExistingContact();
      setSelectedContact(currentSelectedVal);
    } catch (error) {
      setSelectedContact(currentSelectedVal);
    }
  };

  const showSaveChangesButton = selectedContact && !isScreenLocked && isGridPermissionChanged;

  return (
    <PermissionBox id="contact_permissions" role="main" hidden={hidden} mt={2}>
      <ProgressModal
        id="contact_permission_loading"
        showProgress={isLoadingContactList}
      />
      <FullScreenModal
        id="modal_change_contact_confirmation"
        open={openContactChangeConfirmation}
        title={CONTACT_CHANGE_CONFIRMATION_TITLE}
        subtitle=""
        confirmButtonText="Save Changes"
        cancelButtonText="Cancel"
        onSuccess={saveContactChanges}
        onCancel={() => {
          setOpenContactChangeConfirmation(false);
        }}
      />
      <HorizontalBox stickToRight={!!preloadedContactId} noPadding>
        {!preloadedContactId && (
          <Box display="flex">
            <Box display="flex" flexDirection="column" gap={1}>
              <MultiSelect
                id={"contact_investor_filter"}
                label="Investors"
                items={investorFilters.map((item) => ({
                  id: item.id,
                  name: item.name,
                  selected: selectedInvestors.has(item.id),
                })) || []}
                onChange={onInvestorFilterChange}
                noGutter
              />
              <SingleSelect
                isSearchable
                disabled={loadingContacts}
                id={"select_contact_permissions_contact_selection"}
                optionList={contactSelectionList}
                handleOptionSelection={handleOnContactSelected}
                label={"Contact Name"}
                defaultValue=""
              />
            </Box>
            {showSaveChangesButton && (
              <StyledButton>
                <Button
                  id='btn_contact_permissions_save_changes'
                  disabled={isSaveChangesDisabled}
                  variant="contained"
                  color={"primary"}
                  onClick={saveExistingContact}
                >
                  Save Changes
                </Button>
              </StyledButton>
            )}
          </Box>
        )}
        {/*TODO: Implement Export post v1 release*/}
        {/*<SplitButton*/}
        {/*  id={"btn_bulk_action_options"}*/}
        {/*  options={bulkActionOptions}*/}
        {/*  hidden={false}*/}
        {/*  noGutter*/}
        {/*  handleOptionClick={() => {}}*/}
        {/*  ariaLabelMessage="Select bulk action option"*/}
        {/*/>*/}
      </HorizontalBox>

      {!isLoadingContactList && (
        <ContactPermissionsDataGrid
          readonly={readonly}
          investorFilterList={investorFilterList}
          roleList={roleFilters}
          preloadedContactId={preloadedContactId}
          fundFilters={fundFilters}
          isScreenLocked={isScreenLocked}
          setIsScreenLocked={setIsScreenLocked}
          setIsGridPermissionChanged={setIsGridPermissionChanged}
          contactPermissionColumnOrder={contactPermissionColumnOrder}
          primaryInvestors={primaryInvestors}
        />
      )}
    </PermissionBox>
  );
};

export default ContactPermissions;
