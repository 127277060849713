import { Typography } from "@mui/material";
import { GridAlignment } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { StringCell } from "../../components/DataGrid/DataGrid.styles";
import { AppContext } from "../../core/context/appContextProvider";
import useRole from "../../core/routing/useRole";
import { useUsersEffect } from "../../services/hooks/useUsersEffect/useUsersEffect.hooks";
import { updateUserStatus } from "../../services/user.service";
import { getUserLockStatus } from "../../services/user.service.v2";
import { M_DASH_UNICODE } from "../../utils/constants/constants";
import { GENERIC_ERROR_MESSAGE } from "../../utils/constants/text.constants";
import { formatPhoneNumber } from "../../utils/helpers/format.helper";
import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook";
import { CustomType, DataGridColDef } from "../../utils/types/listItems";
import { OperationType, ScopeRole, SelectedUser, User, } from "../../utils/types/user.type";
import { NO_CLIENT, Status } from "./constants";
import { ArkClientBox, ArkClientBoxIcon, SwitchLabel, TimeText } from "./Users.styles";

type segregatedUsers = {
  clients: Record<any, any>;
  superAdmins: User[];
  arkClientTags: Record<any, any>[];
}

const userLevelHeaderList: DataGridColDef[] = [
  {
    field: "firstName",
    headerName: "Name",
    hide: false,
    index: 1,
    renderCell: (params) => {
      const name = `${params?.row?.firstName ?? ''} ${params?.row?.lastName ?? ''}`;

      return (
        <StringCell>
          <Typography>{name || M_DASH_UNICODE}</Typography>
        </StringCell>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: "role.name",
    headerName: "Role",
    hide: false,
    index: 2,
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: "email",
    headerName: "Email",
    hide: false,
    index: 3,
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: "phone",
    headerName: "Phone",
    hide: false,
    index: 4,
    type: 'string',
    renderCell: (params) => {
      return (
        <StringCell>
          <Typography>{formatPhoneNumber(params?.row?.phone ?? '') || M_DASH_UNICODE}</Typography>
        </StringCell>
      );
    },
    align: 'left' as GridAlignment,
  },
  {
    field: "location",
    headerName: "Location",
    hide: false,
    index: 5,
    type: 'string',
    align: 'left' as GridAlignment,
  },
  {
    field: "loginStatus.lastLoginAttempt",
    headerName: "Last Login",
    hide: false,
    index: 6,
    renderCell: (params) => {
      const lastLogin = params?.row?.loginStatus?.lastLoginAttempt ?? '';

      if(!lastLogin) {
        return (
          <StringCell>
            <Typography variant="body2">{M_DASH_UNICODE}</Typography>
          </StringCell>
        );
      }
      const date = format(new Date(lastLogin), 'MM/dd/yyyy');
      const time = format(new Date(lastLogin), 'hh:mm a');

      return (
        <StringCell>
          <Typography variant="body2">{date}</Typography>
          <TimeText variant="body2">{time}</TimeText>
        </StringCell>
      );
    },
    align: 'left' as GridAlignment,
    width: 150,
  },
  {
    field: "loginStatus.attempts",
    headerName: "Attempts",
    hide: false,
    index: 7,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 150
  },
];

const ACTION_COLUMN = {
  field: "action",
  headerName: "Columns",
  hide: false,
  hideable: false,
  type: 'action',
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 100,
};

const folderLevelHeaderList: DataGridColDef[] = [
  {
    field: "ark_client_tag",
    headerName: "Ark Client Tags",
    hide: false,
    index: 1,
    sortable: true,
    renderCell: (params) => {
      const name = params?.row?.ark_client_tag ?? '';

      return (
        <ArkClientBox>
          <ArkClientBoxIcon/>
          <Typography variant="body2">{name}</Typography>
        </ArkClientBox>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
    width: 300
  },
  {
    index: 2,
    ...ACTION_COLUMN
  },
];

const initialSelectedUser: SelectedUser = {
  user: undefined,
  type: undefined,
};

export const useUsers = () => {
  const [list, setList] = useState<Record<any, any>[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClientArkTag, setSelectedClientArkTag] = useState<string | undefined>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(6);
  const [search, setSearch] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<SelectedUser>(initialSelectedUser);
  const [statusUpdateUser, setStatusUpdateUser] = useState<User | undefined>();
  const [showAddNewClientConfirmation, setShowAddNewClientConfirmation] = useState<boolean>(false);
  const [segregatedUsers, setSegregatedUsers] = useState<segregatedUsers>({
    clients: {},
    superAdmins: [],
    arkClientTags: [],
  });
  const {
    informationAlert
  } = useContext(AppContext);
  const {
    hasRole: isSuperAdmin,
  } = useRole([ScopeRole.SUPER_ADMIN]);
  const {
    hasRole: isArkClientAdmin,
  } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const {
    users,
    loading: loadingUsers,
    updateUsers,
    fetchUsers,
  } = useUsersEffect();

  const toggleStatus = useCallback((user: User) => {
    setStatusUpdateUser(user);
  }, []);

  const discardStatusUpdate = () => {
    setStatusUpdateUser(undefined);
  };

  const updateStatus = async () => {
    setStatusUpdateUser(undefined);
    if(!statusUpdateUser) {
      return;
    }
    const {
      id, status
    } = statusUpdateUser;

    try {
      setLoading(true);
      const newStatus = status ===  Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;

      await updateUserStatus(id!, newStatus);
      const updatedUsers = users.map((user) => {
        if(user.id === id) {
          user.status = newStatus;
        }

        return user;
      });

      updateUsers(updatedUsers);
      setLoading(false);
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  const userHeaderListWithActiveToggle = useMemo(() => {
    return [...userLevelHeaderList,
      {
        index: 9,
        ...ACTION_COLUMN
      }];
  }, [toggleStatus]);

  const getFilteredList = useCallback((list: User[], searchString?: string) => list?.filter(({
    firstName, lastName, email
  }: User) => {
    const name = lastName ? `${firstName} ${lastName}`.toLowerCase(): (firstName ?? "").toLowerCase();
    const searchValue = new RegExp(searchString ?? "", "i");

    return searchValue.test(name) || searchValue.test(email || "");
  }), []);

  //This use effect processes the list of users, list of header and filters them based on the search string, selected client and selected tab.
  useEffect(() => {
    let currentList: Record<any, any>[] = [];
    let currentHeaderList: Array<DataGridColDef> = [];

    //If super admin, Show tabs with clients folder, individual client users and super admins
    if(isSuperAdmin) {
      if(activeIndex === 0 && !selectedClientArkTag) {
        if(search.length > 0) {
          currentList = segregatedUsers.arkClientTags.filter(({
            ark_client_tag
          }) => ark_client_tag.toLowerCase().includes(search.toLowerCase()));
        } else {
          currentList = [...segregatedUsers.arkClientTags];
        }
        currentHeaderList = [...folderLevelHeaderList];
      } else if(activeIndex === 0 && selectedClientArkTag) {
        if(search.length > 0) {
          currentList = getFilteredList(segregatedUsers.clients[selectedClientArkTag], search);
        } else {
          currentList = [...(segregatedUsers.clients[selectedClientArkTag] || [])];
        }
        currentHeaderList = [...userHeaderListWithActiveToggle];
      } else if(activeIndex === 1) {
        if(search.length > 0) {
          currentList = getFilteredList(segregatedUsers.superAdmins, search);
        } else {
          currentList = [...segregatedUsers.superAdmins];
        }
        currentHeaderList = [...userHeaderListWithActiveToggle];
      }
    } else if(isArkClientAdmin) {
      //If ark client admin, show tabs with individual client users based on logged in user
      const clientTag = segregatedUsers.arkClientTags?.[0]?.ark_client_tag;

      currentList = getFilteredList(segregatedUsers.clients[clientTag], search);
      currentHeaderList = [...userHeaderListWithActiveToggle];
      setSelectedClientArkTag(clientTag);
    }

    setList(currentList);
    setHeaderList(currentHeaderList);

  }, [segregatedUsers, search, activeIndex, selectedClientArkTag]);

  // This use effect processes the entire list of user and divides them into clients and super admins.
  useEffect(() => {
    const {
      segregatedClients,
      segregatedSuperAdmins
    } = users.reduce((processedUsers: Record<string, any>, user) => {
      const {
        segregatedClients, segregatedSuperAdmins
      } = processedUsers;

      if(user?.role?.name === 'Super Admin') {
        return {
          ...processedUsers,
          segregatedSuperAdmins: [
            ...segregatedSuperAdmins,
            user
          ]
        };
      }
      const value = user['arkClientTag'] || NO_CLIENT;

      // eslint-disable-next-line no-param-reassign
      segregatedClients[value] = (segregatedClients[value] || []).concat(user);
      return {
        ...processedUsers,
        segregatedClients
      };
    }, {
      segregatedClients: {},
      segregatedSuperAdmins: []
    });
    const clientTags = Object.keys(segregatedClients).sort().map((client, id) => ({
      ark_client_tag: client,
      id: id + 1,
    }));

    setSegregatedUsers({
      clients: segregatedClients,
      superAdmins: segregatedSuperAdmins,
      arkClientTags: clientTags
    });
  }, [users]);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedClientArkTag('');
    setActiveIndex(newValue);
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter((header) => !header.hide && header?.type !== "action");

    const updatedHeaders: Array<DataGridColDef> = headerList.map(
      (header) => {
        return {
          ...header,
          hide: (header.field === field && (!(!header.hide && activeFields.length <= 1))) ? !header.hide : header.hide,
        };
      });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeIndex === 0 ? 6 : activeHeaders.length - 1);
    }
  };

  const handleOnView = (userId: string, user: any) => {
    if(activeIndex === 0 && !selectedClientArkTag) {
      setSelectedClientArkTag(user.ark_client_tag);
    } else {
      setSelectedUser({
        user,
        type: activeIndex === 0 ? OperationType.EditUser : OperationType.EditSuperAdmin,
        arkClientTag: selectedClientArkTag
      });
    }
  };

  const onSearch = (search: string) => {
    setSearch(search);
  };

  const buttonTitle = useMemo(() => {
    if(activeIndex === 0 && !selectedClientArkTag) {
      return OperationType.AddNewClient;
    } else if(activeIndex === 0 && selectedClientArkTag) {
      return OperationType.AddNewUser;
    } else if(activeIndex === 1) {
      return OperationType.AddSuperAdmin;
    }
    return OperationType.AddNewUser;
  }, [activeIndex, selectedClientArkTag]);

  const handleAddNewUser = () => {
    let type = OperationType.AddNewUser;

    switch (buttonTitle) {
      case OperationType.AddNewClient:
        setShowAddNewClientConfirmation(true);
        return;
      case OperationType.AddSuperAdmin:
        type = OperationType.AddSuperAdmin;
        break;
      case OperationType.AddNewUser:
      default:
        type = OperationType.AddNewUser;
    }
    setSelectedUser({
      type,
      arkClientTag: selectedClientArkTag
    });
  };

  const onAddNewClientConfirm = () => {
    setShowAddNewClientConfirmation(false);
    setSelectedUser({
      type: OperationType.AddNewClient,
    });
  };

  const onAddNewClientCancel = () => {
    setShowAddNewClientConfirmation(false);
  };

  const cancelAddEdit = () => {
    setSelectedUser(initialSelectedUser);
  };

  const resetFormAndFetchUsers = () => {
    setSelectedUser(initialSelectedUser);
    fetchUsers();
  };

  return {
    headerList,
    loadingUsers,
    buttonTitle,
    loading,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    handleOnView,
    handleTabChange,
    activeIndex,
    selectedClientArkTag,
    setSelectedClientArkTag,
    statusUpdateUser,
    discardStatusUpdate,
    updateStatus,
    isSuperAdmin,
    isArkClientAdmin,
    handleAddNewUser,
    selectedUser,
    cancelAddEdit,
    resetFormAndFetchUsers,
    users,
    segregatedUsers,
    showAddNewClientConfirmation,
    onAddNewClientConfirm,
    onAddNewClientCancel,
  };
};
