import React, { ReactElement } from 'react';

import FilePreview from "./FilePreview";
import FileSelector from "./FileSelector";
import {
  PreviewContainer,
} from "./FileSelector.style";

type Props = {
  fileHandler: any;
  img?: string;
  previewWidth?: string | number;
  previewHeight?: string | number;
  disabled?: boolean;
  showDeleteButton?: boolean;
  selectNewImageOnChange?: boolean;
};

const FileSelectorPreview = ({
  fileHandler,
  img,
  previewWidth,
  previewHeight,
  disabled,
  showDeleteButton = true,
  selectNewImageOnChange = false,
}: Props): ReactElement => {
  return (
    <>
      {img && (
        <PreviewContainer
          previewWidth={previewWidth}
          previewHeight={previewHeight}>
          <FilePreview
            url={img}
            previewWidth={previewWidth}
            previewHeight={previewHeight}
            showDeleteButton={showDeleteButton}
            clearImage={() => fileHandler(undefined)}
            selectNewImageOnChange={selectNewImageOnChange}
            onUpload={fileHandler}
          />
        </PreviewContainer>)}
      {!img && <FileSelector onUpload={fileHandler} disabled={disabled} />}
    </>
  );
};

export default FileSelectorPreview;
