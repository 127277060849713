import { useContext, useState } from 'react';

import { AppContext } from '../../core/context/appContextProvider';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../services/columnOrder.service';
import { useEffectAsync } from '../../utils/hooks/useEffectAsync.hook';
import { ColumnOrder } from '../../utils/types/columnOrder';
import { ListItem } from '../../utils/types/listItems';
import {
  CapitalAccountSummaryItem,
  ChartWidgets,
  DashboardWidget,
  FundSummaryItem,
  SummaryCards,
  visualizationsDataItem,
  WidgetType,
} from '../../utils/types/visualDashboard.type';
import { getBarChartData } from './barChart/BarChart.hooks';
import { TypeCountItem } from './barChart/EntityTypeCounts';
import { getLineChartData } from './lineChart/LineChart.hooks';
import { getPieChartData } from './pieChart/PieChart.hooks';
import { getSummaryCards } from './summaryCards/SummaryCard.hooks';
import { PageSummary, PageSummaryItem } from './VisualDashboard';
import {
  defaultCapitalAccountsPageDashboardWidgets,
  defaultDashboardSoiWidgets,
  defaultFundPageDashboardWidgets,
  defaultRealizedSoiWidgets,
  defaultUnrealizedSoiWidgets,
  VisualDashboardType,
  VisualDashboardViewKey,
} from './VisualDashboard.constants';

export const useVisualDashboard = (
  pageType: string,
  pageSummary: PageSummary[],
  allItemsSelected: boolean,
  fundEntityTypeList?: ListItem[]
) => {
  let defaultDashboardWidgets: DashboardWidget[] = [];
  let viewKey = '';
  let pageSummaryItems: PageSummaryItem[] = [];

  switch (pageType) {
    case VisualDashboardType.Fund:
      viewKey = VisualDashboardViewKey.Fund;
      defaultDashboardWidgets = defaultFundPageDashboardWidgets;
      pageSummaryItems = pageSummary[0].items.filter(
        (fund) => fund.id.toLowerCase() !== 'total'
      );
      break;
    case VisualDashboardType.CapitalAccounts:
      viewKey = VisualDashboardViewKey.CapitalAccounts;
      defaultDashboardWidgets = defaultCapitalAccountsPageDashboardWidgets;
      pageSummaryItems = pageSummary[0].items.filter(
        (fund) => fund.id.toLowerCase() !== 'total'
      );
      break;
    case VisualDashboardType.SoiUnrealized:
      viewKey = VisualDashboardViewKey.SoiUnrealized;
      defaultDashboardWidgets = defaultUnrealizedSoiWidgets;
      pageSummaryItems = pageSummary[0].items;
      break;
    case VisualDashboardType.SoiRealized:
      viewKey = VisualDashboardViewKey.SoiRealized;
      defaultDashboardWidgets = defaultRealizedSoiWidgets;
      pageSummaryItems = pageSummary[0].items;
      break;
    case VisualDashboardType.SoiDashboard:
      viewKey = VisualDashboardViewKey.SoiDashboard;
      defaultDashboardWidgets = defaultDashboardSoiWidgets;
      pageSummaryItems = pageSummary[0].items;
      break;
  }

  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const [currency, setCurrency] = useState<string[]>(
    pageSummary.map((summary) => {
      return summary.currencyCode;
    })
  );
  const [fundTypeCounts, setFundTypeCounts] = useState<TypeCountItem[]>([]);
  const [isEditingWidgets, setIsEditingWidgets] = useState<boolean>(false);
  const [dashboardWidgets, setDashboardWidgets] = useState<DashboardWidget[]>(
    defaultDashboardWidgets
  );
  let typeCountItems: any = [];

  useEffectAsync(
    async (isCanceled) => {
      let colOrder: ColumnOrder | undefined;

      try {
        colOrder = await getColumnOrder(viewKey, clientId);

        if (isCanceled()) return;
      } catch {
        informationAlert(
          'Error retreiving Visual Dashboard Widget configuration',
          'error'
        );
        colOrder = undefined;
      }

      if (
        colOrder?.viewItems?.length &&
        colOrder?.viewItems?.length === defaultDashboardWidgets.length
      ) {
        const widgets = colOrder.viewItems.map<DashboardWidget>((vi) => {
          const widget = defaultDashboardWidgets.find(
            (w) => w.name === vi.code
          )!;
          let order = vi.order;

          //NOT SURE THIS IS NEEDED --> Can we update defaults to not reset index value when type changes?
          if (widget.widgetType === WidgetType.Charts) {
            order -= defaultDashboardWidgets.filter(
              (widget) => widget.widgetType === WidgetType.SummaryCard
            ).length;
          } else if (widget.widgetType === WidgetType.EntityTypeByFund) {
            order -=
              defaultDashboardWidgets.filter(
                (widget) => widget.widgetType === WidgetType.EntityTypeByFund
              ).length +
              defaultDashboardWidgets.filter(
                (widget) => widget.widgetType === WidgetType.SummaryCard
              ).length;
          }

          return {
            index: order,
            name: vi.code,
            title: widget.title,
            visible: vi.visible,
            widgetType: widget.widgetType,
          };
        });

        setDashboardWidgets(widgets);
      } else {
        setDashboardWidgets(defaultDashboardWidgets);
      }
    },
    [pageType]
  );

  async function saveDashboardWidgets(dashboardWidgets: DashboardWidget[]) {
    const viewItems = dashboardWidgets.map((widget) => {
      let index = widget.index;

      //NOT SURE THIS IS NEEDED --> Can we update defaults to not reset index value when type changes?
      if (widget.widgetType === WidgetType.Charts) {
        index += dashboardWidgets.filter(
          (widget) => widget.widgetType === WidgetType.SummaryCard
        ).length;
      } else if (widget.widgetType === WidgetType.EntityTypeByFund) {
        index +=
          dashboardWidgets.filter(
            (widget) => widget.widgetType === WidgetType.EntityTypeByFund
          ).length +
          dashboardWidgets.filter(
            (widget) => widget.widgetType === WidgetType.SummaryCard
          ).length;
      }

      return {
        code: widget.name,
        label: widget.title,
        visible: widget.visible,
        order: index,
      };
    });

    await saveColumnOrder({
      clientId: clientId,
      viewKey: viewKey,
      viewItems: viewItems,
    });
  }

  function handleWidgetVisibilityChange(name: string, visible: boolean) {
    const revisedWidgets = dashboardWidgets.map((widget: DashboardWidget) =>
      widget.name === name ? { ...widget, visible: visible } : widget
    );

    setDashboardWidgets(revisedWidgets);

    saveDashboardWidgets(revisedWidgets);
  }

  function handleWidgetOrderChange(
    widgetType: WidgetType,
    orderedVisibleWidgets: DashboardWidget[]
  ) {
    const subArrayMap = new Map(orderedVisibleWidgets.map((w) => [w.name, w]));
    const remainingItems = dashboardWidgets.filter(
      (w) => !subArrayMap.has(w.name)
    );

    const newArray = [...orderedVisibleWidgets, ...remainingItems];

    newArray.forEach((item, idx) => {
      if (item) item.index = idx;
    });

    saveDashboardWidgets(newArray);
  }

  function mergeChartData(
    chartWidgets: { charts: any[]; currency: string }[],
    ...chartDataArrays: { charts: any[]; currency: string }[][]
  ): { charts: any[]; currency: string }[] {
    chartDataArrays.forEach((chartDataArray) => {
      chartDataArray.forEach((newData) => {
        const existingWidget = chartWidgets.find(
          (widget) => widget.currency === newData.currency
        );

        if (existingWidget) {
          existingWidget.charts = [...existingWidget.charts, ...newData.charts];
        } else {
          chartWidgets.push(newData);
        }
      });
    });

    return chartWidgets;
  }

  let chartWidgets: ChartWidgets[] = [];

  const summaryCardItems: SummaryCards[] = pageSummary.reduce<SummaryCards[]>(
    (acc, summary) => {
      if (summary.items.length > 0) {
        const summaryCard = getSummaryCards(
          summary.items,
          summary.currencyCode,
          pageType,
          dashboardWidgets,
          allItemsSelected
        );

        acc.push(summaryCard);
      }
      return acc;
    },
    []
  );

  const pieChartData = pageSummary.map((summary) => {
    return getPieChartData(
      summary.items,
      pageType,
      dashboardWidgets,
      summary.currencyCode
    );
  });

  const barChartData = pageSummary.map((summary) => {
    return getBarChartData(
      summary.items,
      pageType,
      dashboardWidgets,
      summary.currencyCode
    );
  });

  // Line chart not yet in use. Temp disabled. Hooks order causing react warning.
  // const lineChartData = pageSummary.map((summary) => {
  //   return getLineChartData(
  //     summary.items,
  //     pageType,
  //     dashboardWidgets,
  //     summary.currencyCode
  //   );
  // });

  let visualizationData: visualizationsDataItem[] = [];

  if (fundEntityTypeList) {
    typeCountItems = pageSummary.map((summary) => {
      let tempFundTypeCounts = fundEntityTypeList.map<TypeCountItem>(
        (entityType) => {
          const filteredSummaryItems = summary.items.filter(
            (ps) => ps.entityType === entityType.id
          );

          const count = filteredSummaryItems?.length
            ? filteredSummaryItems.length
            : 0;

          return {
            id: entityType.id,
            label: entityType.label,
            count: count,
          };
        }
      );

      tempFundTypeCounts = tempFundTypeCounts
        .filter((ftc) => ftc.count > 0)
        .sort((a, b) => b.count - a.count);

      return {
        currencyCode: summary.currencyCode,
        typeCountItems: tempFundTypeCounts,
      };
    });
  }

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
    case VisualDashboardType.Fund:
    case VisualDashboardType.SoiUnrealized:
    case VisualDashboardType.SoiRealized:
    case VisualDashboardType.SoiDashboard:
      chartWidgets = mergeChartData(pieChartData, barChartData); //, lineChartData);
      chartWidgets.forEach((currencyGrouping) => {
        return currencyGrouping.charts.sort((a, b) => a.index - b.index);
      });

      visualizationData = pageSummary.reduce<visualizationsDataItem[]>(
        (acc, summary) => {
          if (summary.items.length > 0) {
            const summaryCardData = summaryCardItems?.find(
              (cardItems) => cardItems.currencyCode === summary.currencyCode
            )?.summaryCards;
            const chartData = chartWidgets.find(
              (charts) => charts.currency === summary.currencyCode
            )?.charts;
            const showEntityTypeByFund = !!dashboardWidgets.find(
              (widget) => widget.widgetType === WidgetType.EntityTypeByFund
            )?.visible;
            const typeCountData = typeCountItems
              ? typeCountItems.find(
                  (typeCount: any) =>
                    typeCount.currencyCode === summary.currencyCode
                )
              : undefined;

            acc.push({
              currencyCode: summary.currencyCode,
              summaryCards: summaryCardData,
              charts: chartData,
              showEntityTypeByFund: showEntityTypeByFund,
              typeCountItems: typeCountData,
            });
          }
          return acc;
        },
        []
      );
      break;
  }

  const showEntityTypeByFund = !!dashboardWidgets.find(
    (widget) => widget.widgetType === WidgetType.EntityTypeByFund
  )?.visible;

  return {
    dashboardWidgets,
    summaryCardItems,
    handleWidgetVisibilityChange,
    handleWidgetOrderChange,
    isEditingWidgets,
    saveDashboardWidgets,
    showEntityTypeByFund,
    setIsEditingWidgets,
    fundTypeCounts,
    visualizationData,
  };
};

export function isFundSummaryItemArray(items: any[]): FundSummaryItem[] {
  return items.filter((item): item is FundSummaryItem => 'value' in item);
}

//TO BE USED if Cap Accounts eventually differentiates from Fund Summary structure
export function isCapitalAccountSummaryItemArray(
  items: any[]
): CapitalAccountSummaryItem[] {
  return items.filter(
    (item): item is CapitalAccountSummaryItem => 'value' in item
  );
}
