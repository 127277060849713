import { useContext, useMemo } from "react";

import { ScopeRole } from "../../utils/types/user.type";
import { AppContext } from "../context/appContextProvider";

const useRole = (roles?: ScopeRole[]) => {
  const {
    state,
  } = useContext(AppContext);

  const hasRole = useMemo(() => {
    return roles?.includes(state?.loginUser?.currentUser?.scopeRole as ScopeRole ?? "");
  }, [state, roles]);

  return {
    hasRole,
  };
};

export default useRole;
