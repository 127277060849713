import { ArrowForwardIosOutlined, CheckRounded, RemoveCircle } from "@mui/icons-material";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import React, { ReactElement, useMemo } from "react";

import { ClientListItem, TeamMember } from "../../../../../utils/types/user.type";
import { SelectedClientListItemButton } from "../../UserDetailPanel.styles";

interface Props {
    client: ClientListItem;
    onClientDeselect: (clientId: string) => void,
    onClientSelect: (client: ClientListItem) => void,
    teamMembers?: TeamMember[];
}

const SelectedClientsListRow: React.FC<Props> = ({
  client,
  onClientDeselect,
  onClientSelect,
  teamMembers
}: Props): ReactElement => {

  const hasTeamSelected = useMemo(() => {
    const currentTeamsIDs = client?.teams?.map(team => team.teamId) || [];
    const selectedTeamsIDs = teamMembers?.map(teamMember => teamMember?.team?.id || '') || [];

    return !!selectedTeamsIDs.find(selectedTeamID => currentTeamsIDs?.includes(selectedTeamID));
  }, [client, teamMembers]);

  return (
    <ListItem
      key={client?.clientId}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="arrow"
          onClick={() => onClientSelect(client)}>
          <ArrowForwardIosOutlined fontSize="small" />
        </IconButton>
      }
      disablePadding
      id={`client_list${client?.clientId}`}
    >
      <SelectedClientListItemButton onClick={() => onClientSelect(client)} dense>
        <IconButton edge="end" aria-label="remove" onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
          onClientDeselect(client.clientId);
        }}>
          <RemoveCircle color="error" fontSize="small" />
        </IconButton>
        {hasTeamSelected && <CheckRounded color="success" fontSize="small"/>}
        <ListItemText primary={client?.clientName} />
      </SelectedClientListItemButton>
    </ListItem>
  );
};

export default SelectedClientsListRow;
