import {
  CapitalCallDocument,
  CapitalCallId,
  CapitalCalls,
  FundFilter,
  InvestorFilter,
  PortalCapitalCalls,
  Reviews,
  ReviewsSummary,
  StatusType,
  TeamMembers,
  Transaction,
} from '../utils/types/capitalCalls.type';
import {
  PostAddAdjustment,
  PostUpdateAdjustment,
} from '../utils/types/cashReceipt.type';
import { Quarter } from '../utils/types/filter.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const download = async (payload: any) =>
  await apiClient().post(`/downloads`, payload, {
    responseType: 'blob',
  });

export const getInvestorsFilter = async () =>
  await apiClient().get<InvestorFilter[]>(`/investors/filters`);

export const getFundsFilter = async () =>
  await apiClient().get<FundFilter[]>(`/funds/filters`);

export const getQuarterList = async () =>
  await apiClient().get<Quarter[]>(`/quarters/filters?published=true`);

export const getPortalCapitalCall = async (quarterName: string) => {
  return await apiClient().get<PortalCapitalCalls[]>(
    `/capital-calls?quarterName=${quarterName}`
  );
};

export const getCapitalCalls = async () =>
  await apiClient().get<CapitalCalls[]>('admin/capital-calls');

export const updateCapitalCallsStatus = async (id: string, status: string) =>
  await apiClient().patch(`admin/capital-calls/${id}/status`, {
    status,
  });

export const getCapitalCallDetails = async (id: string) =>
  await apiClient().get<CapitalCalls>(`admin/capital-calls/${id}`);

export const createCapitalCall = async (params: CapitalCalls) =>
  await apiClient().post<CapitalCallId>(`admin/capital-calls`, params);

export const getTeamRoleswithMembersForFund = async (fundId: string) =>
  await apiClient().get<TeamMembers>(`teams/members/?fundId=${fundId}`);

export const uploadDocument = async (
  capitalCallId: String,
  file: FileList,
  type: string
) => {
  const formData = new FormData();

  formData.append('file', file[0]);
  await apiClient().post(
    `admin/capital-calls/${capitalCallId}/documents?type=${type}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const getDocuments = async (id: string) =>
  await apiClient().get<CapitalCallDocument>(
    `admin/capital-calls/${id}/documents`
  );

export const updateCapitalCall = async (id: string, params: CapitalCalls) =>
  await apiClient().put(`admin/capital-calls/${id}`, params);

export const getTransactions = async (id: string) =>
  await apiClient().get<Transaction[]>(
    `admin/capital-calls/${id}/transactions`
  );

export const addOrUpdateTransaction = async (
  id: string,
  params: Transaction[]
) => await apiClient().put(`admin/capital-calls/${id}/transactions`, params);

export const getReviews = async (id: string) =>
  await apiClient().get<Reviews[]>(`admin/capital-calls/${id}/reviews`);

export const getReviewSummary = async (id: string) =>
  await apiClient().get<ReviewsSummary>(
    `admin/capital-calls/${id}/reviews/summary`
  );
export const updateStatus = async (
  capitalCallId: string,
  approvalId: string,
  params: StatusType
) => {
  await apiClient().patch(
    `admin/capital-calls/${capitalCallId}/approvals/${approvalId}`,
    params
  );
};

export const getCapitalCallTransactions = async (id: string) =>
  await apiClient().get<any[]>(`admin/capital-calls/${id}/transactions`);

export const saveCapitalCallTransactions = async (id: string, data: any) =>
  await apiClient().post<any[]>(
    `admin/capital-calls/${id}/cash-receipts`,
    data
  );

export const updateCapitalCallTransactions = async (id: string, data: any) =>
  await apiClient().post<any[]>(
    `admin/capital-calls/${id}/cash-receipts`,
    data
  );

export const addAdjustment = async (id: string, data: any) =>
  await apiClient().post<PostAddAdjustment>(
    `admin/capital-calls/${id}/cash-receipts/:addAdjustment`,
    data
  );

export const updateAdjustment = async (id: string, data: any) =>
  await apiClient().post<PostUpdateAdjustment>(
    `admin/capital-calls/${id}/cash-receipts/:updateAdjustment`,
    data
  );
