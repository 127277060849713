import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FundDetails } from "../../../utils/types/fund.v2.type";
import { getFunds } from "../../teams.service";

export const useFundsEffect = () => {
  const [fundsList, setFundsList] = useState<FundDetails[]>();
  const [loading, setLoading] = useState(false);
  const {
    state,
    informationAlert,
  } = useContext(AppContext);

  const clientId = state?.loginUser?.clientId;

  useEffectAsync(async (isCanceled) => {
    setLoading(true);
    try {
      if(clientId) {
        const statusResponse = await getFunds(clientId);

        if (isCanceled()) return;

        setFundsList(statusResponse.map(item => item.fund));
      }
    } catch (e) {
      informationAlert("Error in getting funds", "error");
    }
    setLoading(false);
  }, [clientId]);

  return {
    fundsList,
    loading,
  };
};
