import { styled, Typography } from "@mui/material";

import { PENDING_YELLOW_COLOR } from "../../../../styles/colors";

export const PendingInfoDot = styled("span")(({ theme }) => ({
  height: "8px",
  width: "8px",
  borderRadius: "50%",
  backgroundColor: PENDING_YELLOW_COLOR,
  display: "inline-block",
  marginRight: "8px",
}));

export const ItalicLabel = styled((props: any) => {
  const { children, ...other } = props;

  return (
    <Typography {...other} variant="caption">
      {" "}
      {children}{" "}
    </Typography>
  );
})(({ theme }) => ({
  fontStyle: "italic",
}));

export const InfoLine = styled("div")(({ theme }) => ({
  marginTop: "12px",
  marginBottom: "12px",
}));

export const ListContainer = styled("div")(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "12px",
}));
