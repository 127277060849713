import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import { useFundsEffect } from "../../../../services/hooks/useFundsEffect/useFundsEffect";
import { addTeam, editTeam, getClientTeams } from "../../../../services/teams.service.v2";
import { TeamCreateUpdateParams, TeamCRUDStatus, TeamUpsertData } from "../../../../utils/types/team.type";
import {
    CREATE_TEAM_ERROR,
    CREATE_TEAM_SUCCESS,
    UPDATE_TEAM_ERROR,
    UPDATE_TEAM_SUCCESS
} from "../constants";

type Option = { id: string, name: string };

type Props = {
    team?: TeamUpsertData;
    onTeamDetailClose: Function;
    isNewTeam: boolean;
    setTeamResponse: Function;
}

export const useTeamDetails = ({
    team,
    onTeamDetailClose,
    isNewTeam,
    setTeamResponse,
}: Props) => {
    const [loading, setLoading] = useState<TeamCRUDStatus>();
    const [showExitConfirmation, setShowExitConfirmation] = useState<boolean>(false);
    const {
        state,
        informationAlert
    } = useContext(AppContext);
    const { clientId } = state.loginUser;
    const {
        fundsList,
        loading: loadingFunds,
    } = useFundsEffect();

    const {
        register,
        handleSubmit,
        formState: {
            errors
        },
        control,
        watch,
        reset
    } = useForm<TeamUpsertData>({
        defaultValues: team ? team : {},
    });
    const {
        isDirty
    } = useFormState({
        control
    });

    const [selectedFunds, setSelectedFunds] = useState<Option[]>([]);

    const funds = team?.funds?.map((item) => ({ ...item, id: item.id })) || [];

    useEffect(() => {
        setSelectedFunds(funds);
    }, [JSON.stringify(funds)]);

    const createTeam = async (data: TeamCreateUpdateParams) => {
        try {
            await addTeam(data);
            const response = await getClientTeams(clientId);
            const teams = response.items ?? [];

            setTeamResponse(teams);
            informationAlert(CREATE_TEAM_SUCCESS, "success");
            closeDrawer();
        } catch (error) {
            informationAlert(CREATE_TEAM_ERROR, "error");
        }
    };

    const updateTeam = async (data: TeamCreateUpdateParams) => {
        try {
            await editTeam(data);
            const response = await getClientTeams(clientId);
            const teams = response.items ?? [];

            setTeamResponse(teams);
            informationAlert(UPDATE_TEAM_SUCCESS, "success");
            closeDrawer();
            // TODO: Redirect to add members page
        } catch (error) {
            informationAlert(UPDATE_TEAM_ERROR, "error");
        }
    };

    const createUpdateTeam = async (data: TeamUpsertData) => {
        const funds = [...selectedFunds];
        const resultData: TeamCreateUpdateParams = {
            ...(isNewTeam ? {} : { id: data?.id }),
            name: data?.name,
            tenantId: clientId,
            funds: funds,
        };

        if (isNewTeam) {
            setLoading(TeamCRUDStatus.Adding);
            await createTeam(resultData);
        } else {
            setLoading(TeamCRUDStatus.Updating);
            await updateTeam(resultData);
        }

        setLoading(undefined);
    };

    const toggleDrawer = () => {
        if(isDirty || selectedFunds?.length > 0) {
            setShowExitConfirmation(true);
        } else {
            closeDrawer();
        }
    };

    const closeDrawer = () => {
        reset({});
        onTeamDetailClose();
        setShowExitConfirmation(false);
    };

    const keepDrawerOpen = () => {
        setShowExitConfirmation(false);
    };

    const removeSelectedFund = (id: string): void => {
        const filteredFunds = selectedFunds.filter(fund => fund.id !== id);
    
        setSelectedFunds(filteredFunds);
    };

    const handleFundsSelection = (item: Option, checked: boolean) => {
        let selected_options = [...selectedFunds];

        if(checked) {
            selected_options.push(item);
        } else {
            selected_options = selected_options.filter(option => option.id !== item.id);
        }
        setSelectedFunds(selected_options);
    };

    const handleAllFundsSelection = (items: Option[], checked: boolean) => {
        checked ? setSelectedFunds(items) : setSelectedFunds([]);
    };

    return {
        loading: loading || loadingFunds,
        register,
        handleSubmit,
        errors,
        control,
        createUpdateTeam,
        toggleDrawer,
        closeDrawer,
        fundsList,
        selectedFunds,
        showExitConfirmation,
        keepDrawerOpen,
        handleFundsSelection,
        handleAllFundsSelection,
        removeSelectedFund,
    };
};
