import { DateRange } from '@mui/x-date-pickers-pro';
import { cloneDeep } from 'lodash';
import { useContext, useState } from 'react';

import {
  CategoryFieldDef,
  GridData,
  GridRow,
  NumberFormatTypes,
  ReportGridBaseData,
  ValueFieldDef,
} from '../../../../../components/ReportGrid/ReportGrid.types';
import { AppContext } from '../../../../../core/context/appContextProvider';
import { getAllArkAccounts } from '../../../../../services/arkGL.service';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../../../services/columnOrder.service';
import { DateTimeFormat } from '../../../../../utils/helpers/format.helper';
import { getFirstDayOfYear } from '../../../../../utils/helpers/getFirstDayOfYear';
import { getLastDayOfLastYear } from '../../../../../utils/helpers/getLastDayOfPrevYear';
import { awaitReactUpdate } from '../../../../../utils/helpers/timeoutFunctions';
import { useEffectAsync } from '../../../../../utils/hooks/useEffectAsync.hook';
import {
  ColumnOrder,
  ViewItemsEntity,
} from '../../../../../utils/types/columnOrder';
import {
  Account,
  AccountCategoryProps,
  AccountHierarchyItem,
  AccountNumber,
  ColumnTypes,
  createAccountAttrributeGridDataSums,
  createAccountChildrenSums,
  CreditRange,
  DateColumnInfo,
  DebitRange,
  EndBalanceRange,
  FSDisplayNotSet,
  getAccountCategories,
  getAccountParentHierarchy,
  getFieldDefColumns,
  getLineItemsForDateCol,
  getTrialBalanceDetailsDataNew,
  GlStatuses,
  GridCallerData,
  insertMissingReportAccount,
  mergeCallerData,
  mergeDataProps,
  mergeLandPCallerData,
  mergeLandPDataProps,
  mergeSameFsNameToParent,
  netIncomeTotalCatStr,
  netInvestmentIncomeCatStr,
  netRealizedCatStr,
  netUnrealizedCatStr,
  ReportAccount,
  ReportLineItem,
  retainedEarningsTotalCatStr,
  retainedInvestmentIncomeCatStr,
  retainedRealizedCatStr,
  retainedUnrealizedCatStr,
  StartBalanceRange,
  suppressGridRowsIfZero,
  TrialBalanceDetailsParams,
  ViewKeyCode,
  viewKeyOrderDefaults,
} from '../shared';

export type BalanceSheetParams = {
  startDate: Date;
  endDate: Date;
  accounts: string[];
  ledgerIDs: string[];
  glStatus: GlStatuses[];
  attributes: string[];
  fsName?: string;
  summaryRow?: boolean;
  isCustom?: boolean;
};

export const useBalanceSheet = (
  params: BalanceSheetParams,
  reportView: string,
  suppressIfZero: boolean,
  dateRange: DateRange<Date>,
  funds: any,
  attributes: any,
  viewFilters: any,
  setIsLoading: (isLoading: boolean) => void,
  handleValueLinkButtonClick: (
    gridRow: GridRow,
    valueFieldOrder: ValueFieldDef,
    dateColInfo: DateColumnInfo[],
    isCustom: boolean,
    currentDecimals: number,
    netCatStartDate: Date | string,
    retainedCatEndDate: Date | string
  ) => void,
  onDataGridChange: (gridData: GridData, exportName: string) => void,
  handleDateRangeChange: (
    dateRange: DateRange<Date>,
    clearGrid: boolean
  ) => void,
  generalAttributes?: string[],
  fetchGlReport?: boolean
) => {
  const { state } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [categoryFieldOrder, setCategoryFieldOrder] = useState<
    CategoryFieldDef[]
  >([]);
  const [currentCurrency, setCurrentCurrency] = useState('');
  const [currentDecimals, setCurrentDecimals] = useState(-1);
  const [dataFieldOrder, setDataFieldOrder] = useState<ValueFieldDef[]>([]);
  const [footerRollUp, setFooterRollUp] = useState(true);
  const [hideUseerCategorySelector, setHideUseerCategorySelector] =
    useState(true);
  const [pinActionColumn, setPinActionColumn] = useState<boolean>(true);
  const [reportGridBaseData, setReportGridBaseData] = useState<
    ReportGridBaseData[]
  >([]);
  const [dateColInfosResponse, setDateColInfosResponse] = useState<
    DateColumnInfo[]
  >([]);
  const [dateColInfosResponseArray, setDateColInfosResponseArray] = useState<
    DateColumnInfo[]
  >([]);
  const [rollupMode, setRollupMode] = useState(false);
  const [showGroups, setShowGroups] = useState(true);
  const [showMonthlyGroup, setShowMonthlyGroup] = useState(false);
  const [showQuarterlyGroup, setShowQuarterlyGroup] = useState(false);
  const [showYearlyGroup, setShowYearlyGroup] = useState(false);
  const [reportAccountsResponse, setReportAccountsResponse] = useState<
    ReportAccount[]
  >([]);
  const [firstLineItemDate, setFirstLineItemDate] = useState<Date>();
  const [retainedStartDate, setRetainedStartDate] = useState<Date>();
  const [buildReportDataGridCounter, setBuildReportDataGridCounter] =
    useState(0);
  const [showStartBalance, setShowStartBalance] = useState(true);
  const [showSelected, setShowSelected] = useState(false);
  const [showDebit, setShowDebit] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [showItd, setShowItd] = useState(true);
  const [showEndBalance, setShowEndBalance] = useState(false);
  const [hideAllZeros, setHideAllZeros] = useState(true); //only false for Trial Balance, all other views are true
  const [viewKeyResponse, setViewKeyResponse] = useState<ColumnOrder>();
  const [viewKeyCounter, setViewKeyCounter] = useState(0);
  const [viewKeyOrder, setViewKeyOrder] = useState<any>([]);
  const pushViewKeyOrder = (viewItem: any) => {
    setViewKeyOrder((prevArray: any) => [...prevArray, viewItem]);
  };

  useEffectAsync(
    async (isCanceled) => {
      setIsLoading(true);
      setViewKeyOrder([]);

      const trialBalanceResponse = await getTrialBalanceDetailsDataNew(
        params,
        isCanceled
      );

      if (!isCanceled() && !trialBalanceResponse) return;

      const arkAccountsResponse = await getAllArkAccounts();

      if (!isCanceled() && !arkAccountsResponse) return;

      const { reportAccounts, firstLineItemDate, decimals, currency } =
        trialBalanceResponse!;

      const viewKeyResponseData = await getColumnOrder(
        `${reportView.toUpperCase()}_FIELD_VIEW`,
        clientId
      );

      setViewKeyResponse(cloneDeep(viewKeyResponseData));

      viewKeyResponseData.viewItems?.map((viewItem) => {
        switch (viewItem.code) {
          case StartBalanceRange:
            viewItem.visible
              ? setShowStartBalance(true)
              : setShowStartBalance(false);
            pushViewKeyOrder(ViewKeyCode.START_BALANCE);
            break;
          case DebitRange:
            viewItem.visible ? setShowDebit(true) : setShowDebit(false);
            pushViewKeyOrder(ViewKeyCode.DEBIT);
            break;
          case CreditRange:
            viewItem.visible ? setShowCredit(true) : setShowCredit(false);
            pushViewKeyOrder(ViewKeyCode.CREDIT);
            break;
          case EndBalanceRange:
            viewItem.visible
              ? setShowEndBalance(true)
              : setShowEndBalance(false);
            pushViewKeyOrder(ViewKeyCode.END_BALANCE);
            break;
          default:
            if (viewItem.code.includes(ViewKeyCode.MONTHLY)) {
              viewItem.visible
                ? setShowMonthlyGroup(true)
                : setShowMonthlyGroup(false);
              pushViewKeyOrder(ViewKeyCode.MONTHLY);
            }

            if (viewItem.code.includes(ViewKeyCode.QUARTERLY)) {
              viewItem.visible
                ? setShowQuarterlyGroup(true)
                : setShowQuarterlyGroup(false);
              pushViewKeyOrder(ViewKeyCode.QUARTERLY);
            }

            if (viewItem.code.includes(ViewKeyCode.YEARLY)) {
              viewItem.visible
                ? setShowYearlyGroup(true)
                : setShowYearlyGroup(false);
              pushViewKeyOrder(ViewKeyCode.YEARLY);
            }

            if (viewItem.code.includes(ViewKeyCode.ITD)) {
              viewItem.visible ? setShowItd(true) : setShowItd(false);
              pushViewKeyOrder(ViewKeyCode.ITD);
            }

            if (viewItem.code.includes(ViewKeyCode.SELECTED)) {
              viewItem.visible ? setShowSelected(true) : setShowSelected(false);
              pushViewKeyOrder(ViewKeyCode.SELECTED);
            }
        }
      });

      setCurrentCurrency(currency);
      setCurrentDecimals(decimals);
      setReportAccountsResponse(reportAccounts);

      let tempAccounts = arkAccountsResponse.items as Account[];

      tempAccounts = tempAccounts.sort((a, b) => (a.name < b.name ? -1 : 1));

      if (params.startDate) {
        setFirstLineItemDate(params.startDate);
        setRetainedStartDate(firstLineItemDate);
      } else {
        setFirstLineItemDate(firstLineItemDate);
        setRetainedStartDate(firstLineItemDate);
        handleDateRangeChange([firstLineItemDate, params.endDate], false);
      }

      setAccounts(tempAccounts);
      setIsLoading(false);
      setBuildReportDataGridCounter(buildReportDataGridCounter + 1);
    },
    [suppressIfZero]
  );

  useEffectAsync(
    async (isCanceled) => {
      if (buildReportDataGridCounter === 0) return;
      setIsLoading(true);

      const trialBalanceResponse = await getTrialBalanceDetailsDataNew(
        params,
        isCanceled
      );

      if (!isCanceled() && !trialBalanceResponse) return;

      const arkAccountsResponse = await getAllArkAccounts();

      if (!isCanceled() && !arkAccountsResponse) return;

      const { reportAccounts, firstLineItemDate, decimals, currency } =
        trialBalanceResponse!;

      setCurrentCurrency(currency);
      setCurrentDecimals(decimals);
      setReportAccountsResponse(reportAccounts);

      let tempAccounts = arkAccountsResponse.items as Account[];

      tempAccounts = tempAccounts.sort((a, b) => (a.name < b.name ? -1 : 1));

      if (params.startDate) {
        setFirstLineItemDate(params.startDate);
        setRetainedStartDate(firstLineItemDate);
      } else {
        setFirstLineItemDate(firstLineItemDate);
        setRetainedStartDate(firstLineItemDate);
        handleDateRangeChange([firstLineItemDate, params.endDate], false);
      }

      setAccounts(tempAccounts);
      setIsLoading(false);
      setBuildReportDataGridCounter(buildReportDataGridCounter + 1);
    },
    [showMonthlyGroup, showQuarterlyGroup, showYearlyGroup, fetchGlReport]
  );

  useEffectAsync(async () => {
    if (buildReportDataGridCounter === 0) return;

    setIsLoading(true);
    await awaitReactUpdate();

    const currencyFormat = `Currency.${currentDecimals}` as NumberFormatTypes;
    const accountHierarchy = getAccountParentHierarchy(accounts);

    insertMissingReportAccount(
      accountHierarchy,
      reportAccountsResponse,
      generalAttributes!,
      currentDecimals
    );

    if (suppressIfZero) suppressGridRowsIfZero(reportAccountsResponse, true);

    const currentReportCategoryFieldDefs = getAccountCategories(
      accountHierarchy,
      'account'
    );

    let fieldDefsArray: ValueFieldDef[] = [];
    let dateColInfosArray: DateColumnInfo[] = [];

    let viewKeyOrderData = viewKeyOrder.filter(
      (value: any, index: any) => viewKeyOrder.indexOf(value) === index
    );

    if (viewKeyOrderData.length < 9) viewKeyOrderData = viewKeyOrderDefaults;

    const startDate = dateRange[0] ? dateRange[0] : firstLineItemDate;
    const { fieldDefs, dateColInfos } = getFieldDefColumns(
      startDate!,
      dateRange[1]!,
      currencyFormat,
      hideAllZeros,
      showStartBalance,
      showMonthlyGroup,
      showQuarterlyGroup,
      showYearlyGroup,
      showSelected,
      showDebit,
      showCredit,
      showItd,
      showEndBalance,
      viewKeyResponse,
      viewKeyOrderData,
      false,
      reportView
    );

    fieldDefsArray = fieldDefs;
    dateColInfosArray = dateColInfos;
    setDateColInfosResponseArray(dateColInfos);

    const filterDateColInfos: DateColumnInfo[] = [];

    dateColInfosArray.map((column) => {
      if (column.category === undefined) {
        filterDateColInfos.push(column);
      }
      if (showMonthlyGroup) {
        column.category === ColumnTypes.Monthly
          ? filterDateColInfos.push(column)
          : '';
      }
      if (showQuarterlyGroup) {
        column.category === ColumnTypes.Quarterly
          ? filterDateColInfos.push(column)
          : '';
      }
      if (showYearlyGroup) {
        column.category === ColumnTypes.Yearly
          ? filterDateColInfos.push(column)
          : '';
      }
    });

    fieldDefsArray.map((field) => {
      if (field.category === ColumnTypes.Monthly) {
        field.visible = showMonthlyGroup;
      }
      if (field.category === ColumnTypes.Quarterly) {
        field.visible = showQuarterlyGroup;
      }
      if (field.category === ColumnTypes.Yearly) {
        field.visible = showYearlyGroup;
      }
    });

    const reportGridData = buildReportGridData(
      reportAccountsResponse,
      filterDateColInfos,
      currentCurrency,
      accountHierarchy,
      currentReportCategoryFieldDefs
    );

    const suppressed = reportGridData.filter(
      (row: any) => row.callerData.reportAccounts.length > 0
    );

    setCategoryFieldOrder(cloneDeep(currentReportCategoryFieldDefs));
    setDataFieldOrder(cloneDeep(fieldDefsArray));
    setDateColInfosResponse(cloneDeep(filterDateColInfos));
    setPinActionColumn(true);
    setReportGridBaseData(
      suppressIfZero ? cloneDeep(suppressed) : cloneDeep(reportGridData)
    );
    setIsLoading(false);
  }, [buildReportDataGridCounter]);

  const dateRangeFilter = {
    name: 'Date Range',
    title: 'Report Range',
    visible: false,
    useLinkButton: false,
    startDate: dateRange[0]!,
    endDate: dateRange[1]!,
  };

  function buildReportGridData(
    reportAccounts: ReportAccount[],
    dateColInfos: DateColumnInfo[],
    responseCurrency: string,
    accountHierarchy: AccountHierarchyItem[],
    categoryFieldDefs: CategoryFieldDef[]
  ): ReportGridBaseData[] {
    const incomeAttrib = attributes.find((a: any) => a.type === 'Income');
    const expenseAttrib = attributes.find((a: any) => a.type === 'Expense');
    const gainLossAttrib = attributes.find((a: any) => a.type === 'Gain/Loss');
    const partnersCap = attributes.filter(
      (a: any) => a.type === 'Partners Capital'
    );

    const partnersCapitalCatStr = '3|Partners Capital';
    const cumulativeNetInvestmentIncomeCatStr =
      'ZZ1|Cumulative net investment income/(loss)';
    const cumulativeNetRealizedGainLossCatStr =
      'ZZ2|Cumulative net realized gain/(loss) on investments';
    const cumulativeNetUnrealizedGainLossCatStr =
      'ZZ3|Cumulative net unrealized gain/(loss) on investments';

    const netCatStartDate = getFirstDayOfYear(dateRange[1]!);
    const retainedCatEndDate = getLastDayOfLastYear(dateRange[1]!);

    const reportGridData: ReportGridBaseData[] = [];
    const exAndGLreportGridData: ReportGridBaseData[] = [];
    const netGridData: ReportGridBaseData[] = [];
    const retainedGridData: ReportGridBaseData[] = [];
    const netAndRetainedGridData: ReportGridBaseData[] = [];
    const netAccounts: ReportAccount[] = [];
    const retainedAccounts: ReportAccount[] = [];
    const netAndRetainedAccounts: ReportAccount[] = [];

    const allAccounts: ReportAccount[] = [];

    reportAccounts.forEach((acct) => {
      if (expenseAttrib.id === acct.attributeId) {
        acct.lineItems.forEach((li) => (li['subtract'] = true));
      }

      allAccounts.push(acct);
    });

    for (let i = 0; i < allAccounts.length; i++) {
      const account = allAccounts[i];

      let categoryProps = {} as AccountCategoryProps;
      const netCategoryProps = {} as AccountCategoryProps;
      const retainedCategoryProps = {} as AccountCategoryProps;

      const dataProps = {} as any;
      const foundIncome = incomeAttrib.id === account.attributeId;
      const foundExpense = expenseAttrib.id === account.attributeId;
      const foundGainLoss = gainLossAttrib.id === account.attributeId;

      if (!foundIncome && !foundExpense && !foundGainLoss) {
        categoryProps = getAccountCategoryProperties(
          account.accountId,
          accountHierarchy,
          categoryFieldDefs
        );
      } else {
        let cat: string;
        let netCat: string;
        let retainedCat: string;

        if (foundIncome || foundExpense) {
          cat = cumulativeNetInvestmentIncomeCatStr;
          netCat = netInvestmentIncomeCatStr;
          retainedCat = retainedInvestmentIncomeCatStr;
        } else {
          const acctName = account.accountName.toLowerCase();

          // since realized is in unrealized we need to check both
          if (
            acctName.includes('realized') &&
            !acctName.includes('unrealized')
          ) {
            cat = cumulativeNetRealizedGainLossCatStr;
            netCat = netRealizedCatStr;
            retainedCat = retainedRealizedCatStr;
          } else {
            cat = cumulativeNetUnrealizedGainLossCatStr;
            netCat = netUnrealizedCatStr;
            retainedCat = retainedUnrealizedCatStr;
          }
        }

        categoryProps.attributeId = partnersCap.id;
        categoryProps.attributeName = partnersCapitalCatStr;
        categoryProps.account_id_1 = undefined;
        categoryProps.account_name_1 = cat;
        categoryProps.account_id_2 = undefined;
        categoryProps.account_name_2 = undefined;
        categoryProps.account_id_3 = undefined;
        categoryProps.account_name_3 = undefined;

        netCategoryProps.attributeId = partnersCap.id;
        netCategoryProps.attributeName = partnersCapitalCatStr;
        netCategoryProps.account_id_1 = undefined;
        netCategoryProps.account_name_1 = netCat;
        netCategoryProps.account_id_2 = undefined;
        netCategoryProps.account_name_2 = undefined;
        netCategoryProps.account_id_3 = undefined;
        netCategoryProps.account_name_3 = undefined;

        retainedCategoryProps.attributeId = partnersCap.id;
        retainedCategoryProps.attributeName = partnersCapitalCatStr;
        retainedCategoryProps.account_id_1 = undefined;
        retainedCategoryProps.account_name_1 = retainedCat;
        retainedCategoryProps.account_id_2 = undefined;
        retainedCategoryProps.account_name_2 = undefined;
        retainedCategoryProps.account_id_3 = undefined;
        retainedCategoryProps.account_name_3 = undefined;

        const netAccount = cloneDeep(account);
        const retainedAccount = cloneDeep(account);

        netAccount.categoryProps = netCategoryProps;
        retainedAccount.categoryProps = retainedCategoryProps;

        netAccount.lineItems = netAccount.lineItems.filter(
          (li: ReportLineItem) =>
            DateTimeFormat.isDateBetween(
              li.date,
              netCatStartDate,
              dateRange[1]!
            )
        );
        retainedAccount.lineItems = retainedAccount.lineItems.filter(
          (li: ReportLineItem) =>
            DateTimeFormat.isDateBetween(
              li.date,
              retainedStartDate!,
              retainedCatEndDate
            )
        );

        let netStartBalance: number = 0;
        let retainedEndBalance: number = 0;

        retainedAccount.lineItems.forEach((lineItem: ReportLineItem) => {
          if (lineItem.subtract) {
            netStartBalance -= lineItem.amount;
          } else {
            netStartBalance += lineItem.amount;
          }
        });

        netAccount.lineItems.forEach((lineItem: ReportLineItem) => {
          if (lineItem.subtract) {
            retainedEndBalance -= lineItem.amount;
          } else {
            retainedEndBalance += lineItem.amount;
          }
        });

        netAccount.startBalance = 0;
        retainedAccount.startBalance = 0;
        retainedAccount.endBalance = account.endBalance - retainedEndBalance;

        netAccounts.push(netAccount);
        retainedAccounts.push(retainedAccount);
        netAndRetainedAccounts.push(netAccount);
        netAndRetainedAccounts.push(retainedAccount);
      }

      // Set the dataProps based on the account api response data
      dataProps[EndBalanceRange] = account.endBalance;
      dataProps[StartBalanceRange] = account.startBalance;
      dataProps[AccountNumber] = account.accountNo;

      // Filter through each column and pass it the date range selected "dc" to filter items in range and type
      // getLineItems is part of the shared.tsx
      dateColInfos
        .filter(
          (cd) =>
            cd.name !== StartBalanceRange &&
            cd.name !== EndBalanceRange &&
            cd.numberFormat !== 'Text'
        )
        .forEach((dc) => {
          //copy to not modify original dc to capture ITD for grouped columns
          const dcCopy = cloneDeep(dc);

          const groupedColumn =
            dc.name.includes('month') ||
            dc.name.includes('quarter') ||
            dc.name.includes('year')
              ? true
              : false;

          let groupedLineItemRangeCheck = [];

          if (groupedColumn && firstLineItemDate) {
            dcCopy.startDate = firstLineItemDate;
            groupedLineItemRangeCheck = getLineItemsForDateCol(
              account.lineItems,
              dc
            );
          }

          const lineItemsInRange = groupedColumn
            ? getLineItemsForDateCol(account.lineItems, dcCopy)
            : getLineItemsForDateCol(account.lineItems, dc);

          let lIvalue: number;

          if (foundIncome || foundExpense) {
            lIvalue = 0;

            lineItemsInRange.forEach((lineItem) => {
              if (lineItem.subtract) {
                lIvalue -= lineItem.amount;
              } else {
                lIvalue += lineItem.amount;
              }
            });

            dataProps[EndBalanceRange] = lIvalue;
          } else {
            lIvalue = lineItemsInRange.reduce(
              (prevVal, lineItem) => prevVal + lineItem.amount,
              0
            );
          }

          if (groupedColumn) {
            dataProps[dc.name] =
              groupedLineItemRangeCheck.length > 0 || lIvalue !== 0
                ? lIvalue
                : undefined;
          } else {
            dataProps[dc.name] =
              lineItemsInRange.length > 0 ? lIvalue : undefined;
          }

          if (dc.name.includes('itd')) {
            dataProps[EndBalanceRange] = dataProps[dc.name];
          }
        });

      const callerData: GridCallerData = { reportAccounts: [account] };

      const gridData = {
        itemId: account.accountId,
        currencyCode: responseCurrency,
        categoryProps: categoryProps,
        dataProps: dataProps,
        callerData: callerData,
        decimals: currentDecimals,
      } as ReportGridBaseData;

      if (!foundIncome && !foundExpense && !foundGainLoss) {
        reportGridData.push(gridData);
      } else {
        exAndGLreportGridData.push(gridData);
      }
    }

    for (let i = 0; i < netAndRetainedAccounts.length; i++) {
      const account = netAndRetainedAccounts[i];

      const categoryProps = account.categoryProps;
      const dataProps = {} as any;

      const foundIncome = incomeAttrib.id === account.attributeId;
      const foundExpense = expenseAttrib.id === account.attributeId;
      const foundGainLoss = gainLossAttrib.id === account.attributeId;

      // Set the dataProps based on the account api response data
      dataProps[EndBalanceRange] = account.endBalance;
      dataProps[StartBalanceRange] = account.startBalance;
      dataProps[AccountNumber] = account.accountNo;

      // Filter through each column and pass it the date range selected "dc" to filter items in range and type
      // getLineItems is part of the shared.tsx
      dateColInfos
        .filter(
          (cd) =>
            cd.name !== StartBalanceRange &&
            cd.name !== EndBalanceRange &&
            cd.numberFormat !== 'Text'
        )
        .forEach((dc) => {
          if (
            account.categoryProps?.account_name_1 ===
              retainedEarningsTotalCatStr ||
            account.categoryProps?.account_name_1 ===
              retainedInvestmentIncomeCatStr ||
            account.categoryProps?.account_name_1 ===
              retainedUnrealizedCatStr ||
            account.categoryProps?.account_name_1 === retainedRealizedCatStr
          ) {
            if (
              dc.name.includes('itd') ||
              dc.name.includes('monthly') ||
              dc.name.includes('quarterly') ||
              dc.name.includes('yearly') ||
              dc.name.includes('selected')
            ) {
              ('');
            } else {
              dc.startDate = retainedStartDate!;
            }
          }

          const dcCopy = cloneDeep(dc);

          const groupedColumn =
            dc.name.includes('month') ||
            dc.name.includes('quarter') ||
            dc.name.includes('year')
              ? true
              : false;

          if (groupedColumn && retainedStartDate)
            dcCopy.startDate = retainedStartDate;

          let lineItemsInRange = groupedColumn
            ? getLineItemsForDateCol(account.lineItems, dcCopy)
            : getLineItemsForDateCol(account.lineItems, dc);

          if (groupedColumn)
            lineItemsInRange = getLineItemsForDateCol(
              lineItemsInRange,
              dateRangeFilter
            );

          let lIvalue: number;

          if (foundIncome || foundExpense) {
            lIvalue = 0;

            lineItemsInRange.forEach((lineItem) => {
              if (lineItem.subtract) {
                lIvalue -= lineItem.amount;
              } else {
                lIvalue += lineItem.amount;
              }
            });

            dataProps[EndBalanceRange] = lIvalue;
          } else {
            lIvalue = lineItemsInRange.reduce(
              (prevVal, lineItem) => prevVal + lineItem.amount,
              0
            );
          }

          if (!lineItemsInRange.length) {
            dataProps[EndBalanceRange] = 0;
          }

          dataProps[dc.name] = lIvalue;

          if (dc.name.includes('itd')) {
            dataProps[EndBalanceRange] = dataProps[dc.name];
          }
        });

      const callerData: GridCallerData = { reportAccounts: [account] };

      const gridData = {
        itemId: account.accountId,
        currencyCode: responseCurrency,
        categoryProps: categoryProps,
        dataProps: dataProps,
        callerData: callerData,
        decimals: currentDecimals,
      } as ReportGridBaseData;

      if (!foundIncome && !foundExpense && !foundGainLoss) {
        reportGridData.push(gridData);
      } else {
        netAndRetainedGridData.push(gridData);
        if (/ZZ[123]/.test(gridData.categoryProps.account_name_1)) {
          netGridData.push(gridData);
        } else {
          retainedGridData.push(gridData);
        }
      }
    }

    const filters = viewFilters.filteredAttributesFilter.filter(
      (a: any) =>
        a.id !== gainLossAttrib.id &&
        a.id !== incomeAttrib.id &&
        a.id !== expenseAttrib.id
    );

    createAccountAttrributeGridDataSums(
      reportGridData,
      filters,
      dateColInfos,
      currentCurrency
    );
    createAccountChildrenSums(
      reportGridData,
      dateColInfos,
      categoryFieldDefs,
      currentCurrency,
      false
    );

    const partnersCapitalRow = reportGridData.find(
      (rgd) =>
        rgd.categoryProps.attributeName === partnersCapitalCatStr &&
        !rgd.categoryProps.account_name_1
    )!;

    const numberCols = dateColInfos.filter((c) => c.numberFormat !== 'Text');

    netAndRetainedGridData.forEach((rgd) => {
      numberCols.forEach((col) => {
        if (
          typeof rgd.dataProps[col.name] === 'number' &&
          !isNaN(rgd.dataProps[col.name])
        ) {
          partnersCapitalRow.dataProps[col.name] += rgd.dataProps[col.name];
        }
      });

      partnersCapitalRow.callerData.reportAccounts =
        partnersCapitalRow.callerData.reportAccounts.concat(
          rgd.callerData.reportAccounts
        );

      reportGridData.push(rgd);
    });

    if (netGridData.length) {
      const netTotalCatProps = {
        account_name_1: netIncomeTotalCatStr,
        account_name_2: undefined,
        account_name_3: undefined,
        account_id_1: undefined,
        account_id_2: undefined,
        account_id_3: undefined,
        attributeName: '3|Partners Capital',
      };

      const netTotalDataProps = mergeDataProps(netGridData);
      const netTotalCallerData = mergeCallerData(netGridData);

      const netIncomeTotalDataRow = {
        itemId: 'net_income_total_row_id',
        currencyCode: currentCurrency ?? '',
        categoryProps: netTotalCatProps,
        dataProps: netTotalDataProps,
        callerData: { reportAccounts: netTotalCallerData },
      } as ReportGridBaseData;

      reportGridData.push(netIncomeTotalDataRow);
    }

    if (retainedGridData.length) {
      const retainedTotalCatProps = {
        account_name_1: retainedEarningsTotalCatStr,
        account_name_2: undefined,
        account_name_3: undefined,
        account_id_1: undefined,
        account_id_2: undefined,
        account_id_3: undefined,
        attributeName: '3|Partners Capital',
      };

      const retainedTotalDataProps = mergeDataProps(retainedGridData);
      const retainedTotalCallerData = mergeCallerData(retainedGridData);

      const retainedEarningsTotalDataRow = {
        itemId: 'retained_earnings_total_row_id',
        currencyCode: currentCurrency ?? '',
        categoryProps: retainedTotalCatProps,
        dataProps: retainedTotalDataProps,
        callerData: { reportAccounts: retainedTotalCallerData },
      } as ReportGridBaseData;

      reportGridData.push(retainedEarningsTotalDataRow);
    }

    let liabilitiesRow = {} as ReportGridBaseData;
    let partnersCapRow = {} as ReportGridBaseData;

    reportGridData.map((item) => {
      if (
        item.categoryProps.attributeName === '2|Liabilities' &&
        Object.keys(item.categoryProps).length === 2
      )
        liabilitiesRow = item;
      if (
        item.categoryProps.attributeName === '3|Partners Capital' &&
        Object.keys(item.categoryProps).length === 2
      )
        partnersCapRow = item;
    });

    if (liabilitiesRow && partnersCapRow) {
      const lAndPCatProps = {
        attributeId: 'l_and_pc_cat_props_id',
        attributeName: '4|Total Liabilities & Partners Capital',
      };

      const lAndPDataProps = mergeLandPDataProps(
        liabilitiesRow,
        partnersCapRow
      );
      const lAndPCallerData = mergeLandPCallerData(
        liabilitiesRow,
        partnersCapRow
      );

      const liabilitiesAndPartnersCap = {
        itemId: 'l_and_pc_row_id',
        currencyCode: currentCurrency ?? '',
        categoryProps: lAndPCatProps,
        dataProps: lAndPDataProps,
        callerData: { reportAccounts: lAndPCallerData },
      } as ReportGridBaseData;

      reportGridData.push(liabilitiesAndPartnersCap);
    }

    return reportGridData;
  }

  function getAccountCategoryProperties(
    reportAccountId: string,
    accountHierarchy: AccountHierarchyItem[],
    categoryFieldDefs: CategoryFieldDef[]
  ): any {
    const acct = accountHierarchy!.find(
      (a) => a.accountId === reportAccountId
    )!;
    const attrib = attributes.find((f: any) => f.id === acct.attributeId);
    const categoryProps = {} as any;

    (categoryProps.attributeId = attrib.id),
      (categoryProps.attributeName = getCategoryNameWithOrder(attrib.type));
    categoryFieldDefs = categoryFieldDefs.filter((c) => c.id !== 'attributeId');

    categoryFieldDefs.forEach((cat, index) => {
      const parentAccount = acct.parentAccounts[index];

      // @ts-ignore: expression error
      categoryProps[cat.id] = parentAccount?.id;
      // @ts-ignore: expression error
      categoryProps[cat.name] = parentAccount?.number
        ? parentAccount?.number + ' - ' + parentAccount?.name
        : parentAccount?.name;
    });

    return categoryProps;
  }

  function getCategoryNameWithOrder(name: string) {
    let order: string = '';

    switch (name) {
      case 'Assets':
      case 'Income':
        order = '1';
        break;
      case 'Liabilities':
      case 'Expense':
        order = '2';
        break;
      case 'Partners Capital':
      case 'Gain/Loss':
        order = '3';
        break;
    }

    return `${order}|${name}`;
  }

  function handleCategoryFieldOrderChange(
    updatedCategoryFieldOrder: CategoryFieldDef[]
  ) {
    setCategoryFieldOrder(cloneDeep(updatedCategoryFieldOrder));
  }

  function handleDataFieldOrderChange(
    updatedDataFieldOrder: ValueFieldDef[],
    fieldName?: string
  ) {
    if (fieldName) {
      if (
        fieldName === ColumnTypes.Monthly ||
        fieldName === ColumnTypes.Quarterly ||
        fieldName === ColumnTypes.Yearly
      ) {
        switch (fieldName) {
          case 'Months': {
            setShowMonthlyGroup(!showMonthlyGroup);
            break;
          }
          case 'Quarterly': {
            setShowQuarterlyGroup(!showQuarterlyGroup);
            break;
          }
          case 'Yearly': {
            setShowYearlyGroup(!showYearlyGroup);
          }
        }
        updatedDataFieldOrder.map((column: any) => {
          if (column.category === fieldName) {
            column.visible = !column.visible;
          }
        });
        setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
        setViewKeyCounter(viewKeyCounter + 1);
        if (viewKeyCounter !== 0) {
          saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
        }
      } else {
        const dataField = updatedDataFieldOrder.find(
          (field) => field.name === fieldName
        )!;

        dataField.visible = !dataField.visible;

        setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
        setViewKeyCounter(viewKeyCounter + 1);
        if (viewKeyCounter !== 0) {
          saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
        }
      }
    } else {
      setDataFieldOrder(cloneDeep(updatedDataFieldOrder));
      setViewKeyCounter(viewKeyCounter + 1);
      if (viewKeyCounter !== 0) {
        saveFieldDefOrder(cloneDeep(updatedDataFieldOrder));
      }
    }
  }
  async function saveFieldDefOrder(updatedDataFieldOrder: ValueFieldDef[]) {
    const firstOccurence: any = {};
    const itemMap = updatedDataFieldOrder.filter((item) => {
      const type = item.name.includes(ViewKeyCode.MONTHLY)
        ? ViewKeyCode.MONTHLY
        : item.name.includes(ViewKeyCode.QUARTERLY)
        ? ViewKeyCode.QUARTERLY
        : item.name.includes(ViewKeyCode.YEARLY)
        ? ViewKeyCode.YEARLY
        : null;

      if (type && !firstOccurence[type]) {
        firstOccurence[type] = true;
        return true;
      }

      return !type;
    });

    let orderNum = 0;
    const viewItems = itemMap.map((catCol) => {
      orderNum += 1;

      return {
        label: catCol.headerName,
        code: catCol.name,
        order: orderNum,
        visible: catCol.visible,
      } as ViewItemsEntity;
    });

    viewItems.sort((a, b) => a.order - b.order);
    const newColumnOrder: ColumnOrder = {
      clientId: clientId,
      viewKey: `${reportView.toUpperCase()}_FIELD_VIEW`,
      viewItems: viewItems,
    };

    try {
      await saveColumnOrder(newColumnOrder);
    } catch (e) {
      informationAlert('Error saving column order.', 'error');
    }
  }

  function handleDataGridChange(gridData: GridData) {
    onDataGridChange(
      gridData,
      `report export ${DateTimeFormat.getFormattedDate(new Date())}.csv`
    );
  }

  function handleValueClick(gridRow: GridRow, valueFieldOrder: ValueFieldDef) {
    const netCatStartDate = getFirstDayOfYear(dateRange[1]!);
    const retainedCatEndDate = getLastDayOfLastYear(dateRange[1]!);

    // @ts-ignore
    handleValueLinkButtonClick(
      gridRow,
      valueFieldOrder,
      dateColInfosResponse,
      false,
      currentDecimals,
      netCatStartDate,
      retainedCatEndDate
    ); //false because not custom
  }

  return {
    categoryFieldOrder,
    currentCurrency,
    currentDecimals,
    dataFieldOrder,
    footerRollUp,
    handleCategoryFieldOrderChange,
    handleDataFieldOrderChange,
    handleDataGridChange,
    handleValueClick,
    hideUseerCategorySelector,
    pinActionColumn,
    reportGridBaseData,
    rollupMode,
    setShowGroups,
    setShowMonthlyGroup,
    setShowQuarterlyGroup,
    setShowYearlyGroup,
    showGroups,
    showMonthlyGroup,
    showQuarterlyGroup,
    showYearlyGroup,
  };
};

function informationAlert(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
