import { FormControlLabel, Switch, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import ConfirmationDialog from '../../../../../components/Modal/ConfirmationDialog';
import useRole from '../../../../../core/routing/useRole';
import { M_DASH_UNICODE } from '../../../../../utils/constants/constants';
import { ScopeRole } from '../../../../../utils/types/user.type';
import { useDocumentsContext } from '../../../hooks';
import { defaultPageSize } from '../../../hooks/useDocuments.hooks';
import { NewItemDot } from './DocumentsGridCells.styles';

type Props = {
  row: any;
};

const FileStatusCellRenderer: React.FC<Props> = ({
  row,
}: Props): ReactElement => {
  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const isEditable: boolean =
    !!isSuperAdmin || !!isClientAdmin || !!isBasicAdmin;

  const { state, toggleFileStatus } = useDocumentsContext();

  const [published, setPublished] = useState<boolean>(false);
  const [showPrompt, setPrompt] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPublished(row.published);
    setLoading(false);
  }, [row.published]);

  const filters: any = { ...(state?.selectedFilters || {}) };

  if (
    state?.selectedFilters['funds'].length ===
    state?.filtersData['funds'].length
  ) {
    filters.funds = [];
  }
  if (
    state?.selectedFilters['investors'].length ===
    state?.filtersData['investors'].length
  ) {
    filters.investors = [];
  }
  if (
    state?.selectedFilters['quarters'].length ===
    state?.filtersData['quarters'].length
  ) {
    filters.quarters = [];
  }

  filters.searchText = state?.selectedFilters.searchText || '';

  const payload = {
    page: 1,
    pageSize: state?.pagination.pageSize || defaultPageSize,
    ...filters,
    sort: state?.sort || [],
  };

  return row.type === 'folder' ? (
    <Typography variant="cells">{M_DASH_UNICODE}</Typography>
  ) : isEditable ? (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={published}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              setLoading(true);
              if (
                e.target.checked &&
                (isEmpty(row.investors) || isEmpty(row.funds))
              ) {
                setPrompt(true);
              } else {
                showPrompt && setPrompt(false);
                toggleFileStatus(
                  row,
                  state?.currentFolder?.id,
                  e.target.checked,
                  payload
                );
              }
            }}
            name="published"
          />
        }
        label={published ? 'Published' : 'Unpublished'}
        disabled={
          loading || !isEditable || state?.isPageLocked || state?.isReadOnly
        }
      />
      <ConfirmationDialog
        open={showPrompt}
        onClose={() => setPrompt(false)}
        id="prompt_box_publish"
        actionDisplayType="inline"
        actions={[
          {
            label: 'OK',
            onClick: () => {
              setPrompt(false);
              toggleFileStatus(
                row,
                state?.currentFolder?.id,
                !published,
                payload
              );
            },
            id: 'ok_button',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Warning"
        content="The file will not be available on client portal unless it has both investors and funds."
      />
    </>
  ) : (
    <>
      <NewItemDot
        color={row.published ? 'green' : 'toggle-grey'}
        sx={{ position: 'static' }}
        title="published"
      />
      <Typography>{row.published ? 'Published' : 'Unpublished'}</Typography>
    </>
  );
};

export default FileStatusCellRenderer;
