import { FormLabel, Stack, styled, TextField } from "@mui/material";

export const StyledFormLabel = styled(FormLabel)(({
  theme
}) => ({
  color: theme.palette.text.primary,
}));

export const StyledStack = styled(Stack)(() => ({
  minWidth: '400px'
}));

export const StyledOrderTextField = styled(TextField)(() => ({
  maxWidth: '150px'
}));
