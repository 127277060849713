import { DetailsType } from "./form.type";

export type ArkLedger = {
  id?: string;
  name: string;
  fundId?: string;
  platformLedgerId?: string;
  currency: string;
  description: string;
  state?: string;
  status?: string;
  glPlatform?: string;
  ledgerId?: string;
  linkedBy?: string;
  linkedOn?: string;
  generalLedgerId?: string;
};

export type SelectedLedger = {
  ledger?: ArkLedger;
  type?: DetailsType;
  isNewQBLedgerAllowed?: boolean;
};

export enum LedgerType {
  QUICKBOOKS_ONLINE = "QUICKBOOKS_ONLINE",
  ARK_LEDGER = "ARK_LEDGER",
}

export enum LedgerName {
  QUICKBOOKS_ONLINE = "QuickBooks Online",
}
