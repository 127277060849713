import { useState } from "react";

export const usePageNavigation = () => {
  const [lockedPrompt, setLockedPrompt] = useState<boolean>(false);

  const showLockedDialog = () => {
    setLockedPrompt(true);
  };

  return { lockedPrompt, setLockedPrompt, showLockedDialog };
};
