import invert from "invert-color";

import palette from "../../styles/theme/palette";

export const getThemeColors = (color?: string | null, shouldInvert?: boolean) => {
  if(!color) {
    return "";
  }
  if(shouldInvert) {
    return invert(`#${color}`, {
      black: palette.text.primary,
      white: palette.common.white,
    });
  }
  return `#${color}`;
};

