import * as yup from "yup";

import { ContactDetail, ContactInfo } from "../types/contactDetail.type";
import { CustomProperty } from "../types/customProperty.type";
import { FundDetailInfo, FundDetails } from "../types/fund.type";
import {
  BeneficiaryBankInfo,
  InvestorDetail,
  MailingAddress,
  ReceivingBankInfo,
  WireInfo,
} from "../types/investor.type";
import { SelectionOptionItem } from "../types/listItems";
import { INVESTOR_TYPE_INDIVIDUAL } from "./text.constants";

export const prefixOptions = [
  { id: "", label: "" },
  { id: "Mr.", label: "Mr." },
  { id: "Mrs.", label: "Mrs." },
  { id: "Ms.", label: "Ms." },
];

export const investorEntityTypes: SelectionOptionItem[] = [
  {
    id: "rb_investor_individual",
    label: "Individual",
    value: "INDIVIDUAL",
  },
  {
    id: "rb_investor_entity",
    label: "Entity",
    value: "ENTITY",
  },
];

export const defaultMailingAddress: MailingAddress = {
  id: "",
  street1: "",
  street2: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
};

export const defaultTaxAddress: MailingAddress = {
  id: "",
  street1: "",
  street2: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
};

export const defaultBankInfo: ReceivingBankInfo = {
  graphId: 0,
  id: "",
  abaNumber: "",
  name: "",
  type: "",
  addressLine1: "",
  addressLine2: "",
};

export const defaultWireInfo: WireInfo = {
  id: "",
  receivingBankInfo: defaultBankInfo,
  beneficiaryAccount: "",
  beneficiaryName: "",
  beneficiaryAddressLine1: "",
  beneficiaryAddressLine2: "",
  changesPending: false,
  beneficiaryBankInfo: defaultBankInfo,
  intermediaryBankInfo: defaultBankInfo,
  originatorToBeneficiaryInstructions1: "",
  originatorToBeneficiaryInstructions2: "",
  originatorToBeneficiaryInstructions3: "",
  originatorToBeneficiaryInstructions4: "",
  bankToBankInstructions1: "",
  bankToBankInstructions2: "",
  bankToBankInstructions3: "",
  bankToBankInstructions4: "",
  foreign: false,
};

export const defaultFundDetailInfo: FundDetailInfo = {
  id: "",
  name: "",
  shortName: "",
  clientId: "",
  fundFamily: "",
  type: "",
  entityType: "",
  legalType: "",
  reportingPeriod: "",
  taxIdentificationNumber: "",
  active: false,
  inceptionDate: null,
  currency: "",
  fee: "",
  phone: "",
  fax: "",
  mailingAddress: defaultMailingAddress,
};

export const defaultFundDetails: FundDetails = {
  fundDetail: defaultFundDetailInfo,
  teams: [],
  investors: [],
  wireInfo: defaultWireInfo,
};

export const defaultReceivingBankInfo: ReceivingBankInfo = {
  graphId: 0,
  id: "",
  abaNumber: "",
  name: "",
  type: "",
  addressLine1: "",
  addressLine2: "",
};

export const defaultBeneficiaryBankInfo: BeneficiaryBankInfo = {
  graphId: 0,
  id: "",
  abaNumber: "",
  name: "",
  type: "",
  addressLine1: "",
  addressLine2: "",
};

export const fundDetailSchema = {
  name: yup.string().required().label("Name"),
  shortName: yup.string().optional().label("Short Name"),
  fundFamily: yup.string().optional().label("Fund Family"),
  type: yup.string().optional().label("Type"),
  entityType: yup.string().optional().label("Entity Type"),
  legalType: yup.string().optional().label("Legal Type"),
  reportingPeriod: yup.string().optional().label("Reporting Period"),
  taxIdentificationNumber: yup.string().optional().label("TIN"),
  phone: yup.string().optional().label("Phone"),
  fax: yup.string().optional().label("Fax"),
  active: yup.bool().optional().label("Active"),
  inceptionDate: yup.date().required().label("Inception Date"),
  currency: yup.string().required().label("Currency"),
  fee: yup.string().optional().label("Fee"),
};

export const investorSchema = {
  name: yup.string().optional().label("Name"),
  firstName: yup.string().optional().label("First Name"),
  lastName: yup.string().optional().label("Last Name"),
  middle: yup.string().optional().label("M.I"),
  pre: yup.string().optional().label("Prefix"),
  phone: yup.string().optional().label("Phone"),
  taxIdentificationNumber: yup.string().optional().label("TIN"),
  investorType: yup.object().required().label("Investor Type"),
  legalType: yup.object().optional().label("Legal Type"),
  entityType: yup.string().optional().label("Entity Type"),
  disregardedEntity: yup.bool().label("Disregarded Entity"),
  ownerName: yup.string().required().label("Owner Name"),
  ownerSSN: yup.string().required().label("Owner SSN"),
};

export const addressSchema = {
  street1: yup.string().label("Address line 1"),
  street2: yup.string().label("Address line 2"),
  city: yup.string().label("City"),
  postalCode: yup.number().label("Postal code"),
  state: yup.string().label("State"),
  country: yup.string().label("Country"),
};

export const bankInfoSchema = {
  graphId: yup.number(),
  id: yup.string(),
  abaNumber: yup.string(),
  name: yup.string().optional(),
  type: yup.string().optional(),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
};

export const wireInfoSchema = {
  id: yup.string(),
  receivingBankInfo: yup.object(),
  beneficiaryAccount: yup.string(),
  beneficiaryName: yup.string(),
  beneficiaryAddressLine1: yup.string(),
  beneficiaryAddressLine2: yup.string(),
  changesPending: false,
  beneficiaryBankInfo: yup.object(),
  intermediaryBankInfo: yup.object(),
  originatorToBeneficiaryInstructions1: yup.string(),
  originatorToBeneficiaryInstructions2: yup.string(),
  originatorToBeneficiaryInstructions3: yup.string(),
  originatorToBeneficiaryInstructions4: yup.string(),
  bankToBankInstructions1: yup.string(),
  bankToBankInstructions2: yup.string(),
  bankToBankInstructions3: yup.string(),
  bankToBankInstructions4: yup.string(),
  foreign: false,
};

export const contactDetailSchema = {
  tenantId: yup.string().optional().label("Tenant Id"),
  contactType: yup.string().optional().label("Contact Type"),
  prefix: yup.string().optional().label("Prefix"),
  firstName: yup.string().required().label("First Name"),
  middleName: yup.string().optional().label("Middle Name"),
  lastName: yup.string().required().label("Last Name"),
  name: yup.string().required().label("Name"),
  indexedName: yup.string().optional().label("Indexed Name"),
  email: yup.string().email().required().label("Email"),
  countryCode: yup.string().optional().label("Country Code"),
  countryAbbreviation: yup.string().optional().label("Country Abbreviation"),
  phoneNumber: yup.string().optional().label("Phone Number"),
  pendingCountryCode: yup.string().optional().label("Pending Country Code"),
  pendingCountryAbbreviation: yup.string().optional().label("Pending Country Abbreviation"),
  pendingPhoneNumber: yup.string().optional().label("Pending Phone Number"),
  faxNumber: yup.string().optional().label("Fax Number"),
  organization: yup.string().optional().label("Organization"),
  status: yup.string().optional().label("Status"),
  lastApprovedDate: yup.string().optional().label("Last Approved date"),
  address: yup.object().optional().label("Address"),
  fullName: yup.string().optional().label("Full Name"),
  rawName: yup.string().optional().label("Raw Name"),
  fullPendingPhoneNumber: yup.string().optional().label("Full Pending Phone Number"),
  fullPhoneNumber: yup.string().optional().label("Full Phone Number"),
};

export const defaultContactInfo: ContactInfo = {
  id: "",
  tenantId: "",
  contactType: INVESTOR_TYPE_INDIVIDUAL,
  prefix: "",
  firstName: "",
  middleName: "",
  lastName: "",
  name: "",
  indexedName: "",
  email: "",
  countryCode: "1",
  countryAbbreviation: "us",
  phoneNumber: "",
  pendingCountryCode: "",
  pendingCountryAbbreviation: "",
  pendingPhoneNumber: "",
  faxNumber: "",
  organization: "",
  status: "",
  lastApprovedDate: "",
  address: defaultMailingAddress,
  fullName: "",
  rawName: "",
  fullPendingPhoneNumber: "",
  fullPhoneNumber: "",
};

export const defaultContactResource: ContactDetail = {
  details: defaultContactInfo,
  investorFundRoles: [],
};

export const CUSTOM_PROPERTY_FORM_DEFAULT_VALUE: CustomProperty = {
  id: '',
  name: '',
  tenantId: '',
  description: '',
  type: null,
  formType: null,
  values: ['', ''],
  formattingType: null,
  formattingValue: null
};

export const accountStatusTypes: SelectionOptionItem[] = [
  {
    id: "rb_status_draft",
    label: "Draft",
    value: "DRAFT",
  },
  {
    id: "rb_status_posted",
    label: "Posted",
    value: "POSTED",
  },
];

export const yesNoOptions = [
  {
    id: "false",
    label: "No",
    value: false
  },
  {
    id: "true",
    label: "Yes",
    value: true
  },
];
