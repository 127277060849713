import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../core/context/appContextProvider";
import { GET_GL_ACCOUNT_LIST_ERROR } from "../../../pages/arkGL/accounts/accountList/AccountList.constants";
import { getArkAccounts } from "../../../services/arkGL.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const useFSMappingFilterEffect = (fundId: string) => {
  const { informationAlert } = useContext(AppContext);

  const doNotMapAccount = {
    id: "1",
    name: "Do Not Map",
  };

  const [fsMappingList, setFSMappingList] = useState<any[]>([doNotMapAccount]);
  const [loading, setLoading] = useState(false);

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    setLoading(true);
    try {
      if (isCanceled?.()) return;

      const response = await getArkAccounts(fundId);

      const accounts = response.items
        .map((account: any) => {
          return {
            id: account.id,
            name: `${account.number} - ${account.name}`,
          };
        })
        .sort();

      accounts.unshift(doNotMapAccount);

      setFSMappingList(accounts);
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, "error");
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccountList(isCanceled);
  }, []);

  return {
    fsMappingList,
    loading,
  };
};

export default useFSMappingFilterEffect;
