import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import { EMAIL_REGEX, ENTER_KEY_CODE } from "../../../../utils/constants/constants";
import { ChipStyle, MultipleCcEmail } from "./ClientSettingForm.style";

type Props = {
  value?: string;
  onChange: (value: string) => void;
}

const CCEmail = ({
  value = "",
  onChange
}: Props) => {

  const [ccEmail, setCcEmail] = useState<string>("");
  const [ccEmailError, setCcEmailError] = useState<string>("");

  const ccEmailList = useMemo(() => {
    if (value) {
      return value.split(',');
    }
    return [];
  }, [value]);

  const keyHandler = (e: any) => {
    if (e.charCode === ENTER_KEY_CODE) {
      e?.preventDefault();
      e?.stopPropagation();
      ccEmailHandler();
    }
  };

  const emailValidation = () => {
    if (!EMAIL_REGEX.test(ccEmail)) {
      setCcEmailError('Please enter valid email address');
      return false;
    } else {
      setCcEmailError('');
      return true;
    }
  };

  const ccEmailHandler = () => {
    if (emailValidation()) {
      if (ccEmailList?.length === 0) {
        onChange(ccEmail);
      } else {
        onChange(`${value},${ccEmail}`);
      }
      setCcEmail("");
    }
  };

  const handleDelete = (val: string) => {
    const updatedList = ccEmailList.filter(item => item !== val);

    if (updatedList.length === 0) {
      onChange("");
    } else {
      onChange(updatedList.join(','));
    }
  };

  return (
    <Box>
      <FormControl error={Boolean(ccEmailError)} fullWidth>
        <Box sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
          gridGap: 16,
        }}>
          <TextField
            id="cc_email_box"
            fullWidth
            label="CC Email"
            variant="outlined"
            size="small"
            value={ccEmail}
            onChange={(e) => setCcEmail(e.target.value)}
            onKeyPress={keyHandler}
          />
          <Button
            variant="contained"
            size="medium"
            onClick={ccEmailHandler}>
			Add Email
          </Button>
        </Box>
        {ccEmailError &&
        (<FormHelperText>
          {ccEmailError}
        </FormHelperText>)}
        <MultipleCcEmail>
          {ccEmailList?.map((email, index) =>
            <ChipStyle key={`cc_email_${index}`}>
              <Chip
                label={email}
                id={`cc_email_${index}`}
                onDelete={() => handleDelete(email)}
              />
            </ChipStyle>)}
        </MultipleCcEmail>
      </FormControl>
    </Box>
  );
};

export default CCEmail;
