import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { ReactElement } from 'react';

import Container from '../../../components/Container/Container';
import DataGrid from '../../../components/DataGrid/DataGrid';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import { FormProps } from '../../../utils/types/form.type';
import {
  Fund,
  FundInvestorType,
  InvestorDetail,
} from '../../../utils/types/investor.type';
import {
  useFundListEffect,
  useUpdateFundListEffect,
} from './InvestorFunds.hooks';
import {
  FundsSelectedTypography,
  SearchBarInput,
  SearchInputAdornment,
  StyledParentCard,
} from './InvestorFunds.style';

type Props = {
  form: FormProps;
};

const InvestorFunds: React.FC<Props> = ({ form }: Props): ReactElement => {
  const { fundList } = useFundListEffect(false, form);

  const { watch } = form;
  const investorFundList: Fund[] = watch('funds');
  const fundInvestorTypes: FundInvestorType[] = watch('fundInvestorTypes');

  const {
    gridHeaderList,
    getGridFundList,
    selectionModel,
    investorTypeList,
    handleEnabledInvestorTypId,
    searchVal,
    handleOnSearchChange,
    onFundCheck,
  } = useUpdateFundListEffect(fundList, form, false);

  const gridFundList = getGridFundList();
  const missingInvestorType = fundInvestorTypes.find(
    (t) =>
      !t.investorType.label && t.investorType && t.investorType.label === ''
  );

  return (
    <Container id="investor_funds_container" role="main">
      <Typography variant="h4">Funds</Typography>
      <Typography variant="errorText" hidden={investorFundList.length > 0}>
        At least one fund is required
      </Typography>
      <Typography variant="errorText" hidden={!missingInvestorType}>
        Investor Types must be set for selected funds
      </Typography>
      <StyledParentCard variant="outlined">
        <Grid container spacing={2} mb={2}>
          <Grid xs={6} item>
            <SearchBarInput
              id="inputSearch"
              size="small"
              placeholder="Search"
              onChange={handleOnSearchChange}
              value={searchVal}
              InputProps={{
                startAdornment: (
                  <SearchInputAdornment position="start"></SearchInputAdornment>
                ),
                readOnly: false,
              }}
            />
          </Grid>

          <Grid xs={6} item>
            <FormControl sx={{ marginTop: '10px' }}>
              <SingleSelect
                id="enabled_investor_types"
                width="300px"
                fullWidth
                noGutter
                optionList={investorTypeList}
                label="Bulk Select Investor Types"
                value={''}
                idField="id"
                labelField="label"
                handleOptionSelection={handleEnabledInvestorTypId}
              />
            </FormControl>
          </Grid>
        </Grid>
        <FundsSelectedTypography>{`${selectionModel.length} Fund(s) selected`}</FundsSelectedTypography>
        <DataGrid
          id="data_grid"
          headerList={gridHeaderList}
          dataList={gridFundList}
          checkboxSelection={true}
          onSelectionModelChange={onFundCheck}
          selectionModel={selectionModel}
          autoHeight={false}
          minHeight="270px"
          keepNonExistentRowsSelected={true}
        />
      </StyledParentCard>
    </Container>
  );
};

export default InvestorFunds;
