import { DownloadFileParam } from "../utils/types/download.type";
import { Transaction, TransactionParams } from "../utils/types/transaction.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const getTransactions = async (params: TransactionParams) => await apiClient().post("transactions/page", params);

export const getTransactionsCount = async (params: TransactionParams): Promise<string> => await apiClient().post("transactions/total", params);

export const getTransactionIDs = async (params: TransactionParams) => await apiClient().post("transactions/gettransactionids", params);

export const deleteTransaction = async (transactionID: string) => await apiClient().delete(`transactions/${transactionID}`, {});

export const deleteTransactions = async (params: string[]) => await apiClient().post("transactions/delete", params);

export const deleteAllTransactionsService = async (params: TransactionParams) => await apiClient().post("transactions/deleteall", params);

export const downloadData = async (params: DownloadFileParam) => await apiClient().post('downloads/', params);

export const addTransaction = async (params: Transaction, clientID: string) => await apiClient().post(`transactions?clientId=${clientID}`, params);

export const updateTransaction = async (params: Transaction, transactionID: string, clientID: string) => await apiClient().put(`transactions/${transactionID}?clientId=${clientID}`, params);
