import { Edit,OpenInNewSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from 'react';
import { Link } from "react-router-dom";

import EyeIcon from "../../assets/images/icons/icon_eye.svg";
import LinkIcon from "../../assets/images/icons/icon_link.svg";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { underscoreToCapitalize } from "../../utils/helpers/capitalize";
import { DashboardType } from "../../utils/types/dashboard.type";
import {
  AddressBar,
  AddressBarLink,
  AddressBarWrapper,
  CopyLinkButton,
  DashboardSubtitle,
  DashboardTitle,
  PendingItemsHeaderCell,
  PendingItemsIcon,
  PendingItemsPaper,
  PendingItemsRow,
  PendingItemsScrollableSection,
  PendingItemsTitle,
  StyledDivider,
  ViewChangesButton,
  ViewPortalButton,
} from "./Dashboard.styles";
import { useDashboard } from "./useDashboard";

const Dashboard: React.FC = () => {

  const {
    copy,
    pendingItems,
    portalLink,
    loading,
    sideNavs,
    state,
    handleRedirection,
    redirectToClientConfig,
    isSuperOrClientAdmin
  } = useDashboard();

  if (!sideNavs || sideNavs?.length < 1) {
    return (
      <Typography variant="body1" align="center">Loading...</Typography>
    );
  }

  return (
    <div>
      <Progress
        id="dashboard_loader"
        showProgress={loading}
      />
      <DashboardTitle>Dashboard</DashboardTitle>
      <DashboardSubtitle>
        Welcome {state?.loginUser?.email}!
      </DashboardSubtitle>
      {state?.loginUser?.clientId && <>
        <Box>
          <AddressBarWrapper sx={{
            gridTemplateColumns: isSuperOrClientAdmin ? "1fr 1fr 4fr" : "1fr 4fr"
          }}>
            {isSuperOrClientAdmin && (<ViewPortalButton
              id="configure_client"
              variant="contained"
              startIcon={<Edit />}
              onClick={redirectToClientConfig}
              color="primary"
            >
              {`Edit ${state?.loginUser?.clientName ?? "Client"}`}
            </ViewPortalButton>)}
            <ViewPortalButton
              id="view_portal"
              variant="contained"
              component={Link}
              endIcon={<OpenInNewSharp />}
              color="primary"
              to={{
                pathname: portalLink || "/"
              }}
              target="_blank"
            >
              View Investor Portal
            </ViewPortalButton>
            <AddressBar>
              <AddressBarLink variant="body1">
                {portalLink}
              </AddressBarLink>
              <CopyLinkButton
                id="copy_link"
                endIcon={<img src={LinkIcon} alt="link" />}
                color="primary"
                onClick={() => copy(portalLink)}
              >
                Copy URL
              </CopyLinkButton>
            </AddressBar>
          </AddressBarWrapper>
          {pendingItems?.length > 0 && (
            <PendingItemsPaper elevation={6}>
              <PendingItemsTitle>
                <PendingItemsIcon />
                Pending Changes
              </PendingItemsTitle>
              <PendingItemsRow>
                <PendingItemsHeaderCell>Type</PendingItemsHeaderCell>
                <Box />
                <PendingItemsHeaderCell>Name</PendingItemsHeaderCell>
                <Box />
                <PendingItemsHeaderCell>Link</PendingItemsHeaderCell>
              </PendingItemsRow>
              <StyledDivider />
              <PendingItemsScrollableSection>
                {pendingItems.map((pendingItem, index) => (
                  <PendingItemsRow key={pendingItem.id}>
                    <Typography>
                      {underscoreToCapitalize(pendingItem?.type || "")}
                    </Typography>
                    <Box />
                    <Typography>{pendingItem?.name}</Typography>
                    <Box />
                    <ViewChangesButton
                      id={`view_changes_${index}`}
                      startIcon={<img src={EyeIcon} alt="link" />}
                      color="primary"
                      onClick={() => handleRedirection(pendingItem?.type as DashboardType, pendingItem?.id || "")}
                    >
                      View
                    </ViewChangesButton>
                  </PendingItemsRow>
                ))}
              </PendingItemsScrollableSection>
            </PendingItemsPaper>
          )}
        </Box>
      </>}
      {!state?.loginUser?.clientId && <Typography variant="body1" align="center">Select a client to get started.</Typography>}
    </div>
  );
};

export default React.memo(Dashboard);
