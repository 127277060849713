import { Box, Container, Grid, Modal, styled } from "@mui/material";

export const MainStyledContainer = styled(Container)`

`;

export const DetailsGridStyle = styled(Grid)`
    padding-top: 1px; 
    padding-left: 1px;
`;




export const StyledModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
`;