import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Box,
  ButtonBase,
  Collapse,
  Container,
  Fade,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import {
  FormContainer,
  StyledCancelButton,
  StyledSaveButton,
} from '../BankConnectionPanel.styles';
import { useBankInstitutionSelector } from './BankInstitutionSelector.hooks';
import {
  DatePickerBox,
  FoundBankBox,
  FoundBankButtonBase,
  InstitutionSearchBox,
  PopularBankBox,
  PopularBankDefaultIcon,
  PopularBankLogoBox,
  PopularBankNameTypography,
  PopularBanksTypography,
  SearchBarInput,
  SearchInstitutionTypography,
} from './BankInstitutionSelector.styles';

interface Props extends RouteProps {
  setIsLoading: (value: string) => void;
  onOk: (institutionId: string, importStartDate: Date) => void;
  onCancel: () => void;
}

export const BankInstitutionSelector: React.FC<Props> = ({
  setIsLoading,
  onOk,
  onCancel,
}: Props) => {
  const {
    popularBankInstitutioins,
    searchText,
    handleOnSearchChange,
    filteredBankInstitutions,
    selectedDate,
    setSelectedDate,
    handleSelectionModel,
    selectedBankInstitution,
    handleSave,
    minDate,
    isValidImportDate,
    isValidForm,
  } = useBankInstitutionSelector({ setIsLoading, onOk });

  return (
    <>
      <FormContainer>
        <DatePickerBox>
          <Typography variant="body1">Import start date</Typography>
          <Tooltip
            id={'start_date_tooltip'}
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            enterDelay={300}
            title={
              <Box padding={'8px'}>
                <Typography>
                  <strong> Date Range section: </strong>
                  Ark will pull transactions from selected accounts from the
                  date selected through today. It is recommended that only
                  transactions without existing journal entries are pulled into
                  Ark. Some bank limitations may apply.
                </Typography>
              </Box>
            }
          >
            <InfoOutlinedIcon color="disabled" fontSize="small" />
          </Tooltip>
          <DatePicker
            label="Date"
            minDate={minDate}
            renderInput={(params) => (
              <TextField
                required
                placeholder="Date"
                {...params}
                size="small"
                id="filter_date"
                helperText={isValidImportDate}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'off',
                }}
              />
            )}
            onChange={setSelectedDate}
            value={selectedDate}
          />
        </DatePickerBox>
        <InstitutionSearchBox>
          <SearchInstitutionTypography>
            Search for your financial institution to connect your accounts
          </SearchInstitutionTypography>
          <SearchBarInput
            id="inputSearch"
            size="small"
            placeholder="Search from thousands of supported institutions"
            onChange={(evt) => {
              handleOnSearchChange(evt.target.value);
            }}
            value={searchText}
            InputProps={{
              autoComplete: 'off',
              startAdornment: <SearchRoundedIcon sx={{ color: '#2E7DF7' }} />,
              readOnly: false,
            }}
          />
        </InstitutionSearchBox>
        <PopularBanksTypography>
          {!searchText?.length
            ? 'Or choose from this list of popular financial institutions:'
            : 'Financial institutions matching your search:'}
        </PopularBanksTypography>
        <>
          <Box overflow={'auto'} minHeight={'100px'}>
            <Collapse in={!searchText?.length}>
              <Container>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  overflow={'auto'}
                >
                  {popularBankInstitutioins?.map((item) => (
                    <Grid item xs={12} sm={6} key={`popular_Bank_${item.id}`}>
                      <ButtonBase
                        disableRipple
                        onClick={() => {
                          handleSelectionModel(item.id);
                        }}
                        sx={{ width: '100%', display: 'block' }}
                      >
                        <PopularBankBox
                          isSelected={item.id === selectedBankInstitution}
                        >
                          <PopularBankLogoBox>
                            {item.bankLogo ? (
                              <img
                                src={`data:image/png;base64,${item.bankLogo}`}
                                alt=""
                                width={44}
                                height={44}
                              />
                            ) : (
                              <PopularBankDefaultIcon />
                            )}
                          </PopularBankLogoBox>
                          <PopularBankNameTypography variant="body1">
                            {item.bankName}
                          </PopularBankNameTypography>
                        </PopularBankBox>
                      </ButtonBase>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Collapse>
            <Collapse in={!!searchText?.length}>
              <Container>
                {filteredBankInstitutions?.map((bi) => {
                  return (
                    <FoundBankButtonBase
                      key={bi.id}
                      disableRipple
                      onClick={() => {
                        handleSelectionModel(bi.id);
                      }}
                    >
                      <Grid container alignItems="center">
                        <FoundBankBox
                          isSelected={bi.id === selectedBankInstitution}
                        >
                          <PopularBankLogoBox>
                            {bi.bankLogo ? (
                              <img
                                src={`data:image/png;base64,${bi.bankLogo}`}
                                alt=""
                                width={32}
                                height={32}
                              />
                            ) : (
                              <PopularBankDefaultIcon />
                            )}
                          </PopularBankLogoBox>
                          <PopularBankNameTypography variant="body1">
                            {bi.bankName}
                          </PopularBankNameTypography>
                        </FoundBankBox>
                      </Grid>
                    </FoundBankButtonBase>
                  );
                })}
              </Container>
            </Collapse>
          </Box>
        </>
      </FormContainer>
      <Box position={'relative'} height={'80px'}>
        <HorizontalBox addTopShadow attachToBottom={true} fullWidth>
          <StyledCancelButton
            id="cancel_button"
            variant="outlined"
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </StyledCancelButton>

          <StyledSaveButton
            id={'save_button'}
            variant="contained"
            type={'submit'}
            onClick={handleSave}
            disabled={!isValidForm}
            fullWidth
          >
            Continue
          </StyledSaveButton>
        </HorizontalBox>
      </Box>
    </>
  );
};
