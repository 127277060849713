import { useEffect, useState } from 'react';

function getWindow() {
  const { innerWidth: width, innerHeight: height } = window;
  
  return {
    width,
    height
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindow());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindow());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}