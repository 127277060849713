import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Client } from "../../../utils/types/client.type";
import { getClientDetails } from "../../client.service.v2";

export const useClientEffect = (id?: string) => {
  const [client, setClient] = useState<Client | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchClient = useCallback( async (isCanceled?: () => boolean) => {
    if(!id) return;
    try {
      setLoading(true);
      const response = await getClientDetails(id!);

      if (isCanceled?.()) return;

      setClient(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting client", "error");
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async (isCanceled) => {
    await fetchClient(isCanceled);
  }, [fetchClient]);

  return {
    client,
    fetchClient,
    loading,
    setClient
  };
};
