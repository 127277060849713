import { cloneDeep } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import RoutingPaths from "../../../../core/routing/routingPaths";
import { getBaseUrl, getWorkbookById, getWorkbookLock, lockWorkbook, unlockWorkbook } from "../../../../services/workbook.service";
import { InformationMessage } from "../../../../utils/constants/app.types";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import { ScopeRole } from "../../../../utils/types/user.type";
import { AdditionalWorkbookProperties, Workbook, WorkbookLockStatus } from "../workbook.type";
import { WorkbookSheetsManager } from "./sheets/WorkbookSheets.Manager";
import { SpreadsheetGrid } from "./SpreadsheetGrid";

const defaultTemplateUrl = '/Layouts/allocations_template.xlsx';
const exportFilename = "Allocation.xlsx";

const defaultSavedInfonMsg:InformationMessage = {
  open: false,
  severity: 'success',
  text: 'Workbook saved successfuly'
};

export const useWorkbook = () => {
  const [spreadsheetUrl, setSpreadsheetUrl] = useState('');
  const [treeGridLibraryLoaded, setTreeGridLibraryLoaded] = useState(false);
  const [showModelSaveAs, setShowModelSaveAs] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string|undefined>(undefined);
  const [savedInfonMsg, setSavedInfonMsg] = useState(defaultSavedInfonMsg);
  const [showReadonlyMessageDialog, setShowReadonlyMessageDialog] = useState(false);
  const [workbookLockStatus, setWorkbookLockStatus] = useState<WorkbookLockStatus>({ locked: false });
  const [workbookHasChanges, setWorkbookHasChanges] = useState(false);
  const [loadWBEffect, setLoadWBEffect] = useState(0);

  const { state, setTimeoutWarningCallback, setTimeoutCallback } = useContext(AppContext);
  const username = state.loginUser.currentUser?.username || "";
  const isSuperAdmin = state.loginUser.currentUser?.scopeRole === "SUPER_ADMIN";
  const isArkClientAdmin = state.loginUser.currentUser?.scopeRole === ScopeRole.ARK_CLIENT_ADMIN;
  const blockUnlockPrivileges = isSuperAdmin || isArkClientAdmin ? false : true;

  const { workbookId, fundId } = useParams<{ fundId: string, workbookId: string }>();
  const location = useLocation<Workbook>();

  const workbookSheetsManagerRef = useRef<WorkbookSheetsManager>();
  const workbookSheetManager = workbookSheetsManagerRef.current!;

  const history = useHistory();

  useEffect(() => {
    const elementId = 'TreeGridComponet';
    const existingScript = document.getElementById(elementId);

    if (existingScript) {
      setTreeGridLibraryLoaded(true);
    } else {
      const script = document.createElement('script');

      script.src = '/Grid/GridED.js';
      script.id = elementId;
      document.body.appendChild(script);
      script.onload = () => { 
        setTreeGridLibraryLoaded(true);
      };
    }
  }, []);

  useEffectAsync(async (isCancelled) => {
    const workbook = await getInitialWorkbook();

    if(isCancelled()) return;

    if(!workbook.id) {
      setLoadWBEffect(loadWBEffect+1);
    } else {
      const workbookLockStatus = await ensureWorkbookLockState(workbook.id!);

      if(isCancelled()) return;

      if(isWorkbookReadonly(workbookLockStatus)) {
        setShowReadonlyMessageDialog(true);
      } else {
        setLoadWBEffect(loadWBEffect+1);
      }
    }
  }, []);
  
  useEffectAsync(async (isCancelled) => {
    if(!loadWBEffect) return;

    const workbook = await getInitialWorkbook();

    if(isCancelled()) return;

    let spreadsheetUrl: string;
  
    if(!workbook.id) {
      spreadsheetUrl = defaultTemplateUrl;
    } else {
      spreadsheetUrl = getExistingSpreadsheetFileUrl(workbook.id!);
    }

    setWorkbookSheetsManagerRef(workbook, !workbook.id);
    setSpreadsheetUrl(spreadsheetUrl);

  }, [loadWBEffect]);

  async function getInitialWorkbook() {
    let workbook: Workbook;
  
    if(location.state) {      
      workbook = location.state;
    } else {
      workbook = await getWorkbookById(workbookId!);
    }

    return workbook;
  }

  function setWorkbookSheetsManagerRef(workbook: Workbook, isNewWorkbook: boolean, additionalWorkbookProperties?: AdditionalWorkbookProperties) {
    const readonly = workbookLockStatus ? isWorkbookReadonly(workbookLockStatus) : false;

    workbookSheetsManagerRef.current = new WorkbookSheetsManager();
    workbookSheetsManagerRef.current.reloadWorkbook = reloadWorkbook;
    workbookSheetsManagerRef.current.handleWorkbookChangeMade = setWorkbookHasChanges;
    workbookSheetsManagerRef.current.setWorkbookData(workbook, isNewWorkbook, true, readonly, additionalWorkbookProperties);
  }

  async function reloadWorkbook(workbook: Workbook, loadAsNew: boolean, additionalWorkbookProperties?: AdditionalWorkbookProperties) {
    if(workbookId && workbookId !== 'new') {
      await unlockWorkbook(workbookId!);
    }

    if(loadAsNew) {
      history.replace(`${RoutingPaths.FundAllocationsFile}/${fundId}/new`, workbook);
    } else {
      history.replace(`${RoutingPaths.FundAllocationsFile}/${fundId}/${workbook.id}`);
      lockWorkbook(workbook.id!);
    }    

    setWorkbookSheetsManagerRef(workbook, loadAsNew, additionalWorkbookProperties);

    // clearing spreadsheetUrl and resetting it in setTimeout(), forces treegrid component to reload
    setSpreadsheetUrl('');

    setTimeout(() => {
      let url: string;

      if(loadAsNew) {
        url = defaultTemplateUrl;
      } else {
        url = getExistingSpreadsheetFileUrl(workbook.id!);
      }

      setSpreadsheetUrl(url);
    }, 100);
  }

  function getExistingSpreadsheetFileUrl(workbookId: string) {
    return `${getBaseUrl()}workbooks/${workbookId}/file`;
  }

  function handlePreloadSheet(sheetName: string) {
    workbookSheetManager.setPreloadSheet(sheetName);
  }

  function handleSheetLoaded(spreadsheetGrid: SpreadsheetGrid) {
    workbookSheetManager.setLoadedSheet(spreadsheetGrid);
  }

  function handleSaveClicked() {
    setWorkbookHasChanges(false);
    workbookSheetManager.beginWorkbookSaving();
    setLoadingMessage('Saving workbook');
  }

  function handleSave(fileBlob: Blob): void {
    workbookSheetManager.saveWorkbookToApi(fileBlob);

    const tempInfoMsg = cloneDeep(savedInfonMsg);

    tempInfoMsg.open = true;

    setSavedInfonMsg(tempInfoMsg);
    setLoadingMessage(undefined);
  }

  function handleSaveAsClicked() {
    workbookSheetManager.cancelCurrentMode();
    setShowModelSaveAs(true);
  }

  function handleSaveAsPromptClose(saveClicked: boolean, reportName?: string | undefined) {
    if(saveClicked) {
      workbookSheetManager.beginWorkbookSavingAs(reportName!);
      setLoadingMessage(`Saving as ${reportName}`);
    }

    setShowModelSaveAs(false);
  }

  function handleSaveAs(fileBlob: Blob): void {
    workbookSheetManager.saveWorkbookToApi(fileBlob);
    setLoadingMessage(undefined);
  }

  function handleToolbarButtonClick(buttonName: string) {
    workbookSheetManager.activeSheetManager.onToolbarButtonClick(buttonName);
  }

  function handleStartEdit(row: string, col: string) {
      return workbookSheetManager.activeSheetManager.onStartEdit(row, col);
  };

  function handleEndEdit(row: string, col: string, save: boolean, value: string) {
      return workbookSheetManager.activeSheetManager.onEndEdit(row, col, save, value);
  }

  function handleColsAdd(cols: string[], toCol: string, right: boolean, empty: boolean) {
      return workbookSheetManager.activeSheetManager.onColsAdd(cols, toCol, right, empty);
  }

  function handleRowAdd(parentRow: string){
      return workbookSheetManager.activeSheetManager.onRowAdd(parentRow);
  }

  function handleCanColDelete(col: string) {
      return workbookSheetManager.activeSheetManager.onCanColDelete(col);
  }

  function handleCanRowDelete(row: string){
      return workbookSheetManager.activeSheetManager.onCanRowDelete(row);
  }

  function handleCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number){
      return workbookSheetManager.activeSheetManager.onCanPaste(startCol, startRow, numOfCols, numOfRows);
  } 

  function handleCloseSavedInfo() {
    const tempInfoMsg = cloneDeep(savedInfonMsg);

    tempInfoMsg.open = false;

    setSavedInfonMsg(tempInfoMsg);
  }

  async function ensureWorkbookLockState(wbId: string) {
    const lockStatusResponse = await getWorkbookLock(wbId);
    
    if(!isWorkbookReadonly(lockStatusResponse)) {
      await lockWorkbook(wbId);
    }

    setWorkbookLockStatus(lockStatusResponse);

    return lockStatusResponse;
  }

  function isWorkbookReadonly(workbookLockStatus: WorkbookLockStatus) {
    if(!workbookLockStatus.locked) {
      return false;
    }

    return workbookLockStatus.lockedBy !== username;
  }

  function handleSetWorkbookreadonly() {
    setLoadWBEffect(loadWBEffect+1);
    setShowReadonlyMessageDialog(false);
  }

  async function forceSuperAdminUnLock() {
    if(isSuperAdmin || isArkClientAdmin) {
      await lockWorkbook(workbookId!); // force current admin use to be user locking WB
      await ensureWorkbookLockState(workbookId);
      
      setLoadWBEffect(loadWBEffect+1);
      setShowReadonlyMessageDialog(false); 
    }
  }

  async function handleNavigateAway() {
    if(!workbookHasChanges && workbookId !== 'new') {
      await unlockWorkbook(workbookId!);
    }
  }

  return {
    activeSheetManager :workbookSheetManager?.activeSheetManager,
    workbookHasChanges,
    fundId,
    spreadsheetUrl,
    treeGridLibraryLoaded,
    exportFilename,
    showModelSaveAs,
    showReadonlyMessageDialog,setShowReadonlyMessageDialog,
    workbookLockStatus,
    isSuperAdmin,
    isArkClientAdmin,
    blockUnlockPrivileges,
    loadingMessage,
    savedInfonMsg, handleCloseSavedInfo,
    handleNavigateAway,
    handlePreloadSheet,
    handleSheetLoaded,
    handleSaveClicked,
    handleSaveAsClicked,
    handleSave,
    handleSaveAs,
    handleSaveAsPromptClose,
    handleToolbarButtonClick,
    handleStartEdit,
    handleEndEdit,
    handleColsAdd,
    handleRowAdd,
    handleCanColDelete,
    handleCanRowDelete,
    handleCanPaste,
    handleSetWorkbookreadonly,
    forceSuperAdminUnLock
  };
};