import CloseIcon from '@mui/icons-material/Close';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Controller, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import IconDragColumn from "../../../../assets/images/icons/icon_drag_column.svg";
import { MultiSelectItem } from '../../../../components/MultiSelect/MultiSelect';
import { TemplateTitleBox } from '../../Clients.styles';
import { TextAlignment } from "../../constants";
import { ItemListData } from '../../constants';
import { GeneralReportForm, HeaderTypeForm } from '../useReportConfig.hooks';
import FundsMultiple from "./components/FundsMultiple";
import ListView from './components/ListView';
import { CloseIconBox, StyledTypography } from './components/ListView.style';
import {
  AlignCenterContainer,
  BorderContainer,
  GeneralSettingsContainer,
  HorizontalContainer,
  HorizontalLine,
  HorizontalText,
  HorizontalTextLayer,
  MainContainer,
  SectionContainer,
  SvgContainer
} from "./GeneralReportHeader.style";

type Props = {
    reportForm: UseFormReturn<GeneralReportForm, any>;
    control: UseFieldArrayReturn<GeneralReportForm, "headerElements">;
    fundNameList: MultiSelectItem[];
    addHeaderElement: (type: string) => void;
    removeHeaderElement: (id: number) => void;
    isDirty: boolean;
};

const GeneralReportHeaderLayout = ({
  reportForm,
  control,
  fundNameList,
  addHeaderElement,
  removeHeaderElement,
  isDirty,
}: Props): React.ReactElement => {
  const [selectIndex, setSelectedIndex] = useState<number> (-1);
  const headerElementList = control.fields;

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if(selectIndex !== -1){
      const currentElement = reportForm?.getValues().headerElements[selectIndex];

      reportForm?.setValue(`headerElements.${selectIndex}.alignment`, newAlignment, { shouldDirty: true });
      control?.update(selectIndex, {
        type: currentElement?.type,
        index: selectIndex,
        alignment: currentElement?.alignment
      });
    }
  };

  const checkHandler = (value:string) =>{
    if(headerElementList.map(res => res.type === value).includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setSelectedIndex(result.destination.index);
    control?.move(result.source.index, result.destination.index);
    // Re-arrange indexing of elements

    for(let i=0; i<headerElementList.length; i++){
      reportForm.setValue(`headerElements.${i}.index`, (i+1), { shouldDirty: true });
    }
    
  };

  const handleChange = (value: string, checked: boolean) => {
    if(checked) {
      addHeaderElement(value);
    } else {
      const index: number = headerElementList.findIndex((res: HeaderTypeForm) => res.type === value);

      if(index > -1) {
        removeHeaderElement(index);
      }
    }
  };

  const handleRemoveItem = (id: number) => {
    removeHeaderElement(id);
    setSelectedIndex(-1);
  };

  const handleListItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <SectionContainer>
      <GeneralSettingsContainer>
        <TemplateTitleBox variant="h4">
            General Report & Header Layout Settings
        </TemplateTitleBox>
      </GeneralSettingsContainer>
      <MainContainer>
        <BorderContainer>
          <Controller
            name={`headerElements`}
            render={({
              field: {
                value
              },
            }) => {
              return (
                <FormControl fullWidth size="small" >
                  <InputLabel>Add Item</InputLabel>
                  <Select label="Add Item"
                    multiple
                    value={value}
                    renderValue={() => `${value?.length || 0} Selected`}
                    labelId="add_item_general_report"
                    id="add_item_general_report"
                  >
                    {
                      ItemListData?.map((data, ind: number)=>{
                        return(
                          <MenuItem
                            key={`menu_list_${ind}`}
                            value={data.type}
                          >
                            <Checkbox
                              checked={checkHandler(data.type)}
                              onChange={(e) => handleChange(data.type, e.target.checked as boolean)}
                            />
                            {data.name}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              );
            }}
            control={reportForm?.control}
          />
          <AlignCenterContainer>
            <Typography variant="label2">
                            ALIGN ITEMS:
            </Typography>
            <SvgContainer>
              <ToggleButtonGroup
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment">
                <ToggleButton
                  size="small"
                  selected={reportForm?.getValues()?.headerElements[selectIndex]?.alignment === TextAlignment.left}
                  value={TextAlignment.left}
                  aria-label="left aligned"
                >
                  <FormatAlignLeftIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  selected={reportForm?.getValues()?.headerElements[selectIndex]?.alignment === TextAlignment.center}
                  value={TextAlignment.center}
                  aria-label="centered"
                >
                  <FormatAlignCenterIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  selected={reportForm?.getValues()?.headerElements[selectIndex]?.alignment === TextAlignment.right}
                  value={TextAlignment.right}
                  aria-label="right aligned"
                >
                  <FormatAlignRightIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </SvgContainer>
          </AlignCenterContainer>
          <Controller
            name={`funds`}
            render={({
              field: {
                onChange, value
              },
              fieldState: {
                error
              }
            }) => (
              <FundsMultiple
                size="small"
                id={`fund_${selectIndex}`}
                value={value}
                error={error}
                idSelectOnly
                onChange={onChange}
                fundsList={fundNameList}
                placeholder="Funds"
              />
            )}
            control={reportForm?.control}
          />
          <Controller
            name={`roundingStrategy`}
            render={({
              field: {
                onChange, value
              }
            }) => (
              <FormControl>
                <Typography variant="label2">ROUND VALUES TO NEAREST</Typography>
                <RadioGroup row value={value || ''} name="roundValuesToNearest" onChange={onChange}>
                  <FormControlLabel value="DOLLAR" control={<Radio/>} label="Dollar"/>
                  <FormControlLabel value="CENT" control={<Radio/>} label="Cent"/>
                </RadioGroup>
              </FormControl>
            )}
            control={reportForm?.control}
          />
          <Controller
            name={`useWatermark`}
            render={({
              field: {
                onChange, value
              }
            }) => {
              const currentValue = value ? 'YES' : 'NO';

              return (
                <FormControl>
                  <Typography variant="label2">USE WATERMARK?</Typography>
                  <RadioGroup
                    row
                    value={currentValue}
                    name="useWaterMark"
                    onChange={(e) => {
                      onChange(e.target.value === 'YES');
                    }}>
                    <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              );
            }}
            control={reportForm?.control}
          />
          <Controller
            name={`useInvestorPrefix`}
            render={({
              field: {
                onChange, value
              }
            }) => {
              const currentValue = value ? 'YES' : 'NO';

              return (
                <FormControl>
                  <Typography variant="label2">USE PREFIX?</Typography>
                  <RadioGroup
                    row
                    value={currentValue}
                    name="usePrefix"
                    onChange={(e) => {
                      onChange(e.target.value === 'YES');
                    }}
                  >
                    <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              );
            }}
            control={reportForm?.control}
          />
        </BorderContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                <List>
                  {headerElementList?.map((data: HeaderTypeForm, index: number) => (
                    <Draggable key={`key_${index}`} draggableId={`draggable_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <ListItemButton disableRipple key={`list_${index}`}
                          ref={provided.innerRef} selected={selectIndex === index}
                          onClick={(event)=>handleListItem(event,index) } {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListItemText>
                            {['HORIZONTAL_LINE'].includes(data.type) ?
                            <HorizontalContainer>
                              <CloseIconBox>
                                {selectIndex === index &&
                                  <img src={IconDragColumn} alt="IconDragColumn"/>
                                }
                              </CloseIconBox>
                              <HorizontalLine>
                                <HorizontalText>
                                  <HorizontalTextLayer>
                                    <StyledTypography variant="h5">
                                      Horizontal Line
                                    </StyledTypography>
                                  </HorizontalTextLayer>
                                </HorizontalText>
                              </HorizontalLine>
                              {selectIndex === index &&
                                <IconButton onClick={() => handleRemoveItem(index)}>
                                  <CloseIcon/>
                                </IconButton>
                              }
                              </HorizontalContainer>
                              :
                              <ListView
                                type={data.type}
                                id={data.index}
                                isDirty={isDirty}
                                index={index}
                                reportForm={reportForm}
                                cancelIcon={selectIndex && selectIndex}
                                handleRemove={handleRemoveItem}
                                alignmentOrder={data.alignment}
                              />
                            }
                          </ListItemText>
                        </ListItemButton>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </MainContainer>
    </SectionContainer>
  );
};

export default React.memo(GeneralReportHeaderLayout);
