import {
  DocControlPermissions,
  Documents,
  Folder,
  Permission,
} from '../../../utils/types/documents.type';

interface IDocumentsFilter {
  id: string;
  name: string;
  raw?: any;
}
interface IDocumentsFilters {
  funds: string[];
  investors: string[];
  quarters: string[];
  searchText: string;
  showFolders: boolean;
  includeArchived: boolean;
}

interface AvailableDocOptions {
  funds: IDocumentsFilter[];
  investors: IDocumentsFilter[];
  quarters: IDocumentsFilter[];
}

interface IDocumentsFiltersData {
  funds: IDocumentsFilter[];
  investors: IDocumentsFilter[];
  quarters: IDocumentsFilter[];
}
interface IDocumentsState {
  cursor: Array<string>;
  documents: Documents | null | undefined;
  tree: Documents | null | undefined;
  currentFolder: Documents | null | undefined;
  permissions: Permission[] | null | undefined;
  filtersData: IDocumentsFiltersData;
  isInitializing: boolean;
  isLoading: boolean;
  isPageLocked: boolean;
  isReadOnly: boolean;
  isCreating: boolean;
  isDownloading: boolean;
  isDeleting: boolean;
  pagination: DocumentsPagination;
  availableOptions: AvailableDocOptions;
  selectedDocumentVariant: SelectedDocumentVariant[];
  selectedFilters: IDocumentsFilters;
  selectedPermissions: Permission[];
  selectedPermissionsFilter: Array<Permission | '(none)'>;
  selectedNameFilter: Array<string>;
  selectedRows: string[];
  sort: string[];
  consent: string | null | undefined;
}

interface IDocumentsAction {
  type: string;
  payload?: any;
}

interface IDocumentsContext {
  state: IDocumentsState | null;
  changeFolder: Function;
  fetchDocuments: Function;
  fetchDocumentsFilters: Function;
  fetchFolderContent: Function;
  initialize: Function;
  resetFiltersSelection: Function;
  toggleFileStatus: Function;
  toggleDocControlPermissions: Function;
  toggleFileArchiveSettings: Function;
  togglePageLock: Function;
  toggleWaterMark: Function;
  updateRowsSelection: Function;
  fetchPermissions: Function;
  createFolder: Function;
  downloadFiles: Function;
  deleteFolder: Function;
  updateFolder: Function;
  applyFilters: Function;
  updateFiles: Function;
  resetOnChange: Function;
  resetSelectedFiles: Function;
  deleteFiles: Function;
  deleteFilesAndFolders: Function;
  moveFiles: Function;
  publishFiles: Function;
  downloadDocLinks: Function;
  changeFolderIndex: Function;
  fetchPublishedContent: Function;
  openDocument: Function;
  fetchDocumentTree: Function;
  consentAction: Function;
  fetchConsent: Function;
  applyPermissionFilter: Function;
  applyNameFilter: Function;
  toggleFolders: Function;
  toggleArchived: Function;
  removeLoading: Function;
}

interface IDocumentsPagination {
  page: number;
  pageSize: number;
  isLastPage: boolean;
}

export enum SelectedDocumentVariant {
  FOLDER,
  FOLDERS,
  PUBLISHED_FILE,
  PUBLISHED_FILES,
  UNPUBLISHED_FILE,
  UNPUBLISHED_FILES,
}

export type DocumentsAction = IDocumentsAction;
export type DocumentsContext = IDocumentsContext;
export type DocumentsFilter = IDocumentsFilter;
export type DocumentsFilters = IDocumentsFilters;
export type DocumentsFiltersData = IDocumentsFiltersData;
export type DocumentsState = IDocumentsState;
export type DocumentsPagination = IDocumentsPagination;
