import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";

import { AppContext } from '../../../core/context/appContextProvider';
import { StyledTextField } from '../../../pages/auth/Login.styles';
import {
  maxAllowedAttempts,
  requestLoginCode,
  totalAttempts,
  verifyLoginCode,
} from "../../../services/login.service";
import { MfaInfo } from "../../../utils/types/user.type";
import { StyledBox, StyledDiv } from "./InvestorList.styles";
import MfaRegistration from './MfaRegistration';

const codeValidation = Yup.object({
  code: Yup.string().required("Code is required"),
});

const MfaModal = ({
  open,
  handleClose,
  userMfaInfo,
  setUserMfaInfo,
  setLoadingInvestorList,
  onSuccessCallback,
  onfailCallback
}: {
  open: boolean;
  handleClose: () => void;
  userMfaInfo?: MfaInfo;
  setUserMfaInfo: any;
  setLoadingInvestorList: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessCallback: () => void
  onfailCallback: () => void
}) => {

  const {
    token,
    informationAlert
  } = useContext(AppContext);

  const [pageIndex, setPageIndex] = useState(0);
  const [requestIdInfo, setRequestInfo] = useState<any>();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<{
    deliveryType: string;
    trusted: boolean;
  }>({
    defaultValues: {
      deliveryType: "text",
      trusted: userMfaInfo?.trustedDevice,
    },
  });

  const {
    handleSubmit: handleCodeSubmit,
    control: confirmCodeformControl,
    register: codeFormRegister,
    formState: { errors: confirmCodeErrors },
    reset: codeFormReset
  } = useForm<{
    code: string;
    verifySuccess?: string;
  }>({
    defaultValues: {
      code: "",
      verifySuccess: "success",
    },
    resolver: yupResolver(codeValidation),
  });

const getRequestIdInfo = async (data: any) => {
  const bodyParams = {
    accessToken: token,
    code: null,
    deliveryType: data?.deliveryType,
    errorMessage: null,
    expirationDate: null,
    requestId: null,
    verificationAttempts: null,
    verifyCodeFakeResult: null,
    verifySuccess: null,
  };
  
  return await requestLoginCode(bodyParams);
};

  const getLoginCode = async (data: any) => {
    setLoadingInvestorList(true);
    const requestData = await getRequestIdInfo(data);

    if(requestData) {
      setRequestInfo(requestData);
      setPageIndex(1);
      setLoadingInvestorList(false);
    }
  };

  const confirmCode = async (data: any) => {
    setLoadingInvestorList(true);
    const requestBody = {
      ...requestIdInfo,
      ...data,
      verifySuccess: data?.verifySuccess === "success",
      verifyCodeFakeResult: data?.verifySuccess
    };

    try {
      const loginCode: any = await verifyLoginCode(requestBody);
 
      if(loginCode?.accessToken) {
        setLoadingInvestorList(false);
        setPageIndex(0);
        onSuccessCallback();
        reset();
        codeFormReset();
      } else {
        const maxAttempts = await maxAllowedAttempts();
        const total = await totalAttempts();

        setUserMfaInfo((prev: MfaInfo) => ({
          ...prev,
          showWarning: total !== 0,
          totalAttempts: total,
          remainingAttempts: maxAttempts - total
        }));
        setLoadingInvestorList(false);
      }
    } catch {
      setPageIndex(0);
      onfailCallback();
      reset();
      codeFormReset();
      setLoadingInvestorList(false);
    }
  };

  const SendCode = (
    <form onSubmit={handleSubmit(getLoginCode)}>
      <Typography variant="h4">Multi-factor Authentication Required</Typography>
      <StyledDiv />
      Account: {userMfaInfo?.userEmail}
      <StyledDiv />
      Upon investor editing a verification code is required.
      <StyledDiv />
      A verification code will be sent to the number on file:
      <StyledDiv />
      <Typography variant="h4">
        **** - **** - {userMfaInfo?.phoneNumber}
      </Typography>
      <StyledDiv />
      <Link href="#" underline="none">
        Click here
      </Link>{" "}
      to update the phone number. Message and data rates may apply.
      <StyledDiv />
      <Divider />
      <FormControl>
        <FormLabel id="mfa_preferemce">
          <Typography variant="label">Select delivery preferrence:</Typography>
        </FormLabel>

        <Controller
          name="deliveryType"
          control={control}
          render={({ field }) => (
            <RadioGroup id="preference_group" {...field} row>
              <FormControlLabel
                value="text"
                control={<Radio />}
                label="Text Message (SMS)"
              />
              <FormControlLabel
                value="phone"
                control={<Radio />}
                label="Phone Call"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      <StyledDiv />
      <FormControl>
        <FormLabel id="mfa_trust_device_label">
          <Typography variant="label">
            Trust this device for 30 days?
          </Typography>
        </FormLabel>
        <Controller
          name="trusted"
          control={control}
          render={({ field }) => (
            <RadioGroup id="mfa_trust_device_option" row {...field}>
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          )}
        />
      </FormControl>
      <StyledDiv />
      <Button
        type="submit"
        variant="contained"
        className="btn"
        disableElevation
        fullWidth
      >
        Send Code
      </Button>
    </form>
  );

  const ConfirmCode = (
    <form onSubmit={handleCodeSubmit(confirmCode)}>
      <Typography variant="h4">Confirmation Verification Code</Typography>
      <StyledDiv />
      A verification code will be sent to the number on file
      <StyledDiv />
      <Typography variant="h4">***_***_{userMfaInfo?.phoneNumber}</Typography>
      <StyledDiv />
      Enter the code
      <StyledDiv />
      {userMfaInfo?.showWarning && 
        <FormLabel error={true}> {userMfaInfo?.remainingAttempts} attempts remaining</FormLabel>}
      <StyledTextField
        size="small"
        id="confirm_code"
        type="text"
        variant="outlined"
        InputProps={{ notched: true }}
        {...codeFormRegister("code", { required: true })}
        error={!!confirmCodeErrors.code}
        helperText={confirmCodeErrors?.code?.message}
      />
      <StyledDiv />
      Didn&apos;t get the code? Need to update delivery preferences? Click here
      to resend it.
      <StyledDiv />
      Need to recover your account? Click here and a member of the support team
      will be in touch shortly
      <StyledDiv />
      {userMfaInfo?.isDevEnv && (
        <FormControl>
          <FormLabel id="simulation_verification">
            <Typography variant="label">
              DEV ONLY - SIMULATE SUCCESSFUL OR FAILED CODE VERIFICATION
            </Typography>
          </FormLabel>
          <Controller
            name="verifySuccess"
            control={confirmCodeformControl}
            render={({ field }) => (
              <RadioGroup id="dev_simulation_option" row {...field}>
                <FormControlLabel
                  value={"success"}
                  control={<Radio />}
                  label="Simulate successful verification"
                />
                <FormControlLabel
                  value={"fail"}
                  control={<Radio />}
                  label="Simulate failed verification"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      )}
      <StyledDiv />
      <Button
        type="submit"
        variant="contained"
        className="btn"
        disableElevation
        fullWidth
      >
        Confirm
      </Button>
    </form>
  );

  return (
    <Modal 
      open={open}
      disableEscapeKeyDown={true}
      id="mfa_confirmation_modal">
      <StyledBox>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton disableRipple onClick={() => { 
            onfailCallback();
            handleClose();
          }}>
            <CloseIcon />
          </IconButton>
        </div>
        {userMfaInfo?.phoneNumber ? 
          (pageIndex === 0 ? SendCode : ConfirmCode) : 
            <MfaRegistration 
              userMfaInfo={userMfaInfo}
              setUserMfaInfo={setUserMfaInfo}
              setLoadingInvestorList={setLoadingInvestorList}
            />
        }
       
      </StyledBox>
    </Modal>
  );
};

export default MfaModal;
