import { Box, Typography } from "@mui/material";

import { FormBorder }from "./TitleBox.style";

type Props = {
  title: string;
  children: any;
};

const TitleBox = ({
  title,
  children
}: Props)  => {

  return(
    <Box>
      {title && <Typography variant="formTitle">{title}</Typography>}
      <FormBorder>
        {children}
      </FormBorder>
    </Box>
  );
};

export default TitleBox;
