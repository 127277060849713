import LockIcon from '@mui/icons-material/Lock';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';

import { ProgressModal } from '../../../components';
import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import DataGrid from '../../../components/DataGrid/DataGrid';
import LockConfirmationDialog from '../../../components/LockConfirmationDialog/LockConfirmationDailog';
import MultiSelectSplitFilters from '../../../components/MultiSelectFilters/MultiSelectSplitFilters';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import {
  ViewOption,
  ViewOptionsTabs,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';
import { VisualDashboard } from '../../../components/Visualizations/VisualDashboard';
import ExportButton from './ExportButton/ExportButton';
import { getDefaultSoiViews } from './ScheduleOfInvestments.defaults';
import { useScheduleOfInvestments } from './ScheduleOfInvestments.hooks';
import {
  DataGridBox,
  HeaderButtonBox,
  HeaderRow,
  HeaderSubComponent,
  IconBox,
  SoiDataBox,
  SoiDataWrapper,
  SoiDataWrapperBox,
  VisualizationsBox,
} from './ScheduleOfInvestments.styles';

export const ScheduleOfInvestments: React.FC = () => {
  const {
    isLoadingList,
    currSOIview,
    handleSoiViewChange,
    soiGridData,
    handleColumnOrderChange,
    dateFilter,
    lockedPrompt,
    showLockedDialog,
    client,
    setLockedPrompt,
    handleDateChange,
    viewState,
    setViewState,
    visualizationsEnabled,
    handleDashboardFilter,
    filterMap,
    soiVisualizationsPageSummary,
    soiVisualizationPageType,
    combinedSoiBaseData,
    dashboardSplitFilter,
    dashboardFilterMap,
    handleViewStateChange,
  } = useScheduleOfInvestments();

  return (
    <SoiDataWrapperBox id="soi_list_main">
      {isLoadingList && (
        <ProgressModal
          id="gl_report_info_loading"
          showProgress={isLoadingList}
        />
      )}
      <HeaderRow>
        <HeaderSubComponent>
          {' '}
          <Typography variant="pageTitle">Schedule of Investments</Typography>
          {client?.soiLocked && (
            <IconButton aria-label="locked" onClick={showLockedDialog}>
              <LockIcon />
            </IconButton>
          )}
          {visualizationsEnabled && (
            <ViewOptionsTabs
              viewOptions={[
                ViewOption.DASHBOARD,
                ViewOption.HYBRID,
                ViewOption.DATA_VIEW,
              ]}
              viewState={viewState}
              setViewState={setViewState}
              handleViewStateChange={handleViewStateChange}
            />
          )}
        </HeaderSubComponent>
        <HeaderButtonBox>
          {viewState === ViewOption.DASHBOARD ? (
            <Box sx={{ width: '100px', display: 'flex', alignItems: 'center' }}>
              <MultiSelectSplitFilters
                id="soi_dashboard_filter"
                label="Filter"
                onFilter={handleDashboardFilter}
                splitFilters={dashboardSplitFilter}
                filterMap={dashboardFilterMap}
              />
            </Box>
          ) : (
            <Box sx={{ width: '200px', display: 'flex', alignItems: 'center' }}>
              <SingleSelect
                id="select_view"
                value={currSOIview}
                optionList={getDefaultSoiViews()}
                label={'SOI View'}
                handleOptionSelection={(evt: any) => {
                  handleSoiViewChange(evt.target.value);
                }}
                width="200px"
              />
            </Box>
          )}
          {viewState !== ViewOption.DASHBOARD && (
            <HeaderSubComponent>
              <ExportButton
                id="btn_export_wrapper"
                hidden={!(soiGridData?.soiGridRows?.length > 0)}
                headers={soiGridData?.dataGridColDefs || []}
                date={dateFilter}
                data={soiGridData?.soiGridRows || []}
              />
            </HeaderSubComponent>
          )}
          <HeaderSubComponent width={'150px'}>
            <DatePicker
              label="Date"
              renderInput={(params) => (
                <TextField {...params} size="small" id="filter_date" />
              )}
              onChange={(value) => {
                handleDateChange(value);
              }}
              value={dateFilter}
            />
          </HeaderSubComponent>
        </HeaderButtonBox>
      </HeaderRow>
      <SoiDataWrapper id="soi_data_wrapper">
        <SoiDataBox id="soi_data_box" viewState={viewState}>
          <VisualizationsBox>
            {visualizationsEnabled &&
              viewState !== ViewOption.DATA_VIEW &&
              soiVisualizationsPageSummary &&
              !!soiVisualizationsPageSummary[0].items.length && (
                <VisualDashboard
                  pageType={soiVisualizationPageType}
                  pageSummary={soiVisualizationsPageSummary}
                  allItemsSelected={true}
                  viewState={viewState}
                />
              )}
          </VisualizationsBox>
          {viewState !== ViewOption.DASHBOARD && (
            <DataGridBox>
              <DataGrid
                id="soi_data_grid"
                dataList={soiGridData.soiGridRows || []}
                headerList={soiGridData.dataGridColDefs}
                noDataMessage={isLoadingList ? 'Fetching Data...' : undefined}
                minHeight="1vh"
                autoHeight={false}
                activeHeaderFields={soiGridData.dataGridColDefs.length}
                checkboxSelection={false}
                onColumnOrderChange={(colDefs) => {
                  handleColumnOrderChange(
                    colDefs.oldIndex,
                    colDefs.targetIndex
                  );
                }}
              />
            </DataGridBox>
          )}
        </SoiDataBox>
      </SoiDataWrapper>
      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
      />
    </SoiDataWrapperBox>
  );
};
