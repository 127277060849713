import { FormControlLabel, RadioGroup, styled } from "@mui/material";


export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({

}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  alignItems: 'flex-start',
}));