import { Radio, RadioGroup, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { Role, Team } from "../../../../../utils/types/team.type";
import { RoleFormControlLabel } from "../../UserDetailPanel.styles";
import ClientCard from "../ClientCard";

interface Props {
    teamRoles?: Role[];
    onRoleSelect: (roleId: string) => void,
    selectedRole?: string;
    selectedTeam?: Team;
}

const RolesList: React.FC<Props> = ({
  teamRoles,
  onRoleSelect,
  selectedRole="",
  selectedTeam
}: Props): ReactElement => {
  return (
    <ClientCard title="Roles">
      <RadioGroup
        aria-labelledby="role-selection"
        name="role-selection"
        value={selectedRole}
        onChange={(e) => onRoleSelect(e.target.value)}
      >
        {selectedTeam && teamRoles && teamRoles?.length > 0 ? teamRoles?.map((role) => {
          return (
            <RoleFormControlLabel
              key={role.key}
              id={role.key}
              value={role.key}
              control={<Radio size="small" />}
              label={role.title} />
          );
        }) : (
          <Typography align="center">No Team Selected</Typography>
        )}
      </RadioGroup>
    </ClientCard>
  );
};

export default RolesList;
