import { CashReceiptTransaction } from '../../../utils/types/cashReceipt.type';
import { DetailsType } from '../../../utils/types/form.type';

export const UPDATE_TRANSACTION_ERROR =
  'Error in updating transaction details.';
export const UPDATE_TRANSACTION_SUCCESS =
  'Transaction details updated successfully.';
export const UPDATE_ADJUSTMENT_SUCCESS = 'Adjustments updated successfully.';
export const UPDATE_ADJUSTMENT_ERROR = 'Error in updating adjustments.';
export const PROMPT_ERROR_NOTIFY_HAS_ADJUSTMENTS =
  'One or more Cash Receipt entries selected contain adjustments. Cash Receipts with adjustments can be sent individually via the detail view screen. Please deselect entries that contain adjustments before proceeding.';
export const PROMPT_ERROR_NOTIFY_ALREADY_NOTIFIED =
  'One or more Cash Receipt entries selected have already been notified. Please be aware that each Cash Receipt notification can only be sent once per original entry.';
export const TOOLTIP_ARKGL_LOCKED =
  'This feature is currently available on the full version of the Ark platform. Please contact a system administrator for assistance.';
export const TOOLTIP_SETUP_GL =
  'Set up an arkGL for this fund to use this feature.';

export enum TransactionAction {
  NotifyAndSendToGL = 'notify_and_send',
  Notify = 'notify',
  SendToGL = 'send_to_gl',
  ExportAll = 'export_all',
  ExportSelected = 'export_selected',
  OpenBulkEdit = 'open_bulk_edit',
}

export enum CashReceiptCardTypes {
  TotalRemainingDue = 'total_remaining_due',
  TotalCapitalCalled = 'total_capital_called',
  TotalAmountPaid = 'total_amount_paid',
}

export const csvHeaders = [
  { label: 'fund', key: 'fund' },
  { label: 'investor', key: 'investor' },
  { label: 'remaining_due', key: 'remainingDue' },
  { label: 'capital_called', key: 'amount' },
  { label: 'amount_paid', key: 'amountPaid' },
  { label: 'cash_received', key: 'cashReceived' },
  { label: 'date_of_receipt', key: 'receiptDate' },
  { label: 'notified', key: 'notified' },
  { label: 'adjustment_1_amount', key: 'adjustment1Amount' },
  { label: 'adjustment_2_amount', key: 'adjustment2Amount' },
];

export type SelectedCashReceiptTransaction = {
  transaction?: CashReceiptTransaction;
  investor?: { id: string; name: string };
  type?: DetailsType;
};

export enum BulkEditOptions {
  KeepExisting = 'keep_existing',
  CashReceived = 'cash_received',
  UncheckCashReceived = 'uncheck_cash_received',
}
