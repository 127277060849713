import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ProgressPanel } from '../../../components';
import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import DataGrid from '../../../components/DataGrid/DataGrid';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import DeleteConfirmationDialog from '../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import FullScreenModal from '../../../components/Modal/FullScreenModal/FullScreenModal';
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../components/SearchBarWithDebounce/SearchBar';
import { AppContext } from '../../../core/context/appContextProvider';
import RoutingPaths from '../../../core/routing/routingPaths';
import useRole from '../../../core/routing/useRole';
import { uploadFile } from '../../../services/uploads.service';
import {
  APPROVE_CONTACT_POPUP_DESCRIPTION,
  CONFIRM_UPLOAD_POPUP_TITLE,
  DECLINE_CONTACT_POPUP_DESCRIPTION,
  GENERIC_ERROR_MESSAGE,
  UPLOAD_RESULTS_POPUP_DESCRIPTION,
  UPLOAD_RESULTS_POPUP_TITLE,
} from '../../../utils/constants/text.constants';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import { FundItem } from '../../../utils/types/fund.type';
import { InvestorFilter } from '../../../utils/types/investor.type';
import { FilterItem } from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import ContactAddOptionsPopover from './ContactAddOptionsPopover/ContactAddOptionsPopover';
import ContactInfoPanel from './contactDetailPanel/ContactDetailPanel';
import ReadOnlyContactPanel from './contactDetailPanel/ReadOnlyContactPanel';
import {
  OPTION_APPROVE,
  OPTION_DECLINE,
  OPTION_DELETE,
  useContactsInfoEffect,
  useGeneralActionSelectionEffect,
} from './ContactInfo.hooks';
import {
  ContactActionsContainer,
  InfoGridBox,
  StyledBox,
} from './ContactInfo.styles';
import MultiSelectFilter from './MultiSelectFilters';

type Props = {
  hidden?: boolean;
  roleFilters: FilterItem[];
  fundFilters: FundItem[];
  investorFilters: InvestorFilter[];
  contactColumnOrder: ColumnOrder | null | undefined;
};

type ModalData = {
  title: string;
  Description: string;
  function: Function;
};

const ContactInfo: React.FC<Props> = (props: Props): ReactElement => {
  const { informationAlert, state, token } = useContext(AppContext);
  const clientId = state.loginUser.currentUser?.clientId || '';
  const username = state.loginUser.currentUser?.username || '';
  const {
    hidden = false,
    fundFilters,
    roleFilters,
    investorFilters,
    contactColumnOrder,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [showUploadResults, setShowUploadResults] = useState(false);

  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);

  const {
    contactList,
    investorFilteredList,
    isLoadingContactList,
    isLoadingImpersonation,
    showPanel,
    setShowPanel,
    selectedContact,
    isPrimaryUser,
    selectedContactId,
    readonly,
    setSelectedContactId,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    handleFilter,
    searchText,
    setSearchText,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    showConfirmationPopUp,
    contactSelectionModel,
    setContactSelectionModel,
    confirmationPopUpText,
    handleConfirmationPopUpSuccess,
    handleHideConfirmationPopUp,
    setDataUpdate,
    onRowColumnOrderChange,
    onNextPage,
    loadinGrid,
    setLoadingContactList,
    confirmationButtonText,
    confirmationPopupTitle,
    fetchContacts,
    handleImpersonateUser,
    handleActionOptionClick,
    selectedActionOption,
    resetContactPanel,
    setResetContactPanel,
    investorList,
    selectedRoleOptions,
    selectedFundOptions,
    selectedInvestorOptions,
    setSelectedRoleOptions,
    setSelectedFundOptions,
    setSelectedInvestorOptions,
    canEditContact,
    isFetchingData,
    pendingContacts,
    primaryInvestors,
    selfContact,
    setIsLastPage,
    setPage,
  } = useContactsInfoEffect(
    isSubmitting,
    clientId,
    informationAlert,
    setIsSubmitting,
    investorFilters,
    contactColumnOrder,
    token,
    username
  );
  const history = useHistory();

  const [showAddOptions, setShowAddOptions] = useState(false);
  const [addOptionsAnchorEl, setAddOptionsAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const hasPrimary = useMemo(() => {
    return contactList.some((contact: any) => contact.primary);
  }, [contactList]);

  const handleAddNewButtonAction = (event: any) => {
    setShowAddOptions(true);
    setAddOptionsAnchorEl(event.currentTarget);
  };

  const handleOnAddOptionsClose = (event: any) => {
    setShowAddOptions(false);
  };

  const [showUploadFileConfirmation, setShowUploadFileConfirmation] =
    useState(false);
  const [fileUploaded, setFileUpdaloaded] = useState<File | null>();
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    title: 'Contact Info',
    Description: '',
    function: () => {},
  });

  const handleOnTemplateUpload = (event: any) => {
    const fileSelected = event.target.files[0];

    if (fileSelected) {
      setFileUpdaloaded(fileSelected);
      setShowUploadFileConfirmation(true);
    }
  };

  const handleOnTemplateUploadCancel = (event: any) => {
    setShowUploadFileConfirmation(false);
    setShowAddOptions(false);
  };

  const handleOnModalCancel = (event: any) => {
    setShowModalWarning(false);
  };

  const handleOnNewContact = (event: any) => {
    setShowAddOptions(false);
    setSelectedContactId('new');
    setShowPanel(true);
  };

  const handleOnTemplateUploadConfirmation = async (event: any) => {
    if (!fileUploaded) return;
    try {
      setIsUploadingFile(true);
      await uploadFile('contacts', fileUploaded);
      setShowUploadResults(true);
    } catch (exception) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setIsUploadingFile(false);
      setShowUploadFileConfirmation(false);
    }
  };

  const { bulkActionOptions } = useGeneralActionSelectionEffect(
    readonly,
    contactSelectionModel,
    isPrimaryUser,
    pendingContacts
  );

  const handleOnSearch = (newValue: string | null) => {
    if (typeof newValue === 'string') {
      setSearchText(newValue);
      if (newValue === '') {
        setIsLastPage(false);
        setPage(1);
      }
    } else if (newValue === null) {
      setIsLastPage(false);
      setSearchText('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const reloadContactList = () => {
    fetchContacts(headerList, 1, '', false, () => false);
  };

  const handleOnTemplateUploadResultCancel = () => {
    reloadContactList();
    setShowUploadResults(false);
  };

  const handleOnTemplateUploadResult = () => {
    setShowUploadResults(false);
    history.push(RoutingPaths.UploadHistory);
  };

  const isRowSelectable = (params: GridRowParams) => {
    return true;
  };

  const handleOptionsFilter = (filterName: any, selected: any) => {
    if (filterName === 'investor_filter') {
      setSelectedInvestorOptions((prev: any) => [...selected]);
    }
    if (filterName === 'fund_filter') {
      setSelectedFundOptions((prev: any) => [...selected]);
    }
    if (filterName === 'role_filter') {
      setSelectedRoleOptions((prev: any) => [...selected]);
    }
    setIsLastPage(false);
    handleFilter(filterName, selected);
  };

  return (
    <>
      <ProgressPanel
        id="contact_info_loading"
        showProgress={isLoadingContactList || loadinGrid}
        text={'Loading'}
      />
      <div></div>
      <DataWrapperBox hidden={hidden} gapSize={0}>
        <HorizontalBox id="contact_info" fullWidth noPadding>
          <ProgressModal
            id="contact_submit_loading"
            showProgress={isSubmitting || isUploadingFile || isFetchingData}
          />

          <ContactActionsContainer>
            <SearchBar
              id="txt_search_contact"
              size="small"
              onChange={handleOnSearch}
              value={searchText}
              fullWidth
            />
            <Box
              flex={1}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ display: 'flex' }}
            >
              {/* TODO - Need to remove once we add contact 2.0 API */}
              <StyledBox>
                <MultiSelectFilter
                  size="small"
                  id={'contact_investor_filter'}
                  value={selectedInvestorOptions}
                  handleFilter={handleOptionsFilter}
                  label="Investor Filter"
                  listData={investorList as any}
                  filterName={'investor_filter'}
                />
              </StyledBox>
              <StyledBox>
                <MultiSelectFilter
                  size="small"
                  id={'contact_funds_filter'}
                  value={selectedFundOptions}
                  handleFilter={handleOptionsFilter}
                  label="Fund Filter"
                  listData={fundFilters as any}
                  filterName={'fund_filter'}
                />
              </StyledBox>
              <StyledBox>
                <MultiSelectFilter
                  size="small"
                  id={'contact_role_filter'}
                  value={selectedRoleOptions}
                  handleFilter={handleOptionsFilter}
                  label="Role Filter"
                  listData={roleFilters as any}
                  filterName={'role_filter'}
                />
              </StyledBox>
              <SplitButton
                id={'btn_bulk_action_options'}
                options={bulkActionOptions}
                hidden={false}
                handleOptionClick={handleBulkOptionClick}
                ariaLabelMessage="Select bulk action option"
              />
              {(!readonly || isPrimaryUser) && (
                <Button
                  id="btn_add_new_options_button"
                  variant="contained"
                  icon={<AddIcon />}
                  text="Add New"
                  color={'primary'}
                  onClick={handleAddNewButtonAction}
                />
              )}
            </Box>
          </ContactActionsContainer>

          <ContactAddOptionsPopover
            id={'pop_contact_list_add_options'}
            anchorEl={addOptionsAnchorEl}
            showPopover={showAddOptions}
            handleOnPopoverClose={handleOnAddOptionsClose}
            handleOnTemplateUpload={handleOnTemplateUpload}
            handleOnNewContact={handleOnNewContact}
          />

          <FullScreenModal
            id="modal_contact_upload_confirmation"
            open={showUploadFileConfirmation}
            title={CONFIRM_UPLOAD_POPUP_TITLE}
            subtitle={
              <div>
                Are you sure you want to upload{' '}
                <strong>{fileUploaded?.name}</strong> ?
              </div>
            }
            confirmButtonText="Upload"
            cancelButtonText="Cancel"
            onSuccess={handleOnTemplateUploadConfirmation}
            onCancel={handleOnTemplateUploadCancel}
          />

          <FullScreenModal
            id="modal_contact_list_upload_results"
            open={showModalWarning}
            title={modalData.title}
            subtitle={modalData.Description}
            confirmButtonText="Approve"
            cancelButtonText="Close"
            onSuccess={modalData.function}
            onCancel={handleOnModalCancel}
          />
        </HorizontalBox>
        {headerList.length > 0 && (
          <DataGrid
            id="contact_info_data_grid"
            dataList={investorFilteredList || []}
            headerList={headerList}
            activeHeaderFields={activeHeaderFields}
            handleUpdateHeader={handleUpdateHeader}
            handleFilter={handleFilter}
            selectionModel={contactSelectionModel}
            setSelectionModel={setContactSelectionModel}
            onColumnOrderChange={onRowColumnOrderChange}
            onNextPage={onNextPage}
            isRowSelectable={isRowSelectable}
            disableVirtualization={true}
            autoHeight={false}
            minHeight={'1vh'}
            hideSelectAll={true}
          />
        )}
        {canEditContact ? (
          <ContactInfoPanel
            showPanel={showPanel}
            setShowPanel={setShowPanel}
            selectedContact={selectedContactId}
            setSelectedContact={setSelectedContactId}
            roleFilters={roleFilters}
            fundFilters={fundFilters}
            setDataUpdate={setDataUpdate}
            investorFilters={investorFilters}
            primaryInvestors={primaryInvestors}
            setLoadingContactList={setLoadingContactList}
            reloadContactList={reloadContactList}
            isLoadingImpersonation={isLoadingImpersonation}
            handleImpersonateUser={handleImpersonateUser}
            onApprove={() =>
              handleActionOptionClick(OPTION_APPROVE, [selectedContactId])
            }
            onDecline={() =>
              handleActionOptionClick(OPTION_DECLINE, [selectedContactId])
            }
            resetContactPanel={resetContactPanel}
            setResetContactPanel={setResetContactPanel}
          />
        ) : (
          <ReadOnlyContactPanel
            showPanel={showPanel}
            setShowPanel={setShowPanel}
            selectedContact={selectedContactId}
            setSelectedContact={setSelectedContactId}
            roleFilters={roleFilters}
            fundFilters={fundFilters}
            investorFilters={investorFilters}
          />
        )}

        <DeleteConfirmationDialog
          open={selectedActionOption === OPTION_DELETE}
          id="delete_confirmation_modal"
          primaryButtonAction={handleConfirmationPopUpSuccess}
          secondaryButtonAction={handleHideConfirmationPopUp}
          content="This action is permanent and cannot be undone"
        />

        <FullScreenModal
          id="modal_contact_info_confirmation"
          open={
            showConfirmationPopUp &&
            ![OPTION_DELETE].includes(selectedActionOption)
          }
          title={confirmationPopupTitle}
          subtitle={confirmationPopUpText}
          confirmButtonText={confirmationButtonText}
          cancelButtonText="Cancel"
          onSuccess={handleConfirmationPopUpSuccess}
          onCancel={handleHideConfirmationPopUp}
        />

        <FullScreenModal
          id="modal_contact_list_upload_results"
          open={showUploadResults}
          title={UPLOAD_RESULTS_POPUP_TITLE}
          subtitle={UPLOAD_RESULTS_POPUP_DESCRIPTION}
          confirmButtonText="Upload History"
          cancelButtonText="Close"
          onSuccess={handleOnTemplateUploadResult}
          onCancel={handleOnTemplateUploadResultCancel}
        />
      </DataWrapperBox>
    </>
  );
};

export default ContactInfo;
