import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { FC, ReactElement } from "react";

import ListItem from "../../ListItem/ListItem";
import ListItemIcon from "../../ListItemIcon/ListItemIcon";

interface Option {
  id: string;
  icon?: any;
  label: string;
  divider?:boolean;
  value: any;
}

type Props = {
  options: Array<Option> | undefined;
  selectedOption?: boolean;
  handleFilter: (filter: any, selected: boolean) => void;
  filterName: string;
}

const PopoverFilterList: FC<Props> = ({
  options,
  handleFilter,
  selectedOption = true,
  filterName,
}: Props): ReactElement => {

  return (
    <List dense={true}>
      {options?.map(option => (
        <ListItem key={option.id}>
          <ListItemButton
            onClick={() => handleFilter(filterName, option.value)}
            divider={option.divider}
            selected={selectedOption === option.value}
          >
            { !!option.icon && <ListItemIcon 
              id={option.id}
              icon={option.icon}
            /> }
            <Typography variant="subtitle2">{option.label}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default PopoverFilterList;