import {
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";

import { FormProps } from "../../../utils/types/form.type";
import { InvestorDetail } from "../../../utils/types/investor.type";
import { CheckBox } from "../investorList/investorDetailPanel/InvestorDetailPanel.styles";
import { useWireEffect } from "./InvestorWire.hooks";

type Props = {
  form: FormProps;
  investor?: InvestorDetail;
  scrollToBottom: Function;
};

const InvestorWire: React.FC<Props> = (props: Props): ReactElement => {
  const { form, investor, scrollToBottom } = props;

  const { register, control, errors, setError, setValue, watch } = form;

  const foreign = watch('wireInfo.foreign');

  const {
    onIntermediaryBankCheck
  } = useWireEffect(
    form,
    scrollToBottom,
    investor
  );

  return (
    <Container id="investor_wire_container">
      <Grid container xs={12} spacing={2} mb={4} mt={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Wire Instructions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">Receiving Bank information</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="txt_receiving_bank_aba"
            label="Receiving Bank ABA/Swift"
            size="small"
            fullWidth
            {...register("wireInfo.receivingBankInfo.abaNumber")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="txt_receiving_bank_name"
            label="Receiving Bank Name"
            size="small"
            fullWidth
            {...register("wireInfo.receivingBankInfo.name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="txt_receiving_bank_address1"
            label="Receiving Bank Address 1"
            size="small"
            fullWidth
            {...register("wireInfo.receivingBankInfo.addressLine1")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="txt_receiving_bank_address2"
            label="Receiving Bank Address 2"
            size="small"
            fullWidth
            {...register("wireInfo.receivingBankInfo.addressLine2")}
          />
        </Grid>

        <Grid container xs={6} spacing={2} mt={2} ml={0}>
          <Grid item xs={12}>
            <Typography variant="h3">Beneficiary Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_account"
              label="Beneficiary Account"
              {...register("wireInfo.beneficiaryAccount")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_name"
              label="Beneficiary Name"
              {...register("wireInfo.beneficiaryName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_address_line_1"
              label="Beneficiary Address Line 1"
              {...register("wireInfo.beneficiaryAddressLine1")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_address_line_2"
              label="Beneficiary Address Line 2"
              {...register("wireInfo.beneficiaryAddressLine2")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_bank_aba"
              label="Beneficiary bank ABA/Swift"
              {...register("wireInfo.beneficiaryBankInfo.abaNumber")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_bank_name"
              label="Beneficiary Bank Name"
              {...register("wireInfo.beneficiaryBankInfo.name")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_bank_addressLine1"
              label="Beneficiary Bank Address Line 1"
              {...register("wireInfo.beneficiaryBankInfo.addressLine1")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_beneficiary_bank_addressLine2"
              label="Beneficiary Bank Address Line 2"
              {...register("wireInfo.beneficiaryBankInfo.addressLine2")}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container xs={6} spacing={2} mt={2} ml={0}>
          <Grid item xs={12}>
            <Typography variant="h3">Additional Instructions</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              id="txt_originator_to_beneficiary"
              label="Originator to Beneficiary Instructions 1"
              rows={1}
              multiline
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.originatorToBeneficiaryInstructions1")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_originator_to_beneficiary"
              label="Originator to Beneficiary Instructions 2"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.originatorToBeneficiaryInstructions2")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_originator_to_beneficiary"
              label="Originator to Beneficiary Instructions 3"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.originatorToBeneficiaryInstructions3")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_originator_to_beneficiary"
              label="Originator to Beneficiary Instructions 4"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.originatorToBeneficiaryInstructions4")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">Receiving Bank Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_bank_to_bank_instructions1"
              label="Bank to Bank Instructions 1"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.bankToBankInstructions1")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_bank_to_bank_instructions2"
              label="Bank to Bank Instructions 2"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.bankToBankInstructions2")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_bank_to_bank_instructions3"
              label="Bank to Bank Instructions 3"
              size="small"
              rows={1}
              multiline
              fullWidth
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.bankToBankInstructions3")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="txt_bank_to_bank_instructions4"
              label="Bank to Bank Instructions 4"
              size="small"
              rows={1}
              multiline
              inputProps={{ maxLength: 255 }}
              {...register("wireInfo.bankToBankInstructions4")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="wireInfo.foreign"
            render={({
              field: { onChange, value },
              fieldState: { error }
            }) => {
              return (
                <CheckBox>
                  <FormControlLabel
                    label="Bank Account not in the US."
                    control={
                      <Checkbox
                        id="check_bank_account_not_in_us"
                        checked={value || false}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                          onIntermediaryBankCheck(newValue);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />}
                  />
                  <FormHelperText>
                    Click here if your bank account is foreign to the US.
                  </FormHelperText>
                </CheckBox>
              );
            }}
            control={control}
          />
        </Grid>
        {foreign && (
          <>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="txt_intermediary_bank_aba"
                label="Intermediary Bank ABA/Swift"
                {...register("wireInfo.intermediaryBankInfo.abaNumber")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="txt_intermediary_bank_address1"
                label="Intermediary Bank Address 1"
                {...register("wireInfo.intermediaryBankInfo.addressLine1")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="txt_intermediary_bank_address2"
                label="Intermediary Bank Address 2"
                {...register("wireInfo.intermediaryBankInfo.addressLine2")}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default InvestorWire;
