import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid";
import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import {
  downloadTransactionHistoryExcel,
  downloadTransactionHistoryPdf,
  getTransactionHistory,
  getTransactionHistoryForStatementReport
} from "../../../services/capitalAccounts.service";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { NegativeCurrencyFormat, NumberFormat } from "../../../utils/helpers/format.helper";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import {
  FileDownloadTransHistoryParams,
  SelectedTransaction,
  TransactionHistoryEntry,
  TransactionHistoryParams,
  TransactionHistoryResponse
} from "../../../utils/types/capitalAccounts.type";
import { DownloadTransactionHistoryOptionIds } from "../../../utils/types/common.type";
import { CustomType, DataGridColDef } from "../../../utils/types/listItems";

type Props = {
  transaction?: SelectedTransaction;
  onTransactionHistoryClose: Function;
}

const headerList: DataGridColDef[] = [{
  field: "fundName",
  headerName: "Fund",
  type: "string",
  hide: false,
  index: 0,
  align: 'left' as GridAlignment,
  width: 160,
}, {
  field: "date",
  headerName: "Transaction Date",
  type: "date",
  hide: false,
  index: 1,
  align: 'left' as GridAlignment,
  width: 160,
}, {
  field: "type",
  headerName: "Transaction Type",
  type: "string",
  hide: false,
  index: 2,
  align: 'left' as GridAlignment,
  width: 160,
}, {
  field: "amount",
  headerName: "Amount",
  type: "number",
  customType: CustomType.NegativeCurrency,
  hide: false,
  index: 3,
  align: 'right' as GridAlignment,
  width: 170,
  renderCell: (params: GridRenderCellParams) => {
    const currencyCode = params.row.currencyCode || "USD";
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

    if(params.row.useMetric) {

      if (datePattern.test(params.row.metricSign)){
        return params.row.metricSign;
      } 

      return NumberFormat(params.row.metricFractionDigit).format(params.value) + params.row.metricSign;
    }
    return NegativeCurrencyFormat(currencyCode, params.row.metricFractionDigit).format(params.value);
  },
}];

type TransactionAmount = {
  value: number;
  metricData: null | {
    useMetric: boolean;
    metricFractionDigit: number;
    metricSign: string;
  }
}

export const DOWNLOAD_TRANSACTION_ERROR = "Error in downloading transactions.";

export const useTransactionHistory = ({
  transaction,
  onTransactionHistoryClose,
}: Props) => {
  const formatter = NegativeCurrencyFormat(transaction?.currencyCode || "USD");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState<TransactionHistoryResponse | null>(null);
  const [transactions, setTransactions] = useState<TransactionHistoryEntry[]>([]);
  const [amountTotal, setAmountTotal] = useState<TransactionAmount>({
    value: 0,
    metricData: null,
  });
  const {
    informationAlert
  } = useContext(AppContext);
  
  const toggleDrawer = () => {
    closeDrawer();
  };

  const closeDrawer = () => {
    onTransactionHistoryClose();
  };

  const fetchTransactionHistory = async (isCanceled: () => boolean) => {
    const {
      fundId,
      investorId,
      quarter,
      transactionType,
      additionalReportElements,
      reportColumnType,
      reportElementType,
      label,
      transactionTypes,
      transactionCode,
      currencyCode,
    } = transaction || {};
    
    if (fundId && investorId && quarter) {
      try {
        setIsLoading(true);
        let transactionHistoryResponse: TransactionHistoryResponse;

        if (transactionType && transactionCode) {
          const params: TransactionHistoryParams = {
            fundId,
            investorId,
            quarter,
            transactionCode,
          };

          transactionHistoryResponse = await getTransactionHistory(params);
        } else {
          const params: SelectedTransaction = {
            fundId,
            investorId,
            quarter,
            transactionTypes,
            additionalReportElements,
            label,
            reportColumnType,
            reportElementType
          };

          transactionHistoryResponse = await getTransactionHistoryForStatementReport(params);
        }
        if(isCanceled()) return;

        setTransactionHistory(transactionHistoryResponse);
        setTransactions(
          transactionHistoryResponse.transactions.map((trans, index) => ({
            ...trans,
            id: `trans${index}`,
            fundName: transactionHistoryResponse.fundName,
            currencyCode,
          }))
        );
        setAmountTotal({
          value: transactionHistoryResponse.transactions.reduce(
            (total, trans) => (total += trans.amount),
            0
          ),
          metricData: transactionHistoryResponse.transactions.length > 0 && transactionHistoryResponse.transactions[0].useMetric ? {
            useMetric: transactionHistoryResponse.transactions[0].useMetric,
            metricFractionDigit: transactionHistoryResponse.transactions[0].metricFractionDigit,
            metricSign: transactionHistoryResponse.transactions[0].metricSign
          }: null
        });
      } catch (e) {
        informationAlert("Error in getting transaction history", "error");
      }
    }
    setIsLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchTransactionHistory(isCanceled);
  }, [transaction]);

  const handleSplitButtonAction = (actionId: DownloadTransactionHistoryOptionIds) => {
    setIsLoading(true);
    switch (actionId) {
      case DownloadTransactionHistoryOptionIds.DownloadExcel:
        downloadExcel();
        break;
      case DownloadTransactionHistoryOptionIds.DownloadPdf:
        downloadPdf();
        break;
    }
  };

  const downloadExcel = async () => {
    const {
      fundId,
      investorId,
      quarter,
      transactionType,
      additionalReportElements,
      reportColumnType,
      reportElementType,
      label,
      transactionTypes,
      fundName,
      investorName
    } = transaction || {};

    if (fundId && investorId && quarter) {

      try {
        let params: FileDownloadTransHistoryParams;

        if (transactionType) {
          params = {
            clientId: "",
            fundId,
            fundIds: null,
            fundWithInvestorIds: null,
            ids: [],
            investorId,
            quarter,
            transactionCode: transactionType?.toLocaleUpperCase(),
            type: 'TRANSACTION_HISTORY'
          };
        } else {
          params = {
            additionalReportElements,
            clientId: "",
            fundId,
            fundIds: null,
            fundWithInvestorIds: null,
            ids: [],
            investorId,
            label,
            quarter,
            reportColumnType,
            reportElementType,
            transactionTypes,
            type: 'TRANSACTION_HISTORY',
            username: null
          };
        }

        const downloadedData = await downloadTransactionHistoryExcel(params);

        downloadFile(downloadedData, `Transaction History - ${fundName} - ${investorName}`, "csv");
      } catch (error) {
        informationAlert(DOWNLOAD_TRANSACTION_ERROR, "error");
      }
    }

    setIsLoading(false);
  };

  const downloadPdf = async () => {
    const {
      fundId,
      investorId,
      quarter,
      transactionType,
      additionalReportElements,
      reportColumnType,
      reportElementType,
      label,
      transactionTypes,
      fundName,
      investorName
    } = transaction || {};

    if (fundId && investorId && quarter) {
      try {
        let params: FileDownloadTransHistoryParams;

        if (transactionType) {
          params = {
            clientId: "",
            fundId,
            fundIds: null,
            fundWithInvestorIds: null,
            ids: [],
            investorId,
            quarter,
            transactionCode: transactionType?.toLocaleUpperCase(),
            type: 'TRANSACTION_HISTORY'
          };
        } else {
          params = {
            additionalReportElements,
            clientId: "",
            fundId,
            fundIds: null,
            fundWithInvestorIds: null,
            ids: [],
            investorId,
            label,
            quarter,
            reportColumnType,
            reportElementType,
            transactionTypes,
            type: 'TRANSACTION_HISTORY',
            username: null
          };
        }

        const downloadedData = await downloadTransactionHistoryPdf(params);

        downloadFile(downloadedData, `Transaction History - ${fundName} - ${investorName}`, "pdf");
      } catch (error) {
        informationAlert(DOWNLOAD_TRANSACTION_ERROR, "error");
      }
    }

    setIsLoading(false);
  };

  return {
    toggleDrawer,
    isLoading,
    transactionHistory,
    transactions,
    headerList,
    amountTotal,
    handleSplitButtonAction,
  };
};
