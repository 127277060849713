import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormLabel,
  Stack,
  styled,
  Typography as MUITypography,
} from '@mui/material';

import { INFO_PANEL_MAX_WIDTH } from '../../../../utils/constants/styles.constants';

export const DetailPanel = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  },
}));

export const Panel = styled(Box)`
  margin: 15px 0;
`;

export const Typography = styled(MUITypography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const ChipStack = styled(Stack)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const TransactionListBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTypography-root': {
    marginBottom: '10px',
  },
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '16px',
}));

export const HeaderRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '55% 45%',
  gap: theme.spacing(1),
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  '&.MuiTypography-root': {
    margin: '0',
  },
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  paddingTop: theme.spacing(4),
}));

export const CheckboxLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

export const CheckboxFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
