import IconDownload from "../../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../assets/images/icons/icon_upload.svg";
import { ButtonOptions } from "../../../components/ButtonWithOptions/ButtonWithOptions";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import { Investor, InvestorDetail, MailingAddress, ReceivingBankInfo, WireInfo } from "../../../utils/types/investor.type";

export const INVESTOR_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const INVESTOR_TYPE_ENTITY = "ENTITY";

export const GET_INVESTOR_DETAILS_ERROR = "Error in getting investor details.";
export const UPDATE_INVESTOR_ERROR = "Error in updating investor.";
export const UPDATE_INVESTOR_SUCCESS = "Investor updated successfully.";
export const SAVE_INVESTOR_ERROR = "Error in saving investor.";
export const SAVE_INVESTOR_SUCCESS = "Investor saved successfully.";
export const CREATE_INVESTOR_ERROR = "Error in creating investor.";
export const CREATE_INVESTOR_SUCCESS = "Investor created successfully.";
export const DELETE_INVESTOR_SUCCESS = "Investor deleted successfully";

export const DOWNLOADED_TEMPLATE_INVESTOR_FILE_NAME = "standard_investor_import_template";
export const DEFAULT_MAILING_ADDRESS: MailingAddress = {
  id: "",
  street1: "",
  street2: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
};

export const DEFAULT_TAX_ADDRESS: MailingAddress = {
  id: "",
  street1: "",
  street2: "",
  city: "",
  postalCode: "",
  state: "",
  country: "",
};

export const DEFAULT_BANK_INFO: ReceivingBankInfo = {
  id: "",
  abaNumber: "",
  name: "",
  type: "",
  addressLine1: "",
  addressLine2: "",
};

export const DEFAULT_WIRE_INFO: WireInfo = {
  id: "",
  receivingBankInfo: DEFAULT_BANK_INFO,
  beneficiaryAccount: "",
  beneficiaryName: "",
  beneficiaryAddressLine1: "",
  beneficiaryAddressLine2: "",
  changesPending: false,
  beneficiaryBankInfo: DEFAULT_BANK_INFO,
  intermediaryBankInfo: DEFAULT_BANK_INFO,
  originatorToBeneficiaryInstructions1: "",
  originatorToBeneficiaryInstructions2: "",
  originatorToBeneficiaryInstructions3: "",
  originatorToBeneficiaryInstructions4: "",
  bankToBankInstructions1: "",
  bankToBankInstructions2: "",
  bankToBankInstructions3: "",
  bankToBankInstructions4: "",
  foreign: false,
};

export const DEFAULT_INVESTOR: InvestorDetail = {
  name: "",
  fullName: "",
  firstName: "",
  lastName: "",
  phone: "",
  id: "",
  tenantId: "",
  indexedName: "",
  taxAddress: DEFAULT_TAX_ADDRESS,
  mailingAddress: DEFAULT_MAILING_ADDRESS,
  status: "",
  taxIdentificationNumber: "",
  entityType: INVESTOR_TYPE_INDIVIDUAL,
  fundInvestorTypes: [{
    fundId: "",
    investorType: {
      id: "LP",
      label: "Limited Partner"
    }
  }],
  ownerName: "",
  ownerSSN: "",
  legalType: {
    id: "B",
    label: "",
  },
  funds: [],
  wireInfo: DEFAULT_WIRE_INFO,
  disregardedEntity: false,
  useMailingForTax: false,
};

export const NewInvestorOptions: ButtonOptions[] = [{
  label: 'New Entity',
  id: AddNewButtonOptions.AddNew,
  icon: IconNewCircle,
},
{
  label: 'Upload From Template',
  id: AddNewButtonOptions.UploadFromTemplate,
  icon: IconUpload,
  type: 'upload'
},
{
  label: 'Download Template',
  id: AddNewButtonOptions.DownloadTemplate,
  icon: IconDownload,
}];

