import Checkbox from '@mui/material/Checkbox';
import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

import TitleBox from '../../../components/TitleBox/TitleBox';
import { Client } from "../../../utils/types/client.type";
import { CheckboxFormControl, CheckboxLabel, MultiFactorConfigurationContainer } from "./ClientDetails.style";

type Props = {
    control: Control<Client, any>;
}

const MultiFactorConfiguration = ({
  control
}: Props) => {

  return (
    <TitleBox title="Multi-Factor Configuration">
      <MultiFactorConfigurationContainer>
        <Controller
          name="mfaSettings.loginValidation"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CheckboxFormControl fullWidth>
                <CheckboxLabel>
                  <Checkbox
                    id="login_mfa"
                    checked={value || false}
                    onChange={onChange} />
                        Login</CheckboxLabel>
              </CheckboxFormControl>
            );
          }}
          control={control}
        />
        <Controller
          name="mfaSettings.wireInfoValidation"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CheckboxFormControl fullWidth>
                <CheckboxLabel>
                  <Checkbox
                    id="wire_info_validation_mfa"
                    checked={value || false}
                    onChange={onChange} />
                        Investor Updates</CheckboxLabel>
              </CheckboxFormControl>
            );
          }}
          control={control}
        />
        <Controller
          name="mfaSettings.contactValidation"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CheckboxFormControl fullWidth>
                <CheckboxLabel>
                  <Checkbox
                    id="contact_validation_mfa"
                    checked={value || false}
                    onChange={onChange} />
                        Contact Updates</CheckboxLabel>
              </CheckboxFormControl>
            );
          }}
          control={control}
        />
      </MultiFactorConfigurationContainer>
    </TitleBox>);
};

export default MultiFactorConfiguration;

