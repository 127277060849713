import { Dialog, DialogTitle, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  & .MuiDialogContent-root {
    margin: 30px 30px 0;
  }

  & .MuiDialogActions-root {
    margin: 0 40px 30px 40px;
    justify-content: center;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(26),
  lineHeight: 1.1,
  color: theme.palette.common.black,
  textAlign: "center",
  padding: 0,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));