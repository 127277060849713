import {
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridColumns,
  GridRowsProp,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AppContext } from "../../../../core/context/appContextProvider";
import { saveColumnOrder } from "../../../../services/columnOrder.service";
import { CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY } from "../../../../utils/constants/text.constants";
import {
  arrayIndexUpdate as updateArrayIndex,
  arrayVisibilityUpdate,
} from "../../../../utils/helpers/columnOrder.helper";
import {
  ColumnOrder,
  ViewItemsEntity,
} from "../../../../utils/types/columnOrder";
import { FundItem } from "../../../../utils/types/fund.type";
import { InvestorFilter } from "../../../../utils/types/investor.type";
import {
  DataGridColDef,
  DataGridHeaderItem,
  FilterItem,
} from "../../../../utils/types/listItems";
import { formattedSingleContactInvestment } from "../../contactsHelpers/contactHelper";

export const usePermissionsDataGridEffect = (
  readonly: boolean,
  roleList: FilterItem[],
  childCheckbox: any,
  columnHeader: any,
  headerActions: any,
  permissionByInvestorList: GridRowsProp,
  setPermissionsByInvestorList: any,
  investorFilterList: InvestorFilter[],
  fundFilters: FundItem[],
  isContactChanged: boolean,
  setIsContactChanged: any,
  contactPermissionColumnOrder?: ColumnOrder | null,
  primaryInvestors?: Set<string>,
) => {
  const {
    state
  } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const [gridColumns, setGridColumns] = useState<GridColumns>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showAddInvestorsPopover, setShowAddInvestorsPopover] = useState(false);
  const [filteredInvestorFilterList, setFilteredInvestorFilterList] = useState<
    DataGridHeaderItem[]
  >([]);
  const [
    originalFilteredInvestorFilterList,
    setOriginalFilteredInvestorFilterList,
  ] = useState<DataGridHeaderItem[]>([]);
  const [investorFilterText, setInvestorFilterText] = useState<string | null>(
    ""
  );
  const [headerFields, setHeaderFields] = useState<DataGridHeaderItem[]>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    headerFields.length
  );

  const roles: any = {};
  const fundItems: any = {};

  fundFilters.forEach(fund => fundItems[fund.id] = fund.name);
  //TODO: This is hardcoded approach till the backend is able to fix it on their side.
  roleList.forEach(({
    id, name
  }) => {
    if(["Capital Call CC", "Distributions CC", "K1 CC", "Quarterly Reports CC", "Legal CC"]?.includes(name)) {
      roles[id] = false;
    } else {
      roles[id] = true;
    }
  });
  const investorIds: Set<string> = new Set();

  permissionByInvestorList.forEach((investor) => {
    investorIds.add(investor.id);
  });

  const handleOnSearchTextChange = (newValue: string | null) => {
    setInvestorFilterText(newValue);
    setFilteredInvestorFilterList(
      originalFilteredInvestorFilterList.filter((investor) =>
        investor.label
          .toLowerCase()
          .includes(newValue?.toLocaleLowerCase() || "")
      )
    );
  };

  const handleOnInvestorClear = () => {
    setInvestorFilterText("");
  };

  // returns formatted investor roles and funds
  const addInvestorToList = (investorFilter: InvestorFilter) => {
    return formattedSingleContactInvestment(
      investorFilter,
      roles,
      fundItems,
    );
  };

  // eslint-disable-next-line require-await
  const handleSelectAll = async (checked: boolean) => {
    let updatedList: GridRowsProp = permissionByInvestorList;
    const uncheckInvestorIds: Set<any> = new Set();
    const checkedList = filteredInvestorFilterList.map((element) => {
      if (!element.checked) {
        uncheckInvestorIds.add(element.field);
      }
      return {
        ...element,
        checked: checked,
      };
    });

    // update investor options
    setFilteredInvestorFilterList(checkedList);
    if (checked) {
      for(let i = 0; i < investorFilterList.length; i++) {
        const item = investorFilterList[i];

        if(uncheckInvestorIds.has(item.id)) {
          const formattedInvestordata = addInvestorToList(item);

          updatedList = updatedList.concat(formattedInvestordata);
        }
      }
      setPermissionsByInvestorList(updatedList);
    }
    else {
      setPermissionsByInvestorList([]);
    }
    if(!isContactChanged) {
      setIsContactChanged(true);
    }
  };

  const handleSingleSelect = (selectedInvestor: string, checked: boolean) => {
    let updatedList: GridRowsProp = [];
    const updatedFilteredList: DataGridHeaderItem[] =
      filteredInvestorFilterList.map((investor) => {
        return investor.field === selectedInvestor
          ? {
            ...investor,
            checked: checked,
          }
          : {
            ...investor,
          };
      });

    setFilteredInvestorFilterList(updatedFilteredList);

    if (checked) {
      const matchedInvestorOption = investorFilterList.find(
        (investor) => investor.id === selectedInvestor
      );

      if (matchedInvestorOption) {
        const formattedInvestorData = addInvestorToList(matchedInvestorOption);

        updatedList = permissionByInvestorList.concat(formattedInvestorData);
      }
    } else {
      // remove the investor from the investorPermission array
      updatedList = permissionByInvestorList.filter(
        (investor) => !investor.id.includes(selectedInvestor)
      );
    }
    setPermissionsByInvestorList(updatedList);
    if(!isContactChanged) {
      setIsContactChanged(true);
    }
  };

  const investorOptionCheck = (selectedInvestor: string, checked: boolean) => {
    selectedInvestor === "All"
      ? handleSelectAll(checked)
      : handleSingleSelect(selectedInvestor, checked);
    if(!isContactChanged) {
      setIsContactChanged(true);
    }
  };

  const handleUpdateHeader = (field: string) => {
    if (!headerFields || headerFields?.length === 0) {
      return;
    }

    const activeFields = headerFields.filter((header) => header.checked);

    const updatedHeaders: Array<DataGridHeaderItem> = headerFields.map(
      (header) => {
        if (header.field === field) {
          if (!(header.checked && activeFields.length <= 1))
            header.checked = !header.checked;
        }
        return header;
      }
    );

    if (updatedHeaders && updatedHeaders !== undefined) {
      setHeaderFields(updatedHeaders);
      const activeHeaders = headerFields.filter((header) => header.checked);

      setActiveHeaderFields(activeHeaders.length - 1);
    }


    if(readonly) return;

    const updatedHeaderFields = updatedHeaders.map(item => ({
      code: item.field,
      label: item.label,
      visible: item.checked,
      order: item.index
    } as ViewItemsEntity));

    const columnOrderToSave: ColumnOrder = {
      clientId: clientId,
      viewKey: CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY,
      viewItems: updatedHeaderFields
    };

    saveColumnOrder(columnOrderToSave);
  };

  useEffect(() => {
    if (!headerFields || headerFields.length === 0) {
      return;
    }

    const columns: GridColumns = [];
    const headerList: DataGridColDef[] = [];

    headerFields.forEach((header) => {
      columns.push({
        field: header.field,
        headerName: header.label,
        width: 70,
        flex: 1,
        hide: !header.checked,
        renderCell: (params) => {
          return childCheckbox(
            params.row[header.field],
            header.field,
            params.row.id,
            params.row.isLocked
          );
        },
        renderHeader: (params) => {
          return columnHeader(
            header.label.replace("/", "/ "),
            header.field,
            true
          );
        },
      });

      headerList.push({
        index: 0,
        field: header.field,
        inlineFilterName: header.label,
        headerName: header.label,
        hide: !header.checked,
      });
    });

    columns.push({
      field: "action",
      headerName: "",
      width: 150,
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      hide: false,
      renderHeader: (params) => {
        return headerActions(headerList);
      },
    });

    setGridColumns(columns);

    const options = createInvestorOptionsList(
      primaryInvestors ? investorFilterList.filter(investor => primaryInvestors.has(investor.id) || investorIds.has(investor.id)) : investorFilterList,
      investorIds,
    );

    if (options.length > 0) {
      setFilteredInvestorFilterList(options);
      setOriginalFilteredInvestorFilterList(options);
    }
  }, [headerFields]);

  const onRowColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const headerList: DataGridColDef[] = [];
    let updatedHeaderFields: DataGridHeaderItem[] = [];

    headerFields.map((header, index) => {
      headerList.push({
        index: index,
        field: header.field,
        inlineFilterName: header.label,
        headerName: header.label,
        hide: !header.checked,
      });
    });

    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;
    const columnOrderToSave: ColumnOrder = updateArrayIndex(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      CONTACTS_PERMISSION_COLUMN_ORDER_VIEW_KEY
    );

    updatedHeaderFields = (
      columnOrderToSave.viewItems as ViewItemsEntity[]
    ).map((item) => ({
      index: item.order,
      field: item.code,
      label: item.label,
      checked: item.visible,
    }));

    setHeaderFields(updatedHeaderFields);
    if(readonly) return;
    saveColumnOrder(columnOrderToSave);
  };

  useEffect(() => {
    if (!roleList || roleList.length === 0) {
      return;
    }

    const headers: DataGridHeaderItem[] = [];

    //TODO: This is hardcoded approach till the backend is able to fix it on their side.
    roleList.map((role) => {
      headers.push({
        index: 0,
        field: role.id,
        label: role.name,
        checked: !["Capital Call CC", "Distributions CC", "K1 CC", "Quarterly Reports CC", "Legal CC"]?.includes(role.name),
        hide: ["Capital Call CC", "Distributions CC", "K1 CC", "Quarterly Reports CC", "Legal CC"]?.includes(role.name),
      });
    });

    if (
      contactPermissionColumnOrder &&
      contactPermissionColumnOrder.viewItems
    ) {
      contactPermissionColumnOrder.viewItems.map((item) => {
        const header = headers.find((header) => header.field === item.code);

        if (header) {
          header.index = item.order;
          header.checked = item.visible;
        }
      });

      headers.sort((a, b) =>
        (a?.index as number) > (b.index as number) ? 1 : -1
      );
    }

    setHeaderFields(headers);
  }, [roleList, permissionByInvestorList]);

  return {
    gridColumns,
    anchorEl,
    setAnchorEl,
    showAddInvestorsPopover,
    filteredInvestorFilterList,
    investorFilterText,
    setInvestorFilterText,
    handleOnSearchTextChange,
    investorOptionCheck,
    handleOnInvestorClear,
    handleUpdateHeader,
    onRowColumnOrderChange,
  };
};

const createInvestorOptionsList = (
  investorFilterList: InvestorFilter[],
  investorList: Set<string>
) => {
  return investorFilterList.map((investor) => ({
    field: investor.id,
    label: investor.name,
    checked: investorList.has(investor.id),
  }));
};
