import { Button, Divider, FormControl, FormControlLabel, Modal, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import React from "react";

import DataGrid from "../../../../../components/DataGrid/DataGrid";
import TextField from "../../../../../components/TextField/TextField";
import { AllocationsSheetManager } from "../sheets/allocations/AllocationsSheet.Manager";
import { SubmitTypes, useSubmitToPcapEffect } from "./SubmitToPcap.hooks";
import { StyledBox, StyledDatePicker, StyledDatePickerWrapper, StyledSubmitStack } from "./SubmitToPcap.styles";

type SubmitToPcapProps = {
    allocationsSheetManager: AllocationsSheetManager,
    onClose: () => void
};

export const SubmitToPcap: React.FC<SubmitToPcapProps> = ({
  allocationsSheetManager, onClose 
}) => {
  const {
    submitType, setSubmitType,
    headerList,
    gridRows, selectionModel, setSelectionModel,
    handleSubmitButton,
    transactionDate, handleTransactionDateChange, minQuarterDate, maxQuarterDate, transDateErrorText, wbYear, quarter, frequency,
    processing
  } = useSubmitToPcapEffect(allocationsSheetManager);

  return (
    <>
      <Modal
        open={true}
        onClose={() => onClose()}
        id="mdl_new_transaction"
        aria-labelledby="modal_newtranstype_title"
        aria-describedby="modal_newtranstype_description"
      >
        <StyledBox>
          <Typography id="modal_newtranstype_title" component="h2">
            Submit { frequency === 'BY_QUARTER' ? 'Q' + quarter : wbYear } Transactions to Capital Accounts
          </Typography>
          <Typography id="modal_newtranstype_description" variant='body2'>
            {/* TODO: Need verbage here to desribe whats going on */}
          </Typography>

          <Divider />

          {
            submitType === SubmitTypes.Transactions &&
              <StyledDatePickerWrapper>
                <StyledDatePicker
                  label="Transaction Date *"
                  maxDate={maxQuarterDate}
                  minDate={minQuarterDate}
                  renderInput={(params) =>
                    <TextField {...params}
                      size="small"
                      id="dtp_transaction_date"
                      error={transDateErrorText !== ''}
                      helperText={transDateErrorText}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                  }
                  onChange={value => handleTransactionDateChange(value)}
                  value={transactionDate}
                />
              </StyledDatePickerWrapper>
          }

          <DataGrid
            id="data_grid"
            dataList={gridRows}
            headerList={headerList}
            checkboxSelection={true}
            autoHeight={false}
            minHeight="300px"
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            isRowSelectable={params => {
              return submitType === SubmitTypes.Transactions ? params.row.submittable :  params.row.locked;
            }}
          />

          <FormControl>
            <StyledSubmitStack direction="row" justifyContent="start" spacing={2}>
              <RadioGroup
                row
                value={submitType}
                onChange={params => { setSubmitType(params.target.value as SubmitTypes); }}
              >
                <FormControlLabel value="transactions" control={<Radio />} label="Submit" />
                <FormControlLabel value="Unlock-transaction-types" control={<Radio />} label="Unlock and Rollback" />
              </RadioGroup>
            </StyledSubmitStack>

            <Stack direction="row" justifyContent={"end"} spacing={4} sx={{ mt: 3 }}>
              <Button
                id="btn_submit"
                variant="outlined"
                disabled={transDateErrorText!=='' || selectionModel.length === 0}
                onClick={handleSubmitButton}
              >
                { submitType === SubmitTypes.Transactions ? 'Submit' : 'Rollback' }
              </Button>
              <Button 
                id="btn_cancel_save" 
                variant="outlined" 
                disabled={processing}
                onClick={() => onClose()}>
                  Close
              </Button>
            </Stack>
          </FormControl>
        </StyledBox>
      </Modal>
    </>
  );
};
