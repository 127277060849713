export const getCurrentQuarter = (d?: Date) => {
  const currentDate = d ? d : new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const quarterNumber = Math.floor((currentMonth + 3) / 3);

  return {
    currentYear,
    quarterNumber,
    quarterWithYear: `${currentYear} Q${quarterNumber}`,
  };
};
