import { Box, styled } from "@mui/material";

export const ColorSelectorBoxMain = styled(Box)(({
  theme
}) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  textAlign: 'left'
}));

export const ColorPickerWrapper = styled(Box)(({
  theme
}) => ({
  display: "flex",
  gap: 4,
  margin: theme.spacing(2, 0)
}));

