import { useEffect, useState } from "react";

import { getNoticeUrl } from "../../../services/documents.service";
import { PortalDistributions } from "../../../utils/types/distributions.type";
type Props = {
  selectedDistribution: PortalDistributions;
};
export const useViewNotice = ({ selectedDistribution }: Props) => {
  const [pdfLink, setPdfLink] = useState<string>("");
  const getDocument = async () => {
    const res = await getNoticeUrl(selectedDistribution?.documentId || "");

    if (selectedDistribution.documentId) {
      setPdfLink(res[selectedDistribution.documentId]);
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  return {
    pdfLink,
  };
};
