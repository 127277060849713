import {
  Box,
  FormControl as MUIFormControl,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
  styled,
  Typography,
} from "@mui/material";

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "20% 15% 20% 20%",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  alignItems: "center",
}));

export const TabRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "60% 30%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "40% 60%",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const FormControl = styled(MUIFormControl)(({ theme }) => ({
  minWidth: "100%",
}));

export const Select = styled(MUISelect)`
  minwidth: "100%";
  fieldset {
    border: 0;
    padding: 0;
  }
`;

export const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  minWidth: "100%",
}));

export const TypographyBold = styled(Typography)`
  &.MuiTypography-body1 {
    font-weight: 700;
  }
`;

interface GLAccountProps {
  depth: number;
}

export const GLAccount = styled(Box, {
  shouldForwardProp: (props) => props !== "depth",
})<GLAccountProps>(({ theme, depth }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: `${depth * 30}px`,
}));

export const MUIBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#eceef3",
  padding: "15px 5px",
  minWidth: "800px",
  width: "auto",
}));

export const SelectBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridAutoFlow: "column",
  gridTemplateColumns: "15% 20% 20% 35%",
  justifyContent: "left",
  alignItems: "center",
  margin: "5px",

  [`& .MuiSelect-select`]: {
    width: "100%",
    padding: "0",
  },
  [`& .MuiAutocomplete-root`]: {
    width: "100%",
    padding: "0",
  },
  [`& .MuiFormControl-root`]: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginRight: "10px",
  },
  [`& .MuiOutlinedInput-root`]: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: "10px",
    height: "35px",
    width: "100%",
  },
  [`& .MuiTextField-root`]: {
    transform: "translateY(-0px)",
    width: "100%",
  },
  [`& .MuiTextField-root .MuiFormLabel-root`]: {
    top: "-7px",
  },
  [`& .MuiTextField-root .MuiFormLabel-root.Mui-focused`]: {
    transform: "translate(14px, 0px) scale(0.75)",
  },
  [`& .MuiTextField-root .MuiInputBase-input`]: {
    padding: "0",
  },
  [`& .MuiTextField-root .MuiInputLabel-shrink`]: {
    transform: "translate(14px, 0px) scale(0.75)",
  },
  [`&	.MuiInputLabel-formControl .MuiInputLabel-asterisk`]: {
    color: "red",
  },
  [`& .MuiButton-root`]: {
    width: "100px",
  },
  [`& .MuiButton-root, .MuiTypography-root`]: {
    margin: "0 10px",
  },
  [`& .MuiMenuItem-root`]: {
    minWidth: "100%",
  },
}));
