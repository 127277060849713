import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";

import  { ClientThemeContext } from '../../core/context/clientThemeContext';
import { PageType } from '../../utils/types/login.type';
import {
  ActionButton,
  ContentDiv
} from "./Login.styles";

const Thankyou = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
}) => {

  const {
    clientTheme
  } = useContext(ClientThemeContext);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      rowSpacing={1}
      direction="row"
    >
      <Grid container justifyContent="center">
        <ContentDiv>
          <Box my={2}>
            <Typography variant="h1" style={{ color: "white" }}>
              Thank You!
            </Typography>
          </Box>
          <Box my={2}>
              Please check your email for your unique
              link to reset your password.
          </Box>
          <ActionButton
            type="submit"
            variant="contained"
            className="btn"
            disableElevation
            onClick={() => {
              setCurrentPage("LoginScreen");
            }}
            textcolor={clientTheme?.loginButtonTextColor}
            btnbackgroundcolor={clientTheme?.loginButtonBackgroundColor}
            fullWidth
          >
            Return to Login
          </ActionButton>
        </ContentDiv>
      </Grid>
    </Grid>
  );
};

export default Thankyou;
