import { Backdrop, Fade } from "@mui/material";
import { ReactElement } from "react";

import {
  ProgressText,
  StyledBox,
  StyledCircularProgress,
} from "./ProgressPanel.styles";

type Props = {
  id: string;
  showProgress: boolean;
  text?: string;
};

const FullScreenModal = (props: Props): ReactElement => {
  const {
    id, showProgress, text
  } = props;

  return (
    <Fade in={showProgress} timeout={500}>
      <StyledBox id={id}>
        <StyledCircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          thickness={4}
        />
        <ProgressText variant="h5">{text}</ProgressText>
      </StyledBox>
    </Fade>
  );
};

export default FullScreenModal;
