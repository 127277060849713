import { Container, Grid, styled } from "@mui/material";

export const MainStyledContainer = styled(Container)`
    height: 325px;
    width: 500px;
`;

export const MappingGridStyle = styled(Grid)`
    margin-top: 20px;
    margin-left: -25px;
    height: 225px;
    width: 500px;
    overflow-x: auto;
`;