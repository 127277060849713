import saveAs from "file-saver";
import _ from "lodash";

export function fileNameValidator(name: string): boolean {
  try {
    const splitName = name.split(" - ");

    if (splitName.length < 4) return false;

    for (let i = 0; i < splitName.length; i++) {
      const item = splitName[i].trim();

      if (!item) {
        return false;
      }
      else if (i === 2) {
        const quarterYear = item.split("Q");
        const year = parseInt(quarterYear[0].trim());
        const quarter = parseInt(quarterYear[1].trim());

        if (isNaN(year) || isNaN(quarter)) {
          return false;
        }

        if ((year < 2000 || year > 2099) || (quarter < 1 || quarter > 4)) {
          return false;
        }
      }
    }
    return true;
  }
  catch (err) {
    return false;
  }
}

export function fileExtension(fileName: string): string {
  return fileName?.split('.')?.pop()?.toLowerCase() || "";
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */

export function humanReadableFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * Remove protocol information from url
 *
 * @param url URL string
 *
 * @return URL without protocol
 */
export function protocolRemover(url: string): string {
  return url.replace(/(^\w+:|^)\/\//, '');
}

/**
 * Get hostname from url
 *
 * @param url URL string
 *
 * @return hostname
 */

export function getHostname(url: string): string {
  const baseUrl = protocolRemover(url);
  const splitted = baseUrl.split(".");

  return splitted.slice(splitted.length - 2).join(".");
}

export function removeProtocolAndW3(url: string): string {
  return url.replace(/(http(?:s)?:\/\/)?(www\.)?/i, "");
}

export function getSubdomainAndDomain(url: string) {
  const baseUrl = removeProtocolAndW3(url);
  const splitted = baseUrl.split(".");

  return {
    subdomain: splitted?.[0] || "",
    domain: splitted?.slice(1)?.join(".") || "",
  };
}

export function fetchBeforeSave(url: string, filename?: string, disableFetch?: boolean, errCb?: Function) {
  if (disableFetch === true) {
    return saveAs(url, filename);
  }
  fetch(url)
    .then(res => res.blob())
    .then((blob) => {
      saveAs(blob, filename);
    })
    .catch(err => errCb instanceof Function ? errCb(err) : {});
}

export function isArrayEqual(x: Array<any>, y: Array<any>) {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
}