import React from 'react';
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

import ColorSelector from "../../../../components/ColorSelector/ColorSelector";
import LoginSkeleton from "../../../../components/Skeleton/LoginSkeleton/LoginSkeleton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { Client, Theme } from "../../../../utils/types/client.type";
import {
  ColorSelectorContainer,
} from "../ClientDetails.style";

type Props = {
  control: Control<Client, any>;
  theme?: Theme;
}

const LoginThemeConfig = ({
  control
}: Props) => {
  return (
    <TitleBox title="Login Page Colors">
      <Controller
        name="theme.loginColorHex1"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="login_color_1" value={value} title="Color 1" subTitle="Background" onChange={onChange} />
              <LoginSkeleton loginBackground={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
      <Controller
        name="theme.loginColorHex2"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="login_color_2" value={value} title="Color 2" subTitle="Button" onChange={onChange} />
              <LoginSkeleton loginButtonBackground={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
      <Controller
        name="theme.loginColorHex3"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="login_color_3" value={value} title="Color 3" subTitle="Body Background" onChange={onChange} />
              <LoginSkeleton loginBodyBackground={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
    </TitleBox>
  );
};

export default LoginThemeConfig;
