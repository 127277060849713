import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";

import useInvestorFilter from "./useInvestorFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (data: any | null | string) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  fundId?: string;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  isShrink?: boolean;
  nameSelectOnly?: boolean;
  isPlaceHolder?: boolean;
};

type InvestorValue = any | null | undefined;

const InvestorFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  fundId,
  size = "small",
  id = "investor_filter",
  textFieldID = "investor_filter_text_field",
  isShrink = false,
  nameSelectOnly = false,
  isPlaceHolder = true,
}: Props) => {
  const { investorsList, loading } = useInvestorFilter({
    fundId,
  });
  const onSelectChange = (_: any, investor: any) => {
    if (!investor) {
      return;
    }
    onChange?.(
      idSelectOnly || nameSelectOnly
        ? investor?.[idSelectOnly ? "id" : "name"] ?? ""
        : investor
    );
  };
  const selectedValue: InvestorValue =
    idSelectOnly || nameSelectOnly
      ? investorsList?.find(
          (item: any) => item[idSelectOnly ? "id" : "name"] === value
        ) ?? null
      : (value as InvestorValue);

  return (
    <Autocomplete
      options={investorsList || []}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.name === selected?.name
      }
      onChange={onSelectChange}
      id={id}
      value={selectedValue ?? {}}
      disabled={disabled || loading}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={isPlaceHolder ? placeholder : ""}
          variant="outlined"
          label={placeholder}
          error={!!error}
          id={textFieldID}
          helperText={error?.message ?? ""}
          InputLabelProps={
            (isShrink && {
              shrink: true,
            }) ||
            {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(InvestorFilter);
