import { DeleteOutlineOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize
} from "@mui/material";
import React from 'react';
import { Controller } from 'react-hook-form';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from "../../../components/Buttons/Button/Button";
import ButtonWithOptions from '../../../components/ButtonWithOptions/ButtonWithOptions';
import DeleteConfirmationDialog from '../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import Progress from '../../../components/Progress/ProgressModal/ProgressModal';
import { MarginFixContainer, TemplateTitleBox } from '../Clients.styles';
import GeneralReportHeaderLayout from './GeneralReportHeader/GeneralReportHeader';
import  {
  ButtonContainer,
  Container,
  FullWidthFormControl,
  MainContainer,
  ReportConfigContainer,
  SelectTemplateContainer,
  StyledButton
} from "./ReportConfig.style";
import ReportConfiguration from './ReportConfiguration/ReportConfiguration';
import SummaryConfiguration from './SummaryConfiguration/SummaryConfiguration';
import { useReportConfig } from './useReportConfig.hooks';

const ReportConfig: React.FC = () => {
  const {
    templeId,
    TemplateOptions,
    handleNewButtonAction,
    deleteAction,
    setDeleteAction,
    clearFormData,
    getReportConfig,
    deleteReportTemplate,
    reportForm,
    headerElementControls,
    summaryElementsControls,
    reportElementsControls,
    addHeaderElement,
    removeHeaderElement,
    addSummary,
    removeSummary,
    addReportElement,
    removeReportElement,
    loading,
    fundNameList,
    reportTempleteList,
    transactionBuckets,
    handleOnSave,
    handleOnClose,
    isDirty
  } = useReportConfig();

  return (
    <ReportConfigContainer>
      <Progress id="report_config_loading" showProgress={loading} />
      <form onSubmit={reportForm.handleSubmit(handleOnSave)}>
        <Container>
          <Box>
            <TemplateTitleBox variant="h4">
              Select Template
            </TemplateTitleBox>
          </Box>
          <SelectTemplateContainer>
            <FormControl size="small">
              <InputLabel id="report_config_setting">Select</InputLabel>
              <Select labelId="report_config_setting"
                label="Select"
                value={templeId}
                onChange={(e: SelectChangeEvent) => getReportConfig(e.target.value as string)}
              >
                <MenuItem value={''} >
                    Create New Template
                </MenuItem>
                {reportTempleteList?.map((res: any, index: number) => (
                  <MenuItem
                    key={`temple_select_${index}`}
                    value={res.id}
                  >
                    {res.name}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
            <ButtonContainer>
              <ButtonWithOptions
                buttonID="add_button"
                popoverID="add_popover"
                onClick={handleNewButtonAction}
                buttonLabel="Create New Template"
                buttonIcon={<AddIcon />}
                options={TemplateOptions}
              />
              {/* <StyledButton variant="contained" onClick={() => clearFormData()} size="small" startIcon={<AddIcon/>}>
                Add New Template
              </StyledButton> */}
              {templeId.length > 0 &&
                <StyledButton variant="outlined" onClick={() => setDeleteAction(true)} size="small" startIcon={<DeleteOutlineOutlined color="primary" />}>
                  Delete Selected Template
                </StyledButton>
              }
            </ButtonContainer>
          </SelectTemplateContainer>
        </Container>
        <GeneralReportHeaderLayout
          reportForm={reportForm}
          control={headerElementControls}
          addHeaderElement={addHeaderElement}
          removeHeaderElement={removeHeaderElement}
          fundNameList={fundNameList}
          isDirty={isDirty}
        />
        <SummaryConfiguration
          reportForm={reportForm}
          control={summaryElementsControls}
          removeSummary={removeSummary}
          addSummary={addSummary}
          transactionBuckets={transactionBuckets}
        />
        <ReportConfiguration
          reportForm={reportForm}
          control={reportElementsControls}
          addReportElement={addReportElement}
          removeReportElement={removeReportElement}
        />
        <Container>
          <Box>
            <TemplateTitleBox variant="h4">
              Report Footer
            </TemplateTitleBox>
          </Box>
          <MainContainer>
            <FullWidthFormControl size="medium">
              <Controller
                name={`footer`}
                render={({
                  field: {
                    onChange,value
                  },
                }) => (
                  <TextareaAutosize minRows={4} value={value} onChange={onChange}/>
                )}
                control={reportForm.control}
              />
            </FullWidthFormControl>
          </MainContainer>
        </Container>
        <MarginFixContainer>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            sx={{
              px: '30%'
            }}
          >
            <Button
              id={"btn_investor_detail_panel_cancel_report_configs"}
              variant="outlined"
              onClick={handleOnClose}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={"btn_investor_detail_panel_save_report_config"}
              variant="contained"
              type={"submit"}
              text={"Save & Continue to Consent Config"}
              color={"primary"}
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </MarginFixContainer>
        <DeleteConfirmationDialog
          open={deleteAction}
          id="delete_confirmation_modal"
          primaryButtonAction={deleteReportTemplate}
          secondaryButtonAction={() => setDeleteAction(false)}
          content="This action is permanent and cannot be undone"
        />
      </form>
    </ReportConfigContainer>
  );
};

export default React.memo(ReportConfig);
