import { styled, Switch } from "@mui/material";

import {
  SWITCH_DISABLED,
  SWITCH_DISABLED_BACKGROUD,
  SWITCH_ENABLED,
  SWITCH_ENABLED_BACKGROUD,
} from "../../styles/colors";

export const StyledSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    color: ${SWITCH_DISABLED};
  }

  .MuiSwitch-track {
    background-color: ${SWITCH_DISABLED_BACKGROUD};
  }

  .Mui-checked {
    .MuiSwitch-thumb {
      color: ${SWITCH_ENABLED};
    }
  }

  .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: ${SWITCH_ENABLED_BACKGROUD};
  }
`;
