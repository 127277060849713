import { Investor, InvestorDetail, InvestorFilter, InvestorsNames } from "../utils/types/investor.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => ApiClient.Client(ClientType.NotCached, ServiceType.Api);

export const getInvestorList = async () => await apiClient().get<Investor[]>("investors");

export const getInvestor = async (investorId: string) => await apiClient().get<InvestorDetail>(`investors/${investorId}`);

export const getInvestorsByStatus = async (status: string): Promise<Investor[]> => {
  const investors: Investor[] = await apiClient().get<Investor[]>(
    `investors/${status}`
  );

  return investors;
};

export const updateInvestorStatus = async (
  investorIds: string[],
  status: string
) => {
  const body = {
    ids: investorIds,
    status: status
  };

  return await apiClient().patch(
    "investors/statuses",
    body
  );
};

export const deleteInvestors = async (investorIds: string[]) => await apiClient().delete(`investors?investorIds=${investorIds.toString()}`, {});

export const downloadFileInvestor = async (
  templateType: string[],
  fundList: string[],
  type: string,
  clientId: string = "",
  contactStatuses: string[] = [],
  investorIdList: string[] = [],
  roleIdList: string[] = []
) => {
  let body = Object.assign({});

  body = {
    transactionCode: "",
    clientId: clientId,
    fundId: "",
    investorId: "",
    type: type,
    fundIds: fundList,
    ids: templateType,
    fundWithInvestorIds: null,
    quarter: null,
    username: null,
  };

  if (type === "CONTACT") {
    body.investorIds = investorIdList,
      body.contactStatuses = contactStatuses,
      body.roleIds = roleIdList;
  }

  const templateFile = await apiClient().post(`downloads/`, body);

  return templateFile;
};

export const saveInvestor = async (investor: Investor | InvestorDetail) => await apiClient().post("investors", investor);

export const checkInvestorNameAvailability = async (name: string) => await apiClient().get(`investors/availability?name=${encodeURIComponent(name)}`);

export const getInvestorFilters = async () => await apiClient().get<InvestorFilter[]>("investors/filters");

export const approvePendingChanges = async (pendingWireInfoId: string, indestorId: string,) => await apiClient().patch(`wireInfos/pending/${pendingWireInfoId}?investorId=${indestorId}`, {});

export const declinePendingChanges = async (investorId: string) => await apiClient().post(`investors/decline`, { investorId: investorId });

export const getInvestorsNames = async (fundId: string) => await apiClient().get<InvestorsNames[]>(`investors/names?fundId=${fundId}`);

export const getAvailableFunds = async () => await apiClient().get<any>(`funds/options`);