import { Box, styled } from "@mui/material";

export const HeaderRow = styled(Box)(({ theme }) => ({
    display: "flex",
    gridTemplateColumns: "20% 15% 20% 20%",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    alignItems: "center",
  }));

export const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
  }));

export const ButtonBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
  }));