import { createContext } from 'react';

import noop from '../../../utils/helpers/noop';
import { DocumentsContext } from '../types';

const defaultContextValue: DocumentsContext = {
  state: null,
  changeFolder: noop,
  fetchDocuments: noop,
  fetchDocumentsFilters: noop,
  fetchFolderContent: noop,
  initialize: noop,
  resetFiltersSelection: noop,
  toggleFileStatus: noop,
  toggleDocControlPermissions: noop,
  toggleFileArchiveSettings: noop,
  togglePageLock: noop,
  toggleWaterMark: noop,
  updateRowsSelection: noop,
  fetchPermissions: noop,
  createFolder: noop,
  downloadFiles: noop,
  deleteFolder: noop,
  updateFolder: noop,
  applyFilters: noop,
  updateFiles: noop,
  resetOnChange: noop,
  resetSelectedFiles: noop,
  deleteFiles: noop,
  deleteFilesAndFolders: noop,
  moveFiles: noop,
  publishFiles: noop,
  downloadDocLinks: noop,
  changeFolderIndex: noop,
  fetchPublishedContent: noop,
  openDocument: noop,
  fetchDocumentTree: noop,
  consentAction: noop,
  fetchConsent: noop,
  applyPermissionFilter: noop,
  applyNameFilter: noop,
  toggleFolders: noop,
  toggleArchived: noop,
  removeLoading: noop,
};

const Context = createContext(defaultContextValue);

export default Context;
