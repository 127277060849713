import IconDownload from "../../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../../assets/images/icons/icon_upload.svg";
import { ButtonOptions } from "../../../components/ButtonWithOptions/ButtonWithOptions";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import { Transaction } from "../../../utils/types/transaction.type";

export const EXPORT_TRANSACTION_ERROR = "Error in exporting transactions.";
export const DELETE_TRANSACTION_ERROR = "Error in deleting transactions.";
export const DELETE_TRANSACTION_SUCCESS = "Transactions deleted successfully.";
export const DOWNLOAD_TRANSACTION_ERROR = "Error in downloading transactions.";
export const CREATE_TRANSACTION_ERROR = "Error in creating transaction.";
export const CREATE_TRANSACTION_SUCCESS = "Transaction created successfully.";
export const UPDATE_TRANSACTION_ERROR = "Error in updating transaction.";
export const UPDATE_TRANSACTION_SUCCESS = "Transaction updated successfully.";
export const LOCKED_PAGE_TOOLTIP_TEXT = "The page is locked";
export const TransactionOptions: ButtonOptions[] = [{
  label: 'New Transaction',
  id: AddNewButtonOptions.AddNew,
  icon: IconNewCircle,
},
{
  label: 'Upload From Template',
  id: AddNewButtonOptions.UploadFromTemplate,
  icon: IconUpload,
  type: 'upload'
},
{
  label: 'Download Template',
  id: AddNewButtonOptions.DownloadTemplate,
  icon: IconDownload,
}];

export const PORTAL_MAPPING=[{
  id: "Distribution",
  name: "Distribution"
}, {
  id: "CapitalCall",
  name: "CapitalCall"
}];

export enum DELETE_ACTION_TYPE {
  DELETE_ALL = "DELETE_ALL",
  DELETE_SELECTED = "DELETE_SELECTED"
}

export const TRANSACTION_FORM_DEFAULT_VALUE: Transaction = {
  id: '',
  name: '',
  investor: {
    id: '',
    name: ''
  },
  fund: {
    id: '',
    name: ''
  },
  txnType: {
    id: '',
    name: ''
  },
  date: new Date(),
  currencyCode: '',
  amount: 0,
  reviewable: {
    id: '',
    name: ''
  },
  reviewableStatus: {
    id: '',
    name: ''
  },
  hasReviews: false,
  reviewableType: {
    id: '',
    name: ''
  },
  useMetric: false,
  metricFractionDigit: 0,
  metricSign: ''
};
