import { DateTimeFormat } from "../../../../../../../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../../../../../../../utils/helpers/quarter.helper";
import { InvestorRowType } from "../../../../workbook.type";
import { Alignment, CellFormats, ColWidth, Edge, SpreadsheetGrid } from "../../../SpreadsheetGrid";
import { colsAddResponse,ICellEditHandling } from "../../ICellEditHandling";
import { WorkbookSheetsManager } from "../../WorkbookSheets.Manager";
import { InvestorRowManager } from "../InvestorRows.Manager";
import { CommitmentColumnsManager } from "./CommitmentColumns.Manager";
import { InvestorColumnsManager } from "./InvestorColumns.Manager";

export abstract class BaseColumnsManager implements ICellEditHandling{
    private _grid!: SpreadsheetGrid;
    private _investorRowManager!: InvestorRowManager;
    private _workbookSheetsManager!: WorkbookSheetsManager;
    private _investorColumnsManager!: InvestorColumnsManager;
    private _commitmentColumnsManager!: CommitmentColumnsManager;

    protected get grid() {
        return this._grid;
    }

    protected get workbookSheetsManager() {
        return this._workbookSheetsManager;
    }

    protected get investorRowManager() {
        return this._investorRowManager;
    }

    protected get investorColumnsManager() {
        return this._investorColumnsManager;
    }

    protected get commitmentColumnsManager() {
        return this._commitmentColumnsManager;
    }

    public SetDependencies(grid: SpreadsheetGrid, workbookSheetsManager: WorkbookSheetsManager): void {
        this._grid = grid;
        this._workbookSheetsManager = workbookSheetsManager;
        this._investorRowManager = workbookSheetsManager.allocationsSheetmanager.investorRowManager;
    }

    protected setQuarterHeaderStyle(quarterStartCol: string, quarterLastCol: string, label: string) {
        const grid = this._grid;
        const quarterHeaderRowId = this._investorRowManager.quarterHeaderRowId;

        grid.setCellValue(quarterHeaderRowId, quarterStartCol, label, CellFormats.Text_Unformatted);
        grid.setSpanRange(quarterHeaderRowId, quarterStartCol, quarterHeaderRowId, quarterLastCol);
        grid.setCellAlign(quarterHeaderRowId, quarterStartCol, Alignment.Center);
    }

    protected setColumnStyle(colId: string, colCaption: string, colWidth: ColWidth): void {
        const grid = this._grid;
        const headerRowId = this._investorRowManager.mainHeaderRowId;

        grid.setCellValue(headerRowId, colId, colCaption, CellFormats.Text_Unformatted);
        grid.setColWidth(colId, colWidth);
        grid.setCellAlign(headerRowId, colId, Alignment.Center);
        grid.setCellWordWrap(headerRowId, colId, true);
        grid.setCellBold(headerRowId, colId, true);
        grid.setCellBorder(headerRowId, colId, Edge.Bottom, 2);
    }

    protected getLongDateForQuarter(year: number, quarterNumber?: number) {
        if(quarterNumber) {
            const startEndDates = getQuarterStartEndDates(quarterNumber, year);
            const dateStr = DateTimeFormat.longDate(startEndDates.endDate);

            return dateStr;
        } else {
            return year.toString();
        }
    }

    protected setInvestorTypeTotalRowStyling(rowId: string, colId: string, investorType: InvestorRowType ) {
        if(investorType === InvestorRowType.TypeTotal) {
            this.grid.setCellBorder(rowId, colId, Edge.Top, 2);
            this.grid.setCellBorder(rowId, colId, Edge.Bottom, 2);
        } else if(investorType === InvestorRowType.PartnerTotal) {
            this.grid.setCellBorder(rowId, colId, Edge.Top, 3);
            this.grid.setCellBorder(rowId, colId, Edge.Bottom, 3);
        }
    }

    abstract get startColumnId(): string;
    abstract get endColumnId(): string;
    abstract renderColumns(gridRowIds?: string[], gridColIds?: string[]): void;
    abstract containsColumn(colId: string):boolean;

    //  ICellEditHandling 
    abstract onStartEdit(rowID: string, colId: string): boolean;
    abstract onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean;
    abstract onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse;
    abstract onRowAdd(parentRowId: string): boolean;
    abstract onCanColDelete(colId: string): boolean;
    abstract onCanRowDelete(rowID: string): boolean;
    abstract onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean;
}