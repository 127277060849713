import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  GridAlignment,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridFilterModel,
  GridSelectionModel,
  MuiEvent,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';

import { StringCell } from '../../../../components/DataGrid/DataGrid.styles';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  bulkCreateAccounts,
  bulkDeleteAccounts,
  bulkSaveAccounts,
  bulkUpdateAccounts,
  createAccountsFromTemplate,
  downloadAccountsTemplate,
  getArkAccounts,
  getAttributes,
  getTransAccounts,
  importAccountsTemplate,
  searchAllJournalEntries,
} from '../../../../services/arkGL.service';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../../services/columnOrder.service';
import {
  getTransactionTypes,
  postMapsForFund,
} from '../../../../services/fund.service';
import { uploadFile } from '../../../../services/uploads.service';
import { LOCKED_PAGE_TOOLTIP_TEXT } from '../../../../utils/constants/text.constants';
import {
  arrayIndexUpdate as updateArrayIndex,
  arrayVisibilityUpdate,
} from '../../../../utils/helpers/columnOrder.helper';
import downloadFile from '../../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import {
  AccountStatusTypes,
  ArkAccountAddNewButtonOptions,
  ArkGLAccount,
  SelectedAccount,
} from '../../../../utils/types/arkGLAccount.type';
import { ColumnOrder } from '../../../../utils/types/columnOrder';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import { TransType } from '../../../../utils/types/fund.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../../utils/types/listItems';
import { ScopeRole } from '../../../../utils/types/user.type';
import { GET_JOURNAL_ENTRY_LIST_ERROR } from '../../journalEntries/journalEntryList/JournalEntryList.constants';
import {
  ARKGL_ACCOUNTS_COLUMN_ORDER_VIEW_KEY,
  BULK_DELETE_ACCOUNTS_ERROR,
  BULK_DELETE_ACCOUNTS_SUCCESS,
  BULK_MAP_ERROR,
  BULK_MAP_SUCCESS,
  BULK_MAP_VALIDATION_ERROR,
  BulkFieldOptions,
  DOWNLOAD_TEMPLATE_ERROR,
  GET_GL_ACCOUNT_LIST_ERROR,
  IMPORT_TEMPLATE_ERROR,
  IMPORT_TEMPLATE_SUCCESS,
  SAVE_ACCOUNTS_ERROR,
  SAVE_ACCOUNTS_SUCCESS,
  UPLOAD_ACCOUNT_ERROR,
  UPLOAD_ACCOUNT_SUCCESS,
} from './AccountList.constants';
import { csvHeaders, NewAccountOptions } from './AccountList.constants';
import { defaultHeaderList } from './AccountList.defaultHeaders';

enum AccountFilter {
  Transaction = 'transactionTypeName',
  Attribute = 'attributeAccountType',
  FSMapping = 'fsMappingName',
  FSDisplayName = 'fsMappingDisplayName',
  AccountStatus = 'accountStatus',
}

const initialAccount: SelectedAccount = {
  account: undefined,
  type: undefined,
};

type Mapping = Record<AccountStatusTypes, string>;

const StatusType: Mapping = {
  draft: 'yellow',
  posted: 'green',
  unused: 'red',
};

const taxableAccountOptions = [
  { id: 'Y', name: 'Yes' },
  { id: 'N', name: 'No' },
];
const entityRequiredOptions = [
  { id: 'Y', name: 'Yes' },
  { id: 'N', name: 'No' },
];

export const useAccountList = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const arkClientTag = state.loginUser.arkClientTag;

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const readonly: boolean = !!isFundAdmin;
  const isStandishManagementBasicAdmin: boolean = Boolean(
    isBasicAdmin && arkClientTag === 'Standish Management'
  );

  const filterModelUnmappedOnly = {
    items: [
      {
        columnField: 'transactionTypeId',
        operatorValue: 'isEmpty',
        value: '',
      },
    ],
  };
  const filterModelAll = { items: [] };

  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [accountColumnOrder, setAccountColumnOrder] =
    useState<ColumnOrder | null>(null);
  const [accountsResponse, setAccountsResponse] = useState<any>([]);
  const [accountList, setAccountList] = useState<ArkGLAccount[]>([]);
  const [parentAccountList, setParentAccountList] = useState<ArkGLAccount[]>(
    []
  );
  const [accountFilteredList, setAccountFilteredList] = useState<
    ArkGLAccount[]
  >([]);
  const [accountSelectionModel, setAccountSelectionModel] = useState<string[]>(
    []
  );
  const [bulkSelectionModel, setBulkSelectionModel] =
    useState<GridSelectionModel>([]);
  const [bulkMappingField, setBulkMappingField] = useState<string>('');
  const [bulkMappingValue, setBulkMappingValue] = useState<string>('');
  const [bulkMappingValueId, setBulkMappingValueId] = useState<string>('');
  const [bulkMappingFSName, setBulkMappingFSName] = useState<string>('');

  const [parentAccountOptions, setParentAccountOptions] = useState<any>([]);
  const [attributeList, setAttributeList] = useState<any[]>([]);
  const [transactionList, setTransactionList] = useState<TransType[]>([]);
  const [fsMappingList, setFsMappingList] = useState<any[]>([]);
  const [fsMappingOptions, setFsMappingOptions] = useState<any[]>([]);
  const [fsDisplayNameList, setFsDisplayNameList] = useState<any[]>([]);
  const [accountStatusList, setAccountStatusList] = useState<any[]>([]);
  const [valueOptions, setValueOptions] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount>();
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [progress, setProgress] = useState<{
    mappedAmount: number;
    totalAmount: number;
  }>({ mappedAmount: 0, totalAmount: 0 });
  const [hasMappingAdmin, setHasMappingAdmin] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    filterModelUnmappedOnly
  );
  const [isPageLocked, setIsPageLocked] = useState(true);
  const [lockedTooltipText, setLockedTooltipText] = useState<string>('');
  const [isPreview, setIsPreview] = useState(false);
  const [templateToDownload, setTemplateToDownload] = useState('');
  const [enableBulkMap, setEnableBulkMap] = useState(false);
  const [fundName, setFundName] = useState<string>('');
  const [csvFilename, setCsvFilename] = useState<string>('');
  const [csvData, setCsvData] = useState<Record<any, any>[]>([]);
  const date = DateTimeFormat.getFormattedDate(new Date(), '_');
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [showFSNameConfirmation, setShowFSNameConfirmation] =
    useState<boolean>(false);
  const [showBulkConfirmation, setShowBulkConfirmation] =
    useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | undefined>();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<
    string | undefined
  >();
  const [deleteErrorJEAccounts, setDeleteErrorJEAccounts] =
    useState<string[]>();
  const [deleteAccountList, setDeleteAccountList] = useState<string[]>();

  const selectedTransactionList = useMemo(
    () => transactionList?.map((item) => item.id),
    [transactionList]
  );

  const selectedAttributeList = useMemo(
    () => attributeList?.map((item) => item.id),
    [attributeList]
  );

  const selectedFSMappingList = useMemo(
    () => fsMappingList?.map((item) => item.id),
    [fsMappingList]
  );

  const selectedFSDisplayNameList = useMemo(
    () => fsDisplayNameList?.map((item) => item.id),
    [fsDisplayNameList]
  );

  const selectedAccountStatusList = useMemo(
    () => accountStatusList?.map((item) => item.id),
    [accountStatusList]
  );

  const selectedParentAccountList = useMemo(
    () => parentAccountList?.map((item) => item.id),
    [parentAccountList]
  );

  useEffect(() => {
    NewAccountOptions[0].hidden = isPreview ? true : false;
  }, [isPreview]);

  const toggleIsLocked = () => {
    setIsPageLocked((prevState) => !prevState);
  };

  const showLockedTooltip = () => {
    setLockedTooltipText(LOCKED_PAGE_TOOLTIP_TEXT);

    setTimeout(() => {
      setLockedTooltipText('');
    }, 3000);
  };

  const fetchAllAccounts = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);
      const accountsResponse = await getArkAccounts(fundId);

      if (isCanceled?.()) return;

      const transAccountsResponse = await getTransAccounts(fundId);

      if (isCanceled?.()) return;

      const mergedList = accountsResponse.items.map((account: any) => {
        const transAccount = transAccountsResponse.items.find(
          (t: any) => t.accountId === account.id
        );

        return {
          ...account,
          transMappingId: transAccount?.id,
          transactionTypeId: transAccount?.transactionTypeId,
        };
      });

      setFundName(transAccountsResponse.fundName);
      setAccountsResponse(mergedList);

      setIsLoading(undefined);
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const fetchListsAndFilters = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const transactionsResponse = await getTransactionTypes(clientId);

      if (isCanceled?.()) return;

      const updatedTransactions = [...transactionsResponse];

      updatedTransactions.unshift({ id: '1', name: 'Do Not Map' });

      setTransactionList(updatedTransactions);

      const attributesResponse = await getAttributes();

      const accountAttributes = attributesResponse.items.map((attr: any) => {
        return {
          id: attr.id,
          name: `${attr.type} - ${attr.detail}`,
        };
      });

      setAttributeList(accountAttributes);

      setIsLoading(undefined);
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const columnOrderResponse = await getColumnOrder(
        ARKGL_ACCOUNTS_COLUMN_ORDER_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setAccountColumnOrder(columnOrderResponse || null);

      setIsLoading(undefined);
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, 'error');
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAllAccounts(isCanceled);
    await fetchColumnOrder(isCanceled);
    await fetchListsAndFilters(isCanceled);
  }, []);

  useEffect(() => {
    if (
      accountList &&
      transactionList &&
      fsMappingList &&
      fsDisplayNameList &&
      attributeList &&
      accountStatusList &&
      parentAccountList
    ) {
      initializeHeaderList();
      // createBulkMapOptions();
    }
  }, [
    accountList,
    transactionList,
    fsMappingList,
    fsDisplayNameList,
    attributeList,
    accountColumnOrder,
    accountStatusList,
    parentAccountList,
  ]);

  useEffect(() => {
    calculateProgress();
    initializeSearchOptions(accountList);
  }, [accountList]);

  useEffect(() => {
    if (accountsResponse) {
      const processedAccountList = prepRowsForDataGrid(accountsResponse);

      const fsMappings = accountsResponse.map((account: any) => {
        return {
          id: isPreview ? account.number : account.id,
          name: account.name,
        };
      });

      fsMappings.unshift({ id: '1', name: 'Do Not Map' });

      const fsDisplayNames = Array.from(
        new Set(
          accountsResponse.map((account: any) => {
            return {
              id: isPreview ? account.number : account.id,
              name: account.fsDisplayName,
            };
          })
        )
      ).filter((mapping: any) => mapping.name);

      const accountStatus = Array.from(
        new Set(accountsResponse.map((acc: any) => acc.state))
      ).map((state) => ({ id: state, name: state }));

      const parentAccounts = accountsResponse.map((account: any) => {
        return {
          id: isPreview ? account.number : account.id,
          name: account.number + ' - ' + account.name,
          parentId: account.parentId,
        };
      });

      parentAccounts.unshift({ id: '1', name: 'Do Not Map' });

      const fsOptionsMap = new Map();

      accountsResponse.map((account: any) => {
        if (account.fsMappingId && account.fsDisplayName) {
          fsOptionsMap.set(account.fsMappingId, account.fsDisplayName);
        }
      });

      const fsOptions = accountsResponse.map((account: any) => {
        return {
          id: isPreview ? account.number : account.id,
          name: account.name,
          fsDisplayName: fsOptionsMap.get(account.id)
            ? fsOptionsMap.get(account.id)
            : account.name,
        };
      });

      fsOptions.unshift({ id: '1', name: 'Do Not Map' });

      setAccountList(processedAccountList);
      setFsMappingList(fsMappings);
      setFsDisplayNameList(fsDisplayNames);
      setAccountStatusList(accountStatus);
      setParentAccountList(parentAccounts);
      setFsMappingOptions(fsOptions);
    }
  }, [accountsResponse]);

  useEffect(() => {
    if (csvData.length) csvLinkRef?.current?.link.click();
  }, [csvData]);

  const prepRowsForDataGrid = (accounts: ArkGLAccount[]) => {
    const rows = accounts.map((account) => {
      const f: any = (current: any) => {
        if (current.parentId === null) {
          return [current.name];
        }
        if (isPreview) {
          return [
            current.name,
            ...f(
              accounts.find(
                (acc) => acc.number!.toString() === current.parentId
              )
            ),
          ];
        } else {
          return [
            current.name,
            ...f(accounts.find((acc) => acc.id === current.parentId)),
          ];
        }
      };

      return {
        ...account,
        id: isPreview ? account.number!.toString() : account.id,
        path: f(account).reverse(),
      };
    });

    return rows;
  };

  useEffect(() => {
    if (search === '') {
      setAccountFilteredList(accountList);
    } else {
      const escapeRegex = (string: string) => {
        return string.replace(/(?=\W)/g, '\\');
      };

      const searchText = new RegExp(escapeRegex(search), 'ig');

      setAccountFilteredList(
        accountList.filter((account) => {
          const accountAttribute = attributeList.find(
            (attr) => attr.id === account.attributeId
          );
          const accountFsMapping = fsMappingList.find(
            (fsMapping) => fsMapping.id === account.fsMappingId
          );
          const accountFsDisplayName = fsMappingList.find(
            (fsMapping) => fsMapping.id === account.fsMappingId
          );
          const accountStatus = accountStatusList.find(
            (accStatus) => accStatus.name === account.state
          );

          return (
            account.name.match(searchText) ||
            account.number?.toString().match(searchText) ||
            account.transactionTypeName?.match(searchText) ||
            accountFsDisplayName?.name.match(searchText) ||
            accountAttribute?.name.match(searchText) ||
            accountFsMapping?.name.match(searchText) ||
            accountStatus?.name.match(searchText)
          );
        })
      );
    }
  }, [search]);

  const calculateProgress = () => {
    if (accountList) {
      const mappedAmount = accountList.filter(
        (item) => item.transactionTypeId
      ).length;
      const totalAmount = accountList.length;

      setProgress({ mappedAmount, totalAmount });
      if (mappedAmount === totalAmount) {
        setTab(1);
        setFilterModel(filterModelAll);
      }
    }
  };

  const initializeSearchOptions = (response: any[]) => {
    const accountNumberListOptions = Array.from(
      new Set(response.map((account) => account.number.toString()))
    );

    const transactionListOptions = transactionList.map(
      (transaction) => transaction.name
    );
    const attributeListOptions = attributeList.map(
      (attribute) => attribute.name
    );
    const fsMappingListOptions = fsMappingList.map(
      (fsMapping) => fsMapping.name
    );
    const fsDisplayNameListOptions = fsDisplayNameList
      .filter((fsDisplayName) => fsDisplayName.name)
      .map((fsDisplayName) => fsDisplayName.name);

    const accStatusListOptions = accountStatusList.map(
      (accStatus) => accStatus.name
    );

    const options = [
      ...accountNumberListOptions,
      ...transactionListOptions,
      ...attributeListOptions,
      ...fsMappingListOptions,
      ...fsDisplayNameListOptions,
      ...accStatusListOptions,
    ];

    setSearchOptions(Array.from(new Set(options)));
  };

  useEffect(() => {
    let filteredList: ArkGLAccount[] = accountList;

    headerList?.map((header) => {
      const auxList = filteredList ?? accountList;

      switch (header.inlineFilterName) {
        case AccountFilter.Transaction:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            if (isPreview) {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  const transaction = transactionList.find(
                    (t) => t.id === selectedAccount
                  );

                  return transaction?.name === account.transactionType;
                });
              });
            } else {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  return selectedAccount === account.transactionTypeId;
                });
              });
            }
          }
          break;
        case AccountFilter.Attribute:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((account: any) => {
              return header?.inlineFilterSelected?.some((selectedAccount) => {
                return selectedAccount === account.attributeId;
              });
            });
          }
          break;
        case AccountFilter.FSMapping:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            if (isPreview) {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  const fsMapping = fsMappingList.find(
                    (f) => f.id === selectedAccount
                  );

                  return fsMapping?.name === account.fsMappingId;
                });
              });
            } else {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  return selectedAccount === account.fsMappingId;
                });
              });
            }
          }
          break;
        case AccountFilter.FSDisplayName:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            if (isPreview) {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  const fsDisplayName = fsDisplayNameList.find(
                    (f) => f.name === selectedAccount
                  );

                  return fsDisplayName?.id === account.id;
                });
              });
            } else {
              filteredList = auxList?.filter((account: any) => {
                return header?.inlineFilterSelected?.some((selectedAccount) => {
                  return selectedAccount === account.id;
                });
              });
            }
          }
          break;
        case AccountFilter.AccountStatus:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((account: any) => {
              return header?.inlineFilterSelected?.some((selectedAccount) => {
                return selectedAccount === account.state;
              });
            });
          }
          break;
      }
    });

    setAccountFilteredList(filteredList);
  }, [headerList, accountList]);

  const initializeHeaderList = () => {
    const updatedHeaders = [
      {
        field: 'number',
        headerName: 'Account Number',
        hide: false,
        index: 1,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 180,
        disableExport: true,
      },
      {
        field: 'transactionTypeId',
        headerName: 'Ark Transaction',
        hide: false,
        index: 2,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 240,
        disableExport: true,
        disableColumnMenu: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.Transaction,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: transactionList,
        inlineFilterSelected: selectedTransactionList,
        emptySelectionOnClear: false,
        renderCell: (params: any) => {
          if (isPreview) {
            const value = params.row.transactionType;

            return <StringCell>{value ? value : ''}</StringCell>;
          } else {
            const value = transactionList?.find(
              (type) => type.id === params.row.transactionTypeId
            );

            return <StringCell>{value ? value.name : ''}</StringCell>;
          }
        },
      },
      {
        field: 'attributeId',
        headerName: 'Attribute',
        hide: false,
        index: 3,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 240,
        disableExport: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.Attribute,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: attributeList,
        inlineFilterSelected: selectedAttributeList,
        emptySelectionOnClear: false,
        renderCell: (params: any) => {
          const value = attributeList?.find(
            (type) => type.id === params.row.attributeId
          );

          return <StringCell>{value ? value.name : ''}</StringCell>;
        },
      },
      {
        field: 'dateMapped',
        headerName: 'Date Mapped',
        hide: false,
        index: 4,
        type: 'date',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 160,
        disableExport: true,
      },
      {
        field: 'isTaxable',
        headerName: 'Taxable',
        hide: false,
        index: 5,
        type: 'string',
        sortable: true,
        decimalPlaces: 0,
        align: 'left' as GridAlignment,
        width: 140,
        disableExport: true,
        renderCell: (params: any) => (
          <StringCell>
            {params.row.isTaxable === false ? 'No' : 'Yes'}
          </StringCell>
        ),
      },
      {
        field: 'isEntityRequired',
        headerName: 'Entity Requirement',
        hide: false,
        index: 6,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 200,
        disableExport: true,
        renderCell: (params: any) => (
          <StringCell>
            {params.row.isEntityRequired === false ? 'No' : 'Yes'}
          </StringCell>
        ),
      },
      {
        field: 'fsMappingId',
        headerName: 'Financial Statement Mapping',
        hide: false,
        index: 7,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 280,
        disableExport: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.FSMapping,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: fsMappingList,
        inlineFilterSelected: selectedFSMappingList,
        emptySelectionOnClear: false,
        renderCell: (params: any) => {
          if (isPreview) {
            const value = params.row.fsMappingId;

            return <StringCell>{value ? value : ''}</StringCell>;
          } else {
            if (params.row.doNotMap === false && params.row.fsMappingId) {
              const value = fsMappingList?.find(
                (type) => type.id === params.row.fsMappingId
              );

              return <StringCell>{value ? value.name : ''}</StringCell>;
            } else {
              return <StringCell>Do Not Map</StringCell>;
            }
          }
        },
      },
      {
        field: 'fsDisplayName',
        headerName: 'Financial Statement Name',
        hide: false,
        index: 8,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 240,
        disableExport: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.FSDisplayName,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: fsDisplayNameList,
        inlineFilterSelected: selectedFSDisplayNameList,
        emptySelectionOnClear: false,
        renderCell: (params: any) => {
          if (isPreview) {
            const value = params.row.fsDisplayName;

            return <StringCell>{value ? value : ''}</StringCell>;
          } else {
            const value = fsDisplayNameList?.find(
              (type) => type.id === params.row.id
            );

            return <StringCell>{value ? value.name : ''}</StringCell>;
          }
        },
      },
      {
        field: 'state',
        headerName: 'Status',
        hide: false,
        index: 9,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 180,
        disableExport: true,
        inlineFilter: true,
        inlineFilterName: AccountFilter.AccountStatus,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: accountStatusList,
        inlineFilterSelected: selectedAccountStatusList,
        emptySelectionOnClear: false,
        renderCell: (params: any) => {
          if (params.row.state) {
            return (
              <StatusLabel
                color={
                  StatusType[
                    params.row.state.toLowerCase() as keyof typeof StatusType
                  ]
                }
                label={params.row.state}
              />
            );
          }
        },
      },
      {
        field: 'action',
        headerName: 'Columns',
        hide: false,
        hideable: false,
        index: 10,
        type: 'action',
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        width: 100,
      },
    ];

    let sortedHeaders: any;

    if (accountColumnOrder && accountColumnOrder.viewItems) {
      updatedHeaders.map((header) => {
        const savedHeader = accountColumnOrder.viewItems?.find(
          (item) => item.code === header.field
        );

        if (savedHeader && savedHeader?.code !== 'action') {
          header.index = savedHeader?.order;
          header.hide = !savedHeader?.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a, b) =>
        a.index > b.index ? 1 : -1
      );
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1, item2) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleFilter = (filterName: AccountFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }

        return header;
      })
    );
  };

  const handleOnView = (accountId: string, account: ArkGLAccount) => {
    if (isPageLocked && !readonly) {
      showLockedTooltip();
      return;
    }

    if (!accountId) return;

    setSelectedAccount({
      account,
      type: DetailsType.Edit,
    });
  };

  const onDetailsPanelClose = () => {
    setSelectedAccount(initialAccount);
  };

  const handleUpdateHeader = async (
    field: string,
    inlineFilterName?: AccountFilter
  ) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      if (header?.inlineFilterName === inlineFilterName) {
        let inlineFilterSelected;

        if (inlineFilterName === AccountFilter.Transaction) {
          inlineFilterSelected = selectedTransactionList;
        } else if (inlineFilterName === AccountFilter.Attribute) {
          inlineFilterSelected = selectedAttributeList;
        } else if (inlineFilterName === AccountFilter.FSMapping) {
          inlineFilterSelected = selectedFSMappingList;
        } else if (inlineFilterName === AccountFilter.FSDisplayName) {
          inlineFilterSelected = selectedFSDisplayNameList;
        } else if (inlineFilterName === AccountFilter.AccountStatus) {
          inlineFilterSelected = selectedAccountStatusList;
        }

        return {
          ...header,
          hide:
            header.field === field && !(!header.hide && activeFields.length < 1)
              ? !header.hide
              : header.hide,
          inlineFilterSelected: inlineFilterSelected,
        };
      }
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length < 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(
        updatedHeaders.sort((item1, item2) => item1.index - item2.index)
      );
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      ARKGL_ACCOUNTS_COLUMN_ORDER_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const onRowColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = updateArrayIndex(
      headerList,
      oldIndex - 2,
      newIndex - 2,
      clientId,
      ARKGL_ACCOUNTS_COLUMN_ORDER_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleNewButtonAction = async (actionId: string, event: any) => {
    if (
      (actionId === AddNewButtonOptions.AddNew ||
        actionId === AddNewButtonOptions.UploadFromTemplate) &&
      isPageLocked
    ) {
      showLockedTooltip();
      return;
    }

    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedAccount({
            account: undefined,
            type: DetailsType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case AddNewButtonOptions.DownloadTemplate:
        handleTemplateDownload();
        break;
      case ArkAccountAddNewButtonOptions.ImportBasic:
        {
          setIsLoading(LoadingStatus.Loading);

          try {
            const templateFile: any = await importAccountsTemplate(
              fundId,
              'Basic Chart Of Accounts Template'
            );

            setIsPreview(true);
            setAccountsResponse(templateFile.chartOfAccounts);

            setTemplateToDownload('');
            informationAlert(IMPORT_TEMPLATE_SUCCESS, 'success');
            setIsLoading(undefined);
          } catch (e) {
            informationAlert(IMPORT_TEMPLATE_ERROR, 'error');
            setTemplateToDownload('');
            setIsLoading(undefined);
          }
        }
        break;
      case ArkAccountAddNewButtonOptions.ImportAdvanced:
        {
          setIsLoading(LoadingStatus.Loading);

          try {
            const templateFile: any = await importAccountsTemplate(
              fundId,
              'Complex Chart Of Accounts Template'
            );

            setIsPreview(true);
            setAccountsResponse(templateFile.chartOfAccounts);

            setTemplateToDownload('');
            informationAlert(IMPORT_TEMPLATE_SUCCESS, 'success');
            setIsLoading(undefined);
          } catch (e) {
            informationAlert(IMPORT_TEMPLATE_ERROR, 'error');
            setTemplateToDownload('');
            setIsLoading(undefined);
          }
        }
        break;
    }
  };

  const handleExport = () => {
    const csvExportData = accountSelectionModel.map((selectedId) => {
      const acc = accountFilteredList.find(
        (acc) => acc.id === selectedId
      ) as any;

      return {
        number: acc?.number,
        parentAcc: acc?.path.length > 1 ? acc.path[acc.path.length - 2] : '',
        name: acc?.name,
        attribute: attributeList?.find(
          (attribute) => attribute.id === acc?.attributeId
        ).name,
        description: acc?.description,
        fsMapping: accountList.find(
          (mappedAcc) => mappedAcc.id === acc?.fsMappingId
        )?.name,
        fsName: acc?.fsDisplayName,
        arkTransaction: transactionList?.find(
          (type) => type.id === acc?.transactionTypeId
        )?.name,
        isTaxable: acc?.isTaxable ? 'Y' : 'N',
        isEntityRequired: acc?.isEntityRequired ? 'Y' : 'N',
        id: acc?.id,
      };
    });

    const fileName = fundName.replace(/[^\w\s]/gi, '').replace(/ /g, '_');

    setCsvFilename(`${fileName}_Chart_of_Accounts_${date}`);
    setCsvData(csvExportData);
  };

  const resetCsvData = () => {
    setCsvData([]);
    setCsvFilename('');
  };

  const handleDelete = async () => {
    setIsLoading(LoadingStatus.Deleting);
    let hasAccountsWithJE = false;
    let hasAccountsWithChildren = false;

    const error = await deleteAccounts(accountSelectionModel);

    if (error) {
      const children: string[] = [];

      accountSelectionModel.map((current) => {
        const acc = accountFilteredList.find(
          (acc) => acc.id === current
        ) as any;

        const f: any = (c: any) => {
          accountList
            .map((account: any) => {
              if (account.parentId === c.id) {
                f(account);
                hasAccountsWithChildren = true;
                children.push(account.id);

                return {
                  ...account,
                };
              }
            })
            .filter((item) => item);
        };

        f(acc);
      });

      const allAccounts = [...children, ...accountSelectionModel].filter(
        (v, i, a) => a.indexOf(v) === i
      );

      setDeleteAccountList(allAccounts);

      let jeAccounts: any[] = [];

      // Check for associated JEs
      try {
        const reqBody: any = {
          sort: [
            {
              name: 'state',
              descending: false,
            },
            {
              name: 'date',
              descending: true,
            },
          ],
          accountIds: allAccounts,
        };

        const response = await searchAllJournalEntries(reqBody);

        if (response.items.length > 0) {
          hasAccountsWithJE = true;

          response.items.forEach((je: any) => {
            je.lineItems.forEach((line: any) => {
              if (allAccounts.includes(line.accountId)) {
                jeAccounts.push(line.accountId);
              }
            });
          });

          if (jeAccounts.length) {
            jeAccounts = jeAccounts.filter((v, i, a) => a.indexOf(v) === i);
          }
        }
      } catch (e) {
        informationAlert(GET_JOURNAL_ENTRY_LIST_ERROR, 'error');
      }

      if (hasAccountsWithJE && hasAccountsWithChildren) {
        setDeleteError('CHILD_OR_JE');
        setDeleteErrorMessage(
          'Unable to delete. One of these accounts may have children or journal entries.'
        );
      } else if (hasAccountsWithJE && !hasAccountsWithChildren) {
        const deleteMessage =
          'Unable to delete. One or more of the selected accounts has associated journal entries. Please deselect the following accounts before attempting to bulk delete:';

        setDeleteError('JE');

        setDeleteErrorMessage(deleteMessage);

        setDeleteErrorJEAccounts(jeAccounts);
      } else if (hasAccountsWithChildren && !hasAccountsWithJE) {
        setDeleteError('CHILD');
        setDeleteErrorMessage(
          'To delete a parent account, all associated children accounts must be deleted first.'
        );
      }
    }
  };

  const deleteAccounts = async (list: string[]) => {
    try {
      await bulkDeleteAccounts(list);

      informationAlert(BULK_DELETE_ACCOUNTS_SUCCESS, 'success');
      await fetchAllAccounts();
      setIsLoading(undefined);
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        const message = error.response.data.errors;

        if (message && message.includes('POSTED account cannot be deleted')) {
          setDeleteError('POSTED');
          setDeleteErrorMessage(
            'Unable to delete. One or more of the selected accounts has posted journal entries. Please deselect posted accounts before attempting to bulk delete.'
          );
        } else if (message && message.includes('children or journal entries')) {
          return 'CHILD_OR_JE';
        }
      } else {
        informationAlert(BULK_DELETE_ACCOUNTS_ERROR, 'error');
        setIsLoading(undefined);
      }
    }
  };

  const deleteAccountsWithChildren = async () => {
    if (deleteAccountList) {
      try {
        setIsLoading(LoadingStatus.Deleting);
        await bulkDeleteAccounts(deleteAccountList);

        informationAlert(BULK_DELETE_ACCOUNTS_SUCCESS, 'success');
      } catch (error: any) {
        informationAlert(BULK_DELETE_ACCOUNTS_ERROR, 'error');
      } finally {
        setIsLoading(undefined);
        setDeleteAccountList(undefined);
        fetchAllAccounts();
      }
    }
  };

  const onDeleteConfirm = () => {
    deleteAccountsWithChildren();
    setDeleteError(undefined);
    setDeleteErrorMessage(undefined);
  };

  const onDeleteCancel = () => {
    setIsLoading(undefined);
    setDeleteError(undefined);
    setDeleteErrorMessage(undefined);
    setDeleteErrorJEAccounts(undefined);
  };

  const handleTemplateDownload = async () => {
    const cdnEndpoint: string = process.env
      .REACT_APP_CDN_ENDPOINT_URL as string;
    const fileLink = cdnEndpoint.concat('templates/COAUploadTemplate.csv');
    const filename = 'standard_chart_of_accounts_template.csv';

    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      informationAlert(DOWNLOAD_TEMPLATE_ERROR, 'error');
    }
  };

  useEffectAsync(async () => {
    if (templateToDownload) {
      await handleTemplateDownload();
    }
  }, [templateToDownload]);

  const apiRef = useGridApiRef();

  const handleBulkOptionClick = (selectedOption: string) => {
    const exportColumns = headerList
      .filter((header) => header.disableExport === false)
      .map((header) => header.field);

    switch (selectedOption) {
      case 'export':
        handleExport();
        break;
      case 'downloadChangeLog':
        //TODO Download change log
        // eslint-disable-next-line no-console
        console.log('Download change log');
        break;
      case 'delete':
        if (isStandishManagementBasicAdmin) return;
        if (isPageLocked) {
          showLockedTooltip();
          return;
        }
        handleDelete();
        break;
    }
  };

  const handleBulkSave = async () => {
    setIsSaving(true);

    const data = {
      fundId,
      chartOfAccounts: [...accountList],
    };

    try {
      await bulkSaveAccounts(data);

      const accountsResponse = await getArkAccounts(fundId);

      const transAccountsResponse = await getTransAccounts(fundId);

      const transactionsResponse = await getTransactionTypes(clientId);

      const updatedTransactions = [...transactionsResponse];

      updatedTransactions.unshift({ id: '1', name: 'Do Not Map' });

      const transMappings: any[] = data.chartOfAccounts.map((account: any) => {
        const createdAccont = accountsResponse.items.find(
          (a: any) => account.name === a.name
        );

        const transAccount = transAccountsResponse.items.find(
          (t: any) => t.accountId === createdAccont.id
        );

        const transaction = updatedTransactions.find(
          (t: any) => t.name === account.transactionType
        );

        return {
          id: transAccount.id,
          transactionTypeId: transaction?.id,
        };
      });

      const transactionData = {
        fundId: data.fundId,
        glPlatform: 'ARK_LEDGER',
        mappings: transMappings,
      };

      await postMapsForFund(transactionData);

      setIsPreview(false);
      fetchAllAccounts();
      informationAlert(SAVE_ACCOUNTS_SUCCESS, 'success');
    } catch (e) {
      informationAlert(SAVE_ACCOUNTS_ERROR, 'error');
    }

    setIsSaving(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);

    newValue === 0
      ? setFilterModel(filterModelUnmappedOnly)
      : setFilterModel(filterModelAll);
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(LoadingStatus.Uploading);
      await bulkCreateAccounts(uploadedFile, fundId);

      informationAlert(UPLOAD_ACCOUNT_SUCCESS, 'success');
      setUploadedFile(undefined);
      setIsLoading(undefined);
      await fetchAllAccounts();
    } catch (exception) {
      setUploadedFile(undefined);
      informationAlert(UPLOAD_ACCOUNT_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const clearApplyChange = () => {
    setShowBulkConfirmation(false);
    setShowFSNameConfirmation(false);
    setBulkMappingField('');
    setBulkMappingValue('');
    setBulkMappingValueId('');
  };

  const handleApplyChange = () => {
    switch (bulkMappingField) {
      case 'FS Mapping':
        if (bulkMappingValueId === '1') {
          confirmBulkMapUpdate(bulkMappingValueId);
        } else {
          setShowFSNameConfirmation(true);
        }
        break;
      case 'Ark Transaction':
        confirmBulkMapUpdate(bulkMappingValue);
        break;
      case 'Attribute':
      case 'Parent Account':
      case 'Taxable Account? (Y/N)':
      case 'Entity Required? (Y/N)':
        confirmBulkMapUpdate(bulkMappingValueId);
        break;
    }
  };

  const checkStatus = (accounts: any) => {
    const statusList: any = [];

    accounts.map((id: any) => {
      accountList.forEach((account: any) => {
        if (account.id === id) {
          statusList.push(account.state);
        }
      });
    });

    if (statusList.indexOf('POSTED') !== -1) {
      return false;
    } else {
      return true;
    }
  };

  const confirmBulkMapUpdate = async (value: any) => {
    let reqBody: any;

    if (bulkMappingField === 'FS Mapping') {
      const fsAccount = fsMappingList.find(
        (fs) => fs.name === bulkMappingValue
      );

      if (fsAccount.id === '1') {
        reqBody = {
          fundId: fundId,
          fieldValues: {
            FS_MAPPING: null,
          },
          accountIds: accountSelectionModel,
        };
      } else {
        reqBody = {
          fundId: fundId,
          fieldValues: {
            FS_MAPPING: fsAccount.id,
            FS_NAME: bulkMappingFSName === '' ? null : bulkMappingFSName,
          },
          accountIds: accountSelectionModel,
        };
      }
    } else {
      const fieldKey: any = BulkFieldOptions.filter(
        (option: any) => option.name === bulkMappingField
      );

      const inputField: any = fieldKey[0].key;

      value === '1' ? (value = null) : '';

      switch (value) {
        case 'Yes':
          value = true;
          break;
        case 'No':
          value = false;
          break;
      }

      reqBody = {
        fundId: fundId,
        fieldValues: {
          [inputField]: value,
        },
        accountIds: accountSelectionModel,
      };
    }

    const validStatus = checkStatus(accountSelectionModel);

    if (validStatus === false) {
      switch (bulkMappingField) {
        case 'Attribute':
          clearApplyChange();
          informationAlert(BULK_MAP_VALIDATION_ERROR, 'error');
          return;
        case 'Parent Account':
          clearApplyChange();
          informationAlert(BULK_MAP_VALIDATION_ERROR, 'error');
          return;
        default:
          break;
      }
    }

    try {
      clearApplyChange();
      setIsLoading(LoadingStatus.Updating);
      await bulkUpdateAccounts(reqBody);
      informationAlert(BULK_MAP_SUCCESS, 'success');
      await fetchAllAccounts();
      setIsLoading(undefined);
    } catch (e) {
      informationAlert(BULK_MAP_ERROR, 'error');
      clearApplyChange();
      setIsLoading(undefined);
    }
  };

  const createBulkMapOptions = () => {
    const parentAccountOptions: any = [];

    accountList.map((account: any) => {
      if (account.parentId === null) {
        parentAccountOptions.push({ id: account.id, name: account.name });
      }
    });

    parentAccountOptions.unshift({ id: '1', name: 'Do Not Map' });

    setParentAccountOptions(parentAccountOptions);
  };

  useEffect(() => {
    setBulkMappingValue('');
    setBulkMappingValueId('');
    setBulkMappingFSName('');

    switch (bulkMappingField) {
      case 'FS Mapping':
        setValueOptions(fsMappingOptions);
        break;
      case 'Ark Transaction':
        setValueOptions(transactionList);
        break;
      case 'Taxable Account? (Y/N)':
        setValueOptions(taxableAccountOptions);
        break;
      case 'Entity Required? (Y/N)':
        setValueOptions(entityRequiredOptions);
        break;
      case 'Attribute':
        setValueOptions(attributeList);
        break;
      case 'Parent Account':
        const filteredList = parentAccountList.filter(
          (account: any) =>
            !accountSelectionModel.includes(account.id) &&
            !accountSelectionModel.includes(account.parentId)
        );

        setValueOptions(filteredList);
        break;
    }
  }, [bulkMappingField, accountSelectionModel]);

  useEffect(() => {
    if (bulkMappingField === 'FS Mapping') {
      const fsMapping = fsMappingOptions.find(
        (fs: any) => fs.id === bulkMappingValueId
      );

      if (fsMapping && fsMapping.fsDisplayName) {
        setBulkMappingFSName(fsMapping.fsDisplayName);
      }
    }
  }, [bulkMappingValueId]);

  const getOptionLabel = (option: any) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option?.inputValue) {
      return option?.inputValue;
    }
    return option;
  };

  const filter = createFilterOptions<any>();

  const filterOptionsAutocomplete = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option: any) => inputValue === option);

    if (inputValue !== '' && !isExisting) {
      filtered.push(`${inputValue}`);
    }

    return filtered;
  };

  useEffect(() => {
    if (accountSelectionModel.length < 1) {
      setBulkMappingValue('');
      setBulkMappingField('');
    }
  }, [accountSelectionModel]);

  return {
    getOptionLabel,
    filterOptionsAutocomplete,
    isLoading,
    isSaving,
    accountFilteredList,
    accountSelectionModel,
    setAccountSelectionModel,
    headerList,
    activeHeaderFields,
    filterModel,
    handleOnView,
    handleUpdateHeader,
    handleFilter,
    onRowColumnOrderChange,
    handleBulkOptionClick,
    handleNewButtonAction,
    search,
    searchOptions,
    handleSearch,
    showSuggestionPopover,
    setShowSuggestionPopover,
    bulkMappingField,
    setBulkMappingField,
    progress,
    hasMappingAdmin,
    tab,
    handleTabChange,
    readonly,
    isPageLocked,
    setIsPageLocked,
    lockedTooltipText,
    toggleIsLocked,
    fetchAllAccounts,
    selectedAccount,
    onDetailsPanelClose,
    isPreview,
    handleBulkSave,
    fundId,
    NewAccountOptions,
    uploadedFile,
    handleUploadTemplate,
    clearUploadedFile,
    apiRef,
    bulkSelectionModel,
    bulkMappingValue,
    setBulkMappingValue,
    handleApplyChange,
    valueOptions,
    setBulkMappingValueId,
    clearApplyChange,
    confirmBulkMapUpdate,
    showBulkConfirmation,
    setShowBulkConfirmation,
    bulkMappingValueId,
    enableBulkMap,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
    showFSNameConfirmation,
    setShowFSNameConfirmation,
    bulkMappingFSName,
    setBulkMappingFSName,
    onDeleteCancel,
    onDeleteConfirm,
    deleteError,
    deleteErrorMessage,
    deleteErrorJEAccounts,
    isStandishManagementBasicAdmin,
  };
};

export const useBulkActionOptionSelectionEffect = (
  bulkActionList: ImageItem[],
  companySelectionModel: any
) => {
  const { hasRole: superAdminORClientAdminUser } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const { hasRole: basicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: fundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: clientPortalUser } = useRole([ScopeRole.BASIC_USER]);

  const [bulkActionOptions, setBulkActionOptions] = useState<ImageItem[]>([]);

  useEffect(() => {
    let optionList: ImageItem[] = [];

    if (superAdminORClientAdminUser) {
      optionList = bulkActionList;
    }
    if (clientPortalUser) {
      bulkActionList = bulkActionList.filter((item) => item.id === 'export');
    }

    const updatedOptions: ImageItem[] = bulkActionList.map((option) => {
      option.text = option.text + '(' + companySelectionModel.length + ')';
      return option;
    });

    setBulkActionOptions(updatedOptions);
  }, [companySelectionModel]);

  return {
    bulkActionOptions,
  };
};
