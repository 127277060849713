import { SheetTypes, ToolbarButtons } from "../../../workbook.type";
import { SpreadsheetGrid } from "../../SpreadsheetGrid";
import { colsAddResponse } from "../ICellEditHandling";
import { ISheetManager } from "../ISheet.Manager";
import { WorkbookSheetsManager } from "../WorkbookSheets.Manager";

export class UserSheetManager implements ISheetManager {
    private _grid!: SpreadsheetGrid;
    private _workbookSheetsManager!: WorkbookSheetsManager;

    get sheetType(): SheetTypes {
        return SheetTypes.Custom;
    }

    get workbookSheetsManager(): WorkbookSheetsManager {
        return this._workbookSheetsManager;
    }

    setUserSheetData(workbookSheetsManager: WorkbookSheetsManager): void {
        this._workbookSheetsManager = workbookSheetsManager;
    }

    OnPreloadedSheet(): void {
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.Save, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SaveAs, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.GetFromGl, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.RollForward, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.SubmitToPcap, false);
        SpreadsheetGrid.setToolbarVisible(ToolbarButtons.AllocationsConfig, false);
    }

    OnLoadedSheet() {

    };

    onToolbarButtonClick(buttonName: string) {
        // Should never reach here but if for come reason we do get here do nothing
    }

    onStartEdit(row: string, col: string) {
        return true;
    };

    onEndEdit(row: string, col: string, save: boolean, value: string) {
        this._workbookSheetsManager.changeMade();

        return true;
    }

    onColsAdd(cols: string[], toCol: string, right: boolean, empty: boolean): colsAddResponse {
        this._workbookSheetsManager.changeMade();

        return {
            validColumnPosition: true,
            newColumnIDs: cols
        };
    }

    onRowAdd(parentRow: string){
        this._workbookSheetsManager.changeMade();

        return true;
    }

    onCanColDelete(col: string) {
        this._workbookSheetsManager.changeMade();

        return true;
    }

    onCanRowDelete(row: string){
        this._workbookSheetsManager.changeMade();

        return true;
    }

    onCanPaste(startCol: string, startRow: string, numOfCols: number, numOfRows: number){
        this._workbookSheetsManager.changeMade();
        
        return true;
    }
}