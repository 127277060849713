import { useState } from 'react';
import { RouteProps } from 'react-router-dom';

import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import { StyledBackdrop } from '../../../components/DetailPanel/DetailPanel.styles';
import ProgressPanel from '../../../components/Progress/ProgressPanel/ProgressPanel';
import { BankAccount, nameIdFundPair } from '../../../utils/types/bank.type';
import { NameIdPair } from '../../../utils/types/transaction.type';
import { plaidConnectResponseType } from './BankConnectionPanel.defaults';
import { BankFundAssociation } from './bankFundAssociation/BankFundAssociation';
import { BankInstitutionSelector } from './bankInstitutionSelecor/BankInstitutionSelector';
import PlaidLinkWithOAuth from './PlaidLink';

interface Props extends RouteProps {
  openBankConnectionPanel: boolean;
  handleBankConnectionClose: () => void;
  fundList: NameIdPair[];
  ledgerList: nameIdFundPair[];
  CoAList: nameIdFundPair[];
  bankAccountList: BankAccount[];
}

export const BankConnectionPanel: React.FC<Props> = ({
  openBankConnectionPanel,
  handleBankConnectionClose,
  fundList,
  ledgerList,
  CoAList,
  bankAccountList,
}: Props) => {
  const [isLoading, setIsLoading] = useState<string>('');

  const [institutionId, setInstitutionId] = useState<string>();
  const [importStartDate, setImportStartDate] = useState<Date>();

  const [openPlaid, setOpenPlaid] = useState<boolean>(false);
  const [plaidAccountsRes, setPlaidAccountsRes] = useState<
    plaidConnectResponseType | undefined
  >();

  const handleLinkNewBankAccount = (
    institutionId: string,
    importStartDate: Date
  ) => {
    setOpenPlaid(true);
    setInstitutionId(institutionId);
    setImportStartDate(importStartDate);
  };

  return (
    <DetailPanel
      id="bank_connection_panel"
      title={'Connect Account'}
      open={openBankConnectionPanel}
      variant={'temporary'}
      isGridDisplay={true}
      hasTabsPanel={false}
      onClose={handleBankConnectionClose}
    >
      {!plaidAccountsRes?.public_token ? (
        <BankInstitutionSelector
          onOk={handleLinkNewBankAccount}
          onCancel={handleBankConnectionClose}
          setIsLoading={setIsLoading}
        />
      ) : (
        <BankFundAssociation
          openBankConnectionPanel={!!plaidAccountsRes?.public_token}
          handleBankConnectionClose={handleBankConnectionClose}
          plaidAccountsRes={plaidAccountsRes}
          fundList={fundList}
          ledgerList={ledgerList}
          CoAList={CoAList}
          bankAccountList={bankAccountList}
        />
      )}

      {openPlaid && (
        <PlaidLinkWithOAuth
          institutionId={institutionId!}
          importStartDate={importStartDate!}
          setIsLoading={setIsLoading}
          setOpenPlaid={setOpenPlaid}
          setPlaidAccountsRes={setPlaidAccountsRes}
        />
      )}

      <StyledBackdrop open={Boolean(isLoading)} />
      <ProgressPanel
        id={'progress_je_post_dialog'}
        showProgress={Boolean(isLoading)}
        text={isLoading}
      />
    </DetailPanel>
  );
};
