import { Backdrop, Grid, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PRIMARY } from '../../../../styles/colors';
import { INFO_PANEL_MAX_WIDTH } from '../../../../utils/constants/styles.constants';

export const Title = styled('div')(() => ({
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '100%',
  paddingTop: '30px',
  marginLeft: '3%',
  textAlign: 'left',
  verticalAlign: 'center',
  paragraphSpacing: '10px',
  fontWeight: 'bold',
  display: 'flex',
  whiteSpace: 'nowrap',
  'img:first-of-type': {
    paddingLeft: '20px',
  },
}));

export const EditPanelSettingsBox = styled(Box)({
  display: 'flex',
  overflow: 'auto',
  maxHeight: '100%',
  marginBottom: '90px',
  gridTemplateRows: 'auto 1fr auto',
});

export const EditPanelSettingsGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 4.5),
  overflowY: 'scroll',
}));

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
`;

export const PermissionsBox = styled(Box)`
  width: calc(100vw - 20px);
  padding-left: 10px;
  padding-right: 10px;
`;

export const ActionButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${INFO_PANEL_MAX_WIDTH};
  bottom: 0px;
`;

export const PanelActionBox = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const IconText = styled('div')`
  margin-right: 10px;
  margin-left: 10px;
  color: ${PRIMARY};
  cursor: pointer;
`;

export const TooltipTypography = styled(Typography)(() => ({
  variant: 'body1',
  paddingBottom: '8px',
}));
