import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import useParentAccountFilterEffect from "./useParentAccountFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (purchaseTransaction: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  accountId?: string;
};

type ParentAccountValue = any | null | undefined;

export const ParentAccountFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = "small",
  id = "parent_account_filter",
  textFieldID = "txt_parent_account",
  accountId = undefined,
}: Props) => {
  const { parentAccountList, loading } =
    useParentAccountFilterEffect(accountId);

    const filteredList = parentAccountList?.filter((account: any) => accountId !== account.id && accountId !== account.parentId);
    const onSelectChange = (_: any, purchaseTransaction: any) => {
    if (!purchaseTransaction) {
      return;
    }
    onChange?.(
      idSelectOnly ? purchaseTransaction?.id ?? "" : purchaseTransaction
    );
  };

  const selectedValue: ParentAccountValue = idSelectOnly
    ? parentAccountList?.find((item: any) => item.id === value) ?? null
    : (value as ParentAccountValue);

  return (
    <Autocomplete
      id={id}
      options={filteredList || []}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.name === selected?.name
      }
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      size={size}
      disabled={disabled || loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            label={placeholder}
            id={textFieldID}
            error={!!error}
            helperText={error?.message ?? ""}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default ParentAccountFilter;
