import { Box, Typography } from "@mui/material";
import React from "react";

import { StyledClientCardContainer } from "../UserDetailPanel.styles";

type Props = {
    children: React.ReactNode,
    title: string | React.ReactNode,
}

const ClientCard = ({
  children,
  title
}: Props) => (
  <Box>
    <Typography variant="subtitle1">{title}</Typography>
    <StyledClientCardContainer>
      {children}
    </StyledClientCardContainer>
  </Box>
);

export default ClientCard;
