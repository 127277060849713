import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, IconButton, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import Progress from '../../../components/Progress/ProgressModal/ProgressModal';
import {
  StyledTab,
  StyledTabs,
} from '../../../components/TabsPanel/TabsPanel.styles';
import { TabsRow } from '../../users/Users.styles';
import AddTransactionsTab from './AddTransactions/AddTransactions';
import {
  CapitalCallWrapper,
  HeaderBreadcrumbContainer,
  HeaderRow,
  HeaderTitleContainer,
} from './CapitalCallDetails.styles';
import CapitalCallSetUpTab from './CapitalCallSetup/CapitalCallSetUp';
import { CapitalCallDetailsTabs } from './constants';
import PublishTab from './Publish/Publish';
import ReviewDocumentsTab from './ReviewDocuments/ReviewDocuments';
import { useCapitalCallDetails } from './useCapitalCallDetails.hooks';
interface RouteProp {
  id: string;
}

const CapitalCallDetails: React.FC = (): ReactElement => {
  const { tab, loading, handleClose, handleChange } = useCapitalCallDetails();
  const { id } = useParams<RouteProp>();

  return (
    <CapitalCallWrapper id="client_list_main">
      <Progress id="capitalcall_detail_list_loading" showProgress={loading} />
      <HeaderRow>
        <HeaderTitleContainer>
          <IconButton onClick={handleClose} aria-label="Back">
            <ArrowBackIosRoundedIcon color="primary" fontSize="large" />
            <Typography variant="h3">Return to Capital Calls</Typography>
          </IconButton>
          <HeaderBreadcrumbContainer></HeaderBreadcrumbContainer>
          <Box />
        </HeaderTitleContainer>
      </HeaderRow>
      <TabsRow>
        <StyledTabs
          id="capitalcall_detail_tabs"
          value={tab}
          aria-label="Capital Call Details Tabs"
          onChange={id !== 'new' ? handleChange : () => {}}
        >
          <StyledTab key={0} label={CapitalCallDetailsTabs.CapitalCallSetUp} />
          <StyledTab key={1} label={CapitalCallDetailsTabs.AddTransactions} />
          <StyledTab key={2} label={CapitalCallDetailsTabs.ReviewDocuments} />
          <StyledTab key={3} label={CapitalCallDetailsTabs.Publish} />
        </StyledTabs>
      </TabsRow>
      <Box>
        <Switch>
          <Route
            path={`/capital-calls/:id/${CapitalCallDetailsTabs.CapitalCallSetUp}`}
            exact
          >
            <CapitalCallSetUpTab
              onNavigateAwayCanceled={() => {
                handleChange(undefined, 0);
              }}
            />
          </Route>
          <Route
            path={`/capital-calls/:id/${CapitalCallDetailsTabs.AddTransactions}`}
            component={AddTransactionsTab}
            exact
          />
          <Route
            path={`/capital-calls/:id/${CapitalCallDetailsTabs.ReviewDocuments}`}
            component={ReviewDocumentsTab}
            exact
          />
          <Route
            path={`/capital-calls/:id/${CapitalCallDetailsTabs.Publish}`}
            component={PublishTab}
            exact
          />
        </Switch>
      </Box>
    </CapitalCallWrapper>
  );
};

export default React.memo(CapitalCallDetails);
