export type Quarter = {
  id: string;
  tenantID: string;
  name: string;
  year: number;
  quarterNumber: number;
  endDate: Date;
  qnumber: number;
  qyear: number;
  startDate: Date;
}

export type Filter = {
  column: string;
  data: string;
};

export type FilterSelectionType = "All" | "Partial"; 

export type FilterColumn = {
  column: string;
  selectedList: string[];
};

export type Option = { id: string, name: string };

export enum CapitalCallFilter {
  InvestorId = 'investorId',
  FundId = 'fundId',
  TransId = 'uid'
}

export enum DistributionFilter {
  InvestorId = 'investorId',
  FundId = 'fundId',
  TransId = 'transactionId'
}
