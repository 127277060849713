import { ReactElement } from "react";

import { StyledSwitch } from "./Switch.styles";

type Props = {
  id: string;
  checked?: boolean;
  label: string;
  onChange?: any;
};

const Switch = (props: Props): ReactElement => {
  const { id, checked = false, label, onChange } = props;

  return (
    <StyledSwitch
      id={id}
      checked={checked}
      size="small"
      aria-label={label}
      onChange={onChange}
    />
  );
};

export default Switch;
