import { ArkGLEntity } from "../../../../utils/types/arkGLEntity.type";

export const ENTITY_FORM_DEFAULT_VALUE: ArkGLEntity = {
    name: "",
    phoneNumber: "",
    emailAddress: "",
    tin:  "",
    type: "",
    is1099:  false,
    mailingAddress: {
        city:  "",
        country:  "",
        postalCode:  "",
        state:  "",
        street1:  "",
        street2:  ""
    }
};

export const DEFAULT_ENTITY_TYPES: string[] = ["Bank", "Vendor"];

export const UPDATE_ENTITY_SUCCESS = "Successfully updated Entity.";
export const UPDATE_ENTITY_ERROR = "Error updating Entity.";
export const CREATE_ENTITY_SUCCESS = "Successfully created Entity.";
export const CREATE_ENTITY_ERROR = "Error creating Entity.";
export const COUNTRY_LIST_ERROR = "Error occured when getting available Country options.";
export const STATE_LIST_ERROR = "Error occured when getting available State options.";