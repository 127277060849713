import {
  DashboardWidget,
  FundSummaryItem,
  PieDataItem,
  SoiSummaryItem,
  WidgetType,
} from '../../../utils/types/visualDashboard.type';
import { getColor } from '../helpers/Chart.utils';
import { PageSummaryItem } from '../VisualDashboard';
import { VisualDashboardType } from '../VisualDashboard.constants';
import { isFundSummaryItemArray } from '../VisualDashboard.hooks';
import { PortfolioCompanyBarChart } from './PortfolioCompanyBarChart';
import { TotalValueAndDistributionsBarChart } from './TotalValueAndDistributionsBarChart';

export function getBarChartData(
  summaryItems: PageSummaryItem[],
  pageType: string,
  dashboardWidgets: DashboardWidget[],
  currency: string
) {
  let pageSummaryItems: FundSummaryItem[] = [];
  let soiSummaryItems: SoiSummaryItem[] = [];
  let chartWidgets = [];

  switch (pageType) {
    case VisualDashboardType.CapitalAccounts:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);

      pageSummaryItems = Object.values(
        pageSummaryItems.reduce((acc, current) => {
          if (!acc[current.name]) {
            acc[current.name] = { ...current };
          } else {
            acc[current.name].distribution += current.distribution;
            acc[current.name].value += current.value;
          }
          return acc;
        }, {} as { [key: string]: FundSummaryItem })
      );
      break;
    case VisualDashboardType.Fund:
      pageSummaryItems = isFundSummaryItemArray(summaryItems);

      break;
    case VisualDashboardType.SoiUnrealized:
    case VisualDashboardType.SoiRealized:
    case VisualDashboardType.SoiDashboard:
      soiSummaryItems = summaryItems;
  }

  chartWidgets = dashboardWidgets
    ?.filter(
      (widget: DashboardWidget) =>
        widget.widgetType === WidgetType.Charts && widget.visible
    )
    .map((widget: DashboardWidget) => {
      let display = false;
      let chart: JSX.Element | undefined = undefined;

      switch (widget.name) {
        case 'totalValueDistributions':
          chart = (
            <TotalValueAndDistributionsBarChart
              fundSummaries={pageSummaryItems}
              currency={currency}
            />
          );
          if (pageSummaryItems.length > 0) display = true;
          break;
        case 'portfolioCompanyByLocation':
          chart = (
            <PortfolioCompanyBarChart
              soiSummaries={soiSummaryItems}
              currency={'USD'}
              type={'portfolioCompanyByLocation'}
              pageType={pageType}
            />
          );
          if (soiSummaryItems.length > 0) display = true;
          break;
        case 'topPortfolioCompanies':
          chart = (
            <PortfolioCompanyBarChart
              soiSummaries={soiSummaryItems}
              currency={'USD'}
              type={'topPortfolioCompanies'}
              pageType={pageType}
            />
          );
          if (soiSummaryItems.length > 0) display = true;
          break;
      }

      return {
        index: widget.index,
        name: widget.name,
        title: widget.title,
        visible: widget.visible,
        widgetType: widget.widgetType,
        chart: chart,
        display: display,
      };
    })
    .sort((a, b) => a.index - b.index);

  const filteredCharts = chartWidgets.filter(
    (chart) => chart.chart !== undefined
  );

  return { currency: currency, charts: filteredCharts };
}
