import { NameIdPair } from "./transaction.type";

export enum Statuses {
  PUBLISHED = "published",
  REVIEWING = "reviewing",
  INACTIVE = "inactive",
  EDITING = "editing",
}

export enum DownloadAction {
  DownloadAll = "download-all",
}

export type DistributionTeamMembers = {
  ASSOCIATE?: string[] | [];
  FUND_CONTROLLER?: string[] | [];
  FUND_USER_READ_ONLY?: string[] | [];
  MANAGER?: string[] | [];
  DIRECTOR?: string[] | [];
};

export type DistributionId = {
  distributionId: string;
};

export type SelectedTransaction = {
  transaction?: Transaction;
  type?: TransactionType;
};

export type DistributionReviews = {
  approvalId: string;
  canApprove: boolean;
  checked: boolean | null;
  contactEmails: string[];
  creator: false;
  documentId: string;
  investorName: string;
  order: number;
  role: string;
  transactionId: string;
  username: string;
};

export enum TransactionType {
  New = "new",
  Edit = "edit",
}

export type StatusType = {
  status: boolean | null;
};

type approvalType = {
  approved: boolean | null;
  id: string;
  role: string;
  tenantId: string;
  username: string;
};
type ReviewType = {
  approvals: approvalType[];
  clientId: string | null;
  id: string;
  investor: string;
  order: number;
  transaction: string | null;
};
export type DistributionReviewsSummary = {
  createdBy: string;
  fund: string;
  fundId: string;
  hasInactiveContact: boolean;
  id: string;
  readyForPublish: boolean;
  reviews: ReviewType[];
  role: string;
};

export type Distributions = {
  amount: number | null;
  clientId?: string | null;
  createdBy?: string | null;
  createdOn?: Date | null;
  date?: Date | string;
  documentId?: string | null;
  emailDocumentId?: string | null;
  fundId?: string;
  fundName: string;
  id: string;
  index: Number;
  name: string;
  reviewers: any | null;
  status: Statuses | string;
  transactions: any | null;
};

export enum DistributionsFilter {
  Statuses = "Statuses",
}

export type DistributionDocument = {
  document?: {
    fileId: string;
    name: string;
    size: number;
  };
  email?: {
    fileId: string;
    name: string;
    size: number;
  };
};

export type Transaction = {
  id?: string;
  name: string;
  date: Date | string;
  dateString?: Date | string | null;
  displayOrder?: string;
  quarter?: string | null;
  metricSign?: string | null;
  metricFractionDigit?: number;
  amount: number | string;
  components: any[];
  useMetric: boolean;
  fund: string;
  investor: string;
  type: string;
  currentInvestor?: NameIdPair;
  currentType?: NameIdPair;
  isUnsaved?: boolean;
};

export enum TransactionCRUDStatus {
  Adding = "Adding",
  Updating = "Updating",
  Deleting = "Deleting",
}

export enum TransactionAction {
  DeleteSelected = "delete_selected",
  DeleteAll = "delete_all",
}

export type Component = {
  id: string;
  label: string;
  mergeTag: string;
  tenantId: string;
  value: string;
};

export type Transactions = {
  amount?: number;
  distributionDate?: string;
  distributionId?: string;
  documentId?: string;
  name?: string;
  transactionId?: string;
  callDate?: string;
  uid?: string;
};

export type Funds = {
  fundCurrencyCode: string;
  fundId: string;
  fundName: string;
  transactions: Transactions[];
};

export type PortalDistributions = {
  amount: number;
  distributionDate: string;
  distributionId: string;
  documentId?: string;
  fundCurrencyCode: string;
  fundId: string;
  fundName: string;
  funds: Funds[];
  id: string;
  investorId: string;
  investorName: string;
  name: string;
  transactionId: string;
  transactionName: string;
  transactions: Transactions[];
};

export enum PortalDistributionExportActions {
  AllDistributionReportExcel = "all_distr_report_excel",
  AllDistributionReportPDF = "all_distr_report_pdf",
}
