import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import { StyledBackdrop } from '../../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import RoutingPaths from '../../../../core/routing/routingPaths';
import { JournalEntryDetails } from '../../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetails';
import { CapitalCallDetailsTabs } from '../constants';
import {
  CheckboxFormControl,
  ConfirmationText,
  ContentContainer,
  Header,
  HeaderTitle,
  MarginFormControlLabel,
  PublishTable,
  PublishTableCell,
  RadioButtonBox,
  ReviewersTable,
  ReviewersTitle,
  StyledBox,
  StyledButton,
  StyledCard,
  StyledDivider,
  StyledLink,
} from './Publish.styles';
import { UsePublish } from './UsePublish.hooks';

type RouteProp = {
  id: string;
};

const PublishTab: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteProp>();
  const isCapitalCallPublished =
    sessionStorage.getItem(params.id) === 'capCallPublished';

  const {
    reviewSummary,
    selectReviewer,
    submitReview,
    status,
    isLoading,
    confirmPublish,
    handlePublishCancel,
    handlePublishConfirm,
    setConfirmPublish,
    isSuperAdminOrClientAdmin,
    toReview,
    allowEdit,
    headerTitles,
    dummyTitle,
    formattedReviewSummary,
    allowPublish,
    sendToGL,
    setSendToGL,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    arkGlLocked,
  } = UsePublish();

  return (
    <>
      <Box>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'publish_documents_progress_bar'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ''}
        />
        <Box>
          <Header direction="row" spacing={1}>
            <HeaderTitle variant="h1">
              <strong>Publish</strong>
            </HeaderTitle>
            {isSuperAdminOrClientAdmin && (
              <Button
                id="btn_submit_review"
                variant="outlined"
                onClick={submitReview}
                disabled={
                  toReview === false ||
                  status === 'REVIEWING' ||
                  status === 'PUBLISHED' ||
                  allowEdit === false
                }
              >
                <strong>Submit for Review</strong>
              </Button>
            )}
            {isSuperAdminOrClientAdmin && (
              <Button
                id="btn_save_continue"
                variant="contained"
                onClick={() => {
                  sessionStorage.setItem(params?.id, 'capCallPublished');
                  setConfirmPublish(true);
                }}
                disabled={
                  status === 'PUBLISHED' ||
                  !reviewSummary?.readyForPublish ||
                  !allowPublish ||
                  isCapitalCallPublished
                }
              >
                Publish and Email
              </Button>
            )}
          </Header>
          <StyledDivider variant="fullWidth"></StyledDivider>
          <ReviewersTable>
            <ReviewersTitle variant="h3">
              <strong>Reviewers</strong>
            </ReviewersTitle>
            <TableContainer>
              <PublishTable aria-label="Reviewers">
                <TableHead>
                  <TableRow>
                    <TableCell key="reviewer0">
                      <StyledBox>
                        <strong>Investor Name</strong>
                      </StyledBox>
                    </TableCell>
                    {headerTitles?.map((name, index) => (
                      <TableCell key={`reviewer${index + 1}`}>
                        <StyledCard>{selectReviewer(name)}</StyledCard>
                      </TableCell>
                    ))}
                    {headerTitles?.length < 5 &&
                      dummyTitle.map((name, index) => {
                        if (index >= headerTitles?.length) {
                          return (
                            <TableCell key={`reviewer_dummy_${index + 1}`}>
                              <StyledBox />
                            </TableCell>
                          );
                        }
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formattedReviewSummary?.map((review: any) => (
                    <TableRow key={`reviewer_details_${Math.random()}`}>
                      <TableCell key={`reviewer_details_cell_${Math.random()}`}>
                        {review.investor}
                      </TableCell>
                      {headerTitles.map((header, index) => {
                        return (
                          <PublishTableCell
                            key={`reviewer_details_cell_${Math.random()}_${index}`}
                          >
                            {review[header.toUpperCase()]?.map((data: any) => (
                              <RadioButtonBox key={`${Math.random()}`}>
                                <MarginFormControlLabel
                                  control={
                                    data.approved === null ? (
                                      <RadioButtonUncheckedIcon />
                                    ) : data.approved === true ? (
                                      <CheckCircleOutlineIcon color="success" />
                                    ) : (
                                      <FlagCircleIcon color="error" />
                                    )
                                  }
                                  label={data?.username || ''}
                                />
                                <br />
                              </RadioButtonBox>
                            ))}
                          </PublishTableCell>
                        );
                      })}
                      {headerTitles?.length < 5 &&
                        dummyTitle.map((name, index) => {
                          if (index >= headerTitles?.length) {
                            return (
                              <TableCell key={`reviewer_dummy_${index + 1}`}>
                                <StyledBox />
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                  ))}
                </TableBody>
              </PublishTable>
            </TableContainer>
          </ReviewersTable>
        </Box>

        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          stickToCenter={true}
          columnGap={true}
          sx={{
            position: 'fixed',
            width: '100%',
            marginLeft: (theme) => theme.spacing(-2),
          }}
        >
          <StyledButton
            id="btn_go_back"
            variant="outlined"
            onClick={() => {
              history.push(
                `/capital-calls/${params?.id}/${CapitalCallDetailsTabs.ReviewDocuments}`
              );
            }}
          >
            Go Back
          </StyledButton>
        </HorizontalBox>
        <ConfirmationDialog
          open={confirmPublish}
          onClose={handlePublishCancel}
          id="handle_publish_email"
          isTextContent={false}
          actions={[
            {
              label: 'Yes, Continue',
              onClick: handlePublishConfirm,
              id: 'handle_publish_confirm',
              variant: 'contained',
              color: 'primary',
              disabled: !isSuperAdminOrClientAdmin || status === 'PUBLISHED',
            },
            {
              label: 'Cancel',
              onClick: handlePublishCancel,
              id: 'handle_publish_cancel',
              variant: 'outlined',
              color: 'error',
              disabled: !isSuperAdminOrClientAdmin,
            },
          ]}
          content={
            <ConfirmationText>
              {reviewSummary?.hasInactiveContact && (
                <>
                  The publish button will send email notifications to users.
                  There are
                  <ContentContainer>
                    &nbsp;
                    <StyledLink to={RoutingPaths.Contacts}>
                      PENDING and/or NEW Contacts
                    </StyledLink>
                    &nbsp;
                  </ContentContainer>
                  that will not receive these email notifications unless they
                  are approved, and their status is ACTIVE.
                  <br />
                  <br />
                </>
              )}
              <Typography>
                It may take a few minutes for this batch to process and for
                emails to be sent.
              </Typography>
              <CheckboxFormControl>
                <FormControlLabel
                  label="Send to GL"
                  control={
                    <Checkbox
                      id="check_send_to_gl"
                      checked={sendToGL}
                      value={sendToGL}
                      onChange={(e) => setSendToGL(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  disabled={arkGlLocked}
                />
              </CheckboxFormControl>
              <Typography>Would you like to continue?</Typography>
            </ConfirmationText>
          }
          title="Continue to Publish"
        />
      </Box>
      {!!selectedJournalEntry?.type && (
        <JournalEntryDetails
          selectedJournalEntry={selectedJournalEntry}
          setSelectedJournalEntry={setSelectedJournalEntry}
          onDetailClose={onJournalEntryPanelClose}
          fetchAllJournalEntries={() => {}}
          fundId={selectedJournalEntry.journalEntry?.fundId}
        />
      )}
    </>
  );
};

export default React.memo(PublishTab);
