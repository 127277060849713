import { AnyPtrRecord } from 'dns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  createCapitalCall,
  getDocuments,
  getTeamRoleswithMembersForFund,
  updateCapitalCall,
  uploadDocument,
} from '../../../../services/capitalCalls.service';
import { useCapitalCallDetailsEffect } from '../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks';
import { awaitReactUpdate } from '../../../../utils/helpers/timeoutFunctions';
import {
  CapitalCallDocument,
  CapitalCalls,
  TeamMembers,
} from '../../../../utils/types/capitalCalls.type';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  CapitalCallDetailsTabs,
  CREATE_CAPITAL_CALL_ERROR,
  CREATE_CAPITAL_CALL_SUCCESS,
  CREATE_CAPITAL_CALL_UPDATE,
  DOCUMENT_GET_ERROR,
  DOCUMENT_UPLOAD_ERROR,
  InitialErrorMessages,
  REVIEW_VALIDATION_ERROR,
  TEAM_MEMBERS_ROLE_ERROR,
} from '../constants';

const newCapitalCall = {
  name: '',
  fundId: '',
  fundName: '',
  reviewers: [],
  clientId: '',
  amount: 0,
  date: new Date(),
  documentId: '',
  emailDocumentId: '',
  id: '',
};

const DefaultTeamMembers = {
  ASSOCIATE: [],
  MANAGER: [],
  DIRECTOR: [],
  FUND_CONTROLLER: [],
  FUND_USER_READ_ONLY: [],
};

export interface ICapitalCallSetupForm extends CapitalCalls {
  noticeTemplate?: FileList;
  emailTemplate?: FileList;
}

interface RouteProp {
  id: string;
  section: string;
}

export const useCapitalCallSetUp = (onNavigateAwayCanceled: () => void) => {
  const history = useHistory();
  const { id } = useParams<RouteProp>();
  const [teamMembers, setTeamMembers] =
    useState<TeamMembers>(DefaultTeamMembers);
  const [documents, setDocuments] = useState<CapitalCallDocument | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const { informationAlert, state } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState<any>(InitialErrorMessages);
  const { capitalCall } = useCapitalCallDetailsEffect(
    id !== 'new' ? id : undefined
  );

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm<ICapitalCallSetupForm>({
    defaultValues: {
      clientId: '',
      amount: 0,
      date: new Date(),
      documentId: '',
      emailDocumentId: '',
      id: '',
    },
  });

  const allowEdit = useMemo(() => {
    if (id !== 'new') {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          capitalCall?.createdBy?.toLowerCase() &&
        capitalCall?.status?.toLowerCase() !== 'published'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [id, capitalCall, state]);

  const allowReviewerEdit = useMemo(() => {
    if (capitalCall !== null) {
      const reviewersList = capitalCall?.reviewers?.reduce(
        (
          acc: string[],
          current: {
            name?: string;
          }
        ) => {
          const teamMembersForRole =
            teamMembers?.[(current?.name as keyof TeamMembers) || ''] || [];

          if (teamMembersForRole?.length > 0) {
            return [...acc, ...teamMembersForRole];
          }
          return acc;
        },
        []
      );

      if (id !== 'new') {
        if (
          state.loginUser.currentUser?.username.toLowerCase() !==
            capitalCall?.createdBy?.toLowerCase() &&
          capitalCall?.status?.toLowerCase() === 'reviewing' &&
          reviewersList?.includes(
            state.loginUser.currentUser?.username.toLowerCase() || ''
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }, [id, capitalCall, state, teamMembers]);

  const addCapitalCall = async (data: ICapitalCallSetupForm | any) => {
    const { noticeTemplate, emailTemplate, ...capitalCallData } =
      data as ICapitalCallSetupForm;

    try {
      setLoading(true);
      if (id === 'new') {
        const res = await createCapitalCall(capitalCallData);

        if (noticeTemplate) {
          await uploadDocument(res.capitalCallId, noticeTemplate, 'document');
        }

        if (emailTemplate) {
          await uploadDocument(res.capitalCallId, emailTemplate, 'email');
        }

        history.replace(
          `/capital-calls/${res.capitalCallId}/${CapitalCallDetailsTabs.CapitalCallSetUp}`
        );

        informationAlert(CREATE_CAPITAL_CALL_SUCCESS, 'success');
      } else {
        await updateCapitalCall(id, capitalCallData);

        if (noticeTemplate) {
          await uploadDocument(id, noticeTemplate, 'document');
        }

        if (emailTemplate) {
          await uploadDocument(id, emailTemplate, 'email');
        }
        informationAlert(CREATE_CAPITAL_CALL_UPDATE, 'success');
      }
    } catch (error) {
      informationAlert(CREATE_CAPITAL_CALL_ERROR, 'error');
    } finally {
      setLoading(false);
      reset({}, { keepValues: true });
    }
  };

  const getTeamRolesWithMembers = async (fundId: string) => {
    try {
      setTeamMembers(await getTeamRoleswithMembersForFund(fundId));
    } catch (error) {
      informationAlert(TEAM_MEMBERS_ROLE_ERROR, 'error');
    }
  };

  const addDocument = async (id: string, file: FileList, type: string) => {
    try {
      await uploadDocument(id, file, type);
    } catch (error) {
      informationAlert(DOCUMENT_UPLOAD_ERROR, 'error');
    }
  };

  const getDocument = async (id: string) => {
    try {
      const response = await getDocuments(id);

      setDocuments(response);
    } catch (error) {
      informationAlert(DOCUMENT_GET_ERROR, 'error');
    }
  };

  const handleValidateReviewers = (
    value: { name: string }[] | null | undefined
  ) => {
    let isValid = true;
    const errMsg: { [key: string]: any } = {};

    value?.forEach((reviewer) => {
      const filteredTeamMembers =
        teamMembers[(reviewer.name as keyof TeamMembers) || '']?.filter(
          (user) =>
            user?.toLowerCase() !==
            state.loginUser.currentUser?.username?.toLowerCase()
        ) || [];

      if (filteredTeamMembers.length === 0) {
        errMsg[reviewer.name] = REVIEW_VALIDATION_ERROR;
        isValid = false;
      } else errMsg[reviewer.name] = '';
    });

    setErrorMessage({ ...InitialErrorMessages, ...errMsg });
    return isValid;
  };

  const handleconfirmSaveAndNavagateAway = async (form: HTMLFormElement) => {
    const validForm = await trigger();

    if (!validForm) {
      onNavigateAwayCanceled();
      return false;
    }

    form.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
    await awaitReactUpdate(2000);

    return true;
  };

  useEffect(() => {
    if (capitalCall) {
      getTeamRolesWithMembers(capitalCall?.fundId as string);
      reset(capitalCall);
      setLoading(false);
    } else {
      reset(newCapitalCall);
      setLoading(false);
    }
  }, [capitalCall]);

  useEffect(() => {
    if (id && id !== 'new') {
      setLoading(true);
      getDocument(id).then(() => setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    handleValidateReviewers(getValues('reviewers'));
  }, [teamMembers]);

  return {
    register,
    handleSubmit,
    errors,
    control,
    addCapitalCall,
    getTeamRolesWithMembers,
    getValues,
    setValue,
    teamMembers,
    addDocument,
    id,
    documents,
    setDocuments,
    loading,
    isSuperAdminOrClientAdmin,
    allowEdit,
    allowReviewerEdit,
    handleValidateReviewers,
    errorMessage,
    setErrorMessage,
    isDirty,
    handleconfirmSaveAndNavagateAway,
  };
};
