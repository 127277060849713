import { autocompleteClasses, Box, styled } from "@mui/material";

export const StyledLateralBox = styled(Box)`
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.7em;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c2c2c6;
    border-radius: 8px;
  }
`;
