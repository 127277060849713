import { Box, styled, Typography } from '@mui/material';

import DataGrid from '../../../../components/DataGrid/DataGrid';

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gridTemplateColumns: '40% 49% 9%',
  gap: theme.spacing(1),
  justifyContent: "left",
  alignItems: "center",
  mb: theme.spacing(2),
}));

export const StyledDataGrid = styled(DataGrid)`
  margin: 20px 0 50px 0;
`;

export const TimeText = styled(Typography)(({
    theme
  }) => ({
    color: theme.palette.grey[500],
  }),
) as typeof Typography;