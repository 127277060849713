import React, { useEffect } from 'react';

type Props = {
    defer?: boolean;
}

const Zendesk = ({
  defer
}: Props) => {

  // WARNING:
  // @ts-ignore and @ts-expect-error suppress TypeScript validations by ignoring errors.
  // Suppressing TypeScript validations can be 🔴dangerous🔴.
  // Here it is suppressed as zendesk doesn't support typescript by default, and we are using globally exported object zE.
  const hideOnLoad = () => {
    // @ts-ignore
    if(window && window.zE && window?.zE?.hide) {
      // @ts-ignore
      window?.zE?.hide?.();
    } else {
      setTimeout(() => {
        hideOnLoad();
      }, 1000);
    }
  };

  useEffect(() => {
    let script: HTMLScriptElement | undefined;

    if (!document.getElementById("ze-snippet")) {
      script = document.createElement("script");

      if (defer) {
        script.defer = true;
      } else {
        script.async = true;
      }
      script.setAttribute("id", "ze-snippet");
      script.setAttribute("async", "");
      script.setAttribute("defer", "");
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}&hide=true`;
      script.addEventListener('load', hideOnLoad);
      window.document.body.appendChild(script);
    }

    () => {
      if(script) {
        window.document.body.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default React.memo(Zendesk);
