import {
  Autocomplete, Backdrop, Button,
  Card,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment, Modal, Popover, styled, TextField, Typography
} from "@mui/material";
import { Box } from "@mui/system";

import { PRIMARY } from "../../../styles/colors";

export const Title = styled("div")(() => ({
  fontStyle: "normal",
  fontSize: "24px",
  lineHeight: "100%",
  paddingTop: "30px",
  marginLeft: "3%",
  textAlign: "left",
  verticalAlign: "center",
  paragraphSpacing: "10px",
  fontWeight: "bold",
  display: "flex",
  whiteSpace: "nowrap",
  "img:first-of-type": {
    paddingLeft: "20px",
  },
}));

export const StyledBackdrop = styled(Backdrop)`
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
`;

export const IconText = styled("div")`
    margin-right: 10px;
    margin-left: 10px;
    color: ${PRIMARY};
    cursor: pointer;
`;

export const PermissionsBox = styled(Box)`
    width: calc(100vw - 20px);
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    height: 100%;
`;

export const PanelActionBox = styled(Box)`
    display: flex;
`;

export const FormBox = styled(Box)(({
  theme
}) => ({
  padding: theme.spacing(4),
  paddingTop: 0
}));

export const StyledParentCard = styled(Card)`
  max-height: 360px;
  padding: 15px;
  margin-right: 10px;
  margin-left: 20px;
  margin-top: 5px;
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-left: "10px";
  margin-top: "5px";
`;

export const FundContent = styled("div")(() => ({
  marginLeft: "10px",
  marginTop: "5px",
}));

export const InvestorContent = styled("div")(() => ({
  marginLeft: "10px",
  marginTop: "5px",
}));

export const RoleContent = styled("div")(() => ({
  marginLeft: "10px",
  marginTop: "5px",
}));

export const ChipsContent = styled("div")(() => ({
  maxWidth: "90%",
}));

export const StyledChecksCard = styled(Card)`
  max-height: 200px;
  border: 0px;
  overflow: auto;
`;



export const SearchBarInput = styled(TextField)`
  padding: 10px;
  fieldset {
    border-radius: 50px;
    border: 1px solid #919195;
  }

  input {
    width: 100%;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #919195;
  }
`;

export const SearchBarInputPopover = styled(TextField)`
  & .MuiInput-input {
    height: 52px;
  }
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const AutoCompleteInput = styled(Autocomplete)`
  width: 300px;
`;

export const SearchInputAdornment = styled(InputAdornment)`
  padding-left: 10px;
`;

export const ButtonText = styled(Button)`
  font-style: normal;
  font-weight: bold;
  text-transform: initial;
  font-size: 16px;
  line-height: 19px;
`;

export const HeaderTitle = styled("div")`
  text-align: end;
`;

export const AutoCompletePopover = styled(Popover)`
  & .MuiPaper-root {
    border: 1px solid #919195;
  }
`;

export const AutoCompleteDivider = styled(Divider)`
  margin-top: -10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  background: #919195;
`;

export const FormControlCheckAll = styled(FormControlLabel)`
  & .MuiTypography-root {
    font-weight: bold;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  white-space: nowrap;
`;

export const StyledButtonBox = styled(Box)(({ theme }) => ({
  marginRight: "12px",
  display: 'flex',
}));

export const HeaderRecipient = styled(Typography)(({ theme }) => ({
  fontSize: "small",
  fontWeight: "bold",
  marginLeft: "5px",
}));

export const ListRecipient = styled(Typography)(({ theme }) => ({
  fontSize: "small",
  marginLeft: "5px",
}));

export const ContactRecipient = styled(Typography)(({ theme }) => ({
  fontSize: "small",
  marginLeft: "20px",
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  fontSize: "small",
  display: 'flex',
  justifyContent: 'center',
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  position: 'absolute' as 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
}));