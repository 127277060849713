import { Box, styled } from '@mui/material';

export const SectionContainer = styled(Box)(({
    theme
}) => ({
    display: 'grid',
    gridGap: theme.spacing(1),
    marginTop: theme.spacing(3)
}));

export const MainContainer = styled(Box)(({
    theme
})=>({
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(2)
}));

export const AddRowContainer = styled(Box)(({
    theme
})=>({
    padding: theme.spacing(2,3.5),
    display: "grid",
    gridTemplateColumns: "0.7fr 2fr 1.5fr",
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
}));

export const ReportContainer = styled(Box)(({
    theme
})=>({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gridGap: theme.spacing(1)
}));

export const SpiltButtonContainer = styled(Box)`
    display: flex;
    justify-content: flex-start;
`;
export const ReportConfigurationDataContainer = styled(Box)(({
    theme
}) => ({
    padding: theme.spacing(2.5)
}));     

export const ReportConfigurationDataLabel = styled(Box)(({
    theme
}) => ({
    display: "grid",
    gridTemplateColumns: "0.3fr 0.4fr 1.2fr 0.5fr 0.1fr",
    gridGap: theme.spacing(2.5),
    alignItems: "end",
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    paddingBottom: theme.spacing(2.5)
}));     
 
export const TextFieldsContainer = styled(Box)(({
    theme
})=>({
    display: "grid",
    flexWrap: "wrap",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3,minmax(0,1fr))",
    paddingRight: theme.spacing(5)
}));


export const FontStyleContainer = styled(Box)`
    display: grid;
    grid-template-columns: 0.4fr 1fr 1fr;
    align-items: center;
`;

export const FontStyleBox = styled(Box)(({
    theme
}) => ({
    display: "flex",
    gridGap: theme.spacing(1.8)
}));    

export const IncDecBox = styled(Box)(({
    theme
})=>({
    display: "flex",
    gridGap: theme.spacing(2.2)
}));