import { DeleteOutlined, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
} from "@mui/material";
import React, { useRef } from 'react';

import {
  DeleteButton,
  IconButtonWithBackground,
  ImgDisplayBox,
  PreviewActionContainer, PreviewChangeButton,
  StyledPreviewImageOverlay,
  StyledPreviewImageOverlayContainer,
  StyledPreviewImg,
  UploadFileInput,
} from "./FileSelector.style";

type Props={
  id?: string,
  url?: string;
  clearImage: () => void;
  showFullScreenPreview?: boolean;
  overlayColor?: string;
  overlayColorOpacity?: number | string;
  previewWidth?: string | number;
  previewHeight?: string | number;
  showDeleteButton?: boolean;
  selectNewImageOnChange?: boolean;
  onUpload?: Function;
};

const FilePreview = ({
  id = 'preview',
  url,
  clearImage,
  showFullScreenPreview = false,
  showDeleteButton = true,
  overlayColor,
  overlayColorOpacity,
  previewHeight,
  previewWidth,
  selectNewImageOnChange = false,
  onUpload = () => {},
}: Props) => {
  const buttonRef = useRef<HTMLInputElement>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target?.files?.length > 0) {
      const files:FileList = event.target?.files;

      onUpload(URL.createObjectURL(files[0]), files);
    }
  };

  const handleUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    e?.preventDefault();

    buttonRef.current?.click();
  };
  
  if(!url) {
    return null;
  }

  return(
    <Box>
      <ImgDisplayBox>
        <StyledPreviewImageOverlayContainer>
          <StyledPreviewImg
            src={url || ''}
            alt="Preview"
            previewHeight={previewHeight}
            previewWidth={previewWidth} />
          <StyledPreviewImageOverlay
            overlayColor={overlayColor}
            overlayOpacity={overlayColorOpacity}/>
        </StyledPreviewImageOverlayContainer>
        { showDeleteButton && <DeleteButton>
          <IconButtonWithBackground
            aria-label="Delete"
            color="secondary"
            id={`${id}_delete`}
            onClick={clearImage}
          >
            <DeleteOutlined color="primary"/>
          </IconButtonWithBackground>
        </DeleteButton> }
        <PreviewActionContainer>
          <PreviewChangeButton
            id={`${id}_edit_button`}
            variant="text"
            disableElevation
            startIcon={<Edit />}
            onClick={selectNewImageOnChange ? handleUpload: clearImage}
          >
            Change Image
          </PreviewChangeButton>
          {showFullScreenPreview && (
            <Button
              id={`${id}_preview_button`}
              variant="text"
              disableElevation
              color="primary"
              startIcon={<Visibility />}
              onClick={clearImage}
            >
                Preview Background
            </Button>
          )}
        </PreviewActionContainer>
      </ImgDisplayBox>
      <UploadFileInput
        type="file"
        id={`image_upload_input`}
        ref={buttonRef}
        onChange={onChange}
      />
    </Box>
  );
};

export default FilePreview;
