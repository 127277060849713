import { Typography } from '@mui/material';
import {
  GridColumnOrderChangeParams,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { GridAlignment } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import {
  CreditRange,
  DebitRange,
  EndBalanceRange,
  ReportAccount,
  StartBalanceRange,
} from '../../pages/arkGL/reports/glReports/shared';
import {
  CurrencyFormat,
  NumberFormat,
} from '../../utils/helpers/format.helper';
import { generateUUID } from '../../utils/helpers/uuidGenerator';
import { DataGridColDef } from '../../utils/types/listItems';
import HeaderSelectionPopover from '../DataGrid/headerSelectionPopover/HeaderSelectionPopover';
import { DandDitem } from '../DragAndDropList/DragAndDropList';
import { ActionLink } from '../Link/ActionLink/ActionLink';
import { CategorySelectionPopover } from './categorySelectionPopover/CategorySelectionPopover';
import {
  CategoryFieldDef,
  GridData,
  GridRow,
  GridRowCategorySource,
  LevelColors,
  ReportGridBaseData,
  ValueFieldDef,
} from './ReportGrid.types';

enum ColRefreshState {
  InitialState,
  NeedsToRun,
  RanOnce,
}

enum GlViews {
  trialBalance = 'trial_balance',
  balanceSheet = 'balance_sheet',
  balanceSheetFsMapping = 'balance_sheet_fs_mapping',
  incomeStatement = 'income_statement',
  incomeStatementFsMapping = 'income_statement_fs_mapping',
}

export const useReportGrid = (
  baseData: ReportGridBaseData[],
  userCategoryFieldOrder: CategoryFieldDef[],
  userDataColDefs: ValueFieldDef[],
  summaryRowCategoryName: string,
  hideUseerCategorySelector: boolean,
  rollupMode: boolean,
  categoryColumnWidth: number,
  onDataGridChange: (gridData: GridData) => void,
  onCategoryFieldOrderChange: (categoryFieldOrder: CategoryFieldDef[]) => void,
  onDataFieldOrderChange: (
    valueFieldOrder: ValueFieldDef[],
    fieldName?: string
  ) => void,
  onCategoryLinkButtonClick?: (gridRow: GridRow) => void,
  onValueLinkButtonClick?: (
    gridRow: GridRow,
    valueFieldOrder: ValueFieldDef
  ) => void,
  reportView?: string,
  suppressIfZero?: boolean,
  currentDecimals?: number,
  currentCurrency?: string,
  footerRollUp?: boolean,
  hideFooter?: boolean,
  fsView?: boolean,
  roundedReportActivated?: boolean
) => {
  const [gridData, setGridData] = useState<GridData>();
  const [initalFieldorderChage, setInitalFieldorderChage] = useState(
    ColRefreshState.InitialState
  );

  useEffect(() => {
    const newGridData = getGridData();

    setGridData(cloneDeep(newGridData));
    onDataGridChange(cloneDeep(newGridData));

    if (
      initalFieldorderChage === ColRefreshState.InitialState &&
      newGridData.dataGridColDefs.length > 2
    ) {
      // because of datagrid bug not initially hiding col set to be hidden we'll force refresh but only once
      setInitalFieldorderChage(ColRefreshState.NeedsToRun);
    }
  }, [
    baseData,
    userCategoryFieldOrder,
    userDataColDefs,
    summaryRowCategoryName,
    hideUseerCategorySelector,
  ]);

  useEffect(() => {
    // force refresh here.  can't do it above otherwise we'll end up in endless loop
    if (initalFieldorderChage === ColRefreshState.NeedsToRun) {
      onDataFieldOrderChange(cloneDeep(userDataColDefs));
      setInitalFieldorderChage(ColRefreshState.RanOnce);
    }
  }, [initalFieldorderChage]);

  function getGridData(): GridData {
    if (!baseData?.length) return { gridRows: [], dataGridColDefs: [] };

    let gridRows: GridRow[] = [];
    let rowId = 0;

    baseData?.forEach((baseDataRow) => {
      const currDataCategories: GridRowCategorySource[] = [];
      let currDataCategoryPath = '';

      const visibleCategories = userCategoryFieldOrder
        .filter((cfo) => cfo.visible)
        .sort((a, b) => a.order - b.order);

      // for each baseData, go though each user specified category and find its place
      visibleCategories.forEach((cfo, index) => {
        // @ts-ignore: expression error
        const currDataRowCat = baseDataRow.categoryProps[cfo.name];

        if (!currDataRowCat) return;

        const nextCfo = visibleCategories[index + 1];
        const isLastCategory = !!(nextCfo
          ? baseDataRow.categoryProps[nextCfo.name]
          : undefined);
        const rollupCurrCatValues = rollupMode || !isLastCategory;

        const currLevel = index + 1;
        const parentDataCatPath = currDataCategoryPath;

        currDataCategoryPath = `${currDataCategoryPath}/${currDataRowCat}`;
        currDataCategories.push({
          entityId: baseDataRow.categoryProps[cfo.id],
          categoryName: baseDataRow.categoryProps[cfo.name],
          categorySource: cfo.name,
        });

        let gridRowToUpdate: GridRow | undefined = undefined;
        let rowInsertPos = -1;

        rowInsertPos = gridRows.findIndex(
          (r) =>
            parentDataCatPath === r.parentCategoryPath &&
            currDataRowCat <= r.categoryName
        );
        if (rowInsertPos === -1) {
          // if we make it here then we need to find position of end of parent.
          // This will also handle parents that are currently empty
          const parentCatPos = gridRows.findIndex(
            (r) =>
              parentDataCatPath === `${r.parentCategoryPath}/${r.categoryName}`
          );

          for (let r = parentCatPos + 1; ; r++) {
            const tmpRow = gridRows[r];

            if (!tmpRow || tmpRow.level < currLevel) {
              rowInsertPos = r;
              break;
            }
          }
        } else {
          // if we make it here then we know rowInsertPos is either the exact position or place we need to insert
          const tmpRow = gridRows[rowInsertPos];

          if (tmpRow.categoryName === currDataRowCat) {
            gridRowToUpdate = tmpRow; // we found exact position so we'll just update gridRowToUpdate data values
          }
        }

        if (!gridRowToUpdate) {
          // create and insert new GridRow
          const newGridRow: GridRow = {
            id: (rowId++).toString(),
            entityId: baseDataRow.itemId,
            categorySource: cfo.name,
            categoryName: currDataRowCat,
            parentCategoryPath: parentDataCatPath,
            parentCategorySources: cloneDeep(currDataCategories),
            level: currLevel,
            currencyCode: baseDataRow.currencyCode
              ? baseDataRow.currencyCode
              : currentCurrency!,
            isCategoryLinkButton: cfo.useLinkButton,
            callerDataArray: [],
            decimals: baseDataRow?.decimals,
            footerMethod: baseDataRow?.footerMethod,
          };

          gridRows.splice(rowInsertPos, 0, newGridRow);

          gridRowToUpdate = newGridRow;
        }

        if (rollupCurrCatValues) {
          gridRowToUpdate.callerDataArray.push(baseDataRow.callerData);

          userDataColDefs.forEach((dataField) => {
            if (dataField.numberFormat === 'Text') {
              // @ts-ignore: expression error
              gridRowToUpdate[dataField.name] = isLastCategory
                ? ''
                : baseDataRow.dataProps[dataField.name];

              return;
            }

            if (baseDataRow.dataProps[dataField.name] === undefined) return;

            let valueToAdd = 0;

            try {
              // @ts-ignore: expression error
              valueToAdd = baseDataRow.dataProps[dataField.name];
              if (isNaN(valueToAdd)) {
                valueToAdd = 0;
              }
            } catch {
              valueToAdd = 0;
            }
            // @ts-ignore: expression error
            gridRowToUpdate[dataField.name] =
              (gridRowToUpdate![dataField.name] ?? 0) + valueToAdd;
          });
        }
      });
    });

    if (!hideFooter) {
      const footerRow: GridRow = {
        id: (rowId++).toString(),
        entityId: '',
        categorySource: '',
        categoryName: summaryRowCategoryName,
        parentCategoryPath: '',
        parentCategorySources: [],
        level: 1,
        currencyCode: (baseData.find((x) => x.currencyCode)
          ?.currencyCode as string)
          ? (baseData.find((x) => x.currencyCode)?.currencyCode as string)
          : currentCurrency!,
        isCategoryLinkButton: false,
        callerDataArray: [],
        decimals: currentDecimals,
      };

      // Make sure to include parent values or not based on rollupMode or footerRollUp mode set from Report List View hooks
      userDataColDefs
        .filter((dataField) => dataField.numberFormat !== 'Text')
        .forEach((dataField) => {
          const rowsToSum =
            rollupMode || footerRollUp
              ? gridRows.filter(
                  (r) => r.level === 1 && r.footerMethod === undefined
                )
              : gridRows.filter((r) => r.footerMethod !== undefined);
          const rowsToSubtract =
            rollupMode || footerRollUp
              ? gridRows.filter(
                  (r) => r.level === 1 && r.footerMethod === 'SUBTRACT'
                )
              : gridRows.filter((r) => r.footerMethod === 'SUBTRACT');
          const sumValToAdd = rowsToSum.reduce(
            (p, c) =>
              p + (!isNaN(c[dataField.name]) ? c[dataField.name] : 0) ?? 0,
            0
          );
          const sumValToSubtract = rowsToSubtract.reduce(
            (p, c) =>
              p + (!isNaN(c[dataField.name]) ? c[dataField.name] : 0) ?? 0,
            0
          );

          // @ts-ignore: expression error
          footerRow[dataField.name] = sumValToAdd - sumValToSubtract;
        });

      gridRows.push(footerRow);
    }

    const tempColHeaders = buildColumnHeaders();

    if (
      fsView ||
      reportView === GlViews.balanceSheet ||
      reportView === GlViews.balanceSheetFsMapping ||
      reportView === GlViews.incomeStatement ||
      reportView === GlViews.incomeStatementFsMapping
    ) {
      const updated = formatFsGridData(cloneDeep(gridRows));

      gridRows = updated;
    }

    const newGridData = {
      gridRows: cloneDeep(gridRows),
      dataGridColDefs: cloneDeep(tempColHeaders),
    };

    return newGridData;
  }

  function formatFsGridData(rows: GridRow[]) {
    //NOTE: Using 3 for the for loop because there are three attribute sections in custom BS and IS reports
    for (let i = 0; i <= 3; i++) {
      if (rows.filter((row) => row.categoryName.startsWith(`${i}|`)).length) {
        const headerRow = rows.filter((row) =>
          row.categoryName.startsWith(`${i}|`)
        );
        const totalRow = cloneDeep(headerRow[0]);

        Object.keys(headerRow[0]).forEach((key) => {
          if (
            typeof headerRow[0][key] === 'number' &&
            key !== 'decimals' &&
            key !== 'level'
          )
            headerRow[0][key] = null;
        });

        totalRow.categoryName = `Total ${totalRow.categoryName.split('|')[1]}`;
        totalRow.id = generateUUID();

        let lastIndex = -1;

        for (let i = rows.length - 1; i >= 0; i--) {
          if (
            rows[i].parentCategorySources.length &&
            rows[i].parentCategorySources[0].categoryName ===
              headerRow[0].categoryName
          ) {
            lastIndex = i;
            break;
          }
        }

        rows.splice(lastIndex + 1, 0, totalRow);
      }
    }

    return rows;
  }

  function buildColumnHeaders() {
    const tempColHeaders: DataGridColDef[] = [];

    userDataColDefs
      .sort((a, b) => a.order - b.order)
      .forEach((dataField) => {
        tempColHeaders.push({
          index: dataField.order,
          field: dataField.name,
          headerName: dataField.headerName,
          headerAlign: 'center' as GridAlignment,
          editable: false,
          disableReorder: false,
          minWidth: 175,
          sortable: false,
          resizable: true,
          align: 'right' as GridAlignment,
          flex: 150,
          hide: !dataField.visible,
          renderCell: (params: GridRenderCellParams) => {
            const dataField = userDataColDefs.find(
              (f) => f.name === params.colDef.field
            )!;
            const row = params.row as GridRow;
            let value = params.value;
            let numberFormat =
              value || value === 0 ? dataField.numberFormat : ''; // if there is no value then display empty string
            let valueFormated: string;

            if (roundedReportActivated) {
              value = Math.round(value);
              numberFormat = 'Currency.0';
            }

            if (
              reportView === GlViews.incomeStatement ||
              reportView === GlViews.incomeStatementFsMapping ||
              reportView === GlViews.balanceSheet ||
              reportView === GlViews.balanceSheetFsMapping
            ) {
              if (value === undefined && row.categoryName !== 'Totals') {
                value = '';
                numberFormat = '';
              }
            }

            switch (numberFormat) {
              case 'Currency.0':
                valueFormated = CurrencyFormat(row.currencyCode, 0).format(
                  value
                );
                break;
              case 'Currency.1':
                valueFormated = CurrencyFormat(row.currencyCode, 1).format(
                  value
                );
                break;
              case 'Currency.2':
                valueFormated = CurrencyFormat(row.currencyCode, 2).format(
                  value
                );
                break;
              case 'Currency.3':
                valueFormated = CurrencyFormat(row.currencyCode, 3).format(
                  value
                );
                break;
              case 'Currency.4':
                valueFormated = CurrencyFormat(row.currencyCode, 4).format(
                  value
                );
                break;
              case 'Integer-use_commas':
                valueFormated = NumberFormat(0, true).format(value);
                break;
              case 'Decimal-use-comas.2':
                if (String(value).includes('.')) {
                  valueFormated = NumberFormat(2, true).format(value);
                } else {
                  valueFormated = NumberFormat(0, true).format(value);
                }
                break;
              default:
                valueFormated = value;
            }

            const typographyElement = (
              <Typography
                align={'right'}
                fontWeight={row.level === 1 ? 'bold' : 'normal'}
                fontSize={row.level === 1 ? '1.15rem' : 'normal'}
              >
                {valueFormated}
              </Typography>
            );

            return (
              <>
                {dataField.useLinkButton && row.entityId ? (
                  <ActionLink
                    id={`link_col_${dataField.name}`}
                    onClick={() => {
                      if (onValueLinkButtonClick)
                        onValueLinkButtonClick(row, dataField);
                    }}
                  >
                    {typographyElement}
                  </ActionLink>
                ) : (
                  typographyElement
                )}
              </>
            );
          },
        });
      });

    insertBaseDefCols(tempColHeaders);

    return tempColHeaders;
  }

  function insertBaseDefCols(catColDefs: DataGridColDef[]) {
    const dAndDitems = userCategoryFieldOrder.map((colField) => {
      return {
        id: colField.name,
        name: colField.headerName,
        data: colField,
      } as DandDitem;
    });

    const categoryColDef: DataGridColDef = {
      index: 1,
      field: '_categoryName',
      editable: fsView ? true : false,
      disableReorder: true,
      headerName: 'Categories',
      width: categoryColumnWidth,
      minWidth: 225,
      sortable: false,
      resizable: true,
      flex: 250,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.categoryName;
      },
      renderCell: (params: GridRenderCellParams) => {
        const row: GridRow = params.row;
        const levelColor = LevelColors[row.level - 1];
        const catNameParts = row.categoryName.split('|');
        const categoryName = catNameParts[catNameParts.length - 1];

        const typographyElement = (
          <Typography
            align="left"
            fontWeight={row.level === 1 ? 'bold' : 'normal'}
            color={levelColor}
            marginLeft={`${row.level * 25}px`}
          >
            {categoryName}
          </Typography>
        );

        return (
          <>
            {row.isCategoryLinkButton && row.id ? (
              <ActionLink
                id={`link_category_button_${row.id}`}
                onClick={() => {
                  if (onCategoryLinkButtonClick) onCategoryLinkButtonClick(row);
                }}
              >
                {typographyElement}
              </ActionLink>
            ) : (
              typographyElement
            )}
          </>
        );
      },
    };

    if (!hideUseerCategorySelector) {
      // Would have prefered this be place above with other props
      // but for some reason thefonts dont show up correctly
      categoryColDef.renderHeader = (_params) => {
        return (
          <CategorySelectionPopover
            dAndDitems={dAndDitems}
            onChange={handleDandDItemsChange}
            onVisibleChange={handleDandDVisibleChange}
          />
        );
      };
    }

    catColDefs.splice(0, 0, categoryColDef);

    // Pass the handleFieldFilterChange function to manage grouped view toggles on report list view hooks

    const fieldFilterColDef: DataGridColDef = {
      index: catColDefs.length + 1,
      field: '_fieldFilterColDef',
      editable: false,
      disableReorder: true,
      headerName: '',
      width: 70,
      minWidth: 32.5,
      sortable: false,
      resizable: false,
      renderHeader: (_params) => {
        return (
          <HeaderSelectionPopover
            id="data_grid_column_selector"
            headerFields={catColDefs.filter(
              (c) =>
                c.field !== '_categoryName' &&
                c.field !== '_fieldFilterColDef' &&
                c.field !== 'Account_Number'
            )}
            handleUpdateHeader={handleFieldFilterChange}
            gridCategory={'gl_reports_data_grid'}
          />
        );
      },
      renderCell: (_params: GridRenderCellParams) => {
        return <></>;
      },
    };

    catColDefs.push(fieldFilterColDef);
  }

  function handleDandDVisibleChange(itemId: string) {
    const catField = userCategoryFieldOrder.find(
      (field) => field.name === itemId
    )!;

    catField.visible = !catField.visible;

    onCategoryFieldOrderChange(userCategoryFieldOrder);
  }

  function handleDandDItemsChange(items: DandDitem[]) {
    const newCategoryFieldOrder = items.map((item, index) => {
      const fieldDef = item.data as CategoryFieldDef;

      fieldDef.order = index + 1;
      return fieldDef;
    });

    onCategoryFieldOrderChange(newCategoryFieldOrder);
  }

  function handleFieldFilterChange(fieldName: any) {
    onDataFieldOrderChange(cloneDeep(userDataColDefs), fieldName);
  }

  function handleColumnOrderChange(changeParams: GridColumnOrderChangeParams) {
    const { oldIndex, targetIndex } = changeParams;

    const sourceCol = userDataColDefs.find(
      (field) => field.order === oldIndex
    )!;
    const targetCol = userDataColDefs.find(
      (field) => field.order === targetIndex
    )!;

    targetCol.order = oldIndex;
    sourceCol.order = targetIndex;

    userDataColDefs.sort((a, b) => a.order - b.order);

    onDataFieldOrderChange(cloneDeep(userDataColDefs));
  }

  return {
    gridData,
    handleColumnOrderChange,
  };
};
