import { ClientTeamList, RolesList, TeamCreateUpdateParams, TeamMemberParams, TeamsList } from "../utils/types/team.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2Admin); };

export const getTeams = async () => await apiClient().get<TeamsList>('clients/teams');

export const getClientTeams = async (clientId: string) => await apiClient().get<ClientTeamList>(`clients/${clientId}/teams`);

export const getRoles = async () => await apiClient().get<RolesList>('teams/roles');

export const addTeam = async (params: TeamCreateUpdateParams) => await apiClient().post("/teams", params);

export const editTeam = async (params: TeamCreateUpdateParams) => await apiClient().put(`/teams/${params.id}`, params);

export const addTeamMember = async (params: TeamMemberParams) => await apiClient().post(`/teams/${params.teamId}/team-members`, params);

export const editTeamMember = async (params: TeamMemberParams) => await apiClient().put(`/teams/${params.teamId}/team-members/${params.employeeId}`, params);

export const getTeamMembers = async (teamId: string) => await apiClient().get<ClientTeamList>(`teams/${teamId}`);

export const deleteTeam = async (teamId: string) => await apiClient().delete(`/teams/${teamId}`);

export const deleteTeamMember = async (teamId: string, memberId: string) => await apiClient().delete(`/teams/${teamId}/team-members/${memberId}`);