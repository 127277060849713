import React, { ReactElement } from "react";

import { StyledTab, StyledTabs } from "./TabsPanel.styles";

type tabOption = string | { label: string; disableCheck: boolean }
type Props = {
  id: string;
  value: number;
  handleChange: any;
  options: tabOption[]
};

const TabsPanel: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id, value, handleChange, options
  } = props;

  return (
    <StyledTabs
      id={id}
      value={value}
      onChange={handleChange}
      aria-label="Investor details panel"
    >
      {options.map((option: tabOption) => {
        const tabLabel: string = typeof option === 'string' ? option : option?.label;

        return (
          <StyledTab
            key={tabLabel}
            label={tabLabel}
            id={`${id}_${tabLabel.toLowerCase().replace(' ', '')}`}
            aria-controls={`${id}_${tabLabel.toLowerCase().replace(' ', '')}`}
            disabled={typeof option === 'string' ? false : option?.disableCheck as boolean}
          />
        );
      })}
    </StyledTabs>
  );
};

export default TabsPanel;
