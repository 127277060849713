export type CustomProperty = {
  tenantId?: string;
  id: string;
  name: string;
  description?: string;
  type: any;
  formType: any;
  values: string[] | any;
  formattingType?: string | null;
  formattingValue?: string | null;
}

export enum CustomPropertyDetailsViewType {
  New = 'new',
  Edit = 'edit',
}
export enum CustomPropertyCRUDStatus {
  Adding = 'Adding',
  Updating = 'Updating',
  Deleting = 'Deleting',
  Loading = 'Loading'
}

export type SelectedCustomProperty = {
  customProperty?: CustomProperty;
  viewType?: CustomPropertyDetailsViewType;
}