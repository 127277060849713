import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Typography } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { StringCell } from "../../../components/DataGrid/DataGrid.styles";
import { AppContext } from "../../../core/context/appContextProvider";
import { downloadFilesAndFolder } from "../../../services/documents.service";
import { usePortalDistributionsEffect } from "../../../services/hooks/useClientPortal/useDistributionEffect.hooks";
import { M_DASH_UNICODE } from "../../../utils/constants/constants";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { DateTimeFormat, NegativeCurrencyFormat } from "../../../utils/helpers/format.helper";
import { PortalDistributionExportActions, PortalDistributions } from "../../../utils/types/distributions.type";
import { DistributionFilter } from "../../../utils/types/filter.type";
import { CustomType, DataGridColDef, ImageItem } from "../../../utils/types/listItems";
import { CellBox } from "../../capitalAccounts/capitalAccountList/CapitalAccountList.styles";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "investorName",
    headerName: "Investor Name",
    hide: false,
    index: 1,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "fundName",
    headerName: "Fund Name",
    hide: false,
    index: 2,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "transactionName",
    headerName: "Distribution Name",
    hide: false,
    index: 3,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "amount",
    headerName: "Amount",
    hide: false,
    index: 4,
    type: "number",
    customType: CustomType.Currency,
    currencyCodeField: "currencyCode",
    align: "right" as GridAlignment,
    width: 300,
  },
  {
    field: "distributionDate",
    headerName: "Date",
    hide: false,
    index: 5,
    renderCell: (params) => {
      const date = params?.row?.distributionDate ?? "";

      if (!date) {
        return (
          <StringCell variant="body2">
            {M_DASH_UNICODE}
          </StringCell>
        );
      }
      const formattedData = DateTimeFormat.shortDate(date);

      return (
        <StringCell variant="body2">
          {formattedData}
        </StringCell>
      );
    },
    type: "string",
    align: "right" as GridAlignment,
    width: 150,
  },
  {
    field: "action",
    headerName: "Statement",
    hide: false,
    hideable: false,
    index: 6,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];

export const usePortalDistribution = () => {
  const [list, setList] = useState<PortalDistributions[]>();
  const [headerList, setHeaderList] =
    useState<Array<DataGridColDef>>(defaultHeaderList);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [selectedDistribution, setSelectedDistribution] = useState<
    PortalDistributions | undefined
  >(undefined);

  const { state } = useContext(AppContext);

  const {
    selectedQuarter,
    quaterList,
    portalDistribution,
    investorList,
    fundList,
    transactionList,
    fileIds,
    isLoading,
    fetchPortalDistribution,
    fetchPortalDistributions,
    downloadDistribution,
  } = usePortalDistributionsEffect();

  const investors = useMemo(
    () => investorList?.map((item) => item.id),
    [investorList]
  );
  const funds = useMemo(() => fundList?.map((item) => item.id), [fundList]);
  const transactions = useMemo(
    () => transactionList?.map((item) => item.id),
    [transactionList]
  );

  const bulkActions: ImageItem[] = [
    {
      id: PortalDistributionExportActions.AllDistributionReportExcel,
      text: "Distributions Report, all (Excel)",
      icon: undefined,
      optionsSelected: 0,
    },
    {
      id: PortalDistributionExportActions.AllDistributionReportPDF,
      text: "Distributions Report, all (PDF)",
      icon: undefined,
      optionsSelected: 0,
    }
  ];

  const getSelectedFilters = useCallback(
    (currentHeaderList: DataGridColDef[]) => {
      return currentHeaderList?.reduce(
        (acc: Record<DistributionFilter, string[] | undefined>, header) => {
          if (
            header.inlineFilter &&
            header.inlineFilterSelected &&
            header.inlineFilterSelected?.length > 0
          ) {
            return {
              ...acc,
              [header.inlineFilterName ?? ""]: header.inlineFilterSelected?.map(
                (item) => item.toLowerCase()
              ),
            };
          }
          return acc;
        },
        {
          [DistributionFilter.InvestorId]: undefined,
          [DistributionFilter.FundId]: undefined,
          [DistributionFilter.TransId]: undefined,
        }
      );
    },
    []
  );

  useEffect(() => {
    const {
      [DistributionFilter.InvestorId]: selectedInvestors,
      [DistributionFilter.FundId]: selectedFunds,
      [DistributionFilter.TransId]: selectedTrans,
    } = getSelectedFilters(headerList);

    const currentList =
      portalDistribution?.filter(
        (data: PortalDistributions) =>
          selectedInvestors?.includes(data.investorId?.toLowerCase() || "") &&
          selectedFunds?.includes(data.fundId?.toLowerCase() || "") &&
          selectedTrans?.includes(data.transactionId?.toLowerCase() || "")
      ) ?? [];

    setList(currentList);
  }, [portalDistribution, getSelectedFilters, headerList]);

  const initializeHeaderList = () => {
    const updatedHeader = defaultHeaderList.map((header, i) => {
      let newHeader = header;

      if (header.field === "amount") {

        newHeader = {
          ...newHeader,
          renderCell: ({ row, value }) => {

            const formatter = NegativeCurrencyFormat(row.fundCurrencyCode || "USD", 0);

            return formatter.format(value);
          }
        };
      }
      if (header.headerName === "Date") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: "distributionDate",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: quaterList || [],
          inlineFilterSelected: [],
          singleSelectFilter: true,
        };
      }

      if (header.field === "investorName") {
        newHeader = {
          ...newHeader,

          inlineFilter: true,
          inlineFilterName: "investorId",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: investorList,
          inlineFilterSelected: investors,
        };
      }

      if (header.field === "fundName") {
        newHeader = {
          ...newHeader,

          inlineFilter: true,
          inlineFilterName: "fundId",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: fundList,
          inlineFilterSelected: funds,
        };
      }

      if (header.field === "transactionName") {
        newHeader = {
          ...newHeader,

          inlineFilter: true,
          inlineFilterName: "transactionId",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: transactionList,
          inlineFilterSelected: transactions,
        };
      }
      if (header.field === "action") {
        newHeader = {
          ...newHeader,
          renderCell: (params: GridRenderCellParams) => {
            if (params?.row?.documentId !== null) {
              return (
                <CellBox>
                  <Button
                    id={`btn_data_grid_${params.row.id}`}
                    variant="text"
                    disableElevation
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleOnView(params.row.id, params.row)}
                    name="View button"
                  >
                    View
                  </Button>
                </CellBox>
              );
            }
            return null;
          },
        };
      }

      return newHeader;
    });

    setHeaderList(updatedHeader);
  };

  useEffect(() => {
    initializeHeaderList();
  }, [quaterList, fundList, portalDistribution, investorList, transactionList]);

  const handleFilter = (filterName: string, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );

    if (filterName === "distributionDate") {
      fetchPortalDistributions(selected.join(","));
    }
  };

  const handleOnView = (id: string, distribution: PortalDistributions) => {
    setSelectedDistribution(distribution);
  };

  const onViewNoticeClose = () => {
    setSelectedDistribution(undefined);
  };

  const exportAllDistributionReportExcel = async () => {
    const ids = list?.map((data) => `${data.investorId}--${data.fundId}--${data.transactionId}`);

    const payload = {
      clientId: state.loginUser.currentUser?.clientId,
      fundId: null,
      fundIds: null,
      fundWithInvestorIds: null,
      ids: ids,
      investorId: null,
      quarter: selectedQuarter,
      transactionCode: null,
      type: "DISTRIBUTION",
      username: state.loginUser.email,
    };

    const response = await downloadDistribution(payload);

    downloadFile(
      response,
      `DISTRIBUTION_Document_Link_Export_${DateTimeFormat.getFormattedDate(
        new Date()
      )}`,
      "csv"
    );
  };

  const exportAllDistributionReportPDF = async () => {
    const fileIdString: string = fileIds.join(",");

    const fileBlob = await downloadFilesAndFolder('', fileIdString);

    if (fileBlob && fileBlob.type) {
      const extension = fileBlob.type.split("/").reverse()[0];

      downloadFile(fileBlob, `download-${DateTimeFormat.getFormattedDate(new Date())}`, extension);
    }
  };

  const handleBulkActions = (actionId: PortalDistributionExportActions) => {
    switch (actionId) {
      case PortalDistributionExportActions.AllDistributionReportExcel:
        exportAllDistributionReportExcel();
        break;
      case PortalDistributionExportActions.AllDistributionReportPDF:
        exportAllDistributionReportPDF();
        break;
    }
  };

  return {
    headerList,
    quaterList,
    portalDistribution,
    list,
    investorList,
    fundList,
    isLoading,
    fetchPortalDistribution,
    selectionModel,
    setSelectionModel,
    handleFilter,
    handleOnView,
    selectedDistribution,
    onViewNoticeClose,
    bulkActions,
    handleBulkActions,
  };
};
