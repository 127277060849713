import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { Box, IconButton, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import ExportIcon from "../../../assets/images/icons/icon_export.svg";
import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../components/DataGrid/DataGrid";
import LockConfirmationDialog from "../../../components/LockConfirmationDialog/LockConfirmationDailog";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import RoutingPaths from "../../../core/routing/routingPaths";
import { ImageItem } from "../../../utils/types/listItems";
import { IconBox } from "../fundList/FundList.styles";
import { CustomFieldDetails } from "./customFieldDetails/customFieldDetails";
import { PortfolioCompanyDetails } from "./portfolioCompanyDetails/portfolioCompanyDetails";
import { ReadOnlyPortfolioCompanyDetails } from "./portfolioCompanyDetails/ReadOnlyPortfolioCompanyDetails";
import { PortfolioCompanyOptions } from "./PortfolioCompanyList.constants";
import { useBulkActionOptionSelectionEffect, usePortfolioCompanies } from "./PortfolioCompanyList.hooks";
import { HeaderRow } from "./PortfolioCompanyList.styles";
import { ButtonBox } from "./PortfolioCompanyList.styles";

export const PortfolioCompanyList: React.FC = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const history = useHistory();

  const {
    fetchAllPortfolioCompanies,
    activeHeaderFields,
    companyFilteredList,
    companySelectionModel,
    headerList,
    readonly,
    clearUploadedFile,
    clearUploadCompleted,
    isCustomFieldPanelOpen,
    isUploadComplete,
    handleFilter,
    handleNewButtonAction,
    handleOnView,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleUploadTemplate,
    onPortfolioCompanyPanelClose,
    onCustomFieldPanelClose,
    isLoading,
    search,
    searchOptions,
    selectedPortfolioCompany,
    selectedCustomProperty,
    setSelectedCustomProperty,
    setCompanySelectionModel,
    showSuggestionPopover,
    setShowSuggestionPopover,
    handleBulkOptionClick,
    client,
    lockedPrompt,
    showLockedDialog,
    setLockedPrompt,
    uploadedFile
  } = usePortfolioCompanies();

  const defaultBulkActions: ImageItem[] = [
    {
      id: "export",
      text: "Export",
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    }];

  const { bulkActionOptions } = useBulkActionOptionSelectionEffect(
    defaultBulkActions,
    companySelectionModel
  );

  return (
    <DataWrapperBox id="portfolio_companies_main" role="main">
      <ProgressModal
        id="modal_portfolio_companies_loading"
        showProgress={isLoading}
      />

      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Portfolio Companies</Typography>
          {client?.soiLocked && (
            <IconButton aria-label="locked" onClick={showLockedDialog}>
              <LockIcon />
            </IconButton>
          )}
        </IconBox>
        <SearchBar
          id="portfolio_companies_search"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActionOptions}
            hidden={companySelectionModel.length === 0}
            handleOptionClick={handleBulkOptionClick}
            ariaLabelMessage="Select bulk action option"
          />

          {!readonly && (
            <ButtonWithOptions
              buttonID="add_portfolio_company_button"
              popoverID="add_portfolio_company_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={PortfolioCompanyOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <DataGrid
        id="companylist_data_grid"
        dataList={
          !isLoading && companyFilteredList?.length > 0
            ? companyFilteredList
            : []
        }
        minHeight="1vh"
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={companySelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setCompanySelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        onColumnOrderChange={onColumnOrderChange}
        handleFilter={handleFilter}
      />
      {!!selectedPortfolioCompany?.type &&
        (readonly ? (
          <ReadOnlyPortfolioCompanyDetails
            selectedPortfolioCompany={selectedPortfolioCompany}
            onDetailClose={onPortfolioCompanyPanelClose}
            fetchAllPortfolioCompanies={fetchAllPortfolioCompanies}
            {...(fundId ? { fundId: fundId } : {})}
          />
        ) : (
          <PortfolioCompanyDetails
            selectedPortfolioCompany={selectedPortfolioCompany}
            onDetailClose={onPortfolioCompanyPanelClose}
            fetchAllPortfolioCompanies={fetchAllPortfolioCompanies}
            {...(fundId ? { fundId: fundId } : {})}
          />
        ))}
      {!!selectedCustomProperty?.viewType &&
        <CustomFieldDetails
          selectedCustomProperty={selectedCustomProperty}
          setSelectedCustomProperty={setSelectedCustomProperty}
          fetchAllPortfolioCompanies={fetchAllPortfolioCompanies}
          onDetailClose={onCustomFieldPanelClose}
        />
      }
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: 'Upload',
            onClick: handleUploadTemplate,
            id: 'btn_upload',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Cancel',
            onClick: clearUploadedFile,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content={<Box component="span">Are you sure you want to upload <strong>{uploadedFile?.name}</strong>?</Box>}
        title="Confirm Upload" />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="confirmation_upload_complete"
        actions={[
          {
            label: 'Upload History',
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: 'btn_upload_history',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Close',
            onClick: clearUploadCompleted,
            id: 'btn_close',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content="Portfolio companies file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the upload."
        title="Upload Results" />

      <LockConfirmationDialog
        lockedPrompt={lockedPrompt}
        setLockedPrompt={setLockedPrompt}
      />
    </DataWrapperBox>
  );
};
