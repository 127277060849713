import { Box, Drawer, styled } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../utils/constants/styles.constants";

export const DetailSections = styled(Box)(({
  theme
}) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  maxWidth: INFO_PANEL_MAX_WIDTH,
}));

export const TabsRow = styled(Box)(({
  theme
}) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  maxWidth: INFO_PANEL_MAX_WIDTH
}));

export const TotalRow = styled(Box)(({
  theme
}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(7),
  paddingTop: theme.spacing(1),
  borderTop: "2px solid",
  maxWidth: INFO_PANEL_MAX_WIDTH
}));

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
    display: "grid",
    gridTemplateRows: "19% 71% 10%",
  }
}));

export const ArrowIconBox = styled(Box)`
  display: flex;
  flex-direction: row;

  h6 {
    margin-left: 8px;
  }
`;