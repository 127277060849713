import { useCallback, useContext, useState } from "react";

import { MultiSelectItem } from "../../../components/MultiSelect/MultiSelect";
import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ReportTemplete } from "../../../utils/types/reports.type";
import { NameIdPair } from "../../../utils/types/transaction.type";
import { getFundsName, getReportTemplete, getTransactionBuckets } from '../../reports.service';

export const useReportEffect = (clientId: string) => {
  const [ fundNameList, setFundNameList ] = useState<MultiSelectItem[]>([]);
  const [ transactionBuckets, setTransactionBuckets ] = useState<NameIdPair[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    informationAlert
  } = useContext(AppContext);

  const fetchReportConfig = useCallback( async (isCanceled: () => boolean) => {
    if(!clientId) return;
    try {
      setLoading(true);
      const [
        fundName,
        bucketsList
      ] = await Promise.all([
        getFundsName(),
        getTransactionBuckets(clientId)
      ]);

      if (isCanceled()) return;

      setFundNameList(fundName);
      setTransactionBuckets(bucketsList);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting report data", "error");
      setLoading(false);
    }
  }, [clientId]);

  useEffectAsync(async (isCanceled) => {
    await fetchReportConfig(isCanceled);
  }, [fetchReportConfig]);

  return {
    fundNameList,
    transactionBuckets,
    commonApiLoading: loading
  };
};

