import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  styled,
  Table,
  TableCell,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export const ConfirmationText = styled("p")(() => ({
  textAlign: "center",
}));

export const MarginFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(0),
}));

export const FirstTableCall = styled(TableCell)(({ theme }) => ({
  paddingRight: theme.spacing(10),
}));

export const MarginFixContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, -4),
}));
export const StyledButton = styled(Button)(() => ({
  width: "20%",
}));
export const ButtonBox = styled(Box)`
  display: flex;
`;
export const Header = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 1fr 1fr",
  marginTop: theme.spacing(3.2),
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(8),
}));

export const ReviewersTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(30),
}));

export const ReviewersHeader = styled(Stack)(({ theme }) => ({
  display: "grid",
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(6),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: theme.spacing(25),
  minWidth: theme.spacing(25),
  padding: theme.spacing(0.5, 1),
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: theme.spacing(0.8),
}));

export const PublishTable = styled(Table)(({ theme }) => ({
  minWidth: theme.spacing(81),
}));

export const ReviewersContainer = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "45vh",
}));

export const ReviewersTable = styled(Box)(({ theme }) => ({
  overflowY: "hidden",
  overflowX: "auto",
  paddingLeft: theme.spacing(5.8),
}));

export const ReviewersCard = styled(Paper)(({ theme }) => ({
  boxShadow: "none",
}));

export const ContentContainer = styled(Stack)(({ theme }) => ({
  display: "inline",
  color: theme.palette.primary.main,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const PublishTableCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: "top",
}));

export const RadioButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const CheckboxFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
