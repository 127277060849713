export const GET_SFTP_VENDOR_LIST_ERROR = "Error in getting SFTP Vendor list.";

export enum ClientTabs {
  ClientDetails = "Client Details",
  TransactionMapping = "Transaction Mapping",
  ReportConfig = "Report Config",
  ConsentConfig = "Consent Config",
  FeatureConfig = "Feature Config",
}

export const CONSENT_OPTIONS = [
  {
    id: "K1",
    name: "K1 Consent",
  },
];

export enum TextAlignment {
  left = "LEFT",
  center = "CENTER",
  right = "RIGHT",
}

export enum ItemData {
  report = "REPORT_NAME",
  Date = "AS_OF_DATE",
}

export enum TextStyles {
  bold = "BOLD",
  italic = "ITALIC",
  underLined = "UNDERLINE",
  doubleUnderLine = "DOUBLE_UNDERLINE",
  singleLineIcon = "OVERLINE",
  doubleLineIcon = "DOUBLE_OVERLINE",
  rightSpace = "RIGHTSPACE",
  leftSpace = "LEFTSPACE",
}

export const ItemListData = [
  {
    id: 1,
    name: "Logo",
    type: "LOGO",
    disable: true,
    alignment: 0,
  },
  {
    id: 2,
    name: "Report Name",
    type: "REPORT_NAME",
    disable: false,
    alignment: 0,
  },
  {
    id: 3,
    name: "As of Date",
    type: "AS_OF_DATE",
    disable: true,
    alignment: 0,
  },
  {
    id: 4,
    name: "Fund Name",
    type: "FUND_NAME",
    disable: true,
    alignment: 0,
  },
  {
    id: 5,
    name: "Investor Name",
    type: "INVESTOR_NAME",
    disable: true,
    alignment: 0,
  },
  {
    id: 6,
    name: "Horizontal Line",
    type: "HORIZONTAL_LINE",
    disable: true,
    alignment: 0,
  },
];

export const ReportColumData = [
  {
    index: 0,
    label: "Quarter To Date",
    reportColumnType: "QUARTER_TO_DATE",
  },
  {
    index: 1,
    label: "Year To Date",
    reportColumnType: "YEAR_TO_DATE",
  },
  {
    index: 2,
    label: "Inception To Date",
    reportColumnType: "INCEPTION_TO_DATE",
  },
  {
    index: 3,
    label: "Trailing 12 Months",
    reportColumnType: "TRAILING_TWELVE_MONTHS",
  },
];
