export enum CapitalAccountsFilter {
  FundIds = 'fundIds',
  InvestorIds = 'investorIds',
  QuarterName = 'quarterName',
}

export type CapitalAccountsParams = {
  quarterName?: string | null;
  offset?: number;
  pageSize?: number;
  isAll?: boolean;
  fundIds?: string[] | null;
  investorIds?: string[] | null;
};

export type CapitalAccount = {
  id?: string;
  investorId: string;
  investorName: string;
  isParent?: string;
  isChild?: string;
  fundId?: string;
  quarter?: string;
  funds: Fund[];
  currencyCode?: string;
  fundName?: string;
  Committed?: number;
  Contributions?: number;
  Distribution?: number;
  Unfunded?: number;
  Value?: number;
};

//API is returning the data with incorrect capitalization
export type Fund = {
  currencyCode: string;
  fundId: string;
  fundName: string;
  hasReport: boolean;
  quarter: string;
  transactions: Transaction[];
  id?: string;
  Committed: number;
  Contributions: number;
  Distribution: number;
  Unfunded: number;
  Value: number;
};

export type Transaction = {
  amount: number;
  code: string;
  index: number;
  label: string;
  metricFractionDigit: number;
  metricSign: string;
  unpublishedCapCalls: string | null;
  unpublishedDistributions: string | null;
  useMetric: boolean;
};

export type SelectedFund = {
  fundId?: string;
  fundName?: string;
  investorId?: string;
  investorName?: string;
  quarter?: string;
  id?: string;
  currencyCode?: string;
};

export type SelectedTransaction = {
  fundId?: string;
  fundName?: string;
  investorId?: string;
  investorName?: string;
  quarter?: string;
  transactionType?: string;
  additionalReportElements?: string[];
  label?: string;
  reportColumnType?: string;
  reportElementType?: string;
  transactionTypes?: string[] | null;
  currencyCode?: string;
  transactionCode?: string;
};

export type StatementReportParams = {
  fundId: string;
  investorId: string;
  quarter: string;
};

export type StatementReportResponse = {
  asOf: Period;
  columns: StatRepColumns[];
  rows: StatRepRows[];
};

export type Period = {
  id: string | null;
  tenantId: string | null;
  startDate: Date;
  endDate: Date;
  name: string;
  qnumber: number;
  quarterNumber: number;
  qyear: number;
  year: number;
};

export type StatRepColumns = {
  index: number;
  label: string;
  reportColumnType: string;
};

export type StatRepRows = {
  index: number;
  label: string;
  numberOfTabs: number;
  includeRemainder: boolean;
  rowsToSum: string[] | null;
  styles: string[];
  suppressIfZero: boolean;
  transactionTypes: string[] | null;
  type: StatRepTransactionType;
  amounts: any | null;
};

export enum StatRepTransactionType {
  BEGINNING = 'BEGINNING',
  SPACER = 'SPACER',
  TRANSACTION = 'TRANSACTION',
  LABEL = 'LABEL',
  SUBTOTAL = 'SUBTOTAL',
  ENDING = 'ENDING',
}

export type TransactionHistoryParams = {
  fundId: string;
  investorId: string;
  quarter: string;
  transactionType?: string;
  transactionCode: string;
};

export type TransactionHistoryResponse = {
  endDate: Date;
  startDate: Date;
  inceptionDate: Date;
  fundName: string;
  investorName: string;
  label: string;
  transactionTypeCode: string;
  transactions: TransactionHistoryEntry[];
};

export type TransactionHistoryEntry = {
  amount: number;
  date: Date;
  metricFractionDigit: number;
  metricSign: string;
  reviews: null;
  type: string;
  typeId: string;
  useMetric: false;
  fundName?: string;
  id?: string;
};

export type FileDownloadTransHistoryParams = SelectedTransaction & {
  clientId: string;
  fundIds: null;
  fundWithInvestorIds: null;
  ids: string[];
  transactionCode?: string;
  type: 'TRANSACTION_HISTORY';
  username?: string | null;
};

export type SummaryDataExportParams = {
  fundIds: string[];
  investorFundIds: (string | null)[];
  investorIds: string[];
  quarterName: string;
};

export type CapAccReportExcelExportParams = {
  investorFundIds: string[];
  quarterName: string;
};

export type CapAccReportPdfExportParams = {
  async: boolean;
  clientId: string;
  fundIds: string[];
  ids: (string | null)[];
  investorIds: string[];
  quarter: string;
  transactionCode: string;
  type: 'CAPITAL_ACCOUNT';
  username: string;
};

export enum CapAccExportActions {
  AllSummaryData = 'all_summary_data',
  SelectedSummaryData = 'selected_summary_data',
  AllCapAccReportExcel = 'all_capacc_report_excel',
  AllCapAccReportPDF = 'all_capacc_report_pdf',
  SelectedCapAccReportExcel = 'selected_capacc_report_excel',
  SelectedCapAccReportPDF = 'selected_capacc_report_pdf',
}

export type Quarter = {
  published: boolean;
  quarter: string;
  quarterId: string;
};

export type PublishFund = {
  fund: PublishFundDetail;
  fundName: string;
  transactions: Fund[];
};

export type PublishFundDetail = {
  active: boolean;
  currency: string;
  id: string;
};
