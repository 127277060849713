import {
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

import IconAddHeader from "../../../assets/images/icons/icon_add_header.svg";
import IconConfig from "../../../assets/images/icons/icon_config.svg";
import IconRemoveHeader from "../../../assets/images/icons/icon_remove_header.svg";
import { CustomType, DataGridColDef } from "../../../utils/types/listItems";
import ArrowPopover from "../../ArrowPopover/ArrowPopover";
import ImgIcon from "../../ImgIcon/ImgIcon";
import { OptionsBox, StyledList } from "./HeaderSelectionPopover.styles";

type Props = {
  id: string;
  headerFields?: Array<DataGridColDef>;
  handleUpdateHeader: Function;
  icon?: string;
  gridCategory?: string;
};

const HeaderSelectionPopover: React.FC<Props> = ({
  id,
  headerFields,
  handleUpdateHeader,
  icon = IconConfig,
  gridCategory,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = (): void => {
    setAnchorEl(null);
  };

  let months = false;
  let quarters = false;
  let years = false;

  if (gridCategory === "gl_reports_data_grid" && headerFields !== undefined) {
    const monthlyFieldNames: any = [];
    const monthlyIndexes: any = [];
    const quarterlyFieldNames: any = [];
    const quarterlyIndexes: any =[];
    const yearlyFieldNames: any = [];
    const yearlyIndexes: any = [];

    headerFields?.map((header: any) => {
      if (header.field.includes("monthly")){
        months = true;
        const data = {
          index: header.index,
          field: header.field,
          headerName: header.headerName,
          hide: header.hide,
        };

        monthlyIndexes.push(header.index);
        monthlyFieldNames.push(data);
      }
      if (header.field.includes("quarterly")){
        quarters = true;
        const data = {
          index: header.index,
          field: header.field,
          headerName: header.headerName,
          hide: header.hide,

        };

        quarterlyIndexes.push(header.index);
        quarterlyFieldNames.push(data);
      }      
      if (header.field.includes("yearly")){
        years = true;
        const data = {
          index: header.index,
          field: header.field,
          headerName: header.headerName,
          hide: header.hide,

        };

        yearlyIndexes.push(header.index);
        yearlyFieldNames.push(data);
      }
    });

    const indexesToRemove: any = monthlyIndexes.concat(quarterlyIndexes, yearlyIndexes);
    const groupHeaderFields: any = headerFields.filter((header: any) => !indexesToRemove.includes(header.index));

    const makeGroupData = (index: any, groupName: any, field: any, fieldNames: any) => {
      const group: any = {
        index: index,
        field: groupName,
        fieldNames: fieldNames,
        fieldName: field,
        headerName: groupName,
        hide: fieldNames[0].hide
      };

      return group;
    };

    const monthlyGroup = months ? (makeGroupData(monthlyIndexes[0], "Monthly", "Months", monthlyFieldNames)) : ([]);
    const quarterlyGroup = quarters ? (makeGroupData(quarterlyIndexes[0], "Quarterly", "Quarterly", quarterlyFieldNames)) : ([]);
    const yearlyGroup = years ? (makeGroupData(yearlyIndexes[0], "Yearly", "Yearly", yearlyFieldNames)) : ([]);

    months ? groupHeaderFields.splice(1, 0, monthlyGroup) : '' ;
    quarters ? groupHeaderFields.splice(2, 0, quarterlyGroup) : '';
    years? groupHeaderFields.splice(3, 0, yearlyGroup) : '';

    headerFields = groupHeaderFields.sort((a: any, b: any) => a.index - b.index);
  }

  return (
    <div>
      <OptionsBox>
        <IconButton
          aria-label="Column Settings"
          color="inherit"
          id={`btn_data_grid_columns_filter_${id}`}
          onClick={handleOnClick}
        >
          <ImgIcon icon={icon} useCursorPointer />
        </IconButton>
      </OptionsBox>
      <ArrowPopover
        id={id}
        anchorEl={anchorEl}
        showPopover={Boolean(anchorEl)}
        handleOnPopoverClose={handleOnClose}
        verticalOrigin="top"
        content={
          <StyledList dense={true}>
            {headerFields?.reduce((prevValue: ReactNode[], currentValue) => {
              if (currentValue?.customType !== CustomType.Action) {
                prevValue.push(
                  <ListItem
                    id={`checkbox-list-label-${currentValue.field}`}
                    key={currentValue.field}
                    secondaryAction={
                      <IconButton
                        id={`${id}_${currentValue.field}`}
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleUpdateHeader(
                            (currentValue?.fieldNames) ? (currentValue?.fieldName) : (currentValue?.field),
                            currentValue?.inlineFilterName
                          )
                        }
                      >
                        <img
                          id={`${id}_${currentValue.field}_img`}
                          src={
                            currentValue.hide ? IconAddHeader : IconRemoveHeader
                          }
                          alt={`${currentValue.hide ? "Add" : "Remove"} header`}
                        />
                      </IconButton>
                    }
                  >
                    <Typography variant="subtitle1">
                      {currentValue.headerName}
                    </Typography>
                  </ListItem>
                );
              }
              return prevValue;
            }, [] as ReactNode[])}
          </StyledList>
        }
      />
    </div>
  );
};

export default HeaderSelectionPopover;
