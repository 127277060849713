import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useContext } from "react";

import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import useRole from "../../core/routing/useRole";
import { PageType } from "../../utils/types/login.type";
import { ADMIN_ROLES } from "../../utils/types/user.type";
import {
  ActionButton,
  ContentDiv,
  HelperText,
  StyledTextField,
} from "./Login.styles";

type TestClient = {
  clientId: string;
  clientName: string;
  key: string;
};

type LoginScreenProps = {
  onLoginUser: (data: any) => void;
  rememberMe: boolean;
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
  loginForm: any;
};

const LoginScreen = ({
  onLoginUser,
  rememberMe,
  setRememberMe,
  setCurrentPage,
  loginForm,
}: LoginScreenProps) => {
  const { clientTheme } = useContext(ClientThemeContext);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = loginForm;

  const { preAuthClientConfigs } = useContext(AppContext);

  const supportEmail = `mailto:${preAuthClientConfigs?.supportEmail || ""}`;

  return (
    <form onSubmit={handleSubmit(onLoginUser)}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={1}
        direction="row"
      >
        <Grid container justifyContent="center">
          <ContentDiv mb={12}>
            Email Address
            <StyledTextField
              size="small"
              required
              id="login_email"
              type="text"
              error={!!errors.email}
              {...register("email")}
              helperText={errors.email?.message}
            />
          </ContentDiv>
        </Grid>
        <Grid container justifyContent="center">
          <ContentDiv mb={12}>
            Password:
            <StyledTextField
              size="small"
              id="login_pass"
              type="password"
              variant="outlined"
              InputProps={{ notched: true }}
              {...register("password")}
              error={!!errors.password}
              helperText={errors?.password?.message}
            />
          </ContentDiv>
        </Grid>
        <Grid container justifyContent="center">
          <ContentDiv>
            <ActionButton
              type="submit"
              variant="contained"
              className="btn"
              disableElevation
              textcolor={clientTheme?.loginButtonTextColor}
              btnbackgroundcolor={clientTheme?.loginButtonBackgroundColor}
              fullWidth
            >
              Submit
            </ActionButton>
            <HelperText>
              <Grid container justifyContent="center" id="linkContainer">
                <Button
                  variant="text"
                  sx={{
                    color: "inherit",
                  }}
                  href={supportEmail}
                >
                  Need Support
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: "inherit",
                  }}
                  onClick={() => {
                    setCurrentPage("ForgotPassword");
                  }}
                >
                  Forgot Password?
                </Button>
              </Grid>
            </HelperText>
          </ContentDiv>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginScreen;
