import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button } from "@mui/material";
import React from "react";

import Filter from "../../../components/DataGrid/Filter/Filter";
import { PopoverFilter } from "../../../components/DataGrid/Filter/PopoverFilter.style";
import { FilterSelectionType } from "../../../utils/types/filter.type";


type Props = {
  value?: any[] | null | string[];
  handleFilter?: (filter: any, selected: any[] | any, selectionType?: FilterSelectionType) => void;
  label?: string;
  size?: "small" | "medium";
  loading?: boolean;
  listData?: []
  filterName: string
  id: string
};

const MultiSelectFilter = ({
  id,
  label,
  handleFilter,
  value,
  filterName,
  listData
}: Props) => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleOnClose = (): void => {
    setAnchorEl(null);
  };

  const onFilter = (filter: any, selectedOptions: Array<any>, selectionType?: FilterSelectionType) => {
    handleOnClose();
    handleFilter?.(filterName, selectedOptions, selectionType);
  };

  const handleOnClick = (
    event: any
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
        <Button
          variant="outlined"
          endIcon={
            <FilterAltIcon/>
          }
          onClick={handleOnClick} >
          {label}
        </Button>
        <PopoverFilter
          id={`${label}_${id}_popover`}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Filter
            handleFilter={onFilter}
            options={listData}
            selectedOptions={value as string[]}
            idField={"id"}
            labelField={"name"}
            filterName={filterName as any}
          />
        </PopoverFilter>
    </Box>
  );
};

export default React.memo(MultiSelectFilter);
