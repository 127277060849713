import React from 'react';
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

import ColorSelector from "../../../../components/ColorSelector/ColorSelector";
import PortalSkeleton from "../../../../components/Skeleton/PortalSkeleton/PortalSkeleton";
import TitleBox from "../../../../components/TitleBox/TitleBox";
import { Client, Theme } from "../../../../utils/types/client.type";
import {
  ColorSelectorContainer,
} from "../ClientDetails.style";

type Props = {
  control: Control<Client, any>;
  theme?: Theme;
}

const PortalThemeConfig = ({
  control
}: Props) => {
  return (
    <TitleBox title="LP Portal Colors">
      <Controller
        name="theme.mainColorHex1"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="main_color_1" value={value} title="Color 1" subTitle="Left navigation" onChange={onChange} />
              <PortalSkeleton navigationColor={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
      <Controller
        name="theme.mainColorHex3"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="main_color_3" value={value} title="Color 2" subTitle="Button" onChange={onChange} />
              <PortalSkeleton buttonColor={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
      <Controller
        name="theme.mainColorHex5"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <ColorSelectorContainer>
              <ColorSelector id="main_color_5" value={value} title="Color 3" subTitle="Header Background" onChange={onChange} />
              <PortalSkeleton headerBackgroundColor={value ? `#${value}` : ""} />
            </ColorSelectorContainer>
          );
        }}
        control={control}
      />
    </TitleBox>
  );
};

export default PortalThemeConfig;
