import { FormControlLabel, Switch, Typography } from "@mui/material";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";

import useRole from "../../../../../core/routing/useRole";
import { M_DASH_UNICODE } from "../../../../../utils/constants/constants";
import { ScopeRole } from "../../../../../utils/types/user.type";
import { useDocumentsContext } from "../../../hooks";
import { NewItemDot } from "./DocumentsGridCells.styles";

type Props = {
  row: any;
};

const CopyCellRenderer: React.FC<Props> = ({ row }: Props): ReactElement => {
  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const isEditable: boolean =
    !!isSuperAdmin || !!isClientAdmin || !!isBasicAdmin;

  const { state, toggleDocControlPermissions } = useDocumentsContext();

  const isPDF: boolean = row.fileType === "pdf";
  const [copyEnabled, setCopyEnabled] = useState<boolean>(
    row.filePermissions?.copyEnabled
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) return;
    setCopyEnabled(row?.filePermissions?.copyEnabled);
  }, [row.filePermissions]);

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setCopyEnabled(e.target.checked);
    const res = await toggleDocControlPermissions(row, {
      copyEnabled: e.target.checked,
    });

    if (res === "error") {
      setCopyEnabled(row?.filePermissions?.copyEnabled);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return !isPDF ? (
    <Typography variant="cells">{M_DASH_UNICODE}</Typography>
  ) : isEditable ? (
    <FormControlLabel
      control={
        <Switch
          checked={copyEnabled}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            handleOnChange(e);
          }}
          disabled={state?.isPageLocked || state?.isReadOnly || loading}
          name="Copy"
        />
      }
      label={copyEnabled ? "Copy On" : "Copy Off"}
    />
  ) : (
    <>
      <NewItemDot
        color={copyEnabled ? "green" : "toggle-grey"}
        sx={{ position: "static" }}
      />
      <Typography>{copyEnabled ? "Copy On" : "Copy Off"}</Typography>
    </>
  );
};

export default CopyCellRenderer;
