import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";

import ContentColumnBox from "../../../components/Boxes/ContentColumnBox/ContentColumnBox";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import {
  BeneficiaryBankInfo,
  ReceivingBankInfo,
  WireInfo,
} from "../../../utils/types/investor.type";
import {
  BankInstructions,
  InfoSection,
} from "../investorList/investorDetailPanel/InvestorDetailPanel.styles";

const renderValue = (value: string | undefined) => (value ? value : "-");
const FieldValue = (label: string, value: string | undefined) => (
  <InfoSection>
    <div>
      <Typography variant="label">{label}</Typography>
    </div>
    <Typography className="field-value">{renderValue(value)}</Typography>
  </InfoSection>
);

const InvestorWireReadOnly = ({
  investorReceivingInfo,
  investorBeneficiaryInfo,
  investorIntermediaryInfo,
  investorWireInfo,
}: {
  investorReceivingInfo: ReceivingBankInfo | undefined;
  investorBeneficiaryInfo: BeneficiaryBankInfo | undefined;
  investorIntermediaryInfo: any;
  investorWireInfo: WireInfo | undefined;
}) => {
  const bankAcountCheck =
    investorIntermediaryInfo?.abaNumber ||
    investorIntermediaryInfo?.addressLine1 ||
    investorIntermediaryInfo?.addressLine2;

  return (
    <Container id="investor_wire_container">
      <br />
      <HorizontalBox>
        <Typography variant="h4">Wire Instructions</Typography>
      </HorizontalBox>
      <HorizontalBox>
        <Typography variant='formTitle2'>Receiving Bank information</Typography>
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Receiving Bank ABA/Swift",
          investorReceivingInfo?.abaNumber ?? "-"
        )}
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue("Receiving Bank Name", investorReceivingInfo?.name ?? "-")}
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Receiving Bank Address 1",
          investorReceivingInfo?.addressLine1 ?? "-"
        )}
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Receiving Bank Address 2",
          investorReceivingInfo?.addressLine2 ?? "-"
        )}
      </HorizontalBox>

      <HorizontalBox>
        <Box>
          <HorizontalBox noPadding>
            <Typography variant="formTitle2">Beneficiary Information</Typography>
          </HorizontalBox>
          {FieldValue(
            "Beneficiary Account",
            investorWireInfo?.beneficiaryAccount ?? "-"
          )}
          {FieldValue(
            "Beneficiary Name",
            investorWireInfo?.beneficiaryName ?? "-"
          )}
          {FieldValue(
            "Beneficiary Address Line 1",
            investorWireInfo?.beneficiaryAddressLine1 ?? "-"
          )}
          {FieldValue(
            "Beneficiary Address Line 2",
            investorWireInfo?.beneficiaryAddressLine2 ?? "-"
          )}
          {FieldValue(
            "Beneficiary Bank  ABA/Swift",
            investorBeneficiaryInfo?.abaNumber ?? "-"
          )}
          {FieldValue(
            "Beneficiary Bank Name",
            investorBeneficiaryInfo?.name ?? "-"
          )}
          {FieldValue(
            "Beneficiary Bank Address 1",
            investorBeneficiaryInfo?.addressLine1 ?? "-"
          )}
          {FieldValue(
            "Beneficiary Bank Address 2",
            investorBeneficiaryInfo?.addressLine2 ?? "-"
          )}
        </Box>
        <Box>
          <HorizontalBox noPadding>
            <InfoSection>
              <br />
              <Typography variant="label">Additional Instructions</Typography>
              <BankInstructions>
                {renderValue(
                  investorWireInfo?.originatorToBeneficiaryInstructions1
                )}
                <br />
                {renderValue(
                  investorWireInfo?.originatorToBeneficiaryInstructions2
                )}
                <br />
                {renderValue(
                  investorWireInfo?.originatorToBeneficiaryInstructions3
                )}
                <br />
                {renderValue(
                  investorWireInfo?.originatorToBeneficiaryInstructions4
                )}
              </BankInstructions>
            </InfoSection>
          </HorizontalBox>
          <HorizontalBox noPadding>
            <InfoSection>
              <Typography variant="label">
                Receiving Bank Information
              </Typography>
              <BankInstructions>
                {renderValue(investorWireInfo?.bankToBankInstructions1)}
                <br />
                {renderValue(investorWireInfo?.bankToBankInstructions2)}
                <br />
                {renderValue(investorWireInfo?.bankToBankInstructions3)}
                <br />
                {renderValue(investorWireInfo?.bankToBankInstructions4)}
              </BankInstructions>
            </InfoSection>
          </HorizontalBox>
        </Box>
      </HorizontalBox>
      <ContentColumnBox>
        <FormControlLabel
          control={
            <Checkbox id="check_bank_account_presence" disabled={true} />
          }
          id="check_bank_account_presence"
          label="Bank Account not in the US."
          checked={bankAcountCheck}
        />
        <FormHelperText>
          Check if your bank account is foreign to the US.
        </FormHelperText>
      </ContentColumnBox>
      <HorizontalBox>
        <Typography variant='formTitle2'>Intermediary Bank Info</Typography>
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Intermediary Bank ABA/Swift",
          investorIntermediaryInfo?.abaNumber ?? "-"
        )}
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Intermediary Bank Address 1",
          investorIntermediaryInfo?.addressLine1 ?? "-"
        )}
      </HorizontalBox>
      <HorizontalBox>
        {FieldValue(
          "Intermediary Bank Address 2",
          investorIntermediaryInfo?.addressLine2 ?? "-"
        )}
      </HorizontalBox>
    </Container>
  );
};

export default InvestorWireReadOnly;
