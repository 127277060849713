import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../core/context/appContextProvider";
import { GET_GL_ACCOUNT_LIST_ERROR } from "../../../pages/arkGL/accounts/accountList/AccountList.constants";
import { getArkAccounts } from "../../../services/arkGL.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const useParentAccountFilterEffect = (accountId?: string) => {
  const { informationAlert } = useContext(AppContext);
  const { fundId } = useParams<{ fundId: string }>();

  const [parentAccountList, setParentAccountList] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    setLoading(true);
    try {
      if (isCanceled?.()) return;

      const response = await getArkAccounts(fundId);

      const accounts = response.items
        .filter((account: any) => account.id !== accountId)
        .map((account: any) => {
          return {
            id: account.id,
            name: `${account.number} - ${account.name}`,
            parentId: account.parentId
          };
        })
        .sort();

      setParentAccountList(accounts);
    } catch (e) {
      informationAlert(GET_GL_ACCOUNT_LIST_ERROR, "error");
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccountList(isCanceled);
  }, []);

  return {
    parentAccountList,
    loading,
  };
};

export default useParentAccountFilterEffect;
