import { Typography } from "@mui/material";

import { TooltipTypography } from "./EditPanel.styles";

interface JSXElementObject {
  download: React.ReactNode;
  print: React.ReactNode;
  copy: React.ReactNode;
}

export const EditPanelTooltipText: JSXElementObject = {
  download: (
    <>
      <TooltipTypography>
        <strong>Keep Existing:</strong> Selecting 'Keep Existing' will retain
        the current download settings for each selected file, ensuring no
        changes are applied during the bulk edit operation.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Download On:</strong> Enabling download allows LP users to
        download the selected PDFs.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Download Off:</strong> Disabling download prevents LP users from
        downloading the selected PDFs.
      </TooltipTypography>
    </>
  ),
  print: (
    <>
      <TooltipTypography>
        <strong>Keep Existing:</strong> Selecting 'Keep Existing' will retain
        the current print settings for each selected file, ensuring no changes
        are applied during the bulk edit operation.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Print On:</strong> Enabling print allows LP users to print the
        selected PDFs.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Print Off:</strong> Disabling print prevents LP users from
        printing the selected PDFs.
      </TooltipTypography>
    </>
  ),
  copy: (
    <>
      <TooltipTypography>
        <strong>Keep Existing:</strong> Selecting 'Keep Existing' will retain
        the current copy settings for each selected file, ensuring no changes
        are applied during the bulk edit operation.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Copy On:</strong> Enabling copy allows LP users to copy text
        from the selected PDFs.
      </TooltipTypography>
      <TooltipTypography>
        <strong>Copy Off:</strong> Disabling copy prevents LP users from copying
        text from the selected PDFs.
      </TooltipTypography>
    </>
  ),
};
