import {
  ClickAwayListener,
  Fade,
  Grow,
  Paper,
} from "@mui/material";
import React, { ReactElement, useContext, useMemo } from "react";
import { CSVLink } from "react-csv";

import Button from "../../../../components/Buttons/Button/Button";
import {
  StyledArrowIcon,
  StyledBox,
  StyledBoxText,
  StyledButton,
  StyledButtonGroup,
  StyledMenuItem,
  StyledMenuList,
  StyledPopper,
} from "../../../../components/Buttons/SplitButton/SplitButton.styles";
import { AppContext } from "../../../../core/context/appContextProvider";
import { ClientThemeContext } from "../../../../core/context/clientThemeContext";
import { CurrencyFormat, DateTimeFormat } from "../../../../utils/helpers/format.helper";
import { DataGridColDef } from "../../../../utils/types/listItems";
import { ScheduleOfInvestmentAction } from "../../../../utils/types/portfolioCompany.type";
import { defaultDataFieldOrder } from "../ScheduleOfInvestments.defaults";

type Props = {
  id: string;
  hidden: boolean;
  noGutter?: boolean;
  headers?: DataGridColDef[];
  data?: Record<string, any>[];
  date?: Date | null;
};

type headerType = {
  label: string;
  key: string;
}

export const ExportButton: React.FC<Props> = ({
  id,
  hidden = false,
  noGutter = false,
  headers,
  data,
  date
}: Props): ReactElement => {
  const {
    clientTheme
  } = useContext(ClientThemeContext);
  const {
    state
  } = useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  data?.forEach(row => {
    if(row['moic']){
      row['moic'] = row['moic'] === Infinity ? '' : parseFloat(row['moic']).toFixed(2) + 'x';
    } else {
      row['moic'] = row['cost'] === 0 ? '' : '0.00x';
    }
  });

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const formatValue = (format: string, currencyCode: string, value: number) => {
    let valueFormatted: string;

    switch (format) {
      case "Currency.0":
        valueFormatted = CurrencyFormat(currencyCode, 0).format(
          value
        );
        break;
      case "Currency.2":
        valueFormatted = CurrencyFormat(currencyCode, 2).format(
          value
        );
        break;
      case "Integer-use_commas":
        valueFormatted = Math.round(value).toLocaleString();
        break;
      default:
        valueFormatted = value.toString();
    }
    return valueFormatted;
  };

  const csvHeaders: headerType[] = useMemo(() => headers?.reduce((acc: headerType[], header: DataGridColDef) => {
    if(header.field !== "_fieldFilterColDef") {
      return [...acc, {
        label: header.headerName || "",
        key: (header?.field === "_categoryName" ? "categoryName" : header.field) || "",
      }];
    }
    return acc;
  }, []), [headers]) || [];

  const csvData = useMemo(() => data?.reduce((acc: Record<string, any>[], row) => {
    const formattedData = defaultDataFieldOrder?.reduce((previousValue, field) => {
      if(row[field?.name]) {
        return {
          ...previousValue,
          [field?.name]: formatValue(field?.numberFormat, row.currencyCode, row[field?.name]),
        };
      }
      return previousValue;
    }, {}) || {};

    if(row.level !== 1) {
      return [...acc, {
        ...row,
        categoryName: `${" "?.repeat(row?.level * 2)}${row.categoryName}`,
        ...formattedData
      }];
    } else {
      return [...acc, {
        ...row,
        ...formattedData
      }];
    }
    return acc;
  }, []), [data]) || [];

  return (
    <React.Fragment>
      <Fade in={!hidden}>
        <StyledButtonGroup
          nogutter={noGutter.toString()}
          variant="contained"
          ref={anchorRef}
          aria-label="export button"
          disableElevation
          id={id}
        >
          <Button
            id={`btn_export`}
            onClick={handleToggle}
            color="secondary"
            size="medium"
            variant="outlined"
            text="Export"
          />
          <StyledButton
            id={`btn_options_menu`}
            size="medium"
            backgroundcolor={clientTheme?.buttonBackgroundColor}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="Open Export Menu"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <StyledArrowIcon textcolor={clientTheme?.buttonTextColor}/>
          </StyledButton>
        </StyledButtonGroup>
      </Fade>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({
          TransitionProps, placement
        }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList id="split-button-menu">
                  <StyledMenuItem
                    key={ScheduleOfInvestmentAction.ExportAsCSV}
                    color="primary"
                  >
                    <CSVLink
                      data={csvData}
                      headers={csvHeaders || []}
                      style={{
                        textDecoration: 'none'
                      }}
                      onClick={() => setOpen(false)}
                      filename={`${state?.loginUser?.clientName ?? ""}_soi_${DateTimeFormat.getFormattedDate(date || new Date(), "_")}.csv`}>
                      <StyledBox>
                        <StyledBoxText color="primary">
                        Export as CSV
                        </StyledBoxText>
                      </StyledBox>
                    </CSVLink>
                  </StyledMenuItem>
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </React.Fragment>
  );
};

export default React.memo(ExportButton);
