import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Fade, Tooltip, Typography } from '@mui/material';
import { ReactElement } from 'react';

type Props = {
  id?: string;
  text?: string;
  children?: React.ReactNode;
  label?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
};

export const InfoTooltip = ({
  id = 'styled_tooltip',
  text = '',
  children,
  placement = undefined,
  label = '',
}: Props): ReactElement => {
  return (
    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
      {label}

      <Tooltip
        id={id}
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
        enterDelay={300}
        placement={placement}
        title={
          <Box padding={'8px'}>
            <Typography variant="body2">{text}</Typography>
            {children}
          </Box>
        }
      >
        <InfoOutlinedIcon
          color="disabled"
          fontSize="small"
          sx={{ marginLeft: '8px' }}
        />
      </Tooltip>
    </Typography>
  );
};
