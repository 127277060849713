import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from 'react';

import { ClientTransTypeColumn, ClientTransTypeRow } from "../../../utils/types/clientTransMapping.type";
import { ActionCellContainer, ActionCellText } from "./TransactionMapping.styles";

type Props = {
    type: 'row' | 'column',
    data: ClientTransTypeRow | ClientTransTypeColumn,
    onEdit?: () => void,
}

const ActionCell = ({
  type,
  data,
  onEdit,
}: Props) => {

  const title = type === 'row' ? (data as ClientTransTypeRow).type : (data as ClientTransTypeColumn).label;

  return (
    <ActionCellContainer>
      <ActionCellText>{title}</ActionCellText>
      <IconButton
        aria-label="Edit"
        aria-controls="edit"
        color="default"
        id={`edit-${data.id}_${title}`}
        onClick={onEdit}
      >
        <Edit />
      </IconButton>
    </ActionCellContainer>
  );
};

export default React.memo(ActionCell);
