import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditIcon from '@mui/icons-material/Edit';
import IosShareIcon from '@mui/icons-material/IosShare';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Tooltip, Typography } from '@mui/material';
import {
  GridAlignment,
  GridColumnOrderChangeParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import { getJournalEntry } from '../../../services/arkGL.service';
import {
  categorizeBankTransactions,
  searchAllBankTransactions,
  updateBankTransactions,
} from '../../../services/bank.service';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../services/columnOrder.service';
import { API_DATE_FORMAT } from '../../../utils/constants/constants';
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from '../../../utils/helpers/columnOrder.helper';
import {
  DateTimeFormat,
  FormatIntegerWithCurrencyOffset,
} from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  AccountMatchedBankTransaction,
  BankAccount,
  BankFeedsDataGridTab,
  BankFeedsStatus,
  BankFeedView,
  BankSummary,
  BankTransaction,
  BankTypeOptions,
  EditedRows,
  GlAccountsMap,
  NameGlAccIdMap,
  SearchTransactionsPayload,
  SelectedBankAccount,
  SendToGlValidation,
  UpdateBankTransactionsPayload,
} from '../../../utils/types/bank.type';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import { DetailsType } from '../../../utils/types/form.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import { ReportAccount } from '../../arkGL/reports/glReports/shared';
import { CapitalCallCellRenderer } from '../components/cellRenderers/CapitalCallCellRenderer';
import { GLAccountCellRenderer } from '../components/cellRenderers/GLAccountCellRenderer';
import { MemoTagCellRenderer } from '../components/cellRenderers/MemoTagCellRenderer';
import { useBankfilters } from '../components/Filters.hooks';
import {
  AddNewButtonOptions,
  BANK_FEEDS_SORT_NAME,
  BANK_FEEDS_VIEW_KEY,
  BANK_STATUS_FILTER_LIST,
  BANK_TRANS_UI_STATUS_VALUE,
  BankFeedsFilter,
  BankFeedViewOptions,
  BanksBulkAction,
  BankStatusOptions,
  DEFAULT_PAGE_SIZE,
  defaultHeaderList,
} from './BankFeedList.defaults';
import {
  FundChip,
  ScoreChip,
  StatusChip,
  TransactionTypeAvatar,
} from './BankFeedList.styles';

export const useBankFeedsList = () => {
  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);
  const isAdmin: boolean = !!(
    !!isSuperAdmin ||
    !!isClientAdmin ||
    !!isBasicAdmin
  );

  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const isReadonly: boolean = !!isFundAdmin;

  const [viewState, setViewState] = useState<BankFeedView>(
    BankFeedViewOptions.HYBRID
  );
  const [selectedDataGridTab, setSelectedDataGridTab] =
    useState<BankFeedsDataGridTab>('FOR_REVIEW');
  const [headerList, setHeaderList] =
    useState<Array<DataGridColDef>>(defaultHeaderList);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [bankFeedDataResponse, setBankFeedDataResponse] = useState<
    BankTransaction[]
  >([]);
  const [bankFeedsDataList, setBankFeedsDataList] = useState<
    AccountMatchedBankTransaction[]
  >([]);
  const [editedRows, setEditedRows] = useState<EditedRows>({});

  const [showUnsavedInlineEditsPrompt, setShowUnsavedInlineEditsPrompt] =
    useState<boolean>(false);
  const [showNoGlAccountPrompt, setShowNoGlAccountPrompt] =
    useState<boolean>(false);

  const [bankFeedsColumnOrder, setBankFeedsColumnOrder] =
    useState<ColumnOrder | null>(null);
  const [bankFeedsSelectionModel, setBankFeedsSelectionModel] = useState<
    string[]
  >([]);

  const [showBulkEdit, setShowBulkEdit] = useState<boolean>(false);
  const [sendToGlValidation, setSendToGlValidation] =
    useState<SendToGlValidation>();
  const [showGlValidationDetails, setShowGlValidationDetails] =
    useState<boolean>(false);
  const [triggerFetchTrialBalance, setTriggerFetchTrialBalance] =
    useState<number>(0);

  const [openBankConnectionPanel, setOpenBankConnectionPanel] =
    useState<boolean>(false);

  const [aggregateAccountsList, setAggregateAccountsList] = useState<
    BankSummary[]
  >([]);

  const [pageCountState, setPageCountState] = useState(0);
  const [rowCountState, setRowCountState] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterToggleState, setFilterToggleState] = useState(false);

  const [selectedBankTransaction, setSelectedBankTransaction] =
    useState<AccountMatchedBankTransaction>();
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<SelectedBankAccount>();
  const [selectedJournalEntry, setSelectedJournalEntry] = useState<any>();
  const [selectedFundsFilterList, setSelectedFundsFilterList] = useState<
    string[]
  >([]);

  const {
    fetchAllFilters,
    fetchAllBankAccounts,
    bankAccountList,
    fundList,
    ledgerList,
    CoAList,
    memoEntityList,
    memoEntityFilterList,
    capitalCallList,
    selectedBankAccountsList,
    selectedFundsList,
    selectedCoAList,
    selectedMemoEntityFilterList,
    selectedStatusList,
    glAccountDetails,
    fetchEntityList
  } = useBankfilters(setIsLoading, triggerFetchTrialBalance);

  const bankAccountIdNameGlAccMap = useMemo(() => {
    return new Map<string, NameGlAccIdMap>(
      bankAccountList?.map((acc) => [
        acc.id,
        { name: acc.name, glAccountId: acc.glAccountId },
      ])
    );
  }, [bankAccountList]);

  const glAccountIdNameMap = useMemo(() => {
    return new Map<string, GlAccountsMap>(
      CoAList.map((acc) => [
        acc.id,
        {
          name: acc.name,
          number: acc.number,
          isEntityRequired: acc.isEntityRequired,
        },
      ])
    );
  }, [CoAList]);

  const glTrialBalanceMap = useMemo(() => {
    const resultMap = new Map<string, Map<string, number>>();

    function processTrialBalance(glAccDetail: ReportAccount) {
      if (!resultMap.has(glAccDetail.accountId)) {
        resultMap.set(glAccDetail.accountId, new Map<string, number>());
      }
      const ledgerIdMap = resultMap.get(glAccDetail.accountId)!;

      glAccDetail.lineItems.forEach((lineItem) => {
        const currentSum = ledgerIdMap.get(lineItem.ledgerId) || 0;

        ledgerIdMap.set(lineItem.ledgerId, currentSum + lineItem.amount);
      });

      if (glAccDetail.childAccounts?.length > 0) {
        glAccDetail.childAccounts.forEach((childGlAccDetail) =>
          processTrialBalance(childGlAccDetail)
        );
      }
    }

    glAccountDetails.forEach((glAccDetail) => processTrialBalance(glAccDetail));

    return resultMap;
  }, [glAccountDetails]);

  useEffect(() => {
    if (!bankAccountList?.length) return;

    const acctTypeOrder: string[] = Object.values(BankTypeOptions);
    const groupedAccountsMap: { [key: string]: BankSummary } = {};
    let groupedAccounts: BankSummary[] = [];

    bankAccountList.forEach((acct) => {
      if (!selectedFundsFilterList.includes(acct.fundId)) return;

      const acctType = acctTypeOrder.includes(acct.type)
        ? acct.type
        : BankTypeOptions.Other;
      const key = `${acctType}-${acct.currencyCode}`;

      if (!groupedAccountsMap[key]) {
        groupedAccountsMap[key] = {
          type: acctType,
          currencyCode: acct.currencyCode,
          accounts: [acct],
          balanceSum: 0,
          availableCreditSum: 0,
          lineOfCreditSum: 0,
        };
      } else {
        groupedAccountsMap[key].accounts.push(acct);
      }

      groupedAccounts = Object.values(groupedAccountsMap).sort(
        (a, b) => acctTypeOrder.indexOf(a.type) - acctTypeOrder.indexOf(b.type)
      );

      groupedAccounts.forEach((acctGroup) => {
        if (acctGroup.type === BankTypeOptions.Credit) {
          let balanceSum: number | null = 0;
          let availableCreditSum: number | null = 0;
          let lineOfCreditSum: number | null = 0;

          acctGroup.accounts.forEach((acct) => {
            let { balanceTotal, availableCredit, lineOfCredit } = acct;

            const nullCount = [
              balanceTotal,
              availableCredit,
              lineOfCredit,
            ].filter((val) => val === null).length;

            if (nullCount >= 2) {
              if (balanceTotal === null) balanceSum = null;
              if (availableCredit === null) availableCreditSum = null;
              if (lineOfCredit === null) lineOfCreditSum = null;
            } else {
              if (balanceTotal === null) {
                balanceTotal = lineOfCredit!! - availableCredit!!;
              } else if (availableCredit === null) {
                availableCredit = lineOfCredit!! - balanceTotal;
              } else if (lineOfCredit === null) {
                lineOfCredit = balanceTotal + availableCredit;
              }
            }

            if (balanceSum !== null) {
              if (balanceTotal !== null) balanceSum += balanceTotal;
              else balanceSum = null;
            }
            if (availableCreditSum !== null) {
              if (availableCredit !== null)
                availableCreditSum += availableCredit;
              else availableCreditSum = null;
            }
            if (lineOfCreditSum !== null) {
              if (lineOfCredit !== null) lineOfCreditSum += lineOfCredit;
              else lineOfCreditSum = null;
            }
          });
          acctGroup.balanceSum = balanceSum;
          acctGroup.availableCreditSum = availableCreditSum;
          acctGroup.lineOfCreditSum = lineOfCreditSum;
        } else {
          let balanceSum = 0;

          acctGroup.accounts.forEach((acct) => {
            balanceSum += acct.balanceTotal;
          });

          acctGroup.balanceSum = balanceSum;
        }
      });
    });

    setAggregateAccountsList(groupedAccounts);
  }, [bankAccountList, selectedFundsFilterList]);

  useEffect(() => {
    const aggregateAccounts: BankSummary[] = cloneDeep(aggregateAccountsList);

    if (!aggregateAccounts.length || !glTrialBalanceMap.size) return;

    aggregateAccounts.forEach((aggregate) => {
      const seenIds = new Set<string>();
      let aggregateGlAccountBalance: number | undefined = undefined;

      aggregate.accounts.forEach((bankAccount) => {
        const glAccountId = bankAccount.glAccountId;
        const ledgerId = bankAccount.ledgerId;

        if (!glAccountId || !ledgerId) return;

        const glAccountBalance = glTrialBalanceMap
          .get(glAccountId)
          ?.get(ledgerId);

        bankAccount.glBalance = glAccountBalance;

        if (!seenIds.has(glAccountId.concat(ledgerId))) {
          seenIds.add(glAccountId.concat(ledgerId));
          if (glAccountBalance !== undefined) {
            if (aggregateGlAccountBalance === undefined) {
              aggregateGlAccountBalance = glAccountBalance;
            } else {
              aggregateGlAccountBalance += glAccountBalance;
            }
          }
        }
      });

      aggregate.glAccountsTotal = aggregateGlAccountBalance;
    });

    setAggregateAccountsList(aggregateAccounts);
  }, [glTrialBalanceMap]);

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      const columnOrderResponse = await getColumnOrder(
        BANK_FEEDS_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setBankFeedsColumnOrder(columnOrderResponse || null);
    } catch (e) {
      informationAlert('Error getting column order', 'error');
    }
  };

  const editedRowsRef = useRef(editedRows);

  useEffect(() => {
    editedRowsRef.current = editedRows;
  }, [editedRows]);

  const updateBankFeedsDataList = (
    updatedRow: AccountMatchedBankTransaction
  ) => {
    if (
      updatedRow.isEditedRow.isGlAccountEdited ||
      updatedRow.isEditedRow.isEntityEdited ||
      updatedRow.isEditedRow.isCapCallEdited
    ) {
      setEditedRows((prevState) => ({
        ...prevState,
        [updatedRow.id]: updatedRow,
      }));
    } else {
      setEditedRows((prevState) => {
        const { [updatedRow.id]: _, ...newState } = prevState;

        return newState;
      });
    }

    setBankFeedsDataList((prevState) =>
      prevState.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  };

  const resetBankFeedsDataList = () => {
    const reset = true;

    setEditedRows({});
    prepResponseForDataGrid(reset);
  };

  const prepResponseForDataGrid = (reset: boolean = false) => {
    if (!bankAccountList.length || !bankFeedDataResponse.length) {
      setBankFeedsDataList([]);
      return;
    }

    const matchedBankFeedData = bankFeedDataResponse.map((t) => {
      if (!reset && editedRows.hasOwnProperty(t.id))
        return {
          ...editedRows[t.id],
          receivableTransactionId: t.receivableTransactionId,
        };

      const matchedBankAccount = bankAccountList.find(
        (account) => account.id === t.bankAccountId
      );
      const matchedGlAccount = glAccountIdNameMap.get(
        matchedBankAccount?.glAccountId || ''
      );

      return {
        ...t,
        glAccountId: matchedBankAccount?.glAccountId || null,
        glAccountName: matchedBankAccount?.glAccountName || null,
        bankAccountDisplayName: matchedBankAccount?.name || t.bankAccountName,
        isNonGlBankAccount: !(matchedBankAccount?.glAccountId && t.ledgerId),
        isEditedRow: {
          isGlAccountEdited: false,
          isEntityEdited: false,
          isCapCallEdited: false,
          oldValues: {
            journalEntry: t.journalEntry,
            memoEntityId: t.memoEntityId,
            receivableTransactionId: t.receivableTransactionId,
          },
        },
        isEntityRequiredByBankAcc: matchedGlAccount?.isEntityRequired || false,
      };
    });

    setBankFeedsDataList(matchedBankFeedData);
  };

  useEffect(() => {
    if (!bankAccountList.length || !bankFeedDataResponse) return;
    prepResponseForDataGrid();
  }, [bankFeedDataResponse, bankAccountList, glAccountIdNameMap]);

  const fetchBankTransactions = async () => {
    setIsLoading(true);
    try {
      const searchReqBody: SearchTransactionsPayload = {
        offset: pageCountState * DEFAULT_PAGE_SIZE,
        pageSize: DEFAULT_PAGE_SIZE,
      };

      headerList?.map((header: DataGridColDef) => {
        const filterOptions = header.inlineFilterOptions;
        const selectedOptions = header.inlineFilterSelected;

        if (filterOptions && selectedOptions) {
          switch (header.inlineFilterName) {
            case BankFeedsFilter.BankAccount:
              if (filterOptions.length !== selectedOptions.length) {
                searchReqBody.bankAccountIds = selectedOptions;
              } else {
                return;
              }
              break;

            case BankFeedsFilter.Fund:
              if (filterOptions.length !== selectedOptions.length) {
                searchReqBody.fundIds = selectedOptions;
              } else {
                return;
              }
              break;

            case BankFeedsFilter.Status:
              if (filterOptions.length !== selectedOptions.length) {
                searchReqBody.statuses = selectedOptions;
              } else {
                return;
              }
              break;

            case BankFeedsFilter.Date:
              if (selectedOptions[1].length === 2) {
                const startDate = selectedOptions?.[1]?.[0];
                const endDate = selectedOptions?.[1]?.[1];

                if (startDate) {
                  const start = format(startDate, API_DATE_FORMAT).valueOf();

                  searchReqBody.startDate = start;
                }

                if (endDate) {
                  const end = format(endDate, API_DATE_FORMAT).valueOf();

                  searchReqBody.endDate = end;
                }
              }
              break;

            case BankFeedsFilter.MemoEntity:
              if (filterOptions.length !== selectedOptions.length) {
                searchReqBody.memoEntityIds = selectedOptions.map((option) =>
                  option === 'null' ? null : option
                );
              } else {
                return;
              }
              break;

            case BankFeedsFilter.RuleApplied:
              if (filterOptions.length !== selectedOptions.length) {
                searchReqBody.ruleIds = selectedOptions;
              } else {
                return;
              }
              break;
          }
        }
      });

      if (sortModel?.length) {
        searchReqBody.sort = {
          column: BANK_FEEDS_SORT_NAME[sortModel[0].field],
          direction: sortModel[0].sort === 'asc' ? 'ASC' : 'DESC',
        };
      }

      if (selectedDataGridTab === 'FOR_REVIEW')
        searchReqBody.statuses = ['PENDING'];

      const allResponse = await searchAllBankTransactions(searchReqBody);

      setBankFeedDataResponse(allResponse.items);
      setRowCountState(allResponse.pageInfo.totalItems);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      informationAlert('Error getting bank transactions', 'error');
    }
  };

  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);
    try {
      // await fetchColumnOrder(isCanceled);
      await fetchAllFilters(isCanceled);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTab: BankFeedsDataGridTab
  ) => {
    setPageCountState(0);
    setRowCountState(0);
    setSelectedDataGridTab(newTab);

    setHeaderList((prevHeaderList) => {
      return prevHeaderList.map((header) => {
        if (header.field === 'status') {
          return newTab === 'ALL'
            ? {
              ...header,
              sortable: true,
              inlineFilter: true,
              inlineFilterName: BankFeedsFilter.Status,
              inlineFilterIDField: 'id',
              inlineFilterLabelField: 'name',
              inlineFilterOptions: BANK_STATUS_FILTER_LIST,
              inlineFilterSelected: selectedStatusList,
              emptySelectionOnClear: false,
            }
            : {
              field: 'status',
              headerName: 'Status',
              hide: header.hide,
              index: header.index,
              width: header.width,
              type: header.type,
              renderCell: header.renderCell,
              sortable: false,
            };
        } else {
          return header;
        }
      });
    });
  };

  useEffectAsync(async () => {
    await fetchBankTransactions();
  }, [pageCountState, filterToggleState, selectedDataGridTab]);

  useEffect(() => {
    if (bankAccountIdNameGlAccMap && glAccountIdNameMap) {
      initializeHeaderList();
    }
  }, [
    fundList,
    bankAccountIdNameGlAccMap,
    memoEntityList,
    glAccountIdNameMap,
    capitalCallList,
  ]);

  const initializeHeaderList = () => {
    const updatedHeaders: DataGridColDef[] = [
      {
        field: 'bankAccountName',
        headerName: 'Bank Account',
        hide: false,
        index: 1,
        width: 250,
        type: 'string',
        sortable: true,
        inlineFilter: true,
        inlineFilterName: BankFeedsFilter.BankAccount,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: bankAccountList,
        inlineFilterSelected: selectedBankAccountsList,
        emptySelectionOnClear: false,
        renderCell: ({ row, value }) => {
          return (
            <>
              <Typography>
                {row.bankAccountDisplayName
                  ? row.bankAccountDisplayName
                  : row.name}
              </Typography>
            </>
          );
        },
      },
      {
        field: 'fundName',
        headerName: 'Fund',
        hide: false,
        index: 2,
        width: 150,
        type: 'string',
        sortable: true,
        inlineFilter: true,
        inlineFilterName: BankFeedsFilter.Fund,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: fundList,
        inlineFilterSelected: selectedFundsList,
        emptySelectionOnClear: false,
        renderCell: (params) => {
          const foundFundName = fundList.find(
            (fund) => fund.id === params.row.fundId
          )?.name;

          return <FundChip label={foundFundName || params.row.fundId} />;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        hide: false,
        index: 3,
        width: 170,
        type: 'string',
        ...(selectedDataGridTab === 'ALL'
          ? {
            sortable: true,
            inlineFilter: true,
            inlineFilterName: BankFeedsFilter.Status,
            inlineFilterIDField: 'id',
            inlineFilterLabelField: 'name',
            inlineFilterOptions: BANK_STATUS_FILTER_LIST,
            inlineFilterSelected: selectedStatusList,
            emptySelectionOnClear: false,
          }
          : { sortable: false }),
        renderCell: ({ value }) => {
          return (
            <StatusChip
              status={value}
              label={BANK_TRANS_UI_STATUS_VALUE[value]}
            />
          );
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        hide: false,
        index: 4,
        width: 150,
        type: 'date',
        sortable: true,
        renderCell: ({ value }) => {
          return <Typography>{DateTimeFormat.shortDate(value)}</Typography>;
        },
        inlineFilter: true,
        inlineFilterName: BankFeedsFilter.Date,
        inlineFilterOptions: [],
        inlineFilterSelected: ['specific_date', [null, null]],
      },
      {
        field: 'inflow',
        headerName: 'Inflow',
        hide: false,
        index: 5,
        width: 160,
        type: 'string',
        sortable: false,
        align: 'right' as GridAlignment,

        renderCell: ({ row }) => {
          return (
            <Typography>
              {row.type === 'INFLOW'
                ? FormatIntegerWithCurrencyOffset(
                  Math.abs(row.amount),
                  row.currency
                )
                : ''}
            </Typography>
          );
        },
      },
      {
        field: 'outflow',
        headerName: 'Outflow',
        hide: false,
        index: 6,
        width: 160,
        type: 'number',
        sortable: false,
        customType: CustomType.Currency,
        currencyCodeField: 'currencyCode',
        align: 'right' as GridAlignment,
        renderCell: ({ row }) => {
          return (
            <Typography>
              {row.type === 'OUTFLOW'
                ? FormatIntegerWithCurrencyOffset(
                  Math.abs(row.amount),
                  row.currency
                )
                : ''}
            </Typography>
          );
        },
      },
      {
        field: 'description',
        headerName: 'Description',
        hide: false,
        index: 7,
        width: 200,
        type: 'string',
        sortable: false,
      },
      {
        field: 'memo',
        headerName: 'Bank Memo',
        hide: false,
        index: 8,
        width: 200,
        type: 'string',
        sortable: false,
      },
      {
        field: 'account',
        headerName: 'GL Account',
        hide: false,
        index: 9,
        width: 280,
        type: 'string',
        sortable: false,
        inlineFilter: false,
        inlineFilterName: BankFeedsFilter.CoA,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: CoAList,
        inlineFilterSelected: selectedCoAList,
        renderCell: (params) => (
          <GLAccountCellRenderer
            isAdmin={isAdmin}
            row={params.row}
            CoAList={CoAList || []}
            glAccountIdNameMap={glAccountIdNameMap}
            updateBankFeedsDataList={updateBankFeedsDataList}
          />
        ),
      },
      {
        field: 'memoEntityName',
        headerName: 'Memo Tags',
        hide: false,
        index: 10,
        width: 280,
        type: 'chips',
        sortable: true,
        filterable: true,
        align: 'left' as GridAlignment,
        inlineFilter: true,
        inlineFilterName: BankFeedsFilter.MemoEntity,
        inlineFilterType: 'grouped',
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: memoEntityFilterList ? memoEntityFilterList : [],
        inlineFilterSelected: selectedMemoEntityFilterList,
        emptySelectionOnClear: false,
        renderCell: (params) => (
          <MemoTagCellRenderer
            isAdmin={isAdmin}
            row={params.row}
            memoEntityList={memoEntityList || []}
            updateBankFeedsDataList={updateBankFeedsDataList}
          />
        ),
      },
      {
        field: 'capCallReceived',
        headerName: 'Capital Call Received',
        hide: false,
        index: 11,
        width: 200,
        type: 'string',
        sortable: false,
        renderCell: (params) => (
          <CapitalCallCellRenderer
            isAdmin={isAdmin}
            row={params.row}
            capitalCallList={capitalCallList || []}
            updateBankFeedsDataList={updateBankFeedsDataList}
            memoEntityList={memoEntityList}
          />
        ),
      },
      {
        field: 'action',
        headerName: 'Columns',
        hide: false,
        hideable: false,
        index: 12,
        type: 'action',
        sortable: false,
        filterable: false,
        maxWidth: 100,
        customType: CustomType.Action,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: ({ row }) => {
          return row.isNonGlBankAccount ? (
            <Tooltip
              id={`disabled_view_tooltip_${row.id}`}
              sx={{ marginLeft: '0px', paddingLeft: '0px' }}
              title={
                <>
                  <Typography variant="body2">
                    Please assign a Ledger & GL Account to the Bank Account
                  </Typography>
                </>
              }
              arrow
              placement="left"
              children={
                <span>
                  <Button
                    id={`btn_disabled_data_grid_${row.id}`}
                    variant="text"
                    disableElevation
                    startIcon={<VisibilityOffIcon />}
                    disabled={row.isNonGlBankAccount}
                  >
                    View
                  </Button>
                </span>
              }
            />
          ) : (
            <Button
              id={`btn_data_grid_${row.id}`}
              variant="text"
              disableElevation
              startIcon={<VisibilityIcon />}
              onClick={() => handleOnView(row)}
              disabled={row.isNonGBankAccount}
            >
              {(row.status === BankStatusOptions.Categorized &&
                !!row.journalEntryId) ||
                isReadonly
                ? 'View'
                : 'Edit'}
            </Button>
          );
        },
      },
    ];

    let sortedHeaders: any;

    if (bankFeedsColumnOrder && bankFeedsColumnOrder.viewItems) {
      bankFeedsColumnOrder.viewItems.map((item) => {
        const header = updatedHeaders.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(updatedHeaders);
  };

  const handleAggregateDetailsClick = (bankIds: string[]) => {
    handleFilter(BankFeedsFilter.BankAccount, bankIds);
    setViewState(BankFeedViewOptions.HYBRID);
  };

  const handleFundOnlyFilter = (_filter: any, fundIds: string[]) => {
    handleFilter(BankFeedsFilter.Fund, fundIds);
  };

  useEffect(() => {
    const fundInlineFilterSelected = headerList.find(
      (header) => header.inlineFilterName === BankFeedsFilter.Fund
    )?.inlineFilterSelected;

    setSelectedFundsFilterList(fundInlineFilterSelected || []);
  }, [headerList]);

  const handleFilter = (filterName: BankFeedsFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }
        return header;
      })
    );
    setFilterToggleState(!filterToggleState);
  };

  const handleSort = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    setFilterToggleState(!filterToggleState);
  };

  const handlePageChange = (page: number) => {
    setPageCountState(page);
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    if (Object.keys(editedRows).length) {
      setShowUnsavedInlineEditsPrompt(true);
      return;
    }
    switch (actionId) {
      case AddNewButtonOptions.LinkAccount:
        if (!ledgerList.length) {
          setShowNoGlAccountPrompt(true);
          return;
        }
        setOpenBankConnectionPanel(true);
        break;

      case AddNewButtonOptions.UploadBankFeedTemplate:
        const file = event?.target?.files?.[0];

        if (file) {
          //setUploadedFile(file);
        }
        break;

      case AddNewButtonOptions.DownloadBankFeedTemplate: {
        //handleTemplateDownload();
        break;
      }
    }
  };

  const bulkActionOptions: ImageItem[] = useMemo(() => {
    const actions = [];
    const editSelected = {
      id: BanksBulkAction.BulkEditSelected,
      text: `Open Bulk Edit`,
      icon: <EditIcon />,
    };

    const sendToGl = {
      id: BanksBulkAction.SendToGL,
      text: `Send to GL (${bankFeedsSelectionModel?.length || 0})`,
      icon: <DescriptionOutlinedIcon />,
    };

    const exportSelected = {
      id: BanksBulkAction.ExportSelected,
      text: `Export Selected (${bankFeedsSelectionModel?.length || 0})`,
      icon: <IosShareIcon />,
    };

    const excludeFromGLSelected = {
      id: BanksBulkAction.ExcludeFromGlSelected,
      text: `Exclude from GL (${bankFeedsSelectionModel?.length || 0})`,
      icon: <EditIcon />,
    };

    const reviewBankTransactionSelected = {
      id: BanksBulkAction.ReviewBankTransactionSelected,
      text: `Review Bank Transaction (${bankFeedsSelectionModel?.length || 0})`,
      icon: <EditIcon />,
    };

    const deleteSelected = {
      id: BanksBulkAction.DeleteSelected,
      text: `Delete Selected (${bankFeedsSelectionModel?.length || 0})`,
      textColor: 'error',
      icon: <DeleteOutlinedIcon color="error" />,
      color: 'error',
    };

    actions.push(editSelected);

    if (bankFeedsSelectionModel?.length > 0) {
      actions.push(sendToGl);

      if (hasOnlySelected('PENDING')) {
        actions.push(excludeFromGLSelected);
      } else if (hasOnlySelected('EXCLUDED')) {
        actions.push(reviewBankTransactionSelected);
      }
    }

    return actions;

    function hasOnlySelected(bankFeedsStatus: BankFeedsStatus) {
      if (!bankFeedsSelectionModel?.length) return false;

      const selectedBankFeedDataList = getSelectedBankFeedsDataList();
      const onlyPending = selectedBankFeedDataList.every(
        (item) => item.status === bankFeedsStatus
      );

      return onlyPending;
    }
  }, [bankFeedsSelectionModel, showBulkEdit]);

  function getSelectedBankFeedsDataList() {
    const selectedBankFeedDataList = bankFeedsDataList.filter((item) =>
      bankFeedsSelectionModel.find((selectedId) => item.id === selectedId)
    );

    return selectedBankFeedDataList;
  }

  const handleBulkAction = (actionId: BanksBulkAction) => {
    if (Object.keys(editedRows).length) {
      setShowUnsavedInlineEditsPrompt(true);
      return;
    }
    switch (actionId) {
      case BanksBulkAction.BulkEditSelected:
        handleOpenBulkEdit();
        break;
      case BanksBulkAction.SendToGL:
        sendToGlValidator();
        break;
      case BanksBulkAction.CloseEditSelected:
        handleCloseBulkEdit();
        break;
      case BanksBulkAction.ExportSelected:
        () => { };
        break;
      case BanksBulkAction.ExcludeFromGlSelected:
        handleUpdatingSelectedBankTransactions('EXCLUDED');
        break;
      case BanksBulkAction.ReviewBankTransactionSelected:
        handleUpdatingSelectedBankTransactions('PENDING');
        break;
      case BanksBulkAction.DeleteSelected:
        () => { };
        break;
    }
  };

  const handleOnView = async (
    bankTransaction: AccountMatchedBankTransaction
  ) => {
    if (bankTransaction.isNonGlBankAccount) return;

    if (!!Object.keys(editedRowsRef.current).length) {
      setShowUnsavedInlineEditsPrompt(true);
      return;
    }

    if (bankTransaction.journalEntryId) {
      setIsLoading(true);
      try {
        const journalEntryDetails = await getJournalEntry(
          bankTransaction.journalEntryId
        );

        setSelectedJournalEntry({
          journalEntry: journalEntryDetails,
          type: DetailsType.Edit,
        });
        setIsLoading(false);
      } catch (e) {
        informationAlert('Error getting Journal Entry');
        setIsLoading(false);
      }
    } else {
      setSelectedBankTransaction(bankTransaction);
    }
  };

  const onJournalEntryPanelClose = () => {
    setSelectedJournalEntry(undefined);
  };

  const handleOnBankAccountView = (bankAccount: BankAccount) => {
    setSelectedBankAccount({
      bankAccount,
      type: DetailsType.Edit,
    });
  };

  const onDetailsClose = () => {
    setSelectedBankTransaction(undefined);
  };

  const onBankAccountDetailsClose = () => {
    setSelectedBankAccount(undefined);
  };

  const handleOpenBulkEdit = () => {
    setViewState(BankFeedViewOptions.DATA_VIEW);
    setShowBulkEdit(true);
  };

  const handleCloseBulkEdit = () => {
    setShowBulkEdit(false);
  };

  useEffect(() => {
    if (showBulkEdit) setShowBulkEdit(false);
  }, [editedRows]);

  const handleUpdatingSelectedBankTransactions = async (
    bankFeedsStatus: BankFeedsStatus
  ) => {
    const selectedBankFeedDataList = getSelectedBankFeedsDataList();
    const payload = selectedBankFeedDataList.map<UpdateBankTransactionsPayload>(
      (item) => {
        return {
          id: item.id,
          status: bankFeedsStatus,
          isPlaid: item.isPlaid,
        };
      }
    );

    try {
      setIsLoading(true);
      await updateBankTransactions(payload);
      setIsLoading(false);
      fetchBankTransactions();
      setBankFeedsSelectionModel([]);
    } catch (e) {
      setIsLoading(false);
      informationAlert('Error saving Bank Transactions', 'error');
    }
  };

  const sendToGlValidator = () => {
    const results: SendToGlValidation = {
      failedTotal: 0,
      failedTypes: {
        nonGlLinkedAcc: 0,
        excluded: 0,
        categorized: 0,
        glAccount: 0,
        memoEntity: 0,
      },
      validatedTransactionIds: [],
      missingMemoTag: 0,
    };

    bankFeedsSelectionModel.forEach((id) => {
      const transaction = bankFeedsDataList.find((trans) => trans.id === id);

      if (!transaction || transaction.isNonGlBankAccount) {
        results.failedTotal++;
        results.failedTypes.nonGlLinkedAcc++;
        return;
      }

      if (transaction.status === BankStatusOptions.Excluded) {
        results.failedTotal++;
        results.failedTypes.excluded++;
        return;
      }

      if (
        transaction.status === BankStatusOptions.Categorized &&
        transaction.journalEntryId
      ) {
        results.failedTotal++;
        results.failedTypes.categorized++;
        return;
      }

      const allJELinesHaveGlAcc = transaction.journalEntry.every(
        (li) => li.accountId
      );

      if (!allJELinesHaveGlAcc) {
        results.failedTotal++;
        results.failedTypes.glAccount++;
        return;
      }

      const allJELinesEntityReqsMet = transaction.journalEntry.every(
        (li) =>
          (glAccountIdNameMap.get(li.accountId)?.isEntityRequired &&
            li.entityIds.length) ||
          !glAccountIdNameMap.get(li.accountId)?.isEntityRequired
      );

      if (
        !allJELinesEntityReqsMet ||
        (transaction.isEntityRequiredByBankAcc && !transaction.memoEntityId)
      ) {
        results.failedTotal++;
        results.failedTypes.memoEntity++;
        return;
      }

      results.validatedTransactionIds.push(transaction.id);
    });

    if (results.failedTotal) {
      setSendToGlValidation(results);
    } else {
      handleSendToGL();
    }
  };

  const handleShowGlValidationDetails = () => {
    setShowGlValidationDetails(!showGlValidationDetails);
  };

  const cancelSendToGl = () => {
    setSendToGlValidation(undefined);
    setShowGlValidationDetails(false);
  };

  const sendValidatedTransToGl = () => {
    setBankFeedsSelectionModel(
      sendToGlValidation?.validatedTransactionIds || []
    );
    handleSendToGL(sendToGlValidation?.validatedTransactionIds);
    setSendToGlValidation(undefined);
    setShowGlValidationDetails(false);
  };

  const handleSendToGL = async (transactionIds: string[] = []) => {
    const payload = {
      transactionIds: transactionIds.length
        ? transactionIds
        : bankFeedsSelectionModel,
    };

    setIsLoading(true);

    try {
      await categorizeBankTransactions(payload);
    } catch (e) {
      setIsLoading(false);
      informationAlert(
        'Error sending bank transactions to General Ledger',
        'error'
      );
    } finally {
      fetchBankTransactions();
      setTriggerFetchTrialBalance((prev) => prev + 1);
    }
  };

  const handleUpdateHeader = (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      BANK_FEEDS_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      BANK_FEEDS_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleBankConnectionClose = () => {
    setOpenBankConnectionPanel(false);

    fetchBankTransactions();
    fetchAllBankAccounts();
  };

  return {
    isReadonly,
    viewState,
    setViewState,
    selectedDataGridTab,
    handleNewButtonAction,
    openBankConnectionPanel,
    setOpenBankConnectionPanel,
    handleBankConnectionClose,
    isLoading,
    setIsLoading,
    bankFeedsDataList,
    editedRows,
    setEditedRows,
    resetBankFeedsDataList,
    showUnsavedInlineEditsPrompt,
    setShowUnsavedInlineEditsPrompt,
    showNoGlAccountPrompt,
    setShowNoGlAccountPrompt,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    onColumnOrderChange,
    bankFeedsSelectionModel,
    setBankFeedsSelectionModel,
    handleFilter,
    rowCountState,
    pageCountState,
    handleTabChange,
    handlePageChange,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    selectedBankTransaction,
    onDetailsClose,
    fundList,
    CoAList,
    ledgerList,
    bankAccountList,
    memoEntityList,
    handleFundOnlyFilter,
    selectedFundsFilterList,
    bulkActionOptions,
    handleBulkAction,
    showBulkEdit,
    handleCloseBulkEdit,
    fetchBankTransactions,
    sortModel,
    handleSort,
    aggregateAccountsList,
    handleAggregateDetailsClick,
    selectedBankAccount,
    handleOnBankAccountView,
    onBankAccountDetailsClose,
    fetchAllFilters,
    sendToGlValidation,
    showGlValidationDetails,
    handleShowGlValidationDetails,
    cancelSendToGl,
    sendValidatedTransToGl,
    fetchEntityList
  };
};
