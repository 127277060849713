import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  TextField,
} from '@mui/material';
import { ReactElement, useState } from 'react';

import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import {
  AccountMatchedBankTransaction,
  nameIdTypePair,
} from '../../../../utils/types/bank.type';
import { BankStatusOptions } from '../../bankFeeds/BankFeedList.defaults';
import {
  AutocompleteGroupHeader,
  CellBox,
} from '../../bankFeeds/BankFeedList.styles';
import {
  EditableChipWithDelete,
  MemoEntityRequiredChip,
  NoLedgerOrGlAccountChip,
  ReadOnlyChipsOrDash,
  SelectOptionChip,
} from './DataGridChips';

type Props = {
  isAdmin: boolean;
  row: AccountMatchedBankTransaction;
  memoEntityList: nameIdTypePair[];
  updateBankFeedsDataList: (updatedRow: AccountMatchedBankTransaction) => void;
};

export const MemoTagCellRenderer: React.FC<Props> = ({
  isAdmin,
  row,
  memoEntityList,
  updateBankFeedsDataList,
}: Props): ReactElement => {
  const isReadOnly = !isAdmin || row.status === BankStatusOptions.Categorized;

  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (row.isNonGlBankAccount) {
    return (
      <CellBox
        onMouseEnter={() => setIsHovered(true)}
        onMouseMove={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? <NoLedgerOrGlAccountChip /> : M_DASH_UNICODE}
      </CellBox>
    );
  }

  const currentOption = memoEntityList.find(
    (entity) => entity.id === row.memoEntityId
  );

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleStopEditing = () => {
    setIsEditing(false);
    setIsHovered(false);
  };

  const handleDeleteMemoTag = () => {
    handleMemoTagSelection(null, null);
  };

  const handleMemoTagSelection = (
    selectedMemoEntityId: string | null,
    selectedMemoEntityName: string | null
  ) => {
    const updatedRow = {
      ...row,
      memoEntityId: selectedMemoEntityId,
      memoEntityName: selectedMemoEntityName,
      journalEntry: row.journalEntry.map((lineItem) => {
        return {
          ...lineItem,
          entityIds: selectedMemoEntityId ? [selectedMemoEntityId] : [],
        };
      }),
      isEditedRow: {
        ...row.isEditedRow,
        isEntityEdited:
          selectedMemoEntityId === row.isEditedRow.oldValues.memoEntityId
            ? false
            : true,
        isEntityRequired:
          row.isEntityRequiredByBankAcc || row.isEditedRow.isEntityRequired,
      },
    };

    updateBankFeedsDataList(updatedRow);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: nameIdTypePair) => `${option.name} ${option.type}`,
  });

  return isReadOnly ? (
    <ReadOnlyChipsOrDash
      chipData={row.memoEntityName ? [row.memoEntityName] : []}
    />
  ) : isEditing ? (
    <FormControl fullWidth>
      <Autocomplete
        id="memo_tags_inline_edit"
        size="small"
        openOnFocus
        options={memoEntityList}
        getOptionLabel={(option) => `${option.name}`}
        value={currentOption}
        groupBy={(option) => option.type}
        filterOptions={filterOptions}
        onClose={handleStopEditing}
        onChange={(event: React.SyntheticEvent, value: any) => {
          if (value) handleMemoTagSelection(value.id, value.name);
        }}
        onKeyDown={(event) => {
          if (
            event.key === ' ' ||
            event.key === 'ArrowDown' ||
            event.key === 'ArrowUp'
          ) {
            event.stopPropagation();
          }
        }}
        renderGroup={(params) => (
          <div key={params.key}>
            <AutocompleteGroupHeader>{params.group}</AutocompleteGroupHeader>
            {params.children}
          </div>
        )}
        renderInput={(params) => (
          <TextField autoFocus {...params} placeholder="Memo Tag" />
        )}
      />
    </FormControl>
  ) : row.memoEntityName ? (
    <EditableChipWithDelete
      label={row.memoEntityName}
      onChipClick={handleStartEditing}
      onDelete={handleDeleteMemoTag}
    />
  ) : (
    <CellBox
      onMouseEnter={() => setIsHovered(true)}
      onMouseMove={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <SelectOptionChip
          label="Select Memo Tag"
          onChipClick={handleStartEditing}
        />
      ) : row.isEditedRow &&
        row.isEditedRow.isEntityRequired &&
        !row.memoEntityId ? (
        <MemoEntityRequiredChip />
      ) : (
        M_DASH_UNICODE
      )}
    </CellBox>
  );
};
