import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Grid } from '@mui/material';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  PanelBoxExpanded,
  StyledBackdrop,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import JEAccountFilter from '../../../../components/Selectors/JEAccountFilter/JEAccountFilter';
import JEEntityFilter from '../../../../components/Selectors/JEEntityFilter/JEEntityFilter';
import JEFundFilter from '../../../../components/Selectors/JEFundFilter/JEFundFilter';
import JELedgerFilter from '../../../../components/Selectors/JELedgerFilter/JELedgerFilter';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import DecimalFormatCustom from '../../../../components/TextFieldNumeric/DecimalFormat';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import { NumberFormat } from '../../../../utils/helpers/format.helper';
import {
  JournalEntryStatusTypes,
  SelectedJournalEntry,
} from '../../../../utils/types/arkGLJournalEntry.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import { useJournalEntryDetails } from './JournalEntryDetails.hooks';
import {
  AttachmentListBox,
  DocumentLinkBox,
  FormControlCheckbox,
  KeyLabel,
  KeyValuePair,
  Label,
  Line,
  LineCell,
  LineHeader,
  LineItemBox,
  LineItemList,
  LineTextField,
  StatusBox,
  ValueLabel,
} from './JournalEntryDetails.styles';

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedJournalEntry: SelectedJournalEntry;
  fetchAllJournalEntries: Function;
  fundId?: string;
}

type Mapping = Record<JournalEntryStatusTypes, string>;

const StatusType: Mapping = {
  draft: 'yellow',
  posted: 'green',
};

export const JournalEntryDetailsReadOnly: React.FC<Props> = ({
  onDetailClose,
  selectedJournalEntry,
  fetchAllJournalEntries,
  fundId,
}: Props) => {
  const { type, journalEntry } = selectedJournalEntry;

  const isNewJournalEntry = type === DetailsType.New;

  const {
    isLoading,
    totalCredit,
    totalDebit,
    closeDrawer,
    toggleDrawer,
    status,
    accountList,
    selectedFundId,
    totalError,
    selectedAccount,
    ledgerList,
    groupedEntityList,
    handleDownloadAttachment,
  } = useJournalEntryDetails({
    journalEntry,
    selectedJournalEntry,
    onClose: onDetailClose,
    fetchAllJournalEntries,
    isNewJournalEntry,
    setSelectedJournalEntry: () => {},
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_journal_entry"
        title={
          isNewJournalEntry
            ? 'Add Journal Entry'
            : `Journal Entry #${journalEntry?.number}`
        }
        open={Boolean(type)}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <PanelBoxExpanded>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StatusBox>
                {status && (
                  <StatusLabel
                    color={
                      StatusType[
                        status?.toLowerCase() as keyof typeof StatusType
                      ]
                    }
                    label={status}
                  />
                )}
              </StatusBox>
            </Grid>
            <Grid item xs={12} sm={1.6} md={1.6} lg={1.6}>
              <KeyValuePair>
                <KeyLabel>Date</KeyLabel>
                <ValueLabel>{journalEntry?.date || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={3}>
              <JEFundFilter
                size="small"
                id={'fund_filter'}
                idSelectOnly
                value={journalEntry?.fundId}
                disabled={false}
                onChange={() => {}}
                placeholder="Fund Name"
                readonly
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <JELedgerFilter
                size="small"
                id={'gl_filter'}
                idSelectOnly
                value={journalEntry?.ledgerId}
                onChange={() => {}}
                placeholder="GL Name"
                ledgerList={ledgerList}
                loading={isLoading}
                setSelectedLedgerCurrency={() => {}}
                setSelectedLedgerDecimal={() => {}}
                readonly
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <KeyValuePair>
                <KeyLabel>Journal No.</KeyLabel>
                <ValueLabel>
                  {journalEntry?.number || M_DASH_UNICODE}
                </ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={2}>
              <KeyValuePair>
                <KeyLabel>Is Adjusting Journal Entry?</KeyLabel>
                <ValueLabel>
                  {journalEntry?.adjustingJournalEntry === true ? 'Yes' : 'No'}
                </ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12}>
              <LineItemBox>
                <LineItemList readonly>
                  <Grid container>
                    <LineHeader>
                      <LineCell item xs={0.3} br>
                        <Label bold>#</Label>
                      </LineCell>
                      <LineCell item xs={2} br>
                        <Label bold>ACCOUNT</Label>
                      </LineCell>
                      <LineCell item xs={2} br>
                        <Label bold number>
                          DEBITS
                        </Label>
                      </LineCell>
                      <LineCell item xs={2} br>
                        <Label bold number>
                          CREDITS
                        </Label>
                      </LineCell>
                      <LineCell item xs={3} br>
                        <Label bold>DESCRIPTION</Label>
                      </LineCell>
                      <LineCell item xs={2}>
                        <Label bold>ENTITY NAME</Label>
                      </LineCell>
                      <LineCell item xs={0.5}></LineCell>
                    </LineHeader>
                    {journalEntry?.lineItems.map((item: any, index: number) => {
                      return (
                        <Line key={index}>
                          <LineCell item xs={0.3} br>
                            <Label>{index + 1}</Label>
                          </LineCell>
                          <LineCell item xs={2} br readonly>
                            <JEAccountFilter
                              size="small"
                              id={`account_${index}`}
                              idSelectOnly
                              value={item.accountId ? item.accountId : ''}
                              handleAddNewAccount={() => {}}
                              accountList={accountList}
                              loading={isLoading}
                              onChange={() => {}}
                              setValue={() => {}}
                              setValueField=""
                              readonly
                            />
                          </LineCell>
                          <LineCell item xs={2} br readonly>
                            <Label number>
                              {item.entryType === 'DEBIT'
                                ? NumberFormat(
                                    journalEntry.currencyDecimal,
                                    true
                                  ).format(item.amount)
                                : ''}
                            </Label>
                          </LineCell>
                          <LineCell item xs={2} br readonly>
                            <Label number>
                              {item.entryType === 'CREDIT'
                                ? NumberFormat(
                                    journalEntry.currencyDecimal,
                                    true
                                  ).format(item.amount)
                                : ''}
                            </Label>
                          </LineCell>
                          <LineCell item xs={3} br readonly>
                            <Label>
                              {item.entryMemo ? item.entryMemo : ''}
                            </Label>
                          </LineCell>
                          <LineCell item xs={2.5} readonly>
                            <JEEntityFilter
                              size="small"
                              id={`entity_${index}`}
                              options={groupedEntityList}
                              loading={isLoading}
                              idSelectOnly
                              value={item.entities ? item.entities : null}
                              onChange={() => {}}
                              setValue={() => {}}
                              setValueField=""
                              readonly
                            />
                          </LineCell>
                          <LineCell item xs={0.5}></LineCell>
                        </Line>
                      );
                    })}
                    <LineCell item xs={0.3}>
                      <Label bold></Label>
                    </LineCell>
                    <LineCell item xs={2}>
                      <Label bold number>
                        TOTAL
                      </Label>
                    </LineCell>
                    <LineCell item xs={2}>
                      <LineTextField
                        id="total_debit"
                        size="small"
                        variant="outlined"
                        fullWidth
                        number
                        disabled={true}
                        value={totalDebit}
                        error={totalError}
                        inputProps={{
                          decimalPlaces: journalEntry?.currencyDecimal,
                          fixedDecimalScale: true,
                        }}
                        InputProps={{
                          inputComponent: DecimalFormatCustom,
                        }}
                      />
                    </LineCell>
                    <LineCell item xs={2}>
                      <LineTextField
                        id="total_credit"
                        size="small"
                        variant="outlined"
                        fullWidth
                        number
                        disabled={true}
                        value={totalCredit}
                        error={!!totalError}
                        inputProps={{
                          decimalPlaces: journalEntry?.currencyDecimal,
                          fixedDecimalScale: true,
                        }}
                        InputProps={{
                          inputComponent: DecimalFormatCustom,
                        }}
                      />
                    </LineCell>
                    <LineCell item xs={3}></LineCell>
                    <LineCell item xs={2.5}></LineCell>
                    <LineCell item xs={0.5}></LineCell>
                  </Grid>
                </LineItemList>
              </LineItemBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <KeyValuePair>
                <KeyLabel>Description</KeyLabel>
                <ValueLabel>{journalEntry?.memo || M_DASH_UNICODE}</ValueLabel>
              </KeyValuePair>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <KeyValuePair>
                <KeyLabel>Attachments</KeyLabel>
              </KeyValuePair>
              {journalEntry?.currentAttachments &&
                journalEntry?.currentAttachments.length > 0 &&
                journalEntry?.currentAttachments?.map((a) => {
                  return (
                    <AttachmentListBox readonly>
                      <ActionLink
                        id={`link_${a.documentId}`}
                        onClick={() => handleDownloadAttachment(a.documentId)}
                      >
                        <DocumentLinkBox readonly>
                          <FilePresentIcon />
                          {a.documentId}
                        </DocumentLinkBox>
                      </ActionLink>
                    </AttachmentListBox>
                  );
                })}
            </Grid>
          </Grid>
        </PanelBoxExpanded>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
        >
          <Button
            id={'btn_account_close'}
            variant="outlined"
            onClick={closeDrawer}
            text={'Close'}
            color={'secondary'}
            fullWidth
          />
        </HorizontalBox>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_glsetup_panel'}
          showProgress={Boolean(isLoading)}
          text={
            isLoading
              ? isLoading === LoadingStatus.Downloading
                ? `${isLoading} attachment...`
                : `${isLoading} journal entry...`
              : ''
          }
        />
      </DetailPanel>
    </>
  );
};
