import { cloneDeep } from "lodash";

import { DateTimeFormat } from "../../../../utils/helpers/format.helper";
import { AllocationColumn, CommitmentColumn, ItdColumn, TransactionColumn } from "../workbook.type";
import { SpreadsheetGrid } from "./SpreadsheetGrid";

export function debugPrintAllCols(
    startMesssage: string,
    grid: SpreadsheetGrid, 
    commitmentColumns: CommitmentColumn[],
    allocRulesColumns: AllocationColumn[],
    pitdColumns: ItdColumn[],
    transColumns: TransactionColumn[],
    ytdColumns?: ItdColumn[],
    ItdColumns?: ItdColumn[]
) {
    consoleDebug(`-------------------------`);
    consoleDebug(`-------------------------`);
    consoleDebug(`-- ${startMesssage} --`);
    consoleDebug('** Commitment Columns **');
    debugPrintCommitCols(grid, commitmentColumns);
    consoleDebug('** Alloc Rules Columns **');
    debugPrintAllocCols(grid, allocRulesColumns);
    consoleDebug('** pitd Columns **');
    debugPrintPitdCols(grid, pitdColumns);
    
    debugPrintTransCols(grid, transColumns);

    if(ytdColumns?.length) {
        consoleDebug('** ytd Columns **');
        debugPrintYtdCols(grid, ytdColumns);
    }

    if(ItdColumns?.length) {
        consoleDebug('** Itd Columns **');
        debugPrintItdCols(grid, ItdColumns);
    }
}

export function debugPrintCommitCols(grid: SpreadsheetGrid, commitmentColumns: CommitmentColumn[]) {
    commitmentColumns
        .sort((a,b) => DateTimeFormat.compareDateOnly(a.commitmentDate, b.commitmentDate))
        .forEach(c => 
    {
        const caption = grid.getColCaption(c.gridColId);

        consoleDebug(`${caption}, ${c.commitmentDate}, ${c.label}, ${c.quarterNumber}, ${c.quarterSum}`);
    });
}

export function debugPrintAllocCols(grid: SpreadsheetGrid, allocRulesColumns: AllocationColumn[]) {
    for(let q=1; q<=4; q++) {
        const quarterAllocRulesCols = allocRulesColumns.filter(c => c.quarterNumber === q);

        if(!quarterAllocRulesCols?.length) continue;

        quarterAllocRulesCols
        .sort((a,b)=>a.index-b.index)
        .forEach(c => {
            const caption = grid.getColCaption(c.gridColId);

            consoleDebug(`${caption}, ${c.index}, ${c.label}, ${c.quarterNumber}`);
        });
}
}

export function debugPrintPitdCols(grid: SpreadsheetGrid, pitdColumns: ItdColumn[]) {
    const cols = cloneDeep(pitdColumns).sort((a,b)=>a.index-b.index);

    cols.forEach(c => {
        const caption = grid.getColCaption(c.gridColId);

        consoleDebug(`${caption}, ${c.index}, ${c.label}`);
    });
}

export function debugPrintTransCols(grid: SpreadsheetGrid, transColumns: TransactionColumn[]) {
    for(let q=1; q<=4; q++) {
        const qtrCols = transColumns.filter(c => c.quarterNumber === q);

        if(!qtrCols?.length) continue;

        consoleDebug(`** Quarter ${q} Columns **`);
        qtrCols.sort((a,b)=>a.index-b.index).forEach(c => {
            const caption = grid.getColCaption(c.gridColId);

            consoleDebug(`${caption}, ${c.index}, ${c.label}`);
        });
    }
}

export function debugPrintYtdCols(grid: SpreadsheetGrid, ytdColumns: ItdColumn[]) {
    const cols = cloneDeep(ytdColumns).sort((a,b)=>a.index-b.index);

    cols.forEach(c => {
        const caption = grid.getColCaption(c.gridColId);

        consoleDebug(`${caption}, ${c.index}, ${c.label}`);
    });
}

export function debugPrintItdCols(grid: SpreadsheetGrid, itdColumns: ItdColumn[]) {
    const cols = cloneDeep(itdColumns).sort((a,b)=>a.index-b.index);

    cols.forEach(c => {
        const caption = grid.getColCaption(c.gridColId);

        consoleDebug(`${caption}, ${c.index}, ${c.label}`);
    });
}


export function consoleDebug(message: string) {
    // eslint-disable-next-line no-console
    console.log(message);
}