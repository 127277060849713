import { styled } from "@mui/material";

export const ResultContent = styled("div")(() => ({
  paddingLeft: 20,
  paddingTop: 5,
  paddingBottom: 5,
}));

export const Option = styled("p")(() => ({
  marginTop: 5,
  marginBottom: 5,
  width: "75%",
}));

export const MessageContent = styled("p")(() => ({
  marginTop: 5,
  marginBottom: 3,
}));

export const OptionListContent = styled("div")(() => ({
  maxHeight: 245,
  overflow: "scroll",
}));

export const ListRow = styled("div")(() => ({
  marginBottom: 15,
}));

export const PlaceAddress = styled("div")(() => ({
  display: "none",
}));
