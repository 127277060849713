export type BUTTON_COLOR_OPTIONS = "primary" | "secondary" | "error";
export type BUTTON_STYLE_VARIANTS =
  | "text"
  | "contained"
  | "outlined"
  | undefined;
export type POPOVER_VERTICAL_ORIGIN = "top" | "bottom" | "center";
export type POPOVER_ORIENTATION = "right" | "center" | "left";
export const INFO_PANEL_MAX_WIDTH = "740px";
export const INFO_PANEL_DURATION_MS = 3000;
export type INFO_ALERT_SEVERTIY_TYPES =
  | "success"
  | "error"
  | "info"
  | "warning";
export const DATA_GRID_ROW_LENGTH = 10000;
export const DATA_GRID_DEFAULT_WIDTH = 230;
export const LOCK_PANEL_HEIGHT = "57px";
export const DATA_GRID_DEFAULT_MIN_WIDTH = 50;
