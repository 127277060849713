import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Typography } from '@mui/material';
import { ReactElement, useContext, useState } from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import { ProgressModal } from '../../../components';
import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import { AppContext } from '../../../core/context/appContextProvider';
import {
  getDocumentsPreviews,
  getGeneralMailing,
  getPreview,
} from '../../../services/generalMailings.service';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import {
  GeneralMailings,
  SelectedDocuments,
} from '../../../utils/types/generalMailings.type';
import {
  BodyBox,
  HeaderRow,
  LinkButton,
  StyledBox,
  StyledModal,
  Title,
} from './MailingsPreviewPanel.styles';

type Props = {
  showModal: boolean;
  setShowModal: any;
  selectedPreviewMailing: any;
  setSelectedPreviewMailing: any;
  previewCounter: number;
  setPreviewCounter: any;
};

const MailingsPreviewPanel: React.FC<Props> = (props: Props): ReactElement => {
  const {
    showModal,
    setShowModal,
    selectedPreviewMailing,
    setSelectedPreviewMailing,
    previewCounter = 0,
    setPreviewCounter,
  } = props;

  const [mailing, setMailing] = useState<GeneralMailings>();
  const [multiplePreviewsOpen, setMultiplePreviewsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { informationAlert, state } = useContext(AppContext);
  const [ccEmails, setCcEmails] = useState('');
  const [bcEmails, setBcEmails] = useState('');
  const [attachedFiles, setAttachedFiles] = useState<Array<string>>([]);
  const [displayAttachedFile, setDisplayAttachedFile] = useState<string>('');
  const [groupedEmails, setGroupedEmails] = useState<string[]>([]);
  const [displayEmails, setDisplayEmails] = useState<string>('');
  const [messageBodies, setMessageBodies] = useState<Array<string>>([]);
  const [froalaModal, setFroalaModal] = useState('');
  const [investorNames, setInvestorNames] = useState<Array<string>>([]);
  const [displayFunds, setDisplayFunds] = useState<string>('');
  const [displayInvestors, setDisplayInvestors] = useState<string>('');
  const [displaySubject, setDisplaySubject] = useState<string>('');
  const [subjectFields, setSubjectFields] = useState<Array<string>>([]);
  const [previewLength, setPreviewLength] = useState(0);
  const [displayRoles, setDisplayRoles] = useState('');

  const close = () => {
    setSelectedPreviewMailing(undefined);

    setCcEmails('');
    setBcEmails('');
    setMultiplePreviewsOpen(false);
    setShowModal(false);
    setDisplayFunds('');
    setDisplayRoles('');
    setDisplayInvestors('');
    setAttachedFiles([]);
    setDisplayEmails('');
    setDisplayAttachedFile('');
    setGroupedEmails([]);
    setFroalaModal('');
    setInvestorNames([]);
    setPreviewLength(0);
    setPreviewCounter(0);
    setMessageBodies([]);
    setMailing(undefined);
  };

  const nextPreview = () => {
    setPreviewCounter(previewCounter + 1);
    setDisplayInvestors(investorNames[previewCounter + 1]);
    setDisplayEmails(groupedEmails[previewCounter + 1]);
    setFroalaModal(messageBodies[previewCounter + 1]);
    setDisplaySubject(subjectFields[previewCounter + 1]);
    if (mailing?.type === 'DOCUMENTS') {
      setDisplayAttachedFile(attachedFiles[previewCounter + 1]);
    }
  };

  const prevPreview = () => {
    setPreviewCounter(previewCounter - 1);
    setDisplayInvestors(investorNames[previewCounter - 1]);
    setDisplayEmails(groupedEmails[previewCounter - 1]);
    setFroalaModal(messageBodies[previewCounter - 1]);
    setDisplaySubject(subjectFields[previewCounter - 1]);
    if (mailing?.type === 'DOCUMENTS') {
      setDisplayAttachedFile(attachedFiles[previewCounter - 1]);
    }
  };

  useEffectAsync(async () => {
    setIsLoading(true);

    if (selectedPreviewMailing && selectedPreviewMailing.id) {
      try {
        const mailing: any = await getGeneralMailing(selectedPreviewMailing.id);

        setMailing(mailing);

        const previewResponse: any = await getPreview(
          selectedPreviewMailing.id
        );

        const previews = previewResponse.previews;

        setPreviewLength(previews.length);

        if (previews.length > 1) {
          setMultiplePreviewsOpen(true);
        }

        const investorNames: any = previews.map(
          (contact: any) => contact.investorName
        );
        const emailGroups: Array<any> = previews.map(
          (preview: any) => preview.to
        );

        let emailStrings = emailGroups.map((group) => group.join(', '));

        if (mailing.status === 'SENT') {
          // sent previewResponse investor list comes in a different order than mailing
          emailStrings = previewResponse.previews.map((preview: any) => {
            const sent = mailing.emailRecipients.find(
              (emailRecipient: any) =>
                preview.investorName === emailRecipient.investor &&
                emailRecipient.fund === selectedPreviewMailing.fundName
            );

            return sent ? sent.emailAddresses : '';
          });
        }
        const linkedBodies = previews.map((p: any) => p.messageBody);
        const linkedSubjects = previews.map((p: any) => p.subject);

        let fileNames: any = [];

        if (mailing.type === 'DOCUMENTS') {
          fileNames = previews.map((p: any) => p.filename);

          setAttachedFiles(fileNames);
        }

        setDisplayRoles(Array.from(new Set(previews[0].roles)).join(', '));
        setMessageBodies(linkedBodies);
        setSubjectFields(linkedSubjects);
        setInvestorNames(investorNames);
        setGroupedEmails(emailStrings);
        setDisplayFunds(selectedPreviewMailing.fundName);
        setCcEmails(mailing.cc.toString());
        setBcEmails(mailing.bcc.toString());

        if (!multiplePreviewsOpen) {
          setDisplayInvestors(investorNames[0]);
          setDisplayEmails(emailStrings[0]);
          setFroalaModal(linkedBodies[0]);
          setDisplaySubject(linkedSubjects[0]);
          setDisplayAttachedFile(fileNames[0]);
        }
        if (previewCounter !== 0 && previews.length > 1) {
          setDisplayInvestors(investorNames[previewCounter - 1]);
          setDisplayEmails(emailStrings[previewCounter - 1]);
          setPreviewCounter(previewCounter - 1);
          setFroalaModal(linkedBodies[previewCounter - 1]);
          setDisplaySubject(linkedSubjects[previewCounter - 1]);
          if (mailing.type === 'DOCUMENTS') {
            setDisplayAttachedFile(fileNames[previewCounter - 1]);
          }
        }
      } catch (error) {
        informationAlert('Error occured when loading mailing preview', 'error');
      }
    }
    setIsLoading(false);
  }, [selectedPreviewMailing]);

  return (
    <>
      <ProgressModal id="modal_previews_loading" showProgress={isLoading} />
      <StyledModal
        id="mailings_preview_modal"
        open={showModal}
        onClose={() => {
          close();
        }}
        sx={{ borderRadius: '10px' }}
      >
        <StyledBox sx={{ borderRadius: '10px' }}>
          <HorizontalBox
            sx={{
              justifyContent: 'center',
              backgroundColor: '#1f2e96',
              width: '100%',
              height: '80px',
              paddingBottom: '30px',
              color: 'primary',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <HeaderRow id="title">
              <Typography
                variant="formTitle"
                sx={{ color: '#FFFFFF', paddingTop: '45px' }}
              >
                Preview{' '}
                {multiplePreviewsOpen
                  ? `(${previewCounter + 1} of ${previewLength})`
                  : ''}
              </Typography>
            </HeaderRow>
          </HorizontalBox>
          <BodyBox
            sx={{ flex: 'wrap', width: '100%', backgroundColor: '#FFFFFF' }}
          >
            {mailing?.type === 'DOCUMENTS' && (
              <>
                <Typography fontWeight={'bold'} fontSize={'small'}>
                  ATTACHED:
                </Typography>
                <Typography fontSize={'small'} sx={{ paddingBottom: '15px' }}>
                  {displayAttachedFile}
                </Typography>
              </>
            )}
            <>
              <Typography fontWeight={'bold'} fontSize={'small'} sx={{}}>
                TO: {displayFunds} - {displayInvestors}
              </Typography>
              <Typography fontSize={'small'} sx={{ paddingBottom: '15px' }}>
                {displayEmails}
              </Typography>
              <Typography fontWeight={'bold'} fontSize={'small'} sx={{}}>
                ROLES:
              </Typography>
              <Typography fontSize={'small'} sx={{ paddingBottom: '15px' }}>
                {displayRoles}
              </Typography>
            </>
            {ccEmails ? (
              <>
                <Typography fontWeight={'bold'} fontSize={'small'} sx={{}}>
                  CC:
                </Typography>
                <Typography fontSize={'small'} sx={{ paddingBottom: '15px' }}>
                  {ccEmails}
                </Typography>
              </>
            ) : (
              ''
            )}
            {bcEmails ? (
              <>
                <Typography fontWeight={'bold'} fontSize={'small'} sx={{}}>
                  BCC:
                </Typography>
                <Typography fontSize={'small'} sx={{ paddingBottom: '15px' }}>
                  {bcEmails}
                </Typography>
              </>
            ) : (
              ''
            )}
            <Typography fontWeight={'bold'} fontSize={'small'}>
              SUBJECT:
            </Typography>
            <Typography fontSize={'small'} sx={{}}>
              {displaySubject}
            </Typography>
            <FroalaEditorView model={froalaModal} />
          </BodyBox>
          <HorizontalBox
            attachToBottom={true}
            fullWidth={true}
            sx={{
              justifyContent: 'space-evenly',
              alingItems: 'center',
              backgroundColor: '#FFFFFF',
              paddingTop: '5px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            <LinkButton
              id="back_preview"
              variant="text"
              color="primary"
              name="Previous"
              disableElevation
              disabled={
                (multiplePreviewsOpen && previewCounter >= 1) ||
                previewCounter >= 1
                  ? false
                  : true
              }
              onClick={() => {
                prevPreview();
              }}
              startIcon={<ArrowBackIosRoundedIcon />}
              sx={{ right: '12px' }}
            >
              Previous
            </LinkButton>
            <LinkButton
              id="close_preview"
              variant="text"
              color="primary"
              name="Close"
              disableElevation
              onClick={() => {
                close();
              }}
              sx={{ right: '12px' }}
            >
              Close
            </LinkButton>
            <LinkButton
              id="next_preview"
              variant="text"
              name="Next"
              color="primary"
              disableElevation
              disabled={
                multiplePreviewsOpen && previewCounter < previewLength - 1
                  ? false
                  : true
              }
              onClick={() => {
                nextPreview();
              }}
              endIcon={<ArrowForwardIosIcon />}
              sx={{ right: '12px' }}
            >
              Next
            </LinkButton>
          </HorizontalBox>
        </StyledBox>
      </StyledModal>
    </>
  );
};

export default MailingsPreviewPanel;
