import {
  Box,
  Button,
  Divider,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";

export const MarginFixContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, -4),
}));
export const StyledButton = styled(Button)(() => ({
  width: "20%",
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1.2),
}));
export const ReviewTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(7),
}));
export const ButtonBox = styled(Box)`
  display: flex;
`;

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 1fr",
  height: "65vh",
  overflowY: "auto",
}));
export const StyledStack = styled(Stack)(({ theme }) => ({
  width: "20%",
  marginRight: theme.spacing(8),
}));

export const ApproveNextContainer = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(6),
}));

export const RightBox = styled(Stack)(({ theme }) => ({
  boxShadow: "-3px 4px 10px rgba(0, 0, 0, 0.25)",
  paddingBottom: theme.spacing(10),
}));

export const PreviousContainer = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(23),
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "16px",
}));

export const FlagButton = styled(Button)<{ fullscreen: string }>(
  ({ theme, fullscreen }) => ({
    width: "50%",
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginLeft: fullscreen === "true" ? theme.spacing(1) : "",
  })
);
export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  marginTop: theme.spacing(2),
}));

export const EmailContainer = styled(Box)<{ fullscreen: string }>(
  ({ theme, fullscreen }) => ({
    marginLeft: fullscreen === "true" ? theme.spacing(-37) : " ",
    width: fullscreen === "true" ? "125%" : "95%",
    height: 'calc(100vh - 400px)',
    overflow: "scroll",
    borderRadius: theme.spacing(0.8),
  })
);

export const EmailPdfContainer = styled(Box)<{ fullscreen: string }>(
  ({ theme, fullscreen }) => ({
    marginLeft: fullscreen === "true" ? theme.spacing(-37) : " ",
    width: fullscreen === "true" ? "125%" : "95%",
    height: 'calc(100vh - 400px)',
    border: `1px solid ${theme.palette.secondary.dark}`,
    overflow: "scroll",
    borderRadius: theme.spacing(0.8),
  })
);

export const ButtonContainer = styled(Stack)(({ theme }) => ({
  justifyContent: "end",
  marginTop: theme.spacing(2),
}));

export const DownLoadButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(3),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const LeftBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const SplitButtonContainer = styled(Stack)(({ theme }) => ({
  display: "grid",
  justifyContent: "center",
  marginTop: theme.spacing(2),
}));

export const SelectBoxContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledSelect = styled(Select)<{
  onChange: (event: SelectChangeEvent<string>) => void;
}>(({ theme }) => ({
  height: "75%",
}));

export const InvestorContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));
