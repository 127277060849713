import { Collapse, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';
import { Controller } from 'react-hook-form';

import IconRightArrow from '../../../../assets/images/icons/icon_right_arrow.svg';
import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import ImgIcon from '../../../../components/ImgIcon/ImgIcon';
import { CloseIcon } from '../../../../components/Modal/NextStepDialog/NextStepDialog.styles';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import useDocumentsContext from '../../hooks/useDocumentsContext.hooks';
import { ARCHIVE_TOOLTIP_TEXT } from '../grid/cells/archiveCellRenderer/ArchiveCellRenderer.constants';
import { InfoTooltip } from '../infoTooltip/InfoTooltip';
import { EditPanelTooltipText } from './EditPanel.constants';
import {
  EditPanelSettingsBox,
  EditPanelSettingsGrid,
  PanelActionBox,
  Title,
  TooltipTypography,
} from './EditPanel.styles';
import useEditPanel from './useEditPanel.hooks';

type File = { id: string; type: 'folder' | 'file'; fileType?: string };

interface Props {
  title: string;
  open?: boolean;
  handleClose?: () => void;
  files: File[];
  reload: Function;
}

const EditPanel = ({
  title,
  open = false,
  handleClose = () => {},
  files = [],
  reload,
}: Props) => {
  const { state } = useDocumentsContext();

  const {
    loading,
    fileCount,
    pdfFileCount,
    DOC_CONTROL_OPTIONS,
    DOC_ARCHIVE_OPTIONS,
    watchArchivePolicy,
    minDate,
    control,
    validateDate,
    handleSubmit,
    handleSave,
    onClose,
  } = useEditPanel(state, { handleClose, files, reload });

  return (
    <React.Fragment key="right">
      <HorizontalBox>
        <Title id="title">
          {title} <ImgIcon icon={IconRightArrow} />
        </Title>
        <PanelActionBox>
          <CloseIcon
            id="btn_bulk_edit_file_close"
            onClick={onClose}
            color="primary"
          />
        </PanelActionBox>
      </HorizontalBox>
      <EditPanelSettingsBox id="edit_panel_settings_box">
        <EditPanelSettingsGrid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography>
              <strong> File Settings </strong>
            </Typography>
            <Typography>
              <small>
                {`${fileCount} ${fileCount > 1 ? 'files' : 'file'} selected`}
              </small>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InfoTooltip
              id="edit_panel_archive_tooltip"
              label="Archive"
              children={
                <>
                  <TooltipTypography>
                    <strong>Keep Existing: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.KEEP_EXISTING}
                  </TooltipTypography>
                  <TooltipTypography>
                    <strong>Respect Folder Settings: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.RESPECT_FOLDER_SETTINGS}
                  </TooltipTypography>
                  <TooltipTypography>
                    <strong>Disable Archiving: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.DISABLE_ARCHIVING}
                  </TooltipTypography>
                  <TooltipTypography>
                    <strong>Set Custom Date: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.SET_CUSTOM_DATE}
                  </TooltipTypography>
                </>
              }
            />
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Controller
                name="archivePolicy.archivePolicy"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SingleSelect
                      id="archivePolicy"
                      optionList={DOC_ARCHIVE_OPTIONS}
                      label=""
                      fullWidth={true}
                      idField="id"
                      labelField="label"
                      handleOptionSelection={(event: SelectChangeEvent) =>
                        onChange(event.target.value)
                      }
                      defaultValue={'keepExisting'}
                      value={value ?? ''}
                      error={!!error}
                      noGutter={true}
                    />
                  );
                }}
                control={control}
              />
              <Collapse in={watchArchivePolicy === 'RESPECT_FILE'}>
                <Controller
                  name="archivePolicy.archiveOn"
                  rules={{
                    validate: (value) => validateDate(value),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <StaticDatePicker
                        label="Archive selected files on:"
                        value={validateDate(value) === true ? value : ''}
                        minDate={minDate}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            id={'edit_panel_archive_date_textfield'}
                            {...params}
                            size="small"
                            onChange={(value) => {
                              onChange(value);
                            }}
                            value={value}
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        )}
                        toolbarFormat={'MM/dd/yyyy'}
                      />
                    );
                  }}
                  control={control}
                />
              </Collapse>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <strong> PDF Specific Settings </strong>
            </Typography>

            {pdfFileCount ? (
              <Typography>
                <small>
                  {`${pdfFileCount} PDF ${
                    pdfFileCount > 1 ? 'files' : 'file'
                  } selected`}
                </small>
              </Typography>
            ) : (
              <Typography color={'error'}>
                <small> No PDF files selected </small>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Any adjustments made in 'PDF Specific Settings' will only affect
              PDF files in this bulk edit. Please be aware that settings applied
              to PDFs before upload will persist.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InfoTooltip
              id="edit_panel_download_tooltip"
              label="Download"
              children={EditPanelTooltipText.download}
            />
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Controller
                name="permissions.downloadEnabled"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SingleSelect
                      id="select_downloadEnabled"
                      disabled={loading || !pdfFileCount}
                      optionList={DOC_CONTROL_OPTIONS}
                      label=""
                      fullWidth={true}
                      idField="id"
                      labelField="label"
                      handleOptionSelection={(event: SelectChangeEvent) =>
                        onChange(event.target.value)
                      }
                      defaultValue={'keepExisting'}
                      value={value ?? ''}
                      error={!!error}
                      noGutter={true}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <InfoTooltip
                id="edit_panel_print_tooltip"
                label="Print"
                children={EditPanelTooltipText.print}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Controller
                name="permissions.printEnabled"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SingleSelect
                      id="select_printEnabled"
                      disabled={loading || !pdfFileCount}
                      optionList={DOC_CONTROL_OPTIONS}
                      label=""
                      fullWidth={true}
                      idField="id"
                      labelField="label"
                      handleOptionSelection={(event: SelectChangeEvent) =>
                        onChange(event.target.value)
                      }
                      defaultValue={'keepExisting'}
                      value={value ?? ''}
                      error={!!error}
                      noGutter={true}
                      helperText={error?.message ?? ''}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InfoTooltip
              id="edit_panel_print_tooltip"
              label="Copy"
              children={EditPanelTooltipText.copy}
            />
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Controller
                name="permissions.copyEnabled"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SingleSelect
                      id="select_copyEnabled"
                      disabled={loading || !pdfFileCount}
                      optionList={DOC_CONTROL_OPTIONS}
                      label=""
                      fullWidth={true}
                      idField="id"
                      labelField="label"
                      handleOptionSelection={(event: SelectChangeEvent) =>
                        onChange(event.target.value)
                      }
                      defaultValue={'keepExisting'}
                      value={value ?? ''}
                      error={!!error}
                      noGutter={true}
                      helperText={error?.message ?? ''}
                    />
                  );
                }}
                control={control}
              />
            </Grid>
          </Grid>
        </EditPanelSettingsGrid>
      </EditPanelSettingsBox>
      <HorizontalBox
        addTopShadow
        attachToBottom={true}
        hidden={false}
        fullWidth
      >
        <Button
          id="btn_edit_file_detail_panel_cancel"
          variant="outlined"
          onClick={onClose}
          text={'Cancel'}
          color={'secondary'}
          fullWidth
          addSpaceBetweenButtons
        />

        <Button
          id={'btn_investor_detail_panel_save'}
          variant="contained"
          onClick={handleSubmit(handleSave)}
          text={'Save & Close'}
          color={'primary'}
          fullWidth
          addSpaceBetweenButtons
        />
      </HorizontalBox>
    </React.Fragment>
  );
};

export default EditPanel;
