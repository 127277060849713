import { Box, FormControl, FormLabel, styled } from '@mui/material';

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Container = styled(Box)(({
  theme
}) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(8),
  width: '100%',
  overflowY: 'scroll',
  maxHeight: "71vh",
}));

export const MultiFactorConfigurationContainer = styled(Box)(({
  theme
}) => ({
  borderRadius: theme.spacing(1),
  display:"grid",
  backgroundColor: theme.palette.common.white,
}));

export const FileSelectorWrapper =styled(Box)(({
  theme
})=>({
  paddingBottom: theme.spacing(3.75),
}));

export const ColorSelectorContainer =styled(Box)(({
  theme
})=>({
  display: "flex",
  justifyContent: "space-between",
  margin: theme.spacing(4, 0),
  "&:first-child": {
    marginTop: 0
  },
  "&:last-child": {
    marginBottom: 0,
  }
}));

export const CheckboxLabel = styled(FormLabel)(({
  theme
})=>({
  color: theme.palette.text.primary,
  cursor: 'pointer'
}));

export const CheckboxFormControl = styled(FormControl)(({
  theme
})=>({
  marginBottom: theme.spacing(2),
}));
