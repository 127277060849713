import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Typography } from '@mui/material';

import { DataWrapperBox } from '../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressModal from '../../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { GLSetupDetails } from '../setupDetails/GLSetupDetails';
import { GLSetupDetailsReadOnly } from '../setupDetails/GLSetupDetailsReadOnly';
import { glSetupOptions } from './GLSetupList.constants';
import { useGLSetupList } from './GLSetupList.hooks';
import { ButtonBox, HeaderRow, IconBox } from './GLSetupList.styles';

export const GLSetupList: React.FC = () => {
  const {
    isLoading,
    headerList,
    activeHeaderFields,
    ledgerFilteredList,
    ledgerSelectionModel,
    setLedgerSelectionModel,
    search,
    searchOptions,
    handleSearch,
    handleOnView,
    handleUpdateHeader,
    onColumnOrderChange,
    handleFilter,
    handleNewButtonAction,
    showSuggestionPopover,
    setShowSuggestionPopover,
    readonly,
    selectedLedger,
    fundId,
    onDetailsPanelClose,
    fetchAllLedgers,
    showDeleteConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    isSuperAdmin,
    isClientAdmin,
    bulkActionOptions,
    handleBulkAction,
    ledgersToDelete,
    deleteRole,
    arkGlLocked,
    glLocked,
  } = useGLSetupList();

  const isArkGlLocked = arkGlLocked === undefined ? true : arkGlLocked;
  const isGlLocked = glLocked === undefined ? true : glLocked;

  return (
    <DataWrapperBox id="glsetup_list_main" role="main">
      <ProgressModal id="modal_glsetup_loading" showProgress={isLoading} />

      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">GL Setup</Typography>
        </IconBox>
        <SearchBar
          id="glsetup_search"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          {ledgerSelectionModel.length >= 1 && deleteRole ? (
            <SplitButton
              id={'btn_bulk_action_options'}
              options={bulkActionOptions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          ) : (
            ''
          )}
          {!readonly && (
            <ButtonWithOptions
              buttonID="add_gl_button"
              popoverID="add_gl_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={glSetupOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <DataGrid
        id="glsetup_data_grid"
        dataList={
          !isLoading && ledgerFilteredList?.length > 0 ? ledgerFilteredList : []
        }
        minHeight="1vh"
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={ledgerSelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setLedgerSelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
        onColumnOrderChange={onColumnOrderChange}
      />
      {!!selectedLedger?.type &&
        (readonly ? (
          <GLSetupDetailsReadOnly
            selectedLedger={selectedLedger}
            onDetailClose={onDetailsPanelClose}
            fetchAllLedgers={fetchAllLedgers}
            {...(fundId ? { fundId: fundId } : {})}
            arkGlLocked={isArkGlLocked}
            glLocked={isGlLocked}
          />
        ) : (
          <GLSetupDetails
            selectedLedger={selectedLedger}
            onDetailClose={onDetailsPanelClose}
            fetchAllLedgers={fetchAllLedgers}
            {...(fundId ? { fundId: fundId } : {})}
            arkGlLocked={isArkGlLocked}
            glLocked={isGlLocked}
          />
        ))}
      <ConfirmationDialog
        open={showDeleteConfirmation === 'UNUSED'}
        id="delete_unused_ledger"
        actions={[
          {
            label: 'Yes',
            onClick: onDeleteConfirm,
            id: 'btn_continue_delete',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'No',
            onClick: onDeleteCancel,
            id: 'btn_cancel_delete',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        title={`Are you sure you want to delete ${
          ledgersToDelete?.unused?.length > 1
            ? 'these Ledgers?'
            : 'this Ledger?'
        } `}
        content="This action is permanent and cannot be undone. Continue?"
      />
      <ConfirmationDialog
        open={showDeleteConfirmation === 'DRAFT'}
        id="delete_draft_ledger"
        actions={[
          {
            label: 'Yes',
            onClick: onDeleteConfirm,
            id: 'btn_continue_delete',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'No',
            onClick: onDeleteCancel,
            id: 'btn_cancel_delete',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        title={`Deleting ${
          ledgersToDelete?.draft?.length > 1 ? 'Ledgers' : 'Ledger'
        } with draft Journal Entires`}
        content={`Deleting ${
          ledgersToDelete?.draft?.length > 1 ? 'these Ledgers' : 'this Ledger'
        } will delete all associated draft Journal Entries. Continue?`}
      />
      <ConfirmationDialog
        open={showDeleteConfirmation === 'POSTED'}
        id="delete_used_ledger"
        actions={[
          {
            label: 'OK',
            onClick: onDeleteCancel,
            id: 'btn_close_notification',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Delete Error"
        content="Ledgers with posted Journal Entries cannot be deleted."
      />
    </DataWrapperBox>
  );
};
