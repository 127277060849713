import { FolderOpen } from "@mui/icons-material";
import { Box, Drawer, FormControlLabel,styled, Typography, typographyClasses } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const ArkClientBoxIcon = styled(FolderOpen)(({
  theme
}) => ({
  color: theme.palette.grey[500],
}));

export const ArkClientBox = styled(Box)(({
  theme
}) => ({
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  gridTemplateColumns: "auto auto",
  gridGap: theme.spacing(2),
}));

export const Container = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 'calc(100% - 32px)',
    maxWidth: 'calc(100% - 32px)',
    padding: theme.spacing(2, 4, 2, 4),
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255,255,255, 0.7)',
  },
}));

export const HeaderTitleContainer = styled(Box)(({
  theme
}) => ({
  display:"grid",
  alignItems:"center",
  gridTemplateColumns:"repeat(2, auto) 1fr",
  gridGap: theme.spacing(4),
}));

export const HeaderBreadcrumbContainer = styled(Box)(({
  theme
}) => ({
  display:"grid",
  alignItems:"center",
  gridTemplateColumns:"repeat(3, auto)",
  gridGap: theme.spacing(2),
}));

export const FormBox = styled(Box)(() => ({
  margin: '0 auto',
  maxWidth: '70%',
}));

export const MarginFixContainer = styled(Box)(({
  theme
}) => ({
  margin: theme.spacing(0, -4),
}));

export const TransactionMappingContainer = styled(Box)(({
  theme
}) => ({
  padding: theme.spacing(2, 0),
  maxHeight: 'calc(100vh - 250px)',
  overflow: 'auto',
}));


export const TransactionMappingRowHeaderContainer = styled(Box)<{ columnlength: string }>(({
  theme, columnlength
}) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${parseInt(columnlength, 10) + 2}, 180px)`,
  gridGap: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));


export const TransactionMappingRowContainer = styled(Box)<{ columnlength: string }>(({
  theme, columnlength
}) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${parseInt(columnlength, 10) + 2}, 180px)`,
  gridGap: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  "& .delete-button": {
    visibility: 'hidden',
  },
  "&:hover .delete-button": {
    visibility: 'visible',
  }
}));

export const TransactionMappingRowFooterContainer = styled(Box)<{ columnlength: string }>(({
  theme, columnlength
}) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${parseInt(columnlength, 10) + 2}, 180px)`,
  gridGap: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  "& .delete-button-wrapper": {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  "& .delete-button-wrapper .delete-button": {
    visibility: 'hidden',
  },
  "& .delete-button-wrapper:hover .delete-button": {
    visibility: 'visible',
  },
}));

export const TemplateTitleBox = styled(Typography) (({
  theme
}) =>({
  color:theme.palette.text.primary
}));