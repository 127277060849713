import { Button, FormControl, InputLabel, LinearProgress, LinearProgressProps, MenuItem, Select, Typography } from "@mui/material";
import { ReactElement } from "react";

import { Box, SelectBox } from "./BulkMapping.styles";


type Props = {
  transactionList: any[];
  selectionModel: any;
  bulkMappingSelection: any;
  setBulkMappingSelection: Function;
  handleTypeChange: Function;
}

export const BulkMapping: React.FC<Props> = ({
  selectionModel,
  bulkMappingSelection,
  setBulkMappingSelection,
  transactionList,
  handleTypeChange,
  ...props
}: Props): ReactElement => {
  return (
    <Box>
      <SelectBox>
        <Typography variant="body1">Bulk map selected</Typography>
        <FormControl disabled={!(selectionModel.length > 1)} size="small">
          <InputLabel id="label_bulk_mapping_select">Select Transaction</InputLabel>
          <Select
            labelId="select_bulk_mapping_label"
            id="select_bulk_mapping"
            label="Select Transaction"
            size="small"
            value={bulkMappingSelection}
            onChange={(e) => setBulkMappingSelection(e.target.value)}
          >
            <MenuItem value='0'>Unmapped</MenuItem>
            {transactionList.map((type) => {
              return (
                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
              );
            }
            )}
          </Select>
          <Button
            id="btn_apply_bulk_mapping"
            variant="contained"
            onClick={() => handleTypeChange('transactionTypeId', selectionModel, bulkMappingSelection)}
            disableElevation
          >
            Apply
          </Button>

        </FormControl>
      </SelectBox>
    </Box>
  );
};
