import { Box, Button, Select, styled, Typography } from '@mui/material';

export const FilterBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{
  error?: boolean;
}>(({ theme, error }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: error ? '1px solid red' : '0',
  '.MuiInput-input:focus': {
    background: 'none',
  },
}));

export const TypeLabel = styled(Typography)(() => ({
  lineHeight: '3',
}));

export const OptionList = styled('div')(() => ({
  maxWidth: '320px',
  maxHeight: '200px',
  overflow: 'auto',
  padding: '0 15px',
}));

export const Dropdown = styled(Select)(() => ({
  width: '100%',

  '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
    opacity: '60%',
    padding: '4px 0 5px 10px',
    fontSize: '0.9em',
  },
}));

export const ChipBox = styled(Box)(() => ({
  width: '90%',
  padding: '5px',

  '.MuiBox-root': {
    flexFlow: 'wrap',
  },
}));

export const CreateNewButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '16px',
  width: '100%',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'start'
}));