import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getSecurityTypeFilterList } from "../../../services/filters.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { SecurityTypeSimple } from "../../../utils/types/portfolioCompany.type";

export const useSecurityTypeFilterEffect = () => {
  const { informationAlert } = useContext(AppContext);
  const [securityTypeList, setSecurityTypeList] = useState<SecurityTypeSimple[]>();
  const [loading, setLoading] = useState(false);

  useEffectAsync(async (isCanceled) => {
    setLoading(true);
    try {
      const statusResponse = await getSecurityTypeFilterList();

      if (isCanceled()) return;

      if (statusResponse)
        setSecurityTypeList(statusResponse);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setLoading(false);
  }, []);

  return {
    securityTypeList,
    loading,
  };
};

export default useSecurityTypeFilterEffect;

