import DeleteOutline from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button } from '@mui/material';
import {
  GridAlignment,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import DeleteIcon from '../../../../assets/images/icons/icon_delete.svg';
import { StringCell } from '../../../../components/DataGrid/DataGrid.styles';
import { ActionLink } from '../../../../components/Link/ActionLink/ActionLink';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  deleteBulkJournalEntries,
  deleteSelectedLedgers,
  getArkLedgers,
  getLedgerDetails,
  getLedgers,
} from '../../../../services/arkGL.service';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../../services/columnOrder.service';
import {
  getFundCurrencyList,
  getFundNames,
} from '../../../../services/fund.service';
import { useClientEffect } from '../../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import { capitalize } from '../../../../utils/helpers/capitalize';
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from '../../../../utils/helpers/columnOrder.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { ColumnOrder } from '../../../../utils/types/columnOrder';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';
import { DetailsType } from '../../../../utils/types/form.type';
import {
  ArkLedger,
  LedgerName,
  LedgerType,
  SelectedLedger,
} from '../../../../utils/types/glSetup.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
  ListItem,
} from '../../../../utils/types/listItems';
import { NameIdPair } from '../../../../utils/types/transaction.type';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  DELETE_ALL_LEDGER_ERROR,
  DELETE_ALL_LEDGER_SUCCESS,
  DELETE_LEDGER_ERROR,
  DELETE_LEDGER_SUCCESS,
  DeleteConfirmationType,
  GET_LEDGER_LIST_ERROR,
  GET_VIEW_LIST_ERROR,
  GL_SETUP_VIEW_KEY,
  LedgerAction,
  LedgerFilter,
  StatusType,
} from './GLSetupList.constants';
import { defaultHeaderList } from './GLSetupList.defaultHeaders';

const initialLedger: SelectedLedger = {
  ledger: undefined,
  type: undefined,
  isNewQBLedgerAllowed: true,
};

type ActionProp = {
  row: any;
  allowDelete?: boolean;
  onView: () => void;
  onDelete: () => void;
};

const Actions = ({
  row,
  allowDelete = false,
  onView,
  onDelete,
}: ActionProp): ReactElement => {
  return (
    <Box>
      <Button
        id={`${row?.id}_view_button`}
        variant="text"
        disableElevation
        startIcon={<VisibilityIcon />}
        onClick={onView}
      >
        View
      </Button>
      {allowDelete && (
        <Button
          id={`${row?.id}_delete_button`}
          variant="text"
          color="error"
          disableElevation
          startIcon={<DeleteOutline />}
          onClick={onDelete}
        >
          Delete
        </Button>
      )}
    </Box>
  );
};

export const useGLSetupList = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== 'new' ? clientId : undefined);
  const arkGlLocked = client?.arkGlLocked;
  const glLocked = client?.glLocked;

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);
  const { hasRole: deleteRole } = useRole([
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.SUPER_ADMIN,
  ]);

  const readonly: boolean = !!isFundAdmin;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [statusList, setStatusList] = useState<any>([]);

  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrder | null>(null);
  const [ledgersResponse, setLedgersResponse] = useState<any>([]);
  const [ledgerList, setLedgerList] = useState<ArkLedger[]>([]);
  const [ledgerFilteredList, setLedgerFilteredList] = useState<any[]>([]);
  const [ledgerSelectionModel, setLedgerSelectionModel] = useState<string[]>(
    []
  );
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [selectedLedger, setSelectedLedger] = useState<SelectedLedger>();
  const [currencyList, setCurrencyList] = useState<ListItem[]>([]);
  const [fundNameList, setFundNameList] = useState<NameIdPair[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<DeleteConfirmationType>(DeleteConfirmationType.FALSE);

  const [ledgersToDelete, setLedgersToDelete] = useState<any>({});

  const fetchAllLedgers = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const allGLResponse = await getLedgers(fundId);
      let arkGLResponse: any;

      if (
        allGLResponse.items &&
        allGLResponse.items.some(
          (item: any) => item.glPlatform === LedgerType.ARK_LEDGER
        )
      ) {
        arkGLResponse = await getArkLedgers(fundId);
      }

      if (isCanceled?.()) return;

      const ledgers = allGLResponse.items
        .map((ledger: any) => {
          if (ledger.glPlatform === LedgerType.ARK_LEDGER) {
            ledger.state =
              arkGLResponse.items.find(
                (arkGL: any) => arkGL.id === ledger.platformLedgerId
              )?.state || '';
          } else {
            ledger.state = 'EXTERNAL';
          }
          if (
            ledger.glPlatform === LedgerType.QUICKBOOKS_ONLINE &&
            !ledger.name &&
            ledger.status === 'AUTHENTICATED'
          ) {
            ledger.name = LedgerName.QUICKBOOKS_ONLINE;
          } else if (
            ledger.glPlatform === LedgerType.QUICKBOOKS_ONLINE &&
            ledger.status === 'DISCONNECTED'
          ) {
            return null;
          }
          return ledger;
        })
        .filter((l: any) => l);

      setLedgersResponse(ledgers);
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrency = async (isCanceled?: () => boolean) => {
    try {
      const currencyListResponse = await getFundCurrencyList();

      if (isCanceled?.()) return;

      setCurrencyList(currencyListResponse);
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    }
  };

  const fetchFundNames = async (isCanceled?: () => boolean) => {
    try {
      const response = await getFundNames();

      if (isCanceled?.()) return;

      setFundNameList(response);
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    } finally {
    }
  };

  const fetchColumnOrder = async (isCanceled?: () => boolean) => {
    try {
      const columnOrderResponse = await getColumnOrder(
        GL_SETUP_VIEW_KEY,
        clientId
      );

      if (isCanceled?.()) return;

      setColumnOrder(columnOrderResponse || null);
    } catch (e) {
      informationAlert(GET_VIEW_LIST_ERROR, 'error');
    }
  };

  useEffect(() => {
    initializeHeaderList();
  }, [columnOrder]);

  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);
    await fetchCurrency(isCanceled);
    await fetchFundNames(isCanceled);
    await fetchColumnOrder(isCanceled);
    await fetchAllLedgers(isCanceled);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    initializeSearchOptions(ledgerList);
  }, [ledgerList, currencyList, fundNameList]);

  useEffect(() => {
    if (ledgersResponse && ledgersResponse.length > 0) {
      const processedList = prepRowsForDataGrid(ledgersResponse);

      setLedgerList(processedList);
    } else {
      setLedgerList([]);
    }
  }, [ledgersResponse]);

  const prepRowsForDataGrid = (ledgers: ArkLedger[]) => {
    return ledgers
      .map((ledger) => {
        return {
          ...ledger,
          id: ledger.ledgerId,
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  };

  useEffect(() => {
    const isActiveStatus = (status: string | undefined) => {
      return ledgerList.find((ledger) => {
        return ledger.state === status;
      });
    };

    const activeStatusList: { id: string; name: string }[] = [];

    for (const key in StatusType) {
      if (isActiveStatus(key)) {
        activeStatusList.push(StatusType[key]);
      }
    }

    setStatusList(activeStatusList);
  }, [ledgerList]);

  const initializeSearchOptions = (list: any[]) => {
    const nameListOptions = Array.from(
      new Set(list.map((ledger) => ledger.name))
    );

    const statusListOptions = Array.from(
      new Set(list.map((ledger) => StatusType[ledger.state]?.name))
    );

    const fundIdList = Array.from(new Set(list.map((ledger) => ledger.fundId)));

    const fundNameListOptions = fundIdList.map((fundId) => {
      const fund = fundNameList.find((f) => f.id === fundId);

      return fund?.name;
    });

    const currencyIdList = Array.from(
      new Set(list.map((ledger) => ledger.currency))
    );

    const currencyListOptions = currencyIdList.map((currencyId) => {
      const currency = currencyList.find((c) => c.id === currencyId);

      return currency?.label;
    });

    const options = [
      ...nameListOptions,
      ...fundNameListOptions,
      ...currencyListOptions,
      ...statusListOptions,
    ].filter((option) => !!option);

    setSearchOptions(Array.from(new Set(options)));
  };

  useEffect(() => {
    setLedgerFilteredList(ledgerList);
  }, [headerList, ledgerList]);

  useEffect(() => {
    if (search === '') {
      setLedgerFilteredList(ledgerList);
    } else {
      const searchText = new RegExp(search, 'i');

      setLedgerFilteredList(
        ledgerList.filter((ledger) => {
          const currency = currencyList.find(
            (currency) => currency.id === ledger.currency
          );
          const fund = fundNameList.find((fund) => fund.id === ledger.fundId);

          return (
            ledger.name.match(searchText) ||
            ledger.state?.match(searchText) ||
            fund?.name.match(searchText) ||
            currency?.label.match(searchText)
          );
        })
      );
    }
  }, [search]);

  const initializeHeaderList = () => {
    const updatedHeaders = [
      {
        field: 'name',
        headerName: 'Name',
        hide: false,
        index: 1,
        sortable: true,
        width: 300,
        type: 'string',
        renderCell: (params: any) => {
          return (
            <ActionLink
              id={`link_gl_${params.row.id}`}
              onClick={() => handleOnView(params.row.id, params.row)}
            >
              {params.value}
            </ActionLink>
          );
        },
      },
      {
        field: 'fundId',
        headerName: 'Fund',
        hide: false,
        index: 2,
        sortable: false,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        renderCell: (params: any) => {
          const value = fundNameList?.find(
            (fund) => fund.id === params.row.fundId
          );

          return <StringCell>{value ? value.name : ''}</StringCell>;
        },
      },
      {
        field: 'currency',
        headerName: 'Currency',
        hide: false,
        index: 3,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 240,
        renderCell: (params: any) => {
          const value = currencyList?.find(
            (currency) => currency.id === params.row.currency
          );

          return <StringCell>{value ? value.label : ''}</StringCell>;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        hide: false,
        index: 4,
        type: 'string',
        sortable: true,
        filterable: true,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.state;
        },
        renderCell: (params: any) => {
          const state = params.row.state;

          if (state !== '') {
            const status = StatusType[state as keyof typeof StatusType];

            return (
              <StatusLabel
                color={status.color}
                isUpperCase={false}
                label={capitalize(status.name ?? M_DASH_UNICODE)}
              />
            );
          } else {
            <StringCell>{''}</StringCell>;
          }
        },
        inlineFilter: true,
        inlineFilterName: LedgerFilter.Status,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: statusList ? statusList : [],
        inlineFilterSelected: selectedStatusList,
        emptySelectionOnClear: false,
      },
      {
        field: 'action',
        headerName: 'Columns',
        hide: false,
        hideable: false,
        index: 5,
        type: 'action',
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 80,
        renderCell: ({ row }: any) => {
          if (row.id === 'total') return null;
          return (
            <Actions
              row={row}
              onView={() => {
                handleOnView(row.id, row);
              }}
              allowDelete={false}
              onDelete={() => {
                setLedgersToDelete(row.id);
                onDeleteConfirm();
              }}
            />
          );
        },
      },
    ];

    let sortedHeaders: any;

    if (columnOrder && columnOrder.viewItems) {
      columnOrder.viewItems.map((item) => {
        const header = updatedHeaders.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );

      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleOnView = (ledgerId: string, ledger: ArkLedger) => {
    setSelectedLedger({
      ledger,
      type: DetailsType.Edit,
    });
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      headerList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      GL_SETUP_VIEW_KEY
    );

    saveColumnOrder(columnOrderToSave);
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      headerList,
      clientId,
      GL_SETUP_VIEW_KEY,
      field
    );

    saveColumnOrder(visiblityUpdate);
  };

  const onDetailsPanelClose = () => {
    setSelectedLedger(initialLedger);
  };

  const handleFilter = (filterName: LedgerFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }
        return header;
      })
    );
  };

  const selectedStatusList = useMemo(
    () => statusList?.map((item: any) => item.id),
    [statusList]
  );

  useEffect(() => {
    let filteredList: ArkLedger[] = ledgerList;

    headerList?.map((header: any) => {
      const auxList = filteredList ?? ledgerList;

      switch (header.inlineFilterName) {
        case LedgerFilter.Status:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((ledger: any) => {
              return header?.inlineFilterSelected?.some((selectedName: any) => {
                return selectedName === ledger.state;
              });
            });
          }
          break;
      }
    });

    setLedgerFilteredList(filteredList);
  }, [headerList]);

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedLedger({
            ledger: undefined,
            type: DetailsType.New,
          });
        }
        break;
    }
  };

  const handleDelete = (ledgerIds: any[]) => {
    const toDelete: any = { used: [], unused: [], draft: [] };

    ledgerIds?.map((id: any) => {
      const ledgerData: any = ledgerList.find(
        (ledger) => ledger.ledgerId === id
      );

      if (ledgerData.state === 'POSTED') {
        toDelete.used.push(ledgerData.platformLedgerId);
      } else if (ledgerData.state === 'DRAFT') {
        toDelete.draft.push(ledgerData.platformLedgerId);
      } else {
        toDelete.unused.push(ledgerData.platformLedgerId);
      }
      setLedgersToDelete(toDelete);
      if (toDelete.used.length) {
        setShowDeleteConfirmation(DeleteConfirmationType.USED);
      } else if (toDelete.draft.length) {
        setShowDeleteConfirmation(DeleteConfirmationType.DRAFT);
      } else {
        setShowDeleteConfirmation(DeleteConfirmationType.UNUSED);
      }
    });
  };

  const onDeleteConfirm = async (isCanceled?: () => boolean) => {
    setShowDeleteConfirmation(DeleteConfirmationType.FALSE);
    setIsLoading(true);

    const reqBody = { ledgerIds: ledgersToDelete.unused };

    if (ledgersToDelete.draft.length) {
      const deleteJEsReqBody: any = {
        filters: {
          ledgerIds: ledgersToDelete.draft,
        },
      };

      reqBody.ledgerIds = reqBody.ledgerIds.concat(ledgersToDelete.draft);

      try {
        await deleteBulkJournalEntries(deleteJEsReqBody).then(async () => {
          await deleteSelectedLedgers(reqBody).then(
            async () => await fetchAllLedgers()
          );
        });

        if (isCanceled?.()) return;
        setLedgersToDelete(undefined);
        informationAlert(DELETE_LEDGER_SUCCESS, 'success');
      } catch (error) {
        informationAlert(DELETE_LEDGER_ERROR, 'error');
      }
    } else {
      try {
        await deleteSelectedLedgers(reqBody).then(
          async () => await fetchAllLedgers()
        );
        if (isCanceled?.()) return;
        setLedgersToDelete(undefined);
        informationAlert(DELETE_LEDGER_SUCCESS, 'success');
      } catch (error) {
        informationAlert(DELETE_LEDGER_ERROR, 'error');
      }
    }
    setIsLoading(false);
  };

  const onDeleteCancel = () => {
    setLedgersToDelete(undefined);
    setShowDeleteConfirmation(DeleteConfirmationType.FALSE);
  };

  const bulkActionOptions: ImageItem[] = useMemo(() => {
    const actions = [];
    const deleteSelected = {
      id: LedgerAction.DeleteSelected,
      text: `Delete Selected (${ledgerSelectionModel?.length || 0})`,
      icon: <img src={DeleteIcon} alt="Delete Selected" height="15" />,
      optionsSelected: 0,
    };

    if (ledgerSelectionModel?.length > 0) {
      actions.push(deleteSelected);
    }

    return actions;
  }, [ledgerSelectionModel]);

  const handleBulkAction = (actionId: LedgerAction) => {
    const ledgerIds: string[] = ledgerSelectionModel;

    switch (actionId) {
      case LedgerAction.DeleteSelected:
        handleDelete(ledgerIds);
        break;
    }
  };

  return {
    isLoading,
    headerList,
    activeHeaderFields,
    ledgerFilteredList,
    ledgerSelectionModel,
    setLedgerSelectionModel,
    search,
    searchOptions,
    handleSearch,
    handleOnView,
    handleUpdateHeader,
    onColumnOrderChange,
    handleFilter,
    handleNewButtonAction,
    showSuggestionPopover,
    setShowSuggestionPopover,
    readonly,
    selectedLedger,
    fundId,
    onDetailsPanelClose,
    fetchAllLedgers,
    showDeleteConfirmation,
    onDeleteConfirm,
    onDeleteCancel,
    isSuperAdmin,
    isClientAdmin,
    bulkActionOptions,
    handleBulkAction,
    ledgersToDelete,
    deleteRole,
    arkGlLocked,
    glLocked,
  };
};
