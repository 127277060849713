import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  styled,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";

import palette from "../../styles/theme/palette";

export const LeftSection = styled(Grid)<{
  background?: string;
  textcolor?: string;
}>(({ theme, background, textcolor }) => ({
  backgroundColor: background || palette.login.leftNavigation,
  color: textcolor || palette.common.white,
  height: "100%",
}));
export const StyledGrid = styled(Grid)(() => ({
  height: "100vh",
}));

export const ContentDiv = styled("div")<{ mb?: number }>(({ mb }) => ({
  width: "70%",
  ...(mb
    ? {
        marginBottom: `${mb}px`,
      }
    : {}),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.inputOutline}`,
    "&.Mui-focused": {
      borderColor: theme.palette.common.inputOutline,
    },
  },
  "& .MuiInputLabel-shrink": {
    color: palette.login.headerBackground,
    "&.Mui-focused": {
      color: palette.login.headerBackground,
    },
  },
}));

export const HelperText = styled("div")`
  text-align: center;
  cursor: pointer;
`;

export const FooterSection = styled("div")<{ textcolor?: string }>(
  ({ textcolor }) => ({
    fontSize: "10px",
    textAlign: "center",
    color: textcolor,
    marginBottom: "12px",
  })
);

export const ActionButton = styled(Button)<{
  textcolor?: string;
  btnbackgroundcolor?: string;
}>(({ theme, textcolor, btnbackgroundcolor }) => ({
  marginTop: "10px",
  marginBottom: "10px",
  color: textcolor || theme.palette.common.white,
  backgroundColor: btnbackgroundcolor || theme.palette.primary.main,
  "&:hover": {
    color: textcolor || palette.common.white,
    backgroundColor: btnbackgroundcolor || theme.palette.primary.main,
  },
}));

export const RenderImage = styled("img")(() => ({
  width: "390px",
  textAlign: "center",
}));

export const StyledLabel = styled("span")<{ color?: string }>(
  ({ theme, color }) => ({
    color: color || theme.palette.common.white,
    wordWrap: "break-word",
  })
);
export const StyledResponsiveLabel = styled("span")<{ color?: string }>(
  ({ theme, color }) => ({
    color: color || theme.palette.common.white,
    wordWrap: "break-word",
    fontSize: theme.typography.pxToRem(16),
  })
);
export const HeaderDiv = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "350px",
  height: "56px",
}));

export const LoginView = styled(Grid)(() => ({
  minHeight: "100vh",
  margin: "0px",
}));

export const WhiteIcon = styled(ArrowBackIosIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const HeaderGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.common.white,
  margin: "0px",
}));

export const LoginBody = styled(Grid)<{
  background?: string;
  textcolor?: string;
}>(({ theme, background, textcolor }) => ({
  background: background || theme.palette.common.white,
  color: textcolor,
  height: "100%",
}));

export const StyledDivider = styled(Divider)<{ border?: string }>(
  ({ theme, border }) => ({
    marginTop: "16px",
    marginBottom: "16px",
    borderColor: border || theme.palette.common.white,
  })
);

export const StyledFont = styled("span")<{ size?: string; textcolor?: string }>(
  ({ theme, size, textcolor }) => ({
    fontSize: size
      ? theme.typography.pxToRem(parseInt(size))
      : theme.typography.pxToRem(14),
    ...(textcolor
      ? {
          color: textcolor,
        }
      : {}),
  })
);
export const ClickLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const DivSpacing = styled("div")(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
}));

export const StyledSVG = styled(Grid)<{
  svgcolor?: string;
}>(({ svgcolor }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "8px",
  "& svg": {
    "& path": {
      fill: svgcolor ? `${svgcolor} !important` : "#ffffff !important",
    },
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(16),
}));
