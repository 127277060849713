import { DateTimeFormat } from '../utils/helpers/format.helper';
import { CustomProperty } from '../utils/types/customProperty.type';
import { PortfolioCompany, PortfolioCompanyNew } from '../utils/types/portfolioCompany.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2); };

export const getPortfolioCompanies = async (fundIds?: string) => {
  const path = '/entities/portfolio-companies'.concat(fundIds ? `?funds=${fundIds}` : '');
  const response = await apiClient().get<any>(path);

  return response;
};

export const getSoiTransactionSummary = async (dateThru: Date) => {
  //const dateThruToUse = new Date(dateThru.setTime(dateThru.getTime() + 86400000)); // add a day
  const dateThruToUse = new Date(dateThru);

  dateThruToUse.setTime(dateThruToUse.getTime() + 86400000);

  const isoDate = DateTimeFormat.isoDateString(dateThruToUse);
  const path = `/transactions/soi/transactions/summary?date=${isoDate}`;
  const response = await apiClient().get<any>(path);

  return response;
};

export const getSoiTransactionSummaryV2 = async (dateThru: Date, view: string) => {
  
  const currentView = view.toUpperCase();
  const dateThruToUse = DateTimeFormat.getReversedDate(dateThru);

  const path = `/transactions/soi/transactions/summary/view?date=${dateThruToUse}&view=${currentView}`;
  const response = await apiClient().get<any>(path);

  return response;
};

export const addPortfolioCompany = async (params: PortfolioCompany | PortfolioCompanyNew) => await apiClient().post(`/entities/portfolio-companies`, params);

export const updatePortfolioCompany = async (params: PortfolioCompany, portfolioCompanyID: string, clientID: string) => await apiClient().put(`/entities/portfolio-companies/${portfolioCompanyID}`, params);

export const deletePortfolioCompany = async (portfolioCompanyID: string) => await apiClient().delete(`/entities/portfolio-companies/${portfolioCompanyID}`, {});

export const uploadPortfolioCompanyLogo = async (clientId: string, portfolioCompanyID: string, file: any) => {
  const formData = new FormData();

  formData.append('portfolioCompanyId', portfolioCompanyID);
  formData.append('logo', file);

  return await apiClient().post(`/entities/portfolio-companies/${clientId}/logo`, formData);
};

export const downloadPortfolioCompanies = async (
  portfolioCompanyList: string[],
  fundList: string[],
) => {
  const body = {
    fundIds: fundList,
    ids: portfolioCompanyList,
  };

  const templateFile = await apiClient().post('/entities/portfolio-companies/downloads', body);

  return templateFile;
};

export const getCustomPropertyTypes = async () => await apiClient().get(`/entities/custom-properties/default-types`);

export const addCustomProperty = async (params: any) => await apiClient().post(`/entities/custom-properties`, params);

export const updateCustomProperty = async (params: CustomProperty, customPropertyId: string) => await apiClient().put(`/entities/custom-properties/${customPropertyId}`, params);

export const getCustomProperties = async () => await apiClient().get(`/entities/custom-properties`);

export const deleteCustomProperty = async (customPropertyId: string) => await apiClient().delete(`/entities/custom-properties/${customPropertyId}`);
