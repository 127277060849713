import { FileDownloadOutlined } from "@mui/icons-material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import useRole from "../../../../core/routing/useRole";
import { fetchBeforeSave } from "../../../../utils/helpers/misc.helper";
import { ScopeRole } from "../../../../utils/types/user.type";
import DisplayPdf from "../../../capitalCalls/capitalCallDetails/ReviewDocuments/DisplayPdf";
import EmailViewer from "../../../capitalCalls/capitalCallDetails/ReviewDocuments/EmailViewer";
import PdfViewer from "../../../capitalCalls/capitalCallDetails/ReviewDocuments/PdfViewer";
import { DistributionDetailsTabs } from "../Constants";
import {
  ApproveNextContainer,
  ButtonBox,
  ButtonContainer,
  DownLoadButton,
  EmailContainer,
  EmailPdfContainer,
  FlagButton,
  LeftBox,
  LinkButton,
  MainContainer,
  MarginFixContainer,
  PreviousContainer,
  ReviewTypography,
  RightBox,
  SplitButtonContainer,
  StyledButton,
  StyledContainer,
  StyledDivider,
  StyledStack,
} from "./ReviewDocument.style";
import { useReviewDistribution } from "./useReviewDocument.hook";

type RouteProp = {
  id: string;
};

const ReviewDocument: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteProp>();

  const {
    selectedContactMode,
    contactModeOptions,
    onContactModeChange,
    distributionReviews,
    distributionInvestors,
    documentUrls,
    distributionEmailContent,
    handleSelectChange,
    handleRightSideOnClick,
    currentIndex,
    handlePrevious,
    handleNext,
    handleFlag,
    fullScreen,
    setFullScreen,
    currentReview,
    isNextDisabled,
    nextButtonText,
    loading,
    isSuperAdminOrClientAdmin,
    distributions,
    allowEdit,
    handleDownloadAll,
  } = useReviewDistribution();

  return (
    <Box>
      <Progress id="review_documents_progress_bar" showProgress={loading} />
      {distributionReviews?.length === 0 ? (
        <>
          <ReviewTypography variant="h2">No reviews found</ReviewTypography>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
            stickToCenter={true}
            columnGap={true}
            sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }}
          >
            <StyledButton
              id="btn_go_back"
              variant="outlined"
              onClick={() => {
                history.push(
                  `/distributions/${params?.id}/${DistributionDetailsTabs.AddTransactions}`
                );
              }}
            >
              Go Back
            </StyledButton>
            <StyledButton
              id="btn_save_continue"
              variant="contained"
              onClick={() => {
                history.push(
                  `/distributions/${params?.id}/${DistributionDetailsTabs.Publish}`
                );
              }}
            >
              Continue to Publish
            </StyledButton>
          </HorizontalBox>
        </>
      ) : (
        <>
          <StyledDivider variant="fullWidth" />
          <MainContainer>
            <LeftBox>
              <Stack direction="row">
                <StyledStack>
                  <Select
                    id="investors_select"
                    value={currentReview?.investorName || ""}
                    onChange={handleSelectChange}
                    sx={{
                      height: "75%",
                    }}
                  >
                    {distributionInvestors.map((investor, index) => (
                      <MenuItem key={index * Math.random()} value={investor}>
                        <Stack
                          key={(index + 1) * Math.random()}
                          direction="row"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            key={index + 2 * Math.random()}
                            variant="caption"
                          >
                            <strong> {investor} </strong>
                          </Typography>

                          <Typography
                            key={index + 3 * Math.random()}
                            variant="h6"
                            sx={{
                              marginLeft: "10px",
                            }}
                          >
                            {index + 1} of {distributionInvestors.length}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </StyledStack>
                <ButtonBox>
                  <ToggleButton
                    id="toggle_contact_mode"
                    options={contactModeOptions}
                    value={selectedContactMode}
                    onChange={onContactModeChange}
                  />
                </ButtonBox>
                <PreviousContainer>
                  <LinkButton
                    id="previous"
                    variant="text"
                    disableElevation
                    startIcon={<ArrowBackIosRoundedIcon />}
                    onClick={handlePrevious}
                    name="previous"
                    disabled={currentIndex === 0 || !isSuperAdminOrClientAdmin}
                  >
                    Previous
                  </LinkButton>
                </PreviousContainer>
                <ApproveNextContainer>
                  <LinkButton
                    id="approve_next"
                    variant="contained"
                    disableElevation
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleNext}
                    name="next"
                    disabled={isNextDisabled}
                  >
                    {distributions?.status?.toLowerCase() !== "reviewing" &&
                    !allowEdit
                      ? "Next"
                      : currentReview?.canApprove
                      ? nextButtonText
                      : "Next"}
                  </LinkButton>
                </ApproveNextContainer>
              </Stack>
              <StyledContainer>
                <Stack>
                  {currentReview?.canApprove && (
                    <FlagButton
                      size="small"
                      variant="outlined"
                      disabled={
                        (distributions?.status?.toLowerCase() !== "reviewing" &&
                          !allowEdit) ||
                        distributions?.status?.toLowerCase() === "published"
                      }
                      startIcon={<FlagCircleIcon />}
                      onClick={() => {
                        const DistributionResultFlag =
                          currentReview?.checked ||
                          currentReview?.checked === null
                            ? false
                            : null;

                        handleFlag(DistributionResultFlag);
                      }}
                      color={
                        currentReview?.checked === false ? "error" : "inherit"
                      }
                      fullscreen={fullScreen.toString()}
                    >
                      Flag
                    </FlagButton>
                  )}
                </Stack>
                {selectedContactMode === "distribution" && (
                  <EmailPdfContainer fullscreen={fullScreen.toString()}>
                    <ButtonContainer direction="row" spacing={1}>
                      <DownLoadButton
                        id={`pdf_download_button`}
                        variant="outlined"
                        onClick={() =>
                          fetchBeforeSave(
                            documentUrls[currentIndex],
                            `${distributionInvestors[currentIndex]}.pdf`
                          )
                        }
                        size="small"
                        startIcon={<FileDownloadOutlined />}
                      >
                        <strong>Download PDF</strong>
                      </DownLoadButton>
                      <IconButton
                        aria-label="fullscreen"
                        onClick={() =>
                          setFullScreen((fullscreen) => !fullscreen)
                        }
                      >
                        <FullscreenIcon />
                      </IconButton>
                    </ButtonContainer>
                    <PdfViewer pdfLink={documentUrls[currentIndex]} />
                  </EmailPdfContainer>
                )}
                {selectedContactMode === "email" && (
                  <EmailContainer fullscreen={fullScreen.toString()}>
                    <EmailViewer
                      contactEmails={currentReview?.contactEmails}
                      email={distributionEmailContent[currentIndex]}
                    />
                  </EmailContainer>
                )}
              </StyledContainer>
            </LeftBox>

            <RightBox>
              <SplitButtonContainer>
                <DownLoadButton
                  id={`pdf_download_button`}
                  variant="outlined"
                  onClick={handleDownloadAll}
                  size="small"
                  startIcon={<FileDownloadOutlined />}
                >
                  <strong>Download All </strong>&nbsp;
                </DownLoadButton>
                <DisplayPdf
                  handleOnClick={handleRightSideOnClick}
                  investors={distributionInvestors}
                  documentUrls={documentUrls}
                  reviews={distributionReviews}
                  selectedIndex={currentIndex}
                ></DisplayPdf>
              </SplitButtonContainer>
            </RightBox>

            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              stickToCenter={true}
              columnGap={true}
              sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }} 
            >
              <StyledButton
                id="btn_go_back"
                variant="outlined"
                onClick={() => {
                  history.push(
                    `/distributions/${params?.id}/${DistributionDetailsTabs.AddTransactions}`
                  );
                }}
              >
                Go Back
              </StyledButton>
              <StyledButton
                id="btn_save_continue"
                variant="contained"
                onClick={() => {
                  history.push(
                    `/distributions/${params?.id}/${DistributionDetailsTabs.Publish}`
                  );
                }}
              >
                Continue to Publish
              </StyledButton>
            </HorizontalBox>
          </MainContainer>
        </>
      )}
    </Box>
  );
};

export default React.memo(ReviewDocument);
