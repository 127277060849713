import { useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Role, RolesList } from "../../../utils/types/team.type";
import { getRoles } from "../../teams.service.v2";

export const useTeamRolesEffect = () => {
  const [teamRoles, setTeamRoles] = useState<RolesList | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchTeamRoles = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getRoles();

      if (isCanceled?.()) return;

      setTeamRoles(response);
      setLoading(false);
    } catch (e) {
        informationAlert("Error in getting team roles", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchTeamRoles(isCanceled);
  }, []);

  const currentTeamRoles = useMemo(() => {
    return teamRoles?.items || [];
  }, [teamRoles]);

  const updateTeamRoles = (updatedTeamRoles: Role[]) => {
    setTeamRoles((prevState) => ({
      ...(prevState ? prevState : {}),
      items: updatedTeamRoles
    }));
  };

  return {
    teamRoles: currentTeamRoles,
    fetchTeamRoles,
    loading,
    updateTeamRoles,
  };
};
