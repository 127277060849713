import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import RoutingPaths from '../../core/routing/routingPaths';
import useRole from '../../core/routing/useRole';
import { ScopeRole } from '../../utils/types/user.type';
import { ClientTabs } from './constants';

interface RouteProp {
  id: string;
  arkTag: string;
  section: string;
}

const getIndexBySection = (section?: string) => {
  switch (section) {
    case ClientTabs.TransactionMapping:
      return 1;
    case ClientTabs.ReportConfig:
      return 2;
    case ClientTabs.ConsentConfig:
      return 3;
    case ClientTabs.FeatureConfig:
      return 4;
    case ClientTabs.ClientDetails:
    default:
      return 0;
  }
  return 0;
};

export const useClient = () => {
  const { id, arkTag, section } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const history = useHistory();

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const { hasRole: isArkClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const isNewClientTag = useMemo(() => arkTag === 'new', [arkTag]);

  const isNewClient = useMemo(() => id === 'new', [id]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) {
      return history.push(
        `/clients/${id}/${arkTag}/${ClientTabs.ClientDetails}`
      );
    }
    if (newValue === 1) {
      return history.push(
        `/clients/${id}/${arkTag}/${ClientTabs.TransactionMapping}`
      );
    }
    if (newValue === 2) {
      return history.push(
        `/clients/${id}/${arkTag}/${ClientTabs.ReportConfig}`
      );
    }
    if (newValue === 3) {
      return history.push(
        `/clients/${id}/${arkTag}/${ClientTabs.ConsentConfig}`
      );
    }
    if (newValue === 4) {
      return history.push(
        `/clients/${id}/${arkTag}/${ClientTabs.FeatureConfig}`
      );
    }
    return null;
  };

  useEffect(() => {
    setTab(getIndexBySection(section));
  }, [section]);

  const handleClose = () => {
    history.push(RoutingPaths.Clients);
  };

  const handleCloseIcon = () => {
    history.push(RoutingPaths.AppDashboard);
  };

  return {
    tab,
    setTab,
    arkTag,
    isNewClientTag,
    isNewClient,
    handleChange,
    handleClose,
    handleCloseIcon,
    isSuperAdmin,
    isArkClientAdmin,
  };
};
