import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import * as React from "react";
import { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import IconRightArrow from "../../../assets/images/icons/icon_right_arrow.svg";
import HorizontalBox from "../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../components/Buttons/Button/Button";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import CountrySelect from "../../../components/CountryCodeSelector/CountrySelect";
import ImgIcon from "../../../components/ImgIcon/ImgIcon";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import ProgressPanel from "../../../components/Progress/ProgressPanel/ProgressPanel";
import SingleSelect from "../../../components/SingleSelect/SingleSelect";
import { EMAIL_REGEX } from "../../../utils/constants/constants";
import { SelectedUser } from "../../../utils/types/user.type";
import { StyledBackdrop } from "../../investors/investorList/investorDetailPanel/InvestorDetailPanel.styles";
import { STATUS } from "../constants";
import ClientTeamRoleSelector from "./ClientTeamRoleSelector/ClientTeamRoleSelector";
import { ArrowIconBox, DetailPanel, FormBox, PhoneFormHelperText, StyledGrid, TitleBox } from "./UserDetailPanel.styles";
import { useUserDetails } from "./useUserDetails.hooks";

interface Props extends RouteProps {
    onUserDetailClose: Function;
    resetPaginationAndFetch: Function;
    selectedUser: SelectedUser;
}

const UserDetails: React.FC<Props> = ({
  onUserDetailClose,
  resetPaginationAndFetch,
  selectedUser,
}: Props): ReactElement => {
  const {
    type, user, arkClientTag,
  } = selectedUser;
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    control,
    createUpdateUser,
    loading,
    toggleDrawer,
    toBeDeleted,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    bulkActions,
    handleBulkAction,
    currentRoles,
    isNewUser,
    isAddingEditingSuperAdmin,
    isAddingClient,
    showResendConfirmation,
    userToCreateUpdate,
    onResendCancel,
    onResendConfirm,
    onCreateCancel,
    onCreateConfirm,
  } = useUserDetails({
    selectedUser: selectedUser || {},
    resetPaginationAndFetch,
    onUserDetailClose,
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="user_details_panel"
        anchor="right"
        open={Boolean(type)}
        onClose={toggleDrawer}
      >
        <TitleBox>
          <Typography id="title" variant="formTitle" display="flex">
            {isNewUser ? "Add New User ": `${user?.firstName ?? ''} ${user?.lastName}`} <ArrowIconBox><ImgIcon icon={IconRightArrow}/></ArrowIconBox>
          </Typography>
          <CloseIcon color="primary" onClick={toggleDrawer}/>
        </TitleBox>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdateUser)}
          noValidate
        >
          <FormBox>
            <StyledGrid container xs={12} spacing={2}>
              <Grid item xs={8}>
                <TextField
                  id="user_first_name"
                  size="small"
                  fullWidth
                  aria-describedby="first name"
                  label="First Name"
                  placeholder="First Name"
                  variant="outlined"
                  error={!!errors.firstName}
                  {...register("firstName", {
                    required: true,
                    validate: (value: any) => !!value?.trim(),
                  })}
                  helperText={!!errors.firstName && "First Name is required"}
                />
              </Grid>
              <Grid item xs={4}>
                {!isNewUser && <SplitButton
                  id={"btn_bulk_action_users"}
                  options={bulkActions}
                  hidden={false}
                  handleOptionClick={handleBulkAction}
                  ariaLabelMessage="Select bulk action option"
                />}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="user_last_name"
                  size="small"
                  fullWidth
                  aria-describedby="last name"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="outlined"
                  error={!!errors.lastName}
                  {...register("lastName", {
                    required: true,
                    validate: (value: any) => !!value?.trim(),
                  })}
                  helperText={!!errors.lastName && "Last Name is required"}
                />
              </Grid>
              <Grid xs={8} item>
                <Controller
                  name="role"
                  render={({
                    field: {
                      onChange, value
                    },
                    fieldState: {
                      error
                    }
                  }) => {
                    return (
                      <SingleSelect
                        id="user_role"
                        noGutter
                        optionList={currentRoles || []}
                        idField="id"
                        labelField="name"
                        fullWidth
                        handleOptionSelection={(event: SelectChangeEvent) => {
                          const newRoleId = event.target.value;
                          const newRoleName = currentRoles?.find((role) => role.id === newRoleId)?.name;

                          onChange({
                            id: newRoleId,
                            name: newRoleName
                          });
                        }}
                        value={value?.id ?? ''}
                        error={!!error}
                        helperText={error?.message ?? ''}
                        disabled={isAddingEditingSuperAdmin}
                        label="Role" />
                    );
                  }}
                  rules={{
                    required: "Role is required",
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="user_email"
                  size="small"
                  fullWidth
                  aria-describedby="Email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  inputProps={{ sx: { textTransform: "lowercase" } }}
                  error={!!errors.email}
                  {...register("email", {
                    required: 'Please enter a valid email.',
                    validate: (value: any) => {
                      if(EMAIL_REGEX.test(value)) {
                        return true;
                      } else {
                        return 'Please enter a valid email.';
                      }
                    },
                    setValueAs: (value) => value?.trim()?.toLowerCase() ?? "",
                  })}
                  helperText={errors?.email?.message}
                />
              </Grid>
              <Grid xs={8} item>
                <Controller
                  name="phone"
                  render={({
                    field: {
                      onChange, value
                    },
                    fieldState: {
                      error
                    }
                  }) => {
                    return (
                      <>
                        <CountrySelect
                          id="user_phone"
                          label={"Phone"}
                          error={!!error}
                          fullWidth
                          country="us"
                          onChange={(_: any,__: any,___: any,value: string) => onChange(value)}
                          value={value || ''}
                        />
                        {error && <PhoneFormHelperText error={!!error}>{error.message}</PhoneFormHelperText>}
                      </>
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="user_location"
                  size="small"
                  fullWidth
                  aria-describedby="location"
                  label="Location"
                  placeholder="Location"
                  variant="outlined"
                  error={!!errors.location}
                  {...register("location")}
                  helperText={!!errors.location && "Location is required"}
                />
              </Grid>
              {isAddingClient && (
                <Grid item xs={8}>
                  <TextField
                    id="user_ark_client_tag"
                    size="small"
                    fullWidth
                    aria-describedby="Ark Client Tag"
                    label="Ark Client Tag"
                    placeholder="Ark Client Tag"
                    variant="outlined"
                    error={!!errors.arkClientTag}
                    {...register("arkClientTag", {
                      required: true,
                      validate: (value: any) => !!value?.trim(),
                    })}
                    helperText={!!errors.arkClientTag && "Ark Client Tag is required"}
                  />
                </Grid>
              )}
              <Grid xs={8} item>
                <Controller
                  name="status"
                  render={({
                    field: {
                      onChange, value
                    },
                    fieldState: {
                      error
                    }
                  }) => {
                    return (
                      <SingleSelect
                        id="user_status"
                        noGutter
                        optionList={STATUS || []}
                        idField="id"
                        labelField="name"
                        fullWidth
                        handleOptionSelection={(event: SelectChangeEvent) => {
                          onChange({
                            target: {
                              value: event.target.value
                            }
                          });
                        }}
                        value={value ?? ''}
                        error={!!error}
                        helperText={error?.message ?? ''}
                        label="Status" />
                    );
                  }}
                  control={control}
                />
              </Grid>
              {!isAddingClient && (
                <Grid xs={12} item>
                  <Controller
                    name="teamMembers"
                    render={({
                      field: {
                        onChange, value
                      },
                    }) => {
                      return (
                        <ClientTeamRoleSelector
                          teamMembers={value}
                          onChange={onChange}
                          arkClientTag={arkClientTag} />
                      );
                    }}
                    control={control}
                  />
                </Grid>
              )}
            </StyledGrid>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={"user_cancel_button"}
              variant="outlined"
              onClick={toggleDrawer}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={"user_save_button"}
              variant="contained"
              text={"Save & Close"}
              color={"primary"}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop
          open={Boolean(loading)}
        />
        <ProgressPanel
          id={"progress_user_panel"}
          showProgress={Boolean(loading)}
          text={`${loading} User...`}
        />
      </DetailPanel>
      <DeleteConfirmationDialog
        open={Boolean(toBeDeleted)}
        id="delete_user_confirmation_dialog"
        primaryButtonAction={() => handleConfirmDelete(toBeDeleted as string)}
        secondaryButtonAction={handleCancelDelete} />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_user_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'save_user_modal_button',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'cancel_save_user_modal_button',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"/>
      <ConfirmationDialog
        open={!!userToCreateUpdate}
        onClose={onCreateCancel}
        id="activation_email_confirmation"
        actions={[
          {
            label: 'Send Activation Email',
            onClick: onCreateConfirm,
            id: 'send_activation_email_modal_button',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Cancel',
            onClick: onCreateCancel,
            id: 'cancel_send_activation_email_modal_button',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content="This will send an automatic, system generated activation email for this user to set their credentials and access the portal. Would you like to continue?"
        title="Send Activation Email?"/>
      <ConfirmationDialog
        open={showResendConfirmation}
        onClose={onResendCancel}
        id="activation_email_confirmation"
        actions={[
          {
            label: 'Resend Activation Email',
            onClick: onResendConfirm,
            id: 'resend_activation_email_modal_button',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Cancel',
            onClick: onResendCancel,
            id: 'cancel_resend_activation_email_modal_button',
            variant: 'outlined',
            color: 'error'
          }
        ]}
        content="This will resend an automatic, system generated activation email for this user to set their credentials and access the portal. Would you like to continue?"
        title="Resend Activation Email?"/>
    </React.Fragment>
  );
};

export default UserDetails;
