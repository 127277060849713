import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Divider } from '@mui/material';

import { PENDING_YELLOW_COLOR } from '../../styles/colors';
import HorizontalBox from '../Boxes/HorizontalBox/HorizontalBox';
import Button from '../Buttons/Button/Button';
import Container from '../Container/Container';
import { BoxView, Header, HeaderTitle } from './PendingChangesView.styles';

const PendingChangesView = ({
  declineChanges,
  approveChanges,
  title,
  children,
}: {
  approveChanges: any;
  declineChanges: any;
  title: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Container id="container_investor_main" role="main">
      <HorizontalBox>
        <BoxView>
          <Header>
            <HeaderTitle>
              <div>
                <AccessTimeIcon sx={{ color: PENDING_YELLOW_COLOR }} />
                <div>{title}</div>
              </div>
            </HeaderTitle>
            <div>
              {/* <Button
                id={"btn_investor_detail_panel_cancel"}
                variant="outlined"
                onClick={declineChanges}
                text={"Decline All"}
                color={"error"}
                addSpaceBetweenButtons
              /> */}
              <Button
                id={'btn_investor_detail_panel_save'}
                variant="contained"
                onClick={approveChanges}
                text={'Approve All'}
                color={'primary'}
                addSpaceBetweenButtons
              />
            </div>
          </Header>
          <Divider />
          {children}
        </BoxView>
      </HorizontalBox>
    </Container>
  );
};

export default PendingChangesView;
