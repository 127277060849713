type Props = {
  setLockedPrompt: (value: boolean) => void;
};

export const useLockConfirmationDailog = ({ setLockedPrompt }: Props) => {
  const closeLockedDialog = () => {
    setLockedPrompt(false);
  };

  return {
    closeLockedDialog,
  };
};
