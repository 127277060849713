import { Autocomplete, CircularProgress } from '@mui/material';
import React from 'react';

import { LoadingStatus } from '../../../utils/types/form.type';
import TextField from '../../TextField/TextField';

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (purchaseTransaction: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
  accountId?: string;
  accountList: any[];
  readonly?: boolean;
  setValue: Function;
  setValueField: string;
  loading?: LoadingStatus;
};

type AccountValue = any | null | undefined;

export const AccountFilter = ({
  error,
  onChange,
  placeholder = '',
  value,
  idSelectOnly = false,
  disabled,
  size = 'small',
  id = 'account_filter',
  textFieldID = 'txt_account',
  accountList,
  readonly = false,
  setValue,
  setValueField,
  loading,
}: Props) => {
  const onSelectChange = (_: any, account: any) => {
    if (!account) {
      return;
    }
    onChange?.(idSelectOnly ? account?.id ?? '' : account);
    setValue(setValueField, account.isEntityRequired);
  };

  const selectedValue: AccountValue = idSelectOnly
    ? accountList?.find((item: any) => item.id === value) ?? null
    : (value as AccountValue);

  const readOnlyValue: AccountValue = accountList?.find(
    (item: any) => item.id === value
  );

  if (readonly) {
    return readOnlyValue?.name || '';
  } else {
    return (
      <Autocomplete
        id={id}
        options={accountList || []}
        getOptionLabel={(option: any) => option?.name ?? ''}
        isOptionEqualToValue={(option: any, selected: any) =>
          option?.name === selected?.name
        }
        onChange={onSelectChange}
        value={selectedValue ?? {}}
        size={size}
        disabled={disabled || Boolean(loading)}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              id={textFieldID}
              placeholder={placeholder}
              label={placeholder}
              error={!!error}
              helperText={error?.message ?? ''}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </>
        )}
      />
    );
  }
};

export default AccountFilter;
