import { Grid, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../components/Progress/ProgressPanel/ProgressPanel';
import AccountFilter from '../../../components/Selectors/AccountFilter/AccountFilter';
import JELedgerFilter from '../../../components/Selectors/JELedgerFilter/JELedgerFilter';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import { SelectedBankAccount } from '../../../utils/types/bank.type';
import { useBankAccountDetails } from './BankAccountDetails.hooks';
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from './BankAccountDetails.styles';

interface Props {
  onDetailClose: Function;
  selectedBankAccount: SelectedBankAccount;
  fetchAllFilters: Function;
}

export const BankAccountDetails: React.FC<Props> = ({
  onDetailClose,
  selectedBankAccount,
  fetchAllFilters,
}: Props) => {
  const { type, bankAccount } = selectedBankAccount;

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateBankAccountDetails,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    toggleDrawer,
    ledgerList,
    accountList,
    fundList,
  } = useBankAccountDetails({
    bankAccount,
    onClose: onDetailClose,
    selectedBankAccount,
    fetchAllFilters,
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_bank_card"
        title={bankAccount?.name ? bankAccount.name : ''}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(updateBankAccountDetails)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel">
              <Typography variant="h4">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Name</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.name || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Official Name</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.name || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="nickname"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_nickname"
                          label="Nickname"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={value ?? ''}
                          onChange={onChange}
                          InputLabelProps={{ shrink: !!value }}
                          error={!!error}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} mt={2} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Type</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.type.toUpperCase() || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Sub Type</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.subType.toUpperCase() || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                <Grid item xs={12} marginLeft={1}>
                  <KeyValuePair>
                    <KeyLabel>Fund</KeyLabel>
                    <ValueLabel>
                      {bankAccount?.fundName || M_DASH_UNICODE}
                    </ValueLabel>
                  </KeyValuePair>
                </Grid>
                {ledgerList && ledgerList.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="ledgerId"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <JELedgerFilter
                            size="small"
                            id={'gl_filter'}
                            idSelectOnly
                            value={value}
                            onChange={onChange}
                            ledgerList={ledgerList}
                            loading={isLoading}
                            setSelectedLedgerCurrency={() => {}}
                            setSelectedLedgerDecimal={() => {}}
                            error={error}
                            placeholder="GL Name"
                            disableWhenOne={false}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="glAccountId"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <AccountFilter
                            size="small"
                            id="gl_account_filter"
                            idSelectOnly
                            value={value || ''}
                            accountList={accountList}
                            loading={isLoading}
                            onChange={(accountId) => {
                              onChange(accountId);
                            }}
                            error={error}
                            placeholder="GL Account"
                            setValue={setValue}
                            setValueField={''}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'btn_bank_card_cancel'}
              variant="outlined"
              onClick={toggleDrawer}
              text={'Cancel'}
              color={'secondary'}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={'btn_bank_card_save'}
              variant="contained"
              text={'Save & Close'}
              color={'primary'}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_bank_card_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading}...` : ''}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_bank_card_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_bank_card_save',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_bank_card_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};
