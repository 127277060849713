import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import React, { ReactElement } from "react";

import { MultiSelect } from "../../components/MultiSelect/MultiSelect";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { DISPLAY_DATE_FORMAT } from "../../utils/constants/constants";
import { DateRangeContainer, DateRangeContainerFix, DateRangeTextField, FirstRow, HeaderRow, StyledFormControl } from "./Report.styles";
import { useReports } from "./useReports.hooks";

const Reports: React.FC = (): ReactElement => {
  const {
    loading,
    handleChange,
    handleDateChange,
    handleDateRangeChange,
    dateRange,
    selectedMenu,
    generateReport,
    menuList,
    date,
    funds,
    handleFundsChange,
    isGenerateReportButtonDisabled
  } = useReports();

  return (
    <Box
      id="reports_list_main">
      <Progress
        id="reports_list_loading"
        showProgress={loading}
      />
      <HeaderRow>
        <Typography variant="pageTitle">Reports</Typography>
      </HeaderRow>
      <Divider />
      <FirstRow>
        <FormControl>
          <InputLabel id="demo-simple-select-helper-label">{"Select Report"}</InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="reports_list_select_label"
            id="reports_list_select"
            value={selectedMenu?.value || "select"}
            label="Select Report"
            placeholder="Select Report"
            onChange={handleChange}
          >
            <MenuItem value="select" key="select" disabled>
              <em>Select Report</em>
            </MenuItem>
            {menuList.reduce((prevValue: ReactElement[], {
              value, label, hide
            }) => {
              if(!hide) {
                prevValue.push(
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                );
              }
              return prevValue;
            }, [])}
          </Select>
        </FormControl>
        <Button
          id="generate-report"
          variant="contained"
          color="primary"
          disabled={Boolean(isGenerateReportButtonDisabled)}
          onClick={generateReport}>
            Generate Report
        </Button>
      </FirstRow>
      {selectedMenu?.dateRangeRequired && (
        <DateRangeContainerFix>
          <DateRangePicker
            calendars={1}
            value={dateRange}
            onChange={handleDateRangeChange}
            inputFormat={DISPLAY_DATE_FORMAT}
            showToolbar={false}
            mask="__/__/____"
            renderInput={(startProps, endProps) => (
              <DateRangeContainer>
                <DateRangeTextField
                  {...startProps}
                  id="reports_date_picker_start"
                  size="small"
                  label="From"
                  placeholder="From"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  }} />
                <DateRangeTextField
                  {...endProps}
                  id="reports_date_picker_end"
                  size="small"
                  label="To"
                  placeholder="To"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  }} />
              </DateRangeContainer>
            )}
          />
          <Box />
        </DateRangeContainerFix>
      )}
      {selectedMenu?.dateRequired && (
        <StyledFormControl size="small">
          <DatePicker
            label="Select Date"
            renderInput={(params) =>
              <TextField {...params}
                size="small"
                id="tamarac_reports_date"
              />
            }
            onChange={value => handleDateChange(value)}
            value={date}
          />
        </StyledFormControl>
      )}
      {selectedMenu?.fundsRequired && (
        <MultiSelect
          id="tamarac_reports_funds"
          label="Select Funds"
          items={funds}
          onChange={handleFundsChange}
        />
      )}
    </Box>
  );
};

export default React.memo(Reports);
