import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import ArrowPopover from "../../../../../components/ArrowPopover/ArrowPopover";
import { ClientListItem } from "../../../../../utils/types/user.type";
import { ClientSelectorContainer } from "../../UserDetailPanel.styles";
import ClientList from "./ClientList";

interface Props {
  clientList: ClientListItem[];
  onClientToggle: (clientId: string) => void;
}

const ClientSelector: React.FC<Props> = ({
  clientList,
  onClientToggle
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <ClientSelectorContainer>
        <Button
          id="client_selection_button"
          variant="outlined"
          endIcon={<KeyboardArrowDown />}
          onClick={handleOnClick}
        >
            Select Clients
        </Button>
        <Typography variant="subtitle1">(Optional)</Typography>
      </ClientSelectorContainer>
      <ArrowPopover
        id="client_team_selection"
        showPopover={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleOnPopoverClose={handleOnClose}
        verticalOrigin="bottom"
        orientation="right"
        content={<ClientList options={clientList} onClientToggle={onClientToggle} />}
      />
    </>
  );
};

export default ClientSelector;
