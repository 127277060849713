import { AuthorizationParams, createAuth0Client, IdToken, PopupLoginOptions, User } from '@auth0/auth0-spa-js';

import { AvailableDomain, ClientConfigs, ClientTokenResponse } from "../utils/types/client.type";
import { ClientTransMapping } from "../utils/types/clientTransMapping.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

const authApiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.AuthApi);
};

export const getClientConfigsList = async () => await apiClient().get<ClientConfigs[]>("clients/configs");

export const getClientTransactionMapping = async (clientId:string) => {
  const transMapping = await apiClient().get<ClientTransMapping>(`clients/${clientId}/transactionMapping`);

  return transMapping;
};

export const updateTransactionMapping = async (clientId: string, transactionMapping: ClientTransMapping) => await apiClient().put(`clients/${clientId}/transactionMapping`, transactionMapping);

export const getARKClientTags = async () => await apiClient().get<string[]>("users/arkclients");

export const getAvailableDomains = async () => await apiClient().get<AvailableDomain[]>("domains/available");

export const checkIfSubdomainIsAvailable = async (domain: string, subdomain: string, hostedZoneId?: string) => await apiClient().get<boolean>(`domains/${domain}/subdomains/${subdomain}/hostedZones/${hostedZoneId}`);

export const getArkClientToken = async (clientId: string) => await authApiClient().get<ClientTokenResponse>(`token/client?clientId=${clientId}`);

export const checkMFASettings = async (clientId: string) => await apiClient().get(`api/clients/${clientId}/mfaSettings`);


const getRedirectUri = () => location.origin;

export const getAuth0Token = async (clientConfigs: ClientConfigs, forceRedirect: boolean = false) => {
  const auth0Client = await createAuth0Client({
    domain: clientConfigs.authDomain,
    clientId: clientConfigs.authClientId,
    authorizationParams: {
      redirect_uri: getRedirectUri(),
      organization: clientConfigs.authOrganization,
      audience: '/api',
    }
  });

  const isLoggedIn = await auth0Client.isAuthenticated();

  if (!forceRedirect && (isLoggedIn || location.search.includes("code="))) {
    
    try {
      if(!isLoggedIn) {
        if(!location.search.includes("state=")) {
          throw 'State is required from Auth0 to login';
        }

        await auth0Client.handleRedirectCallback();
      }

      const accessToken = await auth0Client.getTokenSilently();

      return accessToken;
    } catch(_err) {
      throw 'Auth0 Redirect Error';
    }

  } else {

    if(isLoggedIn) {
      logoutAuth0();
    } else {
      auth0Client.loginWithRedirect({
        openUrl(url:string) {
          window.location.replace(url);
        }
      });
    }

    return undefined;
  }
};

export const getAuth0ClientSilentToken = async (clientConfigs: ClientConfigs, arkClientId: string|undefined, useRefreshTokens: boolean, isClientSwitch: boolean) => {
  const authorizationParams: AuthorizationParams = {
    redirect_uri: getRedirectUri(),
    organization: clientConfigs.authOrganization,
    scope: 'openid profile email offline_access',
    audience: '/api',
  };

  if(arkClientId) {
    authorizationParams.ark_client_id = arkClientId;

    // only run this if there is a client otherwise auth0 will generate an error
    if(isClientSwitch) {
      authorizationParams.ark_action = 'client-switch';
    }
  }


  const auth0Client = await createAuth0Client({
    domain: clientConfigs.authDomain,
    clientId: clientConfigs.authClientId,
    useRefreshTokens: useRefreshTokens,
    useRefreshTokensFallback: useRefreshTokens,
    authorizationParams: authorizationParams
  });

  const accessToken = await auth0Client.getTokenSilently();

  return accessToken;
};


export const linkWithNewAuth0Account = async (clientConfigs: ClientConfigs) => {
  const audience = 'https://arkpes-dev-portal.us.auth0.com/api/v2/';
  const scope = 'openid profile email offline_access update:users update:current_user_identities';
  const auth0Client = await createAuth0Client({
    domain: clientConfigs.authDomain,
    clientId: clientConfigs.authClientId,
    authorizationParams: {
      redirect_uri: getRedirectUri(),
      organization: clientConfigs.authOrganization,
      scope: scope,
      audience: audience,
    }
  });

  const accessToken = await auth0Client.getTokenSilently();
  // sub is the user ID
  const { sub } = await auth0Client.getUser() as User;

  try {
    const idTokenClaims = await authenticateUserWithPopup(clientConfigs, scope, audience);

    if(!idTokenClaims) {
      return false;
    }

    const { 
      __raw: targetUserIdToken,
      email_verified,
      email,
    } = idTokenClaims;

    await fetch(`https://${clientConfigs.authDomain}/api/v2/users/${sub}/identities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        link_with: targetUserIdToken,
      }),
    });    

    return true;
  } catch {
    return false;
  }

};

async function authenticateUserWithPopup(clientConfigs: ClientConfigs, scope: string, audience: string) {
  const popupLoginOptions: PopupLoginOptions = {
    authorizationParams: {
      max_age: 0,
      organization: clientConfigs.authOrganization,
      scope: scope,
      audience: audience,
    }
  };

  const width = 700, height = 700;
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;

  const popup = window.open(undefined,'auth0:authorize:popup',`left=${left},top=${top},width=${width},height=${height},resizable=0,scrollbars=0,status=0`);

  let error:any = undefined;
  let idTokenClaims: IdToken | undefined;

  try {
    const auth0PopupClient = await createAuth0Client({
      domain: clientConfigs.authDomain,
      clientId: clientConfigs.authClientId,
      authorizationParams: {
        redirect_uri: getRedirectUri(),
        organization: clientConfigs.authOrganization,
        scope: scope,
        audience: audience,
      }
    });

    await auth0PopupClient.loginWithPopup(popupLoginOptions, { popup });

    idTokenClaims = await auth0PopupClient.getIdTokenClaims();
  } catch (err:any) {
    error = err;
  }

  return idTokenClaims;
}



export const logoutAuth0 =  () => {
  const redirectUri = getRedirectUri();

  const url = `https://${process.env.REACT_APP_AUTH_ADMIN_DOMAIN!}/oidc/logout?post_logout_redirect_uri=${redirectUri}`;

  window.location.replace(url);
};