import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import useRole from "../../../../core/routing/useRole";
import { useCapitalCallDetailsEffect } from "../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks";
import { ScopeRole } from "../../../../utils/types/user.type";
import { CapitalCallDetailsTabs } from "../constants";
import {
  ButtonBox,
  DataGridContainer,
  HeaderRow,
  MarginFixContainer,
  StyledButton,
  StyledDivider,
} from "./AddTransaction.styles";
import AddTransactionsDetails from "./AddTransactionDetails";
import { TransactionOptions } from "./constants";
import EditTransactionDetails from "./EditTransactionDetails";
import { useTransactionDetails } from "./useAddTransactions.hooks";

type RouteProp = {
  id: string;
};

const AddTransactionsTab: React.FC = () => {
  const params = useParams<RouteProp>();
  const history = useHistory();
  const { capitalCall } = useCapitalCallDetailsEffect(
    params?.id !== "new" ? params?.id : undefined
  );

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const {
    transactionList,
    headerList,
    loading,
    handleUpdateHeader,
    showTransactionForm,
    handleAddTransaction,
    handleNewButtonAction,
    selectedTransaction,
    onTransactionDetailClose,
    handleOnView,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    deleteAction,
    onDeleteConfirm,
    onDeleteCancel,
    transactionSelectionModel,
    setTransactionSelectionModel,
    handleEditTransaction,
    handleDeleteTransaction,
    bulkActions,
    handleBulkAction,
    saveAndProceed,
    isAddingTransaction,
    isSuperAdminOrClientAdmin,
    isAddingNewTransactionRow,
    cancelCreateUpdateTransaction,
    setIsAddingNewTransactionRow,
    showDirtyModal,
    allowEdit,
    allowReviewerEdit,
    addAndUpdateTransaction,
    isGoBack,
    goBack,
    setIsGoBack,
  } = useTransactionDetails();

  return (
    <Box id="add_transaction_capital_call_list" marginLeft={4}>
      <Progress
        id="add_transaction_capital_calls_list_loading"
        showProgress={loading}
      />
      <HeaderRow>
        <Typography variant="h2">Add Transactions</Typography>

        <ButtonBox>
          {(capitalCall?.status?.toLowerCase() !== "published" &&
            transactionList.length > 0 &&
            isSuperAdminOrClientAdmin &&
            allowEdit) ||
          (allowReviewerEdit && transactionList.length > 0) ? (
            <SplitButton
              id={"btn_bulk_action_options"}
              options={bulkActions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          ) : (
            ""
          )}
          {(isSuperAdminOrClientAdmin &&
            capitalCall?.status?.toLowerCase() !== "published" &&
            allowEdit) ||
          allowReviewerEdit ? (
            <ButtonWithOptions
              buttonID="add_transaction_button"
              popoverID="add_transaction_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New Transaction"
              buttonIcon={<AddIcon />}
              options={TransactionOptions}
            />
          ) : (
            ""
          )}
        </ButtonBox>
      </HeaderRow>
      <DataGridContainer>
        <DataGrid
          id="transaction_data_grid"
          dataList={transactionList || []}
          getRowId={(row) => {
            return row.id;
          }}
          headerList={headerList}
          noDataMessage={loading ? "Fetching Data..." : undefined}
          minHeight="10vh"
          autoHeight={true}
          activeHeaderFields={0}
          selectionModel={transactionSelectionModel}
          setSelectionModel={setTransactionSelectionModel}
          handleUpdateHeader={handleUpdateHeader}
          handleOnView={handleOnView}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "investor",
                  sort: "asc",
                },
              ],
            },
          }}
        />
        <StyledDivider variant="fullWidth" />
        {showTransactionForm && !isAddingTransaction && (
          <AddTransactionsDetails
            handleAddTransaction={handleAddTransaction}
            setIsAddingNewTransactionRow={setIsAddingNewTransactionRow}
            isAddingNewTransactionRow={isAddingNewTransactionRow}
            showDirtyModal={showDirtyModal}
            cancelCreateUpdateTransaction={cancelCreateUpdateTransaction}
            addAndUpdateTransaction={addAndUpdateTransaction}
            isGoBack={isGoBack}
            setIsGoBack={setIsGoBack}
          />
        )}
      </DataGridContainer>

      <HorizontalBox
        addTopShadow
        attachToBottom={true}
        hidden={false}
        stickToCenter={true}
        columnGap={true}
        sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-6) }} 
      >
        <StyledButton id="btn_go_back" variant="outlined" onClick={goBack}>
          Go Back
        </StyledButton>
        <StyledButton
          id="btn_save_continue"
          variant="contained"
          onClick={saveAndProceed}
        >
          {allowEdit ? "Save and Continue" : "Continue"}
        </StyledButton>
      </HorizontalBox>
      {!!selectedTransaction?.type &&
        !["reviewing", "published"].includes(
          capitalCall?.status?.toLowerCase() || ""
        ) && (
          <EditTransactionDetails
            isFundAdmin={!isSuperAdminOrClientAdmin}
            selectedTransaction={selectedTransaction}
            onTransactionDetailClose={onTransactionDetailClose}
            handleEditTransaction={handleEditTransaction}
            handleDeleteTransaction={handleDeleteTransaction}
          />
        )}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="upload_confirmation"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "upload_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "cancel_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />

      <DeleteConfirmationDialog
        open={Boolean(deleteAction)}
        id="delete_confirmation_modal"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content="This action is permanent and cannot be undone"
      />
    </Box>
  );
};

export default React.memo(AddTransactionsTab);
