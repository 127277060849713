import { ListItemProps } from "@mui/material";
import { ReactElement } from "react";

import { StyledListItem } from "./ListItem.styles";

const ListItem: React.FC<ListItemProps> = ({ ...props }: ListItemProps): ReactElement => {
  return <StyledListItem {...props} disablePadding>{props.children}</StyledListItem>;
};

export default ListItem;
