import { AccountCircle, MoreHoriz } from "@mui/icons-material";
import { AppBar, styled, TextField, Typography } from "@mui/material";

export const NavBarContainer = styled(AppBar)<{ bgcolor?: string }>(
  ({ theme, bgcolor }) => ({
    display: "grid",
    gridTemplateColumns: "350px 1fr auto",
    justifyContent: "space-between",
    gridGap: theme.spacing(2),
    padding: theme.spacing(1),
    alignItems: "center",
    height: "80px",
    backgroundColor: bgcolor || "transparent",
  })
);

export const ClientImage = styled("img")<{ fullwidth?: string }>(
  ({ theme, fullwidth }) => ({
    maxHeight: 50,
    maxWidth: fullwidth === "true" ? 200 : 72,
    minWidth: 72,
    marginRight: theme.spacing(2),
    objectFit: "contain",
  })
);

export const StyledImage = styled("img")<{ fullwidth?: string }>(
  ({ theme, fullwidth }) => ({
    maxHeight: 68,
    maxWidth: fullwidth === "true" ? 350 : 90,
    minWidth: 72,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  })
);
export const AutocompleteTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-input": {
    minHeight: theme.spacing(6),
  },
}));

export const StyledAccountCircle = styled(AccountCircle)<{
  textcolor?: string;
}>(({ textcolor, theme }) => ({
  color: textcolor || theme.palette.text.primary,
}));

export const StyledMoreHoriz = styled(MoreHoriz)<{ textcolor?: string }>(
  ({ textcolor, theme }) => ({
    color: textcolor || theme.palette.text.primary,
  })
);

export const StyledTypography = styled(Typography)<{ textcolor?: string }>(
  ({ textcolor, theme }) => ({
    color: textcolor || theme.palette.text.primary,
  })
);
