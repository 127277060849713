import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ReactElement } from "react";

import { StyledTextField } from "./TextField.styles";

type Props = TextFieldProps & {
  id?: string;
  size?: string;
  required?: boolean;
  onChange?: any;
  onClick?: any;
  error?: boolean;
  inputProps?: any;
  type?: string;
  fullWidth?: boolean;
  hidden?: boolean;
  isShort?: boolean;
  infoHelperText?: string;
};

const TextField = ({
  id,
  required = false,
  error = false,
  type = "text",
  inputProps = undefined,
  helperText,
  infoHelperText = undefined,
  fullWidth = false,
  rows = 1,
  hidden = false,
  isShort = false,
  size = "small",
  ...props
}: Props): ReactElement => {

  return (
    <>
      {!hidden && (
        <StyledTextField
          size={size}
          type={type}
          id={id}
          required={required}
          error={error}
          helperText={error ? helperText : infoHelperText ?? ""}
          inputProps={inputProps}
          fullWidth={fullWidth}
          isfullwidth={fullWidth.toString()}
          rows={rows}
          multiline={rows > 1}
          isshort={isShort.toString()}
          {...props}
        />
      )}
    </>
  );
};

export default TextField;
