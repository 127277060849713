import {
  Box,
  ClickAwayListener,
  Collapse,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { format } from 'date-fns';
import { ReactElement, useEffect, useRef, useState } from 'react';

import {
  ButtonText,
  FilterCheckBox,
  FilterControlLabel,
  FilterOption,
} from '../../../../../../components/DataGrid/Filter/Filter.style';
import { TooltipTypography } from '../../../editPanel/EditPanel.styles';
import { InfoTooltip } from '../../../infoTooltip/InfoTooltip';
import {
  ARCHIVE_TOOLTIP_TEXT,
  ArchiveOption,
  FileArchiveOptions,
} from './ArchiveCellRenderer.constants';

type Props = {
  anchorEl: HTMLDivElement | null;
  id: string;
  fileArchivePolicy: string;
  setFileArchivePolicy: Function;
  fileArchiveOn: Date | null;
  setFileArchiveOn: Function;
  handleDateChange: Function;
  handleApply: () => void;
  handleOnClose: () => void;
};

export const ArchiveSelectionBox = (props: Props): ReactElement => {
  const {
    anchorEl,
    id,
    fileArchivePolicy,
    setFileArchivePolicy,
    fileArchiveOn,
    setFileArchiveOn,
    handleDateChange,
    handleApply,
    handleOnClose,
  } = props;

  let anchor = anchorEl;

  const localFormattedDate = format(fileArchiveOn ?? new Date(), 'P');

  return (
    <Popover
      anchorEl={anchor}
      open={!!anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: 350,
        },
      }}
    >
      <ClickAwayListener onClickAway={handleOnClose}>
        <Box padding={'15px'}>
          <Typography
            fontSize={'1.1rem'}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Select an Archive Option:
            <InfoTooltip
              id={`${id}_archive_toolTip`}
              children={
                <>
                  <TooltipTypography>
                    <strong>Respect Folder Settings: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.RESPECT_FOLDER_SETTINGS}
                  </TooltipTypography>
                  <TooltipTypography>
                    <strong>Disable Archiving: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.DISABLE_ARCHIVING}
                  </TooltipTypography>
                  <TooltipTypography>
                    <strong>Set Custom Date: </strong>
                    {ARCHIVE_TOOLTIP_TEXT.SET_CUSTOM_DATE}
                  </TooltipTypography>
                </>
              }
            />
          </Typography>
          <br />
          {FileArchiveOptions.map((option: ArchiveOption) => {
            return (
              <FilterOption key={`${option.id}_filter`}>
                <FilterControlLabel
                  control={
                    <FilterCheckBox
                      id={`${option.id}_check`}
                      checked={fileArchivePolicy === option.id}
                      onChange={() => {
                        setFileArchivePolicy(option.id);
                      }}
                    />
                  }
                  label={option.name}
                />
              </FilterOption>
            );
          })}
          {fileArchivePolicy === 'RESPECT_FILE' && (
            <Collapse
              in={fileArchivePolicy === 'RESPECT_FILE'}
              addEndListener={() => {
                anchor = anchorEl;
              }}
            >
              <StaticDatePicker
                label="Archive Document on"
                value={fileArchiveOn}
                onChange={(value: any) => {
                  handleDateChange(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} value={fileArchiveOn} />
                )}
                toolbarFormat={localFormattedDate}
              />
            </Collapse>
          )}
          <Box display="flex" justifyContent="flex-end">
            <ButtonText
              id="btn_apply"
              variant="text"
              onClick={() => {
                handleApply();
              }}
            >
              Apply
            </ButtonText>
            <ButtonText id="btn_clear" variant="text" onClick={handleOnClose}>
              Cancel
            </ButtonText>
          </Box>
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};
