import { useEffect, useState } from "react";

import { getCountryList } from "../../../../services/address.service";
import {
  getContact,
  getContactInvestmentsByPage,
} from "../../../../services/contact.service";
import {
  defaultContactInfo,
  defaultContactResource,
  defaultMailingAddress,
  defaultTaxAddress,
  investorEntityTypes as entityTypes,
} from "../../../../utils/constants/form.constants";
import {
  ADD_NEW_CONTACT,
  GENERIC_ERROR_MESSAGE,
  INVESTOR_TYPE_ENTITY,
  INVESTOR_TYPE_INDIVIDUAL,
} from "../../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import {
  ContactDetail,
  ContactInfo,
} from "../../../../utils/types/contactDetail.type";
import { ContactPermission } from "../../../../utils/types/contactPermission.type";
import { FundItem } from "../../../../utils/types/fund.type";
import { InvestorFilter } from "../../../../utils/types/investor.type";
import {
  CountryItem,
  FilterItem,
  SelectionOptionItem,
} from "../../../../utils/types/listItems";
import { formattedContactInvestmentsList } from "../../contactsHelpers/contactHelper";

type ContactDetailPanelProps = {
  informationAlert: any;
  readonly: boolean;
};

export const useContactDetailPanelEffect = ({ informationAlert }: ContactDetailPanelProps) => {
  const [countryList, setCountryList] = useState<CountryItem[]>([]);
  const [entityTypeOptions, setEntityTypeOptions] =
    useState<SelectionOptionItem[]>(entityTypes);
  const [isLoadingInvestorDetails, setLoadingInvestorDetails] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [showLeaveNoSaveConfirmation, setShowLeaveNoSaveConfirmation] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [activeTabIndex, setAciveTabIndex] = useState(0);
  const [isSubmittingContact, setSubmitingContact] = useState(false);
  const [splitActionConfirmation, setSplitButtonActionConfirmation] = useState({
    show: false,
    confirmText: "Approve",
    cancelText: "Cancel",
    title: "",
    subtitle: "",
    action: "",
  });

  const handleTabChange = (event: any, newValue: any) => {
    setAciveTabIndex(newValue);
  };

  useEffectAsync(async (isCanceled) => {
    try {
      setLoadingInvestorDetails(true);
      const countriesResponse = await getCountryList();

      if (isCanceled()) return;

      countriesResponse.some(
        (country, idx) =>
          country.id === "US" &&
          countriesResponse.unshift(countriesResponse.splice(idx, 1)[0])
      );

      setCountryList(countriesResponse);

      setLoadingInvestorDetails(false);
    } catch (e) {
      setLoadingInvestorDetails(false);
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  }, []);

  return {
    countryList,
    entityTypeOptions,
    isLoadingInvestorDetails,
    showLeaveConfirmation,
    setShowLeaveConfirmation,
    showLeaveNoSaveConfirmation,
    setShowLeaveNoSaveConfirmation,
    activeTabIndex,
    setAciveTabIndex,
    handleTabChange,
    showSaveConfirmation,
    setShowSaveConfirmation,
    isSubmittingContact,
    setSubmitingContact,
    splitActionConfirmation,
    setSplitButtonActionConfirmation,
  };
};

type ContactProps = {
  contactId: string;
  showPanel: boolean;
  informationAlert: any;
  readonly: boolean;
  investorFilters: InvestorFilter[];
  roleFilters: FilterItem[];
  fundFilters?: FundItem[];
  setPermissionsByInvestorList: Function;
}

export const useContactEffect = ({
  contactId,
  showPanel,
  informationAlert,
  readonly,
  investorFilters,
  roleFilters,
  fundFilters,
  setPermissionsByInvestorList,
}: ContactProps) => {
  const [isPanelVisible, setPanelVisible] = useState({
    right: showPanel,
    expand: false,
  });
  const [contact, setContact] = useState<ContactDetail>(defaultContactResource);
  const [contactDetail, setContactDetail] = useState<ContactPermission>();
  const [isLoadingContact, setLoadingContact] = useState(false);
  const [isLoadingPermissions, setLoadingPermissions] = useState(false);
  const [emailError, setEmailError] = useState("");

  const [title, setTitle] = useState("");

  useEffectAsync(
    async (isCanceled) => {
      try {
        if (!contactId) return;
        if (contactId === "new") {
          setContact({
            details: { ...defaultContactInfo, address: { ...defaultTaxAddress } },
            investorFundRoles: []
          });
          setTitle(ADD_NEW_CONTACT);
          return;
        }
        setLoadingContact(true);
        if (readonly) {
          if (fundFilters) {
            const contactResponse: ContactPermission = {
              ...await getContactInvestmentsByPage(
                contactId,
                fundFilters,
                investorFilters,
                roleFilters
              )
            };

            if (contactResponse.contactDetails.contactType === INVESTOR_TYPE_INDIVIDUAL) {
              setTitle(
                `${contactResponse.contactDetails.firstName} ${contactResponse.contactDetails.lastName}`
              );
            }
            else setTitle(contactResponse.contactDetails.name);

            if (!contactResponse.contactDetails.address && !contactResponse.address) {
              contactResponse.address = defaultMailingAddress;
            }

            const formattedList = formattedContactInvestmentsList(
              contactResponse,
              contactId,
              investorFilters,
              fundFilters,
            );

            setContactDetail(contactResponse);
            setPermissionsByInvestorList(formattedList);
          }

          if (isCanceled()) return;

        } else {
          const contactResponse = await getContact(contactId);

          if (isCanceled()) return;

          if (contactResponse.details.contactType === INVESTOR_TYPE_INDIVIDUAL)
            setTitle(
              `${contactResponse.details.firstName} ${contactResponse.details.lastName}`
            );
          else setTitle(contactResponse.details.name);

          if (!contactResponse.details.address) {
            contactResponse.details.address = defaultMailingAddress;
          }

          setContact(contactResponse);
        }

      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, "error");
        setPanelVisible({ ...isPanelVisible, right: false });
      } finally {
        setLoadingContact(false);
      }
    },
    [contactId]
  );

  useEffect(() => {
    setPanelVisible({ ...isPanelVisible, right: showPanel });
  }, [showPanel]);

  return {
    contact,
    setContact,
    contactDetail,
    setContactDetail,
    title,
    isLoadingContact,
    isPanelVisible,
    setPanelVisible,
    isLoadingPermissions,
    setLoadingPermissions,
    emailError,
    setEmailError,
    setLoadingContact,
  };
};

export const useContactValidator = (
  valuesContactDetail: ContactInfo,
  emailError: string
) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (
      valuesContactDetail.contactType === INVESTOR_TYPE_ENTITY &&
      valuesContactDetail.name === ""
    ) {
      setIsFormValid(false);
    } else if (
      valuesContactDetail.contactType === INVESTOR_TYPE_INDIVIDUAL &&
      (valuesContactDetail.firstName === "" ||
        valuesContactDetail.lastName === "")
    ) {
      setIsFormValid(false);
    } else if (!valuesContactDetail.email || Boolean(emailError)) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [valuesContactDetail, emailError]);

  return {
    isFormValid,
  };
};
