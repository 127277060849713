import { ConstructionOutlined } from '@mui/icons-material';
import { parse } from 'date-fns';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../core/context/appContextProvider';
import RoutingPaths from '../../../core/routing/routingPaths';
import useRole from '../../../core/routing/useRole';
import {
  getCountryList,
  getStateList,
} from '../../../services/address.service';
import {
  autoCompleteAddress,
  getDetailsByIdPlace,
  getDetailsByZipCode,
} from '../../../services/autocomplete.service';
import {
  getFundDetails,
  getFundNameAvailability,
  postNewFund,
  updateFundDetails,
} from '../../../services/fund.service';
import {
  getFundCurrencyList,
  getFundEntityTypeList,
  getFundLegalTypeList,
  getFundTypeList,
} from '../../../services/fund.service';
import { getClientTeams } from '../../../services/teams.service.v2';
import {
  addressSchema,
  bankInfoSchema,
  defaultFundDetails,
  defaultMailingAddress,
  defaultReceivingBankInfo,
  defaultWireInfo,
  fundDetailSchema,
  wireInfoSchema,
} from '../../../utils/constants/form.constants';
import {
  ERROR_REQUIRED_FIELD,
  ERROR_TAKEN_FUND_NAME,
  GENERIC_CHANGED_INFO_SUCCESS_MESSAGE,
  GENERIC_ERROR_MESSAGE,
} from '../../../utils/constants/text.constants';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { useForm } from '../../../utils/hooks/useForm.hook';
import { MailingAddress, Team, WireInfo } from '../../../utils/types/fund.type';
import { FundDetails as FundDetailsType } from '../../../utils/types/fund.type';
import { ListItem, SelectionOptionItem } from '../../../utils/types/listItems';
import { TeamInfo } from '../../../utils/types/team.type';
import { ScopeRole } from '../../../utils/types/user.type';

export const useFormTypesEffect = () => {
  const { informationAlert } = useContext(AppContext);

  const [countryList, setCountryList] = useState<ListItem[]>([]);
  const [currencyList, setCurrencyList] = useState<ListItem[]>([]);
  const [typeList, setTypeList] = useState<ListItem[]>([]);
  const [legalTypeList, setLegalTypeList] = useState<ListItem[]>([]);
  const [entityTypeList, setEntityTypeList] = useState<ListItem[]>([]);
  const [isLoadingTypes, setLoadingTypes] = useState<boolean>(false);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoadingTypes(true);
      const countriesResponse = await getCountryList();

      if (isCanceled()) return;
      const typeListResponse = await getFundTypeList();

      if (isCanceled()) return;
      const legalTypeListResponse = await getFundLegalTypeList();

      if (isCanceled()) return;
      const entityTypeListResponse = await getFundEntityTypeList();

      if (isCanceled()) return;
      const currencyListResponse = await getFundCurrencyList();

      if (isCanceled()) return;

      setCountryList(countriesResponse);
      setCurrencyList(currencyListResponse);
      setEntityTypeList(entityTypeListResponse);
      setLegalTypeList(legalTypeListResponse);
      setTypeList(typeListResponse);
      setLoadingTypes(false);
    } catch (e) {
      setLoadingTypes(false);
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    }
  }, []);

  return {
    isLoadingTypes,
    countryList,
    currencyList,
    entityTypeList,
    typeList,
    legalTypeList,
  };
};

export const useFundDetailsEffect = (fundId: string) => {
  const { state, informationAlert } = useContext(AppContext);

  const clientId = state.loginUser.clientId;

  const history = useHistory();

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;

  const [fund, setFund] = useState<any>(defaultFundDetails);
  const [fundName, setFundName] = useState<string>('');
  const [fundNameError, setFundNameError] = useState<string>('');
  const [fundCurrencyError, setFundCurrencyError] = useState<string>('');
  const [entityTypeError, setEntityTypeError] = useState<string>('');
  const [fundTypeError, setFundTypeError] = useState<string>('');
  const [legalTypeError, setLegalTypeError] = useState<string>('');
  const [fundInceptionDate, setFundInceptionDate] = useState<string | null>(
    null
  );
  const [fundInceptionDateError, setFundInceptionDateError] =
    useState<string>('');
  const [fundTeams, setFundTeams] = useState<Team[]>([]);
  const [availableFundTeams, setAvailableFundTeams] = useState<ListItem[]>([]);
  const [activeFundTeams, setActiveFundTeams] = useState<ListItem[]>([]);
  const [fundStatus, setFundStatus] = useState<boolean>(false);
  const [isSendingData, setSendingData] = useState<boolean>(false);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  useEffectAsync(async (isCanceled) => {
    try {
      if (isCanceled()) return;
      let teamListResponse: any = {};

      if (clientId) {
        teamListResponse = await getClientTeams(clientId);
      }

      let teamsList =
        teamListResponse?.items?.map((team: TeamInfo) => ({
          id: team?.id || '',
          label: team?.name || '',
        })) || [];

      if (fundId === 'new') {
        setFundStatus(true);
      } else {
        setLoadingData(true);
        const fundDetailsResponse = await getFundDetails(fundId);

        if (isCanceled()) return;
        setLoadingData(false);

        setFund(fundDetailsResponse);
        const date = parse(
          fundDetailsResponse.fundDetail.inceptionDate!,
          'yyyy-MM-dd',
          new Date()
        );

        const existingTeamIDs = new Set();

        fundDetailsResponse.teams.forEach((team) =>
          existingTeamIDs.add(team.id)
        );

        setFundInceptionDate(DateTimeFormat.shortDate(date));
        setFundName(fundDetailsResponse.fundDetail.name);
        setFundStatus(fundDetailsResponse.fundDetail.active);
        setFundTeams(fundDetailsResponse.teams);

        teamsList = teamsList.filter(
          (item: any) => !existingTeamIDs.has(item.id)
        );
      }

      setAvailableFundTeams(teamsList);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    }
  }, []);

  useEffect(() => {
    const teams = fundTeams.map((team) => {
      return { id: team.id, label: team.name };
    });

    setActiveFundTeams(teams);
  }, [fundTeams]);

  const {
    useInput: useFundDetailsInput,
    useInputSelect: useFundDetailsInputSelect,
    values: valuesFundDetail,
  } = useForm(fund.fundDetail, fundDetailSchema, false);

  const {
    useInput: useAddressInput,
    useInputSelect: useAddressInputSelect,
    values: addressValues,
  } = useForm(fund.fundDetail.mailingAddress!, addressSchema, false);

  const { useInput: useWireInfoInput, values: wireInfoValues } = useForm(
    fund.wireInfo!,
    wireInfoSchema,
    false
  );

  const { useInput: useReceivingBankInfoInput, values: receivingValues } =
    useForm(fund.wireInfo!.receivingBankInfo!, bankInfoSchema, false);

  const { useInput: useBeneficiaryInfoInput, values: beneficiaryValues } =
    useForm(fund.wireInfo!.beneficiaryBankInfo!, bankInfoSchema, false);

  const { useInput: useIntermediaryBankInfoInput, values: intermediaryValues } =
    useForm(fund.wireInfo!.intermediaryBankInfo!, bankInfoSchema, false);

  const createSavingFundObject = () => {
    const updatedFund: FundDetailsType = { ...fund };

    updatedFund.fundDetail = { ...valuesFundDetail };
    updatedFund.fundDetail.name = fundName;
    updatedFund.fundDetail.active = fundStatus;
    updatedFund.fundDetail.inceptionDate = DateTimeFormat.isoDateString(
      new Date(fundInceptionDate!)
    );
    updatedFund.fundDetail.mailingAddress = addressValues;

    updatedFund.teams = activeFundTeams.map((team) => {
      return {
        id: team.id,
        name: team.label,
      };
    });

    updatedFund.wireInfo = wireInfoValues;
    updatedFund.wireInfo.beneficiaryBankInfo = beneficiaryValues;
    updatedFund.wireInfo.receivingBankInfo = receivingValues;
    return updatedFund;
  };

  const handleFundNameValidation = async (value: string) => {
    setFundNameError('');
    if (value && (fundId === 'new' || fundName !== fund.fundDetail.name)) {
      try {
        const fundNameIsAvailable = await getFundNameAvailability(value);

        if (!fundNameIsAvailable) setFundNameError(ERROR_TAKEN_FUND_NAME);
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, 'error');
      }
    }
  };

  const handleFundInceptionDateChange = (value: any) => {
    setFundInceptionDate(value as string);
    setFundInceptionDateError('');
  };

  const handleOnSave = async () => {
    if (
      !fundName ||
      !fundInceptionDate ||
      !valuesFundDetail.currency ||
      !valuesFundDetail.entityType ||
      !valuesFundDetail.legalType ||
      !valuesFundDetail.type
    ) {
      !fundName && setFundNameError(ERROR_REQUIRED_FIELD);
      !fundInceptionDate && setFundInceptionDateError(ERROR_REQUIRED_FIELD);
      !valuesFundDetail.currency && setFundCurrencyError(ERROR_REQUIRED_FIELD);
      !valuesFundDetail.entityType && setEntityTypeError(ERROR_REQUIRED_FIELD);
      !valuesFundDetail.legalType && setLegalTypeError(ERROR_REQUIRED_FIELD);
      !valuesFundDetail.type && setFundTypeError(ERROR_REQUIRED_FIELD);
      return;
    }

    const fundToSave = createSavingFundObject();

    if (fundId === 'new') {
      setSendingData(true);
      try {
        fundToSave.fundDetail.clientId = clientId;
        // TODO: update after api refactor
        fundToSave.wireInfo.isForeign = fundToSave.wireInfo.foreign;
        fundToSave.fundDetail.fee = Number(fundToSave.fundDetail.fee);
        fundToSave.fundDetail.type === ''
          ? (fundToSave.fundDetail.type = null)
          : fundToSave.fundDetail.type;
        fundToSave.fundDetail.entityType === ''
          ? (fundToSave.fundDetail.entityType = null)
          : fundToSave.fundDetail.entityType;
        fundToSave.fundDetail.legalType === ''
          ? (fundToSave.fundDetail.legalType = null)
          : fundToSave.fundDetail.legalType;

        await postNewFund(fundToSave);

        informationAlert(GENERIC_CHANGED_INFO_SUCCESS_MESSAGE, 'success');
        resetValues();
        history.push('/funds');
      } catch (exception) {
        informationAlert(GENERIC_ERROR_MESSAGE, 'error');
      } finally {
        setSendingData(false);
      }
    } else {
      // update existing fund details
      setSendingData(true);
      try {
        await updateFundDetails(fundId, fundToSave);
        informationAlert(GENERIC_CHANGED_INFO_SUCCESS_MESSAGE, 'success');
      } catch (exception) {
        informationAlert(GENERIC_ERROR_MESSAGE, 'error');
      } finally {
        setSendingData(false);
      }
    }
  };

  const handleOnCancel = () => {
    resetValues();
    history.push(RoutingPaths.FundList);
  };

  const resetValues = () => {
    addressValues.street1 = '';
    addressValues.postalCode = '';
  };

  const handleTeamSelect = (event: any) => {
    const updatedAvailableFundTeams: ListItem[] = [];
    const addedFund: ListItem[] = [...activeFundTeams];
    const selected = event.target.value;

    availableFundTeams.forEach((team) => {
      if (team.id === selected) {
        addedFund.push(team);
      } else {
        updatedAvailableFundTeams.push(team);
      }
    });

    setActiveFundTeams(addedFund);
    setAvailableFundTeams(updatedAvailableFundTeams);
  };

  const handleRemoveTeam = (id: string) => {
    const updatedFundTeams: ListItem[] = [];
    const availableTeams: ListItem[] = [...availableFundTeams];

    activeFundTeams.forEach((team) => {
      if (team.id !== id) {
        updatedFundTeams.push(team);
      } else {
        availableTeams.push(team);
      }
    });
    setActiveFundTeams(updatedFundTeams);
    setAvailableFundTeams(availableTeams);
  };

  return {
    fund,
    fundId,
    fundCurrencyError,
    fundInceptionDate,
    fundInceptionDateError,
    fundName,
    fundNameError,
    fundStatus,
    fundTeams,
    availableFundTeams,
    activeFundTeams,
    isSendingData,
    isLoadingData,
    readonly,
    handleFundNameValidation,
    handleFundInceptionDateChange,
    handleOnCancel,
    handleOnSave,
    handleRemoveTeam,
    handleTeamSelect,
    setFundName,
    setFundStatus,
    setFundInceptionDate,
    setFundInceptionDateError,
    useFundDetailsInput,
    useFundDetailsInputSelect,
    valuesFundDetail,
    useAddressInput,
    useAddressInputSelect,
    addressValues,
    useWireInfoInput,
    wireInfoValues,
    useReceivingBankInfoInput,
    receivingValues,
    useBeneficiaryInfoInput,
    beneficiaryValues,
    useIntermediaryBankInfoInput,
    intermediaryValues,
    entityTypeError,
    legalTypeError,
    fundTypeError,
  };
};

export const useTabs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const detailsRef = useRef<HTMLInputElement>(null);
  const wiringRef = useRef<HTMLInputElement>(null);
  const teamRef = useRef<HTMLInputElement>(null);
  const pageBottomRef = useRef<HTMLInputElement>(null);

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 1:
        wiringRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 2:
        teamRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  };

  return {
    currentTab,
    detailsRef,
    wiringRef,
    teamRef,
    pageBottomRef,
    handleTabChange,
  };
};
export const useAddressEffect = (
  addressValues: MailingAddress,
  fundId: string
) => {
  const { informationAlert } = useContext(AppContext);
  const [selectedCountry, setSelectedCountry] = useState(addressValues.country);
  const [selectedState, setSelectedState] = useState(addressValues.state);
  const [addressLine1, setAddressLine1] = useState(addressValues.street1);
  const [city, setCity] = useState(addressValues.city);
  const [stateList, setStateList] = useState<ListItem[]>([]);
  const [zipCode, setZipCode] = useState(addressValues.postalCode);

  if (addressValues.country === 'USA') {
    addressValues.country = 'US';
  }

  useEffectAsync(
    async (isCanceled) => {
      try {
        setSelectedCountry(addressValues.country);
        setAddressLine1(addressValues.street1);
        setCity(addressValues.city);
        setZipCode(addressValues.postalCode);
        setSelectedState(addressValues.state);
        if (selectedCountry) {
          const statesResponse = await getStateList(selectedCountry);

          if (isCanceled()) return;

          setStateList(statesResponse);
        }
      } catch (e) {
        informationAlert(GENERIC_ERROR_MESSAGE, 'error');
      }
    },
    [selectedCountry, selectedState, addressValues]
  );

  useEffect(() => {
    if (fundId === 'new') {
      resetAddressFields();
    }
  }, []);

  const [showMessageNoMatches, setShowMessageNoMatches] =
    useState<boolean>(false);
  const [predictions, setPredictions] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isPopoverOpen = Boolean(anchorEl);

  const handleCloseArrowPopover = () => {
    setAnchorEl(null);
  };

  const handleOnAddressChange = async ({ target, currentTarget }: any) => {
    const { value } = target;

    if (value) {
      setAnchorEl(currentTarget);
    } else {
      setAnchorEl(null);
    }

    setAddressLine1(value);
    const result: any[] = await autoCompleteAddress(value);

    if (result) {
      const found = result.filter(
        (item) => item.description.toLowerCase() === value.toLowerCase()
      );

      if (found.length === 0) {
        setShowMessageNoMatches(true);
      } else {
        setShowMessageNoMatches(false);
      }
      setPredictions(result);
    } else {
      setAnchorEl(null);
    }
  };

  const handleSelectClick = async (placeId: string, routeName: string) => {
    const resp = await getDetailsByIdPlace(placeId);

    if (resp) {
      addressValues.street1 = routeName;
      addressValues.country = resp.country;
      setSelectedCountry(resp.country);
      setAddressLine1(routeName);
      addressValues.state = resp.state;
      setSelectedState(resp.state);
      addressValues.postalCode = resp.zipCode;
      setZipCode(resp.zipCode);
      addressValues.city = resp.city;
      setCity(resp.city);
    }

    setAnchorEl(null);
  };

  const handleStateChange = (event: any) => {
    addressValues.state = event.target.value;
    setSelectedCountry(event.target.value);
  };

  const handleCityChange = (event: any) => {
    addressValues.city = event.target.value;
    setCity(event.target.value);
  };

  const handlePostalCodeChange = async (event: any) => {
    addressValues.postalCode = event.target.value;
    setZipCode(event.target.value);
    if (event.target.value.length >= 5) {
      addressValues.postalCode = event.target.value;

      const placeDetail = await getDetailsByZipCode(event.target.value);

      if (placeDetail.country || placeDetail.city) {
        addressValues.country = placeDetail.country;
        setSelectedCountry(placeDetail.country);
        addressValues.state = placeDetail.state;
        setSelectedState(placeDetail.state);
        addressValues.city = placeDetail.city;
        setCity(placeDetail.city);
      } else {
        resetAddressFields();
      }
    } else {
      resetAddressFields();
    }
  };

  const resetAddressFields = () => {
    addressValues.country = '';
    setSelectedCountry('');
    addressValues.state = '';
    setSelectedState('');
    addressValues.city = '';
    setCity('');
    addressValues.postalCode = '';
    setZipCode('');
    addressValues.street1 = '';
    setAddressLine1('');
  };

  const handleCountryChange = (event: any) => {
    addressValues.country = event.target.value;
    setSelectedCountry(event.target.value);
  };

  return {
    stateList,
    selectedCountry,
    selectedState,
    addressLine1,
    city,
    zipCode,
    showMessageNoMatches,
    predictions,
    anchorEl,
    isPopoverOpen,
    handleCountryChange,
    handleCloseArrowPopover,
    handleOnAddressChange,
    handleSelectClick,
    handleStateChange,
    handleCityChange,
    handlePostalCodeChange,
  };
};

export const useWireEffect = (
  fundWireInfo: WireInfo,
  pageBottomRef: RefObject<HTMLInputElement>,
  fundId: string
) => {
  const [wireInfo, setWireInfo] = useState<WireInfo>(defaultWireInfo);
  const [isIntermediaryBankInfoHidden, setIntermediaryBankInfoHidden] =
    useState(true);
  const [canScroll, setCanScroll] = useState(false);

  useEffect(() => {
    if (!fundWireInfo) return;
    if (
      !fundWireInfo.receivingBankInfo ||
      fundWireInfo.receivingBankInfo === undefined
    )
      fundWireInfo.receivingBankInfo = defaultReceivingBankInfo;

    setWireInfo(fundWireInfo);

    if (
      fundWireInfo.intermediaryBankInfo &&
      fundWireInfo.intermediaryBankInfo.abaNumber
    ) {
      setIntermediaryBankInfoHidden(false);
    }
  }, [fundWireInfo]);

  useEffect(() => {
    if (canScroll) scrollToBottom();
  }, [isIntermediaryBankInfoHidden]);

  useEffect(() => {
    if (fundId === 'new') {
      resetBankFields();
    }
  }, []);

  const resetBankFields = () => {
    if (fundWireInfo && fundWireInfo.receivingBankInfo) {
      fundWireInfo.receivingBankInfo.abaNumber = '';
      fundWireInfo.receivingBankInfo.name = '';
    }
  };

  const scrollToBottom = () => {
    pageBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleIntermediaryBankCheck = (event: any) => {
    setCanScroll(true);
    if (fundWireInfo.intermediaryBankInfo) {
      fundWireInfo.intermediaryBankInfo.abaNumber = '';
      fundWireInfo.intermediaryBankInfo.addressLine1 = '';
      fundWireInfo.intermediaryBankInfo.addressLine2 = '';
    }

    setIntermediaryBankInfoHidden(!event.target.checked);
  };

  return {
    wireInfo,
    isIntermediaryBankInfoHidden,
    handleIntermediaryBankCheck,
  };
};
