import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormState } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import AddIcon from "../../../assets/images/icons/icon_add_blue.svg";
import { ButtonOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { AppContext } from '../../../core/context/appContextProvider';
import { useReportEffect } from '../../../services/hooks/useReportEffect/useReportEffect.hooks';
import {
  addReportConfig,
  deleteReportConfig,
  getReportConfigData,
  getReportTemplete,
  updateReportConfig
} from '../../../services/reports.service';
import { ImageItem } from '../../../utils/types/listItems';
import { ReportTemplete } from '../../../utils/types/reports.type';
import { ClientTabs } from '../constants';

interface RouteProp {
  id: string;
  arkTag: string;
  section: string;
}

export interface CommonType {
  id: number | null;
  index: number;
  label: string;
  type: string;
  transactionTypes: string[];
  numberOfTabs?: number;
  rowsToSum?: string[];
  bucket: string;
  dateRange: string;
  styles: string[];
  suppressIfZero: boolean;
  includeRemainder?: boolean
}

export interface ReportColumns {
  index: number,
  label: string,
  reportColumnType: string,
}

export interface GeneralReportForm {
  id?: string | null;
  tenantId?: string;
  headerAlignment: string;
  name: string;
  roundingStrategy: string;
  useInvestorPrefix: boolean;
  useWatermark: boolean,
  funds: string[],
  headerElements: HeaderTypeForm[],
  summaryElements: CommonType[],
  reportElements: CommonType[],
  footer: string,
  reportColumns: ReportColumns[]
}

export interface HeaderTypeForm {
  index: number;
  type: string;
  alignment: string;
  label?: string | null;
  datePattern?: string | null;
  thickness?: number | null;
  color?: string | null;
}

export const ActionOption: ImageItem[] =[
  {
    id: '',
    text : 'Add Row',
    icon : <img src={AddIcon} alt="addimage"/>,
  },
  {
    id: 'TRANSACTION',
    text : 'Transaction Row',
    
  },
  {
    id: 'LABEL',
    text:'Text Box',
    optionsSelected: 0,
  },
  {
    id:'BEGINNING',
    text:'Standard Beginning',
    optionsSelected: 0,
  },
  {
    id: 'BEGINNING_CUSTOM',
    text: `Custom Beginning`,
    optionsSelected: 0,
  },
  {
    id:'SUBTOTAL',
    text:'Subtotal',
    optionsSelected:0,
  },
  {
    id:'ENDING',
    text:'Ending',
    optionsSelected:0,
  },
  {
    id:'SPACER',
    text:'Spacer',
    optionsSelected:0
  }
];

export const TemplateOptions: ButtonOptions[] = [
  {
    label: 'Blank Template',
    id: 'create_new_template'
  },
  {
    label: 'Create Copy',
    id: 'copy_template'
  }
];

export const useReportConfig = () => {
  const {
    id,
    arkTag
  } = useParams<RouteProp>();

  const {
    informationAlert
  } = useContext(AppContext);

  const history = useHistory();

  const [ loading, setLoading ] = useState(false);
  const [ templeId, setTempleId ] = useState<string>('');
  const [ reportTempleteList, setReportTempleteList ] = useState<ReportTemplete[]>([]);
  const [ deleteAction, setDeleteAction] = useState<boolean>(false);

  const reportForm = useForm<GeneralReportForm>({
    defaultValues: {
      summaryElements: [],
      headerElements: [
        {
          index: 0,
          type : 'LOGO',
          alignment: "CENTER"
        },
        {
          index: 1,
          type:"FUND_NAME",
          alignment: "CENTER"
        },
        {
          index: 2,
          type: "INVESTOR_NAME",
          alignment: "CENTER"
        },
        {
          index: 3,
          type: "REPORT_NAME",
          alignment: "CENTER"
        },
        {
          index: 4,
          type: "HORIZONTAL_LINE",
          alignment: "CENTER"
        }
      ],
      reportElements: [],
      useInvestorPrefix: false,
      useWatermark: false,
      name: "",
      id: null,
      tenantId: '',
      headerAlignment: 'CENTER',
      roundingStrategy: 'DOLLAR',
      funds: [],
      footer: '',
      reportColumns: [
        {
          index:0,
          label:'Quarter To Date',
          reportColumnType:'QUARTER_TO_DATE',
        },
        {
          index:1,
          label:'Year To Date',
          reportColumnType:'YEAR_TO_DATE',
        },
        {
          index:2,
          label:'Inception To Date',
          reportColumnType:'INCEPTION_TO_DATE',
        },
      ]
    }
  });

  // Header Form array
  const headerElementControls = useFieldArray({
    control:reportForm.control,
    name:'headerElements'
  });

  // Summary Form array
  const summaryElementsControls = useFieldArray({
    control: reportForm.control,
    name: 'summaryElements'
  });

  // Report Form array
  const reportElementsControls = useFieldArray({
    control: reportForm.control,
    name: 'reportElements'
  });

  const {
    isDirty,
  } = useFormState({
    control: reportForm.control
  });

  const {
    fundNameList,
    transactionBuckets,
    commonApiLoading
  } = useReportEffect(id);

  useEffect(() => {
    fetchReportTemplate();
  }, [id]);

  const fetchReportTemplate = async () => {
    setLoading(true);
    try {
      const list = await getReportTemplete(id);
      
      setReportTempleteList(list);
    } catch(err) {
      informationAlert("Error in Report Template.", "error");
    } finally {
      setLoading(false);
    }
  };

  const addHeaderElement = (type: string) => {
    const index = (reportForm.getValues().headerElements.length + 1);

    switch(type) {
      case 'HORIZONTAL_LINE':
        headerElementControls.append({
          index,
          type : type,
          thickness: 1,
          color: null
        });
        break;
      case 'AS_OF_DATE':
        headerElementControls.append({
          index,
          type : type,
          label: null,
          alignment: "CENTER",
          datePattern: null
        });
        break;
      default:
        headerElementControls.append({
          index,
          type : type,
          alignment: "CENTER"
        });
        break;
    }
  };

  const removeHeaderElement = (id: number) => {
    headerElementControls.remove(id);
  };

  const addReportElement = (type: string) => {
    const index = (reportForm.getValues()?.reportElements.length + 1);

    switch(type) {
      case 'SPACER':
        reportElementsControls.append({
          id: null,
          index: index,
          type: type,
          label: 'Spacer'
        });
        break;
      case 'SUBTOTAL':
        reportElementsControls.append({
          id: null,
          index: index,
          type: type,
          label: "",
          transactionTypes: [],
          rowsToSum: [],
          numberOfTabs: 0,
          bucket: "",
          dateRange: "ITD",
          styles: [],
          suppressIfZero: true,
          includeRemainder:false
        });
        break;
      case 'LABEL':
        reportElementsControls.append({
          id: null,
          index: index,
          type: type,
          label: "",
          numberOfTabs: 0,
          styles: []
        });
        break;
      default:
        reportElementsControls.append({
          id: null,
          index: index,
          type: type,
          label: "",
          transactionTypes: [],
          numberOfTabs: 0,
          bucket: "",
          dateRange: "ITD",
          styles: [],
          suppressIfZero: true,
          includeRemainder:false
        });
        break;
    }
  };

  const removeReportElement = (id: number) => {
    reportElementsControls.remove(id);
  };

  const handleOnSave = async (data: GeneralReportForm) => {
    if(
      isDirty || reportForm.getFieldState('summaryElements').isDirty || 
      reportForm.getFieldState('reportElements').isDirty || reportForm.getFieldState('headerElements').isDirty
    ) {
      setLoading(true);
      try {
        if(data.id !== null) {
          await updateReportConfig(id, templeId, data);
          informationAlert("Report Config Updated", "success");
        } else {
          await addReportConfig(id, templeId, data);
          informationAlert("Report Config Added", "success");
        }
        history.push(`/clients/${id}/${arkTag}/${ClientTabs.ConsentConfig}`);
      } catch(error) {
        informationAlert("Error in Report Config.", "error");
      } finally {
        setLoading(false);
      }
    } else {
      history.push(`/clients/${id}/${arkTag}/${ClientTabs.ConsentConfig}`);
    }
  };

  const handleOnClose = () => {
    history.push(`/clients/${id}/${arkTag}/${ClientTabs.TransactionMapping}`);
  };

  const addSummary = (type: string) => {
    const index = (reportForm.getValues().summaryElements.length + 1);

    if(type === 'SPACER') {
      summaryElementsControls.append({
        id: null,
        index: index,
        type: type,
        label: 'Spacer'
      });
    } else {
      summaryElementsControls.append({
        id: null,
        index: index,
        label: "",
        type: type,
        transactionTypes: [],
        numberOfTabs: 0,
        bucket: "",
        dateRange: "ITD",
        styles: [],
        suppressIfZero: true
      });
    }
  };

  const removeSummary = (id: number) => {
    summaryElementsControls.remove(id);
  };

  const getReportConfig = async (templeId: string) => {
    if(templeId.length > 0) {
      clearFormData();
      setLoading(true);
      setTempleId(templeId);
      try {
        const response = await getReportConfigData(id, templeId);
        
        if(response) {
          reportForm.reset(response);
        }
      } catch(err) {
        informationAlert("Error getting Report Config.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteReportTemplate = async () => {
    setLoading(true);
    try {
      await deleteReportConfig(id, templeId);
      
      clearFormData();
      
    } catch(err) {
      informationAlert("Error deleting Report Template.", "error");
    } finally {
      setDeleteAction(false);
      setLoading(false);
      fetchReportTemplate();
    }
  };

  const clearFormData = () => {
    reportForm.reset({
      summaryElements: [],
      headerElements: [
        {
          index: 0,
          type : 'LOGO',
          alignment: "CENTER"
        },
        {
          index: 1,
          type:"FUND_NAME",
          alignment: "CENTER"
        },
        {
          index: 2,
          type: "INVESTOR_NAME",
          alignment: "CENTER"
        },
        {
          index: 3,
          type: "REPORT_NAME",
          alignment: "CENTER"
        },
        {
          index: 4,
          type: "HORIZONTAL_LINE",
          alignment: "CENTER"
        }
      ],
      reportElements: [],
      useInvestorPrefix: false,
      useWatermark: false,
      name: "",
      id: null,
      tenantId: '',
      headerAlignment: 'CENTER',
      roundingStrategy: 'DOLLAR',
      funds: [],
      footer: '',
      reportColumns: [
        {
          index:0,
          label:'QUARTER_TO_DATE',
          reportColumnType:'QUARTER_TO_DATE',
        },
        {
          index:1,
          label:'YEAR_TO_DATE',
          reportColumnType:'YEAR_TO_DATE',
        },
        {
          index:2,
          label:'INCEPTION_TO_DATE',
          reportColumnType:'INCEPTION_TO_DATE',
        },
      ]
    });
    setTempleId('');
  };

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch(actionId) {
      case TemplateOptions[0].id:
        clearFormData();
        break;
      case TemplateOptions[1].id:
        if(templeId) {
          reportForm.setValue(`id`, null);
          setTempleId('');
        }
        break;
    }
  };

  return {
    templeId,
    TemplateOptions,
    handleNewButtonAction,
    deleteAction,
    setDeleteAction,
    clearFormData,
    getReportConfig,
    deleteReportTemplate,
    reportForm,
    headerElementControls,
    summaryElementsControls,
    reportElementsControls,
    addHeaderElement,
    removeHeaderElement,
    addSummary,
    removeSummary,
    addReportElement,
    removeReportElement,
    loading: loading || commonApiLoading,
    fundNameList,
    reportTempleteList,
    transactionBuckets,
    handleOnSave,
    handleOnClose,
    isDirty,
  };
};
