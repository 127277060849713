export const GET_ALL_EMAILS = "Error retrieving emails for contacts.";
export const RECIPIENTS_ERROR = "Error loading recipient lists.";
export const INVALID_CC =
  "cc Recipients are invalid, please confirm all emails are correct.";
export const INVALID_BC =
  "bcc Recipients are invalid, please confirm all emails are correct.";
export const CONTACT_RECIPIENT_CHECK =
  "At least one Fund, Investor and Role must be selected.";
export const DRAFT_UPDATE_SUCCESS = "Draft mailing updated successfully.";
export const DRAFT_CREATE_SUCCESS = "Draft mailing created successfully.";
export const DRAFT_ERROR =
  "Error occured while creating/updating draft mailing.";
export const SUBJECT_ERROR =
  "A subject is required for successfully saving a draft.";
export const IMAGE_SIZE_ERROR =
  "Image size too large. Max total for attachments is 6MB.";

export const recipientInputValidation: RegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export enum ViewType {
  Contacts = "contacts",
  Documents = "documents",
  CashReceipts = "cash_receipts",
}
