import { Dispatch, SetStateAction, useContext, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import { getColumnOrder } from '../../../services/columnOrder.service';
import { getInvestmentTransactions } from '../../../services/investmentTransaction.service';
import {
  getPortfolioCompanies,
  getSoiTransactionSummaryV2,
} from '../../../services/portfolioCompanies.service';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import { InvestmentTransaction } from '../../../utils/types/investmentTransaction.type';
import { PortfolioCompany } from '../../../utils/types/portfolioCompany.type';
import { ViewTypes } from './ScheduleOfInvestments.hooks';

export enum SOI_VIEW_CONFIG_KEY {
  UnrealizedColumnKey = 'SOI_FIELD_UNREALIZED_SOI_VIEW',
  UnrealizedCatrgorynKey = 'SOI_CATEGORY_UNREALIZED_SOI_VIEW',
  RealizedColumnKey = 'SOI_FIELD_REALIZED_SOI_VIEW',
  RealizedCategoryKey = 'SOI_CATEGORY_REALIZED_SOI_VIEW',
}

export const useScheduleOfInvestmentsHelper = (
  clientId: string,
  fundId: string,
  dateFilter: Date | null | undefined,
  setIsLoadingList: Dispatch<SetStateAction<boolean>>
) => {
  const { informationAlert } = useContext(AppContext);

  const [portfolioCompaniesResponse, setPortfolioCompaniesResponse] =
    useState<PortfolioCompany[]>();
  const [investmentTransactionsResponse, setInvestmentTransactionsResponse] =
    useState<InvestmentTransaction[]>();
  const [unrealizedSoiSummaryResponse, setUnrealizedSoiSummaryResponse] =
    useState<any>();
  const [realizedSoiSummaryResponse, setRealizedSoiSummaryResponse] =
    useState<any>();

  const [unrealizedColumnOrder, setUnrealizedColumnOrder] =
    useState<ColumnOrder>();
  const [unrealizedCatrgoryOrder, setUnrealizedCategoryOrder] =
    useState<ColumnOrder>();
  const [realizedColumnOrder, setRealizedColumnOrder] = useState<ColumnOrder>();
  const [realizedCategoryOrder, setRealizedCategoryOrder] =
    useState<ColumnOrder>();

  const fetchPortfolioCompanies = async (isCanceled?: () => boolean) => {
    try {
      const portfolioCompaniesResponse = await getPortfolioCompanies();

      if (isCanceled?.()) return;

      setPortfolioCompaniesResponse(portfolioCompaniesResponse.items);
    } catch (e) {
      informationAlert('Error getting portfolio companies list', 'error');
    }
  };

  const fetchInvestmentTransactioinsList = async (
    isCanceled?: () => boolean
  ) => {
    try {
      const investmentTransactionsResponse = (await getInvestmentTransactions())
        .items;

      if (isCanceled?.()) return;

      setInvestmentTransactionsResponse(investmentTransactionsResponse);
    } catch (e) {
      informationAlert('Error getting investment transactions list', 'error');
    }
  };

  const fetchUnrealizedSoiTransactionSummaryV2 = async (
    isCanceled?: () => boolean
  ) => {
    try {
      let unrealizedTransactionSummaryResponse =
        await getSoiTransactionSummaryV2(
          dateFilter || new Date(),
          ViewTypes.Unrealized
        );

      if (isCanceled?.()) return;

      if (fundId) {
        unrealizedTransactionSummaryResponse =
          unrealizedTransactionSummaryResponse.filter(
            (item: any) => item.fundId === fundId
          );
      }

      setUnrealizedSoiSummaryResponse(unrealizedTransactionSummaryResponse);
    } catch (e) {
      informationAlert('Error getting unrealized SOI data', 'error');
    }
  };

  const fetchRealizedSoiTransactionSummaryV2 = async (
    isCanceled?: () => boolean
  ) => {
    try {
      let realizedTransactionSummaryResponse = await getSoiTransactionSummaryV2(
        dateFilter || new Date(),
        ViewTypes.Realized
      );

      if (isCanceled?.()) return;

      if (fundId) {
        realizedTransactionSummaryResponse =
          realizedTransactionSummaryResponse.filter(
            (item: any) => item.fundId === fundId
          );
      }

      setRealizedSoiSummaryResponse(realizedTransactionSummaryResponse);
    } catch (e) {
      informationAlert('Error getting realized SOI data', 'error');
    }
  };

  const fetchUnrealizedColumnViewConfig = async (
    isCanceled?: () => boolean
  ) => {
    try {
      const colOrderResponse = await getColumnOrder(
        SOI_VIEW_CONFIG_KEY.UnrealizedColumnKey,
        clientId
      );

      if (isCanceled?.()) return;

      setUnrealizedColumnOrder(colOrderResponse);
    } catch (e) {
      informationAlert(
        'Error retrieving initial order of unrealized columns',
        'error'
      );
    }
  };

  const fetchUnrealizedCategoryViewConfig = async (
    isCanceled?: () => boolean
  ) => {
    try {
      const colOrderResponse = await getColumnOrder(
        SOI_VIEW_CONFIG_KEY.UnrealizedCatrgorynKey,
        clientId
      );

      if (isCanceled?.()) return;

      setUnrealizedCategoryOrder(colOrderResponse);
    } catch (e) {
      informationAlert(
        'Error retrieving initial order of unrealized categories',
        'error'
      );
    }
  };

  const fetchRealizedColumnViewConfig = async (isCanceled?: () => boolean) => {
    try {
      const colOrderResponse = await getColumnOrder(
        SOI_VIEW_CONFIG_KEY.RealizedColumnKey,
        clientId
      );

      if (isCanceled?.()) return;

      setRealizedColumnOrder(colOrderResponse);
    } catch (e) {
      informationAlert(
        'Error retrieving initial order of realized columns',
        'error'
      );
    }
  };

  const fetchRealizedCategoryViewConfig = async (
    isCanceled?: () => boolean
  ) => {
    try {
      const colOrderResponse = await getColumnOrder(
        SOI_VIEW_CONFIG_KEY.RealizedCategoryKey,
        clientId
      );

      if (isCanceled?.()) return;

      setRealizedCategoryOrder(colOrderResponse);
    } catch (e) {
      informationAlert(
        'Error retrieving initial order of realized categories',
        'error'
      );
    }
  };

  const fetchPortCoAndInvestmentTransList = async (
    isCanceled: () => boolean
  ) => {
    const promises = [
      fetchInvestmentTransactioinsList(isCanceled),
      fetchPortfolioCompanies(isCanceled),
    ];

    await Promise.all(promises);
  };

  const fetchSoiSummaryData = async (isCanceled: () => boolean) => {
    const promises = [
      fetchUnrealizedSoiTransactionSummaryV2(isCanceled),
      fetchRealizedSoiTransactionSummaryV2(isCanceled),
    ];

    await Promise.all(promises);
  };

  const fetchViewConfigs = async (isCanceled: () => boolean) => {
    const promises = [
      fetchUnrealizedColumnViewConfig(isCanceled),
      fetchUnrealizedCategoryViewConfig(isCanceled),
      fetchRealizedColumnViewConfig(isCanceled),
      fetchRealizedCategoryViewConfig(isCanceled),
    ];

    await Promise.all(promises);
  };

  useEffectAsync(async (isCanceled) => {
    const promises = [
      fetchPortCoAndInvestmentTransList(isCanceled),
      fetchViewConfigs(isCanceled),
    ];

    await Promise.all(promises);
  }, []);

  return {
    portfolioCompaniesResponse,
    investmentTransactionsResponse,
    unrealizedSoiSummaryResponse,
    realizedSoiSummaryResponse,

    unrealizedColumnOrder,
    unrealizedCatrgoryOrder,
    realizedColumnOrder,
    realizedCategoryOrder,

    fetchSoiSummaryData,
  };
};
