import {
  BankAccount,
  BankInstitution,
  BankRule,
  ConnectPlaidAccountsReq,
  SearchTransactionsPayload,
  UpdateBankTransactionsPayload,
} from '../utils/types/bank.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

export const getPlaidLinkToken = async (bankEntityId: string, startDate: string) => {
  return await apiClient().get<any>(`banks/bank-integrations?bankEntityId=${bankEntityId}&startDate=${startDate}`);
};

export const connectPlaidAccounts = async (payload: ConnectPlaidAccountsReq) =>
  await apiClient().post<ConnectPlaidAccountsReq>(
    `banks/bank-integrations`,
    payload
  );

export const getBanks = async (fundId?: string) => {
  return fundId
    ? await apiClient().get<any>(`banks/bank-accounts?fundId=${fundId}`)
    : await apiClient().get<any>(`banks/bank-accounts`);
};

export const searchAllBankTransactions = async (
  payload: SearchTransactionsPayload
) => {
  return await apiClient().post<any>(`banks/bank-transactions/search`, payload);
};

export const updateBankTransactions = async (
  payload: UpdateBankTransactionsPayload[]
) => {
  return await apiClient().post<any>(`banks/bank-transactions`, payload);
};

export const categorizeBankTransactions = async (payload: {
  transactionIds: string[];
}) => {
  return await apiClient().post<any>(
    `banks/bank-transactions/categorize`,
    payload
  );
};

export const getBankRules = async (fundId?: string) => {
  return fundId
    ? await apiClient().get<any>(`banks/bank-rules?fundId=${fundId}`)
    : await apiClient().get<any>(`banks/bank-rules`);
};

export const createNewBankRule = async (data: BankRule) =>
  await apiClient().post<BankRule>(`banks/bank-rules`, data);

export const updateBankRule = async (id: string, data: BankRule) =>
  await apiClient().put<BankRule>(`banks/bank-rule/${id}`, data);

export const deleteBankRule = async (id: string) =>
  await apiClient().delete(`banks/bank-rules/${id}`);

export const updateBankAccount = async (id: string, data: any) =>
  await apiClient().put<any>(`banks/bank-accounts/${id}`, data);


export const getBankInstitutions = async () => {
  const cdnEndpoint: string = process.env.REACT_APP_CDN_ENDPOINT_URL as string;
  const dataEndpoint = `${cdnEndpoint}bank_integration/bank_institutions.json`;

  const bankInstitutionsResponse = await fetch(dataEndpoint);
  const bankInstitutionsData = await bankInstitutionsResponse.json() as any[];

  const returnData = bankInstitutionsData.map(bi => {
    return {
      id: bi.bank_entity_id,
      bankName: bi.bank_name.trim(),
      bankLogo: bi.bank_logo
    } as BankInstitution;
  });

  return returnData;
};