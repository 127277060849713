import { Box } from "@mui/material";
import { ReactElement } from "react";

import ConfirmationDialog from "../Modal/ConfirmationDialog";
import { ContentContainer } from "./LockConfirmationDailog.styles";
import { useLockConfirmationDailog } from "./useLockConfirmationDailog.hooks";

type Props = {
  lockedPrompt: boolean,
  setLockedPrompt: (value: boolean) => void,
  customText?: string,
  customTitle?: string
};

const LockConfirmationDialog = ({
  lockedPrompt,
  setLockedPrompt,
  customText,
  customTitle
  
}: Props): ReactElement => {
  const { closeLockedDialog } = useLockConfirmationDailog({ setLockedPrompt });
  const defaultText = <Box component="span">
      This feature is currently available on the full version of the Ark
      platform, please contact
      <ContentContainer> sales@arkpes.com </ContentContainer> or your fund
      administrator for access.
    </Box>;
  
  const text = customText === undefined ? defaultText : customText;
  const title = customTitle ?? 'Locked';

  return (
    <ConfirmationDialog
      open={lockedPrompt}
      onClose={closeLockedDialog}
      id="lock_prompt"
      actions={[
        {
          label: "Cancel",
          onClick: closeLockedDialog,
          id: "close_dialog",
          variant: "outlined",
          color: "error",
        },
      ]}
      content={text}
      title={title}
    />
  );
};

export default LockConfirmationDialog;
