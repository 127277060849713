import { useContext, useEffect, useState } from 'react';
import {
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';
import {
  addAdjustment,
  updateAdjustment,
  updateCapitalCallTransactions,
} from '../../../services/capitalCalls.service';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import {
  Adjustment,
  CashReceiptTransaction,
  SelectedCashReceipt,
} from '../../../utils/types/cashReceipt.type';
import { DetailsType, LoadingStatus } from '../../../utils/types/form.type';
import {
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import {
  SelectedCashReceiptTransaction,
  UPDATE_ADJUSTMENT_ERROR,
  UPDATE_ADJUSTMENT_SUCCESS,
  UPDATE_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_SUCCESS,
} from '../cashReceipts/CashReceipts.constants';

type Props = {
  transaction?: CashReceiptTransaction;
  onClose: Function;
  selectedTransaction: SelectedCashReceiptTransaction;
  fetchCapitalCallTransactions: Function;
  arkGlLocked: boolean;
  setSelectedJournalEntry: Function;
  onJournalEntryPanelClose: Function;
  onJournalEntrySuccessfulPostClose: Function;
  setSelectedMailing: Function;
  setNotifyList: Function;
  isNotifyChecked: boolean;
  isSendToGLChecked: boolean;
  setIsSendToGLChecked: Function;
  notify: Function;
  selectedCapitalCall: any;
  setSelectedJEList: Function;
};
export const useCashReceiptDetails = ({
  transaction,
  onClose,
  fetchCapitalCallTransactions,
  setSelectedJournalEntry,
  onJournalEntryPanelClose,
  onJournalEntrySuccessfulPostClose,
  setSelectedMailing,
  setNotifyList,
  isSendToGLChecked,
  setIsSendToGLChecked,
  isNotifyChecked,
  notify,
  selectedCapitalCall,
  selectedTransaction,
  setSelectedJEList,
}: Props) => {
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const { state, informationAlert } = useContext(AppContext);
  const [isNotifyDisabled, setIsNotifyDisabled] = useState<boolean>(false);
  const [isSendToGLDisabled, setIsSendToGLDisabled] = useState<boolean>(false);
  let newAdjustmentId = '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    getFieldState,
    control,
    setValue,
    watch,
    reset,
  } = useForm<CashReceiptTransaction>({
    defaultValues: transaction,
    reValidateMode: 'onChange',
  });
  const { isDirty } = useFormState({ control });
  const fieldStateAmount = getFieldState('amount');
  const fieldStateAdj1Amount = getFieldState(`adjustments.${0}.amountPaid`);
  const fieldStateAdj2Amount = getFieldState(`adjustments.${1}.amountPaid`);

  const adjustmentValues = useWatch({
    name: 'adjustments',
    control,
  });

  const notifiedStatus = watch('notified');
  const amountPaid = watch('amountPaid');
  const receiptDate = watch('receiptDate');

  useEffect(() => {
    //all amounts have journal entries
    if (
      transaction?.journalEntryId &&
      transaction?.adjustments &&
      transaction?.adjustments[0]?.journalEntryId &&
      transaction?.adjustments[1]?.journalEntryId
    ) {
      setIsSendToGLChecked(false);
      setIsSendToGLDisabled(true);
    }

    // amount has journal entry
    if (transaction?.journalEntryId && transaction?.adjustments.length === 0) {
      setIsSendToGLChecked(false);
      setIsSendToGLDisabled(true);
    }

    // amount/adjustment 1 have journal entries
    if (
      transaction?.journalEntryId &&
      transaction?.adjustments &&
      transaction?.adjustments[0]?.journalEntryId &&
      transaction?.adjustments[0]?.amountPaid &&
      !transaction?.adjustments[1]
    ) {
      setIsSendToGLChecked(false);
      setIsSendToGLDisabled(true);
    }

    // all amounts notified
    if (
      !!transaction?.notified &&
      transaction?.adjustments &&
      !!transaction?.adjustments[0]?.notified &&
      !!transaction?.adjustments[1]?.notified
    ) {
      setIsNotifyDisabled(true);
    }

    // Notified
    // all amounts notified
    if (
      transaction?.notified &&
      transaction?.adjustments &&
      transaction?.adjustments[0]?.notified &&
      transaction?.adjustments[1]?.notified
    ) {
      setIsNotifyDisabled(true);
    }

    // amount is notified
    if (transaction?.notified && transaction?.adjustments.length === 0) {
      setIsNotifyDisabled(true);
    }
    //amount/adj1 notified
    if (
      transaction?.notified &&
      transaction?.adjustments &&
      transaction?.adjustments[0]?.notified &&
      !transaction?.adjustments[1]
    ) {
      setIsNotifyDisabled(true);
    }
  }, [transaction]);

  useEffect(() => {
    if (isDirty && !isNotifyDisabled) {
      if (
        fieldStateAmount.isTouched &&
        transaction?.amountPaid === amountPaid &&
        transaction?.notified
      ) {
        setIsNotifyDisabled(true);
      }

      if (
        fieldStateAdj1Amount.isDirty &&
        transaction?.adjustments &&
        transaction?.adjustments[0]?.notified &&
        transaction?.adjustments[0]?.amountPaid ===
          (adjustmentValues[0]?.amountPaid as Number)
      ) {
        setIsNotifyDisabled(true);
      }

      if (
        fieldStateAdj2Amount.isDirty &&
        transaction?.adjustments &&
        transaction?.adjustments[1]?.notified &&
        transaction?.adjustments[1]?.amountPaid ===
          (adjustmentValues[1]?.amountPaid as Number)
      ) {
        setIsNotifyDisabled(true);
      }

      if (
        fieldStateAdj1Amount.isDirty &&
        transaction?.adjustments &&
        !transaction?.adjustments[0] &&
        !adjustmentValues[0]?.amountPaid &&
        !adjustmentValues[1]
      ) {
        setIsNotifyDisabled(true);
      }

      if (
        fieldStateAdj2Amount.isDirty &&
        transaction?.adjustments &&
        !transaction?.adjustments[1] &&
        !adjustmentValues[1]?.amountPaid
      ) {
        setIsNotifyDisabled(true);
      }
    }

    if (isDirty && !isSendToGLDisabled) {
      if (
        fieldStateAdj1Amount.isDirty &&
        transaction?.adjustments &&
        !transaction?.adjustments[0] &&
        !adjustmentValues[0]?.amountPaid
      ) {
        setIsSendToGLChecked(false);
        setIsSendToGLDisabled(true);
      }

      if (
        fieldStateAdj2Amount.isDirty &&
        transaction?.adjustments &&
        !transaction?.adjustments[1] &&
        !adjustmentValues[1]?.amountPaid
      ) {
        setIsSendToGLChecked(false);
        setIsSendToGLDisabled(true);
      }
    }
  }, [adjustmentValues, amountPaid]);

  const handleAdjustment = async (adjustment: Adjustment | null) => {
    try {
      if (adjustment && adjustment?.amountPaid && adjustment?.adjustmentDate) {
        const request: any = {
          transactionId: transaction?.id,
          amountPaid: adjustment.amountPaid,
          cashReceived: true,
          adjustmentDate: DateTimeFormat.shortDate(adjustment.adjustmentDate!),
        };

        if (!adjustment.id) {
          const id = await addAdjustment(selectedCapitalCall.id, request);

          newAdjustmentId = id as unknown as string;

          informationAlert(UPDATE_ADJUSTMENT_SUCCESS, 'success');
        } else {
          request.adjustmentId = adjustment.id;

          await updateAdjustment(selectedCapitalCall.id, request);
          informationAlert(UPDATE_ADJUSTMENT_SUCCESS, 'success');
        }
      }
    } catch (error) {
      informationAlert(UPDATE_ADJUSTMENT_ERROR, 'error');
    }
  };

  const editTransaction = async (data: CashReceiptTransaction) => {
    if (transaction && transaction.id) {
      try {
        // Main transaction
        if (!transaction.journalEntryId) {
          const request = {
            receivableTransactionUpdates: [
              {
                transactionId: data.id,
                amountPaid: data.amountPaid,
                cashReceived: data.cashReceived,
                receiptDate: DateTimeFormat.shortDate(data.receiptDate!),
              },
            ],
          };

          await updateCapitalCallTransactions(selectedCapitalCall.id, request);
        }

        await handleAdjustment(data.adjustments[0]);
        await handleAdjustment(data.adjustments[1]);

        informationAlert(UPDATE_TRANSACTION_SUCCESS, 'success');
        closeDrawer();
        fetchCapitalCallTransactions();
      } catch (error) {
        informationAlert(UPDATE_TRANSACTION_ERROR, 'error');
      }
    }
  };

  const updateTransaction = async (data: CashReceiptTransaction) => {
    const lineItems: any[] = [];
    const journalEntries: any[] = [];

    setIsLoading(LoadingStatus.Updating);
    await editTransaction(data);

    if (isNotifyChecked) {
      setNotifyList([data]);
    }

    if (isSendToGLChecked) {
      if (!transaction?.journalEntryId && data.amountPaid && data.receiptDate) {
        journalEntries.push({
          amountPaid: Number(data.amountPaid),
          receiptDate:
            typeof data.receiptDate === 'string'
              ? data.receiptDate
              : DateTimeFormat.shortDate(data.receiptDate).replace(
                  /(^|\/)0+/g,
                  '$1'
                ),
          isOriginalAmount: true,
          entities: [selectedTransaction?.investor],
          fundId: selectedCapitalCall.fundId,
          memo: data.name,
          transactionIds: [transaction?.id],
        });
      }
      if (
        transaction?.adjustments &&
        !transaction?.adjustments[0]?.journalEntryId &&
        data.adjustments &&
        data.adjustments[0] &&
        data.adjustments[0].amountPaid &&
        data.adjustments[0].adjustmentDate
      ) {
        journalEntries.push({
          amountPaid: Number(data.adjustments[0].amountPaid),
          receiptDate:
            typeof data.adjustments[0].adjustmentDate === 'string'
              ? data.adjustments[0].adjustmentDate
              : DateTimeFormat.shortDate(
                  data.adjustments[0].adjustmentDate
                ).replace(/(^|\/)0+/g, '$1'),
          isAdjustmentAmount: true,
          adjustmentId:
            (transaction?.adjustments[0] && transaction?.adjustments[0].id) ||
            newAdjustmentId,
          entities: [selectedTransaction?.investor],
          fundId: selectedCapitalCall.fundId,
          memo: data.name,
          transactionIds: [transaction?.id],
        });
      }

      if (
        transaction?.adjustments &&
        !transaction?.adjustments[1]?.journalEntryId &&
        data.adjustments &&
        data.adjustments[1] &&
        data.adjustments[1].amountPaid &&
        data.adjustments[1].adjustmentDate
      ) {
        journalEntries.push({
          amountPaid: Number(data.adjustments[1].amountPaid),
          receiptDate:
            typeof data.adjustments[1].adjustmentDate === 'string'
              ? data.adjustments[1].adjustmentDate
              : DateTimeFormat.shortDate(
                  data.adjustments[1].adjustmentDate
                ).replace(/(^|\/)0+/g, '$1'),
          isAdjustmentAmount: true,
          adjustmentId:
            (transaction?.adjustments[1] && transaction?.adjustments[1].id) ||
            newAdjustmentId,
          entities: [selectedTransaction?.investor],
          fundId: selectedCapitalCall.fundId,
          memo: data.name,
          transactionIds: [transaction?.id],
        });
      }

      let isDifferentDate = false;
      let currentDate: string;

      journalEntries.forEach((je) => {
        if (!currentDate) {
          currentDate = je.receiptDate;
        } else {
          if (currentDate !== je.receiptDate) {
            isDifferentDate = true;
          }
        }
      });

      if (journalEntries.length === 1) {
        //Single amount && single JE
        const adjustmentIds = [];

        const totalAmount = journalEntries[0].amountPaid;

        if (
          journalEntries[0].isAdjustmentAmount &&
          journalEntries[0].adjustmentId
        ) {
          adjustmentIds.push(journalEntries[0].adjustmentId);
        }

        if (newAdjustmentId) {
          adjustmentIds.push(newAdjustmentId);
        }

        lineItems.push({
          ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
          entities: [selectedTransaction?.investor],
          entryType: 'CREDIT',
          amount: totalAmount,
        });
        lineItems.push({
          ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
          entities: [selectedTransaction?.investor],
          entryType: 'DEBIT',
          amount: totalAmount,
        });

        setSelectedJournalEntry({
          journalEntry: {
            ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
            fundId: selectedCapitalCall.fundId,
            lineItems: lineItems,
            date: journalEntries[0].receiptDate,
            memo: data.name,
          },
          type: DetailsType.New,
          connectCashReceiptToJournalEntry: journalEntries[0].isOriginalAmount
            ? {
                cashReceiptTransactionIds: [transaction?.id],
              }
            : null,
          singleView: true,
          connectAdjustmentToJournalEntry: journalEntries[0].isAdjustmentAmount
            ? {
                cashReceiptTransactionIds: [transaction?.id],
                originalJournalEntryId: transaction?.journalEntryId,
                adjustmentIds: adjustmentIds,
              }
            : null,
        });
      } else if (!isDifferentDate && journalEntries.length > 1) {
        // Same date && multiple amounts
        let totalAmount: number = 0;
        const adjustmentIds = [];
        let isOriginalAmountIncluded: boolean = false;
        let isAdjustmentAmountIncluded: boolean = false;

        journalEntries.forEach((je) => {
          totalAmount = totalAmount + je.amountPaid;

          if (je.isOriginalAmount) {
            isOriginalAmountIncluded = true;
          }

          if (je.isAdjustmentAmount) {
            isAdjustmentAmountIncluded = true;
          }

          if (je.isAdjustmentAmount && je.adjustmentId) {
            adjustmentIds.push(je.adjustmentId);
          }
        });

        if (newAdjustmentId) {
          adjustmentIds.push(newAdjustmentId);
        }

        lineItems.push({
          ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
          entities: [selectedTransaction?.investor],
          entryType: 'CREDIT',
          amount: totalAmount,
        });
        lineItems.push({
          ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
          entities: [selectedTransaction?.investor],
          entryType: 'DEBIT',
          amount: totalAmount,
        });

        setSelectedJournalEntry({
          journalEntry: {
            ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
            fundId: selectedCapitalCall.fundId,
            lineItems: lineItems,
            date: journalEntries[0].receiptDate,
            memo: data.name,
          },
          type: DetailsType.New,
          connectCashReceiptToJournalEntry: isOriginalAmountIncluded
            ? {
                cashReceiptTransactionIds: [transaction?.id],
              }
            : null,
          singleView: true,
          connectAdjustmentToJournalEntry: isAdjustmentAmountIncluded
            ? {
                cashReceiptTransactionIds: [transaction?.id],
                adjustmentIds: adjustmentIds,
              }
            : null,
        });
      } else if (isDifferentDate && journalEntries.length > 1) {
        // Different dates, multiple JEs
        setSelectedJEList(journalEntries);
      }
    }

    if (!isSendToGLChecked && isNotifyChecked) {
      notify([data]);
    }

    setIsLoading(undefined);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    updateTransaction,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    toggleDrawer,
    notifiedStatus,
    amountPaid,
    receiptDate,
    adjustmentValues,
    isNotifyDisabled,
    setIsNotifyDisabled,
    isSendToGLDisabled,
    setIsSendToGLDisabled,
  };
};
