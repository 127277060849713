import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { DataGrid, ProgressModal } from '../../../components';
import { DataWrapperBox } from '../../../components/Boxes/DataWrapperBox/DataWrapperBox.style';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../components/ButtonWithOptions/ButtonWithOptions';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { BankRulesDetails } from '../bankRulesDetails/BankRuleDetails';
import { NewBankRuleOptions } from './BankRuleList.defaults';
import { useBankRulesList } from './BankRuleList.hooks';
import { ButtonBox, HeaderRow, IconBox } from './BankRuleList.styles';

export const BankRulesList: React.FC = () => {
  const {
    activeHeaderFields,
    bankList,
    bankRulesList,
    //bankRulesOptions,
    bankRulesSelectionModel,
    bulkActionOptions,
    fetchAllBankRules,
    fundId,
    handleBulkAction,
    handleFilter,
    handleNewButtonAction,
    handleOnView,
    handleRowOrderChange,
    handleSearch,
    handleUpdateHeader,
    headerList,
    isLoading,
    onColumnOrderChange,
    onDetailsClose,
    searchOptions,
    searchText,
    selectedBankRule,
    setBankRulesSelectionModel,
    setShowSuggestionPopover,
    showSuggestionPopover,
  } = useBankRulesList();

  return (
    <DataWrapperBox id="bank_rules_list_main" role="main">
      <ProgressModal
        id="loading_modal_bank_rules_list"
        showProgress={isLoading}
      />
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Bank Rules</Typography>
        </IconBox>
        <SearchBar
          id="bank_rules_search"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={searchText}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          {bankRulesSelectionModel.length >= 1 ? (
            <SplitButton
              id={'btn_bulk_action_options'}
              options={bulkActionOptions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          ) : (
            ''
          )}
          <ButtonWithOptions
            buttonID="add_bank_rule_button"
            popoverID="add_bank_rule_popover"
            onClick={handleNewButtonAction}
            buttonLabel="Add New"
            buttonIcon={<AddIcon />}
            options={NewBankRuleOptions}
          />
        </ButtonBox>
      </HeaderRow>
      <DataGrid
        id="bank_rules_data_grid"
        dataList={bankRulesList}
        minHeight="1vh"
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={bankRulesSelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setBankRulesSelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
        onColumnOrderChange={onColumnOrderChange}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      />
      {selectedBankRule?.type ? (
        <BankRulesDetails
          bankList={bankList}
          fetchAllBankRules={fetchAllBankRules}
          fundId={fundId}
          onDetailsClose={onDetailsClose}
          selectedBankRule={selectedBankRule}
        />
      ) : (
        ''
      )}
    </DataWrapperBox>
  );
};
