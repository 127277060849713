import { ButtonPropsVariantOverrides } from "@mui/material";
import Button from "@mui/material/Button";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";
import { OverridableStringUnion } from "@mui/types";
import React from "react";

import {
  BaseDialogActions,
  CloseIcon,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from "./NextStepDialog.styles";

interface Action {
  // label: This is the text that will be displayed on the button
  label: string;

  // onClick: This is the function that will be called when the button is clicked
  onClick: () => void;

  // variant?: This is the variant of the button as per the Material UI Button component
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;

  // color?: This is the color of the button as per the Material UI Button component
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;

  // id: This is the id of the button
  id: string;

  // disabled?: This is the disabled state of the button
  disabled?: boolean;
}

interface Props {
  // open: This is the boolean that determines if the dialog is open or not
  open: boolean;

  // onClose: This is the function that closes the dialog
  onClose?: () => void;

  // title: This is the title of the dialog
  title?: string | React.ReactNode;

  // children: This is the children of the dialog
  children?: React.ReactNode;

  // actions: This is the array of actions that will display the buttons on the dialog
  actions?: Array<Action>;

  // actionDisplayType: This is the display type of the action buttons if we want to display then stacked or inline, by default it is stacked.
  actionDisplayType?: "inline" | "stacked";

  // fullWidth: This is the boolean that determines if dialog need to be fullwidth or content-length, default is false
  fullWidth?: boolean;

  // id: This is the id of the dialog
  id: string;
}

const NextStepDialog = ({
  open,
  onClose,
  title,
  actions,
  actionDisplayType = "stacked",
  fullWidth = false,
  id,
  children,
}: Props) => {
  const DialogActionContainer =
    actionDisplayType === "inline" ? BaseDialogActions : StyledDialogActions;

  return (
    <StyledDialog
      open={open}
      id={id}
      aria-labelledby="next-step-dialog-title"
      aria-describedby="next-step-dialog-description"
      closeAfterTransition
      PaperProps={{
        elevation: 0,
      }}
      fullWidth={fullWidth}
    >
      {!!title && (
        <StyledDialogTitle>
          {title}
          <CloseIcon
            onClick={onClose}
          />
        </StyledDialogTitle>
      )}
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      <DialogActionContainer>
        {actions?.map((action) => (
          <Button
            key={action.id}
            id={action.id}
            variant={action?.variant ?? "contained"}
            color={action?.color ?? "primary"}
            onClick={action.onClick}
            disabled={action.disabled}
          >
            {action.label}
          </Button>
        ))}
      </DialogActionContainer>
    </StyledDialog>
  );
};

export default NextStepDialog;
