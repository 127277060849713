import IconDownload from '../../../assets/images/icons/icon_download.svg';
import IconNewCircle from '../../../assets/images/icons/icon_new_circle.svg';
import IconUpload from '../../../assets/images/icons/icon_upload.svg';
import { ButtonOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { AddNewButtonOptions } from '../../../utils/types/common.type';
import {
  InvestmentTransaction,
  InvestmentTransactionFollowing,
} from '../../../utils/types/investmentTransaction.type';

export const GET_INVESTMENT_TRANSACTION_DETAILS_ERROR =
  'Error in getting investment transaction details.';
export const GET_INVESTMENT_TRANSACTION_LIST_ERROR =
  'Error in getting investment transaction list.';
export const GET_TRANSACTION_LIST_ERROR =
  'Error in getting purchase transaction list.';
export const EXPORT_INVESTMENT_TRANSACTION_ERROR =
  'Error in exporting investment transactions.';
export const DELETE_INVESTMENT_TRANSACTION_ERROR =
  'Error in deleting investment transactions.';
export const DELETE_INVESTMENT_TRANSACTION_SUCCESS =
  'Investment transactions deleted successfully.';
export const DOWNLOAD_INVESTMENT_TRANSACTION_ERROR =
  'Error in downloading investment transactions.';
export const CREATE_INVESTMENT_TRANSACTION_ERROR =
  'Error in creating investment transaction.';
export const CREATE_INVESTMENT_TRANSACTION_SUCCESS =
  'Investment transaction created successfully.';
export const UPDATE_INVESTMENT_TRANSACTION_ERROR =
  'Error in updating investment transaction.';
export const UPDATE_INVESTMENT_TRANSACTION_SUCCESS =
  'Investment transaction updated successfully.';
export const ERROR_TAKEN_INVESTMENT_TRANSACTION_NAME =
  'Investment transaction name is already used in the system';
export const INVESTMENT_TRANSACTION_VIEW_KEY =
  'INVESTMENT_TRANSACTION_VIEW_KEY';
export const GET_VIEW_LIST_ERROR = 'Error retrieving initial order of columns.';
export const CONNECT_INVESTMENT_TRANSACTION_ERROR =
  'Error connecting investment transaction to journal entry.';
export const CONNECT_INVESTMENT_TRANSACTION_SUCCESS =
  'Successfully connected investment transaction to journal entry.';
export const DISABLE_SEND_TO_GL_ALREADY_LINKED_TOOLTIP =
  "The 'Send to' action is limited to once per entry. This investment transaction already has a linked journal entry.";
export const DISABLE_SEND_TO_SOI_ALREADY_LINKED_TOOLTIP =
  "The 'Send to' action is limited to once per entry. This journal entry already has a linked investment transaction.";
export const DISABLE_SEND_TO_LOOP_TOOLTIP =
  "To prevent continuous loops, the 'Send to' action (SOI to GL or GL to SOI) is limited to once per entry. This action has already been performed for this entry.";

export const INVESTMENT_TRANSACTION_PURCHASE_FORM_DEFAULT_VALUE: InvestmentTransaction =
  {
    id: null,
    convertedTransaction: '',
    amountPerQuantity: null,
    date: null,
    fund: null,
    interestRate: '',
    investmentSecurity: '',
    isSoiDisabled: '',
    name: '',
    noteAgreement: '',
    portfolioCompany: null,
    quantity: null,
    securityType: '',
    status: '',
    tenantId: '',
    totalAmount: null,
    commitmentAmount: '',
  };

export const INVESTMENT_TRANSACTION_FOLLOWING_FORM_DEFAULT_VALUE: InvestmentTransactionFollowing =
  {
    id: null,
    date: null,
    removeFromHistoricalSoi: false,
    newTotalAmount: null,
    newAmountPerQuantity: null,
    totalAmountOther: null,
    quantityOther: null,
    amountPerQuantityOther: null,
  };

export const NewInvestmentTransactionOptions: ButtonOptions[] = [
  {
    label: 'New Investment Transaction',
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
  {
    label: 'Upload From Template',
    id: AddNewButtonOptions.UploadFromTemplate,
    icon: IconUpload,
    type: 'upload',
  },
  {
    label: 'Download Template',
    id: AddNewButtonOptions.DownloadTemplate,
    icon: IconDownload,
  },
];

export enum InvestmentTransactionTypes {
  PURCHASE = 'Purchase' as any,
  SPLIT = 'Split' as any,
  WRITE_OFF = 'Write Off' as any,
  SALE = 'Sale' as any,
  VALUATION = 'Valuation' as any,
  CONVERSION = 'Conversion' as any,
}

export enum InvestmentTransactionSubTypes {
  WRITE_OFF = 'WRITE OFF' as any,
  FULL = 'FULL' as any,
  PARTIAL = 'PARTIAL' as any,
  TOTAL_VALUATION = 'TOTAL' as any,
  NET_EFFECT_VALUATION = 'NET EFFECT' as any,
}

export const NEW_INVESTMENT_TRANSACTION_TYPES = [
  {
    id: 'PURCHASE',
    name: 'Purchase',
  },
  {
    id: 'SALE_FULL',
    name: 'Sale - Total',
  },
  {
    id: 'SALE_PARTIAL',
    name: 'Sale - Partial',
  },
  {
    id: 'TOTAL_VALUATION',
    name: 'Valuation - Total',
  },
  {
    id: 'NET_EFFECT_VALUATION',
    name: 'Valuation - Net Effect',
  },
  {
    id: 'WRITE_OFF',
    name: 'Write Off',
  },
  {
    id: 'SPLIT',
    name: 'Stock Split',
  },
  {
    id: 'CONVERSION',
    name: 'Conversion',
  },
];
