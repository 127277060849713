import Typography from "@mui/material/Typography";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { ReactElement } from "react";

import { M_DASH_UNICODE } from "../../../../../utils/constants/constants";
import { humanReadableFileSize } from "../../../../../utils/helpers/misc.helper";


function FileSizeCellRenderer({ row }: GridRenderEditCellParams): ReactElement {
  return <Typography variant="cells">{row.type === "folder" ? M_DASH_UNICODE: humanReadableFileSize(row.sizeInBytes, true)}</Typography>;
}

export default FileSizeCellRenderer;
