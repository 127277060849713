import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

import { ACTIVE_GREEN, ERROR, FONT_PRIMARY } from "../../styles/colors";

interface FooterBoxProps {
  height: number | string;
}

export const FooterWrapper = styled(Box)(({
  theme
}) => ({
  position: "relative",
  width: "100%",
  backgroundColor: theme.palette.common.white,
}));

export const FooterBox = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "height"
})<FooterBoxProps>(({
  theme, height
}) => ({
  position: "fixed",
  bottom: 0,
  zIndex: 2,
  marginLeft: theme.spacing(-2),
  width: "calc(100% + 16px)",
  backgroundColor: theme.palette.common.white,
  height: height,
}));

export const StyledContainer = styled(Box)`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;

  & .MuiTypography-root {
    text-transform: lowercase;
    margin-left: 15px;
    color: ${FONT_PRIMARY};
  }

  & .MuiTypography-root:first-letter {
    text-transform: capitalize;
  }

`;

export const RedLockIcon = styled(LockOutlinedIcon)`
  color: ${ERROR};
`;

export const GreenLockIcon = styled(LockOpenOutlinedIcon)`
  color: ${ACTIVE_GREEN};
`;

export const StyledTooltip = styled(({
  className, ...props
}: TooltipProps) => (
  <Tooltip {...props} arrow classes={{
    popper: className
  }} />
))(({
  theme
}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    padding: theme.spacing(2, 1, 2, 1),
  },
}));
