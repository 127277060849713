import { FROM_VIEW } from '../constants/common.constants';
import { PageInfo } from './common.type';
import { DetailsType } from './form.type';

export type InvestmentTransactionResponse = {
  pageInfo: PageInfo;
  items: InvestmentTransaction[];
};

export type PortfolioCompany = {
  id: string;
  name?: string;
};

export type Fund = {
  id: string;
  name?: string;
  tenantId: string;
};

export type InvestmentTransaction = {
  id?: string | null;
  investmentTransactionType?: string;
  convertedTransaction?: string;
  amountPerQuantity?: number | null;
  currency?: string | null;
  date: string | Date | null;
  fund?: Fund | null;
  interestRate?: string;
  investmentSecurity?: string | null;
  isSoiDisabled?: string;
  name?: string;
  noteAgreement?: string;
  portfolioCompany?: PortfolioCompany | null;
  quantity?: number | null | string;
  securityType?: string;
  status?: string;
  tenantId: string;
  totalAmount?: number | null;
  commitmentAmount?: string | null;
  transactionType?: string;
  fromTransaction?: any;
  stockSplitQuantity?: number;
  stockSplitAmountPerQuantity?: number;
  fromTransactionId?: string;
  saleType?: string;
  entryType?: string;
  totalAmountOther?: number | null;
  subtype?: string;
};

export type InvestmentTransactionPurchase = {
  id: string | null;
  investmentTransactionType?: string;
  convertedTransaction: string;
  amountPerQuantity: number | null;
  date: string | Date | null;
  fund: Fund | null;
  interestRate: string;
  investmentSecurity: string | null;
  isSoiDisabled: string;
  name: string;
  noteAgreement: string;
  portfolioCompany: PortfolioCompany | null;
  quantity: number | null | string;
  securityType: string;
  status: string;
  tenantId: string;
  totalAmount: number | null;
  commitmentAmount: string | null;
};

export type InvestmentTransactionFollowing = {
  id: string | null;
  purchaseTransactionId?: string;
  date: string | Date | null;
  totalAmountOther?: number | null | string;
  quantityOther?: number | null | string;
  amountPerQuantityOther?: number | null | string;
  commitmentAmount?: string | null;
  investmentTransactionType?: string;
  stockSplitQuantity?: number;
  stockSplitAmountPerQuantity?: number;
  removeFromHistoricalSoi?: boolean;
  newTotalAmount?: number | null;
  newAmountPerQuantity?: number | null;
  newSecurityType?: string;
  saleType?: string;
  newInterestRate?: string;
  newNoteAgreement?: string;
  newCommitmentAmount?: string | null;
  newInvestmentSecurity?: string | null;
  subtype?: string;
};

export enum InvestmentTransactionNewView {
  Purchase = 'purchase',
  ConversionExcluded = 'conversionExcluded',
  Following = 'following',
  All = 'all',
}

export type SelectedInvestmentTransaction = {
  investmentTransactionType?: string;
  investmentTransaction?: InvestmentTransaction;
  viewPurchaseTransactionId?: string;
  type?: DetailsType;
  newView?: InvestmentTransactionNewView;
  connectTransactionToJournalEntry?: {
    journalEntryId?: string;
    investmentTransactionId?: string;
  };
  fromView?: string;
};

export enum InvestmentTransactionType {
  SaleTotal = 'Sale - Total',
  SalePartial = 'Sale - Partial',
  ValuationTotal = 'Valuation - Total',
  ValuationNetEffect = 'Valuation - Net effect',
  WriteOff = 'Write Off',
  StockSplit = 'Stock split',
  Conversion = 'Conversion',
}
