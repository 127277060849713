import {
  Box as MUIBox,
  FormControl as MUIFormControl,
  List as MUIList,
  Stack as MUIStack,
  styled,
  Switch as MUISwitch,
} from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro';

export const Box = styled(MUIBox)`
  margin: 10px;
`;

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '70% 13% 13%',
  gap: theme.spacing(1),
  justifyContent: 'left',
  alignItems: 'center',
  mb: theme.spacing(2),
}));

export const Panel = styled(MUIBox)`
  margin: 15px 0;
`;

export const Switch = styled(MUISwitch)`
  &.Mui-checked {
    backgroundcolor: #65c466;
    opacity: 1;
    border: 0;
  }

  &.Mui-focusVisible .MuiSwitch-thumb {
    color: #33cf4d;
    border: 6px solid #fff;
  }

  &.Mui-disabled .MuiSwitch-thumb {
    color: grey;
  }

  &.Mui-disabled + .MuiSwitch-track {
    opacity: 0.7;
  }

  &.MuiSwitch-thumb {
    boxsizing: border-box;
    width: 22;
    height: 22;
  }

  &.MuiSwitch-track {
    borderradius: 26 / 2;
    backgroundcolor: #e9e9ea;
    opacity: 1;
  }
`;

// export const FormControl = styled(MUIFormControl)`
//   margin: 10px;
//   min-width: 200px;
// `;

export const StatusControl = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 50px;
`;

export const Stack = styled(MUIStack)`
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: flext-start;
  padding: 0;
`;

export const DatePicker = styled(MUIDatePicker)`
  &.MuiFormControl-root.MuiTextField-root {
    margin: 10px;
  }
`;

export const BankInfoStack = styled(MUIStack)`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flext-start;
  padding: 0;

  & .MuiBox-root:first-of-type {
    width: 65%;
  }

  & .MuiBox-root:nth-of-type(2) {
    width: 35%;
  }

  & .MuiFormControl-root.MuiTextField-root {
    margin: 10px 10px 10px 0;
  }
`;

export const List = styled(MUIList)`
  &.MuiList-root {
    width: 320px;
    margin: 10px;
  }

  & .MuiListItem-root {
    border: 1px solid;
  }
`;
