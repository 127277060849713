import {
  Backdrop,
  Box,
  Drawer as MUIDrawer,
  styled,
  Typography as MUITypography,
} from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../utils/constants/styles.constants";

export const Drawer = styled(MUIDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxWidth: INFO_PANEL_MAX_WIDTH,
    minWidth: INFO_PANEL_MAX_WIDTH,
  },
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(4),
  whiteSpace: "nowrap",
  "img:first-of-type": {
    paddingLeft: "20px",
  },
}));

export const Title = styled("div")(() => ({
  fontStyle: "normal",
  fontSize: "24px",
  lineHeight: "100%",
  textAlign: "left",
  verticalAlign: "center",
  paragraphSpacing: "10px",
  fontWeight: "bold",
  display: "flex",
  whiteSpace: "nowrap",
  "img:first-of-type": {
    paddingLeft: "20px",
  },
}));

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
`;

export const FormBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: 0,
}));

export const Panel = styled(Box)`
  margin: 15px 0;
`;

export const Typography = styled(MUITypography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const PanelBoxExpanded = styled(Box)(({ theme }) => ({
  width: "calc(100vw - 20px)",
  padding: theme.spacing(4),
  paddingTop: 0,
  height: "100%",
  overflowX: "hidden",
}));
