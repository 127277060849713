import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getTransactionTypes } from "../../../services/fund.service";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const useArkTransactionFilterEffect = () => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const [arkTransactionList, setArkTransactionList] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  const fetchArkTransactionFilterList = async (isCanceled?: () => boolean) => {
    setLoading(true);
    try {
      const statusResponse = await getTransactionTypes(clientId);

      if (isCanceled?.()) return;

      if (statusResponse) {
        statusResponse.unshift({
          id: "1",
          name: "Do Not Map",
        });

        setArkTransactionList(statusResponse);
      }
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchArkTransactionFilterList(isCanceled);
  }, []);

  return {
    arkTransactionList,
    loading,
  };
};

export default useArkTransactionFilterEffect;
