import { Grid } from "@mui/material";
import React from "react";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import Chip from "../../../../components/Chip/Chip";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import {
  FormBox,
  StyledBackdrop,
} from "../../../../components/DetailPanel/DetailPanel.styles";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import { M_DASH_UNICODE } from "../../../../utils/constants/constants";
import { DetailsType } from "../../../../utils/types/form.type";
import { SelectedPortfolioCompany } from "../../../../utils/types/portfolioCompany.type";
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from "../../../contacts/contactInfo/contactDetailPanel/ContactDetails/ContactDetails.styles";
import {
  usePortfolioCompanyDetails,
  useTabs,
} from "./portfolioCompanyDetails.hooks";
import { ChipStack, Panel, Typography } from "./portfolioCompanyDetails.styles";

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedPortfolioCompany: SelectedPortfolioCompany;
  fetchAllPortfolioCompanies: Function;
  fundId?: string;
}

export const ReadOnlyPortfolioCompanyDetails: React.FC<Props> = ({
  onDetailClose,
  selectedPortfolioCompany,
  fetchAllPortfolioCompanies,
  fundId,
}: Props) => {
  const { type, portfolioCompany } = selectedPortfolioCompany;
  const isNewPortfolioCompany = type === DetailsType.New;

  const {
    currentTab,
    detailsRef,
    fundsRef,
    additionalFieldsRef,
    logoRef,
    handleTabChange,
  } = useTabs();

  const { loading, closeDrawer, isPublic, funds, investmentTypes } =
    usePortfolioCompanyDetails({
      portfolioCompany,
      onClose: onDetailClose,
      fetchAllPortfolioCompanies,
      isNewPortfolioCompany,
      fundId,
    });

  return (
    <React.Fragment>
      <DetailPanel
        id="portfolio_company_panel"
        title={portfolioCompany?.name}
        open={Boolean(type)}
        onClose={closeDrawer}
        hasTabsPanel={true}
        tabsPanelValue={currentTab}
        tabsPanelHandleChange={handleTabChange}
        tabsPanelOptions={[
          "Details",
          "Funds & Securities",
          "Additional Fields",
          "Logo",
        ]}
      >
        <FormBox>
          <Panel id="details_panel" ref={detailsRef}>
            <Typography variant="h4">Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Name</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.name || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Address Line 1</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.street1 || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Address Line 2</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.street2 || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Postal Code</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.postalCode || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>City</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.city || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>State/Province/Region</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.state || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Country</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.address?.country || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Industry</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.industrySector || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>CEO Name</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.ceo || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Year Founded</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.yearFounded || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Number of Employees</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.numOfEmployees || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>About</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.about || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Type</KeyLabel>
                  <ValueLabel>
                    {isPublic === true ? "Public" : "Private"}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12} md={6}>
                <KeyValuePair>
                  <KeyLabel>Ticker Symbol</KeyLabel>
                  <ValueLabel>
                    {isPublic === true ? "Public" : "Private"}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
              <Grid item xs={12}>
                <KeyValuePair>
                  <KeyLabel>Website URL</KeyLabel>
                  <ValueLabel>
                    {portfolioCompany?.website || M_DASH_UNICODE}
                  </ValueLabel>
                </KeyValuePair>
              </Grid>
            </Grid>
          </Panel>
          <Panel id="funds_panel" ref={fundsRef}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Funds &amp; Securities</Typography>
              </Grid>
              <Grid item xs={12}>
                <ChipStack>
                  {funds.map((fund) => {
                    return (
                      <Chip
                        label={fund.name}
                        id={fund.id}
                        key={fund.id}
                        color="green"
                      />
                    );
                  })}
                </ChipStack>
              </Grid>
              <Grid item xs={12}>
                <ChipStack>
                  {investmentTypes.map((security) => {
                    return (
                      <Chip
                        label={security.label}
                        id={security.id}
                        key={security.id}
                        color="blue"
                      />
                    );
                  })}
                </ChipStack>
              </Grid>
            </Grid>
          </Panel>
          <Panel id="additional_fields_panel" ref={additionalFieldsRef}>
            <Typography variant="h4">Additional Fields</Typography>
            <Grid container spacing={2}>
              {portfolioCompany?.additionalFieldsForm?.map(
                (prop: any, index) => (
                  <Grid key={index} item xs={12} md={6}>
                    <KeyValuePair>
                      <KeyLabel>Custom Field {index + 1}</KeyLabel>
                      <ValueLabel>
                        {prop.values.join(",") || M_DASH_UNICODE}
                      </ValueLabel>
                    </KeyValuePair>
                  </Grid>
                )
              )}
            </Grid>
          </Panel>
          <Panel id="logo_panel" ref={logoRef}>
            <Typography variant="h4">Logo</Typography>
            <Grid container spacing={2}>
              <Grid item md={6}>
                {portfolioCompany?.logo ? (
                  <img
                    src={portfolioCompany.logo as string}
                    alt={portfolioCompany?.name}
                    width={40}
                    height={40}
                  />
                ) : (
                  M_DASH_UNICODE
                )}
              </Grid>
            </Grid>
          </Panel>
        </FormBox>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
        >
          <Button
            id={"portfolio_company_close_button"}
            variant="outlined"
            onClick={closeDrawer}
            text={"Close"}
            color={"secondary"}
            fullWidth
          />
        </HorizontalBox>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={"progress_portfolio_company_panel"}
          showProgress={Boolean(loading)}
          text={`${loading} Portfolio Company...`}
        />
      </DetailPanel>
    </React.Fragment>
  );
};
