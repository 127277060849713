import { Box, styled, Typography } from '@mui/material';

export const SingleLegendBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingTop: '2px',
}));

export const LegendColorBox = styled(Box)<{
  color: string;
}>(({ theme, color }) => ({
  width: 20,
  height: 20,
  minWidth: 20,
  backgroundColor: color,
  marginRight: theme.spacing(1),
  borderRadius: '4px',
}));

export const LegendTypography = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',
  minWidth: '160px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
}));
