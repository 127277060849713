import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CircularProgress, Collapse, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import ConfirmationDialog from '../../Modal/ConfirmationDialog';
import Button from '../Button/Button';
import { CustomTooltip, ErrorIcon, InlineEditsToolbarBox, NowrapTypography, TooltipContent } from './SaveDiscardButtonGroup.styles';

type Props = {
  handleSave: Function;
  handleDiscard: Function;
  updatedRowsTotal: number;
  isSaving?: boolean;
  showUnsavedEditsPrompt: boolean;
  setShowUnsavedEditsPrompt: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const SaveDiscardButtonGroup = ({
  updatedRowsTotal,
  handleSave,
  handleDiscard,
  showUnsavedEditsPrompt,
  setShowUnsavedEditsPrompt
}: Props) => {

  const [showDiscardChangesPrompt, setShowDiscardChangesPrompt] =
    useState<boolean>(false);


  const handleDiscardConfirmation = () => {
    handleDiscard();
    setShowDiscardChangesPrompt(false);
  };

  useEffect(() => {
    if (showUnsavedEditsPrompt) {
      const timer = setTimeout(() => {
        setShowUnsavedEditsPrompt(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showUnsavedEditsPrompt]);

  return (
    <>
      <Collapse in={!!updatedRowsTotal} orientation="horizontal" id='button_group_save_discard'>
        <CustomTooltip
          open={showUnsavedEditsPrompt}
          title={
            <TooltipContent>
              <ErrorIcon fontSize="medium" />
              <NowrapTypography>
                Save or Discard Changes First
              </NowrapTypography>
              <IconButton size="small" onClick={() => setShowUnsavedEditsPrompt(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </TooltipContent>
          }
          placement="top"
          arrow
        >
          <span>
            <InlineEditsToolbarBox highlighted={showUnsavedEditsPrompt}>
              <>
                <NowrapTypography>
                  {updatedRowsTotal === 1
                    ? `${updatedRowsTotal} transaction has been edited`
                    : `${updatedRowsTotal} transactions have been edited`}
                </NowrapTypography>
                <Button
                  id="btn_save"
                  variant="contained"
                  text={'Save'}
                  color={'primary'}
                  onClick={handleSave}
                  icon={<SaveOutlinedIcon />}
                />
                <Button
                  id="btn_discard_changes"
                  variant="contained"
                  text={'Discard Changes'}
                  color={'error'}
                  onClick={() => setShowDiscardChangesPrompt(true)}
                  icon={<RestoreIcon />}
                />
              </>
            </InlineEditsToolbarBox>
          </span>
        </CustomTooltip>
      </Collapse>
      <ConfirmationDialog
        open={showDiscardChangesPrompt}
        actionDisplayType='inline'
        onClose={() => setShowDiscardChangesPrompt(false)}
        id="discard_changes_confirmation_dialog"
        actions={[
          {
            label: `Discard`,
            onClick: handleDiscardConfirmation,
            id: 'btn_discard_changes_confirmation',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: () => setShowDiscardChangesPrompt(false),
            id: 'btn_cancel_and_keep_changes',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        title="Discard all changes?"
        content="Changes will be permanently discarded."
      />
    </>
  );
};

export default SaveDiscardButtonGroup;