import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import { getAuth0Token } from "../../../services/client.service";

export enum ErrorType {
    General,
    Auth0General,
    AccessDenied
};

export const authErrorEffect = () => {

    const [errorType, setErrorType] = useState<ErrorType>(ErrorType.General);

    const { 
        onLogout, 
        preAuthClientConfigs 
    } = useContext(AppContext);
    
    function handleRetryLogin() {
        onLogout();
    
        getAuth0Token(preAuthClientConfigs!, true);
    }
    
    function isFromAuth0Error() {
        if(!window?.location?.search) return false;

        const queryParameters = new URLSearchParams(window.location.search);
        const errorSource = queryParameters.get("error_source");

        return errorSource === 'auth0';
    }

    function isAuth0AccessDeniedError() {
        if(!window?.location?.search) return false;

        const queryParameters = new URLSearchParams(window.location.search);
        const error = queryParameters.get("error");

        return error === 'access_denied';
    }
    
    const supportEmail = `${preAuthClientConfigs?.supportEmail||"support@arkpes.com"}`;


    useEffect(() => {
        if(isAuth0AccessDeniedError()) {
            setErrorType(ErrorType.AccessDenied);
            return;
        }

        if(isFromAuth0Error()) {
            setErrorType(ErrorType.Auth0General);
            return;
        }

        setErrorType(ErrorType.General);
    }, []);

    
    return {
        handleRetryLogin,
        supportEmail,
        errorType
    };
};