import { Close } from '@mui/icons-material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, IconButton, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  StyledTab,
  StyledTabs,
} from '../../components/TabsPanel/TabsPanel.styles';
import { TabsRow } from '../users/Users.styles';
import ClientDetailsTab from './ClientDetails/ClientDetailsTab';
import {
  ArkClientBox,
  ArkClientBoxIcon,
  Container,
  HeaderBreadcrumbContainer,
  HeaderRow,
  HeaderTitleContainer,
} from './Clients.styles';
import ConsentConfig from './ConsentConfig/ConsentConfig';
import { ClientTabs } from './constants';
import FeatureConfig from './FeatureConfig/FeatureConfig';
import ReportConfig from './ReportConfig/ReportConfig';
import TransactionMapping from './TransactionMapping/TransactionMapping';
import { useClient } from './useClient.hooks';

const ClientDetails: React.FC = (): ReactElement => {
  const {
    tab,
    arkTag,
    isNewClientTag,
    handleChange,
    handleClose,
    isNewClient,
    handleCloseIcon,
    isSuperAdmin,
    isArkClientAdmin,
  } = useClient();

  return (
    <Container
      id="client_details"
      anchor="right"
      open={true}
      onClose={handleClose}
    >
      <Box id="client_details_page">
        <HeaderRow>
          <HeaderTitleContainer>
            <IconButton onClick={handleClose} aria-label="Back">
              <ArrowBackIosRoundedIcon color="primary" fontSize="large" />
            </IconButton>
            <HeaderBreadcrumbContainer>
              <Typography variant="pageTitle">Clients</Typography>
              {!isNewClientTag && (
                <>
                  <ArrowForwardIosRoundedIcon color="action" />
                  <ArkClientBox>
                    <ArkClientBoxIcon color="secondary" />
                    <Typography>
                      {arkTag && arkTag !== 'null' ? arkTag : 'No Client'}
                    </Typography>
                  </ArkClientBox>
                </>
              )}
            </HeaderBreadcrumbContainer>
            <Box />
          </HeaderTitleContainer>
          <IconButton
            aria-label="Close Client"
            color="inherit"
            id="close_client_drawer"
            onClick={handleCloseIcon}
          >
            <Close />
          </IconButton>
        </HeaderRow>
        <TabsRow>
          <StyledTabs
            id="client_detail_tabs"
            value={tab}
            onChange={handleChange}
            aria-label="Client Details Tabs"
          >
            <StyledTab key={0} label={ClientTabs.ClientDetails} />
            <StyledTab
              key={1}
              label={ClientTabs.TransactionMapping}
              disabled={isNewClient}
            />
            <StyledTab
              key={2}
              label={ClientTabs.ReportConfig}
              disabled={isNewClient}
            />
            <StyledTab
              key={3}
              label={ClientTabs.ConsentConfig}
              disabled={isNewClient}
            />
            {(isSuperAdmin || isArkClientAdmin) && (
              <StyledTab
                key={3}
                label={ClientTabs.FeatureConfig}
                disabled={isNewClient}
              />
            )}
          </StyledTabs>
        </TabsRow>
        <Box>
          <Switch>
            <Route
              path={`/clients/:id/:arkTag/${ClientTabs.ClientDetails}`}
              component={ClientDetailsTab}
              exact
            />
            {!isNewClient && (
              <>
                <Route
                  path={`/clients/:id/:arkTag/${ClientTabs.TransactionMapping}`}
                  component={TransactionMapping}
                  exact
                />
                <Route
                  path={`/clients/:id/:arkTag/${ClientTabs.ReportConfig}`}
                  component={ReportConfig}
                  exact
                />
                <Route
                  path={`/clients/:id/:arkTag/${ClientTabs.ConsentConfig}`}
                  component={ConsentConfig}
                  exact
                />
                {(isSuperAdmin || isArkClientAdmin) && (
                  <Route
                    path={`/clients/:id/:arkTag/${ClientTabs.FeatureConfig}`}
                    component={FeatureConfig}
                    exact
                  />
                )}
              </>
            )}
          </Switch>
        </Box>
      </Box>
    </Container>
  );
};

export default React.memo(ClientDetails);
