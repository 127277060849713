import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import {
  GridAlignment,
  GridColumnOrderChangeParams,
  GridRowClassNameParams,
} from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router';

import ExportIcon from '../../../assets/images/icons/icon_export.svg';
import FileIcon from '../../../assets/images/icons/icon_file.svg';
import PencilIcon from '../../../assets/images/icons/icon_pencil.svg';
import { StringCell } from '../../../components/DataGrid/DataGrid.styles';
import { ActionLink } from '../../../components/Link/ActionLink/ActionLink';
import StatusLabel from '../../../components/StatusLabel/StatusLabel';
import { AppContext } from '../../../core/context/appContextProvider';
import useRole from '../../../core/routing/useRole';
import { getJournalEntry, getLedgers } from '../../../services/arkGL.service';
import {
  getCapitalCallDetails,
  getCapitalCalls,
  getCapitalCallTransactions,
  saveCapitalCallTransactions,
  updateCapitalCall,
} from '../../../services/capitalCalls.service';
import { getEntities } from '../../../services/entity.service';
import { getFundsList } from '../../../services/fund.service';
import {
  EMPTY_REQUIRED_FIELDS_ERROR,
  GET_CAPITAL_CALL_DETAILS_ERROR,
  GET_CAPITAL_CALL_LIST_ERROR,
  GET_CAPITAL_CALL_TRANSACTIONS_ERROR,
  NotifiedStatusType,
  SAVE_CAPITAL_CALL_LIST_ERROR,
  SAVE_CAPITAL_CALL_LIST_SUCCESS,
} from '../../../services/hooks/useCapitalCallsEffect/useCapitalCall.constants';
import { useClientEffect } from '../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import {
  areDateOrNullValuesEqual,
  CurrencyFormat,
  DateTimeFormat,
  FindLengthOfFraction,
  getCurrencySymbol,
} from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { SelectedJournalEntry } from '../../../utils/types/arkGLJournalEntry.type';
import { CapitalCalls } from '../../../utils/types/capitalCalls.type';
import {
  CapCallSummaryCardData,
  CashReceiptTransaction,
} from '../../../utils/types/cashReceipt.type';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import { DetailsType, LoadingStatus } from '../../../utils/types/form.type';
import { LedgerType } from '../../../utils/types/glSetup.type';
import {
  CustomType,
  DataGridColDef,
  ImageItem,
} from '../../../utils/types/listItems';
import { ScopeRole } from '../../../utils/types/user.type';
import {
  GET_ENTITY_LIST_ERROR,
  GET_FUND_LIST_ERROR,
  GET_JOURNAL_ENTRY_ERROR,
  GET_LEDGER_LIST_ERROR,
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import { ViewType } from '../../generalMailings/mailingsComposerPanel/MailingsComposerPanel.constants';
import {
  BulkEditOptions,
  CashReceiptCardTypes,
  csvHeaders,
  PROMPT_ERROR_NOTIFY_ALREADY_NOTIFIED,
  PROMPT_ERROR_NOTIFY_HAS_ADJUSTMENTS,
  SelectedCashReceiptTransaction,
  TOOLTIP_ARKGL_LOCKED,
  TOOLTIP_SETUP_GL,
  TransactionAction,
} from './CashReceipts.constants';
import { defaultHeaderList } from './CashReceipts.defaultHeaders';
import { FormControlCheckbox, LineTextField } from './CashReceipts.styles';

enum CashReceiptTransactionFilter {
  CashReceivedStatus = 'status',
}

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

const initialTransaction: SelectedCashReceiptTransaction = {
  transaction: undefined,
  type: undefined,
};

export const useCashReceipts = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const {
    client,
    fetchClient,
    loading: loadingClient,
  } = useClientEffect(clientId);

  const readonly: boolean = !!isFundAdmin;

  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrder | null>(null);

  const [capitalCallList, setCapitalCallList] = useState<any[]>();
  const [selectedCapitalCall, setSelectedCapitalCall] = useState<any>();
  const [discardChangesCount, setDiscardChangesCount] = useState(0);
  const [transactionsResponse, setTransactionsResponse] = useState<
    CashReceiptTransaction[]
  >([]);
  const [transactionList, setTransactionList] = useState<
    CashReceiptTransaction[]
  >([]);
  const [untouchedTransactionList, setUntouchedTransactionList] = useState<
    CashReceiptTransaction[]
  >([]);
  const [transactionFilteredList, setTransactionFilteredList] = useState<any[]>(
    []
  );
  const [transactionSelectionModel, setTransactionSelectionModel] = useState<
    string[]
  >([]);
  const [capCallSummaryCardData, setCapCallSummaryCardData] =
    useState<CapCallSummaryCardData[]>();
  const [fundCurrencyMap, setFundCurrencyMap] = useState<Map<string, string>>(
    new Map()
  );
  const [currencyCode, setCurrencyCode] = useState<string>('USD');
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] = useState(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [updatedRowsTotal, setUpdatedRowsTotal] = useState<number>(0);
  const [showBulkEdit, setShowBulkEdit] = useState<boolean>(false);
  const [lastDateInput, setLastDateInput] = useState<Date>(new Date());
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [isSendToGLChecked, setIsSendToGLChecked] = useState<boolean>(false);
  const [errorSendToGL, setErrorSendToGL] = useState<boolean>(false);
  const [errorTitleSendToGL, setErrorTitleSendToGL] = useState<string>('');
  const [errorEmptyFields, setErrorEmptyFields] = useState<boolean>(false);
  const [errorNotify, setErrorNotify] = useState<string | undefined>();
  const [errorNotifyActions, setErrorNotifyActions] = useState<any[]>();
  const [noticePostSaveDraft, setNoticePostSaveDraft] =
    useState<boolean>(false);
  const [noticeContinueToNotify, setNoticeContinueToNotify] =
    useState<boolean>(false);
  const [isSendToGLAvailable, setIsSendToGLAvailable] =
    useState<boolean>(false);
  const [isArkGlLocked, setIsArkGlLocked] = useState<boolean>(false);
  const [sendToGLTooltip, setSendToGLTooltip] = useState<string>();
  const [isNotifyChecked, setIsNotifyChecked] = useState<boolean>(true);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();
  const [selectedMailing, setSelectedMailing] = useState<any>();
  const [selectedJEList, setSelectedJEList] =
    useState<SelectedJournalEntry[]>();
  const [entityList, setEntityList] = useState<any[]>([]);
  const [notifyList, setNotifyList] = useState<any[]>([]);
  const [selectedTransaction, setSelectedTransaction] =
    useState<SelectedCashReceiptTransaction>(initialTransaction);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [csvData, setCsvData] = useState<Record<any, any>[]>([]);
  const [csvFilename, setCsvFilename] = useState<string>('');
  const [isCapCallFullyReceived, setIsCapCallFullyReceived] = useState<
    boolean | undefined
  >(false);
  const [capCallDetails, setCapCallDetails] = useState<CapitalCalls>();
  const [showUnsavedEditsPrompt, setShowUnsavedEditsPrompt] =
    useState<boolean>(false);
  const [shouldContinuallyValidate, setShouldContinuallyValidate] =
    useState<boolean>(false);

  const cashReceivedStatusList = [
    {
      id: 'false',
      name: 'No',
    },
    {
      id: 'true',
      name: 'Yes',
    },
  ];

  const selectedCashReceivedStatusList = useMemo(
    () => cashReceivedStatusList?.map((item: any) => item.id),
    [cashReceivedStatusList]
  );

  const fetchEntityList = async (isCanceled?: () => boolean) => {
    setIsLoading(LoadingStatus.Loading);
    try {
      if (isCanceled?.()) return;

      const response = await getEntities();

      setEntityList(response.items);
    } catch (e) {
      informationAlert(GET_ENTITY_LIST_ERROR, 'error');
    }
    setIsLoading(undefined);
  };

  const fetchFundLedgers = async (isCanceled?: () => boolean) => {
    try {
      const response = await getLedgers(selectedCapitalCall.fundId);

      if (isCanceled?.()) return;

      if (
        response.items &&
        !response.items.some(
          (item: any) => item.glPlatform === LedgerType.ARK_LEDGER
        )
      ) {
        setIsSendToGLAvailable(false);
        setIsSendToGLChecked(false);
        setSendToGLTooltip(TOOLTIP_SETUP_GL);
      }
    } catch (e) {
      informationAlert(GET_LEDGER_LIST_ERROR, 'error');
    }
  };

  const fetchFundCurrencyList = async (isCanceled?: () => boolean) => {
    try {
      const response = await getFundsList(clientId);

      if (isCanceled?.()) return;

      const fundCurrencyMap = new Map<string, string>();

      response.forEach((fundItem: any) => {
        fundCurrencyMap.set(fundItem.fund.id, fundItem.fund.currency);
      });

      setFundCurrencyMap(fundCurrencyMap);
    } catch (e) {
      informationAlert(GET_FUND_LIST_ERROR, 'error');
    }
  };

  const fetchCapitalCallList = async (isCanceled?: () => boolean) => {
    try {
      const response = await getCapitalCalls();

      if (isCanceled?.()) return;

      if (response) {
        const list = response
          .filter((c) => c.status === 'PUBLISHED')
          .map((c) => {
            return {
              ...c,
              label: `${c.name} - ${DateTimeFormat.shortDateString(
                c.date as string
              )} - ${c.fundName}`,
            };
          });

        setCapitalCallList(list);
      }
    } catch (e) {
      informationAlert(GET_CAPITAL_CALL_LIST_ERROR, 'error');
    }
  };

  const fetchCapitalCallTransactions = async (isCanceled?: () => boolean) => {
    if (selectedCapitalCall) {
      try {
        const response = await getCapitalCallTransactions(
          selectedCapitalCall.id
        );

        if (isCanceled?.()) return;

        setTransactionsResponse(response);
      } catch (e) {
        informationAlert(GET_CAPITAL_CALL_TRANSACTIONS_ERROR, 'error');
      }
    }
  };

  const fetchCapitalCallDetails = async (isCanceled?: () => boolean) => {
    if (selectedCapitalCall) {
      try {
        const response = await getCapitalCallDetails(selectedCapitalCall.id);

        if (isCanceled?.()) return;

        if (response) {
          setIsCapCallFullyReceived(response.fullyFunded);
          setCapCallDetails(response);
        }
      } catch (e) {
        informationAlert(GET_CAPITAL_CALL_DETAILS_ERROR, 'error');
      }
    }
  };

  useEffectAsync(
    async (isCanceled) => {
      if (!isArkGlLocked) {
        setIsSendToGLAvailable(true);
        setIsSendToGLChecked(true);
      }

      setCurrencyCode(
        fundCurrencyMap?.get(selectedCapitalCall?.fundId) || 'USD'
      );
      if (selectedCapitalCall) {
        if (!isArkGlLocked) {
          await fetchFundLedgers(isCanceled);
        }
        await fetchCapitalCallTransactions(isCanceled);
        await fetchEntityList(isCanceled);
        await fetchCapitalCallDetails(isCanceled);
      }
      setIsDirty(false);
      setUpdatedRowsTotal(0);
      setNotifyList([]);
    },
    [selectedCapitalCall, discardChangesCount]
  );

  useEffect(() => {
    setIsArkGlLocked(client?.arkGlLocked!);

    setIsSendToGLAvailable(!client?.arkGlLocked);
    if (!client?.arkGlLocked) {
      setIsSendToGLChecked(true);
    } else {
      setIsSendToGLChecked(false);
      setSendToGLTooltip(TOOLTIP_ARKGL_LOCKED);
    }
  }, [client]);

  useEffect(() => {
    if (csvData.length) csvLinkRef?.current?.link.click();
  }, [csvData]);

  // useEffect(() => {
  //   initializeHeaderList();
  // }, [columnOrder]);

  useEffectAsync(async (isCanceled) => {
    setIsLoading(LoadingStatus.Loading);
    await fetchCapitalCallList(isCanceled);
    await fetchFundCurrencyList(isCanceled);
    setIsLoading(undefined);
  }, []);

  useEffect(() => {
    initializeHeaderList();
    initializeSearchOptions(transactionList);
  }, [transactionList]);

  useEffect(() => {
    if (transactionList && transactionList.length > 0) {
      const updated = transactionList.find((t) => t.isUpdated);
      const updatedTransactionList = transactionList.filter((t) => t.isUpdated);

      if (updated) {
        setShowBulkEdit(false);
        setIsDirty(true);
        setUpdatedRowsTotal(updatedTransactionList.length);
      } else {
        setIsDirty(false);
        setUpdatedRowsTotal(0);
      }
    }
  }, [transactionList]);

  useEffect(() => {
    if (transactionsResponse && transactionsResponse.length > 0) {
      const processedList = prepRowsForDataGrid(transactionsResponse);

      if (processedList) {
        setTransactionList(processedList);
        setUntouchedTransactionList(processedList);
      }
    }
  }, [transactionsResponse]);

  const prepRowsForDataGrid = (transactions: CashReceiptTransaction[]) => {
    if (transactions && transactions.length > 0) {
      const updatedTransactions: any[] = transactions.map((t) => {
        let value = t.amount - t.amountPaid;
        let adjustment1Amount;
        let adjustment2Amount;

        if (t.adjustments && t.adjustments[0] && t.adjustments[0].amountPaid) {
          value = value - t.adjustments[0].amountPaid;
          adjustment1Amount = t.adjustments[0].amountPaid;
        }

        if (t.adjustments && t.adjustments[1] && t.adjustments[1].amountPaid) {
          value = value - t.adjustments[1].amountPaid;
          adjustment2Amount = t.adjustments[1].amountPaid;
        }

        return {
          ...t,
          remainingDue: value,
          adjustment1Amount,
          adjustment2Amount,
          currency: currencyCode,
          isUpdated: false,
          amountPaid: t.amountPaid === null ? '' : t.amountPaid,
        };
      });

      return updatedTransactions.sort((a, b) =>
        a.remainingDue < b.remainingDue
          ? 1
          : b.remainingDue < a.remainingDue
          ? -1
          : 0
      );
    }
  };

  useEffect(() => {
    if (!transactionList.length) return;

    let totalRemainingDue = 0;
    let totalCapitalCalled = 0;
    let totalAmountPaid = 0;

    transactionList.forEach((t) => {
      totalRemainingDue += t.remainingDue > 0 ? t.remainingDue : 0;
      totalCapitalCalled += t.amount;
      totalAmountPaid += Number(t.amountPaid);

      if (t.adjustments?.length > 0) {
        t.adjustments.forEach((adjustment) => {
          totalAmountPaid += adjustment.amountPaid;
        });
      }
    });

    setCapCallSummaryCardData([
      {
        summaryType: CashReceiptCardTypes.TotalCapitalCalled,
        header: 'Total Capital Called',
        amount: totalCapitalCalled,
        currency: currencyCode,
      },
      {
        summaryType: CashReceiptCardTypes.TotalAmountPaid,
        header: 'Total Amount Paid',
        amount: totalAmountPaid,
        currency: currencyCode,
      },
      {
        summaryType: CashReceiptCardTypes.TotalRemainingDue,
        header: 'Total Remaining Due',
        amount: totalRemainingDue,
        currency: currencyCode,
      },
    ]);
  }, [transactionList]);

  const initializeSearchOptions = (list: any[]) => {
    const investorNameListOptions = Array.from(
      new Set(list.map((transaction) => transaction.investor))
    );
    const options = [...investorNameListOptions].filter((option) => !!option);

    setSearchOptions(Array.from(new Set(options)));
  };

  useEffect(() => {
    let filteredList: any[] | undefined = transactionList;

    headerList?.map((header) => {
      const auxList = filteredList ?? transactionList;

      switch (header.inlineFilterName) {
        case CashReceiptTransactionFilter.CashReceivedStatus:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((transaction) => {
              return header?.inlineFilterSelected?.some((selectedStatus) => {
                return selectedStatus === transaction.cashReceived.toString();
              });
            });
          }
          break;
      }
    });

    setTransactionFilteredList(filteredList);
  }, [headerList, transactionList]);

  useEffect(() => {
    if (search === '') {
      setTransactionFilteredList(transactionList);
    } else {
      const searchText = new RegExp(search, 'i');

      setTransactionFilteredList(
        transactionList.filter((transaction) => {
          return transaction.investor.match(searchText);
        })
      );
    }
  }, [search]);

  const initializeHeaderList = () => {
    const updatedHeaders = [
      {
        field: 'fund',
        headerName: 'Fund Name',
        hide: false,
        index: 1,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 240,
      },
      {
        field: 'investor',
        headerName: 'Investor Name',
        hide: false,
        index: 2,
        type: 'string',
        sortable: true,
        align: 'left' as GridAlignment,
        width: 240,
      },
      {
        field: 'remainingDue',
        headerName: 'Remaining Due',
        hide: false,
        index: 3,
        type: 'number',
        customType: CustomType.NegativeCurrency,
        currencyCodeField: 'currency',
        decimalPlaces: 2,
        sortable: true,
        align: 'right' as GridAlignment,
        width: 160,
      },
      {
        field: 'amount',
        headerName: 'Capital Called',
        hide: false,
        index: 4,
        type: 'number',
        customType: CustomType.PositiveCurrency,
        currencyCodeField: 'currency',
        decimalPlaces: 2,
        sortable: true,
        align: 'right' as GridAlignment,
        width: 200,
      },
      {
        field: 'amountPaid',
        headerName: 'Amount Paid',
        hide: false,
        index: 5,
        sortable: true,
        align: 'right' as GridAlignment,
        width: 200,
        renderCell: (params: any) => {
          const id = params.row.id;
          const value = params.row.amountPaid;
          const field = params.field;
          const isRequired = params.row.isAmountPaidRequired;
          const receiptDate = params.row.receiptDate;
          const journalEntry = params.row.journalEntryId;

          if (readonly || journalEntry) {
            return (
              <StringCell>
                {value ? `${getCurrencySymbol(currencyCode)} ${value}` : ''}
              </StringCell>
            );
          }

          return (
            <LineTextField
              id={`txt_amount_paid_${id}`}
              label=""
              aria-describedby="amount paid"
              placeholder=""
              variant="outlined"
              size="small"
              fullWidth
              isAmountPaid
              value={value}
              onChange={(e) => handleLineEditChange(field, id, e.target.value)}
              error={isDirty && isRequired}
              inputProps={{
                decimalPlaces: 2,
                fixedDecimalScale: true,
                allowNegative: false,
                currency: currencyCode,
              }}
              type="number"
              InputProps={{
                // inputComponent: CurrencyFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {`${getCurrencySymbol(currencyCode)}`}
                  </InputAdornment>
                ),
              }}
            />
          );
        },
      },
      {
        field: 'cashReceived',
        headerName: 'Cash Received',
        hide: false,
        index: 6,
        sortable: true,
        type: 'string',
        align: 'left' as GridAlignment,
        width: 200,
        inlineFilter: true,
        inlineFilterName: CashReceiptTransactionFilter.CashReceivedStatus,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: cashReceivedStatusList,
        inlineFilterSelected: selectedCashReceivedStatusList,
        renderCell: (params: any) => {
          const id = params.row.id;
          const field = params.field;
          const value = params.row.cashReceived;
          const isRequired = params.row.isCashReceivedRequired;
          const journalEntry = params.row.journalEntryId;

          return (
            <FormControlCheckbox error={isDirty && isRequired}>
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    id={`check_cash_received_${id}`}
                    checked={value}
                    value={value}
                    onChange={(e) =>
                      handleLineEditChange(field, id, e.target.checked)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={readonly || journalEntry}
                  />
                }
              />
            </FormControlCheckbox>
          );
        },
      },
      {
        field: 'receiptDate',
        headerName: 'Date of Receipt',
        hide: false,
        index: 7,
        type: 'string',
        sortable: true,
        align: 'right' as GridAlignment,
        width: 220,
        renderCell: (params: any) => {
          const id = params.row.id;
          const field = params.field;
          const value = params.row.receiptDate;
          const isRequired = params.row.isReceiptDateRequired;
          const journalEntry = params.row.journalEntryId;

          if (readonly || journalEntry) {
            return <StringCell>{value}</StringCell>;
          }

          return (
            <DatePicker
              label=""
              value={value || null}
              onChange={(e) => {
                handleLineEditChange(field, id, e);
              }}
              renderInput={(params) => (
                <LineTextField
                  {...params}
                  id={`receipt_date_${id}`}
                  label=""
                  aria-describedby="date"
                  placeholder=""
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={isDirty && isRequired}
                />
              )}
            />
          );
        },
      },
      {
        field: 'notified',
        headerName: 'Notified',
        hide: false,
        index: 8,
        type: 'string',
        sortable: true,
        width: 280,
        renderCell: (params: any) => {
          const value = params.row.notified;

          const status =
            value === false
              ? NotifiedStatusType['NO']
              : NotifiedStatusType['YES'];

          return (
            <StatusLabel
              color={status.color}
              isUpperCase={false}
              label={status.name ?? M_DASH_UNICODE}
            />
          );
        },
      },
      {
        field: 'adjustment1Amount',
        headerName: 'Adjustment 1 Amount',
        hide: false,
        index: 10,
        sortable: false,
        type: 'number',
        customType: CustomType.PositiveCurrency,
        currencyCodeField: 'currency',
        decimalPlaces: 2,
        align: 'right' as GridAlignment,
        width: 200,
        renderCell: (params: any) => {
          const value =
            params.row.adjustments &&
            params.row.adjustments[0] &&
            params.row.adjustments[0].amountPaid;

          return value ? (
            <StringCell>
              {value < 0
                ? `(${CurrencyFormat(
                    currencyCode,
                    FindLengthOfFraction(currencyCode)
                  ).format(Math.abs(value))})`
                : CurrencyFormat(
                    currencyCode,
                    FindLengthOfFraction(currencyCode)
                  ).format(value)}
            </StringCell>
          ) : (
            ''
          );
        },
      },
      {
        field: 'adjustment2Amount',
        headerName: 'Adjustment 2 Amount',
        hide: false,
        index: 10,
        sortable: false,
        type: 'number',
        customType: CustomType.PositiveCurrency,
        currencyCodeField: 'currency',
        decimalPlaces: 2,
        align: 'right' as GridAlignment,
        width: 200,
        renderCell: (params: any) => {
          const value =
            params.row.adjustments &&
            params.row.adjustments[1] &&
            params.row.adjustments[1].amountPaid;

          return value ? (
            <StringCell>
              {value < 0
                ? `(${CurrencyFormat(
                    currencyCode,
                    FindLengthOfFraction(currencyCode)
                  ).format(Math.abs(value))})`
                : CurrencyFormat(
                    currencyCode,
                    FindLengthOfFraction(currencyCode)
                  ).format(value)}
            </StringCell>
          ) : (
            ''
          );
        },
      },
      {
        field: 'journalEntryId',
        headerName: 'Journal Entry',
        hide: false,
        index: 9,
        sortable: false,
        renderCell: (params: any) => {
          const value = params.row.journalEntryId;

          if (value)
            return (
              <ActionLink
                id={`link_journal_entry_id_${params.row.id}`}
                onClick={() => handleOnViewJournalEntry(value)}
              >
                Journal Entry
              </ActionLink>
            );
        },
        type: 'string',
        align: 'center' as GridAlignment,
        width: 170,
      },
      {
        field: 'adjustments[0].journalEntryId',
        headerName: 'Adjustment 1 Journal Entry',
        hide: false,
        index: 10,
        sortable: false,
        renderCell: (params: any) => {
          const value =
            params.row.adjustments &&
            params.row.adjustments[0] &&
            params.row.adjustments[0].journalEntryId;

          if (value)
            return (
              <ActionLink
                id={`link_adjustment1_journal_entry_id_${params.row.id}`}
                onClick={() => handleOnViewJournalEntry(value)}
              >
                Adjustment 1 Journal Entry
              </ActionLink>
            );
        },
        type: 'string',
        align: 'center' as GridAlignment,
        width: 230,
      },
      {
        field: 'adjustments[1].journalEntryId',
        headerName: 'Adjustment 2 Journal Entry',
        hide: false,
        index: 11,
        sortable: false,
        renderCell: (params: any) => {
          const value =
            params.row.adjustments &&
            params.row.adjustments[1] &&
            params.row.adjustments[1].journalEntryId;

          if (value)
            return (
              <ActionLink
                id={`link_adjustment2_journal_entry_id_${params.row.id}`}
                onClick={() => handleOnViewJournalEntry(value)}
              >
                Adjustment 2 Journal Entry
              </ActionLink>
            );
        },
        type: 'string',
        align: 'center' as GridAlignment,
        width: 230,
      },
      {
        field: 'action',
        headerName: '',
        hide: false,
        hideable: false,
        index: 12,
        type: 'action',
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 100,
      },
    ];

    let sortedHeaders: any;

    if (columnOrder && columnOrder.viewItems) {
      columnOrder.viewItems.map((item) => {
        const header = updatedHeaders.find(
          (header: any) => header.field === item.code
        );

        if (header) {
          header.index = item.order;
          header.hide = !item.visible;
        }
      });

      sortedHeaders = updatedHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );

      const activeHeaders = headerList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedHeaders = updatedHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setHeaderList(sortedHeaders);
  };

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }

    // const visiblityUpdate = arrayVisibilityUpdate(
    //   headerList,
    //   clientId,
    //   GL_SETUP_VIEW_KEY,
    //   field
    // );

    // saveColumnOrder(visiblityUpdate);
  };

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const newIndex = params.targetIndex;
    const oldIndex = params.oldIndex;

    // const columnOrderToSave = arrayIndexUpdate(
    //   headerList,
    //   oldIndex - 1,
    //   newIndex - 1,
    //   clientId,
    //   GL_SETUP_VIEW_KEY
    // );

    // saveColumnOrder(columnOrderToSave);
  };

  const handleOnView = (
    transactionId: string,
    transaction: CashReceiptTransaction
  ) => {
    if (isDirty) {
      setShowUnsavedEditsPrompt(true);
      return;
    }
    setSelectedTransaction({
      transaction,
      investor: entityList.find(
        (e) => e.type === 'Investor' && e.name === transaction.investor
      ),
      type: DetailsType.Edit,
    });
  };

  const handleOnViewJournalEntry = async (jounralEntryId: string) => {
    try {
      const response = await getJournalEntry(jounralEntryId);

      setSelectedJournalEntry({
        journalEntry: response,
        type: DetailsType.Edit,
      });
    } catch (e) {
      informationAlert(GET_JOURNAL_ENTRY_ERROR, 'error');
    }
  };

  const onTransactionDetailPanelClose = () => {
    setSelectedTransaction(initialTransaction);
  };

  const handleSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }

    if (newValue !== null) setShowSuggestionPopover(false);
  };

  const handleFilter = (
    filterName: CashReceiptTransactionFilter,
    selected: string[]
  ) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected.length
              ? selected
              : header.inlineFilterOptions?.map((option) => option.id),
          };
        }
        return header;
      })
    );
  };

  const bulkActionOptions: ImageItem[] = useMemo(() => {
    const actions = [];
    const notifyAndSendToGL = {
      id: TransactionAction.NotifyAndSendToGL,
      text: `Notify And Send to GL (${transactionSelectionModel?.length || 0})`,
      icon: (
        <img
          src={PencilIcon}
          alt="Notify And Send to GL Selected"
          height="15"
        />
      ),
      optionsSelected: 0,
    };
    const notify = {
      id: TransactionAction.Notify,
      text: `Notify (${transactionSelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Notify Selected" height="15" />,
      optionsSelected: 0,
    };
    const sendToGL = {
      id: TransactionAction.SendToGL,
      text: `Send to GL (${transactionSelectionModel?.length || 0})`,
      icon: <img src={FileIcon} alt="Send to GL Selected" height="15" />,
      optionsSelected: 0,
    };

    const exportSelected = {
      id: TransactionAction.ExportSelected,
      text: `Export Selected (${transactionSelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: TransactionAction.ExportAll,
      text: 'Export All',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    const openBulkEdit = {
      id: TransactionAction.OpenBulkEdit,
      text: `${showBulkEdit ? 'Close Bulk Edit' : 'Open Bulk Edit'}`,
      icon: <img src={PencilIcon} alt="Bulk Edit" height="15" />,
    };

    if (transactionSelectionModel?.length > 0) {
      if (!readonly) {
        if (isSendToGLAvailable) {
          actions.push(notifyAndSendToGL);
          actions.push(sendToGL);
          actions.push(notify);
        } else {
          actions.push(notify);
        }
      }
      actions.push(exportSelected);
    }

    actions.push(exportAll);

    if (!readonly) {
      actions.push(openBulkEdit);
    }
    return actions;
  }, [transactionSelectionModel, showBulkEdit]);

  const handleBulkAction = (actionId: TransactionAction) => {
    if (isDirty) {
      setShowUnsavedEditsPrompt(true);
      return;
    }
    const list: any[] = [];
    let emptyRequiredFieldsError: boolean = false;
    let hasAdjustmentsError: boolean = false;
    let alreadyNotifiedError: boolean = false;
    let hasMainJournalEntryError: boolean = false;
    const notNotifiedList: any[] = [];

    transactionSelectionModel.map((transactionId) => {
      list.push(transactionList.find((t) => t.id === transactionId));
    });

    list.forEach((t) => {
      if (!t.amountPaid || !t.receiptDate || t.cashReceived !== true) {
        emptyRequiredFieldsError = true;
      }

      if (t.journalEntryId) {
        hasMainJournalEntryError = true;
      }

      if (
        t.adjustments &&
        ((t.adjustments[0] && t.adjustments[0].amountPaid) ||
          (t.adjustments[1] && t.adjustments[1].amountPaid))
      ) {
        hasAdjustmentsError = true;
      } else {
        if (t.notified) {
          alreadyNotifiedError = true;
        } else {
          notNotifiedList.push(t);
        }
      }
    });

    setNotifyList(notNotifiedList);

    switch (actionId) {
      case TransactionAction.NotifyAndSendToGL:
        if (emptyRequiredFieldsError) {
          setErrorEmptyFields(true);
          return;
        } else {
          setErrorTitleSendToGL('Notify and Send to GL');

          if (hasAdjustmentsError || hasMainJournalEntryError) {
            setErrorSendToGL(true);
            return;
          }

          if (alreadyNotifiedError) {
            const actionList = [];

            if (notNotifiedList.length > 0) {
              actionList.push(
                {
                  label: `Proceed with notifying the ${
                    notNotifiedList.length
                  } unsent Cash Receipt${
                    notNotifiedList.length > 1 ? 's' : ''
                  }`,
                  onClick: () => {
                    setErrorNotify(undefined);

                    sendToJournalEntryDetails(list);
                    setIsNotifyChecked(true);
                  },
                  id: 'btn_notify_rest',
                  variant: 'contained',
                  color: 'primary',
                },
                {
                  label: 'Cancel',
                  onClick: () => setErrorNotify(undefined),
                  id: 'btn_cancel_error_notify',
                  variant: 'outlined',
                  color: 'error',
                }
              );
            } else {
              actionList.push({
                label: 'OK',
                onClick: () => setErrorNotify(undefined),
                id: 'btn_cancel_error_notify',
                variant: 'contained',
                color: 'primary',
              });
            }

            setErrorNotifyActions(actionList);
            if (notNotifiedList.length === 0) {
              setErrorNotify(
                'All selected Cash Receipt entries have already been notified. Please be aware that each Cash Receipt notification can only be sent once per original entry.'
              );
            } else {
              setErrorNotify(PROMPT_ERROR_NOTIFY_ALREADY_NOTIFIED);
            }

            return;
          }

          sendToJournalEntryDetails(list);
          setIsNotifyChecked(true);
        }
        break;
      case TransactionAction.Notify:
        if (emptyRequiredFieldsError) {
          setErrorEmptyFields(true);
          return;
        } else {
          if (hasAdjustmentsError) {
            setErrorNotifyActions([
              {
                label: 'OK',
                onClick: () => setErrorNotify(undefined),
                id: 'btn_close_error_notify_adj',
                variant: 'contained',
                color: 'primary',
              },
            ]);
            setErrorNotify(PROMPT_ERROR_NOTIFY_HAS_ADJUSTMENTS);

            return;
          }
          if (alreadyNotifiedError) {
            const actionList = [];

            if (notNotifiedList.length > 0) {
              actionList.push(
                {
                  label: `Proceed with notifying the ${
                    notNotifiedList.length
                  } unsent Cash Receipt${
                    notNotifiedList.length > 1 ? 's' : ''
                  }`,
                  onClick: () => notifyRest(),
                  id: 'btn_notify_rest',
                  variant: 'contained',
                  color: 'primary',
                },
                {
                  label: 'Cancel',
                  onClick: () => setErrorNotify(undefined),
                  id: 'btn_cancel_error_notify',
                  variant: 'outlined',
                  color: 'error',
                }
              );
            } else {
              actionList.push({
                label: 'OK',
                onClick: () => setErrorNotify(undefined),
                id: 'btn_cancel_error_notify',
                variant: 'contained',
                color: 'primary',
              });
            }

            setErrorNotifyActions(actionList);
            if (notNotifiedList.length === 0) {
              setErrorNotify(
                'All selected Cash Receipt entries have already been notified. Please be aware that each Cash Receipt notification can only be sent once per original entry.'
              );
            } else {
              setErrorNotify(PROMPT_ERROR_NOTIFY_ALREADY_NOTIFIED);
            }

            return;
          }
          notify(list);
        }
        break;
      case TransactionAction.SendToGL:
        if (emptyRequiredFieldsError) {
          setErrorEmptyFields(true);
          return;
        } else {
          setErrorTitleSendToGL('Send to GL');
          if (hasAdjustmentsError || hasMainJournalEntryError) {
            setErrorSendToGL(true);
            return;
          }

          sendToJournalEntryDetails(list);
        }
        break;
      case TransactionAction.ExportAll:
        handleExportCsv(actionId);
        break;
      case TransactionAction.ExportSelected:
        handleExportCsv(actionId);
        break;
      case TransactionAction.OpenBulkEdit:
        setShowBulkEdit(!showBulkEdit);
        break;
    }
  };

  const handleBulkEditChange = (selectedOption: string) => {
    switch (selectedOption) {
      case BulkEditOptions.KeepExisting:
        handleLineEditChange(
          'receiptDate',
          transactionSelectionModel,
          lastDateInput
        );

        break;
      case BulkEditOptions.CashReceived:
        handleLineEditChange('cashReceived', transactionSelectionModel, true);

        break;
      case BulkEditOptions.UncheckCashReceived:
        handleLineEditChange('cashReceived', transactionSelectionModel, false);

        break;
      default:
        break;
    }
  };

  const handleLineEditChange = (field: string, id: string[], value: any) => {
    const list = [...transactionList].map((t) => {
      if (id.includes(t.id)) {
        const untouchedTransaction = untouchedTransactionList.find(
          (untouchedTransaction) => untouchedTransaction.id === t.id
        );

        if (field === 'receiptDate' && DateTimeFormat.isValidDate(value))
          setLastDateInput(value as Date);

        let currentTransaction: any = { ...t };

        currentTransaction[field] = value;

        if (field === 'cashReceived') {
          if (value === true) {
            if (!currentTransaction.amountPaid) {
              currentTransaction.amountPaid = currentTransaction.amount;
            }
            if (!currentTransaction.receiptDate) {
              currentTransaction.receiptDate = lastDateInput;
            }
          } else {
            currentTransaction.amountPaid = '';
            currentTransaction.receiptDate = null;
          }
        }

        if (
          untouchedTransaction &&
          currentTransaction.amountPaid === untouchedTransaction.amountPaid &&
          currentTransaction.cashReceived ===
            untouchedTransaction.cashReceived &&
          areDateOrNullValuesEqual(
            currentTransaction.receiptDate,
            untouchedTransaction.receiptDate
          )
        ) {
          currentTransaction.isUpdated = false;
          currentTransaction.isAmountPaidRequired = false;
          currentTransaction.isCashReceivedRequired = false;
          currentTransaction.isReceiptDateRequired = false;
        } else {
          currentTransaction.isUpdated = true;
        }

        if (shouldContinuallyValidate) {
          currentTransaction = validateUpdatedTransaction(currentTransaction);
        }

        return currentTransaction;
      } else {
        return { ...t };
      }
    });

    setTransactionList(list);
  };

  const validateUpdatedTransaction = (t: CashReceiptTransaction) => {
    if (t.isUpdated) {
      const allInputsAreEmpty = !Boolean(
        t.cashReceived || t.amountPaid || t.receiptDate
      );

      return {
        ...t,
        isAmountPaidRequired: !(
          allInputsAreEmpty ||
          (t.amountPaid && t.amountPaid > 0)
        ),
        isCashReceivedRequired: !(allInputsAreEmpty || t.cashReceived),
        isReceiptDateRequired: !(
          allInputsAreEmpty || DateTimeFormat.isValidDate(t.receiptDate)
        ),
      };
    } else {
      return t;
    }
  };

  const handleBulkSave = () => {
    let emptyRequiredFields = false;
    //MVP-7938 update to prevent send-to-GL & Notify for cashReceived === false transactions
    let hasCashReceivedTransactions = false;

    const showRequiredFields = [...transactionList].map((t) => {
      if (t.isUpdated) {
        const validatedTransaction = validateUpdatedTransaction(t);

        if (
          validatedTransaction.isAmountPaidRequired ||
          validatedTransaction.isCashReceivedRequired ||
          validatedTransaction.isReceiptDateRequired
        )
          emptyRequiredFields = true;
        if (t.cashReceived) hasCashReceivedTransactions = true;

        return validateUpdatedTransaction(t);
      } else {
        return { ...t };
      }
    });

    if (emptyRequiredFields) {
      setShouldContinuallyValidate(true);
      informationAlert(EMPTY_REQUIRED_FIELDS_ERROR, 'error');
      setTransactionList(showRequiredFields);
      return;
    }

    if (hasCashReceivedTransactions) {
      setShowSaveConfirmation(true);
    } else {
      bulkSave();
    }
  };

  const handleBulkDiscardChanges = () => {
    setDiscardChangesCount((prev) => prev + 1);
  };

  const bulkSave = async () => {
    try {
      setIsLoading(LoadingStatus.Updating);
      const request: any = {};

      request.receivableTransactionUpdates = [];

      transactionList.map((t) => {
        if (t.isUpdated) {
          request.receivableTransactionUpdates.push({
            transactionId: t.id,
            amountPaid: t.amountPaid ? t.amountPaid : null,
            cashReceived: t.cashReceived,
            receiptDate: t.receiptDate
              ? DateTimeFormat.shortDate(t.receiptDate)
              : null,
          });
        }
      });

      await saveCapitalCallTransactions(selectedCapitalCall.id, request);

      informationAlert(SAVE_CAPITAL_CALL_LIST_SUCCESS, 'success');
      setIsDirty(false);
      setUpdatedRowsTotal(0);

      fetchCapitalCallTransactions();
    } catch (e) {
      informationAlert(SAVE_CAPITAL_CALL_LIST_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const saveCancel = () => {
    setShowSaveConfirmation(false);
    setIsNotifyChecked(true);
  };

  const saveConfirm = () => {
    setShowSaveConfirmation(false);

    bulkSave();

    const updatedRows: CashReceiptTransaction[] = [];

    transactionList.map((t) => {
      if (t.isUpdated && t.cashReceived) {
        //MVP-7938 update to prevent send-to-GL & Notify for cashReceived === false transactions
        updatedRows.push({
          ...t,
        });
      }
    });

    if (isSendToGLChecked) {
      sendToJournalEntryDetails(updatedRows);
    }

    if (isNotifyChecked) {
      setNotifyList(updatedRows);
    }

    if (!isSendToGLChecked && isNotifyChecked) {
      notify(updatedRows);
    }
  };

  const onJournalEntryPanelClose = (isCanceled: boolean = false) => {
    setSelectedJournalEntry(initialJournalEntry);
    setSelectedJEList(undefined);
    if (isNotifyChecked && isCanceled) {
      setNoticeContinueToNotify(true);
    }
    fetchCapitalCallTransactions();
  };

  const onJournalEntryPostClose = () => {
    if (isNotifyChecked) {
      notify(notifyList);
    }
  };

  const continueToNotify = () => {
    setNoticeContinueToNotify(false);
    notify(notifyList);
  };

  const onMailingDraftPostClose = () => {
    setNoticePostSaveDraft(true);
  };

  const sendToJournalEntryDetails = (dataList: any[]) => {
    let date: any;
    let isDifferentDates = false;

    dataList.map((t: any) => {
      if (date && date !== DateTimeFormat.shortDate(t.receiptDate)) {
        isDifferentDates = true;
        return;
      }
      date = DateTimeFormat.shortDate(t.receiptDate);
    });

    if (isDifferentDates) {
      setErrorSendToGL(true);
      setErrorTitleSendToGL('Send to GL');
      return;
    }

    let totalAmount: number = 0;

    const lineItems: any[] = [];
    const transactionIds: string[] = [];

    dataList.map((t: any) => {
      totalAmount = totalAmount + Number(t.amountPaid);
      transactionIds.push(t.id);

      lineItems.push({
        ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
        entities: [
          entityList.find(
            (e) => e.type === 'Investor' && e.name === t.investor
          ),
        ],
        entryType: 'DEBIT',
        amount: Number(t.amountPaid),
      });
    });

    lineItems.push({
      ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
      entities: [],
      entryType: 'CREDIT',
      amount: totalAmount,
    });

    setSelectedJournalEntry({
      journalEntry: {
        ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
        fundId: selectedCapitalCall.fundId,
        lineItems: lineItems,
        date: date,
        memo: selectedCapitalCall.name,
      },
      type: DetailsType.New,
      connectCashReceiptToJournalEntry: {
        cashReceiptTransactionIds: transactionIds,
      },
      singleView: true,
    });
  };

  const notify = (dataList: any[]) => {
    const investors: any[] = [];
    const transactionIds: any[] = [];

    dataList.map((t) => {
      transactionIds.push(t.id);
      const investor: any = entityList.find(
        (e) => e.type === 'Investor' && e.name === t.investor
      );

      if (investor) investors.push(investor.id);
    });

    setSelectedMailing({
      view: ViewType.CashReceipts,
      funds: [selectedCapitalCall.fundId],
      investors: investors,
      roles: [selectedCapitalCall.id],
      receivableId: selectedCapitalCall.id,
      transactionIds: transactionIds,
      data: dataList,
    });

    setNotifyList([]);
    setIsNotifyChecked(true);
  };

  const notifyRest = () => {
    const list = transactionSelectionModel
      .map((transactionId) => {
        const t = transactionList.find((t) => t.id === transactionId);

        if (t && t.notified === false) {
          return t;
        }
      })
      .filter((v) => v !== undefined);

    notify(list);

    setErrorNotify(undefined);
  };

  const handleExportCsv = (actionId: string) => {
    let csvExportData;

    switch (actionId) {
      case TransactionAction.ExportSelected:
        csvExportData = transactionSelectionModel.map((selectedId) => {
          const transaction = transactionFilteredList.find(
            (e) => e.id === selectedId
          ) as any;

          return {
            fund: transaction?.fund,
            investor: transaction?.investor,
            remainingDue: transaction.remainingDue,
            amount: transaction?.amount,
            amountPaid: transaction?.amountPaid,
            cashReceived: transaction?.cashReceived ? 'Y' : 'N',
            receiptDate: transaction?.receiptDate,
            notified: transaction?.notified ? 'Y' : 'N',
            adjustment1Amount:
              transaction.adjustments &&
              transaction.adjustments[0] &&
              transaction.adjustments[0].amountPaid,
            adjustment2Amount:
              transaction.adjustments &&
              transaction.adjustments[1] &&
              transaction.adjustments[1].amountPaid,
          };
        });
        break;
      case TransactionAction.ExportAll:
        csvExportData = transactionFilteredList.map((transaction) => {
          return {
            fund: transaction.fund,
            investor: transaction.investor,
            remainingDue: transaction.remainingDue,
            amount: transaction.amount,
            amountPaid: transaction.amountPaid,
            cashReceived: transaction.cashReceived ? 'Y' : 'N',
            receiptDate: transaction.receiptDate,
            notified: transaction.notified ? 'Y' : 'N',
            adjustment1Amount:
              transaction.adjustments &&
              transaction.adjustments[0] &&
              transaction.adjustments[0].amountPaid,
            adjustment2Amount:
              transaction.adjustments &&
              transaction.adjustments[1] &&
              transaction.adjustments[1].amountPaid,
          };
        });
        break;
    }

    if (csvExportData) {
      setCsvFilename(
        `Cash_Receipts_${DateTimeFormat.getFormattedDate(new Date(), '_')}`
      );
      setCsvData(csvExportData);
    }
  };

  const resetCsvData = () => {
    setCsvData([]);
    setCsvFilename('');
  };

  const toggleFullyReceived = async (e: any) => {
    setIsCapCallFullyReceived(e.target.checked);

    if (capCallDetails) {
      await updateCapitalCall(capCallDetails.id!, {
        ...capCallDetails,
        fullyFunded: e.target.checked,
      });
    }
  };

  const getRowClassName = (params: GridRowClassNameParams): string => {
    const highlight = params.row.isUpdated;

    return highlight ? 'highlight-row' : '';
  };

  return {
    isLoading,
    isDirty,
    showBulkEdit,
    lastDateInput,
    setLastDateInput,
    capitalCallList,
    selectedCapitalCall,
    setSelectedCapitalCall,
    headerList,
    activeHeaderFields,
    transactionFilteredList,
    transactionSelectionModel,
    setTransactionSelectionModel,
    capCallSummaryCardData,
    readonly,
    search,
    searchOptions,
    handleSearch,
    handleUpdateHeader,
    onColumnOrderChange,
    handleFilter,
    showSuggestionPopover,
    setShowSuggestionPopover,
    bulkActionOptions,
    handleBulkAction,
    handleBulkSave,
    handleBulkDiscardChanges,
    handleBulkEditChange,
    showSaveConfirmation,
    saveCancel,
    saveConfirm,
    isSendToGLAvailable,
    isSendToGLChecked,
    isNotifyChecked,
    setIsNotifyChecked,
    setIsSendToGLChecked,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
    errorSendToGL,
    setErrorSendToGL,
    errorTitleSendToGL,
    setErrorTitleSendToGL,
    errorEmptyFields,
    setErrorEmptyFields,
    selectedMailing,
    setSelectedMailing,
    onJournalEntryPostClose,
    onTransactionDetailPanelClose,
    handleOnView,
    setNotifyList,
    selectedTransaction,
    fetchCapitalCallTransactions,
    notify,
    setSelectedJEList,
    selectedJEList,
    setErrorNotify,
    errorNotify,
    errorNotifyActions,
    notifyRest,
    notifyList,
    noticePostSaveDraft,
    setNoticePostSaveDraft,
    onMailingDraftPostClose,
    continueToNotify,
    noticeContinueToNotify,
    setNoticeContinueToNotify,
    toggleFullyReceived,
    isCapCallFullyReceived,
    sendToGLTooltip,
    getRowClassName,
    updatedRowsTotal,
    setShowUnsavedEditsPrompt,
    showUnsavedEditsPrompt,
  };
};
