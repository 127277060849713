import { useTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts';
import { AxisValueFormatterContext } from '@mui/x-charts/models/axis';
import { FC } from 'react';

import { SoiSummaryItem } from '../../../utils/types/visualDashboard.type';
import { LegendColorBox } from '../ChartLegend/ChartLegend.styles';
import { chartsTooltipValueFormatter, getColor } from '../helpers/Chart.utils';
import { PortfolioCompanyBarChartStyled } from './PortfolioCompanyBarChart.styles';
import {
  BarChartHeaderBox,
  BarChartLegendBox,
  barChartParams,
  BarChartTitle,
  chartStyles,
  LegendItem,
} from './TotalValueAndDistributionsBarChart.styles';

type PortfolioCompanyBarChartProps = {
  soiSummaries: SoiSummaryItem[];
  currency: string;
  type: string;
  pageType: string;
};

interface CountryCount {
  country: string;
  count: number;
}

interface TopUnrealizedPortCo {
  companyName: string;
  fairMarketValue: number;
}

interface TopRealizedPortCo {
  companyName: string;
  realizedValue: number;
}

interface TopDashboardPortCo {
  companyName: string;
  value: number;
}

export const PortfolioCompanyBarChart: FC<PortfolioCompanyBarChartProps> = ({
  soiSummaries,
  currency,
  type,
  pageType,
}) => {
  let title: string = '';
  let legends: string[] = [];
  let series: any[] = [];
  let xLabels: string[] = [];
  let yAxis: any[] = [];
  let colors: string[] = [];

  const theme = useTheme();

  function countByCountry(summaryItems: SoiSummaryItem[]): CountryCount[] {
    const countryCountMap: { [country: string]: number } = {};
    const seenPortfolioCompanies = new Set<string>();

    summaryItems.forEach(({ portfolioCompanyId, categoryProps }) => {
      const country = categoryProps?.country || 'None';

      if (
        portfolioCompanyId &&
        !seenPortfolioCompanies.has(portfolioCompanyId)
      ) {
        seenPortfolioCompanies.add(portfolioCompanyId);
        countryCountMap[country] = (countryCountMap[country] || 0) + 1;
      }
    });

    return Object.keys(countryCountMap)
      .map((country) => ({
        country,
        count: countryCountMap[country],
      }))
      .sort((a, b) => {
        if (b.count !== a.count) {
          return b.count - a.count;
        }
        return a.country.localeCompare(b.country);
      });
  }

  function getTopUnrealizedPortfolioCompaniesList(
    soiSummaries: SoiSummaryItem[]
  ): TopUnrealizedPortCo[] {
    const grouped = soiSummaries
      .filter(
        (item) =>
          item.categoryProps?.companyName !== undefined &&
          item.dataProps?.fairMarketValue !== undefined
      )
      .reduce((acc, item) => {
        const companyName = item.categoryProps?.companyName || 'Unknown';
        const fairMarketValue = item.dataProps?.fairMarketValue || 0;

        if (acc[companyName]) {
          acc[companyName].fairMarketValue += fairMarketValue;
        } else {
          acc[companyName] = { companyName, fairMarketValue };
        }

        return acc;
      }, {} as Record<string, { companyName: string; fairMarketValue: number }>);

    return Object.values(grouped).sort((a, b) => {
      if (b.fairMarketValue === a.fairMarketValue) {
        return a.companyName.localeCompare(b.companyName);
      }
      return b.fairMarketValue - a.fairMarketValue;
    });
  }

  function getTopRealizedPortfolioCompaniesList(
    soiSummaries: SoiSummaryItem[]
  ): TopRealizedPortCo[] {
    const grouped = soiSummaries
      .filter(
        (item) =>
          item.categoryProps?.companyName !== undefined &&
          item.dataProps?.realizedValue !== undefined
      )
      .reduce((acc, item) => {
        const companyName = item.categoryProps?.companyName || 'Unknown';
        const realizedValue = item.dataProps?.realizedValue || 0;

        if (acc[companyName]) {
          acc[companyName].realizedValue += realizedValue;
        } else {
          acc[companyName] = { companyName, realizedValue };
        }

        return acc;
      }, {} as Record<string, { companyName: string; realizedValue: number }>);

    return Object.values(grouped).sort((a, b) => {
      if (b.realizedValue === a.realizedValue) {
        return a.companyName.localeCompare(b.companyName);
      }
      return b.realizedValue - a.realizedValue;
    });
  }

  function getTopDashboardPortfolioCompaniesList(
    items: SoiSummaryItem[]
  ): TopDashboardPortCo[] {
    const companyMap = new Map<
      string,
      { realizedValue: number; fairMarketValue: number }
    >();

    items.forEach((item) => {
      const companyName = item.categoryProps?.companyName ?? 'Unknown';
      const realizedValue = item.dataProps?.realizedValue ?? 0;
      const fairMarketValue = item.dataProps?.fairMarketValue ?? 0;

      const existingEntry = companyMap.get(companyName) || {
        realizedValue: 0,
        fairMarketValue: 0,
      };

      if (item.soiPageView === 'Realized_SOI') {
        existingEntry.realizedValue += realizedValue;
      } else if (item.soiPageView === 'Unrealized_SOI') {
        existingEntry.fairMarketValue += fairMarketValue;
      }

      companyMap.set(companyName, existingEntry);
    });

    return Array.from(companyMap.entries())
      .map(([companyName, entry]) => ({
        companyName,
        value: entry.realizedValue + entry.fairMarketValue,
      }))
      .sort((a, b) => {
        if (b.value !== a.value) {
          return b.value - a.value;
        }
        return a.companyName.localeCompare(b.companyName);
      });
  }

  function getColors(amount: number) {
    const colorList: string[] = [];

    for (let i = 0; i < amount; i++) {
      colorList.push(getColor(i));
    }

    return colorList;
  }

  function getFormattedYaxisVal(
    val: number,
    _context: AxisValueFormatterContext
  ) {
    const totalStr = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'currency',
      currency: currency,
    }).format(val);

    return totalStr;
  }

  switch (type) {
    case 'portfolioCompanyByLocation':
      title = 'Portfolio Companies by Location';
      const countData = countByCountry(soiSummaries);

      series = [
        {
          data: countData.map((item) => item.count),
        },
      ];
      colors = getColors(countData.length);
      xLabels = countData.map((item) => item.country);
      yAxis = [
        {
          valueFormatter: (value: number) =>
            Number.isInteger(value) ? value.toString() : '',
        },
      ];
      legends = [];
      break;
    case 'topPortfolioCompanies':
      switch (pageType) {
        case 'SCHEDULE_OF_INVESTMENTS_UNREALIZED':
          let topUnrealizedData =
            getTopUnrealizedPortfolioCompaniesList(soiSummaries);

          title =
            topUnrealizedData.length > 10
              ? 'Top 10 Portfolio Companies Unrealized'
              : 'Top Portfolio Companies Unrealized';

          if (topUnrealizedData.length > 10)
            topUnrealizedData = topUnrealizedData.slice(0, 10);
          series = [
            {
              data: topUnrealizedData.map((item) => item.fairMarketValue),
              valueFormatter: (value: number) =>
                chartsTooltipValueFormatter(value, currency),
            },
          ];
          colors = getColors(topUnrealizedData.length);
          xLabels = topUnrealizedData.map((item) => item.companyName);
          yAxis = [{ valueFormatter: getFormattedYaxisVal }];
          legends = [];
          break;
        case 'SCHEDULE_OF_INVESTMENTS_REALIZED':
          let topRealizedData =
            getTopRealizedPortfolioCompaniesList(soiSummaries);

          title =
            topRealizedData.length > 10
              ? 'Top 10 Portfolio Companies Realized'
              : 'Top Portfolio Companies Realized';

          if (topRealizedData.length > 10)
            topRealizedData = topRealizedData.slice(0, 10);
          series = [
            {
              data: topRealizedData.map((item) => item.realizedValue),
              valueFormatter: (value: number) =>
                chartsTooltipValueFormatter(value, currency),
            },
          ];
          colors = getColors(topRealizedData.length);
          xLabels = topRealizedData.map((item) => item.companyName);
          yAxis = [{ valueFormatter: getFormattedYaxisVal }];
          legends = [];
          break;
        case 'SCHEDULE_OF_INVESTMENTS_DASHBOARD':
          let topDashboardData =
            getTopDashboardPortfolioCompaniesList(soiSummaries);

          title =
            topDashboardData.length > 10
              ? 'Top 10 Portfolio Companies'
              : 'Top Portfolio Companies';

          if (topDashboardData.length > 10) {
            topDashboardData = topDashboardData.slice(0, 10);
          }
          series = [
            {
              data: topDashboardData.map((item) => item.value),
              valueFormatter: (value: number) =>
                chartsTooltipValueFormatter(value, currency),
            },
          ];
          colors = getColors(topDashboardData.length);
          xLabels = topDashboardData.map((item) => item.companyName);
          yAxis = [{ valueFormatter: getFormattedYaxisVal }];
          legends = [];
          break;
      }
      break;
  }

  return (
    <PortfolioCompanyBarChartStyled id="bar_chart">
      <BarChartHeaderBox>
        <BarChartTitle>{title}</BarChartTitle>
      </BarChartHeaderBox>
      <BarChart
        height={270}
        series={series}
        xAxis={[
          {
            data: xLabels,
            scaleType: 'band',
            colorMap: {
              type: 'ordinal',
              colors: colors,
            },
          },
        ]}
        yAxis={yAxis}
        slotProps={{
          legend: { hidden: true },
          popper: {
            sx: chartStyles.popper,
          },
        }}
        {...barChartParams}
      />
    </PortfolioCompanyBarChartStyled>
  );
};
