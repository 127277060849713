import AddIcon from "@mui/icons-material/Add";
import { FormGroup, InputLabel } from '@mui/material';
import React, { ReactElement, useEffect, useReducer, useState } from 'react';

import { LoadingStatus } from '../../../utils/types/form.type';
import { FilterItem } from '../../../utils/types/listItems';
import ChipsCell from '../../DataGrid/ChipsCell/ChipsCell';
import {
  FilterCheckBox,
  FilterControlLabel,
  FilterOption,
} from '../../DataGrid/Filter/Filter.style';
import SearchBar from '../../SearchBarWithDebounce/SearchBar';
import {
  ChipBox,
  CreateNewButton,
  Dropdown,
  FilterBox,
  OptionList,
  TypeLabel,
} from './JEEntityFilter.styles';

type Props = {
  error?: any;
  value?: any | null | string;
  setValue: Function;
  setValueField: string;
  onChange?: (entity: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
  readonly?: boolean;
  loading?: LoadingStatus;
  options: any[];
  onDropdownClose?: () => void;
  handleCreateNewMemo?: Function;
};

type OptionItemProps = {
  id: string;
  label: string;
  onSelect: (item: FilterItem, checked: boolean) => void;
  selected: boolean;
};

const OptionItem: React.FC<OptionItemProps> = ({
  id,
  label,
  onSelect,
  selected = false,
}: OptionItemProps): ReactElement => {
  const onCheck = (e: any): void => {
    const isChecked = e.target.checked;

    onSelect(
      {
        id,
        name: label,
      },
      isChecked
    );
  };

  return (
    <FilterOption>
      <FilterControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
          />
        }
        label={label}
      />
    </FilterOption>
  );
};

export const JEEntityFilter = ({
  onChange,
  value,
  setValue,
  setValueField,
  id = 'entity_filter',
  readonly = false,
  options,
  error,
  placeholder,
  onDropdownClose,
  handleCreateNewMemo
}: Props) => {
  const [search, setSearch] = useState<string>('');
  const [optionList, setOptionList] = useState<Record<string, any>[]>([]);
  const [completeOptionList, setCompleteOptionList] = useState<Record<string, any>[]>([]);
  const [open, setOpen] = useState(false);

  const entityTypes = new Set();

  useEffect(() => {
    if (search === '') {
      setOptionList(completeOptionList);
    } else {
      const escapeRegex = (string: string) => {
        return string.replace(/(?=\W)/g, '\\');
      };

      const searchText = new RegExp(escapeRegex(search), 'ig');

      const listFilter = optionList.filter((item) => item.name.search(searchText) !== -1);

      setOptionList(listFilter);
    }
  }, [search]);

  const onSelectChange = (entity: any, isSelected: boolean) => {
    if (isSelected) {
      if (value.length < 10) {
        onChange?.(value.push({ id: entity.id, name: entity.name }));
      }
    } else {
      const updatedList = value.filter(
        (option: any) => option.id !== entity.id
      );

      onChange?.(updatedList);
      setValue(setValueField, updatedList);
    }
    setOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const setOptions = () => {
    setOptionList([
      {
        id: '+',
        name: 'Create New Memo Entity',
        type: 'Memo Entity'
      },
      ...options
    ]);
    setCompleteOptionList([
      {
        id: '+',
        name: 'Create New Memo Entity',
        type: 'Memo Entity'
      },
      ...options
    ]);
  };

  const handleOpen = () => {
    if (!open) setOpen(true);
    setOptions();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FilterBox error={error} onClick={handleOpen}>
      {value && value.length > 0 && (
        <ChipBox>
          <ChipsCell
            items={value.map((v: any) => v.name)}
            chipLabelField={''}
            chipIDField={''}
            chipCount={2}
          />
        </ChipBox>
      )}
      {!readonly && (
        <>
          <Dropdown
            id={id}
            value={''}
            onChange={() => { }}
            defaultValue=""
            displayEmpty
            disableUnderline
            variant="standard"
            open={open}
            renderValue={() => {
              return <>{placeholder}</>;
            }}
            onClose={handleClose}
          >
            <>
              <SearchBar
                id="filter_search_entity"
                onChange={handleSearch}
                value={search}
              />
              <FormGroup>
                <OptionList>
                  {(
                    optionList.map((option) => (
                      <>
                        {!entityTypes.has(option.type) &&
                          entityTypes.add(option.type) && (
                            <>
                              <TypeLabel variant="label">
                                {option.type}
                              </TypeLabel>
                            </>
                          )}

                        {(option.id === '+') && (
                          <CreateNewButton
                            id="btn_create_new_memo_entity"
                            variant="text"
                            disableElevation
                            onClick={() => {
                              handleCreateNewMemo?.();
                              handleClose();
                            }}
                            startIcon={<AddIcon />}
                          >
                            {option.name}
                          </CreateNewButton>

                        )}
                        {(option.id !== '+') && (
                          <OptionItem
                            key={option.id}
                            label={option.name}
                            id={option.id}
                            onSelect={onSelectChange}
                            selected={value.some((e: any) => option.id === e.id)}
                          />
                        )}
                      </>
                    ))
                  )}
                </OptionList>
              </FormGroup>
            </>
          </Dropdown>
        </>
      )}
    </FilterBox>
  );
};

export default JEEntityFilter;
