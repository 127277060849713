import { List, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { Team } from "../../../../../utils/types/team.type";
import { TeamMember } from "../../../../../utils/types/user.type";
import ClientCard from "../ClientCard";
import TeamsListRow from "./TeamsListRow";

interface Props {
    teamsList?: Team[];
    onTeamDeselect: (teamId: string) => void,
    onTeamSelect: (team: Team) => void,
    teamMembers?: TeamMember[];
}

const TeamsList: React.FC<Props> = ({
  teamsList,
  onTeamDeselect,
  onTeamSelect,
  teamMembers
}: Props): ReactElement => {
  return (
    <ClientCard title="Teams">
      <List>
        {teamsList && teamsList?.length > 0 ? teamsList?.map((team) => (
          <TeamsListRow
            key={team.teamId}
            team={team}
            onTeamDeselect={onTeamDeselect}
            teamMembers={teamMembers}
            onTeamSelect={onTeamSelect}/>
        )) : (
          <Typography align="center">No Client Selected</Typography>
        )}
      </List>
    </ClientCard>
  );
};

export default TeamsList;
