import { CountryItem } from '../utils/types/listItems';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

export const getCountryList = async () => {
    const countryList = await apiClient().get<CountryItem[]>('countries');

    return countryList;
};

export const getStateList = async (countryCode: string) => {
    const countryList = await apiClient().get<CountryItem[]>(`countries/${countryCode}/territories`);

    return countryList;
};