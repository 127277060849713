import { NameIdPair } from "../utils/types/transaction.type";
import { UploadHistory } from "../utils/types/uploadHistory.type";
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

export const getHistory = async () => await apiClient().get<UploadHistory[]>('uploadAudits');

export const getStatuses = async () => await apiClient().get<NameIdPair[]>('uploadAudits/statuses');

export const getTypes = async () => await apiClient().get<NameIdPair[]>('uploadAudits/types');

export const download = async (id: string) => await apiClient().get<any>(`uploadAudits/${id}`);

export const rollback = async (id: string, entity: string, payload: UploadHistory) => await apiClient().post(`rollback/${entity}/audit/${id}`, payload);
