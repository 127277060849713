import {
  Button,
  FormControl,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';

import { Bold600 } from '../../contacts/contactInfo/contactDetailPanel/ContactDetails/ContactPendingView.styles';

export const SubGroupHeader = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginTop: '8px',
}));

export const SelectFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '.MuiInputLabel-formControl': {
    top: '-7px',
  },
  '.MuiInputLabel-shrink': {
    top: '0',
  },
}));

export const SplitByRadioGroup = styled(RadioGroup)`
  display: inline;
`;

export const AddButton = styled(Button)(() => ({
  outline: 'none',
}));
