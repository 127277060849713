import { Add, Remove } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React from 'react';

import { RowValueCellContainer } from "./TransactionMapping.styles";

type Props = {
    count: number,
    onChange: (value: number) => void,
}

const RowValueCounter = ({
  count,
  onChange,
}: Props) => {

  return (
    <RowValueCellContainer>
      <IconButton
        aria-label="Edit"
        aria-controls="edit"
        color="primary"
        id="decrement"
        className="counter-icon"
        disabled={count === -1}
        onClick={() => onChange(count - 1)}
      >
        <Remove />
      </IconButton>
      <Typography>{count}</Typography>
      <IconButton
        aria-label="Edit"
        aria-controls="edit"
        color="primary"
        className="counter-icon"
        id="increment"
        disabled={count === 1}
        onClick={() => onChange(count + 1)}
      >
        <Add />
      </IconButton>
    </RowValueCellContainer>
  );
};

export default RowValueCounter;
