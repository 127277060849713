import { Dispatch, ReactElement, SetStateAction } from 'react';

import DataGrid from '../../components/DataGrid/DataGrid';
import { FS_DISPLAY_NOT_SET } from '../../pages/arkGL/financialStatements/FinancialStatementsMain.hooks';
import { ReportAccount } from '../../pages/arkGL/reports/glReports/shared';
import { useReportGrid } from './ReportGrid.hooks';
import {
  CategoryFieldDef,
  GridData,
  GridRow,
  ReportGridBaseData,
  ValueFieldDef,
} from './ReportGrid.types';

type Props = {
  categoryColumnWidth?: number;
  currentCurrency?: string;
  currentDecimals?: number;
  footerRollUp?: boolean;
  hideFooter?: boolean;
  hideUseerCategorySelector: boolean;
  id: string;
  noDataMessage: string | undefined;
  onCategoryFieldOrderChange: (categoryFieldOrder: CategoryFieldDef[]) => void;
  onCategoryLinkButtonClick?: (gridRow: GridRow) => void;
  onDataFieldOrderChange: (categoryFieldOrder: ValueFieldDef[]) => void;
  onDataGridChange: (gridData: GridData) => void;
  onValueLinkButtonClick?: (
    gridRow: GridRow,
    valueFieldOrder: ValueFieldDef
  ) => void;
  pinActionColumn?: boolean;
  reportGridBaseData: ReportGridBaseData[];
  reportView?: string;
  rollupMode: boolean;
  setShowGroups?: any;
  setShowMonthlyGroup?: any;
  setShowQuarterlyGroup?: any;
  setShowYearlyGroup?: any;
  showGroups?: boolean;
  showMonthlyGroup?: boolean;
  showQuarterlyGroup?: boolean;
  showYearlyGroup?: boolean;
  summaryRowCategoryName: string;
  suppressIfZero?: boolean;
  userCategoryFieldOrder: CategoryFieldDef[];
  userColDefs: ValueFieldDef[];
  fsView?: boolean;
  handleFsNameChange?: (
    params: any,
    gridRows: GridRow[],
    allReportAccounts: ReportAccount[]
  ) => void;
  editModeActivated?: boolean;
  roundedReportActivated?: boolean;
  allReportAccounts?: ReportAccount[];
};

const ReportGrid = ({
  categoryColumnWidth = 400,
  currentCurrency,
  currentDecimals,
  footerRollUp,
  hideFooter = false,
  hideUseerCategorySelector,
  id,
  noDataMessage,
  onCategoryFieldOrderChange,
  onCategoryLinkButtonClick,
  onDataFieldOrderChange,
  onDataGridChange,
  onValueLinkButtonClick,
  pinActionColumn,
  reportGridBaseData,
  reportView,
  rollupMode,
  summaryRowCategoryName,
  suppressIfZero,
  userCategoryFieldOrder,
  userColDefs,
  fsView,
  handleFsNameChange,
  editModeActivated,
  roundedReportActivated,
  allReportAccounts,
}: Props): ReactElement => {
  const { gridData, handleColumnOrderChange } = useReportGrid(
    reportGridBaseData,
    userCategoryFieldOrder,
    userColDefs,
    summaryRowCategoryName,
    !!hideUseerCategorySelector,
    rollupMode,
    categoryColumnWidth,
    onDataGridChange,
    onCategoryFieldOrderChange,
    onDataFieldOrderChange,
    onCategoryLinkButtonClick,
    onValueLinkButtonClick,
    reportView,
    suppressIfZero,
    currentDecimals,
    currentCurrency,
    footerRollUp,
    hideFooter,
    fsView,
    roundedReportActivated
  );

  let initialState = {};

  // Make sure to pin column so it does not disappear when the column number expands as the group selection changes
  pinActionColumn
    ? (initialState = { pinnedColumns: { right: ['_fieldFilterColDef'] } })
    : '';

  return (
    <DataGrid
      id={id}
      dataList={gridData?.gridRows || []}
      headerList={gridData?.dataGridColDefs ?? []}
      noDataMessage={noDataMessage}
      minHeight={'1vh'}
      autoHeight={false}
      checkboxSelection={false}
      onColumnOrderChange={handleColumnOrderChange}
      initialState={initialState}
      isCellEditable={(params: any) =>
        !!fsView &&
        !!editModeActivated &&
        params.row.level !== 1 &&
        !params.row.categoryName.includes('ZZ') &&
        params.row.callerDataArray.length > 0 &&
        params.row.categoryName !== FS_DISPLAY_NOT_SET
      }
      onCellEditCommit={(params: any) =>
        handleFsNameChange!(params, gridData!.gridRows, allReportAccounts!)
      }
    />
  );
};

export default ReportGrid;
