import { Box, Stack, styled } from '@mui/material';
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers-pro";

export const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: #fff;
    box-shadow: 24;
    padding: 25px;

  // Title
  & h2.MuiTypography-root {
    font-weight: bold;
    margin: 10px 0 20px 0;
  }

  // Description
  & p.MuiTypography-root:nth-of-type(2) {
    margin: 10px 0;
    font-weight: normal;
  }

  // Divider
  & .MuiDivider-root {
    margin: 25px 0;
  }

  & .MuiFormControl-root {
    width: 100%;
    padding: 0;
  }
`;

export const StyledDatePickerWrapper = styled(Box)`
  width: 175px;
`;

export const StyledDatePicker = styled(MUIDatePicker)`
  &.MuiFormControl-root.MuiTextField-root {
    margin: 10px;
  }
`;

export const StyledSubmitStack = styled(Stack)`
  margin-top: 20px;
`;
