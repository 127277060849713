import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

import { FilterMap } from '../../pages/fund/scheduleOfInvestments/ScheduleOfInvestments.types';
import { FilterSelectionType } from '../../utils/types/filter.type';
import { inlineFilterTypes } from '../../utils/types/listItems';
import { PopoverFilter } from '../DataGrid/Filter/PopoverFilter.style';
import SplitFilter from '../DataGrid/Filter/SplitFilter';
import { useMultiSelectFilters } from './MultiSelectFilter.hook';

type Props = {
  value?: any[] | null | string[];
  splitFilters: inlineFilterTypes[];
  filterMap?: FilterMap;
  onFilter?: (
    filter: any,
    selected: any[] | any,
    selectionType?: FilterSelectionType
  ) => void;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  listData?: [];
  filterName?: string;
  id: string;
  emptySelectionOnClear?: boolean;
  buttonSx?: SxProps<Theme>;
};

const MultiSelectSplitFilter = ({
  id,
  label,
  onFilter,
  buttonSx,
  splitFilters,
  filterMap,
}: Props) => {
  const { anchorEl, handleOnFilter, handleOnClick, handleOnClose } =
    useMultiSelectFilters(onFilter);

  return (
    <>
      <Button
        variant={label ? 'outlined' : 'text'}
        endIcon={<FilterAltIcon />}
        onClick={handleOnClick}
        sx={buttonSx}
      >
        {label}
      </Button>
      <PopoverFilter
        id={`${label}_${id}_popover`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SplitFilter
          splitFilters={splitFilters}
          handleFilter={onFilter}
          filterMap={filterMap ? filterMap : undefined}
        />
      </PopoverFilter>
    </>
  );
};

export default React.memo(MultiSelectSplitFilter);
