import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import {
  maxAllowedAttempts,
  sendRecoveryEmail,
  totalAttempts,
  updateTrustedDevices,
} from "../../services/login.service";
import {
  getHeaders,
  verifyCodeURL,
} from "../../utils/constants/login.constants";
import {
  ActionButton,
  ClickLink,
  ContentDiv,
  DivSpacing,
  StyledFont,
  StyledLabel,
  StyledTextField,
} from "./Login.styles";

const HOST = process.env.REACT_APP_API_ENDPOINT_URL;

const codeValidation = Yup.object({
  code: Yup.string().required("Code is required"),
});

const ConfirmCode = ({
  textColor,
  btnBackgroundColor,
  loginToken,
  userDetailInfo,
  setShowLoginLoader,
  requestId,
  setUserDetailInfo,
  setCurrentPage,
}: any) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<{
    code: string;
    verifySuccess?: string;
  }>({
    defaultValues: {
      code: "",
      verifySuccess: "success",
    },
    resolver: yupResolver(codeValidation),
  });
  const { onLogin, informationAlert, preAuthClientConfigs } =
    useContext(AppContext);
  const { clientTheme } = useContext(ClientThemeContext);

  async function verifyCode(requestBody: any) {
    try {
      const url = `${HOST}${verifyCodeURL}`;
      const data = await fetch(
        url,
        getHeaders("POST", requestBody, loginToken)
      );
      const response = await data.json();

      return response.accessToken;
    } catch (ex) {
      informationAlert("Error in verifying code.", "error");
      return null;
    }
  }

  const VerifyCodeAndLogin = async (data: any) => {
    const requestBody = {
      ...userDetailInfo?.requestIdResponse,
      ...data,
      verifySuccess: data?.verifySuccess === "success",
      verifyCodeFakeResult: data?.verifySuccess,
    };

    setShowLoginLoader(true);

    try {
      const accessToken = await verifyCode(requestBody);

      if (accessToken) {
        const loginPayload = {
          email: userDetailInfo?.username,
          clientId: "",
        };

        if (userDetailInfo?.trustDevice) {
          await updateTrustedDevices(accessToken);
        }
        await onLogin(loginPayload, accessToken, false);
        setShowLoginLoader(false);
      } else {
        const maxAttempts = await maxAllowedAttempts(loginToken);
        const total = await totalAttempts(loginToken);

        setUserDetailInfo((prev: any) => ({
          ...prev,
          showloginWarning: total !== 0,
          loginRemainingCount: maxAttempts - total,
        }));
        setShowLoginLoader(false);
      }
    } catch {
      setShowLoginLoader(false);
    }
  };

  const accountRecovery = async () => {
    const data = {
      clientBaseDomain: preAuthClientConfigs?.baseDomain || "",
      clientId: null,
      clientName: preAuthClientConfigs?.clientName || "",
      clientSubdomain: preAuthClientConfigs?.subdomain || "",
      clientSupportEmail: preAuthClientConfigs?.supportEmail || "",
      username: userDetailInfo?.username || "",
    };

    try {
      await sendRecoveryEmail(data, loginToken);
      setCurrentPage("AccountRecovery");
    } catch (error) {
      informationAlert(
        (error as unknown as any)?.response?.data?.message ||
          "Recovery email failed",
        "error"
      );
    }
  };

  const sendCode = () => {
    setCurrentPage("MfaSetup");
  };

  return (
    <div>
      <form onSubmit={handleSubmit(VerifyCodeAndLogin)}>
        <Grid container justifyContent="center">
          <ContentDiv>
            <Typography variant="h1">
              <StyledLabel color={textColor}>
                Confirmation Verification Code
              </StyledLabel>
            </Typography>
            <DivSpacing />
            <StyledFont textcolor={textColor}>
              A verification code will be sent to the number on file
            </StyledFont>
            <DivSpacing />
            <StyledLabel color={textColor}>
              ***_***_{userDetailInfo?.phoneNumber}
            </StyledLabel>
            <DivSpacing />
            {userDetailInfo?.showloginWarning && (
              <FormLabel error={true}>
                {" "}
                {userDetailInfo.loginRemainingCount} attempts remaining
              </FormLabel>
            )}
            <br />
            <StyledFont textcolor={textColor}>Enter the code</StyledFont>
            <StyledTextField
              size="small"
              id="confirm_code"
              type="text"
              variant="outlined"
              InputProps={{ notched: true }}
              {...register("code", { required: true })}
              error={!!errors.code}
              helperText={errors?.code?.message}
            />
            <DivSpacing />
            <StyledFont textcolor={textColor}>
              Didn&apos;t get the code? Need to update delivery preferences?
              &nbsp;
              <ClickLink to="#" onClick={sendCode}>
                <StyledFont>Click here</StyledFont>
              </ClickLink>
              &nbsp; to resend it.
            </StyledFont>
            <DivSpacing />
            <StyledFont textcolor={textColor}>
              Need to recover your account? &nbsp;{" "}
              <ClickLink to="#" onClick={accountRecovery}>
                <StyledFont textcolor={textColor}> Click here</StyledFont>
              </ClickLink>
              &nbsp; and a member of the support team will be in touch shortly
            </StyledFont>
            <DivSpacing />
            {userDetailInfo?.isDevEnv && (
              <FormControl>
                <FormLabel id="simulation_verification">
                  <StyledLabel color={clientTheme?.loginTextColor}>
                    DEV ONLY - SIMULATE SUCCESSFUL OR FAILED CODE VERIFICATION
                  </StyledLabel>
                </FormLabel>
                <Controller
                  name="verifySuccess"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup id="dev_simulation_option" {...field}>
                      <FormControlLabel
                        value={"success"}
                        control={<Radio />}
                        label="Simulate successful verification"
                      />
                      <FormControlLabel
                        value={"fail"}
                        control={<Radio />}
                        label="Simulate failed verification"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            )}
            <DivSpacing />
            <ActionButton
              type="submit"
              variant="contained"
              className="btn"
              disableElevation
              textcolor={textColor}
              btnbackgroundcolor={btnBackgroundColor}
              fullWidth
            >
              Confirm
            </ActionButton>
          </ContentDiv>
        </Grid>
      </form>
    </div>
  );
};

export default ConfirmCode;
