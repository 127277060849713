export type FormProps = {
  register: Function;
  control: any;
  errors: any;
  setError: Function;
  setValue: Function;
  watch: Function;
  reset: Function;
  resetField: Function;
};

export enum LoadingStatus {
  Adding = 'Adding',
  Updating = 'Updating',
  Deleting = 'Deleting',
  Loading = 'Loading',
  Connecting = 'Connecting',
  Disconnecting = 'Disconnecting',
  Submitting = 'Submitting',
  Publishing = 'Publishing',
  Downloading = 'Downloading',
  Uploading = 'Uploading',
}

export enum DetailsType {
  New = 'new',
  Edit = 'edit',
}
