import { Box, styled, ToggleButtonGroup, Typography } from "@mui/material";

import { BLACK, PRIMARY, SUBTITLE } from "../../styles/colors";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 130px;
`;

export const StyledText = styled(Typography)`
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 2px;
  color: ${BLACK};
  font-weight: bold;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root {
    border-radius: 8px;
  }
  
  .Mui-selected {
    border: 2px ${PRIMARY} solid;
    border-left: 2px ${PRIMARY} solid !important;
    background-color: white;
    z-index: 1;
  }
`;
