import { Box, Drawer, IconButton, styled, Typography as MUITypography } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";

export const Panel = styled(Box)`
  margin: 15px 0;
`;

export const Typography = styled(MUITypography)(({
  theme
}) => ({
  marginBottom: theme.spacing(4)
}));

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  }
}));

export const FieldButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  minWidth: "47px",
  [`&:hover`]: {
    backgroundColor: "#4aa1ff",
    color: "#FFFFFF",
    border: "0px",
  }
}));

export const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  minWidth: "47px",
}));

export const CustomPropertyListBox = styled(Box)(({
  theme
}) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: `1px solid ${theme.palette.divider}`,
  "& .MuiTypography-root": {
    marginBottom: "10px"
  }
}));

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: '55% 45%',
  gap: theme.spacing(1),
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(4),
  marginBottom: 0,
  '&.MuiTypography-root': {
    margin: '0'
  }
}));
