import { FileDownloadOutlined } from "@mui/icons-material";
import { Button,Link,Typography } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";

import { StringCell } from "../../components/DataGrid/DataGrid.styles";
import StatusLabel from "../../components/StatusLabel/StatusLabel";
import { AppContext } from "../../core/context/appContextProvider";
import { downloadIndividual } from "../../services/downloadHistory.service";
import { useDownloadHistoryEffect } from "../../services/hooks/useDownloadHistoryEffect/useDownloadHistoryEffect.hooks";
import { M_DASH_UNICODE } from "../../utils/constants/constants";
import { capitalize } from "../../utils/helpers/capitalize";
import downloadFile from "../../utils/helpers/fileDownloader";
import { DateTimeFormat } from "../../utils/helpers/format.helper";
import { getFileNameAndExtension } from "../../utils/helpers/getFileNameAndExtension";
import { DownloadHistory } from "../../utils/types/downloadHistory.type";
import { CustomType, DataGridColDef } from "../../utils/types/listItems";


const StatusType = {
  processing: 'yellow',
  in_progress: 'yellow',
  error: "red",
  completed: "green",
};

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "dateStarted",
    headerName: "Date",
    hide: false,
    index: 1,
    renderCell: (params) => {
      const date = params?.row?.dateStarted ?? '';

      if(!date) {
        return (
          <StringCell>
            <Typography variant="body2">{M_DASH_UNICODE}</Typography>
          </StringCell>
        );
      }
      const formattedData = DateTimeFormat.shortDate(date);
      
      return (
        <StringCell>
          <Typography variant="body2">{formattedData}</Typography>
        </StringCell>
      );
    },
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: "filename",
    headerName: "Filename",
    hide: false,
    index: 2,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 600,
  },
  {
    field: "downloadType",
    headerName: "Type",
    hide: false,
    index: 3,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 300,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <StringCell>
          <Typography>{capitalize(params?.row?.downloadType ?? '') || M_DASH_UNICODE}</Typography>
        </StringCell>
      );
    },
  },
  {
    field: "downloadStatus",
    headerName: "Status",
    hide: false,
    index: 4,
    width: 300,
    renderCell: (params: GridRenderCellParams) => {
      const status = params?.row?.downloadStatus?.toLowerCase();
      const color = StatusType[status as keyof typeof StatusType];

      return (
        <StringCell>
          {status ?
            <StatusLabel
              color={color}
              isUpperCase={false}
              label={capitalize(status ?? M_DASH_UNICODE)} />
            : M_DASH_UNICODE}
        </StringCell>
      );
    },
    type: 'string',
    align: 'left' as GridAlignment,
  },
];

const ACTION_COLUMN = {
  field: 'cta',
  headerName: "",
  hide: false,
  hideable: false,
  index: 5,
  type: 'action',
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 200,
};

export const useDownloadHistory = () => {
  const [list, setList] = useState<DownloadHistory[] | undefined>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>(defaultHeaderList);
  const [activeHeaderFields, setActiveHeaderFields] = useState(6);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    informationAlert
  } = useContext(AppContext);

  const {
    history: historyList,
    fetchHistory,
    loading: loadingHistory,
  } = useDownloadHistoryEffect();

  useEffect(() => {
    //Filtering based on the selected filters and search
    const currentList = historyList?.filter(({
      downloadType, filename, downloadStatus
    }) => (downloadType?.toLowerCase().includes(search?.toLowerCase() || '')
          || filename?.toLowerCase().includes(search?.toLowerCase() || '')
          || downloadStatus?.toLowerCase().includes(search?.toLowerCase() || ''))) ?? [];

    setList(currentList);
  }, [search, historyList]);

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter((header) => !header.hide && header?.type !== "action");

    const updatedHeaders: Array<DataGridColDef> = headerList.map(
      (header) => {
        return {
          ...header,
          hide: (header.field === field && (!(!header.hide && activeFields.length <= 1))) ? !header.hide : header.hide,
        };
      });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const onSearch = (search: string) => {
    setSearch(search);
  };

  const onDownload = async (download: DownloadHistory) => {
    setIsLoading(true);
    try {
      const {
        fileExtension,
        fileName,
      } = getFileNameAndExtension(download?.filename);

      const downloadedData = await downloadIndividual(download.id);

      downloadFile(downloadedData, fileName, fileExtension || 'zip');
    } catch (error) {
      informationAlert("Error in downloading the file", "error");
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    setHeaderList([
      ...defaultHeaderList,
      {
        ...ACTION_COLUMN,
        renderCell: (params: GridRenderCellParams) => {
          const status = params?.row?.downloadStatus?.toLowerCase();

          if(status === "completed") {
            return (
              <Button
                id={`${params?.row?.id}_download_button`}
                variant="outlined"
                size="small"
                onClick={() => onDownload(params?.row)}
                startIcon={<FileDownloadOutlined />}
              >
                <strong>
                    Download
                </strong>
              </Button>
            );
          }
          return (
            <StringCell>
              {M_DASH_UNICODE}
            </StringCell>
          );
        },
      }
    ]);
  }, []);

  return {
    headerList,
    loading: loadingHistory || isLoading,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    fetchHistory,
  };
};
