export const capitalize = (str: string) => {
  return `${str?.charAt(0)?.toUpperCase()}${str?.slice(1)?.toLowerCase()}`;
};

export const underscoreToCapitalize = (str: string) => {
  return str?.replace(/_/g, ' ').split(' ').map(word => capitalize(word)).join(' ');
};

export const convertToLowerCase = (str: string) => {
  return `${str.toString().toLowerCase()}`;
};