import { Box, FormControl as MUIFormControl, MenuItem as MUIMenuItem, Select as MUISelect, styled, Typography } from '@mui/material';

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));
