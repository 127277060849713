import IconDownload from '../../../../assets/images/icons/icon_download.svg';
import IconNewCircle from '../../../../assets/images/icons/icon_new_circle.svg';
import IconUpload from '../../../../assets/images/icons/icon_upload.svg';
import { ButtonOptions } from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import {
  ArkAccountAddNewButtonOptions,
  ArkGLAccount,
} from '../../../../utils/types/arkGLAccount.type';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';

export const ARKGL_ACCOUNTS_COLUMN_ORDER_VIEW_KEY =
  'CHART_OF_ACCOUNTS_VIEW_KEY';
export const GET_GL_ACCOUNT_LIST_ERROR = 'Error in getting account list.';
export const GET_ACCOUNT_ATTRIBUTE_LIST_ERROR =
  'Error in getting attribute list.';
export const GET_ARK_TRANSACTION_LIST_ERROR =
  'Error in getting ark transaction list.';
export const UNLOCK_MAPPING = 'Unlock accounts to start mapping';
export const CREATE_ACCOUNT_ERROR = 'Error in creating account.';
export const CREATE_ACCOUNT_SUCCESS = 'Account created successfully.';
export const UPDATE_ACCOUNT_ERROR = 'Error in updating account.';
export const UPDATE_ACCOUNT_SUCCESS = 'Account updated successfully.';
export const DELETE_ACCOUNT_ERROR = 'Error in deleting account.';
export const DELETE_ACCOUNT_SUCCESS = 'Account deleted successfully.';
export const BULK_DELETE_ACCOUNTS_ERROR = 'Error in deleting accounts.';
export const BULK_DELETE_ACCOUNTS_SUCCESS = 'Accounts deleted successfully.';
export const IMPORT_TEMPLATE_ERROR = 'Error in importing accounts.';
export const IMPORT_TEMPLATE_SUCCESS = 'Accounts imported successfully.';
export const SAVE_ACCOUNTS_ERROR = 'Error in saving accounts.';
export const SAVE_ACCOUNTS_SUCCESS = 'Accounts saved successfully.';
export const MAP_ACCOUNT_ERROR = 'Error in mapping account.';
export const MAP_ACCOUNT_SUCCESS = 'Account mapped successfully.';
export const UPLOAD_ACCOUNT_ERROR = 'Error in uploading accounts.';
export const UPLOAD_ACCOUNT_SUCCESS = 'Accounts uploaded successfully.';
export const DOWNLOAD_TEMPLATE_ERROR = 'Error downloading template.';
export const BULK_MAP_SUCCESS = 'Accounts successfully mapped.';
export const BULK_MAP_ERROR = 'Error bulk mapping accounts.';
export const BULK_MAP_VALIDATION_ERROR =
  "Error updating accounts. Please unselect 'POSTED' accounts.";

export const ACCOUNT_FORM_DEFAULT_VALUE: ArkGLAccount = {
  fundId: '',
  parentId: null,
  name: '',
  number: null,
  description: '',
  isTaxable: false,
  isEntityRequired: false,
  transactionTypeId: null,
  transactionTypeName: null,
  doNotMap: false,
  dateMapped: null,
  attributeId: null,
  fsMappingId: '00000000-0000-0000-0000-000000000000',
  fsDisplayName: null,
  state: 'DRAFT',
  cashFlowName: null,
  cashFlowMapping: null,
};

export const NewAccountOptions: ButtonOptions[] = [
  {
    label: 'New Account',
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
  {
    label: 'Upload From Template',
    id: AddNewButtonOptions.UploadFromTemplate,
    icon: IconUpload,
    type: 'upload',
  },
  {
    label: 'Download Template',
    id: AddNewButtonOptions.DownloadTemplate,
    icon: IconDownload,
  },
  {
    label: 'Import Basic Accounts List',
    id: ArkAccountAddNewButtonOptions.ImportBasic,
    icon: IconNewCircle,
  },
  {
    label: 'Import Advanced Accounts List',
    id: ArkAccountAddNewButtonOptions.ImportAdvanced,
    icon: IconNewCircle,
  },
];

export const BulkFieldOptions: any = [
  { id: 0, name: 'FS Mapping', key: 'FS_MAPPING', type: 'selectValue' },
  {
    id: 1,
    name: 'Ark Transaction',
    key: 'ARK_TRANSACTION',
    type: 'selectValue',
  },
  {
    id: 2,
    name: 'Taxable Account? (Y/N)',
    key: 'TAXABLE_ACCOUNT',
    type: 'selectValue',
  },
  {
    id: 3,
    name: 'Entity Required? (Y/N)',
    key: 'ENTITY_REQUIRED',
    type: 'selectValue',
  },
  { id: 4, name: 'Attribute', key: 'ATTRIBUTE', type: 'selectValue' },
  { id: 5, name: 'Parent Account', key: 'PARENT_ACCOUNT', type: 'selectValue' },
];

export const csvHeaders = [
  { label: 'Number', key: 'number' },
  { label: 'Parent Account', key: 'parentAcc' },
  { label: 'Account Name', key: 'name' },
  { label: 'Attribute', key: 'attribute' },
  { label: 'Description', key: 'description' },
  { label: 'FS Mapping', key: 'fsMapping' },
  { label: 'FS Name', key: 'fsName' },
  { label: 'Ark Transaction', key: 'arkTransaction' },
  { label: 'Taxable Account Y-N', key: 'isTaxable' },
  { label: 'Entity Required?', key: 'isEntityRequired' },
  { label: 'Id', key: 'id' },
];

export const cashFlowAttributeOptions = [
  { label: 'Do Not Map', id: null },
  { label: 'Operating Activities', id: 'OPERATING_ACTIVITIES' },
  { label: 'Investing Activities', id: 'INVESTING_ACTIVITIES' },
  { label: 'Financing Activities', id: 'FINANCING_ACTIVITIES' },
];
