import { Box, styled, Typography } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const ButtonContainer = styled(Box)(({
    theme
}) => ({
    display:"flex",
    gap:theme.spacing(1)
})); 