import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { AvailableDomain } from "../../../utils/types/client.type";
import { getAvailableDomains } from "../../client.service";

export const useDomainsEffect = () => {
  const [domains, setDomains] = useState<AvailableDomain[] | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchDomains = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getAvailableDomains();

      if (isCanceled?.()) return;

      setDomains(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting domains", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchDomains(isCanceled);
  }, []);

  return {
    domains,
    fetchDomains,
    loading,
  };
};
