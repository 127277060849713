import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { StringCell } from "../../components/DataGrid/DataGrid.styles";
import StatusLabel from "../../components/StatusLabel/StatusLabel";
import { AppContext } from "../../core/context/appContextProvider";
import useRole from "../../core/routing/useRole";
import { glRollback } from "../../services/arkGL.service";
import { useUploadHistoryEffect } from "../../services/hooks/useUploadHistoryEffect/useUploadHistoryEffect.hooks";
import { useUploadHistoryStatusEffect } from "../../services/hooks/useUploadHistoryEffect/useUploadHistoryStatusEffect.hooks";
import { useUploadHistoryTypesEffect } from "../../services/hooks/useUploadHistoryEffect/useUploadHistoryTypesEffect.hooks";
import { download, rollback } from "../../services/uploadHistory.service";
import { M_DASH_UNICODE } from "../../utils/constants/constants";
import { capitalize } from "../../utils/helpers/capitalize";
import downloadFile from "../../utils/helpers/fileDownloader";
import { CustomType, DataGridColDef } from "../../utils/types/listItems";
import {
  HistoryFilter,
  UploadHistory,
} from "../../utils/types/uploadHistory.type";
import { ScopeRole } from "../../utils/types/user.type";
import { TimeText } from "./UploadHistory.styles";

const StatusType = {
  processing: "yellow",
  error: "red",
  success: "green",
  saving: "aqua",
  rolledback: "blue",
};

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "dateStarted",
    headerName: "Date",
    hide: false,
    index: 1,
    renderCell: (params) => {
      const date = params?.row?.dateStarted ?? "";

      if (!date) {
        return (
          <StringCell>
            <Typography variant="body2">{M_DASH_UNICODE}</Typography>
          </StringCell>
        );
      }
      const formattedData = format(new Date(date), "MM/dd/yyyy");
      const time = format(new Date(date), "hh:mm a");

      return (
        <StringCell>
          <Typography variant="body2">{formattedData}</Typography>
          <TimeText variant="body2">{time}</TimeText>
        </StringCell>
      );
    },
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "uploadedBy",
    headerName: "Username",
    hide: false,
    index: 2,
    type: "string",
    align: "left" as GridAlignment,
    width: 250,
  },
  {
    field: "uploadedFileName",
    headerName: "Filename",
    hide: false,
    index: 3,
    type: "string",
    align: "left" as GridAlignment,
    width: 500,
  },
];

export const useUploadHistory = () => {
  const [list, setList] = useState<UploadHistory[] | undefined>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(6);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [toBeRolledBack, setToBeRolledBack] = useState<
    UploadHistory | undefined
  >();

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);
  const {
    history: historyList,
    fetchHistory,
    loading: loadingHistory,
  } = useUploadHistoryEffect();

  const { statuses, loading: loadingStatuses } = useUploadHistoryStatusEffect();
  const { types, loading: loadingTypes } = useUploadHistoryTypesEffect();

  const typesIds = useMemo(() => types?.map((type) => type.id), [types]);
  const statusesIds = useMemo(
    () => statuses?.map((status) => status.id),
    [statuses]
  );
  const { informationAlert } = useContext(AppContext);

  const getSelectedFilters = useCallback(
    (currentHeaderList: DataGridColDef[]) => {
      return currentHeaderList?.reduce(
        (acc: Record<HistoryFilter, string[] | undefined>, header) => {
          if (
            header.inlineFilter &&
            header.inlineFilterSelected &&
            header.inlineFilterSelected?.length > 0
          ) {
            return {
              ...acc,
              [header.inlineFilterName ?? ""]: header.inlineFilterSelected?.map(
                (item) => item.toLowerCase()
              ),
            };
          }
          return acc;
        },
        {
          [HistoryFilter.Types]: undefined,
          [HistoryFilter.Statuses]: undefined,
        }
      );
    },
    []
  );

  const handleRollback = useCallback((data: UploadHistory) => {
    setToBeRolledBack(data);
  }, []);

  const onRollbackConfirm = async () => {
    if (!toBeRolledBack || !toBeRolledBack.id || !toBeRolledBack.uploadType) {
      return;
    }
    setLoading(true);
    const { id, uploadType, tenantId } = toBeRolledBack;

    try {
      switch (uploadType.toLowerCase()) {
        case "chart_of_accounts":
        case "journal_entries":
          await glRollback(id!);
          break;
        default:
          await rollback(id!, uploadType.toLowerCase(), toBeRolledBack);
      }
      informationAlert("Successfully rolled back", "success");
      fetchHistory();
      setToBeRolledBack(undefined);
    } catch (error) {
      informationAlert("Error in rollback.", "error");
    } finally {
      setLoading(false);
    }
  };

  const onRollbackCancel = () => {
    setToBeRolledBack(undefined);
    setLoading(false);
  };

  const downloadHistory = useCallback(async (id: string, fileName: string) => {
    setLoading(true);

    try {
      const downloadedData = await download(id);
      const name = fileName?.split(".")?.[0];

      downloadFile(downloadedData, name, "csv");
    } catch (error) {
      informationAlert("Error in downloading.", "error");
    }

    setLoading(false);
  }, []);

  //Initialize the header list
  useEffect(() => {
    const newHeaderList = [
      ...defaultHeaderList,
      {
        field: "uploadType",
        headerName: "Type",
        hide: false,
        index: 4,
        type: "string",
        align: "left" as GridAlignment,
        width: 200,
        inlineFilter: true,
        inlineFilterName: HistoryFilter.Types,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: types,
        inlineFilterSelected: typesIds,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <StringCell>
              <Typography>
                {capitalize(params?.row?.uploadType ?? "") || M_DASH_UNICODE}
              </Typography>
            </StringCell>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        hide: false,
        index: 5,
        inlineFilter: true,
        inlineFilterName: HistoryFilter.Statuses,
        inlineFilterIDField: "id",
        inlineFilterLabelField: "name",
        inlineFilterOptions: statuses,
        inlineFilterSelected: statusesIds,
        renderCell: (params: GridRenderCellParams) => {
          const status = params?.row?.status?.toLowerCase();
          const color = StatusType[status as keyof typeof StatusType];

          return (
            <StringCell>
              {status ? (
                <StatusLabel
                  color={color}
                  isUpperCase={false}
                  label={capitalize(status ?? M_DASH_UNICODE)}
                />
              ) : (
                M_DASH_UNICODE
              )}
            </StringCell>
          );
        },
        type: "string",
        align: "left" as GridAlignment,
      },
      {
        field: "cta",
        headerName: "",
        hide: false,
        hideable: false,
        index: 6,
        type: "action",
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          const status = params?.row?.status?.toLowerCase();
          const currentType = params?.row?.uploadType?.toLowerCase();
          const rollbackTypes = [
            "transactions",
            "investors",
            "contacts",
            "funds",
            "chart_of_accounts",
            "journal_entries",
          ];

          if (status === "error") {
            return (
              <Button
                id={`${params?.row?.id}-download-button`}
                onClick={() =>
                  downloadHistory(
                    params?.row?.id,
                    params?.row?.uploadedFileName
                  )
                }
                variant="outlined"
                size="small"
                startIcon={<FileDownloadOutlined />}
              >
                <strong>Download</strong>
              </Button>
            );
          } else if (
            status === "success" &&
            rollbackTypes.includes(currentType) &&
            isSuperAdmin
          ) {
            return (
              <Button
                id={`${params?.row?.id}-rollback-button`}
                onClick={() => handleRollback(params?.row)}
                size="small"
                variant="outlined"
              >
                <strong>Rollback</strong>
              </Button>
            );
          }
          return <StringCell>{M_DASH_UNICODE}</StringCell>;
        },
      },
    ];

    setHeaderList(newHeaderList);
  }, [types, statuses, isSuperAdmin]);

  useEffect(() => {
    const {
      [HistoryFilter.Types]: selectedTypes,
      [HistoryFilter.Statuses]: selectedStatuses,
    } = getSelectedFilters(headerList);

    //Filtering based on the selected filters and search
    const currentList =
      historyList?.filter(
        ({ uploadType, uploadedBy, uploadedFileName, status }) =>
          (uploadType?.toLowerCase().includes(search?.toLowerCase() || "") ||
            uploadedBy?.toLowerCase().includes(search?.toLowerCase() || "") ||
            uploadedFileName
              ?.toLowerCase()
              .includes(search?.toLowerCase() || "") ||
            status?.toLowerCase().includes(search?.toLowerCase() || "")) &&
          selectedStatuses?.includes(status?.toLowerCase() || "") &&
          selectedTypes?.includes(uploadType?.toLowerCase() || "")
      ) ?? [];

    setList(currentList);
  }, [search, historyList, getSelectedFilters, headerList]);

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const handleFilter = (filterName: HistoryFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );
  };

  const onSearch = (search: string) => {
    setSearch(search);
  };

  return {
    headerList,
    loading: loadingHistory || loadingStatuses || loadingTypes || loading,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    isSuperAdmin,
    handleFilter,
    toBeRolledBack,
    onRollbackConfirm,
    onRollbackCancel,
    fetchHistory,
  };
};
