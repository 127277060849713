import AutorenewIcon from "@mui/icons-material/Autorenew";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { Button, Link, Stack, Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGridProProps, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { BulkMapping } from "../../../components/GL/BulkMapping/BulkMapping";
import { MappingProgressBar } from "../../../components/GL/MappingProgress/MappingProgress";
import FullScreenModal from "../../../components/Modal/FullScreenModal/FullScreenModal";
import { SaveExitModal } from "../../../components/Modal/SaveExitModal/SaveExitModal";
import { PageLock } from "../../../components/PageLock/PageLock";
import ProgressModal from "../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../components/SearchBar/SearchBar";
import NewTransactionModal from "./NewTransactionModal/NewTransactionModal";
import { useTransactionMapperEffect } from "./TransactionMapper.hooks";
import {
  ButtonBox,
  GLAccount,
  HeaderRow,
  SyncButton,
  SyncTooltip,
  TabRow,
} from "./TransactionMapper.styles";

const TransactionMapper: React.FC = () => {
  const {
    accountList,
    accountFilteredList,
    headerList,
    activeHeaderFields,
    bulkMappingSelection,
    clientId,
    filterModel,
    glConnectionDate,
    glConnectionPlatform,
    glConnectionUsername,
    readonly,
    handleNewTransactionSave,
    handleSaveMapping,
    handleSyncGL,
    handleTabChange,
    handleTransTypeChange,
    hasMappingAdmin,
    isAlertToUnlockOpen,
    isDirty,
    isLoading,
    isModalOpen,
    isScreenLocked,
    isSending,
    progress,
    search,
    searchOptions,
    handleSearch,
    showSuggestionPopover,
    setShowSuggestionPopover,
    selectionModel,
    setBulkMappingSelection,
    handleFilter,
    handleUpdateHeader,
    setIsAlertToUnlockOpen,
    setIsModalOpen,
    setIsScreenLocked,
    setSelectedFund,
    setSelectionModel,
    tab,
    transBuckets,
    transactionList,
  } = useTransactionMapperEffect();

  function CustomGridTreeDataGroupingCell(params: GridRenderCellParams) {
    const { id, field, value, row, rowNode } = params;

    return (
      <GLAccount depth={rowNode.depth}>
        <Link component="button" underline="none">
          <Typography variant="label">{value}</Typography>
        </Link>
        {row.transactionTypeName === null && <FiberNewIcon color="success" />}
      </GLAccount>
    );
  }

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "GL Accounts",
    sortable: true,
    width: 450,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <DataWrapperBox id="transactionmapper_main" role="main">
      <ProgressModal
        id="modal_transaction_mapping_loading"
        showProgress={isLoading}
      />
      <ProgressModal
        id="modal_saving_mapping"
        showProgress={isSending}
        text="Saving mapping..."
      />
      <HeaderRow>
        <Typography variant="pageTitle">Chart of Accounts Mapping</Typography>
        <SearchBar
          id="search_accounts"
          size="small"
          onChange={handleSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <MappingProgressBar
          value={(progress.mappedAmount / progress.totalAmount) * 100}
          total={progress.totalAmount}
        />
        <ButtonBox>
          {!readonly && (
            <Button
              id="btn_save_mapping"
              color="primary"
              variant="contained"
              onClick={() => handleSaveMapping()}
              disableElevation
              disabled={!hasMappingAdmin || !isDirty || isScreenLocked}
            >
              Save mapping
            </Button>
          )}
          <SyncTooltip
            title={
              <>
                <Typography color="inherit">
                  Sync Chart of Accounts list <br />
                  from General Ledger
                </Typography>
                <Typography>
                  {glConnectionPlatform} account connected on {glConnectionDate}{" "}
                  by {glConnectionUsername}.
                </Typography>
              </>
            }
          >
            <SyncButton onClick={handleSyncGL} size="large">
              <AutorenewIcon />
            </SyncButton>
          </SyncTooltip>
        </ButtonBox>
      </HeaderRow>

      {!isScreenLocked && !readonly && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            marginBottom: "2em",
          }}
        >
          <BulkMapping
            selectionModel={selectionModel}
            bulkMappingSelection={bulkMappingSelection}
            setBulkMappingSelection={setBulkMappingSelection}
            transactionList={transactionList}
            handleTypeChange={() =>
              handleTransTypeChange(selectionModel, bulkMappingSelection)
            }
          />
        </Stack>
      )}

      <TabRow>
        <Box>
          <Tabs
            id="tabgroup_notmapped_all"
            value={tab}
            onChange={handleTabChange}
            aria-label="not mapped all tabs"
          >
            <Tab
              label={`NOT MAPPED ${
                progress.totalAmount - progress.mappedAmount
              }`}
              disabled={progress.mappedAmount === progress.totalAmount}
            />
            <Tab label={`ALL ${progress.totalAmount}`} />
          </Tabs>
        </Box>
      </TabRow>

      <DataGrid
        id="data_grid_account_list"
        dataList={
          !isLoading && accountFilteredList?.length > 0
            ? accountFilteredList
            : []
        }
        treeData
        getTreeDataPath={(row) => row.path}
        defaultGroupingExpansionDepth={-1}
        groupingColDef={groupingColDef}
        autoHeight={false}
        headerList={headerList}
        activeHeaderFields={activeHeaderFields}
        handleOnView={() => {}}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleUpdateHeader={handleUpdateHeader}
        filterModel={filterModel}
        handleFilter={handleFilter}
      />
      <br />
      <br />

      {!readonly && (
        <PageLock
          locked={isScreenLocked}
          disabled={!hasMappingAdmin}
          onChange={() => setIsScreenLocked(!isScreenLocked)}
        />
      )}

      {!readonly && (
        <>
          <FullScreenModal
            id="dialog_unlock_transaction_mapping_confirmation"
            open={isAlertToUnlockOpen}
            title="Unlock accounts to start mapping"
            subtitle={undefined}
            confirmButtonText="Unlock"
            cancelButtonText="Cancel"
            onSuccess={() => {
              setIsScreenLocked(false);
              setIsAlertToUnlockOpen(false);
            }}
            onCancel={() => setIsAlertToUnlockOpen(false)}
          />
          <SaveExitModal
            onActionClickAsync={handleSaveMapping}
            isBlocked={isDirty}
            title="Leave Page?"
            content="Your changes have not been saved. Are you sure you want to leave this page?"
            actionTitle="Save Mapping"
            confirmNavigationTitle="Exit Without Saving"
          />
          <NewTransactionModal
            clientId={clientId}
            open={isModalOpen}
            transTypeModel={transBuckets}
            onClose={() => setIsModalOpen(false)}
            onSuccess={handleNewTransactionSave}
          />
        </>
      )}
    </DataWrapperBox>
  );
};

export default TransactionMapper;
