import { Box, styled } from '@mui/material';

export const FormBorder = styled(Box)(({
  theme
}) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.secondary.dark}`,
}));
