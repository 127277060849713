import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import * as React from "react";
import { ReactElement, useContext } from "react";

import ExpandIcon from "../../../../assets/images/icons/icon_expand.svg";
import MinimizeIcon from "../../../../assets/images/icons/icon_minimize.svg";
import IconRightArrow from "../../../../assets/images/icons/icon_right_arrow.svg";
import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import InformationPanel from "../../../../components/InformationPanel/InformationPanel";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import TabContent from "../../../../components/TabContent/TabContent";
import TabsPanel from "../../../../components/TabsPanel/TabsPanel";
import { AppContext } from "../../../../core/context/appContextProvider";
import {
  defaultContactInfo,
} from "../../../../utils/constants/form.constants";
import { FundItem } from "../../../../utils/types/fund.type";
import { InvestorFilter } from "../../../../utils/types/investor.type";
import { FilterItem } from "../../../../utils/types/listItems";
import { ContactContext } from "../../contactList/ContactList";
import ReadOnlyContactPermissions from "../../contactPermissions/ReadOnlyContactPermission";
import {
  useContactDetailPanelEffect,
  useContactEffect,
} from "./ContactDetailPanel.hooks";
import {
  ActionButtonBox,
  IconText,
  PanelActionBox,
  PermissionsBox,
  StyledBackdrop,
  Title,
} from "./ContactDetailPanel.styles";
import ReadOnlyContactDetails from "./ContactDetails/ReadOnlyContactDetails";

type Anchor = "right";

type Props = {
  showPanel: boolean;
  setShowPanel: any;
  selectedContact: string;
  setSelectedContact: any;
  roleFilters: FilterItem[];
  investorFilters: InvestorFilter[];
  fundFilters?: FundItem[],
};


const ReadOnlyContactPanel: React.FC<Props> = (props: Props): ReactElement => {
  const { setPermissionsByInvestorList } =
    useContext(ContactContext);

  const {
    showPanel,
    setShowPanel,
    selectedContact: selectedContactId,
    setSelectedContact,
    roleFilters,
    investorFilters,
    fundFilters,
  } = props;

  const { informationAlert } = useContext(AppContext);

  const {
    setShowLeaveConfirmation,
    activeTabIndex,
    setAciveTabIndex,
    handleTabChange,
  } = useContactDetailPanelEffect({ informationAlert, readonly: true });

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setShowPanel(open);
    setPanelVisible({
      ...isPanelVisible,
      [anchor]: open,
    });
  };

  const {
    title,
    contactDetail,
    isLoadingContact,
    isPanelVisible,
    setPanelVisible,
    setEmailError,
    setContact,
  } = useContactEffect({
    contactId: selectedContactId,
    showPanel,
    informationAlert,
    readonly: true,
    investorFilters,
    roleFilters,
    fundFilters,
    setPermissionsByInvestorList,
  });


  const resetContactConfig = () => {
    const contactConfig = {
      details: defaultContactInfo,
      investorFundRoles: [],
    };

    setSelectedContact("");
    setContact(contactConfig);
    setAciveTabIndex(0);
    setEmailError("");
    toggleDrawer("right", false);
  };

  const handleOnClose = () => {
    resetContactConfig();
    setShowLeaveConfirmation(false);
  };

  return (
    <React.Fragment key="right">
      <InformationPanel
        id="contact_drawer"
        open={isPanelVisible["right"]}
        onClose={handleOnClose}
        expanded={isPanelVisible.expand}
      >
        <Box>
          <HorizontalBox>
            <Title id="title">
              {title} <ImgIcon icon={IconRightArrow} />
            </Title>
            <PanelActionBox>
              <PanelActionBox
                onClick={() => {
                  setPanelVisible({
                    ...isPanelVisible,
                    expand: !isPanelVisible.expand,
                  });
                }}
              >
                {isPanelVisible.expand ? (
                  <>
                    <ImgIcon icon={MinimizeIcon} />
                    <IconText>Minimize</IconText>{" "}
                  </>
                ) : (
                  <>
                    <ImgIcon icon={ExpandIcon} />
                    <IconText>Expand</IconText>{" "}
                  </>
                )}
              </PanelActionBox>
              <CloseIcon
                id="btn_contact_detail_close"
                color="primary"
                onClick={handleOnClose}
              />
            </PanelActionBox>
          </HorizontalBox>
          <Box>
            <TabsPanel
              id={"contact_detail_panel_tabs"}
              value={activeTabIndex}
              handleChange={handleTabChange}
              options={[
                {
                  label: "Details",
                  disableCheck: false,
                },
                {
                  label: "Permissions",
                  disableCheck: false
                },
              ]}
            />
          </Box>
        </Box>

        <TabContent index={0} value={activeTabIndex}>
          <ReadOnlyContactDetails
            addressValues={contactDetail?.address}
            valuesContactDetail={contactDetail?.contactDetails}
          />
        </TabContent>
        <TabContent index={1} value={activeTabIndex} height="81%">
          <PermissionsBox>
            <ReadOnlyContactPermissions
              preloadedContactId={selectedContactId}
              roleList={roleFilters}
            />
          </PermissionsBox>
        </TabContent>

        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
          stickToRight
        >
          <ActionButtonBox>
            <Button
              id={"btn_contact_detail_panel_close"}
              variant="outlined"
              onClick={handleOnClose}
              text={"Close"}
              color={"secondary"}
              fullWidth
            />
          </ActionButtonBox>
        </HorizontalBox>

        <StyledBackdrop
          open={isLoadingContact}
        />
        <ProgressPanel
          id={"progress_contact_panel"}
          text="Loading"
          showProgress={isLoadingContact}
        />
      </InformationPanel>
    </React.Fragment>
  );
};

export default ReadOnlyContactPanel;
