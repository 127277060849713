import { useContext, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import {
  addPortfolioCompany,
  deleteCustomProperty,
  deletePortfolioCompany,
  getCustomProperties,
  updatePortfolioCompany,
  uploadPortfolioCompanyLogo,
} from "../../../../services/portfolioCompanies.service";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import {
  PortfolioCompany,
  PortfolioCompanyCRUDStatus,
  PortfolioCompanyNew,
} from "../../../../utils/types/portfolioCompany.type";
import {
  CREATE_PORTFOLIO_COMPANY_ERROR,
  CREATE_PORTFOLIO_COMPANY_SUCCESS,
  DELETE_PORTFOLIO_COMPANY_ERROR,
  DELETE_PORTFOLIO_COMPANY_SUCCESS,
  PORTFOLIO_COMPANY_FORM_DEFAULT_VALUE,
  UPDATE_PORTFOLIO_COMPANY_ERROR,
  UPDATE_PORTFOLIO_COMPANY_SUCCESS,
} from "../PortfolioCompanyList.constants";

type Props = {
  portfolioCompany?: PortfolioCompany;
  onClose: Function;
  fetchAllPortfolioCompanies: Function;
  isNewPortfolioCompany: boolean;
  fundId?: string;
};

export const usePortfolioCompanyDetails = ({
  portfolioCompany,
  onClose,
  fetchAllPortfolioCompanies,
  isNewPortfolioCompany,
  fundId,
}: Props) => {
  const [loading, setLoading] = useState<PortfolioCompanyCRUDStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [nameUnavailableError, setNameUnavailableError] =
    useState<boolean>(false);
  const [isLogoUpdated, setIsLogoUpdated] = useState<boolean>(false);

  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<PortfolioCompany>({
    defaultValues: portfolioCompany ?? PORTFOLIO_COMPANY_FORM_DEFAULT_VALUE,
  });
  const { isDirty } = useFormState({ control });
  const { fields } = useFieldArray({ control, name: "additionalFieldsForm" });

  useEffect(() => {
    reset(portfolioCompany);
    setIsLogoUpdated(false);
    setNameUnavailableError(false);
  }, [portfolioCompany, reset]);

  useEffectAsync(async (isCanceled) => {
    if (isCanceled?.()) return;
    const clientCustomProperties: any = await getCustomProperties();

    if (clientCustomProperties.items) {
      if (isNewPortfolioCompany) {
        portfolioCompany = PORTFOLIO_COMPANY_FORM_DEFAULT_VALUE;

        const customPropertyValues = clientCustomProperties.items.map(
          (clientCustomProperty: any) => {
            const propertyValue: { [key: string]: boolean } | any = {};

            if (clientCustomProperty.type === "MULTIPLE_CHECKBOX") {
              clientCustomProperty.values.map((value: string) => {
                propertyValue[value] = false;
              });
            }

            return {
              id: null,
              values: [],
              value:
                clientCustomProperty.type === "MULTIPLE_CHECKBOX"
                  ? propertyValue
                  : null,
              customProperty: { ...clientCustomProperty },
            };
          }
        );

        setValue("additionalFieldsForm", customPropertyValues);
      } else {
        const customPropertyValues = clientCustomProperties.items.map(
          (clientCustomProperty: any) => {
            const portCoProperty = portfolioCompany?.additionalProperties?.find(
              (property) => {
                return property.customProperty.id === clientCustomProperty.id;
              }
            );

            let customPropertyValue: { [key: string]: boolean } | any = null;

            if (clientCustomProperty.type === "MULTIPLE_CHECKBOX") {
              customPropertyValue = {};
              clientCustomProperty.values.map(
                (value: string, index: number) => {
                  if (portCoProperty && portCoProperty.values) {
                    const booleanValue =
                      portCoProperty.values[index] === "true";

                    customPropertyValue[value] = booleanValue;
                  } else {
                    customPropertyValue[value] = false;
                  }
                }
              );
            } else {
              if (
                portCoProperty &&
                portCoProperty?.values &&
                Array.isArray(portCoProperty?.values) &&
                portCoProperty?.values?.length > 0 &&
                portCoProperty.values[0]
              ) {
                customPropertyValue = portCoProperty.values[0];
              }
            }

            return {
              id: portCoProperty?.id,
              values: [],
              value: customPropertyValue,
              customProperty: { ...clientCustomProperty },
            };
          }
        );

        setValue("additionalFieldsForm", customPropertyValues);
      }
    }
  }, []);

  const createPortfolioCompany = async (data: PortfolioCompanyNew) => {
    try {
      const noLogoData = {
        ...data,
        logo: null,
      };

      const newPortfolioCompanyResponse: any = await addPortfolioCompany(
        noLogoData
      );

      if (newPortfolioCompanyResponse) {
        if (isLogoUpdated) {
          if (data.logo) {
            await uploadLogoFile(
              data.logo as File,
              newPortfolioCompanyResponse.id as string
            );
          }
        }

        fetchAllPortfolioCompanies();
        informationAlert(CREATE_PORTFOLIO_COMPANY_SUCCESS, "success");
        closeDrawer();
      }
    } catch (error: any) {
      if (error && error.response && error.response.data.violations) {
        const nameError = error.response.data.violations.filter(
          (err: any) => err.fieldName === "name"
        );

        if (nameError.length > 0 && nameError[0].message === "is not unique") {
          setNameUnavailableError(true);
        }
      }
      informationAlert(CREATE_PORTFOLIO_COMPANY_ERROR, "error");
    }
  };

  const editPortfolioCompany = async (data: PortfolioCompany) => {
    try {
      if (isLogoUpdated) {
        if (data.logo) {
          const response = await uploadLogoFile(
            data.logo as File,
            portfolioCompany?.id ?? ""
          );

          data.logo = response as string;
        }
      }

      await updatePortfolioCompany(data, portfolioCompany?.id ?? "", clientId);

      fetchAllPortfolioCompanies();
      informationAlert(UPDATE_PORTFOLIO_COMPANY_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(UPDATE_PORTFOLIO_COMPANY_ERROR, "error");
    }
  };

  const createUpdatePortfolioCompany = async (data: any) => {
    let updatedAdditionalProperties;

    // process custom properties
    if (data && data.additionalFieldsForm) {
      updatedAdditionalProperties = data.additionalFieldsForm.map(
        (field: any) => {
          if (field.customProperty.type === "MULTIPLE_CHECKBOX") {
            const convertedToArray = Object.values(field.value);

            field.value = convertedToArray;
          }

          field.value = field.value === null ? "" : field.value;
          const fieldValues: any[] = !Array.isArray(field.value)
            ? [field.value]
            : field.value;

          return {
            ...field,
            values: fieldValues,
          };
        }
      );
    }

    if (isNewPortfolioCompany) {
      const resultData = {
        ...(isNewPortfolioCompany ? PORTFOLIO_COMPANY_FORM_DEFAULT_VALUE : {}),
        ...data,
        tenantId: clientId,
        fundIds: fundId ? [fundId] : [],
        isPublic: data.isPublic ? data.isPublic : false,
        id: null,
        holdingStatus: "ACTIVE",
        address: {
          ...data.address,
          id: null,
        },
        additionalProperties: updatedAdditionalProperties,
      };

      setLoading(PortfolioCompanyCRUDStatus.Adding);
      await createPortfolioCompany(resultData);
    } else {
      const fundIds = data.funds.map((fund: any) => fund.id);

      const resultData = {
        ...data,
        fundIds,
        additionalProperties: updatedAdditionalProperties,
      };

      setLoading(PortfolioCompanyCRUDStatus.Updating);
      await editPortfolioCompany(resultData);
    }

    setLoading(undefined);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleDelete = () => {
    setToBeDeleted(portfolioCompany?.id ?? "");
  };

  const handleCancelDelete = () => {
    setToBeDeleted(undefined);
  };

  const handleConfirmDelete = async (companyToBeDeleted: string) => {
    if (!companyToBeDeleted) {
      return;
    }
    setToBeDeleted(undefined);
    try {
      setLoading(PortfolioCompanyCRUDStatus.Deleting);
      await deletePortfolioCompany(companyToBeDeleted as string);
      fetchAllPortfolioCompanies();
      informationAlert(DELETE_PORTFOLIO_COMPANY_SUCCESS, "success");
      closeDrawer();
      setLoading(undefined);
    } catch (error) {
      informationAlert(DELETE_PORTFOLIO_COMPANY_ERROR, "error");
      setLoading(undefined);
    }
  };

  const onLogoChange = (file: File) => {
    setIsLogoUpdated(true);
  };

  const getImagePreview = (file?: File | null | string) => {
    if (!file || typeof file === "string") {
      return undefined;
    }
    return URL.createObjectURL(file);
  };

  const uploadLogoFile = async (
    file: File | null,
    portfolioCompanyId: string
  ) => {
    if (file && portfolioCompanyId) {
      try {
        return await uploadPortfolioCompanyLogo(
          clientId,
          portfolioCompanyId,
          file
        );
      } catch (error) {
        informationAlert("Error in uploading logo", "error");
      }
    }
  };

  const isPublic = watch("isPublic");
  const funds = watch("funds");
  const investmentTypes = watch("investmentTypes");
  const logo = watch("logo");

  return {
    loading,
    register,
    handleSubmit,
    nameUnavailableError,
    setValue,
    errors,
    control,
    fields,
    createUpdatePortfolioCompany,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    getImagePreview,
    onLogoChange,
    isPublic,
    fundId,
    funds,
    investmentTypes,
    logo,
  };
};

export const useTabs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const detailsRef = useRef<HTMLInputElement>(null);
  const fundsRef = useRef<HTMLInputElement>(null);
  const additionalFieldsRef = useRef<HTMLInputElement>(null);
  const logoRef = useRef<HTMLInputElement>(null);

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 1:
        fundsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        additionalFieldsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        logoRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  return {
    currentTab,
    detailsRef,
    fundsRef,
    additionalFieldsRef,
    logoRef,
    handleTabChange,
  };
};
