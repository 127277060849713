import { LinkProps, Typography } from "@mui/material";
import { Link as MUILink } from "@mui/material";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export interface Props extends Omit<LinkProps, "component"> {
  to: any,
  children: any;
}

export const RouterLink = (props: Props): ReactElement => {
  const { children, to, ...other } = props;

  return (
    <MUILink component={Link} {...props} underline="none">
      <Typography variant="label">
        {children}
      </Typography>
    </MUILink>
  );
};
