import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { Box } from "@mui/material";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { ViewPdf } from "./PdfViewer.styles";
import { usePdfViewer } from "./usePdfViewer.hooks";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface Props {
  pdfLink: string;

  renderAnnotationLayer?: boolean;
  renderInteractiveForms?: boolean;
  renderTextLayer?: boolean;
  sideView?: boolean;
  clientView?: boolean;
}

const PDFViewer = ({
  pdfLink,
  renderAnnotationLayer = true,
  renderInteractiveForms = false,
  renderTextLayer = false,
  sideView = false,
  clientView = false,
}: Props) => {
  const { numPages, onDocumentLoadSuccess } = usePdfViewer();

  return (
    <Box>
      <ViewPdf
        sideView={sideView.toString()}
        clientView={clientView.toString()}
      >
        <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={1.5}
              renderAnnotationLayer={renderAnnotationLayer}
              renderInteractiveForms={renderInteractiveForms}
              renderTextLayer={renderTextLayer}
            />
          ))}{" "}
        </Document>
      </ViewPdf>
    </Box>
  );
};

export default React.memo(PDFViewer);
