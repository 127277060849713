import DeleteOutline from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';

import ExportIcon from '../../../../assets/images/icons/icon_export.svg';
import { StringCell } from '../../../../components/DataGrid/DataGrid.styles';
import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  deleteEntity,
  getAllEntities,
  getAllGeneralEntities,
  getEntity,
} from '../../../../services/arkGL.service';
import { uploadFile } from '../../../../services/uploads.service';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import {
  ArkGLEntity,
  SelectedEntity,
} from '../../../../utils/types/arkGLEntity.type';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';
import { DetailsType } from '../../../../utils/types/form.type';
import { CustomType, DataGridColDef } from '../../../../utils/types/listItems';
import { ImageItem } from '../../../../utils/types/listItems';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  csvHeaders,
  DELETE_ENTITY_ERROR,
  DELETE_ENTITY_SUCCESS,
  DOWNLOAD_TEMPLATE_ERROR,
  EntityAction,
  EntityFilter,
  GET_ALL_ENTITIES_ERROR,
} from './EntityList.constants';
import { defaultHeaderList } from './EntityList.defaultHeaders';

const initialEntity: SelectedEntity = {
  entity: undefined,
  type: undefined,
};

export const useEntityList = () => {
  const { fundId } = useParams<{ fundId: string }>();
  const { state, informationAlert } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [showSuggestionPopover, setShowSuggestionPopover] =
    useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>();
  const [entityFilteredList, setEntityFilteredList] = useState<any[]>([]);
  const [entityResponse, setEntityResponse] = useState([]);
  const [headerList, setHeaderList] = useState(defaultHeaderList);
  const [entitySelectionModel, setEntitySelectionModel] = useState([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    defaultHeaderList.length - 1
  );
  const [nameList, setNameList] = useState<any[]>([]);
  const [typeList, setTypeList] = useState<any[]>([]);
  const [stateList, setStateList] = useState<any[]>([]);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [entityList, setEntityList] = useState([]);
  const [showDeletePrompt, setDeletePrompt] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [selectedDelete, setSelectedDelete] = useState<SelectedEntity>();
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [csvData, setCsvData] = useState<Record<any, any>[]>([]);
  const [csvFilename, setCsvFilename] = useState<string>('');

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);
  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const readOnly: boolean = !!isFundAdmin;

  useEffectAsync(async (isCanceled: any) => {
    await fetchAllEntities(isCanceled);
  }, []);

  const fetchAllEntities = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(true);
      const entityResponse = await getAllGeneralEntities();

      if (isCanceled?.()) return;

      const nameListData: any = [];
      const stateListData: any = [];
      const countryListData: any = [];
      const typeListData: any = [];

      entityResponse.items.map((entity: any) => {
        nameListData.push({
          id: entity.name ? entity.name : '',
          name: entity.name ? entity.name : 'None',
        });

        typeListData.push({
          id: entity.type ? entity.type : '',
          name: entity.type ? entity.type : 'None',
        });

        stateListData.push({
          id: entity?.mailingAddress?.state
            ? entity?.mailingAddress?.state
            : '',
          name: entity?.mailingAddress?.state
            ? entity?.mailingAddress?.state
            : 'None',
        });

        countryListData.push({
          id: entity?.mailingAddress?.country
            ? entity?.mailingAddress?.country
            : '',
          name: entity?.mailingAddress?.country
            ? entity?.mailingAddress?.country
            : 'None',
        });
      });

      const isUniqueName = (obj: any, index: any, self: any) => {
        return index === self.findIndex((o: any) => o.name === obj.name);
      };

      const uniqueNameList = nameListData.filter(isUniqueName);
      const uniqueStateList = stateListData.filter(isUniqueName);
      const uniqueCountryList = countryListData.filter(isUniqueName);
      const uniqueTypeList = typeListData.filter(isUniqueName);

      setNameList(uniqueNameList);
      setStateList(uniqueStateList);
      setCountryList(uniqueCountryList);
      setTypeList(uniqueTypeList);

      setEntityResponse(entityResponse.items);
      setEntityList(entityResponse.items);

      setIsLoading(false);
    } catch {
      informationAlert(GET_ALL_ENTITIES_ERROR, 'error');
    }
  };

  useEffect(() => {
    setEntityFilteredList(entityList);
  }, [headerList, entityList]);

  const onSearch = (
    event: any,
    newValue: React.SetStateAction<string> | null
  ) => {
    if (typeof newValue === 'string') {
      setSearch(newValue);
    } else if (newValue === null) {
      setSearch('');
    }
    if (newValue !== null) setShowSuggestionPopover(false);
  };

  useEffect(() => {
    if (entityList.length) {
      initializeSearchOptions(entityList);
    }
  }, [entityList]);

  useEffect(() => {
    if (csvData.length) csvLinkRef?.current?.link.click();
  }, [csvData]);

  const initializeSearchOptions = (response: any[]) => {
    const options: string[] = [];

    if (response.length > 1) {
      response.forEach((entity: any) => {
        if (entity.name) {
          options.push(entity?.name);
        }
        if (entity.type) {
          options.push(entity?.type);
        }
        if (entity.phoneNumber) {
          options.push(entity?.phoneNumber);
        }
        if (entity.emailAddress) {
          options.push(entity?.emailAddress);
        }
        if (entity.tin) {
          options.push(entity?.tin);
        }
        if (entity.mailingAddress) {
          options.push(entity?.mailingAddress?.state);
          options.push(entity?.mailingAddress?.street1);
          options.push(entity?.mailingAddress?.street2);
          options.push(entity?.mailingAddress?.postalCode);
          options.push(entity?.mailingAddress?.country);
          options.push(entity?.mailingAddress?.city);
        }
      });
    } else {
      if (response[0].name !== undefined) {
        options.push(response[0]?.name);
      }
      if (response[0].type !== undefined) {
        options.push(response[0]?.type);
      }
      if (response[0].phoneNumber !== undefined) {
        options.push(response[0]?.phoneNumber);
      }
      if (response[0].emailAddress !== undefined) {
        options.push(response[0]?.emailAddress);
      }
      if (response[0].tin !== undefined) {
        options.push(response[0]?.tin);
      }
      if (response[0].mailingAddress !== undefined) {
        options.push(response[0]?.mailingAddress?.state);
        options.push(response[0]?.mailingAddress?.street1);
        options.push(response[0]?.mailingAddress?.street2);
        options.push(response[0]?.mailingAddress?.postalCode);
        options.push(response[0]?.mailingAddress?.country);
        options.push(response[0]?.mailingAddress?.city);
      }
    }

    const uniqueOptions = options.filter(
      (option, idx) => options.indexOf(option) === idx && !!option
    );

    setSearchOptions(uniqueOptions);
  };

  const searchEntityList = (searchText: string) => {
    if (entityList) {
      if (searchText === '') {
        setEntityList(entityList);
      } else if (typeof searchText === 'string') {
        const entityListData: any = [...entityList];

        const updatedEntityList: any = [];

        entityListData.forEach((entity: any) => {
          if (entity.name) {
            if (entity.name.match(new RegExp(searchText, 'i'))) {
              updatedEntityList.push(entity);
            }
          }
          if (entity.type !== null) {
            if (entity.type) {
              if (entity.type.match(new RegExp(searchText, 'i'))) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.phoneNumber) {
            if (entity.phoneNumber.match(new RegExp(searchText, 'i'))) {
              updatedEntityList.push(entity);
            }
          }
          if (entity.emailAddress) {
            if (entity.emailAddress.match(new RegExp(searchText, 'i'))) {
              updatedEntityList.push(entity);
            }
          }
          if (entity.tin) {
            if (entity.tin.match(new RegExp(searchText, 'i'))) {
              updatedEntityList.push(entity);
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.street1) {
              if (
                entity.mailingAddress.street1.match(new RegExp(searchText, 'i'))
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.street2) {
              if (
                entity.mailingAddress.street2.match(new RegExp(searchText, 'i'))
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.state) {
              if (
                entity.mailingAddress.state.match(new RegExp(searchText, 'i'))
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.country) {
              if (
                entity.mailingAddress.country.match(new RegExp(searchText, 'i'))
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.postalCode) {
              if (
                entity.mailingAddress.postalCode.match(
                  new RegExp(searchText, 'i')
                )
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
          if (entity.mailingAddress !== null) {
            if (entity.mailingAddress.city) {
              if (
                entity.mailingAddress.city.match(new RegExp(searchText, 'i'))
              ) {
                updatedEntityList.push(entity);
              }
            }
          }
        });

        setEntityList(updatedEntityList);
      }
    }
  };

  useEffect(() => {
    searchEntityList(search);
    if (search === '' && entityResponse.length) {
      setEntityList(entityResponse);
    }
  }, [search]);

  const handleNewButtonAction = (actionId: string, event: any) => {
    switch (actionId) {
      case AddNewButtonOptions.AddNew:
        {
          setSelectedEntity({
            entity: undefined,
            type: DetailsType.New,
          });
        }
        break;
      case AddNewButtonOptions.UploadFromTemplate:
        {
          const file = event?.target?.files?.[0];

          if (file) {
            setUploadedFile(file);
          }
        }
        break;
      case EntityAction.DownloadTemplate:
        handleTemplateDownload();
        break;
    }
  };

  const clearUploadedFile = () => {
    setUploadedFile(undefined);
  };

  const clearUploadCompleted = () => {
    setIsUploadComplete(false);
  };

  const handleTemplateDownload = async () => {
    const cdnEndpoint: string = process.env
      .REACT_APP_CDN_ENDPOINT_URL as string;
    const fileLink = cdnEndpoint.concat(
      'templates/standard_general_entity_template.csv'
    );
    const filename = 'standard_memo_entities_template.csv';

    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      informationAlert(DOWNLOAD_TEMPLATE_ERROR, 'error');
    }
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) return;
    try {
      setIsLoading(true);
      await uploadFile('entities', uploadedFile);
      informationAlert('Entity upload successful.', 'success');
      setUploadedFile(undefined);
      setIsUploadComplete(true);
      setIsLoading(false);
      await fetchAllEntities();
    } catch (exception) {
      setUploadedFile(undefined);
      setIsLoading(false);
      informationAlert('Error uploading Entity.', 'error');
    }
  };

  const handleOnView = (entityId: string, entity: ArkGLEntity) => {
    if (!entityId) return;

    const originalEntity: any = entityResponse.find(
      (e: any) => e.id === entity.id
    );

    setSelectedEntity({
      entity: { ...originalEntity },
      type: DetailsType.Edit,
    });
  };

  const handleDelete = (entityId: string, entity: ArkGLEntity) => {
    if (!entityId) return;

    const originalEntity: any = entityResponse.find(
      (e: any) => e.id === entity.id
    );

    setSelectedDelete({
      entity: { ...originalEntity },
      type: DetailsType.Edit,
    });

    setDeletePrompt(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      if (selectedDelete) {
        await deleteEntity(selectedDelete?.entity?.id);
        setEntitySelectionModel([]);
        informationAlert(DELETE_ENTITY_SUCCESS, 'success');
      }
      setIsDeleted(!isDeleted);
      setDeletePrompt(false);
      setSelectedDelete(undefined);
      setIsLoading(false);
    } catch (error) {
      informationAlert(DELETE_ENTITY_ERROR, 'error');
    }
  };

  const onDeleteCancel = () => {
    setDeletePrompt(false);
  };

  useEffectAsync(
    async (isCanceled: any) => {
      await fetchAllEntities(isCanceled);
    },
    [isDeleted]
  );

  const handleFilter = (filterName: EntityFilter, selected: string[]) => {
    setHeaderList((prevHeaderList: any) =>
      prevHeaderList?.map((header: any) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }
        return header;
      })
    );
  };

  const selectedNameList = useMemo(
    () => nameList?.map((item: any) => item.id),
    [nameList]
  );

  const selectedStateList = useMemo(
    () => stateList?.map((item: any) => item.id),
    [stateList]
  );

  const selectedCountryList = useMemo(
    () => countryList?.map((item: any) => item.id),
    [countryList]
  );

  const selectedTypeList = useMemo(
    () => typeList?.map((item: any) => item.id),
    [typeList]
  );

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header: any) => !header.hide && header?.type !== 'action'
    );

    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header: any) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  useEffect(() => {
    let filteredList: ArkGLEntity[] = entityList;

    headerList?.map((header: any) => {
      const auxList = filteredList ?? entityList;

      switch (header.inlineFilterName) {
        case EntityFilter.Name:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((entity: any) => {
              return header?.inlineFilterSelected?.some((selectedName: any) => {
                return selectedName === entity.name;
              });
            });
          }
          break;
        case EntityFilter.State:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((entity: any) => {
              return header?.inlineFilterSelected?.some(
                (selectedState: any) => {
                  if (entity?.mailingAddress?.state !== undefined) {
                    return selectedState === entity.mailingAddress.state;
                  }
                }
              );
            });
          }
          break;
        case EntityFilter.Country:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((entity: any) => {
              return header?.inlineFilterSelected?.some(
                (selectedCountry: any) => {
                  if (entity?.mailingAddress?.country !== undefined) {
                    return selectedCountry === entity.mailingAddress.country;
                  }
                }
              );
            });
          }
          break;
        case EntityFilter.Type:
          if (
            header.inlineFilterSelected?.length !==
            header.inlineFilterOptions?.length
          ) {
            filteredList = auxList?.filter((entity: any) => {
              return header?.inlineFilterSelected?.some((selectedName: any) => {
                return selectedName === entity.type;
              });
            });
          }
          break;
      }
    });

    setEntityFilteredList(filteredList);
  }, [headerList, entityList]);

  useEffect(() => {
    if (entityList) {
      intializeHeaderList();
    }
  }, [entityList]);

  useEffect(() => {
    intializeHeaderList();
  }, [nameList, typeList, stateList, countryList]);

  const intializeHeaderList = () => {
    const updatedHeaders: DataGridColDef[] = [
      {
        field: 'name',
        headerName: 'Name',
        hide: false,
        index: 1,
        sortable: true,
        type: 'string',
        filterable: true,
        inlineFilter: true,
        inlineFilterName: EntityFilter.Name,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: nameList ? nameList : [],
        inlineFilterSelected: selectedNameList,
        renderCell: (params: any) => {
          return <StringCell>{params.row.name}</StringCell>;
        },
        valueGetter: (params: any) => {
          return params.row.name ? params.row.name : '';
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        hide: false,
        index: 2,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (params?.row?.type) {
            return <StringCell>{params.row.type}</StringCell>;
          }
          return M_DASH_UNICODE;
        },
        valueGetter: (params: any) => {
          return params.row.type ? params.row.type : '';
        },
        filterable: true,
        inlineFilter: true,
        inlineFilterName: EntityFilter.Type,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: typeList ? typeList : [],
        inlineFilterSelected: selectedTypeList,
      },
      {
        field: 'phoneNumber',
        headerName: 'Phone',
        hide: false,
        index: 3,
        sortable: true,
        type: 'string',
        renderCell: (params: any) => {
          return <StringCell>{params.row.phoneNumber}</StringCell>;
        },
      },
      {
        field: 'emailAddress',
        headerName: 'Email',
        hide: false,
        index: 4,
        sortable: true,
        type: 'string',
        renderCell: (params: any) => {
          return <StringCell>{params.row.emailAddress}</StringCell>;
        },
      },
      {
        field: 'tin',
        headerName: 'TIN',
        hide: false,
        index: 5,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (params?.row?.tin) {
            return <StringCell>{params.row.tin}</StringCell>;
          }
          return M_DASH_UNICODE;
        },
      },
      {
        field: 'mailingAddress.street1',
        headerName: 'Address Line 1',
        hide: false,
        index: 6,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row?.mailingAddress?.street1) {
            return M_DASH_UNICODE;
          }
          return <StringCell>{params.row.mailingAddress.street1}</StringCell>;
        },
      },
      {
        field: 'mailingAddress.street2',
        headerName: 'Address Line 2',
        hide: false,
        index: 7,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row?.mailingAddress?.street2) {
            return M_DASH_UNICODE;
          }
          return <StringCell>{params.row.mailingAddress.street2}</StringCell>;
        },
      },
      {
        field: 'mailingAddress.city',
        headerName: 'City',
        hide: false,
        index: 8,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row?.mailingAddress?.city) {
            return M_DASH_UNICODE;
          }
          return <StringCell>{params.row.mailingAddress.city}</StringCell>;
        },
      },
      {
        field: 'mailingAddress.country',
        headerName: 'Country',
        hide: false,
        index: 9,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row.mailingAddress?.country) {
            return M_DASH_UNICODE;
          }
          return <StringCell>{params.row.mailingAddress.country}</StringCell>;
        },
        valueGetter: (params: any) => {
          return params.row.mailingAddress.country
            ? params.row.mailingAddress.country
            : '';
        },
        filterable: true,
        inlineFilter: true,
        inlineFilterName: EntityFilter.Country,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: countryList ? countryList : [],
        inlineFilterSelected: selectedCountryList,
      },
      {
        field: 'mailingAddress.state',
        headerName: 'State / Province',
        hide: false,
        index: 10,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row.mailingAddress?.state) {
            return M_DASH_UNICODE;
          }
          return <StringCell>{params.row.mailingAddress.state}</StringCell>;
        },
        valueGetter: (params: any) => {
          return params.row.mailingAddress.state
            ? params.row.mailingAddress.state
            : '';
        },
        filterable: true,
        inlineFilter: true,
        inlineFilterName: EntityFilter.State,
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: stateList ? stateList : [],
        inlineFilterSelected: selectedStateList,
      },
      {
        field: 'mailingAddress.postalCode',
        headerName: 'ZIP Code',
        hide: false,
        index: 11,
        type: 'string',
        sortable: true,
        renderCell: (params: any) => {
          if (!params?.row?.mailingAddress?.postalCode) {
            return M_DASH_UNICODE;
          }
          return (
            <StringCell>{params.row.mailingAddress.postalCode}</StringCell>
          );
        },
      },
      {
        field: 'action',
        headerName: '',
        hide: false,
        hideable: false,
        index: 12,
        type: 'action',
        customType: CustomType.Action,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: (params: any) => {
          return (
            <div>
              <Button
                id={`${params?.row?.id}_view_button`}
                variant="text"
                disableElevation
                startIcon={<VisibilityIcon />}
                onClick={() => handleOnView(params.row.id, params.row)}
              >
                View
              </Button>
              {isSuperAdminOrClientAdmin ? (
                <Button
                  id={`${params?.row?.id}_delete_button`}
                  variant="text"
                  color="error"
                  disableElevation
                  startIcon={<DeleteOutline />}
                  onClick={() => handleDelete(params.row.id, params.row)}
                >
                  Delete
                </Button>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    setHeaderList(updatedHeaders);
  };

  const bulkExportActions: ImageItem[] = useMemo(() => {
    const actions = [];
    const exportSelected = {
      id: EntityAction.ExportSelected,
      text: `Export Selected (${entitySelectionModel?.length || 0})`,
      icon: <img src={ExportIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    };
    const exportAll = {
      id: EntityAction.ExportAll,
      text: 'Export All',
      icon: <img src={ExportIcon} alt="Export All" height="15" />,
      optionsSelected: 0,
    };

    if (entitySelectionModel?.length > 0) {
      actions.push(exportSelected);
    }

    actions.push(exportAll);
    return actions;
  }, [entitySelectionModel]);

  const handleBulkAction = (actionId: EntityAction) => {
    setIsLoading(true);
    switch (actionId) {
      case EntityAction.ExportAll:
        handleExportCsv(actionId);
        break;
      case EntityAction.ExportSelected:
        handleExportCsv(actionId);
        break;
    }
    setIsLoading(false);
  };

  const onDetailsPanelClose = () => {
    setSelectedEntity(initialEntity);
  };

  const handleExportCsv = (actionId: string) => {
    let csvExportData;

    switch (actionId) {
      case EntityAction.ExportSelected:
        csvExportData = entitySelectionModel.map((selectedId) => {
          const entity = entityFilteredList.find(
            (e) => e.id === selectedId
          ) as any;

          return {
            name: entity?.name,
            phoneNumber: entity?.phoneNumber,
            emailAddress: entity?.emailAddress,
            tin: entity?.tin,
            type: entity?.type,
            'mailingAddress.street1': entity?.mailingAddress?.street1,
            'mailingAddress.street2': entity?.mailingAddress?.street2,
            'mailingAddress.city': entity?.mailingAddress?.city,
            'mailingAddress.state': entity?.mailingAddress?.state,
            'mailingAddress.postalCode': entity?.mailingAddress?.postalCode,
            'mailingAddress.country': entity?.mailingAddress?.country,
            is1099: entity?.is1099 ? 'Y' : 'N',
          };
        });
        break;
      case EntityAction.ExportAll:
        csvExportData = entityFilteredList.map((entity) => {
          return {
            name: entity.name,
            phoneNumber: entity.phoneNumber,
            emailAddress: entity.emailAddress,
            tin: entity.tin,
            type: entity.type,
            'mailingAddress.street1': entity.mailingAddress?.street1,
            'mailingAddress.street2': entity.mailingAddress?.street2,
            'mailingAddress.city': entity.mailingAddress?.city,
            'mailingAddress.state': entity.mailingAddress?.state,
            'mailingAddress.postalCode': entity.mailingAddress?.postalCode,
            'mailingAddress.country': entity.mailingAddress?.country,
            is1099: entity.is1099 ? 'Y' : 'N',
          };
        });
        break;
    }

    if (csvExportData) {
      setCsvFilename(
        `Memo_Entities_${DateTimeFormat.getFormattedDate(new Date(), '_')}`
      );
      setCsvData(csvExportData);
    }
  };

  const resetCsvData = () => {
    setCsvData([]);
    setCsvFilename('');
  };

  return {
    isLoading,
    onSearch,
    searchOptions,
    search,
    showSuggestionPopover,
    setShowSuggestionPopover,
    readOnly,
    handleNewButtonAction,
    entityFilteredList,
    entityResponse,
    headerList,
    handleOnView,
    entitySelectionModel,
    setEntitySelectionModel,
    activeHeaderFields,
    handleFilter,
    handleUpdateHeader,
    isFundAdmin,
    isBasicAdmin,
    isSuperAdminOrClientAdmin,
    selectedEntity,
    setSelectedEntity,
    showDeletePrompt,
    onDeleteCancel,
    onDeleteConfirm,
    bulkExportActions,
    handleBulkAction,
    fetchAllEntities,
    onDetailsPanelClose,
    selectedDelete,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    clearUploadCompleted,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
  };
};
