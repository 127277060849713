import sortBy from "lodash/sortBy";

import { ContactPermission } from "../../../utils/types/contactPermission.type";
import { FundItem } from "../../../utils/types/fund.type";
import { InvestorFilter } from "../../../utils/types/investor.type";

export const formattedContactInvestmentsList = (
  contactInvestmentsResponse: ContactPermission,
  preloadedContactId: string,
  investorFilters: InvestorFilter[],
  fundFilters: FundItem[],
) => {
  const investorList: any[] = [];
  const investorAndFunds: Record<string, Set<string>> = {};
  const fundNames: Record<string, string> = {};

  contactInvestmentsResponse.rows.forEach((item) => {
    const investorFound = investorList.find(
      (investor: any) => investor.id === item.investor.id
    );
    
    if(!investorAndFunds[item.investor.id]) {
      investorAndFunds[item.investor.id] = new Set([item.fund.id]);
    } else if(!investorAndFunds[item.investor.id].has(item.fund.id)) {
      investorAndFunds[item.investor.id] = investorAndFunds[item.investor.id].add(item.fund.id);
    }

    const fundId = item.investor.id + "_" + item.fund.id;

    if (!investorFound) {
      investorList.push({
        id: item.investor.id,
        name: item.investor.name,
        investor: [item.investor.name],
        hierarchy: [item.investor.id],
        [item.role.id]: true,
        isLocked: !preloadedContactId,
      });

      investorList.push({
        id: fundId,
        name: item.fund.name,
        investor: [item.investor.name, item.fund.name],
        hierarchy: [item.investor.id, fundId],
        [item.role.id]: true,
        isLocked: !preloadedContactId,
      });
    } else {
      const fundFound = investorList.find(
        (investor: any) => investor.id === item.investor.id + "_" + item.fund.id
      );
      
      if (!fundFound) {
        investorList.push({
          id: fundId,
          name: item.fund.name,
          investor: [item.investor.name, item.fund.name],
          hierarchy: [item.investor.id, fundId],
          [item.role.id]: true,
          isLocked: true,
        });
      } else {
        fundFound[item.role.id] = true;
      }
      investorFound[item.role.id] = true;
    }
  });

  fundFilters.forEach((fund) => {
    fundNames[fund.id] = fund.name;
  });

  investorList.forEach(investor => {
    const investorFound = investorFilters.find((investorFilter: any) => investorFilter.id === investor.id);

    investorFound && investorFound.funds.forEach((fund: any) => {
      if(!investorAndFunds[investor.id].has(fund)) {
        const fundName = fundNames[fund];
        const id = investor.id + "_" + fund;

        investorList.push({
          id: id,
          name: fundName,
          investor: [investor.name, fundName],
          hierarchy: [investor.id, id],
          isLocked: true,
        });
      }
    });
  });

  return sortBy(investorList, ["hierarchy", "name"]);
};

export const formattedSingleContactInvestment = (
  investorFunds: InvestorFilter,
  roles: Record<string, boolean>,
  fundItems: Record<string, string>,
) => {
  const investorList: any[] = [];

  investorList.push({
    id: investorFunds.id,
    name: investorFunds.name,
    investor: [investorFunds.name],
    hierarchy: [investorFunds.id],
    isLocked: false,
    ...roles,
  });

  investorFunds.funds.forEach((fund) => {
    const fundId = `${investorFunds.id}_${fund}`;
    const fundName = fundItems[fund] || "";

    investorList.push({
      id: fundId,
      name: fundName,
      investor: [investorFunds.name, fundName],
      hierarchy: [investorFunds.id, fundId],
      isLocked: false,
      ...roles
    });

  });

  return sortBy(investorList, ["hierarchy", "name"]);
};