import { Box, Dialog,DialogActions,DialogContentText,DialogTitle, styled, Typography } from "@mui/material";

export const StyledDialog = styled(Dialog)(({
  theme
}) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(3, 6),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(1)
  },
  '&  .MuiBackdrop-root': {
    backgroundColor: theme.palette.common.modalBackground,
  }
}));

export const StyledDialogTitle = styled(DialogTitle)(({
  theme
}) =>({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(26),
  lineHeight: 1.1,
  color: theme.palette.common.black,
  textAlign: "center",
  padding: 0,
  marginBottom: theme.spacing(2)
}));

export const StyledDialogContentText = styled(DialogContentText)(({
  theme
}) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: 1.22,
  color: theme.palette.common.black,
  textAlign: "center",
}));

export const StyledDialogActions = styled(DialogActions)(({
  theme
}) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: "center",
  marginTop: theme.spacing(2),
}));

export const StyledInputWrapper = styled(Box)(({
  theme
}) => ({
  margin: theme.spacing(3, 0),
  padding: theme.spacing(0, 3),
}));

export const StyledInputCaption = styled(Typography)<{ component: React.ElementType }>(({
  theme
}) => ({
  marginBottom: theme.spacing(1),
}));
