import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../core/context/appContextProvider';
import { updateTransactionMapping } from '../../../services/client.service';
import { useClientTransactionMappingEffect } from '../../../services/hooks/useClientsEffect/useClientTransactionMappingEffect.hooks';
import { GENERIC_ERROR_MESSAGE } from '../../../utils/constants/text.constants';
import {
  ClientTransMapping,
  ClientTransTypeColumn,
  ClientTransTypeRow,
} from '../../../utils/types/clientTransMapping.type';
import { ClientTabs } from '../constants';

interface RouteProp {
  id: string;
  arkTag: string;
  section: string;
}

type DeleteType = 'row' | 'column';

type MetricFormat = {
  metricSign: string | null;
  metricFractionDigit: number;
};

type ToBeDeleted = {
  type: DeleteType;
  index: number;
};

const newColumn = {
  code: '',
  index: 0,
  label: '',
  metricFractionDigit: 0,
  metricSign: '',
  useMetric: false,
  dateRange: '',
};

const newRow = {
  index: 0,
  metricFractionDigit: 0,
  transactionCount: 0,
  metricSign: '',
  type: '',
  useMetric: false,
  values: [],
};

type AddUpdateColumn = {
  type: 'add' | 'update';
  column: ClientTransTypeColumn;
  currentIndex: number;
};

type AddUpdateRow = {
  type: 'add' | 'update';
  row: ClientTransTypeRow;
  currentIndex: number;
};

export const useTransactionMapping = () => {
  const { id, arkTag } = useParams<RouteProp>();
  const [toBeDeleted, setToBeDeleted] = useState<ToBeDeleted | undefined>();
  const [loading, setLoading] = useState(false);
  const [addUpdateColumn, setAddUpdateColumn] = useState<
    AddUpdateColumn | undefined
  >();
  const [addUpdateRow, setAddUpdateRow] = useState<AddUpdateRow | undefined>();
  const [hasChanges, setHasChanges] = useState(false);
  const [metricFormatMismatch, setMetricFormatMismatch] =
    useState<boolean>(false);
  const [
    metricRowToColumnMappingMismatch,
    setMetricRowToColumnMappingMismatch,
  ] = useState<boolean>(false);
  const {
    transactionMapping,
    loading: loadingTransactionMapping,
    fetchTransactionMappings,
    setTransactionMapping,
  } = useClientTransactionMappingEffect(id);
  const { informationAlert } = useContext(AppContext);
  const history = useHistory();
  const updateValue = (
    rowIndex: number,
    valueIndex: number,
    updatedValue: number
  ) => {
    let metricFormatMismatch: boolean = false;
    let currentMetricFormat: MetricFormat = {
      metricSign: null,
      metricFractionDigit: 0,
    };

    const updatedTransactionMapping = {
      columns: transactionMapping?.columns!,
      rows:
        transactionMapping?.rows?.map((transaction, txRowIndex) => {
          if (transaction.useMetric) {
            if (currentMetricFormat.metricSign === null) {
              currentMetricFormat = {
                metricSign: transaction.metricSign,
                metricFractionDigit: transaction.metricFractionDigit,
              };
            } else if (
              currentMetricFormat.metricSign !== transaction.metricSign ||
              currentMetricFormat.metricFractionDigit !==
                transaction.metricFractionDigit
            ) {
              metricFormatMismatch = true;
            }
          }
          if (txRowIndex === rowIndex) {
            return {
              ...transaction,
              values: transaction.values.map((value, index) => {
                if (index === valueIndex) {
                  return updatedValue;
                }
                return value;
              }),
            };
          }
          return transaction;
        }) || [],
    };

    const metricMismatch = updatedTransactionMapping.rows.some((r) => {
      return r.values.some((value, index) => {
        const column = updatedTransactionMapping?.columns?.[index];

        if (value) {
          if (r.useMetric === true) {
            if (column?.useMetric === false) {
              return true;
            }
          } else if (column?.useMetric === true) {
            return true;
          }
        }
        return false;
      });
    });

    setMetricRowToColumnMappingMismatch(metricMismatch);
    setMetricFormatMismatch(metricFormatMismatch);
    setHasChanges(true);
    setTransactionMapping(updatedTransactionMapping);
  };

  const deleteRow = (index: number) => {
    const updatedTransactionMapping = {
      columns: transactionMapping?.columns || [],
      rows:
        transactionMapping?.rows
          ?.filter((transaction, txRowIndex) => txRowIndex !== index)
          ?.map((row, index) => ({ ...row, index })) || [],
    };

    setTransactionMapping(updatedTransactionMapping);
    setToBeDeleted(undefined);
  };

  const deleteColumn = (index: number) => {
    const updatedTransactionMapping = {
      columns:
        transactionMapping?.columns
          ?.filter((column, columnIndex) => columnIndex !== index)
          ?.map((column, index) => ({ ...column, index })) || [],
      rows:
        transactionMapping?.rows?.map((row) => ({
          ...row,
          values: row.values?.filter(
            (value, valueIndex) => valueIndex !== index
          ),
        })) || [],
    };

    setTransactionMapping(updatedTransactionMapping);
    setToBeDeleted(undefined);
  };

  const onDelete = (type: DeleteType, index: number) => {
    setToBeDeleted({
      type,
      index,
    });
  };

  const onDeleteCancel = () => {
    setToBeDeleted(undefined);
  };

  const onDeleteConfirm = () => {
    if (toBeDeleted?.type === 'row') {
      deleteRow(toBeDeleted?.index);
    } else if (toBeDeleted?.type === 'column') {
      deleteColumn(toBeDeleted?.index);
    }
    setHasChanges(true);
  };

  const { columnLength, rowLength } = useMemo(
    () => ({
      columnLength: transactionMapping?.columns?.length || 0,
      rowLength: transactionMapping?.rows?.length || 0,
    }),
    [transactionMapping]
  );

  const onAddColumn = () => {
    setAddUpdateColumn({
      type: 'add',
      column: {
        ...newColumn,
        index: columnLength,
      },
      currentIndex: columnLength,
    });
  };

  const onUpdateColumn = (column: ClientTransTypeColumn) => {
    setAddUpdateColumn({
      type: 'update',
      column,
      currentIndex: column?.index || 0,
    });
  };

  const onCancelAddUpdateColumn = () => {
    setAddUpdateColumn(undefined);
  };

  const addNewColumn = (column: ClientTransTypeColumn) => {
    const currentColumns = [...(transactionMapping?.columns || [])];

    // Add column at specific index and rearrange the indexes
    currentColumns.splice(column?.index || 0, 0, column);
    const updatedColumns = currentColumns.map((column, index) => ({
      ...column,
      index,
    }));

    // add values to the new column
    const updatedRows =
      transactionMapping?.rows?.map((row) => {
        const currentValues = [...(row?.values || [])];

        currentValues.splice(column?.index || 0, 0, 0);

        return {
          ...row,
          values: [...currentValues],
        };
      }) || [];

    setTransactionMapping({
      columns: updatedColumns,
      rows: updatedRows,
    });
    setAddUpdateColumn(undefined);
  };

  const updateColumn = (column: ClientTransTypeColumn) => {
    const currentColumns = [...(transactionMapping?.columns || [])];

    // Update column at specific index and rearrange the indexes
    currentColumns.splice(addUpdateColumn?.currentIndex || 0, 1, column);
    currentColumns.splice(
      column?.index || 0,
      0,
      currentColumns.splice(addUpdateColumn?.currentIndex || 0, 1)[0]
    );
    const updatedColumns = currentColumns.map((currentColumn, index) => ({
      ...currentColumn,
      index,
    }));
    let updatedRows: ClientTransTypeRow[] | [] = [];

    // return existing rows if there is no change in index else update the index of values
    if (addUpdateColumn?.currentIndex === column?.index) {
      updatedRows = [...(transactionMapping?.rows || [])];
    } else {
      updatedRows =
        transactionMapping?.rows?.map((row) => {
          const currentValues = [...(row?.values || [])];

          currentValues.splice(
            column?.index || 0,
            0,
            currentValues.splice(addUpdateColumn?.currentIndex || 0, 1)[0]
          );

          return {
            ...row,
            values: [...currentValues],
          };
        }) || [];
    }

    const metricMismatch = updatedRows.some((r) => {
      return r.values.some((value, index) => {
        const column = updatedColumns[index];

        if (value) {
          if (r.useMetric === true) {
            if (column?.useMetric === false) {
              return true;
            }
          } else if (column?.useMetric === true) {
            return true;
          }
        }
        return false;
      });
    });

    setMetricRowToColumnMappingMismatch(metricMismatch);

    setTransactionMapping({
      columns: updatedColumns,
      rows: updatedRows,
    });
    setAddUpdateColumn(undefined);
  };

  const onSaveAddUpdateColumn = (column: ClientTransTypeColumn) => {
    if (addUpdateColumn?.type === 'add') {
      addNewColumn(column);
    } else if (addUpdateColumn?.type === 'update') {
      updateColumn(column);
    }
    setHasChanges(true);
  };

  const onAddRow = () => {
    setAddUpdateRow({
      type: 'add',
      row: {
        ...newRow,
        index: rowLength || 0,
        values: Array(columnLength).fill(0),
        metricSign: '',
      },
      currentIndex: rowLength || 0,
    });
  };

  const onUpdateRow = (row: ClientTransTypeRow) => {
    let metricSign = '';

    if (row.metricSign === '00/00/0000') {
      metricSign = '00/00/0000';
    } else {
      metricSign = `0.${
        Array(row?.metricFractionDigit || 0)
          .fill(0)
          .join('') || 0
      }${row?.metricSign ?? ''}`;
    }

    setAddUpdateRow({
      type: 'update',
      row: {
        ...row,
        metricSign: metricSign,
      },
      currentIndex: row?.index || 0,
    });
  };

  const onCancelAddUpdateRow = () => {
    setAddUpdateRow(undefined);
  };

  const addNewRow = (row: ClientTransTypeRow) => {
    const rows = [...(transactionMapping?.rows || []), row];

    setTransactionMapping({
      columns: transactionMapping?.columns!,
      rows,
    });
    setAddUpdateRow(undefined);
  };

  const updateRow = (row: ClientTransTypeRow) => {
    const updatableColumns: Array<number> = [];
    const rows =
      transactionMapping?.rows?.map((r) => {
        if (r?.index === row?.index) {
          updatableColumns.push(...row.values);
          return row;
        }
        return r;
      }) || [];

    const metricMismatch = rows.some((r) => {
      return r.values.some((value, index) => {
        const column = transactionMapping?.columns?.[index];

        if (value) {
          if (r.useMetric === true) {
            if (column?.useMetric === false) {
              return true;
            }
          } else if (column?.useMetric === true) {
            return true;
          }
        }
        return false;
      });
    });

    setMetricRowToColumnMappingMismatch(metricMismatch);

    setTransactionMapping({
      columns: transactionMapping?.columns?.map(
        (col: ClientTransTypeColumn, index: number) => {
          if (updatableColumns[index]) {
            if (col.useMetric) {
              return {
                ...col,
                metricSign: row.metricSign,
                metricFractionDigit: row.metricFractionDigit,
              };
            } else {
              return {
                ...col,
                metricSign: null,
                metricFractionDigit: 0,
              };
            }
          } else return col;
        }
      )!,
      rows,
    });
    setAddUpdateRow(undefined);
  };

  const onSaveAddUpdateRow = (row: ClientTransTypeRow) => {
    if (addUpdateRow?.type === 'add') {
      addNewRow(row);
    } else if (addUpdateRow?.type === 'update') {
      updateRow(row);
    }
    setHasChanges(true);
  };

  const saveTransactionMapping = async () => {
    if (!transactionMapping || !hasChanges) {
      history.push(`/clients/${id}/${arkTag}/${ClientTabs.ReportConfig}`);
      return;
    }
    if (metricRowToColumnMappingMismatch) {
      return;
    }
    setLoading(true);
    try {
      const rows = [];
      const columns =
        transactionMapping?.columns.map((col, index) => ({ ...col, index })) ||
        [];

      for (
        let index = 0;
        index < transactionMapping?.rows?.length || 0;
        index++
      ) {
        const row = transactionMapping.rows[index];

        rows.push({
          ...row,
          index,
        });

        if (row && Array.isArray(row.values) && row.useMetric) {
          for (const i in row.values) {
            const value = row.values[i];

            if (value) {
              columns[i] = {
                ...columns[i],
                metricSign: row.metricSign,
                metricFractionDigit: row.metricFractionDigit,
              };
            }
          }
        }
      }

      const formattedMapping: ClientTransMapping = {
        ...transactionMapping,
        rows,
        columns,
      };

      await updateTransactionMapping(id, formattedMapping);
      await fetchTransactionMappings();
      history.push(`/clients/${id}/${arkTag}/${ClientTabs.ReportConfig}`);
    } catch (error) {
      informationAlert('Error in updating transaction mapping.', 'error');
    } finally {
      setLoading(false);
      setHasChanges(false);
    }
  };

  const cancelTransactionMapping = async () => {
    setLoading(true);
    try {
      await fetchTransactionMappings();
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, 'error');
    } finally {
      setLoading(false);
      setHasChanges(true);
    }
  };

  return {
    transactionMapping,
    loading: loadingTransactionMapping || loading,
    updateValue,
    toBeDeleted,
    onDelete,
    onDeleteCancel,
    onDeleteConfirm,
    columnLength,
    onAddColumn,
    onUpdateColumn,
    addUpdateColumn,
    onCancelAddUpdateColumn,
    onSaveAddUpdateColumn,
    onAddRow,
    onUpdateRow,
    addUpdateRow,
    onCancelAddUpdateRow,
    onSaveAddUpdateRow,
    saveTransactionMapping,
    cancelTransactionMapping,
    hasChanges,
    metricFormatMismatch,
    metricRowToColumnMappingMismatch,
  };
};
