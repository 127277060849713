import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, Stack, Switch, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import IconDelete from "../../../../../assets/images/icons/icon_delete.svg";
import IconNewCircle from "../../../../../assets/images/icons/icon_new_circle.svg";
import IconWarning from "../../../../../assets/images/icons/icon_warning.svg";
import ContentColumnBox from "../../../../../components/Boxes/ContentColumnBox/ContentColumnBox";
import HorizontalBox from "../../../../../components/Boxes/HorizontalBox/HorizontalBox";
import { DragAndDropList } from "../../../../../components/DragAndDropList/DragAndDropList";
import { StyledDragAndDropPaperItem } from "../../../../../components/DragAndDropList/DragAndDropList.Style";
import ImgIcon from "../../../../../components/ImgIcon/ImgIcon";
import FullScreenModal from "../../../../../components/Modal/FullScreenModal/FullScreenModal";
import { MultiSelect } from "../../../../../components/MultiSelect/MultiSelect";
import ProgressModal from "../../../../../components/Progress/ProgressModal/ProgressModal";
import SingleSelect from "../../../../../components/SingleSelect/SingleSelect";
import TabsPanel from "../../../../../components/TabsPanel/TabsPanel";
import TextField from "../../../../../components/TextField/TextField";
import { AppContext } from "../../../../../core/context/appContextProvider";
import { ListItem } from "../../../../../utils/types/listItems";
import { WbConfigDialogType, Workbook } from "../../workbook.type";
import { WbConfigDialogInfo } from "../sheets/allocations/AllocationsSheet.hooks";
import { defaultError4Message, startQuarters, useClientTransactionMappingEffect, workbookFrequency } from "./WorkbookConfiguration.hooks";
import { DetailsGridStyle, DragAndDropItemWrapper, MainStyledContainer, MappingGridStyle, ScrollingStyledContainer, StyledCustomColumnWrapper, StyledDataGrid, StyledDivider, StyledIncludeColumns, TransactionColumnsGridStyle } from "./WorkbookConfiguration.styles";

type AllocationWorkbookInfoProps = {
  workbook?: Workbook,
  importWorkbooks?: Workbook[],
  wbConfigDialogType: WbConfigDialogType,
  onOk: (wbConfigDialogInfo: WbConfigDialogInfo) => void,
  onCancel: () => void
};

export const AllocationWorkbookInfo: React.FC<AllocationWorkbookInfoProps> = ({
  workbook, importWorkbooks, wbConfigDialogType, onOk, onCancel
}) => {
  const { state } = useContext(AppContext);
  const { clientId, email:userName } = state.loginUser;
  const { fundId } = useParams<{ fundId: string }>();

  const [tabsPanelValue, setTabsPanelValue] = useState<number>(0);
  const detailsRef = useRef<HTMLInputElement>(null);
  const mappingRef = useRef<HTMLInputElement>(null);

  const {
    isNewWorkbook,
    startYears,
    isLoading,
    initialLoadComplete,
    reportName, reportNameError,
    frequency, frequencyError,
    startYear, setStartYear,
    startQuarter, handleStartQuarterChange,
    dandDItems,
    commitmentTransTypeItems, commitmentTransTypeError,
    allocationMappingsHeaderList,
    allocationMappings,
    decimalRounding, setDecimalRounding,
    includeYTDColumn, includeITDColumn,
    importSelections,
    useCommitmentPercent, commitmentPercent, setCommitmentPercent, handleUseCommitmentPercentChange,
    useLpPercent, lpPercent, setLpPercent, handleUseLpPercentChange,
    useGpPercent, gpPercent, setGpPercent, handleUseGpPercentChange,
    customFields,
    handleOnDandDChange,
    handleOnUseArkTransValueChange,
    handleAddCustomColumn, handleEditCustomColumn, handleRemoveCustomColumn,
    handleWorkBookImport,
    handleSave,
    handleReportNameChange, handleFrequencyChange, handleCommitmentTransTypeChange,
    handleIncludeYTDColumnChange, handleIncludeITDColumnChange
  } = useClientTransactionMappingEffect(workbook, clientId, fundId, importWorkbooks, wbConfigDialogType, onOk);

  useEffect(() => {
    setTimeout(() => { 
      if(wbConfigDialogType === WbConfigDialogType.Rollforward && initialLoadComplete) {
        handleTabsPanelChange(null, 1);     
      } 
    }, 1);    
  }, [initialLoadComplete]);

  function getDetailsJsx() {
    return (
      <>
        <ContentColumnBox>

          <Typography variant="subtitle1">Workbook Information</Typography>

          <TextField
            id="txt_report_name"
            size="small"
            label="Report Name"
            required
            value={reportName}
            onChange={handleReportNameChange}
            error={reportNameError}
            helperText={"This field is required and the name must not already exist for this fund"}
            onKeyDown={(e) => e.stopPropagation()}
            focused
          />

          <SingleSelect
            id="select_workbook_frequency"
            value={frequency}
            optionList={workbookFrequency}
            label="Workbook frequency"
            required
            handleOptionSelection={handleFrequencyChange}
            error={frequencyError}
            helperText={defaultError4Message}
            disabled={!isNewWorkbook}
          />

          {
            frequency !== '' ?
              (<SingleSelect
                id="select_workbook_start_year"
                value={startYear}
                optionList={startYears}
                label={"Start Year"}
                handleOptionSelection={(evt: any) => { setStartYear(evt.target.value); }}
                disabled={!isNewWorkbook}
              />) : null
          }

          {
            frequency === 'BY_QUARTER' ?
              (<SingleSelect
                id="select_workbook_start_quarter"
                value={startQuarter}
                optionList={startQuarters}
                label={"Start Quarter"}
                handleOptionSelection={(evt: any) => { handleStartQuarterChange(evt.target.value); }}
                disabled={!isNewWorkbook}
              />) : null
          }

          <StyledDivider>Commitment Column</StyledDivider>

          <HorizontalBox>
            <MultiSelect
              id="transaction_types_for_commitment_multiselect"
              label="Commitment Transaction Types"
              items={commitmentTransTypeItems}
              error={commitmentTransTypeError}
              helperText="Must select at least one Transaction Type"
              infoHelperText="Map commitment transactions that are used for the fund. This will gather commitments already booked for the fund and consolidate them into a column that is used as a reference in standard allocation rules."
              onChange={handleCommitmentTransTypeChange}
              disabled={!isNewWorkbook}
            />
          </HorizontalBox>

          <StyledDivider>Allocation Rule Columns</StyledDivider>

          <HorizontalBox>
            <Checkbox
              checked={useCommitmentPercent}
              onChange={handleUseCommitmentPercentChange}
              disabled={!isNewWorkbook}
            />
            <TextField
              id="txt_commitment_percent"
              size="small"
              label=""
              value={commitmentPercent}
              onChange={(evt: any) => { setCommitmentPercent(evt.target.value); }}
              required
              disabled={!isNewWorkbook}
            />
          </HorizontalBox>

          <HorizontalBox>
            <Checkbox
              checked={useLpPercent}
              onChange={handleUseLpPercentChange}
              disabled={!isNewWorkbook}
            />
            <TextField
              id="txt_lp_percent"
              size="small"
              label=""
              value={lpPercent}
              onChange={(evt: any) => { setLpPercent(evt.target.value); }}
              required
              disabled={!isNewWorkbook}
            />
          </HorizontalBox>

          <HorizontalBox>
            <Checkbox
              checked={useGpPercent}
              onChange={handleUseGpPercentChange}
              disabled={!isNewWorkbook}
            />
            <TextField
              id="txt_gp_custom_rule_title_percent"
              size="small"
              label=""
              value={gpPercent}
              onChange={(evt: any) => { setGpPercent(evt.target.value); }}
              required
              disabled={!isNewWorkbook}
            />
          </HorizontalBox>

          <StyledDivider />
          
          <StyledCustomColumnWrapper marginLeft="-50px">
            <Typography variant="subtitle1">Custom Allocation Rule Columns</Typography>
            {
              isNewWorkbook && 
                <ImgIcon
                  icon={IconNewCircle}
                  useCursorPointer={true}
                  onClick={() => handleAddCustomColumn()}
                />
            }
          </StyledCustomColumnWrapper>
          

          {
            customFields.length === 0 ? (
              <Typography variant="subtitle1">No Custom Allocation Rule Columns Created</Typography>
            ) : (
              customFields.map((col, idx) => {
                return (
                  <HorizontalBox key={idx}>
                    <TextField
                      id={idx.toString()}
                      size="small"
                      label={`Custom Allocation Rule Name ${idx + 1}`}
                      value={col.label}
                      onChange={(val: any) => { handleEditCustomColumn(idx, val); }}
                      required
                      error={col.error}
                      helperText={defaultError4Message}
                      disabled={!isNewWorkbook}
                    />
                    {
                      isNewWorkbook ?
                        <ImgIcon
                          icon={IconDelete}
                          useCursorPointer={true}
                          onClick={() => { handleRemoveCustomColumn(idx); }}
                        /> : null
                    }
                  </HorizontalBox>
                );
              })
            )
          }

        </ContentColumnBox>
      </>
    );
  }

  function getTransactionColumnsJsx() {
    return (
      <ContentColumnBox>

        {
          isNewWorkbook ?
            <SingleSelect
              id="select_workbook_frequency"
              value={""}
              optionList={importSelections}
              label={"Import Previous Workbook"}
              handleOptionSelection={(a: any) => { handleWorkBookImport(a.target.value); }}
            /> : null
        }

        <Typography variant="subtitle1" >Transaction Columns</Typography>
        <FormHelperText>Transaction columns will be used quarter over quarter and can gather data either from the GL or from transactions that already exist in Ark (for example: expenses may be allocated from the GL, while capital activity originates in Ark). Below, indicate whether the transaction allocation will come from the GL (white switch) or Ark (blue switch). Drag and drop columns to control the display order.</FormHelperText>

        <ContentColumnBox>
          <StyledDragAndDropPaperItem>
            <FormControlLabel
              control={<span />}
              label={"ITD Ending Balance"}
            />
          </StyledDragAndDropPaperItem>
          <StyledDragAndDropPaperItem>
            <FormControlLabel
              control={<span />}
              label={"Begining Balance"}
            />
          </StyledDragAndDropPaperItem>
        </ContentColumnBox>

        <DragAndDropList
          items={dandDItems}
          onChange={handleOnDandDChange}
          onItemRender={ item => 
            <DragAndDropItemWrapper>
              <Box>
                <Typography variant="label">{item.name}</Typography>
              </Box>
              <Box flex="1"/>
              <Box>
                <Switch
                    id={"swtch_status_".concat(item.id)}
                    checked={item.data.useArkTransactionValues}
                    onChange={() => handleOnUseArkTransValueChange(item.id)}
                    disabled={!isNewWorkbook}
                  />
              </Box>
          </DragAndDropItemWrapper>
          }
          disabled={!isNewWorkbook}
        />

        <ContentColumnBox>
          <StyledDragAndDropPaperItem>
            <FormControlLabel
              control={<span />}
              label={"Ending Balance"}
            />
          </StyledDragAndDropPaperItem>
        </ContentColumnBox>


        <StyledDivider />

          <SingleSelect
              id="default_workbook_rounding"
              value={decimalRounding.toString()}
              optionList={[{ id: '-1', label: 'No rounding' }, { id: '0', label: 'To the Dollar ($0)' }, { id: '2', label: 'To the cents ($0.00)' }] as ListItem[]}
              label="Default Rounding precision"
              required
              handleOptionSelection={(a: any) => { setDecimalRounding(a.target.value); }}
              disabled={!isNewWorkbook}
            />

        <StyledDivider />

        {
          frequency === "BY_QUARTER" ?
            <StyledIncludeColumns
              control={
                <Checkbox
                  checked={includeYTDColumn}
                  onChange={ handleIncludeYTDColumnChange }
                />
              }
              label="Include Year to Date Totals"
              disabled={!isNewWorkbook}
            /> : null
        }

        <StyledIncludeColumns
          control={
            <Checkbox
              checked={includeITDColumn}
              onChange={ handleIncludeITDColumnChange }
            />
          }
          label="Include Inception to Date Totals"
          disabled={!isNewWorkbook}
        />

      </ContentColumnBox>
    );
  }

  function getMappingJsx() {

    return (
      <ContentColumnBox>
        <Typography variant="subtitle1">Column Mappings</Typography>
        <FormHelperText>Map transactions to allocation rules to automatically set allocation calculations in the workbook.</FormHelperText>

        {
          wbConfigDialogType === WbConfigDialogType.Standard &&
            <FormHelperText>
              
              <Typography variant="subtitle1">
              <img src={IconWarning} alt="Delete Selected" height="20" /> Updating allocation rules will remove all data and formulas that were manually entered in that quarter's transaction column. Saving this update will use the new rule reference and the fund's transaction total to allocate the transaction to investors. Submitted columns and previous quarters will remain unchanged.
              </Typography>
            </FormHelperText>
        }

        <StyledDataGrid
          id="data_grid"
          dataList={allocationMappings}
          headerList={allocationMappingsHeaderList}
          checkboxSelection={false}
          minHeight="100px"
        />
      </ContentColumnBox>
    );
  }

  const handleTabsPanelChange = (_event: any, newValue: any) => {
    setTabsPanelValue(newValue);
    switch (newValue) {
      case 0:
        detailsRef.current?.scrollIntoView({
          behavior: "smooth"
        });
        break;
      case 1:
        mappingRef.current?.scrollIntoView({
          behavior: "smooth"
        });
        break;
    }
  };

  function getModelBodyJsx() {
    return (
      <MainStyledContainer
        id="main-dialog-container"
        role="main"
      >

        <TabsPanel
          id="allocation_workbook_dialog_panel_tabs"
          value={tabsPanelValue}
          handleChange={handleTabsPanelChange}
          options={["Details", "Mapping"]}
        />

        <ScrollingStyledContainer>
          <div ref={detailsRef}>
            <Grid container spacing={12} xs={12}>

              <DetailsGridStyle xs={6} item>
                { getDetailsJsx() }
              </DetailsGridStyle>

              <TransactionColumnsGridStyle xs={2} item>
                { getTransactionColumnsJsx() }
              </TransactionColumnsGridStyle>

            </Grid>
          </div>
            
          <div ref={mappingRef}>
            <MappingGridStyle xs={4} item >
              { getMappingJsx() }
            </MappingGridStyle>
          </div>

        </ScrollingStyledContainer>

        <Stack direction="row" justifyContent={"end"} spacing={4} sx={{ mt: 3 }}>
          <Button id="btn_ok" variant="outlined" disabled={isLoading} onClick={handleSave}>Ok</Button>
          <Button id="btn_cancel" variant="outlined" onClick={onCancel}>Cancel</Button>
        </Stack>

        <ProgressModal
          id="workbook_loading"
          showProgress={isLoading}
        />

      </MainStyledContainer>
    );
  }

  return (
    <FullScreenModal
      id="modal_allocation_workbook_info"
      open={true}
      title=""
      subtitle={getModelBodyJsx()}
    />
  );

};
