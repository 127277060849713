import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  ListItemButton,
  styled
} from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../utils/constants/styles.constants";

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  }
}));

export const TitleBox = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(4)
}));

export const FormBox = styled(Box)(({
  theme
}) => ({
  padding: theme.spacing(4),
  paddingTop: 0,
}));

export const ArrowIconBox = styled(Box)(({
  theme
}) => ({
  marginLeft: theme.spacing(4),
  display: "flex",
  alignItems: "center"
}));

export const LinkButton = styled(Button)(({
  theme
}) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '16px',
}));

export const StyledGrid = styled(Grid)(({
  theme
}) => ({
  maxHeight: '80vh',
  paddingBottom: theme.spacing(8),
  overflowY: 'auto',
}));

export const StyledClientSelector = styled(Box)(({
  theme
}) => ({
  display: 'grid',
  gridTemplateColumns: '5fr 4fr 3fr',
  gridGap: theme.spacing(1),
}));

export const StyledClientCardContainer =  styled(Box)(({
  theme
}) => ({
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1, 0),
  maxHeight: '200px',
  overflowX: 'auto'
}));

export const ClientListContainer = styled(Box)(({
  theme
}) => ({
  padding: theme.spacing(2),
}));

export const ClientListFormGroup = styled(FormGroup)(({
  theme
}) => ({
  paddingLeft: theme.spacing(2),
}));

export const ClientSelectorContainer = styled(Box)(({
  theme
}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  gridGap: theme.spacing(1),
  alignItems: 'center'
}));

export const ClientTeamRoleGridContainer = styled(Box)(({
  theme
}) => ({
  marginTop: theme.spacing(2)
}));

export const SelectedClientListItemButton = styled(ListItemButton)(({
  theme
}) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto 1fr',
  gridGap: theme.spacing(1),
}));

export const RoleFormControlLabel = styled(FormControlLabel)(({
  theme
}) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const PhoneFormHelperText = styled(FormHelperText)(({
  theme
}) => ({
  marginLeft: theme.spacing(2),
}));
