import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getCurrentQuarter } from "../../../utils/helpers/getCurrentQuarter";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FundFilter, InvestorFilter } from "../../../utils/types/capitalCalls.type";
import { PortalDistributions } from "../../../utils/types/distributions.type";
import { Quarter } from "../../../utils/types/filter.type";
import { getFundsFilter, getInvestorsFilter } from "../../capitalCalls.service";
import { download, getPortalDistribution, getQuarterList } from "../../distributions.service";

export const usePortalDistributionsEffect = () => {
  const [portalDistribution, setPortalDistribution] = useState<PortalDistributions[]>();
  const [selectedQuarter, setSelectedQuarter] = useState<string>("");
  const [quaterList, setQuarterList] = useState<Quarter[]>();
  const [fundList, setFundList] = useState<FundFilter[]>();
  const [investorList, setInvestorList] = useState<InvestorFilter[]>();
  const [transactionList, setTransactionList] = useState<{ id: string; name: string }[]>();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchPortalDistribution = useCallback(
    async (isCanceled?: () => boolean) => {
      try {
        setIsLoading(true);

        if (isCanceled?.()) return;

        const [fundsListResponse, investorListResponse, quarterListResponse] =
          await Promise.all([
            getFundsFilter(),
            getInvestorsFilter(),
            getQuarterList(),
          ]);

        setQuarterList(quarterListResponse);
        setFundList(fundsListResponse);
        setInvestorList(investorListResponse);

        const { quarterWithYear } = getCurrentQuarter();

        await fetchPortalDistributions(quarterWithYear);
      } catch (e) {
        informationAlert("Error in getting distributions", "error");
      } finally {
        setIsLoading(false);
      }
    }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchPortalDistribution(isCanceled);
  }, []);

  const downloadDistribution = async (body: any) => {
    return await download(body);
  };

  const fetchPortalDistributions = async (quarterName: string) => {
    setSelectedQuarter(quarterName);

    const portalDistributionResponse = await getPortalDistribution(quarterName);

    const updatedResponse: PortalDistributions[] = [];

    const transactionData: { id: string; name: string }[] = [];
    const documentIds: string[] = [];

    portalDistributionResponse.map((i) =>
      i?.funds?.map((fund: any) =>
        fund?.transactions?.map((tx: any, index: number) => {
          if (
            transactionData.findIndex(
              (data) => data.id === tx.transactionId
            ) === -1
          ) {
            transactionData.push({
              id: tx.transactionId,
              name: tx.name === null ? "" : tx.name,
            });
            updatedResponse.push({
              ...tx,
              ...fund,
              ...i,
              fundName: fund.fundName,
              transactionName: tx.name,
              id: `temp-${index}-${tx.transactionId}`,
            });
          }

          if (tx.documentId) {
            documentIds.push(tx.documentId);
          }
        })
      )
    );

    setTransactionList(transactionData);
    setPortalDistribution(updatedResponse);
    setFileIds(documentIds);
  };

  return {
    selectedQuarter,
    portalDistribution,
    quaterList,
    fundList,
    investorList,
    transactionList,
    fileIds,
    fetchPortalDistribution,
    fetchPortalDistributions,
    isLoading,
    downloadDistribution,
  };
};
