import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { CapitalCalls } from "../../../utils/types/capitalCalls.type";
import { getCapitalCalls } from "../../capitalCalls.service";
import { GET_CAPITAL_CALL_LIST_ERROR } from "./useCapitalCall.constants";

export const useCapitalCallsEffect = () => {
  const [capitalCalls, setCapitalCalls] = useState<CapitalCalls[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchCapitalCalls = useCallback(async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getCapitalCalls();

      if (isCanceled?.()) return;

      setCapitalCalls(response);
      setLoading(false);
    } catch (e) {
      informationAlert(GET_CAPITAL_CALL_LIST_ERROR, "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchCapitalCalls(isCanceled);
  }, []);

  const updateCapitalCalls = (updatedCapitalCalls: CapitalCalls[]) => {
    setCapitalCalls(updatedCapitalCalls);
  };

  return {
    capitalCalls,
    fetchCapitalCalls,
    loading,
    setCapitalCalls,
    updateCapitalCalls,
  };
};
