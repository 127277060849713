import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";

import { StyledDialog, StyledDialogTitle } from './SaveExitModal.styles';

type Props = {
  content?: string|JSX.Element;
  title: string;
  actionTitle?: string,
  confirmNavigationTitle?: string,
  cancelTitle?: string,
  onActionClick?: () => boolean|void,
  onActionClickAsync?: () => Promise<boolean|void>,
  onNavigateAway?: () => void,
  onCancelClick?: () => void,
  isBlocked: boolean;
};

export const SaveExitModal: React.FC<Props> = (props: Props) => {
  const {
    content = <>Your changes have not been saved. <br />Save your changes now or exit without saving.</>, 
    title, isBlocked, actionTitle, confirmNavigationTitle, cancelTitle, onActionClick, onActionClickAsync, onNavigateAway: onConfirmNavigationClick, onCancelClick
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: React.SetStateAction<Location<unknown>>) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: React.SetStateAction<Location<unknown>>) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    } else if(onConfirmNavigationClick) {
      onConfirmNavigationClick();
    }

    return true;
  };

  const handleActionClick = async () => {
    let confirmNav: boolean|void;

    if(onActionClickAsync) {
      confirmNav = await onActionClickAsync();
    } else if(onActionClick) {
      confirmNav = onActionClick();
    } else {
      return;
    }

    setConfirmedNavigation(!!confirmNav);
    closeModal();
  };

  const handleConfirmNavigationClick = () => {
    closeModal();    
    setConfirmedNavigation(true);
    if(onConfirmNavigationClick) {
      onConfirmNavigationClick();
    }
  };

  const handleCancelClick = () => {
    onCancelClick!();
    closeModal();
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with specified function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: { returnValue: string; }) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = 'blocked';
      }
      if (shouldUnload) {
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", unload);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isBlocked, content, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />

      <StyledDialog
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby=""
        aria-describedby="alert_dialog_description_save"
      >
        {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
        <DialogContent>
          <DialogContentText id="alert_dialog_description_save">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!!actionTitle && <Button id="btn_save" onClick={handleActionClick} autoFocus variant="contained">{actionTitle}</Button>}
          {!!confirmNavigationTitle && <Button id="btn_exit" onClick={handleConfirmNavigationClick} variant="outlined">{confirmNavigationTitle}</Button>}
          {!!onCancelClick && <Button id="btn_cancel" onClick={handleCancelClick} variant="outlined">{cancelTitle??'Cancel'}</Button>}
        </DialogActions>
      </StyledDialog>
    </>
  );
};