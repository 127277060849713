import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { getARKClientTags } from "../../client.service";

export const useARKClientTagsEffect = () => {
  const [arkClientTags, setArkClientTags] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchArkClientTags = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getARKClientTags();

      if (isCanceled?.()) return;

      setArkClientTags(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting ark client tags", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchArkClientTags(isCanceled);
  }, [fetchArkClientTags]);

  return {
    arkClientTags,
    fetchArkClientTags,
    loading,
    setArkClientTags
  };
};
