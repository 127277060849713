import { Backdrop, styled } from "@mui/material";
import { Box } from "@mui/system";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import { PRIMARY } from "../../../../styles/colors";
import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";

export const Title = styled("div")(() => ({
  fontStyle: "normal",
  fontSize: "24px",
  lineHeight: "100%",
  paddingTop: "30px",
  marginLeft: "3%",
  textAlign: "left",
  verticalAlign: "center",
  paragraphSpacing: "10px",
  fontWeight: "bold",
  display: "flex",
  whiteSpace: "nowrap",
  "img:first-of-type": {
    paddingLeft: "20px",
  },
}));

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
`;

export const PermissionsBox = styled(Box)`
  width: calc(100vw - 20px);
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  height: 100%;
`;

export const ActionButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${INFO_PANEL_MAX_WIDTH};
  bottom: 0px;
`;

export const PanelActionBox = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const IconText = styled("div")`
  margin-right: 10px;
  margin-left: 10px;
  color: ${PRIMARY};
  cursor: pointer;
`;