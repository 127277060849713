export const CurrencyFormat = (currency: string, decimalPlaces: number = 2) => {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const FindLengthOfFraction = (currency: string) => {
  const numberFormatUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  return (
    numberFormatUSD.formatToParts(1).find((part) => part.type === 'fraction')
      ?.value.length ?? 0
  );
};

export const FormatIntegerWithCurrencyOffset = (
  value: number,
  currency: string
) => {
  const decimals = FindLengthOfFraction(currency);
  const decimalFormattedValue = value / Math.pow(10, decimals);

  return CurrencyFormat(currency, decimals).format(decimalFormattedValue);
};

export const NegativeCurrencyFormat = (
  currency: string,
  decimalPlaces: number = 2
) => {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    currencySign: 'accounting',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const NumberFormat = (
  decimalPlaces: number = 2,
  useCommas: boolean = false
) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping: true,
  });
};

export const DateTimeFormat = {
  longDateTime: (date: Date | string) => {
    const d: Date =
      typeof date === 'string' || typeof date === 'number'
        ? new Date(date)
        : date;
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  longDate: (date: Date | string) => {
    const d: Date =
      typeof date === 'string' || typeof date === 'number'
        ? new Date(date)
        : date;
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
    //const dateStr = dt.toLocaleString('en-US', { day: 'numeric', year: 'numeric', month: 'long' });
  },
  shortDate: (date: Date | string) => {
    const d: Date =
      typeof date === 'string' || typeof date === 'number'
        ? new Date(date)
        : date;
    const dateOptions: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  shortDateString: (date: string) => {
    const d: Date = new Date(new Date(date).toISOString());

    const dateOptions: Intl.DateTimeFormatOptions = {
      timeZone: 'UTC',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  isoDateString: (d: Date) => {
    const isoDateTimeStr = DateTimeFormat.ensureAsDate(d).toISOString();
    const dateParts = isoDateTimeStr.split('T');
    const dateIso = dateParts[0];

    return dateIso;
  },
  noMilliseconds: (d: Date) => {
    return d.toISOString().replace('.000Z', 'Z');
  },
  getDateOnly: (d: Date) => {
    const dateToUse = DateTimeFormat.ensureAsDate(d);

    dateToUse.setHours(0, 0, 0, 0);

    return dateToUse;
  },
  ensureAsDate: (d: Date | string) => {
    let dateToUse: Date;

    if (typeof d === 'string' || d instanceof String) {
      const dateStr = DateTimeFormat.shortDateString(d as unknown as string);

      dateToUse = new Date(dateStr);
    } else {
      dateToUse = d;
    }

    return dateToUse;
  },
  getToday: () => {
    return DateTimeFormat.getDateOnly(new Date());
  },
  compareDateOnly: (d1: Date | string, d2: Date | string) => {
    const date1 = DateTimeFormat.getDateOnly(DateTimeFormat.ensureAsDate(d1));
    const date1Ms = date1.getTime();
    const date2 = DateTimeFormat.getDateOnly(DateTimeFormat.ensureAsDate(d2));
    const date2Ms = date2.getTime();

    if (date1Ms === date2Ms) {
      return 0;
    } else if (date1Ms < date2Ms) {
      return -1;
    } else {
      return 1;
    }
  },
  isDateBetween: (d: Date, startDate: Date, endDate: Date) => {
    return (
      DateTimeFormat.compareDateOnly(d, startDate) >= 0 &&
      DateTimeFormat.compareDateOnly(d, endDate) <= 0
    );
  },
  getFormattedDate: (d: Date, separator?: string): string => {
    const year = d.getFullYear();
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    return `${month}${separator || '-'}${day}${separator || '-'}${year}`;
  },
  getReversedDate: (d: Date, separator?: string): string => {
    const year = d.getFullYear();
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    return `${year}${separator || '-'}${month}${separator || '-'}${day}`;
  },
  getMonthAndYearAsString: (d: Date): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      year: 'numeric',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  getMonthString: (d: Date): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  getYearAsStribng: (d: Date): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
    };
    const formattedDate = d.toLocaleDateString('en-US', dateOptions);

    return formattedDate;
  },
  addDate: (d: Date, daysToAdd: number): Date => {
    const dayMiliSecs = 86400000;

    return new Date(d.getTime() + dayMiliSecs * daysToAdd);
  },
  subtractDate: (d: Date, daysToSubtract: number): Date => {
    const dayMiliSecs = 86400000;

    return new Date(d.getTime() - dayMiliSecs * daysToSubtract);
  },
  isValidDate: (d: Date | string | undefined | null) => {
    if (!d) return false;

    let dateToCheck: Date;

    if (d instanceof Date) {
      dateToCheck = d;
    } else {
      dateToCheck = new Date(Date.parse(d));
    }

    return (
      dateToCheck &&
      dateToCheck instanceof Date &&
      !isNaN(dateToCheck.getTime()) &&
      dateToCheck.getFullYear() > 1960
    );
  },
};

export const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const formatPhoneNumber = (phoneNumberString: string | number) => {
  const match = ('' + phoneNumberString)
    .replace(/\D/g, '')
    .match(/^(\d*|)(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1] ? `+${match[1]}` : ''} ${match[2]}-${match[3]}-${
      match[4]
    }`;
  } else {
    return phoneNumberString;
  }
  return null;
};

export const formatDate = (date: string) => {
  const datearray = date.split('-');
  const month = datearray[0].length === 1 ? '0' + datearray[0] : datearray[0];
  const day = datearray[1].length === 1 ? '0' + datearray[1] : datearray[1];
  const year = datearray[2];
  const newdate = month + '/' + day + '/' + year;

  return newdate;
};

export const areDateOrNullValuesEqual = (value1: any, value2: any): boolean => {
  const parseDate = (value: any): string | null => {
    if (!value) return null;

    const date = new Date(value);

    if (isNaN(date.getTime())) return 'Invalid';
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`;
  };

  const date1 = parseDate(value1);
  const date2 = parseDate(value2);

  if (date1 === null && date2 === null) return true;
  if (date1 === 'Invalid' || date2 === 'Invalid') return date1 === date2;
  return date1 === date2;
};
