import { BankFeedViewOptions } from '../../pages/banks/bankFeeds/BankFeedList.defaults';
import { DetailsType } from './form.type';
import { SelectionOptionItem } from './listItems';

export type BankFeedView =
  | BankFeedViewOptions.DASHBOARD
  | BankFeedViewOptions.HYBRID
  | BankFeedViewOptions.DATA_VIEW;

type BankAccountType =
  | 'depository'
  | 'credit'
  | 'loan'
  | 'investment'
  | 'other';

export enum BankTypeOptions {
  Depository = 'depository',
  Credit = 'credit',
  Other = 'other',
}

export type BankAccount = {
  id: string;
  accountId: string;
  name: string;
  bankName: string;
  officialName: string;
  nickname: string | null;
  type: BankAccountType;
  subType: string;
  currencyCode: string;
  balanceTotal: number;
  availableCredit: number | null;
  lineOfCredit: number | null;
  fundId: string;
  fundName: string | null;
  ledgerId: string | null;
  ledgerName: string | null;
  glAccountId: string | null;
  glAccountName: string | null;
  glBalance?: number;
};

export type BankTransaction = {
  id: string;
  date: string;
  description?: string;
  type: BankTransInflowOutflow;
  memo?: string;
  fundId: string;
  fundName: string;
  ledgerId?: string;
  ledgerName?: string;
  memoEntityId: string | null;
  memoEntityName: string | null;
  score?: number;
  ruleId?: string;
  ruleName?: string;
  bankAccountId: string;
  bankAccountName: string;
  bankAccountNickname?: string;
  amount: number;
  currency: string;
  isPlaid: boolean;
  status: BankFeedsStatus;
  journalEntry: BankTransactionJELineItem[];
  journalEntryId: string | null;
  receivableTransactionId: string | null;
};

export type AccountMatchedBankTransaction = BankTransaction & {
  glAccountId: string | null;
  glAccountName: string | null;
  bankAccountDisplayName: string;
  isNonGlBankAccount: boolean;
  isEditedRow: {
    isGlAccountEdited: boolean;
    isEntityEdited: boolean;
    isCapCallEdited: boolean;
    oldValues: {
      journalEntry: BankTransactionJELineItem[];
      memoEntityId: string | null;
      receivableTransactionId: string | null;
    };
    isEntityRequired?: boolean;
  };
  isEntityRequiredByBankAcc: boolean;
};

export type EditedRows = {
  [id: string]: AccountMatchedBankTransaction;
};

export type BankSummary = {
  type: BankAccountType;
  currencyCode: string;
  accounts: BankAccount[];
  balanceSum: number | null;
  availableCreditSum: number | null;
  lineOfCreditSum: number | null;
  glAccountsTotal?: number;
};

export type BankRule = {
  id?: string;
  ruleName?: string;
  appliesTo?: [];
  banks?: [];
  status?: string;
  includes?: string;
  detail?: string;
  condition?: string;
  value?: string;
  account?: string;
  accountId?: string;
  splitBy?: string;
  percentage?: number;
  amount?: number;
  memoEntity?: [];
  splitDetails?: Split[];
  conditions?: Condition[];
};

export type ConnectPlaidAccountsReq = {
  publicToken: string;
  username?: string;
  accounts: {
    id: string;
    name: string;
    fundId: string;
    ledgerId?: string;
    glAccountId?: string;
    startDate: string;
  }[];
};

export type Split = {
  detailValue?: number;
  detailAccount?: string;
};

export type Condition = {
  condition?: string;
  detail?: string;
  value?: string;
};

export type SelectedBankRule = {
  bankRule?: BankRule;
  type?: DetailsType;
};

export enum BankRuleFilter {
  RuleName = 'ruleName',
  Bank = 'bank',
  AppliedTo = 'appliedTo',
  Status = 'status',
}

export enum BankRulesAction {
  ExportSelected = 'export_selected_data',
  InactivateSelected = 'inactivate_selected_data',
  ActivateSelected = 'activate_selected_data',
}

export type BankFilter = {
  id?: string;
  name?: string;
};

export const statusOptions: any = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'inactive',
    name: 'Inactive',
  },
];

export const appliesToOptions: any = [
  {
    id: 'inflow',
    name: 'Inflow',
  },
  {
    id: 'outflow',
    name: 'Outflow',
  },
];

export const appliesToAutoOptions: any = ['Inflow', 'Outflow'];

export const includesMenuOptions: any = ['Any Conditions', 'All Conditions'];

export const detailMenuOptions: any = ['Description', 'Bank Text', 'Amount'];

export const conditionMenuOptions: any = [
  'Contains',
  `Doesn't contain`,
  'Is exactly',
];

export const splitByOptions: SelectionOptionItem[] = [
  {
    id: 'rb_percentage',
    label: 'Percentage',
    value: 'PERCENTAGE',
  },
  {
    id: 'rb_amount',
    label: 'Amount',
    value: 'AMOUNT',
  },
];

export type BulkEditSelection = {
  plaidNum: number;
  uploadedNum: number;
  totalNum: number;
};

export type uneditableTransInBulkEdit = {
  total: number;
  nonGlBankAccountTotal: number;
  categorizedTransTotal: number;
};

export type NameIdPair = {
  id: string;
  name: string;
};

export type nameIdFundPair = {
  id: string;
  name: string;
  fundId: string;
};

export type nameIdTypePair = {
  id: string;
  name: string;
  type: string;
};

export type GlAccountDetails = {
  fundId: string;
  id: string;
  isEntityRequired: boolean;
  name: string;
  number: number;
  parentId: string;
  isDisabled?: boolean;
  depth: number;
};

export type SelectedBankAccount = {
  bankAccount?: BankAccount;
  type?: DetailsType;
};

export type SortDirectionType = 'ASC' | 'DESC';

export type SortType = {
  column: string;
  direction: SortDirectionType;
};

export type BankFeedsStatus = 'PENDING' | 'CATEGORIZED' | 'EXCLUDED';

export type BankFeedsDataGridTab = 'FOR_REVIEW' | 'ALL';

export type BankTransInflowOutflow = 'INFLOW' | 'OUTFLOW';

export type JEPosting = 'DEBIT' | 'CREDIT';

export type SearchTransactionsPayload = {
  searchTerm?: string;
  fundIds?: string[];
  memoEntityIds?: string[];
  ledgerIds?: string[];
  bankAccountIds?: string[];
  // glAccountIds?: string[]; //! Confirm: is removed?
  ruleIds?: string[];
  statuses?: BankFeedsStatus[];
  startDate?: string;
  endDate?: string;
  sort?: SortType;
  offset: number;
  pageSize: number;
};

export type BankTransactionJELineItem = {
  accountId: string;
  amount: number;
  type: JEPosting | '';
  entityIds: string[];
  memo: string;
};

export type UpdateBankTransactionsPayload = {
  id: string;
  isPlaid: boolean;
  date?: string;
  amount?: number;
  description?: string;
  type?: BankTransInflowOutflow;
  memo?: string;
  glAccountId?: string;
  memoEntityId?: string | null;
  bankAccountId?: string;
  journalEntryLineItem?: BankTransactionJELineItem[];
  receivableTransactionId?: string | null;
  status: BankFeedsStatus;
};

export type JEPreviewFormatBankTransaction = {
  date: string;
  fundId: string;
  memo: string;
  ledgerId: string;
  memoEntity: NameIdPair[];
  journalEntry: JEPreviewFormatBanksJournalEntry[];
};

export type JEPreviewFormatBanksJournalEntry = {
  accountId: string;
  amount: number;
  type: JEPosting | '';
  entityIds?: NameIdPair[];
  memo: string;
  isBankTransactionEntry: boolean;
  isEntityRequired?: boolean;
};

export type SendToGlValidation = {
  failedTotal: number;
  failedTypes: {
    glAccount: number;
    categorized: number;
    excluded: number;
    memoEntity: number;
    nonGlLinkedAcc: number;
  };
  validatedTransactionIds: string[];
  missingMemoTag: number;
};

export type NameGlAccIdMap = {
  name: string;
  glAccountId: string | null;
};

export type BankInstitution = {
  id: string;
  bankName: string;
  bankLogo: string;
};

export type GlAccountsMap = {
  name: string;
  number: number;
  isEntityRequired: boolean;
};

export type BanksTrialBalanceParams = {
  accountIds: string[];
  ledgerIds: string[];
  startDate: string;
  endDate: string;
};
