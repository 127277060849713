import React from "react";

import ConfirmationDialog from "../../../../../../components/Modal/ConfirmationDialog";
import ProgressModal from "../../../../../../components/Progress/ProgressModal/ProgressModal";
import { WbConfigDialogType } from "../../../workbook.type";
import { GlConnect } from "../../glConnect/glConnect";
import { SubmitToPcap } from "../../submitToPcap/SubmitToPcap";
import { AllocationWorkbookInfo } from "../../workbookConfiguration/WorkbookConfiguration";
import { useAllocationsSheet } from "./AllocationsSheet.hooks";
import { AllocationsSheetManager } from "./AllocationsSheet.Manager";

type AlloocationsSheetProps = {
    allocationsSheetManager: AllocationsSheetManager
}

export const AlloocationsSheet: React.FC<AlloocationsSheetProps> = ({
    allocationsSheetManager
}) => {
    const {
        loadingMessage,
        showWbConfigDialog, setShowWbConfigDialog, handleConfigOK,
        invalidPasteText, setInvalidPasteText,
        messageDialogText, setMessageDialogText,
        showSubmitToPcapDialog, setShowSubmitToPcapDialog,
        showPcapSavePromt, setShowPcapSavePromt, handleSaveAndLoadPcapDialog,
        showGlConnectDialog, setShowGlConnectDialog,
        showRolloverNewWBDialog, setShowRolloverNewWBDialog, handleRolloverNewWB
    } = useAllocationsSheet(allocationsSheetManager);

    return (
        <>
            {
                showWbConfigDialog.wbConfigDialogType !== WbConfigDialogType.NoShow &&
                    <AllocationWorkbookInfo
                        workbook={showWbConfigDialog.workbook}
                        wbConfigDialogType={showWbConfigDialog.wbConfigDialogType}
                        onOk={handleConfigOK}
                        onCancel={() => {setShowWbConfigDialog({ wbConfigDialogType: WbConfigDialogType.NoShow });}}
                    />
            }

            {
                !!loadingMessage && 
                    <ProgressModal
                        id="progress_message"
                        showProgress={!!loadingMessage}
                        text={loadingMessage}
                    />
            }

            <ConfirmationDialog
                open={!!invalidPasteText}
                onClose={() => setInvalidPasteText(undefined)}
                id="modal_invalid_paste"
                actions={[{
                    label: 'Cancel',
                    onClick: () => {setInvalidPasteText(undefined);},
                    id: 'cencel_button',
                    variant: 'contained',
                    color: 'primary'
                }]}
                title={"Invalid Paste"}
                content={(
                    <>
                        One or more columns cannot be pasted over because they are pulled from data in Ark. If you would like to change this data, please do so outside of the Allocations workbook.
                        <br/><br/>
                        {invalidPasteText}
                    </>
                )}
            />

            <ConfirmationDialog
                open={!!messageDialogText}
                onClose={() => {setMessageDialogText(undefined);}}
                id="modal_error"
                actions={[{
                    label: 'Ok',
                    onClick: () => {setMessageDialogText(undefined);},
                    id: 'ok_button',
                    variant: 'contained',
                    color: 'primary'
                }]}
                content={messageDialogText?.message}
                title={messageDialogText?.title}
            />

            <ConfirmationDialog
                id="rollover_new_wb_dialog"
                open={showRolloverNewWBDialog}
                onClose={() => { setShowRolloverNewWBDialog(false); }}
                actions={[
                {
                    id: "ok_button",
                    label: "Continue",
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleRolloverNewWB
                }, {
                    id: "cancel_cancel",
                    label: "Cancel",
                    variant: 'contained',
                    color: 'secondary',
                    onClick: () => { setShowRolloverNewWBDialog(false); }
                }
                ]}
                title="Rollover New Workbook"
                content="This will save this workbook and create a new workbook.  Do you want to continue?"
            />

            <ConfirmationDialog
                open={showPcapSavePromt}
                onClose={() => { setShowPcapSavePromt(false); }}
                id="modal_prompt_save_first"
                actions={[
                {
                    label: 'Save',
                    onClick: () => { handleSaveAndLoadPcapDialog(); },
                    id: 'save_button',
                    variant: 'contained',
                    color: 'primary'
                },
                {
                    label: 'Cancel',
                    onClick: () => { setShowPcapSavePromt(false); },
                    id: 'cancel_button',
                    variant: 'outlined',
                    color: 'error'
                }
                ]}
                content="This workbook must be saved before proceeding."
                title="Confirm Save"
            />

            {
                showSubmitToPcapDialog && 
                    <SubmitToPcap
                        allocationsSheetManager={allocationsSheetManager}
                        onClose={() => { setShowSubmitToPcapDialog(false); }}           
                    />
            }
            {
                showGlConnectDialog && 
                    <GlConnect
                        allocationsSheetManager={allocationsSheetManager}
                        onClose={() => { setShowGlConnectDialog(false); }}
                    />
            }
        </>
    );
};