export enum Statuses {
  REVIEWING = 'reviewing',
  EDITING = 'editing',
  APPROVED = 'approved',
  SENT = 'sent',
}

export interface GeneralMailings {
  id?: string;
  status?: Statuses | string;
  createdOn?: Date | string;
  sentDate?: Date | string;
  createdBy?: string | null;
  totalRecipients?: number;
  openRate?: number | null;
  to?: {
    investors?: (string | null | undefined)[];
    funds?: (string | null | undefined)[];
    roles?: (string | null | undefined)[];
  };
  cc?: Array<string> | null;
  bcc?: Array<string> | null;
  documents?: any | null;
  subject?: string | null;
  messageBody?: string | null;
  type: string;
  isParent?: boolean;
  isChild?: boolean;
  previews?: [];
  imageIds?: [];
  receivableId?: string | null;
  transactionIds?: string[] | null;
  draftType?: string;
  data?: any;
}

export interface Files {
  embeddedFiles?: File;
}

export type newGeneralMailings = {
  to?: {
    investors?: (string | null | undefined)[];
    funds?: (string | null | undefined)[];
    roles?: (string | null | undefined)[];
  };
  cc?: Array<string> | null;
  bcc?: Array<string> | null;
  documents?: any | null;
  subject?: string | null;
  messageBody?: string | null;
  imageIds?: Array<string> | null;
  receivableId?: string | null;
  transactionIds?: string[] | null;
  draftType?: string;
};

export type generalMailingsType = GeneralMailings;

export const defaultGeneralMailings: GeneralMailings = {
  id: '',
  status: 'editing',
  createdOn: '',
  sentDate: '',
  createdBy: '',
  totalRecipients: undefined,
  openRate: undefined,
  to: {},
  cc: [],
  bcc: [],
  subject: '',
  messageBody: '',
  type: '',
  imageIds: [],
};

export type Fund = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  isChecked?: boolean;
};

export type Investor = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  isChecked?: boolean;
  funds?: string[];
};

export type Role = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  isChecked?: boolean;
};

export type SelectedMailing = {
  id?: string;
  status?: string;
  fundRecipients?: Array<Fund>;
  investorRecipients?: Array<Investor>;
  roleRecipients?: Array<Role>;
  subject?: string | null;
  messageBody?: string | null;
};

export type SelectedFund = {
  fundId?: string;
  fundName?: string;
  investorId?: string;
  investorName?: string;
  quarter?: string;
  id?: string;
  currencyCode?: string;
};

export enum GeneralMailingsFilter {
  Statuses = 'Statuses',
  FundIds = 'fundIds',
  InvestorIds = 'investorIds',
  RoleIds = 'roleIds',
}

export enum GeneralMailingsInboxFilter {
  Subject = 'subject',
  Created = 'created',
}

export type GeneralMailingsSummary = {
  items: GeneralMailingsSummaryItem[];
};

export type GeneralMailingsSummaryItem = {
  id: string;
  messages: string;
  [key: string]: string | number;
};

export type SelectedDocuments = {
  fileIds: Array<string>;
};

export type SelectedContacts = {
  funds: Array<string>;
  investors: Array<string>;
  roles: Array<string>;
};

export type Previews = {
  previews: Array<object>;
  fileDownloadLinks: Array<object>;
};

export type sendIds = {
  draftIds: Array<string>;
};

export type Emails = [String];

export enum GeneralMailingsBulkAction {
  DeleteSelected = 'delete_selected',
}
