import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getQuarters } from "../../../services/capitalAccounts.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Period } from "../../../utils/types/capitalAccounts.type";

export const useCapAccQuartersEffect = (published: Boolean = true) => {
  const [quartersList, setQuartersList] = useState<Period[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoading(true);
      const statusResponse = await getQuarters(published.toString());

      if (isCanceled()) return;

      setQuartersList(statusResponse);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting capital account quarters.", "error");
      setLoading(false);
    }
  }, []);

  return {
    quartersList,
    loading
  };
};

