import { CustomType, DataGridColDef } from "../../../../utils/types/listItems";

export const defaultHeaderList: DataGridColDef[] = [
  {
    field: "entityName",
    headerName: "Name",
    hide: false,
    index: 1,
    sortable: true,
    type: "string",
  },
  {
    field: "phoneNumber",
    headerName: "Phone",
    hide: false,
    index: 2,
    sortable: true,
    type: "string"
  },
  {
    field: "emailAddress",
    headerName: "Email",
    hide: false,
    index: 3,
    sortable: true,
    type: "string"
  },
  {
    field: "tinNumber",
    headerName: "TIN",
    hide: false,
    index: 4,
    type: "string",
    sortable: true,
  },
  {
    field: "addressLine1",
    headerName: "Address Line 1",
    hide: false,
    index: 5,
    type: "string",
    sortable: true,
  },
  {
    field: "addressLine2",
    headerName: "Address Line 2",
    hide: false,
    index: 6,
    type: "string",
    sortable: true,
  },
  {
    field: "city",
    headerName: "City",
    hide: false,
    index: 7,
    type: "string",
    sortable: true,
  },
  {
    field: "country",
    headerName: "Country",
    hide: false,
    index: 8,
    type: "string",
    sortable: true,
  },
  {
    field: "state",
    headerName: "State / Province",
    hide: false,
    index: 9,
    type: "string",
    sortable: true,
  },
  {
    field: "zip",
    headerName: "ZIP Code",
    hide: false,
    index: 10,
    type: "string",
    sortable: true,
  },
  {
    field: "type",
    headerName: "Type",
    hide: false,
    index: 11,
    type: "string",
    sortable: true,
  },
  {
    field: "action",
    headerName: "",
    hide: false,
    index: 12,
    type: "action",
    sortable: true,
  },
];