import { useContext, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';
import { getArkAccountNames } from '../../../services/arkGL.service';
import {
  createNewBankRule,
  getBankRules,
  updateBankRule,
} from '../../../services/bank.service';
import { getEntities } from '../../../services/entity.service';
import { getFundFilters, getFundsList } from '../../../services/fund.service';
import { awaitReactUpdate } from '../../../utils/helpers/timeoutFunctions';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { BankRule, Split } from '../../../utils/types/bank.type';
import { BANK_RULE_FORM_DEFAULT_VALUE } from './BankRuleDetails.constants';

type Props = {
  bankRule?: BankRule;
  fetchAllBankRules: () => void;
  fundId?: string;
  isNewBankRule: boolean;
  onDetailsClose: () => void;
};

export const useBankRulesDetails = ({
  bankRule,
  fetchAllBankRules,
  fundId,
  isNewBankRule,
  onDetailsClose,
}: Props) => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [accountListOptions, setAccountListOptions] = useState<any>([]);
  const [fundList, setFundList] = useState<any>([]);
  const [memoEntityList, setMemoEntityList] = useState<any>([]);
  // const [lastSplit, setLastSplit] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    getValues,
    setValue,
    watch,
    trigger,
    reset,
  } = useForm<BankRule>({
    defaultValues: bankRule ?? BANK_RULE_FORM_DEFAULT_VALUE,
  });

  const { isDirty } = useFormState({ control });
  const splitByType = watch('splitBy');
  const splitDetailsList = watch('splitDetails');
  const conditionsList = watch('conditions');

  const lastSplit = splitDetailsList!.length - 1;
  const lastCondition = conditionsList!.length - 1;

  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);
    try {
      // const bankRuleResponse = await getBankRule(ruleId);
      const arkAccountsResponse = await getArkAccountNames(fundId);
      const fundsFilterResponse = await getFundFilters();
      const entitiesResponse = await getEntities();

      if (isCanceled?.()) return;

      setAccountListOptions(arkAccountsResponse.items);
      setFundList(fundsFilterResponse);
      setMemoEntityList(entitiesResponse.items);
      // const grouped: { type: string; entities: any[] }[] = [
      //     {
      //       type: 'Fund',
      //       entities: [],
      //     },
      //     {
      //       type: 'Investor',
      //       entities: [],
      //     },
      //     {
      //       type: 'Portfolio Company',
      //       entities: [],
      //     },
      //     {
      //       type: 'Memo Entity',
      //       entities: [],
      //     },
      //   ];

      // entitiesResponse.items.forEach((entity: any) => {
      //     if (entity.type === 'PortfolioCompany') {
      //       entity.type = 'Portfolio Company';
      //     }
      //     if (entity.type === 'GeneralEntity') {
      //       entity.type = 'Memo Entity';
      //     }
      //   });

      // entitiesResponse.items.map((entity: any) => {
      //     grouped
      //       .find((list) => list.type === entity.type)
      //       ?.entities.push(entity);
      //   });

      // console.log([
      //     ...grouped[0].entities,
      //     ...grouped[1].entities,
      //     ...grouped[2].entities,
      //     ...grouped[3].entities,
      //   ]);

      // setMemoEntityList([
      //     ...grouped[0].entities,
      //     ...grouped[1].entities,
      //     ...grouped[2].entities,
      //     ...grouped[3].entities,
      //   ]);
    } catch (e) {
      informationAlert('error fetching Account list', 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (accountListOptions.length && fundList.length) {
      accountListOptions.forEach((account: any) => {
        const fund = fundList.filter((fund: any) => fund.id === account.fundId);

        account.fund = fund[0].name;
      });
    }
  }, [accountListOptions, fundList]);

  const createUpdateBankRule = async (data: BankRule) => {
    // console.log("handle submit data:", data);
    //await createBankeRule(data);
  };

  const createBankeRule = async (data: BankRule) => {
    setIsLoading(true);
    try {
      await createNewBankRule(data);
      await fetchAllBankRules();
      informationAlert('success creating bank rule', 'success');
      closeDrawer();
    } catch (e) {
      informationAlert('error creating bank rule', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const editBankRule = async (id: string, data: BankRule) => {
    setIsLoading(true);
    try {
      await updateBankRule(id, data);
      await fetchAllBankRules();
      informationAlert('success updating bank rule', 'success');
      closeDrawer();
    } catch (e) {
      informationAlert('error updating bank rule', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSplit = () => {
    const newList = splitDetailsList?.concat({
      detailValue: undefined,
      detailAccount: '',
    });

    setValue('splitDetails', newList);
  };

  const handleAddCondition = () => {
    const newList = conditionsList?.concat({
      condition: '',
      detail: '',
      value: '',
    });

    setValue('conditions', newList);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onDetailsClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    accountListOptions,
    closeDrawer,
    control,
    conditionsList,
    createUpdateBankRule,
    fundList,
    handleSubmit,
    handleAddSplit,
    handleAddCondition,
    isLoading,
    lastSplit,
    lastCondition,
    memoEntityList,
    register,
    getValues,
    setValue,
    showExitConfirmation,
    toggleDrawer,
    trigger,
    errors,
    isSubmitted,
    keepDrawerOpen,
    watch,
    splitByType,
    splitDetailsList,
  };
};
