import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button as ButtonMui,
  LinearProgress,
  Link,
  Typography,
} from '@mui/material';
import { DataGridProProps, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { ReactElement, useCallback, useMemo } from 'react';

import {
  DATA_GRID_DEFAULT_MIN_WIDTH,
  DATA_GRID_DEFAULT_WIDTH,
  DATA_GRID_ROW_LENGTH,
} from '../../utils/constants/styles.constants';
import noop from '../../utils/helpers/noop';
import { DataGridColDef } from '../../utils/types/listItems';
import ColumnHeaderCell from './columnHeaderCell/ColumnHeaderCell';
import { getComponent } from './DataGrid.helper';
import { StyledDataGridPro } from './DataGrid.styles';
import HeaderSelectionPopover from './headerSelectionPopover/HeaderSelectionPopover';

interface Props extends Omit<DataGridProProps, 'columns' | 'rows'> {
  id: string;
  dataList: any[];
  headerList?: Array<DataGridColDef>;
  handleOnView?: Function;
  minHeight?: string;
  selectionModel?: Array<string>;
  setSelectionModel?: Function;
  activeHeaderFields?: number;
  handleUpdateHeader?: Function;
  onNextPage?: () => void;
  handleFilter?: (filter: any, selected: any[]) => void;
  scrollEndThreshold?: number;
  disableVirtualization?: boolean;
  pinnedColumns?: { left?: Array<string>; right?: Array<string> };
  checkboxSelection?: boolean;
  noDataMessage?: string;
  hideColumnToggleAction?: boolean;
  actionName?: string;
  hideSelectAll?: boolean;
  cellOverflow?: string;
}

const DataGrid: React.FC<Props> = ({
  id,
  dataList,
  headerList,
  selectionModel,
  setSelectionModel,
  activeHeaderFields = 0,
  handleUpdateHeader = () => {},
  handleOnView = useCallback(() => {}, []),
  handleFilter = () => {},
  minHeight = '85vh',
  autoHeight = true,
  onNextPage,
  disableVirtualization = false,
  scrollEndThreshold = 500,
  pinnedColumns = {
    right: ['action'],
  },
  checkboxSelection = true,
  loading = false,
  noDataMessage = 'No Data Available',
  hideColumnToggleAction = false,
  actionName = 'View',
  hideSelectAll = false,
  cellOverflow = 'hidden',
  ...props
}: Props): ReactElement => {
  const columns: DataGridColDef[] = useMemo(() => {
    return (
      headerList?.map((item) => {
        return {
          flex: activeHeaderFields < 5 ? 1 : 0,
          rowLength: DATA_GRID_ROW_LENGTH,
          width: item?.width ?? DATA_GRID_DEFAULT_WIDTH,
          sortable: item?.sortable ?? true,
          filterable: item?.filterable ?? false,
          disableColumnMenu: item?.disableColumnMenu ?? true,
          minWidth: item?.minWidth ?? DATA_GRID_DEFAULT_MIN_WIDTH,
          name: item.headerName,
          renderCell: (params: GridRenderCellParams) => {
            if (item.type !== 'action') {
              return getComponent(item, params.row);
            } else {
              const selectedID = params.row.id;

              if (selectedID) {
                return (
                  <ButtonMui
                    id={`btn_data_grid_${selectedID}`}
                    variant="text"
                    disableElevation
                    startIcon={actionName && <VisibilityIcon />}
                    onClick={
                      handleOnView
                        ? () => handleOnView(selectedID, params.row)
                        : noop
                    }
                    name="View button"
                  >
                    {actionName}
                  </ButtonMui>
                );
              }
            }
          },
          renderHeader: () => {
            if (item.type !== 'action') {
              return (
                <ColumnHeaderCell item={item} handleFilter={handleFilter} />
              );
            } else if (!hideColumnToggleAction) {
              return (
                <HeaderSelectionPopover
                  id="data_grid_column_selector"
                  headerFields={headerList}
                  handleUpdateHeader={handleUpdateHeader}
                />
              );
            }
          },
          ...item,
        };
      }) || []
    );
  }, [headerList, handleOnView]);

  return (
    <Box id={id}>
      <StyledDataGridPro
        rowHeight={75}
        rows={dataList}
        loading={loading}
        minHeight={minHeight}
        columns={columns}
        columnBuffer={8}
        density="compact"
        aria-label="Data List"
        autoPageSize
        cellOverflow={cellOverflow}
        disableVirtualization={disableVirtualization}
        onSelectionModelChange={(selectedModel) =>
          setSelectionModel ? setSelectionModel(selectedModel) : null
        }
        selectionModel={selectionModel}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        hideFooter
        autoHeight={autoHeight}
        scrollEndThreshold={scrollEndThreshold}
        onRowsScrollEnd={onNextPage}
        hideSelectAll={hideSelectAll}
        components={{
          ColumnUnsortedIcon: ({ sortingOrder, ...other }) => (
            <UnfoldMoreIcon {...other} />
          ),
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Typography id="no_data" align="center">
              {noDataMessage}
            </Typography>
          ),
          ...props.components,
        }}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
        {...props}
      />
    </Box>
  );
};

export default DataGrid;
