import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React, { ReactElement, useContext, useState } from "react";

import DeleteIconRed from "../../../../../assets/images/icons/icon_delete_red.svg";
import EyeIcon from "../../../../../assets/images/icons/icon_eye.svg";
import {
  MessageContent,
  Option,
  OptionListContent,
  PlaceAddress,
  ResultContent,
} from "../../../../../components/AddressAutoComplete/AddressAutocomplete.style";
import ArrowPopover from "../../../../../components/ArrowPopover/ArrowPopover";
import SplitButton from "../../../../../components/Buttons/SplitButton/SplitButton";
import { StyledStack } from "../../../../../components/ChipsGroup/ChipsGroup.style";
import CountrySelect from "../../../../../components/CountryCodeSelector/CountrySelect";
import RadioSelection from "../../../../../components/RadioSelection/RadioSelection";
import SingleSelect from "../../../../../components/SingleSelect/SingleSelect";
import TextField from "../../../../../components/TextField/TextField";
import useRole from "../../../../../core/routing/useRole";
import {
  autoCompleteAddress,
  getDetailsByIdPlace,
  getDetailsByZipCode,
} from "../../../../../services/autocomplete.service";
import { checkUniqueEmail } from "../../../../../services/contact.service";
import {
  INVESTOR_TYPE_ENTITY,
  INVESTOR_TYPE_INDIVIDUAL,
} from "../../../../../utils/constants/text.constants";
import { Prediction } from "../../../../../utils/types/address.type";
import {
  ContactAddress,
  ContactInfo,
} from "../../../../../utils/types/contactDetail.type";
import {
  Address,
  ContactPermissionDetail,
} from "../../../../../utils/types/contactPermission.type";
import {
  CountryItem,
  ImageItem,
  SelectionOptionItem,
} from "../../../../../utils/types/listItems";
import { ScopeRole } from "../../../../../utils/types/user.type";
import { ContactContext } from "../../../contactList/ContactList";
import { useAddressEffect } from "./ContactDetails.hooks";
import {
  EmptySplitButton,
  HorizontalPanelBox,
  StyledHorizontalBox,
  StyledSplitButton,
} from "./ContactDetails.styles";
import ContactPendingView from "./ContactPendingView";

type Props = {
  useContactInput: any;
  valuesContactDetail: ContactInfo | ContactPermissionDetail;
  addressValues: ContactAddress | Address;
  useContactAddressInput: any;
  useInputSelectContactAddress: any;
  entityTypeOptions: SelectionOptionItem[];
  countryList: CountryItem[];
  emailError: string;
  setEmailError: any;
  handleOnSplitButtonClick: (option: string) => void;
  onApprove: Function;
  onDecline: Function;
  investorFundRoles?: any;
};

const defaultGroupActions: ImageItem[] = [
  {
    id: "btn_impersonate",
    icon: <img src={EyeIcon} alt="" height="15" />,
    text: "Impersonate User",
  },
  {
    id: "btn_resend_email",
    text: "Resend Activation Email",
  },
  {
    id: "btn_delete_contact",
    icon: <img src={DeleteIconRed} alt="" height="15" />,
    text: "Delete Selected",
    textColor: "error",
  },
];

const ContactDetails: React.FC<Props> = (props: Props): ReactElement => {
  const {
    useContactInput,
    valuesContactDetail,
    useContactAddressInput,
    entityTypeOptions,
    countryList,
    addressValues,
    emailError,
    setEmailError,
    handleOnSplitButtonClick,
    onApprove,
    onDecline,
    investorFundRoles,
  } = props;

  const {
    selectedCountry,
    selectedState,
    city,
    zipCode,
    stateList,
    addressLine1,
    setSelectedCountry,
    setSelectedState,
    setAddressLine1,
    setCity,
    setZipCode,
  } = useAddressEffect(addressValues);

  const { isContactChanged, setIsContactChanged } =
    useContext(ContactContext);

  const [showMessageNoMatches, setShowMessageNoMatches] =
    useState<boolean>(false);
  const [predictions, setPredictions] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const isPopoverOpen = Boolean(anchorEl);

  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);
  const { hasRole: allowDeleteOperation } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
  ]);

  const handleCountryChange = (event: any) => {
    if (isContactChanged && event.target.value !== addressValues.country) {
      setIsContactChanged(true);
    }
    addressValues.state = "";
    addressValues.country = event.target.value;
    setSelectedCountry(event.target.value);
  };

  const handleStateChange = (event: any) => {
    if (isContactChanged && event.target.value !== addressValues.state) {
      setIsContactChanged(true);
    }
    addressValues.state = event.target.value;
    setSelectedState(event.target.value);
  };

  const handleCountryCodeChange = (
    countryCode: string,
    countryAbbreviation: string,
    phoneNumber: string
  ) => {
    if (!isContactChanged && (countryCode !== valuesContactDetail.countryCode || countryAbbreviation !== valuesContactDetail.countryAbbreviation || phoneNumber !== valuesContactDetail.phoneNumber)) {
      setIsContactChanged(true);
    }
    valuesContactDetail.countryCode = countryCode;
    valuesContactDetail.countryAbbreviation = countryAbbreviation;
    valuesContactDetail.phoneNumber = phoneNumber;
  };

  const handleOnEmailFocus = () => {
    setEmailError("");
  };

  const handleOnEmailBlur = async (event: any) => {
    const isEmailUnique = await checkUniqueEmail(
      valuesContactDetail.email,
      valuesContactDetail.id !== "new" ? valuesContactDetail.id : ""
    );

    if (!isEmailUnique) {
      setEmailError(
        "The email address is not valid or is already used in the system."
      );
    }
  };

  const handleSelectClick = async (placeId: string, routeName: string) => {
    const resp = await getDetailsByIdPlace(placeId);

    if (resp) {
      if (!isContactChanged && (
        addressValues.street1 !== routeName ||
        addressValues.country !== resp.country ||
        addressValues.state !== resp.state ||
        addressValues.postalCode !== resp.zipCode ||
        addressValues.city !== resp.city
      )) {
        setIsContactChanged(true);
      }
      addressValues.street1 = routeName;
      addressValues.country = resp.country;
      setSelectedCountry(resp.country);
      setAddressLine1(routeName);
      addressValues.state = resp.state;
      setSelectedState(resp.state);
      addressValues.postalCode = resp.zipCode;
      setZipCode(resp.zipCode);
      addressValues.city = resp.city;
      setCity(resp.city);
    }

    setAnchorEl(null);
  };

  const handleOnAddressChange = async ({ target, currentTarget }: any) => {
    const { value } = target;

    if (value) {
      setAnchorEl(currentTarget);
    } else {
      setAnchorEl(null);
    }

    setAddressLine1(value);
    if (!isContactChanged && value !== addressValues.street1) {
      setIsContactChanged(true);
    }
    const result: any[] = await autoCompleteAddress(value);

    if (result) {
      const found = result.filter(
        (item) => item.description.toLowerCase() === value.toLowerCase()
      );

      if (found.length === 0) {
        setShowMessageNoMatches(true);
      } else {
        setShowMessageNoMatches(false);
      }
      setPredictions(result);
    } else {
      setAnchorEl(null);
    }
  };

  const handleCityChange = (event: any) => {
    const value = event.target.value;

    setCity(value);

    if (!isContactChanged && value !== addressValues.city) {
      setIsContactChanged(true);
    }
    addressValues.city = value;
  };

  const handlePostalCodeChange = async (event: any) => {
    if (!isContactChanged && event.target.value !== addressValues.postalCode) {
      setIsContactChanged(true);
    }
    addressValues.postalCode = event.target.value;
    setZipCode(event.target.value);
    if (event.target.value.length >= 5) {
      addressValues.postalCode = event.target.value;

      const placeDetail = await getDetailsByZipCode(event.target.value);

      if (placeDetail.country || placeDetail.city) {
        addressValues.country = placeDetail.country;
        setSelectedCountry(placeDetail.country);
        addressValues.state = placeDetail.state;
        setSelectedState(placeDetail.state);
        addressValues.city = placeDetail.city;
        setCity(placeDetail.city);
      } else {
        resetAddressFields();
      }
    } else {
      resetAddressFields();
    }
  };

  const resetAddressFields = () => {
    addressValues.country = "";
    setSelectedCountry("");
    addressValues.state = "";
    setSelectedState("");
    addressValues.city = "";
    setCity("");
  };

  const handleCloseArrowPopover = () => {
    setAnchorEl(null);
  };

  const getUserActions = () => {
    if (allowDeleteOperation) {
      return defaultGroupActions;
    }
    return defaultGroupActions.filter(({ id }) => id !== "btn_delete_contact");
  };

  const emailInput = useContactInput("email", setEmailError);

  return (
    <Container id="container_contact_main" role="main">
      <StyledHorizontalBox>
        <HorizontalPanelBox>
          <Typography>This contact is an</Typography>
          <RadioSelection
            id={"radio_contacts_details_entity_type"}
            label={"Entity type selection"}
            options={entityTypeOptions}
            {...useContactInput("contactType")}
          />
        </HorizontalPanelBox>

        {!isBasicUser &&
          valuesContactDetail?.id &&
          valuesContactDetail?.id !== "new" ? (
          <StyledSplitButton>
            <SplitButton
              id={"split_btn_contact_details"}
              ariaLabelMessage={"Select action option"}
              options={getUserActions()}
              hidden={false}
              handleOptionClick={handleOnSplitButtonClick}
            />
          </StyledSplitButton>
        ) : (
          <Box style={{ width: "230px" }}></Box>
        )}
      </StyledHorizontalBox>
      {!isBasicUser && valuesContactDetail.status === "PENDING" && (
        <ContactPendingView
          selectedContact={valuesContactDetail}
          investorFundRoles={investorFundRoles}
          approvePendingChanges={onApprove}
          declinePendingChanges={onDecline}
        />
      )}
      <Box>
        <TextField
          size="small"
          id="txt_contact_name"
          label="First Name"
          required={
            valuesContactDetail.contactType === INVESTOR_TYPE_INDIVIDUAL
          }
          {...useContactInput("firstName")}
          hidden={valuesContactDetail.contactType === INVESTOR_TYPE_ENTITY}
        />
      </Box>
      <Box>
        <TextField
          size="small"
          id="txt_contact_last_name"
          label="Last Name"
          required={
            valuesContactDetail.contactType === INVESTOR_TYPE_INDIVIDUAL
          }
          {...useContactInput("lastName")}
          hidden={valuesContactDetail.contactType === INVESTOR_TYPE_ENTITY}
        />
      </Box>

      <Box id="personal_info_panel">
        <TextField
          size="small"
          id="txt_contact_name_info"
          label="Name"
          required={valuesContactDetail.contactType === INVESTOR_TYPE_ENTITY}
          fullWidth
          {...useContactInput("name")}
          hidden={valuesContactDetail.contactType !== INVESTOR_TYPE_ENTITY}
        />
        <TextField
          size="small"
          id="txt_contact_email"
          label="Email"
          type="email"
          inputProps={{ 
            sx: { textTransform: "lowercase" },
            maxLength: 255
          }}
          required
          onBlur={handleOnEmailBlur}
          onFocus={handleOnEmailFocus}
          {...emailInput}
          {...(emailError
            ? { error: Boolean(emailError), helperText: emailError }
            : {})}
        />
      </Box>

      <CountrySelect
        id={"select_country_contact_details"}
        label={"Phone Number"}
        onChange={handleCountryCodeChange}
        value={
          valuesContactDetail.pendingPhoneNumber 
            ? valuesContactDetail.pendingCountryCode + valuesContactDetail.pendingPhoneNumber
            : valuesContactDetail.phoneNumber
              ? valuesContactDetail.countryCode + valuesContactDetail.phoneNumber
              : valuesContactDetail.countryCode
        }
      />

      <TextField
        size="small"
        id="txt_contact_organization"
        label="Organization"
        fullWidth
        {...useContactInput("organization")}
      />
      <Box>
        <TextField
          size="small"
          id="txt_contact_main_address1"
          label="Address line 1"
          fullWidth
          onChange={handleOnAddressChange}
          value={addressLine1}
        />
        <PlaceAddress id="placeid" />
        <ArrowPopover
          id="arrow_popover"
          anchorEl={anchorEl}
          showPopover={isPopoverOpen}
          size="lg"
          handleOnPopoverClose={handleCloseArrowPopover}
          content={
            <ResultContent>
              <MessageContent>
                {showMessageNoMatches
                  ? `An exact match could not be found. 
                Please select from the options below.`
                  : ""}
              </MessageContent>
              <OptionListContent>
                {predictions?.map((option: Prediction, index) => {
                  return (
                    <div key={option.place_id}>
                      <StyledStack direction="row">
                        <Option>{option.description || "Not found"}</Option>
                        <Button
                          id={`btn_option_${option.place_id}`}
                          variant="text"
                          disableElevation
                          onClick={() =>
                            handleSelectClick(
                              option.place_id,
                              option.structured_formatting.main_text
                            )
                          }
                          name="Select"
                        >
                          Select
                        </Button>
                      </StyledStack>

                      <Divider />
                    </div>
                  );
                })}
              </OptionListContent>
            </ResultContent>
          }
        />
      </Box>

      <TextField
        size="small"
        id="txt_contact_main_address2"
        label="Address line 2"
        fullWidth
        {...useContactAddressInput("street2")}
      />

      <Box>
        <TextField
          size="small"
          id="txt_contact_zip1"
          label="Zip/Post-Code"
          value={zipCode}
          onChange={handlePostalCodeChange}
          inputProps={{ minLength: 5, maxLength: 7, pattern: "[0-9]" }}
        />
      </Box>

      <Box>
        <TextField
          size="small"
          id="txt_contact_city"
          label="City"
          value={city}
          onChange={handleCityChange}
        />
      </Box>
      <Box>
        <SingleSelect
          id="select_contact_country"
          value={selectedCountry || ""}
          optionList={countryList}
          label="Country"
          handleOptionSelection={handleCountryChange}
        />
      </Box>
      <Box>
        <SingleSelect
          id="select_contact_state"
          optionList={stateList}
          label="State/Province/Region"
          value={selectedState || ""}
          handleOptionSelection={handleStateChange}
        />
      </Box>
    </Container>
  );
};

export default ContactDetails;
