import { FileDownloadOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { Divider, Stack, Typography } from "@mui/material";
import React from "react";

import { fetchBeforeSave } from "../../../../utils/helpers/misc.helper";
import { Reviews } from "../../../../utils/types/capitalCalls.type";
import {
  Container,
  DownLoadButton,
  FlagButton,
  PdfContainer,
  StyledStack,
} from "./DisplayPdf.styles";
import PdfViewer from "./PdfViewer";

type Props = {
  handleOnClick: (value: number) => void;
  investors: string[];
  documentUrls: string[];
  disabled?: boolean;
  reviews: Reviews[];
  selectedIndex?: number;
};

const DisplayPdf = ({
  handleOnClick,
  investors,
  documentUrls,
  reviews,
  selectedIndex = 1,
  disabled = false,
}: Props) => {
  return (
    <Container>
      {investors.map((investor, index) => {
        return (
          <PdfContainer key={index}>
            <Typography variant="h3">
              <strong>{investor}</strong>
            </Typography>
            <StyledStack active={selectedIndex === index} onClick={() => handleOnClick(index)}>
              <Stack>
                <PdfViewer pdfLink={documentUrls[index]} sideView={true} />
              </Stack>
              <Divider variant="fullWidth"></Divider>
              <Stack direction="row">
                <FlagButton
                  aria-label="flag button"
                  color={reviews[index].checked === true ? "success" : "error"}
                  title="status"
                  id="flag_button"
                >
                  {reviews[index].checked === true ? (
                    <CheckCircleIcon />
                  ) : reviews[index].checked === null ? (
                    ""
                  ) : (
                    <FlagCircleIcon />
                  )}
                </FlagButton>

                <DownLoadButton
                  disabled={disabled}
                  aria-label="download file"
                  color="primary"
                  title="Download File"
                  id="download_file"
                  onClick={() => fetchBeforeSave(documentUrls[index], `${investor}.pdf`)}
                >
                  <FileDownloadOutlined />
                </DownLoadButton>
              </Stack>
            </StyledStack>
          </PdfContainer>
        );
      })}
    </Container>
  );
};

export default React.memo(DisplayPdf);
