import { DateTimeFormat } from "./format.helper";

export function getQuarterStartEndDates(quarterNumber: number, year: number) {
    let startDate: Date;
    let endDate: Date;

    switch(quarterNumber) {
        case 1:
            startDate = new Date(`${year}-01-01`);
            endDate = new Date(`${year}-03-31`);
            break;
        case 2: 
            startDate = new Date(`${year}-04-01`);
            endDate = new Date(`${year}-06-30`); 
            break;
        case 3: 
            startDate = new Date(`${year}-07-01`);
            endDate = new Date(`${year}-09-30`); 
            break;
        case 4: 
            startDate = new Date(`${year}-10-01`);
            endDate = new Date(`${year}-12-31`); 
            break;
        default: throw `Invalid quarter ${quarterNumber}`;
    }

    return {
        startDate: new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()),
        endDate: new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate())
    };
} 

export function getQuarterStartEndDateByDate(d: Date) {
    const year = d.getFullYear();

    for(let q = 1; q <= 4; q++) {
        const testDates = getQuarterStartEndDates(q, year);

        if(DateTimeFormat.compareDateOnly(d, testDates.startDate) >=0 && DateTimeFormat.compareDateOnly(d, testDates.endDate) <=0) {
            return testDates;
        }
    }

    //  should never be possible to get here
    throw 'Error retreiving quarter Start and End Dates';
}