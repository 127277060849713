import { useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { User, UserList } from "../../../utils/types/user.type";
import { getUsers } from "../../user.service.v2";

export const useUsersEffect = () => {
  const [users, setUsers] = useState<UserList | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchUsers = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getUsers();

      if (isCanceled?.()) return;
      setUsers(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting users", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchUsers(isCanceled);
  }, []);

  const currentUsers = useMemo(() => {
    return users?.items || [];
  }, [users]);

  const updateUsers = (updatedUsers: User[]) => {
    setUsers((prevState) => ({
      ...(prevState ? prevState : {}),
      items: updatedUsers
    }));
  };

  return {
    users: currentUsers,
    fetchUsers,
    loading,
    updateUsers,
  };
};
