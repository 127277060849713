import {
  Button as MUIButton,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  TextField as MUITextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  MessageContent,
  Option,
  OptionListContent,
  PlaceAddress,
  ResultContent,
} from '../../../components/AddressAutoComplete/AddressAutocomplete.style';
import ArrowPopover from '../../../components/ArrowPopover/ArrowPopover';
import ContentColumnBox from '../../../components/Boxes/ContentColumnBox/ContentColumnBox';
import Button from '../../../components/Buttons/Button/Button';
import { DeleteIcon } from '../../../components/Chip/Chip.styles';
import { StyledStack } from '../../../components/ChipsGroup/ChipsGroup.style';
import Container from '../../../components/Container/Container';
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import SingleSelect from '../../../components/SingleSelect/SingleSelect';
import Switch from '../../../components/Switch/Switch';
import TabsPanel from '../../../components/TabsPanel/TabsPanel';
import TextField from '../../../components/TextField/TextField';
import { Prediction } from '../../../utils/types/address.type';
import {
  useAddressEffect,
  useFormTypesEffect,
  useFundDetailsEffect,
  useTabs,
  useWireEffect,
} from './FundDetails.hooks';
import {
  BankInfoStack,
  DatePicker,
  HeaderRow,
  Panel,
} from './FundDetails.style';
import { Box, List, Stack, StatusControl } from './FundDetails.style';

export const FundDetails: React.FC = () => {
  const { fundId } = useParams<{ fundId: string }>();

  const {
    fund,
    fundCurrencyError,
    fundInceptionDate,
    fundInceptionDateError,
    fundName,
    fundNameError,
    fundStatus,
    fundTeams,
    availableFundTeams,
    activeFundTeams,
    isSendingData,
    isLoadingData,
    readonly,
    handleOnCancel,
    handleOnSave,
    handleRemoveTeam,
    handleTeamSelect,
    handleFundNameValidation,
    handleFundInceptionDateChange,
    setFundName,
    setFundStatus,
    setFundInceptionDate,
    setFundInceptionDateError,
    useFundDetailsInput,
    useFundDetailsInputSelect,
    useAddressInput,
    addressValues,
    useWireInfoInput,
    useReceivingBankInfoInput,
    useBeneficiaryInfoInput,
    useIntermediaryBankInfoInput,
    valuesFundDetail,
    entityTypeError,
    legalTypeError,
    fundTypeError,
  } = useFundDetailsEffect(fundId);

  const {
    stateList,
    selectedCountry,
    selectedState,
    addressLine1,
    city,
    zipCode,
    showMessageNoMatches,
    predictions,
    anchorEl,
    isPopoverOpen,
    handleCountryChange,
    handleCloseArrowPopover,
    handleOnAddressChange,
    handleSelectClick,
    handleStateChange,
    handleCityChange,
    handlePostalCodeChange,
  } = useAddressEffect(addressValues, fundId);

  const { countryList, currencyList, entityTypeList, typeList, legalTypeList } =
    useFormTypesEffect();

  const {
    currentTab,
    detailsRef,
    teamRef,
    wiringRef,
    pageBottomRef,
    handleTabChange,
  } = useTabs();

  const { isIntermediaryBankInfoHidden, handleIntermediaryBankCheck } =
    useWireEffect(fund.wireInfo, pageBottomRef, fundId);

  return (
    <Container id="fund_details_main" role="main">
      <ProgressModal
        id="modal_fund_details_loading"
        showProgress={isLoadingData}
      />

      <ProgressModal
        id="modal_saving_fund_details"
        showProgress={isSendingData}
        text="Saving fund details..."
      />

      <HeaderRow>
        <TabsPanel
          id={'fund_detail_panel_tabs'}
          value={currentTab}
          handleChange={handleTabChange}
          options={['Details', 'Wiring', 'Fund Team']}
        />

        <Stack>
          <Button
            id="btn_fund_detail_cancel"
            variant="text"
            onClick={handleOnCancel}
            text="Cancel"
            color="secondary"
          />
          <Button
            id="btn_fund_detail_save"
            variant="contained"
            onClick={handleOnSave}
            text="Save"
            color="primary"
          />
        </Stack>
      </HeaderRow>

      <Box>
        <Panel id="details_panel" ref={detailsRef}>
          <Typography variant="h4">Fund Details</Typography>
          <Stack>
            <TextField
              fullWidth
              size="small"
              id="txt_fund_name"
              variant="outlined"
              label="Name"
              required
              error={fundNameError !== ''}
              helperText={fundNameError}
              value={fundName}
              onBlur={(e) => handleFundNameValidation(e.target.value)}
              onChange={(e: any) => setFundName(e.target.value)}
            />
            <TextField
              size="small"
              id="txt_fund_shortname"
              label="Short Name"
              {...useFundDetailsInput('shortName')}
            />
          </Stack>
          <Stack>
            <DatePicker
              label="Inception Date *"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  id="dtp_inception_date"
                  error={fundInceptionDateError !== ''}
                  helperText={fundInceptionDateError}
                />
              )}
              onChange={(value) => handleFundInceptionDateChange(value)}
              value={fundInceptionDate}
            />

            <StatusControl>
              <FormControlLabel
                id="chk_fund_status"
                label={fundStatus ? 'ACTIVE' : 'INACTIVE'}
                control={
                  <Switch
                    id="swtch_status"
                    checked={fundStatus}
                    label="status"
                    onChange={() => setFundStatus(!fundStatus)}
                  />
                }
              />
              <FormHelperText>Check if active</FormHelperText>
            </StatusControl>
          </Stack>

          <TextField
            size="small"
            id="txt_fund_tin"
            label="TIN"
            {...useFundDetailsInput('taxIdentificationNumber')}
          />
          <SingleSelect
            id="select_fund_legal_type"
            optionList={legalTypeList}
            label="Legal Type *"
            {...useFundDetailsInputSelect('legalType')}
            error={legalTypeError !== '' && !valuesFundDetail.legalType}
            helperText={legalTypeError}
          />
          <TextField
            size="small"
            id="txt_fund_reporting_period"
            label="Reporting Period"
            {...useFundDetailsInput('reportingPeriod')}
          />
          <SingleSelect
            id="select_fund_type"
            optionList={typeList}
            label="Fund Type *"
            {...useFundDetailsInputSelect('type')}
            error={fundTypeError !== '' && !valuesFundDetail.type}
            helperText={fundTypeError}
          />
          <SingleSelect
            id="select_fund_entity_type"
            optionList={entityTypeList}
            label="Entity Type *"
            {...useFundDetailsInputSelect('entityType')}
            error={entityTypeError !== '' && !valuesFundDetail.entityType}
            helperText={entityTypeError}
          />
          <TextField
            size="small"
            id="txt_fund_family"
            label="Fund Family"
            {...useFundDetailsInput('fundFamily')}
          />
          <SingleSelect
            id="select_fund_currency"
            optionList={currencyList}
            label="Fund Currency *"
            {...useFundDetailsInputSelect('currency')}
            error={fundCurrencyError !== '' && !valuesFundDetail.currency}
            helperText={fundCurrencyError}
          />

          <TextField
            size="small"
            id="txt_fund_fee"
            label="Fund Fee"
            {...useFundDetailsInput('fee')}
          />
        </Panel>

        {/* Contact info */}
        <Panel id="address_panel">
          <TextField
            size="small"
            id="txt_fund_phone"
            label="Phone"
            {...useFundDetailsInput('phone')}
          />

          <TextField
            size="small"
            id="txt_fund_fax"
            label="Fax"
            {...useFundDetailsInput('fax')}
          />
          <br />

          <TextField
            size="small"
            id="txt_fund_main_address1"
            label="Address line 1"
            onChange={handleOnAddressChange}
            value={addressLine1}
          />
          <PlaceAddress id="placeid" />
          <ArrowPopover
            id="arrowPopover"
            anchorEl={anchorEl}
            showPopover={isPopoverOpen}
            size="lg"
            handleOnPopoverClose={handleCloseArrowPopover}
            content={
              <ResultContent>
                <MessageContent>
                  {showMessageNoMatches
                    ? `An exact match could not be found. 
              Please select from the options below.`
                    : ''}
                </MessageContent>
                <OptionListContent>
                  {predictions?.map((option: Prediction, index) => {
                    return (
                      <div key={option.place_id}>
                        <StyledStack direction="row">
                          <Option>{option.description || 'Not found'}</Option>
                          <MUIButton
                            id={`btn_option_${option.place_id}`}
                            variant="text"
                            disableElevation
                            onClick={() =>
                              handleSelectClick(
                                option.place_id,
                                option.structured_formatting.main_text
                              )
                            }
                            name="Select"
                          >
                            Select
                          </MUIButton>
                        </StyledStack>

                        <Divider />
                      </div>
                    );
                  })}
                </OptionListContent>
              </ResultContent>
            }
          />

          <TextField
            size="small"
            id="txt_fund_address2"
            label="Address line 2"
            {...useAddressInput('street2')}
          />
          <br />

          <TextField
            size="small"
            id="txt_fund_zip1"
            label="Zip/Post-Code"
            value={zipCode}
            onChange={handlePostalCodeChange}
            inputProps={{
              minLength: 5,
              maxLength: 7,
              pattern: '[0-9]',
            }}
          />
          <TextField
            size="small"
            id="txt_fund_city"
            label="City"
            value={city}
            onChange={handleCityChange}
          />

          <br />
          <SingleSelect
            value={selectedState}
            id="select_fund_state"
            optionList={stateList}
            label="State/Province/Region"
            handleOptionSelection={handleStateChange}
          />
          <SingleSelect
            id="select_fund_country1"
            value={selectedCountry}
            optionList={countryList}
            label="Country"
            handleOptionSelection={handleCountryChange}
          />
        </Panel>

        <Panel id="wireinfo_panel" ref={wiringRef}>
          <Stack>
            <Typography variant="h4">Wire Instructions</Typography>
          </Stack>
          <BankInfoStack>
            <Box>
              <Typography variant="h3">Receiving Bank information</Typography>
              <TextField
                size="small"
                id="txt_investor_Bank_ABA"
                label="Receiving Bank ABA/Swift"
                fullWidth
                {...useReceivingBankInfoInput('abaNumber')}
              />

              <TextField
                size="small"
                id="txt_receiving_bank_name"
                label="Receiving Bank Name"
                fullWidth
                {...useReceivingBankInfoInput('name')}
              />

              <TextField
                size="small"
                id="txt_Receiving_bank_Address1"
                label="Receiving Bank Address 1"
                fullWidth
                {...useReceivingBankInfoInput('addressLine1')}
              />

              <TextField
                size="small"
                id="txt_Receiving_bank_Address2"
                label="Receiving Bank Address 2"
                fullWidth
                {...useReceivingBankInfoInput('addressLine2')}
              />
            </Box>
            <Box>
              <Typography variant="h3">
                Originator to Beneficiary Instructions
              </Typography>
              <MUITextField
                size="small"
                id="txt_originator_to_beneficiary"
                label="Originator to Beneficiary Instructions 1"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('originatorToBeneficiaryInstructions1')}
              />
              <MUITextField
                size="small"
                id="txt_originator_to_beneficiary"
                label="Originator to Beneficiary Instructions 2"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('originatorToBeneficiaryInstructions2')}
              />
              <MUITextField
                size="small"
                id="txt_originator_to_beneficiary"
                label="Originator to Beneficiary Instructions 3"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('originatorToBeneficiaryInstructions3')}
              />
              <MUITextField
                size="small"
                id="txt_originator_to_beneficiary"
                label="Originator to Beneficiary Instructions 4"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('originatorToBeneficiaryInstructions4')}
              />
            </Box>
          </BankInfoStack>
          <br />

          <BankInfoStack>
            <Box>
              <Typography variant="h3">Beneficiary Information</Typography>
              <TextField
                size="small"
                id="txt_Beneficiary_Account"
                label="Beneficiary Account"
                fullWidth
                {...useWireInfoInput('beneficiaryAccount')}
              />

              <TextField
                size="small"
                id="txt_Beneficiary_Name"
                label="Beneficiary Name"
                fullWidth
                {...useWireInfoInput('beneficiaryName')}
              />

              <TextField
                size="small"
                id="txt_Beneficiary_Address_Line_1"
                label="Beneficiary Address Line 1"
                fullWidth
                {...useWireInfoInput('beneficiaryAddressLine1')}
              />

              <TextField
                size="small"
                id="txt_Beneficiary_Address_Line_2"
                label="Beneficiary Address Line 2"
                fullWidth
                {...useWireInfoInput('beneficiaryAddressLine2')}
              />

              <TextField
                size="small"
                id="txt_Beneficiary_bank_ABA"
                label="Beneficiary bank ABA/Swift"
                fullWidth
                {...useBeneficiaryInfoInput('abaNumber')}
              />

              <TextField
                size="small"
                id="txt_Beneficiary_Bank_Name"
                label="Beneficiary Bank Name"
                fullWidth
                {...useBeneficiaryInfoInput('name')}
              />

              <TextField
                size="small"
                id="txt_BankAddressLine1"
                label="Beneficiary Bank Address Line 1"
                fullWidth
                {...useBeneficiaryInfoInput('addressLine1')}
              />

              <TextField
                size="small"
                id="txt_BankAddressLine2"
                label="Beneficiary Bank Address Line 2"
                fullWidth
                {...useBeneficiaryInfoInput('addressLine2')}
              />
            </Box>
            <Box>
              <Typography variant="h3">Bank to Bank Instructions</Typography>
              <MUITextField
                size="small"
                id="txt_bank_to_bank"
                label="Bank to Bank Instructions 1"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('bankToBankInstructions1')}
              />
              <MUITextField
                size="small"
                id="txt_bank_to_bank"
                label="Bank to Bank Instructions 2"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('bankToBankInstructions2')}
              />
              <MUITextField
                size="small"
                id="txt_bank_to_bank"
                label="Bank to Bank Instructions 3"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('bankToBankInstructions3')}
              />
              <MUITextField
                size="small"
                id="txt_bank_to_bank"
                label="Bank to Bank Instructions 4"
                rows={1}
                multiline
                fullWidth
                inputProps={{ maxLength: 255 }}
                {...useWireInfoInput('bankToBankInstructions4')}
              />
            </Box>
          </BankInfoStack>

          <ContentColumnBox>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isIntermediaryBankInfoHidden}
                  value={isIntermediaryBankInfoHidden}
                  onClick={handleIntermediaryBankCheck}
                />
              }
              id="txt_Bank_Account_not_in_US"
              label="Bank Account not in the US."
            />
            <FormHelperText>
              Click here if your bank account is foreign to the US.
            </FormHelperText>
          </ContentColumnBox>
          <div hidden={isIntermediaryBankInfoHidden}>
            <TextField
              size="small"
              id="txt_investor_intermediary_bank_ABA"
              label="Intermediary Bank ABA/Swift"
              fullWidth
              {...useIntermediaryBankInfoInput('abaNumber')}
            />

            <TextField
              size="small"
              id="txt_Receiving_intermediary_bank_Address1"
              label="Intermediary Bank Address 1"
              fullWidth
              {...useIntermediaryBankInfoInput('addressLine1')}
            />

            <TextField
              size="small"
              id="txt_Receiving_intermediary_bank_Address2"
              label="Intermediary Bank Address 2"
              fullWidth
              {...useIntermediaryBankInfoInput('addressLine2')}
            />
            <div ref={pageBottomRef} />
          </div>
        </Panel>

        <Panel id="team_panel" ref={teamRef}>
          <Typography variant="h4">Teams</Typography>

          <SingleSelect
            id="select_fund_teams"
            optionList={availableFundTeams}
            label="Add a Team"
            value=""
            handleOptionSelection={handleTeamSelect}
          />

          <List dense>
            {activeFundTeams.map((team) => {
              return (
                <ListItem
                  key={team.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveTeam(team.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={team.label} />
                </ListItem>
              );
            })}
          </List>
          <br />
        </Panel>
      </Box>
    </Container>
  );
};
