import { AccessTime } from "@mui/icons-material";
import { Box, Button, Divider, Paper, styled, Typography } from "@mui/material";
import React from 'react';

export const DashboardTitle = styled("h1")(({
  theme
}) => ({
  marginBottom: theme.spacing(0),
}));

export const DashboardSubtitle = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.common.inputOutline,
  opacity: 0.6,
  fontWeight: 600,
  fontSize: '16px'
}));

export const AddressBarWrapper = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gap: theme.spacing(2),
  margin: theme.spacing(3, 0),
}));

export const AddressBar = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[500]}`,
  padding: theme.spacing(1),
  alignItems: "center",
}));

export const AddressBarLink = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.common.inputOutline,
  opacity: 0.6,
  fontSize: '20px'
}));

export const ViewPortalButton = styled(Button)<{ target?: any, to?: any, component?: React.ElementType }>(({
  theme
}) => ({
  fontSize: '18px',
  fontWeight: 800,
  borderRadius: theme.spacing(2),
}));

export const CopyLinkButton = styled(Button)(({
  theme
}) => ({
  fontSize: '16px',
  fontWeight: 800,
}));

export const PendingItemsPaper = styled(Paper)(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 5),
  borderRadius: theme.spacing(2),
}));

export const PendingItemsScrollableSection = styled(Box)(({
  theme
}) => ({
  width: '100%',
  maxHeight: '35vh',
  overflowY: 'auto',
  [theme.breakpoints.up("lg")]: {
    maxHeight: '45vh',
  },
}));

export const PendingItemsTitle = styled("h3")(({
  theme
}) => ({
  fontSize: '22px',
  fontWeight: 600,
  lineHeight: '1.1',
  color: theme.palette.common.subTitle,
  marginBottom: theme.spacing(4),
  display: 'flex',
  gap: theme.spacing(2)
}));

export const PendingItemsIcon = styled(AccessTime)(({
  theme
}) => ({
  color: theme.palette.common.warningYellow,
}));

export const PendingItemsRow = styled(Box)(({
  theme
}) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '300px 1fr 300px 1fr 300px',
}));

export const PendingItemsHeaderCell = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.common.inputOutline,
  opacity: 0.6,
  fontWeight: 600,
  fontSize: '16px',
}));

export const ViewChangesButton = styled(Button)(({
  theme
}) => ({
  fontSize: '16px',
  fontWeight: 800,
  paddingLeft: 0,
  justifyContent: 'flex-start',
}));


export const StyledDivider = styled(Divider)(({
  theme
}) => ({
  margin: theme.spacing(2, 0),
}));
