import React, { ReactElement } from "react";

import Chip from "../Chip/Chip";
import { StyledStack } from "./ChipsGroup.style";

type Props = {
  ChipTitleList: any;
  handleRemoveFilter?: any;
  color?: string;
};

const ChipGroup: React.FC<Props> = ({
  ChipTitleList,
  handleRemoveFilter,
  color = "green",
}: Props): ReactElement => {
  return (
    <StyledStack direction="row" spacing={1}>
      {ChipTitleList.map((option: any) => (
        <Chip
          label={option.name}
          id={option.id}
          key={option.id}
          color={color}
          {...(handleRemoveFilter
            ? {
                onDelete: () => handleRemoveFilter(option.id),
              }
            : {})}
        />
      ))}
    </StyledStack>
  );
};

export default ChipGroup;
