import {
  ConsentBodyParams,
  ConsentQueryParams,
  DocControlPermissions,
  DocEventLogData,
  DocumentLinkParams,
  Documents,
  EmailOrUrl,
  FileDetails,
  Files,
  Folder,
  FolderContentRequestPayload,
  NoticeUrl,
  Permission,
  PublishFileParams,
  PublishStatusEnum,
  UploadFileResponse,
} from '../utils/types/documents.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.DocumentsApi);
};

export const getBaseUrl = () => {
  return apiClient().BaseUrl;
};

export async function getDocuments(): Promise<Documents> {
  const documents: Documents = await apiClient().get<Documents>(
    'document-api/folders'
  );

  return documents;
}

export async function getDocumentTree(): Promise<Documents> {
  const documentTree: Documents = await apiClient().get<Documents>(
    'document-api/folders/tree'
  );

  return documentTree;
}

export async function getDocumentsFolders(id: string): Promise<Documents> {
  const documents: Documents = await apiClient().get<Documents>(
    `document-api/folders/${id}`
  );

  return documents;
}

export async function getDocumentsFolderFilesV2(
  id: string,
  published: boolean,
  payload: FolderContentRequestPayload
): Promise<Files> {
  const files: Files = await apiClient().post<Files>(
    `document-api/folders/files/${id}?isPublished=${published}`,
    payload
  );

  return files;
}

export async function getDocumentFolderPermissions(): Promise<Permission> {
  const permissions: Permission = await apiClient().get<Permission>(
    `api/permissions?group=DOCUMENT_STORE`
  );

  return permissions;
}

export async function setDocControlPermissions(
  fileIds: string[],
  permissions: DocControlPermissions,
  archivePolicy?: any
): Promise<any> {
  const data = {
    fileIds: fileIds,
    permissions: { ...permissions },
    archivePolicy: {
      archiveOn: archivePolicy?.archiveOn || null,
      archivePolicy: archivePolicy?.archivePolicy || null,
    },
  };

  return await apiClient().put<any>(`/document-api/files/update`, data);
}

export async function addNewFolder(
  id: string,
  params: Folder
): Promise<Documents> {
  const folder: Documents = await apiClient().post(
    `document-api/folders/${id}/folders`,
    params
  );

  return folder;
}

export async function downloadFilesAndFolder(
  folderIds: string,
  fileIds: string,
  isDownload: boolean = true
): Promise<Blob> {
  return await apiClient().get(
    `document-api/files?folderIds=${folderIds || ''}&fileIds=${
      fileIds || ''
    }&download=${isDownload}`,
    {
      responseType: 'blob',
    }
  );
}

export async function deletingFolder(folderId: string): Promise<Documents> {
  return await apiClient().delete(`document-api/folders/${folderId}`);
}

export async function deleteFiles(fileIds: string): Promise<Documents> {
  return await apiClient().delete(`document-api/files?ids=${fileIds}`);
}

export async function updateFolder(
  id: string,
  params: Folder | Documents
): Promise<Documents> {
  const folder: any = await apiClient().put(
    `document-api/folders/${id}`,
    params
  );

  return folder;
}

export function uploadFiles(
  id: string,
  upload_type: string,
  files: FileList | File[],
  uploadProgressCb?: (progress: number) => void
): Promise<Documents> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progress: any) => {
      if (typeof uploadProgressCb === 'function') {
        uploadProgressCb(Math.ceil(progress.loaded / progress.total) * 100);
      }
    },
  };
  const formData = new FormData();

  formData.append('formatted', upload_type);

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  return apiClient().post(`document-api/folders/${id}/files`, formData, config);
}

export async function updateFiles(
  id: string,
  params: any
): Promise<UploadFileResponse> {
  return await apiClient().put(`document-api/files/update/${id}`, params);
}

export async function addFileTags(params: any): Promise<UploadFileResponse> {
  return await apiClient().patch(`document-api/files`, params);
}

export const getDocumentUrl = async (id: string) =>
  await apiClient().get<EmailOrUrl>(`document-api/files/${id}/url`);

export const getNoticeUrl = async (id: string) =>
  await apiClient().get<NoticeUrl>(`/document-api/files/urls?fileIds=${id}`);

export const getEmail = async (id: string) =>
  await apiClient().get<EmailOrUrl>(`document-api/approvals/${id}/emails`);

export async function moveFiles(
  fileIds: string,
  folderId: string
): Promise<any> {
  return await apiClient().patch(`document-api/files/${fileIds}`, {
    destinationFolderId: folderId,
  });
}

export async function publishFiles(
  status: PublishStatusEnum,
  params: PublishFileParams
): Promise<any> {
  return await apiClient().put(`document-api/files/status/${status}`, params);
}

export async function downloadFilesLinks(
  params: DocumentLinkParams
): Promise<any> {
  return await apiClient().post(`api/downloads`, params, {
    responseType: 'blob',
  });
}

export async function changeFolderIndex(params: any): Promise<any> {
  return await apiClient().put(`document-api/folders/${params.id}`, params);
}

export async function fetchConsent(
  params: ConsentQueryParams
): Promise<string> {
  return await apiClient().get(
    `api/clients/${params.clientId}/consents/${params.consentType}`
  );
}

export async function consentAction(
  params: ConsentBodyParams
): Promise<Documents> {
  return await apiClient().post(
    `document-api/folders/${params.folderId}/consent`,
    params
  );
}

export const getFileDetails = async (id: string) =>
  await apiClient().get<FileDetails>(`document-api/files/${id}/details`);

export const logDocumentEvent = async (
  logData: DocEventLogData
): Promise<void> => await apiClient().post(`document-api/event`, logData);
