import {
  Box,
  Button,
  Drawer,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";
export const DetailPanel = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  },
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(4),
}));

export const FormBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxHeight: "calc(100vh - 200px)",
  overflow: "auto",
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "16px",
  width: "70%",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "48%",
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: "48%",
}));

export const AmountTextField = styled(TextField)(({ theme }) => ({
  width: "24%",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  marginBottom: theme.spacing(2),
}));
