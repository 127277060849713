import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { ClientTransTypeColumn } from "../../../../utils/types/clientTransMapping.type";

type Props = {
  column?: ClientTransTypeColumn
  onSave: Function;
  onCancel: Function;
}

export const DATE_RANGE=[{
  id: "QTD",
  name: "Quarter to Date (QTD)"
}, {
  id: "ITD",
  name: "Inception to Date (ITD)"
}];

export const useAddColumns = ({
  column,
  onSave,
  onCancel,
}: Props) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
  } = useForm<ClientTransTypeColumn>({
    defaultValues: column ?? {}
  });

  const label = watch("label");
  const code = column?.code;

  useEffect(() => {
    if(label && (code === undefined || code === null || code.length === 0)) {
      setValue("code", label.toUpperCase());
    }
  },[label, code]);

  useEffect(() => {
    reset(column);
  }, [column, reset]);

  const addUpdatedColumn = (data: ClientTransTypeColumn) => {
    onSave({
      ...data
    });
    reset();
  };

  const handleCancel = () => {
    onCancel();
    reset();
  };

  return {
    addUpdatedColumn,
    handleSubmit,
    control,
    handleCancel,
  };
};


