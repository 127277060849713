import { Box, styled } from "@mui/material";

export const MappingProgress = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex row",
    alignItems: "center",
    paddingTop: "10px",
    widthMax: "300px",
    width: "300px",
  },

  "& .MuiLinearProgress-root.MuiLinearProgress-determinate": {
    height: "12px",
    width: "100%",
  },

  "& .MuiTypography-root": {
    width: "250px",
    color: theme.palette.primary.main,
  },

  "& .MuiTypography-root:nth-of-type(2)": {
    textAlign: "right",
  },
}));

export const NumberBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
