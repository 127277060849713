import { Box, styled } from "@mui/material";

import Filter from "../../../../components/DataGrid/Filter/Filter";

export const StyledFilter = styled(Filter)(({
    theme
}) => ({
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(1),
}));

export const FundBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(1)
}));

export const ChipsContent = styled("div")(() => ({
  maxWidth: "90%",
}));