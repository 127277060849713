import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import {
  StyledDialog,
  StyledDialogTitle,
} from '../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog.styles';
import { ClientTransTypeRow } from '../../../../utils/types/clientTransMapping.type';
import { StyledFormLabel, StyledStack } from './AddRow.styles';
import { useAddRow } from './useAddRow.hooks';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSave: (data: ClientTransTypeRow) => void;
  row?: ClientTransTypeRow;
}

const AddRow = ({ row, open, onCancel, onSave }: Props) => {
  const { addUpdatedRow, handleSubmit, control, handleCancel, useMetric } =
    useAddRow({
      row,
      onSave,
      onCancel,
    });

  return (
    <StyledDialog
      open={open}
      id="add_row_dialog"
      aria-labelledby="add-row-dialog"
      aria-describedby="add-row-dialog"
      PaperProps={{
        elevation: 0,
      }}
    >
      <StyledDialogTitle>Add Transaction or Metric</StyledDialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(addUpdatedRow)}
          noValidate
        >
          <StyledStack spacing={2}>
            <Controller
              name="useMetric"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl>
                  <StyledFormLabel id="row_type_label">
                    <strong>What type of Transaction is this?</strong>
                  </StyledFormLabel>
                  <RadioGroup
                    row
                    onChange={(event) => {
                      onChange(event.target.value === 'true');
                    }}
                    value={value === true ? 'true' : 'false'}
                  >
                    <FormControlLabel
                      value="true"
                      id="metric_option"
                      control={<Radio />}
                      label="Metric"
                    />
                    <FormControlLabel
                      value="false"
                      id="transaction_option"
                      control={<Radio />}
                      label="Transaction"
                    />
                  </RadioGroup>
                  {!!error && (
                    <FormHelperText error={!!error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              rules={{
                validate: (value) => {
                  if (value === null || value === undefined) {
                    return 'Type is required';
                  }
                  return true;
                },
              }}
              control={control}
            />
            <Controller
              name="type"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={useMetric ? 'Metric Name' : 'Name'}
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={value ?? ''}
                    onChange={onChange}
                    id="metric_name"
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                );
              }}
              rules={{
                required: `${useMetric ? 'Metric ' : ''}Name is required`,
              }}
              control={control}
            />
            {useMetric && (
              <Controller
                name="metricSign"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label="Format"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Format (0.00x)"
                      value={value}
                      onChange={onChange}
                      id="format"
                      error={!!error}
                      helperText={error?.message ?? ''}
                    />
                  );
                }}
                rules={{
                  validate: (value) => {
                    if (!useMetric) {
                      return true;
                    } else if (value === null || value === undefined) {
                      return 'Format is required';
                    } else if (value === '00/00/0000') {
                      return true;
                    } else if (
                      value.substring(0, value.length - 1).split('.').length < 2
                    ) {
                      return 'Please enter a valid format';
                    }
                  },
                }}
                control={control}
              />
            )}
            <Button
              id="ok_button"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleSubmit(addUpdatedRow);
              }}
              fullWidth
            >
              Ok
            </Button>
            <Button
              id="cancel_button"
              variant="outlined"
              color="error"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </StyledStack>
        </form>
      </DialogContent>
    </StyledDialog>
  );
};

export default AddRow;
