import { ReactElement } from "react";

import { StyledContentBox } from "./ContentColumnBox.styles";

type Props = { children: any };

const ContentColumnBox = (props: Props): ReactElement => {
  const { children } = props;

  return (
    <StyledContentBox>
      {children}
    </StyledContentBox>
  );
};

export default ContentColumnBox;
