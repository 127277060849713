import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Controller, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import DoubleLineAIcon from "../../../../assets/images/icons/icon_double_line_a.svg";
import DoubleUnderLineIcon from "../../../../assets/images/icons/icon_double_underline.svg";
import SingleLineAIcon from "../../../../assets/images/icons/icon_single_line_a.svg";
import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import { TemplateTitleBox } from '../../Clients.styles';
import { ReportColumData } from '../../constants';
import { ActionOption, CommonType, GeneralReportForm } from "../useReportConfig.hooks";
import { TextStyles } from "./../../constants";
import RcDataList from "./components/RcDataList";
import {
  AddRowContainer,
  FontStyleBox,
  FontStyleContainer,
  IncDecBox,
  MainContainer,
  ReportConfigurationDataContainer,
  ReportConfigurationDataLabel,
  SectionContainer,
  SpiltButtonContainer,
  TextFieldsContainer
} from "./ReportConfiguration.style";

type columnConfig = {
  index: number,
  label: string,
  reportColumnType: string,
}

type Props = {
  reportForm: UseFormReturn<GeneralReportForm, any>;
  control: UseFieldArrayReturn<GeneralReportForm, "reportElements">;
  addReportElement: (item: string) => void;
  removeReportElement: (index: number) => void;
}

const ReportConfiguration = ({
  reportForm,
  control,
  addReportElement,
  removeReportElement,
}:Props):React.ReactElement => {
  const [ selectedIndex,setSelectedIndex ]=useState<number>(-1);

  const reportElement = control.fields;

  const handleAddActions = (item: string) => {
    if(item.length > 0) {
      addReportElement(item);
    }
  };

  const handleRemoveActions = (index: number) => {
    removeReportElement(index);
    setSelectedIndex(-1);
  };

  const handleChange = (value: string, checked: boolean) => {
    if(checked) {
      reportForm.setValue(`reportColumns`, [...reportForm.getValues().reportColumns, ReportColumData.filter(res => res.reportColumnType === value)[0]], { shouldDirty: true });
    } else {
      const updatedReportColumns = reportForm.getValues().reportColumns.filter(res => res.reportColumnType !== value);

      reportForm.setValue(`reportColumns`, updatedReportColumns, { shouldDirty: true });
    }
  };

  const checkSelected = (value: string) => {
    if(reportForm.getValues().reportColumns.map((res) => res.reportColumnType === value)?.includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  const handleStyleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {

    if(reportForm?.getValues().reportElements[selectedIndex].type !== 'SPACER') {
      const currentStyles = reportForm?.getValues().reportElements[selectedIndex]?.styles;

      if(currentStyles?.includes(newFormats[0])) {

        const updatedStyle = currentStyles?.filter(res => res !== newFormats[0]);

        reportForm?.setValue(`reportElements.${selectedIndex}.styles`, updatedStyle, { shouldDirty: true });
      } else {
        reportForm?.setValue(`reportElements.${selectedIndex}.styles`, [...currentStyles, newFormats[0]], { shouldDirty: true });
      }
    }
  };

  const handleSpaceFormat = (event: React.MouseEvent<HTMLElement>, value: number) => {
    reportForm?.setValue(`reportElements.${selectedIndex}.numberOfTabs`, value, { shouldDirty: true });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setSelectedIndex(result.destination.index);
    control.move(result.source.index, result.destination.index);
    reportElement.forEach((res, index) => {
      reportForm.setValue(`reportElements.${index}.index`, (index + 1), { shouldDirty: true });
    });
  };

  const reportConfig = reportForm.watch("reportElements");

  return (
    <SectionContainer>
      <Box>
        <TemplateTitleBox variant="h4">
          Report Configuration
        </TemplateTitleBox>
      </Box>
      <MainContainer>
        <AddRowContainer>
          <SpiltButtonContainer>
            <SplitButton
              id={"btn_add_row_options"}
              options={ActionOption}
              hidden={false}
              handleOptionClick={handleAddActions}
              ariaLabelMessage="Select action option"
            />
          </SpiltButtonContainer>
          <FontStyleContainer key={`${selectedIndex}_style`}>
            <Typography variant="label2">
              FONT STYLE:
            </Typography>
            <FontStyleBox>
              <ToggleButtonGroup
                  onChange={handleStyleFormat}
                  aria-label="text alignment"
              >
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.bold)}
                    value={TextStyles.bold}
                    aria-label="bold">
                  <FormatBoldIcon/>
                </ToggleButton>
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.italic)}
                    value={TextStyles.italic}
                    aria-label="italic">
                  <FormatItalicIcon />
                </ToggleButton>
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.underLined)}
                    value={TextStyles.underLined}
                    aria-label="underlined">
                  <FormatUnderlinedIcon/>
                </ToggleButton>
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.doubleUnderLine)}
                    value={TextStyles.doubleUnderLine}
                    aria-label="doubleUnderline">
                  <img src={DoubleUnderLineIcon} alt="doubleUnderline"/>
                </ToggleButton>
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.singleLineIcon)}
                    value={TextStyles.singleLineIcon}
                    aria-label="singllineaicon">
                  <img src={SingleLineAIcon} alt="SingleLineAIcon"/>
                </ToggleButton>
                <ToggleButton
                    size="small"
                    selected={reportConfig[selectedIndex]?.styles?.includes(TextStyles.doubleLineIcon)}
                    value={TextStyles.doubleLineIcon}
                    aria-label="doubleUnderline">
                  <img src={DoubleLineAIcon} alt="DoubleLineAIcon"/>
                </ToggleButton>
              </ToggleButtonGroup>
            </FontStyleBox>
          </FontStyleContainer>
          <IncDecBox key={`${selectedIndex}_alignment`}>
            <ToggleButtonGroup
              exclusive
              onChange={handleSpaceFormat}
              aria-label="text alignment"
            >
              <ToggleButton
                  size="small"
                  selected={reportConfig[selectedIndex]?.numberOfTabs === 1}
                  value={1}
                  aria-label="Increase">
                <FormatIndentIncreaseIcon/>
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={reportConfig[selectedIndex]?.numberOfTabs === 0}
                  value={0}
                  aria-label="Decrease">
                <FormatIndentDecreaseIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </IncDecBox>
        </AddRowContainer>

        <ReportConfigurationDataContainer>
          <ReportConfigurationDataLabel>
            <Typography variant="footerNote">
              Label
            </Typography>
            <Typography variant="footerNote">
              Transaction Type
            </Typography>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <TextFieldsContainer>
                  {value?.map((res, index) => (
                    <TextField 
                      id={res.reportColumnType} 
                      size="small" 
                      variant="outlined"
                      key={`columnList_${index}`} 
                      value={res.label}
                      onChange={(e) => {  
                        const data = reportForm.getValues('reportColumns');
                        
                        data[index].label = e.target.value;
                        
                        reportForm.setValue('reportColumns', data);
                      }}
                    />
                  ))}
                </TextFieldsContainer>
              )}
              control={reportForm?.control}
            />
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => {
                return (
                  <FormControl size="small" fullWidth>
                    <InputLabel id="add_column_select_label">Add Column</InputLabel>
                    <Select
                      multiple
                      value={value}
                      renderValue={() => `${value?.length || 0} Selected`}
                      labelId="add_column_report"
                      id="add_column_report"
                      label="Add Column"
                    >
                      {
                        ReportColumData?.map((data: columnConfig, index: number)=>{
                          return(
                            <MenuItem
                              key={`ReportColumData${index}`}
                              value={data.reportColumnType}
                            >
                              <Checkbox
                                checked={checkSelected(data.reportColumnType)}
                                onChange={(e) => handleChange(data.reportColumnType, e.target.checked as boolean)}
                              />
                              {data.label}
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                  </FormControl>
                );
              }}
              control={reportForm?.control}
            />
          </ReportConfigurationDataLabel>
          <Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    <List>
                      {reportElement?.map((item: CommonType, index: number) => (
                        <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                          {(provided, snapshot) => (
                            <ListItemButton disableRipple key={`list_${index}`}
                              ref={provided.innerRef} selected={selectedIndex === index}
                              onClick={() => setSelectedIndex(index)}
                              {...provided.draggableProps} {...provided.dragHandleProps}
                            >
                              <ListItemText>
                                <RcDataList
                                  reportForm={reportForm}
                                  selectIndex={selectedIndex}
                                  type={item.type}
                                  index={index}
                                  handleRemove={handleRemoveActions}
                                />
                              </ListItemText>
                            </ListItemButton>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </ReportConfigurationDataContainer>
      </MainContainer>
    </SectionContainer>
  );
};

export default React.memo(ReportConfiguration);
