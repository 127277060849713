import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { addFileTags } from '../../../../services/documents.service';
import { fileExtension } from '../../../../utils/helpers/misc.helper';
import {
  DocFile,
  Error,
  FolderContentRequestPayload,
} from '../../../../utils/types/documents.type';
import { DocumentsState } from '../../types';
import {
  DEFAULT_FORM_VALUES,
  ERROR_MESSAGE,
  Tags,
  TagType,
} from './AddTags.constants';
import { ARCHIVE_POLICY_OPTIONS, ArchiveOption } from './AddTags.constants';

const useAddTags = (state: DocumentsState | null, props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddtlProperties, setShowAddtlProperties] =
    useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const minDate = new Date('01/01/1970');

  const { handleSubmit, control, getValues, setValue, trigger, reset, watch } =
    useForm<Tags>({
      defaultValues: {
        ...DEFAULT_FORM_VALUES,
      },
    });

  const addFolderArchiveSettingToLabel = (
    option: ArchiveOption,
    archiveIn: number | undefined | null
  ): ArchiveOption => {
    if (option.id === 'RESPECT_FOLDER') {
      return {
        ...option,
        label: `${option.label} (${
          archiveIn !== null
            ? archiveIn
              ? `${archiveIn} Months After Quarter`
              : 'At End of Quarter'
            : 'Never'
        })`,
      };
    }
    return option;
  };

  const archiveOptions: ArchiveOption[] = ARCHIVE_POLICY_OPTIONS.map((option) =>
    addFolderArchiveSettingToLabel(option, state?.currentFolder?.archiveIn)
  );

  const getDateString = (archiveDate: Date) => {
    const year = archiveDate.getFullYear();
    const month = String(archiveDate.getMonth() + 1).padStart(2, '0');
    const day = String(archiveDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const watchArchivePolicy = watch('archivePolicy');

  useEffect(() => {
    if (watchArchivePolicy !== 'RESPECT_FILE') {
      setValue('archiveOn', new Date());
      trigger('archiveOn');
    }
  }, [watchArchivePolicy]);

  const resetForm = () => {
    setLoading(false);
    reset();
    setShowAddtlProperties(false);
    setShowConfirmationDialog(false);
  };

  const onReview = (data: Tags, files: DocFile[]): void => {
    setLoading(true);
    const errorProneFiles: Array<Error> = [];
    const { investor, funds, quarter, permissions, archivePolicy, archiveOn } =
      data;
    const taggedForAllInvestors = investor?.includes('all');
    const tagFields = {
      investorIds: taggedForAllInvestors
        ? state?.availableOptions.investors.map((investor) => investor.id)
        : investor
        ? investor
        : [],
      fundIds: funds,
      quarterId: quarter,
      taggedForAllInvestors: taggedForAllInvestors,
      permissions: permissions,

      archivePolicy: archivePolicy,
      ...(archivePolicy === 'RESPECT_FILE'
        ? { archiveOn: getDateString(archiveOn!!) }
        : {}),
    };

    const fileParams = {
      fileIds: files.map((file) => file.id),
      ...tagFields,
    };

    if (state?.currentFolder?.id) {
      addFileTags({
        folderId: state.currentFolder.id,
        ...fileParams,
      })
        .then((res: any) => {
          setLoading(false);
          if (!isEmpty(res.failedFiles)) {
            // handle error
            for (const i in res.failedFiles) {
              errorProneFiles.push({
                id: i,
                label: i,
                errorMsg: res.failedFiles[i],
                iconType: fileExtension(i),
              });
            }
            props.setErrors(errorProneFiles);
            resetForm();
            closeAddTags();
            return;
          }
          resetForm();
          closeAddTags();
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            // handle error
          }
        });
    } else {
      setLoading(false);
    }
  };

  const validateDate = (value: Date | undefined) => {
    if (!value) {
      return ERROR_MESSAGE.INVALID;
    }
    const selectedDate = new Date(value);

    if (
      !(value instanceof Date && !isNaN(value.getTime())) ||
      selectedDate < new Date('01/01/1900')
    ) {
      return ERROR_MESSAGE.INVALID;
    } else if (selectedDate < minDate) {
      return ERROR_MESSAGE.PAST_MIN_DATE;
    }
    return true;
  };

  const handleShowAddtlProperties = () => {
    setShowAddtlProperties(!showAddtlProperties);
  };

  const handleCloseAddTags = () => {
    const currentValues = getValues();
    const hasUserInput =
      JSON.stringify(currentValues) !== JSON.stringify(DEFAULT_FORM_VALUES);

    if (hasUserInput) {
      setShowConfirmationDialog(true);
    } else {
      closeAddTags();
    }
  };

  const closeAddTags = () => {
    resetForm();
    props.resetUploadFiles();
    if (state?.currentFolder?.id) {
      const filters = { ...(state?.selectedFilters || {}) };

      if (
        state.selectedFilters['funds'].length ===
        state.filtersData['funds'].length
      ) {
        filters.funds = [];
      }
      if (
        state.selectedFilters['investors'].length ===
        state.filtersData['investors'].length
      ) {
        filters.investors = [];
      }
      if (
        state.selectedFilters['quarters'].length ===
        state.filtersData['quarters'].length
      ) {
        filters.quarters = [];
      }

      const payload: FolderContentRequestPayload = {
        ...filters,
        ...state.pagination,
        searchText: state.selectedFilters.searchText,
        sort: state.sort,
      };

      props.reload();
    }
  };

  const getInvestors = (): Array<any> =>
    state?.availableOptions.investors.map((investor) => ({
      id: investor.id,
      name: investor.name,
    })) ?? [];

  const getFunds = (): Array<any> => state?.availableOptions.funds ?? [];

  const getQuarters = (): Array<any> =>
    state?.availableOptions.quarters.map((quarter) => ({
      label: quarter.name,
      value: quarter.id,
    })) ?? [];

  return {
    getFunds,
    getInvestors,
    getQuarters,
    archiveOptions,
    loading,
    control,
    showAddtlProperties,
    handleShowAddtlProperties,
    watchArchivePolicy,
    validateDate,
    onReview,
    handleSubmit,
    handleCloseAddTags,
    showConfirmationDialog,
    setShowConfirmationDialog,
    closeAddTags,
  };
};

export default useAddTags;
