import { Button, Link, Typography } from "@mui/material";
import { GridAlignment, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { StringCell } from "../../components/DataGrid/DataGrid.styles";
import StatusLabel from "../../components/StatusLabel/StatusLabel";
import { AppContext } from "../../core/context/appContextProvider";
import useRole from "../../core/routing/useRole";
import { updateCapitalCallsStatus } from "../../services/capitalCalls.service";
import { useCapitalCallsEffect } from "../../services/hooks/useCapitalCallsEffect/useCapitalCallsEffect.hooks";
import { M_DASH_UNICODE } from "../../utils/constants/constants";
import { GENERIC_ERROR_MESSAGE } from "../../utils/constants/text.constants";
import { capitalize } from "../../utils/helpers/capitalize";
import { DateTimeFormat } from "../../utils/helpers/format.helper";
import {
  CapitalCalls,
  CapitalCallsFilter,
  Statuses,
} from "../../utils/types/capitalCalls.type";
import { CustomType, DataGridColDef } from "../../utils/types/listItems";
import { ADMIN_ROLES, ScopeRole } from "../../utils/types/user.type";
import { TimeText } from "./CapitalCalls.styles";
const StatusType = {
  published: "green",
  reviewing: "yellow",
  inactive: "red",
  editing: "aqua",
};

const STATUS_FILTER = [
  {
    id: "published",
    name: "Published",
  },
  {
    id: "reviewing",
    name: "Reviewing",
  },
  {
    id: "inactive",
    name: "Inactive",
  },
  {
    id: "editing",
    name: "Editing",
  },
];

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "fundName",
    headerName: "Fund Name",
    hide: false,
    index: 2,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "createdOn",
    headerName: "Date Created",
    hide: false,
    index: 3,
    renderCell: (params) => {
      const date = params?.row?.createdOn ?? "";

      if (!date) {
        return <Typography variant="body2">{M_DASH_UNICODE}</Typography>;
      }
      const formattedData = DateTimeFormat.shortDate(new Date(date));

      return <Typography variant="body2">{formattedData}</Typography>;
    },
    align: "left" as GridAlignment,
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    hide: false,
    index: 4,
    width: 150,
    filterable: true,
    renderCell: (params: GridRenderCellParams) => {
      const status = params?.row?.status?.toLowerCase();
      const color = StatusType[status as keyof typeof StatusType];

      return status ? (
        <StatusLabel
          color={color}
          isUpperCase={false}
          label={capitalize(status ?? M_DASH_UNICODE)}
        />
      ) : (
        M_DASH_UNICODE
      );
    },
    type: "string",
    align: "left" as GridAlignment,
    inlineFilter: true,
    inlineFilterName: CapitalCallsFilter.Statuses,
    inlineFilterIDField: "id",
    inlineFilterLabelField: "name",
    inlineFilterOptions: STATUS_FILTER,
    inlineFilterSelected: STATUS_FILTER?.map((status) => {
      if (status.name !== "Inactive") {
        return status.id;
      }
      return "";
    }),
  },
];

const ACTION_HEADER = {
  field: "action",
  headerName: " ",
  hide: false,
  hideable: false,
  index: 5,
  type: "action",
  customType: CustomType.Action,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  width: 100,
};

const NAMEHEADER = {
  field: "name",
  headerName: "Name",
  hide: false,
  index: 1,
  type: "string",
  align: "left" as GridAlignment,
  width: 300,
};

export const useCapitalCalls = () => {
  const [list, setList] = useState<CapitalCalls[] | undefined>();
  const [statusUpdateCapitalCalls, setStatusUpdateCapitalCalls] = useState<
    CapitalCalls | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCapitalCallSetUp, setOpenCapitalCallSetUp] = useState<
    string | undefined
  >(undefined);
  const [headerList, setHeaderList] =
    useState<Array<DataGridColDef>>(defaultHeaderList);
  const [activeHeaderFields, setActiveHeaderFields] = useState(6);

  const { informationAlert } = useContext(AppContext);

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const {
    capitalCalls: capitalCallsList,
    fetchCapitalCalls,
    loading: loadingCapitalCalls,
    updateCapitalCalls,
  } = useCapitalCallsEffect();
  const toggleStatus = useCallback((capitalCalls: CapitalCalls) => {
    setStatusUpdateCapitalCalls(capitalCalls);
  }, []);
  const updateStatus = async () => {
    setStatusUpdateCapitalCalls(undefined);
    if (!statusUpdateCapitalCalls) {
      return;
    }
    const { id, status: currentStatus } = statusUpdateCapitalCalls;

    try {
      setLoading(true);

      let updatedStatus: string = "";

      switch (currentStatus?.toUpperCase()) {
        case "PUBLISHED":
          updatedStatus = "EDITING";
          break;
        case "REVIEWING":
          updatedStatus = "INACTIVE";
          break;
        case "EDITING":
          updatedStatus = "INACTIVE";
          break;
        default:
          updatedStatus = "EDITING";
          break;
      }

      await updateCapitalCallsStatus(id!, updatedStatus);

      if (list) {
        const updatedCapitalCalls = list.map((capitalCall) => {
          if (capitalCall.id === id) {
            return {
              ...capitalCall,
              status: updatedStatus,
            };
          } else return capitalCall;
        });

        updateCapitalCalls(updatedCapitalCalls);
        setLoading(false);
      }
    } catch (error) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  };

  useEffect(() => {
    const newHeaderList = [
      {
        ...NAMEHEADER,
        renderCell: (params: any) => {
          return (
            <Link
              id="capital_call_setup_link"
              underline="none"
              component="button"
              onClick={(event) => {
                setOpenCapitalCallSetUp(params.row.id);
              }}
            >
              {params.value}
            </Link>
          );
        },
      },
      ...defaultHeaderList,
      {
        ...ACTION_HEADER,
        renderCell: (params: GridRenderCellParams) => {
          const status = params?.row?.status?.toLowerCase();
          const displayUpdateStatus = status === "inactive" ? false : true;

          return (
            displayUpdateStatus &&
            isSuperAdminOrClientAdmin && (
              <Button
                id={`${params?.row?.id}-capital-call`}
                variant="outlined"
                size="small"
                onClick={() => toggleStatus(params.row)}
              >
                <strong>{getStatus(status)}</strong>
              </Button>
            )
          );
        },
      },
    ];

    setHeaderList(newHeaderList);
  }, [toggleStatus]);

  const getSelectedFilters = useCallback(
    (currentHeaderList: DataGridColDef[]) => {
      return currentHeaderList?.reduce(
        (acc: Record<CapitalCallsFilter, string[] | undefined>, header) => {
          if (
            header.inlineFilter &&
            header.inlineFilterSelected &&
            header.inlineFilterSelected?.length > 0
          ) {
            return {
              ...acc,
              [header.inlineFilterName ?? ""]: header.inlineFilterSelected?.map(
                (item) => item.toLowerCase()
              ),
            };
          }
          return acc;
        },
        {
          [CapitalCallsFilter.Statuses]: undefined,
        }
      );
    },
    []
  );

  useEffect(() => {
    const { [CapitalCallsFilter.Statuses]: selectedStatuses } =
      getSelectedFilters(headerList);

    const currentList =
      capitalCallsList?.filter(({ status }) =>
        selectedStatuses?.includes(status?.toLowerCase() || "")
      ) ?? [];

    setList(currentList);
  }, [capitalCallsList, headerList]);

  const handleUpdateHeader = async (field: string) => {
    if (!headerList || headerList?.length === 0) {
      return;
    }

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== "action"
    );

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide:
          header.field === field && !(!header.hide && activeFields.length <= 1)
            ? !header.hide
            : header.hide,
      };
    });

    if (updatedHeaders) {
      await setHeaderList(updatedHeaders);
      const activeHeaders = headerList.filter((header) => !header.hide);

      await setActiveHeaderFields(activeHeaders.length - 1);
    }
  };

  const handleFilter = (filterName: CapitalCallsFilter, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );
  };

  const discardStatusUpdate = () => {
    setStatusUpdateCapitalCalls(undefined);
  };

  const discardOpenCapitalCallSetUp = () => {
    setOpenCapitalCallSetUp(undefined);
  };
  const getStatus = useCallback((status: string) => {
    switch (status.toLowerCase()) {
      case Statuses.PUBLISHED:
        return "Unpublish";
      case Statuses.INACTIVE:
        return "Activate";
      case Statuses.EDITING:
        return "Inactivate";
      case Statuses.REVIEWING:
        return "Inactivate";
      default:
        return "";
    }
    return "";
  }, []);

  const statusAction = useMemo(() => {
    return getStatus(statusUpdateCapitalCalls?.status ?? "");
  }, [statusUpdateCapitalCalls, getStatus]);

  return {
    headerList,
    loading: loadingCapitalCalls,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    handleFilter,
    fetchCapitalCalls,
    statusUpdateCapitalCalls,
    discardStatusUpdate,
    updateStatus,
    statusAction,
    openCapitalCallSetUp,
    discardOpenCapitalCallSetUp,
    isSuperAdminOrClientAdmin,
  };
};
