import { Box, FormControl, styled, TextField } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const DateRangeContainer = styled(Box)(({
  theme
}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const DateRangeContainerFix = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '400px 1fr',
}));

export const DateRangeTextField = styled(TextField)(() => ({
  margin: 0
}));

export const FirstRow = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gridTemplateColumns: "400px 200px",
  gridGap: theme.spacing(2),
  alignItems: "center",
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(2)
}));

//This is added to match multi-select spacing in reports for DatePicker
export const StyledFormControl = styled(FormControl)`
  margin: 10px 10px 10px 0;
`;
