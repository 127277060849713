import { FormControl, MenuItem, Select } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { cloneDeep } from "lodash";
import { useState } from "react";

import { DandDitem } from "../../../../../components/DragAndDropList/DragAndDropList";
import { MultiSelectItem } from "../../../../../components/MultiSelect/MultiSelect";
import { getFund } from "../../../../../services/fund.service";
import { getTranactionTypeColumns, getWorkbookById, getWorkbooksByFund } from "../../../../../services/workbook.service";
import { useEffectAsync } from "../../../../../utils/hooks/useEffectAsync.hook";
import { ClientTransTypeRow } from "../../../../../utils/types/clientTransMapping.type";
import { DataGridColDef, ListItem } from "../../../../../utils/types/listItems";
import { AllocationColumn, AllocationColumnTypes, StringConstants, TransactionColumn, WbConfigDialogType, Workbook, WorkbookFrequencyType, WorkbookVersion } from "../../workbook.type";
import { WbConfigDialogInfo } from "../sheets/allocations/AllocationsSheet.hooks";

type AllocationMapItem = {
  id: string,
  transactionTypeName: string,
  allocationId1?: string,
  allocation1QuarterRef?: number,
  allocationId2?: string,
  allocation2QuarterRef?: number,
  allocationId3?: string,
  allocation3QuarterRef?: number,
  allocationId4?: string
  allocation4QuarterRef?: number,
};

type CustomField = {
  label: string,
  error: boolean
};

type DAndDItemsData = {
  isUserCol: boolean,
  useArkTransactionValues: boolean,
  metricSign: number
}

//export const dynamicDateStr = '<dynamic date>';
export const defaultError4Message = 'This field is required';
export const workbookFrequency: ListItem[] = [{ id: "BY_YEAR", label: "Year" }, { id: "BY_QUARTER", label: "Quarter" }];
export const startQuarters: ListItem[] = [{ id: "1", label: "1" }, { id: "2", label: "2" }, { id: "3", label: "3" }, { id: "4", label: "4" }];

export const useClientTransactionMappingEffect = (
    existingWorkbook: Workbook|undefined, 
    clientId: string, 
    fundId: string, 
    importWorkbooks: Workbook[]|undefined, 
    wbConfigDialogType: WbConfigDialogType,
    onOk: (wbConfigDialogInfo: WbConfigDialogInfo) => void) => {

  const isNewWorkbook = existingWorkbook===undefined;

  const [fundName, setFundName] = useState<string>('');
  const [fundCurrency, setFundCurrency] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [clientTransTypeItems, setClientTransTypeItems] = useState<ClientTransTypeRow[]>([]);
  const [dandDItems, setClientTransMappingDandDItems] = useState<DandDitem[]>([]);
  const [origDandDItems, setOrigDandDItems] = useState<DandDitem[]>([]);
  const [commitmentTransTypeItems, setCommitmentTransTypeItems] = useState<MultiSelectItem[]>([]);
  const [commitmentTransTypeError, setCommitmentTransTypeError] = useState<boolean>(false);
  const [allocationMappings, setAllocationMappings] = useState<AllocationMapItem[]>([]);
  const [origAllocationMappings, setOrigAllocationMappings] = useState<AllocationMapItem[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [reportNameError, setReportNameError] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<WorkbookFrequencyType>('');
  const [frequencyError, setFrequencyError] = useState<boolean>(false);
  const [startQuarter, setStartQuarter] = useState<string>('1');
  const [lastQuarter, setLastQuarter] = useState(1);
  const [startYear, setStartYear] = useState<string>('');
  const [decimalRounding, setDecimalRounding] = useState(-1);
  const [includeYTDColumn, setIncludeYTDColumn] = useState<boolean>(true);
  const [includeITDColumn, setIncludeITDColumn] = useState<boolean>(true);
  const [startYears, setStartYears] = useState<ListItem[]>([]);
  const [importSelections, setImportSelections] = useState<ListItem[]>([]);
  const [useCommitmentPercent, setUseCommitmentPercent] = useState<boolean>(true);
  const [commitmentPercent, setCommitmentPercent] = useState<string>(`Commitment % at ${StringConstants.dynamicDateStr}`);
  const [useLpPercent, setUseLpPercent] = useState<boolean>(true);
  const [lpPercent, setLpPercent] = useState<string>(`LP % at ${StringConstants.dynamicDateStr}`);
  const [useGpPercent, setUseGpPercent] = useState<boolean>(true);
  const [gpPercent, setGpPercent] = useState<string>(`GP % at ${StringConstants.dynamicDateStr}`);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  
  useEffectAsync(async (isCanceled) => {
    setIsLoading(true);

    const transTypes = await getTranactionTypeColumns(clientId);

    if(isCanceled() || !transTypes) return;

    const year = new Date().getFullYear();
    const yearStr = year.toString();
    const prevYearStr = (year-1).toString();
    const nextYearStr = (year+1).toString();

    setStartYear(yearStr);
    setStartYears( [{ id: nextYearStr, label: nextYearStr }, { id: yearStr, label: yearStr }, { id: prevYearStr, label: prevYearStr }] );

    if(isNewWorkbook) {
      const fund = await getFund(fundId);

      if (isCanceled()) return;

      loadNewWorkbookInfo(fund.fundDetail.name, fund.fundDetail.currency??'USD', transTypes);
    } else {
      loadExistingWorkbookInfo(transTypes);
    }

    setIsLoading(false);
    setInitialLoadComplete(true);
  }, []);

  const loadNewWorkbookInfo = (fundName: string, currency: string, transTypes: ClientTransTypeRow[]) => {
    const wb = existingWorkbook as Workbook;

    setFundName(fundName);
    setFundCurrency(currency);

    const commitmentTransTypeItems = transTypes.map<MultiSelectItem>((row) => {
      return {
          id: row.id as string,
          name: row.type,
          selected: false
      };
    });

    const dAndDmappings = transTypes.filter(row => row.metricSign !== '0' ).map<DandDitem>(row => {
        const DAndDItemsData = { 
          isUserCol: false, 
          useArkTransactionValues: false,
          metricSign: parseInt(row.metricSign!)
        } as DAndDItemsData;

        return {
            id: row.id as string,
            name: row.type,
            data: DAndDItemsData
        };
    });

    const allocMappings = transTypes.filter(row => row.id && row.metricSign !== '0').map<AllocationMapItem>(row => {
      return {
        id: row.id as string,
        transactionTypeName: row.type,
        allocationId1: undefined,
        allocation1QuarterRef: undefined
      };
    });

    setClientTransTypeItems(transTypes);
    setClientTransMappingDandDItems(dAndDmappings);
    setOrigDandDItems(dAndDmappings);
    setCommitmentTransTypeItems(commitmentTransTypeItems);
    setAllocationMappings(allocMappings);
    setOrigAllocationMappings(allocMappings);

    
    const importSelections = !importWorkbooks ? [] : importWorkbooks.map((wb) => {
      return {
        id: wb.id ?? '',
        label: wb.reportName
      };
    });

    setImportSelections(importSelections);
  };
    
  const loadExistingWorkbookInfo = (transTypes: ClientTransTypeRow[]) => {
    const wb = existingWorkbook as Workbook;
    const existingWorkbookLastQuarter = wb.transactionColumns
        .reduce((prev, curr) => prev > curr.quarterNumber ? prev : curr.quarterNumber, 1);
    let lastQuarter = existingWorkbookLastQuarter;

    if(wbConfigDialogType === WbConfigDialogType.Rollforward) {
      lastQuarter++;

      const newTransactionCol = cloneDeep(wb.transactionColumns.filter(c => c.quarterNumber === existingWorkbookLastQuarter));
      
      newTransactionCol.forEach(newCol => {
        newCol.id = undefined;
        newCol.quarterNumber = lastQuarter;
        newCol.allocationCodeQuarterReference = lastQuarter;
        newCol.locked = false;
        newCol.transSubmitError = false;
      });

      wb.transactionColumns = wb.transactionColumns.concat(newTransactionCol);
    }

    setClientTransTypeItems(transTypes);
    setFundName(wb.fund.name);
    setFundCurrency(wb.fund.currency);
    setReportName(wb.reportName);
    setFrequency(wb.frequency);
    setStartYear(wb.startQuarterYear.toString());
    setStartQuarter(wb.startQuarterNumber.toString());
    setIncludeITDColumn(wb.includeItd);
    setIncludeYTDColumn(wb.includeYtd);
    setLastQuarter(lastQuarter);
    
    const commitmentTransTypeItems = transTypes.map(tt => {
      const transType = wb.commitmentTransactionTypeIds.find(type => type === tt.type);

      return {
        id: tt.id,
        name: tt.type,
        selected: transType !== undefined
      } as MultiSelectItem;
    });

    setCommitmentTransTypeItems(commitmentTransTypeItems);

    const commitmentPercent = wb.allocationColumns.find(allocCol => allocCol.code === 'COMMITMENT_PERCENT' && allocCol.quarterNumber === existingWorkbookLastQuarter);
    const lpPercent = wb.allocationColumns.find(allocCol => allocCol.code === 'LP' && allocCol.quarterNumber === existingWorkbookLastQuarter);
    const gpPercent = wb.allocationColumns.find(allocCol => allocCol.code === 'GP' && allocCol.quarterNumber === existingWorkbookLastQuarter);

    if(commitmentPercent) {
      setUseCommitmentPercent(true);
      setCommitmentPercent(commitmentPercent.label);
    } else {
      setUseCommitmentPercent(false);
      setCommitmentPercent('');
    }
    
    if(lpPercent) {
      setUseLpPercent(true);
      setLpPercent(lpPercent.label);
    } else {
      setUseLpPercent(false);
      setLpPercent('');
    }

    if(gpPercent) {
      setUseGpPercent(true);
      setGpPercent(gpPercent.label);
    } else {
      setUseGpPercent(false);
      setGpPercent('');
    }

    const customAllocCols = wb.allocationColumns
      .filter(allocCol => allocCol.transactionType === AllocationColumnTypes.CUSTOM_FIELD && allocCol.quarterNumber === existingWorkbookLastQuarter);
    const customCols = customAllocCols.map(custCol => {
      return {
        label: custCol.label,
        error: false
      };
    });

    setCustomFields(customCols);

    const dAndDItems = wb.transactionColumns.filter(tc => tc.quarterNumber === lastQuarter).map(transType => {
      const dAndDItemsData: DAndDItemsData = {
        isUserCol: transType.isUserColumn,
        useArkTransactionValues: transType.useArkTransactionValues,
        metricSign: transType.metricSign
      };

      return {
        id: transType.transTypeId,
        name: transType.label,
        data: dAndDItemsData,
      } as DandDitem;
    });
    
    const allocMappings = transTypes
        .sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0) )
        .map<AllocationMapItem>(transType => {
      const transactionColumn1 = wb.transactionColumns.find(col => col.label === transType.type && col.quarterNumber === 1);
      const transactionColumn2 = wb.transactionColumns.find(col => col.label === transType.type && col.quarterNumber === 2);
      const transactionColumn3 = wb.transactionColumns.find(col => col.label === transType.type && col.quarterNumber === 3);
      const transactionColumn4 = wb.transactionColumns.find(col => col.label === transType.type && col.quarterNumber === 4);

      return {
        id: transType.id as string,
        transactionTypeName: transType.type,
        allocationId1: transactionColumn1?.allocationCode,
        allocation1QuarterRef: transactionColumn1?.allocationCodeQuarterReference,
        allocationId2: transactionColumn2?.allocationCode,
        allocation2QuarterRef: transactionColumn2?.allocationCodeQuarterReference,
        allocationId3: transactionColumn3?.allocationCode,
        allocation3QuarterRef: transactionColumn3?.allocationCodeQuarterReference,
        allocationId4: transactionColumn4?.allocationCode,
        allocation4QuarterRef: transactionColumn4?.allocationCodeQuarterReference,
      };
    });

    setAllocationMappings(allocMappings);
    setOrigDandDItems(dAndDItems);

    setDecimalRounding(wb.decimalRounding);


    const selectedCommitmentTypes = commitmentTransTypeItems.filter((item) => item.selected);

    commitmentTransTypeChangeAdjustment(commitmentTransTypeItems, selectedCommitmentTypes, transTypes, dAndDItems, dAndDItems, allocMappings);
  };


  const handleSave = async () => {    
    const oldReportName = existingWorkbook?.reportName;

    setIsLoading(true);
    const workbooksResponse = await getWorkbooksByFund(fundId);
    
    setIsLoading(false);

    const nameList = workbooksResponse.map(wb => wb.reportName);
    const existingReportName =  nameList.filter(rn => oldReportName !== rn).find(rn => rn === reportName);

    if(reportName.trim() === ''|| existingReportName) {
      setReportNameError(true);
      return;
    }

    if(frequency.trim() ==='') {
      setFrequencyError(true);
      return;
    }

    const selectedCommitmentTypes = commitmentTransTypeItems.filter((item) => item.selected);
    
    if(selectedCommitmentTypes.length === 0) {
      setCommitmentTransTypeError(true);
      return;
    }

    customFields.forEach((col) => {
      col.error = col.label.trim() === '';
    }); 

    if(customFields.find((col) => col.error)) {
      setCustomFields(cloneDeep(customFields));
      return;
    }


    let transactionCols: TransactionColumn[];

    if(isNewWorkbook) {
      transactionCols = dandDItems.map((item, index) => {
        const allocMap = allocationMappings.find(allocMap => allocMap.id === item.id);

        return {
          transTypeId: item.id,
          index: index,
          label: item.name,
          locked: false,
          transSubmitError: false,
          quarterNumber: parseInt(startQuarter),
          isUserColumn: false,
          // @ts-ignore: expression error
          allocationCode: allocMap ? allocMap['allocationId' + startQuarter] : undefined,
          // @ts-ignore: expression error
          allocationCodeQuarterReference: allocMap ? allocMap[`allocation${startQuarter}QuarterRef`] : undefined,
          metricSign: item.data.metricSign,
          useArkTransactionValues: item.data.useArkTransactionValues,
          colType:1,
          gridColId:'',
          isUserCol: false
        };
      });
    } else {
      transactionCols = cloneDeep(existingWorkbook.transactionColumns);

      transactionCols.forEach(col => {
        const allocMap = allocationMappings.find(allocMap => allocMap.id === col.transTypeId);

        if(allocMap) {
          const q = col.quarterNumber;

          // @ts-ignore: expression error
          col.allocationCode = allocMap['allocationId' + q];
          // @ts-ignore: expression error
          col.allocationCodeQuarterReference = allocMap[`allocation${q}QuarterRef`];
        }
      });
    } 

    const allocationColumns: AllocationColumn[] = getAllocationColumnsFromFormFields(parseInt(startQuarter));
    const commitmentTransactionTypes = commitmentTransTypeItems.filter((item) => item.selected).map((item)=>item.name);

    let updatedWorkbook: Workbook;

    if(isNewWorkbook) {
      const newWorkbook: Workbook = {
        version: WorkbookVersion,
        tenantId: clientId,
        fund: {
          id: fundId,
          name: fundName,
          currency: fundCurrency
        },
        type: "ALLOCATIONS",
        reportName: reportName,
        fileName: "allocation-workbook.xlsx",
        fileType: undefined,
        frequency: frequency,
        startQuarterYear: parseInt(startYear),
        startQuarterNumber: parseInt(startQuarter),
        decimalRounding: decimalRounding,
        includeItd: includeITDColumn,
        includeYtd: includeYTDColumn,
        allocationColumns: allocationColumns,
        transactionColumns: transactionCols,
        investorRows: [], // will get set later in allocationWorkbook
        commitmentTransactionTypeIds: commitmentTransactionTypes,
        commitmentColumns: [],
        itdColumns:[]
      };

      updatedWorkbook = newWorkbook;
    } else {
      updatedWorkbook = cloneDeep(existingWorkbook);

      updatedWorkbook.reportName = reportName;
      updatedWorkbook.transactionColumns = transactionCols;
    }

    setIsLoading(true);
    setTimeout(() => { onOk( { wbConfigDialogType: wbConfigDialogType, workbook: updatedWorkbook } ); }, 1);
  };

  
  const handleUseCommitmentPercentChange = (evt: any) => {
    const wb = existingWorkbook as Workbook;

    if(!isNewWorkbook) {
      const commitmentPercent = wb.allocationColumns.find((allocCol) => allocCol.code === 'COMMITMENT_PERCENT');

      if(commitmentPercent) {
        setUseCommitmentPercent(true);
      } else {
        setUseCommitmentPercent(Boolean(evt.target.checked)); 
      }
    } else {
      setUseCommitmentPercent(Boolean(evt.target.checked)); 
    }
  };

  const handleUseLpPercentChange = (evt: any) => {
    const wb = existingWorkbook as Workbook;

    if(!isNewWorkbook) {
      const lpPercent = wb.allocationColumns.find((allocCol) => allocCol.code === 'LP');

      if(lpPercent) {
        setUseLpPercent(true);
      } else {
        setUseLpPercent(Boolean(evt.target.checked)); 
      }
    } else {
      setUseLpPercent(Boolean(evt.target.checked)); 
    }
  };

  const handleUseGpPercentChange = (evt: any) => {
    const wb = existingWorkbook as Workbook;

    if(!isNewWorkbook) {
      const gpPercent = wb.allocationColumns.find((allocCol) => allocCol.code === 'GP');

      if(gpPercent) {
        setUseGpPercent(true);
      } else {
        setUseGpPercent(Boolean(evt.target.checked)); 
      }
    } else {
      setUseGpPercent(Boolean(evt.target.checked)); 
    }
  };

  const handleOnDandDChange = (items: DandDitem[]) => {
    setClientTransMappingDandDItems(items);
  };

  const handleOnUseArkTransValueChange = (itemId: string) => {
    const updateItem = dandDItems.find(x => x.id === itemId)!;

    updateItem.data.useArkTransactionValues = !updateItem.data.useArkTransactionValues;
    setClientTransMappingDandDItems(cloneDeep(dandDItems));

    const itemsWithUseArk = dandDItems.filter(item => item.data.useArkTransactionValues)?.map(item => item.id);
    const allocMappings = origAllocationMappings.filter(item => !itemsWithUseArk.find(id => id === item.id));

    setAllocationMappings(cloneDeep(allocMappings));
  };

  const handleAddCustomColumn = () => {
    customFields.push({ label: '', error: false });
    setCustomFields(cloneDeep(customFields));
  };

  const handleEditCustomColumn = (imdex: number, evt: any) => {
    const value = evt.target.value as string;

    customFields[imdex] = { label: value, error: value.trim() === '' };
    setCustomFields(cloneDeep(customFields));
  };

  const handleRemoveCustomColumn = (position: number) => {
    customFields.splice(position, 1);
    setCustomFields(cloneDeep(customFields));
  };

  const handleWorkBookImport = async (value: string) => {
    const wb = await getWorkbookById(value);

    const items: DandDitem[] = [];

    wb.transactionColumns.forEach((col, idx) => {
      items.push({
        id: col.index?.toString() as string,
        name: col.label,
        data: { metricSign: col.metricSign }
      });
    });

    setClientTransMappingDandDItems(items);
  };


  function getAllocationColumnsFromFormFields(quarter: number) {
    const allocationColumns: AllocationColumn[] = [];
    
    if (useCommitmentPercent) {
      addAllocationColumn('COMMITMENT_PERCENT', commitmentPercent, AllocationColumnTypes.INVESTMENT_TYPE);
    }

    if (useLpPercent) {
      addAllocationColumn('LP', lpPercent, AllocationColumnTypes.INVESTMENT_TYPE);
    }

    if (useGpPercent) {
      addAllocationColumn('GP', gpPercent, AllocationColumnTypes.INVESTMENT_TYPE);
    }

    customFields.forEach((col, idx) => {
      addAllocationColumn(`CUSTOM_FIELD_${idx}`, col.label, AllocationColumnTypes.CUSTOM_FIELD);
    });

    return allocationColumns;

    function addAllocationColumn(code: string, label: string, colType: AllocationColumnTypes) {
      const col: AllocationColumn = {
        id: undefined,
        gridColId: '', // set later in Allocation Rules manager
        code: code,
        transactionType: colType,
        index: allocationColumns.length + 1,
        label: label,
        quarterNumber: quarter
      };

      allocationColumns.push(col);
    }
  }

  const handleReportNameChange = (evt: any) => {
    const value = evt.target.value as string;

    setReportName(value);
    setReportNameError(value.trim() === '');
  };

  const handleFrequencyChange = (evt: any) => {
    const value = evt.target.value as WorkbookFrequencyType;

    setFrequency(value);
    setFrequencyError(value.trim() === '');

    if(value === 'BY_YEAR') {
      setIncludeYTDColumn( Boolean(false) );
    }
  };

  const handleCommitmentTransTypeChange = (items: MultiSelectItem[]) => {
    const selectedCommitmentTypes = commitmentTransTypeItems.filter((item) => item.selected);

    commitmentTransTypeChangeAdjustment(items, selectedCommitmentTypes, clientTransTypeItems, dandDItems, origDandDItems, allocationMappings);
  };

  const commitmentTransTypeChangeAdjustment = 
    (items: MultiSelectItem[], selectedCommitmentTypes: MultiSelectItem[], clientTransTypeItems: ClientTransTypeRow[], dAndDItems: DandDitem[], origDandDItems: DandDitem[], allocationMappings: AllocationMapItem[]) => {
    
    setCommitmentTransTypeError(selectedCommitmentTypes.length === 0);
    setCommitmentTransTypeItems(items);

    // remove trans types that were selected in the Commitment Transaction Type dropdown
    let filteredTransMappingItems = clientTransTypeItems.filter(mapItem => !selectedCommitmentTypes.find((typeItem) => mapItem.id === typeItem.id));

    const tempTransMappingItems = origDandDItems.filter(item => (filteredTransMappingItems.find((mappingItem) => item.id === mappingItem.id) !== undefined) || item.data.isUserCol);
    

    setClientTransMappingDandDItems(cloneDeep(tempTransMappingItems));

    filteredTransMappingItems = filteredTransMappingItems
      .filter(x => dAndDItems.find(ddItem => ddItem.id === x.id && ddItem.data.useArkTransactionValues ) === undefined );

    const tempAllocMappingItems = filteredTransMappingItems.map(transItem => {
      const allocMappingItem = allocationMappings.find(item => item.id === transItem.id);

      if(allocMappingItem) {
        return allocMappingItem;
      } else {
        return {
          id: transItem.id,
          transactionTypeName: transItem.type,
          allocationId1: undefined,
          allocation1QuarterRef: undefined,
          allocationId2: undefined,
          allocation2QuarterRef: undefined,
          allocationId3: undefined,
          allocation3QuarterRef: undefined,
          allocationId4: undefined,
          allocation4QuarterRef: undefined
        } as AllocationMapItem;
      }
    });

    setAllocationMappings(cloneDeep(tempAllocMappingItems));
  };


  const handleIncludeYTDColumnChange = (evt: any) => {
    if(isNewWorkbook) {
      setIncludeYTDColumn(Boolean(evt.target.checked)); 
    }
  };

  const handleIncludeITDColumnChange = (evt: any) => {
    if(isNewWorkbook) {
      setIncludeITDColumn(Boolean(evt.target.checked)); 
    }
  };


  function getAllocationMappingsHeaderList() {
    const sq = parseInt(startQuarter);
    const eq = lastQuarter;

    const hideAllocCol1 = sq !== 1;
    const hideAllocCol2 = sq > 2 || eq < 2;
    const hideAllocCol3 = sq > 3 || eq < 3;
    const hideAllocCol4 = eq < 4;

    const allocationMappingsHeaderList:DataGridColDef[] = [
      {
        field: "transactionTypeName",
        headerName: "Transaction Column",
        hide: false,
        index: 0,
        type: "string",
        sortable: false,
        align: "left",
        width: 100
      },
      {
        field: "allocationId1",
        headerName: "Allocation Rule".concat(frequency === "BY_QUARTER" ? " Q1" : ""),
        hide: hideAllocCol1,
        index: 1,
        type: "string",
        sortable: false,
        align: "left",
        width: 100,
        renderCell: dataGridSelectJsx
      },
      {
        field: "allocationId2",
        headerName: "Allocation Rule Q2",
        hide: hideAllocCol2,
        index: 2,
        type: "string",
        sortable: false,
        align: "left",
        width: 100,
        renderCell: dataGridSelectJsx
      },
      {
        field: "allocationId3",
        headerName: "Allocation Rule Q3",
        hide: hideAllocCol3,
        index: 3,
        type: "string",
        sortable: false,
        align: "left",
        width: 100,
        renderCell: dataGridSelectJsx
      },
      {
        field: "allocationId4",
        headerName: "Allocation Rule Q4",
        hide: hideAllocCol4,
        index: 4,
        type: "string",
        sortable: false,
        align: "left",
        width: 100,
        renderCell: dataGridSelectJsx
      }
    ];

    return allocationMappingsHeaderList;
  }

  const allocationMappingsHeaderList = getAllocationMappingsHeaderList();


  function dataGridSelectJsx(params: GridRenderCellParams<any, any, any>) {
    type SelectAllocationColumn = AllocationColumn & { selectId: string };

    const allocMapItem = params.row as AllocationMapItem;
    const currQuarter = (params.colDef as any).index;
    const allocField = "allocationId" + currQuarter;

    let isLocked = false;

    if(existingWorkbook) {
      const col = existingWorkbook.transactionColumns.find(c => c.quarterNumber === currQuarter && c.transTypeId === allocMapItem.id);

      isLocked = !!col?.locked;
    }


    const tempAllocColumns = getTempAllocColumns();

    // @ts-ignore: expression error
    const allocValue = tempAllocColumns.find(ac => ac.code === allocMapItem[allocField] && ac.quarterNumber === allocMapItem[`allocation${currQuarter}QuarterRef`])!;
    const value = allocValue ? allocValue.selectId : '*UNMAPPED*';

    return (
      <FormControl sx={{ minWidth: '100%' }}>
        <Select
          id={`select_allocation_${params.row.id}`}
          labelId={`select_allocation_${params.row.id}_label`}
          label="Transaction Name"
          disabled={(currQuarter !== lastQuarter) || isLocked}
          value={value}
          onChange={e => { handleAllocationMappingChange(params.row.id, e.target.value as string, currQuarter); }}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: '10px',
                height: '200px',
                width: '180px',
                '& .MuiBox-root': { width: '100%' },
                '& MuiFormControl-root': { minWidth: '100%' }
              }
            }
          }}
        >
          <MenuItem 
            sx={{ minWidth: '100%' }}
            value='*UNMAPPED*'
          >
              Unmapped
          </MenuItem>
          {
            tempAllocColumns.map(type => {
              return (
                <MenuItem 
                  sx={{ minWidth: '100%' }} 
                  key={type.selectId} 
                  value={type.selectId}
                >
                  {type.label}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    );

    function getTempAllocColumns(): SelectAllocationColumn[] {    
      let allAllocColsForCurrQuarter: AllocationColumn[];

      if(wbConfigDialogType === WbConfigDialogType.NewWorkbook) {
        allAllocColsForCurrQuarter = getAllocationColumnsFromFormFields(currQuarter);
      } else if(wbConfigDialogType === WbConfigDialogType.Standard) {
        allAllocColsForCurrQuarter = existingWorkbook!.allocationColumns.filter(ac => ac.quarterNumber <= currQuarter);
      } else if(wbConfigDialogType === WbConfigDialogType.Rollforward) {
        const lastQuarterAllocRules = cloneDeep(existingWorkbook!.allocationColumns.filter(ac => ac.quarterNumber === (lastQuarter-1)));

        lastQuarterAllocRules.forEach(ar => ar.quarterNumber = lastQuarter);
        allAllocColsForCurrQuarter = existingWorkbook!.allocationColumns.filter(ac => ac.quarterNumber <= currQuarter);
        allAllocColsForCurrQuarter = allAllocColsForCurrQuarter.concat(lastQuarterAllocRules);
      } else {
        throw 'error in getTempAllocColumns().  Invalid wbConfigDialogType type';
      }

      allAllocColsForCurrQuarter.sort((a,b) => b.quarterNumber - a.quarterNumber);

      const selectItems = allAllocColsForCurrQuarter.map<SelectAllocationColumn>((item, index) => {
        const qtrStr = frequency === 'BY_QUARTER' ? `Q${item.quarterNumber} - ` : '';
        const label =  qtrStr + `${item.label.replace(StringConstants.dynamicDateStr, '')}`;

        const newSelectItem: SelectAllocationColumn = {
          ...item,
          label,
          selectId: index.toString()
        };
        
        return newSelectItem;
      });      

      return selectItems;
    }

    function handleAllocationMappingChange(allocationMappingID: string, selectId: string, quarter: number) {
      const mapping = allocationMappings.find(mapItem => mapItem.id === allocationMappingID);
      const tempAllocCol =  tempAllocColumns.find(a => a.selectId === selectId)!;

      if(mapping) {
         // @ts-ignore: expression error
        mapping['allocationId' + quarter] = tempAllocCol?.code;
         // @ts-ignore: expression error
        mapping[`allocation${quarter}QuarterRef`] = tempAllocCol?.quarterNumber;
      }
  
      setAllocationMappings(cloneDeep(allocationMappings));
    }
  }

  function handleStartQuarterChange(newStartQuarter: string) {
    setStartQuarter(newStartQuarter);

    if(frequency === 'BY_QUARTER') {
      setLastQuarter(parseInt(newStartQuarter));
    } else {
      setLastQuarter(4);
    }
  }

  return { 
    isNewWorkbook,
    isLoading,
    initialLoadComplete,
    startYears,
    reportName, reportNameError,
    frequency, frequencyError,
    startYear, setStartYear,
    startQuarter, handleStartQuarterChange,
    dandDItems,
    commitmentTransTypeItems, commitmentTransTypeError,
    allocationMappingsHeaderList,
    allocationMappings,
    decimalRounding, setDecimalRounding,
    includeYTDColumn, includeITDColumn,
    importSelections,
    useCommitmentPercent, commitmentPercent, setCommitmentPercent, handleUseCommitmentPercentChange,
    useLpPercent, lpPercent, setLpPercent, handleUseLpPercentChange,
    useGpPercent, gpPercent, setGpPercent, handleUseGpPercentChange,
    customFields,
    handleOnDandDChange,
    handleOnUseArkTransValueChange,
    handleAddCustomColumn, handleEditCustomColumn, handleRemoveCustomColumn,
    handleWorkBookImport,
    handleSave,
    handleReportNameChange, handleFrequencyChange, handleCommitmentTransTypeChange,
    handleIncludeYTDColumnChange, handleIncludeITDColumnChange
  };
};