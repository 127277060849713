import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../../core/context/appContextProvider";
import { postNewTransType } from "../../../../services/fund.service";
import {
  NewTransType,
  TransTypeBucket,
} from "../../../../utils/types/fund.type";
import { SAVE_NEW_TRANSACTION_ERROR } from "../TransactionMapper.constants";

type Props = {
  clientId: string;
  transTypeModel: TransTypeBucket[];
  onSuccess: () => void;
  onClose: () => void;
};

export const useNewTransactionModal = (props: Props) => {
  const { informationAlert } = useContext(AppContext);
  const { clientId, transTypeModel, onSuccess, onClose } = props;
  const [name, setName] = useState<string>("");
  const [newTransTypeModel, setNewTransTypeModel] = useState<TransTypeBucket[]>(
    []
  );
  const [isSending, setSending] = useState<boolean>(false);

  useEffect(() => {
    setNewTransTypeModel(transTypeModel);
  }, [transTypeModel]);

  const handleNewTransactionSave = async () => {
    if (newTransTypeModel && name) {
      const newTransType: NewTransType = {
        typeName: name,
        transactionBuckets: newTransTypeModel,
        tenantId: clientId,
      };

      try {
        setSending(true);
        await postNewTransType(newTransType);

        setSending(false);
        onSuccess();
        onClose();
      } catch (e) {
        informationAlert(SAVE_NEW_TRANSACTION_ERROR, "error");
      }
    }
  };

  const handleNewTransactionValues = (action: number, name: string) => {
    const newValues = [...newTransTypeModel];
    const updatedValue = newValues.find((v) => v.label === name);

    if (updatedValue) {
      updatedValue.value = updatedValue.value + action;
    }

    setNewTransTypeModel(newValues);
  };

  return {
    handleNewTransactionValues,
    handleNewTransactionSave,
    isSending,
    name,
    setName,
    newTransTypeModel,
  };
};
