import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import useFSMappingFilterEffect from "./useFSMappingFilter.hook";

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (purchaseTransaction: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  doNotMap?: boolean;
  fundId: string;
  isNewAccount: boolean;
  accountName: string;
  accountNumber: number | null;
};

type FSMappingValue = any | null | undefined;

export const FSMappingFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = "small",
  id = "fsmapping_filter",
  textFieldID = "txt_fsmapping",
  doNotMap = false,
  fundId,
  isNewAccount,
  accountName,
  accountNumber,
}: Props) => {
  const { fsMappingList, loading } = useFSMappingFilterEffect(fundId);
  const newAccount = {
    id: "00000000-0000-0000-0000-000000000000",
    name: "",
  };

  useEffect(() => {
    if (isNewAccount && fsMappingList) {
      fsMappingList.unshift(newAccount);
    }
  }, [fsMappingList]);

  useEffect(() => {
    if (isNewAccount && fsMappingList) {
      fsMappingList[0].name = `${
        accountNumber ? accountNumber + " - " : ""
      }${accountName}`;
    }
  }, [accountName, accountNumber]);

  const onSelectChange = (_: any, fsMapping: any) => {
    if (!fsMapping) {
      return;
    }
    onChange?.(idSelectOnly ? fsMapping?.id ?? "" : fsMapping);
  };

  const selectedValue: FSMappingValue = idSelectOnly
    ? doNotMap
      ? { id: "1", name: "Do Not Map" }
      : fsMappingList?.find((item: any) => item.id === value) ?? null
    : (value as FSMappingValue);

  return (
    <Autocomplete
      id={id}
      options={fsMappingList || []}
      getOptionLabel={(option: any) => option?.name ?? ""}
      isOptionEqualToValue={(option: any, selected: any) =>
        option?.name === selected?.name
      }
      onChange={onSelectChange}
      value={selectedValue ?? {}}
      size={size}
      disabled={disabled || loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            label={placeholder}
            id={textFieldID}
            error={!!error}
            helperText={error?.message ?? ""}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </>
      )}
    />
  );
};

export default FSMappingFilter;
