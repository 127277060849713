export * as actionTypes from "./actionTypes";

export const LOCKED_PAGE_TOOLTIP_TEXT = "The page is locked";
export const DOWNLOAD_SUCCESS         = "Downloaded successfully!";
export const DOWNLOAD_ERROR           = "Error during download.";
export const FILE_MOVE_ERROR          = "Unable to move this file.";
export const FOLDER_CREATE_ERROR      = "Error during folder creation.";
export const FOLDER_DELETE_ERROR      = "Error during folder deletion.";
export const FOLDER_RENAME_ERROR      = "Unable to rename this folder.";
export const FOLDER_EDIT_ERROR        = "Unable to edit this folder.";
export const LINKED_FILE_ERROR        = "Error fetching linked file.";