import { cloneDeep, findLast } from "lodash";

import { InvestorCommitment, InvestorRow,InvestorRowType } from "../../../workbook.type";
import { NewRowPosition, SpreadsheetGrid } from "../../SpreadsheetGrid";
import { WorkbookSheetsManager } from "../WorkbookSheets.Manager";

export enum InvestorChangeType { Add, NameChange, Deleted }

export class InvestorRowManager {
    private _grid!: SpreadsheetGrid;
    private _investorRows!: InvestorRow[];

    public get quarterHeaderRowId() {
        return '3';
    }

    public get mainHeaderRowId() {
        return '4';
    }

    public get firstInvestorRowSpacerId() {
        return '5';
    }

    public get firstInvestorRowId() {
        return '6';
    }

    public get investorRows() {
        return cloneDeep(this._investorRows);
    }

    public getPartnerTypeTotalRow(partnerType: string) {
        return this.investorRows.find(ir => ir.rowType === InvestorRowType.TypeTotal && ir.investorType === partnerType);
    }

    public getInvestorsOfType(partnerType: string) {
        return this.investorRows.filter(ir => ir.rowType === InvestorRowType.Investor && ir.investorType === partnerType);
    }

    public getPartnerTotalRow() {
        return this.investorRows.find(ir => ir.rowType === InvestorRowType.PartnerTotal)!;
    }

    public getReconcilerRowId() {
        return this._grid.getNextRowId(this.getPartnerTotalRow().gridRowId);
    }

    public get investorIds() {
        return this._investorRows.filter(i => i.rowType === InvestorRowType.Investor).map(i => i.investorId);
    }

    public getInvestorTypeStartEndRowIds(investorType: string) {
        const startRow = this._investorRows.find(ir => ir.rowType === InvestorRowType.Investor && ir.investorType === investorType);

        if(!startRow) {
            return undefined;
        }

        const endRow = findLast(this._investorRows, ir => ir.rowType === InvestorRowType.Investor &&  ir.investorType === investorType)!;

        return {
            startRowId: startRow.gridRowId,
            endRowId: endRow.gridRowId
        };
    }

    public getInvestorTypeRowCaptions(investorType: string) {
        const grid = this._grid;
        const investorTypeIds = this.getInvestorTypeStartEndRowIds(investorType);

        if(!investorTypeIds) {
            throw `The investor type { ${investorType} } does not exist in`;
        }

        const startRowId = investorTypeIds.startRowId === '5' ? '4' : grid.getPrevRow(investorTypeIds.startRowId);
        const endRowId = grid.getNextRowId(investorTypeIds.endRowId);

        return {
            startRow: grid.getRowCaption(startRowId),
            endRow: grid.getRowCaption(endRowId)
        };
    }

    public getInvestorTypes() {
        const allTypes = this._investorRows.map(ir => ir.investorType);

        return Array.from(new Set(allTypes));
    }

    public SetDependencies(grid: SpreadsheetGrid) {
        this._grid = grid;
    } 

    public initInvestorsFromInvestorCommitments(investorCommitments: InvestorCommitment[]) {
        this._investorRows = [];

        const sortedInvestorCommitments = this.sortInvestorCommitmentsByTypeThenName(investorCommitments);

        let currRowId = this.firstInvestorRowSpacerId;

        for(let i = 0; i < sortedInvestorCommitments.length; i++) {
            const investorCommitment = sortedInvestorCommitments[i];

            currRowId = this._grid.getNextRowId(currRowId);
            this._investorRows.push({
                gridRowId: currRowId,
                rowType: InvestorRowType.Investor,
                investorId: investorCommitment.investorId,
                investorType: investorCommitment.investorType,
                investorName: investorCommitment.investorName,
                totalTransactionAmount: investorCommitment.totalTransactionAmount,
                deleted: false
            });

            const isLastRow = i >= (sortedInvestorCommitments.length-1);

            if(isLastRow || investorCommitment.investorType !== sortedInvestorCommitments[i+1].investorType) {
                currRowId = this._grid.getNextRowId(currRowId, 2); //spacer row
                this._investorRows.push(this.createNewInvestorTotal(currRowId, InvestorRowType.TypeTotal, investorCommitment.investorType));

                currRowId = this._grid.getNextRowId(currRowId); //spacer row
            }
        }

        currRowId = this._grid.getNextRowId(currRowId);
        this._investorRows.push(this.createNewInvestorTotal(currRowId, InvestorRowType.PartnerTotal, 'Partners'));
    }

    public initInvestorsFromWorkbook(investorRows: InvestorRow[]) {
        this._investorRows = investorRows.sort((a,b) => parseInt(a.gridRowId) - parseInt(b.gridRowId));
    }

    public updateInvestorFromInvestorCommitments(investorCommitments: InvestorCommitment[]) {
        const grid = this._grid;
        const existingInvestorRows = this._investorRows;
        const updatedRowIDs: string[] = [];

        investorCommitments.forEach(ic => {
            const existingInvestor = existingInvestorRows.find(ir => ir.investorId === ic.investorId);

            if(existingInvestor) {
                if(existingInvestor.investorName !== ic.investorName) {
                    existingInvestor.investorName = ic.investorName;
                    updatedRowIDs.push(existingInvestor.gridRowId);
                }

                return;
            }
            // we make it here then ic is a mew investor
            const newInvestor = ic;

            let newInvestorRowId = this.getInvestorTypeStartEndRowIds(newInvestor.investorType)?.endRowId;

            if(newInvestorRowId) {
                newInvestorRowId = grid.insertRow(newInvestorRowId, NewRowPosition.belowDestination)!;
            } else {
                const typeToInsertAfter = this.getInvestorTypes()
                    .filter(t => t !== 'LP') // LP is always first so wont deal with it
                    .sort((a,b) => a < b ? -1 : 1) // sort descending
                    .find(t => newInvestor.investorType > t);

                let firstRowInNextType: string|undefined;

                if(typeToInsertAfter) {
                    firstRowInNextType = this.getInvestorTypeStartEndRowIds(typeToInsertAfter)?.endRowId;
                }

                if(firstRowInNextType) {
                    firstRowInNextType = grid.getNextColId(firstRowInNextType, 4);
                } else {
                    firstRowInNextType = this.getPartnerTotalRow().gridRowId;
                }

                grid.insertRow(firstRowInNextType, NewRowPosition.destination, 4)!;

                const newTotalTypeRowId = grid.getPrevRow(firstRowInNextType, 2);

                updatedRowIDs.push(newTotalTypeRowId);
                existingInvestorRows.push(this.createNewInvestorTotal(newTotalTypeRowId, InvestorRowType.TypeTotal, newInvestor.investorType));
                
                newInvestorRowId = grid.getPrevRow(newTotalTypeRowId, 2);                
            }

            updatedRowIDs.push(newInvestorRowId);
            existingInvestorRows.push({
                gridRowId: newInvestorRowId,
                rowType: InvestorRowType.Investor,
                investorId: newInvestor.investorId,
                investorType: newInvestor.investorType,
                investorName: newInvestor.investorName,
                totalTransactionAmount: newInvestor.totalTransactionAmount,
                deleted: false
            });            
        });

        if(updatedRowIDs.length) {
            updatedRowIDs.push(this.getPartnerTotalRow().gridRowId);
        }

        return updatedRowIDs;
    }

    private createNewInvestorTotal(rowId: string, rowType: InvestorRowType, investorType: string) {
        const newInvestorTotal: InvestorRow = {
            gridRowId: rowId,
            rowType: rowType,
            investorId: '-',
            investorType: investorType,
            investorName: '-',                
            deleted: false
        };

        return newInvestorTotal;
    }

    private sortInvestorCommitmentsByTypeThenName(investorCommitments: InvestorCommitment[]) {
        const sortedInvestorCommitments = cloneDeep(investorCommitments);

        sortedInvestorCommitments.sort((a, b) => {
            if(a.investorType === b.investorType) {
                return a.investorName < b.investorName ? -1 : 1;
            }

            //force LP on top
            if(a.investorType === 'LP') return -1;
            if(b.investorType === 'LP') return 1;

            return a.investorType < b.investorType ? -1 : 1;
        }) ;

        return sortedInvestorCommitments;
    }
}