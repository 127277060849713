import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Drawer, styled, Typography } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../utils/constants/styles.constants";

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  }
}));

export const TitleBox = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: `${theme.spacing(4)}`,
  maxWidth: INFO_PANEL_MAX_WIDTH,
}));

export const MarginBox = styled(Box)(({ theme }) => ({
  minWidth: INFO_PANEL_MAX_WIDTH,
  maxWidth: INFO_PANEL_MAX_WIDTH,
  padding: `0px ${theme.spacing(4)}`
}));

export const ColumnsBox = styled(Box)(({
  theme
}) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  gap: theme.spacing(2),
}));

export const SectionBox = styled(Box)(({ theme }) => (`
  height: 80vh;
`));

export const BorderBox = styled(Box)(({ theme }) => (`
  margin-top: ${theme.spacing(1)};
  border: 1px solid ${theme.palette.secondary.dark};
  border-radius: ${theme.spacing(0.75)};
  height: calc(100% - 33px);
`));

export const ScrollBox = styled(Box)`
  overflow: auto;
  height: calc(100% - 63px);
`;

export const FundItem = styled(Box)(({ theme }) => (`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  cursor: pointer;
  align-items: center;

  &:hover {
    background: rgba(0, 122, 255, 0.1);
  }

  &.selected {
    background: rgba(0, 122, 255);
    color: ${theme.palette.common.white};

    & .MuiSvgIcon-root {
      color: ${theme.palette.common.white};
    }
  }
`));

export const QuarterItem = styled(Box)(({ theme }) => (`
  display: grid;
  grid-template-columns: 40% 60%;
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  align-items: center;
  & .MuiFormControlLabel-root {
    height: 24px;
  }
`));

export const ArrowIcon = styled(ArrowForwardIosIcon)(({ theme }) => (`
  color: ${theme.palette.secondary.dark}
`));

export const SearchBox = styled(Box)`
  height: 63px;
`;
