import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { memo } from "react";

import {
  BodyContainer,
  Container,
  HeaderContainer,
  StyledBodyWrapperSkeleton,
  StyledHeaderSkeleton,
  StyledSkeleton
} from "../Skeleton.style";

type Props = {
    backgroundColor?: string | null;
    buttonColor?: string | null;
    bodyBackgroundColor?: string | null;
    headerBackgroundColor?: string | null;
    navigationColor?: string | null;
}
const PortalSkeleton = ({
  navigationColor,
  backgroundColor,
  buttonColor,
  bodyBackgroundColor,
  headerBackgroundColor
}: Props) => {
  const theme = useTheme();

  return (
    <Container>
      <HeaderContainer backgroundColor={headerBackgroundColor || theme.palette.common.white}>
        <StyledHeaderSkeleton variant="rectangular" animation={false} />
        <StyledHeaderSkeleton variant="text" animation={false} />
        <Box />
        <StyledHeaderSkeleton variant="text" animation={false} />
        <StyledHeaderSkeleton variant="circular" animation={false} />
      </HeaderContainer>
      <BodyContainer>
        <StyledSkeleton
          backgroundColor={navigationColor || theme.palette.common.sidebarBackground}/>
        <StyledSkeleton backgroundColor={backgroundColor || theme.palette.secondary.dark} p={1}>
          <StyledBodyWrapperSkeleton backgroundColor={bodyBackgroundColor || theme.palette.common.white}>
            <StyledSkeleton width="100%" backgroundColor={theme.palette.common.sidebarBackground} />
            <StyledSkeleton width="20%" backgroundColor={buttonColor ||  theme.palette.secondary.dark} />
            <StyledSkeleton width="100%" backgroundColor={theme.palette.common.sidebarBackground} />
            <StyledSkeleton width="20%" backgroundColor={buttonColor ||  theme.palette.secondary.dark} />
          </StyledBodyWrapperSkeleton>
        </StyledSkeleton>
      </BodyContainer>
    </Container>
  );
};

export default memo(PortalSkeleton);
