import { FC } from 'react';

import {
  BodyTypographyStyled,
  MainPaperStyled,
  RightAlignedBox,
  SummaryBoxStyled,
  TitleTypographyStyled,
} from './SummaryCard.styles';

interface SummaryCardProps {
  title: string;
  value: string;
}

export const SummaryCard: FC<SummaryCardProps> = ({ title, value }) => {
  return (
    <MainPaperStyled>
      <SummaryBoxStyled>
        <TitleTypographyStyled>{title}</TitleTypographyStyled>
        <RightAlignedBox>
          <BodyTypographyStyled variant="h4">{value}</BodyTypographyStyled>
        </RightAlignedBox>
      </SummaryBoxStyled>
    </MainPaperStyled>
  );
};
