import CssBaseline from '@mui/material/CssBaseline';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import React, { useContext, useMemo } from 'react';

import { ClientThemeContext } from "../../core/context/clientThemeContext";
import componentsOverride from './overrides';
import palette from './palette';
import typography from './typography';

interface Props {
  children: React.ReactNode;
}

const ThemeConfig = ({
  children
}: Props) => {
  const {
    clientTheme
  } = useContext(ClientThemeContext);
  const themeOptions = useMemo(
    () => ({
      typography,
      palette,
    }),
    [],
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme, clientTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
