import { Box, styled, Typography } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
}));

export const FilterBox = styled(Box)`
  display: grid;
  grid-template-columns: 400px auto;
  align-items: center;
`;

export const TimeText = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.grey[500],
}));
