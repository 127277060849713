import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DefaultizedPieValueType } from '@mui/x-charts';
import { useDrawingArea } from '@mui/x-charts/hooks';
import {
  pieArcLabelClasses,
  PieChart as MuiPieChart,
} from '@mui/x-charts/PieChart';
import React, { FC } from 'react';

import { generateUUID } from '../../../utils/helpers/uuidGenerator';
import { PieDataItem } from '../../../utils/types/visualDashboard.type';
import { getChartLegend } from '../ChartLegend/ChartLegend';
import { chartsTooltipValueFormatter } from '../helpers/Chart.utils';
import {
  LegendGrid,
  PieChartAndLegendContainer,
  PieChartGrid,
  pieChartStyles,
  PieChartTitle,
  PieChartWrapper,
  pieParams,
} from './PieChart.styles';

const StyledText = styled('text')<{ fontSize: number }>(
  ({ theme, fontSize }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: `${fontSize}px`,
  })
);

interface PieCenterLabelProps {
  children: React.ReactNode;
  label: string;
}

function PieCenterLabel({ children, label }: PieCenterLabelProps) {
  const { width, height, left, top } = useDrawingArea();
  const fontSize = 0.09 * width > 20 ? 20 : 0.09 * width;

  return (
    <>
      <StyledText
        id="PieChartWrapper_CenterLabelTotal"
        x={left + width / 2}
        y={top + height / 2 - fontSize * 0.67}
        fontSize={fontSize}
      >
        {children}
      </StyledText>
      <StyledText
        x={left + width / 2}
        y={top + height / 2 + fontSize * 0.67}
        fontSize={fontSize}
      >
        {label}
      </StyledText>
    </>
  );
}

interface PieProps {
  id: string;
  pieDataItems: PieDataItem[];
  currency: string;
  title: string;
  centerLabel: string;
}

export const PieChart: FC<PieProps> = ({
  id = 'PieChartWrapper',
  pieDataItems,
  currency,
  title,
  centerLabel,
}) => {
  if (!pieDataItems?.length) return <></>;

  const pieTotal = pieDataItems.reduce(
    (total, item) => total + ((item.value as number) ?? 0),
    0
  );
  const totalStr = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'currency',
    currency: currency,
  }).format(pieTotal);

  const legend = getChartLegend(pieDataItems);

  return (
    <PieChartWrapper id={id} container>
      <Grid>
        <PieChartTitle id="pie_chart_title">{title}</PieChartTitle>
      </Grid>
      <PieChartAndLegendContainer container direction="row">
        <LegendGrid item xs={6}>
          {legend}
        </LegendGrid>
        <PieChartGrid item xs={6}>
          <MuiPieChart
            id={'mui_pie_chart'}
            series={[
              {
                data: pieDataItems,
                innerRadius: '60%',
                arcLabel: (params: DefaultizedPieValueType) => '',
                valueFormatter: ({ value }) =>
                  chartsTooltipValueFormatter(value, currency),
                cx: '50%',
                cy: '50%',
              },
            ]}
            slotProps={{
              legend: { hidden: true },
              popper: {
                sx: pieChartStyles.popper,
              },
            }}
            sx={pieChartStyles.arcLabel}
            {...pieParams}
          >
            <PieCenterLabel label={centerLabel}>{totalStr}</PieCenterLabel>
          </MuiPieChart>
        </PieChartGrid>
      </PieChartAndLegendContainer>
    </PieChartWrapper>
  );
};
