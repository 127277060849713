import { useCallback, useContext, useMemo, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { ClientConsentList } from "../../../utils/types/client.type";
import { getConsents } from "../../client.service.v2";

export const useClientConsentsEffect = (id?: string) => {
  const [consents, setConsents] = useState<ClientConsentList | undefined>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchConsents = useCallback( async (isCanceled?: () => boolean) => {
    if(!id) return;
    try {
      setLoading(true);
      const response = await getConsents(id!);

      if (isCanceled?.()) return;

      setConsents(response);
      setLoading(false);
    } catch (e) {
        informationAlert("Error in getting consents", "error");
      setLoading(false);
    }
  }, [id]);

  useEffectAsync(async (isCanceled) => {
    await fetchConsents(isCanceled);
  }, [fetchConsents]);

  const consentsList = useMemo(() => consents?.consents || {}, [consents]);

  return {
    consents: consentsList,
    fetchConsents,
    loading,
    setConsents
  };
};
