import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";

import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import {
  getCurrentEnv,
  maxAllowedAttempts,
  sendRecoveryEmail,
} from "../../services/login.service";
import {
  getHeaders,
  requestCodeURL,
} from "../../utils/constants/login.constants";
import {
  ActionButton,
  ContentDiv,
  DivSpacing,
  StyledDivider,
  StyledFont,
  StyledLabel,
  StyledLink,
  StyledResponsiveLabel,
} from "./Login.styles";

const url = window.location.hostname;
const HOST = process.env.REACT_APP_API_ENDPOINT_URL;

const MultiFactorAuth = ({
  loginToken,
  userDetailInfo,
  setShowLoginLoader,
  setUserDetailInfo,
  setCurrentPage,
}: any) => {
  const LabelText = (text: string) => (
    <StyledLabel color={clientTheme?.loginTextColor}>{text}</StyledLabel>
  );
  const ResponsiveTextLabel = (text: string) => (
    <StyledResponsiveLabel color={clientTheme?.loginTextColor}>
      {text}
    </StyledResponsiveLabel>
  );
  const { clientTheme } = useContext(ClientThemeContext);
  const { preAuthClientConfigs, informationAlert } = useContext(AppContext);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<{
    deliveryType: string;
    trusted: boolean;
  }>({
    defaultValues: {
      deliveryType: "text",
      trusted: userDetailInfo?.trustedDevice,
    },
  });

  const getRequestId = async (data: any) => {
    const bodyParams = {
      accessToken: loginToken,
      code: null,
      deliveryType: data?.deliveryType,
      errorMessage: null,
      expirationDate: null,
      requestId: null,
      verificationAttempts: null,
      verifyCodeFakeResult: null,
      verifySuccess: null,
    };
    const requestHeader = getHeaders("POST", bodyParams, loginToken);

    try {
      const url = `${HOST}${requestCodeURL}`;
      const data = await fetch(url, requestHeader);

      const response = await data.json();

      return response;
    } catch (ex) {
      return null;
    }
  };

  const checkEnv = async () => {
    try {
      const currentEnv = await getCurrentEnv(loginToken);

      return currentEnv;
    } catch (ex) {
      return null;
    }
  };

  const requestLoginCode = async (data: any) => {
    setShowLoginLoader(true);
    try {
      const requestInfo = await getRequestId(data);
      const currentEnv = await checkEnv();
      const maxAttempts = maxAllowedAttempts(loginToken);

      setUserDetailInfo((prev: any) => ({
        ...prev,
        requestIdResponse: requestInfo,
        isDevEnv: currentEnv === "dev",
        trustDevice: !!data.trusted,
      }));
      setCurrentPage("ConfirmCode");
      setShowLoginLoader(false);
    } catch (e) {
      setShowLoginLoader(false);
    }
  };

  const accountRecovery = async () => {
    const data = {
      clientBaseDomain: preAuthClientConfigs?.baseDomain || "",
      clientId: preAuthClientConfigs?.clientId || null,
      clientName: preAuthClientConfigs?.clientName || "",
      clientSubdomain: preAuthClientConfigs?.subdomain || "",
      clientSupportEmail: preAuthClientConfigs?.supportEmail || "",
      username: userDetailInfo?.username || "",
    };

    try {
      await sendRecoveryEmail(data, loginToken);
      setCurrentPage("AccountRecovery");
    } catch (error) {
      informationAlert(
        (error as unknown as any)?.response?.data?.message ||
          "Recovery email failed",
        "error"
      );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(requestLoginCode)}>
        <Grid container justifyContent="center">
          <ContentDiv>
            <Typography variant="h1">
              {LabelText("Multi-factor Authentication Required")}
            </Typography>
            <DivSpacing />
            Account:
            <Typography variant="h1">
              {LabelText(`${userDetailInfo?.username}`)}
            </Typography>
            {userDetailInfo?.showloginWarning && (
              <FormLabel error={true}>
                {" "}
                {userDetailInfo.loginRemainingCount} attempts remaining
              </FormLabel>
            )}
            <StyledDivider color={clientTheme?.loginTextColor} />
            <StyledFont>
              {ResponsiveTextLabel(
                "A verification code will be sent to the number on file:"
              )}
            </StyledFont>
            <DivSpacing>
              <Typography variant="h1">
                {LabelText(`**** - **** - ${userDetailInfo?.phoneNumber}`)}
              </Typography>
            </DivSpacing>
            <StyledFont>
              <StyledLink to="#" onClick={accountRecovery}>
                Click here
              </StyledLink>
              &nbsp;
              {ResponsiveTextLabel(
                " to update the phone number. Message and data rates may apply."
              )}
            </StyledFont>
            <StyledDivider color={clientTheme?.loginTextColor} />
            <DivSpacing>
              <FormControl>
                <FormLabel id="mfa_preferemce">
                  {ResponsiveTextLabel("Select delivery preferrence:")}
                </FormLabel>
                <Controller
                  name="deliveryType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup id="preference_group" {...field}>
                      <FormControlLabel
                        value="text"
                        control={<Radio />}
                        label="Text Message (SMS)"
                      />
                      <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Phone Call"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </DivSpacing>
            <div>
              <FormControl>
                <FormLabel id="mfa_trust_device_label">
                  {ResponsiveTextLabel("Trust this device for 30 days?")}
                </FormLabel>
                <Controller
                  name="trusted"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup id="mfa_trust_device_option" {...field}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>
            <ActionButton
              type="submit"
              variant="contained"
              className="btn"
              disableElevation
              textcolor={clientTheme?.loginButtonTextColor}
              btnbackgroundcolor={clientTheme?.loginButtonBackgroundColor}
              fullWidth
            >
              Send Code
            </ActionButton>
          </ContentDiv>
        </Grid>
      </form>
    </div>
  );
};

export default MultiFactorAuth;
