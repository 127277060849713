import { FormHelperText, InputLabel, MenuItem, TextField } from "@mui/material";
import React, { ReactElement } from "react";

import { ListItem, SelectionOptionItem } from "../../utils/types/listItems";
import { NameIdPair } from "../../utils/types/transaction.type";
import { StyledAutoComplete, StyledFormControl, StyledSelect } from "./SingleSelect.styles";

type Props = {
  id: string;
  optionList: ListItem[] | NameIdPair[] | any[];
  handleOptionSelection: any;
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
  value?: string;
  defaultValue?: string;
  fullWidth?: boolean;
  width?: string;
  isShort?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  noGutter?: boolean;
  idField?: string;
  labelField?: string;
  isSearchable?: boolean;
};

const SingleSelect: React.FC<Props> = (props: Props): ReactElement => {
  const {
    id = "single-select",
    label,
    optionList,
    handleOptionSelection,
    required = false,
    error = false,
    helperText = "",
    value,
    defaultValue = "",
    fullWidth = false,
    isShort = false,
    hidden = false,
    disabled = false,
    noGutter = false,
    width,
    idField = 'id',
    labelField = 'label',
    isSearchable = false,
  } = props;

  const [options, setOptions] = React.useState<ListItem[] | NameIdPair[] | any[]>([]);


  React.useEffect(() => {
    setOptions(optionList);
  }, [JSON.stringify(optionList)]);

  return (
    <>
      {!hidden && (
        isSearchable ? <StyledAutoComplete
          id={id}
          options={options}
          getOptionLabel={(option: any) => option[labelField]}
          onChange={handleOptionSelection}
          disabled={disabled}
          isFullWidth={fullWidth.toString()}
          isShort={isShort.toString()}
          renderInput={(params) => <TextField {...params} label={label} />}
        /> : (
          <StyledFormControl
            size="small"
            isFullWidth={fullWidth.toString()}
            isShort={isShort.toString()}
            noGutter={noGutter.toString()}
          >
            <InputLabel
              id={`${id}_label`}
              error={error}>
              {label}
            </InputLabel>
            <StyledSelect
              required={required}
              labelId={`${id}_label`}
              id={id}
              defaultValue={defaultValue}
              value={value || ''}
              label={label}
              onChange={handleOptionSelection}
              error={error}
              isFullWidth={fullWidth.toString()}
              isShort={isShort.toString()}
              disabled={disabled}
              width={width}
            >
              {options.map((option, key) => {
                return (
                  <MenuItem key={key} value={option[idField as keyof (NameIdPair | ListItem)]}>
                    {option[labelField as keyof (NameIdPair | ListItem)]}
                  </MenuItem>
                );
              })}
            </StyledSelect>
            <FormHelperText hidden={!error} error={error} id={`${id}-helper-text`}>
              {helperText}
            </FormHelperText>
          </StyledFormControl>
        )
      )}
    </>
  );
};

export default SingleSelect;
