import { GridAlignment } from "@mui/x-data-grid-pro";

import { CustomType, DataGridColDef } from "../../../utils/types/listItems";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Company Name",
    hide: false,
    index: 1,
    sortable: true,
    type: 'string',
    align: 'left' as GridAlignment,
    width: 200,
  },
  {
    field: "industrySector",
    headerName: "Industry/Sector",
    hide: false,
    index: 2,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "holdingStatus",
    headerName: "Holding Status",
    hide: false,
    index: 3,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 180
  },
  {
    field: "address.country",
    headerName: "Country",
    hide: false,
    index: 4,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 180
  },
  {
    field: "funds",
    headerName: "Funds",
    hide: false,
    index: 5,
    type: 'chips',
    chipLabelField: "name",
    chipIDField: "id",
    sortable: false,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "logo",
    headerName: "Logo",
    hide: false,
    index: 6,
    type: 'string',
    sortable: false,
    align: 'left' as GridAlignment,
    width: 180
  },
  {
    field: "address.street1",
    headerName: "Address Line 1",
    hide: false,
    index: 7,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "address.street2",
    headerName: "Address Line 2",
    hide: false,
    index: 8,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "address.postalCode",
    headerName: "Zip code",
    hide: false,
    index: 9,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "address.city",
    headerName: "City",
    hide: false,
    index: 10,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "address.state",
    headerName: "State",
    hide: false,
    index: 11,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "yearFounded",
    headerName: "Year Founded",
    hide: false,
    index: 12,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "about",
    headerName: "About",
    hide: false,
    index: 13,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "ceo",
    headerName: "CEO",
    hide: false,
    index: 14,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "numOfEmployees",
    headerName: "Number of employees",
    hide: false,
    index: 15,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "isPublic",
    headerName: "Public",
    hide: false,
    index: 16,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "tickerSymbol",
    headerName: "Ticker Symbol",
    hide: false,
    index: 17,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "website",
    headerName: "Website",
    hide: false,
    index: 18,
    type: 'string',
    sortable: true,
    align: 'left' as GridAlignment,
    width: 200
  },
  {
    field: "investmentTypes",
    headerName: "Securities",
    hide: false,
    index: 19,
    type: 'chips',
    chipLabelField: "label",
    chipIDField: "id",
    sortable: false,
    align: 'left' as GridAlignment,
    width: 250
  },
  {
    field: "action",
    headerName: "Columns",
    hide: false,
    hideable: false,
    index: 20,
    type: 'action',
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  }
];

export default defaultHeaderList;