import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Chip, Tooltip, Typography } from '@mui/material';
import React from 'react';

import ChipsCell from '../../../../components/DataGrid/ChipsCell/ChipsCell';
import { M_DASH_UNICODE } from '../../../../utils/constants/constants';
import {
  BlueAddCircleOutlineOutlinedIcon,
  CellBox,
  DisabledStyledChip,
  GreenCheckCircleOutlineIcon,
  NowrapTypography,
  OrangeErrorOutlineOutlinedIcon,
} from '../../bankFeeds/BankFeedList.styles';

type ChipComponentProps = {
  label?: string;
  chipData?: string[];
  value?: string | null;
  onChipClick?: () => void;
  onDelete?: () => void;
};

export const EditableGlAccountChips: React.FC<ChipComponentProps> = ({
  chipData,
  onChipClick,
}) => {
  const idLabelChips = chipData?.map((str, index) => ({
    id: index,
    label: str,
  }));

  return (
    <ChipsCell
      name={'glAccountNames'}
      items={idLabelChips || []}
      chipLabelField={'label'}
      chipIDField={'id'}
      chipCount={1}
      onChipClick={onChipClick}
    />
  );
};

export const EditableChipWithDelete: React.FC<ChipComponentProps> = ({
  label,
  onChipClick,
  onDelete,
}) => {
  return <Chip label={label} onClick={onChipClick} onDelete={onDelete} />;
};

export const ReadOnlyChipsOrDash: React.FC<ChipComponentProps> = ({
  chipData,
}) => {
  const idLabelChips = chipData?.map((str, index) => ({
    id: index,
    label: str,
  }));

  return idLabelChips?.length ? (
    <ChipsCell
      items={idLabelChips}
      chipLabelField={'label'}
      chipIDField={'id'}
      chipCount={1}
    />
  ) : (
    <>{M_DASH_UNICODE}</>
  );
};

export const NoLedgerOrGlAccountChip: React.FC<ChipComponentProps> = () => {
  return (
    <DisabledStyledChip
      label={'Editing Not Available'}
      icon={
        <Tooltip
          arrow
          enterDelay={500}
          placement="right"
          title={
            <Typography variant="body2">
              Please assign a Ledger & GL Account to the Bank Account
            </Typography>
          }
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      }
    />
  );
};

export const SelectOptionChip: React.FC<ChipComponentProps> = ({
  label,
  onChipClick,
}) => {
  return (
    <Chip
      label={label}
      icon={<BlueAddCircleOutlineOutlinedIcon />}
      onClick={onChipClick}
    />
  );
};

export const MemoEntityRequiredChip: React.FC<ChipComponentProps> = () => {
  return (
    <Chip
      label="Memo Required"
      variant="outlined"
      color="error"
      icon={<AddCircleOutlineOutlinedIcon />}
    />
  );
};

export const EditableCapCallChip: React.FC<ChipComponentProps> = ({
  onChipClick,
  onDelete,
}) => {
  return (
    <Chip
      label="Capital Call Received"
      icon={<GreenCheckCircleOutlineIcon />}
      onClick={onChipClick}
      onDelete={onDelete}
    />
  );
};

export const ReadOnlyCapCallChip: React.FC<ChipComponentProps> = ({
  value,
}) => {
  return !!value ? (
    <Chip
      label="Capital Call Received"
      icon={<GreenCheckCircleOutlineIcon />}
    />
  ) : (
    <>{M_DASH_UNICODE}</>
  );
};
