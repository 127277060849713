import { Box, Paper, styled, SxProps, Theme, Typography } from '@mui/material';

export const TotalValueAndDistributionsBarChartStyled = styled(Paper)(
  ({ theme }) => ({
    padding: theme.spacing(1),
    height: '300px',
    boxShadow: 'none',
  })
);

export const BarChartHeaderBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
}));

export const BarChartLegendBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const LegendItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const BarChartTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  textAlign: 'left',
  whiteSpace: 'nowrap',
}));

export const barChartParams = {
  margin: {
    left: 64,
    right: 24,
    top: 32,
    bottom: 48,
  },
};

export const chartStyles = {
  popper: {
    '& .MuiChartsTooltip-valueCell': {
      textAlign: 'right',
    },
  } as SxProps<Theme>,
};
