import EmailIcon from '@mui/icons-material/Email';
import { createSvgIcon, SelectChangeEvent, useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import useRole from '../../../../core/routing/useRole';
import {
  getReviews,
  updateStatus,
} from '../../../../services/capitalCalls.service';
import {
  downloadFilesAndFolder,
  getDocumentUrl,
  getEmail,
} from '../../../../services/documents.service';
import { useCapitalCallDetailsEffect } from '../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks';
import { CAPITAL_CALL_ICON } from '../../../../utils/constants/text.constants';
import downloadFile from '../../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { fetchBeforeSave } from '../../../../utils/helpers/misc.helper';
import { Reviews } from '../../../../utils/types/capitalCalls.type';
import { ImageItem } from '../../../../utils/types/listItems';
import { ScopeRole } from '../../../../utils/types/user.type';
import { REVIEW_FETCH_ERROR } from '../AddTransactions/constants';
import {
  DOCUMENT_GET_ERROR,
  REVIEW_DOCUMENT_ERROR,
  REVIEW_STATUS_UPDATE_ERROR,
  UPDATE_STATUS_DOCUMENT_ERROR,
} from '../constants';

type RouteProp = {
  id: string;
};
export const useReviewDocuments = () => {
  const theme = useTheme();
  const params = useParams<RouteProp>();
  const [selectedContactMode, setSelectedContactMode] =
    useState('capitalCalls');
  const [reviews, setReviews] = useState<Reviews[]>([] || undefined);
  const [investors, setInvestors] = useState<string[]>([] || undefined);
  const [documentUrls, setDocumentUrls] = useState<string[]>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { informationAlert, state } = useContext(AppContext);

  const { capitalCall } = useCapitalCallDetailsEffect(
    params.id !== 'new' ? params.id : undefined
  );
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);
  const CapitalCallIcon = createSvgIcon(
    <path d={CAPITAL_CALL_ICON} fill={theme.palette.primary.main} />,
    'Home'
  );
  const defaultContactModes: ImageItem[] = [
    {
      id: 'capitalCalls',
      text: 'CAPITAL CALL',
      icon: <CapitalCallIcon />,
      optionsSelected: 0,
    },
    {
      id: 'email',
      text: 'Email',
      icon: <EmailIcon />,
      optionsSelected: 0,
    },
  ];
  const [contactModeOptions, setContactModeOptions] =
    useState<ImageItem[]>(defaultContactModes);

  const onContactModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    if (newValue) setSelectedContactMode(newValue);
  };

  const handleDownloadAll = async () => {
    setLoading(true);

    const singleDocument = documentIds.length === 1 ? true : false;

    const fileBlob = await downloadFilesAndFolder('', documentIds.join(','));

    if (fileBlob) {
      downloadFile(
        fileBlob,
        `Capital_Calls-${DateTimeFormat.getFormattedDate(new Date())}`,
        `${singleDocument ? 'pdf' : 'zip'}`
      );
    }

    setLoading(false);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const index = investors.indexOf(event.target.value as string);

    setCurrentIndex(index);
  };

  const handleRightSideOnClick = (value: number) => {
    setCurrentIndex(value);
  };

  const fetchReviews = async (id: string) => {
    try {
      setLoading(true);
      const res = await getReviews(id);

      setReviews(res);
      await setReviewStateDetails(res);
    } catch (error) {
      informationAlert(REVIEW_FETCH_ERROR, 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentUrl = async (documentId: string) => {
    try {
      const res = await getDocumentUrl(documentId);

      return res;
    } catch (error) {
      informationAlert(DOCUMENT_GET_ERROR, 'error');
    }
  };

  const setReviewStateDetails = async (reviews: Reviews[]) => {
    try {
      const investors: string[] = [];

      reviews.forEach((review) => {
        if (!investors.includes(review.investorName)) {
          investors.push(review.investorName);
        }
      });

      const urls = await Promise.all(
        reviews.map(async (review) => {
          const document = await fetchDocumentUrl(review.documentId);

          return document?.value || '';
        })
      );

      const docIds = reviews.map((review) => review.documentId);

      const emails = await Promise.all(
        reviews.map(async (review) => {
          const emailValues = await getEmail(review.approvalId);

          return emailValues.value;
        })
      );

      setInvestors(investors);
      setDocumentUrls(urls);
      setDocumentIds(docIds);
      setEmails(emails);
    } catch (error) {
      setLoading(false);
      informationAlert(REVIEW_DOCUMENT_ERROR, 'error');
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((index) => index - 1);
    }
  };

  const handleFlag = async (status: boolean | null) => {
    try {
      await updateStatus(params?.id, reviews[currentIndex].approvalId, {
        status,
      });

      setReviews((prevReviews) =>
        prevReviews?.map((review, index) => {
          if (index === currentIndex) {
            return {
              ...review,
              checked: status,
            };
          }
          return review;
        })
      );
    } catch (error) {
      informationAlert(REVIEW_STATUS_UPDATE_ERROR, 'error');
    }
  };

  useEffect(() => {
    if (params?.id) {
      fetchReviews(params?.id);
    }
  }, [params?.id]);
  const allowEdit = useMemo(() => {
    if (params?.id !== 'new') {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          capitalCall?.createdBy?.toLowerCase() &&
        capitalCall?.status?.toLowerCase() !== 'published'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [params.id, capitalCall, state]);

  const { isNextDisabled, nextButtonText, currentReview, shouldApprove } =
    useMemo(() => {
      let isNextDisabled = false;
      let nextButtonText = 'Approve and Next';

      let shouldApprove = false;
      const isLast = currentIndex === reviews.length - 1;

      if (!isLast && reviews?.[currentIndex]?.checked === null) {
        //Initial State
        isNextDisabled = false;
        nextButtonText = 'Approve and Next';
        shouldApprove = true;
      } else if (!isLast && reviews?.[currentIndex]?.checked === false) {
        //Flagged
        nextButtonText = 'Next';
        isNextDisabled = false;
      } else if (isLast && reviews?.[currentIndex]?.checked === null) {
        isNextDisabled = false;
        nextButtonText = allowEdit
          ? 'Approve'
          : capitalCall?.status?.toLowerCase() === 'reviewing'
          ? 'Approve'
          : 'Next';
        shouldApprove = true;
      } else if (isLast && reviews?.[currentIndex]?.checked !== null) {
        isNextDisabled = true;
        nextButtonText = allowEdit
          ? 'Approve'
          : capitalCall?.status?.toLowerCase() === 'reviewing'
          ? 'Approve'
          : 'Next';
      }
      return {
        isNextDisabled,
        nextButtonText,
        currentReview: reviews?.[currentIndex],
        shouldApprove,
      };
    }, [reviews, currentIndex, capitalCall]);

  const handleNext = async () => {
    if (
      allowEdit ||
      (!allowEdit && capitalCall?.status?.toLowerCase() === 'reviewing')
    ) {
      if (shouldApprove && currentReview?.canApprove) {
        try {
          await updateStatus(params?.id, reviews[currentIndex].approvalId, {
            status: true,
          });
          setReviews((prevReviews) =>
            prevReviews?.map((review, index) => {
              if (index === currentIndex) {
                return {
                  ...review,
                  checked: true,
                };
              }
              return review;
            })
          );
        } catch (error) {
          informationAlert(UPDATE_STATUS_DOCUMENT_ERROR, 'error');
        }
      }
    }
    if (currentIndex < reviews.length - 1) {
      setCurrentIndex((index) => index + 1);
    }
  };

  return {
    selectedContactMode,
    setSelectedContactMode,
    contactModeOptions,
    onContactModeChange,
    reviews,
    investors,
    documentUrls,
    handleSelectChange,
    handleRightSideOnClick,
    currentIndex,
    setCurrentIndex,
    handlePrevious,
    handleNext,
    handleFlag,
    fullScreen,
    setFullScreen,
    emails,
    shouldApprove,
    currentReview,
    isNextDisabled,
    nextButtonText,
    loading,
    isSuperAdminOrClientAdmin,
    capitalCall,
    allowEdit,
    handleDownloadAll,
  };
};
