import { Box, Chip, Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import React from "react";

import ChipsCell from "../../../../components/DataGrid/ChipsCell/ChipsCell";
import {
  ChipContainer,
  Container,
  StyledStack,
  StyledTypography,
} from "./EmailViewer.styles";

type Props = {
  contactEmails: string[];
  email: string;
};
const EmailViewer = ({ contactEmails, email }: Props) => {
  const parser = new DOMParser();

  return (
    <Container>
      <StyledStack>
        <StyledTypography variant="h1">Email Recipient List</StyledTypography>
        <ChipContainer>
          <ChipsCell
            name={"email"}
            items={contactEmails}
            chipLabelField={""}
            chipIDField={""}
            chipCount={6}
            variant="outlined"
            display="block"
          />
        </ChipContainer>
      </StyledStack>
      <StyledStack>
        <StyledTypography variant="h1">Email</StyledTypography>
        {parse(email)}
      </StyledStack>
    </Container>
  );
};

export default React.memo(EmailViewer);
