export type ArchiveOption = {
  id: string;
  name: string;
};

export const FileArchiveOptions: ArchiveOption[] = [
  { id: 'RESPECT_FOLDER', name: 'Respect Folder Settings' },
  { id: 'DISABLE_ARCHIVE', name: 'Disable Archiving' },
  { id: 'RESPECT_FILE', name: 'Set Custom Date' },
];

export const UI_VALUE_LOOKUP: {
  [key: string]: string;
} = {
  RESPECT_FOLDER: 'Respect Folder Settings',
  DISABLE_ARCHIVE: 'Disable Archiving',
};

export enum ARCHIVE_TOOLTIP_TEXT {
  KEEP_EXISTING = "Selecting 'Keep Existing' will retain the current archive settings for each selected file, ensuring no changes are applied during the bulk edit operation.",
  RESPECT_FOLDER_SETTINGS = "Selecting 'Respect Folder Settings' will retain the current folder archive settings for documents based on the document's tagged period.",
  DISABLE_ARCHIVING = "Selecting 'Disable Archiving' will prevent the document from being archived, regardless of folder settings.",
  SET_CUSTOM_DATE = 'Selecting a custom date will archive the document on the selected date, regardless of folder settings.',
}
