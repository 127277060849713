import { useContext } from "react";

import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import { useNavBarContext } from "../../core/context/navbarContext";
import useRole from "../../core/routing/useRole";
import { ScopeRole } from "../../utils/types/user.type";

const useSideBar = () => {
  const {
    isNavBarOpen,
    setIsNavBarOpen,
    isNavBarPinned,
    setIsNavBarPinned,
  } = useNavBarContext();
  const {
    state,
    sideNavs
  } = useContext(AppContext);
  const {
    clientTheme
  } = useContext(ClientThemeContext);

  const {
    hasRole: hasUserRole
  } = useRole([ScopeRole.BASIC_USER]);

  const openSideBar = () => {
    if(isNavBarPinned) {
      return;
    }
    setIsNavBarOpen(true);
  };

  const closeSideBar = () => {
    if(isNavBarPinned) {
      return;
    }
    setIsNavBarOpen(false);
  };

  const togglePinSideBar = () => {
    setIsNavBarPinned(!isNavBarPinned);
  };

  return {
    isNavBarOpen,
    openSideBar,
    closeSideBar,
    isDisabled: !state?.loginUser?.clientId && !hasUserRole,
    isNavBarPinned,
    togglePinSideBar,
    sideNavs,
    clientTheme
  };
};

export default useSideBar;
