import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  GridAlignment,
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { ReactElement, useContext, useMemo, useState } from "react";

import { ActionLink } from "../../../components/Link/ActionLink/ActionLink";
import { AppContext } from "../../../core/context/appContextProvider";
import useRole from "../../../core/routing/useRole";
import { useTeamRolesEffect } from "../../../services/hooks/useTeamsEffect/useTeamRolesEffect.hooks";
import {
  deleteTeam,
  deleteTeamMember,
  getClientTeams,
} from "../../../services/teams.service.v2";
import { getUsers } from "../../../services/user.service.v2";
import { M_DASH_UNICODE } from "../../../utils/constants/constants";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import noop from "../../../utils/helpers/noop";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { AddNewButtonOptions } from "../../../utils/types/common.type";
import { CustomType, DataGridColDef } from "../../../utils/types/listItems";
import {
  SelectedTeam,
  SelectedTeamMember,
  TeamInfo,
  TeamType,
} from "../../../utils/types/team.type";
import { ScopeRole, User, UserList } from "../../../utils/types/user.type";
import {
  DELETE_TEAM_MEMBER_ERROR,
  DELETE_TEAM_MEMBER_SUCCESS,
} from "./constants";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Team Name",
    hide: false,
    index: 0,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 300,
    flex: 0,
    renderCell: ({ row }: GridRenderEditCellParams): ReactElement => {
      return (
        <ActionLink underline="none" id={`team_name_${row.id}`} onClick={noop}>
          <Typography sx={{ ml: 2 }} variant="body1">
            {row.name}
          </Typography>
        </ActionLink>
      );
    },
  },
  {
    field: "funds",
    headerName: "Funds",
    hide: false,
    index: 1,
    sortable: false,
    type: "chips",
    chipLabelField: "name",
    chipIDField: "id",
    align: "left" as GridAlignment,
    chipCount: 5,
    flex: 1,
  },
];

const defaultChildHeaderList: DataGridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    hide: false,
    index: 0,
    type: "string",
    sortable: true,
    align: "left" as GridAlignment,
    width: 300,
    flex: 1,
    valueGetter: ({ row }) => `${row.firstName || ""} ${row.lastName || ""}`,
    renderCell: ({ row }: GridRenderEditCellParams): ReactElement => {
      return (
        <ActionLink
          underline="none"
          id={`team_member_name_${row.id}`}
          onClick={noop}
        >
          <Typography sx={{ ml: 2 }} variant="body1">
            {`${row.firstName || ""} ${row.lastName || ""}`}
          </Typography>
        </ActionLink>
      );
    },
  },
  {
    field: "role",
    headerName: "Role",
    hide: false,
    index: 1,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    flex: 1,
    valueGetter: ({ row }) => row.teamMembers[0].role,
    renderCell: ({ row }) => row.teamMembers[0].role,
  },
  {
    field: "email",
    headerName: "Email",
    hide: false,
    index: 2,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    flex: 1,
    valueGetter: ({ value }) =>
      `${value !== "null" && value ? value : M_DASH_UNICODE}`,
  },
  {
    field: "phone",
    headerName: "Phone",
    hide: false,
    index: 3,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    flex: 1,
    valueGetter: ({ value }) =>
      `${value !== "null" && value ? value : M_DASH_UNICODE}`,
    renderCell: ({ value }) =>
      `${value !== "null" && value ? value : M_DASH_UNICODE}`,
  },
  {
    field: "location",
    headerName: "Location",
    hide: false,
    index: 4,
    sortable: true,
    type: "string",
    align: "left" as GridAlignment,
    flex: 1,
    valueGetter: ({ value }) =>
      `${value !== "null" && value ? value : M_DASH_UNICODE}`,
    renderCell: ({ value }) =>
      `${value !== "null" && value ? value : M_DASH_UNICODE}`,
  },
];

const initialSelectedTeam: SelectedTeam = {
  team: undefined,
  type: undefined,
};

export const useTeamList = () => {
  const { state, informationAlert } = useContext(AppContext);
  const { clientId } = state.loginUser;
  const [teamsResponse, setTeamsResponse] = useState<TeamInfo[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] =
    useState<SelectedTeam>(initialSelectedTeam);
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<SelectedTeamMember | null>(null);
  const [openTeam, setOpenTeam] = useState<TeamInfo | null | undefined>(null);
  const [addMember, setAddMember] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    useState<boolean>(false);
  const [actionableTeam, setActionableTeam] = useState<TeamInfo | null>(null);
  const [actionableTeamMember, setActionableTeamMember] =
    useState<SelectedTeamMember | null>(null);
  const { teamRoles, loading: loadingRoles } = useTeamRolesEffect();

  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const isEditable: boolean = !!(isSuperAdmin || isClientAdmin);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoading(true);
      const response = await getClientTeams(clientId);

      if (isCanceled()) return;

      setTeamsResponse(response?.items ?? []);
      setLoading(false);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    }
  }, []);

  const goBack = async () => {
    setOpenTeam(null);
    setLoading(true);
    const response = await getClientTeams(clientId);

    setTeamsResponse(response?.items ?? []);
    setLoading(false);
  };

  const handleTeamMember = (action: string, memberId?: string) => {
    if (action === "AddNewMember") {
      setAddMember(true);
    }
  };

  const handleEditTeamMember = (member: SelectedTeamMember) => {
    setSelectedTeamMember(member);
  };

  const handleDeleteMember = (memberId: string) => {
    if (memberId && openTeam?.id) {
      setLoading(true);
      deleteTeamMember(openTeam.id, memberId)
        .then(async () => {
          const response = await getClientTeams(clientId);
          const teams = response.items ?? [];
          const currentTeam = teams.find((item) => item.id === openTeam?.id);

          setOpenTeam(currentTeam);
          informationAlert(DELETE_TEAM_MEMBER_SUCCESS, "success");
          setLoading(false);
          setSelectedTeamMember(null);
          setActionableTeamMember(null);
          setShowDeleteConfirmationBox(false);
        })
        .catch((err) => {
          informationAlert(DELETE_TEAM_MEMBER_ERROR, "error");
          setLoading(false);
        });
    }
  };

  const handleDeleteTeam = (teamId: string) => {
    setLoading(true);
    deleteTeam(teamId)
      .then(async () => {
        const response = await getClientTeams(clientId);
        const teams = response.items ?? [];

        setTeamsResponse(teams);
        informationAlert(DELETE_TEAM_MEMBER_SUCCESS, "success");
        setLoading(false);
        setSelectedTeamMember(null);
        setOpenTeam(null);
        setSelectedTeam(initialSelectedTeam);
        setActionableTeam(null);
        setShowDeleteConfirmationBox(false);
      })
      .catch((err) => {
        informationAlert(DELETE_TEAM_MEMBER_ERROR, "error");
        setLoading(false);
      });
  };

  const handleUpsertTeamButton = (teamId: string) => {
    if (teamId === AddNewButtonOptions.AddNew) {
      setSelectedTeam({
        team: undefined,
        type: TeamType.New,
      });
      return;
    }
    if (teamId !== "") {
      const selectedTeamData = teamsResponse.find((team) => team.id === teamId);

      setSelectedTeam({
        team: {
          id: selectedTeamData?.id || "",
          name: selectedTeamData?.name || "",
          funds: selectedTeamData?.funds || [],
        },
        type: TeamType.Edit,
      });
      return;
    }
  };

  const onTeamDetailClose = () => {
    setSelectedTeam(initialSelectedTeam);
    setSelectedTeamMember(null);
    setAddMember(false);
  };

  const handleOnCellClick = (params: GridCellParams): void => {
    switch (params.field) {
      case "name": {
        if (isEditable) {
          if (openTeam) {
            setSelectedTeamMember(params.row);
          } else {
            setOpenTeam(params.row);
            getUsers().then((users: UserList) => setUsers(users.items));
          }
        } else {
          if (!openTeam) {
            setOpenTeam(params.row);
          }
        }
        break;
      }
      default:
    }
  };

  const handleDeleteMemberAction = (row: any) => {
    setSelectedTeam(initialSelectedTeam);
    setActionableTeamMember(row);
    setShowDeleteConfirmationBox(true);
  };

  const Actions = ({ row }: any): ReactElement => {
    if (row.funds) {
      return (
        <Box>
          <Button
            id={`${row?.id}_edit_button`}
            variant="text"
            disableElevation
            startIcon={<Edit />}
            onClick={() => handleUpsertTeamButton(row.id)}
          >
            Edit
          </Button>
          <Button
            id={`${row?.id}_delete_button`}
            variant="text"
            disableElevation
            startIcon={<Delete />}
            onClick={() => {
              setActionableTeam(row);
              setShowDeleteConfirmationBox(true);
            }}
          >
            Delete
          </Button>
        </Box>
      );
    }
    return (
      <Box>
        <Button
          id={`${row?.id}_edit_member_button`}
          variant="text"
          disableElevation
          startIcon={<Edit />}
          onClick={() => handleEditTeamMember(row)}
        >
          Edit
        </Button>
        <Button
          id={`${row.id}_delete_member_button`}
          variant="text"
          disableElevation
          startIcon={<Delete />}
          onClick={() => handleDeleteMemberAction(row)}
        >
          Delete
        </Button>
      </Box>
    );
  };

  const teamsHeaderList = useMemo(() => {
    let headerList: Array<DataGridColDef> = [];

    if (openTeam) {
      headerList = [...defaultChildHeaderList].map((item) =>
        item.field === "role"
          ? {
              ...item,
              renderCell: ({ row }) =>
                teamRoles.find((role) => role.key === row.teamMembers[0].role)
                  ?.title,
            }
          : item
      );

      if (isEditable) {
        headerList[headerList.length - 1] = {
          ...headerList[headerList.length - 1],
          renderCell: (params: GridRenderCellParams) => {
            const [hover, setHover] = useState<boolean>(false);

            return (
              <Stack
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: 300 }}
              >
                <Typography>{`${
                  params.value !== "null" && params.value
                    ? params.value
                    : M_DASH_UNICODE
                }`}</Typography>
                {hover && <Actions {...params} />}
              </Stack>
            );
          },
        };
      } else {
        headerList = headerList.map((item) => {
          if (item.field === "name") {
            return {
              ...item,
              renderCell: ({ row }: GridRenderEditCellParams): ReactElement => {
                return (
                  <Typography sx={{ ml: 2 }} variant="body1">
                    {`${row.firstName || ""} ${row.lastName || ""}`}
                  </Typography>
                );
              },
            };
          }
          return item;
        });
      }
      return headerList;
    }
    headerList = [...defaultHeaderList];
    if (isEditable) {
      headerList[headerList.length - 1] = {
        ...headerList[headerList.length - 1],
        renderOnHover: (row: any) => <Actions row={row} />,
      };
    }
    return headerList;
  }, [teamsResponse, openTeam?.id, isEditable, teamRoles]);

  const onDeleteConfirm = () => {
    if (actionableTeam?.id) {
      handleDeleteTeam(actionableTeam.id);
    } else if (actionableTeamMember?.id) {
      handleDeleteMember(actionableTeamMember.id);
    }
  };

  const onDeleteCancel = () => {
    setActionableTeam(null);
    setActionableTeamMember(null);
    setShowDeleteConfirmationBox(false);
    setSelectedTeam(initialSelectedTeam);
    setSelectedTeamMember(null);
  };

  return {
    teamsResponse,
    isLoading,
    headerList: teamsHeaderList,
    selectedTeam,
    openTeam,
    addMember,
    selectedTeamMember,
    users,
    showDeleteConfirmationBox,
    isEditable,
    teamRoles,
    loadingRoles,
    setSelectedTeam,
    handleUpsertTeamButton,
    onTeamDetailClose,
    setOpenTeam,
    handleOnCellClick,
    goBack,
    handleTeamMember,
    setTeamsResponse,
    handleDeleteMember,
    handleDeleteMemberAction,
    onDeleteCancel,
    onDeleteConfirm,
  };
};
