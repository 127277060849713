import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Typography } from "@mui/material";
import { GridAlignment, GridCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import { parse } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { StringCell } from "../../../components/DataGrid/DataGrid.styles";
import { AppContext } from "../../../core/context/appContextProvider";
import { downloadFilesAndFolder } from "../../../services/documents.service";
import { usePortalCapitalCallEffect } from "../../../services/hooks/useClientPortal/useCapitalCallEffect.hooks";
import { M_DASH_UNICODE } from "../../../utils/constants/constants";
import downloadFile from "../../../utils/helpers/fileDownloader";
import {
  DateTimeFormat,
  formatDate,
  NegativeCurrencyFormat,
} from "../../../utils/helpers/format.helper";
import { ManipulatedPortalCapitalCalls, PortalCapCallExportActions } from "../../../utils/types/capitalCalls.type";
import { CapitalCallFilter, Quarter } from "../../../utils/types/filter.type";
import { CustomType, DataGridColDef, ImageItem } from "../../../utils/types/listItems";
import { CellBox } from "../../capitalAccounts/capitalAccountList/CapitalAccountList.styles";

const defaultHeaderList: DataGridColDef[] = [
  {
    field: "investorName",
    headerName: "Investor Name",
    hide: false,
    index: 1,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "fundName",
    headerName: "Fund Name",
    hide: false,
    index: 2,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "transactionName",
    headerName: "Capital Call Name",
    hide: false,
    index: 3,
    type: "string",
    align: "left" as GridAlignment,
    width: 300,
  },
  {
    field: "amount",
    headerName: "Amount",
    hide: false,
    index: 4,
    type: "number",
    customType: CustomType.Currency,
    currencyCodeField: "currencyCode",
    align: "right" as GridAlignment,
    width: 300,
  },
  {
    field: "callDate",
    headerName: "Date",
    hide: false,
    index: 5,
    valueGetter: (params) => parse(params.row.callDate, "M-d-yyyy", new Date()),
    renderCell: (params) => {
      const date = params?.row?.callDate ?? "";

      if (!date) {
        return (
          <StringCell variant="body2">
            {M_DASH_UNICODE}
          </StringCell>
        );
      }

      const newDate = formatDate(date);
      const formattedData = DateTimeFormat.shortDate(new Date(newDate));

      return (
        <StringCell variant="body2">
          {formattedData}
        </StringCell>
      );
    },

    align: "right" as GridAlignment,
    width: 150,
  },
  {
    field: "action",
    headerName: "Statement",
    hide: false,
    hideable: false,
    index: 6,
    type: "action",
    customType: CustomType.Action,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    width: 100,
  },
];

export const usePortalCapitalCall = () => {
  const [list, setList] = useState<ManipulatedPortalCapitalCalls[]>();
  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>(defaultHeaderList);
  const [selectionModel, setSelectionModel] = useState<string[]>([]);
  const [selectedCapitalCall, setSelectedCapitalCall] = useState<ManipulatedPortalCapitalCalls | undefined>(undefined);

  const { state } = useContext(AppContext);

  const {
    fileIds,
    selectedQuarter,
    quaterList,
    portalCapitalCall,
    investorList,
    fundList,
    transactionList,
    isLoading,
    downloadCapitalCall,
    fetchPortalCapitalCall,
    fetchPortalCapitalCalls,
  } = usePortalCapitalCallEffect();

  const investors = useMemo(
    () => investorList?.map((item) => item.id),
    [investorList]
  );
  const funds = useMemo(() => fundList?.map((item) => item.id), [fundList]);
  const transactions = useMemo(
    () => transactionList?.map((item) => item.id),
    [transactionList]
  );

  const bulkActions: ImageItem[] = [
    {
      id: PortalCapCallExportActions.AllCapCallReportExcel,
      text: "Capital Calls Report, all (Excel)",
      icon: undefined,
      optionsSelected: 0,
    },
    {
      id: PortalCapCallExportActions.AllCapCallReportPDF,
      text: "Capital Calls Report, all (PDF)",
      icon: undefined,
      optionsSelected: 0,
    }
  ];

  const getSelectedFilters = useCallback(
    (currentHeaderList: DataGridColDef[]) => {
      return currentHeaderList?.reduce(
        (acc: Record<CapitalCallFilter, string[] | undefined>, header) => {
          if (
            header.inlineFilter &&
            header.inlineFilterSelected &&
            header.inlineFilterSelected?.length > 0
          ) {
            return {
              ...acc,
              [header.inlineFilterName ?? ""]: header.inlineFilterSelected?.map(
                (item) => item.toLowerCase()
              ),
            };
          }
          return acc;
        },
        {
          [CapitalCallFilter.InvestorId]: undefined,
          [CapitalCallFilter.FundId]: undefined,
          [CapitalCallFilter.TransId]: undefined,
        }
      );
    },
    []
  );

  useEffect(() => {
    const {
      [CapitalCallFilter.InvestorId]: selectedInvestors,
      [CapitalCallFilter.FundId]: selectedFunds,
      [CapitalCallFilter.TransId]: selectedTrans,
    } = getSelectedFilters(headerList);

    const currentList =
      portalCapitalCall?.filter(
        (data: ManipulatedPortalCapitalCalls) =>
          selectedInvestors?.includes(data.investorId?.toLowerCase() || "") &&
          selectedFunds?.includes(data.fundId?.toLowerCase() || "") &&
          selectedTrans?.includes(data.uid?.toLowerCase() || "")
      ) ?? [];

    setList(currentList);
  }, [portalCapitalCall, getSelectedFilters, headerList]);

  const handleOnView = (
    id: string,
    capitalCall: ManipulatedPortalCapitalCalls
  ) => {
    setSelectedCapitalCall(capitalCall);
  };

  const initializeHeaderList = () => {
    const updatedHeader = defaultHeaderList.map((header, i) => {
      let newHeader: any = header;

      if (header.field === "amount") {
        newHeader = {
          ...newHeader,
          renderCell: ({ row, value }: GridCellParams) => {
            const formatter = NegativeCurrencyFormat(
              row.currencyCode || "USD",
              0
            );

            return formatter.format(value);
          },
        };
      }
      if (header.headerName === "Date") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: "callDate",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: quaterList || [],
          inlineFilterSelected: [],
          singleSelectFilter: true,
        };
      }

      if (header.field === "investorName") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: "investorId",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: investorList,
          inlineFilterSelected: investors,
        };
      }

      if (header.field === "fundName") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: "fundId",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: fundList,
          inlineFilterSelected: funds,
        };
      }

      if (header.field === "transactionName") {
        newHeader = {
          ...newHeader,
          inlineFilter: true,
          inlineFilterName: "uid",
          inlineFilterIDField: "id",
          inlineFilterLabelField: "name",
          inlineFilterOptions: transactionList,
          inlineFilterSelected: transactions,
        };
      }

      if (header.field === "action") {
        newHeader = {
          ...newHeader,
          renderCell: (params: GridRenderCellParams) => {
            if (params?.row?.documentId !== null) {
              return (
                <CellBox>
                  <Button
                    id={`btn_data_grid_${params.row.id}`}
                    variant="text"
                    disableElevation
                    startIcon={<VisibilityIcon />}
                    onClick={() => handleOnView(params.row.id, params.row)}
                    name="View button"
                  >
                    View
                  </Button>
                </CellBox>
              );
            }
            return null;
          },
        };
      }

      return newHeader;
    });

    setHeaderList(updatedHeader);
  };

  useEffect(() => {
    initializeHeaderList();
  }, [quaterList, fundList, portalCapitalCall, investorList]);

  const handleFilter = (filterName: string, selected: string[]) => {
    setHeaderList((prevHeaderList) =>
      prevHeaderList?.map((header) => {
        if (header.inlineFilterName === filterName) {
          return {
            ...header,
            inlineFilterSelected: selected,
          };
        }

        return header;
      })
    );

    if (filterName === "callDate") {
      fetchPortalCapitalCalls(selected[0]);
    }
  };

  const onViewNoticeClose = () => {
    setSelectedCapitalCall(undefined);
  };
  const exportAllCapCallReportExcel = async () => {
    const ids = list?.map((data) => `${data.investorId}--${data.fundId}--${data.uid}`);

    const payload = {
      clientId: state.loginUser.currentUser?.clientId,
      fundId: null,
      fundIds: null,
      fundWithInvestorIds: null,
      ids: ids,
      investorId: null,
      quarter: selectedQuarter,
      transactionCode: null,
      type: "CAPITAL_CALL",
      username: state.loginUser.email,
    };

    const response = await downloadCapitalCall(payload);

    downloadFile(
      response,
      `CAPITAL_CALL_Document_Link_Export_${DateTimeFormat.getFormattedDate(
        new Date()
      )}`,
      "csv"
    );
  };

  const exportAllCapCallReportPDF = async () => {
    const fileIdString: string = fileIds.join(",");

    const fileBlob = await downloadFilesAndFolder('', fileIdString);

    if (fileBlob && fileBlob.type) {
      const extension = fileBlob.type.split("/").reverse()[0];

      downloadFile(fileBlob, `download-${DateTimeFormat.getFormattedDate(new Date())}`, extension);
    }
  };

  const handleBulkActions = (actionId: PortalCapCallExportActions) => {
    switch (actionId) {
      case PortalCapCallExportActions.AllCapCallReportExcel:
        exportAllCapCallReportExcel();
        break;
      case PortalCapCallExportActions.AllCapCallReportPDF:
        exportAllCapCallReportPDF();
        break;
    }
  };

  return {
    headerList,
    quaterList,
    portalCapitalCall,
    list,
    investorList,
    fundList,
    isLoading,
    fetchPortalCapitalCall,
    selectionModel,
    setSelectionModel,
    handleFilter,
    handleOnView,
    onViewNoticeClose,
    selectedCapitalCall,
    bulkActions,
    handleBulkActions
  };
};
