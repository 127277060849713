import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import {
  LegendColorBox,
  LegendTypography,
  SingleLegendBox,
} from './ChartLegend.styles';

interface LegendItem {
  color: string;
  label: string;
}

export const LegendItem: FC<LegendItem> = ({ color, label }) => {
  return (
    <SingleLegendBox>
      <LegendColorBox color={color} />
      <LegendTypography id="legend_name">{label}</LegendTypography>
    </SingleLegendBox>
  );
};

export const getChartLegend = (LegendItems: LegendItem[]): JSX.Element => {
  const legend = (
    <Box
      sx={{
        columnWidth: '188px',
        overflowY: 'auto !important',
      }}
    >
      {LegendItems.map((item, idx) => (
        <LegendItem
          key={`legend_item_${idx}`}
          color={item.color}
          label={item.label}
        />
      ))}
    </Box>
  );

  return legend;
};
