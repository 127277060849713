import { Dispatch, SetStateAction } from "react";

import DataGrid from "../../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import FullScreenModal from "../../../../components/Modal/FullScreenModal/FullScreenModal";
import { GridData, GridRow, ValueFieldDef } from "../../../../components/ReportGrid/ReportGrid.types";
import { useChangesInPartnersCapital } from "./changesInPartnersCapital.hooks";
import { ChangesInPartnersCapitalDetails } from "./financialStatementsDetails/changesInPartnersCapitalDetails";

interface Props {
    params: any,
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
    setStatementOfChangeData: (statementOfChangeData: boolean) => void,
    editModeActivated: boolean,
    roundedReportActivated: boolean,
    suppressIfZeroActivated: boolean,
    currentCurrency: string,
    setFsPackageFlag: (fsPackageFlag: boolean) => void,
    setFsSaveFieldOrder: Dispatch<SetStateAction<any[]>>,
    fsUpdateColumnOrder: any,
    fsColumnBuilder: boolean,
}

export const ChangesInPartnersCapital: React.FC<Props> = ({
    params,
    isLoading,
    setIsLoading,
    setStatementOfChangeData,
    editModeActivated,
    roundedReportActivated,
    suppressIfZeroActivated,
    currentCurrency,
    setFsPackageFlag,
    setFsSaveFieldOrder,
    fsUpdateColumnOrder,
    fsColumnBuilder,
}) => {

    const {
        activeHeaderFields,
        apiResponse,
        cellInfo,
        currencyCode,
        detailsOpen,
        gridData,
        headerList,
        handleCellClick,
        handleCloseDetails,
        handleColumnOrderChange,
        handleUpdateHeaderVisibility,
        editTransactionName,
        confirmEditTransaction,
        cancelEditTransaction,
        transactionNameChangeOpen,
        transactionDetails,
    } = useChangesInPartnersCapital (
        params,
        setStatementOfChangeData,
        setIsLoading,
        editModeActivated,
        roundedReportActivated,
        suppressIfZeroActivated,
        currentCurrency,
        setFsPackageFlag,
        setFsSaveFieldOrder,
        fsUpdateColumnOrder,
        fsColumnBuilder,
    );

    return (
        <>
            <DataGrid
                id={'changes_in_partners_capital_grid_id'}
                dataList={gridData ?? []}
                headerList={headerList ?? []}
                noDataMessage={isLoading ? "" : 'No Data'}
                minHeight={'1vh'}
                autoHeight={false}
                checkboxSelection={false}
                onColumnOrderChange={handleColumnOrderChange}
                handleUpdateHeader={handleUpdateHeaderVisibility}
                isCellEditable={(params: any) => params.row.id !== 'Starting Balance' && params.row.id !== 'Ending Balance'}
                onCellEditCommit={(params: any) => {editTransactionName(params);}}
                onCellClick={(params: any) => {handleCellClick(params);}}
            />
            <ChangesInPartnersCapitalDetails
                open={detailsOpen}
                onClose={handleCloseDetails}
                cellInfo={cellInfo}
                transacitonDetails={transactionDetails}
                apiResponse={apiResponse}
                roundedReportActivated={roundedReportActivated}
                currencyCode={currencyCode}
                params={params}
            />
            <FullScreenModal
                id='transaction_name_change_id'
                open={transactionNameChangeOpen}
                title={'Update Transaction Name'}
                subtitle={'Changing this transaction name will update the name in both Financial Statements and the Client Configuration Transaction Mapping. This will change historical transaction names across the client. If maintaining historical transaction names is important, create a new transaction type instead.'}
                confirmButtonText={'Proceed'}
                cancelButtonText={'Cancel'}
                onSuccess={confirmEditTransaction}
                onCancel={cancelEditTransaction}
            />
        </>
    );
};