import { Cached } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { ReactElement } from "react";

import { DataWrapperBox } from "../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import DataGrid from "../../components/DataGrid/DataGrid";
import { Action } from "../../components/Modal/DeleteConfirmationDialog/constants";
import DeleteConfirmationDialog from "../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../components/SearchBarWithDebounce/SearchBar";
import { FilterBox,HeaderRow } from "./UploadHistory.styles";
import { useUploadHistory } from "./useUploadHistory.hooks";

const UploadHistory: React.FC = (): ReactElement => {
  const {
    headerList,
    list,
    activeHeaderFields,
    handleUpdateHeader,
    search,
    onSearch,
    loading,
    handleFilter,
    toBeRolledBack,
    onRollbackConfirm,
    onRollbackCancel,
    fetchHistory
  } = useUploadHistory();

  return (
    <DataWrapperBox
      id="upload_history_list_main">
      <Progress
        id="upload_history_list_loading"
        showProgress={loading}
      />
      <HeaderRow>
        <Typography variant="pageTitle">Upload History</Typography>
        <FilterBox>
          <SearchBar id="upload-history-search" onChange={onSearch} value={search} />
          <IconButton
            aria-label="Refresh List"
            color="inherit"
            title="Refresh List"
            id="refresh-list"
            onClick={() => fetchHistory()}
          >
            <Cached/>
          </IconButton>
        </FilterBox>
      </HeaderRow>
      <DataGrid
        id="upload_history_data_grid"
        dataList={list || []}
        noDataMessage={loading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        autoHeight={false}
        activeHeaderFields={activeHeaderFields}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
      />
      <DeleteConfirmationDialog
        open={Boolean(toBeRolledBack)}
        id="rollback-confirmation-modal"
        primaryButtonAction={onRollbackConfirm}
        actionType={Action.ROLLBACK}
        title="Are you sure you want to Rollback?"
        content="This action is permanent and cannot be undone."
        primaryButtonLabel="Confirm"
        secondaryButtonAction={onRollbackCancel} />
    </DataWrapperBox>
  );
};

export default React.memo(UploadHistory);
