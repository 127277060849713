import React, { useContext, useState } from "react";

import { AppContext } from "../../../../core/context/appContextProvider";
import { TeamMembers } from "../../../../utils/types/capitalCalls.type";
import { REVIEW_VALIDATION_ERROR, ReviewersList } from "../constants";
type Props = {
  value?: { name?: string }[] | null;
  onChange: (value?: { name?: string }[] | null) => void;
  teamMembers: TeamMembers;
  errorMessage: any;
  setErrorMessage: (value: any) => void;
};
export const useReviewers = ({
  value,
  onChange,
  teamMembers,
  errorMessage,
  setErrorMessage,
}: Props) => {
  const { state } = useContext(AppContext);

  const isChecked = (reviewer: string) =>
    Boolean(
      value?.find(
        (item) => item?.name?.toLowerCase() === reviewer?.toLowerCase()
      )
    );

  const handleChange = (reviewer: string) => {
    const currentValue = [...(value || [])];
    const currentIndex = currentValue?.findIndex(
      (item) => item?.name?.toLowerCase() === reviewer?.toLowerCase()
    );

    if (currentIndex === -1) {
      onChange([
        ...currentValue,
        {
          name: reviewer,
        },
      ]);
      handleValidateReviewer(reviewer);
    } else {
      currentValue.splice(currentIndex, 1);
      onChange([...currentValue]);
      setErrorMessage(changeErrorMessage(reviewer, false));
    }
  };

  const handleValidateReviewer = (reviewer: string) => {
    if (teamMembers[reviewer.trim() as keyof TeamMembers] === undefined) {
      setErrorMessage(changeErrorMessage(reviewer, true));
    } else {
      const filteredTeamMembers =
        teamMembers[reviewer.trim() as keyof TeamMembers]?.filter(
          (user) =>
            user?.toLowerCase() !==
            state.loginUser.currentUser?.username?.toLowerCase()
        ) || [];

      if (filteredTeamMembers.length === 0) {
        setErrorMessage(changeErrorMessage(reviewer, true));
      } else {
        setErrorMessage(changeErrorMessage(reviewer, false));
      }
    }
  };

  const selectReviewer = (member: string) => {
    switch (member) {
      case ReviewersList.Associate:
        return "Associate";
      case ReviewersList.Director:
        return "Director";
      case ReviewersList.FundController:
        return "Fund Controller";
      case ReviewersList.FundUserReadOnly:
        return "Fund User Read Only";
      case ReviewersList.Manager:
        return "Manager";
      default:
        return "";
    }
  };

  const changeErrorMessage = (reviewer: string, checked: boolean) => {
    switch (reviewer) {
      case ReviewersList.Associate:
        return checked
          ? {
              ...errorMessage,
              ASSOCIATE: REVIEW_VALIDATION_ERROR,
            }
          : { ...errorMessage, ASSOCIATE: "" };
      case ReviewersList.Director:
        return checked
          ? {
              ...errorMessage,
              DIRECTOR: REVIEW_VALIDATION_ERROR,
            }
          : { ...errorMessage, DIRECTOR: "" };
      case ReviewersList.FundController:
        return checked
          ? {
              ...errorMessage,
              FUND_CONTROLLER: REVIEW_VALIDATION_ERROR,
            }
          : { ...errorMessage, FUND_CONTROLLER: "" };
      case ReviewersList.FundUserReadOnly:
        return checked
          ? {
              ...errorMessage,
              FUND_USER_READ_ONLY: REVIEW_VALIDATION_ERROR,
            }
          : { ...errorMessage, FUND_USER_READ_ONLY: "" };
      case ReviewersList.Manager:
        return checked
          ? {
              ...errorMessage,
              MANAGER: REVIEW_VALIDATION_ERROR,
            }
          : { ...errorMessage, MANAGER: "" };
      default:
        return { ...errorMessage };
    }
  };

  return {
    isChecked,
    selectReviewer,
    handleChange,
    errorMessage,
    handleValidateReviewer,
  };
};
