import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { FundSimple } from "../../../utils/types/fund.type";
import { getFundsFilterList } from "../../filters.service";

export const useFundFilterEffect = () => {
  const [fundList, setFundList] = useState<FundSimple[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    setLoading(true);
    try {
      if (isCanceled()) return;

      const statusResponse = await getFundsFilterList();

      setFundList(statusResponse);
    } catch (e) {
      informationAlert("Error in getting fund list", "error");
    }
    setLoading(false);
  }, []);

  return {
    fundList,
    loading,
    setFundList
  };
};
