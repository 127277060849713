import React from "react";

import InformationAlert from "../../../../components/InformationAlert/InformationAlert";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import { SaveExitModal } from "../../../../components/Modal/SaveExitModal/SaveExitModal";
import ProgressModal from "../../../../components/Progress/ProgressModal/ProgressModal";
import { DateTimeFormat } from "../../../../utils/helpers/format.helper";
import noop from "../../../../utils/helpers/noop";
import { SheetTypes } from "../workbook.type";
import { SaveAsPrompt } from "./saveAsPromptDialog/SaveAsPrompt";
import { AlloocationsSheet } from "./sheets/allocations/AllocationsSheet";
import { AllocationsSheetManager } from "./sheets/allocations/AllocationsSheet.Manager";
import { useWorkbook } from "./Workbook.hooks";
import { WorkbookBaseComponent } from "./WorkbookBase.Component";

export const Workbook: React.FC = () => {
  const { 
    activeSheetManager,
    workbookHasChanges,
    fundId,
    spreadsheetUrl,
    treeGridLibraryLoaded,
    exportFilename,
    showModelSaveAs,
    showReadonlyMessageDialog, setShowReadonlyMessageDialog,
    workbookLockStatus,
    isSuperAdmin,
    isArkClientAdmin,
    blockUnlockPrivileges,
    loadingMessage,
    savedInfonMsg, handleCloseSavedInfo,
    handleNavigateAway,
    handlePreloadSheet,
    handleSheetLoaded,
    handleSaveClicked,
    handleSaveAsClicked,
    handleSave,
    handleSaveAs,
    handleSaveAsPromptClose,
    handleToolbarButtonClick,
    handleStartEdit,
    handleEndEdit,
    handleColsAdd,
    handleRowAdd,
    handleCanColDelete,
    handleCanRowDelete,
    handleCanPaste,
    handleSetWorkbookreadonly,
    forceSuperAdminUnLock
  } = useWorkbook();

  return (
    <>
      {
        spreadsheetUrl && treeGridLibraryLoaded  &&
          <WorkbookBaseComponent
            spreadsheetUrl={spreadsheetUrl}
            exportFilename={exportFilename}
            onPreloadSheet={handlePreloadSheet}
            onSheetLoaded={handleSheetLoaded}
            onSaveClicked={handleSaveClicked}
            onSaveAsClicked={handleSaveAsClicked}
            onSave={handleSave}
            onSaveAs={handleSaveAs}
            onToolbarButtonClick={handleToolbarButtonClick}
            onStartEdit={handleStartEdit}
            onEndEdit={handleEndEdit}
            onColsAdd={handleColsAdd}
            onRowAdd={handleRowAdd}
            onCanColDelete={handleCanColDelete}
            onCanRowDelete={handleCanRowDelete}
            onCanPaste={handleCanPaste}
          /> 
      }      
      {
        showModelSaveAs &&
          <SaveAsPrompt
            fundId={fundId}
            onClose={handleSaveAsPromptClose}
          />
      }
      {
        !!loadingMessage && 
          <ProgressModal
            id="progress_message"
            showProgress={!!loadingMessage}
            text={loadingMessage}
          />
      }
      {
        spreadsheetUrl && activeSheetManager?.sheetType === SheetTypes.Allocations && 
          <AlloocationsSheet
            allocationsSheetManager={activeSheetManager as AllocationsSheetManager}
          />
      }

      <SaveExitModal
        isBlocked={workbookHasChanges}
        title="Leave Page?"
        content="Your changes have not been saved. Are you sure you want to leave this workbook?"
        actionTitle="Save Workbook"
        confirmNavigationTitle="Exit Without Saving"
        onActionClick={handleSaveClicked}
        onNavigateAway={handleNavigateAway}
        onCancelClick={noop}
      />

      <ConfirmationDialog
        open={showReadonlyMessageDialog}
        onClose={() => { setShowReadonlyMessageDialog(false); }}
        id="modal_readonly_message"
        actions={[
          {
            label: 'Open read only',
            onClick: handleSetWorkbookreadonly,
            id: 'ok_button',
            variant: 'contained',
            color: 'primary'
          },
          {
            label: 'Force unlock',
            onClick: forceSuperAdminUnLock,
            id: 'ok_button',
            variant: 'contained',
            color: 'primary',
            hide: blockUnlockPrivileges
          }
        ]}
        content={ 
          <> 
            This workbook is locked by { workbookLockStatus?.lockedBy } <br/>
            on { workbookLockStatus?.lockedAt ? DateTimeFormat.longDateTime(workbookLockStatus?.lockedAt) : null } 

            {
              isSuperAdmin || isArkClientAdmin && (
                <>
                  <br/><br/>
                  As an admin, you can override this and lock this workbook under your username.  Once you exit this workbook, the lock will be cleared as usual.
                </>
              )
            }
          </> 
        }
        title="Workbook is read only"
      />

      <InformationAlert
        id={"allocartions_saved_alert"}
        text={savedInfonMsg.text}
        open={savedInfonMsg.open}
        severity={savedInfonMsg.severity}
        title={savedInfonMsg.title}
        actionTitle={savedInfonMsg.actionTitle}
        handleClose={handleCloseSavedInfo}
        action={savedInfonMsg.action}
      />
    </>
  );
};