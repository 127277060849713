import NumberFormat from 'react-number-format';

const DecimalFormatCustom = (props: any) => {
  const {
    inputRef,
    onChange,
    decimalPlaces = 2,
    fixedDecimalScale = false,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange?.({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={decimalPlaces}
      fixedDecimalScale={fixedDecimalScale}
      thousandSeparator
      isNumericString
    />
  );
};

export default DecimalFormatCustom;
