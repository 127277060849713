import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { Controller } from 'react-hook-form';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import { DeleteButton } from '../../../../components/Buttons/DeleteButton/DeleteButton.styles';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  StyledBackdrop,
  Typography,
} from '../../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import ArkTransactionFilter from '../../../../components/Selectors/ArkTransactionFilter/ArkTransactionFilter';
import FSMappingFilter from '../../../../components/Selectors/FSMappingFilter/FSMappingFilter';
import ParentAccountFilter from '../../../../components/Selectors/ParentAccountFilter/ParentAccountFilter';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';
import NumberFormatCustom from '../../../../components/TextFieldNumeric/NumberFormat';
import { yesNoOptions } from '../../../../utils/constants/form.constants';
import {
  AccountStatusTypes,
  SelectedAccount,
} from '../../../../utils/types/arkGLAccount.type';
import { DetailsType } from '../../../../utils/types/form.type';
import { cashFlowAttributeOptions } from '../accountList/AccountList.constants';
import { InfoLabelNumber } from './AccountDetails.constants';
import { useAccountDetails } from './AccountDetails.hooks';
import {
  CustomWidthTooltip,
  SelectFormControl,
  SubSectionTitle,
} from './AccountDetails.styles';

interface Props {
  onDetailClose: Function;
  selectedAccount: SelectedAccount;
  fetchAllAccounts: Function;
  fundId: string;
}

type Mapping = Record<AccountStatusTypes, string>;

const StatusType: Mapping = {
  draft: 'yellow',
  posted: 'green',
  unused: 'red',
};

export const AccountDetails: React.FC<Props> = ({
  onDetailClose,
  selectedAccount,
  fetchAllAccounts,
  fundId,
}: Props) => {
  const { type, account } = selectedAccount;
  const isNewAccount = type === DetailsType.New;

  const {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    isValid,
    control,
    isSubmitted,
    trigger,
    createUpdateAccount,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    handleFSNameCheck,
    handleFSNameChange,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    showFSNameConfirmation,
    toggleDrawer,
    isSubAccount,
    setIsSubAccount,
    accountList,
    arkTransactionList,
    attributeList,
    status,
    fsMappingId,
    fsDisplayName,
    parentId,
    accountName,
    accountNumber,
    doNotMap,
    isFocused,
    handleBlur,
    handleFocus,
    validateUniqueFSName,
    cashFlowMappingSet,
    cashFlowNameSet,
    currentTab,
    handleTabChange,
    generalLedgerRef,
    detailsRef,
    isStandishManagementBasicAdmin,
  } = useAccountDetails({
    account,
    onClose: onDetailClose,
    fetchAllAccounts,
    isNewAccount,
    fundId,
  });

  return (
    <>
      <DetailPanel
        id="detail_panel_account"
        title={isNewAccount ? 'Add Account' : account?.name}
        open={Boolean(type)}
        variant={'temporary'}
        onClose={toggleDrawer}
        hasTabsPanel={true}
        tabsPanelValue={currentTab}
        tabsPanelHandleChange={handleTabChange}
        tabsPanelOptions={['Details', 'General Ledger Reporting']}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(handleFSNameCheck)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel">
              <Grid container spacing={2}>
                <Grid item xs={12} mt={-2} mb={-4} ref={detailsRef}></Grid>
                <Grid item xs={8}>
                  <Controller
                    name="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_account_name"
                          label="Account Name"
                          aria-describedby="name"
                          placeholder="Account Name"
                          variant="outlined"
                          fullWidth
                          disabled={
                            account?.state === 'POSTED' ||
                            isStandishManagementBasicAdmin
                          }
                          size="small"
                          value={value ?? ''}
                          onChange={(event) => {
                            const name = event.target.value;

                            onChange(event);
                            if (
                              name &&
                              fsMappingId ===
                              '00000000-0000-0000-0000-000000000000'
                            ) {
                              setValue('fsDisplayName', name);
                            }
                          }}
                          InputLabelProps={
                            !isNewAccount ? { shrink: !!value } : {}
                          }
                          error={!!error}
                          helperText={error?.message ?? ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        const input = value.trim().length;

                        if (!input) {
                          return 'Account Name is required';
                        } else if (input < 3 || input > 128) {
                          return 'Name must be between 3 and 128 characters';
                        }
                        return true;
                      },
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="number"
                    rules={{
                      required: 'Account Number is required',
                      minLength: {
                        value: 3,
                        message: 'Account number must be at least 3 digits',
                      },
                      validate: (value: number | null) => {
                        if (value === null) {
                          return 'Account number is required';
                        }
                        const valueString = value.toString();

                        if (!/^\d+$/.test(valueString)) {
                          return 'Account number must contain only digits';
                        }
                        if (valueString.length > 10) {
                          return 'Account number must not exceed 10 digits';
                        }
                        if (valueString.startsWith('0')) {
                          return 'Account number cannot start with 0';
                        }
                        return true;
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_account_number"
                          label={
                            <div>
                              Number
                              {isFocused && (
                                <CustomWidthTooltip title={InfoLabelNumber}>
                                  <IconButton size="small">
                                    <InfoIcon
                                      fontSize="small"
                                      color="primary"
                                    />
                                  </IconButton>
                                </CustomWidthTooltip>
                              )}
                            </div>
                          }
                          aria-describedby="account_number"
                          placeholder="Account Number"
                          size="small"
                          value={value ?? ''}
                          fullWidth
                          variant="outlined"
                          disabled={
                            account?.state === 'POSTED' ||
                            isStandishManagementBasicAdmin
                          }
                          onChange={(e) => onChange(e.target.value)}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          inputProps={{
                            thousandSeparator: false,
                            decimalScale: 0,
                            allowNegative: false,
                            allowLeadingZeros: false,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          InputLabelProps={
                            !isNewAccount ? { shrink: !!value } : {}
                          }
                          error={!!error}
                          helperText={error?.message ?? ''}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="attributeId"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectFormControl>
                          <InputLabel id="label_attribute" error={!!error}>
                            Account Attribute
                          </InputLabel>
                          <Select
                            id="select_attribute"
                            labelId="label_attribute"
                            label="Account Attribute"
                            size="small"
                            disabled={
                              account?.state === 'POSTED' ||
                              isStandishManagementBasicAdmin
                            }
                            value={value ? value : ''}
                            onChange={(e) => onChange(e.target.value)}
                            error={!!error}
                          >
                            {attributeList.map((attribute: any) => (
                              <MenuItem key={attribute.id} value={attribute.id}>
                                {attribute.type} - {attribute.detail}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error>
                            {error?.message ?? ''}
                          </FormHelperText>
                        </SelectFormControl>
                      );
                    }}
                    rules={{
                      required: 'Account Attribute is required',
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4} mt={1}>
                  {status && (
                    <StatusLabel
                      color={
                        StatusType[
                        status?.toLowerCase() as keyof typeof StatusType
                        ]
                      }
                      label={status}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="txt_account_description"
                    label="Description"
                    aria-describedby="description"
                    placeholder="Description"
                    size="small"
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    error={!!errors.description}
                    disabled={account?.state === 'POSTED'}
                    {...register('description', {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    label="Is sub-account"
                    control={
                      <Checkbox
                        id="check_is_subaccount"
                        checked={isSubAccount}
                        value={isSubAccount}
                        disabled={
                          account?.state === 'POSTED' ||
                          isStandishManagementBasicAdmin
                        }
                        onChange={(e) => setIsSubAccount(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={8}>
                  {isSubAccount && (
                    <Controller
                      name="parentId"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <ParentAccountFilter
                          size="small"
                          id={'parent_account_filter'}
                          idSelectOnly
                          value={value}
                          disabled={
                            account?.state === 'POSTED' ||
                            isStandishManagementBasicAdmin
                          }
                          accountId={account?.id}
                          onChange={(parentId) => {
                            onChange(parentId);

                            const parentAccount = accountList.find(
                              (acc) => acc.id === parentId
                            );

                            if (parentAccount && parentAccount.fsMappingId) {
                              setValue(
                                'fsMappingId',
                                parentAccount.fsMappingId
                              );
                              setValue(
                                'fsDisplayName',
                                parentAccount.fsDisplayName
                              );
                            }
                          }}
                          error={error}
                          placeholder="Parent Account"
                        />
                      )}
                      control={control}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="isTaxable"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          id="select_is_taxable"
                          noGutter
                          idField="id"
                          labelField="label"
                          label="Taxable Account? (Y/N)"
                          optionList={yesNoOptions}
                          value={value === false ? 'false' : 'true'}
                          disabled={isStandishManagementBasicAdmin}
                          handleOptionSelection={(e: SelectChangeEvent) => {
                            onChange({
                              target: {
                                value: e.target.value === 'true' ? true : false,
                              },
                            });
                          }}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="isEntityRequired"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelect
                          id="select_is_entity_required"
                          noGutter
                          idField="id"
                          labelField="label"
                          label="Entity Required? (Y/N)"
                          optionList={yesNoOptions}
                          value={value === false ? 'false' : 'true'}
                          disabled={isStandishManagementBasicAdmin}
                          handleOptionSelection={(e: SelectChangeEvent) => {
                            onChange({
                              target: {
                                value: e.target.value === 'true' ? true : false,
                              },
                            });
                          }}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} mt={2} mb={-4}>
                  <Divider
                    variant="middle"
                    sx={{ marginBottom: 2, marginTop: -2 }}
                  />
                  <Typography variant="h4" ref={generalLedgerRef}>
                    General Ledger Reporting
                  </Typography>
                  <SubSectionTitle variant="h5">
                    Financial Statement Reporting
                  </SubSectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="fsMappingId"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <FSMappingFilter
                          size="small"
                          id={'fs_mapping_filter'}
                          idSelectOnly
                          doNotMap={doNotMap}
                          fundId={fundId}
                          isNewAccount={isNewAccount}
                          accountName={accountName}
                          accountNumber={accountNumber}
                          value={value}
                          onChange={(id) => {
                            onChange(id);

                            if (id === '1') {
                              setValue('doNotMap', true);
                              setValue('fsDisplayName', null);
                            } else {
                              setValue('doNotMap', false);

                              const populateFsDisplayName = () => {
                                if (
                                  id === '00000000-0000-0000-0000-000000000000'
                                )
                                  return accountName;
                                const existingFsMap = accountList?.find(
                                  (fs) => fs.fsMappingId === id
                                );

                                if (existingFsMap)
                                  return existingFsMap.fsDisplayName;
                                return accountList?.find((fs) => fs.id === id)
                                  .name;
                              };

                              setValue(
                                'fsDisplayName',
                                populateFsDisplayName(),
                                {
                                  shouldValidate: isSubmitted,
                                }
                              );
                              trigger();
                            }
                          }}
                          error={error}
                          placeholder="Financial Statement Mapping"
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  {fsMappingId && fsMappingId !== '1' && (
                    <Controller
                      name="fsDisplayName"
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          id="txt_fs_display_name"
                          size="small"
                          fullWidth
                          value={value === '' ? null : value}
                          aria-describedby="fs_display_name"
                          label="FS Display Name"
                          placeholder="FS Display Name"
                          variant="outlined"
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          error={!!error}
                          helperText={error?.message ?? ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      rules={{
                        required:
                          fsMappingId &&
                          accountName &&
                          fsMappingId !== '1' &&
                          'FS Display Name is required when an FS Mapping is selected. Please enter an FS Display Name',
                        validate: () => {
                          if (validateUniqueFSName()) {
                            return 'Please enter a unique FS Display Name.';
                          }
                        },
                      }}
                      control={control}
                    />
                  )}
                </Grid>
                <Grid item xs={12} mt={2} mb={-4}>
                  <SubSectionTitle variant="h5">
                    Cash Flow Reporting
                  </SubSectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="cashFlowMapping"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SingleSelect
                          id={'cash_flow_mapping_selector'}
                          noGutter={true}
                          labelField="label"
                          label="Cash Flow Mapping"
                          optionList={cashFlowAttributeOptions}
                          value={value ?? ''}
                          fullWidth={true}
                          error={!!error}
                          helperText={error?.message ?? ''}
                          handleOptionSelection={(e: SelectChangeEvent) => {
                            onChange({
                              target: {
                                value: e.target.value,
                              },
                            });
                          }}
                        />
                      );
                    }}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value && cashFlowNameSet) {
                          return 'Cash Flow Attribute required if Cash Flow Name is set.';
                        }
                        return true;
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="txt_cash_flow_name"
                    label="Cash Flow Name"
                    aria-describedby="cashFlowName"
                    placeholder="Cash Flow Name"
                    size="small"
                    fullWidth
                    variant="outlined"
                    error={!!errors.cashFlowName}
                    helperText={
                      errors.cashFlowName ? errors.cashFlowName.message : ''
                    }
                    {...register('cashFlowName', {
                      validate: (value) => {
                        if (!value && cashFlowMappingSet) {
                          return 'Cash Flow Name required if Cash Flow Attribute is set.';
                        }
                        return true;
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} mt={2} mb={-4}>
                  <SubSectionTitle variant="h5">
                    Investor Reporting
                  </SubSectionTitle>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="transactionTypeId"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <ArkTransactionFilter
                          size="small"
                          id={'ark_transaction_filter'}
                          idSelectOnly
                          value={value}
                          onChange={(id) => {
                            onChange(id);

                            if (id === '1') {
                              setValue('transactionTypeName', 'Do Not Map');
                            } else {
                              const arkTransaction = arkTransactionList.find(
                                (type) => type.id === id
                              );

                              setValue(
                                'transactionTypeName',
                                arkTransaction.name
                              );
                            }
                          }}
                          error={error}
                          placeholder="Ark Transaction"
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Panel>
            {
              !isNewAccount &&
              account?.state !== 'POSTED' &&
              !isStandishManagementBasicAdmin && (
                <Panel id="delete_panel">
                  <DeleteButton
                    id="btn_delete_account"
                    variant="text"
                    disableElevation
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={handleDelete}
                    name="Delete Account"
                  >
                    Delete Account
                  </DeleteButton>
                </Panel>
              )
            }
          </FormBox >
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'btn_account_cancel'}
              variant="outlined"
              onClick={toggleDrawer}
              text={'Cancel'}
              color={'secondary'}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={'btn_account_save'}
              variant="contained"
              text={'Save & Close'}
              color={'primary'}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
              disabled={Boolean(
                fsMappingId && fsMappingId !== '1' && fsDisplayName === ''
              )}
            />
          </HorizontalBox>
        </form >
        <StyledBackdrop open={Boolean(isLoading)} />
        <ProgressPanel
          id={'progress_account_panel'}
          showProgress={Boolean(isLoading)}
          text={isLoading ? `${isLoading} account...` : ''}
        />
      </DetailPanel >
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete_portco_confirmation"
        actions={[
          {
            label: 'Delete Permanently',
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: 'btn_confirm_delete_account',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: handleCancelDelete,
            id: 'btn_cancel_delete',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Are you sure you want to delete this account? This action cannot be undone."
        title="Delete account?"
      />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_account_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_account_save',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_account_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
      <ConfirmationDialog
        open={showFSNameConfirmation}
        onClose={keepDrawerOpen}
        id="fs_name_change_confirmation"
        actions={[
          {
            label: 'Continue',
            onClick: handleFSNameChange,
            id: 'btn_save_fs_name_change',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: keepDrawerOpen,
            id: 'btn_account_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Updating the FS Display Name will apply the new name to all accounts associated with the chosen FS Mapping."
        title="FS Display Name Change"
      />
    </>
  );
};
