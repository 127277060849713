import { ReactElement } from "react";

import { StyledIcon } from "./ImgIcon.styles";

type Props = {
  icon: string;
  id?: string;
  className?: string;
  useCursorPointer?: boolean;
  onClick?: any;
  hide?: boolean;
};

const ImgIcon = (props: Props): ReactElement => {
  const {
    icon, id = "image_icon", className, useCursorPointer = false, onClick, hide = false,
  } = props;

  return (
    <StyledIcon
      usecursorpointer={useCursorPointer?.toString() || "false"}
      hide={hide?.toString()}>
      <img
        id={id}
        alt="edit"
        src={icon}
        className={className}
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
      />
    </StyledIcon>
  );
};

export default ImgIcon;
