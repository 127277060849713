import { Box, Breadcrumbs, styled, Typography } from '@mui/material';

export const DocumentsBreadcrumbs = styled(Breadcrumbs)(() => ({
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '24px',
}));

export const DocumentsLastBreadcrumbsTypography = styled(Typography)(() => ({
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
}));

export const InfoIconBox = styled(Box)(() => ({
  marginBottom: '-2px',
}));
