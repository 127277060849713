import { cloneDeep } from "lodash";

import { StringConstants, TransactionColumn, TransactionColumnType } from "../../../../workbook.type";
import { BaseColumnsManager } from "./BaseColumns.Manager";

export abstract class BaseTransactionsColumnsManager extends BaseColumnsManager {
    
    public buildNewTransactionColumns(baseTransactionColumns: TransactionColumn[], quarter: number) {
        const newTransactionColumns = cloneDeep(baseTransactionColumns);

        newTransactionColumns.splice(0, 0, {
            index: -1,
            label: StringConstants.beginingBalanceStr,
            colType: TransactionColumnType.BEGINING_BALANCE,
            gridColId: '',
            isUserColumn: false,
            allocationCode: undefined,
            metricSign: 0,
            useArkTransactionValues: false,
            locked: true,
            quarterNumber: quarter,
            transSubmitError: false,
            transTypeId: ''
        });

        newTransactionColumns.push({
            index: newTransactionColumns.length,
            label: StringConstants.endingBalanceStr,
            colType: TransactionColumnType.ENDING_BALANCE,
            gridColId: '',
            isUserColumn: false,
            allocationCode: undefined,
            metricSign: 0,
            useArkTransactionValues: false,
            locked: true,
            quarterNumber: quarter,
            transSubmitError: false,
            transTypeId: ''
        });

        newTransactionColumns.sort((a, b) => a.index - b.index).forEach((col, index) => {            
            col.index = index+1;
        });

        return newTransactionColumns;
    }
}