import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Box, keyframes, styled, Tooltip, TooltipProps, Typography } from '@mui/material';

const shake = keyframes`
0%, 100% { transform: translateX(0); }
25% { transform: translateX(-5px); }
50% { transform: translateX(5px); }
75% { transform: translateX(-5px); }
`;

export const ButtonBox = styled(Box)<{}>(({ theme }) => ({

}));

export const InlineEditsToolbarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'highlighted',
})<{
  highlighted?: boolean;
}>(({ theme, highlighted }) => ({
  border: highlighted ? '3px solid #F2B203' : 'none',
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  marginRight: '0px',
  padding: '4px',
  gap: '16px',
  flexWrap: 'nowrap',
}));

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className, arrow: className }} arrow />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid gray',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    animation: `${shake} 0.5s ease-in-out`,
  },
  [`& .MuiTooltip-arrow`]: {
    color: 'white',
    '&::before': {
      border: '1px solid gray',
    },
  },
});

export const NowrapTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  color: 'GrayText',
  marginLeft: '4px',
}));

export const TooltipContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ErrorIcon = styled(ErrorOutlinedIcon)(() => ({
  color: '#F2B203 !important',
}));