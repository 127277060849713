import { Close } from "@mui/icons-material";
import { Chip, styled } from "@mui/material";

import { CHIP_BLUE, CHIP_GREEN, SUBTITLE } from "../../styles/colors";

export const StyledChip = styled(Chip)<{
  chipcolor: string;
}>`
  margin: 5px !important;
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => {
    switch (props.chipcolor) {
      case "green":
        return CHIP_GREEN;
      case "blue":
        return CHIP_BLUE;
    }
  }};
`;

export const DeleteIcon = styled(Close)`
  color: ${SUBTITLE} !important;
  font-size: 13px !important;
`;
