import IconDownload from "../../assets/images/icons/icon_download.svg";
import IconNewCircle from "../../assets/images/icons/icon_new_circle.svg";
import IconUpload from "../../assets/images/icons/icon_upload.svg";
import { ButtonOptions } from "../../components/ButtonWithOptions/ButtonWithOptions";
import { AddNewButtonOptions } from "../types/common.type";

export const EXPORT_FUND_ERROR = "Error in exporting funds.";
export const DELETE_FUND_ERROR = "Error in deleting funds.";
export const DELETE_FUND_SUCCESS = "Funds deleted successfully.";

export const NewFundOptions: ButtonOptions[] = [{
  label: 'New Fund',
  id: AddNewButtonOptions.AddNew,
  icon: IconNewCircle,
},
{
  label: 'Upload From Template',
  id: AddNewButtonOptions.UploadFromTemplate,
  icon: IconUpload,
  type: 'upload'
},
{
  label: 'Download Template',
  id: AddNewButtonOptions.DownloadTemplate,
  icon: IconDownload,
}];
