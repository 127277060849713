import AddIcon from '@mui/icons-material/Add';
import {
  Collapse,
  Divider,
  TablePaginationProps,
  Typography,
} from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  GridPagination,
  GridRowClassNameParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { DataGrid, ProgressModal } from '../../../components';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../components/ButtonWithOptions/ButtonWithOptions';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import { SaveExitModal } from '../../../components/Modal/SaveExitModal/SaveExitModal';
import MultiSelectFilters from '../../../components/MultiSelectFilters/MultiSelectFilters';
import { BanksJournalEntryPreview } from '../../arkGL/journalEntries/banksJournalEntryPreview/banksJournalEntryPreview';
import { JournalEntryDetails } from '../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetails';
import { JournalEntryDetailsReadOnly } from '../../arkGL/journalEntries/journalEntryDetails/JournalEntryDetailsReadOnly';
import { BankAccountDetails } from '../bankAccountDetails/BankAccountDetails';
import { BankAccountDetailsReadOnly } from '../bankAccountDetails/BankAccountDetailsReadOnly';
import { BankConnectionPanel } from '../bankConnectionPanel/BankConnectionPanel';
import { BankCards } from '../components/bankCards/BankCards';
import { BankBulkEditREV } from '../components/bulkEdit/BulkEdit';
import { DataGridToolbar } from '../components/dataGridToolbar/DataGridToolbar';
import {
  BankFeedViewOptions,
  DEFAULT_PAGE_SIZE,
  NewBankFeedOptions,
} from './BankFeedList.defaults';
import { useBankFeedsList } from './BankFeedList.hooks';
import {
  BankFeedWrapperBox,
  BlueContentButtonBase,
  ButtonBox,
  DataGridBox,
  FilterBorderColor,
  HeaderRow,
  IconBox,
  RotatingIcon,
  ViewOptionChip,
  ViewOptionsBox,
} from './BankFeedList.styles';

export const BankFeedsList: React.FC = () => {
  const {
    isReadonly,
    viewState,
    setViewState,
    selectedDataGridTab,
    handleNewButtonAction,
    openBankConnectionPanel,
    setOpenBankConnectionPanel,
    handleBankConnectionClose,
    isLoading,
    setIsLoading,
    bankFeedsDataList,
    editedRows,
    setEditedRows,
    resetBankFeedsDataList,
    showUnsavedInlineEditsPrompt,
    setShowUnsavedInlineEditsPrompt,
    showNoGlAccountPrompt,
    setShowNoGlAccountPrompt,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    onColumnOrderChange,
    bankFeedsSelectionModel,
    setBankFeedsSelectionModel,
    handleFilter,
    rowCountState,
    handlePageChange,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    selectedBankTransaction,
    onDetailsClose,
    fundList,
    CoAList,
    ledgerList,
    bankAccountList,
    memoEntityList,
    handleFundOnlyFilter,
    selectedFundsFilterList,
    bulkActionOptions,
    handleBulkAction,
    showBulkEdit,
    handleCloseBulkEdit,
    fetchBankTransactions,
    sortModel,
    handleSort,
    aggregateAccountsList,
    handleAggregateDetailsClick,
    selectedBankAccount,
    handleOnBankAccountView,
    onBankAccountDetailsClose,
    fetchAllFilters,
    sendToGlValidation,
    showGlValidationDetails,
    handleShowGlValidationDetails,
    cancelSendToGl,
    sendValidatedTransToGl,
    pageCountState,
    handleTabChange,
    fetchEntityList
  } = useBankFeedsList();

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event: any, newPage: any) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const getRowClassName = (params: GridRowClassNameParams): string => {
    const { isCapCallEdited, isEntityEdited, isGlAccountEdited } =
      params.row.isEditedRow;

    const highlight = isCapCallEdited | isEntityEdited | isGlAccountEdited;

    return highlight ? 'highlight-row' : '';
  };

  return (
    <BankFeedWrapperBox
      id="bank_feed_list_main"
      role="main"
      viewState={viewState}
    >
      <ProgressModal
        id="loading_modal_bank_feeds_list"
        showProgress={isLoading}
      />
      <HeaderRow>
        <IconBox>
          <Typography variant="pageTitle">Bank Feeds</Typography>
          <ViewOptionsBox>
            <ViewOptionChip
              size="small"
              label={BankFeedViewOptions.DASHBOARD}
              color={
                viewState === BankFeedViewOptions.DASHBOARD
                  ? 'primary'
                  : 'secondary'
              }
              clickable
              onClick={() => {
                if (Object.keys(editedRows).length) {
                  setShowUnsavedInlineEditsPrompt(true);
                  return;
                } else {
                  setViewState(BankFeedViewOptions.DASHBOARD);
                }
              }}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <ViewOptionChip
              size="small"
              label={BankFeedViewOptions.HYBRID}
              color={
                viewState === BankFeedViewOptions.HYBRID
                  ? 'primary'
                  : 'secondary'
              }
              clickable
              onClick={() => setViewState(BankFeedViewOptions.HYBRID)}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <ViewOptionChip
              size="small"
              label={BankFeedViewOptions.DATA_VIEW}
              color={
                viewState === BankFeedViewOptions.DATA_VIEW
                  ? 'primary'
                  : 'secondary'
              }
              clickable
              onClick={() => setViewState(BankFeedViewOptions.DATA_VIEW)}
            />
          </ViewOptionsBox>
        </IconBox>

        <ButtonBox>
          {viewState !== BankFeedViewOptions.DATA_VIEW && (
            <MultiSelectFilters
              size="small"
              id={'fund_filter'}
              value={selectedFundsFilterList}
              onFilter={handleFundOnlyFilter}
              label={`Fund Filter`}
              listData={(fundList as []) || []}
              filterName={'fund_filter'}
              emptySelectionOnClear={false}
              buttonSx={FilterBorderColor}
            />
          )}
          {!isReadonly &&
            viewState !== BankFeedViewOptions.DASHBOARD &&
            bankFeedsSelectionModel && (
              <SplitButton
                id={'btn_bulk_action_options'}
                text="Actions"
                showSelectedOptionIcon={true}
                options={bulkActionOptions}
                hidden={false}
                handleOptionClick={handleBulkAction}
                ariaLabelMessage="Select bulk action option"
                noGutter={true}
              />
            )}

          {!isReadonly && (
            <ButtonWithOptions
              buttonID="add_new_bank_feeds_button"
              popoverID="add_new_bank_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewBankFeedOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      {viewState !== BankFeedViewOptions.DATA_VIEW ? (
        <BankCards
          aggregateAccountsList={aggregateAccountsList}
          handleAggregateDetailsClick={handleAggregateDetailsClick}
          viewState={viewState}
          handleOnBankAccountView={handleOnBankAccountView}
        />
      ) : (
        <Collapse in={showBulkEdit}>
          <BankBulkEditREV
            showBulkEdit={showBulkEdit}
            closeBulkEdit={handleCloseBulkEdit}
            selectionModel={bankFeedsSelectionModel}
            setSelectionModel={setBankFeedsSelectionModel}
            dataList={bankFeedsDataList}
            memoEntityList={memoEntityList}
            CoAList={CoAList}
            fundList={fundList}
            fetchBankTransactions={fetchBankTransactions}
          />
        </Collapse>
      )}
      {viewState === BankFeedViewOptions.DASHBOARD ? null : (
        <DataGridBox>
          <DataGridToolbar
            selectedDataGridTab={selectedDataGridTab}
            editedRows={editedRows}
            setEditedRows={setEditedRows}
            resetBankFeedsDataList={resetBankFeedsDataList}
            showUnsavedInlineEditsPrompt={showUnsavedInlineEditsPrompt}
            setShowUnsavedInlineEditsPrompt={setShowUnsavedInlineEditsPrompt}
            fetchBankTransactions={fetchBankTransactions}
            handleTabChange={handleTabChange}
          />
          <DataGrid
            id="bank_feeds_data_grid"
            dataList={bankFeedsDataList || []}
            noDataMessage={isLoading ? 'Loading...' : 'No Data Available'}
            minHeight="1vh"
            autoHeight={false}
            headerList={headerList}
            selectionModel={bankFeedsSelectionModel}
            setSelectionModel={setBankFeedsSelectionModel}
            activeHeaderFields={activeHeaderFields}
            handleUpdateHeader={handleUpdateHeader}
            handleFilter={handleFilter}
            onColumnOrderChange={onColumnOrderChange}
            sortModel={sortModel}
            onSortModelChange={handleSort}
            sortingMode="server"
            rowsPerPageOptions={[20]}
            hideFooter={false}
            pagination
            paginationMode="server"
            pageSize={DEFAULT_PAGE_SIZE}
            rowCount={rowCountState}
            components={{ Footer: CustomPagination }}
            onPageChange={handlePageChange}
            page={pageCountState}
            getRowClassName={getRowClassName}
          />
        </DataGridBox>
      )}
      {openBankConnectionPanel && (
        <BankConnectionPanel
          openBankConnectionPanel={openBankConnectionPanel}
          handleBankConnectionClose={handleBankConnectionClose}
          fundList={fundList}
          ledgerList={ledgerList}
          CoAList={CoAList}
          bankAccountList={bankAccountList}
        />
      )}
      {!!selectedBankAccount?.type &&
        (isReadonly ? (
          <BankAccountDetailsReadOnly
            selectedBankAccount={selectedBankAccount}
            onDetailClose={onBankAccountDetailsClose}
            fetchAllFilters={fetchAllFilters}
          />
        ) : (
          <BankAccountDetails
            selectedBankAccount={selectedBankAccount}
            onDetailClose={onBankAccountDetailsClose}
            fetchAllFilters={fetchAllFilters}
          />
        ))}
      {selectedBankTransaction && (
        <BanksJournalEntryPreview
          selectedBankTransaction={selectedBankTransaction}
          onDetailsClose={onDetailsClose}
          fundList={fundList}
          glAccountList={CoAList}
          ledgerList={ledgerList}
          memoEntityList={memoEntityList}
          bankAccountList={bankAccountList || []}
          fetchBankTransactions={fetchBankTransactions}
          fetchEntityList={fetchEntityList}
          isReadOnly={isReadonly}
        />
      )}
      {selectedJournalEntry &&
        (isReadonly ? (
          <JournalEntryDetailsReadOnly
            selectedJournalEntry={selectedJournalEntry}
            onDetailClose={onJournalEntryPanelClose}
            fetchAllJournalEntries={() => { }}
            fundId={selectedJournalEntry.journalEntry?.fundId}
          />
        ) : (
          <JournalEntryDetails
            selectedJournalEntry={selectedJournalEntry}
            setSelectedJournalEntry={setSelectedJournalEntry}
            onDetailClose={onJournalEntryPanelClose}
            fetchAllJournalEntries={() => { }}
            fundId={selectedJournalEntry.journalEntry?.fundId}
          />
        ))}
      <ConfirmationDialog
        open={!!sendToGlValidation}
        onClose={cancelSendToGl}
        id="send_to_gl_failed_validation_dialog"
        isTextContent={false}
        actions={[
          {
            label: `Continue sending ${sendToGlValidation?.validatedTransactionIds.length} transaction(s) to GL`,
            onClick: sendValidatedTransToGl,
            id: 'btn_send_validated_trans_to_gl',
            variant: 'contained',
            color: 'primary',
            hide: !sendToGlValidation?.validatedTransactionIds.length,
          },
          {
            label: 'Cancel',
            onClick: cancelSendToGl,
            id: 'btn_cancel_send_to_gl',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <>
            <Typography>
              {`${sendToGlValidation?.failedTotal} / ${bankFeedsSelectionModel.length} Transactions cannot be sent to GL`}

              <BlueContentButtonBase
                onClick={handleShowGlValidationDetails}
                aria-expanded={showGlValidationDetails}
                disableRipple
              >
                <RotatingIcon expanded={showGlValidationDetails} />
              </BlueContentButtonBase>
            </Typography>
            <Collapse in={showGlValidationDetails}>
              {!!sendToGlValidation?.failedTypes.nonGlLinkedAcc && (
                <li>
                  <small>{`${sendToGlValidation.failedTypes.nonGlLinkedAcc} Transaction(s) from Bank Account(s) not associated with a Ledger and a GL Account selected`}</small>
                </li>
              )}
              {!!sendToGlValidation?.failedTypes.excluded && (
                <li>
                  <small>{`${sendToGlValidation.failedTypes.excluded} Excluded transaction(s) selected`}</small>
                </li>
              )}
              {!!sendToGlValidation?.failedTypes.categorized && (
                <li>
                  <small>{`${sendToGlValidation.failedTypes.categorized} Transaction(s) that were already sent to GL selected`}</small>
                </li>
              )}
              {!!sendToGlValidation?.failedTypes.glAccount && (
                <li>
                  <small>{`${sendToGlValidation.failedTypes.glAccount} In Review transaction(s) have not been assigned a GL Account`}</small>
                </li>
              )}
              {!!sendToGlValidation?.failedTypes.memoEntity && (
                <li>
                  <small>{`${sendToGlValidation.failedTypes.memoEntity} Transaction(s) that require Memo Tags selected`}</small>
                </li>
              )}
            </Collapse>
            {!!sendToGlValidation?.validatedTransactionIds.length && (
              <Typography>
                {`You may continue sending ${sendToGlValidation?.validatedTransactionIds.length} transaction(s) to GL or cancel to edit your selection.`}
              </Typography>
            )}
          </>
        }
        title={`${sendToGlValidation?.validatedTransactionIds.length
          ? 'Some'
          : 'Selected'
          } transactions cannot be sent to General Ledger`}
      />

      <ConfirmationDialog
        open={!!showNoGlAccountPrompt}
        onClose={() => setShowNoGlAccountPrompt(false)}
        id="No_gl_available_dialog"
        actions={[
          {
            label: 'OK',
            onClick: () => setShowNoGlAccountPrompt(false),
            id: 'btn_close_no_gl_available_dialog',
            variant: 'outlined',
          },
        ]}
        content={
          'If no GL is connected, please navigate to the General Ledgers - Setup page first before proceeding with the bank account connection.'
        }
        title={
          'To connect a bank account, a general ledger must be connected first.'
        }
      />
      <SaveExitModal
        onActionClick={() => { }}
        isBlocked={!!Object.keys(editedRows).length}
        title="Leave Page?"
        content="Your changes have not been saved. Are you sure you want to leave this page?"
        actionTitle="Save changes"
        confirmNavigationTitle="Exit Without Saving"
      />
    </BankFeedWrapperBox>
  );
};
