import { useTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts';
import { AxisValueFormatterContext } from '@mui/x-charts/models/axis';
import { FC } from 'react';

import { FundSummaryItem } from '../../../utils/types/fund.type';
import { LegendColorBox } from '../ChartLegend/ChartLegend.styles';
import { chartsTooltipValueFormatter } from '../helpers/Chart.utils';
import {
  BarChartHeaderBox,
  BarChartLegendBox,
  barChartParams,
  BarChartTitle,
  chartStyles,
  LegendItem,
  TotalValueAndDistributionsBarChartStyled,
} from './TotalValueAndDistributionsBarChart.styles';

type TotalValueAndDistributionsBarChartProps = {
  fundSummaries: FundSummaryItem[];
  currency: string;
};

export const TotalValueAndDistributionsBarChart: FC<
  TotalValueAndDistributionsBarChartProps
> = ({ fundSummaries, currency }) => {
  const theme = useTheme();

  fundSummaries.sort((a, b) => (a.inceptionDate > b.inceptionDate ? 1 : -1));

  const navData = fundSummaries.map((item) =>
    Math.abs((item.value as number) ?? 0)
  );
  const distributionData = fundSummaries.map((item) =>
    Math.abs((item.distribution as number) ?? 0)
  );
  const xLabels = fundSummaries.map((item) => item.name);

  function getFormattedYaxisVal(
    val: number,
    _context: AxisValueFormatterContext
  ) {
    const totalStr = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'currency',
      currency: currency,
    }).format(val);

    return totalStr;
  }

  return (
    <TotalValueAndDistributionsBarChartStyled id="bar_chart">
      <BarChartHeaderBox>
        <BarChartTitle>Total Value</BarChartTitle>
        <BarChartLegendBox>
          <LegendItem>
            <LegendColorBox color={theme.palette.common.visUltramarineBlue} />
            Distributions
          </LegendItem>
          <LegendItem>
            <LegendColorBox color={theme.palette.common.visSkyBlue} />
            NAV
          </LegendItem>
        </BarChartLegendBox>
      </BarChartHeaderBox>

      <BarChart
        colors={[
          `${theme.palette.common.visUltramarineBlue}`,
          `${theme.palette.common.visSkyBlue}`,
        ]}
        height={270}
        series={[
          {
            data: distributionData,
            label: 'Distributions',
            id: 'distributionsId',
            stack: 'total',
            valueFormatter: (value) =>
              chartsTooltipValueFormatter(value, currency),
          },
          {
            data: navData,
            label: 'NAV',
            id: 'navId',
            stack: 'total',
            valueFormatter: (value) =>
              chartsTooltipValueFormatter(value, currency),
          },
        ]}
        xAxis={[{ data: xLabels, scaleType: 'band' }]}
        yAxis={[{ valueFormatter: getFormattedYaxisVal }]}
        slotProps={{
          legend: { hidden: true },
          popper: {
            sx: chartStyles.popper,
          },
        }}
        {...barChartParams}
      />
    </TotalValueAndDistributionsBarChartStyled>
  );
};
