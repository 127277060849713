import { format } from "date-fns";
import { useContext, useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import { useCapitalCallDetailsEffect } from "../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks";
import { Transaction } from "../../../../utils/types/capitalCalls.type";

type RouteProp = {
  id: string;
  section: string;
};

type Props = {
  handleAddTransaction: (transaction: Transaction) => void;
  setIsAddingNewTransactionRow: (value: boolean) => void;
  isAddingNewTransactionRow: boolean;
  cancelCreateUpdateTransaction: () => void;
  addAndUpdateTransaction: (transaction: Transaction) => void;
  setIsGoBack: (value: boolean) => void;
};

export const useAddTransactionDetails = ({
  handleAddTransaction,
  setIsAddingNewTransactionRow,
  isAddingNewTransactionRow,
  cancelCreateUpdateTransaction,
  addAndUpdateTransaction,
  setIsGoBack,
}: Props) => {
  const { id } = useParams<RouteProp>();
  const { capitalCall } = useCapitalCallDetailsEffect(
    id !== "new" ? id : undefined
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    clearErrors,
    getValues,
    setError,
  } = useForm<Transaction>({
    defaultValues: {
      components: [],
      dateString: null,
      metricFractionDigit: 0,
      metricSign: null,
      useMetric: false,
      quarter: "",
    },
  });

  const { isDirty } = useFormState({
    control,
  });
  const { informationAlert } = useContext(AppContext);

  useEffect(() => {
    if (isAddingNewTransactionRow !== isDirty) {
      setIsAddingNewTransactionRow(isDirty);
    }
  }, [isDirty]);

  const defaultTransactionValues = {
    fund: capitalCall?.fundName || "",
    currentType: {},
    currentInvestor: {},
    date: "",
    amount: " ",
    name: "",
    components: [],
    dateString: null,
    metricFractionDigit: 0,
    metricSign: null,
    useMetric: false,
    quarter: "",
  };

  const addTransaction = (data: Transaction | any) => {
    const { currentInvestor, currentType, ...remainingData } = data;
    const newData = {
      ...remainingData,
      investor: currentInvestor.name,
      type: currentType.name,
      date: format(remainingData.date, "MM/dd/yyyy"),
    };

    handleAddTransaction(newData);
    reset(defaultTransactionValues);
    clearErrors();
  };

  const addTransactionPopUp = async () => {
    const { currentInvestor, currentType, ...remainingData } = getValues();
    const isCurrentInvestorPresent = currentInvestor
      ? Object.keys(currentInvestor).length > 0
        ? true
        : false
      : false;
    const isCurrentTypePresent = currentType
      ? Object.keys(currentType).length > 0
        ? true
        : false
      : false;

    let flag = true;
    const name: string = remainingData.name;

    if (
      remainingData.amount === undefined ||
      remainingData.date === undefined ||
      remainingData.name === undefined ||
      isCurrentInvestorPresent === false ||
      isCurrentTypePresent === false ||
      name.trim() === "" ||
      remainingData.amount.toString().trim() === ""
    ) {
      informationAlert(
        "Incomplete data, please fill out all required fields.",
        "error"
      );
      (remainingData.amount === undefined ||
        remainingData.amount.toString().trim() === "") &&
        setError(
          "amount",
          { type: "custom", message: "Amount is required " },
          { shouldFocus: true }
        );

      (remainingData.name === undefined || name.trim() === "") &&
        setError(
          "name",
          { type: "custom", message: "Transaction Name is required" },
          { shouldFocus: true }
        );

      remainingData.date === undefined &&
        setError(
          "date",
          { type: "custom", message: "Date is required" },
          { shouldFocus: true }
        );
      isCurrentInvestorPresent === false &&
        setError(
          "currentInvestor",
          { type: "custom", message: "Investor is required" },
          { shouldFocus: true }
        );
      isCurrentTypePresent === false &&
        setError(
          "currentType",
          { type: "custom", message: "Transaction Type is required" },
          { shouldFocus: true }
        );

      flag = false;
      setIsGoBack(false);
    }

    if (flag) {
      const newData = {
        ...remainingData,
        investor: currentInvestor?.name || "",
        type: currentType?.name || "",
        date: format(new Date(remainingData.date), "MM/dd/yyyy"),
      };

      await addAndUpdateTransaction(newData);
      reset(defaultTransactionValues);
      clearErrors();
    }
    cancelCreateUpdateTransaction();
  };

  useEffect(() => {
    if (capitalCall) {
      setValue("fund", capitalCall.fundName || "");
    }
  }, [capitalCall]);

  return {
    register,
    handleSubmit,
    errors,
    watch,
    control,
    addTransaction,
    capitalCall,
    addTransactionPopUp,
    clearErrors,
  };
};
