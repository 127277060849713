import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import { DataWrapperBox } from "../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import ProgressModal from "../../../../components/Progress/ProgressModal/ProgressModal";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import RoutingPaths from "../../../../core/routing/routingPaths";
import { EntityDetails } from "../entityDetails/EntityDetails";
import { useEntityList } from "./EntityList.hooks";
import {
  ButtonBox,
  HeaderRow,
  IconBox,
  StyledDataGridPro,
} from "./EntityList.styles";
import { NewEntityListOptions } from "./EntityList.styles";

export const EntityList: React.FC = () => {
  const {
    isLoading,
    onSearch,
    searchOptions,
    search,
    showSuggestionPopover,
    setShowSuggestionPopover,
    readOnly,
    handleNewButtonAction,
    entityFilteredList,
    entityResponse,
    headerList,
    handleOnView,
    entitySelectionModel,
    setEntitySelectionModel,
    activeHeaderFields,
    handleFilter,
    handleUpdateHeader,
    isFundAdmin,
    isBasicAdmin,
    isSuperAdminOrClientAdmin,
    selectedEntity,
    setSelectedEntity,
    showDeletePrompt,
    onDeleteCancel,
    onDeleteConfirm,
    bulkExportActions,
    handleBulkAction,
    fetchAllEntities,
    onDetailsPanelClose,
    selectedDelete,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    clearUploadCompleted,
    csvLinkRef,
    csvHeaders,
    csvData,
    csvFilename,
    resetCsvData,
  } = useEntityList();

  const history = useHistory();

  return (
    <DataWrapperBox id="entity_list_main">
      <ProgressModal id="modal_entity_list_loading" showProgress={isLoading} />
      <HeaderRow id="entity_list_header">
        <IconBox>
          <Typography variant="pageTitle">Memo Entities</Typography>
        </IconBox>
        <SearchBar
          id="entity_list_search"
          size="small"
          onChange={onSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          {entityFilteredList && entityFilteredList?.length > 0 && (
            <SplitButton
              id={"btn_bulk_action_options"}
              options={bulkExportActions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          )}
          {!readOnly && (
            <ButtonWithOptions
              buttonID="add_entity_button"
              popoverID="add_entity_button"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewEntityListOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <StyledDataGridPro
        id="entity_list_grid"
        dataList={
          !isLoading && entityFilteredList?.length > 0 ? entityFilteredList : []
        }
        minHeight="1vh"
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={entitySelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setEntitySelectionModel}
        handleFilter={handleFilter}
        handleUpdateHeader={handleUpdateHeader}
      />
      {selectedEntity?.type ? (
        <EntityDetails
          selectedEntity={selectedEntity}
          entityResponse={entityResponse}
          setSelectedEntity={setSelectedEntity}
          onDetailClose={onDetailsPanelClose}
          fetchAllEntities={fetchAllEntities}
        />
      ) : (
        ""
      )}
      <DeleteConfirmationDialog
        open={showDeletePrompt}
        onClose={() => {}}
        id="delete_confirmation"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content={
          <Box component="span">
            This action will remove this entity and everything connected to it.
            This action is permanent and cannot be undone.
          </Box>
        }
        title="Are you sure you want to delete?"
        actionType={`DELETE ${selectedDelete?.entity?.name}`}
      />
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "btn_upload",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "btn_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="upload_complete_confirmation"
        actions={[
          {
            label: "Upload History",
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: "upload_history_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Close",
            onClick: clearUploadCompleted,
            id: "close_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Entity file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the import."
        title="Upload Results"
      />
      <CSVLink
        ref={csvLinkRef}
        headers={csvHeaders}
        data={csvData}
        filename={csvFilename}
        onClick={resetCsvData}
      />
    </DataWrapperBox>
  );
};
