import { useContext, useReducer, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import RoutingPaths from '../../../../core/routing/routingPaths';
import useRole from '../../../../core/routing/useRole';
import {
  createLedger,
  getLedgers,
  updateLedger,
} from '../../../../services/arkGL.service';
import {
  disconnectGL,
  getFundCurrencyList,
  getFundNames,
  getGLAuthQuickbooks,
} from '../../../../services/fund.service';
import { useEffectAsync } from '../../../../utils/hooks/useEffectAsync.hook';
import { LoadingStatus } from '../../../../utils/types/form.type';
import { ArkLedger } from '../../../../utils/types/glSetup.type';
import { ListItem } from '../../../../utils/types/listItems';
import { NameIdPair } from '../../../../utils/types/transaction.type';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  CONNECT_LEDGER_ERROR,
  CREATE_LEDGER_ERROR,
  CREATE_LEDGER_SUCCESS,
  DISCONNECT_GL_ERROR,
  DISCONNECT_GL_SUCCESS,
  GET_CURRENCY_LIST_ERROR,
  GET_FUND_LIST_ERROR,
  GET_LEDGER_LIST_ERROR,
  LEDGER_FORM_DEFAULT_VALUE,
  StatusType,
  UPDATE_LEDGER_ERROR,
  UPDATE_LEDGER_SUCCESS,
} from '../setupList/GLSetupList.constants';

type Props = {
  ledger?: ArkLedger;
  onClose: Function;
  fetchAllLedgers: Function;
  isNewLedger: boolean;
  arkGlLocked: boolean;
  glLocked: boolean;
  fundId?: string;
};
export const useGLSetupDetails = ({
  ledger,
  onClose,
  fetchAllLedgers,
  isNewLedger,
  fundId,
}: Props) => {
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [toBeDeleted, setToBeDeleted] = useState<string | undefined>();
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>('');
  const [setupCurrentIndex, setSetupCurrentIndex] = useState<number>(1);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<ListItem[]>([]);
  const [fundList, setFundList] = useState<NameIdPair[]>([]);
  const [showReadyToDisconnect, setShowReadyToDisconnect] =
    useState<boolean>(false);
  const [selectedFundId, setSelectedFundId] = useState<string>();
  const [isNewQBLedgerAllowed, setIsNewQBLedgerAllowed] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { state, informationAlert } = useContext(AppContext);

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const readonly: boolean = !!isFundAdmin;
  const history = useHistory();

  const setupSteps = [
    {
      label: 'Select General Ledger',
      description: 'Select a General Ledger below',
    },
    { label: 'Authenticate', description: '' },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<ArkLedger>({
    defaultValues: ledger ?? LEDGER_FORM_DEFAULT_VALUE,
  });
  const { isDirty } = useFormState({ control });

  const fetchCurrency = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const currencyListResponse = await getFundCurrencyList();

      if (isCanceled?.()) return;

      setCurrencyList(currencyListResponse);
    } catch (e) {
      informationAlert(GET_CURRENCY_LIST_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const fetchFundNames = async (isCanceled?: () => boolean) => {
    try {
      setIsLoading(LoadingStatus.Loading);

      const response = await getFundNames();

      if (isCanceled?.()) return;

      setFundList(response);
    } catch (e) {
      informationAlert(GET_FUND_LIST_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const fetchFundLedgers = async (isCanceled?: () => boolean) => {
    if (fundId || selectedFundId) {
      try {
        setIsLoading(LoadingStatus.Loading);

        const response = await getLedgers(fundId || selectedFundId);
        const qbLedgerExists = response.items.find(
          (ledger: any) =>
            ledger.glPlatform === 'QUICKBOOKS_ONLINE' &&
            ledger.status === 'AUTHENTICATED'
        );

        if (!qbLedgerExists) setIsNewQBLedgerAllowed(true);

        if (isCanceled?.()) return;
      } catch (e) {
        informationAlert(GET_LEDGER_LIST_ERROR, 'error');
      } finally {
        setIsLoading(undefined);
      }
    }
  };

  const getGLAuthUrl = async () => {
    if (fundId || selectedFundId) {
      try {
        setIsLoading(LoadingStatus.Connecting);

        const redirectUrl = `${window.location.protocol}//${
          window.location.host
        }${RoutingPaths.TransactionMapper}/${fundId || selectedFundId}`;

        const data = await getGLAuthQuickbooks(
          fundId || selectedFundId!,
          redirectUrl
        );

        return data.authUrl;
      } catch (e) {
        informationAlert(CONNECT_LEDGER_ERROR, 'error');
      } finally {
        setIsLoading(undefined);
      }
    }
  };

  useEffectAsync(async (isCanceled) => {
    await fetchCurrency(isCanceled);
    await fetchFundNames(isCanceled);
    if (isNewLedger && fundId) {
      await fetchFundLedgers(isCanceled);
      setValue('fundId', fundId);
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      setIsNewQBLedgerAllowed(false);
      forceUpdate();
      await fetchFundLedgers(isCanceled);
      if (isNewLedger && selectedFundId) {
        setValue('fundId', selectedFundId);
      }
    },
    [selectedFundId]
  );

  const createNewLedger = async (data: ArkLedger) => {
    try {
      await createLedger(data);
      informationAlert(CREATE_LEDGER_SUCCESS, 'success');
      closeDrawer();
      history.push(
        `${RoutingPaths.FundGLAccounts}/${fundId || selectedFundId}`,
        { data: 'show_coa_fund_menu' }
      );
    } catch (error) {
      informationAlert(CREATE_LEDGER_ERROR, 'error');
    }
  };

  const editLedger = async (data: ArkLedger) => {
    if (ledger && ledger.id) {
      try {
        const request = {
          name: data.name,
          description: data.description,
          currency: data.currency,
        };

        await updateLedger(data.platformLedgerId!, request);

        informationAlert(UPDATE_LEDGER_SUCCESS, 'success');
        closeDrawer();
        fetchAllLedgers();
      } catch (error) {
        informationAlert(UPDATE_LEDGER_ERROR, 'error');
      }
    }
  };

  const createUpdateLedger = async (data: ArkLedger) => {
    data.name = data.name.trim();
    data.description = data.description.trim();

    if (isNewLedger) {
      setIsLoading(LoadingStatus.Adding);

      await createNewLedger({
        ...data,
      });
    } else {
      setIsLoading(LoadingStatus.Updating);
      await editLedger(data);
    }
    setIsLoading(undefined);
  };

  const handleSelectPlatform = (e: any) => {
    e.stopPropagation();
    setSelectedPlatform(e.target.id);
  };

  const authQuickBooks = async () => {
    const authUrl = await getGLAuthUrl();

    window.location.href = authUrl;
  };

  const handleConnectLedger = async (e: any) => {
    setSetupCurrentIndex(2);
    switch (selectedPlatform) {
      case 'qb':
        setIsRedirecting(true);
        try {
          await authQuickBooks();
        } catch (e) {
          informationAlert(CONNECT_LEDGER_ERROR, 'error');
        }
        break;
    }
  };

  const handleDisconnect = () => {
    setShowReadyToDisconnect(true);
  };

  const handleCancelDisconnect = () => {
    setShowReadyToDisconnect(false);
  };

  const handleConfirmDisconnect = async () => {
    setShowReadyToDisconnect(false);
    if (ledger && ledger.generalLedgerId) {
      try {
        setIsLoading(LoadingStatus.Disconnecting);

        await disconnectGL(ledger.generalLedgerId);

        informationAlert(DISCONNECT_GL_SUCCESS, 'success');
        closeDrawer();
        fetchAllLedgers();
      } catch (error) {
        informationAlert(DISCONNECT_GL_ERROR, 'error');
      } finally {
        setIsLoading(undefined);
      }
    }
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const status = StatusType[watch('state') as keyof typeof StatusType] || '';

  return {
    isLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    createUpdateLedger,
    toBeDeleted,
    closeDrawer,
    keepDrawerOpen,
    showExitConfirmation,
    selectedPlatform,
    handleSelectPlatform,
    setupCurrentIndex,
    setupSteps,
    isRedirecting,
    handleConnectLedger,
    toggleDrawer,
    currencyList,
    fundList,
    status,
    readonly,
    showReadyToDisconnect,
    handleDisconnect,
    handleCancelDisconnect,
    handleConfirmDisconnect,
    selectedFundId,
    setSelectedFundId,
    isNewQBLedgerAllowed,
    setIsNewQBLedgerAllowed,
    setSetupCurrentIndex,
  };
};
