import { Box, styled } from "@mui/material";


export const ListContainer = styled("ul")(({ theme }) => ({
  height: '100%',
  margin: 0,
  overflow: 'auto',
  padding: theme.spacing(0.25),
  whiteSpace: 'nowrap'
}));

export const ChildListContainer = styled("ul")(({ theme }) => ({
  display: 'flex',
  margin: 0,
  paddingBottom: theme.spacing(1),
  flexDirection: 'column',
}));

export const ClickableBox = styled("div")(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  paddingBottom: theme.spacing(1),
  '& > span': {
    display: 'inline-flex'
  }
}));

export const List = styled("li")(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: theme.spacing(0.125, 0, 0)
}));

interface ClickableSpanProps {
  visible: boolean;
}

export const ClickableSpan = styled("span", { shouldForwardProp: (propName) => propName !== "visible" && propName !== "selected" })<ClickableSpanProps>(({ theme, visible }) => ({
  cursor: 'pointer',
  visibility: visible ? 'visible': 'hidden',
}));

interface HoverableSpanProps {
  selected: boolean;
}

export const HoverableSpan = styled("span", { shouldForwardProp: (propName) => propName !== "selected" })<HoverableSpanProps>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.light: 'transparent',
  padding: theme.spacing(0.5, 1),
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.light: theme.palette.grey[300],
    transition: 'background-color 0.3s ease-in-out'
  }
}));