import { Box, Stack, styled, Typography } from "@mui/material";

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: "95%",
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: theme.spacing(0.8),
  padding: theme.spacing(4),
  marginBottom: theme.spacing(1),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(4),
}));

export const ChipContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
}));
export const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
