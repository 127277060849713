import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import DetailPanel from '../../../../components/DetailPanel/DetailPanel';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import {
  BankAccount,
  nameIdFundPair,
  NameIdPair,
} from '../../../../utils/types/bank.type';
import { GreyInfoIcon } from '../../bankFeeds/BankFeedList.styles';
import { plaidConnectResponseType } from '../BankConnectionPanel.defaults';
import { useBankFundAssociation } from './BankFundAssociation.hooks';
import {
  AddCountBox,
  BanksInfoBox,
  ButtonCircularProgress,
  DateBox,
  FormBox,
  FormContainer,
  GreenAddIcon,
  InstitutionBox,
  InstitutionTitle,
  InstructionBox,
  MarginOptioniBox,
  PaddedTextBox,
  StyledBankIcon,
  StyledCancelButton,
  StyledFormControl,
  StyledSaveButton,
} from './BankFundAssociation.styles';
import { TwoStepDropdown } from './TwoStepDropdown';

interface Props extends RouteProps {
  openBankConnectionPanel: boolean;
  handleBankConnectionClose: () => void;
  plaidAccountsRes: plaidConnectResponseType;
  fundList: NameIdPair[];
  ledgerList: nameIdFundPair[];
  CoAList: nameIdFundPair[];
  bankAccountList: BankAccount[];
}

export const BankFundAssociation: React.FC<Props> = ({
  openBankConnectionPanel,
  handleBankConnectionClose,
  plaidAccountsRes,
  fundList,
  ledgerList,
  CoAList,
  bankAccountList,
}: Props) => {
  const {
    fundLedgerCoAOptions,
    initFormValues,
    totalAccountsToAdd,
    totalExistingAccounts,
    control,
    getValues,
    reset,
    setValue,
    watch,
    watchFormValues,
    errors,
    trigger,
    handleSubmit,
    handleResetAccount,
    onReview,
    minDate,
    onExitConfirmation,
    showExitConfirmation,
    keepPanelOpen,
    isSaving,
  } = useBankFundAssociation({
    plaidAccountsRes,
    fundList,
    ledgerList,
    CoAList,
    bankAccountList,
    handleBankConnectionClose,
  });

  return (
    <>
      <DetailPanel
        id="bank_connection_panel"
        title={'Connect Account'}
        open={openBankConnectionPanel}
        variant={'temporary'}
        isGridDisplay={true}
        onClose={onExitConfirmation}
      >
        <>
          <FormContainer>
            <InstitutionBox>
              <StyledBankIcon fontSize="large" />
              <InstitutionTitle>
                {plaidAccountsRes.institution.name}
              </InstitutionTitle>
            </InstitutionBox>

            <DateBox>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <AddCountBox>
                    <GreenAddIcon fontSize="large" />

                    <Typography>
                      <strong>New Accounts | </strong>
                      {`Adding ${totalAccountsToAdd} of ${initFormValues?.accounts.length}`}
                    </Typography>
                    {!!totalExistingAccounts && (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            {totalExistingAccounts === 1
                              ? `${totalExistingAccounts} bank account (not shown below) has already been added.`
                              : `${totalExistingAccounts} bank accounts (not shown below) have already been added.`}
                          </Typography>
                        }
                      >
                        <GreyInfoIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </AddCountBox>
                  <InstructionBox>
                    <Typography>
                      Select and connect the bank accounts that are relevant to
                      your funds.
                    </Typography>
                  </InstructionBox>
                </Grid>
              </Grid>
            </DateBox>

            <Box overflow={'auto'}>
              <form onSubmit={handleSubmit(onReview)}>
                <HorizontalBox hidden={false} fullWidth>
                  {!!initFormValues?.accounts?.length ? (
                    <FormBox>
                      {initFormValues?.accounts?.map((item, index) => (
                        <Grow
                          key={`grow_${index}`}
                          in={true}
                          timeout={300}
                          style={{ transitionDelay: `${index * 60}ms` }}
                        >
                          <div key={index}>
                            <StyledFormControl
                              key={index}
                              isSelected={
                                !!getValues(`accounts.${index}.selected`)
                              }
                            >
                              <Grid container direction="row">
                                <Grid item xs={8}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="baseline"
                                  >
                                    <Grid item xs={1}>
                                      <Controller
                                        name={`accounts.${index}.selected`}
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => {
                                          return (
                                            <Checkbox
                                              disabled={isSaving}
                                              checked={value}
                                              onChange={(newValue) => {
                                                if (!newValue.target.checked) {
                                                  handleResetAccount(index);
                                                }
                                                onChange(newValue);
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={11}>
                                      <PaddedTextBox>
                                        <Typography>
                                          <strong>
                                            {`${item.subtype?.toUpperCase()}: ${
                                              item.name
                                            } (${item.mask})`}
                                          </strong>
                                        </Typography>
                                      </PaddedTextBox>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                  <Grid
                                    container
                                    direction={'row'}
                                    justifyContent="flex-end"
                                  >
                                    <Grid item>
                                      <PaddedTextBox>
                                        <Typography
                                          color={'GrayText'}
                                          gutterBottom={true}
                                        >
                                          Apply to
                                        </Typography>
                                      </PaddedTextBox>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TwoStepDropdown
                                        fundLedgerCoAOptions={
                                          fundLedgerCoAOptions
                                        }
                                        index={index}
                                        control={control}
                                        watchFormValues={watchFormValues}
                                        setValue={setValue}
                                        getValues={getValues}
                                        errors={errors}
                                        trigger={trigger}
                                        isSaving={isSaving}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Collapse
                                        in={
                                          !!getValues(
                                            `accounts.${index}.ledgerId`
                                          )
                                        }
                                      >
                                        <MarginOptioniBox>
                                          <FormControl fullWidth size="small">
                                            <Controller
                                              name={`accounts.${index}.glAccountId`}
                                              control={control}
                                              render={({
                                                field: { onChange, value },
                                              }) => {
                                                const fundId = watch(
                                                  `accounts.${index}.fundId`
                                                );

                                                const glAccountOptions =
                                                  fundLedgerCoAOptions.find(
                                                    (fund) => fund.id === fundId
                                                  );

                                                return (
                                                  <>
                                                    <InputLabel
                                                      id={`gl_account_input_label_${index}`}
                                                    >
                                                      GL Account
                                                    </InputLabel>
                                                    <Select
                                                      id={`gl_account_select_${index}`}
                                                      disabled={isSaving}
                                                      labelId={`gl_account_input_label_${index}`}
                                                      label="GL Account"
                                                      fullWidth
                                                      size="small"
                                                      value={value || ''}
                                                      onChange={(newValue) => {
                                                        onChange(newValue);
                                                        setValue(
                                                          `accounts.${index}.selected`,
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      {glAccountOptions?.CoA?.map(
                                                        (option) => (
                                                          <MenuItem
                                                            key={option.id}
                                                            value={option.id}
                                                          >
                                                            {option.name}
                                                          </MenuItem>
                                                        )
                                                      )}
                                                    </Select>
                                                  </>
                                                );
                                              }}
                                            />
                                          </FormControl>
                                        </MarginOptioniBox>
                                      </Collapse>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </StyledFormControl>
                          </div>
                        </Grow>
                      ))}
                    </FormBox>
                  ) : (
                    <Typography>No new bank accounts.</Typography>
                  )}
                </HorizontalBox>
              </form>
            </Box>

            <BanksInfoBox>
              <Typography>
                Connecting bank accounts will automatically sync transactions
                that are posted to this bank account. As transactions clear the
                bank account, they will sync into Ark to be categorized. Leave
                accounts that you do not wish to connect.
              </Typography>
            </BanksInfoBox>
          </FormContainer>
          <Box position={'relative'} height={'80px'}>
            <HorizontalBox
              addTopShadow
              attachToBottom={true}
              hidden={false}
              fullWidth
            >
              <StyledCancelButton
                id="cancel_button"
                variant="outlined"
                onClick={onExitConfirmation}
                disabled={isSaving}
                fullWidth
              >
                Cancel
              </StyledCancelButton>

              <StyledSaveButton
                id={'save_button'}
                variant="contained"
                type={'submit'}
                onClick={handleSubmit((data) => onReview(data))}
                disabled={
                  isSaving ||
                  !initFormValues?.accounts?.length ||
                  totalAccountsToAdd < 1
                }
                fullWidth
              >
                {isSaving && <ButtonCircularProgress size={20} />}
                Save & Connect
              </StyledSaveButton>
            </HorizontalBox>
          </Box>
        </>
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepPanelOpen}
        id="exit_bank_connection_confirmation"
        actions={[
          {
            label: 'Return to Connect Bank Accounts',
            onClick: keepPanelOpen,
            id: 'btn_return_to_connect',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Exit Without Saving',
            onClick: handleBankConnectionClose,
            id: 'btn_exit_confirmation',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          'The Bank Accounts have not been connected to Ark. If you exit, current progress will be lost.'
        }
        title={'Bank Accounts Not Connected'}
      />
    </>
  );
};
