import { IconButton, Switch, Typography } from "@mui/material";
import React from "react";

import IconConfig from "../../../../assets/images/icons/icon_config.svg";
import IconFilter from "../../../../assets/images/icons/icon_filter.svg";
import ArrowPopover from "../../../../components/ArrowPopover/ArrowPopover";
import { PopoverFilter } from "../../../../components/DataGrid/Filter/Filter.style";
import SplitFilter from "../../../../components/DataGrid/Filter/SplitFilter";
import {
  DandDitem,
  DragAndDropList,
} from "../../../../components/DragAndDropList/DragAndDropList";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import { DataGridColDef } from "../../../../utils/types/listItems";
import { FilterMap } from "../ScheduleOfInvestments.types";
import {
  CategoryGearBox,
  CategoryHeaderBox,
  CategoryHeaderTypography,
  DragAndDropItemLabelBox,
  DragAndDropItemSwitchBox,
  DragAndDropItemWrapper,
  DragAndDropListWrapper,
  StyledList,
} from "./CategorySelectionPopover.styles";

type Props = {
  dAndDitems: DandDitem[];
  onChange: (items: DandDitem[]) => void;
  onVisibleChange: (itemId: string) => void;
  categoryColDef: DataGridColDef;
  handleFilter: (filter: any, selected: any[] | any) => void;
  filterMap?: FilterMap;
};

export const CategorySelectionPopover: React.FC<Props> = ({
  dAndDitems,
  onChange,
  onVisibleChange,
  categoryColDef,
  handleFilter,
  filterMap,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <CategoryHeaderBox>
        <CategoryHeaderTypography>Categories</CategoryHeaderTypography>
        <CategoryGearBox id="category_header_grear_box" onClick={handleOnClick}>
          <IconButton
            id="column_config"
            aria-label="Column Configuration"
            color="inherit"
            title="Column Configuration"
          >
            <ImgIcon icon={IconConfig} useCursorPointer />
          </IconButton>
        </CategoryGearBox>

        <CategoryGearBox id={"category_header_filter"} onClick={handleOnClick}>
          <IconButton
            id="column_filter"
            aria-label="Filter"
            color="inherit"
            title="Filter"
          >
            <ImgIcon useCursorPointer icon={IconFilter} />
          </IconButton>
        </CategoryGearBox>
      </CategoryHeaderBox>

      <ArrowPopover
        id={"category_header"}
        anchorEl={anchorEl}
        showPopover={Boolean(anchorEl?.id === "category_header_grear_box")}
        handleOnPopoverClose={handleOnClose}
        verticalOrigin="top"
        content={
          <DragAndDropListWrapper>
            <DragAndDropList
              items={dAndDitems}
              onChange={onChange}
              onItemRender={(item) => (
                <DragAndDropItemWrapper>
                  <DragAndDropItemLabelBox>
                    <Typography variant="label">{item.name}</Typography>
                  </DragAndDropItemLabelBox>

                  <DragAndDropItemSwitchBox>
                    <Switch
                      id={"swtch_status_".concat(item.id)}
                      checked={item.data.visible}
                      onChange={() => onVisibleChange(item.id)}
                    />
                  </DragAndDropItemSwitchBox>
                </DragAndDropItemWrapper>
              )}
            />
          </DragAndDropListWrapper>
        }
      />

      {categoryColDef?.inlineFilter && categoryColDef?.inlineFilterName && (
        <PopoverFilter
          id={"category_filter_popover"}
          open={Boolean(anchorEl?.id === "category_header_filter")}
          anchorEl={anchorEl}
          onClose={handleOnClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SplitFilter
            splitFilters={categoryColDef.inlineSplitFilters!}
            handleFilter={handleFilter}
            filterMap={filterMap}
          />
        </PopoverFilter>
      )}
    </>
  );
};