import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Checkbox, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { ReactElement, useMemo } from "react";

import { Team } from "../../../../../utils/types/team.type";
import { TeamMember } from "../../../../../utils/types/user.type";

interface Props {
    team: Team;
    onTeamDeselect: (teamId: string) => void;
    onTeamSelect: (team: Team) => void;
    teamMembers?: TeamMember[];
}

const TeamsListRow: React.FC<Props> = ({
  team,
  onTeamDeselect,
  onTeamSelect,
  teamMembers
}: Props): ReactElement => {
  const isTeamSelected = useMemo(() => !!teamMembers?.find(teamMember => teamMember?.team?.id === team?.teamId), [teamMembers, team]);

  return (
    <ListItem
      key={team?.teamId}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="arrow"
          onClick={() => onTeamSelect(team)}>
          <ArrowForwardIosOutlined fontSize="small" />
        </IconButton>
      }
      disablePadding
      id={`team_list${team?.teamId}`}
    >
      <ListItemButton onClick={() => onTeamSelect(team)} dense>
        <ListItemIcon>
          <Checkbox
            onClick={() => onTeamDeselect(team?.teamId ?? '')}
            edge="start"
            size="small"
            disabled={!isTeamSelected}
            checked={isTeamSelected}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={team?.teamName} />
      </ListItemButton>
    </ListItem>
  );
};

export default TeamsListRow;
