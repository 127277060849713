import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import { TeamMembers } from "../../../../utils/types/capitalCalls.type";
import {
  ErrorLabel,
  StyledParentCard,
  StyledReviewerContainer,
} from "./CapitalCallSetUp.style";
import { useReviewers } from "./useReviewers.hooks";

const REVIEWERS = [
  "ASSOCIATE",
  "FUND_CONTROLLER",
  "MANAGER",
  "DIRECTOR",
  "FUND_USER_READ_ONLY",
];

type Props = {
  value?: { name?: string }[] | null;
  onChange: (value?: { name?: string }[] | null) => void;
  teamMembers: TeamMembers;
  disabled?: boolean;
  errorMessage: any;
  setErrorMessage: (value: any) => void;
};

const Reviewers = ({
  onChange,
  value,
  teamMembers,
  disabled,
  errorMessage,
  setErrorMessage,
}: Props) => {
  const { isChecked, handleChange, selectReviewer } = useReviewers({
    onChange,
    value,
    teamMembers,
    errorMessage,
    setErrorMessage,
  });

  return (
    <FormGroup>
      <StyledReviewerContainer>
        {REVIEWERS.map((reviewer) => {
          const isCheckboxChecked: boolean = isChecked(reviewer);

          return (
            <Stack key={reviewer}>
              <StyledParentCard
                variant="outlined"
                key={reviewer}
                isChecked={isCheckboxChecked}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={isCheckboxChecked}
                      onChange={() => handleChange(reviewer)}
                    />
                  }
                  label={selectReviewer(reviewer)}
                />
              </StyledParentCard>
              {isChecked(reviewer) && (
                <ErrorLabel>{errorMessage[reviewer]}</ErrorLabel>
              )}
              <List
                sx={{
                  marginLeft: 4,
                }}
              >
                {isChecked(reviewer) &&
                  teamMembers?.[reviewer as keyof TeamMembers]?.map(
                    (member) => (
                      <ListItem disablePadding key={member}>
                        <ListItemText>{member}</ListItemText>
                      </ListItem>
                    )
                  )}
              </List>
            </Stack>
          );
        })}
      </StyledReviewerContainer>
    </FormGroup>
  );
};

export default React.memo(Reviewers);
