import { cloneDeep } from "lodash";

import { generateUUID } from "../../../../../../../utils/helpers/uuidGenerator";
import { AllocationColumn,AllocationColumnTypes, CustomAllocationsRules, InvestorRowType, StringConstants, TransactionColumnType } from "../../../../workbook.type";
import { CellFormats, ColWidth, NewColPosition } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseColumnsManager } from "./BaseColumns.Manager";

export class AllocationRulesColumnsManager extends BaseColumnsManager {
    private _allocationRulesColumns!: AllocationColumn[];

    public get startColumnId(): string {
        return this.allocationRulesColumns[0].gridColId;
    }

    public get endColumnId(): string {
        return this.allocationRulesColumns[this.allocationRulesColumns.length-1].gridColId;
    }

    public get allocationRulesColumns() {
        return cloneDeep(this._allocationRulesColumns).sort((a,b) => a.quarterNumber - b.quarterNumber || a.index - b.index);
    }

    public initAllocationRulesColumnsFromWorkbook(allocationRulesColumns: AllocationColumn[], initialStartColId?: string) {
        if(this._allocationRulesColumns?.length) {
            throw 'Allocation Rules are already initialized';
        }

        this._allocationRulesColumns = allocationRulesColumns;


        if(!initialStartColId) {
            return;
        }

        let currColId = initialStartColId;

        this._allocationRulesColumns.forEach(ac => {
            ac.gridColId = currColId;

            currColId = this.grid.getNextColId(currColId);
        });
    }

    public insertNewColumnsForQuarter() {
        const currQuarter = this.workbookSheetsManager.lastQuarter;
        const newAllocationCols = cloneDeep(this._allocationRulesColumns.filter(ac => ac.quarterNumber === currQuarter-1));

        let currColId = this.endColumnId;

        newAllocationCols.forEach(allocCol => {
            currColId = this.grid.insertCol(currColId, 1, NewColPosition.rightOfDestination);
    
            allocCol.id = generateUUID();
            allocCol.quarterNumber = currQuarter;
            allocCol.gridColId = currColId;
        });

        this._allocationRulesColumns = this._allocationRulesColumns.concat(newAllocationCols);
    }

    public renderColumns(gridRowIds?: string[], gridColIds?: string[], forceColUpdate = false): void {
        const grid = this.grid;
        const wbManager = this.workbookSheetsManager;
        const commitmentColumns = wbManager.allocationsSheetmanager.commitmentColumnsManager.commitmentColumns;
        const investorRowManager = this.investorRowManager;
        const investorRows = this.investorRowManager.investorRows;
        const allocationColumns = this._allocationRulesColumns;
        const customAllocationsRules = this.workbookSheetsManager.additionalWorkbookProperties?.customAllocationsRules;

        grid.startUpdate();

        for(let q = wbManager.firstQuarter; q <= wbManager.lastQuarter; q++) {
            const quarterCommit = commitmentColumns.find(c => (wbManager.frequency === "BY_YEAR" || c.quarterNumber === q) && c.quarterSum)!;
            const closeColCaption = this.grid.getColCaption(quarterCommit.gridColId);
            const quarterAllocationCols = allocationColumns.filter(ac => ac.quarterNumber === q).sort((a,b) => a.index-b.index);
            const quarterStartColId = quarterAllocationCols[0].gridColId;
            const quarterLastColId = quarterAllocationCols[quarterAllocationCols.length-1].gridColId;
            const qtrDateStr = wbManager.frequency === 'BY_QUARTER' ? `Q${q} Allocation Rules` : `${wbManager.workbookYear} Allocation Rules`;
            const totalPartnerRowId = investorRowManager.getPartnerTotalRow().gridRowId;
            const totalPartnerRowCaption = grid.getRowCaption(totalPartnerRowId);

            this.setQuarterHeaderStyle(quarterStartColId!, quarterLastColId!, qtrDateStr);

            const colsToRender = gridColIds?.length ? quarterAllocationCols.filter(c => !!gridColIds.find(colId => colId === c.gridColId)) : quarterAllocationCols;

            colsToRender.forEach(col => {
                const currColId = col.gridColId;
                const currColCaption = grid.getColCaption(currColId);

                const longStartDateStr = this.getLongDateForQuarter(wbManager.workbookYear, wbManager.frequency === 'BY_QUARTER' ? q : undefined);
                const label = col.label.replace(StringConstants.dynamicDateStr, longStartDateStr);

                this.setColumnStyle(col.gridColId, label,  ColWidth.currency);

                const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

                rowsToRender.forEach(ir => {
                    this.setInvestorTypeTotalRowStyling(ir.gridRowId, col.gridColId, ir.rowType);

                    if(!forceColUpdate && !grid.isCellEmpty(ir.gridRowId, col.gridColId)) return;

                    const currRowId = ir.gridRowId;
                    const currRowCaption = grid.getRowCaption(currRowId);

                    let formula: string|undefined;
                    let cellValue: number = 0;

                    switch(ir.rowType) {
                        case InvestorRowType.Investor: 
                            if(col.transactionType !== AllocationColumnTypes.CUSTOM_FIELD) {
                                if(col.code === 'COMMITMENT_PERCENT') {
                                    formula = `IF(${closeColCaption}${totalPartnerRowCaption}, ${closeColCaption}${currRowCaption}/${closeColCaption}${totalPartnerRowCaption}, 0)`;
                                } else if(col.transactionType === AllocationColumnTypes.INVESTMENT_TYPE) {
                                    const partnerTypeRow = investorRowManager.getPartnerTypeTotalRow(col.code)!;

                                    if(partnerTypeRow && col.code === ir.investorType) {
                                        const partnerTypeRowCaption = grid.getRowCaption(partnerTypeRow.gridRowId);

                                        formula = `IF(${closeColCaption}${partnerTypeRowCaption}, ${closeColCaption}${currRowCaption}/${closeColCaption}${partnerTypeRowCaption}, 0 )`;
                                    }
                                }
                            }
                            break;
                        case InvestorRowType.TypeTotal:
                            const startEndVals = investorRowManager.getInvestorTypeStartEndRowIds(ir.investorType)!;
                            const startCaption = grid.getRowCaption(startEndVals.startRowId);
                            const endCaption = grid.getRowCaption(startEndVals.endRowId);

                            formula = `SUM(${currColCaption}${startCaption}:${currColCaption}${endCaption})`;
                            break;
                        case InvestorRowType.PartnerTotal: {
                            const typeTotalRows = investorRows.filter(r => r.rowType === InvestorRowType.TypeTotal)
                                .map((r) => `${currColCaption}${grid.getRowCaption(r.gridRowId)}`).join(`,`);
        
                            formula = `SUM(${typeTotalRows})`;
                            break;
                        }
                    }

                    if(col.transactionType === AllocationColumnTypes.CUSTOM_FIELD && customAllocationsRules){
                        let rulesData = customAllocationsRules.find((rulesCol: CustomAllocationsRules) => rulesCol.code === col.code && rulesCol.investorId === ir.investorId);

                        if(ir.investorId === "-"){
                            rulesData = customAllocationsRules.find((rulesCol: CustomAllocationsRules) => rulesCol.code === col.code && rulesCol.rowType === ir.rowType);
                        }

                        if(typeof rulesData?.value === 'number'){
                            cellValue = rulesData.value;
                            formula = undefined;
                        } else {
                            formula = rulesData?.value;
                        }
                    }

                    if(formula) {
                        grid.setCellFormula(ir.gridRowId, col.gridColId, formula, CellFormats.Number_Percent_2);
                    } else {
                        grid.setCellValue(ir.gridRowId, col.gridColId, cellValue, CellFormats.Number_Percent_2);
                    }
                });
            });
        }

        grid.endUpdate();
    }

    public rollForwardCustomRules (gridRowIds?: string[], gridColIds?: string[]): void {
        const grid = this.grid;
        const wbManager = this.workbookSheetsManager;
        const investorRows = this.investorRowManager.investorRows;
        const allocationColumns = this._allocationRulesColumns;
        
        const q =  wbManager.lastQuarter;
        const quarterAllocationCols = allocationColumns.filter(ac => ac.quarterNumber === q).sort((a,b) => a.index-b.index);
        const colsToUpdate = gridColIds?.length ? quarterAllocationCols.filter(c => !!gridColIds.find(colId => colId === c.gridColId)) : quarterAllocationCols;

        grid.startUpdate();

        colsToUpdate.forEach(col => {
            const rowsToUpdate = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

            rowsToUpdate.forEach(ir => {
                if(col.transactionType === AllocationColumnTypes.CUSTOM_FIELD){
                    const ruleColId = this._allocationRulesColumns.find(ruleCol => ruleCol.code === col.code && ruleCol.quarterNumber === q - 1);
                    const cellFormula = grid.getCellFormula(ir.gridRowId, ruleColId!.gridColId);

                    if(cellFormula){
                        grid.setCellFormula(ir.gridRowId, col.gridColId, cellFormula, CellFormats.Number_Percent_2);
                    } else {
                        const cellValue = grid.getCellValue(ir.gridRowId, ruleColId!.gridColId);

                        grid.setCellValue(ir.gridRowId, col.gridColId, cellValue, CellFormats.Number_Percent_2);
                    }
                }
            });
        });

        grid.endUpdate();
    }

    public getAllocationsRulesData () {
        const grid = this.grid;
        const wbManager = this.workbookSheetsManager;
        const investorRows = this.investorRowManager.investorRows;
        const allocationColumns = this._allocationRulesColumns;

        const q =  wbManager.lastQuarter;
        const quarterAllocationCols = allocationColumns.filter(ac => ac.quarterNumber === q).sort((a,b) => a.index-b.index);
        const colsToRollOver= quarterAllocationCols.filter(col => col.transactionType === AllocationColumnTypes.CUSTOM_FIELD);


        const customRulesData: CustomAllocationsRules[] = [];

        colsToRollOver.forEach(col => {
            const rowsToRollOver = investorRows;

            rowsToRollOver.forEach(ir => {
                const cellFormula = grid.getCellFormula(ir.gridRowId, col.gridColId);
                const rowData: CustomAllocationsRules = {
                    rowType: ir.rowType,
                    investorId: ir.investorId,
                    investorType: ir.investorType,
                    code: col.code,
                    value: ""
                };

                if(cellFormula){
                    rowData.value = cellFormula;
                } else {
                    const cellValue = grid.getCellValue(ir.gridRowId, col.gridColId);

                    rowData.value = cellValue;
                }
                customRulesData.push(rowData);
            });
        });

        return customRulesData;
    }

    public containsColumn(colId: string): boolean {
        return !!this._allocationRulesColumns.find(c => c.gridColId === colId);
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        return true;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        const wbManager = this.workbookSheetsManager;
        const allocCol = this._allocationRulesColumns.find(ac => ac.gridColId === colId);
        let canEdit: boolean;

        if(allocCol && wbManager.lastQuarter === allocCol.quarterNumber) {
            const transCols = wbManager.allocationsSheetmanager.transactionColumnsManager.transactionColumns;
            const lockedTransCol = transCols
                .find(c => 
                    c.quarterNumber === wbManager.lastQuarter 
                    && c.locked 
                    && c.colType === TransactionColumnType.STANDARD_TRANSACTION_TYPE
                    && c.allocationCode === allocCol.code
                );

            canEdit = !lockedTransCol;

            if(canEdit && parseInt(rowID) === 4) {
                allocCol.label = value;
            }
        } else {
            canEdit = false;
        }

        return canEdit;
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        const grid = this.grid;
        const allocCols = this._allocationRulesColumns;
        const lastCol = allocCols[allocCols.length-1];

        if(toColId !== lastCol.gridColId) {
            return { validColumnPosition: false };
        }

        const newColIDs: string[] = [];
        let currColId = toColId;

        cols.forEach(_col => {
            currColId = grid.insertCol(currColId, 1, NewColPosition.rightOfDestination);
            newColIDs.push(currColId);

            allocCols.push({
                gridColId: currColId,
                index: lastCol.index+1,
                label: 'Custom Allocation Column',
                code: generateUUID(),
                quarterNumber: this.workbookSheetsManager.lastQuarter,
                transactionType: AllocationColumnTypes.CUSTOM_FIELD
            });
        });

        return {
            validColumnPosition: true,
            newColumnIDs: newColIDs
        };
    }

    public onRowAdd(parentRowId: string): boolean {
        return false;
    }

    public onCanColDelete(colId: string): boolean {
        return false;
    }

    public onCanRowDelete(rowID: string): boolean {
        return false;
    }

    public onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }
}