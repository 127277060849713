import { Backdrop, Box, Fade } from "@mui/material";
import { ReactElement } from "react";

import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import { StyledModal } from "../../../components/Progress/ProgressModal/ProgressModal.styles";
import ProgressPanel from "../../../components/Progress/ProgressPanel/ProgressPanel";
import { ProgressText, StyledBox, StyledCircularProgress } from "../../../components/Progress/ProgressPanel/ProgressPanel.styles";


type Props = {
  id: string;
  showProgress: boolean;
  text?: string;
};

const FsProgressModal = (props: Props): ReactElement => {
  const { id, showProgress, text = "Loading..." } = props;

  return (
    <StyledModal
      id={id}
      open={showProgress}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DataWrapperBox gapSize={0} hidden={!showProgress}>
      <Fade in={showProgress} timeout={500}>
      <StyledBox id={id} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <StyledCircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          thickness={4}
        />
        <ProgressText variant="h4" fontWeight={'bold'} marginTop={2}>Generating financial statement package.</ProgressText>
        <ProgressText variant="h5" marginTop={2}>This process may take a few moments. Please stay on the page.</ProgressText>
      </StyledBox>
    </Fade>
      </DataWrapperBox>
    </StyledModal>
  );
};

export default FsProgressModal;
