import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Controller, UseFormReturn } from 'react-hook-form';

import TransactionTypesMultiple from '../../../../../components/Selectors/TransactionTypesFilter/TransactionTypesMultiple';
import { CheckboxLabel } from '../../../ClientDetails/ClientDetails.style';
import { CloseIconBox } from "../../GeneralReportHeader/components/ListView.style";
import { GeneralReportForm } from '../../useReportConfig.hooks';
import {
  BorderBoxContainer,
  LabelBox,
  LabelTextField,
  RcBorderBox,
  RcDataListContainer,
  Spacer,
  SpacerBox,
  SpacerText,
} from "./RcDataList.style";

type Props = {
  selectIndex: number;
  reportForm: UseFormReturn<GeneralReportForm, any>;
  type: string;
  index: number;
  handleRemove: (index: number) => void;
}

const RcDataList = ({
  selectIndex,
  reportForm,
  type,
  index,
  handleRemove,
}: Props): React.ReactElement => {
  const theme = useTheme();

  const handleRemoveItem = (id: number) => {
    handleRemove(id);
  };

  const handleRowsTosumChange = (value: string, checked: boolean) => {
    const current = reportForm?.getValues().reportElements[selectIndex]?.rowsToSum || [];   

    if(checked) {
      reportForm.setValue(`reportElements.${selectIndex}.rowsToSum`, [...current, value], { shouldDirty: true });
    } else {
      const updated = current?.filter(res => res !== value);

      reportForm.setValue(`reportElements.${selectIndex}.rowsToSum`, updated, { shouldDirty: true });
    }
  };

  const renderTypes = () => {

    switch (type) {
      case 'TRANSACTION':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <Box>
              <Controller
                name={`reportElements.${index}.transactionTypes`}
                render={({
                  field: {
                    onChange, value
                  },
                  fieldState: {
                    error
                  }
                }) => (
                  <TransactionTypesMultiple
                    size="small"
                    id={`transaction_type_${index}`}
                    value={value}
                    error={error}
                    idSelectOnly
                    onChange={onChange}
                    placeholder="Transaction Type"
                  />
                )}
                control={reportForm?.control}
              />
            </Box>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <RcBorderBox key={`reportColumns_${index}`}>
                        <Typography variant="borderBoxText">$0</Typography>
                      </RcBorderBox>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box>
              <Controller
                name={`reportElements.${index}.suppressIfZero`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => {
                  return (
                    <CheckboxLabel>
                      <Checkbox
                        checked={value || false}
                        onChange={onChange} />
                      Suppress if &#34;0&#34;
                    </CheckboxLabel>
                  );
                }}
                control={reportForm?.control}
              />
              <br/>
              <Controller
                name={`reportElements.${index}.includeRemainder`}
                render={({
                  field: {
                    value
                  },
                }) => {
                  return (
                    <CheckboxLabel>
                      <Checkbox
                        checked={value || false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                          const value = e.target.checked as boolean;

                          if(value) {
                            reportForm.getValues().reportElements.map((data, ind) => {
                              if(index === ind) {
                                reportForm.setValue(`reportElements.${index}.includeRemainder`, true), { shouldDirty: true };
                              } else {
                                reportForm.setValue(`reportElements.${ind}.includeRemainder`, false, { shouldDirty: true });
                              }
                            });
                          } else {
                            reportForm.setValue(`reportElements.${index}.includeRemainder`, false, { shouldDirty: true });
                          }
                        }} />
                        Include Remainder
                    </CheckboxLabel>
                  );
                }}
                control={reportForm?.control}
              />
            </Box>
            <CloseIconBox>
              {selectIndex === index &&
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <CloseIcon />
                </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      case 'BEGINNING_CUSTOM':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <Box>
              <Controller
                name={`reportElements.${index}.transactionTypes`}
                render={({
                  field: {
                    onChange, value
                  },
                  fieldState: {
                    error
                  }
                }) => (
                  <TransactionTypesMultiple
                    size="small"
                    id={`transaction_type_${index}`}
                    value={value}
                    error={error}
                    idSelectOnly
                    onChange={onChange}
                    placeholder="Transaction Type"
                  />
                )}
                control={reportForm?.control}
              />
            </Box>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <Box key={`reportColumns_${index}`}/>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box/>
            <CloseIconBox>
              {selectIndex === index &&
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <CloseIcon />
                      </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      case 'TextBox':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <Box key={`reportColumns_${index}`}/>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box/>
            <Box/>
            <CloseIconBox>
              {selectIndex === index &&
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <CloseIcon />
                      </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      case 'BEGINNING':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <LabelBox>
              <Typography variant="borderBoxText">Beginning Balance</Typography>
            </LabelBox>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <Box key={`reportColumns_${index}`}/>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box/>
            <CloseIconBox>
              {selectIndex === index &&
                  <IconButton onClick={() => handleRemoveItem(index)}>
                    <CloseIcon />
                  </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>

        );
      case 'SUBTOTAL':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <Box>
              <Controller
                name={`reportElements.${index}.transactionTypes`}
                render={({
                  field: {
                    onChange, value
                  },
                  fieldState: {
                    error
                  }
                }) => (
                  <TransactionTypesMultiple
                    size="small"
                    id={`transaction_type_${index}`}
                    value={value}
                    error={error}
                    idSelectOnly
                    onChange={onChange}
                    placeholder="Transaction Type"
                  />
                )}
                control={reportForm?.control}
              />
              <br/>
              <Controller
                name={`reportElements.${index}.rowsToSum`}
                render={({
                  field: {
                    value
                  }
                }) => (
                  <FormControl fullWidth size="small" >
                    <InputLabel>Report Buckets</InputLabel>
                    <Select label="Report Buckets"
                      multiple
                      value={value || []}
                      renderValue={() => `${value?.length || 0} Selected`}
                      labelId="report_buckets_report"
                      id="report_buckets_report"
                    >
                      {
                        reportForm.getValues()?.reportElements?.map((data, ind: number) => {
                          if(data.type !== 'SUBTOTAL' && data.type !== 'SPACER') {
                            return(
                              <MenuItem
                                key={`menu_list_${ind}`}
                                value={data.label}
                              >
                                <Checkbox
                                  checked={value?.map(res => res === data.label).includes(true)}
                                  onChange={(e) => handleRowsTosumChange(data.label, e.target.checked as boolean)}
                                />
                                {data.label}
                              </MenuItem>
                            );
                          }
                        })
                      }
                    </Select>
                  </FormControl>
                )}
                control={reportForm?.control}
              />
            </Box>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <RcBorderBox key={`reportColumns_${index}`}>
                        <Typography variant="borderBoxText">$0</Typography>
                      </RcBorderBox>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box>
              <Controller
                name={`reportElements.${index}.suppressIfZero`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => {
                  return (
                    <CheckboxLabel>
                      <Checkbox
                        checked={value || false}
                        onChange={onChange} />
                        Suppress if &#34;0&#34;
                    </CheckboxLabel>
                  );
                }}
                control={reportForm?.control}
              />
              <br/>
              <Controller
                name={`reportElements.${index}.includeRemainder`}
                render={({
                  field: {
                    value
                  }
                }) => {
                  return (
                    <CheckboxLabel>
                      <Checkbox
                        checked={value || false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                          const value = e.target.checked as boolean;

                          if(value) {
                            reportForm.getValues().reportElements.map((data, ind) => {
                              if(index === ind) {
                                reportForm.setValue(`reportElements.${index}.includeRemainder`, true, { shouldDirty: true });
                              } else {
                                reportForm.setValue(`reportElements.${ind}.includeRemainder`, false, { shouldDirty: true });
                              }
                            });
                          } else {
                            reportForm.setValue(`reportElements.${index}.includeRemainder`, false, { shouldDirty: true });
                          }
                        }}/>
                      Include Remainder
                    </CheckboxLabel>
                  );
                }}
                control={reportForm?.control}
              />
            </Box>
            <CloseIconBox>
              {selectIndex === index &&
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <CloseIcon />
                </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      case 'ENDING':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <LabelBox>
              <Typography variant="borderBoxText">Ending Balance</Typography>
            </LabelBox>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <Box key={`reportColumns_${index}`}/>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box/>
            <CloseIconBox>
              {selectIndex === index &&
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <CloseIcon />
                </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      case 'SPACER':
        return (
          <SpacerBox>
            <Spacer>
              <SpacerText>Spacer</SpacerText>
            </Spacer>
            <CloseIconBox>
              {selectIndex === index &&
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <CloseIcon />
                </IconButton>
              }
            </CloseIconBox>
          </SpacerBox>
        );
      case 'LABEL':
        return (
          <RcDataListContainer spacingOrder={theme.spacing(0)}>
            <LabelBox>
              <Controller
                name={`reportElements.${index}.label`}
                render={({
                  field: {
                    onChange, value
                  }
                }) => (
                  <LabelTextField styles={reportForm.getValues('reportElements')[index]?.styles}
                    id={`rc_data_list_View`}
                    label="Row Name"
                    size="small"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={reportForm?.control}
              />
            </LabelBox>
            <Box/>
            <Controller
              name={`reportColumns`}
              render={({
                field: {
                  value
                },
              }) => (
                <BorderBoxContainer>
                  {value?.map((data, index) => {
                    return (
                      <Box key={`reportColumns_${index}`}/>
                    );
                  })}
                </BorderBoxContainer>
              )}
              control={reportForm?.control}
            />
            <Box/>
            <CloseIconBox>
              {selectIndex === index &&
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <CloseIcon />
                </IconButton>
              }
            </CloseIconBox>
          </RcDataListContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Box key={`rc_data_list_${index}`}>
      {renderTypes()}
    </Box>
  );
};

export default React.memo(RcDataList);
