export interface IDocuments {
  id: string;
  name: null | string;
  requiresConsent: boolean;
  index: number;
  hasWatermark: boolean | null;
  clientId: null | string;
  createdBy: null | string;
  createdOn: null | string;
  folders: Documents[] | null;
  publishedFiles: Files | null;
  unPublishedFiles: Files | null;
  permissions: Permission[] | null;
  root: boolean;
  isRoot: boolean;
  width?: number;
  type?: string;
  archiveIn?: number | null;
}

export enum DocumentFilter {
  Folder = 'foldersVisibility',
  Archive = 'includeArchived',
  Name = 'names',
  Fund = 'funds',
  Status = 'status',
  Investor = 'investors',
  FileSize = 'fileSizeInBytes',
  Quarter = 'quarters',
  Permission = 'permissions',
}

export enum DocumentTypeEnum {
  Folder = 'folder',
  File = 'file',
}

export type Permission = {
  name: string;
  key: string;
  description?: string;
};

export type DocControlPermissions = {
  printEnabled?: boolean;
  copyEnabled?: boolean;
  downloadEnabled?: boolean;
};

export type FileArchiveSettings = {
  archivePolicy?: string | null;
  archiveOn?: string | null;
};

export type FolderContentRequestPayload = {
  funds: string[];
  investors: string[];
  page: number;
  pageSize: number;
  quarters: string[];
  searchText: string;
  sort: string[];
  showFolders?: boolean | undefined;
  includeArchived: boolean;
};

export type Folder = {
  folders: Documents[] | null;
  hasWatermark: boolean;
  index: number;
  loaded: boolean;
  name: string;
  permissions: Permission[] | null;
  unPublishedFiles: Files | null;
  publishedFiles: Files | null;
};

export type File = {
  id: string;
  name: string;
  fileType: string;
  sizeInBytes: number;
  published: boolean;
  investor: Fund | null;
  fund: Fund;
  quarter: string;
  filePermissions: DocControlPermissions;
  index: number;
  isNew?: boolean;
  isSelected?: boolean;
  folderId?: string;
  clientId?: string;
  width?: number;
};

export type FileV2 = {
  id: string;
  name: string;
  fileType: string;
  sizeInBytes: number;
  published: boolean;
  investors: Array<Fund>;
  funds: Array<Fund>;
  quarter: string;
  index: number;
  taggedForAllInvestors: boolean;
  isNew?: boolean;
  isSelected?: boolean;
};

export type Fund = {
  id: string;
  name: string;
};

export type Files = {
  pageSize: number;
  totalItems: number;
  files: File[];
};

export type FilesV2 = {
  totalItems: number;
  files: FileV2[];
};

export type UploadFileResponse = {
  files: File[];
  failedFiles: Object;
};

export type FileTagParams = {
  investorId?: string;
  fundId?: string;
  quarterid?: string;
  fileIds: Array<string>;
  folderId: string;
};

export type Error = {
  id: string;
  label: string;
  errorMsg: string;
  iconType?: string;
};

export type PublishFileParams = {
  fileIds: Array<string>;
  folderId: string;
};

export enum PublishStatusEnum {
  Publish = 'PUBLISH',
  Unpublish = 'UNPUBLISH',
}

export type DocumentLinkParams = {
  baseDocumentUrl: string;
  ids: Array<string>;
  type: 'DOCUMENT_LINK';
  clientName: string;
};

export type Documents = IDocuments;
export type DocumentType = DocumentTypeEnum;
export type DocFile = File;
export type EmailOrUrl = {
  value: string;
};
export type NoticeUrl = {
  [key: string]: string;
};

export type ConsentQueryParams = {
  clientId: string;
  consentType: string;
};

export type ConsentBodyParams = {
  folderId: string;
  response: boolean;
};

export type FileDetails = {
  size: number;
  name: string;
  fileId: string;
  fileType: string;
  parentFolderId: string;
  permissions: DocControlPermissions;
};

export type ColumnWidths = {
  [key: string]: number;
};

export type AdobeEvent = {
  type: DocEvent;
  data: {
    filename: string;
    pageNumber?: number;
    startPage?: {
      pageNumber: number;
    };
    endPage?: {
      pageNumber: number;
    };
    searchedText?: string;
    copiedText?: string;
  };
};

export enum DocEvent {
  DOCUMENT_OPEN = 'DOCUMENT_OPEN',
  PAGE_VIEW = 'PAGE_VIEW',
  PAGES_IN_VIEW_CHANGE = 'PAGES_IN_VIEW_CHANGE',
  DOCUMENT_PRINT = 'DOCUMENT_PRINT',
  DOCUMENT_DOWNLOAD = 'DOCUMENT_DOWNLOAD',
  TEXT_SEARCH = 'TEXT_SEARCH',
  TEXT_COPY = 'TEXT_COPY',
}

export type DocEventLogData = {
  documentEventType?: DocEvent;
  fileId?: string;
  fileName?: string;
  pageNumber?: number;
  printSupported?: boolean;
  searchedText?: string;
  copiedText?: string;
};

export type DebounceFunction = (event: AdobeEvent) => void;
