import { Backdrop, Box, Fade } from "@mui/material";
import { ReactElement } from "react";

import { BUTTON_COLOR_OPTIONS } from "../../../utils/constants/styles.constants";
import Button from "../../Buttons/Button/Button";
import {
  ModalTitle,
  StyledBox,
  StyledModal,
  StyledSeparator,
} from "./FullScreenModal.styles";

type Props = {
  id: string;
  open: boolean;
  setOpen?: any;
  title: string;
  subtitle: any;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onSuccess?: any;
  onCancel?: any;
  cancelButtonColor?: BUTTON_COLOR_OPTIONS;
};

const FullScreenModal = (props: Props): ReactElement => {
  const {
    id,
    open,
    setOpen = undefined,
    title,
    subtitle,
    confirmButtonText = "",
    cancelButtonText = "",
    onSuccess = undefined,
    onCancel = undefined,
    cancelButtonColor = "error",
  } = props;

  const handleClose = () => {
    if (setOpen !== undefined) setOpen(false);
  };

  return (
    <StyledModal
      id={id}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={handleClose}
    >
      <Fade in={open}>
        <StyledBox>
          <ModalTitle variant="h2">{title}</ModalTitle>
          <ModalTitle variant="h3">{subtitle}</ModalTitle>

          {confirmButtonText && (
            <Box>
              <StyledSeparator />
              <Button
                id={`${id}_btn_confirm`}
                variant={"outlined"}
                text={confirmButtonText}
                color={"primary"}
                fullWidth
                onClick={onSuccess}
              />
            </Box>
          )}

          {cancelButtonText && (
            <Box>
              <StyledSeparator />
              <Button
                id={`${id}_btn_cancel`}
                variant={"outlined"}
                text={cancelButtonText}
                color={cancelButtonColor}
                fullWidth
                onClick={onCancel}
              />
            </Box>
          )}
        </StyledBox>
      </Fade>
    </StyledModal>
  );
};

export default FullScreenModal;
