import { Divider } from '@mui/material';
import React from 'react';
import { Dispatch, FC, SetStateAction } from 'react';

import { ViewOptionChip, ViewOptionsBox } from './ViewOptionsTabs.style';
export type ViewOptionTypes = 'Dashboard' | 'Hybrid' | 'Data View';

export enum ViewOption {
  DASHBOARD = 'Dashboard',
  HYBRID = 'Hybrid',
  DATA_VIEW = 'Data View',
}
interface ViewOptionsProps {
  viewOptions: ViewOption[];
  viewState: string;
  setViewState: Dispatch<SetStateAction<ViewOptionTypes>>;
  handleViewStateChange?: (view: ViewOption) => void;
}

export const ViewOptionsTabs: FC<ViewOptionsProps> = ({
  viewOptions,
  viewState,
  setViewState,
  handleViewStateChange,
}) => {
  return (
    <ViewOptionsBox>
      {viewOptions.map((viewOption, index) => (
        <React.Fragment key={viewOption}>
          <ViewOptionChip
            size="small"
            label={viewOption}
            color={viewState === viewOption ? 'primary' : 'secondary'}
            clickable
            onClick={() =>
              handleViewStateChange
                ? handleViewStateChange(viewOption)
                : setViewState(viewOption)
            }
          />
          {index < viewOptions.length - 1 && (
            <Divider orientation="vertical" variant="middle" flexItem />
          )}
        </React.Fragment>
      ))}
    </ViewOptionsBox>
  );
};
