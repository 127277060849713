import { Quarter } from '../utils/types/filter.type';
import { FundItem } from '../utils/types/fund.type';
import { InvestorFilter } from '../utils/types/investor.type';
import {
  PortfolioCompanyResponse,
  PortfolioCompanySimple,
} from '../utils/types/portfolioCompany.type';
import { StatusFilter } from '../utils/types/statusFilter.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};
const apiClient2 = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

export const getStatusFilterList = async () => {
  const statusFilterList = await apiClient().get<StatusFilter[]>(
    'contacts/status/filters'
  );

  return statusFilterList;
};

export const getFundsFilterList = async () => {
  const fundFilterList = await apiClient()
    .get<FundItem[]>('funds/filters')
    .catch(() => []);

  return fundFilterList;
};

export const getInvestorsFilterList = async () =>
  await apiClient().get<InvestorFilter[]>('investors/filters');

export const getQuartersFilterList = async (
  published: boolean
): Promise<Quarter[]> =>
  await apiClient().get<Quarter[]>(`quarters/filters?published=${published}`);

export const getPortfolioCompanyFilterList = async () => {
  const portfolioCompanyList = await apiClient2().get<PortfolioCompanyResponse>(
    '/entities/portfolio-companies'
  );

  return portfolioCompanyList.items.map((company) => {
    return {
      id: company.id,
      name: company.name,
    };
  });
};

export const getSecurityTypeFilterList = async () => {
  const securityTypeList = await apiClient2().get<any>(
    '/transactions/soi/transactions/security-types'
  );

  return securityTypeList.map((securityType: string) => {
    return {
      id: securityType,
      name: securityType,
    };
  });
};

export const getCustomPropertyTypeFilterList = async () => {
  const customPropertyTypeList = await apiClient2().get<any>(
    `/entities/custom-properties/default-types`
  );

  return customPropertyTypeList.reduce((list: any[], type: any) => {
    if (type.formatList && type.formatList.length > 0) {
      type.formatList.map((subType: any) => {
        list.push({
          id: subType.type,
          name: `${type.label} - ${subType.label}`,
        });
      });
    } else {
      list.push({
        id: type.type,
        name: type.label,
      });
    }
    return list;
  }, []);
};
