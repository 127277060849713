import { Button, Container } from '@mui/material';
import { styled } from '@mui/system';

export const FormContainer = styled(Container)(({ theme }) => ({
  paddingRight: '32px !important',
  paddingLeft: '32px !important',
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'auto auto auto 1fr auto',
  overflow: 'auto',
  gap: theme.spacing(2),
}));

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
}));

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));
