import { cloneDeep } from "lodash";

import { ItdColumn, ITDType, TransactionColumn, TransactionColumnType } from "../../../../workbook.type";
import { CellFormats, ColWidth } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseTransactionsColumnsManager } from "./BaseTransactionsColumns.Manager";

export class YtdColumnsManager extends BaseTransactionsColumnsManager {
    private _ytdTransactionColumns!: ItdColumn[];

    public get ytdTransactionColumns() {
        return cloneDeep(this._ytdTransactionColumns).sort((a,b)=> a.index-b.index);
    }

    public get startColumnId(): string {
        return this.ytdTransactionColumns[0].gridColId;
    }

    public get endColumnId(): string {
        return this.ytdTransactionColumns[this.ytdTransactionColumns.length-1].gridColId;
    }

    public initYtdColumnsData(ytdTransactionColumns: ItdColumn[], transactionColumns?: TransactionColumn[], initialStartColId?: string) {

        if(this.workbookSheetsManager.isNewWorkbook) {
            const newTransactionColumns = this.buildNewTransactionColumns(transactionColumns!, this.workbookSheetsManager.firstQuarter)!;

            let currColId = initialStartColId!;

            this._ytdTransactionColumns = newTransactionColumns.map<ItdColumn>(transCol => {
                let ytdType: ITDType;

                switch(transCol.colType) {
                    case TransactionColumnType.BEGINING_BALANCE:
                        ytdType = ITDType.BEGINING_BALANCE_YTD_TYPE;
                        break;
                    case TransactionColumnType.STANDARD_TRANSACTION_TYPE:
                        ytdType = ITDType.STANDARD_YTD_TYPE;
                        break;
                    case TransactionColumnType.ENDING_BALANCE:
                        ytdType = ITDType.ENDING_BALANCE_YTD_TYPE;
                        break;
                    default: throw `Invalid YTD col type ${transCol.colType}`;
                }

                const newYtd = {
                    colType: ytdType,
                    transTypeId: transCol.transTypeId,
                    index: transCol.index,
                    label: transCol.label,
                    metricSign: transCol.metricSign,
                    gridColId: currColId
                };

                currColId = this.grid.getNextColId(currColId);

                return newYtd;
            });
        } else {
            this._ytdTransactionColumns = ytdTransactionColumns;
        }
    }

    public renderColumns(gridRowIds?: string[], gridColIds?: string[]): void {
        const grid = this.grid;
        const ytdTransactionColumns = this.ytdTransactionColumns;
        const allocationsSheetmanager = this.workbookSheetsManager.allocationsSheetmanager;
        const pitdManager = allocationsSheetmanager.pitdColumnsManager;
        const transactionCols = allocationsSheetmanager.transactionColumnsManager.transactionColumns;        
        const investorRowManager = this.investorRowManager;
        const investorRows = investorRowManager.investorRows; 
        const startColId = ytdTransactionColumns[0].gridColId;
        const lastColId = ytdTransactionColumns[ytdTransactionColumns.length-1].gridColId;
        const pitdStartColCaption = grid.getColCaption(pitdManager.endColumnId);
        const startColCaption = grid.getColCaption(startColId);
        const lastCalcColId = grid.getPrevCol(lastColId);
        const lastCalcColCaption = grid.getColCaption(lastCalcColId);

        this.setQuarterHeaderStyle(startColId, lastColId, `YTD ${this.workbookSheetsManager.workbookYear}`);

        const colsToRender = gridColIds?.length ? ytdTransactionColumns.filter(c => !!gridColIds.find(colId => colId === c.gridColId)) : ytdTransactionColumns;

        grid.startUpdate();

        colsToRender.forEach(col => {
            
            this.setColumnStyle(col.gridColId, col.label, ColWidth.currency);

            const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

            rowsToRender.forEach(ir => {
                this.setInvestorTypeTotalRowStyling(ir.gridRowId, col.gridColId, ir.rowType);
                
                const rowCaption = grid.getRowCaption(ir.gridRowId);
                
                if(col.colType === ITDType.BEGINING_BALANCE_YTD_TYPE) {
                    grid.setCellFormula(ir.gridRowId, col.gridColId, `${pitdStartColCaption}${rowCaption}`, CellFormats.Number_Accounting);
                    return;
                }

                if(col.colType === ITDType.ENDING_BALANCE_YTD_TYPE) {
                    const endBalanceFormula = `SUM(${startColCaption}${rowCaption}:${lastCalcColCaption}${rowCaption})`;

                    grid.setCellFormula(ir.gridRowId, col.gridColId, endBalanceFormula, CellFormats.Number_Accounting);
                    return;
                }

                const transactionColsValues = transactionCols.filter(tc => tc.transTypeId === col.transTypeId).map(tc => `${grid.getColCaption(tc.gridColId)}${rowCaption}`);
                const formula = `SUM(${transactionColsValues.join(',')})`;
                
                grid.setCellFormula(ir.gridRowId, col.gridColId, formula, CellFormats.Number_Accounting);
            });
        });

        grid.endUpdate();
    }

    public containsColumn(colId: string): boolean {
        return !!this._ytdTransactionColumns.find(c => c.gridColId === colId);
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        return false;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        return false;
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        return { validColumnPosition: false };
    }

    public onRowAdd(parentRowId: string): boolean {
        return false;
    }

    public onCanColDelete(colId: string): boolean {
        return false;
    }

    public onCanRowDelete(rowID: string): boolean {
        return false;
    }

    public onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }    
}