import { Box, Container, styled, Typography } from "@mui/material";

export const MainContainer = styled(Box)(() => ({
  height: "100%",
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
  maxWidth: "false"
}));

export const ReportSelectorButtonBox = styled(Box)(() => ({
  display: "flex",
  marginLeft: "50px"
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex"
}));

export const ButtonBoxDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "40% 60%",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "20px"
}));

export const StyledDateFilterBox = styled(Box)(() => ({
  marginTop: "10px",
  marginRight: "8px",
}));

export const GLReportsActionsContainer = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gridGap: '0',

  width: "100%",
  ['@media (min-width: 1300px)']: {
    gridTemplateColumns: 'repeat(auto-fill, 45%)',
  },
  gridTemplateColumns: 'repeat(auto-fill, 100%)',

}));

export const StyledFilterBox = styled(Box)(() => ({
  marginTop: "8px",
  marginRight: "8px",
}));

export const StyledFilterSpacerBox = styled(StyledFilterBox)(() => ({
  width: "15px"
}));

export const NoDataMessageTypography = styled(Typography)(() => ({
  textAlign: "center",
  marginTop: "35px",
  variant: "h3"
}));