import { Box, IconButton, Stack, styled } from "@mui/material";
export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  height: 'calc(100vh - 400px)',
  overflow: "scroll",
}));
export const PdfContainer = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyledStack = styled(Stack)<{ active: boolean }>(
  ({ theme, active }) => ({
    cursor: 'pointer',
    border: active
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.secondary.dark}`,
  })
);

export const FlagButton = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacing(13),
}));
export const DownLoadButton = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacing(7),
}));
