import {
  InvestmentTransaction,
  InvestmentTransactionResponse,
} from '../utils/types/investmentTransaction.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2);
};

export const getInvestmentTransactionNameAvailability = async (
  investmentTransactionName: string
) => {
  const encodedInvestmentTransactionName = encodeURIComponent(
    investmentTransactionName
  );

  return await apiClient().get<boolean>(
    `/transactions/soi/transactions/availability?name=${encodedInvestmentTransactionName}`
  );
};

export const getInvestmentTransaction = async (invTransactionId: string) =>
  await apiClient().get<InvestmentTransaction>(
    `/transactions/soi/transactions/${invTransactionId}`
  );

export const getInvestmentTransactions = async () =>
  await apiClient().get<any>(`/transactions/soi/transactions`);

export const addInvestmentTransaction = async (data: any) =>
  await apiClient().post<any>(`/transactions/soi/transactions`, data);

export const updateInvestmentTransaction = async (
  data: any,
  invTransactionId: string
) =>
  await apiClient().put<any>(
    `/transactions/soi/transactions/${invTransactionId}`,
    data
  );

export const getFollowingTransactionsList = async (invTransactionId: string) =>
  await apiClient().get<any>(
    `/transactions/soi/transactions/${invTransactionId}/all`
  );

export const getInvestmentTransactionsTypes = async () =>
  await apiClient().get<any>(`/transactions/soi/transactions/security-types`);

export const deleteInvestmentTransactionPurchase = async (
  invTransactionId: string
) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions/${invTransactionId}`
  );

export const deleteInvestmentTransactions = async (transactionIds: string[]) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions?transactionIds=${transactionIds.join(',')}`
  );

// Sale
export const addSaleTransaction = async (data: any, invTransactionId: string) =>
  await apiClient().post<any>(
    `/transactions/soi/transactions/${invTransactionId}/sales`,
    data
  );

export const updateSaleTransaction = async (
  data: any,
  invTransactionId: string,
  saleId: string
) =>
  await apiClient().put<any>(
    `/transactions/soi/transactions/${invTransactionId}/sales/${saleId}`,
    data
  );

export const deleteSaleTransaction = async (
  invTransactionId: string,
  saleId: string
) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions/${invTransactionId}/sales/${saleId}`
  );

// Valuation
export const getAllValuationTransactions = async (invTransactionId: string) =>
  await apiClient().get<any>(
    `/transactions/soi/transactions/${invTransactionId}/valuations`
  );

export const getValuationTransaction = async (
  invTransactionId: string,
  valuationId: string
) =>
  await apiClient().get<any>(
    `/transactions/soi/transactions/${invTransactionId}/valuations/${valuationId}`
  );

export const addValuationTransaction = async (
  data: any,
  invTransactionId: string
) =>
  await apiClient().post<any>(
    `/transactions/soi/transactions/${invTransactionId}/valuations`,
    data
  );

export const addMultipleValuationTransaction = async (data: any) =>
  await apiClient().post<any>(
    `/transactions/soi/transactions/valuations`,
    data
  );

export const updateValuationTransaction = async (
  data: any,
  invTransactionId: string,
  valuationId: string
) =>
  await apiClient().put<any>(
    `/transactions/soi/transactions/${invTransactionId}/valuations/${valuationId}`,
    data
  );

export const deleteValuationTransaction = async (
  invTransactionId: string,
  valuationId: string
) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions/${invTransactionId}/valuations/${valuationId}`
  );

// Stock Split

export const addStockSplitTransaction = async (
  data: any,
  invTransactionId: string
) =>
  await apiClient().post<any>(
    `/transactions/soi/transactions/${invTransactionId}/stock-splits`,
    data
  );

export const updateStockSplitTransaction = async (
  data: any,
  invTransactionId: string,
  splitId: string
) =>
  await apiClient().put<any>(
    `/transactions/soi/transactions/${invTransactionId}/stock-splits/${splitId}`,
    data
  );

export const deleteStockSplitTransaction = async (
  invTransactionId: string,
  splitId: string
) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions/${invTransactionId}/stock-splits/${splitId}`
  );

// Conversion

export const addConversionTransaction = async (
  data: any,
  invTransactionId: string
) =>
  await apiClient().post<any>(
    `/transactions/soi/transactions/${invTransactionId}/conversions`,
    data
  );

export const updateConversionTransaction = async (
  data: any,
  invTransactionId: string
) =>
  await apiClient().put<any>(
    `/transactions/soi/transactions/${invTransactionId}/conversions`,
    data
  );

export const deleteConversionTransaction = async (invTransactionId: string) =>
  await apiClient().delete<any>(
    `/transactions/soi/transactions/${invTransactionId}`
  );

//Export

export const downloadFileInvestmentTransaction = async (
  investmentTransactionList: string[],
  fundList: string[],
  type: string
) => {
  let body = Object.assign({});

  body = {
    fundIds: fundList,
    portfolioIds: [],
    ids: investmentTransactionList,
    type: type,
  };

  const templateFile = await apiClient().post(
    'transactions/soi/transactions/downloads',
    body
  );

  return templateFile;
};
