import { Theme } from '@mui/system';

import { ClientTheme } from "../../../core/context/clientThemeContext";
import Button from './Button';
import Typography from './Typography';

const componentsOverride = (theme: Theme, clientTheme?: ClientTheme) =>
  Object.assign({},
    Typography(theme),
    Button(theme, clientTheme),
  );

export default componentsOverride;
