import { useContext, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import {
  addTeamMember,
  editTeamMember,
  getClientTeams,
} from "../../../../services/teams.service.v2";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import {
  SelectedTeamMember,
  TeamInfo,
  TeamMemberData,
} from "../../../../utils/types/team.type";
import {
  CREATE_TEAM_MEMBER_ERROR,
  CREATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER_SUCCESS,
} from "../constants";

type Props = {
  team?: TeamInfo;
  member?: SelectedTeamMember | null;
  onTeamDetailClose: Function;
  setTeamResponse: Function;
  setOpenTeam: Function;
};

export const useTeamMembers = ({
  member,
  team,
  onTeamDetailClose,
  setOpenTeam,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const { state, informationAlert } = useContext(AppContext);
  const { clientId } = state.loginUser;

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm<TeamMemberData>({
    defaultValues: {
      role: "FUND_USER_READ_ONLY",
      employee: null,
      teamId: team?.id || "",
    },
  });

  const { isDirty } = useFormState({
    control,
  });

  // eslint-disable-next-line require-await
  useEffectAsync(async (isCanceled) => {
    if(isCanceled()) return;

    if(member?.teamMembers) {
      setValue("role", member.teamMembers[0].role);
    }
  }, [JSON.stringify(member)]);

  // eslint-disable-next-line require-await
  useEffectAsync(async (isCanceled) => {
    if(isCanceled()) return;

    if(member?.teamMembers) {
      setValue("role", member.teamMembers[0].role);
    }
  }, [JSON.stringify(member)]);

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onTeamDetailClose();
    setShowExitConfirmation(false);
    setLoading(false);
  };

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  const handleCreateMember = (data: any) => {
    if(!data.employee.isFundAdmin && data.role === "FUND_USER_READ_ONLY") {
      return setError("role", { message: "Please select a role." });
    }
    setLoading(true);
    addTeamMember({ ...data, employeeId: data.employee.id })
      .then(async () => {
        const response = await getClientTeams(clientId);
        const teams = response.items ?? [];
        const currentTeam = teams.find(item => item.id === team?.id);

        setOpenTeam(currentTeam);
        informationAlert(CREATE_TEAM_MEMBER_SUCCESS, "success");
        setLoading(false);
        closeDrawer();
      })
      .catch(err => {
        informationAlert(CREATE_TEAM_MEMBER_ERROR, "error");
        setLoading(false);
        if(err?.response?.data?.violations) {
          err.response.data.violations?.map((errItem: any) => setError(errItem.fieldName, { type: "custom", message: errItem.message }));
        }
      });
  };

  const handleEditMember = (data: any) => {
    if(member?.id) {
      setLoading(true);
      editTeamMember({ ...data, employeeId: member.id })
        .then(async () => {
          const response = await getClientTeams(clientId);
          const teams = response.items ?? [];
          const currentTeam = teams.find(item => item.id === team?.id);

          setOpenTeam(currentTeam);
          informationAlert(UPDATE_TEAM_MEMBER_SUCCESS, "success");
          setLoading(false);
          closeDrawer();
        })
        .catch(err => {
          informationAlert(UPDATE_TEAM_MEMBER_ERROR, "error");
          setLoading(false);
        });
    }
  };

  return {
    loading,
    register,
    handleSubmit,
    errors,
    control,
    watch,
    toggleDrawer,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    handleCreateMember,
    handleEditMember,
  };
};
