import { Checkbox, Popover, styled } from "@mui/material";

export const ListFilter = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  marginLeft: "5px",
}));

export const ColumnName = styled("div")(() => ({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  paddingLeft: "5px",
  width: "100%",
  alignItems: "center",
}));

export const FilterItem = styled("div")(({ color }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "10px",
  color: color,
}));

export const FilterAll = styled("div")(() => ({
  marginLeft: "3px",
}));

export const TextInfo = styled("div")(() => ({
  marginTop: "20px",
  marginLeft: "20px",
  marginRight: "20px",
}));

export const FundsContent = styled("div")(() => ({
  maxWidth: "250px",
  maxHeight: "200px",
  overflow: "auto",
}));

export const SplitFilterContent = styled("div")(() => ({
  maxWidth: "500px",
  overflow: "auto",
  display: 'flex'
}));

export const FilterContent = styled("div")`
  max-width: 250px;
  overflow: auto;
  padding: 8px;
  :not(:first-of-type) {
    border-left: 1px solid #3C3C43;
  }
`;

export const IconColumn = styled("div")(() => ({
  marginTop: "5px",
}));

export const ButtonContentEnd = styled("div")(() => ({
  textAlign: "end",
}));

export const PopoverFilter = styled(Popover)`
  .MuiPopover-paper {
    padding: 1%;
  }
`;

export const FilterCheckBox = styled(Checkbox)`
  padding: 2px;
  &.emptyResult {
    opacity: 60%;
  }
`;
