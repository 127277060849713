import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, Checkbox, FormControlLabel, Link, Skeleton } from "@mui/material";
import {
  GridGroupingColDefOverride,
  GridRowId,
  GridRowProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import React, {
  ChangeEvent,
  memo,
  MouseEvent,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import IconAdd from "../../../../assets/images/icons/icon_add_blue.svg";
import IconConfig from "../../../../assets/images/icons/icon_config.svg";
import ArrowPopover from "../../../../components/ArrowPopover/ArrowPopover";
import { DataWrapperBox } from "../../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../../../components/Buttons/Button/Button";
import HeaderSelectionPopover from "../../../../components/DataGrid/headerSelectionPopover/HeaderSelectionPopover";
import ImgIcon from "../../../../components/ImgIcon/ImgIcon";
import FullScreenModal from "../../../../components/Modal/FullScreenModal/FullScreenModal";
import { PageLock } from "../../../../components/PageLock/PageLock";
import SearchBar from "../../../../components/SearchBarWithDebounce/SearchBar";
import { ColumnOrder } from "../../../../utils/types/columnOrder";
import { FundItem } from "../../../../utils/types/fund.type";
import { InvestorFilter } from "../../../../utils/types/investor.type";
import { DataGridColDef, FilterItem } from "../../../../utils/types/listItems";
import { ContactContext } from "../../contactList/ContactList";
import { usePermissionsDataGridEffect } from "./ContactPermissionsDataGrid.hooks";
import {
  AddInvestorsBox,
  ButtonContainer,
  CheckboxOverlay,
  ChildCheckbox,
  ChildRow,
  GridBox,
  InvestorsFilterBox,
  InvestorsListBox,
  ParentCheckbox,
  ParentRow,
  StyledDataGridPro,
  StyledFormControlLabel,
  StyledTypography,
  TopHeaderBox,
  WrapperCheckBox,
} from "./ContactPermissionsDataGrid.styles";

type Props = {
  readonly: boolean;
  investorFilterList: InvestorFilter[];
  roleList: FilterItem[];
  preloadedContactId?: string;
  fundFilters: FundItem[];
  isScreenLocked: boolean;
  setIsScreenLocked?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsGridPermissionChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  contactPermissionColumnOrder?: ColumnOrder | null;
  primaryInvestors?: Set<string>;
  autoHeight?: boolean;
};

type ParentCheckboxProps = {
  id: string;
  title: string;
  checked: boolean;
  indeterminate: boolean;
  disabled: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement | HTMLInputElement>) => void;
  overlay?: ReactElement | null;
};

const ParentCheckboxWrapper = ({
  id,
  title,
  checked = false,
  indeterminate = false,
  disabled = false,
  onChange = (e) => { },
  onClick = (e) => { },
  overlay,
}: ParentCheckboxProps) => {
  return (
    <TopHeaderBox>
      <Box>
        <StyledTypography>{title}</StyledTypography>
      </Box>
      <WrapperCheckBox>
        <ParentCheckbox
          id={id}
          aria-label={title}
          checked={checked}
          indeterminate={indeterminate}
          size="small"
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        {overlay}
      </WrapperCheckBox>
    </TopHeaderBox>
  );
};

type InvestorProp = {
  field: string;
  label: string;
  checked: boolean;
};

type OptionProp = {
  field: string;
  label: string;
  checked: boolean;
  disabled: boolean;
  loading: boolean;
  onChange: (value: boolean) => void;
};
const Options = ({
  field,
  label,
  checked,
  disabled,
  onChange,
  loading,
}: OptionProp) => {
  return loading ? (
    <Skeleton variant="rectangular" />
  ) : (
    <StyledFormControlLabel
      key={field}
      label={label}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          disabled={disabled}
        />
      }
    />
  );
};

const ContactPermissionsDataGrid: React.FC<Props> = (
  props: Props
): ReactElement => {
  const {
    permissionsByInvestorList,
    setPermissionsByInvestorList,
    isContactChanged,
    setIsContactChanged,
  } = useContext(ContactContext);

  const {
    readonly,
    roleList,
    preloadedContactId = "",
    investorFilterList,
    fundFilters,
    isScreenLocked,
    setIsScreenLocked,
    setIsGridPermissionChanged,
    contactPermissionColumnOrder,
    primaryInvestors,
    autoHeight = false
  } = props;

  const [isAlertToUnlockOpen, setIsAlertToUnlockOpen] = useState<boolean>(false);

  const columnHeader = (title: string, roleId: string) => {
    let partialSelected: boolean = false;
    let noSelectedRoles: boolean = true;
    let itemFound: boolean = false;

    for (let i = 0; i < permissionsByInvestorList.length; i++) {
      const investor = permissionsByInvestorList[i];

      if (investor[roleId] === true) {
        itemFound = true;
        noSelectedRoles = false;
      } else if (investor[roleId] === false) {
        itemFound = true;
        partialSelected = true;
        noSelectedRoles = true;
        break;
      }
    }

    return (
      <ParentCheckboxWrapper
        id={roleId}
        title={title}
        checked={itemFound && noSelectedRoles === false}
        indeterminate={noSelectedRoles ? false : itemFound && partialSelected}
        disabled={readonly || isScreenLocked}
        onChange={(event: any) => {
          event.stopPropagation();
          if (readonly) return;
          onParentCheckboxChange(event, roleId);
          setIsGridPermissionChanged?.(true);
        }}
        onClick={(event: any) => {
          event.stopPropagation();
          if (readonly) return;
        }}
        overlay={
          readonly ? null : (
            <CheckboxOverlay
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsAlertToUnlockOpen(isScreenLocked);
              }}
            />
          )
        }
      />
    );
  };

  const headerActions = (headerList: Array<DataGridColDef>) => {
    return (
      <HeaderSelectionPopover
        id="contact_permissions_column_selector"
        headerFields={headerList}
        handleUpdateHeader={handleUpdateHeader}
        icon={IconConfig}
      />
    );
  };

  const childCheckbox = (
    value: boolean,
    roleId: string,
    fundId: string,
    isGridLocked: boolean
  ) => {
    let isUndeterminate = false;
    let checked = 0;
    let children = 0;

    if (!fundId.includes("_")) {
      for (const investor of permissionsByInvestorList) {
        if (investor.hierarchy.length > 1 && investor.hierarchy[0] === fundId) {
          children++;
          if (investor[roleId] === true) {
            checked++;
          }
        }
      }

      if (checked > 0 && checked !== children) isUndeterminate = true;
    }

    return (
      <WrapperCheckBox>
        <ChildCheckbox
          id={fundId + "_" + roleId}
          aria-label="Fund"
          checked={value}
          indeterminate={isUndeterminate}
          size="small"
          onChange={(event) => {
            onChildCheckboxChange(event, roleId, fundId);
            setIsGridPermissionChanged?.(true);
          }}
          disabled={readonly || (isScreenLocked && !preloadedContactId)}
        />
        {!readonly && (
          <CheckboxOverlay
            onClick={(e) => setIsAlertToUnlockOpen(isScreenLocked)}
          />
        )}
      </WrapperCheckBox>
    );
  };

  const {
    gridColumns,
    anchorEl,
    setAnchorEl,
    showAddInvestorsPopover,
    filteredInvestorFilterList,
    investorFilterText,
    setInvestorFilterText,
    handleOnSearchTextChange,
    investorOptionCheck,
    handleOnInvestorClear,
    handleUpdateHeader,
    onRowColumnOrderChange,
  } = usePermissionsDataGridEffect(
    readonly,
    roleList,
    childCheckbox,
    columnHeader,
    headerActions,
    permissionsByInvestorList,
    setPermissionsByInvestorList,
    investorFilterList,
    fundFilters,
    isContactChanged,
    setIsContactChanged,
    contactPermissionColumnOrder,
    primaryInvestors
  );

  interface CustomRowProps extends GridRowProps {
    rowId: GridRowId;
  }

  const CustomRow = (props: CustomRowProps) => {
    const apiRef = useGridApiContext();

    const { rowId } = props;
    const rowNode = apiRef.current.getRowNode(rowId);

    return (
      <>
        {(rowNode?.depth ?? 0) > 0 ? (
          <ChildRow {...props} />
        ) : (
          <ParentRow {...props} />
        )}
      </>
    );
  };

  const onChildCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    roleId: string,
    investorAndFundId: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isContactChanged) {
      setIsContactChanged(true);
    }
    if (!investorAndFundId.includes("_")) {
      const fundList = [];
      let roleValueDefined = false;
      let roleValue = false;

      for (let i = 0; i < permissionsByInvestorList.length; i++) {
        const investor = permissionsByInvestorList[i];

        if (investor.id.includes(investorAndFundId)) {
          if (!roleValueDefined) {
            roleValue = investor[roleId] || false;
          }
          fundList.push({
            ...investor,
            [roleId]: !roleValue,
          });
          roleValueDefined = true;
        } else {
          fundList.push(investor);
        }
      }
      setPermissionsByInvestorList(fundList);
    } else {
      const selectedFund = permissionsByInvestorList.find(
        (investor) => investor.id === investorAndFundId
      );

      if (selectedFund) {
        selectedFund[roleId] = event.target.checked;

        const parentId: string = selectedFund.hierarchy[0];
        let checkedChildren = false;
        let parentInvestor: any;

        for (let i = 0; i < permissionsByInvestorList.length; i++) {
          const investor = permissionsByInvestorList[i];

          if (investor.id === parentId) {
            parentInvestor = investor;
          }
          if (
            investor.hierarchy.length > 1 &&
            investor.hierarchy[0] === parentId &&
            investor[roleId] === true
          ) {
            checkedChildren = true;
            break;
          }
        }

        if (parentInvestor) {
          parentInvestor![roleId] = checkedChildren;
        }

        const fundList = [];

        for (let i = 0; i < permissionsByInvestorList.length; i++) {
          const investor = permissionsByInvestorList[i];

          if (investor.id === selectedFund.id) {
            fundList.push(selectedFund);
          } else if (investor.id === parentInvestor.id) {
            fundList.push(parentInvestor);
          } else fundList.push(investor);
        }

        setPermissionsByInvestorList(fundList);
      }
    }
  };

  const onParentCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    roleId: string
  ) => {
    event.stopPropagation();
    event.preventDefault();

    const investorPermissions = [
      ...permissionsByInvestorList.map((investor) => ({
        ...investor,
        [roleId]: event.target.checked,
      })),
    ];

    if (!isContactChanged) {
      setIsContactChanged(true);
    }
    setPermissionsByInvestorList(investorPermissions);
  };

  const enableGrid = () => {
    const isLocked = !isScreenLocked;

    setIsScreenLocked?.(isLocked);

    const investorPermissions = [
      ...permissionsByInvestorList.map((investor) => ({
        ...investor,
        isLocked,
      })),
    ];

    setPermissionsByInvestorList(investorPermissions);
  };

  const getTreeDataPath = (row: any) => row.hierarchy;

  const onAddInvestorsClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseArrowPopover = () => {
    setAnchorEl(null);
  };

  const RenderOption = ({ index, style, ...props }: any) => {
    const item: InvestorProp = filteredInvestorFilterList[index];
    const loading = !isItemLoaded(index);

    return React.useMemo(
      () => (
        <div style={style}>
          <Options
            disabled={readonly}
            field={item.field}
            label={item.label}
            checked={item.checked}
            loading={loading}
            onChange={(value: boolean) => {
              investorOptionCheck(item.field, value);
              setIsGridPermissionChanged?.(true);
            }}
          />
        </div>
      ),
      [item, loading]
    );
  };

  const CustomGroupingColDef: GridGroupingColDefOverride = {
    headerName: "Investors",
    valueGetter: (params) => params.row.name,
  };

  const [optionState, setOptionState] = React.useState<any>({
    loading: false,
    hasNext: true,
    items: [],
  });

  const loadMoreItems = (...args: any[]) => {
    if (optionState.hasNext === false) return;
    setOptionState((state: any) => ({
      ...state,
      loading: true,
    }));
  };

  const isItemLoaded = (index: number): boolean => !optionState.hasNext || index < optionState.items.length;

  React.useEffect(() => {
    if (optionState.hasNext && optionState.loading) {
      setTimeout(() => {
        setOptionState((state: any) => ({
          hasNext: state.items.length < filteredInvestorFilterList.length,
          loading: false,
          items: [
            ...state.items,
            ...filteredInvestorFilterList.slice(
              state.items.length,
              state.items.length + 10
            ),
          ],
        }));
      }, 700);
    }
  }, [optionState.hasNext, optionState.loading]);

  const columnVisibilityModel = useMemo(
    () =>
      gridColumns?.reduce((acc, item) => {
        return {
          ...acc,
          [item.field]: !item.hide,
        };
      }, {}),
    [gridColumns]
  );

  return (
    permissionsByInvestorList && (
      <>
        <FullScreenModal
          id="dialog_unlock_permission_confirmation"
          open={isAlertToUnlockOpen}
          title="Unlock permissions to make changes"
          subtitle={undefined}
          confirmButtonText="Unlock"
          cancelButtonText="Cancel"
          onSuccess={() => {
            enableGrid();
            setIsAlertToUnlockOpen(false);
          }}
          onCancel={() => setIsAlertToUnlockOpen(false)}
        />
        <GridBox>
          <StyledDataGridPro
            filterMode="client"
            density="compact"
            columnVisibilityModel={columnVisibilityModel}
            columns={gridColumns}
            rows={permissionsByInvestorList}
            aria-label="Data List"
            treeData
            getTreeDataPath={getTreeDataPath}
            components={{
              Row: CustomRow,
              ColumnUnsortedIcon: UnfoldMoreIcon,
            }}
            groupingColDef={CustomGroupingColDef}
            rowsPerPageOptions={[]}
            autoHeight={autoHeight}
            disableChildrenFiltering
            disableColumnMenu={true}
            rowamount={permissionsByInvestorList.length}
            onColumnOrderChange={onRowColumnOrderChange}
            pageSize={50}
            pagination
            initialState={{
              pinnedColumns: {
                right: ["action"],
              },
            }}
          />
        </GridBox>

        {!readonly && !isScreenLocked && (
          <AddInvestorsBox>
            <Link
              id="btn_add_investors"
              underline="none"
              component="button"
              onClick={onAddInvestorsClick}
            >
              <ImgIcon icon={IconAdd} />
              {" Add or Remove Investors"}
            </Link>
          </AddInvestorsBox>
        )}

        {!preloadedContactId && !readonly && (
          <PageLock
            locked={isScreenLocked}
            disabled={false}
            onChange={() => enableGrid()}
          />
        )}

        <ArrowPopover
          id="selector_add_investors"
          anchorEl={anchorEl}
          showPopover={Boolean(anchorEl)}
          handleOnPopoverClose={handleCloseArrowPopover}
          verticalOrigin="top"
          transformOrigin="bottom"
          orientation="left"
          content={
            <InvestorsFilterBox>
              <ButtonContainer>
                <Button
                  id={"btn_contact_permissions_investors_clear"}
                  variant={"text"}
                  text={"Clear"}
                  color={"secondary"}
                  onClick={handleOnInvestorClear}
                />
              </ButtonContainer>

              <SearchBar
                id="contact_permissions_investors_search"
                onChange={handleOnSearchTextChange}
                value={investorFilterText || ""}
              />

              <InvestorsListBox>
                <FormControlLabel
                  label="All"
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        !filteredInvestorFilterList.find(
                          (item) => !item.checked
                        )
                      }
                      disabled={readonly}
                      onChange={(e) => {
                        investorOptionCheck("All", e.target.checked);
                        setIsGridPermissionChanged?.(true);
                      }}
                    />
                  }
                />
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={filteredInvestorFilterList.length}
                  loadMoreItems={
                    optionState.loading ? () => { } : loadMoreItems
                  }
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      className="List"
                      height={300}
                      itemCount={filteredInvestorFilterList.length}
                      itemSize={60}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width="100%"
                    >
                      {RenderOption}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              </InvestorsListBox>
            </InvestorsFilterBox>
          }
        />
      </>
    )
  );
};

export default memo(ContactPermissionsDataGrid);
