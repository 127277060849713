import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { DataWrapperBox } from "../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../components/Buttons/Button/Button";
import DataGrid from "../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../components/Modal/ConfirmationDialog";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import RoutingPaths from "../../core/routing/routingPaths";
import { CapitalCallDetailsTabs } from "./capitalCallDetails/constants";
import { HeaderRow } from "./CapitalCalls.styles";
import { useCapitalCalls } from "./useCapitalCalls.hooks";

const CapitalCalls: React.FC = (): ReactElement => {
  const {
    headerList,
    list,
    handleFilter,
    activeHeaderFields,
    handleUpdateHeader,
    loading,
    discardStatusUpdate,
    updateStatus,
    statusUpdateCapitalCalls,
    statusAction,
    openCapitalCallSetUp,
    discardOpenCapitalCallSetUp,
    isSuperAdminOrClientAdmin,
  } = useCapitalCalls();

  const history = useHistory();

  return (
    <DataWrapperBox id="capital_calls_list_main">
      <Progress id="capital_calls_list_loading" showProgress={loading} />
      <HeaderRow>
        <Typography variant="pageTitle">Capital Calls</Typography>
        {isSuperAdminOrClientAdmin && (
          <Button
            id="btn_add_new_capital_calls_button"
            variant="contained"
            icon={<AddIcon />}
            text="Start New Capital Call"
            color={"primary"}
            onClick={() => {
              history.push(
                `${RoutingPaths.CapitalCalls}/new/${CapitalCallDetailsTabs.CapitalCallSetUp}`
              );
            }}
          />
        )}
      </HeaderRow>
      <DataGrid
        id="captal_calls_data_grid"
        dataList={list || []}
        noDataMessage={loading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        autoHeight={false}
        activeHeaderFields={activeHeaderFields}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
      />
      <ConfirmationDialog
        open={!!statusUpdateCapitalCalls}
        onClose={discardStatusUpdate}
        id="toggle_capital_calls_status_confirmation"
        actions={[
          {
            label: "Update Status",
            onClick: updateStatus,
            id: "toggle_capital_calls_status_confirmation_update",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: discardStatusUpdate,
            id: "toggle_capital_calls_status_confirmation_cancel",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`You are about to ${statusAction} this Capital Call batch. Would you like to continue? If you are unsure of the implications of this function, please reach out to your systems team before continuing.`}
        title="Update Status?"
      />
      <ConfirmationDialog
        open={!!openCapitalCallSetUp}
        onClose={discardOpenCapitalCallSetUp}
        id="toggle_capital_calls_setup_open_confirmation"
        actions={[
          {
            label: "Yes, skip to transaction",
            linkTo: `${RoutingPaths.CapitalCalls}/${openCapitalCallSetUp}/${CapitalCallDetailsTabs.AddTransactions}`,
            id: "toggle_open_skip_to_transaction_confirmation",
            variant: "contained",
            color: "primary",
          },
          {
            label: "No, I’ll start from the beginning",
            linkTo: `${RoutingPaths.CapitalCalls}/${openCapitalCallSetUp}/${CapitalCallDetailsTabs.CapitalCallSetUp}`,
            id: "toggle_open_start_from_beginning_confirmation",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={`Do you want to skip right to Add Transactions? You will still be able to view all sections`}
        title="To speed up the review process"
      />
    </DataWrapperBox>
  );
};

export default React.memo(CapitalCalls);
