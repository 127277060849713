import { cloneDeep } from 'lodash';
import { Dispatch, useMemo, useReducer } from 'react';

import {
  addNewFolder,
  changeFolderIndex,
  consentAction,
  deleteFiles,
  deletingFolder,
  downloadFilesAndFolder,
  downloadFilesLinks,
  fetchConsent,
  getDocumentFolderPermissions,
  getDocuments,
  getDocumentsFolderFilesV2,
  getDocumentsFolders,
  getDocumentTree,
  getFileDetails,
  moveFiles,
  publishFiles,
  setDocControlPermissions,
  updateFiles,
  updateFolder,
} from '../../../services/documents.service';
import {
  getInvestorsFilterList,
  getQuartersFilterList,
} from '../../../services/filters.service';
import { getFundFilters, getFunds } from '../../../services/fund.service';
import { getInvestorsByStatus } from '../../../services/investor.service';
import downloadFile from '../../../utils/helpers/fileDownloader';
import { DateTimeFormat } from '../../../utils/helpers/format.helper';
import {
  ConsentBodyParams,
  ConsentQueryParams,
  DocControlPermissions,
  DocumentFilter,
  DocumentLinkParams,
  Documents,
  Folder,
  FolderContentRequestPayload,
  Permission,
  PublishFileParams,
  PublishStatusEnum,
} from '../../../utils/types/documents.type';
import { Quarter } from '../../../utils/types/filter.type';
import { FundFilters } from '../../../utils/types/fund.type';
import { Fund } from '../../../utils/types/fund.v2.type';
import { Investor, InvestorFilter } from '../../../utils/types/investor.type';
import { actionTypes } from '../constants';
import {
  DocumentsAction,
  DocumentsState,
  SelectedDocumentVariant,
} from '../types';

const none = {
  id: '(none)',
  name: '(none)',
};

export const defaultPageSize = 100;

const includeArchivedSessionStorage = sessionStorage.getItem(
  'includeArkArchivedFilesSessionStorage'
);

const initialState: DocumentsState = {
  isInitializing: false,
  isLoading: false,
  isPageLocked: true,
  isReadOnly: false,
  isCreating: false,
  isDownloading: false,
  isDeleting: false,
  documents: null,
  tree: null,
  currentFolder: null,
  permissions: null,
  consent: null,
  cursor: [],
  availableOptions: {
    funds: [],
    investors: [],
    quarters: [],
  },
  filtersData: {
    funds: [],
    investors: [],
    quarters: [],
  },
  selectedFilters: {
    funds: [],
    investors: [],
    quarters: [],
    searchText: '',
    showFolders: true,
    includeArchived: includeArchivedSessionStorage === 'true' ? true : false,
  },
  selectedPermissionsFilter: [],
  selectedNameFilter: [],
  selectedPermissions: [],
  selectedRows: [],
  selectedDocumentVariant: [],
  pagination: {
    page: 1,
    pageSize: defaultPageSize,
    isLastPage: false,
  },
  sort: [],
};

function reducer(state: DocumentsState, action: DocumentsAction) {
  let currentFolder = state.currentFolder;
  let documents = state.documents;
  const names = [
    ...(currentFolder?.folders?.map((folder) => ({
      name: folder.name,
      id: folder.id,
    })) || []),
    ...(currentFolder?.publishedFiles?.files?.map((file) => ({
      name: file.name,
      id: file.id,
    })) || []),
    ...(currentFolder?.unPublishedFiles?.files?.map((file) => ({
      name: file.name,
      id: file.id,
    })) || []),
  ];

  switch (action.type) {
    case actionTypes.initialize:
      return {
        ...state,
        isInitializing: true,
      };

    case actionTypes.loading:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.initialized:
      return {
        ...state,
        isInitializing: false,
      };

    case actionTypes.fetchDocuments:
      return { ...state, documents: action.payload, isLoading: false };

    case actionTypes.fetchPermissions:
      return {
        ...state,
        permissions: action.payload,
      };

    case actionTypes.openFolder:
      return {
        ...state,
        isLoading: true,
        cursor: state.cursor.includes(action.payload)
          ? [...state.cursor]
          : [...state.cursor, action.payload],
      };

    case actionTypes.fetchDocumentOptions:
      return {
        ...state,
        availableOptions: {
          ...state.availableOptions,
          funds: [...(action.payload?.funds || [])],
          investors: [...(action.payload?.investors || [])],
          quarters: [...(action.payload?.quarters || [])],
        },
        isLoading: false,
      };

    case actionTypes.fetchUserDocumentFilters:
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          funds: [...(action.payload.funds || [])],
          investors: [...(action.payload.investors || [])],
          quarters: [...(action.payload.quarters || [])],
        },
        isLoading: false,
      };

    case actionTypes.resetFiltersSelection:
      if (action.payload.allSelected) {
        const page: number =
          action.payload.page || initialState.pagination.page || 1;

        return {
          ...state,
          selectedPermissionsFilter: [
            ...(state.permissions?.map((item) => item.key) || []),
            none.id,
          ],
          selectedFilters: {
            ...initialState.selectedFilters,
            funds: [...state.filtersData.funds.map(({ id }) => id)],
            investors: [...state.filtersData.investors.map(({ id }) => id)],
            quarters: [...state.filtersData.quarters.map(({ name }) => name)],
            includeArchived: state.selectedFilters.includeArchived,
          },
          selectedNameFilter: [...names.map(({ id }) => id)],
          pagination: {
            ...initialState.pagination,
            page: page,
          },
          sort: initialState.sort,
        };
      }
      return {
        ...state,
        selectedNameFilter: [...names.map(({ id }) => id)],
        selectedPermissionsFilter: [
          ...(state.permissions?.map((item) => item.key) || []),
          none.id,
        ],
        selectedFilters: {
          ...initialState.selectedFilters,
          includeArchived: state.selectedFilters.includeArchived,
        },
      };

    case actionTypes.fetchConsent:
      return {
        ...state,
        consent: action.payload,
      };

    case actionTypes.consentConfirmation:
      if (
        currentFolder?.folders &&
        currentFolder.folders?.findIndex(
          (folder) => folder.id === action.payload.id
        ) !== -1
      ) {
        currentFolder = {
          ...currentFolder,
          folders: currentFolder.folders.map((folder) =>
            folder.id === action.payload.id
              ? { ...folder, ...action.payload }
              : folder
          ),
        };
      } else if (
        documents?.folders?.findIndex &&
        documents?.folders?.findIndex(
          (folder) => folder.id === action.payload.id
        ) !== -1
      ) {
        documents = {
          ...documents,
          folders: documents.folders.map((folder) =>
            folder.id === action.payload.id
              ? { ...folder, ...action.payload }
              : folder
          ),
        };
      }
      return {
        ...state,
        isLoading: false,
        currentFolder,
        documents,
      };

    case actionTypes.updateFilterParams:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...action.payload.selectedFilters,
        },
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        sort: action.payload.sort || state.sort,
        selectedNameFilter: [...names.map(({ id }) => id)],
      };

    case actionTypes.downloadDocLinks:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.publishFiles:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.toggleFileStatus:
      if (currentFolder?.id && currentFolder?.id === action.payload.folderId) {
        currentFolder = {
          ...currentFolder,
          ...(currentFolder.publishedFiles
            ? {
                publishedFiles: {
                  pageSize: currentFolder.publishedFiles?.pageSize || 0,
                  totalItems: currentFolder.publishedFiles?.totalItems || 0,
                  files: currentFolder.publishedFiles?.files?.map((file: any) =>
                    file.id === action.payload.fileId
                      ? { ...file, published: action.payload.status }
                      : file
                  ),
                },
              }
            : { publishedFiles: currentFolder.publishedFiles }),
          ...(currentFolder.unPublishedFiles
            ? {
                unPublishedFiles: {
                  pageSize: currentFolder.unPublishedFiles?.pageSize || 0,
                  totalItems: currentFolder.unPublishedFiles?.totalItems || 0,
                  files: currentFolder.unPublishedFiles?.files?.map(
                    (file: any) =>
                      file.id === action.payload.fileId
                        ? { ...file, published: action.payload.status }
                        : file
                  ),
                },
              }
            : { unPublishedFiles: currentFolder.unPublishedFiles }),
        };
      }

      return {
        ...state,
        currentFolder,
        isLoading: false,
      };

    case actionTypes.toggleDocControlPermissions:
      const row = action.payload.row;

      if (
        currentFolder?.id &&
        currentFolder?.id === row.folderId &&
        row.published
      ) {
        currentFolder = {
          ...currentFolder,
          ...(currentFolder.publishedFiles
            ? {
                publishedFiles: {
                  pageSize: currentFolder.publishedFiles?.pageSize || 0,
                  totalItems: currentFolder.publishedFiles?.totalItems || 0,
                  files: currentFolder.publishedFiles?.files?.map((file: any) =>
                    file.id === row.id
                      ? {
                          ...file,
                          filePermissions: {
                            ...file.filePermissions,
                            ...action.payload.permissions,
                          },
                        }
                      : file
                  ),
                },
              }
            : { publishedFiles: currentFolder.publishedFiles }),
        };
      } else if (currentFolder) {
        currentFolder = {
          ...currentFolder,
          ...(currentFolder.unPublishedFiles
            ? {
                unPublishedFiles: {
                  pageSize: currentFolder.unPublishedFiles?.pageSize || 0,
                  totalItems: currentFolder.unPublishedFiles?.totalItems || 0,
                  files: currentFolder.unPublishedFiles?.files?.map(
                    (file: any) =>
                      file.id === row.id
                        ? {
                            ...file,
                            filePermissions: {
                              ...file.filePermissions,
                              ...action.payload.permissions,
                            },
                          }
                        : file
                  ),
                },
              }
            : { unPublishedFiles: currentFolder.unPublishedFiles }),
        };
      }

      return {
        ...state,
        currentFolder,
        documents,
      };

    case actionTypes.toggleFileArchiveSettings:
      if (
        currentFolder?.id &&
        currentFolder?.id === action.payload.row.folderId &&
        action.payload.row.published
      ) {
        currentFolder = {
          ...currentFolder,
          ...(currentFolder.publishedFiles
            ? {
                publishedFiles: {
                  pageSize: currentFolder.publishedFiles?.pageSize || 0,
                  totalItems: currentFolder.publishedFiles?.totalItems || 0,
                  files: currentFolder.publishedFiles?.files?.map((file: any) =>
                    file.id === action.payload.row.id
                      ? {
                          ...file,
                          archivePolicy: action.payload.row.archivePolicy,
                          archiveOn: action.payload.row.archiveOn,
                        }
                      : file
                  ),
                },
              }
            : { publishedFiles: currentFolder.publishedFiles }),
        };
      } else if (currentFolder) {
        currentFolder = {
          ...currentFolder,
          ...(currentFolder.unPublishedFiles
            ? {
                unPublishedFiles: {
                  pageSize: currentFolder.unPublishedFiles?.pageSize || 0,
                  totalItems: currentFolder.unPublishedFiles?.totalItems || 0,
                  files: currentFolder.unPublishedFiles?.files?.map(
                    (file: any) =>
                      file.id === action.payload.row.id
                        ? {
                            ...file,
                            archivePolicy: action.payload.row.archivePolicy,
                            archiveOn: action.payload.row.archiveOn,
                          }
                        : file
                  ),
                },
              }
            : { unPublishedFiles: currentFolder.unPublishedFiles }),
        };
      }

      return {
        ...state,
        currentFolder,
        documents,
      };

    case actionTypes.toggleFoldersVisibility:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          showFolders: action.payload,
        },
      };

    case actionTypes.toggleArchivedVisibility:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          includeArchived: action.payload,
        },
      };

    case actionTypes.toggleWaterMark:
      if (
        currentFolder?.folders?.findIndex &&
        currentFolder?.folders?.findIndex(
          (folder) => folder.id === action.payload.id
        ) !== -1
      ) {
        currentFolder = {
          ...currentFolder,
          folders: currentFolder.folders.map((folder) =>
            folder.id === action.payload.id ? action.payload : folder
          ),
        };
      } else if (
        documents?.folders?.findIndex &&
        documents?.folders?.findIndex(
          (folder) => folder.id === action.payload.id
        ) !== -1
      ) {
        documents = {
          ...documents,
          folders: documents.folders.map((folder) =>
            folder.id === action.payload.id ? action.payload : folder
          ),
        };
      }
      return {
        ...state,
        currentFolder,
        documents,
      };

    case actionTypes.fetchFolderContent: {
      const clonedState: DocumentsState = cloneDeep(state);

      let pointer = clonedState.documents;

      for (const cursor of state.cursor) {
        pointer = pointer?.['folders']?.find((folder) => folder.id === cursor);
      }
      clonedState.currentFolder = pointer;
      let totalItems = 0;

      if (pointer) {
        pointer.folders =
          action.payload?.documents?.folders || pointer.folders || [];
        if (action.payload.publishedFiles) {
          totalItems += action.payload?.publishedFiles?.totalItems || 0;

          pointer.publishedFiles =
            action.payload.page === 1
              ? action.payload.publishedFiles
              : {
                  pageSize: action.payload?.publishedFiles?.pageSize || 0,
                  totalItems: action.payload?.publishedFiles?.totalItems || 0,
                  files: [
                    ...(pointer?.publishedFiles?.files || []),
                    ...action.payload.publishedFiles.files,
                  ],
                };
        }
        if (action.payload.unPublishedFiles) {
          totalItems += action.payload?.unPublishedFiles?.totalItems || 0;
          pointer.unPublishedFiles =
            action.payload.page === 1
              ? action.payload.unPublishedFiles
              : {
                  pageSize: action.payload?.unPublishedFiles?.pageSize || 0,
                  totalItems: action.payload?.unPublishedFiles?.totalItems || 0,
                  files: [
                    ...(pointer?.unPublishedFiles?.files || []),
                    ...action.payload.unPublishedFiles.files,
                  ],
                };
        }
      }

      const isLastPage =
        totalItems <=
        state.pagination.pageSize *
          (action.payload.page || state.pagination.page);
      const updatedNameList = [
        ...(pointer?.folders?.map((folder) => ({
          name: folder.name,
          id: folder.id,
        })) || []),
        ...(pointer?.publishedFiles?.files?.map((file) => ({
          name: file.name,
          id: file.id,
        })) || []),
        ...(pointer?.unPublishedFiles?.files?.map((file) => ({
          name: file.name,
          id: file.id,
        })) || []),
      ];

      clonedState.selectedNameFilter = [...updatedNameList.map(({ id }) => id)];
      clonedState.pagination.isLastPage = isLastPage;
      clonedState.pagination.page =
        action.payload.page || clonedState.pagination.page;
      clonedState.isLoading = false;
      return clonedState;
    }

    case actionTypes.changeFolderIndex:
      if (
        state.currentFolder &&
        state.currentFolder.folders &&
        state.currentFolder.id === action.payload.documentId
      ) {
        currentFolder = {
          ...state.currentFolder,
          folders: state.currentFolder?.folders
            ?.map((folder: any) => {
              const updatedIndex = action.payload.folders.find(
                (updtFolder: any) => updtFolder.id === folder.id
              );

              return {
                ...folder,
                index: updatedIndex.index,
              };
            })
            .sort((a, b) => a.index - b.index),
        };
      }
      if (
        state.cursor.length === 0 &&
        documents &&
        documents.folders &&
        documents.id === action.payload.documentId
      ) {
        documents = {
          ...documents,
          folders: documents?.folders
            .map((folder: any) => {
              const updatedIndex = action.payload.folders.find(
                (updtFolder: any) => updtFolder.id === folder.id
              );

              return {
                ...folder,
                index: updatedIndex.index,
              };
            })
            .sort((a, b) => a.index - b.index),
        };
      }
      return {
        ...state,
        currentFolder,
        documents,
      };

    case actionTypes.changeFolder: {
      const cursor = state.cursor.slice(0, action.payload);
      let currentFolder = null;

      for (const value of cursor) {
        currentFolder = state.documents?.['folders']?.find(
          (folder) => folder.id === value
        );
      }

      return {
        ...state,
        cursor,
        currentFolder,
      };
    }

    case actionTypes.folderCreating:
      return {
        ...state,
        isCreating: true,
      };

    case actionTypes.folderCreated:
      return {
        ...state,
        folder: action.payload,
        isCreating: false,
      };

    case actionTypes.updateRowsSelection:
      return {
        ...state,
        selectedRows: action.payload.selectedRows,
        selectedDocumentVariant: action.payload.selectedDocumentVariant,
      };

    case actionTypes.togglePageLock:
      return { ...state, isPageLocked: !state.isPageLocked };

    case actionTypes.toggleReadOnly:
      return { ...state, isReadOnly: action.payload };

    case actionTypes.downloadingFiles:
      return { ...state, isDownloading: true };

    case actionTypes.downloadingFilesComplete:
      return {
        ...state,
        isDownloading: false,
      };

    case actionTypes.deletingFilesAndFolder:
      return {
        ...state,
        isDeleting: true,
      };

    case actionTypes.deletionComplete:
      return {
        ...state,
        isDeleting: false,
      };

    case actionTypes.folderUpdated:
      if (state.currentFolder && state.currentFolder.folders) {
        currentFolder = {
          ...state.currentFolder,
          folders: state.currentFolder.folders?.map((folder) =>
            folder.id === action.payload?.id ? action.payload : folder
          ),
        };
      }
      return {
        ...state,
        isLoading: false,
        documents: {
          ...state.documents,
          folders: state.documents?.folders?.map((folder) =>
            folder.id === action.payload?.id ? action.payload : folder
          ),
        },
        currentFolder,
      };

    case actionTypes.filesMoved:
    case actionTypes.removeLoading:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.applyPermissionFilter:
      return {
        ...state,
        selectedPermissionsFilter: action.payload,
        pagination: initialState.pagination,
      };

    case actionTypes.applyNamesFilter:
      return {
        ...state,
        selectedNameFilter: action.payload,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      };

    case actionTypes.documentFolderTree:
      return {
        ...state,
        tree: action.payload,
      };

    default:
      return state;
  }
}

type FetchDocParams = {
  addNone?: boolean;
  isAdmin?: boolean;
};

function dispatchFetchDocuments(dispatch: Dispatch<DocumentsAction>) {
  return async ({ addNone = false, isAdmin = false }: FetchDocParams) => {
    dispatch({ type: actionTypes.loading });
    const fetchDocumentsFiltersDispatcher =
      dispatchFetchUserDocumentsFilters(dispatch);
    const fetchDocumentsPermissionsDispatcher =
      dispatchFetchPermissions(dispatch);
    const resetFiltersSelectionDispatcher =
      dispatchResetFiltersSelection(dispatch);

    const documents = await getDocuments();

    await fetchDocumentsFiltersDispatcher(addNone);
    if (isAdmin) {
      await fetchDocumentsPermissionsDispatcher();
    }

    resetFiltersSelectionDispatcher(false);

    dispatch({ type: actionTypes.fetchDocuments, payload: documents });

    return documents;
  };
}

function dispatchInitialize(dispatch: Dispatch<DocumentsAction>) {
  const fetchDocumentsDispatcher = dispatchFetchDocuments(dispatch);
  const fetchDocumentsFiltersDispatcher =
    dispatchFetchDocumentsFilters(dispatch);
  const fetchDocumentsPermissionsDispatcher =
    dispatchFetchPermissions(dispatch);
  const fetchDocumentTree = dispatchFolderTree(dispatch);
  const resetFiltersSelectionDispatcher =
    dispatchResetFiltersSelection(dispatch);

  return async (clientId: string) => {
    dispatch({ type: actionTypes.initialize });
    await fetchDocumentsDispatcher({ addNone: true });
    await fetchDocumentTree();
    await fetchDocumentsFiltersDispatcher(clientId);
    await fetchDocumentsPermissionsDispatcher();
    resetFiltersSelectionDispatcher(true);
    dispatch({
      type: actionTypes.initialized,
    });
  };
}

function dispatchTogglePageLock(dispatch: Dispatch<DocumentsAction>) {
  return () => {
    dispatch({ type: actionTypes.togglePageLock });
  };
}

function dispatchToggleReadOnly(dispatch: Dispatch<DocumentsAction>) {
  return (isReadOnly: boolean) => {
    dispatch({ type: actionTypes.toggleReadOnly, payload: isReadOnly });
  };
}

function dispatchToggleWaterMark(dispatch: Dispatch<DocumentsAction>) {
  return async (row: Documents) => {
    const folder = await updateFolder(row.id, row);

    dispatch({ type: actionTypes.toggleWaterMark, payload: folder });

    return folder;
  };
}

function dispatchToggleFileStatus(dispatch: Dispatch<DocumentsAction>) {
  return async (
    file: any,
    folderId: string,
    published: boolean,
    payload?: FolderContentRequestPayload
  ) => {
    dispatch({
      type: actionTypes.loading,
    });

    const res = await publishFiles(
      published ? PublishStatusEnum.Publish : PublishStatusEnum.Unpublish,
      { fileIds: [file.id], folderId }
    );

    const requestPayload: any = { ...payload };
    const requiredParams = [
      'searchText',
      'page',
      'pageSize',
      'funds',
      'investors',
      'quarters',
      'sort',
      'includeArchived',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] = requestPayload[index];
    }

    const documentsPublishedFiles = getDocumentsFolderFilesV2(
      folderId,
      true,
      requestBody
    );
    const documentsUnpublishedFiles = getDocumentsFolderFilesV2(
      folderId,
      false,
      requestBody
    );

    const [publishedFiles, unPublishedFiles] = await Promise.all([
      documentsPublishedFiles,
      documentsUnpublishedFiles,
    ]);

    dispatch({
      type: actionTypes.fetchFolderContent,
      payload: {
        page: requestBody.page,
        publishedFiles,
        unPublishedFiles,
      },
    });

    dispatch({
      type: actionTypes.removeLoading,
    });

    return res;
  };
}

function dispatchToggleDocControlPermissions(
  dispatch: Dispatch<DocumentsAction>,
  informationAlert: any
) {
  return async (row: any, permissions: DocControlPermissions) => {
    const fileIds = [row.id];

    try {
      await setDocControlPermissions(fileIds, permissions);

      dispatch({
        type: actionTypes.toggleDocControlPermissions,
        payload: { row: row, permissions: permissions },
      });
    } catch (error) {
      informationAlert('Error setting document permissions', 'error');
      return 'error';
    } finally {
      return 'success';
    }
  };
}

function dispatchToggleFileArchiveSettings(
  dispatch: Dispatch<DocumentsAction>
) {
  return (row: any) => {
    dispatch({
      type: actionTypes.toggleFileArchiveSettings,
      payload: { row: row },
    });
  };
}

function dispatchFetchUserDocumentsFilters(
  dispatch: Dispatch<DocumentsAction>
) {
  return async (addNone: boolean = false) => {
    const fundsRequest: Promise<FundFilters[]> = getFundFilters();
    const investorsRequest: Promise<InvestorFilter[]> =
      getInvestorsFilterList();
    const quartersFilterRequest: Promise<Quarter[]> =
      getQuartersFilterList(false);

    const [funds, investors, quarters] = await Promise.all([
      fundsRequest,
      investorsRequest,
      quartersFilterRequest,
    ]);

    const fundsList = funds.map(({ id, name }) => ({
      id,
      name,
    }));

    const investorsList = investors.map(({ id, name }) => ({
      id,
      name,
    }));

    const quartersList = quarters.map(({ id, name }) => ({
      id,
      name,
    }));

    dispatch({
      type: actionTypes.fetchUserDocumentFilters,
      payload: {
        funds: fundsList,
        investors: investorsList,
        quarters: quartersList,
      },
    });
  };
}

function dispatchFetchDocumentsFilters(dispatch: Dispatch<DocumentsAction>) {
  return async (clientId: string) => {
    const fundsRequest: Promise<Fund[]> = getFunds();
    const investorsRequest: Promise<Investor[]> =
      getInvestorsByStatus('active');
    const quartersRequest: Promise<Quarter[]> = getQuartersFilterList(false);

    const [funds, investors, quarters] = await Promise.all([
      fundsRequest,
      investorsRequest,
      quartersRequest,
    ]);

    dispatch({
      type: actionTypes.fetchDocumentOptions,
      payload: {
        funds: funds.map(({ fund }) => ({ id: fund.id, name: fund.name })),
        investors: investors.map(({ id, name }) => ({
          id,
          name,
        })),
        quarters: quarters.map(({ id, name }) => ({
          id,
          name,
        })),
      },
    });
  };
}

function dispatchResetFiltersSelection(dispatch: Dispatch<DocumentsAction>) {
  return (allSelected: boolean, page: number = 1) => {
    dispatch({
      type: actionTypes.resetFiltersSelection,
      payload: { allSelected, page },
    });
  };
}

function dispatchFolderTree(dispatch: Dispatch<DocumentsAction>) {
  return async () => {
    const tree = await getDocumentTree();

    dispatch({ type: actionTypes.documentFolderTree, payload: tree });
  };
}

function dispatchFetchFolderContent(dispatch: Dispatch<DocumentsAction>) {
  return async (
    id: string,
    payload: FolderContentRequestPayload,
    shouldOpen: boolean = true,
    disableResetFilter: boolean = false
  ) => {
    const documentsRequest = getDocumentsFolders(id);

    if (payload.page === 1) {
      if (shouldOpen) {
        dispatch({ type: actionTypes.openFolder, payload: id });
      } else {
        dispatch({ type: actionTypes.loading });
      }
    }

    const requestPayload: any = { ...payload };
    const requiredParams = [
      'searchText',
      'page',
      'pageSize',
      'funds',
      'investors',
      'quarters',
      'sort',
      'includeArchived',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] = requestPayload[index];
    }

    const documentsPublishedFiles = getDocumentsFolderFilesV2(
      id,
      true,
      requestBody
    );
    const documentsUnpublishedFiles = getDocumentsFolderFilesV2(
      id,
      false,
      requestBody
    );

    const [documents, publishedFiles, unPublishedFiles] = await Promise.all([
      documentsRequest,
      documentsPublishedFiles,
      documentsUnpublishedFiles,
    ]);

    dispatch({
      type: actionTypes.fetchFolderContent,
      payload: {
        documents,
        publishedFiles,
        unPublishedFiles,
        page: payload.page,
      },
    });

    if (disableResetFilter === false) {
      dispatchResetFiltersSelection(dispatch)(true, payload.page);
    }
  };
}

function dispatchFetchConsent(dispatch: Dispatch<DocumentsAction>) {
  return async (params: ConsentQueryParams) => {
    const consent = await fetchConsent(params);

    dispatch({
      type: actionTypes.fetchConsent,
      payload: consent,
    });
  };
}

function dispatchConsentConfirmation(dispatch: Dispatch<DocumentsAction>) {
  return async (params: ConsentBodyParams) => {
    dispatch({ type: actionTypes.loading });

    const consentConfirmation = await consentAction(params);

    dispatch({
      type: actionTypes.consentConfirmation,
      payload: consentConfirmation,
    });
  };
}

function dispatchFetchPublishedContent(dispatch: Dispatch<DocumentsAction>) {
  return async (id: string, payload: any) => {
    dispatch({ type: actionTypes.openFolder, payload: id });

    const requiredParams: Array<string> = [
      'searchText',
      'page',
      'pageSize',
      'funds',
      'investors',
      'quarters',
      'sort',
      'includeArchived',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] = payload[index];
    }

    const documentsRequest = getDocumentsFolders(id);
    const documentsPublishedFiles = getDocumentsFolderFilesV2(
      id,
      true,
      payload
    );

    const [documents, publishedFiles] = await Promise.all([
      documentsRequest,
      documentsPublishedFiles,
    ]);

    dispatch({
      type: actionTypes.fetchFolderContent,
      payload: {
        documents,
        publishedFiles,
        page: payload.page,
      },
    });

    dispatchResetFiltersSelection(dispatch)(true, payload.page);
  };
}

function dispatchApplyFilter(dispatch: Dispatch<DocumentsAction>) {
  return async (
    clientId: string,
    id: string,
    filterName: DocumentFilter,
    payload: FolderContentRequestPayload,
    isSorting: boolean,
    includeUnpublished: boolean = true,
    filtersHavingAllSelections: Array<'investors' | 'funds' | 'quarters'> = []
  ) => {
    dispatch({ type: actionTypes.loading });
    const requestPayload: any = {
      ...payload,
    };

    for (const filter of filtersHavingAllSelections) {
      requestPayload[filter] = [];
    }

    const requiredParams = [
      'searchText',
      'page',
      'pageSize',
      'funds',
      'investors',
      'quarters',
      'sort',
      'includeArchived',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] = requestPayload[index];
    }

    if (filterName !== DocumentFilter.Name) {
      const promises = [];
      const publishedFilesPromise = getDocumentsFolderFilesV2(
        id,
        true,
        requestBody
      );

      promises.push(publishedFilesPromise);
      if (includeUnpublished) {
        const unPublishedFilesPromise = getDocumentsFolderFilesV2(
          id,
          false,
          requestBody
        );

        promises.push(unPublishedFilesPromise);
      }

      const [publishedFiles, unPublishedFiles] = await Promise.all(promises);

      dispatch({
        type: actionTypes.fetchFolderContent,
        payload: {
          page: payload.page,
          publishedFiles,
          unPublishedFiles,
        },
      });
    }
    dispatch({
      type: actionTypes.updateFilterParams,
      payload: isSorting
        ? {
            sort: payload.sort,
          }
        : {
            selectedFilters: {
              investors: payload.investors,
              funds: payload.funds,
              quarters: payload.quarters,
              searchText: payload.searchText,
              includeArchived: payload.includeArchived,
            },
            pagination: {
              page: payload.page,
              pageSize: payload.pageSize,
            },
          },
    });
    dispatch({
      type: actionTypes.removeLoading,
    });
  };
}

function dispatchPermissionFilter(dispatch: Dispatch<DocumentsAction>) {
  return (permissions: Permission[]) => {
    dispatch({
      type: actionTypes.applyPermissionFilter,
      payload: permissions,
    });
  };
}

function dispatchRemoveLoading(dispatch: Dispatch<DocumentsAction>) {
  return () =>
    dispatch({
      type: actionTypes.removeLoading,
    });
}

function dispatchNameFilter(dispatch: Dispatch<DocumentsAction>) {
  return (names: Array<string>) => {
    dispatch({
      type: actionTypes.applyNamesFilter,
      payload: names,
    });
  };
}

function dispatchToggleFolderVisibility(dispatch: Dispatch<DocumentsAction>) {
  return (showFolders: boolean) => {
    dispatch({
      type: actionTypes.toggleFoldersVisibility,
      payload: showFolders,
    });
  };
}

function dispatchToggleArchivedVisibility(dispatch: Dispatch<DocumentsAction>) {
  return (includeArchived: boolean) => {
    dispatch({
      type: actionTypes.toggleArchivedVisibility,
      payload: includeArchived,
    });
  };
}

function dispatchChangeFolder(dispatch: Dispatch<DocumentsAction>) {
  return (index: number, doNotSetToInitial: boolean = false) => {
    const resetFiltersSelection = dispatchResetFiltersSelection(dispatch);

    dispatch({ type: actionTypes.changeFolder, payload: index });
    resetFiltersSelection(doNotSetToInitial);
    return index;
  };
}

function dispatchUpdateRowsSelection(dispatch: Dispatch<DocumentsAction>) {
  return (
    selectedRows: string[],
    selectedDocumentVariant: SelectedDocumentVariant[]
  ) => {
    dispatch({
      type: actionTypes.updateRowsSelection,
      payload: { selectedRows, selectedDocumentVariant },
    });
  };
}

function dispatchFetchPermissions(dispatch: Dispatch<DocumentsAction>) {
  return async () => {
    // dispatch({ type: actionTypes.loading });

    const permissions = await getDocumentFolderPermissions();

    dispatch({
      type: actionTypes.fetchPermissions,
      payload: permissions,
    });
  };
}

function dispatchAddNewFolder(dispatch: Dispatch<DocumentsAction>) {
  return async (id: string, params: Folder) => {
    dispatch({ type: actionTypes.folderCreating });

    const folder = await addNewFolder(id, params);

    dispatch({
      type: actionTypes.folderCreated,
      payload: folder,
    });
  };
}

function dispatchDownloadFiles(dispatch: Dispatch<DocumentsAction>) {
  return async (
    folderIds: string,
    fileIds: string,
    name?: string,
    type?: string
  ) => {
    dispatch({ type: actionTypes.downloadingFiles });

    const fileBlob = await downloadFilesAndFolder(folderIds, fileIds).catch(
      (error) => {
        dispatch({ type: actionTypes.downloadingFilesComplete });
        return Promise.reject(error);
      }
    );

    let fileName = name;

    if (!fileName && fileIds?.length === 1) {
      const fullFileName = (await getFileDetails(fileIds)).name;

      fileName = fullFileName.slice(0, fullFileName.lastIndexOf('.'));
    }

    if (fileBlob && fileBlob.type) {
      const extension = type || fileBlob.type.split('/').reverse()[0];

      downloadFile(
        fileBlob,
        fileName || `download-${DateTimeFormat.getFormattedDate(new Date())}`,
        extension
      );
    }

    dispatch({ type: actionTypes.downloadingFilesComplete });
  };
}

function dispatchViewFile(dispatch: Dispatch<DocumentsAction>) {
  return async (folderIds: string, fileIds: string, name?: string) => {
    dispatch({ type: actionTypes.downloadingFiles });

    const isFileDownload = false;

    const fileBlob = await downloadFilesAndFolder(
      folderIds,
      fileIds,
      isFileDownload
    ).catch((error) => {
      dispatch({ type: actionTypes.downloadingFilesComplete });
      return Promise.reject(error);
    });

    let fileName = name;

    dispatch({ type: actionTypes.downloadingFilesComplete });

    if (!fileName && fileIds?.length === 1) {
      const fullFileName = (await getFileDetails(fileIds)).name;

      fileName = fullFileName.slice(0, fullFileName.lastIndexOf('.'));
    }

    if (fileBlob && fileBlob.type) {
      return fileBlob;
    }
  };
}

function dispatchDeleteFolder(dispatch: Dispatch<DocumentsAction>) {
  return async (folderId: string) => {
    dispatch({ type: actionTypes.deletingFilesAndFolder });

    await deletingFolder(folderId);

    dispatch({ type: actionTypes.deletionComplete });
  };
}

function dispatchDeleteFiles(dispatch: Dispatch<DocumentsAction>) {
  return async (fileIds: string) => {
    dispatch({ type: actionTypes.deletingFilesAndFolder });

    await deleteFiles(fileIds);

    dispatch({ type: actionTypes.deletionComplete });
  };
}

function dispatchDeleteFilesAndFolders(dispatch: Dispatch<DocumentsAction>) {
  return async (folderIds: string, fileIds: string) => {
    dispatch({ type: actionTypes.deletingFilesAndFolder });
    if (folderIds) {
      await deletingFolder(folderIds);
    }
    if (fileIds) {
      await deleteFiles(fileIds);
    }

    dispatch({ type: actionTypes.deletionComplete });
  };
}

function dispatchUpdateFolder(dispatch: Dispatch<DocumentsAction>) {
  return async (id: string, params: Folder | Documents) => {
    dispatch({ type: actionTypes.loading });

    const folder = await updateFolder(id, params);

    dispatch({
      type: actionTypes.folderUpdated,
      payload: folder,
    });
  };
}

function dispatchUpdateFiles(dispatch: Dispatch<DocumentsAction>) {
  return async (
    id: string,
    params: any,
    payload: FolderContentRequestPayload
  ) => {
    dispatch({
      type: actionTypes.loading,
    });

    const res = await updateFiles(id, params);

    const requestPayload: any = { ...payload };
    const requiredParams = [
      'searchText',
      'page',
      'pageSize',
      'funds',
      'investors',
      'quarters',
      'sort',
      'includeArchived',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] = requestPayload[index];
    }

    const documentsPublishedFiles = getDocumentsFolderFilesV2(
      params.folderId,
      true,
      requestBody
    );
    const documentsUnpublishedFiles = getDocumentsFolderFilesV2(
      params.folderId,
      false,
      requestBody
    );

    const [publishedFiles, unPublishedFiles] = await Promise.all([
      documentsPublishedFiles,
      documentsUnpublishedFiles,
    ]);

    dispatch({
      type: actionTypes.fetchFolderContent,
      payload: {
        page: payload.page,
        publishedFiles,
        unPublishedFiles,
      },
    });

    dispatch({
      type: actionTypes.removeLoading,
    });

    return res;
  };
}

function dispatchMoveFiles(dispatch: Dispatch<DocumentsAction>) {
  return async (fileIds: string, folderId: string) => {
    dispatch({ type: actionTypes.loading });

    const res = await moveFiles(fileIds, folderId);

    dispatch({
      type: actionTypes.filesMoved,
    });

    return res;
  };
}

function dispatchPublishFiles(dispatch: Dispatch<DocumentsAction>) {
  return async (status: PublishStatusEnum, params: PublishFileParams) => {
    dispatch({ type: actionTypes.loading });

    const res = await publishFiles(status, params);

    dispatch({
      type: actionTypes.publishFiles,
    });

    return res;
  };
}

function dispatchDownloadDocLinks(dispatch: Dispatch<DocumentsAction>) {
  return async (params: DocumentLinkParams) => {
    dispatch({ type: actionTypes.loading });

    const fileBlob = await downloadFilesLinks(params);

    downloadFile(
      fileBlob,
      `${
        params.clientName
      }_Document_Link_Export_${DateTimeFormat.getFormattedDate(new Date())}`,
      'csv'
    );

    dispatch({
      type: actionTypes.downloadDocLinks,
    });

    return fileBlob;
  };
}

function dispatchChangeFolderIndex(dispatch: Dispatch<DocumentsAction>) {
  return async (params: any) => {
    dispatch({
      type: actionTypes.changeFolderIndex,
      payload: {
        documentId: params.id,
        folders: params.folders,
      },
    });

    const documents = await changeFolderIndex(params);

    return documents;
  };
}

function useActions(
  dispatch: Dispatch<DocumentsAction>,
  informationAlert: any
) {
  return useMemo(() => {
    return {
      changeFolder: dispatchChangeFolder(dispatch),
      fetchDocuments: dispatchFetchDocuments(dispatch),
      fetchDocumentsFilters: dispatchFetchDocumentsFilters(dispatch),
      fetchFolderContent: dispatchFetchFolderContent(dispatch),
      initialize: dispatchInitialize(dispatch),
      resetFiltersSelection: dispatchResetFiltersSelection(dispatch),
      toggleFileStatus: dispatchToggleFileStatus(dispatch),
      toggleDocControlPermissions: dispatchToggleDocControlPermissions(
        dispatch,
        informationAlert
      ),
      toggleFileArchiveSettings: dispatchToggleFileArchiveSettings(dispatch),
      togglePageLock: dispatchTogglePageLock(dispatch),
      toggleReadOnly: dispatchToggleReadOnly(dispatch),
      toggleWaterMark: dispatchToggleWaterMark(dispatch),
      updateRowsSelection: dispatchUpdateRowsSelection(dispatch),
      fetchPermissions: dispatchFetchPermissions(dispatch),
      createFolder: dispatchAddNewFolder(dispatch),
      downloadFiles: dispatchDownloadFiles(dispatch),
      viewFile: dispatchViewFile(dispatch),
      deleteFolder: dispatchDeleteFolder(dispatch),
      updateFolder: dispatchUpdateFolder(dispatch),
      applyFilters: dispatchApplyFilter(dispatch),
      updateFiles: dispatchUpdateFiles(dispatch),
      deleteFiles: dispatchDeleteFiles(dispatch),
      deleteFilesAndFolders: dispatchDeleteFilesAndFolders(dispatch),
      moveFiles: dispatchMoveFiles(dispatch),
      publishFiles: dispatchPublishFiles(dispatch),
      downloadDocLinks: dispatchDownloadDocLinks(dispatch),
      changeFolderIndex: dispatchChangeFolderIndex(dispatch),
      fetchPublishedContent: dispatchFetchPublishedContent(dispatch),
      fetchDocumentTree: dispatchFolderTree(dispatch),
      fetchConsent: dispatchFetchConsent(dispatch),
      consentAction: dispatchConsentConfirmation(dispatch),
      applyPermissionFilter: dispatchPermissionFilter(dispatch),
      applyNameFilter: dispatchNameFilter(dispatch),
      toggleFolders: dispatchToggleFolderVisibility(dispatch),
      toggleArchived: dispatchToggleArchivedVisibility(dispatch),
      removeLoading: dispatchRemoveLoading(dispatch),
    };
  }, [dispatch]);
}

const useDocuments = (informationAlert: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(dispatch, informationAlert);

  return {
    state,
    dispatch,
    ...actions,
  };
};

export default useDocuments;
