import IconNewCircle from "../../../../assets/images/icons/icon_new_circle.svg";
import { ButtonOptions } from "../../../../components/ButtonWithOptions/ButtonWithOptions";
import { AddNewButtonOptions } from "../../../../utils/types/common.type";
import { ArkLedger } from "../../../../utils/types/glSetup.type";

export const GET_LEDGER_LIST_ERROR =
  "Error in getting general ledger setup list.";
export const GET_FUND_LIST_ERROR = "Error in getting fund list.";
export const GET_CURRENCY_LIST_ERROR = "Error in getting currency list.";
export const CREATE_LEDGER_ERROR = "Error in creating ledger.";
export const CREATE_LEDGER_SUCCESS = "Ledger created successfully.";
export const UPDATE_LEDGER_ERROR = "Error in updating ledger.";
export const UPDATE_LEDGER_SUCCESS = "Ledger updated successfully.";
export const CONNECT_LEDGER_ERROR = "Error in connecting ledger.";
export const DISCONNECT_GL_ERROR = "Error in disconnecting General Ledger.";
export const DISCONNECT_GL_SUCCESS =
  "General Ledger disconnected successfully.";
export const DELETE_LEDGER_ERROR = "Error in deleting selected ledger(s).";
export const DELETE_LEDGER_SUCCESS = "Ledger(s) deleted successfully.";
export const DELETE_ALL_LEDGER_ERROR = "Error deleting ledgers.";
export const DELETE_ALL_LEDGER_SUCCESS = "Ledgers deleted successfully.";
export const GL_SETUP_VIEW_KEY = "GL_SETUP_VIEW_KEY";
export const GET_VIEW_LIST_ERROR = "Error retrieving initial order of columns.";

export const LEDGER_FORM_DEFAULT_VALUE: ArkLedger = {
  fundId: "",
  name: "",
  description: "",
  currency: "",
};

export const glSetupOptions: ButtonOptions[] = [
  {
    label: "New General Ledger",
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
];

export enum LedgerAction {
  DeleteSelected = "delete_selected",
}

export enum LedgerFilter {
  Status = "status",
}

export const StatusType: {
  [key: string]: { id: string; color: string; name: string };
} = {
  UNUSED: { id: "UNUSED", color: "red", name: "Unused" },
  DRAFT: { id: "DRAFT", color: "yellow", name: "Draft" },
  POSTED: { id: "POSTED", color: "green", name: "Posted" },
  EXTERNAL: { id: "EXTERNAL", color: "blue", name: "External" },
};

export enum DeleteConfirmationType {
  FALSE = "FALSE",
  USED = "POSTED",
  DRAFT = "DRAFT",
  UNUSED = "UNUSED",
}