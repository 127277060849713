export const WorkbookVersion = 4;

export enum AllocationColumnTypes {
    INVESTMENT_TYPE='INVESTMENT_TYPE', 
    CUSTOM_FIELD='CUSTOM_FIELD'
}

export enum TransactionColumnType {
    STANDARD_TRANSACTION_TYPE=1, 
    BEGINING_BALANCE=2, 
    ENDING_BALANCE=3
}

export enum ITDType {
    STANDARD_PITD_TYPE = 1,
    BEGINING_BALANCE_PITD_TYPE = 2, 
    ENDING_BALANCE_PITD_TYPE = 3,
    STANDARD_YTD_TYPE = 4,
    BEGINING_BALANCE_YTD_TYPE = 5, 
    ENDING_BALANCE_YTD_TYPE = 6,
    STANDARD_ITD_TYPE = 7,
    BEGINING_BALANCE_ITD_TYPE = 8, 
    ENDING_BALANCE_ITD_TYPE = 9
}

export type CommitmentColumn = {    
    id?: string,
    gridColId: string,
    label: string,
    quarterSum: boolean,
    commitmentDate: string,
    quarterNumber: number
}

export type AllocationColumn = {
    id?: string,
    gridColId: string,
    index: number,
    label: string,
    code: string,
    quarterNumber: number,
    transactionType: AllocationColumnTypes
};

export type TransactionColumn = {
    id?: string,
    transTypeId: string,
    gridColId: string,
    index: number,
    label: string,
    allocationCode?: string,
    allocationCodeQuarterReference?: number,
    locked: boolean,
    transSubmitError: boolean,
    quarterNumber: number,
    metricSign: number,
    useArkTransactionValues: boolean,
    colType: TransactionColumnType,
    isUserColumn: boolean,
};

export type ItdColumn = {
    id?: string,
    transTypeId: string,
    gridColId: string,
    index: number,
    label: string,
    metricSign: number,
    colType: ITDType
}

export enum InvestorRowType {Investor=1, TypeTotal=2, PartnerTotal=3}

export type InvestorRow = {
    id?: string,
    gridRowId: string,
    investorId: string, 
    investorType: string,
    investorName: string,
    /**
     * @deprecated  Use for previous versions only 
     */
    row?: number, 
    rowType: InvestorRowType,
    totalTransactionAmount?: number,
    deleted: boolean
};

export type WorkbookFrequencyType = 'BY_YEAR' | 'BY_QUARTER' | '';

export type Workbook = {
    id?: string,
    version: number,
    tenantId: string,
    fileName: string,
    fileType?: string,
    reportName: string,
    createdBy?: string,
    modifiedBy?: string,
    startQuarterYear: number,
    startQuarterNumber: number,
    dateCreated?: string,
    dateModified?: string,
    dateLastOpened?: string,
    type: string,
    frequency: WorkbookFrequencyType,
    decimalRounding: number,
    includeYtd: boolean,
    includeItd: boolean,
    /**
     * @deprecated  Use for previous versions only 
     */
    lastRow?: number,
    /**
     * @deprecated  Use for previous versions only 
     */
    lastCol?: string,
    /**
     * @deprecated  Use for previous versions only 
     */
    commitmentStartCol?: string,
    /**
     * @deprecated  Use for previous versions only 
     */
    commitmentEndCol?: string,
    fund: {
        id: string,
        name: string,
        currency: string
    },
    commitmentTransactionTypeIds: string[],
    investorRows: InvestorRow[],
    commitmentColumns: CommitmentColumn[],
    allocationColumns: AllocationColumn[],
    transactionColumns: TransactionColumn[],
    itdColumns: ItdColumn[],
    customAllocationsRules?: CustomAllocationsRules[],
};

export type AdditionalWorkbookProperties = {
    customAllocationsRules: CustomAllocationsRules[]
};

export type CustomAllocationsRules = {
    code: string,
    investorId: string,
    investorType: string,
    rowType: number,
    value: string|number
};

export type InvestorCommitment = {
    investorId: string,
    investorType: string,
    investorName: string,
    totalTransactionAmount: number,
    transactionsDetails: [InvestorCommitmentTransactionDetail]
};

export type InvestorCommitmentTransactionDetail = {
    date: Date,
    amount: number
}


export type PitdSummaryValues = {
    investorId: string,
    investorName: string,
    investorType: string,
    totalTransactionAmount: number,
    transactionTotalMap: {
        [key: string]: number
    }
};


export type SubmitToPcap = {
    workbookId: string,
    fundId: string,
    workbookTransactionModels: SubmitToPcapTransactionsType[]
}

export type SubmitToPcapTransactionsType = {
    transactionTypeId: string, 
    transactionName: string,
    transactionTypeModels: SubmitToPcapTransaction[]
}

export type SubmitToPcapTransaction = {
    investorId: string, 
    balance: number
}

export type SubmitToPcapResponse = {
    type: string,
    success: boolean, 
    errorMsg: string
}



export type GlEntry = {
    amount: number, 
    quarter: number, 
    year: number, 
    transactionTypeId: string
}

export enum PostingType {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT"
}

export type LineItem = {
    name: string;
    accountId: string;
    amount: string | Number;
    postingType: PostingType;
    description: string;
    currency: string;
};

export type GLEntryV2 = {
    date: Date;
    adjustingEntry: boolean;
    memo: string | null; 
    lineItems: LineItem[];
}

export type GLEntryCreateParams = {
    fundId: string;
    entry: GLEntryV2;
};

export type TransactionTypeQuarterlySum = {
    transTypeId: string, 
    investorId: string, 
    sum: number
}


export type WorkbookLockStatus = {
    lockedAt?: Date,
    lockedBy?: string,
    locked: boolean
};


export enum SheetTypes {
    Allocations = 'Allocations',
    Transfers = 'Transfers',
    Custom = ''
}

export enum StringConstants {
    dynamicDateStr = '<dynamic date>',
    beginingBalanceStr = 'Beg. Balance',
    endingBalanceStr = 'Ending Balance',
    byQuarterStr = 'BY_QUARTER',
    byYearStr = 'BY_YEAR'
}

export enum ToolbarButtons {
    Save = "Save",
    SaveAs = "SaveAs",
    GetFromGl = "GetFromGl",
    RollForward = "RollForward",
    SubmitToPcap = "SubmitToPcap",
    AllocationsConfig = "AllocationsConfig"
}

export enum WbConfigDialogType {NoShow, NewWorkbook, Standard, Rollforward}