import IconDownload from '../../../../assets/images/icons/icon_download.svg';
import IconNewCircle from '../../../../assets/images/icons/icon_new_circle.svg';
import IconUpload from '../../../../assets/images/icons/icon_upload.svg';
import { ButtonOptions } from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import {
  ArkGLJournalEntry,
  JournalEntryLineItem,
} from '../../../../utils/types/arkGLJournalEntry.type';
import { AddNewButtonOptions } from '../../../../utils/types/common.type';

export const JOURNAL_ENTRY_LIST_COLUMN_ORDER_VIEW_KEY =
  'JOURNAL_ENTRY_VIEW_KEY';
export const GET_JOURNAL_ENTRY_LIST_ERROR = 'Error getting journal entry list.';
export const GET_JOURNAL_ENTRY_ERROR = 'Error getting journal entry details.';
export const CREATE_JOURNAL_ENTRY_ERROR = 'Error creating journal entry.';
export const CREATE_JOURNAL_ENTRY_SUCCESS =
  'Journal entry created successfully.';
export const UPDATE_JOURNAL_ENTRY_ERROR = 'Error in updating journal entry.';
export const JOURNAL_ENTRY_FILENAME_SIZE_ERROR =
  'Filenames must be 64 or less characters';
export const UPDATE_JOURNAL_ENTRY_SUCCESS =
  'Journal entry updated successfully.';
export const UPLOAD_JOURNAL_ENTRY_ERROR = 'Error in uploading journal entries.';
export const UPLOAD_JOURNAL_ENTRY_SUCCESS =
  'Journal entries uploaded successfully.';
export const DELETE_JOURNAL_ENTRY_ERROR = 'Error deleting journal entries.';
export const DELETE_JOURNAL_ENTRY_SUCCESS =
  'Journal entry deleted successfully.';
export const POST_JOURNAL_ENTRY_SUCCESS =
  'Successfully posted journal entries.';
export const POST_JOURNAL_ENTRY_ERROR = 'Error posting journal entries.';
export const POST_ACCOUNTS_SUCCESS = 'Successfully posted accounts.';
export const POST_ACCOUNTS_ERROR = 'Error posting accounts.';
export const POST_GL_SUCCESS = 'Successfully posted GL.';
export const POST_GL_ERROR = 'Error posting GL.';
export const POST_ALL_ERROR = 'Error posting all drafted journal entries.';
export const POST_ALL_SUCCESS =
  'Successfully posted all drafted journal entries.';
export const POST_SELECTED_ERROR = 'Error posting selected journal entries.';
export const POST_SELECTED_SUCCESS =
  'Successfully posted all drafted journal entries.';
export const DELETE_ALL_ERROR = 'Error deleting all drafted journal entries.';
export const DELETE_SELECTED_ERROR =
  'Error deleting selected drafted journal entries.';
export const DELETE_BULK_ERROR = 'Error deleting drafted journal entries.';
export const DELETE_BULK_SUCCESS =
  'Successfully deleted drafted journal entries.';
export const EXPORT_ALL_ERROR = 'Error exporting all journal entries.';
export const EXPORT_ALL_SUCCESS = 'Successfully exported all journal entries.';
export const EXPORT_SELECTED_ERROR =
  'Error exporting selected journal entries.';
export const EXPORT_SELECTED_SUCCESS =
  'Success exporting selected journal entries.';
export const SELECTED_POSTED_ERROR = 'Please remove posted selections.';
export const DOWNLOAD_TEMPLATE_ERROR = 'Error downloading template.';
export const GET_FUND_LIST_ERROR = 'Error getting fund list.';
export const GET_LEDGER_LIST_ERROR = 'Error, no ledgers available.';
export const GET_INV_TRANSACTION_LIST_ERROR =
  'Error getting associated investment transaction list.';
export const GET_ENTITY_LIST_ERROR = 'Error getting entity list.';
export const GET_VIEW_LIST_ERROR = 'Error retrieving initial order of columns.';
export const DELETE_ATTACHMENT_ERROR = 'Error deleting attachment file.';
export const DELETE_ATTACHMENT_SUCCESS =
  'Attachment file deleted successfully.';
export const DOWNLOAD_ATTACHMENT_ERROR = 'Error in downloading attachment.';
export const CONNECT_TRANSACTION_ERROR =
  'Error connecting transactions to journal entry.';
export const CONNECT_TRANSACTION_SUCCESS =
  'Successfully connected transactions to journal entry.';

export const JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE: JournalEntryLineItem = {
  accountId: '',
  amount: 0,
  entryMemo: '',
  entryType: '',
  entities: [],
  isEntityRequired: false,
};

export const JOURNAL_ENTRY_FORM_DEFAULT_VALUE: ArkGLJournalEntry = {
  ledgerId: '',
  fundId: '',
  number: null,
  date: null,
  currencyName: '',
  currencyDecimal: 0,
  adjustingJournalEntry: false,
  reference: '',
  state: 'DRAFT',
  postDate: null,
  lineItems: [
    { ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE },
    { ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE },
  ],
  attachments: null,
  memo: '',
};

export const NewJournalEntryOptions: ButtonOptions[] = [
  {
    label: 'New Journal Entry',
    id: AddNewButtonOptions.AddNew,
    icon: IconNewCircle,
  },
  {
    label: 'Upload From Template',
    id: AddNewButtonOptions.UploadFromTemplate,
    icon: IconUpload,
    type: 'upload',
  },
  {
    label: 'Download Template',
    id: AddNewButtonOptions.DownloadTemplate,
    icon: IconDownload,
  },
];

export enum JournalEntryAction {
  PostSelected = 'post_selected',
  PostAll = 'post_all',
  DeleteSelected = 'delete_selected',
  DeleteAll = 'delete_all',
  ExportSelected = 'export_selected',
  ExportAll = 'export_all',
}

export enum JournalEntryFilter {
  Fund = 'fund',
  Account = 'account',
  Entity = 'entity',
  Status = 'status',
  Date = 'date',
  Ledger = 'ledger',
}

export const defaultSortModel = [
  { field: 'status', sort: 'asc' },
  { field: 'date', sort: 'desc' },
  { field: 'journalNumber', sort: 'asc' },
  { field: 'lineNumber', sort: 'asc' },
];
