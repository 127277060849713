import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import useTeamMemberFilter from "./useTeamMemberFilter";

type Props = {
  error?: any;
  value?: any | null | string;
  nameSelectOnly?: boolean;
  filterOptions?: Array<string> | undefined;
  onChange?: (value: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  id?: string;
  textFieldID?: string;
  isShrink?: boolean;
  isPlaceHolder?: boolean;
  fullWidth?: boolean;
};

const TeamMemberFilter = ({
  error,
  placeholder,
  onChange,
  value,
  disabled,
  size = "small",
  id = "transaction_type_filter",
  textFieldID = "transaction_type_filter_text_field",
  isShrink = false,
  isPlaceHolder = true,
  fullWidth = false,
  filterOptions = [],
}: Props) => {

  const { users, loading } = useTeamMemberFilter();

  const formattedUsers = users.map(user => ({
    ...user,
    name:`${user.firstName} ${user.lastName || ""}`
  }));

  const usableOptions = formattedUsers?.filter((item: any) => !filterOptions?.includes(item.id));
  const onSelectChange = (_: any, value: any) => {
    if (!value) {
      return;
    }
    onChange?.(value);
  };

  return (
    <Autocomplete
      options={usableOptions || []}
      id={id}
      getOptionLabel={(option: any) => { return option?.name ?? ""; }}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const regex = new RegExp(inputValue, "igm");

        return options.filter(item => item.name.search(regex) !== -1 || item.email.search(regex) !== -1);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id }
      filterSelectedOptions
      onChange={onSelectChange}
      value={value}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      loading={loading}
      loadingText="...loading"
      renderOption={(props, option) => <li {...props} key={option.id}>{`${option.name} (${option.email})`}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={isPlaceHolder ? placeholder : ""}
          variant="outlined"
          label={placeholder}
          id={textFieldID}
          error={!!error}
          helperText={error?.message ?? ""}
          InputLabelProps={
            (isShrink && {
              shrink: true,
            }) ||
            {}
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default React.memo(TeamMemberFilter);
