import CloseIcon from '@mui/icons-material/Close';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Controller, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import AddIcon from '../../../../assets/images/icons/icon_add_blue.svg';
import DoubleLineAIcon from "../../../../assets/images/icons/icon_double_line_a.svg";
import DoubleUnderLineIcon from "../../../../assets/images/icons/icon_double_underline.svg";
import IconDragColumn from "../../../../assets/images/icons/icon_drag_column.svg";
import SingleLineAIcon from "../../../../assets/images/icons/icon_single_line_a.svg";
import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import TransactionTypesMultiple from "../../../../components/Selectors/TransactionTypesFilter/TransactionTypesMultiple";
import { ImageItem } from '../../../../utils/types/listItems';
import { NameIdPair } from '../../../../utils/types/transaction.type';
import { CheckboxFormControl, CheckboxLabel } from "../../ClientDetails/ClientDetails.style";
import { TemplateTitleBox } from '../../Clients.styles';
import { CloseIconBox } from '../GeneralReportHeader/components/ListView.style';
import {
  CommonType,
  GeneralReportForm,
} from '../useReportConfig.hooks';
import {
  AddRowContainer,
  DragIcon,
  LabelTextField,
  ListContainer,
  ListView,
  MainContainer,
  SectionContainer,
  SpaceBox,
  Spacer,
  SpacerText,
  SpacerView,
  SpiltButtonContainer,
  SummaryMiddleBox,
  TypographyBox
} from "./SummaryConfiguration.style";

type Props = {
  reportForm: UseFormReturn<GeneralReportForm, any>;
  control: UseFieldArrayReturn<GeneralReportForm, "summaryElements">;
  transactionBuckets: NameIdPair[] | undefined;
  addSummary: (type: string) => void;
  removeSummary: (id: number) => void;
}

const SummaryConfiguration = ({
  reportForm,
  removeSummary,
  addSummary,
  transactionBuckets,
  control
}: Props): React.ReactElement => {
  const [selectIndex, setSelectedIndex] = useState<number>(-1);
  const summaryList = control.fields;

  const actionOptions: ImageItem[] = [
    {
      id: 'TRANSACTION',
      text: `Add Row`,
      icon: <img src={AddIcon} alt="Export Selected" height="15" />,
      optionsSelected: 0,
    },
    {
      id: 'SPACER',
      text: 'Spacer',
      optionsSelected: 0
    }
  ];

  const handleAddActions = (actionId: string) => {
    addSummary(actionId);
  };

  const onDragEnd = (result: DropResult) => {
    
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setSelectedIndex(result.destination.index);
    control?.move(result.source.index, result.destination.index);
    // Re-arrange indexing of elements
    summaryList.forEach((res, index) => {
      reportForm.setValue(`summaryElements.${index}.index`, (index + 1), { shouldDirty: true });
    });  
  };

  const handleRemoveItem = (id: number) => {
    removeSummary(id);
    setSelectedIndex(-1);
  };

  const summaryFontStyle = (event: React.MouseEvent<HTMLElement>, newFormats: string) => {

    if(reportForm?.getValues().summaryElements[selectIndex].type !== 'SPACER') {
      const currentStyles = reportForm?.getValues().summaryElements[selectIndex]?.styles;

      if(currentStyles?.includes(newFormats[0])) {

        const updatedStyle = currentStyles?.filter(res => res !== newFormats[0]);

        reportForm.setValue(`summaryElements.${selectIndex}.styles`, updatedStyle, { shouldDirty: true });
      } else {
        reportForm.setValue(`summaryElements.${selectIndex}.styles`, [...currentStyles, newFormats[0]], { shouldDirty: true });
      }
    }
  };

  const summaryConfig = reportForm.watch("summaryElements");

  return (
    <SectionContainer>
      <Box>
        <TemplateTitleBox variant="h4">
          Summary Configuration
        </TemplateTitleBox>
      </Box>
      <MainContainer>
        <AddRowContainer>
          <SpiltButtonContainer>
            <SplitButton
              id={"btn_add_row_options"}
              options={actionOptions}
              hidden={false}
              handleOptionClick={handleAddActions}
              ariaLabelMessage="Select action option"
            />
          </SpiltButtonContainer>
          <SummaryMiddleBox key={`${selectIndex}_style`}>
            <TypographyBox variant="label2">
              FONT STYLE:
            </TypographyBox>
            <ToggleButtonGroup
                onChange={summaryFontStyle}
                aria-label="text alignment">
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('BOLD')}
                  value="BOLD"
                  aria-label="bold"
              >
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('ITALIC')}
                  value="ITALIC"
                  aria-label="italic"
              >
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('UNDERLINE')}
                  value="UNDERLINE"
                  aria-label="underline"
              >
                <FormatUnderlinedIcon />
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('DOUBLE_UNDERLINE')}
                  value="DOUBLE_UNDERLINE"
                  aria-label="doubleUnderline"
              >
                <img src={DoubleUnderLineIcon} alt="doubleUnderline" />
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('OVERLINE')}
                  value="OVERLINE"
                  aria-label="overline"
              >
                <img src={SingleLineAIcon} alt="SingleLineAIcon" />
              </ToggleButton>
              <ToggleButton
                  size="small"
                  selected={summaryConfig[selectIndex]?.styles?.includes('DOUBLE_OVERLINE')}
                  value="DOUBLE_OVERLINE"
                  aria-label="doubleOverline"
              >
                <img src={DoubleLineAIcon} alt="DoubleLineAIcon" />
              </ToggleButton>
            </ToggleButtonGroup>
          </SummaryMiddleBox>
        </AddRowContainer>
        <ListContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sc_droppable">
              {(provided, snapshot) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  <List>
                    {summaryList?.map((item: CommonType, ind: number) => (
                      <Draggable key={item.id} draggableId={`${item.id}`} index={ind}>
                        {(provided, snapshot) => (
                          <ListItemButton 
                            disableRipple 
                            key={`list_${ind}`} 
                            ref={provided.innerRef} 
                            selected={selectIndex === ind} 
                            onClick={() => setSelectedIndex(ind)} 
                            {...provided.draggableProps} 
                            {...provided.dragHandleProps}>
                            <ListItemText>
                              {item.type === 'TRANSACTION' &&
                                <ListView>
                                  <CloseIconBox>
                                    {selectIndex === ind &&<DragIcon src={IconDragColumn} alt="IconDragColumnImage" />}
                                  </CloseIconBox>
                                  <Controller
                                    name={`summaryElements.${ind}.label`}
                                    render={({
                                      field: {
                                        onChange, value
                                      },
                                      fieldState: {
                                        error
                                      }
                                    }) => (
                                      <LabelTextField styles={reportForm.getValues('summaryElements')[ind].styles}
                                        id={`label_${ind}`} 
                                        label="Label" 
                                        size="small" 
                                        variant="outlined" 
                                        value={value}
                                        onChange={onChange}
                                      />
                                    )}
                                    control={reportForm?.control}
                                  />
                                  <Controller
                                    name={`summaryElements.${ind}.transactionTypes`}
                                    render={({
                                      field: {
                                        onChange, value
                                      },
                                      fieldState: {
                                        error
                                      }
                                    }) => (
                                      <TransactionTypesMultiple
                                        size="small"
                                        id={`transaction_type_${ind}`}
                                        value={value}
                                        error={error}
                                        idSelectOnly
                                        onChange={onChange}
                                        placeholder="Transaction Type"
                                      />
                                    )}
                                    control={reportForm?.control}
                                  />
                                  <Controller
                                    name={`summaryElements.${ind}.dateRange`}                                    
                                    render={({
                                      field: {
                                        onChange, value
                                      },
                                    }) => {
                                      return (
                                        <FormControl size="small">
                                          <InputLabel id={`date_range_label_${ind}`}>Date Range</InputLabel>
                                          <Select
                                            labelId={`date_range_seelct_${ind}`}
                                            value={value}
                                            label="Date Range"
                                            onChange={onChange}
                                          >
                                            <MenuItem value={'ITD'}>
                                              Inception To Date(ITD)
                                            </MenuItem>
                                            <MenuItem value={'QTD'}>
                                              Quarter To Date(QTD)
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      );
                                    }}
                                    control={reportForm?.control}
                                  />
                                  <Controller
                                    name={`summaryElements.${ind}.bucket`}                                    
                                    render={({
                                      field: {
                                        onChange, value
                                      },
                                    }) => {
                                      return (
                                        <FormControl size="small">
                                          <InputLabel id={`bucket_label_${ind}`}>Bucket</InputLabel>
                                          <Select
                                            labelId={`bucket_select_${ind}`}
                                            value={value}
                                            label="Bucket"
                                            key={`select_bucket_${item.index}`}
                                            onChange={onChange}
                                          >
                                            {transactionBuckets?.map((res: NameIdPair, index: number) => (
                                              <MenuItem
                                                key={`summary_transaction_bucket_select_${index}`}
                                                value={res.id}
                                              >
                                                {res.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      );
                                    }}
                                    control={reportForm?.control}
                                  />
                                  <Controller
                                    name={`summaryElements.${ind}.suppressIfZero`}                                    
                                    render={({
                                      field: {
                                        onChange, value
                                      }
                                    }) => {
                                      return (
                                        <CheckboxFormControl fullWidth>
                                          <CheckboxLabel>
                                            <Checkbox
                                              checked={value || false}
                                              onChange={onChange} />
                                            Suppress if &#34;0&#34;</CheckboxLabel>
                                        </CheckboxFormControl>
                                      );
                                    }}
                                    control={reportForm?.control}
                                  />
                                  <CloseIconBox>
                                    {selectIndex === ind &&
                                      <IconButton onClick={() => handleRemoveItem(ind)} >
                                        <CloseIcon/>
                                      </IconButton>
                                    }
                                  </CloseIconBox>
                                </ListView>
                              }
                              {item.type === 'SPACER' &&
                                <SpacerView>
                                  <SpaceBox>
                                    <Spacer>
                                      <SpacerText>Spacer</SpacerText>
                                    </Spacer>
                                    <CloseIconBox>
                                      {selectIndex === ind &&
                                        <CloseIcon onClick={(e) => handleRemoveItem(ind)} />
                                      }
                                    </CloseIconBox>
                                  </SpaceBox>
                                </SpacerView>
                              }
                            </ListItemText>
                          </ListItemButton>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </ListContainer>
      </MainContainer>
    </SectionContainer>
  );
};

export default React.memo(SummaryConfiguration);
