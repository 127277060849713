import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { GridRenderEditCellParams, GridRowModel } from '@mui/x-data-grid';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ReactElement } from 'react';

import { M_DASH_UNICODE } from '../../../../../utils/constants/constants';
import { FolderContentRequestPayload } from '../../../../../utils/types/documents.type';
import { Option } from '../../../../../utils/types/filter.type';
import useDocumentsContext from '../../../hooks/useDocumentsContext.hooks';
import EditSelection from '../../editSelection/EditSelection';
import { SelectBox } from './DocumentsGridCells.styles';

interface Quarter {
  id: string;
  name: string;
}

interface SelectionBoxProps {
  anchorEl: HTMLDivElement | null;
  data: GridRowModel;
  handleLoading: (status: boolean) => void;
  handleClose: () => void;
  options: Array<any>;
  update: Function;
  selected: Array<string>;
  field: string;
  payload: FolderContentRequestPayload;
}

export const SelectionBox = ({
  anchorEl,
  data,
  handleLoading,
  handleClose,
  options,
  update,
  selected,
  field,
  payload,
}: SelectionBoxProps) => {
  const handleUpdate = (selected: string[] | string | Option) => {
    const params = {
      ...data,
      [field]: selected,
    };

    const requiredParams = [
      'index',
      'folderId',
      'clientId',
      'fundIds',
      'investorIds',
      'quarter',
      'published',
      'sizeInBytes',
      'fileType',
      'name',
      'id',
      'taggedForAllInvestors',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      if (index === 'investorIds') {
        requestBody[index] = params['investors'].map(({ id }: any) => id);
      } else if (index === 'fundIds') {
        requestBody[index] = params['funds'].map(({ id }: any) => id);
      } else requestBody[index] = params[index];
    }

    handleLoading(true);

    update(data.id, requestBody, payload).catch(() => {
      handleLoading(false);
      handleClose();
    });
  };

  return anchorEl ? (
    <EditSelection
      options={options}
      handleUpdate={handleUpdate}
      handleOnClose={handleClose}
      anchorEl={anchorEl}
      selected={selected}
      shouldOptionReturnValue={false}
    />
  ) : (
    <></>
  );
};

interface PeriodCellProps {
  data: GridRowModel;
  quarter: string;
}

export const PeriodCell = ({ data, quarter }: PeriodCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { state, updateFiles } = useDocumentsContext();

  const renderComponent = () => {
    if (data.type === 'folder' || isEmpty(quarter)) {
      return <Typography variant="cells">{M_DASH_UNICODE}</Typography>;
    }
    return <Typography variant="cells">{quarter || M_DASH_UNICODE}</Typography>;
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    if (state?.isPageLocked || state?.isReadOnly) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleLoading = (status: boolean) => setLoading(status);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    state?.filtersData.quarters.filter((quarter) => quarter.id !== '(none)') ||
    [];
  const selected = data.quarter ?? '';

  const filters: any = { ...(state?.selectedFilters || {}) };

  if (
    state?.selectedFilters['funds'].length ===
    state?.filtersData['funds'].length
  ) {
    filters.funds = [];
  }
  if (
    state?.selectedFilters['investors'].length ===
    state?.filtersData['investors'].length
  ) {
    filters.investors = [];
  }
  if (
    state?.selectedFilters['quarters'].length ===
    state?.filtersData['quarters'].length
  ) {
    filters.quarters = [];
  }

  filters.searchText = state?.selectedFilters.searchText || '';

  const payload = {
    page: 1,
    pageSize: state?.pagination.pageSize || 100,
    ...filters,
    sort: state?.sort || [],
  };

  return data.isSelected ? (
    !loading ? (
      <>
        <SelectBox onClick={handleOnClick}>
          <Box>
            <Typography variant="cells">
              {data.type === 'folder'
                ? M_DASH_UNICODE
                : data.quarter ?? M_DASH_UNICODE}
            </Typography>
          </Box>
          <span>
            <ArrowDropDown />
          </span>
        </SelectBox>
        {data.type === 'file' && (
          <SelectionBox
            anchorEl={anchorEl}
            handleLoading={handleLoading}
            update={updateFiles}
            handleClose={handleClose}
            data={data}
            options={options}
            selected={[selected]}
            field="quarter"
            payload={payload}
          />
        )}
      </>
    ) : (
      <CircularProgress />
    )
  ) : (
    <Box>{renderComponent()}</Box>
  );
};

type Props = {
  row: any;
};

const QuarterPeriodCellRenderer: React.FC<Props> = ({
  row,
}: Props): ReactElement => {
  const value: string = row.quarter;

  const renderQuarter = React.useMemo(
    () => <PeriodCell data={row} quarter={value} />,
    [value, row.isSelected, row.investors, row.funds, row.published]
  );

  return renderQuarter;
};

export default QuarterPeriodCellRenderer;
