import { ReactElement } from "react";

import ImgIcon from "../ImgIcon/ImgIcon";
import { StyledListItemIcon } from "./ListItemIcon.styles";

type Props = {
  id: string;
  icon: string;
};

const ListItemIcon: React.FC<Props> = (props: Props): ReactElement => {
  const { id, icon } = props;

  return (
    <StyledListItemIcon id={id}>
      <ImgIcon icon={icon} />
    </StyledListItemIcon>
  );
};

export default ListItemIcon;
