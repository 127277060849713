import { ReactElement } from "react";

import { DeleteIcon, StyledChip } from "./Chip.styles";

type Props = {
  id: string;
  label: string;
  onDelete?: any;
  color: string;
};

const Button = (props: Props): ReactElement => {
  const { id, label, onDelete, color } = props;

  return (
    <StyledChip
      chipcolor={color}
      label={label}
      id={id}
      key={id}
      deleteIcon={<DeleteIcon id={`${id}_icon`} />}
      {...onDelete
        ? {
           onDelete: onDelete,
          }
        : {}}
    />
  );
};

export default Button;
