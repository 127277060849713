import { Box, List, styled, Typography } from "@mui/material";

export const CategoryHeaderBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  padding-right: 15px;
`;

export const CategoryHeaderTypography = styled(Typography)`
  white-space: break-spaces;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24.5px;
  text-align: center;
  margin-left: 15px;
`;

export const CategoryGearBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));

export const StyledList = styled(List)(() => ({
  maxHeight: '400px !important'
}));



export const DragAndDropListWrapper = styled(Box)(() => ({
  padding: '10px',
  marginTop: '10px'
}));


export const DragAndDropItemWrapper = styled(Box)`
  width: 300px;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
`;

export const DragAndDropItemLabelBox = styled(Box)(() => ({
  width: "275px",
}));

export const DragAndDropItemSwitchBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));