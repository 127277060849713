import { NameIdPair } from '../../../utils/types/bank.type';

export const FETCH_LINK_TOKEN_ERROR = 'Error connecting with Plaid.';

export type fundLedgerCoAType = {
  id: string;
  name: string;
  ledgers: NameIdPair[];
  CoA: NameIdPair[];
};

export type plaidResAccoutType = {
  id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
};

export type plaidInstitutionType = {
  name: string;
  institution_id: string;
};

export type plaidConnectResponseType = {
  institution: plaidInstitutionType;
  accounts: plaidResAccoutType[];
  public_token: string;
};

export type addAccountFormType = {
  selected: boolean;
  id: string;
  fundId: string | null;
  ledgerId: string | null;
  glAccountId: string | null;
  name: string;
  mask: string;
  type: string;
  subtype: string;
};

export type addBanksFormType = {
  startDate: Date | null;
  accounts: addAccountFormType[];
};

export type arkIntegratedAccount = {
  id: string;
  name: string;
  fundId: string;
  ledgerId: string;
  glAccountId: string;
  startDate: 'string';
};

export type bankIntegrationPayload = {
  publicToken: string;
  accounts: arkIntegratedAccount[];
};

export enum VALIDATION_ERROR {
  INVALID = 'Valid date is required',
  PAST_MIN_DATE = 'Date must be within 2 year from today',
}

export const popularBanksIds = [
  'ins_5', // Citibank Online
  'ins_127989', // Bank of America
  'ins_20', // Citizens Bank
  'ins_100103', // Comerica Bank - Web Banking
  'ins_115569', // First Bank and Trust Company
  'ins_56', // Chase
  'ins_115611', // Merrill Lynch
  'ins_115699', // Morgan Stanley Client Serv
  'ins_129907', // UBS - Online Services
  'ins_127990', // U.S. Bank
];

export enum LoadingMessage {
  LOADING_BANKS = 'Loading Banks...',
  CONNECTING = 'Connecting...',
}
