import { Box, styled } from "@mui/material";

export const ViewPdf = styled(Box)<{ sideView: string; clientView: string }>(
  ({ theme, sideView, clientView }) => ({
    width: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .react-pdf__Page__textContent": {
      border: `1px solid grey`,
      boxShadow: `5px 5px 5px 1px grey`,
      borderRadius: theme.spacing(0.8),
    },
    "& .react-pdf__Page": {},
    "& .react-pdf__Page__canvas": {
      marginTop:
        sideView === "true"
          ? theme.spacing(3.1)
          : clientView === "true"
          ? theme.spacing(5)
          : 0,

      width:
        sideView === "true"
          ? "100% !important"
          : clientView === "true"
          ? "100% !important"
          : "",
      height:
        sideView === "true"
          ? `${theme.spacing(34)} !important`
          : clientView === "true"
          ? "calc(100vh - 100px) !important"
          : "",
    },
  })
);
