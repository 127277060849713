import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { InvestorsNames } from "../../../utils/types/investor.type";
import { getInvestorsNames } from "../../investor.service";

type Props = {
  fundId?: string;
}

export const useInvestorsNamesEffect = ({
  fundId
}: Props) => {
  const [investorsList, setInvestorsList] = useState<InvestorsNames[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    if(!fundId) return;
    try {
      setLoading(true);
      const statusResponse = await getInvestorsNames(fundId);

      if (isCanceled()) return;

      setInvestorsList(statusResponse);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting investors", "error");
      setLoading(false);
    }
  }, [fundId]);

  return {
    investorsList,
    loading
  };
};

