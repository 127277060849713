import { Box,styled } from '@mui/material';

export const BorderContainer = styled(Box)(({
  theme
}) => ({
  display:"grid",
  alignItems:"center",
  gridTemplateColumns: "0.65fr 1fr 0.65fr 0.80fr 0.60fr 0.6fr",
  padding:theme.spacing(2,4),
  gap:theme.spacing(2.5),
  borderBottom: `1px solid ${theme.palette.secondary.dark}`
}));

export const SvgContainer = styled(Box)(({
  theme
}) => ({
  paddingLeft:theme.spacing(2)
}));

export const AlignCenterContainer = styled(Box)`
    display:flex;
    align-items: center
`;

export const PerviewReportContainer = styled(Box)`
    display:flex;
    align-items: center
`;

export const SectionContainer = styled(Box)(({
  theme
})=> ({
  display: 'grid',
  gridGap: theme.spacing(1),
  marginTop: theme.spacing(4)
}));

export const GeneralSettingsContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export const MainContainer = styled(Box)(({
  theme
})=>({
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius:theme.spacing(2)
}));

export const HorizontalContainer = styled(Box)(({
  theme
})=>({
  display: 'grid',
  gridTemplateColumns: '0.1fr 3fr 0.1fr',
}));


export const HorizontalLine = styled(Box)(({
  theme
})=> ({
  width:"100%",
  border:`1px dashed ${theme.palette.secondary.dark}`,
  boxSizing:"border-box",
  borderRadius:theme.spacing(0.5),
}));

export const HorizontalText = styled(Box)(({
  theme
}) => ({
  textAlign:"center",
  borderBottom:`2px solid ${theme.palette.grey[500]}`,
  lineHeight:"0.1",
  margin:theme.spacing(2,2,3)
}));

export const HorizontalTextLayer = styled(Box)(({
  theme
})=> ({
  backgroundColor:theme.palette.common.white,
  padding:theme.spacing(0,2),
  display:'inline-block',
  position:"relative",
  top:theme.spacing(1)
}));
