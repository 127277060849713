import { DownloadHistory } from "../utils/types/downloadHistory.type";
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

export const getDownloadHistory = async () => await apiClient().get<DownloadHistory[]>('downloads');

export const downloadIndividual = async (id: string) => await apiClient().get<any>(`downloads/${id}`, {
  responseType: 'blob'
});

