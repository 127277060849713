import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { AppContext } from "../../../../core/context/appContextProvider";
import useRole from "../../../../core/routing/useRole";
import {
  createDistributionSetUp,
  getDistribution,
  getDistributionDocuments,
  getDistributionTeamRoleswithMembersForFund,
  updateDistribution,
  uploadDistributionDocument,
} from "../../../../services/distributions.service";
import { useDistributionDetailsEffect } from "../../../../services/hooks/useDistributionsEffect/useDistributionDetailsEffect.hook";
import { awaitReactUpdate } from "../../../../utils/helpers/timeoutFunctions";
import {
  DistributionDocument,
  Distributions,
  DistributionTeamMembers,
} from "../../../../utils/types/distributions.type";
import { ScopeRole } from "../../../../utils/types/user.type";
import {
  CREATE_DISTRIBUTION_CALL_ERROR,
  CREATE_DISTRIBUTION_SUCCESS,
  CREATE_DISTRIBUTION_UPDATE,
  DISTRIBUTION_DOCUMENT_GET_ERROR,
  DISTRIBUTION_DOCUMENT_UPLOAD_ERROR,
  DISTRIBUTION_REVIEW_VALIDATION_ERROR,
  DISTRIBUTION_TEAM_MEMBERS_ROLE_ERROR,
  DistributionDetailsTabs,
  InitialErrorMessages,
} from "../Constants";

interface RouteProp {
  id: string;
  section: string;
}

const DefaultTeamMembers = {
  ASSOCIATE: [],
  MANAGER: [],
  DIRECTOR: [],
  FUND_CONTROLLER: [],
  FUND_USER_READ_ONLY: [],
};

const newDistribution = {
  name: "",
  fundId: "",
  fundName: "",
  reviewers: [],
  clientId: "",
  amount: 0,
  date: new Date(),
  documentId: "",
  emailDocumentId: "",
  id: "",
};

export interface IDistributionSetupForm extends Distributions {
  noticeTemplate?: FileList;
  emailTemplate?: FileList;
}

export const useDistributionSetUp = (onNavigateAwayCanceled: () => void) => {
  const history = useHistory();
  const { id } = useParams<RouteProp>();
  const [teamMembers, setTeamMembers] =
    useState<DistributionTeamMembers>(DefaultTeamMembers);
  const [documents, setDocuments] = useState<
    DistributionDocument | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const { informationAlert, state } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState<any>(InitialErrorMessages);
  const { distributions } = useDistributionDetailsEffect(
    id !== "new" ? id : undefined
  );

  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    getValues,
    setValue,
    reset,
    trigger
  } = useForm<IDistributionSetupForm>({
    defaultValues: {
      clientId: "",
      amount: 0,
      date: new Date(),
      documentId: "",
      emailDocumentId: "",
      id: "",
    },
  });

  const allowEdit = useMemo(() => {
    if (id !== "new") {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          distributions?.createdBy?.toLowerCase() &&
        distributions?.status?.toLowerCase() !== "published"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [id, distributions, state]);

  const allowReviewerEdit = useMemo(() => {
    if (distributions !== null) {
      const reviewersList = distributions?.reviewers?.reduce(
        (
          acc: string[],
          current: {
            name?: string;
          }
        ) => {
          const teamMembersForRole =
            teamMembers?.[
              (current?.name as keyof DistributionTeamMembers) || ""
            ] || [];

          if (teamMembersForRole?.length > 0) {
            return [...acc, ...teamMembersForRole];
          }
          return acc;
        },
        []
      );

      if (id !== "new") {
        if (
          state.loginUser.currentUser?.username.toLowerCase() !==
            distributions?.createdBy?.toLowerCase() &&
          distributions?.status?.toLowerCase() === "reviewing" &&
          reviewersList?.includes(
            state.loginUser.currentUser?.username.toLowerCase() || ""
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }, [id, distributions, state, teamMembers]);

  const addDistribution = async (data: IDistributionSetupForm | any) => {
    const { noticeTemplate, emailTemplate, ...distributionData } =
      data as IDistributionSetupForm;

    try {
      setLoading(true);
      if (id === "new") {
        const res = await createDistributionSetUp(distributionData);

        if (noticeTemplate) {
          await uploadDistributionDocument(
            res.distributionId,
            noticeTemplate,
            "document"
          );
        }

        if (emailTemplate) {
          await uploadDistributionDocument(
            res.distributionId,
            emailTemplate,
            "email"
          );
        }

        history.replace(
          `/distributions/${res.distributionId}/${DistributionDetailsTabs.DistributionSetup}`
        );

        informationAlert(CREATE_DISTRIBUTION_SUCCESS, "success");
      } else {
        await updateDistribution(id, distributionData);
        if (noticeTemplate) {
          await uploadDistributionDocument(id, noticeTemplate, "document");
        }

        if (emailTemplate) {
          await uploadDistributionDocument(id, emailTemplate, "email");
        }
        informationAlert(CREATE_DISTRIBUTION_UPDATE, "success");
      }
    } catch (error) {
      informationAlert(CREATE_DISTRIBUTION_CALL_ERROR, "error");
    } finally {
      setLoading(false);
    }
  };

  const teamRolesWithMembers = async (fundId: string) => {
    try {
      setTeamMembers(await getDistributionTeamRoleswithMembersForFund(fundId));
    } catch (error) {
      informationAlert(DISTRIBUTION_TEAM_MEMBERS_ROLE_ERROR, "error");
    }
  };

  const AddDistributionDocument = async (
    id: string,
    file: FileList,
    type: string
  ) => {
    try {
      await uploadDistributionDocument(id, file, type);
    } catch (error) {
      informationAlert(DISTRIBUTION_DOCUMENT_UPLOAD_ERROR, "error");
    }
  };

  const getDistributionDocument = async (id: string) => {
    try {
      const response = await getDistributionDocuments(id);

      const detailResponse = await getDistribution(id);

      reset(detailResponse);

      setDocuments(response);
    } catch (error) {
      informationAlert(DISTRIBUTION_DOCUMENT_GET_ERROR, "error");
    }
  };

  const handleconfirmSaveAndNavagateAway = async (form: HTMLFormElement) => {
    const validForm = await trigger();

    if(!validForm) {
      onNavigateAwayCanceled();
      return false;
    }

    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));    
    await awaitReactUpdate(2000);

    return true;
  };

  useEffect(() => {
    if (distributions) {
      teamRolesWithMembers(distributions?.fundId as string);
      reset(distributions);
      setLoading(false);
    } else {
      reset(newDistribution);
      setLoading(false);
    }
  }, [distributions]);

  useEffect(() => {
    if (id && id !== "new") {
      setLoading(true);
      getDistributionDocument(id).then(() => setLoading(false));
      trigger();
    }
  }, [id]);

  const handleValidateReviewers = (
    value: { name: string }[] | null | undefined
  ) => {
    let isValid = true;
    const errMsg: { [key: string]: any } = {};

    value?.forEach((reviewer) => {
      const filteredTeamMembers =
        teamMembers[
          (reviewer.name as keyof DistributionTeamMembers) || ""
        ]?.filter(
          (user) =>
            user?.toLowerCase() !==
            state.loginUser.currentUser?.username?.toLowerCase()
        ) || [];

      if (filteredTeamMembers.length === 0) {
        errMsg[reviewer.name] = DISTRIBUTION_REVIEW_VALIDATION_ERROR;
        isValid = false;
      } else errMsg[reviewer.name] = "";
    });

    setErrorMessage({ ...InitialErrorMessages, ...errMsg });
    return isValid;
  };

  useEffect(() => {
    handleValidateReviewers(getValues("reviewers"));
  }, [teamMembers]);
  return {
    register,
    handleSubmit,
    errors,
    control,
    addDistribution,
    teamRolesWithMembers,
    getValues,
    setValue,
    teamMembers,
    AddDistributionDocument,
    id,
    documents,
    setDocuments,
    loading,
    isSuperAdminOrClientAdmin,
    allowEdit,
    allowReviewerEdit,
    handleValidateReviewers,
    errorMessage,
    setErrorMessage,
    isDirty,
    handleconfirmSaveAndNavagateAway
  };
};
