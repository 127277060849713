import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import InvestorFilter from '../../../../components/Selectors/InvestorsFilter';
import TransactionTypeFilter from '../../../../components/Selectors/TransactionTypesFilter';
import NumberFormatCustom from '../../../../components/TextFieldNumeric/NumberFormat';
import { Transaction } from '../../../../utils/types/capitalCalls.type';
import { DatePicker } from '../../../fund/fundDetails/FundDetails.style';
import { CapitalCallDetailsTabs } from '../constants';
import {
  AddButton,
  ButtonContainer,
  FormBox,
  StyledDivider,
  StyledStack,
} from './AddTransactionDetails.styles';
import { useAddTransactionDetails } from './useAddTransactionDetails.hooks';

type Props = {
  handleAddTransaction: (transaction: Transaction) => void;
  setIsAddingNewTransactionRow: (value: boolean) => void;
  isAddingNewTransactionRow: boolean;
  cancelCreateUpdateTransaction: () => void;
  showDirtyModal: boolean;
  addAndUpdateTransaction: (transaction: Transaction) => void;
  isGoBack: boolean;
  setIsGoBack: (value: boolean) => void;
};

const AddTransactionsDetails = ({
  handleAddTransaction,
  setIsAddingNewTransactionRow,
  isAddingNewTransactionRow,
  cancelCreateUpdateTransaction,
  showDirtyModal,
  addAndUpdateTransaction,
  isGoBack,
  setIsGoBack,
}: Props) => {
  const {
    register,
    handleSubmit,
    addTransaction,
    control,
    errors,
    capitalCall,
    addTransactionPopUp,
    clearErrors,
  } = useAddTransactionDetails({
    handleAddTransaction,
    setIsAddingNewTransactionRow,
    isAddingNewTransactionRow,
    cancelCreateUpdateTransaction,
    addAndUpdateTransaction,
    setIsGoBack,
  });
  const history = useHistory();

  return (
    <Box>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(addTransaction)}
        noValidate
      >
        <FormBox>
          <Controller
            name="name"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  label="Transaction Name"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(name) => {
                    onChange(name);
                    clearErrors('name');
                  }}
                  id="transaction_name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!error}
                  helperText={error?.message ?? ''}
                />
              );
            }}
            rules={{
              required: 'Name is required',
              validate: (value: any) =>
                !!value?.trim() === true ? true : 'Name is required ',
            }}
            control={control}
          />

          <TextField
            id="fund_name"
            size="small"
            aria-describedby="name"
            label="Fund Name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            {...register('fund')}
          />

          <StyledStack>
            <Controller
              name="currentInvestor"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InvestorFilter
                  size="small"
                  id={'transaction_investor_filter'}
                  value={value}
                  fundId={capitalCall ? capitalCall?.fundId : undefined}
                  onChange={(investor) => {
                    onChange({
                      id: investor?.id,
                      name: investor?.name,
                    });
                    clearErrors('currentInvestor');
                  }}
                  isShrink={true}
                  error={error}
                  placeholder="Investor Name"
                  isPlaceHolder={false}
                />
              )}
              rules={{
                required: 'Investor is required',
              }}
              control={control}
            />
          </StyledStack>
          <StyledStack>
            <Controller
              name="currentType"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TransactionTypeFilter
                  size="small"
                  id={'transaction_type_filter'}
                  value={value}
                  error={error}
                  onChange={(txnType) => {
                    onChange({
                      id: txnType?.id,
                      name: txnType?.name,
                    });
                    clearErrors('currentType');
                  }}
                  placeholder="Transaction Type"
                  isShrink={true}
                  isPlaceHolder={false}
                />
              )}
              rules={{
                required: 'Transaction Type is required',
              }}
              control={control}
            />
          </StyledStack>
          <Controller
            name="amount"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  label="Amount"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(amount) => {
                    onChange(amount);
                    clearErrors('amount');
                  }}
                  id="transaction_amount"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  error={!!error}
                  helperText={error?.message ?? ''}
                />
              );
            }}
            rules={{
              required: 'Amount is required',
              validate: (value: any) =>
                !!value?.trim() === true ? true : 'Amount is required ',
            }}
            control={control}
          />
          <Controller
            name="date"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <DatePicker
                  label="Date"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      id="transaction_date"
                      error={!!error}
                      placeholder="MM/DD/YYYY"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={error?.message ?? ''}
                    />
                  )}
                  onChange={(newValue) => {
                    onChange({
                      target: {
                        value: newValue,
                      },
                    });
                    clearErrors('date');
                  }}
                  value={value || null}
                />
              );
            }}
            rules={{
              required: 'Date is required',
              validate: (value) => {
                if (value.toString() === 'Invalid Date') {
                  return 'Invalid Date';
                } else {
                  return true;
                }
              },
            }}
            control={control}
          />
        </FormBox>
        <StyledDivider variant="fullWidth" />
        <ButtonContainer>
          <AddButton id="btn_go_back" variant="outlined" type="submit">
            Add Transaction
          </AddButton>
        </ButtonContainer>
      </form>
      <ConfirmationDialog
        open={showDirtyModal}
        onClose={cancelCreateUpdateTransaction}
        id="add_transaction_confirmation"
        actions={[
          {
            label: 'Add  & Save Transaction',
            onClick: addTransactionPopUp,
            id: 'cancel_transaction_button',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Continue without Saving',
            onClick: () => {
              isGoBack
                ? history.push(
                    `/capital-calls/${capitalCall?.id}/${CapitalCallDetailsTabs.CapitalCallSetUp}`
                  )
                : history.push(
                    `/capital-calls/${capitalCall?.id}/${CapitalCallDetailsTabs.ReviewDocuments}`
                  );
            },
            id: 'create_transaction',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <Box component="span">
            An unsaved transaction may have not been submitted to the list. Do
            you want to add the transaction(s) in list, or continue without
            saving the changes?
          </Box>
        }
        title="Confirm Save Transaction"
      />
    </Box>
  );
};

export default React.memo(AddTransactionsDetails);
