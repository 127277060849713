import {
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState, } from 'react';

import CountrySelect from "../../components/CountryCodeSelector/CountrySelect";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";
import { AppContext } from '../../core/context/appContextProvider';
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import {
  getUserPhoneDetails,
  registerUserPhone
} from '../../services/login.service';
import {
  ActionButton,
  ContentDiv,
  DivSpacing,
  StyledLabel,
} from "./Login.styles";

const MfaSetup = ({ 
  loginToken ,
  setCurrentPage,
  setUserDetailInfo,
  userDetailInfo
}: { 
  loginToken: string 
  setCurrentPage: any 
  setUserDetailInfo: any
  userDetailInfo: any
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [phoneDetails, setPhoneDetails] = useState<any>({
    countryCode: "1",
    countryAbbreviation: "us",
    phoneNumber: ""
  });
  const [error, setError]= useState<string>("");

  const {
    clientTheme
  } = useContext(ClientThemeContext);

  const { preAuthClientConfigs } = useContext(AppContext);

  useEffect(() => {
    if(userDetailInfo?.phoneNumber) {
      setCurrentPage("RequestCode");
      setShowLoader(false);
    }

  },[userDetailInfo?.phoneNumber]);

  const LabelText = (text: string) => (
    <StyledLabel color={clientTheme?.loginTextColor}>{text}</StyledLabel>
  );
  
  const handleCountryCodeChange = (
    countryCode: string,
    countryAbbreviation: string,
    phoneNumber: string
  ) => {

    setPhoneDetails(((prev: any) => ({
      ...prev,
      countryCode,
      countryAbbreviation,
      phoneNumber
    })));
    if(!phoneDetails?.phoneNumber || !phoneDetails?.countryCode) {
      setError("Please provide your phone number with country code");
    } else {
      setError("");
    }
  };


  const registerPhone = async () => {
    if(!phoneDetails?.phoneNumber || !phoneDetails?.countryCode) {
      setError("Please enter your phone number with country code");
    } else {
      setShowLoader(true);
      try {
        const registeredPhoneInfo: any = await registerUserPhone({
          ...phoneDetails
        }, loginToken);

        if(registeredPhoneInfo?.success) {
          const phone: any = await getUserPhoneDetails(loginToken);

          if(phone) {
            setUserDetailInfo(((prev: any)=> ({
              ...prev,
              ...phone
            })));
          }
          
        }
      } catch (e) {
        setShowLoader(false);
      }
    }
  };

  return (
      <div>
        <Progress id="login_loader" showProgress={showLoader} />
        <Grid container justifyContent="center">
          <ContentDiv>
            <Typography variant="h1">
              {LabelText("Multi-factor Authentication Setup")}
            </Typography>
            <DivSpacing />
            <Typography variant="h5">
              {LabelText(`${preAuthClientConfigs?.clientName ?? 'Admin Dev'} requires secondary authentication. Please enter a phone number to receive phone call or text codes.`)}
            </Typography>
            <DivSpacing />
            <Typography variant="h2">
              {LabelText(`Register Phone Number`)}
            </Typography>
            <DivSpacing />
            {error && <FormLabel error={true}> {error}</FormLabel>}
            <DivSpacing />
            <CountrySelect
              id={"select_country_contact_details"}
              onChange={handleCountryCodeChange}
              value={phoneDetails.countryCode + phoneDetails.phoneNumber}
              fullWidth={true}
              listitemcolor={'black'}
              label={''}
            />
            <DivSpacing/>
            <ActionButton
              variant="contained"
              className="btn"
              disableElevation
              textcolor={clientTheme?.loginButtonTextColor}
              btnbackgroundcolor={
                clientTheme?.loginButtonBackgroundColor
              }
              fullWidth
              onClick={registerPhone}
              >
              Next
            </ActionButton>
          </ContentDiv>
        </Grid>
    </div>
  );
};

export default MfaSetup;