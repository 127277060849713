import { ReactElement } from "react";

import {
  StyledBox,
  StyledInfoLabel,
  StyledPhoneInput,
} from "./CountrySelect.styles";

type Props = {
  id: string;
  onChange: any;
  label?: string;
  value: string;
  fullWidth?: boolean;
  error?: boolean
  listitemcolor?: string;
  country?: string;
};

const CountrySelect = (props: Props): ReactElement => {
  const {
    id, onChange, label, value, fullWidth = false, error = false, listitemcolor, country
  } = props;
  const handleOnChange = (
    value: string | any[],
    data: any,
  ) => {
    if(data.dialCode && data.countryCode) {
      onChange(data.dialCode, data.countryCode, value.slice(data.dialCode.length), value);
    }
  };

  return (
    <StyledBox id={id} fullwidth={fullWidth?.toString()}>
      <StyledInfoLabel variant="TextBoxTitle" error={error?.toString()}> {label}</StyledInfoLabel>
      <StyledPhoneInput
        fullwidth={fullWidth?.toString()}
        listitemcolor={listitemcolor}
        error={error?.toString()}
        value={value}
        onChange={handleOnChange}
        preferredCountries={["us"]}
        enableAreaCodes={false}
        specialLabel=""
        placeholder={label}
        key={`phone_number_${id}`}
        country={country || ""}
      />
    </StyledBox>
  );
};

export default CountrySelect;
