import { Box,InputAdornment, TextField, Typography } from "@mui/material";
import { ColorError, ColorPicker, ColorValue } from 'mui-color';

import { ColorPickerWrapper, ColorSelectorBoxMain } from "./ColorSelector.style";

type Props={
    title:string;
    subTitle:string;
    onChange: (color:string) => void;
    value?: string | null;
    defaultValue?: string;
    id: string;
}

const ColorSelector = ({
  id,
  title,
  subTitle,
  onChange,
  value = "",
  defaultValue
}:Props) => {
  const handleChange = (newValue: ColorValue) => {
    onChange(typeof newValue === 'string' ? newValue : (newValue as ColorError)?.hex);
  };

  return(
    <ColorSelectorBoxMain>
      <Typography variant="formTitle2">{title}</Typography>
      <Typography variant="subInfo">{subTitle}</Typography>
      <ColorPickerWrapper>
        <ColorPicker
          deferred
          defaultValue={defaultValue || ""}
          value={value ? `#${value}` : ""}
          hideTextfield
          onChange={handleChange} />
        <TextField variant="outlined"
          id={`${id}_text_field`}
          value={value || ""}
          size="small"
          label="Hex"
          onChange={(e) => onChange(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
        />
      </ColorPickerWrapper>
    </ColorSelectorBoxMain>
  );
};

export default ColorSelector;
