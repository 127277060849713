import { Box, styled, TextField } from '@mui/material'; 

export const LabelTextField = styled(TextField)<{styles: string[]}>(({
    styles
}) => ({
    '& div input': {
        fontWeight: styles?.includes('BOLD') ? 'bold' : 'unset',
        fontStyle: styles?.includes('ITALIC') ? 'italic' : 'unset',
    }
}));

export const RcDataListContainer = styled(Box)<{spacingOrder:string|undefined}>(({
    theme, spacingOrder
}) =>({
    display: "grid",
    alignItems: 'center',
    gridTemplateColumns: "0.3fr 0.6fr 1fr 0.5fr auto",
    gridGap: theme.spacing(2.5),
    marginLeft: spacingOrder
}));

export const RcBorderBox = styled(Box)(({
    theme
}) =>({
    border : `1px dashed ${theme.palette.secondary.dark}`,
    paddingRight: theme.spacing(0.7),
    display: "flex",
    alignItems: "center",
    height: theme.spacing(5),
    justifyContent: "flex-end",
    borderRadius: theme.spacing(0.625)
}));    

export const BorderBoxContainer = styled(Box)(({
    theme
}) =>({
    display: "grid", 
    flexWrap: "wrap",
    alignItems:"center",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3,minmax(0,1fr))"
}));


export const Spacer = styled(Box)(({
    theme
}) => ({
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    border: `2px dashed ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(0.625),
}));

export const SpacerText = styled('span')(({
    theme
}) => ({
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(1)
}));

export const SelectBoxContainer = styled(Box)(({
    theme
})=>({
    display: "grid",
    gridRowGap: theme.spacing(0.8)
}));


export const LabelBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TextFeildContainer = styled(Box)(({
    theme
})=>({
    display: "grid",
    gridRowGap: theme.spacing(0.8)
}));

export const SpacerBox = styled(Box)`
    display: grid;
    grid-template-columns: 2fr 0.1fr;
`;

