import { Grid, Typography } from "@mui/material";
import { FC } from "react";

import IconBrokenRobot from "../../../assets/images/icons/broken_robot.svg";
import { authErrorEffect, ErrorType } from "./AuthError.hooks";
import { StyledContentGrid,StylesRetryButton } from "./AuthError.styles";

const AuthError: FC = () => {

  const {        
    handleRetryLogin,
    supportEmail,
    errorType
  } = authErrorEffect();

  return (
    <>
      <StyledContentGrid container spacing={1}>
        <Grid item>
            <img src={IconBrokenRobot} alt="Broken Image" height="400px" width="400px" />
        </Grid>
        <Grid item>
          <Typography variant="h3">
            {
              (errorType === ErrorType.General) && 
                <>An error has occurred, return to the login page and try again. If this error persists, contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a></>
            }
            {
              (errorType === ErrorType.AccessDenied) && 
                <>Incorrect Email or Password</>
            }
          </Typography>
        </Grid>
        {
          (errorType !== ErrorType.Auth0General) && (
            <>
              <Grid item height="50px"/>
              <Grid item>
                <StylesRetryButton
                  id={"btn_fallback_reload"}
                  variant={"outlined"}
                  text={"Return to login"}
                  color={"primary"}
                  onClick={handleRetryLogin}
                />
              </Grid>
            </>
          )
        }
      </StyledContentGrid>
    </>
  );
};

export default AuthError;
