import { Box, Button, Drawer, styled } from "@mui/material";

import { INFO_PANEL_MAX_WIDTH } from "../../../../utils/constants/styles.constants";

export const DetailPanel = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: INFO_PANEL_MAX_WIDTH,
    maxWidth: INFO_PANEL_MAX_WIDTH,
  }
}));

export const TitleBox = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(4)
}));

export const FormBox = styled(Box)(({
  theme
}) => ({
  padding: theme.spacing(4),
  paddingTop: 0
}));

export const ArrowIconBox = styled(Box)(({
  theme
}) => ({
  marginLeft: theme.spacing(4),
  display: "flex",
  alignItems: "center"
}));

export const LinkButton = styled(Button)(({
  theme
}) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '16px',
}));
