import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import {
  downloadCapAccReportExcel,
  downloadStatementReportPdf,
  getStatementReport,
} from "../../../services/capitalAccounts.service";
import downloadFile from "../../../utils/helpers/fileDownloader";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import {
  SelectedFund,
  SelectedTransaction,
  StatementReportParams,
  StatementReportResponse,
  StatRepColumns,
  StatRepRows,
} from "../../../utils/types/capitalAccounts.type";
import { DownloadStatementReportOptionIds } from "../../../utils/types/common.type";

type Props = {
  fund?: SelectedFund;
  onStatementReportViewClose: Function;
  resetPaginationAndFetch: Function;
  setSelectedTransaction: Function;
};

export const DOWNLOAD_REPORT_ERROR = "Error in downloading statement report.";

export const useStatementReport = ({
  fund,
  onStatementReportViewClose,
  resetPaginationAndFetch,
  setSelectedTransaction,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statementReport, setStatementReport] =
    useState<StatementReportResponse | null>(null);
  const [columns, setColumns] = useState<StatRepColumns[]>([]);
  const [statementRows, setStatementRows] = useState<StatRepRows[]>([]);
  const { informationAlert } = useContext(AppContext);

  const toggleDrawer = () => {
    closeDrawer();
  };

  const closeDrawer = () => {
    onStatementReportViewClose();
  };

  const fetchStatementReport = async (isCanceled: Function) => {
    const { fundId, investorId, quarter } = fund || {};

    if (fundId && investorId && quarter) {
      try {
        setIsLoading(true);
        const params: StatementReportParams = {
          fundId,
          investorId,
          quarter,
        };
        const statementReportResponse: StatementReportResponse =
          await getStatementReport(params);

        if (isCanceled()) return;
        setStatementReport(statementReportResponse);
        setColumns(statementReportResponse.columns);
        setStatementRows(statementReportResponse.rows);
      } catch (e) {
        informationAlert("Error in getting statement report", "error");
      }
    }
    setIsLoading(false);
  };

  useEffectAsync(
    async (isCanceled) => {
      await fetchStatementReport(isCanceled);
    },
    [fund]
  );

  const handleSplitButtonAction = (
    actionId: DownloadStatementReportOptionIds
  ) => {
    setIsLoading(true);
    switch (actionId) {
      case DownloadStatementReportOptionIds.DownloadExcel:
        downloadExcel();
        break;
      case DownloadStatementReportOptionIds.DownloadPdf:
        downloadPdf();
        break;
    }
  };

  const downloadExcel = async () => {
    const { id, investorId, fundId, fundName, investorName, quarter } =
      fund || {};

    if (id && quarter) {
      try {
        const downloadedData = await downloadCapAccReportExcel({
          investorFundIds: [`${investorId}--${fundId}`],
          quarterName: quarter,
        });

        downloadFile(
          downloadedData,
          `${investorName}-${fundName}-${quarter}-CapitalAccountStatement`,
          "csv"
        );
      } catch (error) {
        informationAlert(DOWNLOAD_REPORT_ERROR, "error");
      }
    }

    setIsLoading(false);
  };

  const downloadPdf = async () => {
    const { fundId, investorId, quarter, investorName, fundName } = fund || {};

    if (fundId && investorId && quarter) {
      try {
        const downloadedData = await downloadStatementReportPdf({
          fundId,
          investorId,
          quarter,
        });

        downloadFile(
          downloadedData,
          `${investorName}-${fundName}-${quarter}-CapitalAccountStatement`,
          "pdf"
        );
      } catch (error) {
        informationAlert(DOWNLOAD_REPORT_ERROR, "error");
      }
    }

    setIsLoading(false);
  };

  const handleValueClick = (col: StatRepColumns, row: StatRepRows) => {
    const { fundId, investorId, quarter, fundName, investorName } = fund || {};
    const { label, type, transactionTypes } = row;
    const { reportColumnType } = col;

    if (fundId && investorId && quarter) {
      const payload: SelectedTransaction = {
        additionalReportElements: [],
        fundId,
        investorId,
        quarter,
        label,
        reportColumnType,
        reportElementType: type,
        transactionTypes,
        fundName,
        investorName,
        currencyCode: fund?.currencyCode || "USD",
      };

      setSelectedTransaction(payload);
    }
  };

  return {
    toggleDrawer,
    isLoading,
    statementReport,
    columns,
    statementRows,
    handleSplitButtonAction,
    handleValueClick,
  };
};
