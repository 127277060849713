import { Box, styled } from "@mui/material";

export const ButtonBox = styled(Box)`
  display: flex;
`;

export const AddressList = styled("div")`
  padding: 10%;
`;

export const StyledDiv = styled("div")`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const DivMargin = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    background-color: #fff;
    box-shadow: 24;
    padding: 25px;

  // Title
  & h2.MuiTypography-root {
    font-weight: bold;
    margin: 10px 0 20px 0;
  }

  // Description
  & p.MuiTypography-root:nth-of-type(2) {
    margin: 10px 0;
    font-weight: normal;
  }

  // Divider
  & .MuiDivider-root {
    margin: 25px 0;
  }

  & .MuiFormControl-root {
    width: 100%;
    padding: 0;
  }
`;