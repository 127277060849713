import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendIcon from '@mui/icons-material/Send';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  createFilterOptions,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import { updateBankTransactions } from '../../../../services/bank.service';
import {
  AccountMatchedBankTransaction,
  GlAccountDetails,
  nameIdFundPair,
  NameIdPair,
  nameIdTypePair,
  uneditableTransInBulkEdit,
  UpdateBankTransactionsPayload,
} from '../../../../utils/types/bank.type';
import {
  BankStatusOptions,
  PlaidBulkEditFieldOptions,
} from '../../bankFeeds/BankFeedList.defaults';
import {
  BottomPlacementBulkEditFormControl,
  BulkEditButtonBox,
  BulkEditFormControl,
  BulkEditFormControlLabel,
  BulkEditHeaderBox,
  TransactionTypeBox,
  TransactionTypeButtonGroup,
  TransactionTypeDivider,
} from '../../bankFeeds/BankFeedList.styles';
import {
  AutocompleteGroupHeader,
  BlueModeEditOutlinedIcon,
  BulkEditBox,
  BulkEditCloseButton,
  BulkEditHeader,
  BulkEditInputBox,
  MessagingSubHeader,
  MessagingSubSubHeader,
  OrangeErrorOutlineOutlinedIcon,
} from './BulkEdit.styles';

interface Props extends RouteProps {
  showBulkEdit: boolean;
  closeBulkEdit: () => void;
  selectionModel: string[];
  setSelectionModel: (value: string[]) => void;
  dataList: AccountMatchedBankTransaction[];
  memoEntityList: nameIdTypePair[];
  CoAList: GlAccountDetails[];
  fundList: NameIdPair[];
  fetchBankTransactions: () => void;
}

interface TreeNode extends GlAccountDetails {
  children: TreeNode[];
  depth: number;
}

export const BankBulkEditREV: React.FC<Props> = ({
  showBulkEdit,
  closeBulkEdit,
  selectionModel,
  setSelectionModel,
  dataList,
  memoEntityList,
  CoAList,
  fundList,
  fetchBankTransactions,
}: Props) => {
  const { informationAlert } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [editableTransactionsData, setEditableTransactionsData] = useState<
    AccountMatchedBankTransaction[]
  >([]);
  const [uneditableTransactions, setUneditableTransactions] =
    useState<uneditableTransInBulkEdit>({
      total: 0,
      nonGlBankAccountTotal: 0,
      categorizedTransTotal: 0,
    });
  const [uniqueFundOptions, setUniqueFundOptions] = useState<NameIdPair[]>([]);
  const [glAccountTree, setGlAccountTree] = useState<GlAccountDetails[]>([]);

  const [selectedFund, setSelectedFund] = useState<NameIdPair>();
  const [selectedGlAccount, setSelectedGlAccount] =
    useState<GlAccountDetails>();
  const [selectedMemoEntity, setSelectedMemoEntity] =
    useState<nameIdTypePair>();
  const [isEntityRequiredByBankAcc, setIsEntityRequiredByBankAcc] =
    useState<boolean>(false);
  const [ignoreMemoEntityRequired, setIgnoreMemoEntityRequired] =
    useState<boolean>(false);
  const [selectedBulkMappingField, setSelectedBulkMappingField] =
    useState<string>('');
  const [showFundSelector, setShowFundSelector] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!showBulkEdit) return;
    resetBulkEdit();

    const selectedTransactions = dataList.filter((transaction) =>
      selectionModel.includes(transaction.id)
    );

    let nonGlBankAccountTotal = 0;
    let categorizedTransTotal = 0;
    const editableTransactions: AccountMatchedBankTransaction[] = [];
    const uniqueFundIds = new Set<string>();

    for (let i = 0; i < selectedTransactions.length; i++) {
      if (selectedTransactions[i].isNonGlBankAccount) {
        nonGlBankAccountTotal++;
      } else if (
        selectedTransactions[i].status === BankStatusOptions.Categorized
      ) {
        categorizedTransTotal++;
      } else {
        editableTransactions.push(selectedTransactions[i]);
      }
      uniqueFundIds.add(selectedTransactions[i].fundId);
    }

    const uniqueFundOptions: NameIdPair[] = Array.from(uniqueFundIds).map(
      (id) => {
        const matchingFund = fundList.find((fund) => fund.id === id);

        return { id, name: matchingFund?.name || '' };
      }
    );

    setUneditableTransactions({
      total: nonGlBankAccountTotal + categorizedTransTotal,
      nonGlBankAccountTotal: nonGlBankAccountTotal,
      categorizedTransTotal: categorizedTransTotal,
    });
    setEditableTransactionsData(editableTransactions);
    setUniqueFundOptions(Array.from(uniqueFundOptions));
    resetBulkEdit();
  }, [selectionModel, showBulkEdit, dataList]);

  const resetBulkEdit = () => {
    setSelectedBulkMappingField('');
    setSelectedFund(undefined);
    setSelectedGlAccount(undefined);
    setSelectedMemoEntity(undefined);
    setGlAccountTree([]);
    setIsEntityRequiredByBankAcc(false);
    setIgnoreMemoEntityRequired(false);
  };

  const getFormattedGlAccounts = (fundId: string) => {
    let isEntityRequiredByBankAcc = false;

    for (let i = 0; i < editableTransactionsData.length; i++) {
      if (
        editableTransactionsData[i].fundId === fundId &&
        editableTransactionsData[i].isEntityRequiredByBankAcc
      ) {
        isEntityRequiredByBankAcc = true;
      }
    }

    const fundFilteredGLAccountList: GlAccountDetails[] = CoAList.filter(
      (item) => item.fundId === fundId
    );

    const buildTree = (
      fundFilteredGLAccountList: GlAccountDetails[]
    ): TreeNode[] => {
      const map: { [key: string]: TreeNode } = {};
      const roots: TreeNode[] = [];

      fundFilteredGLAccountList.forEach((item: GlAccountDetails) => {
        map[item.id] = { ...item, children: [], depth: 0 };
      });

      fundFilteredGLAccountList.forEach((item) => {
        if (item.parentId === null) {
          roots.push(map[item.id]);
        } else {
          map[item.parentId].children.push(map[item.id]);
        }
      });

      return roots;
    };

    const flattenTree = (nodes: TreeNode[], depth = 0): GlAccountDetails[] => {
      let result: GlAccountDetails[] = [];

      nodes.forEach((node) => {
        node.depth = depth;
        node.isDisabled = false;
        result.push(node);
        if (node.children.length > 0) {
          result = result.concat(flattenTree(node.children, depth + 1));
        }
      });
      return result;
    };

    const treeData = buildTree(fundFilteredGLAccountList);
    const glAccountOptions = flattenTree(treeData);

    setGlAccountTree(glAccountOptions);
    setIsEntityRequiredByBankAcc(isEntityRequiredByBankAcc);
  };

  useEffect(() => {
    if (uniqueFundOptions.length === 1) {
      getFormattedGlAccounts(uniqueFundOptions[0]?.id);
    }
  }, [uniqueFundOptions, selectedBulkMappingField]);

  useEffect(() => {
    const isEntityRequired =
      (isEntityRequiredByBankAcc || selectedGlAccount?.isEntityRequired) &&
      !ignoreMemoEntityRequired;

    const isEntityReqMet =
      !isEntityRequired || (isEntityRequired && selectedMemoEntity?.id);

    const isNewValueSelected = Boolean(
      selectedGlAccount?.id || selectedMemoEntity?.id
    );

    if (isNewValueSelected && isEntityReqMet) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [
    selectedGlAccount,
    selectedMemoEntity,
    ignoreMemoEntityRequired,
    selectedBulkMappingField,
  ]);

  useEffect(() => {
    setSelectedFund(undefined);
    setSelectedGlAccount(undefined);
    setSelectedMemoEntity(undefined);
    setIsSaveDisabled(true);
  }, [selectedBulkMappingField]);

  useEffect(() => {
    if (
      selectedBulkMappingField !== 'GL Account' ||
      uniqueFundOptions.length === 1
    ) {
      setShowFundSelector(false);
    } else if (uniqueFundOptions.length > 1) {
      setShowFundSelector(true);
    }
  }, [selectedBulkMappingField]);

  useEffect(() => {
    setSelectedGlAccount(undefined);
    setSelectedMemoEntity(undefined);
  }, [selectedBulkMappingField]);

  useEffect(() => {
    if (selectedFund?.id) {
      getFormattedGlAccounts(selectedFund.id);
    }
    setSelectedGlAccount(undefined);
    setSelectedMemoEntity(undefined);
  }, [selectedFund, selectedBulkMappingField]);

  const memoTagFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: nameIdTypePair) => `${option.name} ${option.type}`,
  });

  const handleUnselectButton = () => {
    setSelectionModel(editableTransactionsData.map((t) => t.id));
  };

  const handleIgnoreMemoRequirement = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIgnoreMemoEntityRequired(event.target.checked);
    if (event.target.checked) {
      setSelectedMemoEntity(undefined);
    }
  };

  const handleCloseBulkEdit = () => {
    closeBulkEdit();
    resetBulkEdit();
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    const transactionData = selectedFund?.id
      ? editableTransactionsData.filter((t) => t.fundId === selectedFund.id)
      : editableTransactionsData.map((t) => t);

    const payload: UpdateBankTransactionsPayload[] = transactionData.map(
      (t) => {
        return {
          id: t.id,
          isPlaid: t.isPlaid,
          status: t.status,
          journalEntryLineItem: t.journalEntry.map((je) => {
            return {
              ...je,
              accountId: selectedGlAccount?.id
                ? selectedGlAccount.id
                : je.accountId,
              entityIds: selectedMemoEntity?.id
                ? [selectedMemoEntity.id]
                : je.entityIds,
            };
          }),
          ...(selectedMemoEntity?.id && {
            memoEntityId: selectedMemoEntity.id,
          }),
          ...(selectedGlAccount?.id && {
            glAccountId: selectedGlAccount.id,
          }),
        };
      }
    );

    try {
      setIsSaving(true);
      await updateBankTransactions(payload);
      await fetchBankTransactions();
      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
      informationAlert('Failed to update bank transaction', 'error');
    }
  };

  return (
    <BulkEditBox>
      <BulkEditCloseButton
        aria-label="close-bulk=edit"
        size="small"
        onClick={handleCloseBulkEdit}
      >
        <CloseRoundedIcon />
      </BulkEditCloseButton>
      <BulkEditHeader isError={!!uneditableTransactions.total}>
        {uneditableTransactions.total ? (
          <>
            <Tooltip
              id={'uneditable_transactions_selected_warning'}
              placement="bottom-start"
              title={
                <span>
                  <Typography variant="body2">
                    {uneditableTransactions.categorizedTransTotal
                      ? `${uneditableTransactions.categorizedTransTotal} transction(s) have been sent to GL`
                      : ''}
                  </Typography>
                  <Typography variant="body2">
                    {uneditableTransactions.nonGlBankAccountTotal
                      ? `${uneditableTransactions.nonGlBankAccountTotal} transction(s) from Bank Accounts not yet associated with a Ledger or GL Account`
                      : ''}
                  </Typography>
                </span>
              }
              arrow
              children={<OrangeErrorOutlineOutlinedIcon />}
            />
            <Typography>
              {`${uneditableTransactions.total}/${selectionModel.length} Transactions cannot be edited. Please unselect to continue`}
            </Typography>
            <TransactionTypeButtonGroup variant="text">
              <Button onClick={handleUnselectButton}>UNSELECT</Button>
            </TransactionTypeButtonGroup>
          </>
        ) : (
          <>
            <BlueModeEditOutlinedIcon />
            <Typography>
              <strong>Bulk Edit:</strong>
            </Typography>
            <Typography>
              {`${editableTransactionsData.length} Transactions Selected.`}
            </Typography>
          </>
        )}
      </BulkEditHeader>
      <Collapse
        in={
          (isEntityRequiredByBankAcc &&
            selectedBulkMappingField === 'GL Account') ||
          selectedGlAccount?.isEntityRequired
        }
      >
        {isEntityRequiredByBankAcc ? (
          <>
            <MessagingSubHeader>
              <OrangeErrorOutlineOutlinedIcon />
              <Typography>
                Selected transaction(s) have bank account(s) which require a
                Memo Tag.
              </Typography>
            </MessagingSubHeader>
            <MessagingSubSubHeader>
              <Typography marginLeft={'32px'}>
                <small>
                  Please select a memo tag to continue. If multiple transactions
                  are selected, select to apply this value to all transactions,
                  or just to the transactions that require a memo tag.
                </small>
              </Typography>
            </MessagingSubSubHeader>
          </>
        ) : (
          <>
            {selectedGlAccount?.isEntityRequired && (
              <MessagingSubHeader>
                <OrangeErrorOutlineOutlinedIcon />
                <Typography>
                  Memo Entity is required by the selected GL Account.
                </Typography>
              </MessagingSubHeader>
            )}
          </>
        )}
      </Collapse>
      <BulkEditInputBox>
        <BulkEditFormControl
          disabled={
            !!uneditableTransactions.total || !editableTransactionsData.length
          }
          size="small"
        >
          <InputLabel id="label_bulk_mapping_select_field">
            Select Field
          </InputLabel>
          <Select
            labelId="select_bulk_mapping_label"
            id="select_bulk_mapping"
            label="Select Field"
            size="small"
            value={selectedBulkMappingField}
            onChange={(e) => setSelectedBulkMappingField(e.target.value)}
          >
            {PlaidBulkEditFieldOptions.map((field: any) => {
              return (
                <MenuItem key={field.id} value={field.name}>
                  {field.name}
                </MenuItem>
              );
            })}
          </Select>
        </BulkEditFormControl>
        <div>
          <Collapse in={showFundSelector} orientation="horizontal">
            <BulkEditFormControl size="small">
              <InputLabel id="label_bulk_mapping_select_fund">
                Select Fund
              </InputLabel>
              <Select
                labelId="select_bulk_edit_fund"
                id="select_bulk_mapping_fundId"
                label="Select Fund"
                size="small"
                value={selectedFund?.name || ''}
              >
                {uniqueFundOptions?.map((field: any) => {
                  return (
                    <MenuItem
                      key={field.id}
                      value={field.name}
                      onClick={() => setSelectedFund(field)}
                    >
                      {field.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText id={`fund_select_helper_text`}>
                {`You have selected transactions from ${uniqueFundOptions?.length} funds. Please select a fund to edit.`}
              </FormHelperText>
            </BulkEditFormControl>
          </Collapse>
        </div>
        {selectedBulkMappingField !== 'GL Account' ? (
          <BulkEditFormControl size="small">
            <Autocomplete
              id="bulk_edit_memo_tag_selection"
              size="small"
              disabled={selectedBulkMappingField !== 'Memo Entity'}
              options={memoEntityList}
              value={selectedMemoEntity || null}
              getOptionLabel={(option) => `${option.name}`}
              groupBy={(option) => option.type}
              filterOptions={memoTagFilterOptions}
              onChange={(event: React.SyntheticEvent, value: any) => {
                setSelectedMemoEntity(value);
              }}
              renderGroup={(params) => (
                <div key={params.key}>
                  <AutocompleteGroupHeader>
                    {params.group}
                  </AutocompleteGroupHeader>
                  {params.children}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  placeholder="Select Memo Tag"
                  label={
                    selectedBulkMappingField === 'Memo Entity'
                      ? 'Select Memo Tag'
                      : 'Select Value'
                  }
                />
              )}
            />
          </BulkEditFormControl>
        ) : (
          <Box display="flex" flexDirection="column">
            <BulkEditFormControl size="small">
              <Autocomplete
                id="gl_account_bulk_edit"
                size="small"
                disabled={
                  selectedBulkMappingField !== 'GL Account' ||
                  (uniqueFundOptions?.length > 1 && !selectedFund)
                }
                options={glAccountTree}
                value={selectedGlAccount || null}
                getOptionLabel={(option) => `${option.number} - ${option.name}`}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{ paddingLeft: option.depth * 16 + 16 }}
                  >
                    {option.number} - {option.name}
                  </li>
                )}
                fullWidth
                onChange={(event: React.SyntheticEvent, value: any) => {
                  setSelectedGlAccount(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    placeholder="GL Account"
                    label="GL Account"
                  />
                )}
              />
            </BulkEditFormControl>
            {Boolean(
              selectedGlAccount?.isEntityRequired || isEntityRequiredByBankAcc
            ) && (
              <BulkEditFormControlLabel
                control={
                  <Checkbox
                    checked={ignoreMemoEntityRequired}
                    onChange={handleIgnoreMemoRequirement}
                  />
                }
                label="Tag Later"
              />
            )}
          </Box>
        )}
        {selectedBulkMappingField === 'GL Account' && (
          <div>
            <Collapse
              in={
                (selectedGlAccount?.isEntityRequired ||
                  isEntityRequiredByBankAcc) &&
                !ignoreMemoEntityRequired
              }
              orientation="horizontal"
            >
              <BulkEditFormControl
                disabled={uniqueFundOptions?.length > 1 && !!selectedFund}
                size="small"
              >
                <Autocomplete
                  id="bulk_edit_memo_tag_selection"
                  size="small"
                  options={memoEntityList}
                  value={selectedMemoEntity || null}
                  getOptionLabel={(option) => `${option.name}`}
                  groupBy={(option) => option.type}
                  filterOptions={memoTagFilterOptions}
                  onChange={(event: React.SyntheticEvent, value: any) => {
                    setSelectedMemoEntity(value);
                  }}
                  renderGroup={(params) => (
                    <div key={params.key}>
                      <AutocompleteGroupHeader>
                        {params.group}
                      </AutocompleteGroupHeader>
                      {params.children}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      placeholder="Select Memo Tag"
                      label="Select Memo Tag"
                    />
                  )}
                />
              </BulkEditFormControl>
            </Collapse>
          </div>
        )}
        <BulkEditButtonBox>
          <Button
            id="btn_apply_bulk_mapping"
            variant="contained"
            onClick={handleSubmit}
            disableElevation
            disabled={isSaving || isSaveDisabled}
            endIcon={isSaving ? <CircularProgress size={20} /> : <SendIcon />}
          >
            <span>Apply</span>
          </Button>
        </BulkEditButtonBox>
      </BulkEditInputBox>
    </BulkEditBox>
  );
};
