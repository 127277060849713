import {
  Box,
  Checkbox,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React from 'react';
import { Controller, UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

import TitleBox from "../../../../components/TitleBox/TitleBox";
import { EMAIL_REGEX } from "../../../../utils/constants/constants";
import { convertToLowerCase } from '../../../../utils/helpers/capitalize';
import { Client } from "../../../../utils/types/client.type";
import { CheckboxFormControl, CheckboxLabel } from "../ClientDetails.style";
import CCEmail from "./CCEmail";
import {
  FormFieldWrapper,
  FormTextField,
  Label,
  TextLabelContainer
} from "./ClientSettingForm.style";

type Props = {
  control: Control<Client, any>;
  arkClientTags?: string[];
  checkSubdomainAvailability: (subdomain: string) => void;
  clearErrors: UseFormClearErrors<Client>;
  isSuperAdmin?: boolean;
  setValue: UseFormSetValue<Client>;
}

const ClientSettingForm = ({
  control,
  arkClientTags,
  checkSubdomainAvailability,
  clearErrors,
  isSuperAdmin,
  setValue
}: Props) => {
  return(
    <TitleBox title="General Client Setting">
      <Box>
        <Controller
          name="name"
          render={({
            field: {
              onChange, value
            },
            fieldState: {
              error
            }
          }) => {
            return (
              <FormFieldWrapper>
                <FormTextField
                  id="client_name"
                  label="Client Name"
                  variant="outlined"
                  size="small"
                  value={value || ''}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message ?? ""}
                />
              </FormFieldWrapper>
            );
          }}
          rules={{
            required: "Name is required"
          }}
          control={control}
        />
        <Controller
          name="subdomain"
          render={({
            field: {
              onChange, value
            },
            fieldState: {
              error
            }
          }) => {
            return (
              <FormFieldWrapper>
                <TextLabelContainer>
                  <FormTextField
                    id="subdomain"
                    label="Sub Domain"
                    variant="outlined"
                    size="small"
                    value={value || ''}
                    onChange={(e) => {
                      setValue('subdomain', convertToLowerCase(e.target.value as string));
                      clearErrors('subdomain');
                    }}
                    error={!!error}
                    helperText={error?.message ?? ""}
                    onBlur={() => {
                      if(value) {
                        checkSubdomainAvailability(value);
                      }
                    }}
                  />
                  <Label>.arkpes.com</Label>
                </TextLabelContainer>
              </FormFieldWrapper>
            );
          }}
          rules={{
            required: "Subdomain is required",
            pattern: {
              value: /[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?/ig,
              message: "Invalid subdomain"
            }
          }}
          control={control}
        />
        <FormFieldWrapper>
          <Controller
            name="supportEmail"
            render={({
              field: {
                onChange, value
              },
              fieldState: {
                error
              }
            }) => {
              return (
                <FormTextField
                  id="support_email"
                  label="Support Email"
                  variant="outlined"
                  size="small"
                  value={value || ''}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message ?? ""}
                />
              );
            }}
            rules={{
              required: "Support Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
            }}
            control={control}
          />
          <Controller
            name="useSupportEmail"
            render={({
              field: {
                onChange, value
              }
            }) => {
              return (
                <CheckboxFormControl fullWidth>
                  <CheckboxLabel>
                    <Checkbox
                      id="use_support_email_label"
                      checked={value || false}
                      onChange={onChange} />
                          Send from support email</CheckboxLabel>
                </CheckboxFormControl>
              );
            }}
            control={control}
          />
        </FormFieldWrapper>
        <Controller
          name="ccEmails"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CCEmail onChange={onChange} value={value || ''} />
            );
          }}
          control={control}
        />
        <Controller
          name="arkClientTag"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <FormFieldWrapper>
                <Autocomplete
                  id="client_ark_client_tag"
                  freeSolo
                  options={arkClientTags || []}
                  value={value || ''}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions<string>();
                    const filtered = filter(options, params);

                    const {
                      inputValue
                    } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);

                    if (inputValue !== '' && !isExisting) {
                      filtered.push(inputValue);
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  disabled={!isSuperAdmin}
                  handleHomeEndKeys
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  renderInput={(params) =>
                    <FormTextField
                      {...params}
                      label="Ark Client Tag"
                      size={'small'}
                      variant="outlined" />}
                />
              </FormFieldWrapper>
            );
          }}
          control={control}
        />
      </Box>
    </TitleBox>
  );
};

export default ClientSettingForm;
