import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { StatusColor } from './StatusLabel.style';

type Props = {
  color: string;
  label: string;
  id?: string;
  isUpperCase?: boolean;
};

const StatusLabel = ({
  id = 'id_lbl_status',
  color,
  label,
  isUpperCase = true,
}: Props): ReactElement => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <StatusColor color={color} />
      <Typography id={id}>
        {isUpperCase ? label.toUpperCase() : label}
      </Typography>
    </Stack>
  );
};

export default StatusLabel;
