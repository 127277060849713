import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, UseFormSetValue } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import { NameIdPair } from '../../../../utils/types/bank.type';
import {
  addBanksFormType,
  fundLedgerCoAType,
} from '../BankConnectionPanel.defaults';

export const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

type twoStepSelectType = 'FUND' | 'GL';

interface Props extends RouteProps {
  fundLedgerCoAOptions: fundLedgerCoAType[];
  index: number;
  control: any;
  watchFormValues: addBanksFormType;
  setValue: UseFormSetValue<addBanksFormType>;
  getValues: any;
  errors: any;
  trigger: any;
  isSaving: boolean;
}

export const TwoStepDropdown: React.FC<Props> = ({
  fundLedgerCoAOptions,
  index,
  control,
  watchFormValues,
  setValue,
  getValues,
  errors,
  trigger,
  isSaving,
}: Props) => {
  const [step, setStep] = useState<twoStepSelectType>('FUND');
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const [displayOption, setDisplayOption] = useState<NameIdPair | null>(null);
  const [selectedFund, setSelectedFund] = useState<fundLedgerCoAType | null>(
    null
  );
  const [selectedGL, setSelectedGL] = useState<NameIdPair | null>(null);

  useEffect(() => {
    if (watchFormValues.accounts[index].fundId === null) {
      setSelectedFund(null);
      setSelectedGL(null);
      setStep('FUND');
    }
  }, [watchFormValues]);

  useEffect(() => {
    if (selectedGL?.id) {
      setDisplayOption({
        ...selectedGL,
        name: `${selectedFund?.name}/${selectedGL.name}`,
      });
    } else {
      setDisplayOption(selectedFund);
    }
  }, [selectedFund, selectedGL]);

  const handleOnSelectClick = () => {
    if (openDropdown === false) {
      setOpenDropdown(true);
    }
    return;
  };

  useEffect(() => {
    setValue(`accounts.${index}.fundId`, selectedFund?.id || null);
  }, [selectedFund]);

  useEffect(() => {
    setValue(`accounts.${index}.ledgerId`, selectedGL?.id || null);
  }, [selectedGL]);

  const handleBackClick = () => {
    setStep('FUND');
  };

  const handleFundSelect = (fund: fundLedgerCoAType) => {
    if (fund?.ledgers?.length) {
      setStep('GL');
    } else {
      setOpenDropdown(false);
    }
    setSelectedGL(null);
    setValue(`accounts.${index}.fundId`, fund.id);
    setValue(`accounts.${index}.selected`, true);
    setSelectedFund(fund);
  };

  const hanldeGLSelect = (GL: NameIdPair) => {
    setSelectedGL(GL);
    setOpenDropdown(false);
    setValue(`accounts.${index}.selected`, true);
    trigger(`accounts.${index}`);
  };

  const handleClose = () => {
    setOpenDropdown(false);
  };

  return (
    <FormControl size="small" fullWidth onClick={handleOnSelectClick}>
      <Controller
        name={`accounts.${index}.fundId`}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputLabel id={`fund_gl_input_label_${index}`}>
              Fund/GL Name
            </InputLabel>
            <Select
              id={`fund_gl_select_${index}`}
              defaultValue={''}
              value={displayOption?.id || ''}
              label="Fund/GL Name"
              fullWidth
              disabled={isSaving}
              open={openDropdown && !isSaving}
              onOpen={() => setOpenDropdown(true)}
              onClose={() => {}}
              MenuProps={{
                onClose: handleClose,
              }}
              error={!!error}
            >
              {!openDropdown && (
                <MenuItem value={displayOption?.id}>
                  {displayOption?.name}
                </MenuItem>
              )}

              <div>
                {step === 'FUND' && (
                  <>
                    <Divider>Fund</Divider>
                    {fundLedgerCoAOptions.map(
                      (option: fundLedgerCoAType, key) => {
                        return (
                          <MenuItem
                            selected={
                              selectedFund?.id === option.id ? true : false
                            }
                            disabled={!option.ledgers.length}
                            onClick={() => handleFundSelect(option)}
                            key={key}
                            value={option.id}
                          >
                            {option.name}
                          </MenuItem>
                        );
                      }
                    )}
                  </>
                )}
                {step === 'GL' && (
                  <>
                    <ButtonBox>
                      <Button
                        id="btn_edit_file_detail_panel_cancel"
                        variant="text"
                        onClick={handleBackClick}
                        color={'primary'}
                      >
                        <ChevronLeftIcon />
                        Funds
                      </Button>
                    </ButtonBox>

                    <Divider>Ledger</Divider>

                    {selectedFund?.ledgers?.map((option, key) => {
                      return (
                        <MenuItem
                          selected={selectedGL?.id === option.id ? true : false}
                          onClick={() => hanldeGLSelect(option)}
                          key={key}
                          value={option.id}
                        >
                          {option.name}
                        </MenuItem>
                      );
                    })}
                  </>
                )}
              </div>
            </Select>
            <FormHelperText
              hidden={!error}
              error={!!error}
              id={`fund_helper_text_${index}`}
            >
              {`${error?.message}`}
            </FormHelperText>
          </>
        )}
        rules={{
          validate: (value) => {
            const isSelected = getValues(`accounts.${index}.selected`);
            const selectedFund = getValues(`accounts.${index}.fundId`);
            const selectedLedger = getValues(`accounts.${index}.ledgerId`);

            if (isSelected) {
              if (!selectedFund && !selectedLedger) {
                return 'Fund & GL are required';
              } else if (!selectedLedger) {
                return 'GL is required';
              } else if (!selectedFund) {
                return 'Fund is requred';
              }
            } else {
              return true;
            }
          },
        }}
        control={control}
      />
    </FormControl>
  );
};
