import { useCallback, useContext, useState } from 'react';

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { Distributions } from '../../../utils/types/distributions.type';
import { getDistributions } from '../../distributions.service';

export const useDistrbutionsEffect = () => {
    const [distributions, setDistributions] = useState<Distributions[]>();
    const [loading, setLoading] = useState(false);
    const { informationAlert } = useContext(AppContext);

    const fetchDistributions = useCallback(async (isCanceled?: () => boolean) => {
        try {
            setLoading(true);
            const response = await getDistributions();

            if (isCanceled?.()) return;
            setDistributions(response);
            setLoading(false);
          } catch (e) {
            informationAlert("Error in getting distributions", "error");
            setLoading(false);
          }
    }, []);

    useEffectAsync(async (isCanceled) => {
        await fetchDistributions(isCanceled);
    }, []);

    const updateDistributions = (updatedDistributions: Distributions[]) => {
        setDistributions(updatedDistributions);
    };

    return {
        distributions,
        fetchDistributions,
        loading,
        setDistributions,
        updateDistributions,
    };
};
