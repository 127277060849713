import { Box, Fab, Menu, styled } from '@mui/material';

export const ViewConfigBox = styled(Box)<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
  alignContent: 'center',
}));

export const AddWidgetsMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '200px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: theme.spacing(1.5),
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(1),
    },
    '&::before': {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}));

export const DashboardWidgetsConfigBtn = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'isInEditMode',
})<{
  isInEditMode?: boolean;
}>(({ theme, isInEditMode }) => ({
  width: '32px',
  height: '32px',
  minWidth: '32px',
  minHeight: '32px',
  marginBottom: '8px',

  backgroundColor: `${isInEditMode ? '#B8E8FAFF' : '#B8E8FA00'}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#B8E8FA',
    boxShadow: 'none',
  },
  color: '#000000',
}));

export const AddWidgetButton = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{
  isVisible?: boolean;
}>(({ theme, isVisible }) => ({
  top: '5px',
  right: '24px',
  width: '24px',
  height: '24px',
  minWidth: '24px',
  minHeight: '24px',

  backgroundColor: theme.palette.common.chipBlue,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#B8E8FA',
    boxShadow: 'none',
  },
}));
