import { FormControlLabel, Paper } from '@mui/material';
import  React, { useState } from 'react';

import IconGrip from "../../assets/images/icons/icon_grip.svg";
import ImgIcon from "../../components/ImgIcon/ImgIcon";
import ContentColumnBox from '../Boxes/ContentColumnBox/ContentColumnBox';
import { StyledDragAndDropPaperItem } from './DragAndDropList.Style';


export type DandDitem = {
    id: string,
    name: string,
    data?: any
};

type DaNstate = {
    draggedFrom: number,
    draggedTo: number,
    isDragging: boolean,
    originalOrder: DandDitem[],
    updatedOrder: DandDitem[]
};

type DragAndDropListProps = {
    items: DandDitem[],
    onChange: (item: DandDitem[]) => void,
    onItemRender?: (item: DandDitem) => JSX.Element,
    disabled?:boolean
};


export const DragAndDropList: React.FC<DragAndDropListProps> = ({ items, onChange, onItemRender, disabled=false }) => {

    const [dragAndDrop, setDragAndDrop] = useState({
        draggedFrom: -1,
        draggedTo: -1,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    } as DaNstate);


    const onDragStart = (event: any) => {
        if(disabled) return;

        const initialPosition = Number(event.currentTarget.dataset.position);
    
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: items
        });
    
        event.dataTransfer.setData("text/html", ""); // this keeps firefix happy
    };

    const onDragOver = (event: any) => {
        if(disabled) return;

        event.preventDefault();
      
        let newList = dragAndDrop.originalOrder;
      
        const draggedFrom = dragAndDrop.draggedFrom;
        const draggedTo = Number(event.currentTarget.dataset.position);
      
        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter((_item, index: number) => index !== draggedFrom);

        newList = [];
        newList = newList.concat(remainingItems.slice(0, draggedTo));
        newList.push(itemDragged);
        newList = newList.concat(remainingItems.slice(draggedTo));

        if (draggedTo !== dragAndDrop.draggedTo) {
            setDragAndDrop({
                ...dragAndDrop,
                updatedOrder: newList,
                draggedTo: draggedTo
            });
        }
    };

    const onDrop = (event: any) => {
        onChange(dragAndDrop.updatedOrder);
        
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: -1,
            draggedTo: -1,
            isDragging: false
        });
    };
      
    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: -1
        });
    };


    return (
        <ContentColumnBox>
        {
            items.map((item, idx) => {
                return (
                    <StyledDragAndDropPaperItem 
                        key={idx}
                        data-position={idx}
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        onDragLeave={onDragLeave}
                        draggable
                    >
                        <FormControlLabel
                            id={`test_${idx}`}
                            control={ <ImgIcon icon={IconGrip} /> }
                            label={ onItemRender ? onItemRender(item) : item.name }
                        />
                    </StyledDragAndDropPaperItem>
                );

            })
        }
        </ContentColumnBox>
    );
};