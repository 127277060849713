import { cloneDeep } from "lodash";

import { getPITDSummaryValues } from "../../../../../../../services/workbook.service";
import { DateTimeFormat } from "../../../../../../../utils/helpers/format.helper";
import { getQuarterStartEndDates } from "../../../../../../../utils/helpers/quarter.helper";
import { InvestorRowType, ItdColumn, ITDType, PitdSummaryValues, TransactionColumn, TransactionColumnType } from "../../../../workbook.type";
import { CellFormats, ColWidth } from "../../../SpreadsheetGrid";
import { colsAddResponse } from "../../ICellEditHandling";
import { BaseTransactionsColumnsManager } from "./BaseTransactionsColumns.Manager";

export class PitdColumnsManager extends BaseTransactionsColumnsManager {
    private _pitdTransactionColumns!: ItdColumn[];
    private _pitdSummaryValues!: PitdSummaryValues[];

    public get pitdTransactionColumns() {
        return cloneDeep(this._pitdTransactionColumns).sort((a,b)=> a.index-b.index);
    }

    public get startColumnId(): string {
        return this.pitdTransactionColumns[0].gridColId;
    }

    public get endColumnId(): string {
        return this.pitdTransactionColumns[this.pitdTransactionColumns.length-1].gridColId;
    }

    public async initPitdColumnsData(pitdTransactionColumns: ItdColumn[], transactionColumns?: TransactionColumn[], initialStartColId?: string) {
        const wb = this.workbookSheetsManager;
        const quarterStartEnd = getQuarterStartEndDates(wb.firstQuarter, wb.workbookYear);
        const thruDateAdjusted = DateTimeFormat.subtractDate(quarterStartEnd.startDate, 1);
        const pitdSummaryValues = await getPITDSummaryValues(wb.fund.id, thruDateAdjusted) as PitdSummaryValues[];

        this._pitdSummaryValues = pitdSummaryValues;

        if(this.workbookSheetsManager.isNewWorkbook) {
            const newTransactionColumns = this.buildNewTransactionColumns(transactionColumns!, this.workbookSheetsManager.firstQuarter);

            let currColId = initialStartColId!;

            this._pitdTransactionColumns = newTransactionColumns.map<ItdColumn>(transCol => {
                let pitdType: ITDType;

                switch(transCol.colType) {
                    case TransactionColumnType.BEGINING_BALANCE:
                        pitdType = ITDType.BEGINING_BALANCE_PITD_TYPE;
                        break;
                    case TransactionColumnType.STANDARD_TRANSACTION_TYPE:
                        pitdType = ITDType.STANDARD_PITD_TYPE;
                        break;
                    case TransactionColumnType.ENDING_BALANCE:
                        pitdType = ITDType.ENDING_BALANCE_PITD_TYPE;
                        break;
                    default: throw `Invalid PITD col type ${transCol.colType}`;
                }

                const newPitd = {
                    colType: pitdType,
                    transTypeId: transCol.transTypeId,
                    index: transCol.index,
                    label: transCol.label,
                    metricSign: transCol.metricSign,
                    gridColId: currColId
                };

                currColId = this.grid.getNextColId(currColId);

                return newPitd;
            });
        } else {
            this._pitdTransactionColumns = pitdTransactionColumns;
        }
    }

    public async updateSummaryValues() {
        const wb = this.workbookSheetsManager;
        const quarterStartEnd = getQuarterStartEndDates(wb.firstQuarter, wb.workbookYear);
        const thruDateAdjusted = DateTimeFormat.subtractDate(quarterStartEnd.startDate, 1);
        
        this._pitdSummaryValues = await getPITDSummaryValues(wb.fund.id, thruDateAdjusted) as PitdSummaryValues[];
    }

    public renderColumns(gridRowIds?: string[], gridColIds?: string[]): void {
        const wb = this.workbookSheetsManager;
        const pitdTransactionColumns = this.pitdTransactionColumns;
        const grid = this.grid;
        const investorRowManager = this.investorRowManager;
        const investorRows = investorRowManager.investorRows; 
        const startColId = pitdTransactionColumns[0].gridColId;
        const lastColId = pitdTransactionColumns[pitdTransactionColumns.length-1].gridColId;
        const startColCaption = grid.getColCaption(startColId);
        const lastCalcColId = grid.getPrevCol(lastColId);
        const lastCalcColCaption = grid.getColCaption(lastCalcColId);

        this.setQuarterHeaderStyle(startColId, lastColId, `PITD ${wb.workbookYear-1}`);

        grid.startUpdate();

        const colsToRender = gridColIds?.length ? pitdTransactionColumns.filter(c => !!gridColIds.find(colId => colId === c.gridColId)) : pitdTransactionColumns;

        colsToRender.forEach(col => {
            const colCaption = grid.getColCaption(col.gridColId);

            this.setColumnStyle(col.gridColId, col.label, ColWidth.currency);

            const rowsToRender = gridRowIds?.length ? investorRows.filter(r => !!gridRowIds.find(rowId => rowId === r.gridRowId)) : investorRows;

            rowsToRender.forEach(ir => {
                this.setInvestorTypeTotalRowStyling(ir.gridRowId, col.gridColId, ir.rowType);

                const rowCaption = grid.getRowCaption(ir.gridRowId);

                if(col.colType === ITDType.BEGINING_BALANCE_PITD_TYPE) {
                    grid.setCellValue(ir.gridRowId, col.gridColId, 0, CellFormats.Number_Accounting);
                    return;
                }

                if(col.colType === ITDType.ENDING_BALANCE_PITD_TYPE) {                    
                    const endBalanceFormula = `SUM(${startColCaption}${rowCaption}:${lastCalcColCaption}${rowCaption})`;

                    grid.setCellFormula(ir.gridRowId, col.gridColId, endBalanceFormula, CellFormats.Number_Accounting);
                    return;
                }

                switch(ir.rowType) {
                    case InvestorRowType.Investor: {
                        const pitdInvestor = this._pitdSummaryValues.find(investor => ir.investorId === investor.investorId);

                        if(pitdInvestor?.transactionTotalMap) {
                            let amount = pitdInvestor.transactionTotalMap[col.label];

                            if(!isNaN(amount)) {
                                amount = col.metricSign === -1 ? amount * -1 : amount;

                                grid.setCellValue(ir.gridRowId, col.gridColId, amount, CellFormats.Number_Accounting);
                            } else {
                                grid.setCellValue(ir.gridRowId, col.gridColId, '', CellFormats.Text_Unformatted);
                            }
                        } else {
                            grid.setCellValue(ir.gridRowId, col.gridColId, '', CellFormats.Text_Unformatted);
                        }
                        break;
                    }
                    case InvestorRowType.TypeTotal: 
                        const startEndRows = investorRowManager.getInvestorTypeRowCaptions(ir.investorType);

                        grid.setCellFormula(ir.gridRowId, col.gridColId, `SUM(${colCaption}${startEndRows.startRow}:${colCaption}${startEndRows.endRow})`, CellFormats.Number_Accounting);
                    break;
                    case InvestorRowType.PartnerTotal: {
                        const typeTotalRows = investorRows.filter(r => r.rowType === InvestorRowType.TypeTotal)
                            .map(r => `${colCaption}${grid.getRowCaption(r.gridRowId)}`)
                            .join(`,`);

                        grid.setCellFormula(ir.gridRowId, col.gridColId, `SUM(${typeTotalRows})`, CellFormats.Number_Accounting);
                    }
                    break;
                }
            });
        });

        grid.endUpdate();
    }

    public containsColumn(colId: string): boolean {
        return !!this._pitdTransactionColumns.find(c => c.gridColId === colId);
    }

    public onStartEdit(rowID: string, colId: string): boolean {
        return false;
    }

    public onEndEdit(rowID: string, colId: string, save: boolean, value: string): boolean {
        return false;
    }

    public onColsAdd(cols: string[], toColId: string, right: boolean, empty: boolean): colsAddResponse {
        return { validColumnPosition: false };
    }

    public onRowAdd(parentRowId: string): boolean {
        return false;
    }

    public onCanColDelete(colId: string): boolean {
        return false;
    }

    public onCanRowDelete(rowID: string): boolean {
        return false;
    }

    public onCanPaste(startColID: string, startRowId: string, numOfCols: number, numOfRows: number): boolean {
        return false;
    }    
}