import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridAlignment } from '@mui/x-data-grid-pro';
import { isValid } from 'date-fns';
import { cloneDeep, update } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import HeaderSelectionPopover from '../../../components/DataGrid/headerSelectionPopover/HeaderSelectionPopover';
import { DandDitem } from '../../../components/DragAndDropList/DragAndDropList';
import { ActionLink } from '../../../components/Link/ActionLink/ActionLink';
import {
  ViewOption,
  ViewOptionTypes,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';
import { PageSummary } from '../../../components/Visualizations/VisualDashboard';
import { VisualDashboardType } from '../../../components/Visualizations/VisualDashboard.constants';
import { AppContext } from '../../../core/context/appContextProvider';
import RoutingPaths from '../../../core/routing/routingPaths';
import { saveColumnOrder } from '../../../services/columnOrder.service';
import { useClientEffect } from '../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import {
  CurrencyFormat,
  DateTimeFormat,
  NumberFormat,
} from '../../../utils/helpers/format.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import { ColumnOrder, ViewItemsEntity } from '../../../utils/types/columnOrder';
import { InvestmentTransaction } from '../../../utils/types/investmentTransaction.type';
import {
  DataGridColDef,
  inlineFilterTypes,
} from '../../../utils/types/listItems';
import { PortfolioCompany } from '../../../utils/types/portfolioCompany.type';
import { SoiSummaryItem } from '../../../utils/types/visualDashboard.type';
import { InvestmentTransactionDefaults } from '../InvestmentTransactions/InvestmentTransactionList.hooks';
import { CategorySelectionPopover } from './categorySelectionPopover/CategorySelectionPopover';
import {
  defaultVisualizationData,
  getDefaultCategoryFieldOrder,
  getDefaultDataFieldOrder,
} from './ScheduleOfInvestments.defaults';
import { useScheduleOfInvestmentsHelper } from './ScheduleOfInvestments.hooks.helper';
import {
  CategoryFieldDef,
  FilterMap,
  FilterOptions,
  FilterSelection,
  LevelColors,
  PortfolioCompanyCustomProperty,
  SoiBaseData,
  SoiGridData,
  SoiGridRow,
  soiPageView,
  ValueFieldDef,
} from './ScheduleOfInvestments.types';

const debounceSaveColumnOrder = () => {
  let timeoutId: NodeJS.Timeout | undefined;
  let lastParams: ColumnOrder | undefined;
  let resolveFn: (() => void) | undefined;
  let rejectFn: ((reason?: any) => void) | undefined;

  return (newColumnOrder: ColumnOrder) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<void>((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        if (lastParams) {
          try {
            await saveColumnOrder(lastParams);
            resolve();
          } catch (e) {
            reject(e);
          }
        } else {
          resolve();
        }

        lastParams = undefined;
        resolveFn = undefined;
        rejectFn = undefined;
      }, 600);

      lastParams = newColumnOrder;
      resolveFn = resolve;
      rejectFn = reject;
    });
  };
};

const saveSOIColumnOrder = debounceSaveColumnOrder();

export enum ViewTypes {
  Realized = 'Realized_SOI',
  Unrealized = 'Unrealized_SOI',
}

export const useScheduleOfInvestments = (
  fsView?: boolean,
  reportSoiView?: boolean,
  glReportView?: boolean,
  setFsSaveFieldOrder?: Dispatch<SetStateAction<any[]>>,
  setFsSaveCategoryOrder?: Dispatch<SetStateAction<any[]>>,
  setFsPackageFlag?: (fsPackageFlag: boolean) => void,
  soiFilterSelection?: FilterSelection,
  roundedReportActivated?: boolean
) => {
  const location = useLocation();
  const history = useHistory();
  const defaultDateFilter = new Date();

  const preSelectedView = location.state;
  const defaultView = (preSelectedView as string) || ViewTypes.Unrealized;
  const { fundId } = useParams<{ fundId: string }>();

  const [dateFilter, setDateFilter] = useState<Date | null | undefined>(
    defaultDateFilter
  );

  const [unrealizedSoiBaseData, setUnrealizedSoiBaseData] =
    useState<SoiBaseData[]>();
  const [realizedSoiBaseData, setRealizedSoiBaseData] =
    useState<SoiBaseData[]>();
  const [combinedSoiBaseData, setCombinedSoiBaseData] = useState<SoiBaseData[]>(
    []
  );

  const [soiGridData, setSoiGridData] = useState<SoiGridData>({
    soiGridRows: [],
    dataGridColDefs: [],
  });
  const [visualizationData, setVisualizationsData] = useState<SoiSummaryItem>(
    defaultVisualizationData
  );
  const [soiVisualizationsPageSummary, setSoiVisualizationsPageSummary] =
    useState<PageSummary[]>();

  const [rebuildSoiGridData, setRebuildSoiGridData] = useState(0);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [categoryFieldOrder, setCategoryFieldOrder] = useState<
    CategoryFieldDef[]
  >([]);
  const [dataFieldOrder, setDataFieldOrder] = useState<ValueFieldDef[]>([]);
  const [lockedPrompt, setLockedPrompt] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    portCo: [],
    fund: [],
  });
  const [dashboardFilterOptions, setDashboardFilterOptions] =
    useState<FilterOptions>({
      portCo: [],
      fund: [],
    });
  const [filterSelection, setFilterSelection] = useState<FilterSelection>({
    portCo: [],
    fund: [],
  });
  const [dashboardFilterSelection, setDashboardFilterSelection] =
    useState<FilterSelection>({
      portCo: [],
      fund: [],
    });

  const [dashboardSplitFilter, setDashboardSplitFilter] = useState<
    inlineFilterTypes[]
  >([]);

  const [filterMap, setFilterMap] = useState<FilterMap>();
  const [dashboardFilterMap, setDashboardFilterMap] = useState<FilterMap>();

  const [currSOIview, setCurrSOIview] = useState(defaultView);
  const [viewState, setViewState] = useState<ViewOptionTypes>(
    ViewOption.HYBRID
  );
  const [prevViewState, setPrevViewState] = useState<ViewOptionTypes>(
    ViewOption.HYBRID
  );
  const [triggerRebuild, setTriggerRebuild] = useState<boolean>(false);

  const [soiVisualizationPageType, setSoiVisualizationPageType] =
    useState<string>(ViewTypes.Unrealized);

  const [defaultTotalCostRealized, setDefaultTotalCostRealized] =
    useState<number>();
  const [defaultTotalCostUnrealized, setDefaultTotalCostUnrealized] =
    useState<number>();

  const { state, informationAlert } = useContext(AppContext);

  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== 'new' ? clientId : undefined);

  const {
    portfolioCompaniesResponse,
    investmentTransactionsResponse,
    unrealizedSoiSummaryResponse,
    realizedSoiSummaryResponse,

    unrealizedColumnOrder,
    unrealizedCatrgoryOrder,
    realizedColumnOrder,
    realizedCategoryOrder,

    fetchSoiSummaryData,
  } = useScheduleOfInvestmentsHelper(
    clientId,
    fundId,
    dateFilter,
    setIsLoadingList
  );

  const visualizationsEnabled = client?.visualizationTypes?.find(
    (vt) => vt.name === 'Admin Schedule Of Investments'
  );

  function handleViewStateChange(view: ViewOption) {
    setPrevViewState(viewState);
    setViewState(view);
  }

  useEffect(() => {
    let visPageType: string;

    if (viewState === ViewOption.DASHBOARD) {
      visPageType = VisualDashboardType.SoiDashboard;
    } else if (currSOIview === ViewTypes.Unrealized) {
      visPageType = VisualDashboardType.SoiUnrealized;
    } else {
      visPageType = VisualDashboardType.SoiRealized;
    }

    if (viewState !== ViewOption.DASHBOARD) {
      getSoiGridData(ViewTypes.Unrealized, unrealizedSoiBaseData);
      getSoiGridData(ViewTypes.Realized, realizedSoiBaseData);
    }

    setSoiVisualizationPageType(visPageType);

    if (viewState === ViewOption.DASHBOARD) {
      setDashboardFilterSelection({
        fund: dashboardFilterOptions.fund
          ? dashboardFilterOptions.fund.map((fund) => fund.id)
          : [],
        portCo: dashboardFilterOptions.portCo
          ? dashboardFilterOptions.portCo.map((portCo) => portCo.id)
          : [],
      });
      setFilterSelection({
        fund: filterOptions.fund
          ? filterOptions.fund.map((fund) => fund.id)
          : [],
        portCo: filterOptions.portCo
          ? filterOptions.portCo.map((portCo) => portCo.id)
          : [],
      });
      setTriggerRebuild(!triggerRebuild);
    }

    if (
      (viewState === ViewOption.HYBRID &&
        prevViewState !== ViewOption.DATA_VIEW) ||
      (viewState === ViewOption.DATA_VIEW &&
        prevViewState !== ViewOption.HYBRID)
    ) {
      setDashboardFilterSelection({
        fund: dashboardFilterOptions.fund
          ? dashboardFilterOptions.fund.map((fund) => fund.id)
          : [],
        portCo: dashboardFilterOptions.portCo
          ? dashboardFilterOptions.portCo.map((portCo) => portCo.id)
          : [],
      });
      setFilterSelection({
        fund: filterOptions.fund
          ? filterOptions.fund.map((fund) => fund.id)
          : [],
        portCo: filterOptions.portCo
          ? filterOptions.portCo.map((portCo) => portCo.id)
          : [],
      });
      setTriggerRebuild(!triggerRebuild);
    }
  }, [currSOIview, viewState, visualizationsEnabled]);

  useEffect(() => {
    if (
      unrealizedSoiBaseData &&
      realizedSoiBaseData &&
      unrealizedSoiBaseData.length &&
      realizedSoiBaseData.length
    ) {
      const filteredCombinedData: SoiBaseData[] = [
        ...unrealizedSoiBaseData,
        ...realizedSoiBaseData,
      ];

      setSoiVisualizationsPageSummary([
        {
          currencyCode: 'USD',
          items: getNormalizedVisualsData(filteredCombinedData),
        },
      ]);
    }
  }, [
    unrealizedSoiBaseData,
    realizedSoiBaseData,
    filterSelection,
    dashboardFilterSelection,
    defaultTotalCostRealized,
    defaultTotalCostUnrealized,
    viewState,
  ]);

  const initializeFilters = (
    portCoList: Map<string, string>,
    fundList: Map<string, string>,
    filterMap?: FilterMap
  ) => {
    const portCoOptions = Array.from(portCoList, ([id, name]) => ({
      id,
      name,
    }));
    const portCoSelection = portCoOptions.map((portCo: any) => portCo.id);

    if (fundId) {
      setFilterOptions({ portCo: portCoOptions });
      if (soiFilterSelection) {
        setFilterSelection({
          portCo:
            soiFilterSelection.portCo.length > 0
              ? soiFilterSelection.portCo
              : portCoSelection,
          fund: [fundId],
        });
      } else {
        setFilterSelection({
          portCo: portCoSelection,
          fund: [fundId],
        });
      }
    } else {
      const fundOptions = Array.from(fundList, ([id, name]) => ({
        id,
        name,
      }));
      const fundSelection = fundOptions.map((fund: any) => fund.id);

      setFilterOptions({ portCo: portCoOptions, fund: fundOptions });
      if (soiFilterSelection) {
        setFilterSelection({
          portCo:
            soiFilterSelection.portCo.length > 0
              ? soiFilterSelection.portCo
              : portCoSelection,
          fund:
            soiFilterSelection.fund.length > 0
              ? soiFilterSelection.fund
              : fundSelection,
        });
      } else {
        setFilterSelection({
          portCo: portCoSelection,
          fund: fundSelection,
        });
      }
      setFilterMap(filterMap);
    }
  };

  const initializeDashboardFilters = (
    portCoList: Map<string, string>,
    fundList: Map<string, string>,
    filterMap?: FilterMap
  ) => {
    const portCoOptions = Array.from(portCoList, ([id, name]) => ({
      id,
      name,
    }));
    const portCoSelection = portCoOptions.map((portCo: any) => portCo.id);

    if (fundId) {
      setDashboardFilterOptions({ portCo: portCoOptions });
      if (soiFilterSelection) {
        setDashboardFilterSelection({
          portCo:
            soiFilterSelection.portCo.length > 0
              ? soiFilterSelection.portCo
              : portCoSelection,
          fund: [fundId],
        });
      } else {
        setDashboardFilterSelection({
          portCo: portCoSelection,
          fund: [fundId],
        });
      }
    } else {
      const fundOptions = Array.from(fundList, ([id, name]) => ({
        id,
        name,
      }));
      const fundSelection = fundOptions.map((fund: any) => fund.id);

      setDashboardFilterOptions({ portCo: portCoOptions, fund: fundOptions });
      if (soiFilterSelection) {
        setDashboardFilterSelection({
          portCo:
            soiFilterSelection.portCo.length > 0
              ? soiFilterSelection.portCo
              : portCoSelection,
          fund:
            soiFilterSelection.fund.length > 0
              ? soiFilterSelection.fund
              : fundSelection,
        });
      } else {
        setDashboardFilterSelection({
          portCo: portCoSelection,
          fund: fundSelection,
        });
      }

      setDashboardSplitFilter([
        {
          inlineFilterIDField: 'id',
          inlineFilterLabelField: 'name',
          inlineFilterName: 'fund',
          inlineFilterOptions: fundOptions,
          inlineFilterSelected: fundSelection,
          inlineFilterTitle: 'Funds',
        },
        {
          inlineFilterIDField: 'id',
          inlineFilterLabelField: 'name',
          inlineFilterName: 'portCo',
          inlineFilterOptions: portCoOptions,
          inlineFilterSelected: portCoSelection,
          inlineFilterTitle: 'Portfolio Companies',
        },
      ]);
      setDashboardFilterMap(filterMap);
    }
  };

  const handleFilter = (filterName: string[], selected: string[]) => {
    let updatedSelection: FilterSelection = { fund: [], portCo: [] };

    for (let i = 0; i < filterName.length; i++) {
      updatedSelection = { ...updatedSelection, [filterName[i]]: selected[i] };
    }

    setFilterSelection(updatedSelection);
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);
    setRebuildSoiGridData(rebuildSoiGridData + 1);
  };

  const handleDashboardFilter = (filterName: string[], selected: string[]) => {
    let updatedSelection: FilterSelection = { fund: [], portCo: [] };

    for (let i = 0; i < filterName.length; i++) {
      updatedSelection = { ...updatedSelection, [filterName[i]]: selected[i] };
    }

    setDashboardFilterSelection(updatedSelection);
    setDashboardSplitFilter([
      {
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterName: 'fund',
        inlineFilterOptions: dashboardFilterOptions.fund
          ? dashboardFilterOptions.fund
          : [],
        inlineFilterSelected: updatedSelection.fund,
        inlineFilterTitle: 'Funds',
      },
      {
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterName: 'portCo',
        inlineFilterOptions: dashboardFilterOptions.portCo
          ? dashboardFilterOptions.portCo
          : [],
        inlineFilterSelected: updatedSelection.portCo,
        inlineFilterTitle: 'Portfolio Companies',
      },
    ]);
    setRebuildSoiGridData(rebuildSoiGridData + 1);
  };

  useEffect(() => {
    if (soiFilterSelection) {
      setFilterSelection(soiFilterSelection);
    }
  }, [soiFilterSelection]);

  const isCompleteAndValidDate = (date: Date | null | undefined) => {
    if (!date) return false;
    if (!isValid(date)) return false;

    const year = date.getFullYear();

    return year >= 1900 && year <= new Date().getFullYear();
  };

  const handleDateChange = (value: Date | null | undefined) => {
    if (isCompleteAndValidDate(value)) {
      setDateFilter(value);
    }
  };

  useEffectAsync(
    async (isCanceled) => {
      setIsLoadingList(true);
      await fetchSoiSummaryData(isCanceled);
      setRebuildSoiGridData((prevVal) => prevVal + 1);
      setIsLoadingList(false);
    },
    [dateFilter]
  );

  useEffect(() => {
    if (fsView || glReportView) {
      if (!reportSoiView) {
        setIsLoadingList(false);
        return;
      }
    }

    if (portfolioCompaniesResponse && investmentTransactionsResponse) {
      if (realizedSoiSummaryResponse) {
        buildSoiBaseData(
          portfolioCompaniesResponse,
          realizedSoiSummaryResponse,
          investmentTransactionsResponse.filter(
            (item: any) => item.fundId === fundId
          ),
          ViewTypes.Realized
        );
      }
      if (unrealizedSoiSummaryResponse) {
        buildSoiBaseData(
          portfolioCompaniesResponse,
          unrealizedSoiSummaryResponse,
          investmentTransactionsResponse,
          ViewTypes.Unrealized
        );
      }
    }

    const categoryFieldOrderResponse = getCategoryFieldOrder(
      currSOIview,
      portfolioCompaniesResponse
    );

    const dataFieldOrderResponse = getDataFieldOrder(currSOIview);

    setCategoryFieldOrder(categoryFieldOrderResponse);
    setDataFieldOrder(dataFieldOrderResponse);
    if (setFsSaveCategoryOrder)
      setFsSaveCategoryOrder(formatFsOrder(categoryFieldOrderResponse));
    if (setFsSaveFieldOrder)
      setFsSaveFieldOrder(formatFsOrder(dataFieldOrderResponse));

    const portCoList = new Map<string, string>();
    const fundList = new Map<string, string>();
    const filterMap: FilterMap = {
      dependencies: { fund: 'portCo', portCo: 'fund' },
      listItems: { fund: {}, portCo: {} },
    };

    const dashboardPortCoList = new Map<string, string>();
    const dashboardFundList = new Map<string, string>();
    const dashboardFilterMap: FilterMap = {
      dependencies: { fund: 'portCo', portCo: 'fund' },
      listItems: { fund: {}, portCo: {} },
    };

    let transactionSummaries;
    let dashboardTransactionSummaries;

    if (unrealizedSoiSummaryResponse && realizedSoiSummaryResponse) {
      if (currSOIview === ViewTypes.Unrealized) {
        transactionSummaries = cloneDeep(unrealizedSoiSummaryResponse);
        dashboardTransactionSummaries = [
          ...unrealizedSoiSummaryResponse,
          ...realizedSoiSummaryResponse,
        ];
      } else {
        transactionSummaries = cloneDeep(realizedSoiSummaryResponse);
        dashboardTransactionSummaries = [
          ...unrealizedSoiSummaryResponse,
          ...realizedSoiSummaryResponse,
        ];
      }
    }

    transactionSummaries?.forEach(
      (ts: {
        portfolioCompanyId: any;
        fundId: any;
        categoryData: { fund: string };
      }) => {
        const portCo = portfolioCompaniesResponse?.find(
          (pc) => ts.portfolioCompanyId === pc.id
        );

        if (!portCo) return;

        if (fundId) {
          portCoList.set(portCo.id, portCo.name);
        } else {
          const fid = ts.fundId;
          const pcid = portCo.id;

          if (!fundList.has(fid)) {
            fundList.set(fid, ts.categoryData.fund);
            filterMap.listItems.portCo[fid] = [pcid];
          } else if (!filterMap.listItems.portCo[fid].includes(pcid)) {
            filterMap.listItems.portCo[fid].push(pcid);
          }

          if (!portCoList.has(pcid)) {
            portCoList.set(pcid, portCo.name);
            filterMap.listItems.fund[pcid] = [fid];
          } else if (!filterMap.listItems.fund[pcid].includes(fid)) {
            filterMap.listItems.fund[pcid].push(fid);
          }
        }
      }
    );

    dashboardTransactionSummaries?.forEach(
      (ts: {
        portfolioCompanyId: any;
        fundId: any;
        categoryData: { fund: string };
      }) => {
        const portCo = portfolioCompaniesResponse?.find(
          (pc) => ts.portfolioCompanyId === pc.id
        );

        if (!portCo) return;

        if (fundId) {
          dashboardPortCoList.set(portCo.id, portCo.name);
        } else {
          const fid = ts.fundId;
          const pcid = portCo.id;

          if (!dashboardFundList.has(fid)) {
            dashboardFundList.set(fid, ts.categoryData.fund);
            dashboardFilterMap.listItems.portCo[fid] = [pcid];
          } else if (!dashboardFilterMap.listItems.portCo[fid].includes(pcid)) {
            dashboardFilterMap.listItems.portCo[fid].push(pcid);
          }

          if (!dashboardPortCoList.has(pcid)) {
            dashboardPortCoList.set(pcid, portCo.name);
            dashboardFilterMap.listItems.fund[pcid] = [fid];
          } else if (!dashboardFilterMap.listItems.fund[pcid].includes(fid)) {
            dashboardFilterMap.listItems.fund[pcid].push(fid);
          }
        }
      }
    );

    setDashboardFilterMap(dashboardFilterMap);

    initializeDashboardFilters(
      dashboardPortCoList,
      dashboardFundList,
      dashboardFilterMap
    );
    initializeFilters(portCoList, fundList, filterMap);

    setRebuildSoiGridData((prevVal) => prevVal + 1);

    // For some reason MUI grid wont hide columns on initial
    // display so need to force refresh which makes it work
    setTimeout(() => {
      setRebuildSoiGridData((prevVal) => prevVal + 1);
    }, 1);
  }, [
    dateFilter,
    currSOIview,
    roundedReportActivated,
    portfolioCompaniesResponse,
    unrealizedSoiSummaryResponse,
    realizedSoiSummaryResponse,
    investmentTransactionsResponse,
    triggerRebuild,
  ]);

  function ensureCorrectColSortOrder(cols: CategoryFieldDef[]) {
    const orderedCols = cols.sort((a, b) => a.order - b.order);

    for (let i = 0; i < orderedCols.length; i++) {
      const col = orderedCols[i];

      col.order = i + 1;
    }
  }

  function getCategoryFieldOrder(
    soiViewName: string,
    portfolioCompanies?: PortfolioCompany[]
  ) {
    let colOrderResponse: any;

    if (
      currSOIview === ViewTypes.Unrealized &&
      unrealizedCatrgoryOrder?.viewItems?.length
    ) {
      colOrderResponse = unrealizedCatrgoryOrder;
    } else if (realizedCategoryOrder?.viewItems?.length) {
      colOrderResponse = realizedCategoryOrder;
    }

    const defaultCatOrder: CategoryFieldDef[] = [];
    const initialCategories = getDefaultCategoryFieldOrder(!fundId);
    const customPortfolioCompanyFields: CategoryFieldDef[] =
      getCustomPortfolioCompanyCategories();

    defaultCatOrder.push(...initialCategories, ...customPortfolioCompanyFields);

    if (
      !colOrderResponse ||
      !colOrderResponse.viewItems ||
      colOrderResponse.viewItems!.length === 0
    ) {
      return defaultCatOrder;
    }

    const savedCatOrder = defaultCatOrder.map((col) => {
      const savedCol = colOrderResponse.viewItems?.find(
        (colItem: { code: string }) => colItem.code === col.name
      );

      if (savedCol) {
        return {
          ...col,
          order: savedCol.order,
          visible: savedCol.visible,
        } as CategoryFieldDef;
      }
      return col;
    });

    ensureCorrectColSortOrder(savedCatOrder);

    return savedCatOrder!;

    function getCustomPortfolioCompanyCategories() {
      let order = defaultCatOrder.length + 1;
      const customPortfolioCompanyFields: CategoryFieldDef[] = [];

      portfolioCompanies?.forEach((item) => {
        item.additionalProperties !== null &&
          item.additionalProperties.forEach((property) => {
            if (property.customProperty.type !== 'MULTIPLE_CHECKBOX') {
              const defaultCatOrderContains = defaultCatOrder.some(
                (field) => field.name === property.customProperty.name
              );
              const contains = customPortfolioCompanyFields.some(
                (field) => field.name === property.customProperty.name
              );

              if (!contains && !defaultCatOrderContains) {
                customPortfolioCompanyFields.push({
                  name: property.customProperty.name,
                  headerName: property.customProperty.name,
                  visible: false,
                  order: order,
                });

                order = order + 1;
              }
            }
          });
      });

      return customPortfolioCompanyFields;
    }
  }

  async function saveCategoryFieldOrder(
    updatedCategoryFieldOrder: CategoryFieldDef[]
  ) {
    if (!updatedCategoryFieldOrder || updatedCategoryFieldOrder.length === 0)
      return;

    const viewItems = updatedCategoryFieldOrder.map((catCol) => {
      return {
        label: catCol.headerName,
        code: catCol.name,
        order: catCol.order,
        visible: catCol.visible,
      } as ViewItemsEntity;
    });

    const viewKey = `SOI_CATEGORY_${currSOIview}_VIEW`.toUpperCase();

    const newColumnOrder: ColumnOrder = {
      clientId: clientId,
      viewKey: viewKey,
      viewItems: viewItems,
    };

    if (setFsSaveCategoryOrder) {
      setFsSaveCategoryOrder(formatFsOrder(viewItems));
    }
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);

    try {
      await saveColumnOrder(newColumnOrder);
    } catch (e) {
      informationAlert('Error saving categories order.', 'error');
    }
  }

  function handleDandDVisibleChange(itemId: string) {
    const catField = categoryFieldOrder.find((field) => field.name === itemId)!;

    catField.visible = !catField.visible;
    setCategoryFieldOrder(cloneDeep(categoryFieldOrder));
    setRebuildSoiGridData(rebuildSoiGridData + 1);

    saveCategoryFieldOrder(categoryFieldOrder);
    if (setFsSaveCategoryOrder) {
      setFsSaveCategoryOrder(formatFsOrder(categoryFieldOrder));
    }
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);
  }

  function handleDandDItemsChange(items: DandDitem[]) {
    const newCategoryFieldOrder = items.map((item, index) => {
      const fieldDef = item.data as CategoryFieldDef;

      fieldDef.order = index + 1;
      return fieldDef;
    });

    setCategoryFieldOrder(cloneDeep(newCategoryFieldOrder));
    setRebuildSoiGridData(rebuildSoiGridData + 1);

    saveCategoryFieldOrder(newCategoryFieldOrder);
    if (setFsSaveCategoryOrder) {
      setFsSaveCategoryOrder(formatFsOrder(newCategoryFieldOrder));
    }
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);
  }

  function getDataFieldOrder(soiViewName: string) {
    const defaultDataFieldOrder = getDefaultDataFieldOrder(soiViewName);

    let colOrderResponse: any;

    if (
      currSOIview === ViewTypes.Unrealized &&
      unrealizedColumnOrder?.viewItems?.length
    ) {
      colOrderResponse = unrealizedColumnOrder;
    } else if (realizedColumnOrder?.viewItems?.length) {
      colOrderResponse = realizedColumnOrder;
    }

    if (
      !colOrderResponse ||
      !colOrderResponse.viewItems ||
      colOrderResponse.viewItems!.length === 0
    ) {
      return defaultDataFieldOrder;
    }

    const savedDataFieldOrder: ValueFieldDef[] = [];

    colOrderResponse.viewItems.forEach((item: any) => {
      if (item.label === 'moic') item.label = 'MOIC';
    });

    for (let i = 0; i < colOrderResponse.viewItems.length; i++) {
      const col = colOrderResponse.viewItems[i];
      const dataField = defaultDataFieldOrder.find((f) => f.name === col.code);

      if (!dataField) continue;

      savedDataFieldOrder.push({
        headerName: col.label,
        name: col.code,
        order: col.order,
        visible: col.visible,
        numberFormat: dataField.numberFormat,
      } as ValueFieldDef);
    }

    const defaultsNotInSaved = defaultDataFieldOrder.filter(
      (d) => !savedDataFieldOrder.find((s) => s.name === d.name)
    );

    defaultsNotInSaved.forEach((d) => {
      savedDataFieldOrder.push(d);
    });

    ensureCorrectColSortOrder(savedDataFieldOrder);

    return savedDataFieldOrder;
  }

  async function saveDataFieldOrder(updatedDataFieldOrder: ValueFieldDef[]) {
    if (!updatedDataFieldOrder || updatedDataFieldOrder.length === 0) return;

    const viewItems = updatedDataFieldOrder.map((catCol) => {
      return {
        label: catCol.headerName,
        code: catCol.name,
        order: catCol.order,
        visible: catCol.visible,
      } as ViewItemsEntity;
    });

    const viewKey = `SOI_FIELD_${currSOIview}_VIEW`.toUpperCase();

    const newColumnOrder: ColumnOrder = {
      clientId: clientId,
      viewKey: viewKey,
      viewItems: viewItems,
    };

    if (setFsSaveFieldOrder) setFsSaveFieldOrder(formatFsOrder(viewItems));
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);

    try {
      await saveSOIColumnOrder(newColumnOrder);
    } catch (e) {
      informationAlert('Error saving column order.', 'error');
    }
  }

  function handleFieldFilterChange(fieldName: string) {
    const dataField = dataFieldOrder.find((field) => field.name === fieldName)!;

    dataField.visible = !dataField.visible;
    setDataFieldOrder(cloneDeep(dataFieldOrder));
    setRebuildSoiGridData(rebuildSoiGridData + 1);

    saveDataFieldOrder(dataFieldOrder);
    if (setFsSaveFieldOrder) setFsSaveFieldOrder(formatFsOrder(dataFieldOrder));
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);
  }

  function handleColumnOrderChange(oldIndex: number, newIndex: number) {
    const sourceCol = dataFieldOrder.find((field) => field.order === oldIndex)!;
    const targetCol = dataFieldOrder.find((field) => field.order === newIndex)!;

    sourceCol.order = newIndex;
    targetCol.order = oldIndex;

    const newDataFieldOrder = dataFieldOrder.map((field) => {
      if (field.name === sourceCol.name) return sourceCol;
      if (field.name === targetCol.name) return targetCol;
      return field;
    });

    newDataFieldOrder.sort((a, b) => a.order - b.order);

    setDataFieldOrder(cloneDeep(newDataFieldOrder));
    saveDataFieldOrder(newDataFieldOrder);

    if (setFsSaveFieldOrder) setFsSaveFieldOrder(formatFsOrder(dataFieldOrder));
    if (fsView && setFsPackageFlag) setFsPackageFlag(true);
  }

  function handleSoiViewChange(newView: ViewTypes) {
    setCurrSOIview(newView);
  }

  function buildSoiBaseData(
    portfolioCompanies: PortfolioCompany[],
    transSummary: any[],
    investmentTransactions: InvestmentTransaction[],
    soiView: string
  ) {
    const tmpCatRows: SoiBaseData[] = [];

    const sortedTransactions = investmentTransactions.sort((a, b) => {
      const dateA = a.date ? Date.parse(a.date as string) : 0;
      const dateB = b.date ? Date.parse(b.date as string) : 0;

      return dateA - dateB;
    });

    transSummary.forEach((ts) => {
      const portCo = portfolioCompanies.find(
        (pc) => ts.portfolioCompanyId === pc.id
      );

      if (!portCo) return;

      let initialTransactionDate: Date;
      let latestValuationDate: Date | undefined = undefined;

      const matchInitialTransDate = sortedTransactions.find(
        (it) =>
          ts.portfolioCompanyId === it.portfolioCompany?.id &&
          ts.fundId === it.fund?.id &&
          ts.categoryData.securityType === it.securityType &&
          (ts.categoryData.security === 'null'
            ? null
            : ts.categoryData.security) === it.investmentSecurity
      );

      for (let i = sortedTransactions.length - 1; i >= 0; i--) {
        const it = sortedTransactions[i];

        if (it.investmentTransactionType !== 'VALUATION') continue;

        if (
          ts.portfolioCompanyId === it.portfolioCompany?.id &&
          ts.fundId === it.fund?.id &&
          ts.categoryData.securityType === it.securityType &&
          (ts.categoryData.security === 'null'
            ? null
            : ts.categoryData.security) ===
            it.fromTransaction.investmentSecurity
        ) {
          latestValuationDate = it.date as Date;
          break;
        }
      }

      const fromTransaction = matchInitialTransDate?.fromTransaction;

      if (
        fromTransaction &&
        fromTransaction.date < matchInitialTransDate?.date! &&
        fromTransaction.investmentTransactionType === 'PURCHASE'
      ) {
        initialTransactionDate = fromTransaction.date;
      } else {
        initialTransactionDate = matchInitialTransDate?.date as Date;
      }

      const transactionData = ts.scheduleOfInvestmentsWitchMetricData;
      const categoryData = ts.categoryData;

      const portfolioCompanyCustomParameters: PortfolioCompanyCustomProperty[] =
        extractCustomPortfolioProperties(portCo);

      const catRow: SoiBaseData = {
        fundId: ts.fundId,
        portfolioCompanyId: portCo.id,
        currencyCode: ts.currencyCode,
        soiPageView: soiView,
        categoryProps: {
          companyName: portCo.name,
          industrySector:
            portCo.industrySector && portCo.industrySector !== 'null'
              ? portCo.industrySector
              : '(Industry/Sector not set)',
          country:
            portCo.address.country && portCo.address.country !== 'null'
              ? portCo.address.country
              : '(Country not set)',
          holdingStatus: portCo.holdingStatus,
          isPublic: portCo.isPublic ? 'Public' : 'Private',
          portfolioCustomProperties: portfolioCompanyCustomParameters,

          fund: categoryData.fund,
          security:
            categoryData.security && categoryData.security !== 'null'
              ? categoryData.security
              : '(Security not set)',
          securityType:
            categoryData.securityType && categoryData.securityType !== 'null'
              ? categoryData.securityType
              : '(Security Type not set)',
        },
        dataProps: {
          shares: transactionData.shares,
          cost: transactionData.cost,
          fairMarketValue: transactionData.fairMarketValue,
          unrealizedGainLoss: transactionData.unrealizedGainLoss,
          realizedValue: transactionData.realizedValue,
          realizedGainLoss: transactionData.realizedGainLoss,
          totalGainLoss: transactionData.totalGainLoss,
          costPerQuantity: 0,
          valuePerQuantity: 0,
          latestValuationDate: latestValuationDate || undefined,
          initialInvestmentDate: initialTransactionDate,
          moic:
            currSOIview === ViewTypes.Realized
              ? transactionData.realizedValue / transactionData.cost
              : transactionData.fairMarketValue / transactionData.cost,
        },
      };

      tmpCatRows.push(catRow);

      function extractCustomPortfolioProperties(portCo: PortfolioCompany) {
        const portfolioCompanyCustomParameters: PortfolioCompanyCustomProperty[] =
          [];

        portCo.additionalProperties !== null &&
          portCo.additionalProperties.forEach((property) => {
            const noValue = '(Additional property not set)';
            let value =
              property.values && property.values[0]
                ? property.values[0]
                : noValue;

            if (property.customProperty.type === 'SINGLE_CHECKBOX') {
              value =
                property && property?.customProperty.name
                  ? property?.customProperty.name
                  : noValue;
            }

            if (property.customProperty.type === 'DATE_PICKER') {
              value =
                property?.values && property?.values[0]
                  ? property?.values[0].split('T')[0]
                  : noValue;
            }

            portfolioCompanyCustomParameters.push({
              name: property.customProperty.name,
              value: value,
            });
          });

        return portfolioCompanyCustomParameters;
      }
    });

    if (soiView === ViewTypes.Unrealized) {
      setUnrealizedSoiBaseData(tmpCatRows);
    } else {
      setRealizedSoiBaseData(tmpCatRows);
    }
  }

  const unrealizedSoiGridData = useMemo<{
    soiGridRows: SoiGridRow[];
    dataGridColDefs: DataGridColDef[];
  }>(
    () => getSoiGridData(ViewTypes.Unrealized, unrealizedSoiBaseData),
    [currSOIview, unrealizedSoiBaseData, rebuildSoiGridData]
  );

  const realizedSoiGridData = useMemo<{
    soiGridRows: SoiGridRow[];
    dataGridColDefs: DataGridColDef[];
  }>(
    () => getSoiGridData(ViewTypes.Realized, realizedSoiBaseData),
    [currSOIview, realizedSoiBaseData, rebuildSoiGridData]
  );

  useEffect(() => {
    currSOIview === ViewTypes.Unrealized
      ? setSoiGridData({ ...unrealizedSoiGridData })
      : setSoiGridData({ ...realizedSoiGridData });
  }, [currSOIview, unrealizedSoiGridData, realizedSoiGridData]);

  function getSoiGridData(
    viewType: string,
    soiBaseData: SoiBaseData[] | undefined
  ) {
    if (!soiBaseData) return { soiGridRows: [], dataGridColDefs: [] };

    const gridRows: SoiGridRow[] = [];
    let rowId = 0;

    const footerRow: SoiGridRow = {
      id: (rowId++).toString(),
      portfolioCompanyId: '',
      categorySource: '',
      categoryName: 'Investment Totals',
      parentCategoryPath: '',
      level: 1,
      currencyCode: soiBaseData.find((x) => x.currencyCode)
        ?.currencyCode as string,
    };

    soiBaseData?.forEach((baseDataRow) => {
      if (viewState === ViewOption.DASHBOARD) {
        if (
          !dashboardFilterSelection.portCo.includes(
            baseDataRow.portfolioCompanyId
          )
        )
          return;
        if (
          !fundId &&
          !dashboardFilterSelection.fund?.includes(baseDataRow.fundId)
        )
          return;
      } else {
        if (!filterSelection.portCo.includes(baseDataRow.portfolioCompanyId))
          return;
        if (!fundId && !filterSelection.fund?.includes(baseDataRow.fundId))
          return;
      }
      let currDataCategoryPath = '';

      // for each soiBaseData, go though each user specified category and find its place
      categoryFieldOrder
        .filter((cfo) => cfo.visible)
        .sort((a, b) => a.order - b.order)
        .forEach((cfo, index) => {
          const portfolioCustomPropertyValue =
            baseDataRow.categoryProps.portfolioCustomProperties.find(
              (item) => item.name === cfo.name
            );

          const portfolioCustomProperty =
            portfolioCustomPropertyValue && portfolioCustomPropertyValue.value
              ? portfolioCustomPropertyValue.value
              : '(Additional property not set)';

          // @ts-ignore: expression error
          const currDataRowCat = baseDataRow.categoryProps[cfo.name]
            ? // @ts-ignore: expression error
              baseDataRow.categoryProps[cfo.name]
            : portfolioCustomProperty;

          const currLevel = index + 1;
          const parentDataCatPath = currDataCategoryPath;

          currDataCategoryPath = `${currDataCategoryPath}/${currDataRowCat}`;
          let gridRowToUpdate: SoiGridRow | undefined = undefined;
          let rowInsertPos = -1;

          rowInsertPos = gridRows.findIndex(
            (r) =>
              parentDataCatPath === r.parentCategoryPath &&
              currDataRowCat <= r.categoryName
          );
          if (rowInsertPos === -1) {
            // if we make it here then we need to find position of end of parent.
            // This will also handle parents that are currently empty

            const parentCatPos = gridRows.findIndex(
              (r) =>
                parentDataCatPath ===
                `${r.parentCategoryPath}/${r.categoryName}`
            );

            for (let r = parentCatPos + 1; ; r++) {
              const tmpRow = gridRows[r];

              if (!tmpRow || tmpRow.level < currLevel) {
                rowInsertPos = r;
                break;
              }
            }
          } else {
            // if we make it here then we know rowInsertPos is either exaction position or place we need to insert
            const tmpRow = gridRows[rowInsertPos];

            if (tmpRow.categoryName === currDataRowCat) {
              gridRowToUpdate = tmpRow; // we found exact position so we'll just update gridRowToUpdate data values
            }
          }

          if (!gridRowToUpdate) {
            // create and insert new GridRow

            const newGridRow: SoiGridRow = {
              id: (rowId++).toString(),
              portfolioCompanyId: baseDataRow.portfolioCompanyId,
              categorySource: cfo.name,
              categoryName: currDataRowCat,
              parentCategoryPath: parentDataCatPath,
              level: currLevel,
              currencyCode: baseDataRow.currencyCode,
              initialInvestmentDate:
                baseDataRow.dataProps.initialInvestmentDate,
            };

            gridRows.splice(rowInsertPos, 0, newGridRow);

            gridRowToUpdate = newGridRow;
          }

          const sortedDataFieldOrder = getDataFieldOrder(viewType).sort(
            (a, b) => a.order - b.order
          );

          sortedDataFieldOrder.forEach((dataField) => {
            if (
              dataField.name === 'initialInvestmentDate' ||
              dataField.name === 'latestValuationDate'
            ) {
              gridRowToUpdate![dataField.name] =
                baseDataRow.dataProps[dataField.name];
            } else {
              let valueToAdd = 0;

              try {
                // @ts-ignore: expression error
                valueToAdd = baseDataRow.dataProps[dataField.name];
                if (isNaN(valueToAdd)) {
                  valueToAdd = 0;
                }
              } catch {
                valueToAdd = 0;
              }

              gridRowToUpdate![dataField.name] =
                (gridRowToUpdate![dataField.name] ?? 0) + valueToAdd;

              if (currLevel === 1) {
                footerRow[dataField.name] =
                  (footerRow[dataField.name] ?? 0) + valueToAdd;
              }
            }
          });
        });
    });

    gridRows.push(footerRow);
    if (viewType === ViewTypes.Unrealized) {
      setVisualizationsData((prev) => {
        return {
          ...prev,
          totalCostUnrealized: footerRow.cost,
          unrealizedGainLoss: footerRow.unrealizedGainLoss,
          fairMarketValueUnrealized: footerRow.fairMarketValue,
          portCoCount:
            viewState === ViewOption.DASHBOARD
              ? dashboardFilterSelection.portCo.length
              : filterSelection.portCo.length,
        };
      });
    } else {
      setVisualizationsData((prev) => {
        return {
          ...prev,
          totalCostRealized: footerRow.cost,
          realizedValue: footerRow.realizedValue,
        };
      });
    }

    const visibleLength = categoryFieldOrder.filter((c) => c.visible).length;

    // summary calculations should be done here
    gridRows.forEach((r) => {
      if (r.level === visibleLength && r.shares) {
        r.costPerQuantity = r.cost / r.shares;
        r.valuePerQuantity = r.fairMarketValue / r.shares;
      } else {
        r.costPerQuantity = undefined;
        r.valuePerQuantity = undefined;
      }

      if (r.level !== visibleLength) {
        r.initialInvestmentDate = undefined;
        r.latestValuationDate = undefined;
      } else {
        r.initialInvestmentDate = DateTimeFormat.shortDate(
          new Date(r.initialInvestmentDate as string)
        );
        r.latestValuationDate = r.latestValuationDate
          ? DateTimeFormat.shortDate(new Date(r.latestValuationDate as string))
          : undefined;
      }
    });

    const tempColHeaders = buildColumnHeaders();

    return { soiGridRows: gridRows, dataGridColDefs: tempColHeaders };
  }

  function getSoiVisualizationData(
    view: string,
    soiBaseData: SoiBaseData[] | undefined
  ) {
    if (!soiBaseData) return { soiGridRows: [], dataGridColDefs: [] };

    const gridRows: SoiGridRow[] = [];
    let rowId = 0;

    const footerRow: SoiGridRow = {
      id: (rowId++).toString(),
      portfolioCompanyId: '',
      categorySource: '',
      categoryName: 'Investment Totals',
      parentCategoryPath: '',
      level: 1,
      currencyCode: soiBaseData.find((x) => x.currencyCode)
        ?.currencyCode as string,
    };

    soiBaseData?.forEach((baseDataRow) => {
      if (
        !dashboardFilterSelection.portCo.includes(
          baseDataRow.portfolioCompanyId
        )
      )
        return;
      if (
        !fundId &&
        !dashboardFilterSelection.fund?.includes(baseDataRow.fundId)
      )
        return;

      let currDataCategoryPath = '';

      // for each soiBaseData, go though each user specified category and find its place
      categoryFieldOrder
        .filter((cfo) => cfo.visible)
        .sort((a, b) => a.order - b.order)
        .forEach((cfo, index) => {
          const portfolioCustomPropertyValue =
            baseDataRow.categoryProps.portfolioCustomProperties.find(
              (item) => item.name === cfo.name
            );

          const portfolioCustomProperty =
            portfolioCustomPropertyValue && portfolioCustomPropertyValue.value
              ? portfolioCustomPropertyValue.value
              : '(Additional property not set)';

          // @ts-ignore: expression error
          const currDataRowCat = baseDataRow.categoryProps[cfo.name]
            ? // @ts-ignore: expression error
              baseDataRow.categoryProps[cfo.name]
            : portfolioCustomProperty;

          const currLevel = index + 1;
          const parentDataCatPath = currDataCategoryPath;

          currDataCategoryPath = `${currDataCategoryPath}/${currDataRowCat}`;
          let gridRowToUpdate: SoiGridRow | undefined = undefined;
          let rowInsertPos = -1;

          rowInsertPos = gridRows.findIndex(
            (r) =>
              parentDataCatPath === r.parentCategoryPath &&
              currDataRowCat <= r.categoryName
          );
          if (rowInsertPos === -1) {
            // if we make it here then we need to find position of end of parent.
            // This will also handle parents that are currently empty

            const parentCatPos = gridRows.findIndex(
              (r) =>
                parentDataCatPath ===
                `${r.parentCategoryPath}/${r.categoryName}`
            );

            for (let r = parentCatPos + 1; ; r++) {
              const tmpRow = gridRows[r];

              if (!tmpRow || tmpRow.level < currLevel) {
                rowInsertPos = r;
                break;
              }
            }
          } else {
            // if we make it here then we know rowInsertPos is either exaction position or place we need to insert
            const tmpRow = gridRows[rowInsertPos];

            if (tmpRow.categoryName === currDataRowCat) {
              gridRowToUpdate = tmpRow; // we found exact position so we'll just update gridRowToUpdate data values
            }
          }

          if (!gridRowToUpdate) {
            // create and insert new GridRow

            const newGridRow: SoiGridRow = {
              id: (rowId++).toString(),
              portfolioCompanyId: baseDataRow.portfolioCompanyId,
              categorySource: cfo.name,
              categoryName: currDataRowCat,
              parentCategoryPath: parentDataCatPath,
              level: currLevel,
              currencyCode: baseDataRow.currencyCode,
              initialInvestmentDate:
                baseDataRow.dataProps.initialInvestmentDate,
            };

            gridRows.splice(rowInsertPos, 0, newGridRow);

            gridRowToUpdate = newGridRow;
          }

          const sortedDataFieldOrder = getDataFieldOrder(view).sort(
            (a, b) => a.order - b.order
          );

          sortedDataFieldOrder.forEach((dataField) => {
            if (
              dataField.name === 'initialInvestmentDate' ||
              dataField.name === 'latestValuationDate'
            ) {
              gridRowToUpdate![dataField.name] =
                baseDataRow.dataProps[dataField.name];
            } else {
              let valueToAdd = 0;

              try {
                // @ts-ignore: expression error
                valueToAdd = baseDataRow.dataProps[dataField.name];
                if (isNaN(valueToAdd)) {
                  valueToAdd = 0;
                }
              } catch {
                valueToAdd = 0;
              }

              gridRowToUpdate![dataField.name] =
                (gridRowToUpdate![dataField.name] ?? 0) + valueToAdd;

              if (currLevel === 1) {
                footerRow[dataField.name] =
                  (footerRow[dataField.name] ?? 0) + valueToAdd;
              }
            }
          });
        });
    });

    gridRows.push(footerRow);
    if (view === ViewTypes.Unrealized) {
      if (!defaultTotalCostUnrealized) {
        setDefaultTotalCostUnrealized(footerRow.cost);
      }
      return {
        totalCostUnrealized: footerRow.cost,
        unrealizedGainLoss: footerRow.unrealizedGainLoss,
        fairMarketValueUnrealized: footerRow.fairMarketValue,
        portCoCount:
          viewState === ViewOption.DASHBOARD
            ? dashboardFilterSelection.portCo.length
            : filterSelection.portCo.length,
      };
    } else {
      if (!defaultTotalCostRealized) {
        setDefaultTotalCostRealized(footerRow.cost);
      }
      return {
        totalCostRealized: footerRow.cost,
        realizedValue: footerRow.realizedValue,
      };
    }
  }

  function buildColumnHeaders() {
    const tempColHeaders: DataGridColDef[] = [];

    dataFieldOrder.forEach((dataField) => {
      tempColHeaders.push({
        index: dataField.order,
        field: dataField.name,
        headerName: dataField.headerName,
        headerAlign: 'center' as GridAlignment,
        editable: false,
        disableReorder: false,
        minWidth: 50,
        sortable: false,
        resizable: true,
        align: 'right' as GridAlignment,
        flex: 150,
        hide: !dataField.visible,
        renderCell: (params: GridRenderCellParams) => {
          const dataField = dataFieldOrder.find(
            (f) => f.name === params.colDef.field
          )!;

          let value = params.value;
          let numberFormat = value ? dataField.numberFormat : ''; // if there is no value then display empty string
          let valueFormated: string;

          if (roundedReportActivated && numberFormat === 'Currency.2') {
            numberFormat = 'Currency.0';
          }

          switch (numberFormat) {
            case 'Currency.0':
              valueFormated = CurrencyFormat(params.row.currencyCode, 0).format(
                value
              );
              break;
            case 'Currency.2':
              valueFormated = CurrencyFormat(params.row.currencyCode, 2).format(
                value
              );
              break;
            case 'Integer-use_commas':
              valueFormated = NumberFormat(0, true).format(value);
              break;
            case 'Decimal-use-comas.2':
              if (String(value).includes('.')) {
                valueFormated = NumberFormat(2, true).format(value);
              } else {
                valueFormated = NumberFormat(0, true).format(value);
              }
              break;
            case 'String':
              valueFormated = value;
              break;
            default:
              valueFormated = value;
          }

          if (params.field === 'moic') {
            value =
              currSOIview === ViewTypes.Realized
                ? params.row.realizedValue / params.row.cost
                : params.row.fairMarketValue / params.row.cost;
            if (value) {
              valueFormated = value === Infinity ? '' : value.toFixed(2) + 'x';
            } else {
              valueFormated = params.row.cost === 0 ? '' : '0.00x';
            }
          }

          return (
            <Typography
              align={'right'}
              fontWeight={params.row.level === 1 ? 'bold' : 'normal'}
              fontSize={params.row.level === 1 ? '1.15rem' : 'normal'}
            >
              {valueFormated}
            </Typography>
          );
        },
      });
    });

    insertBaseDefCols(tempColHeaders);

    return tempColHeaders;
  }

  function insertBaseDefCols(catColDefs: DataGridColDef[]) {
    const dAndDitems = categoryFieldOrder.map((colField) => {
      return {
        id: colField.name,
        name: colField.headerName,
        data: colField,
      } as DandDitem;
    });

    const categoryColDef: DataGridColDef = {
      index: 0,
      field: '_categoryName',
      editable: false,
      disableReorder: true,
      headerName: 'Categories',
      width: 400,
      minWidth: 300,
      sortable: false,
      resizable: true,
      inlineFilter: true,
      inlineFilterName: 'Split filter for Fund & PortCo',
      inlineSplitFilters: [
        {
          inlineFilterName: 'portCo',
          inlineFilterIDField: 'id',
          inlineFilterLabelField: 'name',
          inlineFilterOptions: filterOptions.portCo || [],
          inlineFilterSelected: filterSelection.portCo || [],
          inlineFilterTitle: 'Portfolio Companies',
        },
      ],
      renderHeader: (_params) => {
        return (
          <CategorySelectionPopover
            categoryColDef={categoryColDef}
            handleFilter={handleFilter}
            filterMap={filterMap}
            dAndDitems={dAndDitems}
            onChange={handleDandDItemsChange}
            onVisibleChange={handleDandDVisibleChange}
          />
        );
      },
      renderCell: renderCategoryCell,
    };

    if (!fundId) {
      categoryColDef.inlineSplitFilters?.unshift({
        inlineFilterName: 'fund',
        inlineFilterIDField: 'id',
        inlineFilterLabelField: 'name',
        inlineFilterOptions: filterOptions.fund || [],
        inlineFilterSelected: filterSelection.fund || [],
        inlineFilterTitle: 'Funds',
      });
    }

    function renderCategoryCell(params: GridRenderCellParams) {
      const row: SoiGridRow = params.row;
      const levelColor = LevelColors[row.level - 1];

      const typographyElement = (
        <Typography
          align="left"
          fontWeight={row.level === 1 ? 'bold' : 'normal'}
          color={levelColor}
          marginLeft={`${row.level * 25}px`}
        >
          {row.categoryName}
        </Typography>
      );

      return (
        <>
          {' '}
          {row.categorySource === 'companyName' ? (
            <ActionLink
              id={`link_investment_transaction_${row.id}`}
              onClick={() => {
                history.push(`${RoutingPaths.InvestmentTransactions}`, {
                  filters: { portfolioCompanies: [row.categoryName] },
                } as InvestmentTransactionDefaults);
              }}
            >
              {typographyElement}
            </ActionLink>
          ) : (
            typographyElement
          )}{' '}
        </>
      );
    }

    catColDefs.splice(0, 0, categoryColDef);

    const fieldFilterColDef: DataGridColDef = {
      index: catColDefs.length,
      field: '_fieldFilterColDef',
      editable: false,
      disableReorder: true,
      headerName: '',
      width: 50,
      minWidth: 50,
      sortable: false,
      resizable: false,
      renderHeader: (_params) => {
        return (
          <HeaderSelectionPopover
            id="data_grid_column_selector"
            headerFields={catColDefs.filter(
              (c) =>
                c.field !== '_categoryName' && c.field !== '_fieldFilterColDef'
            )}
            handleUpdateHeader={handleFieldFilterChange}
          />
        );
      },
      renderCell: (_params: GridRenderCellParams) => {
        return <></>;
      },
    };

    catColDefs.push(fieldFilterColDef);
  }

  const showLockedDialog = () => {
    setLockedPrompt(true);
  };

  function formatFsOrder(viewItems: any) {
    return viewItems.map((item: any) => {
      const newItem: any = {};

      for (const key in item) {
        if (key === 'headerName') {
          newItem['label'] = item[key];
        } else if (key === 'name') {
          newItem['code'] = item[key];
        } else {
          newItem[key] = item[key];
        }
      }
      return newItem;
    });
  }

  const getNormalizedVisualsData = (soiBaseData: SoiBaseData[]) => {
    const unrealizedSummaryData = getSoiVisualizationData(
      ViewTypes.Unrealized,
      unrealizedSoiBaseData
    );
    const realizedSummaryData = getSoiVisualizationData(
      ViewTypes.Realized,
      realizedSoiBaseData
    );

    const activeFilter =
      filterOptions.portCo &&
      filterOptions.fund &&
      filterSelection.portCo.length === filterOptions.portCo.length &&
      filterSelection.fund.length === filterOptions.fund.length
        ? false
        : true;

    const dashboardVisualizationsData: SoiSummaryItem = {
      ...defaultVisualizationData,
      ...unrealizedSummaryData,
      ...realizedSummaryData,
      totalInvestedCapital:
        (realizedSummaryData.totalCostRealized
          ? realizedSummaryData.totalCostRealized
          : 0) +
        (unrealizedSummaryData.totalCostUnrealized
          ? unrealizedSummaryData.totalCostUnrealized
          : 0),
    };

    const updatedVisualizationData = {
      ...visualizationData,
      totalInvestedCapital: activeFilter
        ? (visualizationData.totalCostRealized
            ? visualizationData.totalCostRealized
            : 0) +
          (visualizationData.totalCostUnrealized
            ? visualizationData.totalCostUnrealized
            : 0)
        : (defaultTotalCostRealized ? defaultTotalCostRealized : 0) +
          (defaultTotalCostUnrealized ? defaultTotalCostUnrealized : 0),
    };

    const normalizedData: SoiSummaryItem[] =
      viewState === ViewOption.DASHBOARD
        ? soiBaseData
            .filter(
              (soiDataItem) =>
                (soiDataItem.soiPageView === currSOIview ||
                  viewState === ViewOption.DASHBOARD) &&
                dashboardFilterSelection.portCo.includes(
                  soiDataItem.portfolioCompanyId
                ) &&
                dashboardFilterSelection.fund.includes(soiDataItem.fundId)
            )
            .map((soiBaseDataItem) => {
              return {
                ...dashboardVisualizationsData,
                ...soiBaseDataItem,
              };
            })
        : soiBaseData
            .filter(
              (soiDataItem) =>
                soiDataItem.soiPageView === currSOIview &&
                filterSelection.portCo.includes(
                  soiDataItem.portfolioCompanyId
                ) &&
                filterSelection.fund.includes(soiDataItem.fundId)
            )
            .map((soiBaseDataItem) => {
              return {
                ...updatedVisualizationData,
                ...soiBaseDataItem,
              };
            });

    return normalizedData;
  };

  // Temp hardcoded to USD. Discovered in MVP-7828 that soiSummary endponts
  // are grouping all currencies into USD. To be addressed later.

  return {
    isLoadingList,
    categoryFieldOrder,
    currSOIview,
    setCurrSOIview,
    handleSoiViewChange,
    soiGridData,
    handleColumnOrderChange,
    dataFieldOrder,
    dateFilter,
    handleDateChange,
    setCategoryFieldOrder,
    setDataFieldOrder,
    setDateFilter,
    lockedPrompt,
    setLockedPrompt,
    showLockedDialog,
    client,
    setRebuildSoiGridData,
    saveDataFieldOrder,
    saveCategoryFieldOrder,
    filterSelection,
    viewState,
    setViewState,
    visualizationsEnabled,
    handleDashboardFilter,
    filterMap,
    soiVisualizationsPageSummary,
    soiVisualizationPageType,
    combinedSoiBaseData,
    dashboardSplitFilter,
    dashboardFilterMap,
    handleViewStateChange,
  };
};
