import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { getColumnsNames } from "../../../services/capitalAccounts.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const useCapAccColumnsEffect = () => {
  const [columnsList, setColumnsList] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  useEffectAsync(async (isCanceled) => {
    try {
      setLoading(true);
      const statusResponse = await getColumnsNames();

      if (isCanceled()) return;

      setColumnsList(statusResponse);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting columns names", "error");
      setLoading(false);
    }
  }, []);

  return {
    columnsList,
    loading
  };
};

