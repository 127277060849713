import { Backdrop, Button, Card, Modal, styled } from "@mui/material";
import { Box } from "@mui/system";

import { PRIMARY } from "../../../styles/colors";

export const Title = styled("div")(() => ({
    fontStyle: "normal",
    fontSize: "20px",
    color: "#FFFFFF",
    lineHeight: "100%",
    paddingTop: "30px",
    textAlign: "center",
    verticalAlign: "center",
    paragraphSpacing: "10px",
    display: "flex",
    whiteSpace: "nowrap",
    "img:first-of-type": {
      paddingLeft: "20px",
    },
  }));

  export const StyledBackdrop = styled(Backdrop)`
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
`;

export const StyledHeaderBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px,
  height: 500px,
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 10px;
`;

export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  height:80%;
  width: 60%;
  transform: translate(-50%, -50%);
  background-color: #f8f8f8;
  box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const BodyBox = styled(Box)`
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: scroll;
  height: 85%;
`;


export const StyledModal = styled(Modal)`
  .MuiBackdrop-root { 
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: theme.spacing(25),
    minWidth: theme.spacing(25),
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: theme.spacing(0.8),
  }));
  
export const PanelActionBox = styled(Box)`
  display: flex;
`;

export const IconText = styled("div")`
    margin-right: 10px;
    margin-left: 10px;
    color: ${PRIMARY};
    cursor: pointer;
`;

export const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: "16px",
}));

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  height:"1vh"
}));
