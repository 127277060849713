export function getLastDayOfLastYear(dateString: Date) {
    const inputDate = new Date(dateString);

    const year = inputDate.getFullYear();

    const lastDayOfLastYear = new Date(year - 1, 11, 31, 23, 59, 59, 999); // Set time to end of day

    const formattedLastDayOfLastYear = lastDayOfLastYear;

    return formattedLastDayOfLastYear;
}