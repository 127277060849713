import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridRenderEditCellParams, GridRowModel } from '@mui/x-data-grid';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ReactElement } from 'react';

import ChipCellStack from '../../../../../components/DataGrid/ChipsCell/ChipCellStack';
import { M_DASH_UNICODE } from '../../../../../utils/constants/constants';
import { FolderContentRequestPayload } from '../../../../../utils/types/documents.type';
import { Option } from '../../../../../utils/types/filter.type';
import useDocumentsContext from '../../../hooks/useDocumentsContext.hooks';
import EditSelection from '../../editSelection/EditSelection';
import { SelectBox } from './DocumentsGridCells.styles';

interface SelectionBoxProps {
  anchorEl: HTMLDivElement | null;
  data: GridRowModel;
  handleLoading: (status: boolean) => void;
  handleClose: () => void;
  options: Array<any>;
  update: Function;
  selected: Array<string>;
  updatedValues: Function;
  field: string;
  payload: FolderContentRequestPayload;
}

const isMulti = true;

export const SelectionBox = ({
  anchorEl,
  data,
  handleLoading,
  handleClose,
  options,
  update,
  selected,
  updatedValues,
  payload,
}: SelectionBoxProps) => {
  const handleUpdate = (
    selected: string[] | string | Option,
    selectedOptions?: Option[]
  ) => {
    const params = {
      ...data,
      fundIds: selected,
    };

    const requiredParams = [
      'index',
      'folderId',
      'clientId',
      'fundIds',
      'investorIds',
      'quarter',
      'published',
      'sizeInBytes',
      'fileType',
      'name',
      'id',
      'taggedForAllInvestors',
    ];

    const requestBody: any = {};

    for (const index of requiredParams) {
      requestBody[index] =
        index === 'investorIds'
          ? params['investors'].map(({ id }: any) => id)
          : params[index];
    }

    handleLoading(true);

    if (requestBody.clientId && requestBody.folderId && requestBody.id) {
      update(data.id, requestBody, payload).catch(() => {
        handleLoading(false);
        handleClose();
      });
    }
  };

  return anchorEl ? (
    <EditSelection
      options={options}
      handleUpdate={handleUpdate}
      handleOnClose={handleClose}
      anchorEl={anchorEl}
      selected={selected}
      isMulti={isMulti}
      hideActionButtons={false}
    />
  ) : (
    <></>
  );
};

interface ChipComponentProps {
  data: GridRowModel;
  items: Array<Fund>;
}

export const ChipComponent = ({ data, items }: ChipComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selection, setSelection] = React.useState<Option[]>([]);

  const { state, updateFiles } = useDocumentsContext();

  const handleOnChipClick = (
    name?: string,
    items?: any,
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    if (state?.isPageLocked || state?.isReadOnly) {
      return;
    }
    event && setAnchorEl(event.currentTarget);
  };

  const handleUpdatedSelection = (selectedOptions: Option[]) => {
    setSelection(selectedOptions);
  };

  React.useEffect(() => {
    let selectedOptions = [];

    if (isMulti && data.funds) {
      if (Array.isArray(data.funds)) {
        selectedOptions = data.funds;
      } else {
        selectedOptions = [data.funds];
      }
    }
    setSelection(selectedOptions);
  }, [data.funds]);

  const header = {
    index: 1,
    field: 'funds',
    chipLabelField: 'name',
    chipIDField: 'id',
    chipCount: 3,
    renderChipForMultiItemsOnly: true,
    toggleVisibilityOnClick: data.isSelected,
    onChipClick: handleOnChipClick,
  };

  const renderComponent = React.useMemo(() => {
    if (data.type === 'folder' || isEmpty(selection)) {
      return <Typography variant="cells">{M_DASH_UNICODE}</Typography>;
    } else if (selection.length > 0) {
      return selection.length === 1 ? (
        <Typography variant="cells">{selection[0]['name']}</Typography>
      ) : (
        <ChipCellStack
          header={header}
          items={selection}
          row={{ name: 'funds' }}
        />
      );
    }
    return <Typography variant="cells">{M_DASH_UNICODE}</Typography>;
  }, [selection, data.isSelected]);

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    if (state?.isPageLocked || state?.isReadOnly) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleLoading = (status: boolean) => setLoading(status);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    state?.filtersData.funds.filter((fund) => fund.id !== '(none)') || [];
  const selectionIDs = selection.map((item: any) => item.id);

  const filters: any = { ...(state?.selectedFilters || {}) };

  if (
    state?.selectedFilters['funds'].length ===
    state?.filtersData['funds'].length
  ) {
    filters.funds = [];
  }
  if (
    state?.selectedFilters['investors'].length ===
    state?.filtersData['investors'].length
  ) {
    filters.investors = [];
  }
  if (
    state?.selectedFilters['quarters'].length ===
    state?.filtersData['quarters'].length
  ) {
    filters.quarters = [];
  }

  filters.searchText = state?.selectedFilters.searchText || '';

  const payload = {
    page: 1,
    pageSize: state?.pagination.pageSize || 100,
    ...filters,
    sort: state?.sort || [],
  };

  return data.isSelected ? (
    !loading ? (
      <>
        {selectionIDs.length > 1 ? (
          <Box>{renderComponent}</Box>
        ) : (
          <SelectBox onClick={handleOnClick}>
            <Box>{renderComponent}</Box>
            <span>
              <ArrowDropDown />
            </span>
          </SelectBox>
        )}
        {data.type === 'file' && (
          <SelectionBox
            anchorEl={anchorEl}
            handleLoading={handleLoading}
            update={updateFiles}
            handleClose={handleClose}
            data={data}
            options={options}
            selected={selectionIDs}
            payload={payload}
            updatedValues={handleUpdatedSelection}
            field="funds"
          />
        )}
      </>
    ) : (
      <CircularProgress />
    )
  ) : (
    <Box>{renderComponent}</Box>
  );
};

const getValue = (fieldName: string, rowValues: GridRowModel) => {
  const childProperty: string[] = fieldName.split('.');

  if (!rowValues[childProperty[0]]) return;

  switch (childProperty.length) {
    case 2:
      return rowValues[childProperty[0]][childProperty[1]];
    case 3:
      return rowValues[childProperty[0]][childProperty[1]][childProperty[2]];
    default:
      return rowValues[fieldName];
  }
};

type Fund = {
  id: string;
  name: string;
};

type Props = {
  row: any;
};

const FundCellRenderer: React.FC<Props> = ({ row }: Props): ReactElement => {
  const value: Fund[] = getValue('funds', row);
  const renderFund = React.useMemo(
    () => <ChipComponent data={row} items={value} />,
    [value, row.isSelected, row.quarter, row.investors, row.published]
  );

  return renderFund;
};

export default FundCellRenderer;
