import { styled, Typography } from "@mui/material";

import { PENDING_YELLOW_COLOR } from "../../../../../styles/colors";

export const PendingInfoDot = styled("span")(({ theme }) => ({
  height: "8px",
  width: "8px",
  borderRadius: "50%",
  backgroundColor: PENDING_YELLOW_COLOR,
  display: "inline-block",
  marginRight: "8px",
}));

export const ItalicLabel = styled((props: any) => {
  const { children, ...other } = props;

  return (
    <Typography {...other} variant="caption">
      {" "}
      {children}{" "}
    </Typography>
  );
})(({ theme }) => ({
  fontStyle: "italic",
}));

export const Bold600 = styled("span")(({ theme }) => ({
  fontWeight: "600",
}));
export const Bold700 = styled("span")(({ theme }) => ({
  fontWeight: "700",
}));

export const Text = styled("div")(({ theme }) => ({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "19px",
}));

export const SectionTitle = styled("div")(({ theme }) => ({
  marginTop: "12px",
  marginBottom: "8px",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "19px",
  fontStyle: "italic",
}));

export const SubSectionTitle = styled("div")(({ theme }) => ({
  marginBottom: "8px",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "14px",
}));

export const FundName = styled("div")(({ theme }) => ({
  marginBottom: "8px",
  fontSize: "14px",
  lineHeight: "16px",
}));

export const ListContainer = styled("div")(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "12px",
}));

export const VBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
}));
export const DiffSection = styled("div")(({ theme }) => ({
  padding: "12px",
  display: "flex",
}));

export const HBox = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const Container = styled("div")(({ theme }) => ({
  "> div": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
