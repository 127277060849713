import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useForm } from 'react-hook-form';

import { uploadFiles } from '../../../../services/documents.service';
import {
  fileExtension,
  fileNameValidator,
} from '../../../../utils/helpers/misc.helper';
import {
  DocFile,
  Error,
  FolderContentRequestPayload,
} from '../../../../utils/types/documents.type';
import { DocumentsState } from '../../types';

type Step = 'upload' | 'tag' | 'finished' | null | undefined;
type UploadType =
  | 'upload_by_filename'
  | 'upload_by_custom_name'
  | 'upload_by_uuid'
  | null;
type DocFiles = {
  uploadedFiles?: FileList;
  uploadType: UploadType;
};

const useUploadFiles = (state: DocumentsState | null, props: any) => {
  const [step, setStep] = React.useState<Step>('upload');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [currentFiles, setCurrentFiles] = React.useState<DocFile[]>([]);
  const [uploadErrors, setUploadErrors] = React.useState<Error[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    setError,
  } = useForm<DocFiles>({
    defaultValues: {
      uploadType: 'upload_by_filename',
    },
  });

  const handleStep = (phase: Step): void => {
    setStep(phase);
  };

  const resetUploadFiles = () => {
    setStep('upload');
    setLoading(false);
    setUploadProgress(0);
    setCurrentFiles([]);
    reset();
    props.closeDialog();
  };

  const handleUpload = (data: DocFiles, reload: Function): void => {
    setLoading(true);
    const errorProneFiles: Array<Error> = [];

    if (state?.currentFolder?.id && data?.uploadedFiles && data.uploadType) {
      const mapUploadType = {
        upload_by_filename: '1',
        upload_by_custom_name: '3',
        upload_by_uuid: '2',
      };

      const type = mapUploadType[data.uploadType];

      if (type === '2' || type === '3') {
        for (let i = 0; i < data.uploadedFiles.length; i++) {
          const file = data.uploadedFiles[i];

          if (!fileNameValidator(file.name)) {
            errorProneFiles.push({
              id: file.name,
              label: file.name,
              errorMsg: 'Incorrect file format',
              iconType: fileExtension(file.name),
            });
          }
        }
        if (errorProneFiles.length) {
          // setErrorHere
          setUploadErrors(errorProneFiles);
          resetUploadFiles();
          return;
        }
      }

      uploadFiles(
        state?.currentFolder?.id,
        type,
        data.uploadedFiles,
        (progress: number) => setUploadProgress(progress)
      )
        .then((res: any) => {
          if (!isEmpty(res.failedFiles)) {
            // Handle errors
            for (const i in res.failedFiles) {
              errorProneFiles.push({
                id: i,
                label: i,
                errorMsg: res.failedFiles[i],
                iconType: fileExtension(i),
              });
            }
            setUploadErrors(errorProneFiles);
            resetUploadFiles();
            return;
          }
          props.addNewFiles(res.files.map((file: DocFile) => file.id));
          if (data.uploadType === 'upload_by_filename') {
            setStep('tag');
            setCurrentFiles(res.files);
            return;
          }
          setStep('finished');
          if (state?.currentFolder?.id) {
            const payload: FolderContentRequestPayload = {
              funds: state.selectedFilters.funds,
              investors: state.selectedFilters.investors,
              quarters: state.selectedFilters.quarters,
              page: state.pagination.page,
              pageSize: state.pagination.pageSize,
              searchText: state.selectedFilters.searchText,
              sort: state.sort,
              includeArchived: state.selectedFilters.includeArchived,
            };

            reload();
          }
          resetUploadFiles();
        })
        .catch((err) => {
          if (err?.response?.data?.errors && err.response.data.errors.length) {
            const mapErrorField: Record<string, any> = {
              files: 'uploadedFiles',
              formatted: 'uploadType',
            };

            for (const resErr of err.response.data.errors) {
              setError(mapErrorField[resErr.field], {
                type: 'custom',
                message: resErr.message,
              });
            }
          }
          setLoading(false);
          return;
        });
    } else {
      setLoading(false);
    }
  };

  const clearUploadErrors = () => {
    setUploadErrors([]);
  };

  return {
    currentFiles,
    loading,
    uploadProgress,
    step,
    register,
    errors,
    control,
    uploadErrors,
    handleStep,
    handleSubmit,
    handleUpload,
    getValues,
    setValue,
    resetUploadFiles,
    reset,
    clearUploadErrors,
    setUploadErrors,
  };
};

export default useUploadFiles;
