import AddIcon from '@mui/icons-material/Add';
import {
  ButtonBase,
  Drawer,
  FormControlLabel,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { ChangeEvent, ReactElement, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import IconAddFolder from '../../assets/images/icons/icon_create_folder.svg';
import IconDeleteRed from '../../assets/images/icons/icon_delete_red.svg';
import IconDown from '../../assets/images/icons/icon_down.svg';
import IconPencil from '../../assets/images/icons/icon_pencil.svg';
import IconUp from '../../assets/images/icons/icon_up.svg';
import IconUpload from '../../assets/images/icons/icon_upload_files.svg';
import IconWarning from '../../assets/images/icons/icon_warning.svg';
import { DataGrid, PageLock, ProgressModal } from '../../components';
import ArrowPopover from '../../components/ArrowPopover/ArrowPopover';
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import Button from '../../components/Buttons/Button/Button';
import SplitButton from '../../components/Buttons/SplitButton/SplitButton';
import Filter from '../../components/DataGrid/Filter/Filter';
import { FolderTree } from '../../components/FolderTree/FolderTree';
import ImgIcon from '../../components/ImgIcon/ImgIcon';
import ListItem from '../../components/ListItem/ListItem';
import ListItemIcon from '../../components/ListItemIcon/ListItemIcon';
import ConfirmationDialog from '../../components/Modal/ConfirmationDialog';
import DeleteConfirmationDialog from '../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import NextStepDialog from '../../components/Modal/NextStepDialog/NextStepDialog';
import { AppContext } from '../../core/context/appContextProvider';
import useRole from '../../core/routing/useRole';
import { DocumentFilter } from '../../utils/types/documents.type';
import { ScopeRole } from '../../utils/types/user.type';
import AdobeViewer from './components/AdobeViewer/AdobeViewer';
import { ArchivePanel } from './components/archivePanel/ArchivePanel';
import { DocumentsBreadcrumbs } from './components/breadcrumbs';
import EditPanel from './components/editPanel/EditPanel';
import { Errors } from './components/errors/Errors';
import PermissionChips from './components/permissionsChips/PermissionChips';
import UploadFiles from './components/uploadFiles/UploadFiles';
import { DocumentsContext } from './context';
import {
  ButtonBox,
  ChipsContent,
  DataContainer,
  DataGridBox,
  EditPanelDrawer,
  HeaderRow,
  MainContainer,
  PermissionBox,
} from './Documents.styles';
import {
  useDocumentDataList,
  useDocuments,
  useDocumentsBulkActions,
  useDocumentsGridHeaders,
} from './hooks';
import { useDocumentsHelper } from './hooks/useDocumentsHelper.hooks';

const ContextMenuOptions = [
  {
    label: 'Edit',
    id: 'edit_folder',
    icon: IconPencil,
    type: 'edit',
    forceVisible: true,
  },
  {
    label: 'Move Up',
    id: 'move_up',
    icon: IconUp,
    type: 'move_up',
  },
  {
    label: 'Move Down',
    id: 'move_down',
    icon: IconDown,
    type: 'move_down',
    divider: true,
  },
  {
    label: 'Delete',
    id: 'delete_folder',
    icon: IconDeleteRed,
    type: 'delete',
    forceVisible: true,
  },
];

const AddNewOptions = [
  {
    label: 'Upload Files',
    id: 'upload_files',
    icon: IconUpload,
    type: 'upload',
  },
  {
    label: 'Create Folder',
    id: 'create_folder',
    icon: IconAddFolder,
    type: 'create',
  },
];

type Params = {
  document_id?: string;
};

function Documents(): ReactElement {
  const theme = useTheme();
  const { state: appState, informationAlert } = useContext(AppContext);
  const { state, ...actions } = useDocuments(informationAlert);
  const linkedFileId = useParams<Params>().document_id;

  const { hasRole: isClientAdmin } = useRole([ScopeRole.ARK_CLIENT_ADMIN]);

  const { hasRole: isSuperAdmin } = useRole([ScopeRole.SUPER_ADMIN]);

  const { hasRole: isFundAdmin } = useRole([ScopeRole.FUND_USER_ADMIN]);

  const { hasRole: isBasicAdmin } = useRole([ScopeRole.BASIC_ADMIN]);

  const { hasRole: isBasicUser } = useRole([ScopeRole.BASIC_USER]);

  const isEditable: boolean =
    !!isSuperAdmin || !!isClientAdmin || !!isBasicAdmin;
  const isAdmin: boolean = !!(
    isSuperAdmin ||
    isFundAdmin ||
    isClientAdmin ||
    isBasicAdmin
  );
  const clientId = state.documents?.clientId || appState.loginUser.clientId;
  const clientName = appState.loginUser.clientName || 'Default Client';

  useEffect(() => {
    if (linkedFileId) {
      viewLinkedDocument(linkedFileId);
    }
  }, [linkedFileId]);

  useEffect(() => {
    if (clientId) {
      if (isEditable) {
        actions.initialize(clientId);
      } else {
        actions
          .fetchDocuments({ isAdmin })
          .then((documents) => {
            if (documents.folders?.some((item) => item.requiresConsent)) {
              if (documents.clientId) {
                actions.fetchConsent({
                  clientId: documents.clientId,
                  consentType: 'K1',
                });
              }
            }
          })
          .catch((err) => {});
      }
    }
  }, [clientId, isEditable]);

  const {
    contextMenu,
    selectedPermissions,
    showDeleteConfirmationBox,
    lockedTooltipText,
    showFolderDialog,
    currentStep,
    selectedFiles,
    folderName,
    folderEdits,
    setFolderEdits,
    isRootView,
    addNewAnchorEl,
    showAddOptions,
    showUploadFiles,
    newFiles,
    editing,
    showEditPanel,
    showMovePanel,
    selectedFolder,
    contextMenuMoveOptions,
    error,
    showConsent,
    resetOnClose,
    onDeleteCancel,
    onDeleteConfirm,
    handleFolderNameChange,
    handleNextAction,
    handleSelectPermission,
    handleAllPermissionSelection,
    handleOnActionItemClick,
    handleAddNewFolderAction,
    handleOnCellClick,
    removeSelectedPermission,
    handleSelectionModelChange,
    handlePermissionUpdate,
    handleContextMenu,
    handleOnContextMenuClose,
    handleContextMenuOption,
    handleAddNewAction,
    handleOnAddNewActionClose,
    handleAddNewPopover,
    handleCloseUploadFiles,
    addNewFiles,
    handleFilter,
    handleSort,
    closeEditPanel,
    resetSelectedFiles,
    handleMoveFiles,
    handleFolderSelection,
    openDocument,
    clearError,
    acceptConsent,
    declineConsent,
    reloadCurrentFolder,
    onNextPage,
    confirmFileRename,
    handleFileNameChange,
    showRenameFileDialog,
    fileName,
    renameLoading,
    setShowPrompt,
    showPrompt,
    handleWarningPrompt,
    downloadableFileIds,
    showBulkFileDlPrompt,
    setShowBulkFileDlPrompt,
    handleLpBulkFileDownload,
    downloadableFolderIds,
    showFolderDlPrompt,
    setShowFolderDlPrompt,
    handleLpFolderDownload,
    currentlyDownloadingIds,
    showDeleteOverflowPrompt,
    setShowDeleteOverflowPrompt,
    handleDeleteOverflowPrompt,
    openAdobeViewer,
    setOpenAdobeViewer,
    pdfLoading,
    pdfFile,
    viewLinkedDocument,
    handleShowArchivedToggle,
    headerAndLockbarHeight,
    docHeaderRef,
    isNavBarOpen,
  } = useDocumentsHelper({
    state,
    actions,
    isEditable,
    isAdmin,
    informationAlert,
    clientName,
  });

  const {
    currentHeaders,
    activeHeaderFields,
    handleColumnOrderChange,
    handleUpdateHeader,
    handleColumnWidthChange,
  } = useDocumentsGridHeaders(state, {
    handleOnChipClick: handlePermissionUpdate,
    isEditable,
    isAdmin,
    clientId,
    currentlyDownloadingIds,
  });

  const { isLoading, dataList } = useDocumentDataList(state, {
    newFiles,
    selectedFiles,
    isEditable,
  });

  const folderCount = selectedFiles.filter(
    (item) => item.type === 'folder'
  ).length;

  const fileCount = selectedFiles.length - folderCount;
  const allowDelete =
    folderCount === 0 ? fileCount > 0 : folderCount === 1 && fileCount >= 0;

  const availableBulkActions = useDocumentsBulkActions(state, {
    allowDelete,
    folderCount,
    fileCount,
    isEditable,
    haveFolders:
      selectedFiles.filter((item) => item.type === 'folder').length > 0,
    isUser: isBasicUser,
  });

  const showFolders: boolean = state.selectedFilters.showFolders;
  const includeArchived: boolean = state.selectedFilters.includeArchived;

  return (
    <DocumentsContext.Provider
      value={{
        state,
        ...actions,
        resetOnChange: resetOnClose,
        resetSelectedFiles,
        openDocument,
      }}
    >
      <MainContainer id="documents_main_container" role="main" maxWidth={false}>
        <HeaderRow ref={docHeaderRef}>
          <DocumentsBreadcrumbs />
          {isEditable ? (
            <ButtonBox>
              {!isRootView && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeArchived}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          handleShowArchivedToggle(e.target.checked);
                        }}
                        name="archive visibility"
                        disabled={showEditPanel}
                      />
                    }
                    label={includeArchived ? 'Show Archive' : 'Hide Archive'}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showFolders}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          handleFilter(DocumentFilter.Folder, e.target.checked);
                        }}
                        name="folders visibility"
                      />
                    }
                    label={showFolders ? 'Show Folders' : 'Hide Folders'}
                  />
                </>
              )}
              {state.selectedRows.length > 0 && (
                <ButtonBase disabled={showEditPanel}>
                  <SplitButton
                    id={'btn_bulk_action_options'}
                    text="Actions"
                    options={availableBulkActions}
                    hidden={false}
                    handleOptionClick={handleOnActionItemClick}
                    ariaLabelMessage="Select bulk action option"
                    showSelectedOptionIcon={false}
                    highlightSelections={false}
                    stopClickForwarding={true}
                  />
                </ButtonBase>
              )}
              <ButtonBase disabled={showEditPanel}>
                <Button
                  id="btn_add_new_folder"
                  color="primary"
                  onClick={
                    isRootView ? handleAddNewFolderAction : handleAddNewAction
                  }
                  icon={<AddIcon />}
                  text={isRootView ? 'New Folder' : 'Add New'}
                />
              </ButtonBase>
            </ButtonBox>
          ) : (
            <ButtonBox>
              {!isRootView && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={includeArchived}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          handleShowArchivedToggle(e.target.checked);
                        }}
                        name="archive visibility"
                      />
                    }
                    label={includeArchived ? 'Show Archive' : 'Hide Archive'}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showFolders}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                          handleFilter(DocumentFilter.Folder, e.target.checked);
                        }}
                        name="folders visibility"
                      />
                    }
                    label={showFolders ? 'Show Folders' : 'Hide Folders'}
                  />
                </>
              )}
              {state.selectedRows.length > 0 && (
                <SplitButton
                  id={'btn_bulk_action_options'}
                  text="Download"
                  options={availableBulkActions}
                  hidden={false}
                  handleOptionClick={handleOnActionItemClick}
                  ariaLabelMessage="Select bulk action option"
                  showSelectedOptionIcon={false}
                  highlightSelections={false}
                />
              )}
            </ButtonBox>
          )}
        </HeaderRow>

        <DataContainer>
          <DataGridBox open={showEditPanel}>
            <DataGrid
              id="contact_info_data_grid"
              checkboxSelection={true}
              dataList={dataList}
              headerList={currentHeaders}
              activeHeaderFields={activeHeaderFields}
              noDataMessage={isLoading ? 'Fetching Data...' : undefined}
              autoHeight={false}
              minHeight={'10vh'}
              sortingMode={state?.cursor?.length > 0 ? 'server' : 'client'}
              onCellClick={handleOnCellClick}
              onColumnWidthChange={(params) => {
                handleColumnWidthChange(params.colDef);
              }}
              handleFilter={handleFilter}
              componentsProps={{
                cell: {
                  onContextMenu:
                    isEditable && !state.isPageLocked && !state.isReadOnly
                      ? handleContextMenu
                      : undefined,
                },
              }}
              onSelectionModelChange={(selectionModel) => {
                handleSelectionModelChange(selectionModel, dataList);
              }}
              selectionModel={selectedFiles.map((item) => item.id)}
              isRowSelectable={() => {
                return showEditPanel ? false : true;
              }}
              hideSelectAll={showEditPanel}
              onSortModelChange={handleSort}
              handleUpdateHeader={handleUpdateHeader}
              onColumnOrderChange={handleColumnOrderChange}
              onNextPage={onNextPage}
            />
          </DataGridBox>
          <EditPanelDrawer
            id="edit_panel_drawer"
            variant="persistent"
            anchor="right"
            open={showEditPanel}
            headerAndLockbarHeight={headerAndLockbarHeight}
          >
            <EditPanel
              title="Bulk Edit"
              open={showEditPanel}
              files={selectedFiles}
              handleClose={closeEditPanel}
              reload={reloadCurrentFolder}
            />
          </EditPanelDrawer>
        </DataContainer>
        {isEditable && (
          <Box height={isNavBarOpen ? 67 : 57}>
            <PageLock
              locked={state.isPageLocked}
              disabled={showEditPanel}
              showTooltip={Boolean(lockedTooltipText)}
              tooltipText={lockedTooltipText}
              onChange={actions.togglePageLock}
            />
          </Box>
        )}
      </MainContainer>
      {showFolderDialog && currentStep === 'folder-name' && (
        <ArchivePanel
          showFolderDialog={showFolderDialog}
          isRootView={isRootView}
          editing={editing}
          handleFolderNameChange={handleFolderNameChange}
          currentStep={currentStep}
          resetOnClose={resetOnClose}
          handleNextAction={handleNextAction}
          folderEdits={folderEdits}
          setFolderEdits={setFolderEdits}
        />
      )}
      <NextStepDialog
        open={
          showFolderDialog && isRootView && currentStep === 'assign-permissions'
        }
        onClose={resetOnClose}
        fullWidth={true}
        id="new_folder_permissions"
        actionDisplayType="inline"
        actions={[
          {
            label: 'Cancel',
            onClick: resetOnClose,
            id: 'cancel_button',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'Save',
            onClick: () =>
              handleNextAction(
                editing ? 'edit-root-folder' : 'create-folder',
                folderEdits
              ),
            id: 'save_button',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Permissions"
      >
        <Box flex={1}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <Filter
                handleSelection={handleSelectPermission}
                handleAllSelection={handleAllPermissionSelection}
                options={state.permissions}
                selectedOptions={selectedPermissions.map((item) => item.id)}
                idField="key"
                labelField="name"
                popoverStyle={{
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  padding: theme.spacing(1),
                  borderRadius: 4,
                }}
                scrollContainerStyle={{ maxWidth: '100%', maxHeight: 400 }}
                enableApplyAction={false}
                applyOnSelect={true}
                clearable={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PermissionBox>
                <ChipsContent>
                  <PermissionChips
                    removeSelected={removeSelectedPermission}
                    permissions={selectedPermissions}
                  />
                </ChipsContent>
              </PermissionBox>
            </Grid>
          </Grid>
        </Box>
      </NextStepDialog>
      <NextStepDialog
        open={showRenameFileDialog && currentStep === 'file-rename'}
        onClose={resetOnClose}
        fullWidth={true}
        id="rename_file"
        actionDisplayType="inline"
        actions={[
          {
            label: 'Cancel',
            onClick: resetOnClose,
            id: 'cancel_file_rename_button',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'OK',
            onClick: () => confirmFileRename(),
            id: 'done_file_rename_button',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title={'Rename'}
      >
        <Box>
          <BootstrapInput
            id="file_rename_input"
            label="File Name"
            autoFocus={true}
            fullWidth={true}
            value={fileName}
            onChange={handleFileNameChange}
          />
        </Box>
      </NextStepDialog>
      <ArrowPopover
        id="context_menu_popover"
        anchorEl={contextMenu?.lastChild || null}
        showPopover={Boolean(contextMenu)}
        handleOnPopoverClose={handleOnContextMenuClose}
        content={
          <List dense={true}>
            {ContextMenuOptions?.filter((option) =>
              contextMenuMoveOptions === 'only_rename'
                ? option.type === 'edit'
                : true
            ).map((option) => (
              <ListItem
                key={option.id}
                sx={{
                  display:
                    contextMenuMoveOptions === option.type ||
                    contextMenuMoveOptions === 'both' ||
                    option.forceVisible
                      ? 'initial'
                      : 'none',
                }}
              >
                <ListItemButton
                  onClick={(event) =>
                    handleContextMenuOption(event, option.type)
                  }
                  divider={option.divider}
                >
                  <ListItemIcon id={`${option.id}`} icon={option.icon} />
                  <Typography variant="subtitle2">
                    {option.id === 'edit_folder' && isRootView
                      ? 'Edit'
                      : option.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
      />
      <ArrowPopover
        id="add_menu_popover"
        anchorEl={addNewAnchorEl}
        showPopover={showAddOptions}
        handleOnPopoverClose={handleOnAddNewActionClose}
        content={
          <List dense={true}>
            {AddNewOptions?.map((option) => (
              <ListItem key={option.id}>
                <ListItemButton
                  onClick={(event) => handleAddNewPopover(event, option.type)}
                >
                  <ListItemIcon id={`${option.id}`} icon={option.icon} />
                  <Typography variant="subtitle2">{option.label}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
      />
      <ProgressModal
        id="documents_progress_modal"
        showProgress={isLoading || renameLoading || pdfLoading}
      />
      {showConsent && (
        <ConfirmationDialog
          open={showConsent}
          onClose={() => {}}
          id="consent_confirmation"
          actionDisplayType="inline"
          actions={[
            {
              label: 'Accept',
              onClick: acceptConsent,
              id: 'accept_button',
              variant: 'contained',
              color: 'primary',
            },
            {
              label: 'Decline',
              onClick: declineConsent,
              id: 'decline_button',
              variant: 'outlined',
              color: 'error',
            },
          ]}
          title="Consent confirmation"
          content={state.consent}
        />
      )}
      {showPrompt && (
        <ConfirmationDialog
          open={showPrompt}
          onClose={() => setShowPrompt(false)}
          id="prompt_box_publish"
          actionDisplayType="inline"
          actions={[
            {
              label: 'OK',
              onClick: handleWarningPrompt,
              id: 'ok_button',
              variant: 'contained',
              color: 'primary',
            },
          ]}
          title="Warning"
          content="The files without investors and funds will not be available on client portal."
        />
      )}
      {showBulkFileDlPrompt && (
        <ConfirmationDialog
          open={showBulkFileDlPrompt}
          onClose={() => setShowBulkFileDlPrompt(false)}
          id="lp_bulk_file_download_prompt"
          actionDisplayType="inline"
          actions={[
            {
              label: 'OK',
              onClick: () => handleLpBulkFileDownload(),
              id: 'ok_button',
              variant: 'contained',
              color: 'primary',
            },
          ]}
          title="Download Notice"
          whitespace={true}
          content={
            downloadableFileIds.length
              ? `Please be aware that only documents with download permissions enabled will be included in the download. 
 
            ${downloadableFileIds.length} of the ${fileCount} selected files will be downloaded.`
              : 'All selected documents have download permissions disabled. No files will be downloaded.'
          }
        />
      )}
      {showFolderDlPrompt && (
        <ConfirmationDialog
          open={showFolderDlPrompt}
          onClose={() => setShowFolderDlPrompt(false)}
          id="lp_folder_download_prompt"
          actionDisplayType="inline"
          actions={[
            {
              label: 'OK',
              onClick: () => handleLpFolderDownload(),
              id: 'ok_button',
              variant: 'contained',
              color: 'primary',
            },
          ]}
          title="Folder Download"
          whitespace={true}
          content={`Please note that only documents with download permissions ON will be downloaded within ${
            downloadableFolderIds.length > 1 ? 'these folders.' : 'this folder.'
          }`}
        />
      )}

      {showDeleteOverflowPrompt && (
        <ConfirmationDialog
          open={showDeleteOverflowPrompt}
          onClose={() => setShowDeleteOverflowPrompt(false)}
          id="overflow_prompt_box_publish"
          actionDisplayType="inline"
          actions={[
            {
              label: 'OK',
              onClick: handleDeleteOverflowPrompt,
              id: 'ok_button',
              variant: 'contained',
              color: 'primary',
            },
          ]}
          title="Alert"
          content="Please only select 150 or less files to delete"
        />
      )}
      <DeleteConfirmationDialog
        open={showDeleteConfirmationBox}
        id="delete_confirmation_modal"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
      />
      <UploadFiles
        showDialog={showUploadFiles}
        closeDialog={handleCloseUploadFiles}
        reload={reloadCurrentFolder}
        addNewFiles={addNewFiles}
      />
      <AdobeViewer
        isAdmin={isAdmin}
        openAdobeViewer={openAdobeViewer}
        setOpenAdobeViewer={setOpenAdobeViewer}
        pdfFile={pdfFile}
      />
      <NextStepDialog
        open={showMovePanel}
        onClose={resetOnClose}
        fullWidth={true}
        id="move_files_in_folder"
        actionDisplayType="inline"
        actions={[
          {
            label: 'Cancel',
            onClick: resetOnClose,
            id: 'cancel_button',
            variant: 'outlined',
            color: 'error',
          },
          {
            label: 'OK',
            onClick: handleMoveFiles,
            id: 'ok_button',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Move File To"
      >
        <Box flex={1}>
          <Typography variant="filterUnchecked">
            Select the folder you want to move files to.
          </Typography>
          <FolderTree
            structure={state?.tree?.folders ?? []}
            onSelect={handleFolderSelection}
            selectedFolder={selectedFolder}
          />
        </Box>
      </NextStepDialog>
      {error && (
        <Errors
          open={!!error.title}
          title={
            <Stack
              gap={1}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <ImgIcon icon={IconWarning} />
              <Typography variant="h3" color="red">
                {error.title}
              </Typography>
            </Stack>
          }
          subTitle={error.subTitle}
          handleClose={clearError}
        />
      )}
    </DocumentsContext.Provider>
  );
}

export default Documents;
