import React, { useContext, useEffect } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { AppContext } from "../../../core/context/appContextProvider";
import { createUpdatedConsents } from "../../../services/client.service.v2";
import { useClientConsentsEffect } from "../../../services/hooks/useClientsEffect/useClientConsentsEffect.hooks";
import { objectToArray } from "../../../utils/helpers/objectToArray";
import { ClientConsent, ClientConsentList } from "../../../utils/types/client.type";
import { ClientTabs, CONSENT_OPTIONS } from "../constants";

interface RouteProp {
  id: string
  arkTag: string
  section: string;
}

const newConsent = {
  text: '',
  type: '',
};

export const useConsent = () => {
  const {
    id,
    arkTag,
  } = useParams<RouteProp>();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const {
    consents,
    loading: loadingConsents,
    fetchConsents,
  } = useClientConsentsEffect(id);

  const {
    informationAlert,
    state
  } = useContext(AppContext);

  const tenantId = state.loginUser.clientId;

  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<ClientConsent>({
    defaultValues: {
      type: CONSENT_OPTIONS[0].id
    },
  });

  const {
    isDirty
  } = useFormState({
    control
  });

  const handleTypeChange = (type?: string | null) => {
    if(type) {
      const currentConsent = consents?.[type as keyof ClientConsentList];

      if(currentConsent) {
        reset(currentConsent);
      } else {
        reset({
          ...newConsent,
          type: type
        });
      }
    }
  };

  useEffect(() => {
    const currentConsentType = getValues("type");

    handleTypeChange(currentConsentType);
  }, [consents]);

  const onSubmit = async (data: ClientConsent) => {
    if(!data?.type) {
      return;
    }
    if(isDirty) {
      
      const currentConsents = {
        ...consents,
        [data?.type || ""]: { ...data, index: 0, tenantId },
      };

      setLoading(true);
      try {
        await createUpdatedConsents(id, objectToArray(currentConsents));
        await fetchConsents();
        history.push(`/clients/${id}/${arkTag}/${ClientTabs.FeatureConfig}`);
      } catch (error) {
        informationAlert("Error in updating consents", "error");
      } finally {
        setLoading(false);
      }
    } else {
      history.push(`/clients/${id}/${arkTag}/${ClientTabs.FeatureConfig}`);
    }
  };

  const onCancel = () => {
    fetchConsents();
  };

  return {
    handleSubmit,
    isDirty,
    control,
    onSubmit,
    onCancel,
    loading: loadingConsents || loading,
    handleTypeChange
  };
};
