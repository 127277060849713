import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "../../core/context/appContextProvider";
import RoutingPaths from "../../core/routing/routingPaths";
import useRole from "../../core/routing/useRole";
import { getClientDetails } from "../../services/client.service.v2";
import { useDashboardEffect } from "../../services/hooks/useDashboardEffect/useDashboardEffect.hooks";
import useCopyToClipboard from "../../utils/hooks/useCopyToClipboard";
import { DashboardType } from "../../utils/types/dashboard.type";
import { ScopeRole } from "../../utils/types/user.type";
import { CapitalCallDetailsTabs } from "../capitalCalls/capitalCallDetails/constants";
import { ClientTabs } from "../clientDetails/constants";
import { DistributionDetailsTabs } from "../distribution/DistributionDetails/Constants";

export const useDashboard = () => {
  const {
    state,
    sideNavs
  } = useContext(AppContext);
  const history = useHistory();

  const {
    copy
  } = useCopyToClipboard();
  const {
    hasRole: isSuperOrClientAdmin
  } = useRole([ScopeRole.SUPER_ADMIN, ScopeRole.ARK_CLIENT_ADMIN]);

  const {
    pendingItems,
    loading,
    portalLink
  } = useDashboardEffect({
    shouldFetch: !!(sideNavs && sideNavs?.length > 0)
  });

  const handleRedirection = (type?: DashboardType, id?: string) => {
    if(!type || !id) {
      return;
    }
    switch(type) {
      case DashboardType.CAPITAL_CALL:
        history.push(`${RoutingPaths.CapitalCalls}/${id}/${CapitalCallDetailsTabs.ReviewDocuments}`);
        break;
      case DashboardType.DISTRIBUTION:
        history.push(`${RoutingPaths.Distributions}/${id}/${DistributionDetailsTabs.ReviewDocuments}`);
        break;
      case DashboardType.INVESTOR:
      case DashboardType.INVESTOR_WIRE_INFO:
        history.push(RoutingPaths.Investors);
        break;
      case DashboardType.CONTACT:
        history.push(RoutingPaths.Contacts);
        break;
    }
  };

  const redirectToClientConfig = async () => {
    if(state?.loginUser?.clientId) {
      const client = await getClientDetails(state?.loginUser?.clientId);

      history.push(`/clients/${client.id}/${client?.arkClientTag}/${ClientTabs.ClientDetails}`);
    }
  };

  return {
    copy,
    pendingItems,
    portalLink,
    loading,
    sideNavs,
    state,
    handleRedirection,
    redirectToClientConfig,
    isSuperOrClientAdmin
  };
};
