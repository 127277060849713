import CloseIcon from '@mui/icons-material/Close';
import { 
  IconButton,
  TextField 
} from "@mui/material";
import { ReactElement } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import IconDragColumn from "../../../../../assets/images/icons/icon_drag_column.svg";
import { ItemData,TextAlignment } from "../../../constants";
import { GeneralReportForm } from "../../useReportConfig.hooks";
import {
  CloseIconBox,
  DashedBorderBox,
  LabelBorderBox,
  ListViewContainer,
  MainRow,
  OrderLayout,
  StyledTypography
} from "./ListView.style";

type Props = {
    id: number;
    type: string;
    index: number;
    cancelIcon: number | undefined;
    alignmentOrder?: string;
    handleRemove: (arg:number) => void;
    reportForm: UseFormReturn<GeneralReportForm, any>;
    isDirty: boolean;
}

const  ListView = ({
  alignmentOrder = 'center',
  id,
  type,
  index,
  cancelIcon,
  handleRemove,
  reportForm,
  isDirty
}: Props): ReactElement => {

  const  orderHandler = () =>{
    switch(alignmentOrder){
      case TextAlignment.left:
        return -1;
      case TextAlignment.center:
        return 0;
      case TextAlignment.right:
        return 1;
      default:
        return 0;
    }
  };

  return (
    <ListViewContainer key={`grh_list_${index}`}>
      <CloseIconBox>
        {cancelIcon === index &&
          <img src={IconDragColumn} alt="IconDragColumn"/>
        }
      </CloseIconBox>

      <MainRow>
        <DashedBorderBox/>
        {type !== ItemData.report ?
          <LabelBorderBox order={orderHandler()}>
            <StyledTypography variant="h5">{type}</StyledTypography>
          </LabelBorderBox>
          :
          type === ItemData.report ?
            <Controller
              name={"name"}
              render={({
                field: {
                  onChange, value
                },
                fieldState: {
                  error
                }
              }) => {
                return (
                  <OrderLayout
                    order={orderHandler()}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      id="list_view"
                      label={type}
                      value={value || ""}
                      onChange={onChange}
                      variant="outlined"
                      error={!!error}
                      helperText={error?.message ?? ''}
                    />
                  </OrderLayout>
                );
              }}
              control={reportForm.control}
              rules={{
                required: isDirty ? "Report name is required" : false,
              }}
            /> :
            <OrderLayout
              order={orderHandler()}
            >
              <TextField
                fullWidth
                size="small"
                id="list_view"
                label={type}
                variant="outlined"
              />
            </OrderLayout>
        }
        <DashedBorderBox/>
      </MainRow>
      <CloseIconBox>
        {cancelIcon === index &&
          <IconButton onClick={() => handleRemove(index)}>
            <CloseIcon/>
          </IconButton>
        }
      </CloseIconBox>
    </ListViewContainer>
  );
};

export default ListView;
