import { ReactElement } from "react";

import { StyledContainer } from "./Container.styles";

type Props = { id: string; role: string, children?: any };

const Container = (props: Props): ReactElement => {
  const { id, role, children } = props;

  return (
    <StyledContainer id={id} role={role}>
      {children}
    </StyledContainer>
  );
};

export default Container;
