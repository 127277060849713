import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Popper,
  styled,
} from "@mui/material";

import {
  ERROR,
  LIST_DIVIDER,
  PRIMARY,
  SPLIT_BUTTON_BACKGROUND,
  WHITE,
} from "../../../styles/colors";

export const StyledButton = styled(Button)<{
  backgroundcolor?: string;
}>`
  background-color: ${(props) =>
    props.backgroundcolor || SPLIT_BUTTON_BACKGROUND};
  border: ${`1px solid ${PRIMARY}`};
  border-radius: 6px;
  border-left: none;
  ${(props) => (props.size === "small" ? "height: 30px;" : "height: 40px;")}

  &:focus {
    background-color: ${(props) =>
      props.backgroundcolor || SPLIT_BUTTON_BACKGROUND};
  }
`;

export const StyledArrowIcon = styled(ArrowDropDownIcon)<{
  textcolor?: string;
}>`
  color: ${(props) => props.textcolor || PRIMARY};
  &:active {
    color: ${(props) => props.textcolor || WHITE};
  }
  &:focus {
    color: ${(props) => props.textcolor || WHITE};
  }
  &:hover {
    color: ${(props) => props.textcolor || WHITE};
  }
`;

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  aligItems: "center",
  minWidth: "130px",
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
}));

export const StyledBoxText = styled(Box)<{ color: string }>`
  padding-left: 10px;
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => {
    if (props.color === "primary") return PRIMARY;
    else return ERROR;
  }};
`;

export const StyledButtonGroup = styled(ButtonGroup)<{
  nogutter: string;
}>`
  margin-right: ${(props) => {
    if (props.nogutter === "true") return "0px";
    else return "8px";
  }};
  min-width: 160px;
  justify-content: flex-end;
`;

export const StyledMenuList = styled(MenuList)`
  border-radius: 6px;
  box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.3),
    -6px 6px 15px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.3),
    -6px 6px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 6px 6px 15px 0px rgba(0, 0, 0, 0.3),
    -6px 6px 15px 0px rgba(0, 0, 0, 0.3);

  z-index: 1;
`;

export const StyledPopper = styled(Popper)`
  z-index: 1300;
`;

export const StyledMenuItem = styled(MenuItem)<{
  color: string;
  hidden?: boolean;
}>`
  border-top: ${(props) => {
    if (props.color === "primary") return "none";
    else return `1px solid ${LIST_DIVIDER}`;
  }};
  display: ${(props) => (props.hidden ? "none" : "inherit")};
`;
