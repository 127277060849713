import { Typography } from "@mui/material";
import React from "react";

import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import Button from "../../../components/Buttons/Button/Button";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import DataGrid from "../../../components/DataGrid/DataGrid";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import { ButtonContainer, HeaderRow } from "./CapitalCalls.styles";
import { usePortalCapitalCall } from "./useCapitalCalls.hook";
import ViewNotice from "./ViewNotice";
const CapitalCalls: React.FC = () => {
  const {
    headerList,
    list,
    isLoading,
    selectionModel,
    setSelectionModel,
    handleFilter,
    handleOnView,
    onViewNoticeClose,
    selectedCapitalCall,
    bulkActions,
    handleBulkActions
  } = usePortalCapitalCall();

  return (
    <DataWrapperBox id="portal_capital_call_list_main">
      <Progress id="portal_capital_call_list_loading" showProgress={isLoading} />
      <HeaderRow>
        <Typography variant="pageTitle">Capital Calls</Typography>
        <ButtonContainer>
          <SplitButton
            id={"btn_bulk_action_options"}
            options={bulkActions}
            hidden={false}
            handleOptionClick={handleBulkActions}
            ariaLabelMessage="Select bulk action option"
          />
        </ButtonContainer>
      </HeaderRow>
      <DataGrid
        id="portal_capital_call_data_grid"
        dataList={list || []}
        noDataMessage={isLoading ? "Fetching Data..." : undefined}
        headerList={headerList}
        minHeight="1vh"
        autoHeight={false}
        handleOnView={handleOnView}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleFilter={handleFilter}
        activeHeaderFields={6}
        initialState={{
          sorting: {
            sortModel: [{ field: "callDate", sort: "desc" }],
          },
          pinnedColumns: {
            right: ["action"],
          },
        }}
      />
      {!!selectedCapitalCall && (
        <ViewNotice
          onViewNoticeClose={onViewNoticeClose}
          selectedCapitalCall={selectedCapitalCall}
        />
      )}
    </DataWrapperBox>
  );
};

export default React.memo(CapitalCalls);
