import Close from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    fullWidth: true,
    padding: 0,
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.25)",
    borderRadius: theme.spacing(1),
    maxWidth: "95%",
    width: "95%",
    height: "90%",
  },
  "&  .MuiBackdrop-root": {
    backgroundColor: theme.palette.common.modalBackground,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: "medium",
  fontSize: theme.typography.pxToRem(20),
  lineHeight: 1.1,
  color: theme.palette.text.primary,
  padding: 0,
  marginBottom: 34,
  position: "relative",
  alignItems: "center",
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(4),
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  position: "absolute",
  right: 4,
  top: 4,
  fontSize: theme.typography.pxToRem(30),
  cursor: "pointer",
  color: "#6e6e6e",
  transition: "color 0.3s",
  "&:hover": {
    color: "black",
  },
}));
