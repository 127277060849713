import React, { ReactElement, useEffect, useMemo } from 'react';
import { CSVLink } from 'react-csv';

import ExportIcon from '../../../assets/images/icons/icon_export.svg';
import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import { NumberFormat } from '../../../utils/helpers/format.helper';
import { DataGridColDef, ImageItem } from '../../../utils/types/listItems';
import { EndBalanceRange } from './glReports/shared';
import { ButtonBox } from './ReportList.styles';
import { defaultDataFieldOrder } from './scheduleOfInvestments/ScheduleOfInvestments.defaults';

type Props = {
  id: string;
  hidden: boolean;
  noGutter?: boolean;
  headers?: DataGridColDef[];
  data?: Record<string, any>[];
  fileName: string;
  reportView?: string;
};

type headerType = {
  label: string;
  key: string;
};

enum GlViews {
  trialBalance = 'trial_balance',
  balanceSheet = 'balance_sheet',
  balanceSheetFsMapping = 'balance_sheet_fs_mapping',
  incomeStatement = 'income_statement',
  incomeStatementFsMapping = 'income_statement_fs_mapping',
}

const ExportButton: React.FC<Props> = ({
  id,
  hidden = false,
  headers,
  data,
  fileName,
  reportView,
}: Props): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const csvLinkRef = React.useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [csvDataExport, setCsvDataExport] = React.useState<Record<any, any>[]>(
    []
  );
  const [csvHeadersExport, setCsvHeadersExport] = React.useState<any>([]);

  if (
    reportView === GlViews.balanceSheet ||
    reportView === GlViews.incomeStatement ||
    reportView === GlViews.balanceSheetFsMapping ||
    reportView === GlViews.incomeStatementFsMapping ||
    reportView === GlViews.trialBalance
  ) {
    for (const row of data!) {
      for (const key in row) {
        const val = row[key];

        if (typeof val === 'string' || val instanceof String) {
          const pos = val.indexOf('|');

          if (pos >= 0) {
            row[key] = val.substring(pos + 1);
          }
        }
      }
    }
  }

  const defaultBulkActions: ImageItem[] = [
    {
      id: 'export',
      text: 'Export',
      icon: <img src={ExportIcon} alt="" height="15" />,
      optionsSelected: 0,
    },
  ];

  const handleExport = () => {
    csvLinkRef?.current?.link.click();
  };

  // Helper function for value formatting prior to export
  const formatValue = (format: string, currencyCode: string, value: number) => {
    let valueFormatted: string;

    switch (format) {
      case 'Currency.0':
        valueFormatted = NumberFormat(0).format(value);
        break;
      case 'Currency.1':
        valueFormatted = NumberFormat(1).format(value);
        break;
      case 'Currency.2':
        valueFormatted = NumberFormat(2).format(value);
        break;
      case 'Currency.3':
        valueFormatted = NumberFormat(3).format(value);
        break;
      case 'Currency.4':
        valueFormatted = NumberFormat(4).format(value);
        break;
      case 'Integer-use_commas':
        valueFormatted = Math.round(value).toLocaleString();
        break;
      default:
        valueFormatted = value.toString();
    }
    return valueFormatted;
  };

  const csvHeaders: headerType[] =
    useMemo(
      () =>
        headers?.reduce((acc: headerType[], header: DataGridColDef) => {
          if (
            header.field !== '_fieldFilterColDef' &&
            header.hide !== true &&
            header.type !== 'action'
          ) {
            return [
              ...acc,
              {
                label: header.headerName || '',
                key:
                  (header?.field === '_categoryName'
                    ? 'categoryName'
                    : header.field) || '',
              },
            ];
          }
          return acc;
        }, []),
      [headers]
    ) || [];

  let currencyCode = '';
  let decimalFormat = 0;

  // Format the CSV data with currencyCode and decimal values
  const csvData =
    useMemo(
      () =>
        data?.reduce((acc: Record<string, any>[], row) => {
          if (reportView) {
            if (row['currencyCode'] && row['decimals']) {
              currencyCode = row['currencyCode'];
              decimalFormat = row['decimals'];
            }

            const formattedRow: any = {};

            for (const key of Object.keys(row)) {
              if (key === EndBalanceRange) {
                row[key] === '' ? (row[key] = 0) : '';
              }
              if (key.includes('itd')) {
                row[key] === '' ? (row[key] = 0) : '';
              }
            }

            for (const [key, value] of Object.entries(row)) {
              formattedRow[key] =
                typeof value === 'number' &&
                key !== 'level' &&
                key !== 'decimals'
                  ? formatValue(
                      `Currency.${decimalFormat}`,
                      currencyCode,
                      value
                    )
                  : value;
            }

            row = formattedRow;
          } else {
            decimalFormat = row['decimals'];
            currencyCode = row['currencyCode'];

            if (row['amount'] !== M_DASH_UNICODE) {
              row['balance'] = parseFloat(row['balance']);
              row['amount'] = parseFloat(row['amount']);
            }

            const formattedRow: any = {};

            for (const [key, value] of Object.entries(row)) {
              formattedRow[key] =
                typeof value === 'number' &&
                key !== 'level' &&
                key !== 'decimals'
                  ? formatValue(
                      `Currency.${decimalFormat}`,
                      currencyCode,
                      value
                    )
                  : value;
            }

            row = formattedRow;
          }

          const formattedData =
            defaultDataFieldOrder?.reduce((previousValue, field) => {
              if (row[field?.name]) {
                return {
                  ...previousValue,
                  [field?.name]: formatValue(
                    field?.numberFormat,
                    row.currencyCode,
                    row[field?.name]
                  ),
                };
              }
              return previousValue;
            }, {}) || {};

          if (row.level !== 1) {
            return [
              ...acc,
              {
                ...row,
                categoryName: `${' '?.repeat(row?.level * 2)}${
                  row.categoryName
                }`,
                ...formattedData,
              },
            ];
          } else {
            return [
              ...acc,
              {
                ...row,
                ...formattedData,
              },
            ];
          }
          return acc;
        }, []),
      [data]
    ) || [];

  useEffect(() => {
    setCsvHeadersExport(csvHeaders);
    setCsvDataExport(csvData);
  }, [csvData, csvHeaders]);

  return (
    <React.Fragment>
      <ButtonBox>
        <SplitButton
          id={'btn_bulk_action_options'}
          options={defaultBulkActions}
          hidden={hidden}
          handleOptionClick={handleExport}
          ariaLabelMessage="Select bulk action option"
        />
      </ButtonBox>
      <CSVLink
        ref={csvLinkRef}
        headers={csvHeadersExport}
        data={csvDataExport}
        filename={fileName}
        onClick={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default React.memo(ExportButton);
