import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { Controller } from "react-hook-form";
import { RouteProps } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import Button from "../../../../components/Buttons/Button/Button";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton/DeleteButton.styles";
import Chip from "../../../../components/Chip/Chip";
import DetailPanel from "../../../../components/DetailPanel/DetailPanel";
import {
  FormBox,
  StyledBackdrop,
} from "../../../../components/DetailPanel/DetailPanel.styles";
import FileSelector from "../../../../components/FileSelector/FileSelectorPreview";
import { RouterLink } from "../../../../components/Link/RouterLink/RouterLink";
import ConfirmationDialog from "../../../../components/Modal/ConfirmationDialog";
import ProgressPanel from "../../../../components/Progress/ProgressPanel/ProgressPanel";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import NumberFormatCustom from "../../../../components/TextFieldNumeric/NumberFormat";
import RoutingPaths from "../../../../core/routing/routingPaths";
import { DetailsType } from "../../../../utils/types/form.type";
import { SelectedPortfolioCompany } from "../../../../utils/types/portfolioCompany.type";
import { DatePicker } from "../../fundDetails/FundDetails.style";
import {
  ERROR_TAKEN_PORTFOLIO_COMPANY_NAME,
  PORTFOLIO_COMPANY_TYPE,
} from "../PortfolioCompanyList.constants";
import {
  usePortfolioCompanyDetails,
  useTabs,
} from "./portfolioCompanyDetails.hooks";
import {
  ChipStack,
  Panel,
  Typography,
  TypographyFunds,
} from "./portfolioCompanyDetails.styles";

interface Props extends RouteProps {
  onDetailClose: Function;
  selectedPortfolioCompany: SelectedPortfolioCompany;
  fetchAllPortfolioCompanies: Function;
  fundId?: string;
}

export const PortfolioCompanyDetails: React.FC<Props> = ({
  onDetailClose,
  selectedPortfolioCompany,
  fetchAllPortfolioCompanies,
  fundId,
}: Props) => {
  const { type, portfolioCompany } = selectedPortfolioCompany;
  const isNewPortfolioCompany = type === DetailsType.New;

  const {
    currentTab,
    detailsRef,
    fundsRef,
    additionalFieldsRef,
    logoRef,
    handleTabChange,
  } = useTabs();

  const {
    loading,
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    fields,
    createUpdatePortfolioCompany,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    nameUnavailableError,
    showExitConfirmation,
    keepDrawerOpen,
    getImagePreview,
    onLogoChange,
    isPublic,
    funds,
    investmentTypes,
    logo,
  } = usePortfolioCompanyDetails({
    portfolioCompany,
    onClose: onDetailClose,
    fetchAllPortfolioCompanies,
    isNewPortfolioCompany,
    fundId,
  });

  return (
    <>
      <DetailPanel
        id="portfolio_company_panel"
        title={
          isNewPortfolioCompany
            ? "Add Portfolio Company"
            : portfolioCompany?.name
        }
        open={Boolean(type)}
        onClose={toggleDrawer}
        variant={"temporary"}
        hasTabsPanel={true}
        tabsPanelValue={currentTab}
        tabsPanelHandleChange={handleTabChange}
        tabsPanelOptions={[
          "Details",
          "Funds & Securities",
          "Additional Fields",
          "Logo",
        ]}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdatePortfolioCompany)}
          noValidate
        >
          <FormBox>
            <Panel id="details_panel" ref={detailsRef}>
              <Typography variant="h4">Details</Typography>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="portfolio_company_name"
                    size="small"
                    fullWidth
                    aria-describedby="name"
                    label="Name"
                    placeholder="Portfolio Company Name"
                    variant="outlined"
                    error={!!errors.name || nameUnavailableError}
                    {...register("name", {
                      required: true,
                      validate: (value: any) => !!value?.trim(),
                    })}
                    helperText={
                      (!!errors.name && "Portfolio Company Name is required") ||
                      (nameUnavailableError &&
                        ERROR_TAKEN_PORTFOLIO_COMPANY_NAME)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_street1"
                    size="small"
                    fullWidth
                    aria-describedby="street1"
                    label="Address 1"
                    placeholder="Address 1"
                    variant="outlined"
                    error={!!errors.address?.street1}
                    {...register("address.street1", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_street2"
                    size="small"
                    fullWidth
                    aria-describedby="street2"
                    label="Address 2"
                    placeholder="Address 2"
                    variant="outlined"
                    error={!!errors.address?.street2}
                    {...register("address.street2", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_postal_code"
                    size="small"
                    fullWidth
                    aria-describedby="postal_code"
                    label="Postal Code"
                    placeholder="Postal Code"
                    variant="outlined"
                    error={!!errors.address?.postalCode}
                    {...register("address.postalCode", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_city"
                    size="small"
                    fullWidth
                    aria-describedby="city"
                    label="City"
                    placeholder="City"
                    variant="outlined"
                    error={!!errors.address?.city}
                    {...register("address.city", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_state"
                    size="small"
                    fullWidth
                    aria-describedby="state"
                    label="State/Province/Region"
                    placeholder="State/Province/Region"
                    variant="outlined"
                    error={!!errors.address?.state}
                    {...register("address.state", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_country"
                    size="small"
                    fullWidth
                    aria-describedby="country"
                    label="Country"
                    placeholder="Country"
                    variant="outlined"
                    error={!!errors.address?.country}
                    {...register("address.country", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_industry"
                    size="small"
                    fullWidth
                    aria-describedby="industry"
                    label="Industry"
                    placeholder="Industry"
                    variant="outlined"
                    error={!!errors.industrySector}
                    {...register("industrySector", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_ceo_name"
                    size="small"
                    fullWidth
                    aria-describedby="ceo_name"
                    label="CEO name"
                    placeholder="CEO Name"
                    variant="outlined"
                    error={!!errors.ceo}
                    {...register("ceo", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_year_founded"
                    size="small"
                    fullWidth
                    aria-describedby="year_founded"
                    label="Year founded"
                    placeholder="Year founded"
                    variant="outlined"
                    error={!!errors.yearFounded}
                    {...register("yearFounded", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="portfolio_company_num_employees"
                    size="small"
                    fullWidth
                    aria-describedby="num_employees"
                    label="Number of employees"
                    placeholder="Number of employees"
                    variant="outlined"
                    error={!!errors.numOfEmployees}
                    {...register("numOfEmployees", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="portfolio_company_about"
                    size="small"
                    rows={8}
                    multiline
                    fullWidth
                    aria-describedby="about"
                    label="About"
                    placeholder="About"
                    variant="outlined"
                    error={!!errors.about}
                    {...register("about", {
                      required: false,
                    })}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Controller
                    name="isPublic"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SingleSelect
                          id="portfolio_company_type"
                          noGutter
                          optionList={PORTFOLIO_COMPANY_TYPE}
                          idField="id"
                          labelField="name"
                          handleOptionSelection={(event: SelectChangeEvent) => {
                            onChange({
                              target: {
                                value:
                                  event.target.value === "Public"
                                    ? true
                                    : false,
                              },
                            });
                          }}
                          value={value === true ? "Public" : "Private"}
                          error={!!error}
                          label="Type"
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid xs={6} item>
                  {isPublic === true && (
                    <TextField
                      id="portfolio_company_tickerSymbol"
                      size="small"
                      fullWidth
                      aria-describedby="tickerSymbol"
                      label="Ticker Symbol"
                      placeholder="Ticker Symbol"
                      variant="outlined"
                      error={!!errors.tickerSymbol}
                      {...register("tickerSymbol", {
                        required: true,
                        validate: (value: any) => !!value?.trim(),
                      })}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="portfolio_company_website"
                    size="small"
                    fullWidth
                    aria-describedby="website"
                    label="Website URL"
                    placeholder="Website URL"
                    variant="outlined"
                    error={!!errors.website}
                    {...register("website", {
                      required: false,
                    })}
                  />
                </Grid>
              </Grid>
            </Panel>
            <Panel id="funds_panel" ref={fundsRef}>
              <TypographyFunds variant="formTitle">
                Funds{/* &amp; Securities */}
              </TypographyFunds>
              <Grid container xs={12}>
                {/* <Grid item xs={12}> */}
                {/* </Grid> */}
                {!isNewPortfolioCompany && (
                  <>
                    <Grid item xs={6}>
                      <ChipStack>
                        {funds.map((fund) => {
                          return (
                            <Chip
                              label={fund.name}
                              id={fund.id}
                              key={fund.id}
                              onDelete={undefined}
                              color="green"
                            />
                          );
                        })}
                      </ChipStack>
                    </Grid>
                    <Grid item xs={6}>
                      {/* TODO BE needs to be fixed */}
                      {/* <ChipStack>
                      {investmentTypes.map(security => {
                        return (
                          <Chip
                            label={security.label}
                            id={security.id}
                            key={security.id}
                            onDelete={undefined}
                            color="blue"
                          />
                        );
                      })}
                    </ChipStack> */}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container xs={12} mt={2} mb={4}>
                <Grid item xs={6}>
                  {fundId ? (
                    <RouterLink
                      to={{
                        pathname: `${RoutingPaths.InvestmentTransactionsFund}/${fundId}`,
                        search: `?portfolio-company=${portfolioCompany?.id}`,
                      }}
                    >
                      Click here to add or view transactions
                    </RouterLink>
                  ) : (
                    <RouterLink
                      to={{
                        pathname: `${RoutingPaths.InvestmentTransactions}`,
                        search: `?portfolio-company=${portfolioCompany?.id}`,
                      }}
                    >
                      Click here to add or view transactions
                    </RouterLink>
                  )}
                  {/* <Link to={'portfolioCompany?.id'}>Click here to add or view transactions</Link> */}
                </Grid>
              </Grid>
            </Panel>
            <Panel id="additional_fields_panel" ref={additionalFieldsRef}>
              <Typography variant="h4">Additional Fields</Typography>
              {fields?.map((field: any, index: any) => (
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  mb={2}
                  key={field.customProperty.id}
                >
                  {field.customProperty.type === "SINGLE_LINE_TEXT" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip title={field.customProperty.description}>
                              <TextField
                                {...field}
                                value={value}
                                id={`additional_field.${index}.customProperty.id`}
                                onChange={(newValue) => {
                                  onChange(newValue);
                                }}
                                size="small"
                                fullWidth
                                aria-describedby={field.customProperty.id}
                                label={field.customProperty.name}
                                placeholder={field.customProperty.name}
                                variant="outlined"
                              />
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "MULTIPLE_LINE_TEXT" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip title={field.customProperty.description}>
                              <TextField
                                {...field}
                                value={value}
                                id={`additional_field.${index}.customProperty.id`}
                                size="small"
                                fullWidth
                                onChange={(newValue) => {
                                  onChange(newValue);
                                }}
                                aria-describedby={`additional_field.${index}.customProperty.id`}
                                label={field.customProperty.name}
                                placeholder={field.customProperty.name}
                                multiline
                                rows={4}
                                variant="outlined"
                              />
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "SINGLE_CHECKBOX" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip
                              title={field.customProperty.description}
                              placement="right"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={
                                      value === "true" || value === true
                                        ? true
                                        : false
                                    }
                                    value={value}
                                    onChange={(newValue) => {
                                      onChange(newValue);
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={field.customProperty.name}
                              />
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "MULTIPLE_CHECKBOX" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <FormControl sx={{ width: "100%" }}>
                              <FormLabel id={`${field.id}_label`}>
                                {field.customProperty.name}
                              </FormLabel>
                              <Tooltip title={field.customProperty.description}>
                                <FormGroup>
                                  {field.customProperty.values &&
                                    field.customProperty.values.map(
                                      (checkboxValue: any, index: any) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              {...field}
                                              checked={value[checkboxValue]}
                                              value={value[checkboxValue]}
                                              name={checkboxValue}
                                              onChange={(event) => {
                                                onChange({
                                                  ...value,
                                                  [event.target.name]:
                                                    event.target.checked,
                                                });
                                              }}
                                              inputProps={{
                                                "aria-label": "controlled",
                                              }}
                                            />
                                          }
                                          label={checkboxValue}
                                        />
                                      )
                                    )}
                                </FormGroup>
                              </Tooltip>
                            </FormControl>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "DROPDOWN_SELECTION" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip title={field.customProperty.description}>
                              <FormControl sx={{ width: "100%" }}>
                                <InputLabel id={`${field.id}_label`}>
                                  {field.customProperty.name}
                                </InputLabel>
                                <Select
                                  {...field}
                                  value={value}
                                  id={field.customProperty.id}
                                  labelId={field.customProperty.name}
                                  onChange={(newValue) => {
                                    onChange(newValue);
                                  }}
                                  label={field.customProperty.name}
                                >
                                  <MenuItem value="">None</MenuItem>
                                  {field.customProperty.values &&
                                    field.customProperty.values.map(
                                      (value: any, index: any) => (
                                        <MenuItem key={value} value={value}>
                                          {value}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "RADIO_SELECTION" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip
                              title={field.customProperty.description}
                              placement="right"
                            >
                              <FormControl>
                                <FormLabel id={field.customProperty.id}>
                                  {field.customProperty.name}
                                </FormLabel>
                                <RadioGroup
                                  {...field}
                                  value={value}
                                  aria-labelledby={`${field.id}_radio_button_group`}
                                  name={`${field.id}_radio_button_group`}
                                  onChange={(newValue) => {
                                    onChange(newValue);
                                  }}
                                >
                                  {field.customProperty.values &&
                                    field.customProperty.values.map(
                                      (value: any, index: any) => (
                                        <FormControlLabel
                                          key={value}
                                          value={value}
                                          control={<Radio />}
                                          label={value}
                                        />
                                      )
                                    )}
                                </RadioGroup>
                              </FormControl>
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "DATE_PICKER" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <DatePicker
                              {...field}
                              value={value}
                              label="Date"
                              renderInput={(params: any) => (
                                <Tooltip
                                  title={field.customProperty.description}
                                  placement="right"
                                >
                                  <TextField
                                    {...params}
                                    size="small"
                                    id={`${field.customProperty.id}_date`}
                                    placeholder="MM/DD/YYYY"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label={field.customProperty.name}
                                  />
                                </Tooltip>
                              )}
                              onChange={(newValue) => {
                                onChange(newValue);
                              }}
                            />
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                  {field.customProperty.type === "NUMBERS" && (
                    <Grid item xs={12}>
                      <Controller
                        name={`additionalFieldsForm.${index}.value`}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Tooltip title={field.customProperty.description}>
                              <TextField
                                {...field}
                                value={value}
                                type={
                                  field.customProperty.formattingType ===
                                  "UNFORMATTED_NUMBER"
                                    ? "number"
                                    : undefined
                                }
                                id={`additional_field.${index}.customProperty.id`}
                                onChange={(newValue) => {
                                  onChange(newValue);
                                }}
                                size="small"
                                fullWidth
                                aria-describedby={field.customProperty.id}
                                label={field.customProperty.name}
                                placeholder={field.customProperty.name}
                                variant="outlined"
                                inputProps={{
                                  format:
                                    field.customProperty.formattingType ===
                                      "FORMATTED_NUMBER" &&
                                    field.customProperty.formattingValue,
                                }}
                                InputProps={{
                                  inputComponent:
                                    (field.customProperty.formattingType ===
                                      "CURRENCY" ||
                                      field.customProperty.formattingType ===
                                        "FORMATTED_NUMBER") &&
                                    NumberFormatCustom,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Tooltip>
                          );
                        }}
                        control={control}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Panel>
            <Panel id="logo_panel" ref={logoRef}>
              <Typography variant="h4">Logo</Typography>
              <Controller
                name="logo"
                render={({ field: { onChange, value } }) => (
                  <FileSelector
                    fileHandler={(newValue?: string, files?: any[]) => {
                      if (!newValue) {
                        setValue("logo", null);
                      }
                      onChange(files?.[0]);
                      onLogoChange(files?.[0]);
                    }}
                    img={
                      typeof logo === "string" ? logo : getImagePreview(value)
                    }
                    previewWidth="400px"
                    previewHeight="250px"
                  />
                )}
                control={control}
              />
            </Panel>
            {!isNewPortfolioCompany && (
              <Panel id="delete_panel">
                <DeleteButton
                  id="btn_delete_portco"
                  variant="text"
                  disableElevation
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  onClick={handleDelete}
                  name="Delete Portfolio Company"
                >
                  Delete Portfolio Company
                </DeleteButton>
              </Panel>
            )}
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={"portfolio_company_cancel_button"}
              variant="outlined"
              onClick={toggleDrawer}
              text={"Cancel"}
              color={"secondary"}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={"portfolio_company_save_button"}
              variant="contained"
              text={"Save & Close"}
              color={"primary"}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={"progress_portfolio_company_panel"}
          showProgress={Boolean(loading)}
          text={`${loading} Portfolio Company...`}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete_portco_confirmation"
        actions={[
          {
            label: "Delete Permanently",
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: "btn_confirm_delete_portco",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: handleCancelDelete,
            id: "btn_cancel_delete",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Are you sure you want to delete this portfolio company? This action cannot be undone."
        title="Delete portfolio company?"
      />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_portfolio_company_confirmation"
        actions={[
          {
            label: "Keep Editing",
            onClick: keepDrawerOpen,
            id: "save_portfolio_company_modal_button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Discard Changes",
            onClick: closeDrawer,
            id: "cancel_save_portfolio_company_modal_button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};
