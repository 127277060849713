export type TransactionParams = {
  fromDateFilter?: string | null;
  toDateFilter?: string | null;
  offset: number;
  pageSize: number;
  searchText?: string | null;
  transTypes?: string[] | null;
  fundFilter?: string[] | null;
  investorFilter?: string[] | null;
}

export type NameIdPair = {
  id: string;
  name: string;
}

export type Transaction = {
  id: string;
  name: string;
  currencyCode: string;
  date: Date | string;
  quarterName?: string | null;
  metricSign: string;
  metricFractionDigit: number;
  amount: number | string;
  hasReviews: boolean;
  useMetric: boolean;
  fund: NameIdPair;
  investor: NameIdPair;
  reviewable: NameIdPair;
  reviewableStatus: NameIdPair;
  reviewableType: NameIdPair;
  txnType: NameIdPair;
}

export enum TransactionFilter {
  FundName = 'fundName',
  InvestorName = 'investorName',
  TransactionType = 'transactionType',
  TransactionName = 'transactionName',
  TransactionDate = 'transactionDate',
}

export enum TransactionAction {
  ExportSelected = 'export_selected',
  ExportAll = 'export_all',
  DeleteSelected = 'delete_selected',
  DeleteAll = 'delete_all',
}

export enum TransactionType {
  New = 'new',
  Edit = 'edit',
}

export type SelectedTransaction = {
  transaction?: Transaction;
  type?: TransactionType;
}

export enum TransactionCRUDStatus {
  Adding = 'Adding',
  Updating = 'Updating',
  Deleting = 'Deleting',
}
