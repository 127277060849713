import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Box, Typography } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import React from 'react';
import { useHistory } from 'react-router-dom';

import SplitButton from '../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../components/ButtonWithOptions/ButtonWithOptions';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import DeleteConfirmationDialog from '../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import MultiSelectFilters from '../../../components/MultiSelectFilters/MultiSelectFilters';
import ProgressModal from '../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../components/SearchBar/SearchBar';
import {
  ViewOption,
  ViewOptionsTabs,
} from '../../../components/Visualizations/viewOptionsTabs/ViewOptionsTabs';
import { VisualDashboard } from '../../../components/Visualizations/VisualDashboard';
import { VisualDashboardType } from '../../../components/Visualizations/VisualDashboard.constants';
import RoutingPaths from '../../../core/routing/routingPaths';
import { NewFundOptions } from '../../../utils/constants/fund.constants';
import { FundSummary, FundSummaryItem } from '../../../utils/types/fund.type';
import { useFundList } from './FundList.hooks';
import {
  FilterBorderColor,
  FundListDataBox,
  FundListDataWrapper,
  FundListHeaderRow,
  FundListWrapper,
  HeaderSubComponent,
  StickyAccordionSummary,
  StyledDataGrid,
} from './FundList.styles';

export const FundList: React.FC = () => {
  const {
    bulkActions,
    fundSummaries,
    headerList,
    activeHeaderFields,
    readonly,
    showDeletePrompt,
    handleBulkActions,
    handleUpdateHeader,
    handleFilter,
    handleNewButtonAction,
    search,
    searchOptions,
    onSearch,
    showSuggestionPopover,
    fundSelectionModel,
    setFundSelectionModel,
    setShowSuggestionPopover,
    isLoading,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    selectedFund,
    clearUploadCompleted,
    onDeleteCancel,
    onDeleteConfirm,
    setCurrentSelectedFund,
    selectedFundIDs,
    setSelectedFundIDs,
    filterSelectorFundSummaries,
    filteredFundSummaries,
    joinedFilteredFundSummaries,
    joinedFundSummaries,
    getFundListWithTotals,
    visualizationsEnabled,
    fundEntityTypeList,
    viewState,
    setViewState,
    selectedCurrencyTab,
    setSelectedCurrencyTab,
  } = useFundList();

  const history = useHistory();

  const getDataGrid = (fund: FundSummary, index: number) => {
    const currencySpecificFundIds: string[] = fund.items.map((f) => f.id);
    const currencySpecificSelectionModel = fundSelectionModel.filter((id) =>
      currencySpecificFundIds.includes(id)
    );

    return (
      <StyledDataGrid
        key={`fundlist_data_grid_${index}`}
        id={`fundlist_data_grid_${index}`}
        dataList={getFundListWithTotals(fund).items}
        headerList={headerList}
        minHeight="1vh"
        selectionModel={currencySpecificSelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={(selectionModel: string[]) => {
          const updatedFundSelectionModel = new Set(fundSelectionModel);

          selectionModel.forEach((id) => updatedFundSelectionModel.add(id));
          currencySpecificFundIds.forEach((id) => {
            if (!selectionModel.includes(id)) {
              updatedFundSelectionModel.delete(id);
            }
          });
          setFundSelectionModel(Array.from(updatedFundSelectionModel));

          const currentFund: FundSummaryItem | undefined = fund.items.find(
            (item) => selectionModel.includes(item.id)
          );

          setCurrentSelectedFund(currentFund);
        }}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
        isRowSelectable={(params) => {
          return params.row.name === 'Total' ? false : true;
        }}
      />
    );
  };

  return (
    <FundListWrapper id="fundlist_main" role="main">
      <ProgressModal id="modal_fundlist_loading" showProgress={isLoading} />
      <FundListHeaderRow>
        <HeaderSubComponent>
          <Typography variant="pageTitle">Funds</Typography>
          {visualizationsEnabled && (
            <ViewOptionsTabs
              viewOptions={[
                ViewOption.DASHBOARD,
                ViewOption.HYBRID,
                ViewOption.DATA_VIEW,
              ]}
              viewState={viewState}
              setViewState={setViewState}
            />
          )}
        </HeaderSubComponent>
        <HeaderSubComponent>
          <SearchBar
            id="funds_search"
            size="small"
            onChange={onSearch}
            options={searchOptions}
            searchText={search}
            showPopover={showSuggestionPopover}
            setShowPopover={setShowSuggestionPopover}
          />
        </HeaderSubComponent>
        <HeaderSubComponent gap={1}>
          <MultiSelectFilters
            size="small"
            id={'account_filter'}
            value={selectedFundIDs}
            onFilter={(_filter: any, fundIDs: string[]) => {
              setSelectedFundIDs(fundIDs);
            }}
            label={`Fund Filter`}
            listData={filterSelectorFundSummaries as []}
            filterName={'account_filter'}
            emptySelectionOnClear={false}
            buttonSx={FilterBorderColor}
          />
          <SplitButton
            id={'btn_bulk_action_options'}
            text="Actions"
            showSelectedOptionIcon={true}
            options={bulkActions}
            hidden={false}
            handleOptionClick={handleBulkActions}
            ariaLabelMessage="Select bulk action option"
            noGutter={true}
          />
          {!readonly && (
            <ButtonWithOptions
              buttonID="btn_add_fund"
              popoverID="popover_add_fund"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewFundOptions}
            />
          )}
        </HeaderSubComponent>
      </FundListHeaderRow>
      <FundListDataWrapper>
        <FundListDataBox>
          {visualizationsEnabled &&
          filteredFundSummaries.length > 0 &&
          filteredFundSummaries[0].items.length > 0 ? (
            <VisualDashboard
              pageType={VisualDashboardType.Fund}
              pageSummary={filteredFundSummaries}
              allItemsSelected={
                joinedFilteredFundSummaries.length ===
                joinedFundSummaries.length
              }
              fundEntityTypeList={fundEntityTypeList}
              selectedCurrencyTab={selectedCurrencyTab}
              setSelectedCurrencyTab={setSelectedCurrencyTab}
              viewState={viewState}
              getDataGrid={getDataGrid}
            />
          ) : (
            <>
              {!isLoading &&
                filteredFundSummaries?.length > 1 &&
                filteredFundSummaries.map((fund, index) => {
                  return (
                    fund.items &&
                    fund.items.length > 0 &&
                    (fund.currencyCode === selectedCurrencyTab ||
                      selectedCurrencyTab === 'ALL' ||
                      viewState === ViewOption.DATA_VIEW) && (
                      <Accordion defaultExpanded>
                        <StickyAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          id={`{${fund.currencyCode}_currency_accordion_header}`}
                        >
                          <Typography>{`${fund.currencyCode}`}</Typography>
                        </StickyAccordionSummary>
                        <AccordionDetails>
                          {getDataGrid(fund, index)}
                        </AccordionDetails>
                      </Accordion>
                    )
                  );
                })}
              {!isLoading && filteredFundSummaries?.length === 1 && (
                <Box display={'grid'}>
                  {getDataGrid(filteredFundSummaries[0], 0)}
                </Box>
              )}
            </>
          )}
        </FundListDataBox>
      </FundListDataWrapper>

      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="upload_confirmation"
        actions={[
          {
            label: 'Upload',
            onClick: handleUploadTemplate,
            id: 'upload_button',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: clearUploadedFile,
            id: 'cancel_button',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{' '}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="upload-complete-confirmation"
        actions={[
          {
            label: 'Upload History',
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: 'upload_history_button',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Close',
            onClick: clearUploadCompleted,
            id: 'close_button',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the import."
        title="Upload Results"
      />
      <DeleteConfirmationDialog
        open={showDeletePrompt}
        onClose={() => {}}
        id="delete_confirmation"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        content={
          <Box component="span">
            This action will remove this entity and everything connected to it.
            This action is permanent and cannot be undone.
          </Box>
        }
        title="Are you sure you want to delete?"
        actionType={`DELETE ${selectedFund?.name}`}
      />
    </FundListWrapper>
  );
};
