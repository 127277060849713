import {
  CapAccReportExcelExportParams,
  CapAccReportPdfExportParams,
  CapitalAccountsParams,
  FileDownloadTransHistoryParams,
  Period,
  PublishFund,
  SelectedFund,
  SelectedTransaction,
  StatementReportParams,
  StatementReportResponse,
  SummaryDataExportParams,
  TransactionHistoryParams,
  TransactionHistoryResponse
} from "../utils/types/capitalAccounts.type";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const getCapitalAccounts = async (params: CapitalAccountsParams) => await apiClient().post("capital-accounts", params);

export const getColumnsNames = async () => await apiClient().get<string[]>('transactionBuckets/names');

export const getQuarters = async (published: string) => await apiClient().get<Period[]>(`quarters/filters?published=${published}`);

export const getStatementReport =
  async (params: StatementReportParams) =>
    await apiClient().get<StatementReportResponse>(
      `capital-accounts/pcap?fundId=${params.fundId}&investorId=${params.investorId}&quarterName=${params.quarter}`
    );

export const getTransactionHistory =
  async (params: TransactionHistoryParams) =>
    await apiClient().get<TransactionHistoryResponse>(
      `transactions/${params.transactionCode}/funds/${params.fundId}/investors/${params.investorId}?quarterName=${params.quarter}`
    );

export const getTransactionHistoryForStatementReport = 
  async ({ 
    additionalReportElements, 
    fundId, 
    investorId, 
    label, 
    quarter, 
    reportColumnType, 
    reportElementType, 
    transactionTypes 
  }: SelectedTransaction) => 
    await apiClient().post<TransactionHistoryResponse>(
      'capital-accounts/transactions/history', 
      { 
        additionalReportElements, 
        fundId, 
        investorId, 
        label, 
        quarter, 
        reportColumnType, 
        reportElementType, 
        transactionTypes 
      }
    );

export const downloadTransactionHistoryExcel =
  async (params: FileDownloadTransHistoryParams) => await apiClient().post(`downloads`, params);

export const downloadTransactionHistoryPdf =
  async (params: FileDownloadTransHistoryParams) =>
    await apiClient().post(
      `clients/funds/${params.fundId}/investors/${params.investorId}/bucket/${params.transactionCode}/transactionReport`,
      params,
      {
        responseType: 'blob'
      }
    );

export const downloadStatementReportPdf = 
  async ({ fundId, investorId, quarter }: SelectedFund) =>
    await apiClient().get(
      `clients/funds/${fundId}/investors/${investorId}/pcap?quarterName=${quarter}`,
      {
        responseType: 'blob'
      }
    );

export const downloadSummaryData = 
  async (params: SummaryDataExportParams) => 
    await apiClient().post('capital-accounts/details', params);

export const downloadCapAccReportExcel = 
  async (params: CapAccReportExcelExportParams) => 
    await apiClient().post('capital-accounts/capAccountReportExcel', params);

export const downloadAllCapAccReportPdf = 
  async (params: CapAccReportPdfExportParams) => 
    await apiClient().post('clients/allReports', params);

export const downloadSelectedCapAccReportPdf = 
  async (params: CapAccReportPdfExportParams) => 
    await apiClient().post('clients/allReports', params, { responseType: 'blob' });

export const getAllFunds = async (clientId: string) => 
  await apiClient().get<PublishFund[]>(`funds/client/${clientId}`);

export const getAllQuartersForFund = async (fundId: string) => 
  await apiClient().get(`funds/${fundId}/quarters`);

export const patchPublishQuarterForFund = 
  async (fundId: string, quarterId: string, publish: boolean) =>
    await apiClient().patch(`funds/${fundId}/quarters/${quarterId}`, { publish });

export const downloadContactAccountReport =
    async (report_id: string) =>
      await apiClient().get(
        `downloads/${report_id}`,
        {
          responseType: 'blob'
        }
      );
