import {
  GridCellParams,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useNavBarContext } from '../../../core/context/navbarContext';
import RoutingPaths from '../../../core/routing/routingPaths';
import { getPortalLink } from '../../../services/dashboard.service';
import {
  getBaseUrl,
  getFileDetails,
} from '../../../services/documents.service';
import { getHostname } from '../../../utils/helpers/misc.helper';
import useSessionStorage from '../../../utils/hooks/useSessionStorage';
import {
  DocControlPermissions,
  DocumentFilter,
  Documents,
  FolderContentRequestPayload,
  PublishStatusEnum,
} from '../../../utils/types/documents.type';
import { FilterSelectionType, Option } from '../../../utils/types/filter.type';
import { ViewType } from '../../generalMailings/mailingsComposerPanel/MailingsComposerPanel.constants';
import {
  DOWNLOAD_ERROR,
  DOWNLOAD_SUCCESS,
  FILE_MOVE_ERROR,
  FOLDER_CREATE_ERROR,
  FOLDER_DELETE_ERROR,
  FOLDER_EDIT_ERROR,
  FOLDER_RENAME_ERROR,
  LINKED_FILE_ERROR,
  LOCKED_PAGE_TOOLTIP_TEXT,
} from '../constants/index';
import { SelectedDocumentVariant } from '../types';
import { defaultPageSize } from './useDocuments.hooks';

type Steps =
  | 'file-rename'
  | 'folder-name'
  | 'assign-permissions'
  | 'create-folder'
  | 'update-permissions'
  | 'edit-root-folder'
  | 'add-edit-subFolder'
  | null;
type Permission = { id: string; name: string };
type File = {
  id: string;
  type: 'folder' | 'file';
  published?: boolean;
  name?: string;
  fileType?: string;
  filePermissions: DocControlPermissions;
  missingInvestorOrFunds?: 'yes' | 'no';
};
type ContextMenu = any;
type NextFolderProps = {
  documents: any;
  cursor: Array<number>;
};
type Error = {
  title: string;
  subTitle: string;
};

function getCurrentFolder(
  document: Documents,
  cursor: Array<string>
): Documents {
  if (!document || !document?.folders) return document;
  if (cursor && cursor.length > 0) {
    return getCurrentFolder(
      document.folders?.find((folder) => folder.id === cursor[0]) || document,
      cursor.slice(1)
    );
  }
  return document;
}

function getNextFolderIndex({ documents, cursor }: NextFolderProps) {
  const mainDocument = documents ?? null;

  if (mainDocument) {
    let currentFolderRoot = mainDocument?.folders;

    for (const index of cursor) {
      currentFolderRoot = currentFolderRoot?.find(
        (folder: any) => folder.id === index
      )?.folders;
    }
    return currentFolderRoot?.length ?? 0;
  }
  return 0;
}

type DocumentHelperProps = {
  state: Record<string, any>;
  actions: any;
  isEditable: boolean;
  isAdmin: boolean;
  informationAlert: Function;
  clientName: string;
};

export const useDocumentsHelper = ({
  state,
  actions,
  isEditable,
  isAdmin,
  informationAlert,
  clientName,
}: DocumentHelperProps) => {
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    useState<boolean>(false);
  const [showFolderDialog, setShowFolderDialog] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<Steps>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [lockedTooltipText, setLockedTooltipText] = useState<string>('');
  const [folderName, setFolderName] = useState<string>('');
  const [folderEdits, setFolderEdits] = useState<any>({});
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const [selectedRowData, setSelectedRowData] = useState<Record<
    string,
    any
  > | null>(null);
  const [contextMenu, setContextMenu] = useState<ContextMenu>(null);
  const [contextMenuAnchor, setContextMenuAnchor] = useState<ContextMenu>(null);
  const [showAddOptions, setShowAddOptions] = useState<boolean>(false);
  const [addNewAnchorEl, setAddNewAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false);
  const [newFiles, setNewFiles] = useState<Array<string>>([]);

  const isRootView = state.cursor.length === 0;
  const [editing, setEditing] = useState<boolean>(false);
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [showMovePanel, setShowMovePanel] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<
    string | null | undefined
  >(null);
  const [contextMenuActions, setContextMenuActions] = useState<boolean>(false);
  const [contextMenuMoveOptions, setContextMenuMoveOptions] =
    useState<string>('both');
  const [error, setError] = useState<Error | null>(null);
  const [showConsent, setShowConsent] = useState<boolean>(false);
  const [consentFolder, setConsentFolder] = useState<string>();

  const [fileName, setFileName] = useState<string>();
  const [showRenameFileDialog, setShowRenameFileDialog] =
    useState<boolean>(false);
  const [renameLoading, setRenameLoading] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [showDeleteOverflowPrompt, setShowDeleteOverflowPrompt] =
    useState<boolean>(false);
  const [showFolderDlPrompt, setShowFolderDlPrompt] = useState<boolean>(false);
  const [showBulkFileDlPrompt, setShowBulkFileDlPrompt] =
    useState<boolean>(false);
  const [downloadableFolderIds, setDownloadableFolderIds] = useState<string[]>(
    []
  );
  const [downloadableFileIds, setDownloadableFileIds] = useState<string[]>([]);
  const [currentlyDownloadingIds, setCurrentlyDownloadingIds] = useState<
    string[]
  >([]);
  const [openAdobeViewer, setOpenAdobeViewer] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const [pdfFile, setPdfFile] = useState<{
    pdfBlob: Blob;
    fileData: any;
  }>();

  const [headerAndLockbarHeight, setHeaderAndLockbarHeight] =
    useState<number>(100);

  const INCLUDE_ARCHIVED_SESSION_DEFAULT: boolean = false;

  const [includeArchivedSession, setIncludeArchivedSession] = useSessionStorage(
    'includeArkArchivedFilesSessionStorage',
    INCLUDE_ARCHIVED_SESSION_DEFAULT
  );

  const docHeaderRef = useRef<HTMLDivElement>(null);
  const { isNavBarOpen } = useNavBarContext();

  const history = useHistory();
  const clientId = state.documents?.clientId;

  useEffect(() => {
    actions.toggleReadOnly(showEditPanel);
  }, [showEditPanel]);

  const reloadCurrentFolder = (pagination?: any) => {
    const filters = { ...(state?.selectedFilters || {}) };

    if (
      state.selectedFilters['funds'].length ===
      state.filtersData['funds'].length
    ) {
      filters.funds = [];
    }
    if (
      state.selectedFilters['investors'].length ===
      state.filtersData['investors'].length
    ) {
      filters.investors = [];
    }
    if (
      state.selectedFilters['quarters'].length ===
      state.filtersData['quarters'].length
    ) {
      filters.quarters = [];
    }
    filters.includeArchived = state.selectedFilters.includeArchived;

    const payload: FolderContentRequestPayload = {
      ...filters,
      ...(pagination || state.pagination),
      page: 1,
      pageSize: defaultPageSize,
      sort: state.sort,
    };

    if (state?.currentFolder?.id) {
      actions.fetchFolderContent(state?.currentFolder?.id, payload, false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (docHeaderRef.current) {
        const sourceHeight =
          docHeaderRef.current.clientHeight + 17 + (isNavBarOpen ? 67 : 57);

        setHeaderAndLockbarHeight(sourceHeight);
      }
    };

    handleResize();
    setTimeout(handleResize, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    if (docHeaderRef.current) {
      resizeObserver.observe(docHeaderRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [isNavBarOpen, showEditPanel]);

  const openDocument = (id: string) => {
    const payload: FolderContentRequestPayload = {
      ...state.pagination,
      funds: [],
      investors: [],
      quarters: [],
      page: 1,
      sort: state.sort,
      includeArchived: state.selectedFilters.includeArchived,
    };

    if (id) {
      actions.fetchFolderContent(id, payload, false);
      actions.fetchDocumentTree();
    } else {
      actions.fetchDocuments({ addNone: isEditable, isAdmin });
    }
  };

  const onDeleteConfirm = (): void => {
    const documents = [...selectedFiles];
    let folders: Array<string> | string = [];
    let files: Array<string> | string = [];

    for (const document of documents) {
      if (document.type === 'folder') folders.push(document.id);
      else if (document.type === 'file') files.push(document.id);
    }

    if (folders.length && files.length) {
      files = files.join(',');
      folders = folders.join(',');
      actions
        .deleteFilesAndFolders(folders, files)
        .then(() => {
          actions.fetchDocumentTree();
          reloadCurrentFolder();
          setShowDeleteConfirmationBox(false);
          setSelectedFiles([]);
          setSelectedRowData(null);
          setContextMenuActions(false);
        })
        .catch(() => informationAlert(FOLDER_DELETE_ERROR, 'error'));
    } else if (folders.length) {
      folders = folders.join(',');
      actions
        .deleteFolder(folders)
        .then(() => {
          actions.fetchDocumentTree();
          state?.cursor?.length
            ? reloadCurrentFolder()
            : actions.initialize(clientId);
          setShowDeleteConfirmationBox(false);
          setSelectedFiles([]);
          setSelectedRowData(null);
          setContextMenuActions(false);
        })
        .catch(() => informationAlert(FOLDER_DELETE_ERROR, 'error'));
    } else if (files.length) {
      files = files.join(',');
      actions.deleteFiles(files).then(() => {
        reloadCurrentFolder();
        setShowDeleteConfirmationBox(false);
        setSelectedFiles([]);
        setSelectedRowData(null);
        setContextMenuActions(false);
      });
    }
  };

  const onDeleteCancel = (): void => {
    setShowDeleteConfirmationBox(false);
    setContextMenuActions(false);
  };

  const handleMoveFiles = (): void => {
    const files: Array<File> = selectedFiles.filter(
      (file: File) => file.type === 'file'
    );

    const fileIds: Array<string> = files.map((file: File) => file.id);

    const fileIdString: string = fileIds.join(',');

    if (state.currentFolder.id && selectedFolder && fileIdString) {
      actions
        .moveFiles(fileIdString, selectedFolder)
        .then(() => {
          setShowMovePanel(false);
          setSelectedFiles([]);
          setSelectedFolder(null);
          reloadCurrentFolder();
        })
        .catch(() => {
          actions.removeLoading();
          informationAlert(FILE_MOVE_ERROR);
        });
    }
  };

  const handleFolderSelection = (id?: string): void => {
    setSelectedFolder(id);
  };

  const handleShowArchivedToggle = (value: boolean) => {
    setIncludeArchivedSession(value);
    handleFilter(DocumentFilter.Archive, value);
  };

  const handleFilter = (
    filterName: DocumentFilter,
    selected: Option[] | string[] | string | boolean,
    selectionType?: FilterSelectionType
  ) => {
    if (
      selectionType === 'All' &&
      filterName !== 'names' &&
      filterName !== 'permissions'
    ) {
      selected = [];
    } else {
      selected = Array.isArray(selected)
        ? selected.map((item: any) => item?.id || item)
        : selected;
    }
    const currentFolder = getCurrentFolder(state.documents, state.cursor);

    if (isRootView && filterName === DocumentFilter.Permission) {
      return actions.applyPermissionFilter(selected);
    }

    if (currentFolder.id) {
      if (filterName === DocumentFilter.Name) {
        return actions.applyNameFilter(selected);
      } else if (filterName === DocumentFilter.Folder) {
        return actions.toggleFolders(selected);
      } else if (filterName === DocumentFilter.Archive) {
        actions.toggleArchived(selected);
      }

      let filtersHavingAllSelections = [];

      if (
        state.selectedFilters['funds'].length ===
        state.filtersData['funds'].length
      ) {
        filtersHavingAllSelections.push('funds');
      }
      if (
        state.selectedFilters['investors'].length ===
        state.filtersData['investors'].length
      ) {
        filtersHavingAllSelections.push('investors');
      }
      if (
        state.selectedFilters['quarters'].length ===
        state.filtersData['quarters'].length
      ) {
        filtersHavingAllSelections.push('quarters');
      }

      if (selectionType === 'Partial') {
        filtersHavingAllSelections = filtersHavingAllSelections.filter(
          (item) => item !== filterName
        );
      }

      const params: FolderContentRequestPayload = {
        ...state.selectedFilters,
        isLastPage: false,
        page: 1,
        pageSize: defaultPageSize,
        [filterName]: selected,
        sort: state.sort,
      };

      actions.applyFilters(
        clientId,
        currentFolder.id,
        filterName,
        params,
        false,
        isAdmin,
        filtersHavingAllSelections
      );
    }
  };

  const handleSort = (sortModel: GridSortModel): void => {
    const currentFolder = getCurrentFolder(state.documents, state.cursor);

    if (!state.cursor?.length) return;
    if (currentFolder.id) {
      const applicableSorts = [];

      for (const model of sortModel) {
        const { field, sort } = model;
        let sortName = field.toUpperCase();

        if (field === 'name') {
          sortName = 'FILENAME';
        }
        switch (sort) {
          case 'asc':
            sortName += '_ASC';

            applicableSorts.push(sortName);
            break;
          case 'desc':
            sortName += '_DESC';

            applicableSorts.push(sortName);
            break;
          default:
        }
      }

      const params: FolderContentRequestPayload = {
        ...{
          funds:
            state.selectedFilters.funds.length ===
            state.filtersData.funds.length
              ? []
              : state.selectedFilters.funds,
          investors:
            state.selectedFilters.investors.length ===
            state.filtersData.investors.length
              ? []
              : state.selectedFilters.investors,
          quarters:
            state.selectedFilters.quarters.length ===
            state.filtersData.quarters.length
              ? []
              : state.selectedFilters.quarters,
          includeArchived: state.selectedFilters.includeArchived,
        },
        ...state.pagination,
        page: 1,
        pageSize: state.pagination.pageSize,
        sort: applicableSorts,
      };

      actions.applyFilters(
        clientId,
        currentFolder.id,
        'sort',
        params,
        true,
        isAdmin
      );
    }
  };

  const handleSelectionModelChange = (
    selectionModel: GridSelectionModel,
    dataList: Array<any>
  ): void => {
    const rowIds: string[] = selectionModel as string[];
    const selectedRowSet = new Set<string>(rowIds);
    const selectedDocumentVariantSet = new Set<SelectedDocumentVariant>();
    const copySelectedDocs: Array<File> = [];
    let consentBasedFolder;

    dataList.forEach((row, index) => {
      if (selectedRowSet.has(row.id)) {
        switch (row.type) {
          case 'folder':
            if (row.requiresConsent) {
              consentBasedFolder = row.id;
            }
            if (
              selectedDocumentVariantSet.has(SelectedDocumentVariant.FOLDER)
            ) {
              selectedDocumentVariantSet.delete(SelectedDocumentVariant.FOLDER);
              selectedDocumentVariantSet.add(SelectedDocumentVariant.FOLDERS);
            } else {
              selectedDocumentVariantSet.delete(
                SelectedDocumentVariant.FOLDERS
              );
              selectedDocumentVariantSet.add(SelectedDocumentVariant.FOLDER);
            }
            break;
          case 'file':
            if (row.published) {
              if (
                selectedDocumentVariantSet.has(
                  SelectedDocumentVariant.PUBLISHED_FILE
                )
              ) {
                selectedDocumentVariantSet.delete(
                  SelectedDocumentVariant.PUBLISHED_FILE
                );
                selectedDocumentVariantSet.add(
                  SelectedDocumentVariant.PUBLISHED_FILES
                );
              } else {
                selectedDocumentVariantSet.delete(
                  SelectedDocumentVariant.PUBLISHED_FILES
                );
                selectedDocumentVariantSet.add(
                  SelectedDocumentVariant.PUBLISHED_FILE
                );
              }
            } else {
              if (
                selectedDocumentVariantSet.has(
                  SelectedDocumentVariant.UNPUBLISHED_FILE
                )
              ) {
                selectedDocumentVariantSet.delete(
                  SelectedDocumentVariant.UNPUBLISHED_FILE
                );
                selectedDocumentVariantSet.add(
                  SelectedDocumentVariant.UNPUBLISHED_FILES
                );
              } else {
                selectedDocumentVariantSet.delete(
                  SelectedDocumentVariant.UNPUBLISHED_FILES
                );
                selectedDocumentVariantSet.add(
                  SelectedDocumentVariant.UNPUBLISHED_FILE
                );
              }
            }
            break;
        }
        copySelectedDocs.push({
          id: row.id,
          type: row.type,
          fileType: row.fileType,
          published: row.published,
          name: row.name,
          filePermissions: row.filePermissions,
          missingInvestorOrFunds:
            isEmpty(row.investors) || isEmpty(row.funds) ? 'yes' : 'no',
        });
      }
    });

    const variants: SelectedDocumentVariant[] = Array.from(
      selectedDocumentVariantSet
    );

    actions.updateRowsSelection(rowIds, variants);

    setSelectedFiles(copySelectedDocs);

    if (!isAdmin && consentBasedFolder) {
      setShowConsent(true);
      setConsentFolder(consentBasedFolder);
    }
  };

  const handleDownloadAndFeedback = async (
    folderIds: string[],
    fileIds: string[],
    fileName: string = '',
    fileType: string = ''
  ) => {
    setCurrentlyDownloadingIds(folderIds.concat(fileIds));

    await actions
      .downloadFiles(folderIds.join(','), fileIds.join(','), fileName, fileType)
      .then(() => {
        informationAlert(DOWNLOAD_SUCCESS);
      })
      .catch(() => {
        informationAlert(DOWNLOAD_ERROR, 'error');
      })
      .finally(() => {
        setCurrentlyDownloadingIds([]);
      });
  };

  const handleOnActionItemClick = (item: string): void => {
    const allowedActionsOnPageLock = new Set([
      'download_selected_folders',
      'download_folder',
      'download_selected_files',
      'mail_selected_files',
    ]);

    if (state.isPageLocked && !allowedActionsOnPageLock.has(item)) {
      showLockedTooltip();
      return;
    }
    const filteredFiles: Array<File> = selectedFiles.filter(
      (file: File) => file.type === 'file'
    );

    const havePublishedAndUnpublishedFiles: boolean =
      filteredFiles.findIndex((item) => item.published === true) !== -1 &&
      filteredFiles.findIndex((item) => item.published === false) !== -1;

    const fileIds: Array<string> = filteredFiles.map((file: File) => file.id);

    const singleFile =
      fileIds.length === 1
        ? filteredFiles.find((item) => item.id === fileIds[0])
        : null;
    const fileName = singleFile?.name ?? '';
    const fileType = singleFile?.fileType ?? '';
    const haveMissingInvestorOrFund = filteredFiles.find(
      (item) => item.missingInvestorOrFunds === 'yes'
    )
      ? true
      : false;

    const files: string = fileIds.join(',');
    const folderIds: string[] = selectedFiles
      .filter((file: File) => file.type === 'folder')
      .map((file: File) => file.id);

    const folders: string = folderIds.join(',');

    const baseURL = getHostname(getBaseUrl());

    if (!state?.documents?.clientId) return;

    switch (item) {
      case 'download_selected_folders':
      case 'download_folder':
        setDownloadableFolderIds(folderIds);
        if (!isAdmin) {
          setShowFolderDlPrompt(true);
        } else {
          handleDownloadAndFeedback(folderIds, []);
        }
        break;
      case 'download_selected_files':
        const downloadableFiles = filteredFiles.filter(
          (file) =>
            file.fileType !== 'pdf' || file.filePermissions.downloadEnabled
        );

        if (!isAdmin && downloadableFiles.length !== filteredFiles.length) {
          const fileIds: string[] = downloadableFiles.map((file) => file.id);

          setDownloadableFileIds(fileIds);
          setShowBulkFileDlPrompt(true);
        } else {
          handleDownloadAndFeedback([], fileIds, fileName, fileType);
        }
        break;
      case 'delete_selected':
        setShowDeleteConfirmationBox(true);
        break;
      case 'edit_selected_files':
        setShowEditPanel(true);
        break;
      case 'delete_selected_files':
        if (selectedFiles.length > 150) {
          setShowDeleteOverflowPrompt(true);
          return;
        }
        if (selectedFiles.length > 0 || folders) {
          setShowDeleteConfirmationBox(true);
        } else {
          onDeleteConfirm();
        }
        break;
      case 'move_selected_files':
        if (folders) return;
        if (havePublishedAndUnpublishedFiles)
          return setError({
            title: 'Unable to move Files',
            subTitle:
              'Published and unpublished files cannot be moved together.',
          });
        setShowMovePanel(true);
        break;
      case 'publish_selected_files':
        if (folders || !state?.currentFolder?.id) return;
        if (haveMissingInvestorOrFund) setShowPrompt(true);
        else {
          actions
            .publishFiles(PublishStatusEnum.Publish, {
              fileIds,
              folderId: state.currentFolder.id,
            })
            .then(() => {
              setSelectedFiles([]);
              setSelectedRowData(null);
              setShowPrompt(false);
              reloadCurrentFolder();
            });
        }
        break;
      case 'unpublish_selected_files':
        if (folders || !state?.currentFolder?.id) return;

        setShowPrompt(false);
        actions
          .publishFiles(PublishStatusEnum.Unpublish, {
            fileIds,
            folderId: state.currentFolder.id,
          })
          .then(() => {
            setSelectedFiles([]);
            setSelectedRowData(null);
            reloadCurrentFolder();
          });
        break;
      case 'download_selected_files_links':
        if (folders || !state?.currentFolder?.id) return;

        getPortalLink(state.documents.clientId)
          .then((portalLink: string) => {
            actions
              .downloadDocLinks({
                ids: fileIds,
                type: 'DOCUMENT_LINK',
                baseDocumentUrl: `${portalLink}/portal/documents/`,
                clientName,
              })
              .then(() => {
                setSelectedFiles([]);
                setSelectedRowData(null);
                informationAlert(DOWNLOAD_SUCCESS);
              })
              .catch(() => informationAlert(DOWNLOAD_ERROR, 'error'));
          })
          .catch(() => informationAlert(DOWNLOAD_ERROR, 'error'));
        break;
      case 'mail_selected_files':
        if (selectedFiles.some((file: any) => file.published === false)) {
          informationAlert(
            'Deselect unpublished document(s) prior to mailing.',
            'error'
          );
          break;
        }
        history.push(`/general-mailings?view=${ViewType.Documents}`);
        sessionStorage.setItem('selectedDocs', JSON.stringify(selectedFiles));
        sessionStorage.setItem('documentsMailingOpen', 'true');
        break;
    }
  };

  const handleWarningPrompt = (): void => {
    setShowPrompt(false);
    const filteredFiles: Array<File> = selectedFiles.filter(
      (file: File) => file.type === 'file'
    );
    const fileIds: Array<string> = filteredFiles.map((file: File) => file.id);

    actions
      .publishFiles(PublishStatusEnum.Publish, {
        fileIds,
        folderId: state.currentFolder.id,
      })
      .then(() => {
        setSelectedFiles([]);
        setSelectedRowData(null);
        setShowPrompt(false);
        reloadCurrentFolder();
      });
  };

  const handleLpBulkFileDownload = (): void => {
    setShowBulkFileDlPrompt(false);
    if (!downloadableFileIds.length) return;
    handleDownloadAndFeedback([], downloadableFileIds);
  };

  const handleLpFolderDownload = (): void => {
    setShowFolderDlPrompt(false);
    handleDownloadAndFeedback(downloadableFolderIds, []);
  };

  const handleDeleteOverflowPrompt = (): void => {
    setShowDeleteOverflowPrompt(false);
  };

  const handleContextMenu = (e: React.MouseEvent): void => {
    e && e.preventDefault();
    const fieldname = e.currentTarget.getAttribute('data-field');
    const id = e.currentTarget.parentElement?.getAttribute('data-id');

    let option = 'both';
    let rowIndex = -1;
    let length = 0;
    let isFile = false;

    if (isRootView) {
      rowIndex = state.documents.folders.findIndex(
        (folder: any) => folder.id === id
      );
      length = state.documents.folders.length;
    } else {
      rowIndex = state.currentFolder.folders.findIndex(
        (folder: any) => folder.id === id
      );
      if (rowIndex === -1) {
        rowIndex = state.currentFolder.unPublishedFiles.files.findIndex(
          (file: any) => file.id === id
        );
        isFile = true;
      }
      if (rowIndex === -1) {
        rowIndex = state.currentFolder.publishedFiles.files.findIndex(
          (folder: any) => folder.id === id
        );
        isFile = true;
      }
      length = state.currentFolder.folders.length;
    }

    if (rowIndex === -1) {
      return;
    }

    if (rowIndex === 0) {
      option = 'move_down';
    }
    if (length === 0) {
      option = 'none';
    } else if (length > 1 && rowIndex === length - 1) {
      option = 'move_up';
    }

    option = isFile ? 'only_rename' : option;

    setContextMenuMoveOptions(option);

    if (fieldname === 'name') {
      setContextMenu(contextMenu === null ? e.currentTarget : null);
      const selection = window.getSelection();
      let anchorEl = null;

      if (contextMenu === null && selection?.getRangeAt) {
        anchorEl = {
          x: e.pageX,
          y: e.pageY,
        };
      }
      setContextMenuAnchor(anchorEl);
    } else {
      setContextMenu(null);
      setContextMenuAnchor(null);
    }
  };

  const handleOnContextMenuClose = (): void => {
    setContextMenu(null);
    setContextMenuAnchor(null);
  };

  const moveFoldersUpORDown = (
    current_folder_id: string,
    folders: Array<Documents>,
    isDown: boolean
  ) => {
    const newFolderArray = [...folders];
    const length = folders.length;
    const itemIndex = folders.findIndex(
      (folder: Documents) => folder.id === current_folder_id
    );

    if (length === 1) {
      return folders.map((folder, index) => ({ ...folder, index }));
    }
    const swapIndex = itemIndex - (isDown ? -1 : 1);

    newFolderArray[swapIndex] = folders[itemIndex];
    newFolderArray[itemIndex] = folders[swapIndex];

    return newFolderArray.map((item, index) => ({
      id: item.id,
      index,
      name: item.name,
    }));
  };

  const handleContextMenuOption = (e: React.MouseEvent, type: string): void => {
    e.preventDefault();
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }

    const id = contextMenu.parentElement?.getAttribute('data-id');

    const selectedDocument: Documents = isRootView
      ? state.documents
      : state.currentFolder;
    const documentFolders = selectedDocument?.folders || [];

    const folder: Documents | undefined | null = documentFolders.find(
      (folder: Documents) => folder.id === id
    );
    const file: any =
      (!folder &&
        selectedDocument?.publishedFiles?.files?.find(
          (file) => file.id === id
        )) ||
      selectedDocument?.unPublishedFiles?.files?.find((file) => file.id === id);

    if (!folder && !file) {
      handleOnContextMenuClose();
      return;
    }

    const name = folder ? folder.name ?? '' : file.name ?? '';
    const permissions =
      folder?.permissions?.map((item) => ({ ...item, id: item.key })) || [];

    setContextMenuActions(true);
    let folders;

    switch (type) {
      case 'edit':
        if (file) {
          setCurrentStep('file-rename');
          setFileName(name);
          setShowRenameFileDialog(true);
          break;
        }
        setCurrentStep('folder-name');
        setShowFolderDialog(true);
        setFolderName(name);
        setFolderEdits(folder);
        setSelectedPermissions(permissions);
        setEditing(true);
        break;
      case 'move_down':
      case 'move_up':
        if (folder?.id) {
          folders = moveFoldersUpORDown(
            folder.id,
            documentFolders,
            type === 'move_down'
          );
          actions
            .changeFolderIndex({
              ...selectedDocument,
              folders,
              publishedFiles: null,
              unPublishedFiles: null,
            })
            .then(() => {
              setTimeout(() => setContextMenuActions(false), 2000);
            })
            .catch(() => {
              setTimeout(() => setContextMenuActions(false), 2000);
            });
        }
        break;
      case 'delete':
        setSelectedFiles(
          folder ? [{ ...folder, type: 'folder' }] : [{ ...file, type: 'file' }]
        );
        setShowDeleteConfirmationBox(true);
        break;
      default:
        setContextMenuActions(false);
    }
    setTimeout(() => contextMenu.click(), 1000);
    handleOnContextMenuClose();
  };

  const addNewFiles = (fileIds: string[]) => {
    setNewFiles(fileIds);
  };

  const onNextPage = async () => {
    let totalPublishedFiles = 0;
    let totalUnpublishedFiles = 0;
    let totalFiles = 0;
    let totalFolders = 0;
    let currentItems = 0;

    if (state?.currentFolder) {
      totalPublishedFiles =
        state.currentFolder?.publishedFiles?.totalItems || 0;
      totalUnpublishedFiles =
        state.currentFolder?.unPublishedFiles?.totalItems || 0;
      totalFiles = totalPublishedFiles + totalUnpublishedFiles;
      totalFolders = state.currentFolder?.folders?.length || 0;
      currentItems =
        totalFolders +
        (state.currentFolder?.publishedFiles?.files?.length || 0) +
        (state.currentFolder?.unPublishedFiles?.files?.length || 0);
    }

    if (
      state.isLoading ||
      state.cursor.length === 0 ||
      state.pagination.isLastPage ||
      totalFiles === 0 ||
      (state.selectedNameFilter.length > 0 &&
        state.selectedNameFilter.length < currentItems) ||
      (totalPublishedFiles <
        (state.pagination.pageSize || 0) * (state.pagination.page || 1) &&
        totalUnpublishedFiles <
          (state.pagination.pageSize || 0) * (state.pagination.page || 1))
    ) {
      return;
    }

    const filters = { ...(state?.selectedFilters || {}) };

    if (
      state.selectedFilters['funds'].length ===
      state.filtersData['funds'].length
    ) {
      filters.funds = [];
    }
    if (
      state.selectedFilters['investors'].length ===
      state.filtersData['investors'].length
    ) {
      filters.investors = [];
    }
    if (
      state.selectedFilters['quarters'].length ===
      state.filtersData['quarters'].length
    ) {
      filters.quarters = [];
    }
    filters.includeArchived = state.selectedFilters.includeArchived;

    const payload: FolderContentRequestPayload = {
      ...filters,
      ...state.pagination,
      pageSize: state.pagination.pageSize,
      page: state.pagination.page + 1,
      sort: state.sort,
    };

    await actions.fetchFolderContent(
      state.currentFolder.id,
      payload,
      false,
      true
    );
  };

  const handleOnCellClick = async (params: GridCellParams): Promise<void> => {
    setSelectedRowData(params.row);

    if (state.isReadOnly) return;

    switch (params.field) {
      case 'action':
        if (state.isDownloading) return;
        if (params.row.type === 'folder') {
          setDownloadableFolderIds([params.row.id]);
          if (isAdmin) {
            handleDownloadAndFeedback([params.row.id], []);
          } else {
            setShowFolderDlPrompt(true);
          }
        } else {
          setDownloadableFileIds([params.row.id]);
          if (!isAdmin && !params.row.filePermissions.downloadEnabled) return;
          handleDownloadAndFeedback([], [params.row.id], params.row.name);
        }
        break;
      case 'name': {
        if (currentStep || contextMenuActions) return;
        if (params.row.type === 'file') {
          if (params.row.fileType === 'pdf') {
            setPdfLoading(true);

            try {
              const res = await actions.viewFile(
                '',
                [params.row.id],
                params.row.name
              );

              setOpenAdobeViewer(true);
              setPdfFile({ pdfBlob: res, fileData: params.row });
              setPdfLoading(false);
            } catch (e) {
              setPdfLoading(false);
              informationAlert('Error loading pdf file.', 'error');
            }
            return;
          } else {
            if (state.isDownloading) return;
            setDownloadableFileIds([params.row.id]);
            handleDownloadAndFeedback([], [params.row.id], params.row.name);
            return;
          }
        }
        if (!isAdmin && params.row.requiresConsent) {
          setShowConsent(true);
          setConsentFolder(params.row.id);
          return;
        }
        const filters = {
          funds: [],
          investors: [],
          quarters: [],
          includeArchived: state.selectedFilters.includeArchived,
        };

        const payload: FolderContentRequestPayload = {
          ...filters,
          ...state.pagination,
          searchText: '',
          page: 1,
          sort: state.sort,
        };

        actions.fetchFolderContent(params.row.id, payload);
        setSelectedFiles([]);
        setSelectedRowData(null);
        setContextMenuActions(false);
        break;
      }
      default:
    }
  };

  const viewLinkedDocument = async (linkedFileId: string): Promise<void> => {
    setPdfLoading(true);

    try {
      const fileDetails = await getFileDetails(linkedFileId);

      if (fileDetails.fileType === 'pdf') {
        const res = await actions.viewFile(
          '',
          [fileDetails.fileId],
          fileDetails.name
        );

        setOpenAdobeViewer(true);
        setPdfFile({
          pdfBlob: res,
          fileData: {
            ...fileDetails,
            filePermissions: fileDetails.permissions,
          },
        });
      } else {
        handleDownloadAndFeedback([], [fileDetails.fileId], fileDetails.name);
      }
    } catch (e) {
      informationAlert(LINKED_FILE_ERROR, 'error');
    } finally {
      setPdfLoading(false);
      history.push(RoutingPaths.Documents);
    }
  };

  const showLockedTooltip = (): void => {
    setLockedTooltipText(LOCKED_PAGE_TOOLTIP_TEXT);

    setTimeout(() => {
      setLockedTooltipText('');
    }, 3000);
  };

  const handleAddNewFolderAction = (event: any): void => {
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }
    setShowFolderDialog(true);
    setCurrentStep('folder-name');
  };

  const resetOnClose = (): void => {
    setShowFolderDialog(false);
    setCurrentStep(null);
    setSelectedPermissions([]);
    setFolderName('');
    setFileName('');
    setFolderEdits({});
    setShowRenameFileDialog(false);
    setSelectedRowData(null);
    setEditing(false);
    setShowMovePanel(false);
    setSelectedFolder(null);
    setContextMenuActions(false);
  };

  const mainDocument = state.documents ?? null;
  const mainDocumentId = mainDocument?.id ?? null;

  const handleNextAction = (step: Steps, data: any): void => {
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }
    setCurrentStep(step);
    const permissions = isRootView
      ? selectedPermissions.map((item) => ({ key: item.id, name: item.name }))
      : null;
    let requestBody: any = {
      folders: [],
      hasWatermark: true,
      index: getNextFolderIndex({
        documents: state.documents,
        cursor: state.cursor,
      }),
      loaded: false,
      name: folderName,
      permissions,
      publishedFiles: [],
      unPublishedFiles: [],
      applyToChildren: folderEdits.applyToChildren,
    };

    if (isRootView) {
      setCurrentStep(step);
    }

    switch (step) {
      case 'create-folder':
        requestBody = {
          ...requestBody,
          name: data.folderName,
          archiveIn: data.archiveInMonths as number,
          applyToChildren: data
            ? data.applyToChildren
            : folderEdits.applyToChildren,
        };
        actions
          .createFolder(state.currentFolder?.id ?? mainDocumentId, requestBody)
          .then(() => {
            resetOnClose();
            actions.fetchDocumentTree();
            if (isRootView) {
              actions.initialize(clientId);
            } else {
              reloadCurrentFolder();
              setNewFiles([]);
            }
          })
          .catch(() => informationAlert(FOLDER_CREATE_ERROR, 'error'));
        return;

      case 'add-edit-subFolder':
        requestBody = {
          ...selectedRowData,
          name: data.folderName,
          archiveIn: data.archiveInMonths as number,
          applyToChildren: data.applyToChildren,
        };
        actions
          .updateFolder(requestBody.id, requestBody)
          .then(() => {
            actions.fetchDocumentTree();
            resetOnClose();
            setNewFiles([]);
          })
          .catch(() => informationAlert(FOLDER_RENAME_ERROR, 'error'));
        return;

      case 'edit-root-folder':
        requestBody = {
          ...selectedRowData,
          permissions,
          name: data.folderName ? data.folderName : folderName,
          folders: null,
          publishedFiles: null,
          unPublishedFiles: null,
          archiveIn: data.archiveInMonths as number,
          applyToChildren: folderEdits.applyToChildren,
        };
        actions
          .updateFolder(requestBody.id, requestBody)
          .then(() => {
            resetOnClose();
            setNewFiles([]);
          })
          .catch(() => informationAlert(FOLDER_EDIT_ERROR, 'error'));
        return;
      default:
        return;
    }
  };

  const confirmFileRename = (): void => {
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }

    if (selectedRowData?.id && selectedRowData?.name !== fileName) {
      const requestBody = {
        clientId: selectedRowData.clientId,
        fileType: selectedRowData.fileType,
        sizeInBytes: selectedRowData.sizeInBytes,
        published: selectedRowData.published,
        investorIds: selectedRowData.investors.map((item: any) => item.id),
        fundIds: selectedRowData.funds.map((item: any) => item.id),
        quarter: selectedRowData.quarter,
        index: selectedRowData.index,
        folderId: selectedRowData.folderId,
        name: fileName,
        id: selectedRowData.id,
        taggedForAllInvestors: selectedRowData.taggedForAllInvestors,
      };

      setRenameLoading(true);

      const filters: any = { ...(state?.selectedFilters || {}) };

      if (
        state?.selectedFilters['funds'].length ===
        state?.filtersData['funds'].length
      ) {
        filters.funds = [];
      }
      if (
        state?.selectedFilters['investors'].length ===
        state?.filtersData['investors'].length
      ) {
        filters.investors = [];
      }
      if (
        state?.selectedFilters['quarters'].length ===
        state?.filtersData['quarters'].length
      ) {
        filters.quarters = [];
      }

      filters.searchText = state?.selectedFilters.searchText || '';

      const payload = {
        page: state?.pagination.page || 1,
        pageSize: state?.pagination.pageSize || 100,
        ...filters,
        sort: state?.sort || [],
      };

      actions
        .updateFiles(selectedRowData.id, requestBody, payload)
        .then(() => {
          setRenameLoading(false);
          handleOnContextMenuClose();
          resetOnClose();
        })
        .catch(() => {
          setRenameLoading(false);
          informationAlert('Unable to rename file.', 'error');
        });
    } else {
      handleOnContextMenuClose();
      resetOnClose();
    }
  };

  const handleFolderNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFolderName(e.target.value);
  };

  const handleFileNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFileName(e.target.value);
  };

  const handleSelectPermission = (
    selectedOption: any,
    checked: boolean
  ): void => {
    const newPermission = selectedOption;
    let allPermissions = [...selectedPermissions];

    if (checked) allPermissions.push(newPermission);
    else
      allPermissions = allPermissions.filter(
        (permission) => permission.id !== newPermission.id
      );
    setSelectedPermissions(allPermissions);
  };

  const handleAllPermissionSelection = (
    options: any,
    checked: boolean
  ): void => {
    if (checked) setSelectedPermissions(() => options);
    else setSelectedPermissions(() => []);
  };

  const removeSelectedPermission = (id: string): void => {
    const filteredPermissions = selectedPermissions.filter(
      (permission) => permission.id !== id
    );

    setSelectedPermissions(filteredPermissions);
  };

  const handlePermissionUpdate = (
    name?: string,
    permissions?: Array<any>
  ): void => {
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }

    setFolderName(name || '');
    setSelectedPermissions(
      permissions?.map((item) => ({ ...item, id: item.key })) || []
    );
    setCurrentStep('assign-permissions');
    setShowFolderDialog(true);
    setEditing(true);
  };

  const handleAddNewPopover = (_: React.MouseEvent, type: string) => {
    if (state.isPageLocked) {
      showLockedTooltip();
      return;
    }
    handleOnAddNewActionClose(_);
    if (type === 'upload') {
      setShowUploadFiles(true);
    } else if (type === 'create') {
      handleAddNewFolderAction(_);
    } else {
      return;
    }
  };

  const handleCloseUploadFiles = () => {
    setShowUploadFiles(false);
  };

  const handleAddNewAction = (event: any) => {
    setShowAddOptions(true);
    setAddNewAnchorEl(event.currentTarget);
  };

  const handleOnAddNewActionClose = (event: any) => {
    setShowAddOptions(false);
    setAddNewAnchorEl(null);
  };

  const closeEditPanel = () => {
    setShowEditPanel(false);
  };

  const resetSelectedFiles = () => {
    setSelectedFiles([]);
    setContextMenuActions(false);
  };

  const clearError = () => {
    setError(null);
  };

  const acceptConsent = () => {
    actions
      .consentAction({
        folderId: consentFolder,
        response: true,
      })
      .then(() => {
        setShowConsent(false);
        setConsentFolder(undefined);
      });
  };

  const declineConsent = () => {
    actions
      .consentAction({
        folderId: consentFolder,
        response: false,
      })
      .then(() => {
        setShowConsent(false);
        setConsentFolder(undefined);
      });
  };

  const handleCloseConsentBox = () => {
    setShowConsent(false);
  };

  return {
    newFiles,
    isRootView,
    contextMenu,
    selectedPermissions,
    selectedFiles,
    showDeleteConfirmationBox,
    lockedTooltipText,
    showFolderDialog,
    currentStep,
    folderName,
    folderEdits,
    setFolderEdits,
    showAddOptions,
    addNewAnchorEl,
    showUploadFiles,
    editing,
    showEditPanel,
    showMovePanel,
    selectedFolder,
    contextMenuMoveOptions,
    error,
    showConsent,
    resetOnClose,
    setShowDeleteConfirmationBox,
    onDeleteConfirm,
    onDeleteCancel,
    handleFolderNameChange,
    handleNextAction,
    handleSelectPermission,
    handleAllPermissionSelection,
    handleOnActionItemClick,
    handleOnCellClick,
    handleAddNewFolderAction,
    removeSelectedPermission,
    handleSelectionModelChange,
    handlePermissionUpdate,
    handleContextMenu,
    handleOnContextMenuClose,
    handleContextMenuOption,
    handleAddNewAction,
    handleOnAddNewActionClose,
    handleAddNewPopover,
    handleCloseUploadFiles,
    addNewFiles,
    handleFilter,
    handleSort,
    closeEditPanel,
    resetSelectedFiles,
    handleMoveFiles,
    handleFolderSelection,
    openDocument,
    clearError,
    setShowConsent,
    acceptConsent,
    declineConsent,
    handleCloseConsentBox,
    reloadCurrentFolder,
    onNextPage,
    handleFileNameChange,
    confirmFileRename,
    showRenameFileDialog,
    fileName,
    renameLoading,
    contextMenuAnchor,
    showPrompt,
    setShowPrompt,
    handleWarningPrompt,
    downloadableFileIds,
    showBulkFileDlPrompt,
    setShowBulkFileDlPrompt,
    handleLpBulkFileDownload,
    downloadableFolderIds,
    showFolderDlPrompt,
    setShowFolderDlPrompt,
    handleLpFolderDownload,
    currentlyDownloadingIds,
    handleDeleteOverflowPrompt,
    setShowDeleteOverflowPrompt,
    showDeleteOverflowPrompt,
    openAdobeViewer,
    setOpenAdobeViewer,
    pdfLoading,
    pdfFile,
    viewLinkedDocument,
    handleShowArchivedToggle,
    headerAndLockbarHeight,
    docHeaderRef,
    isNavBarOpen,
  };
};
