import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AppContext } from '../../../../core/context/appContextProvider';
import RoutingPaths from '../../../../core/routing/routingPaths';
import useRole from '../../../../core/routing/useRole';
import {
  getReviewSummary,
  updateCapitalCallsStatus,
} from '../../../../services/capitalCalls.service';
import { useCapitalCallDetailsEffect } from '../../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks';
import { useClientEffect } from '../../../../services/hooks/useClientsEffect/useClientEffect.hooks';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import { SelectedJournalEntry } from '../../../../utils/types/arkGLJournalEntry.type';
import { ReviewsSummary } from '../../../../utils/types/capitalCalls.type';
import { DetailsType, LoadingStatus } from '../../../../utils/types/form.type';
import { ScopeRole } from '../../../../utils/types/user.type';
import {
  JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
  JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
} from '../../../arkGL/journalEntries/journalEntryList/JournalEntryList.constants';
import {
  REVIEW_DOCUMENT_ERROR,
  REVIEW_DOCUMENT_SUCCESS,
  REVIEW_SUMMARY_GET_ERROR,
  UPDATE_STATUS_ERROR,
} from '../constants';
import { ReviewersList } from '../constants';

type RouteProp = {
  id: string;
};

const initialJournalEntry: SelectedJournalEntry = {
  journalEntry: undefined,
  type: undefined,
};

export const UsePublish = () => {
  const [reviewSummary, setReviewSummary] = useState<ReviewsSummary>();
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<LoadingStatus>();
  const [confirmPublish, setConfirmPublish] = useState<boolean>(false);
  const [toPublish, setToPublish] = useState<boolean>(false);
  const [toReview, setToReview] = useState<boolean>(false);
  const [formattedReviewSummary, setFormattedReviewSummary] = useState<any>();
  const [usernames, setUsernames] = useState<string[]>([]);
  const params = useParams<RouteProp>();
  const { informationAlert, state } = useContext(AppContext);
  const { capitalCall } = useCapitalCallDetailsEffect(params?.id);
  const [sendToGL, setSendToGL] = useState<boolean>(false);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();

  const history = useHistory();

  const clientId = state.loginUser.currentUser?.clientId;
  const { client } = useClientEffect(clientId !== 'new' ? clientId : undefined);
  const arkGlLocked = client?.arkGlLocked;

  const headerTitles = useMemo(() => {
    const headers =
      reviewSummary?.reviews[0].approvals.map((approval) => approval.role) ||
      [];
    const filteredHeaders = headers.filter((header, index) => {
      return headers.indexOf(header) === index;
    });

    return filteredHeaders;
  }, [reviewSummary]);

  const dummyTitle: number[] = [1, 2, 3, 4, 5];
  const { hasRole: isSuperAdminOrClientAdmin } = useRole([
    ScopeRole.SUPER_ADMIN,
    ScopeRole.ARK_CLIENT_ADMIN,
    ScopeRole.BASIC_ADMIN,
  ]);

  useEffect(() => {
    getSummaryOfReviews();
  }, [params?.id]);

  useEffect(() => {
    setStatus(capitalCall?.status);
  }, [capitalCall]);
  const selectReviewer = (member: string) => {
    switch (member) {
      case ReviewersList.Associate:
        return 'Associate';
      case ReviewersList.Director:
        return 'Director';
      case ReviewersList.FundController:
        return 'Fund Controller';
      case ReviewersList.FundUserReadOnly:
        return 'Fund User Read Only';
      case ReviewersList.Manager:
        return 'Manager';
      default:
        return '';
    }
  };
  const allowEdit = useMemo(() => {
    if (params?.id !== 'new') {
      if (
        state.loginUser.currentUser?.username.toLowerCase() ===
          capitalCall?.createdBy?.toLowerCase() &&
        capitalCall?.status?.toLowerCase() !== 'published'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [params.id, capitalCall, state]);
  const allowPublish = useMemo(() => {
    return usernames.includes(
      state.loginUser.currentUser?.username.toLowerCase() || ''
    );
  }, [usernames]);
  const getSummaryOfReviews = async () => {
    try {
      setIsLoading(LoadingStatus.Loading);
      await getReviewSummary(params?.id || '').then((data) => {
        if (data) setReviewSummary(data);

        let reviewerApprovals = false;
        const createdBy = data?.createdBy;
        let isFlaggedReview = false;
        let tosubmitReview = false;
        const formattedReviews = [];
        const userNameList: string[] = [];

        for (const review of data.reviews) {
          const approvals: any = { investor: review.investor };

          for (const approval of review.approvals) {
            !userNameList.includes(approval?.username?.toLowerCase()) &&
              userNameList.push(approval?.username?.toLowerCase());
            approvals[approval.role] = approvals[approval.role]
              ? [
                  ...approvals[approval.role],
                  {
                    id: approval.id,
                    username: approval.username,
                    tenantId: approval.tenantId,
                    approved: approval.approved,
                  },
                ]
              : [
                  {
                    id: approval.id,
                    username: approval.username,
                    tenantId: approval.tenantId,
                    approved: approval.approved,
                  },
                ];
          }

          formattedReviews.push(approvals);
        }
        setFormattedReviewSummary(formattedReviews);
        setUsernames(userNameList);
        data?.reviews?.forEach((review) => {
          const countApprovals = review.approvals.length;
          let count = 0;

          review.approvals.forEach((approval) => {
            if (approval.approved === false) {
              isFlaggedReview = true;

              return;
            }

            if (approval.approved === true) {
              count = count + 1;
            }
            if (approval.approved === true && approval.username === createdBy) {
              tosubmitReview = true;
            }
          });
          if (count === countApprovals) {
            reviewerApprovals = true;
          }
        });

        const publish = !isFlaggedReview && reviewerApprovals;
        const allowsubmitreview = !isFlaggedReview && tosubmitReview;

        setToPublish(publish);
        setToReview(allowsubmitreview);
      });
    } catch (error) {
      informationAlert(REVIEW_SUMMARY_GET_ERROR, 'error');
    } finally {
      setIsLoading(undefined);
    }
  };

  const submitReview = async () => {
    try {
      setIsLoading(LoadingStatus.Submitting);
      await updateCapitalCallsStatus(params?.id, 'REVIEWING');
      setIsLoading(undefined);
      informationAlert(REVIEW_DOCUMENT_SUCCESS, 'success');
      setStatus('REVIEWING');
      setTimeout(() => {
        history.push(RoutingPaths.CapitalCalls);
      }, 1000);
    } catch (error) {
      informationAlert(REVIEW_DOCUMENT_ERROR, 'error');
      setIsLoading(undefined);
    }
  };
  const handlePublishCancel = () => {
    setConfirmPublish(false);
    sessionStorage.removeItem(params?.id);
  };

  const handlePublishConfirm = async () => {
    try {
      setIsLoading(LoadingStatus.Publishing);
      setStatus('PUBLISHED');
      setConfirmPublish(false);

      await updateCapitalCallsStatus(params?.id, 'PUBLISHED');

      setIsLoading(undefined);
      if (sendToGL) {
        setSelectedJournalEntry({
          journalEntry: {
            ...JOURNAL_ENTRY_FORM_DEFAULT_VALUE,
            fundId: capitalCall?.fundId,
            lineItems: [
              {
                ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
                entities: [],
                entryType: 'DEBIT',
                amount: capitalCall?.amount!,
                entryMemo: capitalCall?.name,
              },
              {
                ...JOURNAL_ENTRY_LINE_ITEM_DEFAULT_VALUE,
                entities: [],
                entryType: 'CREDIT',
                amount: capitalCall?.amount!,
                entryMemo: capitalCall?.name,
              },
            ],
            date:
              capitalCall?.transactions && capitalCall?.transactions.length
                ? DateTimeFormat.ensureAsDate(capitalCall.transactions[0].date)
                : null,
          },
          type: DetailsType.New,
        });
      } else {
        setTimeout(() => {
          history.push(RoutingPaths.CapitalCalls);
        }, 1000);
      }
    } catch (error) {
      setStatus(capitalCall?.status);
      informationAlert(UPDATE_STATUS_ERROR, 'error');
      setIsLoading(undefined);
    }
  };

  const onJournalEntryPanelClose = () => {
    setSelectedJournalEntry(initialJournalEntry);
    if (capitalCall && capitalCall.fundId) {
      history.push(
        `${RoutingPaths.FundGLJournalEntries}/${capitalCall.fundId}`
      );
    } else {
      history.push(`${RoutingPaths.GLJournalEntries}`);
    }
  };

  return {
    reviewSummary,
    status,
    selectReviewer,
    submitReview,
    confirmPublish,
    isLoading,
    handlePublishCancel,
    handlePublishConfirm,
    toPublish,
    toReview,
    setConfirmPublish,
    isSuperAdminOrClientAdmin,
    reviewersLength: reviewSummary?.reviews[0]?.approvals?.length || 0,
    allowEdit,
    headerTitles,
    dummyTitle,
    formattedReviewSummary,
    allowPublish,
    sendToGL,
    setSendToGL,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    arkGlLocked,
  };
};
