import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import HorizontalBox from '../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../components/Buttons/Button/Button';
import DetailPanel from '../../../components/DetailPanel/DetailPanel';
import {
  FormBox,
  Panel,
  Typography,
} from '../../../components/DetailPanel/DetailPanel.styles';
import ConfirmationDialog from '../../../components/Modal/ConfirmationDialog';
import {
  appliesToAutoOptions,
  appliesToOptions,
  BankFilter,
  conditionMenuOptions,
  detailMenuOptions,
  includesMenuOptions,
  SelectedBankRule,
  splitByOptions,
} from '../../../utils/types/bank.type';
import { DetailsType } from '../../../utils/types/form.type';
import { useBankRulesDetails } from './BankRuleDetails.hooks';
import {
  AddButton,
  SelectFormControl,
  SplitByRadioGroup,
  SubGroupHeader,
} from './BankRuleDetails.styles';

interface Props extends RouteProps {
  bankList: BankFilter[];
  fetchAllBankRules: () => void;
  fundId?: string;
  onDetailsClose: () => void;
  selectedBankRule: SelectedBankRule;
}

export const BankRulesDetails: React.FC<Props> = ({
  bankList,
  fetchAllBankRules,
  fundId,
  onDetailsClose,
  selectedBankRule,
}: Props) => {
  const { type, bankRule } = selectedBankRule;
  const isNewBankRule = type === DetailsType.New;

  const {
    accountListOptions,
    closeDrawer,
    control,
    conditionsList,
    createUpdateBankRule,
    fundList,
    handleSubmit,
    handleAddSplit,
    handleAddCondition,
    isLoading,
    memoEntityList,
    register,
    getValues,
    setValue,
    showExitConfirmation,
    toggleDrawer,
    trigger,
    errors,
    isSubmitted,
    keepDrawerOpen,
    watch,
    splitByType,
    splitDetailsList,
    lastSplit,
    lastCondition,
  } = useBankRulesDetails({
    bankRule,
    fetchAllBankRules,
    fundId,
    isNewBankRule,
    onDetailsClose,
  });

  return (
    <>
      <DetailPanel
        id="bank_rules_detail_panel"
        title={isNewBankRule ? 'Add Bank Rule' : bankRule?.ruleName}
        open={Boolean(type)}
        variant={'temporary'}
        hasTabsPanel={false}
        onClose={toggleDrawer}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdateBankRule)}
          noValidate
        >
          <FormBox>
            <Panel id="bank_rules_details_panel">
              <Grid container spacing={2}>
                <Grid item xs={2.5}>
                  <FormLabel component="legend" sx={{ marginTop: '8px' }}>
                    Show split by:
                  </FormLabel>
                </Grid>
                <Grid item xs={9.5}>
                  <Controller
                    name={`splitBy`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControl>
                          {/* <FormLabel component="legend">Show split by:</FormLabel> */}
                          <SplitByRadioGroup
                            value={value}
                            aria-labelledby={`radio_btn_split_by`}
                            name={`radio_btn_split_by`}
                            onChange={(e: SelectChangeEvent) => {
                              onChange(e.target.value);
                              // setIsUniqueNameError(false);
                            }}
                          >
                            {splitByOptions.map((value: any) => (
                              <FormControlLabel
                                key={value.value}
                                value={value.value}
                                control={<Radio />}
                                label={value.label}
                              />
                            ))}
                          </SplitByRadioGroup>
                        </FormControl>
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubGroupHeader>Rule Name</SubGroupHeader>
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    name="ruleName"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          id="txt_bank_rule_name"
                          label="Bank Rule Name"
                          aria-describedby="ruleName"
                          placeholder="Bank Rule Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={value ?? ''}
                          onChange={(event) => {
                            const ruleName = event.target.value;

                            onChange(event);
                            if (event.target.name && ruleName) {
                              setValue('ruleName', ruleName);
                            }
                          }}
                          InputLabelProps={
                            !isNewBankRule ? { shrink: !!value } : {}
                          }
                          error={!!error}
                          helperText={error?.message ?? ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) =>
                        value?.trim() ? true : 'Bank Rule Name is Required',
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubGroupHeader>Applies to</SubGroupHeader>
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    name="appliesTo"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectFormControl>
                        <InputLabel id="label_applies_to">
                          Inflow/Outflow
                        </InputLabel>
                        <Select
                          id="applies_to_txt_dropdown"
                          labelId="label_applies_to"
                          label="Inflow/Outflow"
                          fullWidth
                          size="small"
                          multiple
                          aria-describedby="appliesTo"
                          placeholder="Inflow/Outflow"
                          variant="outlined"
                          value={value || []}
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          error={!!errors.appliesTo}
                        >
                          {appliesToAutoOptions.map((option: any) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    )}
                    rules={{
                      validate: (value) =>
                        value?.length ? true : 'Applies to is Required',
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubGroupHeader />
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    name="banks"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectFormControl>
                        <InputLabel id="label_banks">Banks</InputLabel>
                        <Select
                          id="banks_txt_dropdown"
                          labelId="label_banks"
                          label="Banks"
                          multiple
                          fullWidth
                          size="small"
                          aria-describedby="banks"
                          placeholder="Banks"
                          variant="outlined"
                          value={value || []}
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          error={!!errors.banks}
                        >
                          {bankList.map((option: any) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    )}
                    // rules={{
                    //     validate: (value) =>
                    //         value?.length ? true : 'Bank selection is Required'
                    // }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    name="includes"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectFormControl>
                        <InputLabel id="label_includes">Includes</InputLabel>
                        <Select
                          id="includes_txt_dropdown"
                          labelId="label_includes"
                          label="Includes"
                          fullWidth
                          size="small"
                          aria-describedby="includes"
                          placeholder="Includes"
                          variant="outlined"
                          value={getValues('includes')}
                          onChange={(event) => {
                            const includes = event.target.value;

                            onChange(event);
                            if (includes) {
                              setValue('includes', includes);
                            }
                          }}
                          error={!!errors.includes}
                        >
                          {includesMenuOptions.map((option: any) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    )}
                    rules={{
                      validate: (value) =>
                        value?.length ? true : 'Includes is Required',
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubGroupHeader />
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    name="account"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectFormControl>
                        <InputLabel id="label_account">Account</InputLabel>
                        <Select
                          id="account_txt_dropdown"
                          labelId="label_account"
                          label="Account"
                          fullWidth
                          size="small"
                          aria-describedby="account"
                          placeholder="Account"
                          variant="outlined"
                          value={value || ''}
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          error={!!errors.account}
                        >
                          {accountListOptions.map((option: any) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name} - {option.fund}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    )}
                    rules={{
                      validate: (value) =>
                        value?.length ? true : 'Account selection is Required',
                    }}
                    control={control}
                  />
                </Grid>
                {conditionsList?.map((condition, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Grid item xs={2}>
                        <SubGroupHeader />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          key={idx}
                          name={`conditions.${idx}.detail`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <SelectFormControl>
                              <InputLabel id={`label_conditions.${idx}.detail`}>
                                Detail
                              </InputLabel>
                              <Select
                                id={`conditions.${idx}.detail_txt_dropdown`}
                                labelId={`label_conditions.${idx}.detail`}
                                label="Detail"
                                fullWidth
                                size="small"
                                aria-describedby={`conditions.${idx}.detail`}
                                placeholder="Detail"
                                variant="outlined"
                                value={value || ''}
                                onChange={(event) => {
                                  onChange(event.target.value);
                                }}
                                error={!!errors.detail}
                              >
                                {detailMenuOptions.map((option: any) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </SelectFormControl>
                          )}
                          rules={{
                            validate: (value) =>
                              value?.length ? true : 'Detail is Required',
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          key={idx}
                          name={`conditions.${idx}.condition`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <SelectFormControl>
                              <InputLabel
                                id={`label_conditions.${idx}.condition`}
                              >
                                Condition
                              </InputLabel>
                              <Select
                                id={`conditions.${idx}.condition_txt_dropdown`}
                                labelId={`label_conditions.${idx}.condition`}
                                label="Condition"
                                fullWidth
                                size="small"
                                aria-describedby={`conditions.${idx}.condition`}
                                placeholder="Condition"
                                variant="outlined"
                                value={value || ''}
                                onChange={(event) => {
                                  onChange(event.target.value);
                                }}
                                error={!!errors.condition}
                              >
                                {conditionMenuOptions.map((option: any) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </SelectFormControl>
                          )}
                          rules={{
                            validate: (value) =>
                              value?.length ? true : 'Condition is Required',
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <SubGroupHeader />
                      </Grid>
                      <Grid item xs={10}>
                        <Controller
                          key={idx}
                          name={`conditions.${idx}.value`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextField
                                id={`txt_conditions.${idx}.value`}
                                label="Value"
                                aria-describedby={`conditions.${idx}.value`}
                                placeholder="Value"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={value ?? ''}
                                onChange={(event) => {
                                  const value = event.target.value;

                                  onChange(event);
                                  if (event.target.name && value) {
                                    setValue('value', value);
                                  }
                                }}
                                InputLabelProps={
                                  !isNewBankRule ? { shrink: !!value } : {}
                                }
                                error={!!error}
                                //helperText={error?.message ?? ''}
                              />
                            );
                          }}
                          rules={{
                            validate: (value) =>
                              value?.trim() ? true : 'Value is Required',
                          }}
                          control={control}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
                {lastCondition <= 2 ? (
                  <React.Fragment>
                    <Grid item xs={2}>
                      <SubGroupHeader />
                    </Grid>
                    <Grid item xs={10}>
                      <AddButton
                        id={'btn_add_condition'}
                        variant="text"
                        onClick={handleAddCondition}
                        color={'primary'}
                      >
                        + Add a condition
                      </AddButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
                {splitDetailsList?.map((split, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Grid item xs={2}>
                        <SubGroupHeader>{`Split #${idx + 1}`}</SubGroupHeader>
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          key={idx}
                          name={`splitDetails.${idx}.detailValue`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextField
                                id={`txt_splitDetails.${idx}.detailValue`}
                                label={
                                  splitByType === 'AMOUNT'
                                    ? lastSplit === idx
                                      ? 'Remainder'
                                      : 'Amount'
                                    : 'Percentage'
                                }
                                aria-describedby={`splitDetails.${idx}.detailValue`}
                                placeholder={
                                  splitByType === 'AMOUNT'
                                    ? lastSplit === idx
                                      ? 'Remainder'
                                      : 'Amount'
                                    : 'Percentage'
                                }
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={value || ''}
                                onChange={(event) => {
                                  const value = parseFloat(event.target.value);

                                  onChange(event);
                                  if (value) {
                                    setValue(
                                      `splitDetails.${idx}.detailValue`,
                                      value
                                    );
                                  }
                                }}
                                InputLabelProps={
                                  !isNewBankRule ? { shrink: !!value } : {}
                                }
                                error={!!error}
                                helperText={error?.message ?? ''}
                              />
                            );
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Controller
                          key={idx}
                          name={`splitDetails.${idx}.detailAccount`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <SelectFormControl>
                              <InputLabel
                                id={`label_splitDetails.${idx}.detailAccount`}
                              >
                                Account
                              </InputLabel>
                              <Select
                                id={`splitDetails.${idx}.detailAccount_txt_dropdown`}
                                labelId={`label_splitDetails.${idx}.detailAccount`}
                                label="Account"
                                fullWidth
                                size="small"
                                aria-describedby={`splitDetails.${idx}.detailAccount`}
                                placeholder="Account"
                                variant="outlined"
                                value={value || ''}
                                onChange={(event) => {
                                  onChange(event.target.value);
                                }}
                                error={!!error}
                              >
                                {accountListOptions.map((option: any) => (
                                  <MenuItem key={option.id} value={option.name}>
                                    {option.name} - {option.fund}
                                  </MenuItem>
                                ))}
                              </Select>
                            </SelectFormControl>
                          )}
                          control={control}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
                {lastSplit <= 3 ? (
                  <React.Fragment>
                    <Grid item xs={2}>
                      <SubGroupHeader />
                    </Grid>
                    <Grid item xs={10}>
                      <AddButton
                        id={'btn_account_add_split'}
                        variant="text"
                        onClick={handleAddSplit}
                        color={'primary'}
                      >
                        + Add a split
                      </AddButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
                <Grid item xs={2}>
                  <SubGroupHeader />
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    name="memoEntity"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <SelectFormControl>
                        <InputLabel id="label_memo_entity">
                          Memo Entity
                        </InputLabel>
                        <Select
                          id="memo_entity_txt_dropdown"
                          labelId="label_memo_entity"
                          label="Memo Entity"
                          multiple
                          fullWidth
                          size="small"
                          aria-describedby="memoEntity"
                          placeholder="Memo Entity"
                          variant="outlined"
                          value={value || []}
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          error={!!errors.memoEntity}
                        >
                          {memoEntityList.map((option: any) => (
                            <MenuItem key={option.id} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Panel>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'btn_account_cancel'}
              variant="outlined"
              onClick={toggleDrawer}
              text={'Cancel'}
              color={'secondary'}
              fullWidth
              addSpaceBetweenButtons
            />
            <Button
              id={'btn_account_save'}
              variant="contained"
              text={'Save & Close'}
              color={'primary'}
              type="submit"
              fullWidth
              addSpaceBetweenButtons
            />
          </HorizontalBox>
        </form>
      </DetailPanel>
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save_account_confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'btn_account_save',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'btn_account_cancel_save',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </>
  );
};
