import { ListItem } from "../utils/types/listItems";
import { ApiClient, ClientType } from "./apiClient/ApiClient";
import { ServiceType } from "./apiClient/config";

const apiClient = () => {
  return ApiClient.Client(ClientType.NotCached, ServiceType.Api);
};

export const getLegalTypesList = async () => {
  const legalTypesList = await apiClient().get<ListItem[]>(
    "investors/legalTypes"
  );

  return legalTypesList;
};

export const getTypesList = async () => {
  const typesList = await apiClient().get<ListItem[]>("investors/types");

  return typesList;
};
