import { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../core/context/appContextProvider";
import RoutingPaths from "../../../core/routing/routingPaths";
import { getLedgers } from "../../../services/arkGL.service";
import { useClientEffect } from "../../../services/hooks/useClientsEffect/useClientEffect.hooks";
import { GENERIC_ERROR_MESSAGE } from "../../../utils/constants/text.constants";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const usepageMenuOptions = () => {
  const { state, informationAlert } = useContext(AppContext);
  const { fundId } = useParams<{ fundId: string }>();
  const clientId = state.loginUser.clientId;
  const { client } = useClientEffect(clientId !== "new" ? clientId : undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isQBConnected, setIsQBConnected] = useState<boolean>(false);
  const [isFundGlConnected, setIsFundGlConnected] = useState<boolean>(false);

  useEffectAsync(async (isCanceled) => {
    try {
      setIsLoading(true);
      if(client) await checkGLConnection(isCanceled);
    } catch (e) {
      informationAlert(GENERIC_ERROR_MESSAGE, "error");
    } finally {
      setIsLoading(false);
    }
  }, [client]);

  const checkGLConnection = async (isCanceled?: () => boolean) => {
    if (fundId !== "new") {
      const ledgerList = await getLedgers(fundId);

      ledgerList.items.map((ledger: any) => {
        if (
          ledger.glPlatform === "QUICKBOOKS_ONLINE" &&
          ledger.status === "AUTHENTICATED"
        ) {
          setIsQBConnected(true);
        }

        if (
          ledger.fundId === fundId &&
          client?.arkGlLocked === false
          ) {
          setIsFundGlConnected(true);        
        }
      });
    }
  };

  const menu = useMemo(() => {
    return [
      {
        id: RoutingPaths.FundDetails,
        name: "Details",
        path: RoutingPaths.FundDetails,
      },
      {
        id: "performance",
        name: "Performance",
        path: "xx",
        hidden: true,
      },
      {
        id: "investments",
        name: "Investments",
        locked: client?.soiLocked === false ? false : true || true,
        menuOptions: [
          {
            id: "schedule_of_investments",
            name: "Schedule of Investments",
            path: RoutingPaths.ScheduleOfInvestmentsFund,
          },
          {
            id: "investment_companies",
            name: "Portfolio Companies",
            path: RoutingPaths.FundPortfolioCompanies,
          },
          {
            id: "investment_transactions",
            name: "Investment Transactions",
            path: RoutingPaths.InvestmentTransactionsFund,
          },
        ],
      },
      {
        id: "partners",
        name: "Partners",
        path: "xx",
        hidden: true,
      },
      {
        id: "capital_activity",
        name: "Capital Activity",
        path: "xx",
        hidden: true,
      },
      {
        id: "partners_capital",
        name: "Partner's Capital",
        hidden: true,
        menuOptions: [
          {
            id: "capital_accounts",
            name: "Capital Accounts",
            path: "xx",
          },
          {
            id: "quarters_management",
            name: "Quarters Management",
            path: "xx",
          },
        ],
      },
      {
        id: "general_ledger",
        name: "General Ledger",
        menuOptions: [
          {
            id: RoutingPaths.FundGLSetup,
            name: "Setup",
            path: RoutingPaths.FundGLSetup,
          },
          {
            id: RoutingPaths.TransactionMapper,
            name: "Chart of Accounts (External)",
            path: RoutingPaths.TransactionMapper,
            hidden: !isQBConnected,
          },
          {
            id: RoutingPaths.FundGLAccounts,
            name: "Chart of Accounts (arkGL)",
            path: RoutingPaths.FundGLAccounts,
            hidden: !isFundGlConnected
          },
          {
            id: RoutingPaths.FundGLEntityLists,
            name: "Memo Entities",
            path: RoutingPaths.FundGLEntityLists,
            hidden: client?.arkGlLocked
          },
          {
            id: RoutingPaths.FundGLJournalEntries,
            name: "Journal Entries",
            path: RoutingPaths.FundGLJournalEntries,
            hidden: client?.arkGlLocked
          },
          {
            id: RoutingPaths.FundGLReports,
            name: "Reports",
            path: RoutingPaths.FundGLReports,
            hidden: client?.arkGlLocked
          },
        ],
      },
      {
        id: RoutingPaths.FundAllocationsList,
        name: "Allocation Files",
        path: RoutingPaths.FundAllocationsList,
        locked: client?.allocationLocked === false ? false : true || true,
      },
      {
        id: RoutingPaths.FundInvestorList,
        name: "Investors",
        path: RoutingPaths.FundInvestorList,
        hidden: true,
      },
    ];
  }, [client, isQBConnected, isFundGlConnected]);

  return {
    menu,
  };
};
