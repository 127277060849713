import { Collapse, FormControlLabel, Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';
import { Controller } from 'react-hook-form';

import IconPDF from '../../../../assets/images/icons/icon_pdf.svg';
import { FileContainer } from '../../../../components/FileContainer/FileContainer';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import NextStepDialog from '../../../../components/Modal/NextStepDialog/NextStepDialog';
import { MultiSelect } from '../../../../components/MultiSelect/MultiSelect';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import {
  DocFile,
  FolderContentRequestPayload,
} from '../../../../utils/types/documents.type';
import useDocumentsContext from '../../hooks/useDocumentsContext.hooks';
import {
  ArchiveGrid,
  StyledArchiveOutlinedIcon,
} from '../archivePanel/ArchivePanel.styles';
import { TooltipTypography } from '../editPanel/EditPanel.styles';
import { ARCHIVE_TOOLTIP_TEXT } from '../grid/cells/archiveCellRenderer/ArchiveCellRenderer.constants';
import { InfoTooltip } from '../infoTooltip/InfoTooltip';
import { TooltipText } from './AddTags.constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ShadowBox,
} from './AddTags.styles';
import useAddTags from './useAddTags.hooks';

type Props = {
  showDialog?: boolean;
  files?: DocFile[];
  resetUploadFiles: () => void;
  reload: Function;
  setErrors: Function;
};

type AllOption = {
  id: string;
  name: string;
  selected: undefined | boolean;
};

const All_Option: AllOption = {
  id: 'all',
  name: 'Select All Investors',
  selected: false,
};

export function AddTags({
  showDialog = false,
  files = [],
  resetUploadFiles = () => {},
  reload,
  setErrors,
}: Props): React.ReactElement {
  const { state } = useDocumentsContext();

  const pdfFiles = files.filter((file) => file.fileType === 'pdf');

  const {
    getFunds,
    getInvestors,
    getQuarters,
    archiveOptions,
    loading,
    control,
    showAddtlProperties,
    handleShowAddtlProperties,
    watchArchivePolicy,
    validateDate,
    onReview,
    handleSubmit,
    handleCloseAddTags,
    showConfirmationDialog,
    setShowConfirmationDialog,
    closeAddTags,
  } = useAddTags(state, { setErrors, resetUploadFiles, reload });

  return (
    <NextStepDialog
      open={showDialog}
      onClose={handleCloseAddTags}
      fullWidth={true}
      id="review_uploaded_files"
      actionDisplayType="inline"
      actions={[
        {
          label: 'Upload & Tag Later',
          onClick: handleCloseAddTags,
          id: 'upload_and_tag_later_button',
          variant: 'contained',
          color: 'secondary',
          disabled: loading,
        },
        {
          label: 'Save & Review Files',
          onClick: handleSubmit((data) => onReview(data, files)),
          id: 'review_files_button',
          variant: 'contained',
          color: 'primary',
          disabled: loading,
        },
      ]}
      title="Add Tags & Manage File Settings"
    >
      <Box flex={1}>
        <Grid container rowSpacing={2} px={2}>
          <Grid item xs={12} pt={0}>
            <FileContainer
              icon={IconPDF}
              showUploadProgress={false}
              styles={{ marginTop: 0, marginBottom: '20px' }}
              hideClear={true}
              file={files}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              Add tags to all files in upload
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="investor"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                All_Option.selected = value?.includes(All_Option.id);

                const items = [
                  All_Option,
                  ...getInvestors().map((investor: any) => ({
                    ...investor,
                    selected: value?.includes(investor.id),
                  })),
                ];

                return (
                  <MultiSelect
                    id="select_investors"
                    disabled={loading}
                    label="Investor"
                    fullWidth={true}
                    items={items}
                    noGutter={true}
                    onChange={(items) => {
                      const selectedItems: Array<string> = [];

                      items.forEach((item) => {
                        if (item.selected) {
                          selectedItems.push(item.id);
                        }
                      });

                      if (
                        (value?.includes('all') &&
                          !selectedItems.includes('all')) ||
                        (value?.includes('all') &&
                          selectedItems.includes('all') &&
                          selectedItems.length !== items.length)
                      ) {
                        items.forEach((item) => (item.selected = false));
                      } else if (
                        !value?.includes('all') &&
                        selectedItems.includes('all')
                      ) {
                        items.forEach((item) => (item.selected = true));
                      }

                      const itemIDs: Array<string> = [];

                      items.forEach(
                        (item) => item.selected && itemIDs.push(item.id)
                      );

                      onChange(itemIDs);
                    }}
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                );
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="funds"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <MultiSelect
                    disabled={loading}
                    items={getFunds().map((fund: any) => ({
                      ...fund,
                      selected: value?.includes(fund.id),
                    }))}
                    fullWidth={true}
                    noGutter={true}
                    id="select_funds"
                    label="Funds"
                    onChange={(items) => {
                      const itemIDs: Array<string> = [];

                      items.forEach(
                        (item) => item.selected && itemIDs.push(item.id)
                      );

                      onChange(itemIDs);
                    }}
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                );
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="quarter"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SingleSelect
                    id="select_quarter"
                    noGutter={true}
                    disabled={loading}
                    optionList={getQuarters()}
                    label="Quarter"
                    fullWidth={true}
                    idField="value"
                    labelField="label"
                    handleOptionSelection={(event: SelectChangeEvent) => {
                      onChange(event.target.value);
                    }}
                    value={value ?? ''}
                    error={!!error}
                    helperText={error?.message ?? ''}
                  />
                );
              }}
              control={control}
            />
          </Grid>

          <>
            <Grid item xs={12}>
              <Typography variant={'h4'}>
                Manage settings for files in upload
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'}>
                <Grid item xs={11.5}>
                  <Controller
                    name="archivePolicy"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SingleSelect
                          id="select_archive_setting"
                          noGutter={true}
                          disabled={loading}
                          optionList={archiveOptions}
                          label="Archive"
                          fullWidth={true}
                          idField="id"
                          labelField="label"
                          handleOptionSelection={(event: SelectChangeEvent) => {
                            onChange(event.target.value);
                          }}
                          value={value ?? ''}
                          error={!!error}
                          helperText={error?.message ?? ''}
                        />
                      );
                    }}
                    control={control}
                  />
                </Grid>
                <InfoTooltip
                  id={'pdf_settings_tooltip'}
                  children={
                    <>
                      <TooltipTypography>
                        <strong>Respect Folder Settings: </strong>
                        {ARCHIVE_TOOLTIP_TEXT.RESPECT_FOLDER_SETTINGS}
                      </TooltipTypography>
                      <TooltipTypography>
                        <strong>Disable Archiving: </strong>
                        {ARCHIVE_TOOLTIP_TEXT.DISABLE_ARCHIVING}
                      </TooltipTypography>
                      <TooltipTypography>
                        <strong>Set Custom Date: </strong>
                        {ARCHIVE_TOOLTIP_TEXT.SET_CUSTOM_DATE}
                      </TooltipTypography>
                    </>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={watchArchivePolicy === 'RESPECT_FILE'}>
                <Grid container spacing={2} style={{ paddingBottom: '16px' }}>
                  <ArchiveGrid item xs={12} sm={7}>
                    <Box>
                      <Typography alignItems={'center'} display="flex">
                        <StyledArchiveOutlinedIcon />
                        Uploaded documents will automatically archive on:
                      </Typography>
                    </Box>
                  </ArchiveGrid>
                  <Grid item xs={12} sm={4.5}>
                    <Controller
                      name="archiveOn"
                      rules={{
                        validate: (value) => validateDate(value),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <DatePicker
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            value={value}
                            renderInput={(params) => (
                              <TextField
                                id={'add_tags_archive_in_textfield'}
                                {...params}
                                value={value}
                                fullWidth
                                sx={{
                                  '& input': {
                                    padding: '8.5px 32px 8.5px 14px',
                                  },
                                }}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        );
                      }}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </>
          {!!pdfFiles.length && (
            <Grid item xs={12}>
              <ShadowBox>
                <Accordion
                  expanded={showAddtlProperties}
                  onChange={handleShowAddtlProperties}
                >
                  <AccordionSummary>
                    <Typography
                      fontWeight={'medium'}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      PDF Specific Settings
                      <InfoTooltip
                        id={'pdf_settings_tooltip'}
                        children={TooltipText.pdfSettings}
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <Typography marginTop={'-10px'} marginBottom={'15px'}>
                        <small>{`The following settings will apply to the ${
                          pdfFiles.length
                        } PDF ${
                          pdfFiles.length > 1 ? 'files' : 'file'
                        } in this upload.`}</small>
                      </Typography>
                    </Grid>
                    <Controller
                      name="permissions"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Grid container alignItems={'center'}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={value.downloadEnabled}
                                  onChange={(event) => {
                                    onChange({
                                      ...value,
                                      downloadEnabled: event.target.checked,
                                    });
                                  }}
                                  name="Download"
                                />
                              }
                              label={
                                <InfoTooltip
                                  id={'pdf_settings_download_tooltip'}
                                  children={TooltipText.download}
                                  label={
                                    value.downloadEnabled
                                      ? 'Download On'
                                      : 'Download Off'
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              sx={{ marginLeft: '36px' }}
                              control={
                                <>
                                  <Switch
                                    checked={value.printEnabled}
                                    onChange={(event) => {
                                      onChange({
                                        ...value,
                                        printEnabled: event.target.checked,
                                      });
                                    }}
                                    name="Print"
                                  />
                                </>
                              }
                              label={
                                <InfoTooltip
                                  id={'pdf_settings_print_tooltip'}
                                  children={TooltipText.print}
                                  label={
                                    value.printEnabled
                                      ? 'Print On'
                                      : 'Print Off'
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              sx={{ marginLeft: '36px' }}
                              control={
                                <Switch
                                  checked={value.copyEnabled}
                                  onChange={(event) => {
                                    onChange({
                                      ...value,
                                      copyEnabled: event.target.checked,
                                    });
                                  }}
                                  name="Copy"
                                />
                              }
                              label={
                                <InfoTooltip
                                  id={'pdf_settings_copy_tooltip'}
                                  children={TooltipText.copy}
                                  label={
                                    value.copyEnabled ? 'Copy On' : 'Copy Off'
                                  }
                                />
                              }
                            />
                          </Grid>
                        );
                      }}
                      control={control}
                    />
                  </AccordionDetails>
                </Accordion>
              </ShadowBox>
            </Grid>
          )}
        </Grid>
      </Box>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onClose={() => {}}
          id="add_tags_exit_confirmation"
          whitespace={false}
          actions={[
            {
              label: 'Continue',
              onClick: () => closeAddTags(),
              id: 'continue_button',
              variant: 'contained',
              color: 'primary',
            },
            {
              label: 'Return to Tag Files',
              onClick: () => setShowConfirmationDialog(false),
              id: 'return_to_tag_button',
              variant: 'contained',
              color: 'secondary',
            },
          ]}
          title="Are you sure you want to continue?"
          content="File tags will not be saved unless you select 'Save & Review Files' on the bulk upload screen."
        />
      )}
    </NextStepDialog>
  );
}
