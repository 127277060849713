import { useState } from "react";

export const usePdfViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [loadingFile, setLoading] = useState(true);

  // eslint-disable-next-line no-shadow
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);

    setLoading(false);
  };

  return {
    numPages,
    loadingFile,
    onDocumentLoadSuccess,
  };
};
