import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { UploadHistory } from "../../../utils/types/uploadHistory.type";
import { getHistory } from "../../uploadHistory.service";

export const useUploadHistoryEffect = () => {
  const [history, setHistory] = useState<UploadHistory[]>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchHistory = useCallback( async (isCanceled?: () => boolean) => {
    try {
      setLoading(true);
      const response = await getHistory();

      if (isCanceled?.()) return;

      setHistory(response);
      setLoading(false);
    } catch (e) {
      informationAlert("Error in getting upload history", "error");
      setLoading(false);
    }
  }, []);

  useEffectAsync(async (isCanceled) => {
    await fetchHistory(isCanceled);
  }, []);

  return {
    history,
    fetchHistory,
    loading,
    setHistory
  };
};
