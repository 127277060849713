import { useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { GET_FUND_LIST_ERROR } from "../../../pages/arkGL/journalEntries/journalEntryList/JournalEntryList.constants";
import { getFundNames } from "../../../services/fund.service";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";

export const useJEFundFilterEffect = () => {
  const { informationAlert } = useContext(AppContext);

  const [fundList, setJEFundList] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  const fetchAccountList = async (isCanceled?: () => boolean) => {
    setLoading(true);
    try {
      if (isCanceled?.()) return;

      const response = await getFundNames();

      setJEFundList(response.sort());
    } catch (e) {
      informationAlert(GET_FUND_LIST_ERROR, "error");
    }
    setLoading(false);
  };

  useEffectAsync(async (isCanceled) => {
    await fetchAccountList(isCanceled);
  }, []);

  return {
    fundList,
    loading,
  };
};

export default useJEFundFilterEffect;
