import { Box, Paper, styled, Typography } from '@mui/material';

export const MainPaperStyled = styled(Paper)(({ theme }) => ({
  height: '80px',
  padding: theme.spacing(1),
  boxShadow: '0px 2px 6px rgba(46, 125, 247, 0.3)',
  borderRadius: '7px',

  '&.dragging': {
    opacity: 0.5,
  },
}));

export const SummaryBoxStyled = styled(Box)(({ theme }) => ({
  marginLeft: '12px',
}));

export const TitleTypographyStyled = styled(Typography)(({ theme }) => ({
  variant: 'caption',
  display: 'flex',
  marginBottom: '4px',
  marginTop: '4px',
  marginRight: '30px',
}));

export const RightAlignedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row-reverse',
}));

export const BodyTypographyStyled = styled(Typography)(({ theme }) => ({
  variant: 'h4',
  display: 'flex',
  fontWeight: 'bold',
  marginRight: '12px',
}));
