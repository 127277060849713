import { ArrowDropDown } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactElement, useContext, useEffect, useState } from 'react';
import React from 'react';

import { AppContext } from '../../../../../../core/context/appContextProvider';
import { setDocControlPermissions } from '../../../../../../services/documents.service';
import { M_DASH_UNICODE } from '../../../../../../utils/constants/constants';
import { DateTimeFormat } from '../../../../../../utils/helpers/format.helper';
import { useDocumentsContext } from '../../../../hooks';
import { defaultPageSize } from '../../../../hooks/useDocuments.hooks';
import { SelectBox } from '../DocumentsGridCells.styles';
import { UI_VALUE_LOOKUP } from './ArchiveCellRenderer.constants';
import { ArchiveSelectionBox } from './ArchiveSelectionBox';

type Props = {
  row: any;
};

const ArchiveCellRenderer: React.FC<Props> = ({ row }: Props): ReactElement => {
  const { informationAlert } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { state, ...actions } = useDocumentsContext();

  const isFolder: boolean = row.type === 'folder';

  const savedArchiveDate = new Date(row.archiveOn);
  const adjustedArchiveDate = new Date(
    savedArchiveDate.getTime() - savedArchiveDate.getTimezoneOffset() * -60000
  );
  const savedArchivePolicy = row?.archivePolicy
    ? row?.archivePolicy
    : 'RESPECT_FOLDER';

  const [fileArchivePolicy, setFileArchivePolicy] =
    useState<string>(savedArchivePolicy);
  const [fileArchiveOn, setFileArchiveOn] = useState<Date | null>(
    row.archiveOn ? adjustedArchiveDate : new Date()
  );

  useEffect(() => {
    if (fileArchivePolicy !== 'RESPECT_FILE') {
      setFileArchiveOn(null);
    }
  }, [fileArchivePolicy]);

  useEffect(() => {
    setFileArchivePolicy(savedArchivePolicy);
    setFileArchiveOn(row.archiveOn ? adjustedArchiveDate : new Date());
  }, [row]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOnCellClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event?.preventDefault();
    event?.stopPropagation();
    if (state?.isPageLocked || state?.isReadOnly) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleDateChange = (date: Date) => {
    setFileArchiveOn(date);
  };

  const handleOnClear = () => {
    setFileArchivePolicy(row.archivePolicy || 'RESPECT_FOLDER');
    setFileArchiveOn(row.archiveOn ? adjustedArchiveDate : new Date());
  };

  const handleOnClose = () => {
    handleOnClear();
    setAnchorEl(null);
  };

  const handleApply = async () => {
    setLoading(true);
    setAnchorEl(null);

    const permissions = {
      ...row.filePermissions,
    };

    delete permissions.id;

    let selectedDate: string | null = null;

    if (fileArchiveOn) {
      const year = fileArchiveOn.getFullYear();
      const month = String(fileArchiveOn.getMonth() + 1).padStart(2, '0');
      const day = String(fileArchiveOn.getDate()).padStart(2, '0');

      selectedDate = `${year}-${month}-${day}`;
    }

    const archive = {
      archivePolicy: fileArchivePolicy,
      archiveOn: selectedDate,
    };

    const filters: any = { ...(state?.selectedFilters || {}) };

    if (
      state?.selectedFilters['funds'].length ===
      state?.filtersData['funds'].length
    ) {
      filters.funds = [];
    }
    if (
      state?.selectedFilters['investors'].length ===
      state?.filtersData['investors'].length
    ) {
      filters.investors = [];
    }
    if (
      state?.selectedFilters['quarters'].length ===
      state?.filtersData['quarters'].length
    ) {
      filters.quarters = [];
    }

    filters.includeArchived = state?.selectedFilters.includeArchived;

    const payload = {
      ...filters,
      page: 1,
      pageSize: state?.pagination.pageSize || defaultPageSize,
      sort: state?.sort || [],
    };

    await setDocControlPermissions([row.id], permissions, archive)
      .then(() => {
        actions.fetchFolderContent(state?.currentFolder?.id, payload, false);
      })
      .catch((error) => {
        informationAlert('Error setting document archive policy', 'error');
      });
  };

  const renderReadOnlyComponent = () => {
    return isFolder ? (
      <Typography variant="cells">{M_DASH_UNICODE}</Typography>
    ) : (
      <Typography variant="cells">
        {fileArchivePolicy === 'RESPECT_FILE'
          ? `Archive on: ${DateTimeFormat.getFormattedDate(
              adjustedArchiveDate,
              '/'
            )}`
          : UI_VALUE_LOOKUP[savedArchivePolicy]}
      </Typography>
    );
  };

  return row.isSelected ? (
    !loading ? (
      <>
        <SelectBox onClick={handleOnCellClick}>
          <Typography variant="cells">
            {row.archivePolicy === 'RESPECT_FILE'
              ? `Archive on: ${DateTimeFormat.getFormattedDate(
                  adjustedArchiveDate,
                  '/'
                )}`
              : UI_VALUE_LOOKUP[savedArchivePolicy]}
          </Typography>
          <ArrowDropDown />
        </SelectBox>
        <ArchiveSelectionBox
          anchorEl={anchorEl}
          id={row.id}
          fileArchivePolicy={fileArchivePolicy}
          setFileArchivePolicy={setFileArchivePolicy}
          fileArchiveOn={fileArchiveOn}
          setFileArchiveOn={setFileArchiveOn}
          handleDateChange={handleDateChange}
          handleApply={handleApply}
          handleOnClose={handleOnClose}
        />
      </>
    ) : (
      <CircularProgress />
    )
  ) : (
    <Box>{renderReadOnlyComponent()}</Box>
  );
};

export default ArchiveCellRenderer;
