import {
  Box,
  Container,
  Grid,
} from "@mui/material";
import React, { ReactElement } from "react";

import { M_DASH_UNICODE } from "../../../../../utils/constants/constants";
import {
  ContactAddress,
  ContactInfo,
} from "../../../../../utils/types/contactDetail.type";
import { Address, ContactPermissionDetail } from "../../../../../utils/types/contactPermission.type";
import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from "./ContactDetails.styles";

type Props = {
  valuesContactDetail?: ContactInfo | ContactPermissionDetail;
  addressValues?: ContactAddress | Address;
};

const ReadOnlyContactDetails: React.FC<Props> = (props: Props): ReactElement => {
  const {
    valuesContactDetail,
    addressValues,
  } = props;

  return (
    <Container id="container_contact_main" role="main">
      <Box>
        <KeyValuePair>
          <ValueLabel>{valuesContactDetail?.contactType || M_DASH_UNICODE}</ValueLabel>
        </KeyValuePair>
        <KeyValuePair>
          <KeyLabel>Name</KeyLabel>
          <ValueLabel>{valuesContactDetail?.fullName || M_DASH_UNICODE}</ValueLabel>
        </KeyValuePair>
        <KeyValuePair>
          <KeyLabel>Email</KeyLabel>
          <ValueLabel>{valuesContactDetail?.email || M_DASH_UNICODE}</ValueLabel>
        </KeyValuePair>
        <KeyValuePair>
          <KeyLabel>Phone</KeyLabel>
          <ValueLabel>
            {valuesContactDetail?.fullPhoneNumber || M_DASH_UNICODE}
          </ValueLabel>
        </KeyValuePair>
        <KeyValuePair>
          <KeyLabel>Organization</KeyLabel>
          <ValueLabel>{valuesContactDetail?.organization || M_DASH_UNICODE}</ValueLabel>
        </KeyValuePair>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>Address Line 1</KeyLabel>
              <ValueLabel>
                {addressValues?.street1 || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>Address Line 2</KeyLabel>
              <ValueLabel>
                {addressValues?.street2 || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>Zip/Post-Code</KeyLabel>
              <ValueLabel>
                {addressValues?.postalCode || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>City</KeyLabel>
              <ValueLabel>
                {addressValues?.city || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>State/Province/Region</KeyLabel>
              <ValueLabel>
                {addressValues?.state || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyValuePair>
              <KeyLabel>Country</KeyLabel>
              <ValueLabel>
                {addressValues?.country || M_DASH_UNICODE}
              </ValueLabel>
            </KeyValuePair>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ReadOnlyContactDetails;
