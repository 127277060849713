import Close from "@mui/icons-material/Close";
import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    padding: theme.spacing(2, 3),
    boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.25)",
    borderRadius: theme.spacing(1),
    maxWidth: 800,
  },
  "&  .MuiBackdrop-root": {
    backgroundColor: theme.palette.common.modalBackground,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: 800,
  fontSize: theme.typography.pxToRem(34),
  lineHeight: 1.1,
  color: theme.palette.text.primary,
  padding: 16,
  marginBottom: theme.spacing(2),
  position: "relative",
  alignItems: "center",
}));

export const StyledDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.22,
    color: theme.palette.common.black,
  })
);

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(0),
}));

export const BaseDialogActions = styled(DialogActions)(({ theme }) => ({
  paddingInline: 0,
  marginTop: theme.spacing(5),
}));

export const StyledDialogActions = styled(BaseDialogActions)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "auto",
  gridTemplateColumns: "1fr",
  width: "100%",
  justifyContent: "center",
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 4,
  fontSize: theme.typography.pxToRem(34),
  cursor: "pointer",
}));
