import { Checkbox, Typography } from "@mui/material";
import React from 'react';
import { Controller, UseFormSetValue } from "react-hook-form";
import { Control, UseFormWatch } from "react-hook-form/dist/types/form";

import FileSelector from "../../../components/FileSelector/FileSelectorPreview";
import TitleBox from "../../../components/TitleBox/TitleBox";
import { Client } from "../../../utils/types/client.type";
import {
  CheckboxFormControl,
  CheckboxLabel,
  FileSelectorWrapper,
} from "./ClientDetails.style";
import { FormFieldWrapper, FormTextField } from "./ClientSettingForm/ClientSettingForm.style";

type Props = {
  control: Control<Client, any>;
  watch: UseFormWatch<Client>;
  setValue: UseFormSetValue<Client>;
  onLogoChange: () => void;
}

const ClientLogos = ({
  control,
  watch,
  setValue,
  onLogoChange,
}: Props) => {
  const files = watch("files");
  const faviconUrl = watch("faviconUrl");
  const mainLogoUrl = watch("mainLogoUrl");
  const reportLogoUrl = watch("reportLogoUrl");
  const useFavicon = watch("useFavicon");

  const getReportLogoUrl = (value?: File) => {
    if(files?.mainAsReport) {
      return mainLogoUrl || getImage(files?.mainLogo);
    }
    return reportLogoUrl || getImage(value);
  };

  const getImage = (file?: File) => {

    if(!file) {
      return undefined;
    }
    return URL.createObjectURL(file);
  };

  return (
    <TitleBox title="Client Logos">
      <Controller
        name="files.mainLogo"
        render={({
          field: {
            onChange, value
          }
        }) => {
          return (
            <FileSelectorWrapper>
              <Typography variant="formTitle2">Client Header</Typography>
              <FileSelector
                selectNewImageOnChange={true}
                fileHandler={(newValue?: string, files?: any[]) => {
                  if(newValue) {
                    setValue("mainLogoUrl", "");
                  }
                  onChange(files?.[0]);
                  onLogoChange();
                }}
                showDeleteButton={false}
                img={mainLogoUrl || getImage(value)}
                previewWidth="400px"
                previewHeight="250px" />
            </FileSelectorWrapper>
          );
        }}
        control={control}
      />
      <FileSelectorWrapper>
        <Typography variant="formTitle2"> Client Reports </Typography>
        <Controller
          name="files.mainAsReport"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CheckboxFormControl fullWidth>
                <CheckboxLabel>
                  <Checkbox
                    id="same_as_header_logo"
                    checked={value || false}
                    onChange={(e) => {
                      onChange(e);
                      onLogoChange();
                    }} disabled={!mainLogoUrl && !files?.mainLogo} />
                    Same as Client Header Logo</CheckboxLabel>
              </CheckboxFormControl>
            );
          }}
          control={control}
        />
        <br />
        <Controller
          name="files.reportLogo"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <FileSelector
                showDeleteButton={false}
                selectNewImageOnChange={true}
                fileHandler={(newValue?: string, files?: any[]) => {
                  if(newValue) {
                    setValue("reportLogoUrl", "");
                  }
                  onChange(files?.[0]);
                  onLogoChange();
                }}
                img={getReportLogoUrl(value)}
                previewWidth="400px"
                previewHeight="250px" />
            );
          }}
          control={control}
        />
      </FileSelectorWrapper>
      <FileSelectorWrapper>
        <Typography variant="formTitle2">Favicon</Typography>
        <Controller
          name="useFavicon"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <CheckboxFormControl fullWidth>
                <CheckboxLabel>
                  <Checkbox
                    id="use_favicon"
                    checked={value || false}
                    onChange={(e) => {
                      onChange(e);
                      onLogoChange();
                    }} />
                        Add Custom Favicon</CheckboxLabel>
              </CheckboxFormControl>
            );
          }}
          control={control}
        />
        <br />
        <Controller
          name="tabName"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <FormFieldWrapper>
                <FormTextField
                  id="tab_name"
                  label="Tab Name"
                  variant="outlined"
                  size="small"
                  value={value || ''}
                  onChange={onChange}
                />
              </FormFieldWrapper>
            );
          }}
          control={control}
        />
        <br />
        <Controller
          name="files.favicon"
          render={({
            field: {
              onChange, value
            }
          }) => {
            return (
              <FileSelector
                fileHandler={(newValue?: string, files?: any[]) => {
                  if(!newValue) {
                    setValue("faviconUrl", "");
                  }
                  onChange(files?.[0]);
                  onLogoChange();
                }}
                showDeleteButton={false}
                img={faviconUrl || getImage(value)}
                disabled={!useFavicon}
                previewWidth="400px"
                previewHeight="250px" />
            );
          }}
          control={control}
        />
      </FileSelectorWrapper>
    </TitleBox>
  );
};

export default ClientLogos;
