import { Funds, Transactions } from './distributions.type';
import { NameIdPair } from './transaction.type';

export enum Statuses {
  PUBLISHED = 'published',
  REVIEWING = 'reviewing',
  INACTIVE = 'inactive',
  EDITING = 'editing',
}

export type CapitalCalls = {
  amount: number | null;
  clientId?: string | null;
  createdBy?: string | null;
  createdOn?: Date | null;
  date?: Date | string;
  documentId?: string | null;
  emailDocumentId?: string | null;
  fullyFunded?: boolean;
  fundId?: string;
  fundName?: string;
  id?: string;
  index?: Number;
  name?: string;
  reviewers?: { name: string }[] | null;
  status?: Statuses | string;
  transactions?: any[] | null;
};

export type TeamMembers = {
  ASSOCIATE?: string[] | [];
  FUND_CONTROLLER?: string[] | [];
  FUND_USER_READ_ONLY?: string[] | [];
  MANAGER?: string[] | [];
  DIRECTOR?: string[] | [];
};

export enum CapitalCallsFilter {
  Statuses = 'Statuses',
}

export type CapitalCallId = {
  capitalCallId: string;
};

export type CapitalCallDocument = {
  document?: {
    fileId: string;
    name: string;
    size: number;
  };
  email?: {
    fileId: string;
    name: string;
    size: number;
  };
};

export type Transaction = {
  id?: string;
  name: string;
  date: Date | string;
  dateString?: Date | string | null;
  displayOrder?: string;
  quarter?: string | null;
  metricSign?: string | null;
  metricFractionDigit?: number;
  amount: number | string;
  components: any[];
  useMetric: boolean;
  fund: string;
  investor: string;
  type: string;
  currentInvestor?: NameIdPair;
  currentType?: NameIdPair;
  isUnsaved?: boolean;
};

export enum TransactionFilter {
  InvestorName = 'investor',
  TransactionType = 'type',
}
export enum TransactionType {
  New = 'new',
  Edit = 'edit',
}

export type SelectedTransaction = {
  transaction?: Transaction;
  type?: TransactionType;
};
export enum TransactionCRUDStatus {
  Adding = 'Adding',
  Updating = 'Updating',
  Deleting = 'Deleting',
}

export enum TransactionAction {
  DeleteSelected = 'delete_selected',
  DeleteAll = 'delete_all',
}

export type Component = {
  id: string;
  label: string;
  mergeTag: string;
  tenantId: string;
  value: string;
};

export enum DownloadAction {
  DownloadAll = 'download-all',
}

export type Reviews = {
  approvalId: string;
  canApprove: boolean;
  checked: boolean | null;
  contactEmails: string[];
  creator: false;
  documentId: string;
  investorName: string;
  order: number;
  role: string;
  transactionId: string;
  username: string;
};

export type StatusType = {
  status?: boolean | null;
};

type approvalType = {
  approved: boolean | null;
  id: string;
  role: string;
  tenantId: string;
  username: string;
};

type ReviewType = {
  approvals: approvalType[];
  clientId: string | null;
  id: string;
  investor: string;
  order: number;
  transaction: string | null;
};

export type ReviewsSummary = {
  createdBy: string;
  fund: string;
  fundId: string;
  hasInactiveContact: boolean;
  id: string;
  readyForPublish: boolean;
  reviews: ReviewType[];
  role: string;
};

export type PortalCapitalCalls = {
  investorId: string;
  investorName: string;
  funds: Funds[];
};

export type ManipulatedPortalCapitalCalls = {
  amount: number;
  callDate: string;
  currencyCode: string;
  documentId: string | null;
  dueDate?: string;
  fundId: string;
  fundName: string;
  funds: Funds[];
  id: string;
  investorId: string;
  investorName: string;
  name: string;
  transactionName?: string;
  transactions: Transactions[];
  uid: string;
};

export type FundFilter = {
  id: string;
  inceptionDate: string;
  investors: string[];
  name: string;
};

export type InvestorFilter = {
  funds: string[];
  id: string;
  joinedDate?: string;
  name: string;
};

export enum PortalCapCallExportActions {
  AllCapCallReportExcel = 'all_capcall_report_excel',
  AllCapCallReportPDF = 'all_capcall_report_pdf',
}
