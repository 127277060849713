import { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import { AppContext } from "../../../../core/context/appContextProvider";
import { getCountryList, getStateList } from "../../../../services/address.service";
import { createNewEntity, updateEntity } from "../../../../services/arkGL.service";
import { useEffectAsync } from "../../../../utils/hooks/useEffectAsync.hook";
import { ArkGLEntity } from "../../../../utils/types/arkGLEntity.type";
import { COUNTRY_LIST_ERROR, CREATE_ENTITY_ERROR, CREATE_ENTITY_SUCCESS, DEFAULT_ENTITY_TYPES, ENTITY_FORM_DEFAULT_VALUE, STATE_LIST_ERROR, UPDATE_ENTITY_ERROR, UPDATE_ENTITY_SUCCESS } from "./EntityDetails.constants";

type Props = {
  entity?: ArkGLEntity;
  entityResponse?: ArkGLEntity[];
  onClose: Function;
  fetchAllEntities: Function;
  isNewEntity: boolean;
  onCreate?: Function;
};

export const useEntityDetails = ({
  entity,
  entityResponse,
  onClose,
  fetchAllEntities,
  isNewEntity,
  onCreate
}: Props) => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showExitConfirmation, setShowExitConfirmation] =
    useState<boolean>(false);
  const [stateList, setStateList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [entityTypeList, setEntityTypeList] = useState<any[]>([]);


  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    getValues,
    setValue,
    watch,
    trigger,
    reset,
  } = useForm<ArkGLEntity>({
    defaultValues: entity ?? ENTITY_FORM_DEFAULT_VALUE,
  });

  useEffect(() => {
    const uniqueEntityTypes = new Set(DEFAULT_ENTITY_TYPES);

    entityResponse
      ?.filter((entity) => entity.type !== null)
      .map((entity) => uniqueEntityTypes.add(entity.type));

    setEntityTypeList(Array.from(uniqueEntityTypes));
  }, [entityResponse]);

  const mailingCountry = watch('mailingAddress.country');
  const mailingState = watch('mailingAddress.state');

  const { isDirty } = useFormState({ control });

  const createUpdateEntity = async (data: ArkGLEntity) => {
    data.type = data.type.trim();
    data.name = data.name?.trim();
    if (data.emailAddress === "") {
      delete data.emailAddress;
    }
    if (isNewEntity) {
      setIsLoading(true);
      await createEntity({
        ...data,
      });
    } else {
      setIsLoading(true);
      await editEntity(entity?.id, data);
    }
    await fetchAllEntities();
    setIsLoading(false);
  };

  const createEntity = async (data: any) => {
    setIsLoading(true);
    try {
      if (data.emailAddress === "") {
        delete data.emailAddress;
      }

      const response = await createNewEntity(data);

      onCreate?.(response.id);
      await fetchAllEntities();
      informationAlert(CREATE_ENTITY_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(CREATE_ENTITY_ERROR, "error");
    }
    setIsLoading(false);
  };

  const editEntity = async (id: any, data: any) => {
    setIsLoading(true);
    try {
      if (data.emailAddress === "") {
        delete data.emailAddress;
      }
      await updateEntity(id, data);
      await fetchAllEntities();
      informationAlert(UPDATE_ENTITY_SUCCESS, "success");
      closeDrawer();
    } catch (error) {
      informationAlert(UPDATE_ENTITY_ERROR, "error");
    }
    setIsLoading(false);
  };

  const toggleDrawer = () => {
    if (isDirty) {
      setShowExitConfirmation(true);
    } else {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    reset();
    onClose();
    setShowExitConfirmation(false);
  };

  const entityTypeValidator = (value: string): string | undefined => {
    if (value.trim()) {
      /^.{1,32}$/.test(value)
        ? true
        : "Check entry type format. Max 32 character limit.";
    } else return "Entry type is Required";
  };

  useEffectAsync(async (isCanceled) => {
    try {
      const countriesResponse = await getCountryList();

      if (isCanceled()) return;

      countriesResponse.some(
        (country, idx) =>
          country.id === "US" &&
          countriesResponse.unshift(countriesResponse.splice(idx, 1)[0])
      );

      setCountryList(countriesResponse);

    } catch (e) {
      informationAlert(COUNTRY_LIST_ERROR, "error");
    }
  }, []);

  useEffectAsync(
    async (isCanceled) => {
      try {
        if (isCanceled()) return;
        setIsLoading(true);
        if (mailingCountry) {
          const statesResponse = await getStateList(mailingCountry);

          setStateList(statesResponse);
        }
      } catch (e) {
        informationAlert(STATE_LIST_ERROR, "error");
      } finally {
        setIsLoading(false);
      }
    }, [mailingCountry, mailingState]);

  const keepDrawerOpen = () => {
    setShowExitConfirmation(false);
  };

  return {
    isLoading,
    toggleDrawer,
    handleSubmit,
    createUpdateEntity,
    control,
    isSubmitted,
    trigger,
    getValues,
    setValue,
    register,
    errors,
    entityTypeList,
    entityTypeValidator,
    countryList,
    stateList,
    showExitConfirmation,
    keepDrawerOpen,
    closeDrawer,
  };
};