import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DataWrapperBox } from "../../../components/Boxes/DataWrapperBox/DataWrapperBox.style";
import SplitButton from "../../../components/Buttons/SplitButton/SplitButton";
import ButtonWithOptions from "../../../components/ButtonWithOptions/ButtonWithOptions";
import DataGrid from "../../../components/DataGrid/DataGrid";
import ConfirmationDialog from "../../../components/Modal/ConfirmationDialog";
import DeleteConfirmationDialog from "../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { PageLock } from "../../../components/PageLock/PageLock";
import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import RoutingPaths from "../../../core/routing/routingPaths";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { TransactionOptions } from "./constants";
import {
  DELETE_ACTION_TYPE
} from "./constants";
import TransactionDetails from "./transactionDetails/TransactionDetails";
import { ButtonBox, HeaderRow } from "./TransactionList.styles";
import { useTransactions } from "./useTransactions.hooks";

const TransactionList: React.FC = (): ReactElement => {
  const {
    headerList,
    isLoadingList,
    transactionList,
    activeHeaderFields,
    handleUpdateHeader,
    handleFilter,
    transactionSelectionModel,
    setTransactionSelectionModel,
    handleOnView,
    onNextPage,
    bulkActions,
    handleBulkAction,
    isLoading,
    toggleIsLocked,
    isLocked,
    lockedTooltipText,
    handleNewButtonAction,
    uploadedFile,
    clearUploadedFile,
    handleUploadTemplate,
    isUploadComplete,
    clearUploadCompleted,
    selectedTransaction,
    onTransactionDetailClose,
    resetPaginationAndFetch,
    deleteAction,
    onDeleteCancel,
    onDeleteConfirm,
    isSuperAdminOrClientAdmin,
    fetchTransactionCount
  } = useTransactions();
  const history = useHistory();

  const [transactionCount, setTransactionCount] = useState<string | undefined>(undefined);
  const [isCountingTransactions, setIsCountingTransactions] = useState(false);

  useEffectAsync(async (isCanceled) => {

    if (deleteAction === DELETE_ACTION_TYPE.DELETE_ALL) {
      setIsCountingTransactions(true);
      const transactionCount = await fetchTransactionCount();

      if (isCanceled()) return;

      setTransactionCount(transactionCount);
      setIsCountingTransactions(false);
    } else if (deleteAction === DELETE_ACTION_TYPE.DELETE_SELECTED) {
      setTransactionCount(transactionSelectionModel.length.toString());
    } else {
      setTransactionCount(undefined);
    }
  }, [isLoadingList, deleteAction]);

  return (
    <DataWrapperBox id="transaction_list_main">
      <Progress
        id="transaction_detail_list_loading"
        showProgress={isLoadingList || isLoading}
      />
      <HeaderRow>
        <Typography variant="pageTitle">Transaction</Typography>
        <ButtonBox>
          {transactionList && transactionList?.length > 0 && (
            <SplitButton
              id={"btn_bulk_action_options"}
              options={bulkActions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          )}
          {isSuperAdminOrClientAdmin && (
            <ButtonWithOptions
              buttonID="add_transaction_button"
              popoverID="add_transaction_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={TransactionOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <DataGrid
        id="transaction_data_grid"
        dataList={transactionList || []}
        headerList={headerList}
        noDataMessage={
          isLoadingList || isLoading ? "Fetching Data..." : undefined
        }
        minHeight="1vh"
        autoHeight={false}
        handleOnView={handleOnView}
        selectionModel={transactionSelectionModel}
        setSelectionModel={setTransactionSelectionModel}
        activeHeaderFields={activeHeaderFields}
        handleUpdateHeader={handleUpdateHeader}
        handleFilter={handleFilter}
        onNextPage={onNextPage}
        hideSelectAll={true}
      />
      {isSuperAdminOrClientAdmin && (
        <PageLock
          locked={isLocked}
          showTooltip={Boolean(lockedTooltipText)}
          tooltipText={lockedTooltipText}
          disabled={false}
          onChange={toggleIsLocked}
        />
      )}
      {!!selectedTransaction?.type && (
        <TransactionDetails
          selectedTransaction={selectedTransaction}
          resetPaginationAndFetch={resetPaginationAndFetch}
          onTransactionDetailClose={onTransactionDetailClose}
        />
      )}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="upload-confirmation"
        actions={[
          {
            label: "Upload",
            onClick: handleUploadTemplate,
            id: "upload-button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Cancel",
            onClick: clearUploadedFile,
            id: "cancel-button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{" "}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="upload-complete-confirmation"
        actions={[
          {
            label: "Upload History",
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: "upload-history-button",
            variant: "contained",
            color: "primary",
          },
          {
            label: "Close",
            onClick: clearUploadCompleted,
            id: "close-button",
            variant: "outlined",
            color: "error",
          },
        ]}
        content="Transactions file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the import."
        title="Upload Results"
      />

      {isCountingTransactions ?
        (
          <Progress
            id="transaction_detail_list_loading"
            showProgress={true}
          />
        ) : (
          <DeleteConfirmationDialog
            open={Boolean(deleteAction)}
            id="delete-confirmation-modal"
            primaryButtonAction={onDeleteConfirm}
            secondaryButtonAction={onDeleteCancel}
            transactionCount={transactionCount}
          />
        )}
    </DataWrapperBox>
  );
};

export default React.memo(TransactionList);
