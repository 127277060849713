import { Grid, Typography } from "@mui/material";
import React from "react";

import PendingChangesView from "../../../../../components/PendingChangesView/PendingChangesView";
import { KeyLabel, KeyValuePair, ValueLabel } from "./ContactDetails.styles";
import {
  Bold700,
  Container,
  DiffSection,
  FundName,
  HBox,
  ItalicLabel,
  ListContainer,
  PendingInfoDot,
  SectionTitle,
  SubSectionTitle,
  Text,
  VBox,
} from "./ContactPendingView.styles";

function ContactPendingView({
  selectedContact,
  approvePendingChanges,
  declinePendingChanges,
  investorFundRoles,
}: any) {
  const funds: Array<React.ReactElement> = investorFundRoles?.reduce(
    (acc: Array<React.ReactElement>, investor: any) => {
      for (const fund of investor?.funds ?? []) {
        acc.push(
          <FundName key={investor.id + fund.id}>
            <Bold700>{investor.name} -</Bold700> {fund.name}
          </FundName>
        );
      }
      return acc;
    },
    []
  );

  const isPhoneNumberChanged =
    selectedContact.fullPendingPhoneNumber !== selectedContact.fullPhoneNumber;

  return (
    <Container>
      <PendingChangesView
        declineChanges={declinePendingChanges}
        approveChanges={approvePendingChanges}
        title={"Pending Changes"}
      >
        <ListContainer>
          {isPhoneNumberChanged && (
            <VBox>
              <HBox>
                <SectionTitle>Phone Number</SectionTitle>
              </HBox>
              <DiffSection>
                <VBox>
                  <SubSectionTitle>
                    <ItalicLabel>Current</ItalicLabel>
                  </SubSectionTitle>
                  <Text>{selectedContact.fullPhoneNumber}</Text>
                </VBox>
                <VBox>
                  <Typography style={{ display: "flex" }}>
                    <div>
                      <PendingInfoDot />
                      <ItalicLabel>Pending</ItalicLabel>
                    </div>
                  </Typography>
                  <div>{selectedContact.fullPendingPhoneNumber}</div>
                </VBox>
              </DiffSection>
            </VBox>
          )}
          {funds.length > 0 && (
            <VBox>
              <HBox>
                <SectionTitle>Permissions</SectionTitle>
              </HBox>
              <DiffSection>
                <VBox>{funds}</VBox>
              </DiffSection>
            </VBox>
          )}
        </ListContainer>
      </PendingChangesView>
    </Container>
  );
}

export default ContactPendingView;
