import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactElement } from "react";
import React from "react";
import { Route, Switch, useParams } from "react-router-dom";

import Progress from "../../../components/Progress/ProgressModal/ProgressModal";
import { 
    StyledTab,
    StyledTabs 
} from "../../../components/TabsPanel/TabsPanel.styles";
import { TabsRow } from "../../users/Users.styles";
import AddTransaction from "./AddTransaction/AddTransaction";
import { DistributionDetailsTabs } from "./Constants";
import {
  DistributionWrapper,
  HeaderBreadcrumbContainer,
  HeaderRow,
  HeaderTitleContainer
} from "./DistributionDetails.style";
import DistributionSetup from "./DistributionSetUp/DistributionSetUp";
import Publish from "./Publish/Publish";
import ReviewDocument from "./ReviewDocument/ReviewDocument";
import { UseDistributionDetails } from "./useDistributionDetails.usehook";

interface RouteProp {
  id: string;
}

const DistributionDetails: React.FC = (): ReactElement => {

  const {
    tab,
    handleClose,
    loading,
    handleChange
  } = UseDistributionDetails();

  const { id } = useParams<RouteProp>();

  return (
    <DistributionWrapper id="client_list_main">
      <Progress id="distribution_detail_list_loading" showProgress={loading} />
      <HeaderRow>
        <HeaderTitleContainer>
          <IconButton aria-label="Back" onClick={handleClose}>
            <ArrowBackIosRoundedIcon color="primary" fontSize="large" />
            <Typography variant="h3">Return to Distributions</Typography>
          </IconButton>
          <HeaderBreadcrumbContainer></HeaderBreadcrumbContainer>
          <Box />
        </HeaderTitleContainer>
      </HeaderRow>
      <TabsRow>
        <StyledTabs
          id="distribution_detail_tabs"
          value={tab}
          onChange={id !== "new" ? handleChange : () => {}}
          aria-label="distribution Details Tabs"
        >
          <StyledTab key={0} label={DistributionDetailsTabs.DistributionSetup} />
          <StyledTab key={1} label={DistributionDetailsTabs.AddTransactions} />
          <StyledTab key={2} label={DistributionDetailsTabs.ReviewDocuments} />
          <StyledTab key={3} label={DistributionDetailsTabs.Publish} />
        </StyledTabs>
      </TabsRow>
      <Box>
        <Switch>
          <Route
            path={`/distributions/:id/${DistributionDetailsTabs.DistributionSetup}`}
            exact
          >
            <DistributionSetup onNavigateAwayCanceled={() => { handleChange(undefined, 0); }}/>
          </Route>
          <Route
            path={`/distributions/:id/${DistributionDetailsTabs.AddTransactions}`}
            component={AddTransaction}
            exact
          />
          <Route
            path={`/distributions/:id/${DistributionDetailsTabs.ReviewDocuments}`}
            component={ReviewDocument}
            exact
          />
          <Route
            path={`/distributions/:id/${DistributionDetailsTabs.Publish}`}
            component={Publish}
            exact
          />
        </Switch>
      </Box>
    </DistributionWrapper>
  );
};

export default React.memo(DistributionDetails);