import { useContext } from "react";

import packageJson from "../../../package.json";
import { ReactComponent as ARKPES } from "../../../src/assets/images/logos/ark_pes_logo.svg";
import { ClientThemeContext } from "../../core/context/clientThemeContext";
import {
  FooterSection,
  HeaderDiv,
  HeaderGrid,
  LeftSection,
  StyledSVG,
} from "./Login.styles";

const { version } = packageJson;

const LoginSidebar = ({ headers, children }: any) => {
  const { clientTheme } = useContext(ClientThemeContext);
  const currentYear = new Date().getFullYear();

  return (
    <LeftSection
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      background={clientTheme?.loginBackgroundColor}
      textcolor={clientTheme?.loginTextColor}
      style={{ height: "100%" }}
    >
      <HeaderGrid
        container
        justifyContent="center"
        rowSpacing={1}
        direction="row"
      >
        <HeaderDiv>{headers}</HeaderDiv>
      </HeaderGrid>
      {children}
      <FooterSection textcolor={clientTheme?.loginTextColor}>
        <StyledSVG svgcolor={clientTheme?.loginTextColor}>
          <ARKPES />
        </StyledSVG>
        Copyright © Ark PES, 2017-{currentYear}. All rights reserved.
        <div>
          {version}
          <a
            className="privacy-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.arkpes.com/privacy/"
          >
            Privacy Policy
          </a>
        </div>
      </FooterSection>
    </LeftSection>
  );
};

export default LoginSidebar;
