import { useTransactionTypesEffect } from "../../../services/hooks/useTransactionTypesEffect/useTransactionTypesEffect.hooks";
//TODO: Added a hook as this component may need some extra handling in future.

const useTransactionTypesFilter = () => {
  const {
    transactionTypesList,
    loading
  } = useTransactionTypesEffect();

  return {
    transactionTypesList,
    loading,
  };
};

export default useTransactionTypesFilter;

