import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Fade, Tooltip } from '@mui/material';
import { GridColumnOrderChangeParams } from '@mui/x-data-grid';
import { useContext, useMemo, useState } from 'react';

import { AppContext } from '../../../core/context/appContextProvider';
import {
  getColumnOrder,
  saveColumnOrder,
} from '../../../services/columnOrder.service';
import { DOCUMENT_SUB_FOLDERS_ORDER_VIEW_KEY } from '../../../utils/constants/text.constants';
import {
  arrayIndexUpdate,
  arrayVisibilityUpdate,
} from '../../../utils/helpers/columnOrder.helper';
import { useEffectAsync } from '../../../utils/hooks/useEffectAsync.hook';
import useSessionStorage from '../../../utils/hooks/useSessionStorage';
import { ColumnOrder } from '../../../utils/types/columnOrder';
import { ColumnWidths, Permission } from '../../../utils/types/documents.type';
import { DataGridColDef } from '../../../utils/types/listItems';
import {
  ArchiveColDef,
  ColumnFilterColDef,
  CopyColDef,
  DownloadColDef,
  FileSizeColDef,
  FundColDef,
  InvestorColDef,
  PermissionsColDef,
  PrintColDef,
  PublishStatusColDef,
  QuarterPeriodColDef,
  RootNameColDef,
  SubNameColDef,
  WaterMarkColDef,
} from '../components/grid/columns/ColumnDef';
import { DownloadButton } from '../Documents.styles';
import { DocumentsState } from '../types';

type HeaderListProps = {
  handleOnChipClick: () => void;
  isEditable: boolean;
  isAdmin: boolean;
  clientId: string;
  currentlyDownloadingIds: string[];
};

const docColumnWidths: ColumnWidths = {};

const none = {
  key: '(none)',
  name: '(none)',
};

type None = {
  key: string;
  name: string;
};

function useDocumentsGridHeaders(
  state: DocumentsState,
  {
    handleOnChipClick = () => {},
    isEditable = false,
    isAdmin = true,
    clientId,
    currentlyDownloadingIds,
  }: HeaderListProps
) {
  const { informationAlert } = useContext(AppContext);

  const isReadOnly = state.isReadOnly;
  const { funds, investors, quarters } = state.filtersData;
  const selectedNames: Array<string> = state.selectedNameFilter;
  const selectedInvestors = state.selectedFilters.investors;
  const selectedFunds = state.selectedFilters.funds;
  const selectedQuarters = state.selectedFilters.quarters;
  const permissions: Array<Permission | None> | null | undefined = [
    ...(state.permissions || []),
    none,
  ];
  const selectedPermissions = state.selectedPermissionsFilter;
  const searchText = state.selectedFilters.searchText;
  const showFolders = state.selectedFilters.showFolders;
  const sort = state.sort;
  const names = [
    ...(state.currentFolder?.folders?.map((folder) => ({
      name: folder.name,
      id: folder.id,
    })) || []),
    ...(state.currentFolder?.publishedFiles?.files?.map((file) => ({
      name: file.name,
      id: file.id,
    })) || []),
    ...(state.currentFolder?.unPublishedFiles?.files?.map((file) => ({
      name: file.name,
      id: file.id,
    })) || []),
  ];

  const [subHeaderList, setSubHeaderList] = useState<Array<DataGridColDef>>([]);
  const [activeHeaderFields, setActiveHeaderFields] = useState(
    subHeaderList.length - 1
  );
  const [viewConfig, setViewConfig] = useState<ColumnOrder | null>(null);

  const [columnWidths, setColumnWidths] = useSessionStorage(
    'arkDocColWidths',
    docColumnWidths
  );

  const isRootFolder = state.cursor?.length === 0 ? true : false;

  useEffectAsync(async (isCanceled) => {
    try {
      const colOrderResponse = await getColumnOrder(
        DOCUMENT_SUB_FOLDERS_ORDER_VIEW_KEY,
        clientId
      );

      if (isCanceled()) return;

      setViewConfig(colOrderResponse);
    } catch (e) {
      informationAlert('Error retrieving initial order of columns.', 'error');
    }
  }, []);

  const [rootHeaders, subHeaders] = useMemo(() => {
    const rootHeaders: Array<DataGridColDef> = [RootNameColDef];
    const subHeaders: DataGridColDef[] = [
      {
        ...SubNameColDef,
        inlineToggleSwitch: showFolders,
        inlineFilterOptions: names,
        inlineFilterSelected: selectedNames,
      },
      {
        ...InvestorColDef,
        inlineFilterOptions: investors,
        inlineFilterSelected: selectedInvestors,
      },
      {
        ...FundColDef,
        inlineFilterOptions: funds,
        inlineFilterSelected: selectedFunds,
      },
      {
        ...QuarterPeriodColDef,
        inlineFilterOptions: quarters,
        inlineFilterSelected: selectedQuarters,
      },
      FileSizeColDef,
    ];

    if (isAdmin) {
      if (isEditable) {
        rootHeaders.push(
          {
            ...PermissionsColDef,
            onChipClick: handleOnChipClick,
            inlineFilterOptions: permissions,
            inlineFilterSelected: selectedPermissions,
            disableReorder: true,
          },
          {
            ...WaterMarkColDef,
            inlineFilterOptions: permissions,
            inlineFilterSelected: selectedPermissions,
            flex: isRootFolder ? 1 : 0,
          }
        );
        subHeaders.push({
          ...WaterMarkColDef,
          flex: isRootFolder ? 1 : 0,
        });
      } else {
        rootHeaders.push({
          ...PermissionsColDef,
          inlineFilterOptions: permissions,
          inlineFilterSelected: selectedPermissions,
        });
      }
      subHeaders.push(
        DownloadColDef,
        PrintColDef,
        CopyColDef,
        PublishStatusColDef,
        ArchiveColDef,
        {
          ...ColumnFilterColDef,
          renderCell: ({ row }) => {
            return (
              <DownloadButton
                isDownloading={state.isDownloading}
                currentlyDownloadingIds={currentlyDownloadingIds}
                row={row}
                isReadOnly={isReadOnly}
              />
            );
          },
        }
      );
    } else {
      subHeaders.push({
        ...ColumnFilterColDef,
        renderHeader: () => {
          return <></>;
        },
        renderCell: ({ row }) => {
          if (row.type === 'file' && !row.filePermissions?.downloadEnabled) {
            return (
              <Tooltip
                title="This document is not available for download."
                placement="left"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={500}
              >
                <FileDownloadOutlinedIcon color="disabled" />
              </Tooltip>
            );
          } else {
            return (
              <DownloadButton
                isDownloading={state.isDownloading}
                currentlyDownloadingIds={currentlyDownloadingIds}
                row={row}
                isReadOnly={isReadOnly}
              />
            );
          }
        },
      });
    }

    let sortedSubHeaders: any;

    if (viewConfig && viewConfig.viewItems) {
      viewConfig.viewItems.map(
        (item: { code: any; order: number; visible: any }) => {
          const header = subHeaders.find(
            (header: any) => header.field === item.code
          );

          if (header) {
            header.index = item.order;
            header.hide = !item.visible;
            if (
              header.field !== 'action' &&
              columnWidths.hasOwnProperty(header.field)
            ) {
              header.width = columnWidths[header.field];
            }
          }
        }
      );

      sortedSubHeaders = subHeaders.sort((a: any, b: any) =>
        a.index > b.index ? 1 : -1
      );

      const activeHeaders = subHeaders.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    } else {
      sortedSubHeaders = subHeaders.sort(
        (item1: any, item2: any) => item1.index - item2.index
      );
    }

    setSubHeaderList(sortedSubHeaders);

    return [rootHeaders, subHeaders];
  }, [
    isAdmin,
    viewConfig,
    sort,
    showFolders,
    JSON.stringify(selectedNames),
    JSON.stringify(selectedPermissions),
    JSON.stringify(selectedInvestors),
    JSON.stringify(selectedFunds),
    JSON.stringify(selectedQuarters),
    state.isPageLocked,
    state.isDownloading,
    state.isReadOnly,
    currentlyDownloadingIds,
  ]);

  function handleColumnOrderChange(changeParams: GridColumnOrderChangeParams) {
    const newIndex = changeParams.targetIndex;
    const oldIndex = changeParams.oldIndex;

    const columnOrderToSave = arrayIndexUpdate(
      subHeaderList,
      oldIndex - 1,
      newIndex - 1,
      clientId,
      DOCUMENT_SUB_FOLDERS_ORDER_VIEW_KEY
    );

    updateViewConfig(columnOrderToSave);
  }

  const handleUpdateHeader = (field: string) => {
    if (!subHeaderList || subHeaderList?.length === 0) {
      return;
    }

    const activeFields = subHeaderList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );
    const targetField = subHeaderList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) {
      return;
    }

    const updatedHeaders: Array<DataGridColDef> = subHeaderList.map(
      (header) => {
        return {
          ...header,
          hide: header.field === field ? !header.hide : header.hide,
        };
      }
    );

    if (updatedHeaders) {
      setSubHeaderList(updatedHeaders);
      const activeHeaders = subHeaderList.filter((header) => !header.hide);

      setActiveHeaderFields(activeHeaders.length - 1);
    }

    const visiblityUpdate = arrayVisibilityUpdate(
      subHeaderList,
      clientId,
      DOCUMENT_SUB_FOLDERS_ORDER_VIEW_KEY,
      field
    );

    updateViewConfig(visiblityUpdate);
  };

  const updateViewConfig = async (visiblityUpdate: ColumnOrder) => {
    setViewConfig(visiblityUpdate);

    try {
      await saveColumnOrder(visiblityUpdate);
    } catch (e) {
      informationAlert('Error saving column order.', 'error');
    }
  };

  const handleColumnWidthChange = (colDef: any) => {
    if (isRootFolder || !isAdmin) return;
    setColumnWidths((prevColumnWidths: any) => ({
      ...prevColumnWidths,
      [colDef.field]: colDef.width,
    }));
  };

  const currentHeaders = isRootFolder ? rootHeaders : subHeaderList;

  return {
    currentHeaders,
    activeHeaderFields,
    handleColumnOrderChange,
    handleUpdateHeader,
    handleColumnWidthChange,
  };
}

export default useDocumentsGridHeaders;
