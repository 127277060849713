import { MultiSelectItem } from '../components/MultiSelect/MultiSelect';
import { ReportTemplete } from '../utils/types/reports.type';
import { NameIdPair } from '../utils/types/transaction.type';
import { ApiClient, ClientType } from './apiClient/ApiClient';
import { ServiceType } from './apiClient/config';

const apiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Api); };

const reportsApiClient = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.ReportApi); };

const reportsApiV2Client = () => { return ApiClient.Client(ClientType.NotCached, ServiceType.Apiv2); };

export const getReports = async (endpoint: string) => await reportsApiClient().get<any>(endpoint);

export const getReportTemplete = async (clientId: string) => await apiClient().get<ReportTemplete[]>(`clients/${clientId}/reportTemplates`);

export const getTransactionType = async (clientId: string) => await apiClient().get<NameIdPair[]>(`transactions/types/${clientId}`);

export const getFundsName = async () => await apiClient().get<MultiSelectItem[]>(`funds/names/`);

export const getTransactionBuckets = async (clientId: string) => await apiClient().get<NameIdPair[]>(`transactionBuckets/names/${clientId}`);

export const getReportConfigData = async (clientId: string, templeId: string) => await apiClient().get<any>(`clients/${clientId}/reportTemplates/${templeId}`);

export const addReportConfig = async (clientId: string, templeId: string, body: any) => await apiClient().post<any>(`clients/${clientId}/reportTemplates`, body);

export const updateReportConfig = async (clientId: string, templeId: string, body: any) => await apiClient().put<any>(`clients/${clientId}/reportTemplates/${templeId}`, body);

export const deleteReportConfig = async (clientId: string, templeId: string) => await reportsApiV2Client().delete<any>(`/transactions/report-templates/${templeId}?clientId=${clientId}`);