import Switch from '../../../components/Switch/Switch';
import TitleBox from '../../../components/TitleBox/TitleBox';
import { SwitchLabel } from '../../clients/Clients.styles';
import { MultiFactorConfigurationContainer } from "./ClientDetails.style";

type Props = {
    disabled: boolean;
    value: boolean;
    onChange: (value: boolean) => void;
}

const Auth0MigrationClient = ({ disabled, value, onChange }: Props) => {

  return (
    <TitleBox title="Auth0">
      <MultiFactorConfigurationContainer>
      <SwitchLabel
            disabled={disabled}
            control={
            <Switch
                label="Auth0 Migrated Client"
                id={`auth0Migrate`}
                checked={value}                    
                onChange={(e: { target: { checked: any } }) => {
                    if(!disabled) {
                        onChange(e.target.checked);
                    }
                }}
            />
            }
            label="Auth0 Migrated Client"
        />
      </MultiFactorConfigurationContainer>
    </TitleBox>);
};

export default Auth0MigrationClient;