import { Box, Container, Divider, FormControlLabel, Grid, styled } from "@mui/material";

import DataGrid from '../../../../../components/DataGrid/DataGrid';

export const MainStyledContainer = styled(Container)`
    height: 700px;
    width: 875px;
`;

export const ScrollingStyledContainer = styled(Container)`
    height: 600px;
    width: 875px;
    overflow-x: auto;
`;

export const MappingGridStyle = styled(Grid)`
    margin-top: 20px;
`;

export const DetailsGridStyle = styled(Grid)`
    padding-top: 1px; 
    padding-left: 1px;
`;

export const TransactionColumnsGridStyle = styled(Grid)`
    padding-top: 100px;
`;

export const StyledDivider = styled(Divider)`
    width: 95%;
    margin-top: 20px;
    margin-bottom: 8px;
`;

export const StyledCustomColumnWrapper = styled(Box)`
    width:250px;
    margin-top: 30px;
    margin-left: 60px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledIncludeColumns = styled(FormControlLabel)`
    margin-left: 25px;
`;

export const StyledDataGrid = styled(DataGrid)`
  margin: 5px 5px 5px 5px;
  width: 600px;
`;

export const ColumnMappingGridStyle = styled(Grid)`
    padding-left: 80px;
    padding-bottom: 80px; 
`;



export const DragAndDropItemWrapper = styled(Box) `
    display: flex;
    flex-direction: row;
    width: 300px;
    align-items: center
`;