import { Autocomplete, CircularProgress } from '@mui/material';
import React from 'react';

import {
  KeyLabel,
  KeyValuePair,
  ValueLabel,
} from '../../../pages/arkGL/journalEntries/journalEntryDetails/JournalEntryDetails.styles';
import { M_DASH_UNICODE } from '../../../utils/constants/constants';
import TextField from '../../TextField/TextField';
import useJEFundFilterEffect from './useJEFundFilter.hook';

type Props = {
  error?: any;
  value?: any | null | string;
  onChange?: (purchaseTransaction: any | null) => void;
  placeholder?: string;
  idSelectOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  id?: string;
  textFieldID?: string;
  readonly?: boolean;
};

type JEFundValue = any | null | undefined;

export const JEFundFilter = ({
  error,
  placeholder,
  onChange,
  value,
  idSelectOnly = false,
  disabled,
  size = 'small',
  id = 'fund_filter',
  textFieldID = 'txt_fund',
  readonly = false,
}: Props) => {
  const { fundList, loading } = useJEFundFilterEffect();

  const onSelectChange = (_: any, fund: any) => {
    if (!fund) {
      return;
    }
    onChange?.(idSelectOnly ? fund?.id ?? '' : fund);
  };

  const selectedValue: JEFundValue = idSelectOnly
    ? fundList?.find((item: any) => item.id === value) ?? null
    : (value as JEFundValue);

  const readOnlyValue: JEFundValue = fundList?.find(
    (item: any) => item.id === value
  );

  if (readonly) {
    return (
      <KeyValuePair>
        <KeyLabel>Fund</KeyLabel>
        <ValueLabel>{readOnlyValue?.name || M_DASH_UNICODE}</ValueLabel>
      </KeyValuePair>
    );
  } else {
    return (
      <Autocomplete
        id={id}
        options={fundList || []}
        getOptionLabel={(option: any) => option?.name ?? ''}
        isOptionEqualToValue={(option: any, selected: any) =>
          option?.name === selected?.name
        }
        onChange={onSelectChange}
        value={selectedValue ?? {}}
        size={size}
        disabled={disabled || loading}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              label={placeholder}
              id={textFieldID}
              error={!!error}
              helperText={error?.message ?? ''}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          </>
        )}
      />
    );
  }
};

export default JEFundFilter;
