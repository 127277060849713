import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import RoutingPaths from "../../../core/routing/routingPaths";
import { useCapitalCallDetailsEffect } from "../../../services/hooks/useCapitalCallsEffect/useCapitalCallDetailsEffect.hooks";
import { CapitalCallDetailsTabs } from "./constants";

interface RouteProp {
  id: string;

  section: string;
}

const getIndexBySection = (section?: string) => {
  switch (section) {
    case CapitalCallDetailsTabs.AddTransactions:
      return 1;
    case CapitalCallDetailsTabs.ReviewDocuments:
      return 2;
    case CapitalCallDetailsTabs.Publish:
      return 3;
    case CapitalCallDetailsTabs.CapitalCallSetUp:
    default:
      return 0;
  }
  return 0;
};

export const useCapitalCallDetails = () => {
  const { id, section } = useParams<RouteProp>();
  const [tab, setTab] = useState(getIndexBySection(section));
  const history = useHistory();
  const { capitalCall, loading } = useCapitalCallDetailsEffect(
    id !== "new" ? id : undefined
  );

  const handleChange = (_: React.ChangeEvent<{}>|undefined, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) {
      return history.push(
        `/capital-calls/${id}/${CapitalCallDetailsTabs.CapitalCallSetUp}`
      );
    }
    if (newValue === 1) {
      return history.push(
        `/capital-calls/${id}/${CapitalCallDetailsTabs.AddTransactions}`
      );
    }
    if (newValue === 2) {
      return history.push(
        `/capital-calls/${id}/${CapitalCallDetailsTabs.ReviewDocuments}`
      );
    }
    if (newValue === 3) {
      return history.push(
        `/capital-calls/${id}/${CapitalCallDetailsTabs.Publish}`
      );
    }
    return null;
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setTab(getIndexBySection(section));
    });
  
    return unlisten;
  }, []);

  useEffect(() => {
    setTab(getIndexBySection(section));
  }, [section]);

  const handleClose = () => {
    history.push(RoutingPaths.CapitalCalls);
  };

  return {
    tab,
    setTab,
    capitalCall,
    loading,
    handleChange,
    handleClose,
  };
};
