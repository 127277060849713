import { Box, FormHelperText, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import HorizontalBox from "../../../../components/Boxes/HorizontalBox/HorizontalBox";
import FileSelector from "../../../../components/FileSelector/FileSelector";
import { SaveExitModal } from "../../../../components/Modal/SaveExitModal/SaveExitModal";
import Progress from "../../../../components/Progress/ProgressModal/ProgressModal";
import FundFilter from "../../../../components/Selectors/FundsFilter";
import useRole from "../../../../core/routing/useRole";
import noop from "../../../../utils/helpers/noop";
import { ScopeRole } from "../../../../utils/types/user.type";
import { FileDisplay } from "../../../capitalCalls/capitalCallDetails/CapitalCallSetup/FileDisplay";
import { DistributionDetailsTabs } from "../Constants";
import DistributionReviewers from "./DistributionReview";
import {
  Button,
  DistributionFieldContainer,
  DistributionFileLabel,
  DistributionFilesContainer,
  DistributionHeaderLabel,
  DistributionSetUpContainer,
  DistributionSetupWrapper,
  Divider,
  FieldLabelDistribution,
  MarginDistributionFixContainer,
} from "./DistributionSetUp.style";
import { useDistributionSetUp } from "./useDistributionSetUp.hook";

type Props = {
  onNavigateAwayCanceled: () => void,
};

const DistributionsSetUp: React.FC<Props> = ({ onNavigateAwayCanceled }) => {
  const {
    control,
    handleSubmit,
    errors,
    addDistribution,
    getValues,
    setValue,
    teamMembers,
    teamRolesWithMembers,
    id,
    documents,
    setDocuments,
    loading,
    isSuperAdminOrClientAdmin,
    allowEdit,
    allowReviewerEdit,
    handleValidateReviewers,
    errorMessage,
    setErrorMessage,
    isDirty,
    handleconfirmSaveAndNavagateAway
  } = useDistributionSetUp(onNavigateAwayCanceled);

  const history = useHistory();
  const submitRef = useRef<HTMLFormElement | null>(null);

  return (
    <DistributionSetupWrapper>
      <Progress id="distribution_list_loading" showProgress={loading} />
      <form
        autoComplete="off"
        onSubmit={handleSubmit(addDistribution)}
        noValidate
        ref={submitRef}
      >
        <DistributionSetUpContainer>
          <DistributionHeaderLabel variant="h2">
            Select a Fund
          </DistributionHeaderLabel>
          <DistributionFieldContainer>
            <FieldLabelDistribution variant="h6">
              <strong>What is this distribution named?</strong>
            </FieldLabelDistribution>
            <Controller
              name="name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled={!isSuperAdminOrClientAdmin}
                  size="small"
                  id="name_distribution"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: "Distribution Name is Required",
              }}
              control={control}
            />
          </DistributionFieldContainer>
          {errors?.name && (
            <FormHelperText error={true}>
              {errors?.name?.message}{" "}
            </FormHelperText>
          )}

          <FieldLabelDistribution variant="h6">
            <strong>Which fund will be associated with this call?</strong>
          </FieldLabelDistribution>

          <DistributionFieldContainer>
            <Controller
              name="fundName"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const fundId = getValues("fundId");

                return (
                  <FundFilter
                    size="small"
                    id={"transaction_fund_filter"}
                    value={{
                      name: value,
                      id: fundId,
                    }}
                    disabled={!isSuperAdminOrClientAdmin}
                    onChange={(fund) => {
                      onChange(fund?.name || "");
                      setValue("fundId", fund?.id);
                      teamRolesWithMembers(fund?.id);
                    }}
                    error={error}
                  />
                );
              }}
              rules={{
                required: "Fund is required",
              }}
              control={control}
            />
          </DistributionFieldContainer>
          <DistributionHeaderLabel variant="h2">
            Reviewers
          </DistributionHeaderLabel>
          <Divider variant="fullWidth" />
          <Stack>
            <FieldLabelDistribution variant="h6">
              <strong>
                Select at least one user group to review this distribution
              </strong>
            </FieldLabelDistribution>
          </Stack>

          <Controller
            name="reviewers"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <DistributionReviewers
                  disabled={!isSuperAdminOrClientAdmin}
                  value={value}
                  onChange={onChange}
                  teamMembers={teamMembers}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <FormHelperText error={true}>{error?.message}</FormHelperText>
              </>
            )}
            rules={{
              required: "Reviewers are required",
              validate: (value: any) => {
                if (!handleValidateReviewers(value)) {
                  return "";
                } else return true;
              },
            }}
            control={control}
          />

          <DistributionHeaderLabel variant="h2">
            Add Files
          </DistributionHeaderLabel>
          <Divider variant="fullWidth" />
          <DistributionFilesContainer direction="row">
            <Stack>
              <DistributionFileLabel variant="h6">
                <strong>Distribution Notice Template</strong>
              </DistributionFileLabel>

              <Controller
                name="noticeTemplate"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    {!value && !documents?.document && (
                      <>
                        <FileSelector
                          disabled={!isSuperAdminOrClientAdmin}
                          title="Drag and drop your file here, or"
                          acceptType=".txt,.doc,.docx"
                          onUpload={(_, file) => onChange(file)}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                    {value && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        file={value}
                        onClear={() => onChange()}
                      />
                    )}
                    {documents?.document && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        document={documents.document}
                        onClear={() => {
                          onChange();
                          setDocuments({
                            ...documents,
                            document: undefined,
                          });
                        }}
                      />
                    )}
                  </>
                )}
                rules={{
                  required: !documents?.document && "Please upload the Notice Template",
                }}
                control={control}
              />
            </Stack>
            <Stack>
              <DistributionFileLabel variant="h6">
                <strong>Distribution Email Template</strong>
              </DistributionFileLabel>
              <Controller
                name="emailTemplate"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    {!value && !documents?.email && (
                      <>
                        <FileSelector
                          disabled={!isSuperAdminOrClientAdmin}
                          title="Drag and drop your file here, or"
                          acceptType=".txt,.doc,.docx"
                          onUpload={(_, file) => onChange(file)}
                        />
                        <FormHelperText error={true}>
                          {error?.message}
                        </FormHelperText>
                      </>
                    )}
                    {value && (
                      <FileDisplay
                        disabled={isSuperAdminOrClientAdmin}
                        file={value}
                        onClear={() => onChange()}
                      />
                    )}
                    {documents?.email && (
                      <FileDisplay
                        disabled={!isSuperAdminOrClientAdmin}
                        document={documents.email}
                        onClear={() => {
                          onChange();
                          setDocuments({
                            ...documents,
                            email: undefined,
                          });
                        }}
                      />
                    )}
                  </>
                )}
                rules={{
                  required: !documents?.email && "Please upload the Email Template",
                }}
                control={control}
              />
            </Stack>
          </DistributionFilesContainer>
        </DistributionSetUpContainer>
        <HorizontalBox
          addTopShadow
          attachToBottom={true}
          hidden={false}
          fullWidth
          stickToCenter={true}
          columnGap={true}
          sx={{ position: "fixed", width: "100%", marginLeft: theme => theme.spacing(-2) }}
        >
          {(isSuperAdminOrClientAdmin || allowReviewerEdit) && (
            <Button
              variant="contained"
              type="submit"
              disabled={allowReviewerEdit ? false : !allowEdit}
            >
              Save
            </Button>
          )}
          <Button
            variant="contained"
            disabled={!id || id === "new"}
            onClick={() => {
              history.push(
                `/distributions/${id}/${DistributionDetailsTabs.AddTransactions}`
              );
            }}
          >
            Continue to Add Transactions
          </Button>
        </HorizontalBox>
      </form>

      <SaveExitModal
        isBlocked={isDirty && id !== 'new'}
        title="Unsaved Changes Detected"
        content="You have unsaved modifications to either the notice template and/or email template. Would you like to save your changes or cancel and remain on this page?"
        actionTitle="Save Changes"
        onActionClickAsync={ () => handleconfirmSaveAndNavagateAway(submitRef.current!) }
        confirmNavigationTitle="Exit Without Saving"
        onNavigateAway={ noop }
        onCancelClick={onNavigateAwayCanceled}
      />
    </DistributionSetupWrapper>
  );
};

export default React.memo(DistributionsSetUp);
