import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangeValidationError } from '@mui/x-date-pickers-pro/internal/hooks/validation/useDateRangeValidation';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useDateRangePicker = (
  dateRange: DateRange<Date>,
  onChange: (dateRange: DateRange<Date>) => void,
  onError?: (error: DateRangeValidationError) => void
) => {
  const [currentDateRange, setCurrentDateRange] = useState<DateRange<Date>>([
    null,
    null,
  ]);

  useEffect(() => {
    setCurrentDateRange(dateRange);
  }, [dateRange]);

  const handleDateRangeChange = (dateRange: DateRange<any>) => {
    setCurrentDateRange(dateRange);
    onChange(dateRange);
  };

  const handleDateRangeError = (
    error: DateRangeValidationError,
    value: DateRange<Date>
  ) => {
    if (onError) {
      onError(error);
    }
  };

  return {
    currentDateRange,
    handleDateRangeChange,
    handleDateRangeError,
  };
};
