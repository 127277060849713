import { Theme } from '@mui/system';

import { ClientTheme } from "../../../core/context/clientThemeContext";

export default function Button(theme: Theme, clientTheme?: ClientTheme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize" as "capitalize",
        },
        containedPrimary: {
          backgroundColor: clientTheme?.buttonBackgroundColor,
          borderColor: clientTheme?.buttonBackgroundColor,
          color: clientTheme?.buttonTextColor,
          '&:hover': {
            backgroundColor: clientTheme?.buttonBackgroundColor,
            borderColor: clientTheme?.buttonBackgroundColor,
            color: clientTheme?.buttonTextColor,
          }
        },
        outlinedPrimary: {
          borderColor: clientTheme?.buttonBackgroundColor,
          color: clientTheme?.buttonBackgroundColor,
          '&:hover': {
            borderColor: clientTheme?.buttonBackgroundColor,
            color: clientTheme?.buttonBackgroundColor,
          }
        },
        textPrimary: {
          color: clientTheme?.buttonBackgroundColor,
        }
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '& .MuiButtonGroup-grouped': {
            borderColor: clientTheme?.buttonBackgroundColor,
          },
          '& .MuiButtonGroup-grouped:not(:last-of-type)': {
            borderColor: clientTheme?.buttonBackgroundColor,
          }
        }
      }
    }
  };
}
