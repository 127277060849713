import { Box, Grid, styled, SxProps, Theme, Typography } from '@mui/material';
import { pieArcLabelClasses } from '@mui/x-charts';

export const PieChartWrapper = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  maxHeight: '300px',
  height: '300px',
  minWidth: '300px',
}));

export const PieChartTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  textAlign: 'left',
  marginBottom: '16px',
}));

export const PieChartAndLegendContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-evenly',
  alignItems: 'center',
  minWidth: '300px',
}));

export const LegendGrid = styled(Grid)(() => ({
  maxHeight: '220px',
  overflowY: 'auto',
  paddingLeft: '32px !important',
  paddingTop: '0px !important',
  flexGrow: 1,
}));

export const PieChartGrid = styled(Grid)(() => ({
  height: '280px',
  width: '288px',
  marginTop: '-48px',
  flexGrow: 1,
}));

export const pieParams = {
  margin: { right: 5, left: 5 },
};

export const pieChartStyles = {
  popper: {
    '& .MuiChartsTooltip-valueCell': {
      textAlign: 'right',
    },
  } as SxProps<Theme>,

  arcLabel: {
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontSize: 14,
    },
  } as SxProps<Theme>,
};
