import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { CapitalCalls } from "../../../utils/types/capitalCalls.type";
import { getCapitalCallDetails } from "../../capitalCalls.service";

export const useCapitalCallDetailsEffect = (id?: string) => {
  const [capitalCall, setCapitalCall] = useState<CapitalCalls>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchCapitalCallDetails = useCallback(
    async (isCanceled?: () => boolean) => {
      if (!id) return;
      try {
        setLoading(true);
        const response = await getCapitalCallDetails(id!);

        if (isCanceled?.()) return;

        setCapitalCall(response);
        setLoading(false);
      } catch (e) {
        informationAlert("Error in getting capital call details", "error");
        setLoading(false);
      }
    },
    [id]
  );

  useEffectAsync(
    async (isCanceled) => {
      await fetchCapitalCallDetails(isCanceled);
    },
    [fetchCapitalCallDetails]
  );

  return {
    capitalCall,
    fetchCapitalCallDetails,
    loading,
    setCapitalCall,
  };
};
