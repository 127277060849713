import { Box,styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.grey[500]
}));

export const MainRow = styled(Box)(({
  theme
}) => ({
  display:"grid",
  gridGap:theme.spacing(3),
  gridTemplateColumns:"1fr 1fr 1fr"
}));

export const DashedBorderBox = styled(Box)(({
  theme
}) => ({
  padding:theme.spacing(2.5),
  border:`1px dashed ${theme.palette.secondary.dark}`,
  boxSizing:"border-box",
  borderRadius:theme.spacing(0.5)
}));

export const LabelBorderBox = styled(Box)<{order: number|undefined }>(({
  theme, order
}) => ({
  padding:theme.spacing(0.3),
  border:`1px solid ${theme.palette.secondary.dark}`,
  boxSizing:"border-box",
  borderRadius:theme.spacing(0.5),
  backgroundColor:theme.palette.grey[300],
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  order: order
}));

export const CloseIconBox = styled(Box)(({
  theme
}) => ({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  minWidth: '50px'
}));

export const ListViewContainer = styled(Box)(({
  theme
})=>({
  display: "grid",
  gridTemplateColumns: "0.1fr 3fr 0.1fr"
}));

export const OrderLayout = styled(Box)<{order: number|undefined }>(({
  theme,order
})=>({
  order: order
}));

