import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from '@mui/material';

export const BulkEditBox = styled(Box)(({ theme }) => ({
  border: '1px solid #EFEEF0',
  marginTop: '8px',
  marginBottom: '8px',
  backgroundColor: '#F7F7F8',
  maxWidth: '100%',
  overflowX: 'auto',
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '12px',
  position: 'relative',
}));

export const BulkEditInputBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(4),
  display: 'flex',
  alignItems: 'flex-start',
}));

export const BulkEditCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(1),
  color: '#999',
}));

export const OrangeErrorOutlineOutlinedIcon = styled(ErrorOutlineOutlinedIcon)(
  () => ({
    color: '#F2B203 !important',
    marginTop: '-2px',
    marginRight: '8px',
  })
);

export const BlueModeEditOutlinedIcon = styled(ModeEditOutlineOutlinedIcon)(
  () => ({
    color: '#007AFF !important',
    marginTop: '-2px',
  })
);

export const BulkEditHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isError: boolean;
}>`
  border-bottom: ${(props) =>
    props.isError ? '2px solid #F2B203' : '2px solid #007AFF'};
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  gap: 8px;
`;

export const MessagingSubHeader = styled(Box)<{}>`
  display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   padding-bottom: 4px;
  padding-top: 8px;
  padding-left: 30px;
`;

export const MessagingSubSubHeader = styled(Box)<{}>`
  display: flex;

  padding-bottom: 4px;
  //   padding-top: 4px;
  padding-left: 30px;
`;

export const AutocompleteGroupHeader = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  padding: theme.spacing(1),
}));
