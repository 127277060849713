import { styled } from "@mui/material";

export const StatusColor = styled("span")<{
  color: string;
}>`
  background-color: ${(props) => {
    if (props.color === "green") return "#5BBC76";
    else if (props.color === "yellow") return "#EBC500";
    else if (props.color === "blue") return "#007AFFB5";
    else if (props.color === "aqua") return "#1ACCF3";
    else if (props.color === "toggle-grey") return "#979797";
    else return "#E04F50";
  }};
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
`;
