import { Box } from "@mui/material";
import React, { ReactElement } from "react";

import { StyledBox } from "./TabContent.styles";

type Props = {
  index: number;
  value: number;
  children: any;
  height?: number| string;
};

const TabContent: React.FC<Props> = (props: Props): ReactElement => {
  const { value, index, children, height } = props;

  return (
    <StyledBox
      role="tabpanel"
      hidden={value !== index}
      id={`tabcontent-${index}`}
      aria-labelledby={`tab-${index}`}
      height={height}
    >
      {children}
    </StyledBox>
  );
};

export default TabContent;
