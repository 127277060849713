import { Typography } from '@mui/material';
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid';
import { cloneDeep, head } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { start } from 'repl';

import ChipCellStack from '../../../../../components/DataGrid/ChipsCell/ChipCellStack';
import { ActionLink } from '../../../../../components/Link/ActionLink/ActionLink';
import { AppContext } from '../../../../../core/context/appContextProvider';
import {
  getArkAccountNames,
  getJournalEntries,
} from '../../../../../services/arkGL.service';
import { CurrencyFormat } from '../../../../../utils/helpers/format.helper';
import { generateUUID } from '../../../../../utils/helpers/uuidGenerator';
import { useEffectAsync } from '../../../../../utils/hooks/useEffectAsync.hook';
import { AttributeListItem } from '../../../../../utils/types/arkGLAccount.type';
import { SelectedJournalEntry } from '../../../../../utils/types/arkGLJournalEntry.type';
import { DetailsType } from '../../../../../utils/types/form.type';
import {
  CustomType,
  DataGridColDef,
} from '../../../../../utils/types/listItems';
import {
  Account,
  getTrialBalanceDetailsData,
  getTrialBalanceDetailsDataNew,
  ReportAccount,
  ReportLineItem,
} from '../shared';

export type GridRow = {
  id?: string;
  date: Date | string;
  accountNumber: string;
  accountName: string;
  ledger: string;
  journalEntryNumber: number | undefined;
  journalEntryStatus: string | undefined;
  isAdjusting: boolean | undefined;
  memo: string;
  entityNames: string[];
  amount: number | undefined;
  balance: number;
  fundId?: string;
  ledgerId?: string;
  decimals?: number;
  currencyCode?: string;
};

export type GlGridData = {
  gridRows: GridRow[];
  dataGridColDefs: DataGridColDef[];
};

export const useGeneralLedgerReport = (
  params: any,
  setIsLoading: (isLoading: boolean) => void,
  suppressIfZero: boolean,
  attributes: any,
  funds: any,
  onDataGridChange: (gridData: GlGridData, exportName: string) => void,
  fetchGlReport: boolean,
  setFetchGlReport: Function
) => {
  const { state, informationAlert } = useContext(AppContext);
  const clientId = state.loginUser.clientId;

  const [headerList, setHeaderList] = useState<Array<DataGridColDef>>([]);
  const [gridData, setGridData] = useState<GridRow[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>('');
  const [decimalValue, setDecimalValue] = useState<number>(0);
  const [reportAccounts, setReportAccounts] = useState<ReportAccount[]>([]);
  const [selectedAccountsIds, setSelectedAccountsIds] = useState<string[]>([]);
  const [selectedAttributesIds, setSelectedAttributesIds] = useState<string[]>(
    []
  );
  const [journalEntriesList, setJournalEntriesList] = useState([]);
  const [arkAccountList, setArkAccountList] = useState([]);
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<SelectedJournalEntry>();

  useEffectAsync(
    async (isCanceled) => {
      setIsLoading(true);

      setSelectedAccountsIds(params.accounts);
      setSelectedAttributesIds(params.attributes);
      try {
        const response = await getTrialBalanceDetailsData(params, isCanceled);

        if (!isCanceled() && !response) return;

        const arkAccounts = await getArkAccountNames();

        setArkAccountList(arkAccounts.items);

        const { reportAccounts, decimals, currency } = response!;

        setCurrencyCode(currency);
        setDecimalValue(decimals);
        setReportAccounts(reportAccounts);
      } catch (e) {
        informationAlert('Error retreiving data', 'error');
      }

      setIsLoading(false);
    },
    [suppressIfZero, fetchGlReport]
  );

  useEffect(() => {
    if (reportAccounts && currencyCode && decimalValue) {
      buildGridData(cloneDeep(reportAccounts));
    }
  }, [reportAccounts, currencyCode, decimalValue]);

  useEffect(() => {
    if (currencyCode && decimalValue) {
      initializeHeaderList();
    }
  }, [currencyCode, decimalValue, gridData]);

  useEffect(() => {
    if (gridData && headerList) {
      onDataGridChange(
        { gridRows: gridData, dataGridColDefs: headerList },
        'general_ledger_report'
      );
    }
  }, [gridData, headerList]);

  function buildGridData(accounts: ReportAccount[]) {
    const formattedGridData: GridRow[] = [];

    if (!suppressIfZero) {
      selectedAccountsIds.forEach((selectedId) => {
        const isMissing = !accounts.some(
          (account) => account.accountId === selectedId
        );

        if (isMissing) {
          const arkAccount: any = arkAccountList.find(
            (arkAccount: any) => arkAccount.id === selectedId
          );

          if (arkAccount) {
            const missingAccount = {
              accountId: arkAccount.id,
              accountName: arkAccount.name,
              accountNo: arkAccount.number,
              state: arkAccount.state,
              fundId: arkAccount.fundId,
              attributeId: '',
              fsMappingId: '',
              fsName: '',
              startBalance: 0,
              endBalance: 0,
              totalAmount: 0,
              lineItems: [],
              parentAccountId: '',
              childAccounts: [],
              decimals: decimalValue,
              currencyCode: currencyCode
            };

            accounts.push(missingAccount);
          }
        }
      });
    }

    accounts.map((account: ReportAccount) => {
      if (!selectedAccountsIds.includes(account.accountId) && suppressIfZero)
        return;
      if (
        !selectedAttributesIds.includes(account.attributeId) &&
        account.attributeId !== ''
      )
        return;

      if (
        suppressIfZero &&
        account.startBalance === 0 &&
        account.endBalance === 0 &&
        account.lineItems.length === 0
      )
        return;

      const startBalanceLine: GridRow = {
        id: generateUUID(),
        date: 'Starting Balance',
        accountNumber: account.accountNo,
        accountName: account.accountName,
        ledger: '',
        journalEntryNumber: undefined,
        journalEntryStatus: '',
        isAdjusting: undefined,
        memo: '',
        entityNames: [],
        amount: undefined,
        balance: account.startBalance,
        decimals: decimalValue,
        currencyCode: currencyCode
      };

      formattedGridData.push(startBalanceLine);

      let currBalance = account.startBalance;

      const lineItems: GridRow[] = [];

      account.lineItems = account.lineItems.sort((a, b) => {
        const dateComparison =
          new Date(a.date).getTime() - new Date(b.date).getTime();

        if (dateComparison !== 0) {
          return dateComparison;
        }

        return a.journalEntryNum - b.journalEntryNum;
      });

      account.lineItems.map((lineItem: ReportLineItem) => {
        const journalLineItem: GridRow = {
          id: generateUUID(),
          date: lineItem.date,
          accountNumber: account.accountNo,
          accountName: account.accountName,
          ledger: lineItem.ledgerName,
          journalEntryNumber: lineItem.journalEntryNum,
          journalEntryStatus: lineItem.state,
          isAdjusting: lineItem.adjustingJournalEntry,
          memo: lineItem.memo,
          entityNames: lineItem.entityNames ? lineItem.entityNames : [],
          amount: lineItem.amount,
          balance: currBalance + lineItem.amount,
          fundId: account.fundId,
          ledgerId: lineItem.ledgerId,
        };

        lineItems.push(journalLineItem);
        currBalance = currBalance + lineItem.amount;
      });

      lineItems.map((lineItem) => formattedGridData.push(lineItem));

      const endBalanceLine: GridRow = {
        id: generateUUID(),
        date: 'Ending Balance',
        accountNumber: account.accountNo,
        accountName: account.accountName,
        ledger: '',
        journalEntryNumber: undefined,
        journalEntryStatus: '',
        isAdjusting: undefined,
        memo: '',
        entityNames: [],
        amount: undefined,
        balance: account.endBalance,
        decimals: decimalValue,
        currencyCode: currencyCode
      };

      formattedGridData.push(endBalanceLine);
    });

    setGridData(sortGridData(formattedGridData));
  }

  function sortGridData(gridData: GridRow[]) {
    const groupedRows = gridData.reduce((acc, row: GridRow) => {
      if (!acc[row.accountNumber]) {
        acc[row.accountNumber] = [];
      }
      acc[row.accountNumber].push(row);

      return acc;
    }, {} as Record<string, GridRow[]>);

    const sortedGroupKeys = Object.keys(groupedRows)
      .map(Number)
      .sort((a, b) => a - b)
      .map(String);

    const sortedRows = sortedGroupKeys.flatMap((key) => groupedRows[key]);

    return sortedRows;
  }

  function initializeHeaderList() {
    const updatedHeaders: DataGridColDef[] = [
      {
        headerName: 'Date',
        field: 'date',
        hide: false,
        index: 0,
        type: 'date' || 'string',
        sortable: false,
        renderCell: (params: any) => {
          if (params.value === 'Starting Balance') {
            return 'Starting Balance';
          } else if (params.value === 'Ending Balance') {
            return (
              <Typography sx={{ fontWeight: 'bold' }}>
                Ending Balance
              </Typography>
            );
          } else {
            return params.value;
          }
        },
      },
      {
        headerName: 'Account Number',
        field: 'accountNumber',
        hide: false,
        index: 1,
        type: 'number',
        sortable: false,
        align: 'right' as GridAlignment,
        renderCell: (params: any) => {
          if (params.row.date === 'Ending Balance') {
            return (
              <Typography sx={{ fontWeight: 'bold' }}>
                {params.value}
              </Typography>
            );
          }
          return params.value;
        },
      },
      {
        headerName: 'Account Name',
        field: 'accountName',
        hide: false,
        index: 2,
        type: 'string',
        align: 'left' as GridAlignment,
        sortable: false,
        renderCell: (params: any) => {
          if (params.row.date === 'Ending Balance') {
            return (
              <Typography sx={{ fontWeight: 'bold' }}>
                {params.value}
              </Typography>
            );
          }
          return params.value;
        },
      },
      {
        headerName: 'Ledger',
        field: 'ledger',
        hide: false,
        index: 3,
        type: 'string',
        sortable: false,
        align: 'left' as GridAlignment,
        renderCell: (params: any) => {
          if (params.value) return params.value;
          return '';
        },
      },
      {
        headerName: 'Journal Number',
        field: 'journalEntryNumber',
        hide: false,
        index: 4,
        type: 'number',
        sortable: false,
        align: 'right' as GridAlignment,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return (
            <ActionLink
              id={`journal_entry_num_${params.value}`}
              onClick={() => handleViewJournalEntryDetails(params)}
            >
              {params.value}
            </ActionLink>
          );
        },
      },
      {
        headerName: 'Journal Status',
        field: 'journalEntryStatus',
        hide: false,
        index: 5,
        type: 'string',
        sortable: false,
        align: 'left' as GridAlignment,
        renderCell: (params: any) => {
          if (
            params.row.date === 'Starting Balance' ||
            params.row.date === 'Ending Balance'
          )
            return '';
          if (params.value) return params.value;
          return '';
        },
      },
      {
        headerName: 'Is Adjusting',
        field: 'isAdjusting',
        hide: false,
        index: 6,
        type: 'string',
        sortable: false,
        align: 'left' as GridAlignment,
        renderCell: (params: any) => {
          if (
            params.row.date === 'Starting Balance' ||
            params.row.date === 'Ending Balance'
          )
            return '';
          if (params.value === true) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        headerName: 'Line Description',
        field: 'memo',
        hide: false,
        index: 7,
        align: 'left' as GridAlignment,
        type: 'string',
        sortable: false,
        renderCell: (params: any) => {
          if (params.value) return params.value;
          return '';
        },
      },
      {
        headerName: 'Entity Name',
        field: 'entityNames',
        hide: false,
        index: 8,
        align: 'left' as GridAlignment,
        type: 'string',
        sortable: false,
        renderCell: (params: any) => {
          if (params.value.length > 0)
            return (
              <ChipCellStack
                header={{
                  headerName: 'Memo Tags',
                  field: 'entityNames',
                  hide: false,
                  index: 8,
                  align: 'left' as GridAlignment,
                  type: 'string',
                  sortable: false,
                }}
                items={params.value}
                row={params.row}
              ></ChipCellStack>
            );
          return '';
        },
      },
      {
        headerName: 'Amount',
        field: 'amount',
        hide: false,
        index: 9,
        type: 'number',
        align: 'right' as GridAlignment,
        sortable: false,
        renderCell: (params: any) => {
          if (
            params.row.date === 'Starting Balance' ||
            params.row.date === 'Ending Balance'
          )
            return null;
          if (params.value) {
            return CurrencyFormat(currencyCode, decimalValue).format(
              params.value
            );
          } else {
            return CurrencyFormat(currencyCode, decimalValue).format(0);
          }
        },
      },
      {
        headerName: 'Balance',
        field: 'balance',
        hide: false,
        index: 10,
        type: 'number',
        align: 'right' as GridAlignment,
        sortable: false,
        renderCell: (params: any) => {
          if (params.value) {
            if (params.row.date === 'Ending Balance') {
              return (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {CurrencyFormat(currencyCode, decimalValue)
                    .format(params.value)
                    .toString()}
                </Typography>
              );
            }
            return CurrencyFormat(currencyCode, decimalValue).format(
              params.value
            );
          } else {
            if (params.row.date === 'Ending Balance') {
              return (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {CurrencyFormat(currencyCode, decimalValue)
                    .format(0)
                    .toString()}
                </Typography>
              );
            }
            return CurrencyFormat(currencyCode, decimalValue).format(0);
          }
        },
      },
      {
        headerName: '',
        field: 'action',
        hide: false,
        index: 11,
        type: 'action',
        customType: CustomType.Action,
        sortable: false,
        maxWidth: 35,
        renderCell: (params: any) => {
          return '';
        },
      },
    ];

    setHeaderList(updatedHeaders);
  }

  const initialJournalEntry: SelectedJournalEntry = {
    journalEntry: undefined,
    type: undefined,
  };

  function onJournalEntryPanelClose() {
    setIsLoading(true);
    setSelectedJournalEntry(initialJournalEntry);
    setFetchGlReport(!fetchGlReport);
    setIsLoading(false);
  }

  async function handleViewJournalEntryDetails(params: any) {
    setIsLoading(true);

    try {
      const allJournalEntries = await getJournalEntries(
        params.row.fundId,
        params.row.ledgerId
      );

      setJournalEntriesList(allJournalEntries.items);

      const selectedEntry = allJournalEntries.items.find(
        (item: any) =>
          item.number === params.value &&
          item.fundId === params.row.fundId &&
          item.ledgerId === params.row.ledgerId
      );

      if (selectedEntry) {
        setSelectedJournalEntry({
          journalEntry: selectedEntry,
          type: DetailsType.Edit,
        });
      }
    } catch (e) {
      informationAlert('Error retreiving Journal Entries', 'error');
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  function handleUpdateHeader(field: string) {
    if (!headerList || headerList?.length === 0) return;

    const activeFields = headerList.filter(
      (header) => !header.hide && header?.type !== 'action'
    );

    const targetField = headerList.find((header) => header.field === field);

    if (activeFields.length <= 1 && !targetField?.hide) return;

    const updatedHeaders: Array<DataGridColDef> = headerList.map((header) => {
      return {
        ...header,
        hide: header.field === field ? !header.hide : header.hide,
      };
    });

    if (updatedHeaders) {
      setHeaderList(updatedHeaders);
    }
  }

  return {
    headerList,
    gridData,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onJournalEntryPanelClose,
    handleUpdateHeader,
  };
};
