import { useContext } from 'react';

import ArkpesLogo from '../../assets/images/logos/ark_main_logo.png';
import { AppContext } from "../../core/context/appContextProvider";
import { ClientThemeContext } from '../../core/context/clientThemeContext';
import {
    FooterSection,
    HeaderDiv,
    HeaderGrid,
    LeftSection,
    LoginBody,
    LoginView,
    RenderImage,
    WhiteIcon
  } from './Login.styles';

const RightSection = () => {
  const { preAuthClientConfigs } = useContext(AppContext);
  const { clientTheme } = useContext(ClientThemeContext);

  return (
      <LoginBody
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          background={clientTheme?.loginBodyBackgroundColor}
          textcolor={clientTheme?.loginBodyTextColor}
      >
          {preAuthClientConfigs && (preAuthClientConfigs?.logoUrl ? <RenderImage
              src={
              preAuthClientConfigs?.logoUrl
              }
              alt="ark-logo"
          /> : <RenderImage src={ArkpesLogo} alt="arklogo" />)}
      </LoginBody>
  );
};

export default RightSection;
