import React from "react";

import ChipGroup from "../../../../components/ChipsGroup/ChipsGroup";

type Permission = { id: string, name: string };

interface PermissionProps {
  permissions: Permission[],
  removeSelected: (id: string) => void
}

export default function PermissionChips ({
  permissions,
  removeSelected,
}: PermissionProps): React.ReactElement {
  const renderSelectedPermissions = React.useMemo(() => <ChipGroup
    ChipTitleList={permissions}
    handleRemoveFilter={removeSelected}
    color={"green"}
  />, [JSON.stringify(permissions)]);

  return renderSelectedPermissions;
}