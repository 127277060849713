export enum Statuses {
    PROCESSING = 'PROCESSING',
    SAVING = 'SAVING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    ROLLEDBACK = 'ROLLEDBACK',
}

export enum Types {
    CONTACTS = 'CONTACTS',
    FUNDS = 'FUNDS',
    INVESTORS = 'INVESTORS',
    TRANSACTIONS = 'TRANSACTIONS',
    UNKNOWN = 'UNKNOWN',
}

export interface UploadHistory {
    "dateStarted"?: Date | string,
    "id"?: string,
    "lines"?: number,
    "recieptFiles"?: string[],
    "recieptS3Location"?: string,
    "resultS3Location"?: string,
    "status"?: Statuses | string,
    "tenantId"?: string,
    "uploadType"?: Types | string,
    "uploadedBy"?: string,
    "uploadedFileName"?: string,
    "uploadedS3Location"?: string
}

export enum HistoryFilter {
    Types = 'Types',
    Statuses = 'Statuses',
}
