import { Box, styled, Typography } from "@mui/material";

export const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gridTemplateColumns: "15% 61% 23%",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const DataGridCell = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  variant: theme.typography.body1,
})) as typeof Typography;

export function quantityWithCommas(quantityInput: number | null) {
  return quantityInput?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatStringToUpperCase(str: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
