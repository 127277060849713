import { ReactElement, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import ProgressModal from "../../../../components/Progress/ProgressModal/ProgressModal";
import { AppContext } from "../../../../core/context/appContextProvider";
import RoutingPaths from "../../../../core/routing/routingPaths";
import { downloadContactAccountReport } from "../../../../services/capitalAccounts.service";
import downloadFile from "../../../../utils/helpers/fileDownloader";
import { DOWNLOAD_ERROR } from "../../constants";
import useDocuments from "../../hooks/useDocuments.hooks";

type Params = {
  document_id?: string;
  report_id?: string;
};

function Download(): ReactElement {
  const { informationAlert } = useContext(AppContext);
  const history = useHistory();
  const params = useParams<Params>();

  const fileId = params?.report_id || "";

  useEffect(() => {
    if (fileId) {
      downloadContactAccountReport(fileId)
        .then((fileBlob: any) => {
          downloadFile(fileBlob, `Capital_Account_Report`, "zip");
          history.push(RoutingPaths.CapitalAccounts);
        })
        .catch(() => {
          informationAlert(DOWNLOAD_ERROR, "error");
          history.push(RoutingPaths.CapitalAccounts);
        });
    } else {
      history.push(RoutingPaths.Documents);
    }
  }, [fileId]);

  return <ProgressModal id="documents_progress_modal" showProgress={true} />;
}

export default Download;
