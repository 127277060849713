import AddIcon from '@mui/icons-material/Add';
import { Box, TablePaginationProps, Typography } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { useHistory } from 'react-router-dom';

import SplitButton from '../../../../components/Buttons/SplitButton/SplitButton';
import ButtonWithOptions from '../../../../components/ButtonWithOptions/ButtonWithOptions';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import { Action } from '../../../../components/Modal/DeleteConfirmationDialog/constants';
import DeleteConfirmationDialog from '../../../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog';
import ProgressModal from '../../../../components/Progress/ProgressModal/ProgressModal';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import RoutingPaths from '../../../../core/routing/routingPaths';
import { searchAllJournalEntries } from '../../../../services/arkGL.service';
import { JournalEntryDetails } from '../journalEntryDetails/JournalEntryDetails';
import { JournalEntryDetailsReadOnly } from '../journalEntryDetails/JournalEntryDetailsReadOnly';
import JournalEntryPostDialog from '../journalEntryPostDialog/journalEntryPostDialog';
import { NewJournalEntryOptions } from './JournalEntryList.constants';
import { useJournalEntryList } from './JournalEntryList.hooks';
import {
  ButtonBox,
  HeaderRow,
  IconBox,
  StyledDataGridPro,
  StyledDataWrapperBox,
} from './JournalEntryList.styles';

export const JournalEntryList: React.FC = () => {
  const {
    isLoading,
    journalEntryFilteredList,
    journalEntrySelectionModel,
    setJournalEntrySelectionModel,
    headerList,
    activeHeaderFields,
    handleUpdateHeader,
    handleOnView,
    handleFilter,
    handleNewButtonAction,
    selectedJournalEntry,
    setSelectedJournalEntry,
    onDetailsPanelClose,
    fundId,
    readonly,
    onSearch,
    searchOptions,
    search,
    showSuggestionPopover,
    setShowSuggestionPopover,
    journalEntryList,
    bulkAdminActions,
    bulkExportActions,
    handleBulkAction,
    isFundAdmin,
    isSuperAdminOrClientAdmin,
    handleUploadTemplate,
    clearUploadedFile,
    clearUploadCompleted,
    isUploadComplete,
    uploadedFile,
    apiRef,
    postAction,
    postFiltersRequest,
    showPostDialog,
    setShowPostDialog,
    journalEntriesToPost,
    setJournalEntriesToPost,
    setPostAction,
    onPostCancel,
    onPostComplete,
    sortModel,
    setSortModel,
    rowCountState,
    handlePageChange,
    searchFilteredList,
    deleteAction,
    deleteCount,
    onDeleteCancel,
    onDeleteConfirm,
    onRowColumnOrderChange,
    deleteError,
  } = useJournalEntryList();

  const history = useHistory();

  const Pagination = ({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event: any, newPage: any) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  return (
    <StyledDataWrapperBox id="journal_entry_list_main" role="main">
      <ProgressModal
        id="modal_journal_entry_list_loading"
        showProgress={isLoading}
      />
      <HeaderRow id="journal_entry_list_header">
        <IconBox>
          <Typography variant="pageTitle">Journal Entries</Typography>
        </IconBox>
        <SearchBar
          id="journal_entry_search"
          size="small"
          onChange={onSearch}
          options={searchOptions}
          searchText={search}
          showPopover={showSuggestionPopover}
          setShowPopover={setShowSuggestionPopover}
        />
        <ButtonBox>
          {journalEntryList && journalEntryList?.length > 0 && (
            <SplitButton
              id={'btn_bulk_action_options'}
              options={!readonly ? bulkAdminActions : bulkExportActions}
              hidden={false}
              handleOptionClick={handleBulkAction}
              ariaLabelMessage="Select bulk action option"
            />
          )}
          {!readonly && (
            <ButtonWithOptions
              buttonID="add_journal_entry_button"
              popoverID="add_journal_entry_popover"
              onClick={handleNewButtonAction}
              buttonLabel="Add New"
              buttonIcon={<AddIcon />}
              options={NewJournalEntryOptions}
            />
          )}
        </ButtonBox>
      </HeaderRow>
      <StyledDataGridPro
        id="journael_entries_list_grid"
        dataList={journalEntryList?.length > 0 ? journalEntryList : []}
        minHeight="1vh"
        apiRef={apiRef}
        pagination
        paginationMode="server"
        pageSize={2}
        components={{ Footer: CustomPagination }}
        onPageChange={handlePageChange}
        hideFooterPagination={false}
        hideFooter={false}
        rowCount={rowCountState}
        autoHeight={false}
        headerList={headerList}
        handleOnView={handleOnView}
        selectionModel={journalEntrySelectionModel}
        activeHeaderFields={activeHeaderFields}
        setSelectionModel={setJournalEntrySelectionModel}
        handleFilter={handleFilter}
        handleUpdateHeader={handleUpdateHeader}
        onColumnOrderChange={onRowColumnOrderChange}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      />
      {!!selectedJournalEntry?.type &&
        (readonly ? (
          <JournalEntryDetailsReadOnly
            selectedJournalEntry={selectedJournalEntry}
            onDetailClose={onDetailsPanelClose}
            fetchAllJournalEntries={searchFilteredList}
            {...(fundId ? { fundId: fundId } : {})}
          />
        ) : (
          <JournalEntryDetails
            selectedJournalEntry={selectedJournalEntry}
            setSelectedJournalEntry={setSelectedJournalEntry}
            onDetailClose={onDetailsPanelClose}
            fetchAllJournalEntries={searchFilteredList}
            {...(fundId ? { fundId: fundId } : {})}
          />
        ))}
      <ConfirmationDialog
        open={Boolean(uploadedFile)}
        onClose={clearUploadedFile}
        id="confirmation_upload"
        actions={[
          {
            label: 'Upload',
            onClick: handleUploadTemplate,
            id: 'btn_upload',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: clearUploadedFile,
            id: 'btn_cancel',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content={
          <Box component="span">
            Are you sure you want to upload{' '}
            <strong>{uploadedFile?.name}</strong>?
          </Box>
        }
        title="Confirm Upload"
      />
      <ConfirmationDialog
        open={isUploadComplete}
        onClose={clearUploadedFile}
        id="confirmation_upload_complete"
        actions={[
          {
            label: 'Upload History',
            onClick: () => {
              clearUploadCompleted();
              history.push(RoutingPaths.UploadHistory);
            },
            id: 'btn_upload_history',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Close',
            onClick: clearUploadCompleted,
            id: 'btn_close',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Journal Entries file uploaded successfully. The file is being processed. Large files may take a while to finish; Please check the Upload History page to check the status of the upload."
        title="Upload Results"
      />
      <JournalEntryPostDialog
        open={showPostDialog}
        onPostCancel={onPostCancel}
        onPostComplete={onPostComplete}
        onClose={() => {}}
        ids={journalEntriesToPost}
        postAction={postAction}
        postFiltersRequest={postFiltersRequest}
      />
      <DeleteConfirmationDialog
        open={Boolean(deleteAction)}
        id="delete-confirmation-modal"
        primaryButtonAction={onDeleteConfirm}
        secondaryButtonAction={onDeleteCancel}
        actionType={`${Action.DELETE} ${deleteCount}`}
        instructionModifier="to delete the selected journal entry or entries, including all associated line items."
      />
      <ConfirmationDialog
        open={deleteError === 'POSTED'}
        id="delete_je_error"
        actions={[
          {
            label: 'OK',
            onClick: onDeleteCancel,
            id: 'btn_close_error',
            variant: 'contained',
            color: 'primary',
          },
        ]}
        title="Delete Error"
        content="Entries that have been posted will not be deleted, please reverse those journal entries."
      />
    </StyledDataWrapperBox>
  );
};
