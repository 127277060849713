import { Box } from "@mui/material";
import React, { ReactElement } from "react";

import { ColumnOrder } from "../../../utils/types/columnOrder";
import { FilterItem } from "../../../utils/types/listItems";
import ContactPermissionsDataGrid from "./ContactPermissionsDataGrid/ContactPermissionsDataGrid";

type Props = {
  hidden?: boolean;
  roleList: FilterItem[];
  preloadedContactId?: string;
  contactPermissionColumnOrder?: ColumnOrder | null;
};

const ReadOnlyContactPermissions: React.FC<Props> = (props: Props): ReactElement => {
  const {
    hidden = false,
    preloadedContactId = "",
    contactPermissionColumnOrder,
    roleList = [],
  } = props;


  return (
    <Box id="contact_permissions" role="main" hidden={hidden} mt={2}>
      <ContactPermissionsDataGrid
        readonly={true}
        investorFilterList={[]}
        roleList={roleList}
        preloadedContactId={preloadedContactId}
        fundFilters={[]}
        isScreenLocked={true}
        autoHeight={true}
        contactPermissionColumnOrder={contactPermissionColumnOrder}
      />
    </Box>
  );
};

export default ReadOnlyContactPermissions;
