import { useCallback, useContext, useState } from "react";

import { AppContext } from "../../../core/context/appContextProvider";
import { useEffectAsync } from "../../../utils/hooks/useEffectAsync.hook";
import { Distributions } from "../../../utils/types/distributions.type";
import { getDistributionDetails } from "../../distributions.service";

export const useDistributionDetailsEffect = (id?: string) => {
  const [distributions, setDistributions] = useState<Distributions>();
  const [loading, setLoading] = useState(false);
  const { informationAlert } = useContext(AppContext);

  const fetchDistributionsDetails = useCallback(
    async (isCanceled?: () => boolean) => {
      if (!id) return;
      try {
        setLoading(true);

        const response = await getDistributionDetails(id!);

        if (isCanceled?.()) return;
        setDistributions(response);
        setLoading(false);
      } catch (e) {
        informationAlert("Error in getting distributions", "error");
        setLoading(false);
      }
    },
    [id]
  );

  useEffectAsync(
    async (isCanceled) => {
      await fetchDistributionsDetails(isCanceled);
    },
    [fetchDistributionsDetails]
  );

  return {
    distributions,
    fetchDistributionsDetails,
    loading,
    setDistributions,
  };
};
