import React, { ReactElement, useContext, useEffect } from "react";

import { ImageItem } from "../../../utils/types/listItems";

type Props = {
    options: ImageItem[];
    stopClickForwarding: boolean;
    selectedValue?: string;
    handleOptionClick: any;

};

export const useSplitButtonSubmit = ({
    options,
    stopClickForwarding,
    selectedValue,
    handleOptionClick
    }: Props) => {
        
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    useEffect(() => {
        if (options.length - 1 < selectedIndex) {
            setSelectedIndex(0);
    }
    const firstNotHidden = options.findIndex((item) => item.hidden !== true);

    setSelectedIndex(firstNotHidden);
    }, [options]);

    const handleClick = () => {
        if (stopClickForwarding) {
            setOpen((prevOpen) => !prevOpen);
            return;
        }
        if (!selectedValue) handleOptionClick(options[selectedIndex].id);
        else handleOptionClick(options[selectedIndex].id, [selectedValue]);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
        ) => {
            setSelectedIndex(index);
            setOpen(false);
            handleOptionClick(options[index].id, [selectedValue]);
        };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    return {
        open,
        handleClose,
        setOpen,
        handleOptionClick,
        handleToggle,
        handleClick,
        selectedIndex,
        setSelectedIndex,
        handleMenuItemClick,
        anchorRef
    };
};