import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Grid, TextField, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import * as React from 'react';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { RouteProps } from 'react-router-dom';

import IconRightArrow from '../../../../assets/images/icons/icon_right_arrow.svg';
import HorizontalBox from '../../../../components/Boxes/HorizontalBox/HorizontalBox';
import Button from '../../../../components/Buttons/Button/Button';
import ImgIcon from '../../../../components/ImgIcon/ImgIcon';
import ConfirmationDialog from '../../../../components/Modal/ConfirmationDialog';
import ProgressPanel from '../../../../components/Progress/ProgressPanel/ProgressPanel';
import FundFilter from '../../../../components/Selectors/FundsFilter';
import InvestorFilter from '../../../../components/Selectors/InvestorsFilter';
import TransactionTypeFilter from '../../../../components/Selectors/TransactionTypesFilter';
import SingleSelect from '../../../../components/SingleSelect/SingleSelect';
import NumberFormatCustom from '../../../../components/TextFieldNumeric/NumberFormat';
import { DateTimeFormat } from '../../../../utils/helpers/format.helper';
import {
  SelectedTransaction,
  TransactionType,
} from '../../../../utils/types/transaction.type';
import { DatePicker } from '../../../fund/fundDetails/FundDetails.style';
import { StyledBackdrop } from '../../../investors/investorList/investorDetailPanel/InvestorDetailPanel.styles';
import { PORTAL_MAPPING } from '../constants';
import {
  ArrowIconBox,
  DetailPanel,
  FormBox,
  LinkButton,
  TitleBox,
} from './TransactionDetailPanel.styles';
import { useTransactionDetails } from './useTransactionDetails.hooks';

interface Props extends RouteProps {
  onTransactionDetailClose: Function;
  resetPaginationAndFetch: Function;
  selectedTransaction: SelectedTransaction;
}

const TransactionDetails: React.FC<Props> = ({
  onTransactionDetailClose,
  resetPaginationAndFetch,
  selectedTransaction,
}: Props): ReactElement => {
  const { type, transaction } = selectedTransaction;
  const isNewTransaction = type === TransactionType.New;
  const {
    register,
    handleSubmit,
    errors,
    control,
    selectedFund,
    createUpdateTransaction,
    loading,
    toggleDrawer,
    toBeDeleted,
    handleDelete,
    handleCancelDelete,
    handleConfirmDelete,
    closeDrawer,
    showExitConfirmation,
    keepDrawerOpen,
    isSuperAdminOrClientAdmin,
  } = useTransactionDetails({
    transaction,
    resetPaginationAndFetch,
    onTransactionDetailClose,
    isNewTransaction,
  });

  return (
    <React.Fragment>
      <DetailPanel
        id="TransactionDrawer"
        anchor="right"
        open={Boolean(type)}
        onClose={toggleDrawer}
      >
        <TitleBox>
          <Typography id="title" variant="formTitle" display="flex">
            {isNewTransaction ? 'Add Transaction ' : transaction?.name}{' '}
            <ArrowIconBox>
              <ImgIcon icon={IconRightArrow} />
            </ArrowIconBox>
          </Typography>
          <CloseIcon color="primary" onClick={toggleDrawer} />
        </TitleBox>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(createUpdateTransaction)}
          noValidate
        >
          <FormBox>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="transaction-name"
                  size="small"
                  fullWidth
                  aria-describedby="name"
                  label="Transaction Name"
                  placeholder="Transaction Name"
                  variant="outlined"
                  disabled={!isSuperAdminOrClientAdmin}
                  error={!!errors.name}
                  {...register('name', {
                    required: true,
                    validate: (value: any) => !!value?.trim(),
                  })}
                  helperText={!!errors.name && 'Transaction Name is required'}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="fund"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FundFilter
                      size="small"
                      id={'transaction-fund-filter'}
                      value={value}
                      onChange={(fund) => {
                        onChange({
                          id: fund?.id,
                          name: fund?.name,
                        });
                      }}
                      disabled={!isSuperAdminOrClientAdmin}
                      error={error}
                      placeholder="Fund Name"
                    />
                  )}
                  rules={{
                    required: 'Fund is required',
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="investor"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InvestorFilter
                      size="small"
                      id={'transaction-investor-filter'}
                      value={value}
                      fundId={selectedFund ? selectedFund.id : undefined}
                      disabled={!isSuperAdminOrClientAdmin}
                      onChange={(investor) => {
                        onChange({
                          id: investor?.id,
                          name: investor?.name,
                        });
                      }}
                      error={error}
                      placeholder="Investor Name"
                    />
                  )}
                  rules={{
                    required: 'Investor is required',
                  }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="txnType"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TransactionTypeFilter
                      size="small"
                      id={'transaction-type-filter'}
                      value={value}
                      error={error}
                      onChange={(txnType) => {
                        onChange({
                          id: txnType?.id,
                          name: txnType?.name,
                        });
                      }}
                      disabled={!isSuperAdminOrClientAdmin}
                      placeholder="Transaction Type"
                    />
                  )}
                  rules={{
                    required: 'Transaction Type is required',
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="amount"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        label="Amount"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={value ?? ''}
                        onChange={onChange}
                        disabled={!isSuperAdminOrClientAdmin}
                        id="transaction-amount"
                        InputProps={{
                          inputComponent:
                            typeof value === 'number'
                              ? NumberFormatCustom
                              : undefined,
                        }}
                        InputLabelProps={
                          !isNewTransaction
                            ? {
                                shrink: !!value,
                              }
                            : {}
                        }
                        error={!!error}
                        helperText={error?.message ?? ''}
                      />
                    );
                  }}
                  rules={{
                    required: 'Amount is required',
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="reviewable.name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        id="transaction-batch-name"
                        size="small"
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        aria-describedby="batch-name"
                        label="Batch Name"
                        placeholder="Batch Name"
                        disabled={!isSuperAdminOrClientAdmin}
                        variant="outlined"
                        error={!!error}
                        helperText={error?.message ?? ''}
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="reviewableType.name"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <SingleSelect
                        id="transaction-portal-mapping"
                        noGutter
                        optionList={PORTAL_MAPPING}
                        idField="id"
                        labelField="name"
                        handleOptionSelection={(event: SelectChangeEvent) => {
                          onChange({
                            target: {
                              value: event.target.value,
                            },
                          });
                        }}
                        value={value ?? ''}
                        error={!!error}
                        disabled={!isSuperAdminOrClientAdmin}
                        helperText={error?.message ?? ''}
                        label="Portal Mapping"
                      />
                    );
                  }}
                  control={control}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <DatePicker
                        label="Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            id="transaction-date"
                            error={!!error}
                            helperText={error?.message ?? ''}
                          />
                        )}
                        disabled={!isSuperAdminOrClientAdmin}
                        onChange={(value) => {
                          onChange({
                            target: {
                              value: value,
                            },
                          });
                        }}
                        value={value ? DateTimeFormat.shortDate(value) : ''}
                      />
                    );
                  }}
                  rules={{
                    required: 'Date is required',
                  }}
                  control={control}
                  defaultValue={undefined}
                />
              </Grid>
              <Grid xs={6} item />
              {isSuperAdminOrClientAdmin && (
                <Grid xs={12} item>
                  <LinkButton
                    id="delete-transaction-button"
                    variant="text"
                    disableElevation
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={handleDelete}
                    name="Delete Transaction"
                  >
                    Delete Transaction
                  </LinkButton>
                </Grid>
              )}
            </Grid>
          </FormBox>
          <HorizontalBox
            addTopShadow
            attachToBottom={true}
            hidden={false}
            fullWidth
          >
            <Button
              id={'transaction-cancel-button'}
              variant="outlined"
              onClick={toggleDrawer}
              text={'Cancel'}
              color={'secondary'}
              fullWidth
              addSpaceBetweenButtons
            />
            {isSuperAdminOrClientAdmin && (
              <Button
                id={'transaction-save-button'}
                variant="contained"
                text={'Save & Close'}
                color={'primary'}
                type="submit"
                fullWidth
                addSpaceBetweenButtons
              />
            )}
          </HorizontalBox>
        </form>
        <StyledBackdrop open={Boolean(loading)} />
        <ProgressPanel
          id={'progress_transaction_panel'}
          showProgress={Boolean(loading)}
          text={`${loading} Transaction...`}
        />
      </DetailPanel>
      <ConfirmationDialog
        open={!!toBeDeleted}
        onClose={handleCancelDelete}
        id="delete-transaction-confirmation"
        actions={[
          {
            label: 'Delete Permanently',
            onClick: () => handleConfirmDelete(toBeDeleted as string),
            id: 'delete-transaction-modal-button',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Cancel',
            onClick: handleCancelDelete,
            id: 'cancel-delete-transaction-modal-button',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Are you sure you want to delete this transaction? This action cannot be undone."
        title="Delete transaction?"
      />
      <ConfirmationDialog
        open={showExitConfirmation}
        onClose={keepDrawerOpen}
        id="save-transaction-confirmation"
        actions={[
          {
            label: 'Keep Editing',
            onClick: keepDrawerOpen,
            id: 'save-transaction-modal-button',
            variant: 'contained',
            color: 'primary',
          },
          {
            label: 'Discard Changes',
            onClick: closeDrawer,
            id: 'cancel-save-transaction-modal-button',
            variant: 'outlined',
            color: 'error',
          },
        ]}
        content="Unsaved changes will be lost"
        title="You have Unsaved Changes"
      />
    </React.Fragment>
  );
};

export default TransactionDetails;
