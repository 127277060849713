export const changeFolder: string = 'changeFolder';
export const fetchDocuments: string = 'fetchDocuments';
export const fetchPermissions: string = 'fetchPermissions';
export const fetchFolderContent: string = 'fetchFolderContent';
export const initialize: string = 'initialize';
export const initialized: string = 'initialized';
export const loading: string = 'loading';
export const openFolder: string = 'openFolder';
export const resetFiltersSelection: string = 'resetFiltersSelection';
export const toggleFileStatus: string = 'toggleFileStatus';
export const toggleDocControlPermissions: string =
  'toggleDocControlPermissions';
export const toggleFileArchiveSettings: string = 'toggleFileArchiveSettings';
export const togglePageLock: string = 'togglePageLock';
export const toggleReadOnly: string = 'toggleReadOnly';
export const toggleWaterMark: string = 'toggleWaterMark';
export const updateRowsSelection: string = 'updateRowsSelection';
export const folderCreated: string = 'folderCreated';
export const folderCreating: string = 'folderCreating';
export const downloadingFiles: string = 'downloadingFiles';
export const downloadingFilesComplete: string = 'downloadingFilesComplete';
export const deletingFilesAndFolder: string = 'deletingFilesAndFolder';
export const deletionComplete: string = 'deletionComplete';
export const folderUpdated: string = 'folderUpdated';
export const updateFilterParams: string = 'updateFilterParams';
export const removeLoading: string = 'removeLoading';
export const documentFolderTree: string = 'documentFolderTree';
export const filesMoved: string = 'filesMoved';
export const downloadDocLinks: string = 'downloadDocLinks';
export const publishFiles: string = 'publishFiles';
export const changeFolderIndex: string = 'changeFolderIndex';
export const fetchUserDocumentFilters: string = 'fetchUserDocumentFilters';
export const fetchConsent: string = 'fetchConsent';
export const consentConfirmation: string = 'consentConfirmation';
export const applyPermissionFilter: string = 'applyPermissionFilter';
export const applyNamesFilter: string = 'applyNamesFilter';
export const toggleFoldersVisibility: string = 'toggleFoldersVisibility';
export const toggleArchivedVisibility: string = 'toggleArchivedVisibility';
export const fetchDocumentOptions: string = 'fetchDocumentOptions';
