import { FolderOpen } from "@mui/icons-material";
import { Box, Drawer, FormControlLabel,styled, Typography, typographyClasses } from "@mui/material";

export const HeaderRow = styled(Box)(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const FilterRow = styled(Box)(({
  theme
}) => ({
  display: "grid",
  gridTemplateColumns: "400px auto",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const ArkClientBoxIcon = styled(FolderOpen)(({
  theme
}) => ({
  color: theme.palette.grey[500],
}));

export const ArkClientBox = styled(Box)(({
  theme
}) => ({
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  gridTemplateColumns: "auto auto",
  gridGap: theme.spacing(2),
}));

export const Container = styled(Drawer)(({
  theme
}) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 'calc(100% - 32px)',
    maxWidth: 'calc(100% - 32px)',
    padding: theme.spacing(2, 4, 2, 4),
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255,255,255, 0.7)',
  },
}));

export const HeaderTitleContainer = styled(Box)(({
  theme
}) => ({
  display:"grid",
  alignItems:"center",
  gridTemplateColumns:"repeat(2, auto) 1fr",
  gridGap: theme.spacing(4),
}));

export const HeaderBreadcrumbContainer = styled(Box)(({
  theme
}) => ({
  display:"grid",
  alignItems:"center",
  gridTemplateColumns:"repeat(3, auto)",
  gridGap: theme.spacing(2),
}));

export const SwitchLabel = styled(FormControlLabel)(() => ({
  [`& .${typographyClasses.root}`]: {
    textTransform: 'capitalize',
  },
}));
